export const SET_CACHE_MODE = 'SET_CACHE_MODE';
export const ADD_PLUGIN_CACHE_MODE = "ADD_PLUGIN_CACHE_MODE"
export const SAVED_CACHED_PLUGINS = "SAVED_CACHED_PLUGINS"
export const SET_CACHED_PLUGINS_TIME = "SET_CACHED_PLUGINS_TIME"

/**
 * Sets cache mode
 */
export const setCacheMode = (data) => ({
  type: SET_CACHE_MODE,
  payload: data,
});

/*
* Cache mode unsaved changes and plugins
*/
export const addPluginCacheMode = (data) => ({
  type: ADD_PLUGIN_CACHE_MODE,
  payload: data,
})

/*
* Cache mode saved changes and plugins
*/
export const savedPluginsInCacheMode = (data) => ({
  type: SAVED_CACHED_PLUGINS,
  payload: data,
})

/*
* Cached plugins time
*/
export const cachedPluginsTime = (data) => ({
  type: SET_CACHED_PLUGINS_TIME,
  payload: data,
})
