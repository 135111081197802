export const SET_WHATIF = "SET_WHATIF";
/**
 *
 * @param {*} whatIf
 * Action for dashboard what if
 */
export function setWhatIf(whatIf) {
  return dispatch => {
    dispatch({
      type: SET_WHATIF,
      payload: whatIf
    });
  };
}
