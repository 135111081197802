export const CHANGE_PLUGIN_LIST_VISIBILITY = "CHANGE_PLUGIN_LIST_VISIBILITY";
/**
 * 
 * @param {*} status 
 * @returns 
 * 
 * Sets the default plugin object
 */
export const changePluginListVisibility = (data) => ({
  type: CHANGE_PLUGIN_LIST_VISIBILITY,
  payload: data,
});