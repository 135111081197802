import React, { Component } from "react";
import { Tree, Menu, Icon } from "antd";
import { connect } from "react-redux";

import "./TreeModel.css";
import treeModelReducer from "./TreeModelReducer";
import { getModel, getModelRealDb } from "./TreeModelAction";
import TreeModelItem from "./TreeModelItem";

const { TreeNode } = Tree;

class TreeModel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      renamedItem: "",
      info: ""
    };
  }

  componentDidMount(){
    let { modelData } = this.props;
    let updatedData = JSON.parse(JSON.stringify(modelData));

    this.setState({
      data : updatedData
    })
  }

  componentWillReceiveProps(nextProps) {
    const { modelData } = nextProps;
    let { checkedItems, ...data } = modelData;

    this.setState({
      data: data,
      checkedKeys: checkedItems
    });
  }

  onExpand = expandedKeys => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    this.setState({
      expandedKeys,
      autoExpandParent: false
    });
  };

  onCheck = checkedKeys => {
    this.setState({ checkedKeys });
    this.props.changeTreeModel(checkedKeys, this.state.data);
  };

  onSelect = (selectedKeys, e) => {
    if (selectedKeys.length != 0)
      this.setState({ selectedKeys, info: e.target });
  };

  handleDoubleClick = e => {
    e.preventDefault();
    this.setState({
      renamedItem: e.target.innerText
    });
  };

  updateData = (updatedItem, action) => {
    const { data } = this.state
    const updatedData = data;

    const { selectedKeys } = this.state;

    if (selectedKeys[0]) {
      const keys = selectedKeys[0].split(",");
      if (action === "duplicate") {
        if (keys.length == 1) {
          updatedData.tables[keys[0]].duplicated = true;
          updatedItem.aliasName = null;
          updatedData.tables.push(updatedItem);
        } else if (keys.length == 2) {
          updatedData.tables[keys[0]].tables[keys[1]].duplicated = true;
          updatedItem.aliasName = null;
          updatedData.tables[keys[0]].tables.push(updatedItem);
        } else if (keys.length == 3) {
          updatedData.tables[keys[0]].tables[keys[1]].columns[
            keys[2]
          ].duplicated = true;
          updatedItem.aliasName = null;
          updatedData.tables[keys[0]].tables[keys[1]].columns.push(updatedItem);
        }
      } else {
        if (keys.length == 1) {
          updatedData.tables[keys[0]] = updatedItem;
        } else if (keys.length == 2) {
          updatedData.tables[keys[0]].tables[keys[1]] = updatedItem;
        } else if (keys.length == 3) {
          updatedData.tables[keys[0]].tables[keys[1]].columns[
            keys[2]
          ] = updatedItem;
        }
      }
    }

    this.setState({
      data: updatedData,
      renamedItem: "",
      selectedKeys: []
    });
    this.props.changeTreeModel(this.state.checkedKeys, updatedData);
  };

  cancelRename = () => {
    this.setState({
      renamedItem: ""
    });
  };

  renderTreeNodes = (data, parentIndex) => {
    const { renamedItem, info } = this.state;
    return data.map((item, index) => {
      let key = parentIndex != undefined ? [...parentIndex] : [];
      key.push(index);

      return (
        <TreeNode
          key={key}
          dataRef={item}
          title={
            <TreeModelItem
              data={data}
              item={item}
              key={key}
              itemKey={key}
              info={info}
              renamedItem={renamedItem}
              // onSelect={this.onSelect}
              onCancel={this.cancelRename}
              updateData={this.updateData}
            />
          }
        >
          {item.tables && this.renderTreeNodes(item.tables, key)}
          {item.columns && this.renderTreeNodes(item.columns, key)}
        </TreeNode>
      );
    });
  };

  render() {
    const { data } = this.state;
    return (
      <div>
        {Object.keys(data).length !== 0 ? (
          <Tree
            checkable
            onExpand={this.onExpand}
            expandedKeys={this.state.expandedKeys}
            autoExpandParent={this.state.autoExpandParent}
            onCheck={this.onCheck}
            checkedKeys={this.state.checkedKeys}
            onSelect={this.onSelect}
            selectedKeys={this.state.selectedKeys}
            onDoubleClick={this.handleDoubleClick}
          >
            {this.renderTreeNodes(data.tables)}
          </Tree>
        ) : (
          <div />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  getModel,
  getModelRealDb
};

const mapStateToProps = state => {
  return {
    state: state
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TreeModel);
