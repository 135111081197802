import { Component } from "react";
import React from "react"
import { Row } from "react-bootstrap";
import { shadeColor } from "../../ButtonFilter/getButtonComponent";
import { calculateText } from "../../common";

// Measure Header, includes title of plugins
export default class MeasureDefaultHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    // Returns header style
    headerStyle = () => {
        let config = this.props.config
        let configObj = {}
        let isPluginHEqualsOne = this.props.pluginH === 1 ? true : false

        configObj["display"] = isPluginHEqualsOne ? "none" : ""
        configObj["color"] = config.colour
        configObj["fontFamily"] = config.headerFont
        configObj["textAlign"] = config.measureHeaderAlign == undefined ? "left" : config.measureHeaderAlign
        configObj["fontWeight"] = config.headerFontWeight == undefined || config.headerFontWeight ? "bold" : ""
        configObj["fontStyle"] = config.headerFontStyle == undefined ? "" : config.headerFontStyle ? "italic" : ""
        configObj["textDecoration"] = config.headerTextDecor == undefined ? "" : config.headerTextDecor ? "underline" : ""
        configObj["fontSize"] = config.headerTextSize == undefined ? 16 : config.headerTextSize + "px"

        configObj["display"] = "inline-block"
        configObj["width"] = "100%"
        configObj["whiteSpace"] = "nowrap"
        configObj["overflow"] = "hidden"
        configObj["textOverflow"] = "ellipsis"
        configObj["cursor"] = "pointer"

        return configObj
    }

    // Returns header description style
    headerDescriptionStyle = () => {
        let config = this.props.config
        let configObj = {}

        configObj["color"] = config.secondaryColour == undefined ? shadeColor(config.colour === "red" ? "#ff0000" : config.colour, 0, 70) : config.secondaryColour
        configObj["fontFamily"] = config.headerFont
        configObj["textAlign"] = config.measureHeaderAlign == undefined ? "left" : config.measureHeaderAlign
        configObj["fontWeight"] = config.headerDescriptionFontWeight == undefined ? "" : config.headerDescriptionFontWeight ? "bold" : ""
        configObj["fontStyle"] = config.headerDescriptionFontStyle == undefined ? "" : config.headerDescriptionFontStyle ? "italic" : ""
        configObj["textDecoration"] = config.headerDescriptionTextDecor == undefined ? "" : config.headerDescriptionTextDecor ? "underline" : ""
        configObj["fontSize"] = config.headerDescriptionTextSize == undefined ? 11 : config.headerDescriptionTextSize + "px"
        configObj["fontFamily"] = config.headerDescriptionFont

        configObj["display"] = "inline-block"
        configObj["width"] = "100%"
        configObj["whiteSpace"] = "nowrap"
        configObj["overflow"] = "hidden"
        configObj["textOverflow"] = "ellipsis"

        return configObj
    }

    // Returns row style
    rowStyle = () => {
        let config = this.props.config
        let configObj = {}
        let isTitleNotAvailable = config.title === "" || config.title == null || config.title == undefined ? true : false

        configObj["height"] = ((this.props.pluginHeight * 40 / 100)) - (isTitleNotAvailable ? 10 : 0) + "px"
        configObj["margin"] = "0px"
        configObj["maxWidth"] = "100%"

        return configObj
    }

    componentDidMount() {
        let header = calculateText(
            this.props.plugin,
            this.props.config
                ? (this.props.config.measureHeader === undefined || this.props.config.measureHeader === "")
                    ? this.props.pluginData[0]
                        ? this.props.pluginData[0].categoryValue
                        : null
                    : this.props.config.measureHeader
                : null
        );

        this.setState({
            ...this.state,
            header: header
        });
    }
    
    componentWillReceiveProps(nextProps) {
        let newState = {...this.state};
        let isStateChanged = false;
        
        if (this.props.config?.measureHeader !== nextProps.config?.measureHeader) {
            isStateChanged = true;
            newState.header = calculateText(
                this.props.plugin,
                nextProps.config
                    ? (nextProps.config.measureHeader === undefined || nextProps.config.measureHeader === "")
                        ? this.props.pluginData[0]
                            ? this.props.pluginData[0].categoryValue
                            : null
                        : nextProps.config.measureHeader
                    : null
            );
        }

        if (isStateChanged) {
            this.setState(newState);
        }
    }

    render() {
        let config = this.props.config
        let isHeaderFontDescriptionAvailable = config.headerFontDescription && config.headerFontDescription !== "" ? true : false

        return (
            <Row style={this.rowStyle()}>
                {
                    <div style={{ 
                            marginLeft: "10px", 
                            display: "inline-flex", 
                            alignItems: "flex-start", 
                            flexDirection: "column", 
                            justifyContent: "center", 
                            maxWidth: "calc(100% - 15px)", 
                            width: "calc(100% - 15px)",
                            marginTop: isHeaderFontDescriptionAvailable ? "3px" : "0px" }}>
                        <span style={this.headerStyle()}>{this.state.header}</span>
                        {isHeaderFontDescriptionAvailable ?
                            <span style={this.headerDescriptionStyle()}>{config.headerFontDescription}</span>
                            : null}
                    </div>}
            </Row>
        );
    }
}
