export const configurations = {
    animation: "cubic",
    duration: 1,
    font: "Verdana",
    axisFontSize: 12,
    measureFontSize: 12,
    measureAutoFontSize: true,
    showMeasureName: true,
    showMeasureValue: true,
    innerRadius: "80",
    legend: true,
    measureType: "numeric",
    axisMin: "",
    axisMax: "",
    axisType: "numeric",
    showTargets: true,
    targetLabel: "",
    targetDataType: "numeric",
    targetStart: "",
    targetEnd: "",
    targetColor: "#FF0000",
    handType: "triangle",
    measuresOnAxis: false,
    chartType: "radial",
    staticTarget: false,
};

export const actions = [
    {
        trigger: "click",
        type: "click",
        name: "Tıklama",
        output: ["measure"],
        description: ""
    }
];

export const reactions = [
    {
        id: "filter",
        name: "Filtre",
        description: "desc87",
        type: "general"
    }
];

export const conditionalFormatColumnMap = ["measure", "maximum", "minimum", "target"];
export const conditionalFormatTargetMap = null;
export const conditionalFormatOptions = ["backgroundColor"];

export const conversionMap = {
    "table": {
        columnMap: {
            columns: {
                name: "Columns",
                from: ["maximum", "minimum", "measure", "target"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
    "measure-tile": {
        columnMap: {
            measure: {
                name: "Measure",
                from: ["measure", "maximum", "minimum", "target"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
};