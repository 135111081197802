import React, { Component } from "react";
import Text from "../../GeneralComponents/Text/Text";
import { Row, Col, Button, Checkbox } from "antd";
import {
    FontSizeOutlined,
    BoldOutlined,
    ItalicOutlined
} from "@ant-design/icons";
import i18n from "../../../Utils/i18next";
//Changes Font thickness and style for Conditional Formatting.
export default class FontChanger extends Component {
    constructor(props) {
        super(props);
    }

    onChangeBold = (e) => {
        this.props.handleChange({
            type: this.props.type, value: {
                ...this.props.fontStyle,
                bold: e.target.checked
            }
        })
    }

    onChangeItalic = (e) => {
        this.props.handleChange({
            type: this.props.type, value: {
                ...this.props.fontStyle,
                italic: e.target.checked
            }
        })
    }

    render() {
        return (
            <Row style={{ marginBottom: "5px" }}>
                <Col style={{ textAlign: "center" }} span={3}>
                    <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col style={{ textAlign: "left" }} span={9}>
                    <Text
                        style={{
                            fontSize: "16px",
                            display: "inline-block",
                            marginTop: "2px"
                        }}
                    >
                        {i18n.t("Dashboard.ConditionalFormatting.Font")}
                    </Text>
                </Col>
                <Col span={1}><BoldOutlined style={{ marginTop: "-5px" }} /></Col>
                <Col span={1}><Checkbox style={{ marginTop: "5px" }} onChange={this.onChangeBold} defaultChecked={this.props.fontStyle !== undefined ? this.props.fontStyle.bold : false} /></Col>
                <Col span={3}></Col>
                <Col span={1}><ItalicOutlined style={{ marginTop: "-5px" }} /></Col>
                <Col span={1}><Checkbox style={{ marginTop: "5px" }} onChange={this.onChangeItalic} defaultChecked={this.props.fontStyle !== undefined ? this.props.fontStyle.italic : false} /></Col>
            </Row>
        )
    }
}