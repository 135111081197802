import React, { Component } from "react";
import { Icon } from "antd";
import i18n from "../../Utils/i18next";

/**
 * Shows user has already been activated
 */
export default class UserAlreadyActivated extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={"empty-dashboard"}>
        <span>
          <Icon type="smile" className={"icon-in-text"} theme="twoTone"/>
          <span>{i18n.t("UserEntrance.Activation.AlreadyActivated")}</span>
        </span>
      </div>
    );
  }
}
