import { Modal, Steps, Tooltip } from "antd";
import React, { Component } from "react";
import i18n from "../../Utils/i18next";
import { setMinimize } from "./FileUploadAction";
import Button from "../GeneralComponents/Button/Button";
import FileUploadSteps from "./FileUploadSteps";
import "./fileUpload.css";
import { showNotificationWithIcon } from "../../Utils/Notification";
import { connect } from "react-redux";
import { store } from "../..";
import { STATUS } from "./UploadOperation";
import { setComesFromDashboardFileUpload, setDataSourceId, setDataSourceSelfUtils } from "../Datasources/SelfServiceReducer/SelfServiceAction";

const { Step } = Steps;

const stepLength = 3;

/**
 * File Upload base components
 * Holds steps and sub components.
 */
class FileUploadContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: 0,
      chooseFileValidation: false,
      previewValidation: false,
      validationForUploadValidation: false,
      firstDataUpload: false,
      selectedModel: "",
      modelList: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    let isFirstUpload = this.props.state.SelfServiceReducer.firstDataUpload !== nextProps.state.SelfServiceReducer.firstDataUpload && nextProps.state.SelfServiceReducer.firstDataUpload
    let state = {...this.state}
    let flag = false

    if (isFirstUpload) {
      state["firstDataUpload"] = true
      flag = true
    } else if (!nextProps.fileUploadVisibility) {
      state["firstDataUpload"] = false
      state["current"] = 0
      flag = true
    }

    if (nextProps.model !== undefined && Object.keys(nextProps.model).length && nextProps?.model?.id !== this.state.selectedModel?.id && nextProps.comesFromDashboardFileUpload !== true) {
      state["selectedModel"] = nextProps.model
      flag = true
    }

    if (flag) {
      this.setState(state)
    }
  }

  validationOperations = (next, now) => {
    let chooseFileValidation = false;
    let previewValidation = false;
    let validationForUploadValidation = false;

    if (now === 0 && next === 1) {
      chooseFileValidation = true;
    } else if (now === 1 && next === 2) {
      previewValidation = true;
    } else if (now === 2 && next === 3) {
      validationForUploadValidation = true;
    }

    this.setState({
      chooseFileValidation,
      previewValidation,
      validationForUploadValidation,
    });
  };

  validationResponse = (response) => {
    this.setState(
      {
        chooseFileValidation: false,
        previewValidation: false,
        validationForUploadValidation: false,
      },
      () => {
        let status = response.status;
        let message = response.message;

        if (status === true) {
          if (this.state.firstDataUpload && this.state.current == 1) {
            this.nextForStepTwo()
          } else {
            this.next();
          }
        } else if (status === false && message !== undefined) {
          showNotificationWithIcon(i18n.t("Error"), message, "error");
        }
      }
    );
  };

  next(validate = undefined) {
    const current = this.state.current + 1;

    if (validate) {
      this.validationOperations(current, this.state.current);
    } else {
      this.setState({ current });
    }
  }

  /*
  * Check step two operations
  */
  nextForStepTwo(validate = undefined) {
    const current = this.state.firstDataUpload ? this.state.current + 2 : this.state.current + 1;

    if (validate) {
      this.setState({
        chooseFileValidation: false,
        previewValidation: true,
        validationForUploadValidation: false,
      });
    } else {
      this.setState({ current });
    }
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  fileUploadModalCancelVisible = () => {
    this.setState({
      current: 0,
    });

    let data = {
      openFileUpload: false,
      dataSourceId: "",
      firstDataUpload: false
    }

    store.dispatch(setDataSourceSelfUtils(data))
    store.dispatch(setComesFromDashboardFileUpload(false))

    this.props.fileUploadVisibilityChange(false);
  };

  setMinimize = (status) => {
    store.dispatch(setMinimize(status));
  };

  /*
  * Sets or changes selected model
  */
  setSelectedModel = (model, index) => {
    if (model !== "NO_MODEL") {
      model["index"] = index;

      this.setState({
        ...this.state,
        selectedModel: model
      })
    } else {
      this.setState({
        ...this.state,
        selectedModel: "NO_MODEL"
      })
    }
  }

  /*
  * Checks is first step valid
  */
  checkIsFirstStepCorrect = () => {
    if (this.props.dataSource && this.props.state.SelfServiceReducer.dataSourceId === "") {
      store.dispatch(setDataSourceId(this.props.dataSource.id))
    }

    let hasDataSource = this.props.state.SelfServiceReducer.dataSources.length === 1 && this.props.state.SelfServiceReducer.dataSourceId !== "" || this.props.dataSource
    let hasSelectedModel = ((this.state.selectedModel && this.state.selectedModel !== "") || this.state.selectedModel === "NO_MODEL") || this.state.firstDataUpload 
    let isValidForNextStep = (hasDataSource && hasSelectedModel)

    if (isValidForNextStep) {
      return false
    }

    return true
  }

  render() {
    const {
      current,
      chooseFileValidation,
      previewValidation,
      validationForUploadValidation,
    } = this.state;

    const fileUploadReducer = this.props.state.FileUploadReducer;
    const isItDone =
      fileUploadReducer &&
      fileUploadReducer.operationStatus &&
      (fileUploadReducer.operationStatus.status.key === STATUS.FAILED.key ||
        fileUploadReducer.operationStatus.status.key === STATUS.DONE.key);

    if (this.props.fileUploadVisibility === true) {
      return (
        <Modal
          title={i18n.t("FileUpload.AppendData")}
          visible={!fileUploadReducer.minimize}
          width="90%"
          closable={false}
          style={{ top: "50px" }}
          footer={null}
        >
          <Steps progressDot current={current}>
            <Step
              title={i18n.t("FileUpload.ChooseYourFileTitle")}
              description={i18n.t("FileUpload.ChooseYourFileDesc")}
            />
            <Step
              title={i18n.t("FileUpload.PreviewAndEditTitle")}
              description={i18n.t("FileUpload.PreviewAndEditDesc")}
            />
            {!this.state.firstDataUpload ?
              <Step
                title={i18n.t("FileUpload.CheckAcceptibilityTitle")}
                description={i18n.t("FileUpload.CheckAcceptibilityDesc")}
              /> : null}
            <Step
              title={i18n.t("FileUpload.UploadingTitle")}
              description={i18n.t("FileUpload.UploadingDesc")}
            />
          </Steps>

          <div className="steps-content">
            <FileUploadSteps
              current={current}
              chooseFileValidation={chooseFileValidation}
              previewValidation={previewValidation}
              validationForUploadValidation={validationForUploadValidation}
              validationResponse={this.validationResponse}
              dataSource={this.props.dataSource}
              onFileUploadSucces={this.props.onFileUploadSucces}
              firstDataUpload={this.state.firstDataUpload}
              changeFileUploadDataSource={this.props.changeFileUploadDataSource}
              comesFromDashboardFileUpload={this.props.comesFromDashboardFileUpload}
              setSelectedModel={this.setSelectedModel}
              selectedModel={this.state.selectedModel}
            />
          </div>

          <div className="steps-action" style={{ textAlign: "right" }}>
            {current < stepLength && (
              <Button
                style={{ marginLeft: 8 }}
                onClick={() => this.fileUploadModalCancelVisible()}
              >
                {i18n.t("FileUpload.Cancel")}
              </Button>
            )}

            {current === stepLength && isItDone && (
              <Button
                style={{ marginLeft: 8 }}
                onClick={() => this.fileUploadModalCancelVisible()}
              >
                {i18n.t("FileUpload.Close")}
              </Button>
            )}

            {current > 0 && current < stepLength && (
              <Button style={{ marginLeft: 8 }} onClick={() => this.prev()}>
                {i18n.t("FileUpload.Previous")}
              </Button>
            )}
            {current < stepLength - 2 && (
                <Button
                  style={{ marginLeft: 8 }}
                  type="primary"
                  disabled={this.checkIsFirstStepCorrect()}
                  onClick={() => this.next(true)}
                >
                  {i18n.t("FileUpload.Next")}
                </Button>
            )}

            {current === stepLength - 2 && (
              <Button
                style={{ marginLeft: 8 }}
                type="primary"
                onClick={() => this.nextForStepTwo(true)}
              >
                {i18n.t("FileUpload.CheckTables")}
              </Button>
            )}

            {current === stepLength - 1 && (
              <Button
                style={{ marginLeft: 8 }}
                type="primary"
                onClick={() => this.next(true)}
              >
                {i18n.t("FileUpload.Upload")}
              </Button>
            )}

            {current === stepLength && (
              <Button
                style={{ marginLeft: 8 }}
                type="primary"
                onClick={() => this.setMinimize(true)}
              >
                {i18n.t("FileUpload.Minimize")}
              </Button>
            )}
          </div>
        </Modal>
      );
    } else {
      return null;
    }
  }
}

const mapDispatchToProps = {
  setMinimize,
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadContent);
