import { SET_CACHE_MODE } from './CacheModeAction';
import { ADD_PLUGIN_CACHE_MODE } from "./CacheModeAction"
import { SAVED_CACHED_PLUGINS } from "./CacheModeAction"
import { SET_CACHED_PLUGINS_TIME } from "./CacheModeAction"

const initialState = {
  isCacheModeActive: false,
  cachedPluginsMap: new Map(),
  savedCachedPluginsMap: new Map(),
  cachedOptions: {
    type: "ON_THE_HALF_HOUR"
  }
};

/*
 * Reducer for managing setCacheMode configuration in dashboard settings section.
 */
const setCacheMode = (state = initialState, action) => {
  switch (action.type) {
    case SET_CACHE_MODE:
      return { ...state, isCacheModeActive: action.payload };
    case ADD_PLUGIN_CACHE_MODE:
      return { ...state, cachedPluginsMap: action.payload };
    case SAVED_CACHED_PLUGINS:
      return { ...state, savedCachedPluginsMap: action.payload };
    case SET_CACHED_PLUGINS_TIME:
      return { ...state, cachedOptions: action.payload };
    default:
      return state;
  }
};

export default setCacheMode;
