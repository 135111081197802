import React from "react";
import NoDataContent from "../NoDataContent/NoDataContent";
import i18n from "../../../Utils/i18next";
import { store } from "../../../index";
import $ from "jquery";
import { decidePluginForApplyValue } from "./pluginApplyProcess";
import PluginError from "../PluginError";
import { calculateTitleSize, calculatePluginInlineHeight, preparePluginsContainerAfterPluginError, preparePluginsContainerBeforePluginError } from "../../DrillDown/PluginHeightWithDrilldown"
import { connect } from 'react-redux';

const clone = require("rfdc")();
const pluginsToTrigger = new Set([
  "filter",
  "selection-box",
  "radio-button-filter",
  "route-map",
  "world-map"
]);

/**
   * Clears plugin's export handlers
   * 
   * @param {*} pluginId 
   * @returns 
   */
const clearExportHandlers = (pluginId) => {
  if (!pluginId) return;

  let container = document.getElementById(pluginId);

  if (!container) return;

  delete container.PNG;
  delete container.PDF;
  delete container.XLSX;
};

/**
 * This component for common content for all plugins.
 * @param {*} isRerender 
 * @param {*} pluginRender 
 * @param {*} plugin 
 * @param {*} data 
 * @param {*} columnMap 
 * @param {*} pluginConfig 
 * @param {*} condFormats 
 * @param {*} setPluginRerender 
 * @param {*} lastContent 
 * @param {*} updateLastContent 
 * @param {*} THIS 
 * @returns 
 */
export const renderContent = (
  isRerender,
  pluginRender,
  plugin,
  data,
  columnMap,
  pluginConfig,
  condFormats,
  setPluginRerender,
  lastContent,
  updateLastContent,
  THIS
) => {
  // checks if plugin is rendered
  const isPluginRender = plugin => {
    if (plugin.data && plugin.data.length === 0) {
      calculateTitleSize(plugin)
      let reduxState = store.getState();
      let dashboardSettingNoDataTitle = reduxState.SettingNoDataTitleReducer.data;
      let configNoDataTitle = pluginConfig.noDataTitle ? pluginConfig.noDataTitle.trim() : ""
      
      if (configNoDataTitle || dashboardSettingNoDataTitle) {
        return {
          status: false,
          errorMessage: (
            <span>
              {configNoDataTitle !== "" ? configNoDataTitle : dashboardSettingNoDataTitle}
            </span>
          )
        }
      } else {
        return {
          status: false,
          errorMessage: (
            <span>
              {i18n.t("NoDataContent.NoData")} <br></br>{" "}
              {i18n.t("NoDataContent.PluginCouldNotBeVisualized")} 
            </span>
          )
        };  
      }
    }

    return { status: true };
  };

  /**
   * Checks the column map data for plugin default render or render with data.
   * @param {*} plugin
   */
  const findColumnMapHasAnyColumn = plugin => {
    let status = false;

    if (plugin.columnMap === undefined) {
      return status;
    }

    let keys = Object.keys(plugin.columnMap);

    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];

      if (plugin.columnMap[key].data && plugin.columnMap[key].data.length > 0) {
        status = true;
        break;
      }
    }

    return status;
  };

  let renderStatus = isPluginRender(plugin);
  let hasGetData = plugin.getData && plugin.getData === true ? true : false;


  if (!renderStatus.status) {
    if(plugin.rerender === true || plugin.getData === true) {
      setPluginRerender(
        false,
        plugin.id,
        false,
        plugin.isInteraction
      );
    }

    clearExportHandlers(plugin.id);
    preparePluginsContainerBeforePluginError(plugin.id)
    
    return <NoDataContent plugin={plugin} content={renderStatus.errorMessage} />;
  } else {
    if (isRerender && !hasGetData) {
      try {
        preparePluginsContainerAfterPluginError(plugin.id)

        updateLastContent(undefined);
        let columnMapHasAnyColumn = findColumnMapHasAnyColumn(plugin);

        let pluginWithDataCheck =
          columnMapHasAnyColumn &&
          plugin.data !== undefined &&
          plugin.data.length > 0;

        if (columnMapHasAnyColumn === false) {
          pluginRender(plugin.id, data, columnMap, pluginConfig, [], null);
          setPrivateInteractionFiltersForBackNavigation(plugin, THIS);
        } else {
          if (pluginWithDataCheck) {
            let clmnMap = plugin.drillDownColumnMap ? plugin.drillDownColumnMap : plugin.columnMapForPlugin

            for (let i = 0; i < Object.keys(clmnMap).length; i++) {
              let objectKey = Object.keys(clmnMap)[i]
          
              clmnMap[objectKey] = Array.isArray(clmnMap[objectKey])
                ? clmnMap[objectKey].filter(obj => obj.isDisabledColumn !== true)
                : clmnMap[objectKey].isDisabledColumn !== true
                  ? clmnMap[objectKey]
                  : {}
            }
            
            pluginRender(
              plugin.id,
              plugin.data,
              plugin.drillDownColumnMap ? plugin.drillDownColumnMap : plugin.columnMapForPlugin, // Performs drilldown control and sends the appropriate column map accordingly
              pluginConfig,
              condFormats,
              null
            );

            setPrivateInteractionFiltersForBackNavigation(plugin, THIS);
          }
        }
      } catch (error) {
        let errors = new Map();

        if (plugin.errors && plugin.errors.size > 0) {
          errors = new Map([...plugin.errors])
        } else {
          errors.set("renderError", {
            type: "error",
            message: i18n.t("PluginErrors.VisualizationError")
          });
        }

        //clearExportHandlers(plugin.id);
        preparePluginsContainerBeforePluginError(plugin.id)

        let errorContent = <PluginError errors={errors} plugin={plugin}/>;

        setPluginRerender(
          false,
          plugin.id,
          false,
          plugin.isInteraction
        );

        if (error.message) {
          console.error(error.message);
        }

        return errorContent;
      }
    } else if (lastContent) {
      preparePluginsContainerAfterPluginError(plugin.id)

      return lastContent;
    }
  }
};

/**
 *
 * @param {*} plugin
 * When the private interactions made in the main report are returned from the detail report, the private filters are set to the main report.
 */
function setPrivateInteractionFiltersForBackNavigation(plugin, THIS) {
  let reduxState = store.getState();
  let pluginPrivateInteractionFilters =
    reduxState.PluginTriggerReducer.pluginPrivateInteractionFilters;
  let sourcePluginsWithValues =
    reduxState.PluginTriggerReducer.sourcePluginsWithValues;

  if (pluginPrivateInteractionFilters.has(plugin.id)) {
    let privateMethodObject = pluginPrivateInteractionFilters.get(plugin.id);

    plugin[privateMethodObject.privateMethodName](
      privateMethodObject.columns,
      "#" + plugin.id
    );
  }

  if (
    pluginsToTrigger.has(plugin.key) &&
    sourcePluginsWithValues.has(plugin.id)
  ) {
    let values = sourcePluginsWithValues.get(plugin.id);

    decidePluginForApplyValue(plugin, values, THIS);
  }
}

/**
 * Removes the corrupted plugin and adds its last working version instead.
 * @param {*} id
 * @param {*} content
 */
function renderPrevContent(id, content) {
  let plugin = $("#" + id);
  plugin.attr("remove", "true");
  plugin.after(content);
  plugin.remove();
}
