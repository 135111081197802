import React, { Component } from "react";
import { Menu, Dropdown, Icon, Divider } from "antd";
import i18n from "../../Utils/i18next";
import { DeleteOutlined } from "@ant-design/icons";

const { SubMenu } = Menu;

//Table or column, duplicate, aggRule, where clause operations are listed in this component.
export default class ModelItemPanel extends Component {
  menu = (item) => {
    return (
      <Menu onClick={(e) => this.props.onSelect(e, item)}>
        <Menu.Item name={"duplicateRule" + item.name} key="duplicate">
          <Icon type="block" className="treeItemIcons" />
          {i18n.t("Model.Duplicate")}
        </Menu.Item>
        {item && item.hasOwnProperty("whereClause") && (
          <Menu.Item name={"whereClause" + item.name} key="whereClause">
            <i className={"treeItemPanelIcons fontIcon"}> ω </i>{i18n.t("Model.WhereClause")}
          </Menu.Item>
        )}
        {item && item.hasOwnProperty("aggrRule") && item.columnType !== "newColumn" && (
          <SubMenu
            id={"aggRule" + item.name}
            key="aggrRuleMenu"
            title={
              <span>
                <i className={"treeItemPanelIcons fontIcon"}> ∫ </i>{i18n.t("Model.AggrigationRule")}
              </span>
            }
          >
            <Menu.Item name={"sum" + item.name} key="sum">Sum</Menu.Item>
            <Menu.Item name={"count" + item.name} key="count">Count</Menu.Item>
            <Menu.Item name={"distinct" + item.name} key="distinct">Distinct</Menu.Item>
            <Menu.Item name={"countDistinct" + item.name} key="countdistinct">Count Distinct</Menu.Item>
            <Menu.Item name={"avg" + item.name} key="avg">Avg</Menu.Item>
            <Menu.Item name={"max" + item.name} key="max">Max</Menu.Item>
            <Menu.Item name={"min" + item.name} key="min">Min</Menu.Item>
            <Menu.Item name={"custom" + item.name} key="custom">{i18n.t("Custom")}</Menu.Item>

          </SubMenu>
        )}
        {item.aggrRule && item.aggrRule != "none" && (
          <Menu.Item name={"clearAggRule" + item.name} key="clear">
            <i
              className={"treeItemPanelIcons fontIcon"}
              style={{ color: "red", fontFamily: "Georgia, serif" }}
            >
              {" "}
              x{" "}
            </i>
            {i18n.t("Model.ClearAggRule")}
          </Menu.Item>
        )}
        <Menu.Item name={"info" + item.name} key="info">
          <Icon type="info" className="treeItemIcons" />
          {i18n.t("Info")}
        </Menu.Item>
        {item.columnType === "newColumn" ?
          <Menu.Item name={"edit" + item.name} key="edit">
            <Icon type="form" className="treeItemIcons" />
            {i18n.t("Edit")}
          </Menu.Item>
          :
          <Menu.Item name={"rename" + item.name} key="rename">
            <Icon type="form" className="treeItemIcons" />
            {i18n.t("Rename")}
          </Menu.Item>}
        <Divider style={{ margin: "4px 0" }}></Divider>
        {item && item.hasOwnProperty("whereClause") && (
          <Menu.Item name={"delete" + item.name} key="deleteTable">
            <i
              style={{ color: "red" }}
              className={"treeItemPanelIcons fontIcon"}
            >
              {" "}
              X{" "}
            </i>
            {i18n.t("Delete")}
          </Menu.Item>
        )}
        {item.columnType === "newColumn" ?
          <Menu.Item name={"duplicateRule" + item.name} key="remove" style={{ display: "flex", alignItems: "center" }}>
            <DeleteOutlined style={{ color: "red" }} />
            <span style={{ color: "red" }}>
              {i18n.t("Delete")}
            </span>
          </Menu.Item> : null}
      </Menu>
    );
  };

  render() {
    const { item, content, trigger } = this.props;
    return (
      <div>
        <Dropdown
          overlay={this.menu(item)}
          trigger={trigger}
          placement="bottomLeft"
        >
          {content}
        </Dropdown>
      </div>
    );
  }
}
