import React, { Component } from "react";
import FilterShowButton from "./FilterShowButton";
import i18n from "../../../Utils/i18next";
import { Popover, Table, Row, Col } from "antd";
import Tooltip from "../../GeneralComponents/Tooltip/Tooltip";
import "./pluginFilterShow.css";
import { InteractionOutlined, SwapOutlined } from "@ant-design/icons";
import { store } from "../../..";
import evaluate from "../../../Utils/Evaluate";

/**
 * Lists filters applied to plugin.
 */
export default class PluginFilterShow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibilityOfList: false
    };
  }

  /*
* Returns column's current available filter value
*/
  returnValuesForFiltersArea = (column) => {
    if (column.lsqlValue && column.filterArea === "DefaultFilters") {
      if (column.lsqlType === "value" && column.lsqlValue.split("\n").length > 1) {
        return column.lsqlValue.split("\n")?.filter(val => val !== "").join(", ")
      }

      return column.lsqlValue
    } else if (column.value) {
      return column.value
    } else if (column.lastValue) {
      return column.lastValue
    } else {
      return ""
    }
  }

  /**
   * 
   * @param {*} value 
   * @returns 
   * 
   * If value is empty, empty string is set
   */
  emptyShowControl = (column) => {
    let value
    let values = this.returnValuesForFiltersArea(column)
    let isStringHasApostropheWithSingleValue = values && values[0] === "'" && values[values.length - 1] === "'" ? true : false
    let isStringHasBacktickWithSingleValue = values && values[0] === "`" && values[values.length - 1] === "`" ? true : false
    let isStringHasBrackets = values && values[0] === "(" && values[values.length - 1] === ")" ? true : false
    let isValueStringOrNumber = typeof values === "string" || typeof values === "number" ? true : false
    let isValuesComesDefaultFilter = column.filterArea === "DefaultFilters" ? true : false
    let isFilterPredicateNullOrIsNotNull = column.filterPredicate === "is null" || column.filterPredicate === "is not null" ? true : false

    if (values && values.length === 0) {
      value = "";
    } else if (isValueStringOrNumber) {
      if (isStringHasApostropheWithSingleValue || isStringHasBacktickWithSingleValue) {
        value = values.slice(1, values.length - 1)
      } else if (isStringHasBrackets) {
        let slicedValues = values.slice(1, values.length - 1)
        let splittedValues = slicedValues.split(",")
        let valuesArray = []

        splittedValues.map(splittedVal => {
          let isValueHasApostrophe = (splittedVal[0] === "'" || splittedVal[0] === "'") && (splittedVal[splittedVal.length - 1] === "'" || splittedVal[splittedVal.length - 1] === "'") ? true : false

          if (isValueHasApostrophe) {
            let slicedVal = splittedVal.slice(1, splittedVal.length - 1)

            valuesArray.push(slicedVal)
          }
          else {
            valuesArray.push(splittedVal)
          }
        })

        return valuesArray.join(", ")
      } else {
        value = values
      }
    } else if (values) {
      value = values.join(", ")
    }

    if (isValuesComesDefaultFilter && isFilterPredicateNullOrIsNotNull) {
      let filterPredicate = column.filterPredicate === "is null" ? "IsNull" : "IsNotNull"

      return i18n.t(`FilterPredicates.${filterPredicate}`)
    } else {
      return value
    }
  };

  /*
  * Returns icons by filters area (drilldown, navigation etc.)
  */
  returnIconsForFiltersArea = (filtersArea) => {
    switch (filtersArea) {
      case "DefaultFilters":
        return <i className="fa fa-filter"></i>
      case "ProtectedDefaultFilters":
        return <i className="fa fa-key"></i>
      case "Navigation":
        return <SwapOutlined />
      case "Interaction":
        return <InteractionOutlined />
      case "Drilldown":
        return <i className="fas fa-angle-double-down"></i>
    }
  }

  /*
  * Returns plugin filter show's inside
  */
  returnValueByDataTypeForPluginFilterShowInside = (d, i) => {
    let value = this.returnValuesForFiltersArea(d)
    let isValueNull = value == null || value === "" ? true : false
    let isFilterPredicateNullOrIsNotNull = d.filterPredicate === "is null" || d.filterPredicate === "is not null" ? true : false
    let displayName = d.displayName;

    if (i > 0 && d.filterArea === "DefaultFilters") {
      displayName = d.whereOperator + displayName
    }

    if (!isValueNull || isFilterPredicateNullOrIsNotNull) {
      return (
        <Row className={"list-row"}>
          <Col className={"filters-list-text"} span={7}>
            <Tooltip
              tooltipPlacement={"left"}
              tooltip={d.displayName}
            >
              <span style={{fontWeight: "bold"}}>
                {d.displayName}
                </span>
            </Tooltip>
          </Col>
          <Col className={"filters-list-text"} span={7}>
            <Tooltip
              tooltipPlacement={"top"}
              style={{ minWidth: 0 }}
              tooltip={i18n.t("FilterShowPredicates." + d.filterPredicate)}
            >
              <span>
                {i18n.t("FilterShowPredicates." + d.filterPredicate)}
              </span>
            </Tooltip>
          </Col>
          <Col className={"filters-list-text"} span={8}>
            {
              !isFilterPredicateNullOrIsNotNull && (
                <Tooltip tooltipPlacement={"right"} tooltip={this.emptyShowControl(d)}>
                  <span style={{fontWeight: "bold"}}>
                    {this.emptyShowControl(d)}
                  </span>
                </Tooltip>
              )
            }
          </Col>
          <Col span={2} style={{ textAlign: "right", fontSize: 12 }}>
            <Tooltip tooltipPlacement={"right"} tooltip={i18n.t(`AllFilters.${d.filterArea}`)}>
              <span>{this.returnIconsForFiltersArea(d.filterArea)}</span>
            </Tooltip>
          </Col>
        </Row>
      )
    }
  }

  /** Prepare filter content for popover */
  prepareFilters = allFilters => {
    let filtersKeys = Object.keys(allFilters);
    let holderMaxHeight = "312px";

    if (filtersKeys.length >= 3) {
      holderMaxHeight = "90px";
    } else if (filtersKeys.length === 2) {
      holderMaxHeight = "145px";
    }

    if (this.state.visibilityOfList !== true) {
      return null;
    }

    return (
      <div className={"filters-list-area"}>
        {filtersKeys.map((key, index) => {
          let filtersByType = allFilters[key];

          return (
            <div style={{ marginBottom: "10px" }}>
              <h6
                style={{
                  borderTop:
                    index == 0 ? "none" : "1px dashed rgb(204, 204, 204)"
                }}
                className={"filters-list-title"}
              >
                {i18n.t(filtersByType.title)}
              </h6>

              <div
                className={"list-holder"}
                style={{ maxHeight: holderMaxHeight, maxWidth: "80dvw", width: "300px" }}
              >
                <Row>
                  <Col className={"filters-list-header"} span={7}>
                    {i18n.t("Columns")}
                  </Col>
                  <Col className={"filters-list-header"} span={7}>
                    {i18n.t("Predicates")}
                  </Col>
                  <Col className={"filters-list-header"} span={8}>
                    {i18n.t("Values")}
                  </Col>
                </Row>

                {filtersByType.data.map((d, i) => (
                  <>
                    {this.returnValueByDataTypeForPluginFilterShowInside(d, i)}
                  </>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  /** Visibility of plugin filters area */
  showFiltersArea = visible => {
    this.setState({ visibilityOfList: visible });
  };

  render() {
    let popoverContent = this.prepareFilters(this.props.data);

    return (
      <div className={"plugin-filter-shows-area"} style={{ display: this.props.iconVisibility || this.props.iconVisibility == undefined ? "block" : "none" }} >
        <Popover
          content={popoverContent}
          trigger="click"
          visible={this.state.visibilityOfList}
          onVisibleChange={this.showFiltersArea}
          arrowPointAtCenter
          placement="topRight"
        >
          <FilterShowButton style={{ right: "10px", bottom: "2px" }} showFiltersArea={this.showFiltersArea} />
        </Popover>
      </div>
    );
  }
}
