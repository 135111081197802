import { CommentOutlined, ExpandAltOutlined, ShrinkOutlined } from "@ant-design/icons";
import { Col, Row, Tooltip } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { BsArrowReturnRight } from "react-icons/bs";
import i18n from "../../Utils/i18next";
import "./userNotifications.css";

/*
* Per notification component
*/
export default class PerNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seeMore: false
        }
    }

    /*
    * Styles of icons
    */
    iconStyle = {
        fontSize: "24px",
        color: "#dddddd"
    }

    /*
    * Icon List
    */
    iconList = {
        dashboardComment: <CommentOutlined style={this.iconStyle} />,
        dashboardCommentReply: <BsArrowReturnRight style={this.iconStyle} />
    }

    /*
    * Translate list
    */
    translateList = {
        dashboardComment: i18n.t("Notification.MentionedYouAComment"),
        dashboardCommentReply: i18n.t("Notification.RepliedYouAComment")
    }

    /*
    * On click notification, goes to link
    */
    goToLink = (notification) => {
        if (notification.topic === "dashboardComment" || notification.topic === "dashboardCommentReply") {
            let url = "/vispeahen/ui/dashboard/" + notification.link

            window.open(url, '_blank')
        }
    }

    /*
    * Returns username text size for mobile size
    */
    returnUsernameTextForMobileSize = (notification) => {
        if (!this.props.isUserNameTextMustBold) {
            return <>
                <span style={{ textAlign: "left" }}>
                    <b>{notification.createdUser}</b>
                    {this.translateList[notification.topic]}
                </span>
            </>
        } else {
            return <>
                <span style={{ textAlign: "left", display: "flex" }}>
                    {`${notification.createdUser} ${this.translateList[notification.topic]}`}
                </span>
            </>
        }
    }

    render() {
        let notification = this.props.notification
        let userColor = this.props.usersWithColours?.get(notification.createdUser)

        return (
            <div className={"per-notification-main-container"} onClick={() => this.goToLink(notification)}>
                <Row style={{ height: "100%", display: "flex", alignItems: "center" }}>
                    <Col span={5} style={!this.props.isUserPhotoMustShow ? { height: "100%", display: "flex", alignItems: "center", justifyContent: "center" } : {display: "none"}}>
                        <div className="notification-user" style={{ border: `1px solid ${userColor}`, color: userColor }} >
                            {notification.createdUser[0].toUpperCase()}
                        </div>
                        {notification.unread ? <span className="unread" style={{ position: "absolute" }}></span> : null}
                    </Col>
                    <Col span={this.props.isUserPhotoMustShow ? 18 : 14} style={this.props.isUserPhotoMustShow ? { height: "100%", display: "flex", alignItems: "flex-start", fontSize: "14px", flexDirection: "column", marginLeft: "15px" } : { height: "100%", display: "flex", alignItems: "flex-start", fontSize: "14px", flexDirection: "column" }}>
                        <Row>
                            {this.returnUsernameTextForMobileSize(notification)}
                        </Row>
                        <Row>
                            <span className={this.state.seeMore ? "notification-message without-three-dot" : "notification-message with-three-dot"}>
                                {notification.message}
                            </span>
                        </Row>
                    </Col>
                    <Col span={5} style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "column" }}>
                        <div style={{ marginLeft: "7px", fontSize: "11px", color: "#767676" }}>
                            <Tooltip overlayClassName='dashboard-comment-tooltip' placement="top" style={{ fontSize: "11px" }} title={moment(notification.createDate).format("LLLL")}>
                                {moment(notification.createDate).fromNow()}
                            </Tooltip>
                        </div>
                        <div>
                            {this.iconList[notification.topic]}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}
