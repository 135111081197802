import React, { Component } from "react";
import PopupContainer from "../../../../GeneralComponents/PopupContainer/PopupContainer";
import LabelSelector from "./LabelSelector";
import CreateLabel from "./CreateLabel";
import Button from "../../../../GeneralComponents/Button/Button";
import "./label.css";
import { get, post } from "../../../../../Utils/WebService";
import { API_BASE, SHARED_LABEL } from "../../../../../config";
import { showNotificationWithIcon } from "../../../../../Utils/Notification";
import i18n from "../../../../../Utils/i18next";
import { TagsOutlined } from "@ant-design/icons";
import Text from "../../../../GeneralComponents/Text/Text";
import { getIcon, parseJwt } from "../../../../../Utils/Global";
import Cookies from "js-cookie";
import { getRoles } from "../../../../DashboardPage/RoleStore";
import $ from 'jquery'
const clone = require("rfdc")();

/**
 * Return labels without well defineds. If shared label selected, add shared label to this list.
 * @param {*} labels //all labels
 * @param {*} isSharedLabelSelected //shared label status 
 */
const labelsWithoutWellDefineds = (labels, isSharedLabelSelected) => {
  let selectedLabelList = []

  for (let i = 0; i < labels.length; i++) {
    if (labels[i].isDeletable === true || (isSharedLabelSelected && labels[i].name === SHARED_LABEL)) {
      selectedLabelList.push(labels[i])
    }
  }

  return selectedLabelList
}

export default class Labels extends Component {
  constructor(props) {

    super(props);
    this.state = {
      visibleLabels: false,
      visibleLabelSelector: true,
      visibleCreateLabel: false,
      labelList: [],
      sharedLabel: {},
      isSharedLabelSelected: false,
      selectedLabels: this.props.dashboard.labels == undefined
        ? []
        : this.props.dashboard.labels,
      selectedLabelsWithoutWellDefineds: this.props.dashboard.labels == undefined
        ? []
        : labelsWithoutWellDefineds(this.props.dashboard.labels, false),
      isDisplayNameError: false
    };

    this.wrapperRef = React.createRef();
  }


  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.getLabelList();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  //If click outside hide switcher area.
  handleClickOutside = event => {
    if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      if (!$(event.target).closest("#loginPopup").length > 0) {
        this.closeLabels();
      }
    }
  };

  // Sets displayName error visibility
  setIsDisplayNameError = (status) => {
    this.setState({
      ...this.state,
      isDisplayNameError: status
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dashboard.labels != this.props.dashboard.labels) {
      let isSharedLabelSelected = false;

      nextProps.dashboard.labels.map(label => {
        if (label.name === SHARED_LABEL) {
          isSharedLabelSelected = true;
        }
      })

      let selectedLabelsWithoutWellDefineds = labelsWithoutWellDefineds(nextProps.dashboard.labels, isSharedLabelSelected);

      this.setState({
        ...this.state,
        isSharedLabelSelected: isSharedLabelSelected,
        selectedLabelsWithoutWellDefineds: selectedLabelsWithoutWellDefineds,
        selectedLabels: nextProps.dashboard.labels
      });
    }
  }

  isValidRole = (roleOperationTypes, role, userRoleList) => {
    return (roleOperationTypes[role] && roleOperationTypes[role] === "WRITE")
  }

  /**
   * 
   * @param {*} roleOperationTypes 
   * @param {*} label 
   * @returns 
   * Checks whether the roles of the label coming as parameter are authorized to write.
   */
  hasWriteRole = (roleOperationTypes, label) => {
    let hasWriteRole = false;
    let userRoleList = getRoles().split(",");

    if (userRoleList.includes("Admin") || userRoleList.includes("Editor")) {
      hasWriteRole = true;
    } else {
      label.roles !== null &&
        label.roles.map((role) => {
          if (this.isValidRole(roleOperationTypes, role, userRoleList)) {
            hasWriteRole = true;
          }
        });
    }

    return hasWriteRole;
  };

  /**
   * Get all of the labels from api
   */
  getLabelList = () => {
    let url = `${API_BASE}/labels`;

    const successFunc = (result) => {
      let userDefinedLabels = [];
      let sharedLabel = {};
      let sign = parseJwt(Cookies.get("ORA_BIPS_NQID"));
      let roleOperationTypes = sign.operationTypes;

      result.data.map(label => {
        if (label.isDeletable === true && this.hasWriteRole(roleOperationTypes, label)) {
          userDefinedLabels.push(label)
        }

        if (label.name === SHARED_LABEL) {
          sharedLabel = { ...label };
        }
      });

      this.setState({
        ...this.state,
        labelList: userDefinedLabels,
        sharedLabel: sharedLabel
      });
    };

    get(url, successFunc, undefined, false);
  };

  /**
   * Update labelList from states
   * @param {*} givenList - new labelList
   */
  updateLabelList = (givenList) => {
    this.setState({ ...this.state, labelList: givenList });
  };

  /**
   * Change visibility of labelSelector and createLabels components because of they could not be
   * true at the same time set visibility of createLabel with !status
   * @param {boolean} status
   */
  changeShownComponent = (status) => {
    this.setState({
      ...this.state,
      visibleCreateLabel: !status,
      visibleLabelSelector: status,
    });
  };

  /**
   * Push given label to the labelList and save it through api
   * @param {object} label
   */
  addToLabelList = (label) => {
    let currentLabelList = clone(this.state.labelList);
    currentLabelList.push(label);
    this.setState({ ...this.state, labelList: currentLabelList }, () => this.assignLabel(label));
  };

  /**
   * Save given label object through api
   * @param {object} newLabel
   */
  createLabel = (newLabel) => {
    let requestBody = newLabel;
    let url = `${API_BASE}/labels`;

    const successFunc = (result) => {
      this.setState({
        ...this.state,
        visibleApplyLabels: this.state.visibleApplyLabels ? false : false,
      });

      showNotificationWithIcon(i18n.t("Dashboard.Label.Create.LabelSuccesfullyAdded"), null, "success");

      this.addToLabelList(result.data);

      this.changeShownComponent(true);

      this.props.labelCreated(result.data)
    };

    const errorFunc = (err) => {
      if (err === "LabelDisplayNameError") {
        showNotificationWithIcon(i18n.t(`Dashboard.Label.Create.${err}`, { labelDisplayName: newLabel.displayName }), null, "warn");

        this.setIsDisplayNameError(true)
      } else {
        showNotificationWithIcon(err, null, "error");
      }
    }

    post(url, requestBody, successFunc, errorFunc, true);
  };
  /**
   * Remove label from selected labels and save it through api
   * @param {objct} item - label which will be removed
   */

  removeLabel = (item) => {
    let tempSelected = clone(this.state.selectedLabels);
    let flag = 0;

    tempSelected.forEach(function (el, index) {
      if (el.id === item.id) {
        flag = index;
      }
    });

    tempSelected.splice(flag, 1);

    let isSharedLabelSelected = this.state.isSharedLabelSelected;

    if (item.name === SHARED_LABEL) {
      isSharedLabelSelected = false;
    }

    let selectedLabelsWithoutWellDefineds = labelsWithoutWellDefineds(tempSelected, isSharedLabelSelected)

    this.setState({
      ...this.state,
      isSharedLabelSelected: isSharedLabelSelected,
      selectedLabels: tempSelected,
      selectedLabelsWithoutWellDefineds: selectedLabelsWithoutWellDefineds
    });

    this.props.updateLabels(tempSelected);
  };

  /**
   * Assign label to the dashboard through api
   * @param {object} selectedItem - label which will be saved to the db
   */
  assignLabel = (selectedItem) => {
    let tempSelected = clone(this.state.selectedLabels);
    let duplicateCounterSelected = 0;

    tempSelected.forEach(function (item, ct) {
      if (item.id === selectedItem.id) {
        duplicateCounterSelected++;
      }
    });

    if (duplicateCounterSelected == 0) {
      selectedItem.isDeletable = true;
      tempSelected.push(selectedItem);

      let isSharedLabelSelected = this.state.isSharedLabelSelected;

      if (selectedItem.name === SHARED_LABEL) {
        isSharedLabelSelected = true;
      }

      let selectedLabelsWithoutWellDefineds = labelsWithoutWellDefineds(tempSelected, isSharedLabelSelected)

      this.setState({
        ...this.state,
        isSharedLabelSelected: isSharedLabelSelected,
        selectedLabels: tempSelected,
        selectedLabelsWithoutWellDefineds: selectedLabelsWithoutWellDefineds
      });

      this.props.updateLabels(tempSelected);
    } else {
      this.removeLabel(selectedItem);
    }
  };

  /**
   * Save labels of dashboard to the db if dashboard id exists(requestBody.id)
   * @param {array of objects} changedLabels - selected labels for specific dashboard
   */
  saveLabels = (changedLabels) => {
    let requestBody = this.props.dashboard;

    if (requestBody.id != undefined && (requestBody != null) != requestBody) {
      requestBody["labels"] = changedLabels;
      let url = `${API_BASE}/dashboard`;
      const successFunc = (result) => {
        showNotificationWithIcon(
          "Label successfully assigned to dashboard.",
          null,
          "success"
        );
      };

      post(url, requestBody, successFunc, undefined, true);
    }
  };

  openLabels = () => {
    if (this.state.visibleLabels !== true) {
      this.setState({
        visibleLabels: true
      });
    }
  };

  closeLabels = () => {
    this.setState({
      visibleLabels: false
    });
  };

  render() {
    let buttonTheme = this.props.theme?.buttons?.labelsButton;

    return this.props.readOnly === "WRITE" ? (
      <div ref={this.wrapperRef} className={"hide-on-mobile"}>
        <Button
          id="dashboardLabelsButton"
          className="dashboard-button btn btn-new circle-button"
          tooltip={i18n.t("Labels")}
          style={{
            float: "right",
            marginLeft: "5px",
          }}
          onClick={this.openLabels}
        >
          <style>
            {`
              #dashboardLabelsButton {
                background-color: ${buttonTheme?.backgroundColor};
                color: ${buttonTheme?.textColor};
                border-color: ${buttonTheme?.textColor}33;
              }

              #dashboardLabelsButton:hover,
              #dashboardLabelsButton:focus,
              #dashboardLabelsButton:active {
                background-color: ${buttonTheme?.backgroundColorActive};
                color: ${buttonTheme?.textColorActive};
                border-color: ${buttonTheme?.textColorActive}33;
              }
            `}
          </style>
          {
            getIcon(
              buttonTheme?.icon,
              <TagsOutlined style={{ fontSize: 25 }} className="label-icon" />,
              { fontSize: 25 },
              "label-icon"
            )
          }

          <div style={{ display: this.state.visibleLabels ? "block" : "none" }}>
            <PopupContainer width="300px" position="left">
              <LabelSelector
                selectedLabels={this.state.selectedLabelsWithoutWellDefineds}
                visibleLabelSelector={this.state.visibleLabelSelector}
                changeShownComponent={this.changeShownComponent}
                labelList={this.state.labelList}
                updateLabelList={this.updateLabelList}
                assignLabel={this.assignLabel}
                removeLabel={this.removeLabel}
                sharedLabel={this.state.sharedLabel}
              ></LabelSelector>
              <CreateLabel
                visibleCreateLabel={this.state.visibleCreateLabel}
                changeShownComponent={this.changeShownComponent}
                createLabel={this.createLabel}
                setIsDisplayNameError={this.setIsDisplayNameError}
                isDisplayNameError={this.state.isDisplayNameError}
              ></CreateLabel>
              <Button
                id={"closeLabelButton"}
                style={{
                  position: "absolute",
                  top: "3px",
                  right: "3px",
                  borderRadius: "40px",
                  padding: "6px 11px",
                  backgroundColor: "white",
                  zIndex: 1,
                }}
                type={"default"}
                onClick={this.closeLabels}
              >
                X
              </Button>
            </PopupContainer>
          </div>
        </Button>
      </div>
    ) : null;
  }
}
