import React, { Component } from "react";
import i18n from "../../../Utils/i18next";
import { get } from "../../../Utils/WebService";
import { API_BASE, DELETED_LABEL } from "../../../config.js";
import Text from "../../GeneralComponents/Text/Text";
import { TagOutlined } from "@ant-design/icons";
import SwitchSelect from "./SwitchSelect";
import { labelTranslateNameSet } from "../../../Utils/LabelOperations";
import { isEqual } from "lodash";
import { deepCopy } from "../../../Utils/Global";

/**
 * Label list for switcher area.
 */
export default class LabelList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labelList: [],
      newLabel: {}
    };
  }

  componentWillMount() {
    this.getLabels();
  }

  /**
   * Check label id for label list
   */
  getLabelIdInList = (labelList, labelId) => {
    let label = labelList.filter(label => label.id == labelId)[0];
    
    if (label) {
      return { label: i18n.t("WellDefined." + label.name), value: label.id };
    }

    return undefined;
  };

  /**
   * Get labels for change dashboard list
   */
  getLabels = () => {
    let url = `${API_BASE}/labels`;

    const successFunc = result => {
      let labels = labelTranslateNameSet(result.data, true);
      this.setState({
        ...this.state,
        labelList: labels
      });

      if (this.props.defaultLabelId) {
        let selectedLabel = this.getLabelIdInList(
          labels,
          this.props.defaultLabelId
        );
  
        this.props.updateSelectedLabel(selectedLabel);
      }
    };

    get(url, successFunc, undefined, false);
  };

  updateSelectedLabel = label => {
    this.props.updateSelectedLabel(label);
  };

  render() {
    if (!isEqual(this.props.newLabel, this.state.newLabel)) {
      let labelList = deepCopy(this.state.labelList);
      labelList.push(this.props.newLabel);
      
      this.setState({
        ...this.state,
        labelList: labelList,
        newLabel: this.props.newLabel,
      });
    }
    
    return (
      <div className="switch-dashboard-label-list">
        <TagOutlined className="subtitle-icon" />
        <Text className={"switch-dashboard-label-list-title"}>
          {i18n.t("Labels")}
        </Text>
        <SwitchSelect
          labelList={this.state.labelList}
          selectedLabel={this.props.selectedLabel}
          updateSelectedLabel={this.updateSelectedLabel}
          textColor={this.props.textColor}
          backgroundColor={this.props.backgroundColor}
        />
      </div>
    );
  }
}
