import $ from "jquery";

export const copyToClipboard = (data) => {
    $("body").append(
        "<input id='copyToClipboard' type='text' style='opacity: 0' value='" +
          data +
          "'></input>"
      );
      $("#copyToClipboard").select();
      document.execCommand("copy");
      $("#copyToClipboard").remove();
}