import React, { Component } from "react";
import Tab from "../GeneralComponents/Tab";
import Text from "../GeneralComponents/Text/Text";
import Search from "../GeneralComponents/Search/Search";
import BoxItem from "../GeneralComponents/BoxItem/BoxItem";
import i18n from "../../Utils/i18next";

/**
 * Contains tab with self-service connections
 */
export default class WithoutDataSourceConnectionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChanged: false,
    };
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.selectedSource !== this.props.selectedSource) {
      this.setState({
        isChanged: true
      })
    }
  }

  /*
  * Changes selected data
  */
  changeSelectedData = (data) => {
    this.setState({
      ...this.state,
      isChanged: false
    })

    this.props.changeSelectedData(data)
  }
  
  render() {
    return (
      <div>
        <Tab
          changeSelectedData={(data) => this.changeSelectedData(data)}
          col={4}
          data={this.props.viewedConnections}
          imageAndTextExtra={this.props.getExtra}
          activeDataSourceKey={this.props.activeDataSourceKey}
        >
          <Text type="span" className="content-description">
            {i18n.t("Model.SelectedTablesForModel")}
          </Text>
          {this.state.isChanged === true ?
          <Search
            id={"searchData"}
            width="100%"
            searchBarVisible={true}
            onChange={(e) => this.props.handleSearch(e)}
          ></Search>: ""}
          <br></br> 
          <div className={"container"}>
            <div className={"row"}>
              {this.props.tableData.map((table) => (
                <BoxItem
                  id={table.dataSourceKey + "_" + table.name}
                  title={table.name}
                  onClick={() => this.props.onTableSelected(table, "tableData")}
                  className={table.selected ? "selected" : ""}
                  tooltipTitle={table.dataSourceKey.split(".")[1] + "." + table.name}
                ></BoxItem>
              ))}
            </div>
          </div>
        </Tab>
      </div>
    );
  }
}
