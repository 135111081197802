import React, { Component } from "react";
import "./userNotifications.css"
import { BiBell } from "react-icons/bi";
import PopupContainer from "../GeneralComponents/PopupContainer/PopupContainer";
import WebSocketApp from "../WebSocket/WebSocketApp";
import Cookies from "js-cookie";
import NotificationContainer from "./NotificationContainer";
import { get, put } from "../../Utils/WebService";
import { deepCopy, getIcon } from "../../Utils/Global";
import { NOTIFICATION_BASE } from "./notificationConfig";
import { generateAndAppendUserColors } from "../DashboardPage/DashboardCrud/DashboardTools/DashboardComments/userColors";
import { API_BASE } from "../../config";
import $ from "jquery"
import moment from "moment"
import 'moment/locale/tr'

/*
* User Notifications main container
*/
export default class UserNotifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationPopupVisibility: false,
            authenticatedUser: Cookies.get("authenticatedUser"),
            topics: [],
            unreadNotifications: 0,
            notifications: [],
            userList: [],
            usersWithColours: new Map(),
            isPinned: false
        }

        this.wrapperRef = React.createRef();
    }

    /*
    * Gets unread notifications when component mount
    */
    getUnreadNotifications = () => {
        let url = NOTIFICATION_BASE + "/unread"

        let succesFunc = (res) => {
            this.setState({
                ...this.state,
                unreadNotifications: res.data
            })
        }

        get(url, succesFunc, () => { }, false)
    }

    /*
    * Changes status of is pinned
    */
    setIsPinned = () => {
        this.setState({
            ...this.state,
            isPinned: !this.state.isPinned
        })
    }

    /*
    * Gets all notifications when component mount
    */
    getAllNotifications = () => {
        let url = NOTIFICATION_BASE

        let succesFunc = (res) => {
            this.setState({
                ...this.state,
                notifications: res.data
            })
        }

        get(url, succesFunc, () => { }, false)
    }

    /**
    * If click outside hide switcher area.
    */
    handleClickOutside = event => {
        if (!this.state.isPinned) {
            let isTargetWrapper = this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target) ? true : false
            let isTargetTooltip = !$(event.target).closest(".dashboard-comment-tooltip").length > 0
            let isTargetAntSelect = !$(event.target).closest(".ant-select-dropdown-menu").length > 0 ? true : false
            let isTargetSelectItem = !$(event.target).closest(".ant-mentions-dropdown-menu-item").length > 0 ? true : false

            let willPopupClose = isTargetAntSelect && isTargetWrapper && isTargetTooltip && isTargetSelectItem ? true : false

            if (willPopupClose) {
                if (!$(event.target).closest("#loginPopup").length > 0) {
                    this.setVisibilityOfNotificationPopup(false)
                }
            }
        }
    };

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        let currLang = Cookies.get("i18next")

        this.setState({
            ...this.state,
            topics: [
                `/topic/unread-notification-${this.state.authenticatedUser}`,
                `/topic/notification-${this.state.authenticatedUser}`,
                `/topic/read-status-${this.state.authenticatedUser}`
            ]
        }, () => {
            this.getUnreadNotifications()
            this.getAllNotifications()
            this.getUserList()
        })

        moment.locale(currLang)
    }

    /*
    * Updates read status of all notificationss
    */
    updateReadStatus = () => {
        let url = NOTIFICATION_BASE + "/unread/change-status"

        let succesFunc = (res) => {
            let newState = deepCopy(this.state)

            newState["notificationPopupVisibility"] = this.state.notificationPopupVisibility

            if (this.state.notificationPopupVisibility === true) {
                newState["unreadNotifications"] = 0
                newState["notifications"].forEach((notification) => { notification.unread = false })
            }

            this.setState(newState)
        }

        let errorFunc = (err) => {
            console.log(err)
        }

        if (this.state.unreadNotifications > 0) {
            put(url, {}, succesFunc, errorFunc, false, undefined, true, false)
        }
    }

    /*
    * Changes status of notification popup
    */
    setVisibilityOfNotificationPopup = (status) => {
        this.setState({
            ...this.state,
            notificationPopupVisibility: status
        })

        if (status == true) {
            this.updateReadStatus()
        }
    }

    /*
    * Listens notification topic
    */
    listenNotificationTopic = (topic, destination) => {
        if (destination !== `/topic/read-status-${this.state.authenticatedUser}`) {
            topic["unread"] = true

            this.setState({
                ...this.state,
                unreadNotifications: this.state.unreadNotifications + 1,
                notifications: [topic].concat([...this.state.notifications])
            })
        } else {
            let newState = deepCopy(this.state)

            newState["notifications"].forEach((notification) => { notification.unread = false })

            this.setState({
                ...this.state,
                unreadNotifications: 0,
                notifications: newState.notifications
            })
        }
    }

    /*
    * Gets user list
    */
    getUserList = () => {
        let url = `${API_BASE}/user/tenant-users`;

        const succesFunc = (userResponse) => {
            let usersWithColours = generateAndAppendUserColors(userResponse.data)

            this.setState({
                ...this.state,
                userList: userResponse.data,
                usersWithColours: usersWithColours
            })
        }

        let errorFunc = () => { }

        get(url, succesFunc, errorFunc, false);
    }

    render() {
        let buttonTheme = this.props.theme?.buttons?.notificationsButton

        return (
            <>
                <WebSocketApp
                    listenTopic={this.listenNotificationTopic}
                    topics={this.state.topics}
                    dashboardId={"notDashboardId"}
                />

                <div id={this.props.id || "userNotifications"} className="user-notification-container" style={this.props.comesFromTopMenu ? {} : { float: "right", marginLeft: "5px", marginRight: "0px" }} onClick={() => this.setVisibilityOfNotificationPopup(true)}>
                    <style>
                        {this.props.theme?.buttons?.notificationsButton && `
                            #userNotifications {
                                background-color: ${this.props.theme?.buttons?.notificationsButton?.backgroundColor};
                                border-color: ${this.props.theme?.buttons?.notificationsButton?.textColor}33;
                                color: ${this.props.theme?.buttons?.notificationsButton?.textColor};
                            }

                            #userNotifications:hover,
                            #userNotifications:focus,
                            #userNotifications:active {
                                background-color: ${this.props.theme?.buttons?.notificationsButton?.backgroundColorActive} !important;
                                color: ${this.props.theme?.buttons?.notificationsButton?.textColorActive} !important;
                                border-color: ${this.props.theme?.buttons?.notificationsButton?.textColorActive}33 !important;
                            }
                        `}
                    </style>

                    {
                        getIcon(
                            buttonTheme?.icon,
                            <BiBell className="notification-inline-icon" />
                        )
                    }

                    {
                        this.state.unreadNotifications > 0 ?
                            <div className={this.props.comesFromTopMenu ? "new-message-icon" : "new-message-icon landing"} style={{ top: "0px", right: "50px" }}>{this.state.unreadNotifications < 10 ? this.state.unreadNotifications : "9+"}</div>
                            : null
                    }
                </div>

                {this.state.notificationPopupVisibility ?
                    <div
                        className={"user-notification-popup"}
                        ref={this.wrapperRef}>
                        <PopupContainer
                            className={this.props.comesFromTopMenu ? "user-notification-popup-container" : "user-notification-popup-container comesFromMobileMenu"}
                            width="555px"
                            right="55px"
                            top="50px"
                            background={"white"}
                            color="#505050"
                            navbar={true}
                            position={"right"}
                            overflow="hidden"
                            style={{
                                minHeight: "30vh",
                            }}
                            onMouseOver={() => this.updateReadStatus()}>
                            <NotificationContainer
                                setVisibilityOfNotificationPopup={this.setVisibilityOfNotificationPopup}
                                authenticatedUser={this.state.authenticatedUser}
                                notifications={this.state.notifications}
                                usersWithColours={this.state.usersWithColours}
                                getAllNotifications={this.getAllNotifications}
                                isPinned={this.state.isPinned}
                                setIsPinned={this.setIsPinned}
                            />
                        </PopupContainer>
                    </div> : null}
            </>
        );
    }
}
