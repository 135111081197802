export const configuration = {
    xZoom: true,
    yZoom: false,
    xZoomAlignment: "bottom",
    yZoomAlignment: "right",
    singleAxis: false,
    areaChart: true,
    continuousChart: false,
    smoothedLines: true,
    navigator: true,
    bullets: true,
    legend: true,
    groupData: true,
    groupCount: "200",
    groupAggrRule: "average",
    showTargets: false,
    fillTargets: false,
    targets: [{
        name: "",
        value: "",
        color: "red"
    }]
};

export const actions = [
    {
        trigger: "clickSlice",
        type: "click",
        name: "Click Slice",
        output: ["date"],
        description: "ActionClickDesc"
    },
];

export const reactions = [
    {
        id: "filter",
        name: "Filtre",
        description: "desc87",
        type: "general"
    }
];

export const conditionalFormatColumnMap = ["date", "measure"];
export const conditionalFormatTargetMap = ["measure"];
export const conditionalFormatOptions = ["backgroundColor"];

export const conversionMap = {
    "table": {
        columnMap: {
            columns: {
                name: "Columns",
                from: ["date", "measure"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
};