import { showError } from "../../../Utils/Notification";
import i18n from "../../../Utils/i18next";
import { addErrorInPlugin, removeErrorFromPlugin } from "./common";

const VALIDATION_ERROR_KEY = "columnMapValidation";

/**
 * 
 * @param {*} columnMap 
 * Checks if any of the plugin's column map fields have a column.
 */
const isEmptyCheck = (columnMap) => {
  let isEmpty = false;

  for (let item in columnMap) {
    if (columnMap[item]?.data?.length > 0) {
      isEmpty = false;
      return isEmpty;
    }

    isEmpty = true;
  }

  return isEmpty;
};

const isColumnSizeCheck = (dataSize, minimumColumnSize, activeColumnSize) => {
  return dataSize >= minimumColumnSize && activeColumnSize >= minimumColumnSize;
};

/**
 * 
 * @param {*} dependentColumns 
 * @param {*} columnMap 
 * 
 * Checks whether the columns of a plugin's dependent are empty or not
 * 
 */
const isDependentCheck = (dependentColumns, columnMap) => {
  let isValidDependent = true;

  dependentColumns.map((fieldName) => {
    for (let item in columnMap) {
      if (columnMap[item].name === fieldName) {
        isValidDependent = isColumnSizeCheck(
          columnMap[item].data.length,
          columnMap[item].minimumColumnSize,
          columnMap[item].data.filter(d => d.isDisabledColumn !== true).length
        );
        break;
      }
    }
  });

  return isValidDependent;
};

/**
 *
 * @param {*} plugin
 * 
 * Checks whether mandatory fields in column Mapping field are empty
 *
 */
export const columnMapValidation = (plugin) => {
  if (plugin.key === "measure-tile") {
    return true
  }

  let columnMap = plugin.columnMap;
  let isEmptyColumnMap = isEmptyCheck(columnMap);

  if (isEmptyColumnMap === true) {
    return false;
  } else {
    for (let item in columnMap) {
      if (
        !isColumnSizeCheck(
          columnMap[item].data.length,
          columnMap[item].minimumColumnSize,
          columnMap[item].data.filter(d => d.isDisabledColumn !== true).length
        )
      ) {
        let errorMessage = i18n.t("ColumnMapValidError", {
          pluginName: i18n.t("Plugins." + plugin.key + ".Name"),
          interpolation: { escapeValue: false }
        })

        if (columnMap[item].dependentColumns !== undefined) {
          if (
            isDependentCheck(columnMap[item].dependentColumns, columnMap) ===
            false
          ) {
            showError(errorMessage);
            addErrorInPlugin(plugin, errorMessage, VALIDATION_ERROR_KEY, "error");
            return false;
          }
        } else {
          showError(errorMessage);
          addErrorInPlugin(plugin, errorMessage, VALIDATION_ERROR_KEY, "error");
          return false;
        }
      }
    }

    removeErrorFromPlugin(plugin, VALIDATION_ERROR_KEY);
    return true;
  }
};
