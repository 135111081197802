import React, { Component } from 'react';
import { Mentions, Tooltip } from 'antd';
import moment from "moment"
import { BiReply } from "react-icons/bi"
import { RiDeleteBin7Line } from "react-icons/ri"
import { FiEdit2 } from "react-icons/fi"
import { BsArrowReturnRight } from "react-icons/bs"
import Cookies from 'js-cookie';
import { AiOutlineSend } from 'react-icons/ai';
import i18n from '../../../../../Utils/i18next';

class PerDashboardComment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            focus: false,
            comment: "",
            replyVisibility: false,
            editMode: false,
            edittedText: "",
            editModeLineHeight: 0
        };
    }

    /*
    * Removes comment
    */
    removeComment = () => {
        if (this.props.mainComment) {
            this.props.removeComment(true, this.props.dbComment.id)
        } else {
            this.props.removeComment(false, this.props.dbComment.mainCommentId, this.props.dbComment.id)
        }
    }

    /*
    * Changes comment
    */
    onChangeComment = (event) => {
        let value = event

        this.setState({
            ...this.state,
            comment: value,
        })
    }

    /*
    * Changes edited comment
    */
    onChangeEditedComment = (value) => {
        this.setState({
            ...this.state,
            edittedText: value
        })
    }

    /*
    * Changes reply visibility
    */
    changeReplyVisibiliy = () => {
        this.setState({
            ...this.state,
            replyVisibility: !this.state.replyVisibility
        })
    }

    /*
    * Sends comment
    */
    sendComment = (tenant, dbComment) => {
        let username = tenant
        let dashboardId = this.props.mainDashboardId
        let mainCommentId = dbComment.id
        let pattern = /@\w+/g;
        let matches = this.state.comment.match(pattern)
        let relatedUsers = matches && matches.length > 0 ? matches.map(match => match.slice(1)) : [];

        relatedUsers = relatedUsers.concat([`${dbComment.username}`])

        let requestBody = {
            username: username,
            dashboardId: dashboardId,
            mainCommentId: mainCommentId,
            relatedUsers: relatedUsers,
            comment: this.state.comment,
        }

        this.props.sendReply(requestBody)

        this.setState({
            ...this.state,
            comment: "",
            replyVisibility: false
        })
    }

    /*
    * Sends comment with enter
    */
    sendCommentWithEnter = (tenant, dbComment, event) => {
        if (event.key === "Enter") {
            this.sendComment(tenant, dbComment)
        }
    }

    /*
    * Changes edit mode
    */
    changeEditMode = (edittedText, id) => {
        let el = document.getElementById(id);
        let rowCount = 0

        if (el) {
            let divHeight = el.offsetHeight
            let lineHeight = 15;

            rowCount = Math.ceil(divHeight / lineHeight);
        } else {
            rowCount = 0
        }

        this.setState({
            ...this.state,
            editMode: !this.state.editMode,
            edittedText: edittedText,
            editModeLineHeight: rowCount
        })
    }

    /*
    * Edits main comment
    */
    editMainComment = (dbComment, tenant, event) => {
        if (event.key === "Enter") {
            let pattern = /@\w+/g;
            let matches = this.state.comment.match(pattern)
            let relatedUsers = matches && matches.length > 0 ? matches.map(match => match.slice(1)) : [];

            relatedUsers = relatedUsers.concat([`${dbComment.username}`])

            let edittedComment = {
                id: dbComment.id,
                username: tenant,
                dashboardId: this.props.mainDashboardId,
                comment: this.state.edittedText,
                relatedUsers: relatedUsers,
                baseComment: true
            }

            this.props.editComment(edittedComment)

            this.setState({
                ...this.state,
                editMode: false,
            })
        }
    }

    /*
    * Edits reply
    */
    editReply = (dbComment, tenant, event) => {
        if (event.key === "Enter") {
            let pattern = /@\w+/g;
            let matches = this.state.comment.match(pattern)
            let relatedUsers = matches && matches.length > 0 ? matches.map(match => match.slice(1)) : [];

            relatedUsers = relatedUsers.concat([`${dbComment.username}`])

            let edittedComment = {
                mainCommentId: this.props.mainId,
                id: dbComment.id,
                username: tenant,
                dashboardId: this.props.mainDashboardId,
                comment: this.state.edittedText,
                relatedUsers: relatedUsers,
                baseComment: false
            }

            this.props.editComment(edittedComment)

            this.setState({
                ...this.state,
                editMode: false,
            })
        }
    }

    /*
    * Returns comment with colored mentions
    */
    returnCommentWithMentions = comment => {
        let pattern = /(@\w+)|([\w\u00C0-\u017F]+)/g;;
        let splittedComment = comment.match(pattern)
        let usersWithColours = this.props.usersWithColours
        
        return splittedComment 
            ? splittedComment.map(comment => (
                <>
                    {comment[0] === "@" && comment.split("@")[1] && usersWithColours.has(comment.split("@")[1]) 
                        ? <span className="mentioned-comment" style={{marginRight: "3px", color: "rgb(3, 106, 177)", display: "inline-block"}}>{comment}</span> 
                        : <span style={{marginRight: "3px", display: "inline-block"}}>{comment}</span>}
                </>
        )) : <span>{comment}</span>
    }

    render() {
        let dbComment = this.props.dbComment
        let tenant = Cookies.get("lastUser")
        let userColor = this.props.usersWithColours.get(dbComment.username)
        let isUserOperationWrite = this.props.operationType === "WRITE" || this.props.operationType.includes("WRITE") ? true : false

        return (
            <div>
                <div className={"per-dbComment"}>
                    <div className={this.props.mainComment ? "dbComment-comment" : this.props.lastElement ? "dbComment-comment dbComment-reply-no-border-last" : "dbComment-comment dbComment-reply-no-border"} style={this.state.editMode ? { background: "#f4f4f4" } : {}}>
                        <div className="dbComment-user-body" style={this.props.mainComment ? {} : {justifyContent: "center"}}>
                            {this.props.mainComment ?                             
                            <div className="dbComment-user" style={{ border: `1px solid ${userColor}`, color: userColor, cursor: "pointer" }} >
                                {dbComment.username[0].toUpperCase()}
                            </div> : <BsArrowReturnRight style={{ fontSize: "20px", cursor: "pointer" }}/>}
                        </div>
                        <div className="dbComment-comment-comment-body">
                            <div className="dbComment-userName" style={{ color: userColor }}>
                                <div style={{ cursor: "pointer" }}>@{dbComment.username}</div>
                                <div style={{ marginLeft: "7px", fontSize: "9px", color: "#767676" }}>
                                    <Tooltip overlayClassName='dashboard-comment-tooltip' placement="top" style={{ fontSize: "11px" }} title={moment(dbComment.sendDate).format("LLLL")}>
                                        {moment(dbComment.sendDate).fromNow()}
                                    </Tooltip>
                                </div>
                                {dbComment.sendDate !== dbComment.updateDate ?
                                    <Tooltip placement="top" overlayClassName='dashboard-comment-tooltip' className="dbComment-editted-text dbComment-tooltip" title={moment(dbComment.updateDate).format("LLLL")}>
                                        ({i18n.t("DashboardComments.Edited")})
                                    </Tooltip> : null}
                            </div>
                            {!this.state.editMode ?
                                <div style={{ minWidth: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", lineHeight: "15px" }}>
                                    <div id={dbComment.id} style={{ maxWidth: "90%" }}>{this.returnCommentWithMentions(dbComment.comment)}</div>
                                </div> :
                                <>
                                    <Mentions
                                        placement={"top"}
                                        rows={this.state.editModeLineHeight}
                                        onChange={this.onChangeEditedComment}
                                        value={this.state.edittedText}
                                        id={"addCommentBaseArea"}
                                        className={"edit-comments"}
                                        onKeyPress={(e) => this.props.mainComment ? this.editMainComment(dbComment, tenant, e) : this.editReply(dbComment, tenant, e)}
                                        placeholder={dbComment.comment}
                                        autoFocus={true}>
                                        {this.props.users ? this.props.users.map((user, i) => (
                                            <Mentions.Option className="antd-mention-item" value={user.username}>{user.username}</Mentions.Option>
                                        )) : null}
                                    </Mentions>
                                </>}

                        </div>
                        {isUserOperationWrite ? 
                        <div style={{ marginRight: "10px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                            {this.props.mainComment ? <Tooltip overlayClassName='dashboard-comment-tooltip' title={i18n.t("DashboardComments.Reply")} className="dbComment-tooltip">
                                <BiReply style={{ fontSize: "20px", cursor: "pointer", color: this.state.replyVisibility ? "rgb(103, 183, 220)" : "" }} onClick={this.changeReplyVisibiliy} />
                            </Tooltip> : null}
                            {dbComment.username === tenant
                                ?
                                <>
                                    <Tooltip overlayClassName='dashboard-comment-tooltip'
                                     title={i18n.t("DashboardComments.Edit")} className="dbComment-tooltip">
                                        <FiEdit2 className={"dashboard-comment-edit-button"} style={this.state.editMode ? { color: "rgb(103, 183, 220)" } : {}} onClick={() => this.changeEditMode(dbComment.comment, dbComment.id)} />
                                    </Tooltip>
                                    <Tooltip overlayClassName='dashboard-comment-tooltip' title={i18n.t("DashboardComments.Remove")} className="dbComment-tooltip">
                                        <RiDeleteBin7Line onClick={this.removeComment} style={{ fontSize: "16px", marginBottom: "3px", color: "red", cursor: "pointer" }} />
                                    </Tooltip>

                                </> : null}
                        </div> : null}
                    </div>
                    {this.props.children}
                </div>
                {this.props.mainComment && this.state.replyVisibility ?
                    <div className='dbComment-reply'>
                        <div className="dashboard-add-comment-area" style={{ width: "95%", marginTop: "5px", marginBottom: "5px" }}>
                            <Mentions
                                placement={"top"}
                                onChange={this.onChangeComment}
                                value={this.state.comment}
                                id={"addCommentBaseArea"}
                                onKeyPress={(event) => this.sendCommentWithEnter(tenant, dbComment, event)}
                                placeholder={i18n.t("DashboardComments.RepliedTo", { username: dbComment.username })}>
                                {this.props.users ? this.props.users.map((user, i) => (
                                    <Mentions.Option key={i} className="antd-mention-item" value={user.username}>{user.username}</Mentions.Option>
                                )) : null}
                            </Mentions>
                            <AiOutlineSend className={"send-button"} onClick={() => this.sendComment(tenant, dbComment)} />
                        </div>
                    </div> : null}
            </div>
        );
    }
}

export default PerDashboardComment;