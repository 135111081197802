import React, { Component } from "react";
import ImageWithTextItem from "../GeneralComponents/ImageWithTextItem/ImageWithTextItem";

export default class PluginItem extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { data } = this.props;

    return (
      <ImageWithTextItem
        textClass={"plugin-item-text"}
        textType={"label"}
        imageStyle={{ marginBottom: "5px", width: "100%", padding: "5px", height: "121px" }}
        imageSrc={"/api/plugin/" + data.key}
        className={"plugin-item col-lg-2 col-sm-2"}
        text={data.translateName}
        onClick={this.props.onClick}
      />
    );
  }
}
