import $ from "jquery";

/**
 *
 * @param {*} plugin
 * @param {*} value //Selected value, "object array"
 * @param {*} THIS //if necessary plugin components methods, use THIS
 * If triggered interation on specific plugins, apply values.
 */
export function decidePluginForApplyValue(plugin, value, THIS) {
  switch (plugin.key) {
    case "filter":
      filterPluginApplyValue(plugin, value);
      break;
    case "selection-box":
      selectionBoxPluginApplyValue(plugin, value);
      break;
    case "radio-button-filter":
      radioButtonPluginApplyValue(plugin, value);
      break;
    case "route-map":
      routeMapPluginApplyValue(plugin, value, THIS);
      break;
    default:
      break;
  }
}

/**
 *
 * @param {*} plugin
 * @param {*} value
 * Apply values for filter plugin if triggered interaction
 */
function filterPluginApplyValue(plugin, value) {
  let container = $("#plugin-" + plugin.id);
  let select = container.find("select");
  let values = [];

  value.map(v => {
    values.push(v.filter);
  });

  if ($(select[1]).attr("id") !== "select-" + plugin.id) {
    $(select[1]).val(values);
    $(select[1]).trigger("change");
  }

  container
    .find(".select2-selection__clear")
    .css("color", plugin.config["arrowColor"]);
  container
    .find(".select2-selection__rendered")
    .css("color", plugin.config["placeholderColour"]);

  if (plugin.config.isMulti === true) {
    let arrowHtml = prepareHtmlArrow(plugin.config.arrowColor);
    $(container)
      .find(
        ".select2-container--default .select2-selection--multiple .select2-selection__rendered"
      )
      .append(arrowHtml);

    $(container)
      .find(
        ".select2-container--default .select2-selection--multiple .select2-selection__choice__remove"
      )
      .css("color", plugin.config.placeholderColour);
  }
}

/**
 *
 * @param {*} plugin
 * @param {*} value
 * Apply values for selection box plugin if triggered interaction
 */
function selectionBoxPluginApplyValue(plugin, value) {
  let container = $("#plugin-" + plugin.id);
  let selectionBox = container.find(".selectionBox");
  let valueSet = new Set();

  value.map(v => {
    valueSet.add(v.filter);
  });

  selectionBox.find("input").each(function () {
    let checkboxValue = $(this).attr("value");

    if (valueSet.has(checkboxValue)) {
      $(this).attr("checked", true);
    }
  });
}

/**
 *
 * @param {*} plugin
 * @param {*} value
 * Apply values for radio button filter plugin if triggered interaction
 */
function radioButtonPluginApplyValue(plugin, value) {
  let container = $("#plugin-" + plugin.id);
  let radioButtons = container.find(".radio-button-filter-vis");

  let radioValue = value[0].filter;
  radioButtons.find("input[value='" + radioValue + "']").attr("checked", true);
}

/**
 *
 * @param {*} conf
 * @returns
 * Prepares the arrow html of the filter plugin
 */
function prepareHtmlArrow(conf) {
  return `<span class="select2-selection__arrow" role="presentation"><b role="presentation" style="border-color: ${conf} transparent transparent;"></b></span>`;
}
/**
 *
 * @param {*} plugin
 * @param {*} value
 * @param {*} THIS //For access component methods and variables.
 * Apply values for route map plugin if triggered interaction
 */
function routeMapPluginApplyValue(plugin, value, THIS) {
  let sourceId = value[0].sourceId;

  if (sourceId < 10) {
    sourceId = "0" + sourceId;
  }

  let selectedCity = "TR-" + sourceId;
  let data = THIS.props.plugin.data ? THIS.props.plugin.data : [];

  if (THIS !== undefined) {
    THIS.selectedCity = selectedCity;
    THIS.loadLines(THIS.map, THIS.selectedCity, data, plugin.id);
  }
}
