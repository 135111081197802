import { SET_WHATIF } from "./WhatIfAction";

/**
 *
 * @param {*} state
 * @param {*} action
 * Reducer for dashboard what if
 *
 */
export default function WhatIfReducer(
  state = {
    whatIf: {}
  },
  action
) {
  switch (action.type) {
    case SET_WHATIF:
      return {
        ...state,
        whatIf: action.payload
      };
    default:
      return state;
  }
}
