import React, { Component } from "react";
import Text from "../../../GeneralComponents/Text/Text";
import { Row, Col, Select, Checkbox, Divider, Button, Input, Tooltip, InputNumber } from "antd";
import {
  FontSizeOutlined,
  BgColorsOutlined,
  InfoCircleOutlined,
  LineChartOutlined,
  FieldTimeOutlined,
  EyeInvisibleOutlined,
  CloseOutlined,
  AimOutlined,
  PlusOutlined,
  BoldOutlined,
  ItalicOutlined
} from "@ant-design/icons";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import $ from "jquery"
import "rc-color-picker/assets/index.css";
import i18n from "../../../../Utils/i18next";
import { InsightsConfig } from "../../RenderJs/config";
import ThemePalette from "../../../GeneralComponents/ThemePalette/ThemePalette";
import TitleSettingsOfPlugin from "../../../GeneralComponents/TitleSettingsOfPlugin/TitleSettingsOfPlugin";
import { getFormattedValue } from "../format";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { MdDelete, MdErrorOutline } from "react-icons/md";
import uuid from "react-uuid";
import { deepCopy } from "../../../../Utils/Global";
import _ from "lodash";
import ReturnToTheme from "../../PluginToolsComponents/ReturnToTheme";

const { Option } = Select;
const { TextArea } = Input;

/**
 * Configuration file of AgePyramid plugin
 */
export default class AgePyramidConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backgroundColor: this.props.config.backgroundColor,
      colours: this.props.config.colours,
      height: this.props.config.height,
      showHideButton: this.props.config.showHideButton,
      summary: this.props.config.summary,
      theme: this.props.config.theme,
      title: this.props.config.title,
      width: this.props.config.width,
      titleAlign: this.props.config.titleAlign, //added title align props
      paletteColours: InsightsConfig.Palettes[this.props.config.colours]
        ? InsightsConfig.Palettes[this.props.config.colours]
        : this.props.config.colours,

      showRanges: this.props.config.showRanges == undefined ? false : this.props.config.showRanges,
      showValuesOnBar: this.props.config.showValuesOnBar == undefined ? true : this.props.config.showValuesOnBar,
      valuesOnBarFontSize: this.props.config?.valuesOnBarFontSize == undefined ? 12 : this.props.config?.valuesOnBarFontSize,
      valueFontColor: this.props.config?.valueFontColor == undefined ? "#060606" : this.props.config?.valueFontColor,
      showRadarLegends: this.props.config.showRadarLegends == undefined ? true : this.props.config.showRadarLegends,
      showConditionalLegends: this.props.config.showConditionalLegends == undefined ? true : this.props.config.showConditionalLegends,
      showGrids: this.props.config.showGrids,

      titleFontStyle: this.props.config.titleFontStyle === undefined ? false : this.props.config.titleFontStyle,
      titleFontWeight: this.props.config.titleFontWeight === undefined ? false : this.props.config.titleFontWeight,
      titleFontSize: this.props.config.titleFontSize === undefined ? 15 : this.props.config.titleFontSize,
      titleFont: this.props.config.titleFont === undefined ? "Verdana" : this.props.config.titleFont,
      titleColour: this.props.config.titleColour === undefined ? "black" : this.props.config.titleColour,
      titleTextDecor: this.props.config.titleTextDecor === undefined ? false : this.props.config.titleTextDecor,
      changedTitleFontSize: this.props.config.changedTitleFontSize === undefined ? 15 : this.props.config.changedTitleFontSize,
      keyForTitleSize: null,
      noDataTitle: this.props.config.noDataTitle === undefined ? "" : this.props.config.noDataTitle,

      ranges: this.props.config.ranges == undefined ? [] : this.props.config.ranges,
      categorySet: this.props.categorySet == undefined ? new Set() : this.props.categorySet,

      duration: this.props.config.duration === undefined ? 1 : this.props.config.duration,
      animationColor: this.props.config.animationColor === undefined ? "#585858" : this.props.config.animationColor,
      animationFontSize: this.props.config.animationFontSize === undefined ? "24" : this.props.config.animationFontSize,
      animationTitleFontStyle: this.props.config.animationTitleFontStyle === undefined ? false : this.props.config.animationTitleFontStyle,
      animationTitleFontWeight: this.props.config.animationTitleFontWeight === undefined ? false : this.props.config.animationTitleFontWeight,
    };
  }

  /**
   * Plugin compenents receive it's created properties
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      let flag = false

      let newObject = {
        backgroundColor: nextProps.config.backgroundColor,
        colours: nextProps.config.colours,
        height: nextProps.config.height,
        showHideButton: nextProps.config.showHideButton,
        summary: nextProps.config.summary,
        theme: nextProps.config.theme,
        titleAlign: nextProps.config.titleAlign, //added title align props
        title: nextProps.config.title,
        width: nextProps.config.width,
        paletteColours: InsightsConfig.Palettes[nextProps.config.colours]
          ? InsightsConfig.Palettes[nextProps.config.colours]
          : nextProps.config.colours,

        showRanges: nextProps.config.showRanges == undefined ? false : nextProps.config.showRanges,
        showValuesOnBar: nextProps.config.showValuesOnBar == undefined ? true : nextProps.config.showValuesOnBar,
        valuesOnBarFontSize: nextProps.config.valuesOnBarFontSize == undefined ? 12 : nextProps.config.valuesOnBarFontSize,
        valueFontColor: nextProps.config?.valueFontColor == undefined ? "#060606" : nextProps.config?.valueFontColor,
        showRadarLegends: nextProps.config.showRadarLegends == undefined ? true : nextProps.config.showRadarLegends,
        showConditionalLegends: nextProps.config.showConditionalLegends == undefined ? true : nextProps.config.showConditionalLegends,

        titleFont: nextProps.config.titleFont === undefined ? "Verdana" : nextProps.config.titleFont,
        titleFontSize: nextProps.config.titleFontSize === undefined ? 15 : nextProps.config.titleFontSize,
        changedTitleFontSize: nextProps.config.changedTitleFontSize === undefined ? 15 : nextProps.config.changedTitleFontSize,
        titleFontStyle: nextProps.config.titleFontStyle === undefined ? false : nextProps.config.titleFontStyle,
        titleFontWeight: nextProps.config.titleFontWeight === undefined ? false : nextProps.config.titleFontWeight,
        titleTextDecor: nextProps.config.titleTextDecor === undefined ? false : nextProps.config.titleTextDecor,
        titleColour: nextProps.config.titleColour === undefined ? "black" : nextProps.config.titleColour,
        noDataTitle: nextProps.config.noDataTitle === undefined ? "" : nextProps.config.noDataTitle,

        ranges: nextProps.config.ranges == undefined ? [] : nextProps.config.ranges,
        categorySet: nextProps.categorySet == undefined ? new Set() : nextProps.categorySet,

        duration: nextProps.config.duration === undefined ? 1 : nextProps.config.duration,
        animationColor: nextProps.config.animationColor === undefined ? "#585858" : nextProps.config.animationColor,
        animationFontSize: nextProps.config.animationFontSize === undefined ? "24" : nextProps.config.animationFontSize,
        animationTitleFontStyle: nextProps.config.animationTitleFontStyle === undefined ? false : nextProps.config.animationTitleFontStyle,
        animationTitleFontWeight: nextProps.config.animationTitleFontWeight === undefined ? false : nextProps.config.animationTitleFontWeight,
      }

      let copiedRanges = deepCopy(nextProps.config.ranges)
      let newRanges = deepCopy(nextProps.config.ranges)

      if (nextProps.config.ranges) {
        for (let i = 0; i < newRanges.length; i++) {
          let startCategory = newRanges[i].startCategory
          let endCategory = newRanges[i].endCategory
          let categoryAliasName = newRanges[i].categoryAliasName

          if (nextProps.plugin?.columnMapForPlugin?.category?.aliasName !== categoryAliasName) {
            copiedRanges = copiedRanges.filter(range => range.startCategory !== startCategory || range.endCategory !== endCategory)
          }
        }

        if (!_.isEqual(copiedRanges, newRanges)) {
          flag = true

          this.setState({
            ...newObject,
            ranges: copiedRanges,
          }, () => {
            this.setConfigToPlugin({ type: "ranges", value: copiedRanges });
          })
        }
      }

      if (!flag) {
        this.setState(newObject)
      }
    }
  }

  componentDidMount() {
    if (this.props.config.ranges) {
      let copiedRanges = deepCopy(this.props.config.ranges)
      let newRanges = deepCopy(this.props.config.ranges)

      for (let i = 0; i < newRanges.length; i++) {
        let startCategory = newRanges[i].startCategory
        let endCategory = newRanges[i].endCategory
        let categoryAliasName = newRanges[i].categoryAliasName

        if (this.props.plugin?.columnMapForPlugin?.category?.aliasName !== categoryAliasName) {
          copiedRanges = copiedRanges.filter(range => range.startCategory !== startCategory || range.endCategory !== endCategory)
        }
      }

      if (!_.isEqual(copiedRanges, newRanges)) {
        this.setState({
          ...this.state,
          ranges: copiedRanges
        }, () => {
          this.setConfigToPlugin({ type: "ranges", value: copiedRanges });
        })
      }
    }
  }

  //sets defaults we get from plugin.jsx. callback for fontsizekey because it should keep changing to trigger default revert
  setDefaultsForTitle = () => {
    let newConfig = this.props.setDefaultForPluginTitle();

    this.setState(
      {
        ...this.state,
        title: newConfig.title,
        titleAlign: newConfig.titleAlign,
        titleFont: newConfig.titleFont,
        titleFontSize: newConfig.titleFontSize,
        titleFontStyle: newConfig.titleFontStyle,
        titleFontWeight: newConfig.titleFontWeight,
        titleTextDecor: newConfig.titleTextDecor,
        titleColour: newConfig.titleColour,
        changedTitleFontSize: newConfig.titleFontSize
      }, () => {
        this.setConfigToPlugin({ type: "changedTitleFontSize", value: newConfig.titleFontSize })
      }
    );

    if (newConfig.titleFontSize > 10 || newConfig.titleFontSize < 30) {
      $("#titleFontSize").css("background", "")
    }
  }

  titleColourChangeVisible = () => {
    this.setState({
      ...this.state,
      titleColourChange: !this.state.titleColourChange
    });
  };

  /*
  * Changes both of constant and changed value 
  * constant = between 10 and 30 values
  * changed value = all of values
  */
  handleChangeBothOfValues = (constantValue, changedValue) => {
    this.setState({
      ...this.state,
      titleFontSize: constantValue,
      changedTitleFontSize: changedValue
    })

    this.setConfigToPlugin(
      [
        {
          type: "titleFontSize",
          value: constantValue
        },
        {
          type: "changedTitleFontSize",
          value: changedValue
        }
      ]);
  }

  /**
   * Changes the value of text attributes in AgePyramid plugin use handleChange
   */
  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value,
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.value });
  };

  /*
   * changes the title's align in agepyramid
   */
  titleAlignOnChange = (value) => {
    this.setState({
      ...this.state,
      titleAlign: value,
    });

    this.setConfigToPlugin({ type: "titleAlign", value: value });
  };

  /**
   * Checks whether checkbox is checked or not in AgePyramid plugin use handleCheck
   */
  handleCheck = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked,
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.checked });
  };

  /**
   * Updates state attributes in AgePyramid plugin
   */
  setConfigToPlugin = (changedObj, propertyListToDelete = undefined) => {
    let tempState = { ...this.state };

    if (Array.isArray(changedObj)) {
      for (let i = 0; i < changedObj.length; i++) {
        let object = changedObj[i];
        tempState[object.type] = object.value;
      }
    } else {
      tempState[changedObj.type] = changedObj.value;
    }

    if (propertyListToDelete !== undefined) {
      for (let item of propertyListToDelete) {
        delete tempState[item]
      }
    }

    this.props.updateConfig(
      tempState,
      this.props.pluginId,
      propertyListToDelete
    );
  };

  /**
   * Changes the color attribute in AgePyramid plugin use colorOnChange
   */
  colorOnChange = (obj) => {
    this.setState({
      ...this.state,
      colours: obj,
    });

    this.setConfigToPlugin({ type: "colours", value: obj });
  };

  /**
   * Changes the background colour attribute in AgePyramid plugin use backgroundColorOnChange
   */
  backgroundColorOnChange = (obj) => {
    this.setState({
      ...this.state,
      backgroundColor: obj.color,
    });

    this.setConfigToPlugin({ type: "backgroundColor", value: obj.color });
  };

  /**
   * Demonstrates how the choosen color will affect the interface in AgePyramid plugin
   */
  backgroundColorChangeVisible = () => {
    this.setState({
      ...this.state,
      backgroundColorChange: !this.state.backgroundColorChange,
    });
  };

  /*
  * Font color visibility value on change
  */
  valueFontColorChangeVisible = () => {
    this.setState({
      ...this.state,
      valueFontColorChange: !this.state.valueFontColorChange,
    });
  };


  /*
  * Font color visibility value on change
  */
  animationColorChangeVisible = () => {
    this.setState({
      ...this.state,
      animationColorChange: !this.state.animationColorChange,
    });
  };

  /*
  * Font colot value on change
  */
  valueFontColorOnChange = (obj) => {
    this.setState({
      ...this.state,
      valueFontColor: obj.color,
    });

    this.setConfigToPlugin({ type: "valueFontColor", value: obj.color });
  };

  /*
  * Animation color value on change
  */
  animationColorOnChange = (obj) => {
    this.setState({
      ...this.state,
      animationColor: obj.color,
    });

    this.setConfigToPlugin({ type: "animationColor", value: obj.color });
  };

  /*
  * Theme colors on change
  */
  coloursOnChange = (value) => {
    this.setState({
      ...this.state,
      colours: value,
    });

    this.setConfigToPlugin({ type: "colours", value: value });
  };

  /*
  * Value bar font sizes on change
  */
  handleChangeValuesOnBarFontSize = (value) => {
    if (value == 0 || value == 0.0 || value || value >= 1) {
      this.handleChange({ target: { id: "valuesOnBarFontSize", value: Number(value) ? value : 1 } })
    } else {
      return
    }
  }

  handleChangeDuration = (value) => {
    if (value == 0 || value == 0.0 || value || value >= 1) {
      this.handleChange({ target: { id: "duration", value: Number(value) ? value : 1 } })
    } else {
      this.handleChange({ target: { id: "duration", value: 0.1 } })
    }
  }

  /*
  * Value bar font sizes on change
  */
  handleChangeAnimationFontSize = (value) => {
    if (value == 0 || value == 0.0 || value || value >= 1) {
      this.handleChange({ target: { id: "animationFontSize", value: Number(value) ? value : 1 } })
    } else {
      return
    }
  }

  /*
  * Controls is range available
  */
  controlIsRangeAvailable = () => {
    if (this.state.ranges.length === 0) {
      let newCreatedRange = {
        id: uuid(),
        startCategory: null,
        endCategory: null,
        error: false,
        visible: true,
        fill: "#9e3bdd",
        fillOpacity: 0.3,
        categoryAliasName: this.props.plugin?.columnMap?.category?.data[0]?.aliasName
      }

      let newRanges = [newCreatedRange]

      this.setState({
        ...this.state,
        ranges: newRanges
      })
    }
  }

  /*
  * Sets category of range
  */
  setCategory = (category, index, type) => {
    let copiedRanges = deepCopy(this.state.ranges)

    copiedRanges[index][type] = category

    this.setConfigToPlugin({ type: "ranges", value: copiedRanges });
  }

  /*
  * Deletes range
  */
  deleteRange = index => {
    let copiedRanges = deepCopy(this.state.ranges)

    copiedRanges = copiedRanges.filter((range, i) => index !== i)

    this.setConfigToPlugin({ type: "ranges", value: copiedRanges });
  }

  /*
  * Changes visibility of single range
  */
  changeVisibilityOfRange = index => {
    let copiedRanges = deepCopy(this.state.ranges)

    copiedRanges[index]["visible"] = !copiedRanges[index]["visible"]

    this.setConfigToPlugin({ type: "ranges", value: copiedRanges });
  }

  /*
  * Changes fill opacity of range
  */
  handleChangeFillOpacityForRange = (value, index) => {
    if (value || value === 0 || value === 0.0) {
      let copiedRanges = deepCopy(this.state.ranges)

      copiedRanges[index]["fillOpacity"] = value

      this.setConfigToPlugin({ type: "ranges", value: copiedRanges });
    } else {
      return
    }
  }

  /*
  * Sets target color visibility
  */
  setTargetColorVisible = (index) => {
    this.setState({
      ...this.state,
      selectedTarget: index,
      targetColorVisible: index !== null ? true : false
    });
  }

  /*
  * Sets target color
  */
  setTargetColor = (index, color) => {
    let copiedRanges = deepCopy(this.state.ranges)

    copiedRanges[index]["fill"] = color

    this.setConfigToPlugin({ type: "ranges", value: copiedRanges });
  }

  /*
* Gets random color
*/
  getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  /*
* Adds ranges
*/
  addRange = () => {
    let copiedRanges = deepCopy(this.state.ranges)
    let newCreatedRange = {
      id: uuid(),
      startCategory: null,
      endCategory: null,
      error: false,
      visible: true,
      fill: this.getRandomColor(),
      fillOpacity: 0.3,
      categoryAliasName: this.props.plugin?.columnMap?.category?.data[0]?.aliasName
    }

    copiedRanges.push(newCreatedRange)

    this.setState({
      ...this.state,
      ranges: copiedRanges
    })

    this.setConfigToPlugin({ type: "ranges", value: copiedRanges });
  }

  render() {
    return (
      <div style={{ color: "#505050" }}>
        <ReturnToTheme
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          preservedConfigFieldsArray={this.props.plugin.preservedConfigFields ? Array.from(this.props.plugin.preservedConfigFields) : []}
        />

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.GeneralAppearance")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.BackgroundColor")}
            </Text>
          </Col>
          <Col span={10}>
            <Button
              onClick={this.backgroundColorChangeVisible}
              style={{ width: "100%" }}
            >
              {this.state.backgroundColorChange
                ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
            </Button>
            {this.state.backgroundColorChange ? (
              <ColorPickerPanel
                alpha={100}
                color={this.state.backgroundColor}
                onChange={this.backgroundColorOnChange}
                id={"backgroundColor"}
                style={{ marginTop: "5px", position: "absolute", right: 0 }}
              />
            ) : null}
          </Col>
          <Col span={2} onClick={this.backgroundColorChangeVisible} style={{ width: "48px", height: "32px", marginLeft: "5px", cursor: "pointer", backgroundColor: this.state.backgroundColor, border: "1px solid #d7d6d6", borderRadius: "4px" }}>

          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Palette")}
            </Text>
          </Col>
          <Col span={4} style={{ paddingRight: "10px" }}>
            <Select
              id={"colours"}
              defaultValue={this.state.colours}
              value={this.state.colours}
              style={{ width: "100%" }}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={this.coloursOnChange}
            >
              <Option value="Flat-UI">{i18n.t("Dashboard.Configuration.Fields.FlatUI")}</Option>
              <Option value="Flat-UI-Soft">{i18n.t("Dashboard.Configuration.Fields.FlatUISoft")}</Option>
              <Option value="Cool-Scale">{i18n.t("Dashboard.Configuration.Fields.CoolScale")}</Option>
              <Option value="Warm-Scale">{i18n.t("Dashboard.Configuration.Fields.WarmScale")}</Option>
              <Option value="Heatmap">{i18n.t("Dashboard.Configuration.Fields.Heatmap")}</Option>
            </Select>
          </Col>
          <Col span={8}>
            <ThemePalette
              colours={this.state.paletteColours}
              update={this.setConfigToPlugin}
            />
          </Col>
        </Row>

        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <LineChartOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowValuesOnBar")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "3px" }}
              id={"showValuesOnBar"}
              defaultChecked={this.state.showValuesOnBar}
              checked={this.state.showValuesOnBar}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        {
          this.state.showValuesOnBar
            ? <>
              <Row style={{ marginBottom: "5px" }}>
                <Col span={1}></Col>
                <Col style={{ textAlign: "center" }} span={3}>
                  <FieldTimeOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col style={{ textAlign: "left" }} span={9}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px"
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.ValueFontSize")}
                  </Text>
                </Col>
                <Col span={11}>
                  <InputNumber
                    id={"valuesOnBarFontSize"}
                    defaultValue={this.state.valuesOnBarFontSize}
                    style={{ width: "100%" }}
                    min={1}
                    step={1}
                    formatter={value => `${value}`.replace(/[^0-9.]/g, '')}
                    parser={value => `${value}`.replace(/[^0-9.]/g, '')}
                    onChange={(value) => this.handleChangeValuesOnBarFontSize(value)}
                  />
                </Col>
              </Row>
              <Row style={{ marginBottom: "5px" }}>
                <Col span={1}></Col>
                <Col style={{ textAlign: "center" }} span={3}>
                  <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col style={{ textAlign: "left" }} span={9}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px",
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.TextColor")}
                  </Text>
                </Col>
                <Col span={9}>
                  <Button
                    onClick={this.valueFontColorChangeVisible}
                    style={{ width: "100%" }}
                  >
                    {this.state.valueFontColorChange
                      ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                      : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
                  </Button>
                  {this.state.valueFontColorChange ? (
                    <ColorPickerPanel
                      alpha={100}
                      color={this.state.valueFontColor}
                      onChange={this.valueFontColorOnChange}
                      id={"valueFontColor"}
                      style={{ marginTop: "5px", position: "absolute", right: 0 }}
                    />
                  ) : null}
                </Col>
                <Col span={2} onClick={this.valueFontColorChangeVisible} style={{ width: "48px", height: "32px", marginLeft: "5px", cursor: "pointer", backgroundColor: this.state.valueFontColor, border: "1px solid #d7d6d6", borderRadius: "4px" }}>

                </Col>
              </Row>
            </> : null
        }
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowGrids")}
            </Text>
          </Col>
          <Col span={10}>
            <Checkbox
              style={{ float: "left", marginTop: "3px" }}
              id={"showGrids"}
              defaultChecked={this.state.showGrids}
              checked={this.state.showGrids}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Animation")}
        </Divider>

        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FieldTimeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.AnimationDuration")}
            </Text>
          </Col>
          <Col span={12}>
            <InputNumber
              id={"duration"}
              defaultValue={this.state.duration}
              style={{ width: "100%" }}
              min={0.1}
              step={0.1}
              formatter={value => `${value}`.replace(/[^0-9.]/g, '')}
              parser={value => `${value}`.replace(/[^0-9.]/g, '')}
              onChange={(value) => this.handleChangeDuration(value)}
            />
          </Col>
        </Row>

        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FieldTimeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.AnimationFontSize")}
            </Text>
          </Col>
          <Col span={12}>
            <InputNumber
              id={"animationFontSize"}
              defaultValue={this.state.animationFontSize}
              style={{ width: "100%" }}
              min={1}
              step={1}
              formatter={value => `${value}`.replace(/[^0-9.]/g, '')}
              parser={value => `${value}`.replace(/[^0-9.]/g, '')}
              onChange={(value) => this.handleChangeAnimationFontSize(value)}
            />
          </Col>
        </Row>

        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.AnimationTextColor")}
            </Text>
          </Col>
          <Col span={10}>
            <Button
              onClick={this.animationColorChangeVisible}
              style={{ width: "100%" }}
            >
              {this.state.animationColorChange
                ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
            </Button>
            {this.state.animationColorChange ? (
              <ColorPickerPanel
                alpha={100}
                color={this.state.animationColor}
                onChange={this.animationColorOnChange}
                id={"animationColor"}
                style={{ marginTop: "5px", position: "absolute", right: 0 }}
              />
            ) : null}
          </Col>
          <Col span={2} onClick={this.valueFontColorChangeVisible} style={{ width: "48px", height: "32px", marginLeft: "5px", cursor: "pointer", backgroundColor: this.state.animationColor, border: "1px solid #d7d6d6", borderRadius: "4px" }}>

          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col
            style={{ textAlign: "center" }}
            span={3}
          >
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col
            style={{ textAlign: "left" }}
            span={9}
          >
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.LabelFontStyle")}
            </Text>
          </Col>
          <Col style={{ textAlign: "left" }} span={12}>
            {this.state.animationTitleFontWeight !== undefined ? (
              <span>
                <Button
                  onClick={() => this.handleChange({
                    target: {
                      id: "animationTitleFontWeight",
                      value: !this.state.animationTitleFontWeight
                    }
                  })}
                  style={{
                    backgroundColor: this.state.animationTitleFontWeight ? "#9ed3ff" : "initial",
                    color: "black",
                    borderColor: "lightgrey",
                  }}
                >
                  <BoldOutlined
                    style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                  />
                </Button>
              </span>
            ) : null}

            {this.state.animationTitleFontStyle !== undefined ? (
              <span>
                <Button
                  onClick={() => this.handleChange({
                    target: {
                      id: "animationTitleFontStyle",
                      value: !this.state.animationTitleFontStyle
                    }
                  })}
                  style={{
                    marginLeft: "20px",
                    backgroundColor: this.state.animationTitleFontStyle ? "#9ed3ff" : "initial",
                    color: "black",
                    borderColor: "lightgrey",
                  }}
                >
                  <ItalicOutlined
                    style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                  />
                </Button>
              </span>
            ) : null}
          </Col>
        </Row>

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Ranges")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowRanges")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"showRanges"}
              defaultChecked={this.state.showRanges}
              checked={this.state.showRanges}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        {this.state.showRanges ?
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <AimOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Targets")}
              </Text>
            </Col>
          </Row> : null}
        {this.controlIsRangeAvailable()}
        {this.state.showRanges ? this.state.ranges.map((range, i) => (
          <Row style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "10px", marginTop: "10px" }}>
            <Col span={6}>
              <Select 
                value={range.endCategory} 
                style={{ width: "100%" }}
                getPopupContainer={(trigger) => trigger.parentNode}
                placeholder="End Category" 
                onChange={(e) => this.setCategory(e, i, "endCategory")}
              >
                {Array.from(this.state.categorySet.values()).map(category => (
                  <Option key={category}>{this.props.columnMap ? getFormattedValue(this.props.columnMap.category, category) : category}</Option>
                ))}
              </Select>
            </Col>
            <Col span={6}>
              <Select 
                value={range.startCategory} 
                style={{ width: "100%" }} 
                getPopupContainer={(trigger) => trigger.parentNode}
                placeholder="Start Category" 
                onChange={(e) => this.setCategory(e, i, "startCategory")}
              >
                {Array.from(this.state.categorySet.values()).map(category => (
                  <Option key={category}>{this.props.columnMap ? getFormattedValue(this.props.columnMap.category, category) : category}</Option>
                ))}
              </Select>
            </Col>
            <Col span={3}>
              <InputNumber
                value={range.fillOpacity}
                placeholder="Fill Opacity"
                onChange={(value) => this.handleChangeFillOpacityForRange(value, i)}
                style={{ width: "100%" }}
                min={0}
                step={0.1}
                formatter={value => `${value}`.replace(/[^0-9.]/g, '')}
                parser={value => `${value}`.replace(/[^0-9.]/g, '')}>
              </InputNumber>
            </Col>
            <Col span={4} style={{ padding: "0 2px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Tooltip
                placement="right"
                overlayClassName="age-range-tooltip"
                title={
                  !this.state.targetColorVisible || this.state.selectedTarget !== i
                    ? i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")
                    : null
                }
              >
                <div
                  style={{
                    height: "32px",
                    width: "100%",
                    border: "1px solid #d7d6d6",
                    boxShadow: "0 2px 0 rgba(0, 0, 0,}",
                    borderRadius: "4px",
                    cursor: "pointer",
                    backgroundColor: range.fill || "#FF0000",
                  }}
                  onClick={() => this.setTargetColorVisible(!this.state.targetColorVisible ? i : null)}
                />
              </Tooltip>
              {this.state.targetColorVisible && this.state.selectedTarget === i ? (
                <div className={"palette-colour-picker"}>
                  <div className={"close-colour-palette"}>
                    <CloseOutlined onClick={() => this.setTargetColorVisible(!this.state.targetColorVisible ? i : null)} />
                  </div>
                  <ColorPickerPanel
                    alpha={100}
                    color={range.fill}
                    onChange={obj => this.setTargetColor(i, obj.color)}
                    id={"perColor"}
                    style={{ marginTop: "10px" }}
                  />
                </div>
              ) : null}
            </Col>

            <Col span={2} style={{ display: "flex", gap: "10px", alignItems: "center", justifyContent: "flex-end" }}>
              {range.visible ?
                <AiOutlineEye style={{ fontSize: "19px", cursor: "pointer" }} onClick={() => this.changeVisibilityOfRange(i)} /> :
                <AiOutlineEyeInvisible style={{ fontSize: "19px", cursor: "pointer" }} onClick={() => this.changeVisibilityOfRange(i)} />}
              <MdDelete style={{ fontSize: "22px", color: "#e2605e", cursor: "pointer" }} onClick={() => this.deleteRange(i)} />
              {range.error ?
                <Tooltip title={i18n.t("Dashboard.Configuration.Fields.CategoriesNotFound")}>
                  <MdErrorOutline style={{ fontSize: "22px", color: "rgb(255, 165, 34)", cursor: "pointer" }} />
                </Tooltip> : null}
            </Col>
          </Row>
        )) : null}
        {this.state.ranges[this.state.ranges.length - 1]?.startCategory && this.state.ranges[this.state.ranges.length - 1]?.endCategory && this.state.showRanges ?
          <Row style={{ marginTop: "10px" }}>
            <Col span={24} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <PlusOutlined className={"circle-icon"}
                name={"nextColor"}
                style={{ marginTop: "3.5px", cursor: "pointer", fontSize: "14px" }}
                onClick={() => this.addRange()} />
            </Col>
          </Row> : null}

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Legend")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Legend")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"showRadarLegends"}
              defaultChecked={this.state.showRadarLegends}
              checked={this.state.showRadarLegends}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowConditionalFormatting")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"showConditionalLegends"}
              defaultChecked={this.state.showConditionalLegends}
              checked={this.state.showConditionalLegends}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>

        <TitleSettingsOfPlugin
          plugin={this.props.plugin}
          handleChange={this.handleChange}
          commonTitleConfig={this.props.commonTitleConfig}
          updateCommonTitleConfig={this.props.updateCommonTitleConfig}
          isReturnToDefaultforTitleVisible={this.props.isReturnToDefaultforTitleVisible}
          setDefaultsForTitle={this.setDefaultsForTitle}
          title={this.state.title}
          titleAlign={this.state.titleAlign}
          titleColour={this.state.titleColour}
          titleColourChange={this.state.titleColourChange}
          titleColourChangeVisible={this.titleColourChangeVisible}
          titleFont={this.state.titleFont}
          titleFontSize={this.state.titleFontSize}
          keyForTitleSize={this.state.keyForTitleSize}
          titleFontStyle={this.state.titleFontStyle}
          titleFontWeight={this.state.titleFontWeight}
          titleTextDecor={this.state.titleTextDecor}
          setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
          currentAppliedConfig={this.props.currentAppliedConfig}
          changedTitleFontSize={this.state.changedTitleFontSize}
          handleChangeBothOfValues={this.handleChangeBothOfValues}
        />
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Others")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left", display: "flex" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Settings.NoDataConfiguration")}
            </Text>
            <Tooltip
              title={
                <>
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Info")} <br />
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.GraphicConfiguration")} <br />
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Settings")} <br />
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.DefaultText")} <br />
                </>
              }
            >
              <InfoCircleOutlined style={{ position: 'relative', marginTop: '5px', marginLeft: "10px" }} />
            </Tooltip>
          </Col>
          <Col span={12}>
            <TextArea
              allowClear
              id={"noDataTitle"}
              defaultValue={this.state.noDataTitle}
              value={this.state.noDataTitle}
              onChange={this.handleChange}
              autoSize={{ minRows: 3, maxRows: 5 }}
              placeholder={i18n.t("NoDataContent.NoData") + " " +
                i18n.t("NoDataContent.PluginCouldNotBeVisualized")}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
