import { post, del } from "../../Utils/WebService";
import { showNotificationWithIcon } from "../../Utils/Notification";
import { API_BASE } from "../../config";


export const addToFavorite = (dashboardId, successFunc) => {
    let url = `${API_BASE}/favoriteDashboard/${dashboardId}`;

    post(url, null, successFunc);
}

export const removeFromFavorite = (dashboardId, successFunc) => {
    let url = `${API_BASE}/favoriteDashboard/${dashboardId}`;

    del(url, null, successFunc);
}