import { TableOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { Component } from "react";
import { store } from "../..";
import { get, post } from "../../Utils/WebService";
import i18n from "../../Utils/i18next";
import { API_BASE } from "../../config";
import DataConnectionCreateForms from "../Datasources/DataConnections/DataConnectionCreateForms";
import { setComesFromDashboardFileUpload, setDataSourceSelfUtils } from "../Datasources/SelfServiceReducer/SelfServiceAction";
import * as breakDescription from "../EmptyDashboardDescriptionBreaker/emptyDashboardDescriptionBreaker";
import { changePluginListVisibility } from "../Plugins/PluginListReducer/OpenPluginListAction";
import { dataBase, fileUpload } from "./EmptyDashboardImagesBase64";

const host = API_BASE;
const api = "/dataSource";

export default class EmptyDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emptyDashboardDescription: i18n.t("Dashboard.EmptyDashboard.EmptyDashboardDescription"),
      modalVisibility: false
    }
  }

  /*
  * Controls is data source available
  */
  controlIsDataSourceAvailable(dataSourceId, firstDataUpload = false, dataSources) {
    let data = {
      openFileUpload: true,
      dataSourceId: dataSourceId,
      firstDataUpload: firstDataUpload,
      dataSources: dataSources
    }

    store.dispatch(setComesFromDashboardFileUpload(true))
    store.dispatch(setDataSourceSelfUtils(data))
  }

  /*
  * Creates default duck named Vispeahen
  */
  createDefaultDuckNamedVispeahen = () => {
    let dataSourceName = "Vispeahen"
    let url = "/api/data/create-workspace/" + dataSourceName
    let postObj = {}

    let successFunc = (res) => {
      this.controlDataSourceListLength(true)
    }

    let errorFunc = (err) => {
      console.log(err)
    }

    post(url, postObj, successFunc, errorFunc)
  }

  /*
  * Controls data source list length
  */
  controlDataSourceListLength = (isFirstDataUpload = false) => {
    let url = host + api

    let successFunc = (response) => {
      let isDuckdbDataSourceLengthGreaterThanZero = response.data.length > 0

      if (isDuckdbDataSourceLengthGreaterThanZero) {
        this.controlIsDataSourceAvailable(response.data[0].id, isFirstDataUpload, response.data)
      } else {
        this.createDefaultDuckNamedVispeahen()
      }
    }

    let errorFunc = (err) => {
      console.log(err)
    }

    get(url, successFunc, errorFunc)
  }

  /*
  * Checks empty dashboard can show plugin export area
  */
  checkEmptyDashboardAssign = () => {
    let cantExportAreaShow = window.location.pathname === "/vispeahen/ui/dashboard" || window.location.pathname === "/vispeahen/ui/dashboard/" || this.props.canPluginExportAreaMustShow
    
    if (cantExportAreaShow) {
      return "flex"
    }

    return "none"
  }

  render() {
    return (
      <>
        <div id="emptyDescription" className={"empty-dashboard hide-on-mobile"}>
          <div>
            <span style={{ fontWeight: "bold", fontSize: "22px", color: "#484848", display: "inline-block", margin: "12px 0" }} className='mr-0 text'>
              {breakDescription.beforeIcon(i18n.t("Dashboard.EmptyDashboard.EmptyDesc0"))}
            </span>
            <br />
            <span style={{ fontSize: "20px" }} className='mr-0 text'>
              {i18n.t("Dashboard.EmptyDashboard.EmptyDesc1")}
            </span>
            <span style={{ fontSize: "20px" }} className='mx-2 text'>
              <TableOutlined className="icon-in-text" onClick={() => store.dispatch(changePluginListVisibility(true))} />
            </span>
            <span style={{ fontSize: "20px" }} className='ml-0 text'>
              {i18n.t("Dashboard.EmptyDashboard.EmptyDesc2")}
            </span>
          </div>
          <div style={{ display: this.checkEmptyDashboardAssign(), gap: "20px", cursor: "pointer" }}>
            <div className="file-upload-area area-green" onClick={() => this.controlDataSourceListLength()}>
              <div className="file-upload-button hide-on-mobile">
                <img src={fileUpload} alt="file-upload-logo" style={{ height: "50px" }} />
              </div>
              <div className="file-upload-button-text hide-on-mobile">{i18n.t("Dashboard.EmptyDashboard.UploadYourFiles")}</div>
            </div>

            <div className="file-upload-area area-blue" onClick={() => this.setState({ ...this.state, modalVisibility: true })}>
              <div className="file-upload-button hide-on-mobile">
                <img src={dataBase} alt="database-logo" style={{ height: "50px" }} />
              </div>
              <div className="file-upload-button-text hide-on-mobile">{i18n.t("Dashboard.EmptyDashboard.ConnectYourDataSources")}</div>
            </div>
          </div>
        </div>
        <Modal
          visible={this.state.modalVisibility}
          onCancel={() => this.setState({ ...this.state, modalVisibility: false })}
          style={{ top: 20, width: "1000px" }}
          width={700}
          okText={null}
          footer={[
            <Button key="back" onClick={() => this.setState({ ...this.state, modalVisibility: false })}>
              {i18n.t("Close")}
            </Button>,
          ]}>
          <DataConnectionCreateForms
            visible={true}
            changeSelectedDataSourceVisible={true}
            comesFromEmptyDashboard={true}
            addData={() => this.setState({ ...this.state, modalVisibility: false })} />
        </Modal>
      </>
    );
  }
}
