import React, { Component } from "react";
import SettingList from "./SettingList/SettingList";

export default class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleBGColorSetting: false,
      visibleBGImageSetting: false,
      visibleGridSetting: false,
    };
  }

  openBGColorSetting = () => {
    if (this.state.visibleBGColorSetting != true) {
      this.setState({
        visibleBGColorSetting: true
      });
    }
  };

  closeBGColorSetting = () => {
    this.setState({
      visibleBGColorSetting: false
    });
  };

  openGridSetting = () => {
    if (this.state.visibleGridSetting != true) {
      this.setState({
        visibleGridSetting: true,
      });
    }
  };

  closeGridSetting = () => {
    this.setState({
      visibleGridSetting: false,
    });
  };

  openBGImageSetting = () => {
    if (this.state.visibleBGImageSetting != true) {
      this.setState({
        visibleBGImageSetting: true
      });
    }
  };

  closeBGImageSetting = () => {
    this.setState({
      visibleBGImageSetting: false
    });
  };

  render() {
    return (
      <div className={"hide-on-mobile"}>
        <SettingList
          visible={this.props.visible}
          closeSetting={this.props.closeSettings}
          settings={this.props.settings}
          changeSettings={this.props.changeSettings}
          plugins={this.props.plugins}
          gridCalculator={this.props.gridCalculator}
          openBGColorSetting={this.openBGColorSetting}
          openBGImageSetting={this.openBGImageSetting}
          openGridSetting={this.openGridSetting}
          closeBGColorSetting={this.closeBGColorSetting}
          closeBGImageSetting={this.closeBGImageSetting}
          closeGridSetting={this.closeGridSetting}
          customExcelList={this.props.customExcelList}
          getCustomExcels={this.props.getCustomExcels}
          mainDashboardId={this.props.mainDashboardId}
          removeCustomExcel={this.props.removeCustomExcel}
          downloadCustomExcel={this.props.downloadCustomExcel}
          dashboardId={this.props.dashboardId}
          readOnly={this.props.readOnly}
          forceCloseInteraction={this.props.forceCloseInteraction}
          theme={this.props.theme}
          setTheme={this.props.setTheme}
        ></SettingList>
      </div>
    );
  }
}
