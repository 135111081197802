import React, { Component } from "react";
import PopupContainer from "../../../../GeneralComponents/PopupContainer/PopupContainer";
import { post } from "../../../../../Utils/WebService";
import { API_BASE, fullViewWidth } from "../../../../../config";
import $ from "jquery"
import Button from "../../../../GeneralComponents/Button/Button";
import i18n from "../../../../../Utils/i18next";
import { MailOutlined } from "@ant-design/icons";
import { showNotificationWithIcon } from "../../../../../Utils/Notification";
import DashboardMailHeader from "./DashboardMailHeader";
import DashboardMailBody from "./DashboardMailBody";
import { authenticatedUser } from "../../../../../Utils/Auth";
import DashboardMailFooter from "./DashboardMailFooter";
import "./dashboardMail.css"
import uuid from "react-uuid";
import { getIcon } from "../../../../../Utils/Global";

/**
 * Dashboard mail popup component
 */
export default class DashboardMail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            operationType: "",
            mailSubject: "",
            mailBody: "",
            mailAddresses: "",
            mailAddressesArray: [],
            mailFrom: authenticatedUser,
            types: []
        };

        this.wrapperRef = React.createRef();
    }

    /**
   * If click outside hide switcher area.
   */
    handleClickOutside = event => {
        if (!this.state.isPinned && window.screen.width > 796) {
            let isTargetWrapper = this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target) ? true : false
            let isTargetTooltip = !$(event.target).closest(".dashboard-comment-tooltip").length > 0
            let isTargetAntSelect = !$(event.target).closest(".ant-select-dropdown-menu").length > 0 ? true : false
            let isTargetSelectItem = !$(event.target).closest(".ant-mentions-dropdown-menu-item").length > 0 ? true : false

            let willPopupClose = isTargetAntSelect && isTargetWrapper && isTargetTooltip && isTargetSelectItem ? true : false

            if (willPopupClose) {
                if (!$(event.target).closest("#loginPopup").length > 0) {
                    this.changeDashboardMailVisible(false)
                }
            }
        }
    };

    /*
    * Changes pin status
    */
    changePinStatus = () => {
        this.setState({
            ...this.state,
            isPinned: !this.state.isPinned
        })
    }

    /**
     * Changes some components visibility such as header section and switch dashboard
     * 
     * @param {*} status 
     */
    changeSomeComponentsVisibility = (status) => {
        let headerSection = $(".header-section")[0];
        let switchArea = $(".switch-area")[0];

        if (status === true) {
            $(headerSection).show()
            $(switchArea).show()
        } else {
            $(headerSection).hide()
            $(switchArea).hide()
        }
    }

    /**
     * On window resize handler
     */
    onResize = () => {
        if (this.timer) clearTimeout(this.timer);

        this.timer = setTimeout(() => {
            this.changeSomeComponentsVisibility(window.screen.width >= fullViewWidth || !this.state.visible)
        }, 100);
    };

    /*  
    * Changes dashboard mail visibility
    */
    changeDashboardMailVisible = (status) => {
        if (status !== this.state.visible) {
            if (status === true) {
                window.addEventListener("resize", this.onResize, true);
                document.addEventListener("mousedown", this.handleClickOutside);
            } else {
                window.removeEventListener("resize", this.onResize, true);
                document.removeEventListener("mousedown", this.handleClickOutside);
            }

            let someComponentsVisibility = window.screen.width >= fullViewWidth || !status

            this.changeSomeComponentsVisibility(someComponentsVisibility);

            this.setState({
                ...this.state,
                visible: status
            });
        }
    }

    /**
     * Handles changes of a property
     * 
     * @param {*} field 
     * @param {*} value 
     */
    handleChangeProperty = (field, value) => {
        this.setState({
            ...this.state,
            [field]: value
        });
    }

    /*
    * Handles changes mail addresses
    */
    handleChangeMailAddresses = e => {
        if (e.length === 0) {
            return;
        }

        let mailAddressesArray = e.split(/[,; ]+/)

        mailAddressesArray = mailAddressesArray.filter(address => {
            let match = address.match(/[a-z0-9._-]+@[a-z0-9._-]+/i);

            return match && match[0] === address;
        });

        let finalMailAdresses = mailAddressesArray !== null ? mailAddressesArray : [];
        let concattedMailAddresses = finalMailAdresses.concat(this.state.mailAddressesArray);
        let uniqueMailArrayList = [...new Set(concattedMailAddresses)];

        this.setState({
            ...this.state,
            mailAddresses: "",
            mailAddressesArray: uniqueMailArrayList
        });
    }

    /*
    * On change mail address input
    */
    onChangeMailAddressInput = e => {
        this.setState({
            ...this.state.value,
            mailAddresses: e.target.value
        });
    }

    /*
    * Removes mail from list
    */
    removeMailFromMailList = (mailAddress) => {
        let filteredMailAddress = this.state.mailAddressesArray.filter(mail => mailAddress !== mail)

        this.setState({
            ...this.state,
            mailAddressesArray: filteredMailAddress
        })
    }

    /*
    * Removes all mails from list
    */
    removeAllMailsFromList = () => {
        this.setState({
            ...this.state,
            mailAddressesArray: []
        })
    }

    /**
     * Discards draft
     */
    reset = () => {
        this.setState({
            ...this.state,
            mailSubject: "",
            mailBody: "",
            mailAddresses: "",
            mailAddressesArray: [],
            mailFrom: authenticatedUser,
            types: []
        })
    }

    /**
     * Reset & close mail popup
     */
    close = () => {
        this.setState({
            operationType: "",
            mailSubject: "",
            mailBody: "",
            mailAddresses: "",
            mailAddressesArray: [],
            mailFrom: authenticatedUser,
            types: []
        }, () => this.changeDashboardMailVisible(false))
    }

    /*
    * Sends mail
    */
    send = () => {
        let mailDashboardObj = this.state;

        if (mailDashboardObj.mailAddressesArray.length === 0) {
            showNotificationWithIcon(i18n.t("DashboardMail.NoReceipentError"), null, "error");

            return;
        }

        let requestObj = {};
        let mailObject = {}
        let url = API_BASE + "/dashboardMail/instant";

        requestObj["id"] = uuid();

        mailObject["subject"] = mailDashboardObj.mailSubject || this.props.dashboard.settings.title;
        mailObject["body"] = mailDashboardObj.mailBody || this.props.dashboard.settings.description;
        mailObject["toMail"] = mailDashboardObj.mailAddressesArray;
        mailObject["mailScheduleSender"] = mailDashboardObj.mailFrom || "Vispeahen";

        requestObj["mailObject"] = mailObject;
        requestObj["dashboardMailingInfoList"] = [];

        if (mailDashboardObj.types.length > 0) {
            requestObj["dashboardMailingInfoList"].push({
                id: this.props.mainDashboardId ? this.props.mainDashboardId : this.props.dashboard.id,
                types: mailDashboardObj.types
            });
        }

        requestObj["userMail"] = {
            username: authenticatedUser
        };

        const successFunc = (data) => {
            showNotificationWithIcon(i18n.t("DashboardMail.MailSendSuccess"), null, "success");
        }

        const errorFunc = (error) => {
            showNotificationWithIcon(i18n.t("DashboardMail.MailSendError"), null, "error");
        }

        post(url, requestObj, successFunc, errorFunc, false);

        showNotificationWithIcon(i18n.t("DashboardMail.MailSending"), null, "success");

        this.close();
    }

    render() {
        let buttonTheme = this.props.theme?.buttons?.dashboardMailButton;
        let sendValid = this.state.mailAddressesArray && this.state.mailAddressesArray.length > 0;

        return (
            <div style={{ position: "relative" }}>
                {
                    this.props.mainDashboardId !== undefined &&
                    <Button
                        id="dashboardMailOpenButton"
                        className="dashboard-button btn btn-new circle-button"
                        tooltip={i18n.t("DashboardMail.DashboardMail")}
                        onClick={() => this.changeDashboardMailVisible(!this.state.visible)}
                    >
                        <style>
                            {buttonTheme && `
                                #dashboardMailOpenButton {
                                    background-color: ${buttonTheme?.backgroundColor};
                                    color: ${buttonTheme?.textColor};
                                    border-color: ${buttonTheme?.textColor}33;
                                }

                                #dashboardMailOpenButton:hover,
                                #dashboardMailOpenButton:focus,
                                #dashboardMailOpenButton:active {
                                    background-color: ${buttonTheme?.backgroundColorActive};
                                    color: ${buttonTheme?.textColorActive};
                                    border-color: ${buttonTheme?.textColorActive}33;
                                }
                            `}
                        </style>
                        {
                            getIcon(
                                buttonTheme?.icon,
                                <MailOutlined style={{ fontSize: "25px" }} />,
                                { fontSize: "25px" }
                            )
                        }
                    </Button>
                }
                {this.state.visible ?
                    <div
                        className={"dahboard-mail-popup"}
                        ref={this.wrapperRef}
                    >
                        <PopupContainer
                            className="dashboard-mail-popup-container"
                            width="500px"
                            background={"rgb(251, 251, 251)"}
                            color="#505050"
                            overflow="hidden"
                        >
                            <DashboardMailHeader
                                closeMailPopup={this.changeDashboardMailVisible}
                                isPinned={this.state.isPinned}
                                changePinStatus={this.changePinStatus}
                            />
                            <DashboardMailBody
                                mailSubject={this.state.mailSubject}
                                mailBody={this.state.mailBody}
                                mailAddresses={this.state.mailAddresses}
                                mailAddressesArray={this.state.mailAddressesArray}
                                mailFrom={this.state.mailFrom}
                                types={this.state.types}
                                handleChangeProperty={this.handleChangeProperty}
                                handleCheckProperty={this.handleCheckProperty}
                                handleChangeMailAddresses={this.handleChangeMailAddresses}
                                onChangeMailAddressInput={this.onChangeMailAddressInput}
                                removeMailFromMailList={this.removeMailFromMailList}
                                removeAllMailsFromList={this.removeAllMailsFromList}
                                dashboard={this.props.dashboard}
                            />
                            <DashboardMailFooter
                                valid={sendValid}
                                send={this.send}
                                reset={this.reset}
                                closeMailPopup={this.changeDashboardMailVisible}
                            />
                        </PopupContainer>
                    </div> : null}
            </div>
        );
    }
}