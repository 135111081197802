import React, { Component } from 'react';
import i18n from '../../../../../Utils/i18next';
import { Button, Checkbox, Input, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { DeleteOutlined, FileExcelOutlined, FileImageOutlined, FilePdfOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';

/**
 * Dashboard mail body component contains mail sending inputs
 */
class DashboardMailBody extends Component {    
    /*
    * On press enter, sends mail addresses to main component for additions
    */
    onPressEnterMailAdresses = e => {
        this.props.handleChangeMailAddresses(e.target.value);
    }

    /**
     * Handles change of dashboard export types
     * 
     * @param {*} type 
     */
    handleCheckType = (type) => {
        let types = [...this.props.types];

        if (types.includes(type)) {
            types = types.filter(t => t !== type);
        } else {
            types.push(type);
        }

        this.props.handleChangeProperty("types", types);
    }

    render() {
        return (
            <div className='dashboard-mail-body'>
                <div style={{ textAlign: "left", marginTop: 8 }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span className="section-font">{i18n.t("DashboardMail.MailFrom")}</span>
                        <Tooltip style={{ fontSize: "14px" }} title={<span style={{ fontSize: "14px" }}>{i18n.t("DashboardMail.MailFromInfo")}</span>}>
                            <InfoCircleOutlined style={{ color: "#ccc", fontSize: "14px", marginLeft: "4px" }} />
                        </Tooltip>
                    </div>
                    <Input
                        style={{
                            fontSize: "14px"
                        }}
                        onChange={(event) => this.props.handleChangeProperty("mailFrom", event.target.value)}
                        value={this.props.mailFrom}
                        placeholder={i18n.t("DashboardMail.ExampleVispeahen")}
                    />
                </div>

                <div style={{ textAlign: "left", marginTop: 8 }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span className="section-font">{i18n.t("DashboardMail.MailTo")}</span>
                        <Tooltip style={{ fontSize: "14px" }} title={<span style={{ fontSize: "14px" }}>{i18n.t("DashboardMail.MailEnterInfo")}</span>}>
                            <InfoCircleOutlined style={{ color: "#ccc", fontSize: "14px", marginLeft: "4px" }} />
                        </Tooltip>
                    </div>
                    <Input.Group compact>
                        <Input
                            style={{
                                fontSize: "14px",
                                resize: "none",
                                minWidth: "85%",
                                width: "calc(100% - 40px)",
                                borderBottomLeftRadius: this.props.mailAddressesArray.length > 0 ? 0 : 4
                            }}
                            value={this.props.mailAddresses}
                            onPressEnter={this.onPressEnterMailAdresses}
                            onChange={this.props.onChangeMailAddressInput}
                            onBlur={this.onPressEnterMailAdresses}
                            placeholder={i18n.t("DashboardMail.Receipents")}
                        />
                        <Button
                            className='general-button'
                            onClick={() => this.props.handleChangeMailAddresses(this.props.mailAddresses)}
                            style={{
                                margin: 0,
                                padding: 0,
                                fontSize: "14px",
                                maxWidth: "15%",
                                width: "40px",
                                borderBottomRightRadius: this.props.mailAddressesArray.length > 0 ? 0 : 4,
                                display: "inline-flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <PlusOutlined />
                        </Button>
                    </Input.Group>
                    {
                        this.props.mailAddressesArray && this.props.mailAddressesArray.length > 0 ? (
                            <div className="dashboard-mail-receipents">
                                <div className="dashboard-mail-receipents-content">
                                    {
                                        this.props.mailAddressesArray.map((mailAddress, i) => (
                                            <div key={i} style={{ background: i % 2 === 0 ? "white" : "#f6f6f6", display: "flex", alignItems: "center", padding: "4px" }}>
                                                <DeleteOutlined onClick={() => this.props.removeMailFromMailList(mailAddress)} style={{ cursor: "pointer", fontSize: "14px", color: "#ff7878" }} />
                                                <Tooltip style={{ fontSize: "12px" }} title={mailAddress}>
                                                    <div className="dashboard-per-section three-dot" style={{ marginLeft: "8px", fontSize: "13px" }}>{mailAddress}</div>
                                                </Tooltip>
                                            </div>
                                        ))
                                    }
                                    {
                                        this.props.mailAddressesArray.length >= 2 ?
                                            <Button
                                                onClick={() => this.props.removeAllMailsFromList()}
                                                style={{
                                                    height: "22px",
                                                    fontSize: "12px",
                                                    marginBottom: "2px",
                                                    position: "absolute",
                                                    bottom: "3px",
                                                    right: "6px",
                                                }}
                                            >
                                                {i18n.t("DashboardMail.DeleteAll")}
                                            </Button>
                                            : null
                                    }
                                </div>
                            </div>
                        ) : null
                    }
                </div>

                <div style={{ textAlign: "left", marginTop: 8 }}>
                    <span className="section-font">{i18n.t("DashboardMail.MailSubject")}</span>
                    <Input
                        style={{
                            fontSize: "14px"
                        }}
                        placeholder={this.props.dashboard.settings.title}
                        onChange={(event) => this.props.handleChangeProperty("mailSubject", event.target.value)}
                        value={this.props.mailSubject}
                    />
                </div>

                <div style={{ textAlign: "left", marginTop: 8 }}>
                    <span className="section-font">{i18n.t("DashboardMail.MailBody")}</span>
                    <TextArea
                        onChange={(event) => this.props.handleChangeProperty("mailBody", event.target.value)}
                        value={this.props.mailBody} rows={4}
                        style={{ height: "80px", resize: "none", fontSize: "14px" }}
                        placeholder={this.props.dashboard.settings.description}
                    />
                </div>

                <div style={{ textAlign: "left", marginTop: 8 }}>
                    <span className="section-font">{i18n.t("DashboardMail.SendDashboardAs")}</span>
                    <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                        <Checkbox
                            className='export-type-item'
                            checked={this.props.types.includes("excel")}
                            onChange={(event) => this.handleCheckType("excel", event.target.checked)}
                        >
                            <span className='export-type-item'>
                                <FileExcelOutlined style={{ marginRight: 3 }}/>
                                EXCEL
                            </span>
                        </Checkbox>
                        <Checkbox
                            className='export-type-item'
                            checked={this.props.types.includes("pdf")}
                            onChange={(event) => this.handleCheckType("pdf", event.target.checked)}
                        >
                            <span className='export-type-item'>
                                <FilePdfOutlined style={{ marginRight: 3 }}/>
                                PDF
                            </span>
                        </Checkbox>
                        <Checkbox
                            className='export-type-item'
                            checked={this.props.types.includes("png")}
                            onChange={(event) => this.handleCheckType("png", event.target.checked)}
                        >
                            <span className='export-type-item'>
                                <FileImageOutlined style={{ marginRight: 3 }}/>
                                PNG
                            </span>
                        </Checkbox>
                    </div>
                </div>
            </div>
        );
    }
}

export default DashboardMailBody;