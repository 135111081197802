import React, { Component } from "react";
import i18n from "../../../../../Utils/i18next";
import { Checkbox } from "antd";
import { store } from "../../../../../index";

const pluginsThatWontAutoRefresh = new Set([
  "title",
  "filter",
  "radio-button-filter",
  "selection-box",
  "image",
  "flag",
  "i-frame",
  "glassed-title"
]);

/** plugin list for excepted plugins */
class PluginList extends Component {
  /** handle click checkbox from excepted plugins list */
  onChange = e => {
    let status = e.target.checked;
    let pluginId = e.target.value;

    this.props.updateExceptedPlugin(pluginId, status);
  };

  render() {
    let reduxState = store.getState();
    let plugins = Array.from(reduxState.PluginTriggerReducer.plugins.values());

    return (
      <div className={"excepted-plugins-area"}>
        {!this.props.enable ? <div className={"disabled-area"}></div> : null}
        <span
          style={{
            marginRight: "16px",
            marginBottom: "10px",
            display: "block"
          }}
        >
          {i18n.t("Dashboard.AutoRefresh.ExceptedPlugins")}
        </span>
        <div className={"excepted-plugins"}>
          {plugins.map(plugin =>
            !pluginsThatWontAutoRefresh.has(plugin.key) ? (
              <Checkbox
                checked={this.props.exceptedPlugins.has(plugin.id)}
                onChange={e => this.onChange(e)}
                value={plugin.id}
                key={plugin.id}
                className={"excepted-item"}
              >
                <span>
                  ({i18n.t("Plugins." + plugin.key + ".Name")}){" "}
                  {plugin.config && plugin.config.title
                    ? plugin.config.title
                    : i18n.t("TitleNotSet")}
                </span>
              </Checkbox>
            ) : null
          )}
        </div>
      </div>
    );
  }
}

export default PluginList;
