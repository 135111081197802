import React, { Component } from "react";
import SearchedDashboard from "./SearchedDashboard";
class SearchResultArea extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return this.props.data.length >= 0 ? (
      <div class="resultHolder">
        {this.props.data &&
          this.props.data.map((d, index) => (
            <SearchedDashboard key={index} data={d}></SearchedDashboard>
          ))}
      </div>
    ) : (
      ""
    );
  }
}

export default SearchResultArea;
