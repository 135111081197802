import React, { Component } from "react";
import { Tooltip } from "antd";
import {
  ReloadOutlined,
} from "@ant-design/icons";
import { store } from "../../../index";
import i18n from "../../../Utils/i18next"
import { setPluginsDrillDowns } from "../../DrillDown/DrillDownAction";
import $ from "jquery"
import { removeSelectedValuesFromStringFilter } from "../PluginComponents/Filter/StringFilterRemoveInputs";

/**
 * Plugin tools refresh button component
 */
export default class RefreshButton extends Component {
  clearAllFilters = () => { 
    let reduxState = store.getState();
    let pluginInteractionFilters =
      reduxState.PluginTriggerReducer.pluginInteractionFilters;
    let sourcePluginsWithValues = reduxState.PluginTriggerReducer.sourcePluginsWithValues;
    let pluginPrivateInteractionFilters = reduxState.PluginTriggerReducer.pluginInteractionFilters;
    let pluginPrivateInteractionFiltersTitle = reduxState.PluginTriggerReducer.pluginPrivateInteractionFiltersTitle;
    let triggeredDrillDowns = reduxState.DrillDownReducer.triggeredDrillDowns
    let pluginColumnClickedAndShouldBeRemoved = reduxState.DrillDownReducer.pluginColumnClickedAndShouldBeRemoved

    if (pluginColumnClickedAndShouldBeRemoved.has(this.props.pluginId)) {
      pluginColumnClickedAndShouldBeRemoved.delete(this.props.pluginId);
    }

    if (pluginInteractionFilters.has(this.props.pluginId)) {
      pluginInteractionFilters.delete(this.props.pluginId);
    }

    if (sourcePluginsWithValues.has(this.props.pluginId)) {
      sourcePluginsWithValues.delete(this.props.pluginId);
    }
    
    if (pluginPrivateInteractionFilters.has(this.props.pluginId)) {
      pluginPrivateInteractionFilters.delete(this.props.pluginId);
    }

    if (pluginPrivateInteractionFiltersTitle.has(this.props.pluginId)) {
      pluginPrivateInteractionFiltersTitle.delete(this.props.pluginId);
    }

    if (triggeredDrillDowns.has(this.props.pluginId)) {
      triggeredDrillDowns.delete(this.props.pluginId)

      let drillDowns = reduxState.DrillDownReducer.drillDowns
      let pluginDrillDowns = drillDowns.get(this.props.pluginId)

      for (let [key, value] of pluginDrillDowns.drillDownLayerMap) {
        if (value.hasDrillDownOperation === true) {
          value.hasDrillDownOperation = false
          pluginDrillDowns.drillDownLayerMap.set(key, value)
        }
      }

      drillDowns.set(this.props.pluginId, pluginDrillDowns)

      store.dispatch(setPluginsDrillDowns(drillDowns));
    }
  };

  refreshPlugin = () => {
      removeSelectedValuesFromStringFilter(this.props.pluginId)

      this.props.setClickedRefresh(true)
      this.props.refresh(true, this.props.pluginId, true);
      this.clearAllFilters();
      this.props.changeRefreshedPluginId(this.props.pluginId)
  };

  render() {
    const classNameControl = this.props.iconsClassNameControl
    let isPluginHeightOne = this.props.isPluginHeightOne
    let isPluginWidthOne = this.props.isPluginWidthOne

    return (
      <Tooltip
        title={i18n.t("Dashboard.Configuration.Fields.Refresh")}>
        <ReloadOutlined
          name={"refreshPluginButton"}
          onClick={() => this.refreshPlugin()}
          className={classNameControl(isPluginHeightOne, isPluginWidthOne)}
          style={{ cursor: "pointer" }}
        />
      </Tooltip>
    );
  }
}
