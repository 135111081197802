import React, { Component } from "react";
import Tab from "../GeneralComponents/Tab";
import Text from "../GeneralComponents/Text/Text";
import Search from "../GeneralComponents/Search/Search";
import BoxItem from "../GeneralComponents/BoxItem/BoxItem";
import i18n from "../../Utils/i18next";

/**
 * Includes tab with used connections
 */
export default class UsedDataSources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSource: this.props.selectedSource ? this.props.selectedSource : undefined,
      isChanged: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedSource !== this.props.selectedSource) {
      this.setState({
        isChanged: true,
      });
    }
  }

  changeSelectedData = (data) => {
    this.setState({
      ...this.state,
      isChanged: true,
      selectedSource: data
    });

    this.props.changeSelectedData(data, true);
  };

  render() {
    return (
      <div>
        <Tab
          changeSelectedData={(data) => this.changeSelectedData(data)}
          col={3}
          data={this.props.usedSources}
          imageAndTextExtra={this.props.getExtraUsed}
          activeDataSourceKey={this.props.activeDataSourceKey}
        >
          <Text type="span" className="content-description">
            {i18n.t("Model.SelectedTablesForModel")}
          </Text>
          {this.state.isChanged === true ? (
            <Search
              id={"searchData"}
              width="100%"
              searchBarVisible={
                this.state.selectedSource && this.state.selectedSource.schemaName ? true : false
              }
              onChange={(e) => this.props.handleSearch(e, true)}
            ></Search>
          ) : (
            ""
          )}
          <br></br>
          <div className={"container"}>
            <div className={"row"}>
              <div style={{ display: "contents" }}>
                {this.props.usedTableData.map((table) => (
                  <BoxItem
                   id={table.dataSourceKey + "_" + table.name}
                    style={{
                      color:
                        table.source && table.source == "Both"
                          ? "green"
                          : table.source == "RealDB"
                          ? "blue"
                          : table.source == "Local"
                          ? "red"
                          : "black",
                    }}
                    title={table.name}
                    tooltipTitle={table.dataSourceKey.split(".")[1] + "." + table.name}
                    onClick={() =>
                      this.props.onTableSelected(table, "usedTableData")
                    }
                    className={table.selected ? "selected" : ""}
                  ></BoxItem>
                ))}{" "}
              </div>
            </div>
          </div>
        </Tab>
      </div>
    );
  }
}
