import React, { Component } from "react";
import i18n from "../../Utils/i18next";

/**
 * If selected labels contains no dashboards
 */
export default class EmptyDashboardLabel extends Component {
  render() {
    return (
      <div class="section">
        <h4>
          <span style={{ backgroundColor: "rgb(103, 162, 79)" }}></span>
          &nbsp;{this.props.label}
        </h4>
        <div class="row">
          <div class="col-lg-12">
            <div className="empty-dashboard-label">
              <span>{i18n.t("LandingPage.NoDashboard")}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
