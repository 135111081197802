import React, { Component } from "react";
import { Menu, Dropdown } from "antd";
import Button from "../../../../GeneralComponents/Button/Button";
import { DownOutlined, DeleteOutlined } from "@ant-design/icons";
import i18n from "../../../../../Utils/i18next";

/** What if saved scenario list */
export default class ScenarioList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };
  }

  /** Dropdown menu for what if scenarios */
  menu = scenarios => {
    let readOnly = this.props.readOnly === "WRITE";

    return (
      <Menu>
        {scenarios.map(scenario => {
          return (
            <Menu.Item style={{ borderBottom: "1px solid whitesmoke" }}>
              {readOnly ? (
                <div
                  style={{
                    display: "inline-block",
                    textAlign: "center"
                  }}
                >
                  <DeleteOutlined
                    name={"whatIfDeleteButton"}
                    onClick={() => this.props.removeScenario(scenario.id)}
                    className={"scenario-remove-icon"}
                  ></DeleteOutlined>
                </div>
              ) : null}

              <span
                style={{ marginLeft: readOnly ? "" : "10px" }}
                className={"scenario-list-name"}
                onClick={() => this.changeScenario(scenario)}
              >
                {scenario.name}
              </span>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  /** Change for scenario dropdown visibility  */
  handleVisibleChange = status => {
    this.setState({ visible: status });
  };

  /** After clicking on the scenario, updates the parameter value with the one in the scenario */
  changeScenario = scenario => {
    this.props.updateCurrentValuesByScenario(scenario);
    this.setState({ visible: false });
  };

  render() {
    const menu = this.menu(this.props.scenarios);

    return (
      <Dropdown
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        overlay={menu}
        placement="bottomRight"
        arrow
        trigger={"click"}
        overlayStyle={{ minWidth: "100px" }}
        overlayClassName={"scenario-list-dropdown"}
      >
        <Button
          className="btn btn-new circle-button scenario-list-button"
          tooltip={i18n.t("Dashboard.Data.WhatIf.Scenarios")}
          id={"scenario-list-dropdown-button"}
          style={{ width: "25px" }}
        >
          <DownOutlined style={{ fontSize: "16px" }} />
        </Button>
      </Dropdown>
    );
  }
}
