import React, { Component } from "react";
import { Tooltip } from "antd";
import { ToolOutlined } from "@ant-design/icons";
import $ from "jquery";
import { pagePopupConfigure } from "../../../Utils/PagePopupConfigure";
import i18n from "../../../Utils/i18next";

/**
 * Plugin tools configuration button component
 */
export default class ConfigurationButton extends Component {
  pluginConfigurationOpen = () => {
    this.props.changeConfigVisibility(true);
  };

  render() {
    const classNameControl = this.props.iconsClassNameControl
    let isPluginHeightOne = this.props.isPluginHeightOne
    let isPluginWidthOne = this.props.isPluginWidthOne
    
    return (
      <Tooltip 
        title={i18n.t("Dashboard.Configuration.Configuration")}>
        <ToolOutlined
          name={"configurePluginButton"}
          onClick={() => this.pluginConfigurationOpen()}
          className={classNameControl(isPluginHeightOne, isPluginWidthOne)}
          style={{ cursor: "pointer" }}
        ></ToolOutlined>
      </Tooltip>
    );
  }
}
