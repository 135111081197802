import React from "react";
import DataColumn from "../Plugins/DataComponents/DataColumn/DataColumn";

/*
* returns vertical drag drop item and functions (sort area and columnmap)
*/
export const verticalDragDropItem = (object, THIS) => {
    /*
    * Adds column sort direction
    */
    let convertObject = (item) => {
      if (THIS.props.sortedColumnList) {
        let sortedCol = THIS.props.sortedColumnList.find(col => item.uniqeColumnId === col.uniqeColumnId)
      
        item["sortDir"] = sortedCol ? sortedCol["sortDir"] : "asc"
  
        return item
      } else {
        return item
      }
    }

    let data = convertObject(object.item);
    let index = object.index;
    let type = object.type;
    THIS.type = object.type

    if (!data.locationFieldName) {
      data.locationFieldName = object.type;
    }

    let drillDownFieldWarning = object.drillDownFieldWarning;
    let drillDownFieldName = object.drillDownFieldName;
    let drillDownExists = drillDownFieldName === type || typeof drillDownFieldName === "undefined";

    return (
      <DataColumn
        showDefaultFilters={THIS.showDefaultFilters}
        addFilter={THIS.addFilter}
        boxStyle={{ marginBottom: 0, cursor: "grab" }}
        column={data}
        includesDrillDown={THIS.drillDownMustBeFoundArea && THIS.drillDownMustBeFoundArea.includes(data.locationFieldName)}
        disabledDrillDown={!drillDownExists}
        drillDownFieldWarning={drillDownFieldWarning}
        componentTHIS={THIS}
        onColumnMap={true}
        columnIndex={type + "-" + index}
        type={type}
        extra={true}
        updateDrillDowns={THIS.props.updateColumnMap}
        exceptPopupIndex={THIS.state.exceptPopupIndex}
        closeThreePointPopup={THIS.state.closeThreePointPopup}
        isCloseOthers={THIS.closeOtherThreePointPopup}
        removeColumnOnMapping={THIS.removeColumnOnMapping}
        changeColumnProperties={THIS.changeColumnProperties}
        conditionalFormats={THIS.props.conditionalFormats}
        plugin={THIS.props.plugin}
        isDrilldownColumn={THIS.isDrilldownColumn}
        refreshPlugin={THIS.props.refreshPlugin}
        closeOtherPopups={THIS.closeOtherPopups}
        comesFrom={THIS.state.comesFrom}
      />
    );
  };