import React from "react";
import ColorPicker from "./Options/ColorPicker";
import IconSelector from "../GeneralComponents/IconSelector/IconSelector";
import CustomSelect from "../GeneralComponents/Select/Selecting";
import FontChanger from "./Options/FontChanger";
import { Select } from "antd";
import { Col, Row } from "antd";
import { CrownOutlined } from "@ant-design/icons";
import i18n from "../../Utils/i18next";
import Text from "../GeneralComponents/Text/Text";

const { Option } = Select;

/**
 * option selector for conditional format styling
 * @param {*} option
 * @param {*} key
 * @param {*} handleChange
 * @param {*} value
 */

export const optionsSelector = (
  option,
  key,
  handleChange,
  value,
  disabledColorPicker = false,
  handleChangeAlign,
  state
) => {
  switch (option.type) {
    case "COLOR":
      return (
        <ColorPicker
          key={key}
          option={option}
          type={key}
          handleChange={handleChange}
          value={value}
          disabledColorPicker={disabledColorPicker}
        />
      );
    case "ICON":
      return (
        <IconSelector key={key} icon={value} handleChange={handleChange} type={key} />
      );
    case "TEXT":
      return (
        <FontChanger key={key} fontStyle={value} handleChange={handleChange} type={key} />
      );
    case "IMAGE":
      break;
    case "SELECT":
      return (
        <CustomSelect key={key} value={value} icon={state.icon} option={option} handleChangeAlign={handleChangeAlign} type={key}/>
      );
    default:
      break;
  }
};
