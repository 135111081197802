import React, { Component } from "react";
import i18n from "../../Utils/i18next";
import Text from "./Text/Text";
import $ from "jquery";

class Copyright extends Component {
  componentDidMount() {
    this.copyrightLinkToVispeahen();
  }

  /**
   * To exchange "Vispeahen" text to a link for going to www.vispeahen.com web site
   */
  copyrightLinkToVispeahen = () => {
    let ContextCopyright = $("#copyright").text().split(" ");
    let vispeahenIndex;
    
    $("#copyright").text();
    
    for (let index = 0; index < ContextCopyright.length; index++) {
      if (
        ContextCopyright[index] === "Vispeahen." ||
        ContextCopyright[index] === "Vispeahen"
      ) {
        vispeahenIndex = index;
      }
    }

    $("#copyright").empty();
    ContextCopyright[vispeahenIndex] =
      '<a style="color: #23608fa1" target="_blank" href="https://www.vispeahen.com">Vispeahen</a>';
      
    for (let index = 0; index < ContextCopyright.length; index++) {
      $("#copyright").append(ContextCopyright[index]);
      $("#copyright").append(" ");
    }
  };

  render() {
    return (
    <div className={"copyright"}>
        <Text id="copyright" type="span">{i18n.t("Copyright")}</Text>
    </div>)
  }
}

export default Copyright; 