import React, { Component } from "react";
import PerNotification from "./PerNotification";
import "./userNotifications.css";

/*
* Notification List Component
*/
export default class NotificationList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUserNameTextMustBold: window.innerWidth > 500 ? false : true,
            isUserPhotoMustShow: window.innerWidth < 400 ? true : false
        }
    }

    componentDidMount() {
        let handleResize = () => {
            let state = { ...this.state }

            if (window.innerWidth > 500) {
                state["isUserNameTextMustBold"] = false
                state["isUserPhotoMustShow"] = false
            } else {
                state["isUserNameTextMustBold"] = true

                if (window.innerWidth < 400) {
                    state["isUserPhotoMustShow"] = true
                } else {
                    state["isUserPhotoMustShow"] = false
                }
            }

            this.setState(state)
        }

        window.addEventListener('resize', handleResize)
    }

    render() {
        return (
            <>
                <div className="notification-list-container">
                    {
                        this.props.notifications.map(notification => (
                            <PerNotification
                                id={notification.id}
                                notification={notification}
                                usersWithColours={this.props.usersWithColours}
                                isUserNameTextMustBold={this.state.isUserNameTextMustBold}
                                isUserPhotoMustShow={this.state.isUserPhotoMustShow}
                            />
                        ))
                    }
                </div>
            </>
        );
    }
}
