import React, { Component } from "react";
import { Row } from "antd";
import "./dataSources.css";
import Text from "../GeneralComponents/Text/Text";
import DataSourceList from "./DataSourceList";
import i18n from "../../Utils/i18next";

/**
 *This component creates the internal part of the DataSource Popup and calls the Data Source List inside.
 */
export default class DataSourcesArea extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="selection-menu">
        <Row>
          <div className="container">
            <Text type="h4">{i18n.t("DataConnections.Connections")}</Text>
            <Text type="h6" style={{ color: "#a2a2a2", marginBottom: "15px" }}>
              {i18n.t("DataConnections.ConnectionsPreExplanation")}
            </Text>
          </div>
        </Row>
        <Row>
          <DataSourceList
            model={this.props.model}
            editModel={this.props.editModel}
            changeSelectedDataSource={this.props.changeSelectedDataSource}
            newConnection={this.props.newConnection}
            joins={this.props.joins}
            visibleDataConnections={this.props.visibleDataConnections}
            selectedTables={this.props.selectedTables}
            modelTableSaveResponse={this.props.modelTableSaveResponse}          
            fileUploadModalVisible={this.props.fileUploadModalVisible}
            changeFileUploadModalVisibity={this.props.changeFileUploadModalVisibity}
            setVisibleDataUploadStatus={this.props.setVisibleDataUploadStatus}
            datasourceEditMode={this.props.datasourceEditMode}
            dataSourceEditModeId={this.props.dataSourceEditModeId}
            editedSelfService={this.props.editedSelfService}
            dataSourceEditForWithoutDatabase={this.props.dataSourceEditForWithoutDatabase}
            isNotUpdatedSelfService={this.props.isNotUpdatedSelfService}
          />
        </Row>
      </div>
    );
  }
}
