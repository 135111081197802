import React, { Component } from "react";
import PopupContainer from "../GeneralComponents/PopupContainer/PopupContainer";
import PluginGroup from "./PluginGroup";
import { API_BASE } from "../../config";
import { get } from "../../Utils/WebService";
import Search from "../GeneralComponents/Search/Search";
import Button from "../GeneralComponents/Button/Button";
import SplashScreen from "../SplashScreen/SplashScreen";
import { setDefaultPlugins } from "../Plugins/DefaultPluginsStore/DefaultPluginsAction";
import { store } from "../../index";
import { connect } from "react-redux";
import PluginLoader from "./PluginLoader";
import { pluginTranslateNameSet } from "../../Utils/PluginOperations";
import $ from "jquery";
import i18n from 'i18next';

class Plugins extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      searchTempData: [],
      showSplashScreen: "none",
    };

    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  //If click outside hide switcher area.
  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      if (!$(event.target).closest("#loginPopup").length > 0) {
        this.props.cancel()
      }
    }
  };

  componentWillMount() {
    let reducers = store.getState();
    let plugins = [...reducers.DefaultPluginsReducer.plugins];

    if (plugins.length === 0) {
      this.getPluginImages();
    } else {
      this.setState({
        ...this.state,
        data: plugins,
        searchTempData: plugins,
      });
    }
  }

  getPluginImages = () => {
    let url = `${API_BASE}/plugin`;

    this.setState({
      showSplashScreen: "initial",
    });

    const successFunc = (result) => {
      let data = result.data;
      let dataMap = new Map();

      data.map(d => {
        dataMap.set(d.key, d.image)
      });

      let pluginObject = {plugins: data, pluginsMap: dataMap}

      this.props.setDefaultPlugins(pluginObject);

      this.setState({
        ...this.state,
        data: result.data,
        searchTempData: result.data,
        showSplashScreen: "none",
      });
    };

    get(url, successFunc, undefined, false);
  };

//Filters the plugin list based on user input in the search bar. Allows us to search for plugin name and group
  handleSearchPlugin = (event) => {
    let searchResultValues = [...this.state.searchTempData];
    let searchTerm = event.target.value;
    let currentLanguage = i18n.language;

    if (typeof searchTerm === "string" && searchTerm !== "") {

      // Normalize string for some turkish chars
      const normalizeString = (str) => { 
        if(currentLanguage === "tr") {
          return str.replace(/[İ]/gi, "i").replace(/[I]/gi, "ı");
        }

        return str;
      }

      searchTerm = normalizeString(searchTerm);

      let regex = new RegExp(searchTerm, "gi");

      searchResultValues = searchResultValues.filter(item => {
        let name = normalizeString(i18n.t("PluginsName.names." + item.key));
        let group = normalizeString(i18n.t("PluginsName.PluginGroup." + item.group));

        let nameSearch = regex.test(name);
        let groupSearch = regex.test(group);

        return nameSearch || groupSearch;
      });

      this.setState({
        ...this.state,
        data: searchResultValues,
      });
    } else {
      this.setState({
        ...this.state,
        data: this.state.searchTempData,
      });
    }
  };

  selectPlugin = (plugin) => {
    this.props.selectPlugin(plugin);
  };

  render() {
    return (
      <div
        ref={this.wrapperRef}
        className="plugin-list"
        style={{ display: this.props.visible ? "block" : "none" }}
      >
        <PopupContainer width="770px" height="600px" constantHeight="600px" overflow="hidden">
          <div className={"plugins-search-area"}>
            <Search
              id={"searchData"}
              name={"searchPlugin"}
              width="100%"
              searchBarVisible={true}
              onChange={this.handleSearchPlugin}
              autoFocus={true}
            ></Search>
          </div>
          {this.state.showSplashScreen !== "none" ? (
            <div>
              <PluginLoader display={this.state.showSplashScreen !== "none"} />
            </div>
          ) : (
            <PluginGroup
              selectPlugin={this.selectPlugin}
              data={pluginTranslateNameSet(this.state.data)}
            ></PluginGroup>
          )}
          <Button
            id={"closePluginListButton"}
            style={{
              position: "absolute",
              top: "3px",
              right: "15px",
              borderRadius: "40px",
              padding: "6px 11px",
              backgroundColor: "white",
              zIndex: 3,
            }}
            type={"default"}
            onClick={() => this.props.cancel()}
          >
            X
          </Button>
        </PopupContainer>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setDefaultPlugins,
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Plugins);
