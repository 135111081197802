import { convertRule } from "./validationOperations";
import i18n from "../../Utils/i18next";

/** 
 * This JS used for only Map Choropleth compare objects 
 * 
 **/

export function compare(value, condFormat) {
  const operatorSet = new Set(["*", "/", "%", "(", ")", "+", "-"])
  const errors = new Set(["notCurrentTopojson", "notCurrentCode"])
  const operators = {
    "=": function(a, b) { return eval(a) == eval(b) ? true : false},
    "!=": function(a, b) { return eval(a) != eval(b) ? true : false},
    "<": function(a, b) {return eval(a) < eval(b) ? true : false},
    "<=": function(a, b) {return eval(a) <= eval(b) ? true : false},
    ">": function(a, b) {return eval(a) > eval(b) ? true : false},
    ">=": function(a, b) {return eval(a) >= eval(b) ? true : false}
  }

  /*
  * Checks has rules error
  */
  let isRulesHasError = (rule) => {
    let isRulesInError = !errors.has(eval(rule)) ? false : true

    return isRulesInError
  }

  /*
  * Check all rules condition and returns a value for switch-case
  */
  let choroplethConditionChecker = (leftRule, rightRule, operator) => {
    let comparedValues = operators[operator](leftRule, rightRule) ? true : false
    let isLeftRuleInError = isRulesHasError(leftRule)
    let isRightRuleInError = isRulesHasError(rightRule)
    let isRulesIncludesError = isLeftRuleInError || isRightRuleInError === true ? false : true

    if (comparedValues && isRulesIncludesError) {
      return true
    }

    return false
  }

  //Controls if 'rule' has operator in ruleValue and has only one column in rule column and is string. used for like "ÇUBUK-AŞAĞI ÇAVUNDUR" values.
  //This area for changed Choropleth
  let hasRuleArithmeticOperatorsInStringControl = (val, cf, rule) => {
    let isRuleHasColumn = cf[rule].split("{").length === 1 ? true : false
    let isRuleNumber = isNaN(+cf[rule]) ? true : false

    if (isRuleHasColumn && isRuleNumber) {
      let convertedRule = convertRule(cf.RightRule, cf.Columns, val);
      let isValueString = false

      try {
        eval(cf[rule])
      } catch (e) {
        isValueString = true
      }
  
      if (isValueString === true) {
        for (let i = 0; i < cf[rule].length; i++) {
          let currentStr = cf[rule][i]
          
          if (operatorSet.has(currentStr)) {
            return {status: true, value: "'" + `${cf[rule]}` + "'"}
          }
        }
      }

      return convertedRule
    } else {
      return convertRule(cf[rule], cf.Columns, val);
    }
  }

  let leftRule = hasRuleArithmeticOperatorsInStringControl(value, condFormat, "LeftRule")
  let rightRule = hasRuleArithmeticOperatorsInStringControl(value, condFormat, "RightRule")

  let comparison = { status: false, error: false };
  let status = false;

  //This area's conditions changed for choropleth conditional formatting
  switch (condFormat.Operator) {
    case "=":
      status = choroplethConditionChecker(leftRule.value, rightRule.value, condFormat.Operator);
      break;
    case "!=":
      status = choroplethConditionChecker(leftRule.value, rightRule.value, condFormat.Operator);
      break;
    case "<":
      status = choroplethConditionChecker(leftRule.value, rightRule.value, condFormat.Operator);
      break;
    case "<=":
      status = choroplethConditionChecker(leftRule.value, rightRule.value, condFormat.Operator);
      break;
    case ">":
      status = choroplethConditionChecker(leftRule.value, rightRule.value, condFormat.Operator);
      break;
    case ">=":
      status = choroplethConditionChecker(leftRule.value, rightRule.value, condFormat.Operator);
      break;
    default:
      let error =
        '"' + condFormat.Operator + '" ' + i18n.t("OperatorIsNotValid");
      status = false;
      comparison.error = error;
      comparison.errorTitle = i18n.t(
        "Dashboard.ConditionalFormatting.ConditionalFormatting"
      );
      break;
  }

  comparison.status = status;

  return comparison;
}
