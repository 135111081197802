import React from "react";
import { Layout, Avatar } from "antd";
import { authenticatedUser } from "../Utils/Auth";
import { navigate } from "../Utils/Global";
import { logout } from "../Utils/Auth";

const { Header } = Layout;

export default class TopBar extends React.PureComponent {
  navigateToHome = () => {
    navigate(`/vispeahen/app/states/portal/`);
  };

  render() {
    return (
      <Header className="top-bar">
        <div className="topBarLeftItemContainer">
          <Avatar className="topBarLeftItem">
            {authenticatedUser ? authenticatedUser : "!"}
          </Avatar>
          <a onClick={this.navigateToHome}>
            <Avatar className="topBarLeftItem" icon="home" />
          </a>
          <a onClick={logout}>
            <Avatar className="topBarLeftItem" icon="logout" />
          </a>
        </div>
      </Header>
    );
  }
}