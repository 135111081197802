import * as am5 from "@amcharts/amcharts5";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import $ from "jquery";
import _, { isNumber } from "lodash";
import React from "react";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import i18n from "../../../../Utils/i18next";
import ConditionalFormatting from "../../../ConditionalFormatting/ConditionalFormatting";
import { compare, convertHTMLRuletoRule } from "../../../ConditionalFormatting/ConditionalFormattingCommon";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";
import { calculatePluginInlineHeight } from "../../../DrillDown/PluginHeightWithDrilldown";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join";
import { bigNumberPrefixes, decimalSeperators, longMonths, shortMonths, thousandSeperators } from "../../../GeneralComponents/PublicSortItems";
import { createTrigger } from "../../../Interaction/CreateTrigger";
import NavigationContent from "../../../Navigation/NavigationContent";
import { aggregatableDataTypes, dateDataTypes } from "../../DataComponents/DataConfigure";
import { InsightsConfig } from "../../RenderJs/config";
import { rmvpp } from "../../RenderJs/rmvpp";
import AgePyramidConfiguration from "./AgePyramidConfiguration";
import { renderConditionalFormatting, renderConfig, renderData, renderNavigation } from "../PluginsCommonComponents";
import { vispeahenLogo } from "../Table/TablePdfContent";
import { getCurrentDateTime, onComponentWillMount, onComponentWillReceiveProps } from "../common";
import { getFormattedValue } from "../format";
import AgePyramidData from "./AgePyramidData";
import { deepCopy, loadingScreen } from "../../../../Utils/Global";
import { changePluginLoaderVisibility } from "../../../GeneralComponents/PluginLoader/PluginLoaderAction";
import { store } from "../../../..";
import NoDataContent from "../../NoDataContent/NoDataContent";
import { generalExcelExport } from "../../../../Utils/Exports/GeneralExcelExport";

const columnMap = JSON.parse(
  `{"category":{"Code":"\'ucusAlias\'.\'ay_adiAlias\'","Name":"ay adi","DataType":"varchar","Table":"ucusAlias","Measure":"none","ID":"ucusAlias.ay adi","SubjectArea":"BIGDATA","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":"%s","Config":{},"Verified":true,"Type":"Column","Description":""},"either":{"Code":"\'ucusAlias\'.\'bagajtoplamAlias\'","Name":"bagajtoplam","DataType":"double","Table":"ucusAlias","Measure":"sum(ucus.bagajtoplam)","ID":"ucusAlias.bagajtoplam","SubjectArea":"BIGDATA","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":".3s","Config":{},"Verified":false,"Type":"Column","Description":""},"other":{"Code":"\'ucusAlias\'.\'kargotoplamDataAlias\'","Name":"kargotoplam","DataType":"double","Table":"ucusAlias","Measure":"sum(ucus.kargotoplam)","ID":"ucusAlias.kargotoplam","SubjectArea":"BIGDATA","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":".3s","Config":{},"Verified":false,"Type":"Column","Description":""},"hidden":[]}`
);

const pluginConditionalFormatOptions = {
  backgroundColor: {
    title: i18n.t("Dashboard.ConditionalFormatting.BackgroundColor"),
    type: "COLOR",
    defaultValue: "#fffffe"
  }
};

const conditionalFormatColumnMap = new Set(["other", "either", "category", "animation"]);
const conditionalFormatTargetMap = new Set(["other", "either"])


const actions = [
  {
    trigger: "barClick",
    type: "click",
    output: ["category", "animation"],
    name: "PointClick",
    description: "barClick"
  },
];

const reactions = [
  {
    id: "filter",
    name: "Filtre",
    description: "desc87",
    type: "general"
  }
];


const configurationParameters = [
  {
    targetProperty: "width",
    label: "Width",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 600
    },
    desc: "desc89"
  },
  {
    targetProperty: "height",
    label: "Height",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 400
    },
    desc: "desc90"
  },
  {
    targetProperty: "theme",
    label: "Theme",
    inputType: "dropdown",
    inputOptions: {
      multiSelect: false,
      values: [
        "None",
        "Dark",
        "Crimson",
        "Chalk",
        "Light",
        "Patterns",
        "Colorfully",
        "Soft"
      ],
      defaultValue: "None"
    },
    desc: "desc92"
  },
  {
    targetProperty: "showValuesOnBar",
    label: "showValuesOnBar",
    inputType: "checkbox",
    inputOptions: { defaultValue: true },
    desc: "desc208"
  },
  {
    targetProperty: "showConditionalLegends",
    label: "showValuesOnBar",
    inputType: "checkbox",
    inputOptions: { defaultValue: true },
    desc: "desc208"
  },
  {
    targetProperty: "valuesOnBarFontSize",
    label: "valuesOnBarFontSize",
    inputType: "input",
    inputOptions: { defaultValue: 12 },
    desc: "desc208"
  },
  {
    targetProperty: "valueFontColor",
    label: "valueFontColor",
    inputType: "input",
    inputOptions: { defaultValue: "#060606" },
    desc: "desc208"
  },
  {
    targetProperty: "colours",
    label: "Colours",
    inputType: "palette",
    inputOptions: {
      defaultValue: "Flat-UI"
    },
    desc: "desc208"
  },
  {
    targetProperty: "title",
    label: "Title",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc94"
  },
  {
    targetProperty: "summary",
    label: "Summary",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc61"
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    inputType: "textbox",
    inputOptions: { defaultValue: "rgba(255,255,255,0.5)" },
    desc: "desc62"
  },
  {
    targetProperty: "hideLabel",
    label: "HideLabel",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "desc142"
  },
  {
    targetProperty: "showHideButton",
    label: "Show Hide Button",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc230"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  },
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 15
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
];


// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

/*
* Converts conditional format component
*/
const convertFormatConditionalFormatting = (condFormats, columns) => {
  let condFormatList = [];

  condFormats.map(condItem => {
    condItem.targetColumns.map(targetColumn => {
      let conditionalFormat = {};
      let columnsMap = new Map();

      columns.map(column => {
        if (column) columnsMap.set(column.uniqeColumnId, column);
      })

      conditionalFormat.RightRule = condItem.rule.rightRule.rule;
      conditionalFormat.LeftRule = condItem.rule.leftRule.rule;
      conditionalFormat.LeftRuleColumnName = condItem.rule.leftRule.ruleColumnName === "{undefined}" ? convertHTMLRuletoRule(condItem.rule.leftRule).ruleColumnName : condItem.rule.leftRule.ruleColumnName;
      conditionalFormat.RightRuleColumnName = condItem.rule.rightRule.ruleColumnName
      conditionalFormat.Columns = columnsMap;
      conditionalFormat.TargetID = targetColumn.TargetID;
      conditionalFormat.TargetName = targetColumn.TargetName;
      conditionalFormat.Operator = condItem.rule.operator;
      conditionalFormat.id = condItem.id;
      conditionalFormat.Targets = condItem.targetColumns
      conditionalFormat.ConditionalFormatRule = condItem.rule.conditionalFormatRule

      conditionalFormat.Style = {
        colour: condItem.options.color,
        icon: "",
        background: { colour: condItem.options.backgroundColor }
      };

      condFormatList.push(conditionalFormat);
    });
  });

  return condFormatList;
};

/*
* Converts columnMap to conditional format
*/
let convertColumnMapForConditionalFormat = columnMap => {
  let newColMap = []

  newColMap.push(columnMap.category.data[0])
  newColMap.push(columnMap.other.data[0])
  newColMap.push(columnMap.either.data[0])
  newColMap.push(columnMap?.animation?.data[0])

  return newColMap
}

export default class AgePyramid extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.rerenderProcessStarted = false;
    this.callBackObject = {};

    this.state = {
      config: {
        backgroundColor: this.props.config?.backgroundColor ? this.props.config?.backgroundColor : "rgba(255, 255, 255, 1)",
        colours: this.props.config?.colours ? this.props.config?.colours : "Flat-UI",
        paletteColours: this.props.config?.colours
          ? InsightsConfig.Palettes[this.props.config?.colours]
            ? InsightsConfig.Palettes[this.props.config?.colours]
            : this.props.config?.colours
          : ["#67b7dc", "#6771dc", "#a367dc", "#dc67ce", "#dc6788", "#dc8c67"],
        title: this.props.config?.title,
        titleAlign: this.props.config?.titleAlign, //added title align props
        titleFontStyle: this.props.config?.titleFontStyle === undefined ? false : this.props.config?.titleFontStyle,
        titleFontWeight: this.props.config?.titleFontWeight === undefined ? false : this.props.config?.titleFontWeight,
        titleFontSize: this.props.config?.titleFontSize === undefined ? 15 : this.props.config?.titleFontSize,
        titleFont: this.props.config?.titleFont === undefined ? "Verdana" : this.props.config?.titleFont,
        titleColour: this.props.config?.titleColour === undefined ? "black" : this.props.config?.titleColour,
        titleTextDecor: this.props.config?.titleTextDecor === undefined ? false : this.props.config?.titleTextDecor,
        changedTitleFontSize: this.props.config?.changedTitleFontSize === undefined ? 15 : this.props.config?.changedTitleFontSize,
        keyForTitleSize: null,
        noDataTitle: this.props.config?.noDataTitle === undefined ? "" : this.props.config?.noDataTitle,
        showValuesOnBar: this.props.config?.showValuesOnBar == undefined ? true : this.props.config?.showValuesOnBar,
        valuesOnBarFontSize: this.props.config?.valuesOnBarFontSize == undefined ? 12 : this.props.config?.valuesOnBarFontSize,
        valueFontColor: this.props.config?.valueFontColor == undefined ? "#060606" : this.props.config?.valueFontColor,
        showRadarLegends: this.props.config?.showRadarLegends == undefined ? true : this.props.config?.showRadarLegends,
        ranges: this.props.config?.ranges == undefined ? [] : this.props.config.ranges,
        showRanges: this.props.config?.showRanges == undefined ? false : this.props.config?.showRanges,
        showGrids: this.props.config?.showGrids,
        duration: this.props.config?.duration,
        animationColor: this.props.config?.animationColor,
        animationFontSize: this.props.config?.animationFontSize,
        animationTitleFontStyle: this.props.config?.animationTitleFontStyle,
        animationTitleFontWeight: this.props.config?.animationTitleFontWeight
      },
      animationIndex: 0,
      categorySet: new Set(),
      formattedCategorySet: new Set(),
      categorySetForRange: new Set(),
      data: [{
        "category": "2013",
        "formattedCategory": "2013",
        "other": -10175713,
        "either": 9736305,
        "formattedOther": "10175713",
        "formattedEither": "9736305"
      }, {
        "category": "2014",
        "formattedCategory": "2014",
        "other": -10470147,
        "either": 10031835,
        "formattedOther": "10470147",
        "formattedEither": "10031835"
      }, {
        "category": "2015",
        "formattedCategory": "2015",
        "other": -10561873,
        "either": 10117913,
        "formattedOther": "10561873",
        "formattedEither": "10117913"
      }],
      root: null,
      legend: null,
      columnMap: {
        category: {
          name: i18n.t("Plugins.age-pyramid.ColumnMap.Category.Name"),
          type: "dim",
          required: true,
          minimumColumnSize: 1,
          desc: i18n.t("Plugins.age-pyramid.ColumnMap.Category.Desc"),
          data: []
        },
        either: {
          name: i18n.t("Plugins.age-pyramid..ColumnMap.Either.Name"),
          type: "fact",
          desc: i18n.t("Plugins.age-pyramid..ColumnMap.Either.Desc"),
          required: true,
          minimumColumnSize: 1,
          conditionalFormat: true,
          data: []
        },
        animation: {
          name: i18n.t("Plugins.age-pyramid.ColumnMap.Either.Name"),
          type: "fact",
          desc: i18n.t("Plugins.age-pyramid.ColumnMap.Either.Desc"),
          required: false,
          minimumColumnSize: 1,
          conditionalFormat: false,
          data: []
        },
        other: {
          name: i18n.t("Plugins.age-pyramid..ColumnMap.Other.Name"),
          type: "fact",
          desc: i18n.t("Plugins.age-pyramid..ColumnMap.Other.Desc"),
          required: true,
          minimumColumnSize: 0,
          conditionalFormat: true,
          data: []
        },
        hidden: {
          name: i18n.t("Plugins.age-pyramid.ColumnMap.Hidden.Name"),
          type: "fact",
          desc: i18n.t("Plugins.age-pyramid.ColumnMap.Hidden.Desc"),
          multiple: true,
          minimumColumnSize: 0,
          required: true,
          conditionalFormat: true,
          data: []
        }
      },
      pluginH: this.props.plugin.h,
      pluginW: this.props.plugin.w,
      conditionalFormats: this.props.plugin.conditionalFormats || [],
      convertedDataForOther: [],
      convertedDataForEither: []
    };
  }

  /**
   * To set column map this plugin
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      category: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Category.Name"),
        type: "dim",
        required: true,
        minimumColumnSize: 1,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Category.Desc"),
        data: []
      },
      either: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Either.Name"),
        type: "fact",
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Either.Desc"),
        required: true,
        minimumColumnSize: 1,
        conditionalFormat: true,
        data: []
      },
      other: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Other.Name"),
        type: "fact",
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Other.Desc"),
        required: false,
        minimumColumnSize: 0,
        conditionalFormat: true,
        data: []
      },
      animation: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Other.Name"),
        type: "asdf",
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Other.Desc"),
        required: false,
        minimumColumnSize: 0,
        conditionalFormat: true,
        data: []
      },
      hidden: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Hidden.Name"),
        type: "fact",
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Hidden.Desc"),
        multiple: true,
        minimumColumnSize: 0,
        required: false,
        conditionalFormat: true,
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  currentAnimation

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({})

    return tmpCallBackObject;
  }

  /*
  * Controls and sets columnMap to excel export
  */
  controlDataForExcelExport = exportedData => {
    let newData = []

    for (let i = 0; i < this.state.data.length; i++) {
      let isColumnMapDefined = Array.isArray(this.state.columnMap?.category.data) && this.state.columnMap?.category.data.length > 0 && !this.state.columnMap?.category.data[0].isDisabledColumn
        && Array.isArray(this.state.columnMap?.either.data) && this.state.columnMap?.either.data.length > 0 && !this.state.columnMap?.category.data[0].isDisabledColumn

      if (isColumnMapDefined) {
        let colMap = this.state.columnMap
        let isOtherAvailable = colMap?.other?.data?.length > 0 && !colMap?.other?.data[0]?.isDisabledColumn

        let newObj = {}

        newObj["category"] = getFormattedValue(colMap.category.data[0], this.state.data[i].category)
        newObj["either"] = getFormattedValue(colMap.either.data[0], this.state.data[i].either)

        if (isOtherAvailable) {
          newObj["other"] = getFormattedValue(colMap.other.data[0], this.state.data[i].other)
        }

        newData.push(newObj)
      } else {
        newData = [{
          "category": "2013",
          "either": 1017534713,
          "other": 9736305,
        }, {
          "category": "2014",
          "either": 10175713,
          "other": 9736322305,
        }, {
          "category": "2015",
          "either": 10561873,
          "other": 10117913
        }]

        break;
      }
    }

    return newData
  }

  /**
 * Plugin compenent receive its initial id, config etc..
 */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    this.drillDowns = this.props.plugin.drillDowns;

    if (this.props.plugin.columnMap === undefined) {
      this.props.updatePlugin("columnMap", {
        columnMap: deepCopy(this.state.columnMap),
        pluginId: this.props.plugin.id
      });
    } else {
      this.setState({
        ...this.state,
        columnMap: deepCopy(this.props.plugin.columnMap
        )
      })
    }

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    if (this.props.lastRefreshedPlugin !== nextProps.lastRefreshedPlugin && nextProps.lastRefreshedPlugin === this.props.plugin.id) {
      if (this.slider.get("start") !== 0) {
        this.playButton.set("active", false);
        this.slider.set("start", 0)
      }
    }

    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );
  }

  /*
  * Sets X and Y Axes Style
  */
  getXAndYAxesStyle = (xAxis, yAxis1, yAxis2, contColor) => {
    let contrastColor = contColor + "1A"

    xAxis.get("renderer").labels.template.setAll({
      fontSize: 12,
      truncate: true,
      maxWidth: 40
    });

    yAxis1.get("renderer").labels.template.setAll({
      fontSize: 12,
    });

    yAxis2.get("renderer").labels.template.setAll({
      fontSize: 12,
    });

    if (!this.props.config?.showGrids) {
      xAxis.get("renderer").grid.template.setAll({
        strokeOpacity: 0,
        stroke: null
      });

      yAxis1.get("renderer").grid.template.setAll({
        strokeOpacity: 0,
        stroke: null
      });

      yAxis2.get("renderer").grid.template.setAll({
        strokeOpacity: 0,
        stroke: null
      });
    } else {
      xAxis.get("renderer").grid.template.setAll({
        stroke: contrastColor,
        strokeOpacity: 0.2
      });

      yAxis2.get("renderer").grid.template.setAll({
        stroke: contrastColor,
        strokeOpacity: 0.2
      });
    }

    yAxis1.get("renderer").grid.template.setAll({
      strokeOpacity: 0.2,
      stroke: contrastColor,
    });
  }

  /*
* Gets plugin category format
*/
  getFormat = (column) => {
    let type = column.DataType ? column.DataType : column.dataType;
    let dataFormat = column.DataFormat ? column.DataFormat : ",.2f";
    let format = column.DataFormat ? column.DataFormat : ",.2f";

    if (format) {
      if (dateDataTypes.has(type)) {
        let parts = {
          "%Y": "yyyy",
          "%m": "MM",
          "%d": "dd",
          "%H": "HH",
          "%M": "mm",
          "%S": "ss"
        }

        for (let part of Object.keys(parts)) {
          format = format.replace(part, parts[part]);
        }

        this.root.dateFormatter.set("dateFormat", "format");

        return { format: format, placeHolder: `.formatDate('${format}')` };
      } else if (aggregatableDataTypes.has(type)) {
        dataFormat = dataFormat.toLowerCase();

        let isDataFormatDotNumberS = dataFormat[0] === "." && dataFormat[dataFormat.length - 1] === "s";
        let isDataFormatDotCommaNumberS = dataFormat[0] === "," && dataFormat[1] === "." && dataFormat[dataFormat.length - 1] === "s";

        let isDataFormatDotNumberF = dataFormat[0] === "." && dataFormat[dataFormat.length - 1] === "f";
        let isDataFormatDotCommaNumberF = dataFormat[0] === "," && dataFormat[1] === "." && dataFormat[dataFormat.length - 1] === "f";

        let format = {
          format: "#.",
          placeHolder: `.formatNumber('#.')`
        };

        if (dataFormat !== ".s" && isDataFormatDotNumberS) {
          // for .Ns
          let subString = dataFormat.substring(dataFormat.indexOf(".") + 1, dataFormat.lastIndexOf("s"))

          if (!isNaN((Number(subString)))) {
            let zeroCount = parseInt(subString);
            let zeroArr = Array(isNaN(zeroCount) ? 0 : zeroCount + 1).join("0");

            format.placeHolder = `.formatNumber('#.${zeroArr}a')`;
            format.format = `#.${zeroArr}a`
          }
        } else if (isDataFormatDotCommaNumberF) {
          // for ,.Nf
          let subString = dataFormat.substring(dataFormat.indexOf(".") + 1, dataFormat.lastIndexOf("f"))

          if (!isNaN((Number(subString)))) {
            let zeroCount = parseInt(subString);
            let zeroArr = Array(isNaN(zeroCount) ? 0 : zeroCount + 1).join("0");

            format.placeHolder = `.formatNumber('#,###.${zeroArr}')`;
            format.format = `#,###.${zeroArr}`
          }
        } else if (isDataFormatDotCommaNumberS) {
          // for ,.Ns
          let subString = dataFormat.substring(dataFormat.indexOf(".") + 1, dataFormat.lastIndexOf("s"));

          if (!isNaN(Number(subString))) {
            let zeroCount = parseInt(subString);
            let zeroArr = Array(isNaN(zeroCount) ? 0 : zeroCount + 1).join("0");

            format.placeHolder = `.formatNumber('#,###.${zeroArr}a')`;
            format.format = `#,###.${zeroArr}a`
          }
        } else if (dataFormat === ".f") {
          //for .f
          format.placeHolder = `.formatNumber('#.')`;
          format.format = '#.';
        } else if (dataFormat !== ".f" && isDataFormatDotNumberF) {
          // for .Nf
          let subString = dataFormat.substring(dataFormat.indexOf(".") + 1, dataFormat.lastIndexOf("f"));

          if (!isNaN(Number(subString))) {
            let zeroCount = parseInt(subString);
            let zeroArr = Array(zeroCount + 1).join("0")

            format.placeHolder = `.formatNumber('#.${zeroArr}')`;
            format.format = `#.${zeroArr}`
          }
        }

        return format;
      }
    }

    return {
      placeHolder: "",
      format: null
    };
  }

  /*
  * Sorts category
  */
  compareTexts = (a, b) => {
    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    return 0;
  }

  /*
  * Prepares animated data fields
  */
  findAnimatedDatas = () => {
    let data = this.props.plugin.data ? this.props.plugin.data : []
    let animatedDataKeys = new Map()

    for (let i = 0; i < data.length; i++) {
      if (!animatedDataKeys.has(data[i].animation)) {
        animatedDataKeys.set(data[i].animation, [data[i]])
      } else {
        let gettedDataArray = animatedDataKeys.get(data[i].animation)

        gettedDataArray.push(data[i])

        animatedDataKeys.set(data[i].animation, gettedDataArray)
      }
    }

    let isOrderByAvailable = this.state.columnMap?.category?.data[0]?.orderBy == null && this.state.columnMap?.hidden?.data[0]?.orderBy == null && this.state.columnMap?.animation?.data[0]?.orderBy == null

    if (isOrderByAvailable) {
      let keys = Array.from(animatedDataKeys.keys())

      for (let i = 0; i < keys.length; i++) {
        let gettedValues = animatedDataKeys.get(keys[i])

        gettedValues.sort((a, b) => this.compareTexts(a.category, b.category))

        animatedDataKeys.set(keys[i], gettedValues)
      }
    }

    return animatedDataKeys
  }

  chart = null
  root = null
  measure1 = null
  measure2 = null
  measure1BulletSprite = null
  measure2BulletSprite = null
  conditionalFormatLegends = null
  allRanges = null
  yAxis1 = null
  yAxis2 = null
  xAxis = null
  parent = null
  mainContainer = null
  chartContainer = null

  chartContainerHeight = null
  chartContainerWidth = null
  chartHeight = null
  chartWidth = null

  yAxisContentWidth = null

  exporting = null

  slider = null
  playButton = null

  /*
* Gets contrast color
*/
  getContrastColor = (colorRGB) => {
    const yiq = (colorRGB.r * 299 + colorRGB.g * 587 + colorRGB.b * 114) / 1000;

    return yiq >= 128 ? "#000000" : "#FFFFFF"
  };

  // checks if plugin is rendered
  isPluginRender = (plugin, pluginConfig) => {
    if (plugin.data && plugin.data.length === 0) {
      let noDataTitle = pluginConfig.noDataTitle ? pluginConfig.noDataTitle.trim() : ""
      let reduxState = store.getState()
      let noDataSetting = reduxState.SettingNoDataTitleReducer?.data

      if (noDataTitle === "") {
        let isNoDataTitleEmpty = reduxState.SettingNoDataTitleReducer.data && reduxState.SettingNoDataTitleReducer.data !== ""

        if (isNoDataTitleEmpty) {
          return {
            status: false,
            errorMessage: (
              <span>
                {noDataSetting}
              </span>)
          }
        }

        return {
          status: false,
          errorMessage: (
            <span>
              {i18n.t("NoDataContent.NoData")} <br></br>{" "}
              {i18n.t("NoDataContent.PluginCouldNotBeVisualized")}
            </span>
          )
        };
      } else {
        return {
          status: false,
          errorMessage: (
            <span>
              {i18n.t(pluginConfig.noDataTitle)}
            </span>
          )
        }
      }
    }

    return { status: true };
  };

  /*
  * Sets animation theme
  */
  setTheme = (config, contrastColor) => {
    let chartTheme = am5themes_Animated.new(this.root);

    let grip = {
      icon: undefined,
      width: 14,
      height: 14
    }

    let buttonBackground = {
      fill: am5.color(config.backgroundColor),
      stroke: am5.color(contrastColor),
      strokeOpacity: 0.5
    }

    chartTheme.rule("Button").setup = (button) => {
      button.get("background").setAll(buttonBackground);

      button.get("background").states.create("down", {
        fill: am5.color(config.backgroundColor),
        strokeOpacity: 1
      });

      button.get("background").states.create("active", {
        fill: am5.color(config.backgroundColor),
        strokeOpacity: 0.5
      });

      button.get("background").states.create("hover", {
        fill: am5.color(config.backgroundColor),
        strokeOpacity: 0.8
      });
    };

    chartTheme.rule("Graphics", ["button", "icon"]).setAll({
      fill: am5.color(contrastColor),
      stroke: am5.color(contrastColor),
      x: am5.p50,
      centerX: am5.p50,
      y: am5.p50,
      centerY: am5.p50
    });

    chartTheme.rule("Button", ["play"]).setAll({
      width: 24,
      height: 24,
      icon: am5.Graphics.new(this.root, {
        themeTags: ["icon"],
        fill: am5.color(contrastColor),
        stroke: am5.color(contrastColor)
      })
    });

    chartTheme.rule("Button", ["zoom"]).setAll({
      width: 32,
      height: 32,
      exportable: false,
      icon: am5.Graphics.new(this.root, {
        themeTags: ["icon"],
        fill: am5.color(contrastColor),
        stroke: am5.color(contrastColor)
      })
    });


    chartTheme.rule("Scrollbar").setup = (scrollbar) => {
      scrollbar.setAll({
        minWidth: 5,
        minHeight: 5,
        exportable: false
      });

      scrollbar.get("background").setAll({
        fill: am5.color(contrastColor),
        fillOpacity: 0.1
      });

      scrollbar.thumb.setAll({
        fill: am5.color(contrastColor),
        fillOpacity: 0.2
      });

      scrollbar.startGrip.setAll(grip);
      scrollbar.endGrip.setAll(grip);

      scrollbar.startGrip.get("background").setAll(buttonBackground);
      scrollbar.endGrip.get("background").setAll(buttonBackground);
    };

    this.root.setThemes([chartTheme]);
  }

  /*
  * Renders age pyramid
  */
  renderAgePyramidChart = (newState = this.state) => {
    let dataForOther = this.state.data
    let dataForEither = this.state.data
    let pluginWidth = $("#" + this.props.plugin.id).width()
    let contrastColor = this.getContrastColor(am5.color(this.props.config?.backgroundColor ? this.props.config?.backgroundColor : "#FFFFFFF"))
    let isPluginHasCorrectColumnMap = this.state.columnMap?.category?.data.length > 0 && this.state.columnMap?.either?.data.length > 0 ? true : false

    var root = null
    var THIS = this

    if ($("#" + this.props.plugin.id).children().length === 0) {
      root = am5.Root.new(this.props.plugin.id);

      root._logo.dispose();
    } else {
      let fullPluginHeight = calculatePluginInlineHeight(this.props.plugin.id)

      $("#" + this.props.plugin.id).remove()

      let mainContainer = document.createElement('div');
      let pluginHeight = fullPluginHeight

      mainContainer.setAttribute("id", this.props.plugin.id);
      mainContainer.style.height = pluginHeight + "px"
      mainContainer.style.width = "-webkit-fill-available"
      mainContainer.style.backgroundColor = newState.config.backgroundColor

      this.mainContainer = mainContainer

      $("#plugin-" + this.props.plugin.id).children().append(mainContainer)

      root = am5.Root.new(this.props.plugin.id);

      root._logo.dispose();
    }

    $("#" + this.props.plugin.id).css("background", newState.config.backgroundColor)

    root.numberFormatter.setAll({
      numberFormat: this.state.columnMap?.either?.data.length > 0 ? this.getFormat(this.state.columnMap.either.data[0]).format + "s" : "#.##s"
    });

    root.dateFormatter.monthsShort = shortMonths
    root.dateFormatter.months = longMonths
    root.numberFormatter.bigNumberPrefixes = bigNumberPrefixes
    root.locale["_decimalSeparator"] = decimalSeperators;
    root.locale["_thousandSeparator"] = thousandSeperators;


    // Set themes
    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    let parent = root.container.children.push(am5.Container.new(root, {
      height: am5.percent(100),
      width: am5.percent(100),
      layout: root.verticalLayout,
    }))

    let chartContainer = am5.Container.new(root, {
      width: am5.percent(100),
      height: am5.percent(100),
    })

    parent.children.push(chartContainer)

    this.chartContainer = chartContainer

    // Create chart
    var chart = chartContainer.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "none",
      wheelY: "none",
      layout: root.verticalLayout,
      width: am5.percent(100),
      height: am5.percent(100),
    }));

    chart.zoomOutButton.set("forceHidden", true);

    this.chart = chart

    // Create axes
    var yAxis1 = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "formattedCategory",
      renderer: am5xy.AxisRendererY.new(root, {
        minorGridEnabled: true,
        minGridDistance: 15,
      }),
    }));

    var yAxis2 = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "formattedCategory",
      renderer: am5xy.AxisRendererY.new(root, {
        opposite: true,
        minorGridEnabled: true,
        minGridDistance: 15,
      }),
    }));

    var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererX.new(root, {
        minGridDistance: 120,
        fill: contrastColor,
      })
    }));

    yAxis1.get("renderer").labels.template.setAll({
      oversizedBehavior: "truncate",
      maxWidth: pluginWidth / 5,
      tooltipText: "{formattedCategory}",
      fill: contrastColor
    });

    yAxis2.get("renderer").labels.template.setAll({
      oversizedBehavior: "truncate",
      text: "",
      fill: contrastColor
    });

    xAxis.get("renderer").labels.template.setAll({
      fill: contrastColor
    });

    this.xAxis = xAxis

    this.getXAndYAxesStyle(xAxis, yAxis1, yAxis2, contrastColor)

    let tooltipForMeasure1 = am5.Tooltip.new(root, {
      getFillFromSprite: false,
      labelText: "[bold]{name}[/]\n{valueX.formatDate()}: {valueY}"
    });

    tooltipForMeasure1.get("background").setAll({
      fill: "#000000a6",
      fillOpacity: 0.8
    });

    let tooltipForMeasure2 = am5.Tooltip.new(root, {
      getFillFromSprite: false,
      labelText: "[bold]{name}[/]\n{valueX.formatDate()}: {valueY}"
    });

    tooltipForMeasure2.get("background").setAll({
      fill: "#000000a6",
      fillOpacity: 0.8
    });

    // Create series
    var measure1 = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: newState.columnMap?.other?.data[0]?.displayName,
      xAxis: xAxis,
      yAxis: yAxis1,
      valueXField: "other",
      categoryYField: "formattedCategory",
      clustered: false,
      tooltip: tooltipForMeasure1,
      fill: newState.config.paletteColours[1],
      fillField: "color",
      disabled: true
    }));

    var measure2 = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: newState.columnMap?.either?.data[0]?.displayName,
      xAxis: xAxis,
      yAxis: yAxis1,
      valueXField: "either",
      categoryYField: "formattedCategory",
      clustered: false,
      tooltip: tooltipForMeasure2,
      fill: newState.config.paletteColours[0],
      fillField: "color"
    }));

    measure1.get("tooltip").label.adapters.add("text", function (text, target) {
      let paletteColours = THIS.props.config?.colours
        ? InsightsConfig.Palettes[THIS.props.config?.colours]
          ? InsightsConfig.Palettes[THIS.props.config?.colours]
          : THIS.props.config?.colours
        : ["#67b7dc", "#6771dc", "#a367dc", "#dc67ce", "#dc6788", "#dc8c67"]
      measure1.get("tooltip").get("background").setAll({
        fill: "#000000a6",
        tooltipX: 0,
        tooltipY: 0,
      });

      if (THIS.props.plugin.columnMapForPlugin?.category && THIS.props.plugin.columnMapForPlugin?.other && target._dataItem?.dataContext) {
        let isDrilldownColumn = THIS.props.plugin.drillDownColumnMap ? THIS.props.plugin.drillDownColumnMap.category : THIS.state.columnMap.category.data[0]

        if (isDrilldownColumn) {
          let category = getFormattedValue(isDrilldownColumn, target._dataItem?.dataContext.category)
          let value = getFormattedValue(THIS.props.plugin.columnMapForPlugin?.other, target._dataItem?.dataContext.other)

          return `[/] [bold fontSize: 14px]${category}\n[${paletteColours[1]}]●[/] [fontSize: 13px]${THIS.props.plugin.columnMapForPlugin?.other.displayName}[/] [bold fontSize: 13px]${value}`
        }
      } else {
        return `[/] [bold fontSize: 14px]${target._dataItem?.dataContext.category}\n[${paletteColours[1]}]●[/] [fontSize: 13px]${"Kargo Toplam"}[/] [bold fontSize: 13px]${target._dataItem?.dataContext.other * -1}`
      }
    });

    measure2.get("tooltip").label.adapters.add("text", function (text, target) {
      let isOtherAvailable = THIS.state.columnMap?.other?.data.length > 0 && !THIS.state.columnMap?.other?.data[0]?.isDisabledColumn
      let paletteColours = THIS.props.config?.colours
        ? InsightsConfig.Palettes[THIS.props.config?.colours]
          ? InsightsConfig.Palettes[THIS.props.config?.colours]
          : THIS.props.config?.colours
        : ["#67b7dc", "#6771dc", "#a367dc", "#dc67ce", "#dc6788", "#dc8c67"]
      measure2.get("tooltip").get("background").setAll({
        fill: "#000000a6",
        tooltipX: 0,
        tooltipY: 0,
      });

      if (THIS.props.plugin.columnMapForPlugin?.category && THIS.props.plugin.columnMapForPlugin?.either && target._dataItem?.dataContext) {
        let isDrilldownColumn = THIS.props.plugin.drillDownColumnMap ? THIS.props.plugin.drillDownColumnMap.category : THIS.props.plugin.columnMapForPlugin?.category

        if (isDrilldownColumn) {
          let category = getFormattedValue(isDrilldownColumn, target._dataItem?.dataContext.category)
          let value = getFormattedValue(THIS.props.plugin.columnMapForPlugin?.either, isOtherAvailable ? target._dataItem?.dataContext.either * -1 : target._dataItem?.dataContext.either)

          return `[/] [bold fontSize: 14px]${category}\n[${paletteColours[0]}]●[/] [fontSize: 13px]${THIS.props.plugin.columnMapForPlugin?.either.displayName}[/] [bold fontSize: 13px]${value}`
        }
      } else {
        return `[/] [bold fontSize: 14px]${target._dataItem?.dataContext.category}\n[${paletteColours[0]}]●[/] [fontSize: 13px]${"Posta Toplam"}[/] [bold fontSize: 13px]${target._dataItem?.dataContext.either}`
      }
    });

    measure2.columns.template.setAll({
      tooltipText: `age`,
      tooltipX: am5.p100,
      cursorOverStyle: "pointer"
    });

    measure1.columns.template.setAll({
      tooltipText: `age`,
      tooltipX: am5.p100,
      cursorOverStyle: "pointer"
    });

    if (newState.config.showValuesOnBar) {
      measure1.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Label.new(root, {
            centerX: am5.p50,
            x: am5.p50,
            centerY: am5.p50,
            text: "{formattedOther}",
            fill: am5.color(newState.config.valueFontColor),
            fontSize: newState.config.valuesOnBarFontSize == 0 ? 1 : newState.config.valuesOnBarFontSize,
            populateText: true,
          })
        });
      });

      measure2.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Label.new(root, {
            centerX: am5.p50,
            x: am5.p50,
            centerY: am5.p50,
            text: "{formattedEither}",
            fill: am5.color(newState.config.valueFontColor),
            fontSize: newState.config.valuesOnBarFontSize == 0 ? 1 : newState.config.valuesOnBarFontSize,
            populateText: true
          })
        });
      });
    }

    measure1.columns.template.events.on("click", function (ev) {
      let sendedData = {
        category: ev.target.dataItem.dataContext.category
      }

      if (ev.target.dataItem.dataContext) {
        sendedData.animation = ev.target.dataItem.dataContext.animation
      }

      let isDrilldownColumn = THIS.props.plugin.drillDownColumnMap ? THIS.props.plugin.drillDownColumnMap.category : THIS.state.columnMap.category.data[0]
      let mousePosition = { x: window.event.pageX, y: window.event.pageY }
      let convertedColumnMap = {
        category: [isDrilldownColumn],
        other: [THIS.state.columnMap.other.data[0]],
        either: [THIS.state.columnMap.either.data[0]],
        animation: THIS.state?.columnMap?.animation?.data[0] ? [THIS.state?.columnMap?.animation?.data[0]] : []
      }

      createTrigger(
        actions,
        convertedColumnMap,
        chartContainer,
        "barClick",
        sendedData,
        THIS.props.plugin.id,
        THIS.props.interactions,
        THIS.props.navigations,
        mousePosition,
        null,
        THIS.props.plugin.drillDowns,
        [isDrilldownColumn],
        THIS.props.plugin,
        THIS.props.model,
        sendedData
      ); // Trigger event
    });

    measure2.columns.template.events.on("click", function (ev) {
      let sendedData = {
        category: ev.target.dataItem.dataContext.category
      }

      if (ev.target.dataItem.dataContext) {
        sendedData.animation = ev.target.dataItem.dataContext.animation
      }

      let isDrilldownColumn = THIS.props.plugin.drillDownColumnMap ? THIS.props.plugin.drillDownColumnMap.category : THIS.state.columnMap.category.data[0]
      let mousePosition = { x: window.event.pageX, y: window.event.pageY }
      let convertedColumnMap = {
        category: [isDrilldownColumn],
        other: [THIS.state.columnMap.other.data[0]],
        either: [THIS.state.columnMap.either.data[0]],
        animation: THIS.state?.columnMap?.animation?.data[0] ? [THIS.state?.columnMap?.animation?.data[0]] : []
      }

      createTrigger(
        actions,
        convertedColumnMap,
        chartContainer,
        "barClick",
        sendedData,
        THIS.props.plugin.id,
        THIS.props.interactions,
        THIS.props.navigations,
        mousePosition,
        null,
        THIS.props.plugin.drillDowns,
        [isDrilldownColumn],
        THIS.props.plugin,
        THIS.props.model,
        sendedData
      ); // Trigger event
    });

    this.yAxis1 = yAxis1
    this.yAxis2 = yAxis2

    if (!isPluginHasCorrectColumnMap) {
      measure1.data.setAll(dataForOther);
      measure2.data.setAll(dataForEither);

      yAxis1.data.setAll(dataForOther);
      yAxis2.data.setAll(dataForEither);
    }

    root.events.on("frameended", () => {
      root.resize()

      setTimeout(() => this.calculateLabelPositions(), 10)
    });

    this.chartRef.current = chart
    this.chart = chart
    this.root = root
    this.measure1 = measure1
    this.measure2 = measure2
    this.parent = parent
    this.am5 = am5

    this.setTheme(newState.config, contrastColor)

    let legendRoot = parent.children.push(
      am5.Legend.new(root, {
        width: am5.percent(100),
      }))

    var legend = legendRoot.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      y: am5.percent(50),
      centerY: am5.percent(50),
      nameField: "name",
      maxHeight: 60,
      verticalScrollbar: am5.Scrollbar.new(root, {
        orientation: "vertical",
        maxWidth: 5,
      }),
      fillField: "color",
      cursorOverStyle: "pointer"
    }));

    legend.markerRectangles.template.setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusBR: 10,
      width: 12,
      height: 12,
      cursorOverStyle: "pointer"
    });

    legend.labels.template.setAll({
      fontSize: 11,
      oversizedBehavior: "truncate",
      textAlign: "center",
      maxWidth: pluginWidth - 200,
      cursorOverStyle: "pointer",
      fill: contrastColor
    });

    var THIS = this
    let isDataDefaultData = (this.state.columnMap
      && this.state.columnMap?.category?.data?.length === 0
      && this.state.columnMap?.either?.data?.length === 0
      && this.state.columnMap?.other?.data?.length === 0)

    legend.itemContainers.template.events.on("click", function (ev) {
      let series = ev.target._dataItem.dataContext.field === "other" ? THIS.state.chart.series.values[0] : THIS.state.chart.series.values[1]

      if (series.isHiding() || series.isHidden()) {
        series.show();

        setTimeout(() => THIS.calculateLabelPositions(), 10)

        ev.target.states.applyAnimate("default");
      }
      else {
        series.hide();

        ev.target.states.applyAnimate("disabled");
      }
    })

    if ((this.props.plugin.config && this.props.plugin.config?.showRadarLegends == undefined || this.props.plugin.config?.showRadarLegends) && (this.props.plugin.h > 3 && this.props.plugin.w >= 4)) {
      let newLegendData = []
      let coloursList = Array.isArray(this.props.plugin.config.colours) ? this.props.plugin.config.colours : InsightsConfig.Palettes[this.props.plugin.config.colours]

      if (this.state.columnMap.other.data.length > 0 && !this.state.columnMap.other.data[0].isDisabledColumn) {
        newLegendData.push({
          name: this.props.plugin.columnMap.other.data[0]?.displayName,
          color: coloursList[1],
          field: "other"
        })
      } else if (isDataDefaultData) {
        newLegendData.push({
          name: "Postatoplam",
          color: coloursList[1],
          field: "other"
        })
      }

      if (isDataDefaultData) {
        newLegendData.push({
          name: "Kargotoplam",
          color: coloursList[0],
          field: "either"
        })
      } else {
        newLegendData.push({
          name: this.props.plugin.columnMap.either.data[0]?.displayName,
          color: coloursList[0],
          field: "either"
        })
      }

      legend.show()

      legend.data.setAll(newLegendData);
    } else {
      legend.hide()
    }

    this.legend = legend

    let animationText = parent.children.unshift(am5.Label.new(root, {
      text: "",
      fontSize: 25,
      fontWeight: "500",
      textAlign: "center",
      x: am5.percent(50),
      centerX: am5.percent(50),
      oversizedBehavior: "truncate",
      y: -7
    }));

    this.animationText = animationText

    let animationSliderContainer = am5.Container.new(this.root, {
      width: am5.percent(100),
      height: 30,
      layout: this.root.horizontalLayout,
      marginTop: 10,
      marginBottom: 10,
      paddingLeft: 70,
      paddingRight: 40,
      exportable: false,
      visible: false
    });

    parent.children.insertIndex(2, animationSliderContainer)

    // Create play button
    this.playButton = animationSliderContainer.children.push(am5.Button.new(this.root, {
      active: false,
      themeTags: ["play"],
      centerY: am5.p50,
      y: 15,
      marginRight: 20,
      icon: am5.Graphics.new(this.root, {
        themeTags: ["icon"],
        fill: "black"
      })
    }));

    // Play button on click event
    this.playButton.events.on("click", () => {
      let data = this.findAnimatedDatas()
      let animationKeys = Array.from(data.keys())


      if (slider.get("start") === 1) {
        slider.set("start", 0)
      }

      if (this.playButton.get("active")) {
        slider.set("start", slider.get("start"));
      } else {
        slider.animate({
          key: "start",
          to: 1,
          duration: 1000 * (this.state.config.duration || 1) * animationKeys.length * (1 - slider.get("start"))
        });
      }
    });

    // Starts chart animation
    this.startAnimation = async (timeout) => {
      setTimeout(() => {
        let data = this.findAnimatedDatas()
        let animationKeys = Array.from(data.keys())

        slider.animate({
          key: "start",
          to: 1,
          duration: 1000 * (this.state.config.duration || 1) * animationKeys.length * (1 - slider.get("start"))
        });

        this.playButton.set("active", true);
      }, timeout);
    }

    // Stops chart animation
    this.stopAnimation = () => {
      this.playButton.set("active", false);
      slider.set("start", slider.get("start"));
    };

    // Rewinds chart animation
    this.rewindAnimation = () => {
      this.playButton.set("active", false);
      slider.set("start", 0);          
    }

    // Play button pointer over event
    this.playButton.events.on("pointerover", (ev) => {
      document.body.style.cursor = "pointer";
    });

    // Play button pointer out event
    this.playButton.events.on("pointerout", (ev) => {
      document.body.style.cursor = "default";
    });

    // Create animation slider
    let slider = animationSliderContainer.children.push(am5.Slider.new(this.root, {
      orientation: "horizontal",
      start: 0,
      centerY: am5.p100,
      y: 15
    }));

    slider.on("start", (start) => {
      if (start === 1) {
        this.playButton.set("active", false);
      }
    });

    this.slider = slider

    slider.events.on("rangechanged", () => setTimeout(() => {
      let data = this.findAnimatedDatas()
      let animationKeys = Array.from(data.keys())
      let index = Math.round(slider.get("start", 0) * (animationKeys.length - 1));

      if (this.currentAnimation !== animationKeys[index]) {
        this.currentAnimation = animationKeys[index]
      } else {
        return
      }

      let convertedDataForAnimation = this.findAnimatedDatas(this.props.plugin.data)
      let animationValues = Array.from(convertedDataForAnimation.values())

      let convertedDataForOther = this.convertDataForOther(animationValues[index], this.state)
      let convertedDataForEither = this.convertDataForEither(animationValues[index], this.state)
      let isOtherAvailableProps = this.props.plugin.columnMap.other.data.length > 0 && !this.props.plugin.columnMap.other.data[0]?.isDisabledColumn
      let maxValue = 0

      if (chart.series._values.length > 0) {
        for (let i = 0; i < convertedDataForEither.length; i++) {
          let isAnimationFormatValueEmptyAndDouble = this.state.columnMap?.animation?.data[0].DataFormat == undefined && (this.state.columnMap?.animation?.data[0].dataType === "double")
          let isAnimationFormatValueEmptyAndInteger = this.state.columnMap?.animation?.data[0].DataFormat == undefined && (this.state.columnMap?.animation?.data[0].dataType === "integer")
          let copiedAnimationColumn = deepCopy(this.state.columnMap.animation.data[0])

          if (isAnimationFormatValueEmptyAndDouble) {
            copiedAnimationColumn.DataFormat = ",.2f"
            copiedAnimationColumn.DataFormatType = "number"
            copiedAnimationColumn.DataFormatTypeSubTitle = "decimal"
            copiedAnimationColumn.DecimalPlaces = "2"
          } else if (isAnimationFormatValueEmptyAndInteger) {
            copiedAnimationColumn.DataFormat = ".f"
            copiedAnimationColumn.DataFormatType = "number"
            copiedAnimationColumn.DataFormatTypeSubTitle = "wholeNumber"
            copiedAnimationColumn.DecimalPlaces = "0"
          }

          let convertedValue = getFormattedValue(copiedAnimationColumn, convertedDataForEither[i].animation, undefined, false)
          let controlledValue = convertedValue === NaN || convertedValue === "NaN" ? "" : convertedValue

          if (copiedAnimationColumn) {
            animationText.setAll({
              text: controlledValue
            })
          }

          if (chart.series._values[1].data._values.length <= convertedDataForEither.length) {
            if (chart.series._values[1].data._values[i] !== undefined) {
              chart.series._values[1].data.setIndex(i, convertedDataForEither[i])

              chart.yAxes._values[0].data.setIndex(i, convertedDataForEither[i])
            } else {
              chart.series._values[1].data.insertIndex(i, convertedDataForEither[i])

              chart.yAxes._values[0].data.insertIndex(i, convertedDataForEither[i])
            }
          } else {
            let deleteIndex = chart.series._values[1].data.length - 1

            chart.series._values[1].data.removeIndex(deleteIndex)

            chart.yAxes._values[0].data.removeIndex(deleteIndex)

            if (chart.series._values[1].data._values[i] !== undefined) {
              chart.series._values[1].data.setIndex(i, convertedDataForEither[i])

              chart.yAxes._values[0].data.setIndex(i, convertedDataForEither[i])
            } else {
              chart.series._values[1].data.insertIndex(i, convertedDataForEither[i])

              chart.yAxes._values[0].data.insertIndex(i, convertedDataForEither[i])
            }
          }

          if (chart.yAxes._values[0].dataItems[i] && chart.yAxes._values[0].dataItems[i].get("label")) {
            chart.yAxes._values[0].dataItems[i].get("label").setAll({
              text: convertedDataForEither[i].category
            });
          }

          let convertedDataEitherData = isOtherAvailableProps ? -convertedDataForEither[i].either : convertedDataForEither[i].either

          if (maxValue < convertedDataEitherData) {
            maxValue = convertedDataEitherData
          }
        }

        for (let i = 0; i < convertedDataForOther.length; i++) {
          if (chart.series._values[0].data._values.length <= convertedDataForOther.length) {
            if (chart.series._values[0].data._values[i] !== undefined) {
              chart.series._values[0].data.setIndex(i, convertedDataForOther[i])
            } else {
              chart.series._values[0].data.insertIndex(i, convertedDataForOther[i])
            }
          } else {
            let deleteIndex = chart.series._values[0].data.length - 1

            chart.series._values[0].data.removeIndex(deleteIndex)

            if (chart.series._values[0].data._values[i] !== undefined) {
              chart.series._values[0].data.setIndex(i, convertedDataForOther[i])
            } else {
              chart.series._values[0].data.insertIndex(i, convertedDataForOther[i])
            }
          }

          let otherDisplayName = this.state.columnMap?.other?.data[0]?.displayName

          if (maxValue < convertedDataForEither[i][otherDisplayName]) {
            maxValue = convertedDataForEither[i][otherDisplayName]
          }
        }
      }

      if (isOtherAvailableProps) {
        this.xAxis.setAll({
          min: -maxValue,
          max: maxValue
        })
      } else {
        this.xAxis.setAll({
          min: 0,
          max: maxValue
        })
      }

      let categorySetForRange = new Set()

      for (let i = 0; i < animationValues[index].length; i++) {
        let currCategory = this.props.plugin.drillDownColumnMap ? this.props.plugin.drillDownColumnMap.category : this.props.plugin.columnMap.category.data[0]

        categorySetForRange.add(getFormattedValue(currCategory, animationValues[index][i].category))
      }

      this.setState({
        ...this.state,
        animationIndex: index,
        categorySetForRange: categorySetForRange
      })

      this.asyncControlledFunction(this.state)
    }));

    if (this.props.plugin?.columnMap?.animation?.data?.length > 0 && !this.props.isDashboardSliderActive) {
      animationSliderContainer.set("visible", true);
    }

    this.animationSliderContainer = animationSliderContainer

    let exporting = am5plugins_exporting.Exporting.new(root, {
      title: `${this.props.title}`,
      filePrefix: `${this.props.title}`,
      menu: null,
      dataSource: this.controlDataForExcelExport(this.state.data),
      pngOptions: {
        quality: 1,
        minWidth: 4320,
        minHeight: 4320,
        pageOrientation: "portrait"
      },
      pdfOptions: {
        quality: 1,
        addURL: false,
        minWidth: 4320,
        minHeight: 4320,
        align: "center"
      }
    });

    exporting.events.on("dataprocessed", function (ev) {
      let isDataDefaultData = (THIS.state.columnMap
        && THIS.state.columnMap?.category.data.length === 0
        && THIS.state.columnMap?.either.data.length === 0
        && THIS.state.columnMap?.other.data.length === 0)
      let data = THIS.controlDataForExcelExport(THIS.state.data)
      let newArr = []
      let datetime = getCurrentDateTime();
      let date = { [datetime]: datetime }
      let title = { [datetime]: THIS.props.title }
      let category = THIS.state.columnMap.category.data.length > 0 ? THIS.state.columnMap.category.data[0].displayName : "Ay Adı"
      let either = THIS.state.columnMap.either.data.length > 0 ? THIS.state.columnMap.either.data[0].displayName : "Kargo Toplam"
      let other = !isDataDefaultData
        ? THIS.state.columnMap.other.data.length > 0 ? THIS.state.columnMap.other.data[0].displayName : undefined
        : "Posta Toplam"

      let categories = {
        [datetime]: category,
        "": either,
        " ": other
      }

      newArr.push(title)
      newArr.push(categories)

      let excelDisplay = data.map(row => ({
        [datetime]: row.category,
        [""]: row.either,
        [" "]: row.other
      }));

      data = newArr.concat(excelDisplay)

      ev.data = data
    });

    this.exporting = exporting

    let exportDownloadSupport = exporting.downloadSupport();

    let xlsxDownload = () => {
      let dashboard = {}

      dashboard.plugins = [this.props.plugin]
      dashboard.title = `${this.props.title}`;

      generalExcelExport(dashboard, this.props.model)
    }

    if (exportDownloadSupport) {
      let title = `${this.props.title}`;

      // General exporting properties
      exporting.title = title;
      exporting.filePrefix = title;
      exporting.useRetina = true;
      exporting.showTimeout = () => { };

      let plugin = $("#" + this.props.plugin.id)[0]

      plugin.PNG = () => exporting.download("png");
      plugin.PDF = () => exporting.download("pdf");
      plugin.XLSX = () => this.props.excelExport();

      /**
       * Chart PDF document handler
       */
      exporting.events.on("pdfdocready", function (pdf) {
        let { content } = pdf.doc;
        let [title, image] = content;

        pdf.doc.header = {
          columns: [
            {
              text: `${i18n.t("Dashboard.Configuration.Fields.ExportDate")}: ${rmvpp.generateDate()}`,
              bold: false,
              margin: [0, 5, 30, 0],
              alignment: 'right'
            }
          ]
        }

        pdf.doc.content[0] = title;
        pdf.doc.content[1] = image;

        pdf.doc.footer = {
          image: vispeahenLogo,
          margin: [0, 0, 15, 5],
          alignment: 'right',
        };

        pdf.compress = false;

        return pdf;
      });
    }

    /*
    * Controls unique data
    */
    let controlUniqueData = (data) => {
      if (data.length > 0) {
        let categoryHash = new Set()

        for (let i = 0; i < data.length; i++) {
          let dataCategory = data[i].category

          if (!categoryHash.has(dataCategory)) {
            categoryHash.add(dataCategory)
          }
        }

        return Array.from(categoryHash.values()).length
      }

      return 0
    }

    exporting.events.on("exportstarted", (event) => {
      let divId = this.props.plugin.id
      let config = this.props.plugin.config
      let isPluginBigEnough = (this.props.plugin.h > 3 && this.props.plugin.w >= 6)
      let convertedColumnMap = convertColumnMapForConditionalFormat(this.state.columnMap)
      let condFormats = this.props.plugin.conditionalFormats && convertedColumnMap ? convertFormatConditionalFormatting(this.props.plugin.conditionalFormats, convertedColumnMap) : []
      let measuredHeight = this.parent._contentHeight
      let measuredWidth = this.parent._contentWidth
      let isAnimationAvailable = this.state.columnMap?.animation?.data?.length && !this.state.columnMap?.animation?.data[0].isDisabledColumn > 0 ? true : false
      let hasAnimatedKeyHasAnimatedDatas = this.findAnimatedDatas().has(this.currentAnimation) ? this.currentAnimation : Array.from(this.findAnimatedDatas().keys())[0]
      let animatedDataLength = isAnimationAvailable ? Array.from(this.findAnimatedDatas().get(hasAnimatedKeyHasAnimatedDatas)) : this.state.data
      let chartCategoryHeight = controlUniqueData(animatedDataLength) * 25
      let chartCategoryWidth
      let mainContainer = $("#" + this.props.plugin.id)
      let biggestCategoryWidth = 1
      let condFormHeight = 0
      let categoryHeight

      mainContainer[0].style.opacity = "0"

      store.dispatch(changePluginLoaderVisibility(divId, true))

      this.chartHeight = mainContainer.height()
      this.chartWidth = mainContainer.width()

      for (let i = 0; i < this.state.data.length; i++) {
        let perData = this.state.data[i]

        if (perData.category.length > biggestCategoryWidth) {
          biggestCategoryWidth = perData.category.length
          chartCategoryWidth = biggestCategoryWidth * 12
        }
      }

      this.yAxis1.get("renderer").labels.template.setAll({
        oversizedBehavior: "truncate",
        maxWidth: chartCategoryWidth,
      });

      this.yAxisContentWidth = this.yAxis1._contentWidth

      categoryHeight = this.legend._contentHeight

      if ((config.showConditionalLegends == undefined || config.showConditionalLegends === true) && condFormats.length > 0 && isPluginBigEnough) {
        condFormHeight = this.conditionalFormatLegends._contentHeight;

        this.conditionalFormatLegends.get("verticalScrollbar").set("start", 0)
        this.conditionalLegendRoot.set("height", condFormHeight)

        measuredHeight += condFormHeight

        this.parent.set("height", measuredHeight)
      }

      let width = this.state.columnMap.other.data.length > 0 ? chartCategoryWidth * 2 + pluginWidth * 1.25 : chartCategoryWidth + pluginWidth * 1.25
      measuredHeight = chartCategoryHeight + condFormHeight < 370 ? 370 : chartCategoryHeight + condFormHeight
      measuredHeight = isAnimationAvailable ? measuredHeight + animationText.height() + 50 : measuredHeight
      mainContainer.css("height", (measuredHeight) + "px")
      mainContainer.css("width", width + "px")

      this.chartContainerHeight = this.chartContainer._contentHeight
      this.chartContainerWidth = this.chartContainer._contentWidth

      this.chartContainer.set("height", chartCategoryHeight < 330 - condFormHeight - categoryHeight ? 330 - condFormHeight : chartCategoryHeight - condFormHeight - categoryHeight)
      this.chartContainer.set("width", am5.percent(100))

      this.chart.set("height", am5.percent(100))
      this.chart.set("width", am5.percent(100))

      this.root.resize();

      this.animationSliderContainer.hide()
    })

    exporting.events.on("exportfinished", event => {
      let divId = this.props.plugin.id
      let mainContainer = $("#" + divId)

      mainContainer.css("height", this.chartHeight + "px")
      mainContainer.css("width", "100%")

      this.chartContainer.set("height", am5.percent(100))
      this.chartContainer.set("width", am5.percent(100))

      this.chart.set("height", am5.percent(100))
      this.chart.set("width", am5.percent(100))

      this.parent.set("height", am5.percent(100))
      this.parent.set("width", am5.percent(100))

      if (this.conditionalLegendRoot) {
        this.conditionalLegendRoot.set("height", 30)
      }

      this.yAxis1.get("renderer").labels.template.setAll({
        oversizedBehavior: "truncate",
        maxWidth: this.yAxisContentWidth,
      });

      if (this.state.columnMap.animation?.data?.length > 0 && !this.state.columnMap.animation?.data[0]?.isDisabledColumn) {
        this.animationSliderContainer.show()
      }

      this.root.resize();

      setTimeout(() => {
        mainContainer[0].style.opacity = "1"
        store.dispatch(changePluginLoaderVisibility(divId, false))
      }, 1000)
    })

    return {
      root: root,
      chart: chart,
      measure1: measure1,
      measure2: measure2,
      legend: legend,
      xAxis: xAxis
    }
  }

  componentDidMount() {
    let newState = { ...this.state }
    let renderedAgePyramid = this.renderAgePyramidChart(newState)

    this.setState({
      ...newState,
      root: renderedAgePyramid.root,
      chart: renderedAgePyramid.chart,
      measure1: renderedAgePyramid.measure1,
      measure2: renderedAgePyramid.measure2,
      legend: renderedAgePyramid.legend,
      xAxis: renderedAgePyramid.xAxis
    })
  }

  /*
  * Controls is other data available
  */
  controlIsOtherAvailable = () => {
    let isChartHasTwoSerie = this.chart?.series?._values?.length === 2 ? true : false
    let isChartHasOtherField = this.chart?.series?._values[0]?._data?.values[0]?.other !== undefined ? true : false
    let isOtherDataIsNumber = isNumber(+this.chart?.series?._values[0]?._data?.values[0]?.other)

    let isOtherAvailable = isChartHasTwoSerie && isChartHasOtherField && isOtherDataIsNumber

    if (isOtherAvailable) {
      return true
    }

    return false
  }

  /*
  * Calculates label positions
  */
  calculateLabelPositions = () => {
    let isPluginDefaultData = this.state.columnMap?.other?.data?.length === 0 && this.state.columnMap?.either?.data?.length === 0
    let isOtherAvailable = this.controlIsOtherAvailable()
    let pluginWidth = $("#" + this.props.plugin.id).width()
    var THIS = this

    for (let i = 0; i < this.chart.series._values[1].columns._values.length; i++) {
      let target = this.chart.series._values[1].columns._values[i]
      let width = target.width()
      let height = (target.parent.parent._contentHeight / this.chart.series._values[0].columns._values.length) + 20

      if (target.dataItem.bullets) {
        am5.array.each(target.dataItem.bullets, function (bullet) {
          let columnWidth = isOtherAvailable ? -width : width
          let columnHeight = height
          let textWidth = bullet._settings.sprite._contentWidth
          let textHeight = bullet._settings.sprite._contentHeight
          let maxWidth = isOtherAvailable || isPluginDefaultData ? ((pluginWidth - ((pluginWidth / 5))) / 2) : (pluginWidth - (pluginWidth / 5)) - 40
          let isOpacityMustBeZero = false
          let isOpacityZeroControl = (columnWidth < textWidth && columnWidth + textWidth + 10 > maxWidth) || columnHeight < textHeight ? true : false

          if (isOpacityZeroControl) {
            isOpacityMustBeZero = true
          }

          if (columnWidth >= 0) {
            if (columnWidth > textWidth) {
              if (!isOtherAvailable) {
                if (bullet.get("locationX") !== 0 || bullet.get("sprite").get("centerX")._value !== 0) {
                  bullet.set("locationX", 0);
                  bullet.get("sprite").set("centerX", am5.percent(0));
                }
              } else {
                if (bullet.get("locationX") !== 0 || bullet.get("sprite").get("centerX")._value !== 100) {
                  bullet.set("locationX", 0);
                  bullet.get("sprite").set("centerX", am5.percent(100));
                }
              }
            } else {
              if (!isOtherAvailable) {
                if (bullet.get("locationX") !== 1 || bullet.get("sprite").get("centerX")._value !== 0) {
                  bullet.set("locationX", 1);
                  bullet.get("sprite").set("centerX", am5.percent(0));
                }
              } else {
                if (bullet.get("locationX") !== 1 || bullet.get("sprite").get("centerX")._value !== 100) {
                  bullet.set("locationX", 1);
                  bullet.get("sprite").set("centerX", am5.p100);
                }
              }
            }
          } else {
            let newColumnWidth = -columnWidth

            if (isOtherAvailable) {
              bullet.set("locationX", 1);
              bullet.get("sprite").set("centerX", am5.p100);
            } else {
              bullet.set("locationX", 0);
              bullet.get("sprite").set("centerX", am5.p0);
            }
          }

          if (isOpacityMustBeZero) {
            if (bullet._settings.sprite.get("opacity") !== 0) {
              bullet._settings.sprite.set("opacity", 0)
            }
          } else {
            if (bullet._settings.sprite.get("opacity") !== 1) {
              bullet._settings.sprite.set("opacity", 1)
            }
          }
        });
      }
    }

    if (isOtherAvailable || isPluginDefaultData) {
      for (let i = 0; i < this.chart.series._values[0].columns._values.length; i++) {
        let target = this.chart.series._values[0].columns._values[i]
        let width = isPluginDefaultData ? -target.width() : target.width()
        let height = (target.parent.parent._contentHeight / this.chart.series._values[0].columns._values.length) + 20
        let THIS = this

        if (target.dataItem.bullets) {
          am5.array.each(target.dataItem.bullets, function (bullet) {
            let columnWidth = width
            let columnHeight = height
            let textWidth = bullet._settings.sprite._contentWidth
            let textHeight = bullet._settings.sprite._contentHeight
            let maxWidth = isOtherAvailable || isPluginDefaultData ? ((pluginWidth - ((pluginWidth / 5))) / 2) - 40 : (pluginWidth - (pluginWidth / 5)) - 40
            let isOpacityZeroControl = (columnWidth < textWidth && columnWidth + textWidth > maxWidth) || columnHeight < textHeight ? true : false
            let isOpacityMustBeZero = false

            if (isOpacityZeroControl) {
              isOpacityMustBeZero = true
            }

            if (columnWidth > 0) {
              if (columnWidth > textWidth) {
                if (isPluginDefaultData) {
                  if (bullet.get("locationX") !== 0 || bullet.get("sprite").get("centerX")._value !== 100) {
                    bullet.set("locationX", 0);
                    bullet.get("sprite").set("centerX", am5.percent(100));
                  }
                } else {
                  if (bullet.get("locationX") !== 0 || bullet.get("sprite").get("centerX")._value !== 0) {
                    bullet.set("locationX", 0);
                    bullet.get("sprite").set("centerX", am5.percent(0));
                  }
                }
              }
              else {
                if (bullet.get("locationX") !== 1 || bullet.get("sprite").get("centerX")._value !== 0) {
                  bullet.set("locationX", 1);
                  bullet.get("sprite").set("centerX", am5.percent(0));
                }
              }
            } else {
              let newColumnWidth = -columnWidth

              if (isOtherAvailable) {
                bullet.set("locationX", 0);
                bullet.get("sprite").set("centerX", am5.p0);
              } else {
                bullet.set("locationX", 0);
                bullet.get("sprite").set("centerX", am5.p0);
              }
            }

            if (isOpacityMustBeZero) {
              if (bullet._settings.sprite.get("opacity") !== 0) {
                bullet._settings.sprite.set("opacity", 0)
              }
            } else {
              if (bullet._settings.sprite.get("opacity") !== 1) {
                bullet._settings.sprite.set("opacity", 1)
              }
            }
          });
        }
      }
    }
  }

  /*
* Converts data to other for yAxis1
*/
  convertDataForEither = (data, newState) => {
    let newData = []
    let columnMap = this.props.plugin.columnMap
    let isOtherAvailable = newState.columnMap.other.data.length > 0 && !newState.columnMap.other.data[0]?.isDisabledColumn
    let coloursList = Array.isArray(this.props.plugin.config.colours) ? this.props.plugin.config.colours : InsightsConfig.Palettes[this.props.plugin.config.colours]
    let currCategory = this.props.plugin.drillDownColumnMap ? this.props.plugin.drillDownColumnMap.category : this.props.plugin.columnMap.category.data[0]
    let categorySet = new Set()
    let categorySetForRange = new Set()

    for (var i = 0; i < data.length; i++) {
      let newDataObj = {}
      let copiedEither = { ...this.props.plugin.columnMap.either.data[0] }

      if (this.props.plugin.columnMap.either.data[0] && copiedEither.DataFormat == undefined) {
        copiedEither.DataFormat = ",.2f"
      }

      if (!categorySet.has(data[i]["category"])) {
        newDataObj["either"] = isOtherAvailable ? -data[i]["either"] : data[i]["either"]
        newDataObj["category"] = data[i]["category"]
        newDataObj["formattedEither"] = copiedEither ? getFormattedValue(copiedEither, data[i]["either"]) : data[i]["either"]
        newDataObj["formattedCategory"] = getFormattedValue(currCategory, data[i]["category"])
        newDataObj[columnMap.either.data[0]?.displayName] = data[i]["either"]
        newDataObj[currCategory.displayName] = data[i]["category"]
        newDataObj[this.props.plugin.columnMap.category.data[0]?.displayName] = data[i]["category"]
        newDataObj["color"] = coloursList[1]
        newDataObj["fill"] = coloursList[0]
        newDataObj["name"] = columnMap.either.data[0]?.displayName

        if (data[i]["animation"]) {
          newDataObj["animation"] = data[i]["animation"]
          newDataObj[this.state.columnMap.animation.data[0].displayName] = data[i]["animation"]
        }

        if (isOtherAvailable) {
          newDataObj[newState.columnMap.other.data[0].displayName] = data[i]["other"]
          newDataObj[newState.columnMap.other.data[0].aliasName] = data[i]["other"]
        }

        categorySet.add(data[i]["category"])
        categorySetForRange.add(getFormattedValue(currCategory, data[i]["category"]))
        newData.push(newDataObj)
      } else {
        newDataObj = newData.find(d => d.category === data[i]["category"])

        newDataObj[columnMap.either.data[0]?.displayName] += data[i]["either"]
        newDataObj["formattedEither"] = copiedEither ? getFormattedValue(copiedEither, newDataObj[columnMap.either.data[0]?.displayName]) : newDataObj[columnMap.either.data[0]?.displayName]
        newDataObj["either"] = isOtherAvailable ? -(newDataObj[columnMap.either.data[0]?.displayName]) : newDataObj[columnMap.either.data[0].displayName]

        if (isOtherAvailable) {
          newDataObj[newState.columnMap.other.data[0].displayName] = data[i]["other"]
          newDataObj[newState.columnMap.other.data[0].aliasName] = data[i]["other"]
        }
      }
    }

    return newData
  }

  /*
  * Converts data to other for yAxis2
  */
  convertDataForOther = (data, newState) => {
    let newData = []
    let columnMap = this.props.plugin.columnMap
    let isOtherAvailable = newState.columnMap?.other?.data?.length > 0 && !newState.columnMap.other.data[0]?.isDisabledColumn
    let isEitherAvailable = newState.columnMap.either?.data?.length > 0 && !newState.columnMap.either?.data[0]?.isDisabledColumn
    let categorySet = new Set()
    let categorySetForRange = new Set()

    for (var i = 0; i < data.length; i++) {
      let newDataObj = {}
      let coloursList = Array.isArray(this.props.plugin.config.colours) ? this.props.plugin.config.colours : InsightsConfig.Palettes[this.props.plugin.config.colours]
      let currCategory = this.props.plugin.drillDownColumnMap ? this.props.plugin.drillDownColumnMap.category : this.props.plugin.columnMap.category.data[0]
      let copiedOther = { ...this.props.plugin.columnMap.other.data[0] }

      if (this.props.plugin.columnMap.other.data[0] && copiedOther.DataFormat == undefined) {
        copiedOther.DataFormat = ",.2f"
      }

      if (!categorySet.has(data[i]["category"])) {
        newDataObj["category"] = data[i]["category"]
        newDataObj["formattedCategory"] = getFormattedValue(currCategory, data[i]["category"])
        newDataObj[currCategory.displayName] = data[i]["category"]
        newDataObj[this.props.plugin.columnMap.category.data[0].displayName] = data[i]["category"]
        newDataObj["color"] = coloursList[0]
        newDataObj["fill"] = coloursList[0]

        if (data[i]["animation"]) {
          newDataObj["animation"] = data[i]["animation"]
          newDataObj[this.state.columnMap.animation.data[0].displayName] = data[i]["animation"]
        }

        if (isOtherAvailable) {
          newDataObj["formattedOther"] = this.props.plugin.columnMap?.other?.data[0] ? getFormattedValue(copiedOther, data[i]["other"]) : data[i]["other"]
          newDataObj[columnMap.other.data[0].displayName] = data[i]["other"]
          newDataObj["other"] = data[i]["other"]
          newDataObj["name"] = columnMap.other.data[0].displayName
        }

        if (isEitherAvailable) {
          newDataObj[newState.columnMap?.either?.data[0].displayName] = data[i]["either"]
          newDataObj[newState.columnMap?.either?.data[0].aliasName] = data[i]["either"]
        }

        categorySet.add(data[i]["category"])
        categorySetForRange.add(getFormattedValue(currCategory, data[i]["category"]))
        newData.push(newDataObj)
      } else {
        newDataObj = newData.find(d => d.category === data[i]["category"])

        if (isOtherAvailable) {
          newDataObj["formattedOther"] = this.props.plugin.columnMapForPlugin?.other ? getFormattedValue(copiedOther, data[i]["other"] + newDataObj["other"]) : data[i]["other"] + newDataObj["other"]
          newDataObj[columnMap.other.data[0].displayName] += data[i]["other"]
          newDataObj["other"] += data[i]["other"]
        }

        if (isEitherAvailable) {
          newDataObj[newState.columnMap?.either?.data[0].displayName] = data[i]["either"]
          newDataObj[newState.columnMap?.either?.data[0].aliasName] = data[i]["either"]
        }
      }
    }

    return newData
  }

  /*
  * Controls is config changed
  */
  isConfigChanged = (stateConfig, propsConfig) => {
    if (stateConfig.backgroundColor !== propsConfig.backgroundColor
      || stateConfig.changedTitleFontSize !== propsConfig.changedTitleFontSize
      || stateConfig.colours !== propsConfig.colours
      || stateConfig.keyForTitleSize !== propsConfig.keyForTitleSize
      || stateConfig.noDataTitle !== propsConfig.noDataTitle
      || stateConfig.showValuesOnBar !== propsConfig.showValuesOnBar
      || stateConfig.valuesOnBarFontSize !== propsConfig.valuesOnBarFontSize
      || stateConfig.valueFontColor !== propsConfig.valueFontColor
      || stateConfig.showRadarLegends !== propsConfig.showRadarLegends
      || stateConfig.ranges !== propsConfig.ranges
      || stateConfig.showRanges !== propsConfig.showRanges
      || stateConfig.showConditionalLegends !== propsConfig.showConditionalLegends
      || stateConfig.title !== propsConfig.title
      || stateConfig.showGrids !== propsConfig.showGrids
      || stateConfig.duration !== propsConfig.duration
      || stateConfig.animationColor !== propsConfig.animationColor
      || stateConfig.animationFontSize !== propsConfig.animationFontSize
      || stateConfig.animationTitleFontStyle !== propsConfig.animationTitleFontStyle
      || stateConfig.animationTitleFontWeight !== propsConfig.animationTitleFontWeight) {
      return true
    }

    return false
  }

  /*
  * Controls is columnMap format changed
  */
  isColumnMapFormatsChanged = prevProps => {
    if (this.props.plugin.columnMapForPlugin?.category?.DataFormat !== prevProps.plugin.columnMapForPlugin?.category?.DataFormat
      || this.props.plugin.columnMapForPlugin?.either?.DataFormat !== prevProps.plugin.columnMapForPlugin?.either?.DataFormat
      || this.props.plugin.columnMapForPlugin?.other?.DataFormat !== prevProps.plugin.columnMapForPlugin?.other?.DataFormat
      || this.props.plugin.columnMapForPlugin?.animation?.DataFormat !== prevProps.plugin.columnMapForPlugin?.animation?.DataFormat) {
      return true
    }

    return false
  }

  /*
  * Controls is columnMap changed
  */
  isColumnMapChanged = (stateColMap, propsColMap) => {
    if (!_.isEqual(stateColMap, propsColMap) && this.props.clickedRefresh) {
      return true
    }

    return false
  }

  /*
  * Created cond format legends
  */
  createConditionalFormatLegends = () => {
    let contrastColor = this.getContrastColor(am5.color(this.props.config.backgroundColor))
    let conditionalLegendRoot
    let conditionalLegend

    conditionalLegendRoot = this.parent.children.push(
      am5.Legend.new(this.root, {
        width: am5.percent(100),
        height: 30,
      })
    );

    conditionalLegend = conditionalLegendRoot.children.push(
      am5.Legend.new(this.root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        y: am5.percent(50),
        centerY: am5.percent(50),
        nameField: "name",
        fillField: "color",
        strokeField: "color",
        height: am5.percent(100),
        verticalScrollbar: am5.Scrollbar.new(this.root, {
          orientation: "vertical",
          maxWidth: 5,
        }),
      })
    );

    conditionalLegend.markerRectangles.template.setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusBR: 10,
      width: 12,
      height: 12,
    });

    conditionalLegend.labels.template.setAll({
      fontSize: 11,
      oversizedBehavior: "wrap",
      textAlign: "left",
      maxWidth: $("#" + this.props.plugin.id).width() - 100,
      fill: contrastColor
    });

    let mustConditionalShown = (this.props.plugin.h > 3 && this.props.plugin.w >= 6 && (this.props.plugin?.config?.showConditionalLegends || this.props.plugin?.config?.showConditionalLegends == undefined)) && this.props.plugin?.conditionalFormats.length > 0 ? true : false

    if (mustConditionalShown) {
      conditionalLegendRoot.show()
    } else {
      conditionalLegendRoot.hide()
    }

    this.conditionalFormatLegends = conditionalLegend
    this.conditionalLegendRoot = conditionalLegendRoot
  }

  /*
  * Controls and sets conditional format and other utils
  */
  asyncControlledFunction = (newState) => {
    let currCategory = this.props.plugin.drillDownColumnMap ? this.props.plugin.drillDownColumnMap.category : newState.columnMap.category.data[0]
    let columnMap = newState.columnMap
    let paletteColours = this.props.config?.colours
      ? InsightsConfig.Palettes[this.props.config?.colours]
        ? InsightsConfig.Palettes[this.props.config?.colours]
        : this.props.config?.colours
      : ["#67b7dc", "#6771dc", "#a367dc", "#dc67ce", "#dc6788", "#dc8c67"]
    let convertedColumnMap = convertColumnMapForConditionalFormat(columnMap)

    if (this.props.plugin.drillDownColumnMap) {
      convertedColumnMap.push(currCategory)
    }

    let condFormats = this.props.plugin.conditionalFormats && convertedColumnMap ? convertFormatConditionalFormatting(this.props.plugin.conditionalFormats, convertedColumnMap) : []
    let conditionalData = []
    let mustConditionalShow = this.conditionalFormatLegends && this.props.plugin?.conditionalFormats?.length > 0 && (this.state.config.showConditionalLegends || this.state.config.showConditionalLegends == undefined)

    if (!this.conditionalFormatLegends && this.props.plugin?.conditionalFormats?.length > 0) {
      this.createConditionalFormatLegends()
    } else if (this.conditionalLegendRoot && this.props.plugin?.conditionalFormats?.length === 0) {
      this.conditionalLegendRoot.hide()
    } else if (mustConditionalShow) {
      this.conditionalLegendRoot.show()
    }

    for (let i = 0; i < newState.chart.series._values[0].columns._values.length; i++) {
      let isConditionalColourChange = false

      for (let j = 0; j < condFormats.length; j++) {
        let data = newState.chart.series._values[0].columns._values[i]._dataItem.dataContext
        let comparedColumn = compare(data, condFormats[j])
        let isTargetOther = columnMap.other.data[0] && (condFormats[j].TargetName === columnMap.other.data[0].aliasName || condFormats[j].TargetName === "AllColumns")

        if (comparedColumn.status && isTargetOther) {
          newState.chart.series._values[0].columns._values[i].set("fill", condFormats[j].Style.background.colour)

          isConditionalColourChange = true
        }
      }

      if (!isConditionalColourChange) {
        newState.chart.series._values[0].columns._values[i].set("fill", paletteColours[1])
      }
    }

    for (let i = 0; i < newState.chart.series._values[1].columns._values.length; i++) {
      let isConditionalColourChange = false

      for (let j = 0; j < condFormats.length; j++) {
        let data = newState.chart.series._values[1].columns._values[i]._dataItem.dataContext
        let comparedColumn = compare(data, condFormats[j])
        let isTargetEither = condFormats[j].TargetName === columnMap.either.data[0]?.aliasName || condFormats[j].TargetName === "AllColumns"

        if (comparedColumn.status && isTargetEither) {
          newState.chart.series._values[1].columns._values[i].set("fill", condFormats[j].Style.background.colour)

          isConditionalColourChange = true
        }
      }

      if (!isConditionalColourChange) {
        newState.chart.series._values[1].columns._values[i].set("fill", paletteColours[0])
      }
    }

    for (let i = 0; i < condFormats.length; i++) {
      let perConditional = condFormats[i]
      let newStr = ""

      if (!condFormats[i].ConditionalFormatRule) {
        let original = " " + perConditional.LeftRuleColumnName + " " + perConditional.Operator + " " + perConditional.RightRuleColumnName + "";
        let decrypted = /[{()}]/g;

        newStr = original.replace(decrypted, '');
      } else {
        newStr = condFormats[i].ConditionalFormatRule
      }

      let perConditionalData = {
        name: newStr,
        color: perConditional.Style.background.colour
      }

      conditionalData.push(perConditionalData)
    }

    if (this.conditionalFormatLegends) {
      this.conditionalFormatLegends.data.setAll(conditionalData)
    }
  }

  /*
  * All chart update processes
  */
  componentDidUpdate(prevProps, prevState) {
    if (this.props.plugin?.errors?.size > 0 || this.state.data.length === 0) {
      $("#" + this.props.plugin.id).css("height", "0%").css("overflow", "hidden")
      $("#plugin-" + this.props.plugin.id).css("overflow", "hidden")

      this.parent.hide()
    } else if (this.parent.isHiding() || this.parent.isHidden()) {
      let isDrilldowned = this.props.plugin.drillDownColumnMap ? true : false
      let inlineHeight = calculatePluginInlineHeight(this.props.plugin.id)
      let height = isDrilldowned ? inlineHeight - 4 : inlineHeight

      $("#" + this.props.plugin.id).css("height", height + "px").css("overflow", "auto")
      $("#plugin-" + this.props.plugin.id).css("overflow", "auto")

      this.parent.show()
    }

    let newState = { ...this.state };
    let flag = false;
    let divId = this.props.plugin.id
    let isColumnMapChanged = false
    let isDataChanged = false
    let convertedDataForEither = this.state.convertedDataForEither
    let convertedDataForOther = this.state.convertedDataForOther
    let pluginH = this.props.plugin.h
    let pluginW = this.props.plugin.w
    let isPluginWChanged = pluginH !== this.state.pluginH
    let isPluginHChanged = pluginW !== this.state.pluginW
    let isPluginResized = isPluginHChanged || isPluginWChanged
    let pluginWidth = $("#" + this.props.plugin.id).width()
    let isColoursChanged = false
    let isOtherAvailableProps = this.props.plugin.columnMap.other.data.length > 0 && !this.props.plugin.columnMap.other.data[0]?.isDisabledColumn

    if (isPluginResized) {
      this.root.resize()

      newState.pluginH = this.props.plugin.h
      newState.pluginW = this.props.plugin.w

      if (this.conditionalLegendRoot) {
        if ((this.props.plugin.h > 3 && this.props.plugin.w >= 6) && this.props.plugin.config.showConditionalLegends && this.props.plugin?.conditionalFormats.length > 0) {
          this.conditionalLegendRoot.show()
        } else {
          this.conditionalLegendRoot.hide()
        }

        this.conditionalFormatLegends.get("verticalScrollbar").set("start", 0)
        this.legend.get("verticalScrollbar").set("start", 0)
      }

      this.yAxis1.get("renderer").labels.template.setAll({
        oversizedBehavior: "truncate",
        maxWidth: pluginWidth / 5,
      });

      this.legend.labels.template.setAll({
        maxWidth: pluginWidth - 200,
      });

      if (this.conditionalFormatLegends) {
        this.conditionalFormatLegends.labels.template.setAll({
          fontSize: 11,
          oversizedBehavior: "wrap",
          textAlign: "left",
          maxWidth: pluginWidth - 200
        });
      }

      if (this.props.plugin.h <= 3) {
        this.animationSliderContainer.hide()
      } else {
        if (newState.columnMap?.animation?.data?.length > 0 && !newState.columnMap?.animation?.data[0]?.isDisabledColumn) {
          this.animationSliderContainer.show()
        }
      }
    }

    if (this.isColumnMapChanged(this.state.columnMap, this.props.plugin.columnMap)) {
      newState.columnMap = deepCopy(this.props.plugin.columnMap);

      isColumnMapChanged = true

      if (newState.columnMap.animation?.data?.length === 0 || newState.columnMap?.animation?.data[0]?.isDisabledColumn) {
        this.animationSliderContainer.hide()
        this.animationText.hide()

        this.animationText.setAll({
          text: ""
        })
      } else if (newState.columnMap?.animation?.data?.length > 0 && !newState.columnMap?.animation?.data[0]?.isDisabledColumn) {
        this.animationSliderContainer.show()
        this.animationText.show()

        let pluginWidth = $("#plugin-" + this.props.plugin.id).width()

        this.animationText.setAll({
          height: this.props.plugin?.config.animationFontSize ? +this.props.plugin?.config.animationFontSize : 24,
          fontSize: this.props.plugin?.config.animationFontSize ? +this.props.plugin?.config.animationFontSize : 24,
          fontWeight: "500",
          textAlign: "center",
          fill: this.props.plugin?.config?.animationColor ? this.props.plugin?.config?.animationColor : "#585858",
          x: am5.percent(50),
          centerX: am5.percent(50),
          maxWidth: pluginWidth - 50,
          oversizedBehavior: "truncate",
          fontWeight: this.props.plugin?.config?.animationTitleFontWeight === true ? "bold" : "normal",
          fontStyle: this.props.plugin?.config?.animationTitleFontStyle === true ? "italic" : "normal",
        });
      }

      this.root.numberFormatter.setAll({
        numberFormat: this.state.columnMap?.either.data.length > 0 ? this.getFormat(this.state.columnMap.either.data[0]).format + "s" : "#.##s"
      });
    }

    let isJustColorChanged = prevProps.plugin?.config?.colours !== this.state.config?.colours

    if (this.props.config && this.isConfigChanged(this.state.config, this.props.plugin.config) || isPluginResized || this.isConfigChanged(prevProps.plugin?.config, this.state.config)) {
      let root = this.root
      let props = this.props
      let isDataDefaultData = (this.state.columnMap
        && this.state.columnMap?.category.data.length === 0
        && this.state.columnMap?.either.data.length === 0
        && this.state.columnMap?.other.data.length === 0)
      let contrastColor = this.getContrastColor(am5.color(this.props.config.backgroundColor))

      if (this.props.config.backgroundColor !== this.state.config.backgroundColor || prevProps.config.backgroundColor !== this.state.config.backgroundColor) {
        $("#" + divId).css("background", this.props.config.backgroundColor)

        newState.config.backgroundColor = this.props.config.backgroundColor

        this.yAxis1.get("renderer").labels.template.setAll({
          fill: contrastColor
        });

        this.yAxis2.get("renderer").labels.template.setAll({
          fill: contrastColor
        });

        this.xAxis.get("renderer").labels.template.setAll({
          fill: contrastColor
        });

        if (this.conditionalFormatLegends) {
          this.conditionalFormatLegends.labels.template.setAll({
            fill: contrastColor
          });
        }

        if (this.legend) {
          this.legend.labels.template.setAll({
            fill: contrastColor
          });
        }

        this.getXAndYAxesStyle(this.xAxis, this.yAxis1, this.yAxis2, contrastColor)
        this.setTheme(newState.config, contrastColor)
      }

      if (this.props.config.showGrids !== this.state.config.showGrids) {
        newState.config.showGrids = this.props.config.showGrids

        this.getXAndYAxesStyle(this.xAxis, this.yAxis1, this.yAxis2, contrastColor)
        this.setTheme(newState.config, contrastColor)
      }

      this.exporting.setAll({
        title: `${this.props.title}`,
        filePrefix: `${this.props.title}`,
      })

      if (this.conditionalLegendRoot) {
        if (this.props.config.showConditionalLegends === false) {
          this.conditionalLegendRoot.hide()
        } else if (this.props.plugin.h > 3 && this.props.plugin.w >= 6 && this.props.plugin?.conditionalFormats.length > 0) {
          this.conditionalLegendRoot.show()
        }
      }

      if (newState.columnMap?.animation?.data?.length > 0 && !newState.columnMap?.animation?.data[0].isDisabledColumn) {
        let pluginWidth = $("#plugin-" + this.props.plugin.id).width()

        this.animationText.setAll({
          height: this.props.plugin?.config.animationFontSize ? +this.props.plugin?.config.animationFontSize : 24,
          fontSize: this.props.plugin?.config.animationFontSize ? +this.props.plugin?.config.animationFontSize : 24,
          fontWeight: "500",
          textAlign: "center",
          fill: this.props.plugin?.config?.animationColor ? this.props.plugin?.config?.animationColor : "#585858",
          x: am5.percent(50),
          centerX: am5.percent(50),
          maxWidth: pluginWidth - 50,
          oversizedBehavior: "truncate",
          fontWeight: this.props.plugin?.config?.animationTitleFontWeight === true ? "bold" : "normal",
          fontStyle: this.props.plugin?.config?.animationTitleFontStyle === true ? "italic" : "normal",
        });
      }

      if (this.props.config.colours !== this.state.config.colours || isJustColorChanged) {
        let coloursList = Array.isArray(this.props.plugin.config.colours) ? this.props.plugin.config.colours : InsightsConfig.Palettes[this.props.plugin.config.colours]

        isColoursChanged = true

        for (let i = 0; i < this.chart.series._values[0].columns._values.length; i++) {
          this.chart.series._values[0].columns._values[i].set("fill", coloursList[1])
        }

        for (let i = 0; i < this.chart.series._values[1].columns._values.length; i++) {
          this.chart.series._values[1].columns._values[i].set("fill", coloursList[0])
        }

        if ((this.props.plugin.config.showRadarLegends || this.props.plugin.config.showRadarLegends == undefined) && (this.props.plugin.h > 3 && this.props.plugin.w >= 6)) {
          let newLegendData = []

          if (this.state.columnMap.other.data.length > 0 && !this.state.columnMap.other.data[0].isDisabledColumn) {
            newLegendData.push({
              name: this.state.columnMap.other.data[0]?.displayName,
              color: coloursList[1],
              field: "other"
            })
          } else if (isDataDefaultData) {
            newLegendData.push({
              name: "Postatoplam",
              color: coloursList[1],
              field: "other"
            })
          }

          if (!isDataDefaultData) {
            newLegendData.push({
              name: this.state.columnMap.either.data[0]?.displayName,
              color: coloursList[0],
              field: "either"
            })
          } else {
            newLegendData.push({
              name: "Kargotoplam",
              color: coloursList[0],
              field: "either"
            })
          }

          this.state.legend.data.setAll(newLegendData);

          newState.config.showRadarLegends = true
          flag = true

          this.state.legend.show()
        } else {
          this.state.legend.hide()

          flag = true
        }
      }

      if ((this.props.plugin.config.showRadarLegends || this.props.plugin.config.showRadarLegends == undefined) && (this.props.plugin.h > 3 && this.props.plugin.w >= 6)) {
        let coloursList = Array.isArray(this.props.plugin.config.colours) ? this.props.plugin.config.colours : InsightsConfig.Palettes[this.props.plugin.config.colours]
        let newLegendData = []
        let isPluginDefaultData = this.state.columnMap.other.data.length === 0 && this.state.columnMap.either.data.length === 0

        this.state.legend.show()

        if (!isPluginDefaultData) {
          if (this.state.columnMap.other.data.length > 0 && !this.state.columnMap.other.data[0].isDisabledColumn) {
            newLegendData.push({
              name: this.state.columnMap.other.data[0]?.displayName,
              color: coloursList[1],
              field: "other"
            })
          }

          newLegendData.push({
            name: this.state.columnMap.either.data[0]?.displayName,
            color: coloursList[0],
            field: "either"
          })

          this.state.legend.data.setAll(newLegendData);
        } else {
          newLegendData.push({
            name: "Kargotoplam",
            color: coloursList[1],
            field: "other"
          })

          newLegendData.push({
            name: "Postatoplam",
            color: coloursList[0],
            field: "either"
          })

          this.state.legend.data.setAll(newLegendData);
        }

        this.state.legend.show()
        newState.config.showRadarLegends = true
        flag = true
      } else {
        this.state.legend.hide()
        flag = true
      }

      if (this.props.config.showValuesOnBar || this.props.config.showValuesOnBar == undefined) {
        if (this.props.config.valuesOnBarFontSize !== this.state.config.valuesOnBarFontSize || this.props.config.valueFontColor !== this.state.config.valueFontColor || this.props.config.showValuesOnBar !== this.state.config.showValuesOnBar || isPluginResized) {
          this.chart.series._values[0].bullets.clear()
          this.chart.series._values[1].bullets.clear()
          this.chart.series._values[0].bullets.push(function () {
            return am5.Bullet.new(root, {
              sprite: am5.Label.new(root, {
                centerX: am5.p50,
                x: am5.p50,
                centerY: am5.p50,
                text: "{formattedOther}",
                fill: props.config.valueFontColor ? am5.color(props.config?.valueFontColor) : "black",
                fontSize: props.config.valuesOnBarFontSize !== undefined ? props.config.valuesOnBarFontSize == 0 ? 1 : props.config.valuesOnBarFontSize : 12,
                populateText: true,
                opacity: 0
              })
            });
          });

          this.chart.series._values[1].bullets.push(function () {
            return am5.Bullet.new(root, {
              sprite: am5.Label.new(root, {
                centerX: am5.p50,
                x: am5.p50,
                centerY: am5.p50,
                text: "{formattedEither}",
                fill: props.config.valueFontColor ? am5.color(props.config?.valueFontColor) : "black",
                fontSize: props.config.valuesOnBarFontSize !== undefined ? props.config.valuesOnBarFontSize == 0 ? 1 : props.config.valuesOnBarFontSize : 12,
                populateText: true,
                opacity: 0
              })
            });
          });

          setTimeout(() => this.calculateLabelPositions(), 10)

          newState.config.valuesOnBarFontSize = this.props.plugin.config.valuesOnBarFontSize
          flag = true
        }
      } else if (!this.props.config.showValuesOnBar && this.props.config.showValuesOnBar !== undefined && this.props.config.showValuesOnBar !== this.state.config.showValuesOnBar) {
        this.chart.series._values[0].bullets.clear()
        this.chart.series._values[1].bullets.clear()
      }

      newState.config = this.props.config

      flag = true
    }

    let isDataOrColumnMapChanged = (this.props.plugin?.data && !_.isEqual(this.props.plugin?.data, this.state.data))
      || (this.state.columnMap && !_.isEqual(this.state.columnMap, this.props.plugin.columnMap) && this.props.clickedRefresh)
    let isColumnMapValid = newState.columnMap.category.data[0] && newState.columnMap.either.data[0] ? true : false

    if ((isDataOrColumnMapChanged && isColumnMapValid)) {
      let chart = this.state.chart
      let newCategorySet = new Set()
      let categorySetForRange = new Set()
      let formattedCategorySet = new Set()
      let pureCategoryHash = new Map()

      newState.data = this.props.plugin?.data
        ? this.props.plugin?.data
        : this.state.data

      let usedData = []

      if (this.props.plugin?.columnMap?.animation?.data?.length > 0) {
        let convertedDataForAnimation = this.findAnimatedDatas(newState.data)
        let animationValues = Array.from(convertedDataForAnimation.values())

        usedData = animationValues[0]

        let isAnimationValuesEnable = this.state.columnMap?.animation?.data[0] && animationValues && animationValues[0] && animationValues[0][0]?.animation

        if (isAnimationValuesEnable) {
          let isAnimationFormatValueEmptyAndDouble = newState.columnMap?.animation?.data[0].DataFormat == undefined && (newState.columnMap?.animation?.data[0].dataType === "double")
          let isAnimationFormatValueEmptyAndInteger = newState.columnMap?.animation?.data[0].DataFormat == undefined && (newState.columnMap?.animation?.data[0].dataType === "integer")
          let copiedAnimationColumn = deepCopy(newState.columnMap.animation.data[0])

          if (isAnimationFormatValueEmptyAndDouble) {
            copiedAnimationColumn.DataFormat = ",.2f"
            copiedAnimationColumn.DataFormatType = "number"
            copiedAnimationColumn.DataFormatTypeSubTitle = "decimal"
            copiedAnimationColumn.DecimalPlaces = "2"
          } else if (isAnimationFormatValueEmptyAndInteger) {
            copiedAnimationColumn.DataFormat = ".f"
            copiedAnimationColumn.DataFormatType = "number"
            copiedAnimationColumn.DataFormatTypeSubTitle = "wholeNumber"
            copiedAnimationColumn.DecimalPlaces = "0"
          }

          let convertedValue = getFormattedValue(copiedAnimationColumn, animationValues[0][0]?.animation, undefined, false)
          let controlledValue = convertedValue === NaN || convertedValue === "NaN" ? "" : convertedValue

          this.animationText.setAll({
            text: controlledValue
          })
        }
      } else {
        usedData = newState.data
      }

      if (usedData == undefined) {
        usedData = newState.data
      }

      convertedDataForOther = this.convertDataForOther(usedData, newState)
      convertedDataForEither = this.convertDataForEither(usedData, newState)

      if (chart.yAxes._values.length > 0) {
        chart.yAxes._values[0].data.setAll(convertedDataForOther)
        chart.yAxes._values[1].data.setAll(convertedDataForEither)
      }

      if (chart.series._values.length > 0) {
        chart.series._values[0].data.setAll(convertedDataForOther)
        chart.series._values[1].data.setAll(convertedDataForEither)
      }

      convertedDataForEither.map(data => {
        newCategorySet.add(data.category)
        categorySetForRange.add(data.formattedCategory)
        formattedCategorySet.add(data.formattedCategory)
        pureCategoryHash.set(data.formattedCategory, data.category)
      })

      let biggestCategoryWidth = 0
      let chartCategoryWidth = 0

      for (let i = 0; i < convertedDataForEither.length; i++) {
        let perData = convertedDataForEither[i]

        if (perData.category?.length > biggestCategoryWidth) {
          biggestCategoryWidth = perData.category.length
          chartCategoryWidth = biggestCategoryWidth * 12
        }
      }

      if (chartCategoryWidth > pluginWidth / 5) {
        this.yAxis1.get("renderer").labels.template.setAll({
          oversizedBehavior: "truncate",
          maxWidth: chartCategoryWidth,
        });
      } else if (this.yAxis1.get("renderer")?.labels?.template) {
        this.yAxis1.get("renderer").labels?.template.setAll({ // eslint-disable-line
          oversizedBehavior: "truncate",
          maxWidth: pluginWidth / 5,
        });
      }

      newState["categorySet"] = newCategorySet
      newState["categorySetForRange"] = categorySetForRange
      newState["formattedCategorySet"] = formattedCategorySet
      newState["chart"] = chart
      newState["convertedDataForEither"] = convertedDataForEither
      newState["convertedDataForOther"] = convertedDataForOther

      let maxValue = 0
      let maxValueHash = new Map()

      if (usedData?.length > 0) {
        for (let i = 0; i < usedData.length; i++) {
          let data = usedData[i]

          if (!maxValueHash.has(data.category)) {
            maxValueHash.set(data.category, {
              either: data.either,
              other: data.other
            })
          } else {
            let gettedData = maxValueHash.get(data.category)
            let gettedEither = gettedData.either
            let gettedOther = gettedData.other

            let newEither = gettedEither + data.either
            let newOther = gettedOther + data.other

            maxValueHash.set(data.category, {
              either: newEither,
              other: newOther
            })
          }

          let gettedData = maxValueHash.get(data.category)

          if (maxValue < gettedData.either) {
            maxValue = gettedData.either
          }

          if (maxValue < gettedData.other) {
            maxValue = gettedData.other
          }
        }

        if (isOtherAvailableProps) {
          this.xAxis.setAll({
            min: -maxValue,
            max: maxValue
          })
        } else {
          this.xAxis.setAll({
            min: 0,
            max: maxValue
          })
        }
      }

      if (isDataOrColumnMapChanged) {
        setTimeout(() => this.calculateLabelPositions(), 10)
      }

      flag = true;
      isDataChanged = true

      this.slider.set("start", 0)
    }

    if (this.isColumnMapChanged(this.state.columnMap, this.props.plugin.columnMap)) {
      if (this.props.plugin.config && this.props.plugin.config?.showRadarLegends == undefined || this.props.plugin.config?.showRadarLegends) {
        let coloursList = Array.isArray(this.props.plugin.config.colours) ? this.props.plugin.config.colours : InsightsConfig.Palettes[this.props.plugin.config.colours]
        let newLegendData = []

        if (this.props.plugin.columnMap.other?.data?.length > 0 && !this.state.columnMap.other.data[0]?.isDisabledColumn) {
          newLegendData.push({
            name: this.props.plugin.columnMap.other.data[0]?.displayName,
            color: coloursList[1],
            field: "other"
          })
        }

        newLegendData.push({
          name: this.props.plugin.columnMap.either.data[0]?.displayName,
          color: coloursList[0],
          field: "either"
        })

        this.state.legend.data.setAll(newLegendData);

        newState.config.showRadarLegends = true
      }
    }

    let checkRangesHasChange = this.state.config.ranges !== this.props.plugin.config.ranges
    let isCategorySetChange = newState.formattedCategorySet !== this.state.formattedCategorySet && this.state.formattedCategorySet.size !== 0
    let isFirstRangeRender = this.yAxis1?.axisRanges?._values.length === 0 && this.props.plugin?.config?.ranges?.length > 0 && this.props.plugin?.config?.showRanges
    let isRangeStatusFalse = prevProps.plugin?.config?.showRanges !== this.props.plugin?.config?.showRanges
    let isAnimationIndexChanged = this.state.animationIndex !== prevState.animationIndex

    let mustRangeCalculateAgain = checkRangesHasChange || isFirstRangeRender || isCategorySetChange || isRangeStatusFalse || isAnimationIndexChanged

    if (mustRangeCalculateAgain) {
      for (let i = 0; i < this.yAxis1.axisRanges._values.length; i++) {
        let range = this.yAxis1.axisRanges._values[i]

        range.dispose()
      }

      if (this.props.plugin?.config?.showRanges) {
        for (let i = 0; i < this.props.plugin.config?.ranges.length; i++) {
          let createdRange = this.props.plugin.config?.ranges[i]

          if (createdRange.startCategory && createdRange.endCategory && !createdRange.error) {
            let category = getFormattedValue(this.props.plugin.columnMap.category.data[0], createdRange.startCategory)
            let endCategory = getFormattedValue(this.props.plugin.columnMap.category.data[0], createdRange.endCategory)
            let allRanges = []
            let isAnimationAvailable = this.state.columnMap.animation?.data?.length > 0 && !this.state.columnMap.animation?.data[0]?.isDisabledColumn
            let categorySet = isAnimationAvailable
              ? newState.categorySetForRange
                ? newState.categorySetForRange
                : this.state.categorySetForRange
              : newState.categorySet
                ? newState.categorySet
                : this.state.categorySet
            let hasStartCategoryHasAnyRange = (categorySet.has(category) || categorySet.has(+category))
            let hasEndCategoryHasAnyRange = (categorySet.has(endCategory) || categorySet.has(+endCategory))
            let isValuesNull = category == null && endCategory == null

            if (hasStartCategoryHasAnyRange && hasEndCategoryHasAnyRange && !isValuesNull) {
              let range = this.yAxis1.createAxisRange(
                this.yAxis1.makeDataItem(
                  {
                    category: category,
                    endCategory: endCategory,
                    above: false,
                  }));

              range.get("axisFill").setAll({
                visible: createdRange.visible,
                fill: am5.color(createdRange.fill),
                fillOpacity: createdRange.fillOpacity,
                stroke: createdRange.fill,
                strokeOpacity: 1,
              })

              range.get("label").setAll({
                text: ""
              })

              allRanges.push(range)
            }
          }
        }
      }

      if (newState.config.ranges !== this.props.plugin.config.ranges) {
        newState.config.ranges = this.props.plugin.config.ranges
        flag = true
      }
    }

    let isAnyColumnChangedOrReplaced = !_.isEqual(this.state.columnMap, this.props.plugin.columnMap) && this.props.clickedRefresh
    let isConditionalRestarted = this.props.plugin.conditionalFormats !== this.state.conditionalFormats || isDataChanged || isColoursChanged || isAnyColumnChangedOrReplaced

    if (isConditionalRestarted) {
      newState["conditionalFormats"] = this.props.plugin.conditionalFormats

      flag = true

      setTimeout(this.asyncControlledFunction, 5, newState)
    }

    if (flag || isColumnMapChanged) {
      this.setState(newState);

      let tempPlugin = { ...this.props.plugin };

      tempPlugin["rerender"] = false;
    }
  }

  /**
   * Updates state attributes in AgePyramid plugin
   */
  setConfigToPlugin = (changedObj, propertyListToDelete = undefined) => {
    let tempConfig = { ...this.state.config };

    if (Array.isArray(changedObj)) {
      for (let i = 0; i < changedObj.length; i++) {
        let object = changedObj[i];
        tempConfig[object.type] = object.value;
      }
    } else {
      tempConfig[changedObj.type] = changedObj.value;
    }

    if (propertyListToDelete !== undefined) {
      for (let item of propertyListToDelete) {
        delete tempConfig[item]
      }
    }

    this.props.updateConfig(
      tempConfig,
      this.props.pluginId,
      propertyListToDelete
    );
  };

  getDataComponent = props => {
    let columnMap = { ...this.props.plugin.columnMap }

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }

    if (!columnMap["animation"]) {
      columnMap["animation"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.animation.Desc`,
        minimumColumnSize: 0,
        multiple: false,
        type: "animation",
        name: `Plugins.${props.plugin.key}.ColumnMap.animation.Name`,
      }
    }

    return (
      <AgePyramidData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        didNotJoinedTables={checkTableJoins(this.props.join, this.state.columnMap, this.props.refreshedPluginId, this.props.plugin.id, true)}
        setInteractions={this.props.setInteractions}
        interactions={this.props.interactions}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={props.changeDoesPluginHasNotJoinedTable}
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        navigations={props.navigations}
        plugin={props.plugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  // Returns config with title
  getConfigWithTitle = (config) => {
    let copiedConfig = { ...config }

    copiedConfig.title = this.props.config?.title
    copiedConfig.titleAlign = this.props.config?.titleAlign //added title align props
    copiedConfig.titleFontStyle = this.props.config?.titleFontStyle === undefined ? false : this.props.config?.titleFontStyle
    copiedConfig.titleFontWeight = this.props.config?.titleFontWeight === undefined ? false : this.props.config?.titleFontWeight
    copiedConfig.titleFontSize = this.props.config?.titleFontSize === undefined ? 15 : this.props.config?.titleFontSize
    copiedConfig.titleFont = this.props.config?.titleFont === undefined ? "Verdana" : this.props.config?.titleFont
    copiedConfig.titleColour = this.props.config?.titleColour === undefined ? "black" : this.props.config?.titleColour
    copiedConfig.titleTextDecor = this.props.config?.titleTextDecor === undefined ? false : this.props.config?.titleTextDecor
    copiedConfig.changedTitleFontSize = this.props.config?.changedTitleFontSize === undefined ? 15 : this.props.config?.changedTitleFontSize

    return copiedConfig
  }

  getConfigComponent = props => {
    if (this.state.config) {
      return (
        <AgePyramidConfiguration
          config={this.getConfigWithTitle(this.state.config)}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
          currentAppliedConfig={this.props.currentAppliedConfig}
          categorySet={this.state.categorySet}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  getConditionalFormattingComponent = props => {
    let columnMap = props.plugin.columnMap

    return (
      <ConditionalFormatting
        pluginConditionalFormatOptions={pluginConditionalFormatOptions}
        conditionalFormatColumnMap={conditionalFormatColumnMap}
        conditionalFormatTargetMap={conditionalFormatTargetMap}
        pluginId={props.plugin.id}
        conditionalFormats={props.plugin.conditionalFormats}
        columnMap={columnMap}
        updateConditionalFormat={props.updatePlugin}
        isNecessaryAllColumns={true}
        defaultConditionalFormatColumn={this.state.defaultConditionalFormatColumn}
        isLockedTargetValue={false}

      />
    );
  };

  getNavigationComponent = props => {
    return (
      <NavigationContent
        navigations={this.props.navigations}
        setNavigations={this.props.updatePlugin}
        plugin={this.props.plugin}
        dashboardInformation={this.props.dashboardInformation}
      />
    );
  };

  render() {
    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let conditionalFormatComponent = null;
    if (this.props.conditionalFormattingVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      conditionalFormatComponent = renderConditionalFormatting(
        popupPosition,
        this.props,
        this.getConditionalFormattingComponent
      );
    }

    let navigationComponent = null;
    if (this.props.navigationComponentVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      navigationComponent = renderNavigation(
        popupPosition,
        this.props,
        this.getNavigationComponent
      );
    }

    let isDrilldowned = this.props.plugin.drillDownColumnMap ? true : false
    let inlineHeight = calculatePluginInlineHeight(this.props.plugin.id)
    let height = isDrilldowned ? inlineHeight - 4 : inlineHeight
    let controlIsNoData = this.isPluginRender(this.props.plugin, this.props.plugin.config)

    let shouldAnimationBePlayed = this.props.isDashboardSliderActive === true && this.props.isDashboardSliderPlaying === true && this.playButton?.get("active") === false;
    let shouldAnimationBePaused = this.props.isDashboardSliderActive === true && this.props.isDashboardSliderPlaying === false && this.playButton?.get("active") === true;
    let shouldAnimationBeRewinded = this.props.isDashboardSliderActive === true && this.props.isDashboardSliderBackwarded === true;

    if (this.startAnimation && shouldAnimationBePlayed) {
      this.startAnimation(1000);
    } else if (this.stopAnimation && shouldAnimationBePaused) {
      this.stopAnimation();
    } else if (this.rewindAnimation && shouldAnimationBeRewinded) {
      this.rewindAnimation();
    }

    return (
      <>
        {!controlIsNoData.status ?
          <NoDataContent
            plugin={this.props.plugin}
            content={controlIsNoData.errorMessage}
            pluginImage={this.props.plugin.image} /> : null}
        <div id={this.props.plugin.id} style={{ width: "100%", height: height, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "0px 0px 4px 4px" }}>
        </div>
        {dataComponent}
        {configComponent}
        {navigationComponent}
        {conditionalFormatComponent}
      </>
    );
  }
}