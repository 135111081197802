import React from "react";
import { Layout } from "antd";
import Logo from "../Logo/Logo.jsx";
import Navigation from "../Navigation/Navigation.jsx";

const { Sider } = Layout;

class SideBar extends React.Component {
  state = {
    collapsible: false,
    collapsed: false,
    sideBarMenuItems: [
      {
        text: "Homepage",
        icon: "home",
        path: `/admin/`
      },
      {
        text: "Modelling",
        icon: "pie-chart",
        path: `/admin/modelling`
      },
      {
        text: "Settings",
        icon: "setting",
        path: `/admin/settings`
      },
      {
        text: "Users",
        icon: "user",
        path: `/admin/users`
      },
      {
        text: "User Roles",
        icon: "user",
        path: `/admin/user-roles`
      },
      {
        text: "Assign Roles",
        icon: "shrink",
        path: `/admin/assign-roles`
      },
      {
        text: "Catalog Object Auth.",
        icon: "shrink",
        path: `/admin/catalog-object-authorization`
      },
      {
        text: "Authorization",
        icon: "file-protect",
        path: `/admin/authorization`
      }
    ]
  };

  onCollapse = collapsed => {
    this.setState({
      ...this.state,
      collapsed
    });
  };

  onBreakpoint = collapsible => {
    this.setState({
      ...this.state,
      collapsible
    });
  };

  handleChange = selectedItem => {
    this.setState({
      ...this.state,
      selectedItem
    });
  };

  render() {
    const {
      collapsible,
      collapsed,
      onCollapse,
      sideBarMenuItems,
      selectedItem
    } = this.state;

    return (
      <Sider
        breakpoint="sm"
        onBreakpoint={broken => this.onBreakpoint(broken)}
        collapsible={collapsible}
        collapsedWidth={collapsed && 0}
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        <Logo />
        <Navigation
          menuItems={sideBarMenuItems}
          selectedItem={selectedItem}
          onChange={this.handleChange}
        />
      </Sider>
    );
  }
}

export default SideBar;
