import { ExclamationCircleOutlined } from "@ant-design/icons";
import React, { Component } from "react";
import { connect } from "react-redux";
import { showNotificationWithIcon } from "../../admin/Utils/Notification";
import { preparePluginsContainerAfterPluginError, preparePluginsContainerBeforePluginError } from "../DrillDown/PluginHeightWithDrilldown";
import { store } from "../..";
import { changePluginLoaderVisibility } from "../GeneralComponents/PluginLoader/PluginLoaderAction";

/**
 * Shows plugin error icon and notification
 */
class PluginError extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pluginImage: ""
    };
  }

  /**
   * @errors map
   * show notification for errors.
   */
  showErrors = errors => {
    let errorIterator = errors.keys();

    for (let i = 0; i < errors.size; i++) {
      let key = errorIterator.next().value;
      showNotificationWithIcon(
        errors.get(key).message,
        null,
        errors.get(key).type
      );
    }
  };

  /**
   *
   * @returns
   *
   * check for error status
   */
  hasError = () => {
    return (
      this.props.errors &&
      (this.props.errors.size > 0 || this.props.errors.length > 0)
    );
  };

  render() {
    let hasError = this.hasError();

    if (hasError) {
      if (this.props.plugin) {
        preparePluginsContainerBeforePluginError(this.props.plugin.id)

        let isPluginLoaderActive = this.props.state.PluginLoaderReducer.waitForLoadPlugins.size > 0 && this.props.state.PluginLoaderReducer.waitForLoadPlugins.has(this.props.plugin.id)
        
        if (isPluginLoaderActive) {
          store.dispatch(changePluginLoaderVisibility(this.props.plugin.id, false))
        }
      }

      let isPluginLoaderActive = this.props.state.PluginLoaderReducer.waitForLoadPlugins.size > 0 && this.props.state.PluginLoaderReducer.waitForLoadPlugins.has(this.props.plugin.id)
              
      if (isPluginLoaderActive) {
        store.dispatch(changePluginLoaderVisibility(this.props.plugin.id, false))
      }

      let controlledPluginKey = this.props.plugin.key === "radio-button-filter" || this.props.plugin.key === "selection-box" 
      ? "button-filter" 
      : this.props.plugin.key === "pie-chart-enhanced" 
        ? "pie-chart"
        : this.props.plugin.key

      return (
        <div style={{ position: "relative", display: "flex", height: "100%" }}>
          <div className={"plugin-error-area-holder"}>
            <div className={"error-blur-area"}></div>
            <div className={"error-plugin-image"}>
              <img src={"/api/plugin/" + controlledPluginKey}></img>
            </div>
            <div
              onClick={() => this.showErrors(this.props.errors)}
              className={"plugin-error-area"}
            >
              <ExclamationCircleOutlined />
            </div>
          </div>
        </div>
      );
    } else {
      if (this.props.plugin) {
        if (this.props.plugin.data && this.props.plugin.data.length !== 0) {
          preparePluginsContainerAfterPluginError(this.props.plugin.id)
        }
      }

      return null;
    }
  }
}

const mapDispatchToProps = {};

const mapStateToProps = state => {
  return {
    state: state
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PluginError);
