import React, { Component } from "react";

/**
 * This component creates a blur area, the blur area can be configured according to the given props.
 */
export default class BlurComponent extends Component {
  render() {
    const holderClassname = this.props.holderClassname
      ? "container-filter-holder " + this.props.holderClassname
      : "container-filter-holder";
      
    const holderDisplay = this.props.holderDisplay ? "flex" : "none";

    const blurColor = this.props.blurColor
      ? "container-filter-background " + this.props.blurColor
      : "container-filter-background";

    const contentClassname = this.props.contentClassname
      ? "container-filter-content " + this.props.contentClassname
      : "container-filter-content";

    return (
      <div>
        <div
          className={holderClassname}
          style={{ display: holderDisplay }}
          onClick={this.props.onClick ? this.props.onClick : null}
        >
          <div className={blurColor}></div>
          <div className={contentClassname}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}
