import React, { Component } from "react";
import { Slider, Row, Col, Tooltip, InputNumber, Divider } from "antd";
import Text from "../../../../GeneralComponents/Text/Text";
import {
  ToolOutlined,
  DeleteOutlined,
  FunctionOutlined
} from "@ant-design/icons";
import i18n from "../../../../../Utils/i18next";
import { TYPE_INPUT, TYPE_SLIDER } from "./WhatIf";
import { decideSeparator } from "../../../../../Utils/NumberLocalization";

/**
 * gets value and type and decide format.
 * @param {*} value - number
 * @param {*} type - string //percent, float, integer etc.
 */
export function formatter(value, type) {
  if (type === "percent") {
    return `${value}%`;
  } else {
    return convertNumber(value, type);
  }
}

/**
 * convert value by data type.
 * @param {*} value - number
 * @param {*} dataType  - string //percent, float, integer etc.
 */
export function convertNumber(value, dataType) {
  if (dataType === "integer") {
    return Number.parseInt(value);
  } else {
    return Number.parseFloat(value);
  }
}

const numberSeparator = decideSeparator();

/** Parameter component for dashboard what if parameters. */
class Parameter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentValue: this.props.parameter.currentValue
        ? this.props.parameter.currentValue
        : 0,
      marks: {
        0: this.props.parameter.minValue ? this.props.parameter.minValue : "0",
        100: this.props.parameter.maxValue
          ? this.props.parameter.maxValue
          : "100"
      }
    };
  }

  componentWillMount() {
    // if datatype percent, renders marks percent type.
    if (this.props.parameter.dataType === "percent") {
      this.setState({
        ...this.state,
        marks: {
          0: this.props.parameter.minValue
            ? this.props.parameter.minValue + "%"
            : "0%",
          100: this.props.parameter.maxValue
            ? this.props.parameter.maxValue + "%"
            : "100%"
        }
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.parameter &&
      this.props.parameter.currentValue !== nextProps.parameter.currentValue
    ) {
      this.setState({
        ...this.state,
        currentValue: nextProps.parameter.currentValue
      });
    }
  }

  /** Input parameter changes handle method */
  onChange = (e, minValue, maxValue, dataType) => {
    let value = e.target.value;

    if (value < minValue) {
      value = minValue;
    }

    if (value > maxValue) {
      value = maxValue;
    }

    convertNumber(value, dataType)

    if (!Number.isNaN(value)) {
      if (this.state.currentValue !== value) {
        this.setState({
          ...this.state,
          currentValue: value
        });
  
        if (value && value !== "") {
          this.props.updateCurrentValue(this.props.parameter.id, eval(value));
        }
      }
    }
  };

  /** Slider parameter changes handle method */
  onSliderChange = value => {
    this.setState({
      ...this.state,
      currentValue: value
    });
  };

  // when mouse up fired after dragging slider, update current value on parameter
  afterSliderChange = value => {
    this.props.updateCurrentValue(this.props.parameter.id, value);
  };

  // Edit button element
  editButton = id => {
    return (
      <Tooltip title={i18n.t("Edit")}>
        <ToolOutlined
          name={"whatIfEditButton"}
          onClick={() => this.editParameter(id)}
          className={"circle-icon"}
          style={{ cursor: "pointer", margin: "5px 0px 0px" }}
        ></ToolOutlined>
      </Tooltip>
    );
  };

  // Set selected parameter for edit.
  editParameter = id => {
    this.props.setSelectedParameters(id);
  };

  // Remove button element
  removeButton = id => {
    return (
      <Tooltip title={i18n.t("Delete")}>
        <DeleteOutlined
          name={"whatIfDeleteButton"}
          onClick={() => this.removeParameter(id)}
          className={"circle-icon"}
          style={{
            cursor: "pointer",
            color: "#e87d7d",
            borderColor: "#e87d7d",
            margin: "5px 0px 0px"
          }}
        ></DeleteOutlined>
      </Tooltip>
    );
  };

  //remove parameter with id in list
  removeParameter = id => {
    this.props.removeParameter(id);
  };

  // Slider component for parameter
  sliderComponent = (
    dataType,
    defaultValue,
    minValue,
    maxValue,
    incrementValue,
    id
  ) => {
    return (
      <Slider
        tipFormatter={value => formatter(value, dataType)}
        defaultValue={convertNumber(defaultValue, dataType)}
        value={convertNumber(this.state.currentValue, dataType)}
        min={convertNumber(minValue, dataType)}
        max={convertNumber(maxValue, dataType)}
        onChange={this.onSliderChange}
        onAfterChange={this.afterSliderChange}
        step={convertNumber(incrementValue, dataType)}
        key={id}
      />
    );
  };

  //input component for parameter
  inputComponent = (defaultValue, minValue, maxValue, id, dataType) => {
    return (
      <div style={{ margin: "0 15px" }}>
        <InputNumber
          style={{ width: "100%" }}
          id={id}
          onPressEnter={(e) => this.onChange(e, minValue, maxValue, dataType)}
          value={this.state.currentValue}
          min={minValue}
          max={maxValue}
          defaultValue={defaultValue}
          size="small"
          onBlur={(e) => this.onChange(e, minValue, maxValue, dataType)}
          decimalSeparator={numberSeparator}
        ></InputNumber>
      </div>
    );
  };

  // Decider of input or slider parameter
  renderByShowType = (
    showType,
    dataType,
    defaultValue,
    minValue,
    maxValue,
    incrementValue,
    id
  ) => {
    if (showType === TYPE_SLIDER) {
      return this.sliderComponent(
        dataType,
        defaultValue,
        minValue,
        maxValue,
        incrementValue,
        id
      );
    } else if (showType === TYPE_INPUT) {
      return this.inputComponent(
        defaultValue,
        minValue,
        maxValue,
        id,
        dataType
      );
    }
  };

  render() {
    let {
      id,
      defaultValue,
      dataType,
      showType,
      minValue,
      maxValue,
      incrementValue,
      name,
      displayName
    } = this.props.parameter;

    if (incrementValue === null) {
      incrementValue = 1;
    }

    return (
      <div>
        <Row className={"parameter-row"}>
          <Col
            span={4}
            style={{
              borderRight: "1px solid #ececec",
              height: "35px"
            }}
          >
            <Text className={"parameter-display-name"}>
              <Tooltip title={displayName} placement={"topLeft"}>
                {displayName}{" "}
              </Tooltip>
              <Text className={"small-sub-text"}>
                <Tooltip title={name} placement={"topLeft"}>
                  <FunctionOutlined
                    style={{ top: "-2px", position: "relative" }}
                  />
                  : {name}
                </Tooltip>
              </Text>
            </Text>
          </Col>
          <Col
            className={"parameter-col"}
            span={2}
            style={{ textAlign: "right" }}
          >
            <Text>{formatter(minValue, dataType)}</Text>
          </Col>
          <Col span={9} className={"parameter-col"}>
            {this.renderByShowType(
              showType,
              dataType,
              defaultValue,
              minValue,
              maxValue,
              incrementValue,
              id
            )}
          </Col>
          <Col className={"parameter-col"} span={2}>
            <Text>{formatter(maxValue, dataType)}</Text>
          </Col>
          <Col
            className={"parameter-col"}
            style={{
              borderRight: "1px solid #ececec",
              borderLeft: "1px solid #ececec",
              height: "35px",
              fontWeight: "bold",
              color: "#509665",
              textAlign: "center"
            }}
            span={3}
          >
            <Text>({formatter(this.state.currentValue, dataType)})</Text>
          </Col>
          <Col
            style={{
              borderRight: "1px solid #ececec",
              height: "35px",
              textAlign: "center"
            }}
            span={2}
          >
            {this.props.readOnly === "WRITE" ? this.editButton(id) : null}
          </Col>
          <Col style={{ textAlign: "center" }} span={2}>
            {this.props.readOnly === "WRITE" ? this.removeButton(id) : null}
          </Col>
        </Row>
      </div>
    );
  }
}

export default Parameter;
