import React, { Component } from "react";
import { get, del } from "../Utils/WebService";
import { API_BASE } from "../config";
import EditRolesContent from "./EditRolesContent";
import { showNotificationWithIcon } from "../Utils/Notification";
import { Button, Icon, Input, Tag, Table, Divider } from "antd";

const { Column } = Table;

class ListRolesContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roleList: [],
      addStatus: "",
      visibleEdit: false,
      searchText: "",
      propsEditId: ""
    };
  }

  componentWillMount() {
    this.getRole();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.list === true) {
      this.getRole();
    }
  }

  getRole = () => {
    let url = `${API_BASE}/role`;

    const successFunc = result => {
      this.setState({
        roleList: result.data
      });
    };

    const errorFunc = error => {
      showNotificationWithIcon("ERROR!", error.response.data, "error");
    };

    get(url, successFunc, errorFunc);
  };

  deleteRole = id => {
    let url = `${API_BASE}/role/` + id;
    let requestBody = {};

    const successFunc = result => {
      showNotificationWithIcon("OK", "Role successfully deleted.", "success");
      this.getRole();
    };

    const errorFunc = error => {
      showNotificationWithIcon("ERROR!", error.response.data, "error");
    };

    del(url, requestBody, successFunc, errorFunc);
  };

  confirmEdit = result => {
    if (result === true) {
      this.setState({
        visibleEdit: false
      });
      this.getRole();
    } else {
      this.setState({
        visibleEdit: false
      });
    }
  };

  //For Table
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    }
  });

  showEditModal = record => {
    this.setState({
      visibleEdit: true,
      propsEditObj: {...record}
    });
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  //--For Table

  render() {
    return (
      <div id="listRolesContent">
        <Table dataSource={this.state.roleList}>
          <Column
            style={{ fontWeight: "bold" }}
            title="Role Name"
            dataIndex="name"
            key="name"
            {...this.getColumnSearchProps("name")}
          />
          <Column
            title="Description"
            dataIndex="description"
            key="description"
            {...this.getColumnSearchProps("description")}
          />
          <Column
            title="Edit/Delete"
            key="process"
            render={(text, record) => (
              <span>
                <a
                  key="list-loadmore-edit"
                  onClick={() => this.showEditModal(record)}
                >
                  <Icon type="edit"></Icon>
                </a>
                <Divider type="vertical" />
                {record.tenant != record.username ? (
                  <a
                    key="list-loadmore-more"
                    onClick={() => this.deleteRole(record.id)}
                  >
                    <Icon type="delete"></Icon>
                  </a>
                ) : (
                  ""
                )}
              </span>
            )}
          />
        </Table>

        <EditRolesContent
          visible={this.state.visibleEdit}
          confirm={this.confirmEdit}
          role={this.state.propsEditObj}
        />
      </div>
    );
  }
}

export default ListRolesContent;
