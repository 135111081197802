import { CHANGE_PLUGIN_CONTEXT_MENU, PASTE_PLUGIN_FUNCTION } from "./ContextMenuAction";
import { CHANGE_PLUGIN_CONTEXT_MENU_VISIBILITY } from "./ContextMenuAction";
import { ADD_PLUGIN_FUNCTION } from "./ContextMenuAction"
import { CHANGE_RIGHT_CLICKED_PLUGIN } from "./ContextMenuAction"
import { CHANGE_ORIGINAL_PLUGINS } from "./ContextMenuAction"
import { CHANGE_ORIGINAL_INTERACTIONS } from "./ContextMenuAction"

/**
 *
 * @param {*} state
 * @param {*} action
 * Reducer for plugin copy-paste context menu visibility and copied plugin
 *
 */
export default function ContextMenuReducer(
  state = {
    xPosition: 0,
    yPosition: 0,
    contextMenuStatus: false,
    contextMenuType: null,
    addPluginFunction: null,
    pastePluginFunction: null,
    rightClickedPluginFunction: null,
    originalPlugins: new Map(),
    originalInteractions: new Map()
  },
  action
) {
  switch (action.type) {
    case CHANGE_ORIGINAL_PLUGINS:
      return {
        ...state,
        originalPlugins: action.payload,
      };
    case CHANGE_ORIGINAL_INTERACTIONS:
      return {
        ...state,
        originalInteractions: action.payload,
      }
    case CHANGE_PLUGIN_CONTEXT_MENU:
      return {
        ...state,
        xPosition: action.payload.xPosition,
        yPosition: action.payload.yPosition,
        contextMenuStatus: action.payload.status,
        contextMenuType: action.payload.contextMenuType,
      };
    case CHANGE_PLUGIN_CONTEXT_MENU_VISIBILITY:
      return {
        ...state,
        contextMenuStatus: action.payload.status
      };
    case ADD_PLUGIN_FUNCTION: 
      return {
        ...state,
        addPluginFunction: action.payload
      };
    case PASTE_PLUGIN_FUNCTION: 
      return {
        ...state,
        pastePluginFunction: action.payload
      }
    case CHANGE_RIGHT_CLICKED_PLUGIN: 
      return {
        ...state,
        rightClickedPluginFunction: action.payload
      }
    default:
      return state;
  }
}
