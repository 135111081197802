import React, { Component } from "react";
import Text from "../../GeneralComponents/Text/Text";
import { Row, Col, Button } from "antd";
import { BgColorsOutlined } from "@ant-design/icons";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import i18n from "../../../Utils/i18next";
import $ from "jquery";

/**
 * Color picker component for conditional formatting
 */
export default class ColorPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      colorChange: false,
      color: this.props.value
        ? this.props.value
        : this.props.option.defaultValue
    };

    this.wrapperRef = React.createRef();
  }

  componentWillMount() {
    //Set default value in object
    if (this.state.color) {
      this.onChangeSelectedColor(this.state.color);
    }
  }

  /** Mouse listener for click outside */
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  /** Mouse listener for click outside */
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /** Change color */
  onChangeSelectedColor = value => {
    this.props.handleChange({ type: this.props.type, value: value });
  };

  /** If clicked outside close color picker */
  handleClickOutside = event => {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      if (!$(event.target).closest("#loginPopup").length > 0) {
        this.closeChangeVisible();
      }
    }
  };

  /** Change visibility color picker */
  colorChangeVisible = () => {
    this.setState({
      ...this.state,
      colorChange: !this.state.colorChange
    });
  };

  /** Close color picker */
  closeChangeVisible = () => {
    this.setState({
      ...this.state,
      colorChange: false
    });
  };

  /** Handle color change */
  colorOnChange = obj => {
    this.setState({
      ...this.state,
      color: obj.color
    });

    this.onChangeSelectedColor(obj.color);
  };

  render() {
    return (
      <div>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px", color: this.props.disabledColorPicker ? "#8d8c8c" : "" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
                color: this.props.disabledColorPicker ? "#8d8c8c" : ""
              }}
            >
              {this.props.option.title}
            </Text>
          </Col>
          <Col span={12}>
            <div
               ref={this.wrapperRef}
               style={{display: "flex", alignItems: "center"}}
            >
            <div style={{width: "calc(100% - 37px)"}}>
              <Button
                id={"openColorPicker"}
                onClick={!this.props.disabledColorPicker ? this.colorChangeVisible : () => {}}
                style={{ width: "100%", background: this.props.disabledColorPicker ? "#ededed" : "white", cursor: this.props.disabledColorPicker ? "not-allowed" : "pointer" }}
              >
                {this.state.colorChange
                  ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                  : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
              </Button>
              {this.state.colorChange ? (
                <ColorPickerPanel
                  alpha={100}
                  color={this.state.color}
                  onChange={(obj) => this.colorOnChange(obj)}
                  id={"backgroundColor"}
                  style={{
                    marginTop: "5px",
                    position: "absolute",
                    right: 0,
                    marginBottom: "50px"
                  }}
                />
              ) : null}
            </div>
            <div
              onClick={!this.props.disabledColorPicker ? this.colorChangeVisible : () => {}}
              style={{
                width: "32px",
                height: "32px",
                marginLeft: "5px",
                border: "1px solid #d7d6d6",
                boxShadow: "0 2px 0 rgba(0, 0, 0,}",
                borderRadius: "4px",
                cursor: "pointer",
                backgroundColor: this.state.color,
                cursor: this.props.disabledColorPicker ? "not-allowed" : "pointer",
              }}
            />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
