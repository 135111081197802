import React, { Component } from "react";
import Button from "../GeneralComponents/Button/Button";
import { MenuOutlined, SearchOutlined, TagsOutlined } from "@ant-design/icons";
import Text from "../GeneralComponents/Text/Text";
import { Divider } from "antd";
import i18n from "../../Utils/i18next";
import $ from "jquery";
import { fullViewWidth } from "../../config";
import UserNotifications from "../UserNotifications/UserNotifications";
import LabelFilter from "../DashboardPage/LabelFilter";

export default class MobileMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMobileMenu: false,
      showLabelFilter: false
    };

    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  //If click outside hide switcher area.
  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      if (!$(event.target).closest("#loginPopup").length > 0) {
        this.setMobileMenuVisibility(false);
      }
    }
  };

  setMobileMenuVisibility = status => {
    if (this.state.showMobileMenu === status) return;

    this.setState({
      showMobileMenu: status
    });
  };

  setLabelFilterVisibility = status => {
    if (this.state.showLabelFilter === status) return;

    this.setState({
      showLabelFilter: status
    });
  }

  render() {
    return (
      <div ref={this.wrapperRef} className={"mobile-menu show-on-mobile"} hidden={this.props.clickedSearch === true}>
        <Button
          id={"mobileMenuButton"}
          className={"btn-sm mobile-menu-button"}
          onClick={() => this.setMobileMenuVisibility(!this.state.showMobileMenu)}
        >
          <MenuOutlined className={"button-icon"} />
        </Button>

        <UserNotifications
          id="mobileNotifications"
          comesFromTopMenu={false}
        />

        <Button
          id="mobileSearchButton"
          className={"btn-sm mobile-menu-button search-button"}
          onClick={() => this.props.openSearchForMobile()}
        >
          <SearchOutlined className={"button-icon"} />
        </Button>

        {
          this.props.showLabels &&
          <Button
            id="mobileLabelButton"
            className="btn btn-new circle-button mobile-menu-button"
            tooltip={i18n.t("LandingPage.Label.Title")}
            onClick={() => this.setLabelFilterVisibility(true)}
          >
            <TagsOutlined className="label-icon" />
            <LabelFilter
              visibleSelector={this.state.showLabelFilter}
              closeFilter={() => this.setLabelFilterVisibility(false)}
              selectedLabels={this.props.selectedLabels}
              changeSelectedLabels={this.props.changeSelectedLabels}
            ></LabelFilter>
          </Button>
        }

        {this.state.showMobileMenu === true ? (
          <div className={"mobile-menu-content"}>
            <Text onClick={() => this.props.goProfile()} className={"mobile-menu-item"}><i className={"fa fa-user"}></i> &nbsp; {i18n.t("Profile")}</Text>
            <Text onClick={() => { }} className={"mobile-menu-item"}><i className={"fa fa-question"}></i> &nbsp; {i18n.t("Help")}</Text>
            <Divider style={{ margin: "10px 0" }} />
            <Text onClick={() => this.props.logout()} className={"mobile-menu-item"}><i className={"fas fa-power-off"}></i> &nbsp; {i18n.t("Logout")}</Text>

          </div>
        ) : null}

        <style>
          {this.props.theme && `  
            #mobileLabelButton,
            #mobileNotifications,
            #mobileMenuButton,
            #mobileSearchButton {
              background-color: ${this.props.theme?.backgroundColor};
              color: ${this.props.theme?.textColor};
              border-color: ${this.props.theme?.textColor}4d;
            }

            #mobileLabelButton:hover,
            #mobileLabelButton:focus,
            #mobileLabelButton:active,
            #mobileNotifications:hover,
            #mobileNotifications:focus,
            #mobileNotifications:active,
            #mobileMenuButton:hover,
            #mobileMenuButton:focus,
            #mobileMenuButton:active,
            #mobileSearchButton:hover,
            #mobileSearchButton:focus,
            #mobileSearchButton:active {
              background-color: ${this.props.theme?.textColor};
              color: ${this.props.theme?.backgroundColor};
            }
          `}
        </style>
      </div>
    );
  }
}
