import React, { Component } from "react";
import { Button } from "antd";
import { showNotificationWithIcon } from "../../../Utils/Notification";
import { del, post, put } from "../../../Utils/WebService";
import { API_BASE } from "../../../config";
import i18n from "../../../Utils/i18next";
import { PlusCircleOutlined } from "@ant-design/icons";
import SessionVariableTable from "./SessionVariableTable";
import SessionVariableEditor from "./SessionVariableEditor";
import "../SessionVariable/SessionVariableLayout.css";
import { store } from "../../..";
import { deepCopy } from "../../../Utils/Global";
import { setDashboardSessionVariables, setSessionVariableContent } from "./SessionVariableAction";
import _ from "lodash";
import Text from "../Text/Text";

class SessionVariableLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      selectedVariable: null
    };
  }

  /**
   * Closes modal & resets selected variable
   */
  closeModal = () => {
    this.setState({
      showModal: false,
      selectedVariable: null
    });
  };

  /**
   * Creates the editor to edit a session variable
   * 
   * @param {*} obj 
   * @returns 
   */
  handleEditSessionVariable = (obj) => {
    if (!obj?._id) return;

    this.setState({
      showModal: true,
      selectedVariable: obj
    });
  };

  /**
   * Prepares editor to create a new session variable
   */
  handleCreateSessionVariable = () => {
    this.setState(
      {
        showModal: true,
        selectedVariable: {}
      });
  };

  /**
   * Deletes the session variable
   * 
   * @param {*} variable 
   * @returns 
   */
  deleteSessionVariable = (variable) => {
    if (!variable?._id) return;

    let url = `${API_BASE}/session-variable/variable/${variable._id}`

    const successFunc = (result) => {
      let reduxState = store.getState();
      let sessionVariables = new Map(reduxState.SessionVariableReducer.sessionVariables);

      sessionVariables.delete(variable.name);

      store.dispatch(setSessionVariableContent(sessionVariables));
    }

    const errorFunc = (e) => {
      showNotificationWithIcon(e, null, "error");
    }

    del(url, undefined, successFunc, errorFunc);
  };

  /**
   * Creates new session variable
   * 
   * @param {*} obj 
   * @returns 
   */
  createSessionVariable = (obj) => {
    if (!(obj instanceof Object)) return;

    let reduxState = store.getState();
    let sessionVariables = new Map(reduxState.SessionVariableReducer.sessionVariables);
    let url = `${API_BASE}/session-variable/variable`;

    if (sessionVariables.has(obj.name)) {
      showNotificationWithIcon(
        `${i18n.t("SessionVariable")}: ${obj.name}`,
        i18n.t("SessionVariables.NameAlreadyExists"),
        "error"
      );

      return;
    }

    const successFunc = (result) => {
      sessionVariables.set(
        obj.name,
        {
          ...obj,
          _id: result.data
        }
      )

      store.dispatch(setSessionVariableContent(sessionVariables));

      if (obj.scope === "dashboard") {
        this.executeSessionVariables(result.data, () => this.closeModal());
      } else {
        this.closeModal();
      }
    }

    const errorFunc = (e) => {
      showNotificationWithIcon(e, null, "error");
    }

    post(url, obj, successFunc, errorFunc);
  };

  /**
   * Updates the session variable
   * 
   * @param {*} obj 
   * @returns 
   */
  updateSessionVariable = (obj) => {
    let variable = deepCopy(obj);

    if (!obj?._id) return;

    let reduxState = store.getState();
    let sessionVariables = new Map(reduxState.SessionVariableReducer.sessionVariables);
    let sessionVariableNames = new Map(reduxState.SessionVariableReducer.sessionVariableNames);
    let oldVariable = sessionVariables.get(sessionVariableNames.get(variable._id));
    let url = `${API_BASE}/session-variable/variable/${variable._id}`

    if (oldVariable.name !== obj.name && sessionVariables.has(obj.name)) {
      showNotificationWithIcon(
        `${i18n.t("SessionVariable")}: ${obj.name}`,
        i18n.t("SessionVariables.NameAlreadyExists"),
        "error"
      );

      return;
    }

    const successFunc = (result) => {
      sessionVariables.set(variable.name, variable);

      if (oldVariable.name !== variable.name) {
        sessionVariables.delete(oldVariable.name);
      }

      if (variable.scope === "dashboard") {
        this.executeSessionVariables(variable._id, () => {
          store.dispatch(setSessionVariableContent(sessionVariables));
          this.closeModal()
        });
      } else {
        store.dispatch(setSessionVariableContent(sessionVariables));
        this.closeModal();
      }
    }

    const errorFunc = (e) => {
      showNotificationWithIcon(e, null, "error");
    }

    if (!sessionVariables.has(oldVariable.name) || _.isEqual(oldVariable, variable)) {
      this.closeModal();

      return;
    }

    put(url, obj, successFunc, errorFunc);
  };

  /**
   * Executes the given session variables
   * Updates the dashboard session variables storage
   *
   * @param {*} payload 
   * @param {*} callback 
   */
  executeSessionVariables = (payload, callback) => {
    let reduxState = store.getState();
    let dashboardSessionVariables = new Map(reduxState.SessionVariableReducer.dashboard);

    let requestBody = payload instanceof Array ? payload : [payload];
    let url = `${API_BASE}/session-variable/variable/value`;

    let successFunc = result => {
      if (result.data) {
        let resultMap = new Map(Object.entries(result.data));

        resultMap.forEach((obj, id) => {
          if (obj.status !== "success") {
            showNotificationWithIcon(
              `${obj.name}`,
              obj.message || i18n.t("DataConnections.CouldNotFetch"),
              "warning")
          }

          dashboardSessionVariables.set(obj.name, obj.result);
        });

        store.dispatch(setDashboardSessionVariables(dashboardSessionVariables));

        callback instanceof Function && callback();
      }
    }

    let errorFunc = error => {
      showNotificationWithIcon(i18n.t("Error"), error, "error");

      callback instanceof Function && callback();
    }

    post(url, requestBody || [], successFunc, errorFunc);
  }

  render() {
    return (
      <div style={{position: "relative"}}>
        <Text
          type="h4"
          style={{ textAlign: "center", marginBottom: "20px" }}
        >
          {this.props.model.displayName}
        </Text>

        <Button
          id="addSessionVariableButton"
          className="addButton"
          type="dashed"
          onClick={() => this.handleCreateSessionVariable()}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "10px",
            position: "absolute",
            zIndex: 1,
            top: 0,
            right: 0,
          }}
        >
          <PlusCircleOutlined style={{ marginRight: "5px" }} />
          {i18n.t("Add")}
        </Button>

        <div className="join-list-area">
          <SessionVariableTable
            deleteSessionVariable={this.deleteSessionVariable}
            handleEditSessionVariable={this.handleEditSessionVariable}
            handleCreateSessionVariable={this.handleCreateSessionVariable}
          />
        </div>

        <SessionVariableEditor
          visible={this.state.showModal}
          selectedVariable={this.state.selectedVariable}
          closeModal={this.closeModal}
          createSessionVariable={this.createSessionVariable}
          updateSessionVariable={this.updateSessionVariable}
          model={this.props.model}
        />
      </div>
    );
  }
}

export default SessionVariableLayout;
