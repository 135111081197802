import React, { Component } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Icon, Spin } from "antd";
import i18n from "../../Utils/i18next";
import { connect } from "react-redux";
import { changePluginLoaderVisibility } from "../../ui/GeneralComponents/PluginLoader/PluginLoaderAction";
import { store } from "../..";

//Plugin loader component
class PluginLoaderForWithoutPluginRender extends Component {
    render() {
        const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
        let reduxState = store.getState();
        let waitForLoadPlugins = reduxState.PluginLoaderReducer.waitForLoadPlugins;
        
        const isPluginLoaderActive = waitForLoadPlugins.size > 0 && waitForLoadPlugins.has(this.props.pluginId) && this.props.hasError

        return (
            <div className="plugin-loader-without-plugin-render">
                {isPluginLoaderActive ? <Spin indicator={antIcon} style={{
                        width: "30px",
                        position: "absolute",
                        bottom: "10px",
                        left: "15px"
                    }}/> : null}
            </div>
        );
    }
}

/*
* dispatchs change plugin loader visibility function
*/
const mapDispatchToProps = {
    changePluginLoaderVisibility,
};

/*
* returns change plugin loader visibility state
*/
const mapStateToProps = (state) => {
    return {
        state: state,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PluginLoaderForWithoutPluginRender);
