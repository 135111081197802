import React, { Component } from "react";
import { Input, Button } from "antd";
import Text from "../Text/Text";
import i18n from "../../../Utils/i18next";
import { post } from "../../../admin/Utils/WebService";
import { API_BASE } from "../../../config";
import "./mapTopoJson.css";
import {
  showError,
  showNotificationWithIcon,
} from "../../../Utils/Notification";

//Upload new topojson
export default class UploadTopoJson extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      fileName: "",
      file: {},
      name: "",
    };
  }

  onChange(info, THIS) {
    THIS.setState({ ...THIS.state, file: info.file });
  }

  /**
   *
   * @returns
   * Topojson makes necessary validations before registration
   */
  saveTopojsonValidationCheck = () => {
    if (this.state.name === "") {
      showError(i18n.t("PluginErrors.NameOfMapMustNotBeEmpty"));

      return false;
    }

    if (!(this.state.file && this.state.file.size > 0)) {
      showError(i18n.t("PluginErrors.FileSelectionMustNotBeEmpty"));

      return false;
    }

    return true;
  };

  //Save map
  saveMap = () => {
    if (!this.saveTopojsonValidationCheck()) {
      return;
    }

    let formData = new FormData();
    formData.append("file", this.state.file);
    formData.append("name", this.state.name);

    let url = `${API_BASE}/map`;

    const success = () => {
      showNotificationWithIcon(
        i18n.t("Success"),
        i18n.t("Dashboard.Settings.TopoJSON.MapSuccessfullyAdded"),
        "success"
      );

      this.setState({
        loading: false,
        fileName: "",
        name: "",
        file: "",
      });

      this.props.getTopoJsonListForMap();
      this.props.changeUploadVisible(false);
    };

    let header = {
      "Content-Type": "multipart/form-data",
    };

    post(url, formData, success, undefined, header);
  };

  /**
   *
   * @param {*} e
   * @returns
   *
   * Performs validation check for the uploaded file
   */
  fileValidationCheck = (e) => {
    let fileName = e.target.files[0] ? e.target.files[0].name : undefined;

    if (fileName === undefined) {
      return false;
    } else {
      let fileNameSplit = fileName.split(".");
      let fileType = fileNameSplit[fileNameSplit.length - 1];

      if (!(fileType === "topojson" || fileType === "json")) {
        return false;
      }

      return true;
    }
  };

  //handle selected file and name
  onChangeFile = (e) => {
    if (!this.fileValidationCheck(e) || !this.state.file) {
      showError(i18n.t("PluginErrors.PleaseFileInValidFormat"));

      return;
    }

    if (e.target.files[0] && e.target.files[0].name) {
      this.setState({
        ...this.state,
        file: e.target.files[0],
        fileName: e.target.value,
      });
    }
  };

  render() {
    let fileName = this.state.fileName.split("\\");
    fileName = fileName[fileName.length - 1];

    return (
      <div>
        <Text>{i18n.t("Dashboard.Settings.TopoJSON.NameOfMap")}</Text>
        <Input
          name={"name"}
          id={"map-name"}
          value={this.state.name}
          onChange={(e) =>
            this.setState({ ...this.state, name: e.target.value })
          }
        />
        <br></br>

        <Text style={{ display: "block", marginTop: "15px" }}>
          {i18n.t("Dashboard.Settings.TopoJSON.MapWithTopoJsonDesc")}
        </Text>

        <div>
          <label className={"map-select"}>
            {" "}
            {i18n.t("Dashboard.Settings.TopoJSON.SelectMap")}
            <input
              type="file"
              className={"map-file-input"}
              onChange={this.onChangeFile}
            ></input>
          </label>
          {fileName === ""
            ? i18n.t("Dashboard.Settings.TopoJSON.NoFileSelected")
            : fileName}
        </div>
        <Button
          type="primary"
          style={{ float: "right" }}
          onClick={this.saveMap}
        >
          {i18n.t("Save")}
        </Button>
      </div>
    );
  }
}
