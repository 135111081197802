import i18n from "../../../Utils/i18next";

export const TOPOJSON_PATH = "/api/map/wellDefined";

export const choroplethDefaultTopojsons = [
  "turkey/Iller.topojson",
  "turkey/Ilceler.topojson",
  "turkey/ankarailce.json",
  "turkey/ankarailcemahalle.json",
  "world.json",
  "uk/nuts1.json",
  "us/states.json",
  "us/states-mainland.json",
  "us/counties.json"
];

export const wellDefinedTopojsonOldPaths = {
  "turkey/Iller.topojson": "turkey_Iller",
  "turkey/Ilceler.topojson": "turkey_Ilceler",
  "turkey/ankarailce.json": "turkey_ankarailce",
  "turkey/ankarailcemahalle.json": "turkey_ankarailcemahalle",
  "world.json": "world",
  "uk/nuts1.json": "uk_nuts1",
  "us/states.json": "us_states",
  "us/states-mainland.json": "us_states-mainland",
  "us/counties.json": "us_countries"
};

export const choroplethDefaultTopojsonsWithObjects = [
  {
    name: "turkey_Iller",
    label: i18n.t("Dashboard.Settings.TopoJSON.TurkeyProvinces"),
    value: "turkey/Iller.topojson"
  },
  {
    name: "turkey_Ilceler",
    label: i18n.t("Dashboard.Settings.TopoJSON.TurkeyDistricts"),
    value: "turkey/Ilceler.topojson"
  },
  {
    name: "turkey_ankarailce",
    label: i18n.t("Dashboard.Settings.TopoJSON.AnkaraDistricts"),
    value: "turkey/ankarailce.json"
  },
  {
    name: "turkey_ankarailcemahalle",
    label: i18n.t("Dashboard.Settings.TopoJSON.AnkaraNeighborhood"),
    value: "turkey/ankarailcemahalle.json"
  },
  {
    name: "world",
    label: i18n.t("Dashboard.Settings.TopoJSON.WorldCountries"),
    value: "world.json"
  },
  { name: "uk_nuts1", label: "UK - NUTS 1", value: "uk/nuts1.json" },
  {
    name: "us_states",
    label: i18n.t("Dashboard.Settings.TopoJSON.UsStates"),
    value: "us/states.json"
  },
  {
    name: "us_states-mainland",
    label: i18n.t("Dashboard.Settings.TopoJSON.UsStatesMainland"),
    value: "us/states-mainland.json"
  },
  {
    name: "us_countries",
    label: i18n.t("Dashboard.Settings.TopoJSON.UsCountries"),
    value: "us/counties.json"
  }
];

/**
 * is empty or valid topojson
 * @param {*} obj
 */
export const validCheck = obj => {
  if (Array.isArray(obj)) {
    if (obj.length === 0) {
      return false;
    }
  }

  if (typeof obj === "string") {
    if (obj === "") {
      return false;
    }
  }

  if (obj == undefined || obj == null) {
    return false;
  }

  return true;
};
