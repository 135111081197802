import {
  notification
} from "antd";
import i18n from "./i18next";

export const showNotificationWithIcon = (message, description, type, style=undefined) => {
  notification[type]({
    message,
    description,
    style : style
  });
}

/**
 * Show given error message on the screen with error title
 * @param {Error Descriotion} description 
 */
export const showError = (description) => {
  notification["error"]({
    message: i18n.t("Error"),
    description
  });
}