import React, { Component } from "react";
import {
  DragDropContext,
  Droppable as DNDDroppable,
  Draggable as DNDDraggable
} from "react-beautiful-dnd";
import PropTypes from "prop-types";
import { reorder } from "../GeneralComponents/ColumnMapDragDrop"

/*
* some basic styles to make the items look a bit nicer
*/
const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  userSelect: "none",
  marginBottom: "8px",
  borderRadius: "6px",
  border: "2px dashed",
  borderColor: isDragging ? "#b7b7b7" : "#ffffff00",
  top: isDragging ? "auto!important" : "",
  left: isDragging ? "auto!important" : "",
  position: isDragging ? "absolute" : ""
});

const getListStyle = isDraggingOver => ({
  width: "100%"
});

/**
 * Vertical drag and drop to reorder data.
 * Includes react dnd libs.
 * Content returns object to parent components
 */
export default class VerticalDragDrop extends Component {
  constructor(props) {
    super(props);

    this.prototype = {
      content: PropTypes.object, //react node
      type: PropTypes.string,
      data: PropTypes.array,
      updateColumnMapData: PropTypes.func
    };

    this.state = {
      items: this.props.data
    };

    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.setState({
        ...this.state,
        items: nextProps.data
      });
    }
  }

  /*
  * dropped outside the list
  */
  onDragEnd(result) {
    let items = "";

    if (result.destination == null || result.destination == undefined || !result.destination) {
      items = reorder(
        this.state.items,
        result.source.index,
        result.source.index
      );
    }
    else {
      items = reorder(
        this.state.items,
        result.source.index,
        result.destination.index
      );
    }

    this.props.updateColumnMapData(items, this.props.type);
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <DNDDroppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.state.items &&
                this.state.items.map((item, index) => (
                  <DNDDraggable
                    key={item.uniqeColumnId}
                    draggableId={item.uniqeColumnId}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {this.props.content({
                          item: item,
                          index: index,
                          type: this.props.type
                        }, this.props.componentTHIS)}
                      </div>
                    )}
                  </DNDDraggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </DNDDroppable>
      </DragDropContext>
    );
  }
}
