import React, { Component } from "react";
import List from "./List";
import CreateUpdate from "./CreateUpdate";
import "./conditionalFormatting.css";
import { store } from "../../index"
import { deepCopy } from "../../Utils/Global";
import { getPluginsAllColumnsByField } from "../../Utils/PluginOperations";

const EDIT = "edit";
const LIST = "list";

/**
 * Give condition to styling to plugin
 * Includes List and create/update components
 */
export default class ConditionalFormatting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: LIST,
      edittingCondFormat: undefined
    };
  }

  /*
  * Gets drilldown columns and adds cols to parent's area. Prepares columnMap for conditional formatting.
  */
  createColumnMapWithDrilldownColumns = (originalColumnMap) => {
    let colMap = deepCopy(originalColumnMap)
    let pluginId = this.props.pluginId
    let reduxState = store.getState()
    let drilldownColumnsMap = reduxState.DrillDownReducer.drillDowns.get(pluginId)
    let columnsHash = getPluginsAllColumnsByField(originalColumnMap, "hash")

    if (drilldownColumnsMap) {
      let drilldownColumns = Array.from(drilldownColumnsMap["allDrillDownColumnsInPlugin"].values())

      drilldownColumns.map(drillCol => {
        let isDrilldownColumnBelongsToCurrentColumn = drillCol["drillDownParentColumnId"] && columnsHash.has(drillCol.drillDownParentColumnId)
        
        if (isDrilldownColumnBelongsToCurrentColumn) {
          let drilldownColumnArea = drillCol.locationFieldName

          colMap[drilldownColumnArea].data.push(drillCol)
        }
      })
    }

    return colMap;
  }

  /** To switch to the edit screen */
  setEdit = conditionalFormat => {
    this.setState({
      status: EDIT,
      edittingCondFormat: { ...conditionalFormat }
    });
  };

  /** To switch to the list screen */
  closeEditting = () => {
    this.setState({
      status: LIST,
      edittingCondFormat: undefined
    });
  };

  render() {
    if (this.state.status === LIST) {
      return (
        <List
          conditionalFormatList={this.props.conditionalFormats}
          setEdit={this.setEdit}
          updateConditionalFormat={this.props.updateConditionalFormat}
          pluginId={this.props.pluginId}
        />
      );
    } else {
      return (
        <CreateUpdate
          closeEditting={this.closeEditting}
          pluginConditionalFormatOptions={
            this.props.pluginConditionalFormatOptions
          }
          edittingCondFormat={this.state.edittingCondFormat}
          columnMap={this.createColumnMapWithDrilldownColumns(this.props.columnMap)}
          conditionalFormatColumnMap={this.props.conditionalFormatColumnMap}
          updateConditionalFormat={this.props.updateConditionalFormat}
          pluginId={this.props.pluginId}
          conditionalFormatList={this.props.conditionalFormats}
          conditionalFormatTargetMap={this.props.conditionalFormatTargetMap}
          isNecessaryAllColumns={this.props.isNecessaryAllColumns}
          defaultConditionalFormatColumn={this.props.defaultConditionalFormatColumn}
          isLockedTargetValue={this.props.isLockedTargetValue ? this.props.isLockedTargetValue : false}
          isTargetColumnAllColumns={this.props.isTargetColumnAllColumns ? this.props.isTargetColumnAllColumns : false}
          columnMapWithDrills={this.props.columnMapWithDrills ? this.props.columnMapWithDrills : null}
          measureCompareType={this.props.measureCompareType}
        />
      );
    }
  }
}
