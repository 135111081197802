import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Input, Button, Row } from "antd";
import { post, put } from "../Utils/WebService";
import { showNotificationWithIcon } from "../Utils/Notification";
import { API_BASE } from "../config";
import i18n from "../../Utils/i18next";

const host = API_BASE;
const api = "/dataSource";
const url = host + api;

class DataSourceModal extends Component {
  constructor(props) {
    super(props);

    this.modalActions = {
      ADD: "add",
      EDIT: "edit"
    };

    this.initState = {
      modalAction: this.modalActions.ADD,
      confirmLoading: false,
      confirmAndGoModellingLoading: false,
      dataSource: {
        name: "",
        config: ""
      },
      schemaName: ""
    };

    this.state = this.initState;
  }

  componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.selectedItem).length === 0) {
      this.setState(this.initState);
    } else {
      let schema = nextProps.selectedItem.name.split(".")[1];
      let name = nextProps.selectedItem.name.split(".")[0];
      this.setState({
        ...this.state,
        modalAction: this.modalActions.EDIT,
        schemaName: schema, 
        dataSource: {
          name: name,
          config: nextProps.selectedItem.config
        }
      });
    }
  }

  addDataSource = action => {
    const { dataSource,schemaName} = this.state;

    const successFunc = response => {
      let status, title, message;

      if (response.data.result == "success") {
        status = "success";
        title = "Datasource successfully added";
        message = null;
      }
      else{
        status = "error";
        title = "Error";
        message = response.data.result;
      }

      showNotificationWithIcon(title, message, status);

      this.setState({
        ...this.state,
        confirmLoading: false
      });

      let result = {
        action,
        dataSource
      };

      this.props.confirm(result);
    };

    const errorFunc = error => {
      showNotificationWithIcon(
        i18n.t("Datasource.CannotAdd"),
        error.response.data,
        "error"
      );

      this.setState({
        ...this.state,
        confirmLoading: false
      });
    };

    let dataSourceDTO = { ...dataSource };
    if(schemaName != ""){
      dataSourceDTO.name = dataSourceDTO.name + "." + schemaName;
    }
    dataSourceDTO.config = JSON.parse(dataSourceDTO.config);

    post(url, dataSourceDTO, successFunc, errorFunc);
  };

  updateDataSource = action => {
    const { dataSource,schemaName } = this.state;

    const successFunc = response => {

      let status, title, message;

      if (response.data.result == "success") {
        status = "success";
        title = "Datasource successfully updated";
        message = null;
      }
      else{
        status = "error";
        title = "Error";
        message = response.data.result;
      }
      showNotificationWithIcon(title, message, status);

      this.setState({
        ...this.state,
        confirmLoading: false
      });

      let result = {
        action,
        dataSource
      };

      this.props.confirm(result);
    };

    const errorFunc = error => {
      showNotificationWithIcon(
        i18n.t("Datasource.CannotUpdate"),
        error.response.data,
        "error"
      );

      this.setState({
        ...this.state,
        confirmLoading: false
      });
    };

    let urlWithParameters = url + "/" + dataSource.name + "." + schemaName;
    let dataSourceDTO = { ...dataSource };
    dataSourceDTO.config = JSON.parse(dataSourceDTO.config);

    put(urlWithParameters, dataSourceDTO, successFunc, errorFunc);
  };

  handleConfirm = e => {
    const { modalAction } = this.state;

    this.setState({
      ...this.state,
      confirmLoading: true
    });

    if (modalAction === this.modalActions.ADD) {
      this.addDataSource(e.target.id);
    } else if (modalAction === this.modalActions.EDIT) {
      this.updateDataSource(e.target.id);
    }
  };

  handleCancel = e => {
    this.props.confirm(e.target.id);
  };

  onChange = e => {
    this.setState({
      ...this.state,
      dataSource: {
        ...this.state.dataSource,
        [e.target.id]: e.target.value
      }
    });
  };

  onChangeSchemaName = e => {
    this.setState({
      ...this.state,
      schemaName : e.target.value
    });
  };

  render() {
    const {
      modalAction,
      confirmLoading,
      confirmAndGoModellingLoading,
      dataSource,
      schemaName
    } = this.state;

    let modalTitle, confirmButtonText;
    if (modalAction === this.modalActions.ADD) {
      modalTitle = "Add New Datasource";
      confirmButtonText = "Add";
    } else if (modalAction === this.modalActions.EDIT) {
      modalTitle = "Edit Datasource";
      confirmButtonText = "Save";
    }
  console.log("schema Name:", schemaName)
    return (
      <Modal
        title={modalTitle}
        visible={this.props.visible}
        footer={[
          <Button id="cancel" key="cancel" onClick={this.handleCancel}>
            Cancel
          </Button>,
          <Button
            id="confirm"
            key="confirm"
            type="primary"
            ghost="true"
            loading={confirmLoading}
            onClick={this.handleConfirm}
          >
            {confirmButtonText}
          </Button>,
          <Button
            id="confirmAndGoModelling"
            key="confirmAndGoModelling"
            type="primary"
            loading={confirmAndGoModellingLoading}
            onClick={this.handleConfirm}
          >
            {confirmButtonText} & Go Modelling
          </Button>
        ]}
        confirmLoading={this.state.confirmLoading}
        onOk={this.handleAdd}
        onCancel={this.handleCancel}
      >
        <Row>
          <span>Datasource Name</span>
          <Input
            id="name"
            placeholder="Enter datasource name"
            value={dataSource.name}
            onChange={this.onChange}
            disabled={modalAction === this.modalActions.ADD ? false : true}
          />
        </Row>
        <br />
        <Row>
          <span>Schema Name</span>
          <Input
            id="schemaName"
            placeholder="Enter datasource schema name"
            value={schemaName}
            onChange={this.onChangeSchemaName}
            disabled={modalAction === this.modalActions.ADD ? false : true}
          />
        </Row>
        <br />
        <Row>
          <span>Configuration</span>
          <Input.TextArea
            id="config"
            placeholder="Enter datasource configuration"
            value={dataSource.config}
            autosize={{ minRows: 2, maxRows: 6 }}
            onChange={this.onChange}
          />
        </Row>
      </Modal>
    );
  }
}

// DataSourceModal.propTypes = {
//   visible: PropTypes.bool.isRequired,
//   selectedItem: PropTypes.object.isRequired,
//   hideModal: PropTypes.func.isRequired
// };

export default DataSourceModal;
