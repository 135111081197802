export const isUserEntranceInputValid = (event) => {
    let val = event.target.value;

    if (val !== "") {
      val = event.target.value[event.target.value.length - 1];

      /**
       * for non-letter characters toLowerCase() and toUpperCase() is same ( tricky haa:) )
       */
      const isLetter = val.toLowerCase() != val.toUpperCase();
      const isDigit = /[0-9]/.test(val);
      const isUnderScore = val === "_";

      if (
        event.target.id === "username" &&
        isLetter === false &&
        isDigit === false &&
        isUnderScore === false
      ) {
        return false;
      }

      if (event.target.id === "fullname" && isLetter === false) {
        return false;
      }
    }

    return true;
}