import { create, all } from 'mathjs/lib/esm/number';

/**
 * Evaluates a mathematical expression.
 * @param {string} expr - The mathematical expression to be evaluated.
 * @returns {number} The result of the evaluated expression.
 * @example
 * const result = evaluate('2 + 3 * 4');
 * const result = evaluate('(2 + 3) * 4');
 * const result = evaluate('2014');
 */
export default function evaluate(expr) {
    const { evaluate } = create(all);

    return evaluate(`${expr}`);
}