export const formulas = {
    AggregateFunctions: {
        type: "AggregateFunction",
        formulas: [
            {
                key: "COUNT",
                name: "COUNT",
                insertText: "COUNT()",
                usages: [
                    "<b>COUNT</b>(x)"
                ]
            },
            {
                key: "SUM",
                name: "SUM",
                insertText: "SUM()",
                usages: [
                    "<b>SUM</b>(x)"
                ]
            },
            {
                key: "AVG",
                name: "AVG",
                insertText: "AVG()",
                usages: [
                    "<b>AVG</b>(x)"
                ]
            },
            {
                key: "MIN",
                name: "MIN",
                insertText: "MIN()",
                usages: [
                    "<b>MIN</b>(x)"
                ]
            },
            {
                key: "MAX",
                name: "MAX",
                insertText: "MAX()",
                usages: [
                    "<b>MAX</b>(x)"
                ]
            },
        ]
    },
    NumericFunctions: {
        type: "NumericFunction",
        formulas: [
            {
                key: "SQRT",
                name: "SQRT",
                insertText: "SQRT()",
                usages: [
                    "<b>SQRT</b>(x)"
                ]
            },
            {
                key: "ABS",
                name: "ABS",
                insertText: "ABS()",
                usages: [
                    "<b>ABS</b>(x)"
                ]
            },
            {
                key: "CEILING",
                name: "CEILING",
                insertText: "CEILING()",
                usages: [
                    "<b>CEILING</b>(x)"
                ]
            },
            {
                key: "FLOOR",
                name: "FLOOR",
                insertText: "FLOOR()",
                usages: [
                    "<b>FLOOR</b>(x)"
                ]
            },
            {
                key: "ROUND",
                name: "ROUND",
                insertText: "ROUND((), ())",
                usages: [
                    "<b>ROUND</b>(x, y)"
                ]
            },
            {
                key: "EXP",
                name: "EXP",
                insertText: "EXP()",
                usages: [
                    "<b>EXP</b>(x)"
                ]
            },
            {
                key: "LOG",
                name: "LOG",
                insertText: "LOG()",
                usages: [
                    "<b>LOG</b>(x)"
                ]
            },
            {
                key: "LOG10",
                name: "LOG10",
                insertText: "LOG10()",
                usages: [
                    "<b>LOG10</b>(x)"
                ]
            },
            {
                key: "MOD",
                name: "MOD",
                insertText: "MOD((), ())",
                usages: [
                    "<b>MOD</b>(x, y)"
                ]
            },
            {
                key: "POWER",
                name: "POWER",
                insertText: "POWER((), ())",
                usages: [
                    "<b>POWER</b>(x, y)"
                ]
            },
            {
                key: "RADIANS",
                name: "RADIANS",
                insertText: "RADIANS()",
                usages: [
                    "<b>RADIANS</b>(x)"
                ]
            },
            {
                key: "DEGREES",
                name: "DEGREES",
                insertText: "DEGREES()",
                usages: [
                    "<b>DEGREES</b>(x)"
                ]
            },
        ]
    },
    StringFunctions: {
        type: "StringFunction",
        formulas: [
            {
                key: "ASCII",
                name: "ASCII",
                insertText: "ASCII()",
                usages: [
                    "<b>ASCII</b>(x)"
                ]
            },
            {
                key: "CHR",
                name: "CHR",
                insertText: "CHR()",
                usages: [
                    "<b>CHR</b>(x)"
                ]
            },
            {
                key: "CHAR",
                name: "CHAR",
                insertText: "CHAR()",
                usages: [
                    "<b>CHAR</b>(x)"
                ]
            },
            {
                key: "CONCAT",
                name: "CONCAT",
                insertText: "CONCAT((), ())",
                usages: [
                    "<b>CONCAT</b>(x, y)"
                ]
            },
            {
                key: "CONCAT2",
                name: "CONCAT2",
                insertText: "CONCAT2((), ())",
                usages: [
                    "<b>CONCAT2</b>(x, y)"
                ]
            },
            {
                key: "ENDSWITH",
                name: "ENDSWITH",
                insertText: "ENDSWITH((), ())",
                usages: [
                    "<b>ENDSWITH</b>(x, y)"
                ]
            },
            {
                key: "INITCAP",
                name: "INITCAP",
                insertText: "INITCAP()",
                usages: [
                    "<b>INITCAP</b>(x)"
                ]
            },
            {
                key: "LCASE",
                name: "LCASE",
                insertText: "LCASE()",
                usages: [
                    "<b>LCASE</b>(x)"
                ]
            },
            {
                key: "LEFT",
                name: "LEFT",
                insertText: "LEFT((), ())",
                usages: [
                    "<b>LEFT</b>(x, y)"
                ]
            },
            {
                key: "LENGTH",
                name: "LENGTH",
                insertText: "LENGTH()",
                usages: [
                    "<b>LENGTH</b>(x)"
                ]
            },
            {
                key: "CHAR_LENGTH",
                name: "CHAR_LENGTH",
                insertText: "CHAR_LENGTH()",
                usages: [
                    "<b>CHAR_LENGTH</b>(x)"
                ]
            },
            {
                key: "CHARACTER_LENGTH",
                name: "CHARACTER_LENGTH",
                insertText: "CHARACTER_LENGTH()",
                usages: [
                    "<b>CHARACTER_LENGTH</b>(x)"
                ]
            },
            {
                key: "LOCATE",
                name: "LOCATE",
                insertText: "LOCATE((), ())",
                usages: [
                    "<b>LOCATE</b>(x, y)"
                ]
            },
            {
                key: "LTRIM",
                name: "LTRIM",
                insertText: "LTRIM()",
                usages: [
                    "<b>LTRIM</b>(x)"
                ]
            },
            {
                key: "RTRIM",
                name: "RTRIM",
                insertText: "RTRIM()",
                usages: [
                    "<b>RTRIM</b>(x)"
                ]
            },
            {
                key: "REPEAT",
                name: "REPEAT",
                insertText: "REPEAT((), ())",
                usages: [
                    "<b>REPEAT</b>(str1, instances)"
                ]
            },
            {
                key: "RIGHT",
                name: "RIGHT",
                insertText: "RIGHT((), ())",
                usages: [
                    "<b>RIGHT</b>(x, y)"
                ]
            },
            {
                key: "SUBSTRING",
                name: "SUBSTRING",
                insertText: "SUBSTRING((), ())",
                usages: [
                    "<b>SUBSTRING</b>(x, y)<br /><b>SUBSTRING</b>(x FROM y)",
                    "<b>SUBSTRING</b>(x, y,z)<br /><b>SUBSTRING</b>(x FROM y FOR z)"
                ]
            },
            {
                key: "TRANSLATE",
                name: "TRANSLATE",
                insertText: "TRANSLATE()",
                usages: [
                    "<b>TRANSLATE</b>(x, y, z)"
                ]
            },
            {
                key: "TRIM",
                name: "TRIM",
                insertText: "TRIM(() FROM ())",
                usages: [
                    "<b>TRIM</b>(LEADING|TRAILING|BOTH (x) FROM (y))"
                ]
            },
            {
                key: "UCASE",
                name: "UCASE",
                insertText: "UCASE()",
                usages: [
                    "<b>UCASE</b>(x)"
                ]
            },
        ]
    },
    DateFunctions: {
        type: "DateFunction",
        formulas: [
            {
                key: "CURDATE",
                name: "CURDATE",
                insertText: "CURDATE()",
                usages: [
                    "<b>CURDATE</b>()"
                ]
            },
            {
                key: "CURRENT_DATE",
                name: "CURRENT_DATE",
                insertText: "CURRENT_DATE()",
                usages: [
                    "<b>CURRENT_DATE</b>()"
                ]
            },
            {
                key: "CURTIME",
                name: "CURTIME",
                insertText: "CURTIME()",
                usages: [
                    "<b>CURTIME</b>()"
                ]
            },
            {
                key: "CURRENT_TIME",
                name: "CURRENT_TIME",
                insertText: "CURRENT_TIME()",
                usages: [
                    "<b>CURRENT_TIME</b>(precision)"
                ]
            },
            {
                key: "NOW",
                name: "NOW",
                insertText: "NOW()",
                usages: [
                    "<b>NOW</b>()"
                ]
            },
            {
                key: "CURRENT_TIMESTAMP",
                name: "CURRENT_TIMESTAMP",
                insertText: "CURRENT_TIMESTAMP()",
                usages: [
                    "<b>CURRENT_TIMESTAMP</b>(precision)"
                ]
            },
            {
                key: "DAYNAME",
                name: "DAYNAME",
                insertText: "DAYNAME()",
                usages: [
                    "<b>DAYNAME</b>(x)"
                ]
            },
            {
                key: "DAYOFMONTH",
                name: "DAYOFMONTH",
                insertText: "DAYOFMONTH()",
                usages: [
                    "<b>DAYOFMONTH</b>(x)"
                ]
            },
            {
                key: "DAYOFWEEK",
                name: "DAYOFWEEK",
                insertText: "DAYOFWEEK()",
                usages: [
                    "<b>DAYOFWEEK</b>(x)"
                ]
            },
            {
                key: "DAYOFYEAR",
                name: "DAYOFYEAR",
                insertText: "DAYOFYEAR()",
                usages: [
                    "<b>DAYOFYEAR</b>(x)"
                ]
            },
            {
                key: "EXTRACT",
                name: "EXTRACT",
                insertText: "EXTRACT()",
                usages: [
                    "<b>EXTRACT</b>(FIELD FROM x)"
                ]
            },
            {
                key: "HOUR",
                name: "HOUR",
                insertText: "HOUR()",
                usages: [
                    "<b>HOUR</b>(x)"
                ]
            },
            {
                key: "MINUTE",
                name: "MINUTE",
                insertText: "MINUTE()",
                usages: [
                    "<b>MINUTE</b>(x)"
                ]
            },
            {
                key: "MONTH",
                name: "MONTH",
                insertText: "MONTH()",
                usages: [
                    "<b>MONTH</b>(x)"
                ]
            },
            {
                key: "MONTHNAME",
                name: "MONTHNAME",
                insertText: "MONTHNAME()",
                usages: [
                    "<b>MONTHNAME</b>(x)"
                ]
            },
            {
                key: "QUARTER",
                name: "QUARTER",
                insertText: "QUARTER()",
                usages: [
                    "<b>QUARTER</b>(x)"
                ]
            },
            {
                key: "SECOND",
                name: "SECOND",
                insertText: "SECOND()",
                usages: [
                    "<b>SECOND</b>(x)"
                ]
            },
            {
                key: "WEEK",
                name: "WEEK",
                insertText: "WEEK()",
                usages: [
                    "<b>WEEK</b>(x)"
                ]
            },
            {
                key: "YEAR",
                name: "YEAR",
                insertText: "YEAR()",
                usages: [
                    "<b>YEAR</b>(x)"
                ]
            },
            {
                key: "TIMESTAMPADD",
                name: "TIMESTAMPADD",
                insertText: "TIMESTAMPADD()",
                usages: [
                    "<b>TIMESTAMPADD</b>(interval, count, timestamp)"
                ]
            },
            {
                key: "TIMESTAMPDIFF",
                name: "TIMESTAMPDIFF",
                insertText: "TIMESTAMPDIFF()",
                usages: [
                    "<b>TIMESTAMPDIFF</b>(interval, startTime, endTime)"
                ]
            },
        ]
    },
    TypeConversionFunctions: {
        type: "TypeConversionFunction",
        formulas: [
            {
                key: "CONVERT",
                name: "CONVERT",
                insertText: "CONVERT()",
                usages: [
                    "<b>CONVERT</b>(x, type)"
                ]
            },
            {
                key: "CAST",
                name: "CAST",
                insertText: "CAST()",
                usages: [
                    "<b>CAST</b>(x AS type)"
                ]
            }
        ]
    },
    ConditionalFunctions: {
        type: "ConditionalFunction",
        formulas: [
            {
                key: "CASE",
                name: "CASE WHEN",
                insertText: 'CASE ()\n\tWHEN () THEN ()\n\tELSE ()\nEND\n',
                usages: [
                    "<b>CASE</b> (expr)<br />&emsp;<b>WHEN</b> (expr) <b>THEN</b> (expr)<br />&emsp;<b>WHEN</b> (expr) <b>THEN</b> (expr)<br />&emsp;∙∙∙<br />&emsp;<b>WHEN</b> (expr) <b>THEN</b> (expr)<br />&emsp;<b>ELSE</b> (expr)<br /><b>END</b>",
                    "<b>CASE</b><br />&emsp;<b>WHEN</b> (criteria) <b>THEN</b> (expr)<br />&emsp;<b>WHEN</b> (criteria) <b>THEN</b> (expr)<br />&emsp;∙∙∙<br />&emsp;<b>WHEN</b> (criteria) <b>THEN</b> (expr)<br />&emsp;<b>ELSE</b> (expr)<br /><b>END</b>"
                ]

            },
        ]

    }
};