import { SET_NAVIGATION } from "./NavigationAction";

/**
 * 
 * @param {*} state 
 * @param {*} action 
 * action for triggered navigation
 * 
 */
export default function NavigationReducer(
  state = {
      navigation: {}
  },
  action
) {
  switch (action.type) {
    case SET_NAVIGATION:
      return {
        ...state,
        navigation: action.payload
      };
    default:
      return state;
  }
}
