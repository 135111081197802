import React, { Component } from "react";
import Text from "../GeneralComponents/Text/Text";
import Button from "../GeneralComponents/Button/Button";
import { Input, Col, Row } from "antd";
import Tooltip from "../GeneralComponents/Tooltip/Tooltip";
import i18n from "../../Utils/i18next";

const clone = require("rfdc")();

const modelNameEmptyError = i18n.t("Model.ModelNameEmpty");
const modelNameUniqeError = i18n.t("Model.ModelNameUniqeError");

/** 
 * Creates a model by entering this component model name.
 */
export default class ModelCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modelName: "",
      modelNameError: false,
      createModelControl: false,
      modelNameErrorText: modelNameEmptyError,
    };
  }

  /** 
   * Model name is entered
   */
  handleCreateModelName = (e) => {
    this.setState({
      ...this.state,
      modelName: e.target.value,
      model: this.createModel(e.target.value.trim()),
    });
  };

  /** 
   * Model creates
   */
  createModel = (modelName) => {
    let model = {
      displayName: modelName,
      name: modelName,
      businessModel: modelName,
      tables: [],
      new: true,
    };

    return model;
  };

  /** 
   * The visible status change method for the model name entry field
   */
  createModelVisibleChange = (status) => {
    this.props.createModelVisibleChange(status);
  };

  /** 
   * Model name uniq name and validation control operations
   */
  modelNameSet = () => {
    let modelList = clone(this.props.modelList);
    let newState = {...this.state}

    if (this.state.modelName.trim() !== "") {
      modelList = modelList.filter((m) => {
        return m.name == this.state.modelName.trim();
      });

      if (modelList.length > 0) {
        newState.modelNameErrorText = modelNameUniqeError
        newState.modelNameError = true
      } else {
        newState.createModelControl = true
        newState.modelNameError = false
      }
    } else {
      newState.modelNameError = true
      newState.modelNameErrorText = modelNameEmptyError
    }

    this.setState(newState)
  };

  /** 
   * It checks whether the model name is empty in the new model created and sends the model to the parent component.
   */
  addNewModel = () => {
    this.props.addNewModel(this.state.model);
  };

  render() {
    return (
      <div className={"model-create"} style={{ width: "67%", margin: "auto" }}>
        <Text type="span" className={"model-description"}>
          {i18n.t("Model.CreateModelNameNotification")}
        </Text>
        {!this.state.createModelControl ? (
          <Row gutter={6} style={{ margin: "68px 0" }}>
            <Col span={18}>
              <Input
                onChange={this.handleCreateModelName}
                onPressEnter={this.modelNameSet}
                placeholder={i18n.t("Model.ModelName")}
                value={this.state.modelName}
                id="modelName"
              />
              {this.state.modelNameError ? (
                <Text
                  id="modelNameError"
                  type="span"
                  style={{
                    fontSize: "14px",
                    display: "block",
                    marginTop: "15px",
                    color: "rgb(222, 104, 104)",
                    textAlign: "left",
                    whiteSpace: "normal",
                  }}
                >
                  {this.state.modelNameErrorText}
                </Text>
              ) : null}
            </Col>
            <Col span={3}>
              <Tooltip
                tooltip={i18n.t("ModelNamingDone")}
              >
                <Button
                  id="createModelButton"
                  style={{ width: "100%", margin: "0", fontSize: "10px", padding:"0px" }}
                  onClick={this.modelNameSet}
                  className={"general-button"}
                >
                  <Text type="span">{i18n.t("Create")}</Text>
                </Button>
              </Tooltip>
            </Col>
            <Col span={3}>
              <Tooltip tooltip={"Cancel model create."}>
                <Button
                  id="cancelCreateButton"
                  style={{
                    width: "100%",
                    background: "red",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "10px",
                    padding:"0px 3px"
                  }}
                  onClick={() => this.createModelVisibleChange(false)}
                >
                  <Text type="span">{i18n.t("Cancel")}</Text>
                </Button>
              </Tooltip>
            </Col>
          </Row>
        ) : null}
        {this.state.createModelControl ? (
          <Row>
            <Col style={{ marginTop: "40px" }}>
              <Text type={"h5"} style={{ margin: "0" }}>
                {i18n.t("Model.ModelName")}: {this.state.modelName}
              </Text>
            </Col>
            <Col span={24}>
              <Text
                className={"model-description"}
                type="span"
                style={{ display: "block", marginBottom: "20px" }}
              >
                {i18n.t("Model.ModelAfterCreateDescription")}
              </Text>
              <Button
                id="letsGoButton"
                className={"general-button"}
                style={{ width: "fit-content" }}
                onClick={() => this.addNewModel()}
              >
                {i18n.t("Model.ModelAfterCreateButton")}
              </Button>
            </Col>
          </Row>
        ) : null}
      </div>
    );
  }
}
