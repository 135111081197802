    import {
        authenticatedUser
    } from "../../Utils/Auth";
    import Cookies from "js-cookie";

    /**
     * Gets authenticated user's selected labels from cookie if exists, otherwise returns empty array
     */
    export const getSeletectedLabels = () => {
        const key = authenticatedUser + "selectedLabels";
        const stored = Cookies.get(key);
        if (stored != undefined) {
            return JSON.parse(stored);
        }
        return [];
    }

    /**
     * Removes all cookies related to labels
     */
    export const removeSelectedLabels = () => {
        const key = authenticatedUser + "selectedLabels";
        Cookies.set(key, {});
    }

    /**
     * First converts given labels to json string then sets value to user specific cookie
     * @param {Selected Labels} labels 
     */
    export const setSelectedLabels = (labels) => {
        const key = authenticatedUser + "selectedLabels";
        Cookies.set(key, JSON.stringify(labels));
    }