import React, { Component } from "react";
import { Input, Icon, Tooltip } from "antd";
import DashboardTitleEditorPopUp from "./DashboardTitleEditorPopUp";
import i18n from "../../Utils/i18next";
import { showNotificationWithIcon } from "../../admin/Utils/Notification";
import $ from "jquery";
import { isValidWriteRoles } from "../DashboardPage/RoleStore";
import { connect } from "react-redux";
import { changeCustomDashboardStatus } from "../GeneralComponents/CustomDashboard/CustomDashboardAction";
import { store } from "../..";
import { MdOutlineDashboardCustomize } from "react-icons/md"

const clone = require("rfdc")();

//This file contains all the configuration props of the dashboard title and itself. handleChange functions for the configuration are here.
class DasboardTitle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dashboardTitle: this.props.settings.title,
      titleChangeStatus: false,
      visibleEditor: false,
      titleFontWeight: "normal",
      titleTextDecor: "none",
      titleFontStyle: "normal",
      titleFontSize:  +16,
      titleColor: "black",
      titleFont: "Arial",
      titleColorChangeVisible: false,
    };
  }

  componentWillMount() {
    let isDashboardTitleSettingsValid = this.props.settings && this.props.settings.dashboardTitleSettings;
    
    if (isDashboardTitleSettingsValid) {
      this.setState({
        ...this.state,
        dashboardTitle: this.props.settings.title,
        titleFontWeight: this.props.settings.dashboardTitleSettings.titleFontWeight,
        titleTextDecor: this.props.settings.dashboardTitleSettings.titleTextDecor,
        titleFontStyle: this.props.settings.dashboardTitleSettings.titleFontStyle,
        titleFontSize: this.props.settings.dashboardTitleSettings.titleFontSize,
        titleColor: this.props.settings.dashboardTitleSettings.titleColor,
        titleFont: this.props.settings.dashboardTitleSettings.titleFont,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    let newState = { ...this.state };
    let isTitlesNewStateOutdated = newState.dashboardTitle !== this.props.settings.title;
    let titleChanged = nextProps.settings.title !== this.props.settings.title;

    if ( titleChanged || isTitlesNewStateOutdated ) {
      newState.dashboardTitle = nextProps.settings.title;
    }

    if (nextProps.titleColorChangeVisible !== this.props.titleColorChangeVisible) {
      newState.titleColorChangeVisible = nextProps.titleColorChangeVisible;
    }

    if (nextProps.visibleEditor !== this.props.visibleEditor) {
      newState.visibleEditor = nextProps.visibleEditor;
    }

    if (nextProps.titleConfig.titleFontWeight !== this.props.titleConfig.titleFontWeight) {
      newState.titleFontWeight = nextProps.titleConfig.titleFontWeight;
    }

    if (nextProps.titleConfig.titleFontStyle !== this.props.titleConfig.titleFontStyle) {
      newState.titleFontStyle = nextProps.titleConfig.titleFontStyle;
    }

    if (nextProps.titleConfig.titleTextDecor !== this.props.titleConfig.titleTextDecor) {
      newState.titleTextDecor = nextProps.titleConfig.titleTextDecor;
    }

    if (nextProps.titleConfig.titleFontSize !== this.props.titleConfig.titleFontSize) {
      newState.titleFontSize = nextProps.titleConfig.titleFontSize;
    }

    if (nextProps.titleConfig.titleColor !== this.props.titleConfig.titleColor) {
      newState.titleColor = nextProps.titleConfig.titleColor;
    }

    if (nextProps.titleConfig.titleFont !== this.props.titleConfig.titleFont) {
      newState.titleFont = nextProps.titleConfig.titleFont;
    }

    this.setState(newState);
  }

  /* This method updates the dashboard title's configuration. Sets value to corresponding configuration type. */
  updateConfig = (configType, value) => {
    let newTitleConfig = clone(this.props.titleConfig);

    switch (configType) {
      case "titleFontWeight":
        newTitleConfig.titleFontWeight = value;
        break;

      case "titleTextDecor":
        newTitleConfig.titleTextDecor = value;
        break;

      case "titleFontStyle":
        newTitleConfig.titleFontStyle = value;
        break;

      case "titleFontSize":
        newTitleConfig.titleFontSize = value;
        break;

      case "titleColor":
        newTitleConfig.titleColor = value;
        break;

      case "titleFont":
        newTitleConfig.titleFont = value;
        break;

      default:
        break;
    }

    this.props.changeTitleConfig(newTitleConfig);
  };

  /* Controls if any change has been made and helps to show returnToDefault button accordingly */
  isDefaultConfigShow = () => {
    return ( this.props.titleConfig.defaultTitle !== this.props.settings.title ||
        this.props.titleConfig.defaultTitleFontWeight !== this.props.titleConfig.titleFontWeight ||
        this.props.titleConfig.defaultTitleTextDecor !== this.props.titleConfig.titleTextDecor ||
        this.props.titleConfig.defaultTitleFontStyle !== this.props.titleConfig.titleFontStyle ||
        this.props.titleConfig.defaultTitleFontSize !== this.props.titleConfig.titleFontSize ||
        this.props.titleConfig.defaultTitleColor !== this.props.titleConfig.titleColor ||
        this.props.titleConfig.defaultTitleFont !== this.props.titleConfig.titleFont )
      ? true
      : false;
  };

  /* Sets title and its configuration to their defaults (last saved version). 
  Returns title default size in order to help for reverting the value at input field. */
  setConfigDefault = () => {
    let newTitleConfig = clone(this.props.titleConfig);

    newTitleConfig.titleFontWeight = newTitleConfig.defaultTitleFontWeight;
    newTitleConfig.titleTextDecor = newTitleConfig.defaultTitleTextDecor;
    newTitleConfig.titleFontStyle = newTitleConfig.defaultTitleFontStyle;
    newTitleConfig.titleFontSize = newTitleConfig.defaultTitleFontSize;
    newTitleConfig.titleColor = newTitleConfig.defaultTitleColor;
    newTitleConfig.titleFont = newTitleConfig.defaultTitleFont;

    this.setState({
      ...this.state,
      titleColorChangeVisible: false
    });

    let newSettings = clone(this.props.settings);
    newSettings.title = newTitleConfig.defaultTitle;

    this.props.changeTitleConfig(newTitleConfig, newSettings);

    return newTitleConfig.defaultTitleFontSize;
  };

  /* Changes the dashboard title with a new one. If title empty gives notification */
  titleOnChange = (e) => {
    if (e.target.value === "") {
      showNotificationWithIcon(i18n.t("Warning"), i18n.t("Dashboard.Save.TitleEmptyMessage"), "warning" );
    }

    this.setState({ dashboardTitle: e.target.value });
    this.setTitle(e.target.value);
  };

  /* Setting str to title */
  setTitle = (str) => {
    let newSettings = clone(this.props.settings);
    newSettings.title = str;

    this.props.changeSettings(newSettings);
  };

  /* Opens title's text editor pop up */
  openTextEditor = () => {
    this.setState({ visibleEditor: true });
  };

  /* Closes title's text editor pop up */
  closeTextEditor = () => {
    this.setState({ visibleEditor: false });
  };

  /* Changes title's font weight to bold if it is normal or vice versa */
  changeFontWeight = () => {
    if (this.state.titleFontWeight === "normal") {
      this.updateConfig("titleFontWeight", "bold");
    } else if (this.state.titleFontWeight === "bold") {
      this.updateConfig("titleFontWeight", "normal");
    }
  };

  /* Changes title's text decoration to underlined if it is none or vice versa */
  changeTextDecor = () => {
    if (this.state.titleTextDecor === "none") {
      this.updateConfig("titleTextDecor", "underline");
    } else if (this.state.titleTextDecor === "underline") {
      this.updateConfig("titleTextDecor", "none");
    }
  };

  /* Changes title's font style to italic if it is normal or vice versa */
  changeFontStyle = () => {
    if (this.state.titleFontStyle === "normal") {
      this.updateConfig("titleFontStyle", "italic");
    } else if (this.state.titleFontStyle === "italic") {
      this.updateConfig("titleFontStyle", "normal");
    }
  };

  /* Changes title's font size to typed value if its in the right interval 
  if not sets max or min value to size and warns user by making background slightly red and giving notification. */
  changeFontSize = (e) => {
    let size;

    if (Number(e.target.value) > 40) {
      size = 40;

      $("#titleFontSize").css("background", "#ff000042");
    } else if (Number(e.target.value) < 11) {
      size = 11;

      $("#titleFontSize").css("background", "#ff000042");
    } else {
      size = Number(e.target.value);

      $("#titleFontSize").css("background", "");
    }

    this.updateConfig("titleFontSize", size);
  };

  /* Changes title's color to selected color */
  changeColor = (obj) => {
    this.updateConfig("titleColor", obj.color);
  };

  /* Helps to open color picker */
  makeColorChangeVisible = () => {
    this.setState({
      titleColorChangeVisible: !this.state.titleColorChangeVisible,
    });
  };

  /* Changes title's font family to selected value */
  changeFont = (value) => {
    this.updateConfig("titleFont", value);
  };

  render() {
    let isCustomDashboardMode = store.getState().CustomDashboardReducer.isCustomDashboard ? true : false

    return (
      <div
        className="dashboard-title"
        style={{
          zIndex: 2,
          display: "block",
          alignItems: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "70%",
          textAlign: "center",
        }}
      >
        {this.state.titleChangeStatus ? null : (
          <span
            title={this.state.dashboardTitle}
            onDoubleClick={() => {
              this.setState({ titleChangeStatus: isValidWriteRoles() || isCustomDashboardMode });
            }}
            style={{
              maxWidth: "80%",
              display: "inline-block",
              verticalAlign: "middle",
              paddingRight: "8px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "center",
              fontWeight: this.state.titleFontWeight,
              fontStyle: this.state.titleFontStyle,
              textDecoration: this.state.titleTextDecor,
              fontSize: this.state.titleFontSize,
              fontFamily: this.state.titleFont,
              color: this.state.titleColor,
            }}
          >
            <Tooltip title={i18n.t("CustomDashboard.CustomDashboard")}>
              <span style={{marginRight: "5px", fontSize: "19px", color: "#4e7da2"}}>{this.props.isCustomDashboard ? <MdOutlineDashboardCustomize /> : null}</span>
            </Tooltip>
            {this.state.dashboardTitle == "" ? i18n.t("TitleNotSet") : this.state.dashboardTitle }
          </span>
        )}

        {this.state.titleChangeStatus ? (
          <Input
            style={{
              width: "80%",
              display: "inline-block",
              textAlign: "center",
              verticalAlign: "middle",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "center",
              border: "none",
              borderBottom: " 1px solid #226190",
              fontWeight: this.state.titleFontWeight,
              height: `${this.state.titleFontSize + 10}px`,
              fontStyle: this.state.titleFontStyle,
              textDecoration: this.state.titleTextDecor,
              fontSize: this.state.titleFontSize,
              fontFamily: this.state.titleFont,
              color: this.state.titleColor,
            }}
            placeholder={i18n.t("TitleNotSet")}
            onChange={this.titleOnChange}
            onPressEnter={() => {
              this.setState({ titleChangeStatus: false });
            }}
            id={"dashboardTitle"}
            value={this.state.dashboardTitle}
          />
        ) : null}

        {isValidWriteRoles() || isCustomDashboardMode ? (
          <div
            style={{
              alignItems: "center",
              display: "inline-block",
              position: "relative",
              width: "16px",
              verticalAlign: "middle",
            }}
          >
            <a onClick={this.openTextEditor}>
              <Icon
                style={{
                  display: "flex",
                  verticalAlign: "middle",
                  fontSize: "16px",
                  boxSizing: "border-box"
                }}
                type="edit"
              ></Icon>
            </a>
          </div>
        ) : null}

        {this.state.visibleEditor ? (
          <div style={{ display: "inline-block", position: "relative" }}>
            <DashboardTitleEditorPopUp
              isDefaultConfigShow={this.isDefaultConfigShow}
              setConfigDefault={this.setConfigDefault}
              changeFontWeight={this.changeFontWeight}
              isBold={this.state.titleFontWeight === "bold"}
              changeFontStyle={this.changeFontStyle}
              isItalic={this.state.titleFontStyle === "italic"}
              changeTextDecor={this.changeTextDecor}
              isUnderlined={this.state.titleTextDecor === "underline"}
              dashboardTitle={this.state.dashboardTitle}
              titleOnChange={this.titleOnChange}
              fontSize={this.state.titleFontSize}
              changeFontSize={this.changeFontSize}
              titleFont={this.state.titleFont}
              changeFont={this.changeFont}
              color={this.state.titleColor}
              makeColorChangeVisible={this.makeColorChangeVisible}
              colorChangeVisible={this.state.titleColorChangeVisible}
              changeColor={this.changeColor}
              openTextEditor={this.openTextEditor}
              closeTextEditor={this.closeTextEditor}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
/*
* Connected to custom dashboard status for read operator title changes
*/
const mapDispatchToProps = {
  changeCustomDashboardStatus,
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DasboardTitle);
