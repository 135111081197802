import React, { Component } from "react";
import { Divider, Row, Col, Button, Input } from "antd";
import Text from "../../../../GeneralComponents/Text/Text";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import i18n from "../../../../../Utils/i18next";
import { FontSizeOutlined, BgColorsOutlined } from "@ant-design/icons";

/**
 * Filter configuration general appearance category component
 */
export default class GeneralAppearance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backgroundColor: this.props.config.backgroundColor,
      arrowColor: this.props.config.arrowColor,
      placeholder: this.props.config.placeholder,
      placeholderColour: this.props.config.placeholderColour,
      textBackgroundColor: this.props.config.textBackgroundColor
    };
  }

  /**
   * Plugin compenents receive it's created properties
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      this.setState({
        arrowColor: nextProps.config.arrowColor,
        backgroundColor: nextProps.config.backgroundColor,
        placeholder: nextProps.config.placeholder,
        placeholderColour: nextProps.config.placeholderColour,
        textBackgroundColor: nextProps.config.textBackgroundColor
      });
    }
  }

  /**
   * Changes the background colour attribute in Filter plugin use backgroundColorOnChange
   */
  backgroundColorOnChange = obj => {
    this.setState({
      ...this.state,
      backgroundColor: obj.color
    });

    this.props.setConfigsToPlugin({ type: "backgroundColor", value: obj.color }, this.props.config);
  };

  /**
   * Demonstrates how the choosen color will affect the interface in Filter plugin
   */
  backgroundColorChangeVisible = () => {
    this.setState({
      ...this.state,
      backgroundColorChange: !this.state.backgroundColorChange
    });
  };

  /**
   * Changes the value of text attributes in Filter plugin use handleChange
   */
  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    });

    this.props.setConfigsToPlugin({ type: e.target.id, value: e.target.value }, this.props.config);
  };

  /**
   * Handles arrow color changes.
   */
  arrowColorOnChange = obj => {
    this.setState({
      ...this.state,
      arrowColor: obj.color
    });

    this.props.setConfigsToPlugin({ type: "arrowColor", value: obj.color }, this.props.config);
  };

  /**
   * Change arrow color picker visibility
   */
  arrowColorChangeVisible = () => {
    this.setState({
      ...this.state,
      arrowColorChange: !this.state.arrowColorChange
    });
  };

  /**
   * Handles placeholder color changes.
   */
  placeholderColorOnChange = obj => {
    this.setState({
      ...this.state,
      placeholderColour: obj.color
    });

    this.props.setConfigsToPlugin({
      type: "placeholderColour",
      value: obj.color
    }, this.props.config);
  };

  /**
   * Demonstrates how the choosen color will affect the interface in Filter plugin
   */
  placeholderColorChangeVisible = () => {
    this.setState({
      ...this.state,
      placeholderColourChange: !this.state.placeholderColourChange
    });
  };

  /**
   * Added text color, arrow color and placeholder function
   */
  textBackgroundColorOnChange = obj => {
    this.setState({
      ...this.state,
      textBackgroundColor: obj.color
    });

    this.props.setConfigsToPlugin({
      type: "textBackgroundColor",
      value: obj.color
    }, this.props.config);
  };

  /**
   * Change text background color picker visibility
   */
  textBackgroundColorChangeVisible = () => {
    this.setState({
      ...this.state,
      textBackgroundColorChange: !this.state.textBackgroundColorChange
    });
  };

  /**
   * Changes the color attribute in Filter plugin use colorOnChange
   */
  colorOnChange = obj => {
    this.setState({
      ...this.state,
      colour: obj.color
    });

    this.props.setConfigsToPlugin({ type: "colour", value: obj.color }, this.props.config);
  };

  render() {
    const { configurationTemplate, configurationCategoryTemplate } = this.props;

    return (
      <div>
        {configurationCategoryTemplate.has("generalAppearance") ? (
          <Divider
            key={"generalAppearance"}
            className={"config-divider"}
            orientation="left"
          >
            {i18n.t("Dashboard.Configuration.Fields.GeneralAppearance")}
          </Divider>
        ) : null}

        {configurationTemplate.has("backgroundColor") ? (
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.BackgroundColor")}
              </Text>
            </Col>
            <Col span={12}>
              <Button
                onClick={this.backgroundColorChangeVisible}
                style={{ width: "100%" }}
              >
                {this.state.backgroundColorChange
                  ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                  : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
              </Button>
              {this.state.backgroundColorChange ? (
                <ColorPickerPanel
                  alpha={100}
                  color={this.state.backgroundColor}
                  onChange={this.backgroundColorOnChange}
                  id={"backgroundColor"}
                  style={{ marginTop: "5px", position: "absolute", right: 0 }}
                />
              ) : null}
            </Col>
          </Row>
        ) : null}

        {configurationTemplate.has("arrowColor") ? (
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.ArrowColor")}
              </Text>
            </Col>
            <Col span={12}>
              <Button
                onClick={this.arrowColorChangeVisible}
                style={{ width: "100%" }}
              >
                {this.state.arrowColorChange
                  ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                  : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
              </Button>
              {this.state.arrowColorChange ? (
                <ColorPickerPanel
                  alpha={100}
                  color={this.state.arrowColor}
                  onChange={this.arrowColorOnChange}
                  id={"arrowColor"}
                  style={{ marginTop: "5px", position: "absolute", right: 0 }}
                />
              ) : null}
            </Col>
          </Row>
        ) : null}

        {configurationTemplate.has("textBackgroundColor") ? (
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.TextBackgroundColor")}
              </Text>
            </Col>
            <Col span={12}>
              <Button
                onClick={this.textBackgroundColorChangeVisible}
                style={{ width: "100%" }}
              >
                {this.state.textBackgroundColorChange
                  ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                  : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
              </Button>
              {this.state.textBackgroundColorChange ? (
                <ColorPickerPanel
                  alpha={100}
                  color={this.state.textBackgroundColor}
                  onChange={this.textBackgroundColorOnChange}
                  id={"textBackgroundColor"}
                  style={{ marginTop: "5px", position: "absolute", right: 0 }}
                />
              ) : null}
            </Col>
          </Row>
        ) : null}

        {configurationTemplate.has("placeholder") ? (
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.PlaceHolder")}
              </Text>
            </Col>
            <Col span={12}>
              <Input
                id={"placeholder"}
                defaultValue={this.state.placeholder}
                value={this.state.placeholder}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
        ) : null}

        {configurationTemplate.has("placeholderColour") ? (
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.PlaceHolderColor")}
              </Text>
            </Col>
            <Col span={12}>
              <Button
                onClick={this.placeholderColorChangeVisible}
                style={{ width: "100%" }}
              >
                {this.state.placeholderColourChange
                  ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                  : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
              </Button>
              {this.state.placeholderColourChange ? (
                <ColorPickerPanel
                  alpha={100}
                  color={this.state.placeholderColour}
                  onChange={this.placeholderColorOnChange}
                  id={"placeholderColor"}
                  style={{ marginTop: "5px", position: "absolute", right: 0 }}
                />
              ) : null}
            </Col>
          </Row>
        ) : null}
      </div>
    );
  }
}
