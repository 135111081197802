import {
  post,
} from "./WebService";
import {
  eraseCookie,
  createCookie,
  parseJwt,
  navigate
} from "./Global";
import Axios from "axios";
import Cookies from "js-cookie";
import { getCustomization } from "./Customization";

export const authToken = Cookies.get("ORA_BIPS_NQID");
export const refreshToken = Cookies.get("ORA_BIPS_NQIDR");
export const authenticatedUser = Cookies.get("authenticatedUser");

/**
 * Get a new access token with refresh token
 * 
 * @param {*} callback 
 */
export const refreshAccessToken = (callback) => {
  const url = `/security/oauth/token`;
  
  const successFunc = (result) => {
    let token = result.data;

    Axios.defaults.headers.common["Authorization"] = `Bearer ${token.access_token}`;

    setAuthentication(token);
    getCustomization(token);

    callback();
  };

  const errorFunc = (error) => {
    console.error(error);

    navigate("/vispeahen/ui/login")
  };

  const headers = {
    "Authorization": "Basic " + btoa("mobile:pin"),
    "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
  };

  const params = new URLSearchParams();

  params.append("client_id", "mobile");
  params.append("grant_type", "refresh_token");
  params.append("refresh_token", refreshToken);
  params.append("username", authenticatedUser);

  removeAuthentication();

  post(url, params, successFunc, errorFunc, true, false, headers, false, undefined, true);
}

/**
 * Logout function
 * 
 * @param {*} successFunc 
 * @param {*} errorFunc 
 */
export const logout = (successFunc, errorFunc) => {
  const url = "/security/logoff";

  const successLogout = () => {
    removeAuthentication();

    if (successFunc) {
      successFunc();
    } else {
      naviagateToLogin();
    }
  }

  post(url, null, successLogout, successLogout);
};

/**
 * Checks whether user authenticated or not
 */
export const isAuthenticated = () => {
  const token = Cookies.get("ORA_BIPS_NQID");
  return token != undefined && token != null && token !== "";
}

/**
 * Navigates web site to login page
 */
export const naviagateToLogin = (delayAsMilliseconds = 250) => {
  navigate("/vispeahen/ui/login", delayAsMilliseconds);
}

/**
 * Sets tenant, roles, token adn authenticated user to cookie
 * @param {Access Token produced by authentication server} token 
 */
export const setAuthentication = (token) => {
  let sign = parseJwt(token.access_token);
  
  createCookie("ORA_BIPS_NQID", token.access_token, null, "/");
  createCookie("ORA_BIPS_NQIDR", token.refresh_token, null, "/");

  createCookie("tenant", sign.tenant, null, "/");
  createCookie("roles", sign.authorities, null, "/");
  createCookie("authenticatedUser", sign.user_name, null, "/");
  createCookie("operationTypes", getRolesOperations(sign.operationTypes), null, "/");
  createCookie("lastUser", sign.user_name, null, "/");
}

/**
 * Removes all authentication related cookies
 */
export const removeAuthentication = () => {
  eraseCookie('ORA_BIPS_NQID', '/');
  eraseCookie('ORA_BIPS_NQIDR', '/');
  eraseCookie('tenant', '/');
  eraseCookie('roles', '/');
  eraseCookie('authenticatedUser', '/');

  localStorage.removeItem("copiedPlugin");
  sessionStorage.removeItem("currentSlider");
}

/**
 *
 * @param {*} operationTypes
 * @returns
 * Separates the operations of the roles and assigns them to the array
 */
function getRolesOperations(operationTypes) {
  let rolesOperations = [];

  for (let operationField in operationTypes) {
    if (!rolesOperations.includes(operationTypes[operationField])) {
      rolesOperations.push(operationTypes[operationField]);
    }
  }

  return rolesOperations;
}