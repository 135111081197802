import React from "react";
import Search from "../Search/Search";
import Accordion from "../Accordion/Accordion";
import AccordionContent from "../Accordion/AccordionContent";
import EmptyDataContent from "../../Plugins/DataComponents/DataConstantContent/EmptyDataContent";
import PropTypes from "prop-types";
import BoxItem from "../BoxItem/BoxItem";
import { store } from "../../..";
import { setFormulaEditorContent } from "./FormulaEditorAction";
import Text from "../Text/Text";
import i18n from "../../../Utils/i18next";
import { sortByLanguage } from "../../../Utils/SortByLanguage";
import { deepCopy } from "../../../Utils/Global";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { connect } from "react-redux";
import EmptyExpressionContent from "./EmptyExpressionContent";
import _ from "lodash";

/**
 * This components consists of expression parameter list and search bar
 */
class ExpressionParameterList extends React.Component {
  constructor(props) {
    super(props);

    this.prototypes = {
      model: PropTypes.object,
    };

    this.state = {
      searchTempData: Array.from(this.props.definedExpressionSet.values()),
    };
  }

  componentWillReceiveProps(nextProps) {
    let newState = { ...this.state };

    if (!_.isEqual(this.props.definedExpressionSet, nextProps.definedExpressionSet)) {
      newState.searchTempData = Array.from(nextProps.definedExpressionSet.values());
    }

    if (newState !== this.state) {
      this.setState(newState);
    }
  }

  /**
   * Table search
   * @param tempTables tables for search
   * @param e event, value in input
   */
  tableSearch = (tempTables, e) => {
    let data = deepCopy(tempTables);

    return data.filter((datum) => {
      let displayName = datum
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
      let name = datum.toLowerCase().includes(e.target.value.toLowerCase());

      return displayName || name;
    });
  };

  /**
   * Column search
   * @param tempTables tables for search
   * @param e event, value in input
   */
  columnSearch = (tempTables, e) => {
    let data = deepCopy(tempTables);

    return data.filter((table) => {
      table.columns = table.columns.filter((column) => {
        let displayName = column.displayName
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
        let name = column.name
          .toLowerCase()
          .includes(e.target.value.toLowerCase());

        return displayName || name;
      });

      return table.columns.length !== 0;
    });
  };

  /**
   *
   * @param {*} e
   *
   * Search bar change event
   */
  handleSearch = (e) => {
    let tempTables = deepCopy(Array.from(this.props.definedExpressionSet.values()));
    let searchedTables = deepCopy(Array.from(this.props.definedExpressionSet.values()));

    if (e.target.value && e.target.value !== "") {
      //search category is empty
      if (this.state.searchCategory.name === undefined) {
        //first check table display names
        searchedTables = this.tableSearch(tempTables, e);

        //if data is empty, second check column display names
        if (searchedTables.length === 0) {
          tempTables = deepCopy(Array.from(this.props.definedExpressionSet.values()));
          searchedTables = this.columnSearch(tempTables, e);
        }
      } else if (this.state.searchCategory.name === "Table") {
        //search category is table
        searchedTables = this.tableSearch(tempTables, e);
      } else if (this.state.searchCategory.name === "Column") {
        //search category is data
        searchedTables = this.columnSearch(tempTables, e);
      }
    }

    this.setState({
      ...this.state,
      searchTempData: searchedTables,
    });
  };

  /**
   *
   * @param {*} item
   *
   * Sets selected search category
   */
  searchCategory = (item) => {
    this.setState({
      searchCategory: { ...item },
    });
  };

  /**
   *
   * @param {*} event
   * @returns
   *
   * Start dragging event
   */
  onDragStart = (event, data) => {
    let dragImage = event.target;
    let collapseIcon = event.target.querySelector(".collapse-icon");
    let extra = event.target.querySelector(".extra");
    let dropArea = document.getElementById("formula-editor-drop-area");

    dropArea.style.border = "dashed 2px #b7b7b7";
    dragImage.style.opacity = "0.4";

    if (collapseIcon) collapseIcon.style.visibility = "hidden";
    if (extra) extra.style.visibility = "hidden";

    event.dataTransfer.setData("text/plain", data);
    event.dataTransfer.setDragImage(dragImage, 0, 0);
  };

  /**
   *
   * @param {*} event
   *
   * End dragging event
   */
  onDragEnd = (event) => {
    let dragImage = event.target;
    let collapseIcon = dragImage.querySelector(".collapse-icon");
    let extra = dragImage.querySelector(".extra");
    let dropArea = document.getElementById("formula-editor-drop-area");

    if (collapseIcon) collapseIcon.style.visibility = "visible";
    if (extra) extra.style.visibility = "visible";

    dropArea.style.border = "solid 2px transparent";
    dragImage.style.opacity = "1";
  };

  /**
   *
   * @param {*} data
   *
   * Appends data to code
   */
  appendCode = (event, data) => {
    const reduxState = store.getState();
    const { contentObject } = reduxState.FormulaEditorReducer;

    contentObject.code += data;

    store.dispatch(setFormulaEditorContent(contentObject));

    // Prevent the parent element's event
    if (event) {
      event.cancelBubble = true;
      event.stopPropagation();
    }
  };

  /**
   * Sorts tables and columns
   *
   * @param {*} tables
   * @returns
   */
  sortTablesAndColumns = (tempTables) => {
    let reduxState = store.getState();
    let definedExpressionSet = reduxState.ExpressionParameterReducer.definedExpressionSet;
    let data = deepCopy(tempTables);

    // Sort Tables
    let sortedTables = data
    .filter(d => typeof d === "string" && d.length && d !== definedExpressionSet?.get(this.props.contentObject?.uniqeColumnId))
    .sort((a, b) => sortByLanguage(a, b));

    return sortedTables;
  };

  // Checks expression set list
  checkExpressionSetList = () => {
    let values = Array.from(this.props.definedExpressionSet.values())
    let filteredValues = values.filter(val => val !== "")

    if (filteredValues.length === 0) {
      return true
    }

    return false
  }

  render() {


    return (
      <div
        id="data-table-column-list"
        style={{
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          alignContent: "center",
          justifyItems: "center",
        }}
      >
        <div style={{ padding: "0 8px" }}>
          <Text
            style={{
              fontSize: "1rem",
              margin: "16px 0 8px 0",
              display: "block",
              width: "100%",
              textAlign: "center",
              color: "#225f8f",
            }}
          >
            {i18n.t("FormulaEditor.Titles.Parameters")}
          </Text>
          <Search
            preventDrop={true}
            paddingNone={true}
            marginBottom={"8px"}
            inputStyle={{ minWidth: "auto" }}
            id={"searchData"}
            width="100%"
            searchBarVisible={true}
            onChange={this.handleSearch}
            searchItemList={[
              { name: "Table", translate: "Table", icon: "fas fa-table" },
              { name: "Column", translate: "Column", icon: "fas fa-columns" },
            ]}
            searchCategory={this.searchCategory}
            selectedSearchCategory={this.state.searchCategory}
          ></Search>
        </div>
        <Accordion
          accordionStyle={{
            padding: "8px",
          }}
          defaultActiveKey={1}
          activeKey={1}
          accordionClass={"data-area-overflow"}
          titleClass={"dataAccordionHeader"}
          headerStyle={{
            background: "#fff",
            borderBottom: "1px solid #dadada",
          }}
          collapseStyle={{ background: "#fff" }}
        >
          {this.props.definedExpressionSet && this.state.searchTempData ? (
            <AccordionContent
              id={"expParam"}
              key={"expParam"}
              draggable={false}
              onDragStart={() => {}}
              onDragEnd={() => {}}
              onDblClick={() => {}}
              pluginId={this.props.pluginId}
              icon={
                <Tooltip
                  arrowPointAtCenter
                  title={i18n.t("Dashboard.Data.ExpressionParameters")}
                  placement="right"
                >
                  <InfoCircleOutlined
                    className="collapse-icon"
                    style={{
                      cursor: "default",
                      margin: 0,
                    }}
                  />
                </Tooltip>
              }
              title={i18n.t("Dashboard.Data.ExpressionParameters")}
              titleClass={"data-table-title"}
              headerStyle={{
                background: "#fff",
                borderBottom: "1px solid #dadada",
                cursor: "grab",
                transition: "none",
              }}
              collapseStyle={{ background: "#fff" }}
              eventKey={1}
            >
            {this.sortTablesAndColumns(this.state.searchTempData).length !== 0 ? 
            <>
              {this.sortTablesAndColumns(this.state.searchTempData).map(
                (param, index) => {
                  let paramName = param;
                  let paramData = `\${exp.${param}}`;
                  let key = param + index;
                  return (
                    <div
                      id={key}
                      key={key}
                      draggable="true"
                      onDragStart={(event) =>
                        this.onDragStart(event, paramData)
                      }
                      onDragEnd={(event) => this.onDragEnd(event)}
                      onDoubleClick={(event) =>
                        this.appendCode(event, paramData)
                      }
                      type="column"
                      style={{ position: "relative" }}
                    >
                      <BoxItem
                        tooltipTitle={paramName}
                        title={paramName}
                        divClassName={"noStyle"}
                        style={{ cursor: "grab", position: "relative" }}
                        extra={
                          <div style={{ height: "100%" }}>
                            <Tooltip
                              arrowPointAtCenter
                              title={paramName}
                              placement="right"
                            >
                              <InfoCircleOutlined
                                style={{
                                  cursor: "default",
                                  fontSize: "12px",
                                  color: "#484848",
                                  padding: "4px 8px",
                                }}
                              />
                            </Tooltip>
                          </div>
                        }
                      />
                    </div>
                  );
                }
              )}
            </> : i18n.t("FormulaEditor.EmptyExpressionParameterList")}
            </AccordionContent>
          ) : null}
        </Accordion>
      </div>
    );
  }
}
/**
 * Set action method to props
 */
const mapDispatchToProps = {};

/**
 * Set store objects to state
 *
 * @param {*} state
 * @returns
 */
const mapStateToProps = (state) => {
  return {
    definedExpressionSet: state.ExpressionParameterReducer.definedExpressionSet,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpressionParameterList);
