import React, { Component } from "react";
import "./customMultipleSwitch.css";
import PropTypes from "prop-types";
import i18n from "../../../Utils/i18next"

/**
 * Custom switch component
 * Styled
 * General component
 */
export default class CustomMultipleSwitch extends Component {
  constructor(props) {
    super(props);

    this.prototype = {
      hideOffButton: PropTypes.bool,
      data: PropTypes.array,
      selectedValue: PropTypes.string
    };
  }

  onChange = value => {
    let extra = this.props.changeValueExtraParameters
      ? this.props.changeValueExtraParameters
      : null;
    this.props.changeValue(value, extra);
  };

  render() {
    const selectedValue = this.props.selectedValue == undefined ? this.props.defaultValue : this.props.selectedValue
    return (
      <div className={"custom-option-single-select"}>
        {this.props.data.map(option => (
          <div
            id={option.id}
            name={option.name}
            className={
              selectedValue === option.value
                ? option.color
                  ? "option-item " + option.color
                  : "option-item default"
                : "option-item unselect"
            }
            onClick={() => this.onChange(option.value)}
          >
            <span style={{fontSize: '10px'}}>{option.title}</span>
          </div>
        ))}
      </div>
    );
  }
}
