import React, { Component } from "react";
import { DatePicker, Select, Row } from "antd";
import moment from "moment";
import uuid from "react-uuid";

const { RangePicker } = DatePicker;
const { Option } = Select;

/**
 * Includes date and datetime picker components
 */
export default class DateTimePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownValue: []
    };
  }

  componentWillReceiveProps(nextProps) {
    let operatorControl = nextProps.selectedFilterOperator !== "between" && this.state.dropdownValue.length > 0
    let refreshControl = nextProps.filterSelectionProps.refreshPluginStatus && this.state.dropdownValue.length > 0;
    let dropdownControl = nextProps.filterSelectionProps.dropdownAppearance !== this.props.filterSelectionProps.dropdownAppearance;

    if (nextProps.filterSelectionProps.dropdownAppearance === true && this.state.dropdownValue.length === 0) {
      let betweenValue = nextProps.dualCalender && Array.isArray(nextProps.value)
        ? nextProps.value.map(val => {
          try {
            return moment(val).locale("en");
          } catch {
            return null;
          }
        })
        : null

      if (betweenValue !== null) {
        this.setState({
          ...this.state,
          dropdownValue: [betweenValue[0].format(nextProps.format), betweenValue[1].format(nextProps.format)]
        })
      }
    } else if (operatorControl || refreshControl || dropdownControl) {
      this.setState({
        ...this.state,
        dropdownValue: []
      })
    }
  }

  // Triggers when select date from calender
  onChange = (dates, dateStrings) => {
    if (!dates) {
      this.props.onChange(null, "");
    }
  
    this.props.onChange(dates, dateStrings);
  };

  // chooses from dropdown
  onChangeDropdown = (dateStrings) => {
    let dates = dateStrings ? moment(dateStrings) : undefined;

    this.props.onChange(dates, dateStrings);
    this.props.onOk();
  };

  //Triggers when select date from two dropdown
  twoDropdownOnChange = (date1, date2) => {
    let dateStringsOne = date1 ? date1 : ""
    let dateStringsTwo = date2 ? date2 : ""
    let dateStrings = [dateStringsOne, dateStringsTwo]
    let dates = [];

    this.setState({
      ...this.state,
      dropdownValue: dateStrings
    })

    if ((dateStringsOne === "" && dateStringsTwo === "" && this.props.isClearable)) {
      this.props.onChange(null, null);
      this.props.onOk();
    } else{
      if (dateStringsOne != "") {
        dates[0] = moment(dateStringsOne)
      }

      if (dateStringsTwo != "") {
        dates[1] = moment(dateStringsTwo)
      }

      if (dateStringsOne != "" && dateStringsTwo != "") {
        this.props.onChange(dates, dateStrings);
        this.props.onOk();
      }
    }
  }

  onOk = () => {
    this.props.onOk();
  };

  /*
  * Triggers when operator is between and change only one input.
  */
  onCalendarChange = (dates) => {
    let dateStringsOne = dates[0] ? moment(dates[0]).format(this.props.format) : ""
    let dateStringsTwo = dates[1] ? moment(dates[1]).format(this.props.format) : ""
    let dateStrings = [dateStringsOne, dateStringsTwo]

    this.props.onChange(dates, dateStrings);
  }

  /**
   * Reorganizes option titles according to custom date format.
   */
  getOptionTitle = (value, spaceIndex, isTimestamp) => {
    let index = this.props.filterSelectionProps.columnMapForPlugin.filter.DataFormat.indexOf(" ")
    index = index === -1 ? this.props.filterSelectionProps.columnMapForPlugin.filter.DataFormat.length : index

    let dateFormat = this.props.filterSelectionProps.columnMapForPlugin.filter.DataFormat.toUpperCase().slice(0, index).replace("%Y", "year").replace("%M", "month").replace("%D", "day")
    let timestampFormat = this.props.filterSelectionProps.columnMapForPlugin.filter.DataFormat.toUpperCase().slice(index + 1).replace("%H", "hour").replace("%M", "minute").replace("%S", "second")
    let date = value.slice(0, spaceIndex)
    let timestamp = value.slice(spaceIndex)
    let year = date.slice(0, 4);
    let month = dateFormat.includes("month") ? date.slice(4, 6) : "";
    let day = dateFormat.includes("day") ? date.slice(6, 8) : "";
    let hour = timestamp.slice(0, 2);
    let minute = timestampFormat.includes("minute") ? timestamp.slice(2, 4) : "";
    let second = timestampFormat.includes("second") ? timestamp.slice(4, 6) : "";

    dateFormat = dateFormat.replace("year", year).replace("month", month).replace("day", day);
    timestampFormat = isTimestamp ? timestampFormat.replace("hour", hour).replace("minute", minute).replace("second", second) : "";

    return dateFormat + " " + timestampFormat
  }

  /**
   * Reformats option date values to a same format.
   */
  reformat = (value) => {
    let format = this.props.filterSelectionProps.columnMapForPlugin.filter.DataFormat.toUpperCase().replace("%Y", "Y").replace("%M", "M").replace("%D", "D");
    let newValue = "";

    let index = format.indexOf(" ");
    format = index !== -1 ? format.slice(0, index) : format

    let year = value.slice(0, 4);
    let month = value.slice(5, 7);
    let day = value.slice(8, 10);

    newValue = year;
    newValue += format.includes("M") ? month : "";
    newValue += format.includes("D") ? day : "";

    return newValue;
  }

  /**
   * Reformats option timestamp values to a same format.
   */
  getTimestampPart = (value) => {
    let format = this.props.filterSelectionProps.columnMapForPlugin.filter.DataFormat.toUpperCase().trim();
    let index = format.indexOf(" ");

    if (index === -1) {
      return "";
    }

    let timestamp = format.slice(index + 1);
    let newValue = "";
    let hour = value.slice(11, 13)
    let minute = value.slice(14, 16)
    let second = value.slice(17, 19)

    newValue = hour;
    newValue += timestamp.includes("%M") ? minute : "";
    newValue += timestamp.includes("%S") ? second : "";

    return newValue;
  }

  /**
   * Creates and returns options according to custom date format.
   */
  reduceOptions = (properties) => {
    let opts = new Set();
    let reformattedItem;
    let isTimestamp;
    let spaceIndex;

    if (properties.filterSelectionProps.plugin.data !== undefined) {
      properties.filterSelectionProps.plugin.data.map(item => {
        isTimestamp = this.getTimestampPart(item.filter) !== ""
        reformattedItem = this.reformat(item.filter) + this.getTimestampPart(item.filter);
        spaceIndex = this.reformat(item.filter).length

        if (!opts.has(reformattedItem)) {
          opts.add(reformattedItem)
        }
      })

      reformattedItem = [...opts].map(value => {
        let time = value;
        let date = value.slice(0, spaceIndex);
        let timestamp = value.slice(spaceIndex);
        let dateTime = date.slice(0, 4);
        let timestampTime = "";

        if (date.length === 6) {
          dateTime = date.slice(0, 4) + "-" + date.slice(4, 6);
        } else if (date.length === 8) {
          dateTime = date.slice(0, 4) + "-" + date.slice(4, 6) + "-" + date.slice(6, 8);
        }

        if (isTimestamp) {
          if (timestamp.length === 2) {
            timestampTime = timestamp.slice(0, 2);
          } else if (timestamp.length === 4) {
            timestampTime = timestamp.slice(0, 2) + ":" + timestamp.slice(2, 4);
          } else if (timestamp.length === 6) {
            timestampTime = timestamp.slice(0, 2) + ":" + timestamp.slice(2, 4) + ":" + timestamp.slice(4, 6);
          }
        }

        time = dateTime + " " + timestampTime

        let title = this.getOptionTitle(value, spaceIndex, isTimestamp)

        return {
          label: title,
          value: time
        }
      })
    }
    return reformattedItem;
  }

  /**
   * Checks whether data format type is custom or not.
   */
  isDataFormatTypeCustom = () => {
    return this.props.filterSelectionProps.columnMapForPlugin && this.props.filterSelectionProps.columnMapForPlugin.filter?.DataFormatType === "custom";
  }

  /**
   * Changes format in order to prevent a bug about moment().
   */
  getFormat = () => {
    let dataType = this.props.filterSelectionProps.dataType;
    let column = this.props.filterSelectionProps.columnMapForPlugin?.filter;
    let format = column?.DataFormat;

    if (typeof format === "undefined") {
      return dataType === "date" ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm:ss";
    }

    let hasFormatGotAnyTimePart = /%[HMSs]/.test(format);

    if (!format.includes("YYYY-MM-DD")) {
      return dataType === "timestamp" && hasFormatGotAnyTimePart ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD"
    }

    return format
  }

  /*
  * Returns unique values
  */
  returnValues = (data) => {
    let dataSet = new Set()

    for (let i = 0; i < data.length; i++) {
      dataSet.add(data[i].filter)
    }

    return Array.from(dataSet).map((item, i) => 
    <Option 
      key={uuid()} 
      label={moment(item).format(this.props.format)} 
      value={moment(item).format(this.props.format)}>
          {moment(item).format(this.props.format)}
    </Option>)
  }

  render() {
    if (this.props.filterSelectionProps.dropdownAppearance === true) {
      let data = this.props.filterSelectionProps?.plugin?.data;
      let isDataFormatTypeCustom = this.isDataFormatTypeCustom();
      let reducedData = isDataFormatTypeCustom ? this.reduceOptions(this.props) : null

      return (
        this.props.dualCalender === false ? (
          <Select            
            showSearch
            optionFilterProp="label"
            id={"dropdownAppearance-" + this.props.pluginId}
            style={{ width: "100%" }}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={this.onChangeDropdown}
            placeholder={this.props.format}
            disabled={this.props.disabled ? this.props.disabled : false}
            value={
              this.props.value && this.props.format
                ? isDataFormatTypeCustom
                  ? moment(this.props.value).locale('en')._i
                  : moment(this.props.value).locale('en').format(this.props.format)
                : undefined
            }
            allowClear={this.props.isClearable}
            isClearable={this.props.isClearable}
          >
            {typeof data !== "undefined" ?
              isDataFormatTypeCustom ? (
                reducedData.map(item => <Option label={item.label} value={item.value}>{item.label}</Option>)
              ) : (
                this.returnValues(data)
              )
              : null
            }
          </Select>
        ) : (
          <Row>
            <div
              style={{
                display: "inline-block",
                width: this.props.filterSelectionProps.plugin != undefined ? (this.props.filterSelectionProps.plugin.w <= 5 ? "45%" : "48%") : "48%",
              }}
            >
              <Select
                showSearch
                optionFilterProp="label"
                id={"dropdownAppearance-between1-" + this.props.pluginId}
                style={{ width: "100%" }}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(date) => this.twoDropdownOnChange(date, this.state.dropdownValue[1])}
                placeholder={this.props.format}
                disabled={this.props.disabled ? this.props.disabled : false}
                value={
                  this.state.dropdownValue[0] && this.props.format
                    ? isDataFormatTypeCustom
                      ? moment(this.state.dropdownValue[0]).locale('en')._i
                      : moment(this.state.dropdownValue[0]).locale('en').format(this.props.format)
                    : undefined
                }
                allowClear={this.props.isClearable}
                isClearable={this.props.isClearable}
              >
                {typeof data !== "undefined" ?
                  isDataFormatTypeCustom ? (
                    reducedData.map(item => {
                      if (this.state.dropdownValue[1] == undefined || this.state.dropdownValue[1] === '') {
                        return <Option label={item.label} value={item.value}>{item.label}</Option>;
                      } else if (moment(item.value).locale('en') < moment(this.state.dropdownValue[1])) {
                        return <Option label={item.label} value={item.value}>{item.label}</Option>;
                      }
                    }
                    )
                  ) : (
                    data.map(item => {
                      if (this.state.dropdownValue[1] == undefined || this.state.dropdownValue[1] === '') {
                        return (
                          <Option
                            label={moment(item.filter).format(this.props.format)}
                            value={moment(item.filter).format(this.props.format)}
                          >
                            {moment(item.filter).format(this.props.format)}
                          </Option>
                        );
                      } else if (moment(item.filter) < moment(this.state.dropdownValue[1])) {
                        return (
                          <Option
                            label={moment(item.filter).format(this.props.format)}
                            value={moment(item.filter).format(this.props.format)}
                          >
                            {moment(item.filter).format(this.props.format)}
                          </Option>
                        );
                      }
                    }
                    )
                  ) : null
                }
              </Select>
            </div>
            <div
              style={{
                paddingTop: "4px",
                textAlign: "center",
                display: "inline-block",
                width: this.props.filterSelectionProps.plugin != undefined ? (this.props.filterSelectionProps.plugin.w <= 5 ? "10%" : "4%") : "4%",
              }}
            >
              ~
            </div>
            <div
              style={{
                display: "inline-block",
                width: this.props.filterSelectionProps.plugin != undefined ? (this.props.filterSelectionProps.plugin.w <= 5 ? "45%" : "48%") : "48%",
              }}
            >
              <Select
                showSearch
                optionFilterProp="label"
                id={"dropdownAppearance-between2-" + this.props.pluginId}
                style={{ width: "100%" }}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(date) => this.twoDropdownOnChange(this.state.dropdownValue[0], date)}
                placeholder={this.props.format}
                disabled={this.props.disabled ? this.props.disabled : false}
                value={
                  this.state.dropdownValue[1] && this.props.format
                    ? isDataFormatTypeCustom
                      ? moment(this.state.dropdownValue[1]).locale('en')._i
                      : moment(this.state.dropdownValue[1]).locale('en').format(this.props.format)
                    : undefined
                }
                allowClear={this.props.isClearable}
                isClearable={this.props.isClearable}
              >
                {typeof data !== "undefined" ?
                  isDataFormatTypeCustom ? (
                    reducedData.map(item => {
                      if (this.state.dropdownValue[0] == undefined || this.state.dropdownValue[0] === '') {
                        return <Option label={item.label} value={item.value}>{item.label}</Option>;
                      } else if (moment(item.value).locale('en') > moment(this.state.dropdownValue[0])) {
                        return <Option label={item.label} value={item.value}>{item.label}</Option>;
                      }
                    }
                    )
                  ) : (
                    data.map(item => {
                      if (this.state.dropdownValue[0] == undefined || this.state.dropdownValue[0] === '') {
                        return (
                          <Option
                            label={moment(item.filter).format(this.props.format)}
                            value={moment(item.filter).format(this.props.format)}
                          >
                            {moment(item.filter).format(this.props.format)}
                          </Option>
                        );
                      } else if (moment(item.filter) > moment(this.state.dropdownValue[0])) {
                        return (
                          <Option
                            label={moment(item.filter).format(this.props.format)}
                            value={moment(item.filter).format(this.props.format)}
                          >
                            {moment(item.filter).format(this.props.format)}
                          </Option>
                        );
                      }
                    }
                    )
                  ) : null
                }
              </Select>
            </div>
          </Row>
        )
      );
    } else {
      let format = this.getFormat()

      let extraFooter = this.props.renderExtraFooter
        ? this.props.renderExtraFooter
        : "";

      let value = this.props.value ? moment(this.props.value).locale("en") : null;
      let betweenValue = this.props.dualCalender && Array.isArray(this.props.value)
        ? this.props.value.map(val => {
          try {
            return moment(val).locale("en");
          } catch {
            return null;
          }
        })
        : null

      return (
        <div>
          {
            this.props.dualCalender === true ? (
              <div id={"range-picker-" + this.props.pluginId}>
                <RangePicker
                  ranges={this.props.ranges ? this.props.ranges : ""}
                  renderExtraFooter={extraFooter}
                  showTime={this.props.showTime}
                  onCalendarChange={this.onCalendarChange}
                  format={format}
                  placeholder={[format, format]}
                  onChange={this.onChange}
                  onOk={this.onOk}
                  onOpenChange={
                    this.props.onOpenChange ? this.props.onOpenChange : ""
                  }
                  style={{ minWidth: "100%", width: "100%" }}
                  disabled={this.props.disabled ? this.props.disabled : false}
                  open={this.props.open ? this.props.open : false}
                  value={betweenValue}
                  allowClear={this.props.isClearable}
                  isClearable={this.props.isClearable}
                />
              </div>
            ) : (
              <DatePicker
                format={format}
                renderExtraFooter={extraFooter}
                showToday={this.props.showToday ? this.props.showToday : false}
                showTime={this.props.showTime}
                placeholder={format}
                onChange={this.onChange}
                onOk={this.onOk}
                onOpenChange={
                  this.props.onOpenChange ? this.props.onOpenChange : ""
                }
                style={{
                  minWidth: "100%",
                  width: "100%"
                }}
                value={value}
                disabled={this.props.disabled ? this.props.disabled : false}
                open={this.props.open ? this.props.open : false}
                allowClear={this.props.isClearable}
                isClearable={this.props.isClearable}
              />
            )
          }
        </div>
      );
    }
  }
}
