export const fontTypes = [
    {name: "Arial", value: "Arial"},
    {name: "Arial_Black", value: "Arial Black"},
    {name: "Book_Antiqua", value: "Book Antiqua"},
    {name: "Bookman_Old_Style", value: "Bookman Old Style"},
    {name: "Century_Gothic", value: "Century Gothic"},
    {name: "Consolas", value: "Consolas"},
    {name: "Courier_New", value: "Courier New"},
    {name: "fantasy", value: "fantasy"},
    {name: "Georgia", value: "Georgia"},
    {name: "Helvetica", value: "Helvetica"},
    {name: "Impact", value: "Impact"},
    {name: "Open_Sans", value: "Open Sans"},
    {name: "Tahoma", value: "Tahoma"},
    {name: "Times_New_Roman", value: "Times New Roman"},
    {name: "Trebuchet_MS", value: "Trebuchet MS"},
    {name: "Verdana", value: "Verdana"},
]
