import React, { Component } from "react";
import { Row, Col, Switch, Radio, Button } from "antd";
import Tooltip from "../../GeneralComponents/Tooltip/Tooltip";
import { findAndSetColumnsFromColumnMap } from "../../GeneralComponents/SetColumnsFromColumnMap/SetColumnsFromColumnMap";
import i18n from "../../../Utils/i18next";
import {
  FunctionOutlined,
  AlertOutlined,
  ToolOutlined
} from "@ant-design/icons";
import Text from "../../GeneralComponents/Text/Text";
import RuleInput from "../../ConditionalFormatting/Rule/RuleInput";
import "./tableWhatIf.css";
import { showNotificationWithIcon } from "../../../Utils/Notification";

const INPUT = "INPUT";
const OUTPUT = "OUTPUT";

export default class TableWhatIf extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      enabled: false,
      type: INPUT,
      formula: ""
    };

    this.state = { ...this.initialState };
  }

  /** Switch enable or disable to what if. */
  enableDisable = () => {
    this.setState({ ...this.state, enabled: !this.state.enabled });
  };

  typeChange = e => {
    this.setState({ ...this.state, type: e.target.value });
  };

  /** Check what if output formula disable status */
  isOutputDisable = () => {
    return this.state.enabled === true && this.state.type === OUTPUT
      ? false
      : true;
  };

  /** set what if output formula */
  outputFormula = (data, type) => {
    this.setState({
      ...this.state,
      [type]: data
    });
  };

  /** For prepare columns to be used in ouput formula.  */
  prepareColumnsToBeUsedInOutputFormula = props => {
    let columns = findAndSetColumnsFromColumnMap(props);

    return [...columns]
  };

  componentWillMount() {
    let newState = {...this.state};
    let whatIf = this.props.column ? this.props.column.whatIf : undefined

    newState.columnsToBeUsed = this.prepareColumnsToBeUsedInOutputFormula(this.props);
    
    if (whatIf) {
      newState.enabled = whatIf.enabled;
      newState.type = whatIf.type;
      newState.formula = whatIf.formula; 
    }

    this.setState(newState);
  }

  componentWillReceiveProps(nextProps) {
    let newState = {...this.state};
    let isStateChange = false;

    if (nextProps.plugin.columnMap !== this.props.plugin.columnMap) {
      newState.columnsToBeUsed = this.prepareColumnsToBeUsedInOutputFormula(nextProps);
      isStateChange = true;
    }

    if (isStateChange) {
      this.setState(newState);
    }
  }

  /** What if apply button */
  applyButton = (
    <Button
      className={"general-button"}
      style={{ float: "right", margin: "0", width: "35%" }}
      onClick={() => this.apply()}
    >
      {i18n.t("Apply")}
    </Button>
  );

  /** What if set default button */
  clearButton = (
    <Button
      onClick={() => this.clear()}
      style={{
        float: "right",
        margin: "0 10px",
        borderBottom: "2px solid",
        width: "35%"
      }}
    >
      {i18n.t("Default")}
    </Button>
  );

  apply = () => {
    let columnProperties = { ...this.props.columnProperties };
    let formula = {};

    formula.rule = this.state.formula.rule;
    formula.ruleHTML = this.state.formula.ruleHTML;
    formula.searchIndexes = this.state.formula.searchIndexes;

    columnProperties.whatIf = {
      enabled: this.state.enabled,
      type: this.state.type,
      formula: formula
    };

    this.props.updateColumnProperties(columnProperties, true);

    showNotificationWithIcon(i18n.t("Success"), i18n.t("Dashboard.Data.WhatIf.WhatIfSuccessfullyAdded"), "success");
  };

  clear = () => {
    this.setState(
      { ...this.initialState, clearRule: true },
      this.setState({
        ...this.state,
        clearRule: false
      })
    );
  };

  render() {
    return (
      <div>
        <Row className={"bottom-divider"}>
          <Col span={24} style={{ marginTop: "2px", marginBottom: "5px" }}>
            <AlertOutlined style={{ fontSize: "16px" }} />
            <Text
              style={{
                fontSize: "16px",
                marginLeft: "4px",
                display: "inline-block",
                verticalAlign: "-0.25em"
              }}
            >
              {i18n.t("Dashboard.Data.WhatIf.Status")}
            </Text>
          </Col>
          <Col>
            {i18n.t("Disable")} &nbsp;
            <Switch
              id={"enableWhatIf-" + this.props.column.uniqueId}
              checked={this.state.enabled}
              onClick={() => this.enableDisable()}
            />
            &nbsp; {i18n.t("Enable")}
            <Tooltip
              id={"tableWhatIfEnableDescriptionTooltip"}
              tooltip={i18n.t("Dashboard.Data.WhatIf.EnableDescription")}
            >
              <span
                id="tableWhatIfEnableDescription"
                style={{ fontSize: "13px", color: "#226191", float: "right" }}
              >
                &nbsp;(?)&nbsp;
              </span>
            </Tooltip>
          </Col>
        </Row>
        <Row className={"bottom-divider"}>
          <Col span={24} style={{ marginTop: "2px", marginBottom: "5px" }}>
            <ToolOutlined style={{ fontSize: "16px" }} />
            <Text
              style={{
                fontSize: "16px",
                marginLeft: "4px",
                display: "inline-block",
                verticalAlign: "-0.25em"
              }}
            >
              {i18n.t("Dashboard.Data.WhatIf.Type")}
            </Text>
          </Col>
          <Col span={24}>
            <Radio.Group onChange={this.typeChange} value={this.state.type}>
              <Radio value={INPUT}>
                {i18n.t("Dashboard.Data.WhatIf.Input")}{" "}
                <Tooltip
                  id={"tableWhatIfOutputDescriptionTooltip"}
                  tooltip={i18n.t("Dashboard.Data.WhatIf.InputDescription")}
                >
                  <span
                    id="tableWhatIfInputDescription"
                    style={{
                      fontSize: "13px",
                      color: "#226191",
                      float: "right"
                    }}
                  >
                    &nbsp;(?)&nbsp;
                  </span>
                </Tooltip>
              </Radio>
              <Radio value={OUTPUT}>
                {i18n.t("Dashboard.Data.WhatIf.Output")}
                <Tooltip
                  id={"tableWhatIfOutputDescriptionTooltip"}
                  tooltip={i18n.t("Dashboard.Data.WhatIf.OutputDescription")}
                >
                  <span
                    id="tableWhatIfOutputDescription"
                    style={{
                      fontSize: "13px",
                      color: "#226191",
                      float: "right"
                    }}
                  >
                    &nbsp;(?)&nbsp;
                  </span>
                </Tooltip>
              </Radio>
            </Radio.Group>
          </Col>
        </Row>

        <Row className={"bottom-divider"}>
          <Col span={24} style={{ marginTop: "2px", marginBottom: "5px" }}>
            <FunctionOutlined style={{ fontSize: "16px" }} />
            <Text
              style={{
                fontSize: "16px",
                marginLeft: "4px",
                display: "inline-block",
                verticalAlign: "-0.25em"
              }}
            >
              {i18n.t("Dashboard.Data.WhatIf.OutputFormula")}
            </Text>
            <Tooltip
              id={"tableWhatIfOutputFormulaDescriptionTooltip"}
              tooltip={i18n.t("Dashboard.Data.WhatIf.OutputFormulaDescription")}
            >
              <span
                id="tableWhatIfOutputFormulaDescription"
                style={{
                  fontSize: "13px",
                  color: "#226191",
                  float: "right"
                }}
              >
                &nbsp;(?)&nbsp;
              </span>
            </Tooltip>
          </Col>
          <Col span={24}>
            <RuleInput
              updateRule={this.outputFormula}
              //clickedApply={this.props.clickedApply}
              columnsToBeUsed={this.state.columnsToBeUsed} // get column map columns.
              rule={this.state.formula}
              type={"formula"}
              disabledInput={this.isOutputDisable()}
              disabledText={i18n.t("Dashboard.Data.WhatIf.FormulaDisabled")}
              minHeight={"85px"}
              columnListPosition={"top"}
              clearRule={this.state.clearRule}
            />
          </Col>
        </Row>
        <Row>
          {this.applyButton}
          {this.clearButton}
        </Row>
      </div>
    );
  }
}
