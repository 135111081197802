import React from "react";
import { Card, useAccordionToggle } from "react-bootstrap";
/**
 * Accordion Toggle component
 */
const AccordionToggle = ({
    eventKey = String || Number,
    draggable = "false",
    onDragStart = Function,
    onDragEnd = Function,
    onClick = Function,
    onDoubleClick = Function,
    className = String,
    style = String,
    children,
}) => {
    let clickCount = 0;

    /**
     * Opens and closes the accordion
     */
    const toggleAccordion = useAccordionToggle(eventKey);

    /**
     * Accordion Toggle click handler
     * 
     * toggles accordion & changes active key on single click or
     * calls double click handler on  multiple clicks
     */
    const onClickHandler = () => {
        let timer = 0;

        clickCount++;

        timer = setTimeout(() => {
            if (clickCount === 1) {
                toggleAccordion();
                
                setTimeout(onClick);
            } else if (clickCount > 1) {
                onDoubleClick();
                
                clearTimeout(timer);
            }

            clickCount = 0;
        }, 200);
    };

    return (
        <Card.Header
            draggable={draggable}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onClick={onClickHandler}
            className={className}
            style={style}
        >
            {children}
        </Card.Header>
    );
}

export default AccordionToggle;