import { Checkbox, Col, Input, Row, Select, Tooltip } from "antd";
import React, { Component } from "react";
import CustomUploader from "./CustomUploader";
import i18n from "../../Utils/i18next";
import { get } from "../../Utils/WebService";
import { API_BASE } from "../../config";
import { connect } from "react-redux";

const { Option } = Select;

const fileTypes = {
  EXCEL: "EXCEL",
  CSV: "CSV",
  PARQUET: "PARQUET",
  SPSS: "SPSS",
  JSON: "JSON",
};

const splitters = {
  COMMA: ",",
  SEMI_COLON: ";",
  TAB: "tab",
  CUSTOM: "custom",
};

/**
 * Choose file operations for File Upload
 */
class ChooseFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileType: fileTypes.EXCEL,
      csvSplitter: ",",
      customSplitter: "",
      file: undefined,
      header: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.chooseFileValidation !== this.props.chooseFileValidation &&
      nextProps.chooseFileValidation === true
    ) {
      this.validation();
    }

    if (this.props.selfService.dataSourceId !== nextProps.selfService.dataSourceId) {
      this.selectSchemaNameIfNecessary(nextProps)
    }
  }

  componentWillMount() {
    this.selectSchemaNameIfNecessary(this.props);

    this.getModelList(this.props)
  }

  /**
   * Validations for choose file operations
   * @returns
   */
  validation = () => {
    let response = { status: true };

    if (this.state.file === undefined) {
      response.status = false;
      response.message = i18n.t("FileUpload.YouMustChooseAnyFile");

      this.props.validationResponse(response);
      return;
    }

    if (!this.state.schemaName) {
      response.status = false;
      response.message = i18n.t("FileUpload.YouMustChooseAnySchema");

      this.props.validationResponse(response);
      return;
    }

    this.props.setUploadParameters({ ...this.state });
    this.props.validationResponse(response);
    return;
  };

  handleFileUpload = (file) => {
    this.setState({ ...this.state, file: file });
  };

  onChangeFileType = (value) => {
    this.setState({
      ...this.state,
      fileType: value,
    });
  };

  onChangeSplitter = (value) => {
    this.setState({
      ...this.state,
      csvSplitter: value,
    });
  };

  /**
   * Renders content by file type
   * @returns
   */
  getFileTypeBaseContent = () => {
    const { fileType, csvSplitter, customSplitter } = this.state;

    if (fileType === fileTypes.CSV) {
      return null;
      /**
       * TODO: If auto detect splitter can not handle all case. This code will use.
       */
      // return (
      //   <>
      //     <Col span={8}>
      //       <b>Select Splitter:</b>
      //       <Select
      //         defaultValue={csvSplitter}
      //         value={csvSplitter}
      //         style={{ width: "100%" }}
      //         onChange={(e) => this.onChangeSplitter(e)}
      //       >
      //         <Option value={splitters.COMMA}>{splitters.COMMA}</Option>
      //         <Option value={splitters.SEMI_COLON}>
      //           {splitters.SEMI_COLON}
      //         </Option>
      //         <Option value={splitters.TAB}>{splitters.TAB}</Option>
      //         <Option value={splitters.CUSTOM}>{splitters.CUSTOM}</Option>
      //       </Select>
      //     </Col>
      //     {csvSplitter === splitters.CUSTOM ? (
      //       <Col span={8}>
      //         <b>{i18n.t("FileUpload.Splitter")}</b>
      //         <Input
      //           width={"100%"}
      //           defaultValue={customSplitter}
      //           value={customSplitter}
      //           onChange={(e) => {
      //             this.setState({ customSplitter: e.target.value });
      //           }}
      //         />
      //       </Col>
      //     ) : null}
      //   </>
      // );
    } else {
      return null;
    }
  };

  /**
   * Calls setUploadedFile method
   */
  onFileUpload = (event) => {
    this.handleFileUpload(event.target.files[0]);
  };

  /*
  * Gets model list
  */
  getModelList = (props, newState) => {
    let url = `${API_BASE}/subjectArea/nameList`

    let successFunc = (response) => {
      let modelList = response.data;

      if (modelList && modelList.length == 1) {
        this.props.setSelectedModel(modelList[0], 0)
      } else if (modelList && modelList.length === 0) {
        this.props.setSelectedModel("NO_MODEL", 0)
      }

      this.setState({
        ...this.state,
        modelList: response.data
      })
    }

    let errorFunc = (err) => {
      console.log(err)
    }

    get(url, successFunc, errorFunc)
  }

  selectSchemaNameIfNecessary = (props) => {
    if (props.dataSource) {
      let schemaName = props.dataSource.schemaName;
      let schemaNames = schemaName.split(",");

      this.setState({
        ...this.state,
        schemaName: schemaNames[0],
        schemaNames: schemaNames,
      });
    }
  };

  /*
  * Selects model
  */
  selectModel = (modelList) => {
    return (
      <Col span={8} style={{ marginRight: "120px" }}>
        <b>{i18n.t("FileUpload.SelectModel")}</b> <Tooltip title={i18n.t("FileUpload.PleaseSelectModel")}><span style={{color: "red", cursor: "pointer"}}>*</span></Tooltip>
        <Select
          value={this.props.selectedModel?.index}
          style={{ width: "100%" }}
          getPopupContainer={(trigger) => trigger.parentNode}
          onChange={(index) => {
            this.props.setSelectedModel(modelList[index], index)
          }}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          optionFilterProp="children"
          showSearch
        >
          {modelList.map((model, index) => (
            <Option key={model.id} value={index}>{model.displayName}</Option>
          ))}
        </Select>
      </Col>
    );
  };

  selectSchemaName = (schemaNames) => {
    return (
      <Row gutter={12} style={{ marginBottom: "10px", marginTop: "10px" }}>
        <Col span={8}>
          <b>{i18n.t("FileUpload.SelectSchemaName")}</b>
          <Select
            value={this.state.schemaName}
            style={{ width: "100%" }}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(value) => {
              this.setState({ ...this.state, schemaName: value });
            }}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            optionFilterProp="children"
            showSearch
          >
            {schemaNames.map((schemaName) => (
              <Option value={schemaName.trim()}>{schemaName.trim()}</Option>
            ))}
          </Select>
        </Col>
      </Row>
    );
  };

  /*
  * Select data sources
  */
  selectDataSource = () => {
    let dataSourcesList = this.props.selfService.dataSources

    if (dataSourcesList && dataSourcesList.length > 1) {
      return <Col span={8}>
          <b>{i18n.t("FileUpload.SelectDataSource")}</b>  <Tooltip title={i18n.t("FileUpload.PleaseSelectDataSource")}><span style={{color: "red", cursor: "pointer"}}>*</span></Tooltip>
          <Select
            value={this.props.selfService.dataSourceId}
            style={{ width: "100%" }}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(value) => {
              this.props.changeFileUploadDataSource(value)
            }}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch
            optionFilterProp="children"
          >
            {dataSourcesList.map((dataSource) => (
              <Option value={dataSource.id}>{dataSource.displayName}</Option>
            ))}
          </Select>
        </Col>
    }
  }

  render() {
    const { fileType, schemaNames, modelList } = this.state;
    let uploaderAccept = "";

    if (fileType === "EXCEL") {
      uploaderAccept = ".xls, .xlsx";
    } else if (fileType === "CSV") {
      uploaderAccept = ".csv";
    } else if (fileType === "PARQUET") {
      uploaderAccept = ".parquet";
    } else if (fileType === "SPSS") {
      uploaderAccept = ".sav";
    }

    return (
      <div>
        <Row gutter={12}>
          {modelList && modelList.length > 1 && this.props.comesFromDashboardFileUpload && this.selectModel(modelList)}
          {this.props.comesFromDashboardFileUpload ? this.selectDataSource() : null}
        </Row>
        {schemaNames &&
          schemaNames.length > 1 &&
          this.selectSchemaName(schemaNames)}
        <Row gutter={12} style={{marginTop: "10px"}}>
          <Col span={8}>
            <b>{i18n.t("FileUpload.ChooseYourFileType")}</b>
            <Select
              defaultValue={fileType}
              value={fileType}
              style={{ width: "100%" }}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(e) => this.onChangeFileType(e)}
            >
              <Option value={fileTypes.EXCEL}>{fileTypes.EXCEL}</Option>
              <Option value={fileTypes.CSV}>{fileTypes.CSV}</Option>
              <Option value={fileTypes.PARQUET}>{fileTypes.PARQUET}</Option>
              <Option value={fileTypes.SPSS}>{fileTypes.SPSS}</Option>
              <Option disabled value={fileTypes.JSON}>
                {fileTypes.JSON}
              </Option>
            </Select>
          </Col>
          {this.getFileTypeBaseContent()}
        </Row>
        <Row>
          <Col span={24}>
            <div style={{ marginTop: "10px" }}>
              <Checkbox
                style={{ marginRight: "6px" }}
                checked={this.state.header}
                onChange={(e) => {
                  this.setState({ header: e.target.checked });
                }}
              >
                {i18n.t("FileUpload.FileHasHeaderColumns")}
              </Checkbox>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <CustomUploader
              accept={uploaderAccept}
              onFileUpload={this.onFileUpload}
              file={this.state.file}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selfService: state.SelfServiceReducer,
  };
};

export default connect(mapStateToProps)(ChooseFile);
