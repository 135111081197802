import React, { Component } from "react";
import { get } from "../Utils/WebService";
import { API_BASE } from "../config";
import { showNotificationWithIcon } from "../Utils/Notification";
import { Icon, Button } from "antd";

class RoleList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roleList: [],
      userList: [],
      selectedRoleID: ""
    };
  }

  componentWillMount() {
    this.getRole();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.list === true) {
      this.getRole();
    }
  }

  getRole = () => {
    let url = `${API_BASE}/role`;

    const successFunc = result => {
      this.setState({
        roleList: [...result.data]
      });
    };

    const errorFunc = error => {
      showNotificationWithIcon("ERROR!", error.response.data, "error");
    };

    get(url, successFunc, errorFunc);
  };

  getUsersByRole = (role) => {
    let url = `${API_BASE}/role/` + role.id;

    const successFunc = result => {
      this.setState({
        userList: [...result.data]
      });

      let roleUsers = { role: role, users: result.data };
      this.props.confirm(roleUsers);
    };

    const errorFunc = error => {
      showNotificationWithIcon("ERROR!", error.response.data, "error");
    };

    get(url, successFunc, errorFunc);
  };

  selectedRole = role => {
    this.setState({
      selectedRoleID: role.id,
      roleList: [...this.state.roleList],
      userList: [...this.state.userList]
    });
    this.getUsersByRole(role);
  };

  render() {
    return (
      <div>
        {this.state.roleList.map(role => (
          <div>
            <Button
              style={{
                width: "100%",
                textAlign: "left",
                marginBottom: "5px",
                background:
                  this.state.selectedRoleID == role.id
                    ? "rgba(133,197,87,1)"
                    : "white",
                color:
                  this.state.selectedRoleID == role.id
                    ? "white"
                    : "rgba(0,0,0,.65)"
              }}
              onClick={() => this.selectedRole(role)}
              type={"default"}
            >
              {role.name}
              <Icon type="right" style={{ float: "right", marginTop: "3px" }} />
            </Button>
          </div>
        ))}
      </div>
    );
  }
}

export default RoleList;
