import React, { Component } from "react";
import $ from "jquery";
import _ from "lodash";
import i18n from "../../../../Utils/i18next";
import { createTrigger } from "../../../Interaction/CreateTrigger";
import {
  onComponentWillMount,
  onComponentWillReceiveProps,
  getColumnMapping,
  calculatePluginHeight
} from "../common";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import { renderConfig, renderData } from "../PluginsCommonComponents";
import { renderContent } from "../renderContent";
import { getFormattedValue } from "../format";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join"
import { calculatePluginInlineHeight, calculateTitleSize } from "../../../DrillDown/PluginHeightWithDrilldown"
import EnhancedFilterConfiguration from "./EnhancedFilterConfiguration"
import EnhancedFilterData from "./EnhancedFilterData"
import { shadeColor } from "../ButtonFilter/getButtonComponent";
import { InsightsConfig } from "../../RenderJs/config";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";

const data = JSON.parse(
  `[{"filter":"Aralık"},{"filter":"Ağustos"},{"filter":"Ekim"},{"filter":"Eylül"},{"filter":"Haziran"},{"filter":"Kasım"},{"filter":"Mart"},{"filter":"Mayıs"},{"filter":"Nisan"},{"filter":"Ocak"},{"filter":"Temmuz"},{"filter":"Şubat"}]`
);
const columnMap = JSON.parse(
  `{"filter":{"Code":"\'ucusAlias\'.\'ay_adiAlias\'","Name":"ay adi","DataType":"varchar","Table":"ucusAlias","Measure":"none","ID":"ucusAlias.ay adi","SubjectArea":"BIGDATA","SortKey":false,"Sorting":false,"SortDirection":"","SortOrder":0,"Locale":"TR","DataFormat":"%s","Config":{},"Verified":true,"Type":"Column","Description":"","remove":true}}`
);

const configurationParameters = [
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "numFont",
    label: "NumFont",
    inputType: "font",
    inputOptions: { defaultValue: "Verdana" },
    desc: "desc147"
  },
  {
    targetProperty: "fontWeight",
    label: "fontWeight",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "desc142"
  },
  {
    targetProperty: "fontStyle",
    label: "fontStyle",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "desc142"
  },  {
    targetProperty: "textDecor",
    label: "textDecor",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "desc142"
  },
  {
    targetProperty: "wrapText",
    label: "wrapText",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "wrapText"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "fontSize",
    label: "fontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 13
    },
    desc: "fontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 15
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "colours",
    label: "Renkler",
    inputType: "palette",
    inputOptions: {
      defaultValue: "defaultEnhancedPalette"
    },
    desc: "desc208"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
  {
    targetProperty: "width",
    label: "Width",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 400
    },
    desc: "desc89"
  },
  {
    targetProperty: "title",
    label: "Title",
    inputType: "textbox",
    inputOptions: {
      defaultValue: ""
    },
    desc: "desc94"
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: {
      defaultValue: "rgb(255,255,255)"
    },
    desc: "desc62"
  },
  {
    targetProperty: "arrowColor",
    label: "ArrowColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: {
      defaultValue: "rgba(0, 0, 0, 0.65)"
    },
    desc: "ArrowColor"
  },
  {
    targetProperty: "textBackgroundColor",
    label: "TextBackgroundColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: {
      defaultValue: "#fff"
    },
    desc: "textBackgroundColor"
  },
  {
    //Multiselect topojson control
    targetProperty: "isMulti",
    label: "IsMulti",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "IsMulti"
  },
  {
    targetProperty: "threeD",
    label: "threeD",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "threeD"
  },
  {
    targetProperty: "roundCorner",
    label: "roundCorner",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "roundCorner"
  },
  {
    targetProperty: "isMultiple", // Name of the property in config object of render function
    label: "isMultiple", // Name the user will see
    inputType: "checkbox", // Indicates the UI element for setting this parameter
    inputOptions: { defaultValue: true },
    desc: "desc89" // Description displayed to the user
  },
  {
    targetProperty: "spaceBetween",
    label: "spaceBetween",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: {
      defaultValue: 5
    },
    desc: "spaceBetween"
  },
  {
    targetProperty: "placeholder",
    label: "PlaceholderFilter",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: {
      defaultValue: ""
    },
    desc: "PlaceholderFilter"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  },
  {
    targetProperty: "placeholderColour",
    label: "PlaceHolderColour",
    inputType: "colour",
    inputOptions: {
      defaultValue: "rgba(0, 0, 0, 0.65)"
    },
    desc: "desc167"
  },
];

const actions = [
  {
    trigger: "click",
    type: "click",
    name: "click",
    output: ["filter"],
    description: "ActionClickDesc"
  }
];

const reactions = [
  {
    id: "filter",
    name: "Filtre",
    description: "desc78",
    type: "general"
  }
];

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

/**
 * renders Filter plugin in Vispeahen V3
 */
export default class EnhancedFilter extends Component {
  constructor(props) {
    super(props);
    
    this.rerenderProcessStarted = false;
    this.callBackObject = {};
  }

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({})

    return tmpCallBackObject;
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );
  }

  getConfigComponent = props => {
    if (props.config) {
      return (
        <EnhancedFilterConfiguration
          config={{ ...props.config }}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig= {this.props.setCurrentAppliedConfig}
          currentAppliedConfig = {this.props.currentAppliedConfig}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }

    return (
      <EnhancedFilterData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        didNotJoinedTables={checkTableJoins(this.props.join, this.props.plugin.columnMap, this.props.refreshedPluginId, this.props.plugin.id, true)}
        setInteractions={this.props.setInteractions}
        interactions={this.props.interactions}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={props.changeDoesPluginHasNotJoinedTable}
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        plugin={props.plugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  /**
   * To set column map this plugin
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      filter: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Filter.Name"),
        type: "dim",
        minimumColumnSize: 1,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Filter.Desc"),
        required: true,
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;

    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {
    $("#" + divId).html("");

    var THIS = this

    calculateTitleSize(this.props.plugin)

    let container = $("#" + divId);

    container.append(`<div class="enhanced-checkbox enhanced-checkbox-main" id="enhanced-checkbox-${divId}"></div>`)
    container.find(".classic-checkbox").css("flex-direction", config.flexDirection === "bottom" ? "column" : "row")
    container.css("display", "flex").css("align-items", "center").css("justify-content", "center")

    /*
    * Calculates per data width
    */
    let decideDataWidth = (i = data.length) => {
      let plugin = $("#plugin-" + divId)

      if ((plugin.width() / i) >= 90) {
        if (plugin.height() <= $("#" + divId)[0].scrollHeight) {
          if (config.spaceBetween) {
            plugin.find(".custom-button").css("width", ((plugin.width() / i) - ((data.length * config.spaceBetween)) + 6) >= 90 ? ((plugin.width() / i) - ((data.length * config.spaceBetween)) + 6) : 90)

            return ((plugin.width() / i) - ((data.length * config.spaceBetween)) + 6)
          } else {
            plugin.find(".custom-button").css("width", ((plugin.width() / i) - 6))

            return (plugin.width() / i) - 6
          }
        } else {
          if (config.spaceBetween) {
            return ((plugin.width() / i) - ((data.length * config.spaceBetween)) + 6)
          } else {
            return ((plugin.width() / i) - 6)
          }
        }
      } else if (i === 0) {
        return 90
      } else {
        return decideDataWidth(i - 1)
      }
    }

    /*
    * Calculates per data height
    */
    let decideDataHeight = (i = data.length) => {
      let plugin = $("#plugin-" + divId)

      if (plugin.height() / i >= 50) {
        return (plugin.height() / i)
      } else if (i === 0) {
        return 50
      } else {
        return decideDataHeight(i - 1)
      }
    }

    let chartColors

    if (InsightsConfig.Palettes[config.colours] != undefined && config.colours !== "Flat-UI") {
      chartColors = InsightsConfig.Palettes[config.colours];
    } else if (config.colours === "Flat-UI") {
      chartColors =  ["#67b7dc",
      "#6771dc",
      "#a367dc",
      "#dc67ce",
      "#dc6788",
      "#dc8c67",
      "#dcaf67",
      "#dcd267",
      "#c3dc67",
      "#a0dc67",
      "#7cdc67",
      "#67dc75",
      "#67dc98",
      "#67dcbb",
      "#67dadc"]
    } else {
      chartColors = config.colours;
    }

    for (let i = 0; i < data.length; i++) {
      let plugin = $("#plugin-" + divId)
      let value = getFormattedValue(columnMap.filter, data[i].filter);
      let isPluginWidthGreaterThanHeight = plugin.width() > plugin.height() ? true : false
      let perDataWidth = isPluginWidthGreaterThanHeight ? decideDataWidth() : "100%"
      let perDataHeight = !isPluginWidthGreaterThanHeight ? decideDataHeight() : "50px"
      let dataWidth = perDataWidth >= 90 && perDataWidth !== "100%" 
        ? `${perDataWidth}px` 
        : perDataWidth === "100%" 
          ? "100%"
          : perDataWidth >= 90 
            ? perDataWidth
            : "90px"
      let dataHeight = perDataHeight >= 50 && perDataHeight !== "50px"
        ? `${perDataHeight}px`
        : perDataHeight === "50px" 
          ? "50px"
          : perDataHeight >= 50 
            ? perDataHeight
            : "50px"
      let colour = chartColors[i % chartColors.length]
      let roundCorner = config.roundCorner == undefined || config.roundCorner == true ? "18px" : ""
      let border = config.threeD === true ? `6px solid ${shadeColor(colour, -30)}` : "none"
      let font = config.font == undefined ? "Verdana" : config.font
      let fontWeight = config.fontWeight == undefined 
        ? false 
        : config.fontWeight == true 
          ? "bold"
          : ""
      let fontStyle = config.fontStyle == undefined 
        ? false 
        : config.fontStyle 
          ? "italic"
          : ""
      let textDecor = config.textDecor == undefined 
        ? false 
        : config.textDecor 
          ? "underline"
          : ""
      let wordWrap = config.wrapText ? "multiple-row-wrap" : "single-row-wrap"
      let fontSize = config.fontSize < 3 
        ? 3
        : config.fontSize >= 15
          ? 15
          : config.fontSize
      let textColor = config.textColor === undefined ? "rgb(75, 75, 75)" : config.textColor

      let preparedHtml = 
      `<div id="custom-enhanced-button-${divId}" class="custom-button tooltip-enhanced-button" style="width: ${dataWidth}; height: ${dataHeight}; max-height: 75px">
        <input type="checkbox" class="per-filter-button" id="custom-button-${i}-${divId}" style="display: none"/>
        <label title="${value}" for="custom-button-${i}-${divId}" class="per-filter-obj" style="border-radius: ${roundCorner}; color: ${textColor}!important; background: ${colour}; border-bottom: ${border}; color: white; display: flex; align-items: center; justify-content: center; margin: 0; height: 100%; font-family: ${font}; font-weight: ${fontWeight}; font-style: ${fontStyle}; text-decoration: ${textDecor};">
          <div style="max-width: 90%; margin: 0; color: ${textColor}; display: flex; align-items: center; justify-content: center;">
            <p class="${wordWrap}" style="margin: 0; font-size: ${fontSize}px;">${value}</p>
          </div>
        </label>
      </div>`

      $("#enhanced-checkbox-" + divId).css("gap", config.spaceBetween + "px")
      $("#enhanced-checkbox-" + divId).append(preparedHtml)
    }

    $(document).ready(function () {
      let customButtons = $(container).find(".per-filter-button")

      customButtons.change(function () {
        let filters = [];

        for (let i = 0; i < customButtons.length; i++) {
          if (config.isMultiple || config.isMultiple == undefined) {
            if (customButtons[i].checked) {
              $(customButtons[i]).parent().find(".per-filter-obj").removeClass("per-filter-obj-active")

              if (config.threeD) {
                $(customButtons[i]).parent().find(".per-filter-obj").css("border-bottom", "4px solid " + shadeColor(chartColors[i % chartColors.length], -30))
              }
  
              filters.push({filter: data[i].filter});
            } else {
              $(customButtons[i]).parent().find(".per-filter-obj").addClass("per-filter-obj-active")

              if (config.threeD) {
                $(customButtons[i]).parent().find(".per-filter-obj").css("border-bottom", "6px solid " + shadeColor(chartColors[i % chartColors.length], -30))
              }
            }
          } else if (!config.isMultiple) {
            if (customButtons[i] === this) {
              $(customButtons[i]).parent().find(".per-filter-obj").removeClass("per-filter-obj-active")

              if (config.threeD) {
                $(customButtons[i]).parent().find(".per-filter-obj").css("border-bottom", "4px solid " + shadeColor(chartColors[i % chartColors.length], -30))
              }
  
              filters.push({filter: data[i].filter});
            } else {
              $(customButtons[i]).parent().find(".per-filter-obj").addClass("per-filter-obj-active")

              if (config.threeD) {
                $(customButtons[i]).parent().find(".per-filter-obj").css("border-bottom", "6px solid " + shadeColor(chartColors[i % chartColors.length], -30))
              }
            }
          }
        }

        if (filters.length === 0) {
          $("#" + THIS.props.plugin.id).find(".per-filter-obj-active").removeClass("per-filter-obj-active")
        }

        let mousePosition = { x: window.event.pageX, y: window.event.pageY }

        createTrigger(
          actions,
          columnMap,
          container,
          "click",
          filters,
          THIS.props.plugin.id,
          THIS.props.interactions,
          THIS.props.navigations,
          mousePosition
        );
      });
    })
  
    this.props.setPluginRerender(false, this.props.plugin.id, false, this.props.plugin.isInteraction, false, config);
  };

  currentHeight;
  lastContent = undefined;

  updateLastContent = (status) => {
    this.lastContent = status
  }

  render() {
    let pluginId = this.props.plugin.id;

    let configComponent = null;

    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + pluginId),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let dataComponent = null;
    
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + pluginId),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };
    
    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + pluginId).css("padding")
      );

      pluginConfig.height =
        calculatePluginHeight(this.props.plugin, this.props.settings) -
        pluginContainerPadding * 2;

      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={pluginId}></div>
        </div>
      )
    }
    
    let pluginInlineHeight = calculatePluginInlineHeight(pluginId)

    $("#plugin-" + pluginId).css("height", pluginInlineHeight)

    return (
      <div style={{height: "100%"}}>
        <div id={pluginId}></div>
        {renderContent(
          isRerender,
          this.pluginRender,
          this.props.plugin,
          data,
          columnMap,
          pluginConfig,
          [],
          this.props.setPluginRerender,
          this.lastContent,
          this.updateLastContent,
        )}
        {configComponent}
        {dataComponent}
      </div>
    );
  }
}