export const SET_GENERAL_LOADING = "SET_GENERAL_LOADING";

/**
 * 
 * Sets general loading set content
 */
export const setGeneralLoadingSet = (contentObject) => {
    return (dispatch) => {
      dispatch({
        type: SET_GENERAL_LOADING,
        payload: contentObject,
      });
    };
  }