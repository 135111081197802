import React, { Component } from "react";
import * as d3 from "d3";
import $ from "jquery";
import { rmvpp } from "../../RenderJs/rmvpp";
import "select2";
import "select2/dist/css/select2.css";
import TitleConfiguration from "./TitleConfiguration";
import i18n from "../../../../Utils/i18next";
import TitleData from "./TitleData";
import _ from "lodash"
import {
  onComponentWillMount,
  onComponentWillReceiveProps,
  getColumnMapping,
  calculatePluginHeight,
  calculateText
} from "../common";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import { renderConfig, renderData } from "../PluginsCommonComponents";
import "./title.css";
import { renderContent } from "../renderContent";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";

const data = JSON.parse(
  '[{"ay adi":"Aralık","hidden":[{"name":"ay adi","value":"Aralık"}]},{"ay adi":"Ağustos","hidden":[{"name":"ay adi","value":"Ağustos"}]},{"ay adi":"Ekim","hidden":[{"name":"ay adi","value":"Ekim"}]},{"ay adi":"Eylül","hidden":[{"name":"ay adi","value":"Eylül"}]},{"ay adi":"Haziran","hidden":[{"name":"ay adi","value":"Haziran"}]},{"ay adi":"Kasım","hidden":[{"name":"ay adi","value":"Kasım"}]},{"ay adi":"Mart","hidden":[{"name":"ay adi","value":"Mart"}]},{"ay adi":"Mayıs","hidden":[{"name":"ay adi","value":"Mayıs"}]},{"ay adi":"Nisan","hidden":[{"name":"ay adi","value":"Nisan"}]},{"ay adi":"Ocak","hidden":[{"name":"ay adi","value":"Ocak"}]},{"ay adi":"Temmuz","hidden":[{"name":"ay adi","value":"Temmuz"}]},{"ay adi":"Şubat","hidden":[{"name":"ay adi","value":"Şubat"}]}]'
);
const config = JSON.parse(
  '{"width":400,"height":300,"title_":"windows","toggleCriteria":false,"showHideButton":false,"summary":"","backgroundColor":"rgb(255,255,255)"}'
);
const columnMap = JSON.parse(
  `{"hidden":[{"Code":"\'ucusAlias\'.\'ay_adiAlias\'","Name":"ay adi","DataType":"varchar","Table":"ucusAlias","Measure":"none","ID":"ucusAlias.ay adi","SubjectArea":"BIGDATA","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":"%s","Config":{},"Verified":true,"Type":"Column","Description":""}]}`
);

const condFormats = [];
const filters = [];
const pluginName = "title";

let originalTitle = new Map();

const columnMappingParameters = [
  {
    targetProperty: "hidden",
    formLabel: "Hidden",
    multiple: true,
    type: "hidden",
    desc: "desc77" //'Hidden columns that can be used for conditional formatting without being displayed. It is important that adding this column does not alter the granularity of the query, i.e. the hidden column should be of the same level, or less descriptive than the category column.'
  }
];

const configurationParameters = [
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 15
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
  {
    targetProperty: "width",
    label: "Width",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 400
    },
    desc: "desc89"
  },
  {
    targetProperty: "height",
    label: "Height",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 300
    },
    desc: "desc90"
  },
  {
    targetProperty: "title_",
    label: "Title",
    inputType: "textbox",
    inputOptions: {
      subtype: "text",
      defaultValue: i18n.t("TitleNotSet")
    },
    desc: "desc153"
  },
  {
    targetProperty: "linkField",
    label: "LinkField",
    inputType: "textbox",
    inputOptions: {
      subtype: "text"
    },
    desc: "desc153"
  },
  {
    targetProperty: "font",
    label: "Font",
    inputType: "font",
    inputOptions: {
      defaultValue: "Open Sans"
    },
    desc: "desc166"
  },
  {
    targetProperty: "toggleCriteria",
    label: "ToggleCriteria",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "desc59"
  },
  {
    targetProperty: "showHideButton",
    label: "Show Hide Button",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc230"
  },
  {
    targetProperty: "hideLabel",
    label: "HideLabel",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "desc142"
  },
  {
    targetProperty: "summary",
    label: "Summary",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc61"
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: { defaultValue: "rgb(255,255,255)" },
    desc: "desc62"
  },
  {
    targetProperty: "numFont",
    label: "NumFont",
    inputType: "font",
    inputOptions: { defaultValue: "Consolas" },
    desc: "desc147"
  },
  {
    targetProperty: "fontSize",
    label: "FontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 16
    },
    desc: "desc91"
  },
  {
    targetProperty: "titleColor",
    label: "TitleColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: { defaultValue: "rgba(0, 0, 0, .5)" },
    desc: "desc92"
  },
  {
  targetProperty: "linkColor",
  label: "LinkColor",
  //inputType: 'colour',
  inputType: "textbox",
  inputOptions: { defaultValue: "rgb(66, 139, 202)" },
  desc: "desc62"
  },
];

const actions = [];

// Reactions this visualisation can have to other interactions. Everything can use filters
const reactions = [
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

var toggleCriteria;
let titleIncludesLink = false
let isTargetPluginRefresh

const updateTitle = function (output, container) {
  if(!isTargetPluginRefresh) {
    let isParentSpan = titleIncludesLink === true ? "a" : "span"
    let divId = $(container).children("div")[0].id;
    let titleValue = $(container)
      .children(`#${divId}`)
      .children(isParentSpan);
    let titleString = originalTitle.get(divId);
    let fullPostfix = "";
    
    if ($("#mapTitle").length > 0) {
      fullPostfix = $("#mapTitle").text();
    } else {
      //When the value comes from more than one column of the same plugin, it combines the values ​​and shows the title.
      output.map(column => {
        if (column.sourceName) {
          fullPostfix = column.sourceName;
        } else {
          if (fullPostfix !== "" && column.values.length > 0) {
            fullPostfix = fullPostfix + ",";
          }
          
          fullPostfix = fullPostfix + column.values.join(", ");
        }
      });
    }
    
    let title = `${titleString} ${fullPostfix}`;
    let tooltip = title;

    title = `${titleString} ${fullPostfix}`;

    let isOutputValid = output && output.length > 0;

    if (isOutputValid) {
      if (output[0].values.length > 40) {
        if (toggleCriteria) {
          //Clean title plugin if toggleCriteria is enabled and no filter
          title = ``;
        } else {
          title = originalTitle.get(divId);
        }
      } else if (output[0].values.length > 2) {
        let shortenedPostfix = `${fullPostfix
          .split(" ")
          .slice(0, 2)
          .join(" ")} +${output[0].values.length - 2}`;
  
        title = `${titleString} ${shortenedPostfix}`;
      }
    }

    titleValue.text(title);
    titleValue.attr("title", tooltip);
  }
};

/* deletes title string and writes just values to title */
const resetTitle = function (output, container) {
  let divId = $(container).children("div")[0].id;
  let refreShedTitle = originalTitle.get(divId);
  let isParentSpan = titleIncludesLink === true ? "a" : "span"
  let titleValue = $(container)
  .children(`#${divId}`)
  .children(isParentSpan);
  let titleString = "";

  if (!isTargetPluginRefresh) {
    output.map(column => { 
      if (column.sourceName) {
        titleString = column.sourceName;
      } else {
        titleString = titleString + column.values.join(", ");
      }
    });
    
    titleValue.text(titleString);
    titleValue.attr("title", titleString);
  } else {
    titleValue.text(refreShedTitle);
    titleValue.attr("title", refreShedTitle);
  }
}

/**
 * renders Title plugin in Vispeahen V3
 */
export default class Title extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.rerenderProcessStarted = false;
    this.callBackObject = {};
  }

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };
    
    tempPlugin.updateTitle = updateTitle;
    tempPlugin.resetTitle = resetTitle;

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping
    );
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickRefresh);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickRefresh);
  }

  handleClickRefresh = (event) => {
    isTargetPluginRefresh = $(event.target).closest(".anticon-reload").length > 0 ? true : false;  
  }

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({})

    return tmpCallBackObject;
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );

    if (!_.isEqual(this.props.currentAppliedConfig, nextProps.currentAppliedConfig)) {
      this.setState({
        currentAppliedConfig: nextProps.currentAppliedConfig
      })

      this.props.setPluginRerender(true, this.props.plugin.id)
    }
  }

  getConfigComponent = props => {
    if (props.config) {
      return (
        <TitleConfiguration
          config={{ ...props.config }}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig= {this.props.setCurrentAppliedConfig}
          currentAppliedConfig = {this.props.currentAppliedConfig}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );
    return (
      <TitleData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
      />
    );
  };

  /**
   * To set column map this plugin
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      hidden: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Hidden.Name"),
        type: "hidden",
        minimumColumnSize: 0,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Hidden.Desc"),
        multiple: true,
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {
    // Set html empty and set this html to container.
    $("#" + divId).html("");
    let container = $("#" + divId)[0];
    $("#title-" + divId).remove()
    $("#plugin-" + divId).css("height", "100%")
    $("#plugin-tools-" + divId).parent().parent().parent().css("min-height", "75px")
    $("#" + divId).parent().css("height", "100%")
    $("#plugin-" + divId).parent().parent().css("min-height", "50px")

    d3.select(container).style("height", "100%");

    const randomMapId = Math.floor(Math.random() * 1000);
    
    let title = calculateText(this.props.plugin, config.title_);
    
    divId = `baslikDiv${randomMapId}`;
    toggleCriteria = config.toggleCriteria;
    originalTitle.set(divId, title);

    let style =
      `word-wrap:break-word; 
      width: 100%; 
      height: 100%;
      white-space: normal;  
      text-align: center; 
      background-color:` + config.backgroundColor + `;` +
      `color:` + config.titleColor + `;` +
      `font-size:` + config.fontSize + `px;
      font-family:` + config.titleFont + `
      ;display: flex;
      align-items: center;
      justify-content: center`

    let spanStyle =
      `word-wrap:break-word; 
      width: 100%; 
      margin: auto;
      white-space: normal;  
      background-color:` + config.backgroundColor + `;` +
      `color:` + config.titleColour + `;` +
      `font-size:` + config.titleFontSize + `px;
      font-family:` + config.titleFont + `;` +
      `font-weight:` + (config.titleFontWeight ? `bold;` : `normal;`) +
      `font-style:` + (config.titleFontStyle ? `italic;` : 'normal;') +
      `text-decoration:` + (config.titleTextDecor ? "underline;" : "none;") +
      `text-align:` + (config.titleAlign === "center" || config.titleAlign === undefined ? "center" : config.titleAlign === "left" ? "left" : "right")


    //Adds to header if link is given in configuration 
    if (!config.hideLabel) {
      if (config.linkField) {
        let link = config.linkField;
        let pos = link.indexOf("http");
        link = link.replace(/&/g, "&amp;");
        titleIncludesLink = true

         //If the link does not start with http, add http to the beginning
         if (pos === -1) {
          link = "http://" + link;
        }

        config.linkField = link;
        $(".rowButons").remove();

        if (config.toggleCriteria) {
          $(container).append(
            `<div class="baslik" id="${divId}" style="${style}">
            <a style="${spanStyle
            }" href="${link}" target="_blank"></a></div>`
          );
        } else {
          $(container).append(
            `<div class="baslik" id="${divId}" style="${style}">
            <a style="${spanStyle
            }" href="${link}" target="_blank">${title}
            </a></div>`
          );
        }

        //if string in the linkField field is a link, make it blue color and underline
        let input = config.linkField;
        let linkWords = ["http://", "https://", ".com", "www."];
        
        /**
         * Title hover in handler
         */
        const hoverIn = event => {
          $(event.target).css("text-decoration", "underline");
        }

        /**
         * Title hover out handler
         */
        const hoverOut = event => {
          //Hover out for title type
          if (config.titleTextDecor === true) {
            $(event.target).css("text-decoration", config.titleTextDecor);
          } else {
            $(event.target).css("text-decoration", "none");
          }
        }

        if (linkWords.some(l => input.includes(l))) {
          $(container).find(".baslik").find("a").hover(hoverIn, hoverOut);
          $(container).find(".baslik").find("a").css("color", config.linkColor);
        } 
      } else {
        titleIncludesLink = false
        
        if (config.toggleCriteria) {
          $(container).append(
            `<div class="baslik" id="${divId}" style="${style}">
            <span style="${spanStyle
            }"></span></div>`
          );
        } else {
          $(container).append(
            `<div class="baslik" id="${divId}" style="${style}">
            <span style="${spanStyle
            }">${title}</span></div>`
          );
        }
      }
    }

    if (config.showHideButton) {
      let hideButtonToggleKey = [".baslik"];
      rmvpp.hideButton(container, hideButtonToggleKey);
    }

    this.props.setPluginRerender(false, this.props.plugin.id, false, this.props.plugin.isInteraction);
  };

  currentHeight;
  lastContent = undefined;

  updateLastContent = (status) => {
    this.lastContent = status
  }

  render() {
    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };

    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + this.props.plugin.id).css("padding")
      );

      //If we change title plugin, plugin's title deleted. We need to get plugins 
      //normal height. plugins normal height + pluginContainerPadding - margins vs.
      pluginConfig.height =
        calculatePluginHeight(this.props.plugin, this.props.settings) +
        (pluginContainerPadding * 2) - 6;

      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      );
    }

    return (
      <div style={{height: "100%"}}>
        <div id={this.props.plugin.id}></div>
        {renderContent(
          isRerender,
          this.pluginRender,
          this.props.plugin,
          data,
          columnMap,
          pluginConfig,
          condFormats,
          this.props.setPluginRerender,
          this.lastContent,
          this.updateLastContent,
        )}
        {configComponent}
        {dataComponent}
      </div>
    );
  }
}
