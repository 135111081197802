import { Checkbox, Tabs } from "antd";
import React, { Component } from "react";
import TextArea from "antd/lib/input/TextArea";
import { deepCopy } from "../../../../../Utils/Global";
import i18n from "../../../../../Utils/i18next";
import Text from "../../../../GeneralComponents/Text/Text";

export default class HeaderSettings extends Component {
    /**
     * Sets header content
     */
    setHeader = (header, area, value) => {
        let options = deepCopy(this.props.options);
        let headers = options.headers;

        headers[header][area] = value;

        this.props.setOption("headers", headers);
    }

    /**
     * Header content area
     * 
     * @param {*} options 
     * @param {*} headerType 
     * @returns 
     */
    contentArea = (options, headerType) => {
        let width = 100 / 3 + "%";
        
        let header = deepCopy(options.headers[headerType]);
        let locales = {
            left: i18n.t("Designer.HeaderFooter.LeftArea"),
            center: i18n.t("Designer.HeaderFooter.CenterArea"),
            right: i18n.t("Designer.HeaderFooter.RightArea")
        };

        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center"
                }}
            >
                {
                    Object.keys(locales).map(area => (
                        <div
                            style={{
                                width: width,
                                display: "flex",
                                flexDirection: "column",
                                padding: "0 8px"
                            }}
                        >
                            <Text>
                                {locales[area]}
                            </Text>
                            <TextArea
                                autoSize={{ minRows: 3, maxRows: 3 }}
                                value={header[area]}
                                onChange={(event) => this.setHeader(headerType, area, event.target.value)}
                                disabled={!options.showHeader}
                            />
                        </div>
                    ))
                }
            </div>
        )
    }

    render() {
        const { options } = this.props;

        let differentHeaders = [];
        let locales = {
            all: i18n.t("Designer.HeaderFooter.AllPages"),
            first: i18n.t("Designer.HeaderFooter.FirstPage"),
            odd: i18n.t("Designer.HeaderFooter.OddPages"),
            even: i18n.t("Designer.HeaderFooter.EvenPages"),
            rest: i18n.t("Designer.HeaderFooter.RestOfPages")
        };

        if (options.differentFirstHeader) {
            differentHeaders.push("first");
        }

        if (options.differentOddEvenHeader) {
            differentHeaders.push("odd", "even");
        }

        if (options.differentFirstHeader && !options.differentOddEvenHeader) {
            differentHeaders.push("rest")
        }

        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Checkbox
                    onChange={(event) => this.props.setOption("showHeader", event.target.checked)}
                    checked={options.showHeader}
                    style={{
                        width: "fit-content",
                        marginBottom: 16,
                        marginLeft: 0
                    }}
                >
                    {i18n.t("Designer.HeaderFooter.ShowHeader")}
                </Checkbox>

                <Checkbox
                    onChange={(event) => this.props.setOption("differentOddEvenHeader", event.target.checked)}
                    checked={options.differentOddEvenHeader}
                    disabled={!options.showHeader}
                    style={{
                        width: "fit-content",
                        marginBottom: 8,
                        marginLeft: 0
                    }}
                >
                    {i18n.t("Designer.HeaderFooter.DifferentOddEven")}
                </Checkbox>

                <Checkbox
                    onChange={(event) => this.props.setOption("differentFirstHeader", event.target.checked)}
                    checked={options.differentFirstHeader}
                    disabled={!options.showHeader}
                    style={{
                        width: "fit-content",
                        marginBottom: 16,
                        marginLeft: 0
                    }}
                >
                    {i18n.t("Designer.HeaderFooter.DifferentFirst")}
                </Checkbox>

                <Text
                    style={{
                        marginBottom: 8,
                        fontWeight: 600
                    }}
                >
                    {i18n.t("Designer.HeaderFooter.HeaderContent")}
                </Text>
                {

                    <Tabs defaultActiveKey={0} size="small">
                        {
                            differentHeaders.length > 0 ? differentHeaders.map((type, index) => (
                                <Tabs.TabPane tab={locales[type]} key={index}>
                                    {
                                        this.contentArea(options, type)
                                    }
                                </Tabs.TabPane>
                            )) : (
                                <Tabs.TabPane tab={locales["all"]} key={0}>
                                    {
                                        this.contentArea(options, "all")
                                    }
                                </Tabs.TabPane>
                            )
                        }
                    </Tabs>
                }
            </div>
        );
    }
}