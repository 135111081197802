/* eslint-disable no-unused-expressions */

import { CheckOutlined, ExclamationOutlined } from "@ant-design/icons";
import { Row } from "antd";
import Axios from "axios";
import $ from 'jquery';
import React, { Component } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { exportCustomExcel, exportToXLSX } from "../../../Utils/Exports/ExcelExport";
import { EXCEL_EXPORT_PROGRESS, generalExcelExport } from "../../../Utils/Exports/GeneralExcelExport";
import { exportToPNG } from "../../../Utils/Exports/PNGExport";
import { exportToPDF } from "../../../Utils/Exports/PdfExport";
import { changeStatusOfGeneralLoading, clearAllDrillDownsOnPlugins, clearAllInteractionsOnPlugins, deepCopy, loadingScreen, navigate, pushWindowHistoryIfComesFromDashboardSlider, pushWindowHistoryIfComesFromSwitchDashboard } from "../../../Utils/Global";
import { showNotificationWithIcon } from "../../../Utils/Notification";
import { pushWindowHistory } from "../../../Utils/PushWindowHistory";
import { del, get, post } from "../../../Utils/WebService";
import i18n from "../../../Utils/i18next";
import { API_BASE, BASE_URL_UI, exceptInteractionComponent } from "../../../config";
import { store } from "../../../index";
import { addPluginCacheMode, cachedPluginsTime, savedPluginsInCacheMode, setCacheMode } from "../../CacheMode/CacheModeAction";
import { setModelIdToModelsAreaCallbackFunction } from "../../Datasources/SelfServiceReducer/SelfServiceAction";
import { setPluginsDrillDowns, setTriggeredDrillDowns, setpluginColumnClickedAndShouldBeRemoved } from "../../DrillDown/DrillDownAction";
import FileUploadStatusMinimized from "../../FileUpload/FileUploadStatusMinimized";
import CircleLoader from "../../GeneralComponents/CircleLoader/CircleLoader";
import { changeOriginalInteractions, changeOriginalPlugins } from "../../GeneralComponents/ContextMenu/ContextMenuAction";
import { changeDisabledColumnsStatus } from "../../GeneralComponents/CustomDashboard/CustomDashboardAction";
import FormulaEditor from "../../GeneralComponents/FormulaEditor/FormulaEditor";
import Tooltip from '../../GeneralComponents/Tooltip/Tooltip';
import { getSelectedModel } from "../../Modeling/ModelStore";
import NavigationHistory from "../../Navigation/NavigationHistory";
import { getAllPagesForExcel } from "../../Plugins/PluginComponents/Table/Table";
import { calculateText, convertDrillDownStringToMap } from "../../Plugins/PluginComponents/common";
import {
  changeVisiblePopupContent,
  setUpdatedPlugins
} from "../../Plugins/PluginTriggerAction";
import TopMenu from "../../TopMenu/TopMenu";
import { isValidWriteRoles } from ".././RoleStore";
import GridLocationCalculator from "../GridSystem/GridLocationCalculator";
import GridSystem from "../GridSystem/GridSystem";
import SwitchDashboard from "../SwitchDashboard/SwitchDashboard";
import DashboardToolsSection from "./DashboardTools/DashboardToolsSection";
import { setNewColumnList } from "../../GeneralComponents/NewColumn/NewColumnEditorAction";
import { bindData, mapData, mapResults } from "../../Plugins/PluginComponents/dataAndQuery";
import { getPluginsAllColumnsByField } from "../../../Utils/PluginOperations";
import { setDefinedExpression } from "../../ExpressionParameter/ExpressionParameterAction";
import { base64ToArrayBuffer, saveByteArray } from "../../../Utils/Download";
import { setDashboardSessionVariables, setSessionVariableContent } from "../../GeneralComponents/SessionVariable/SessionVariableAction";
import initDefaultFilterLang from "../../../Utils/VispLanguage/default-filter";
import DashboardSliderPlayer from "../../DashboardSlider/Player";
import _ from "lodash";
import Cookies from "js-cookie";

//Not Exported Excel Plugins
const excludeExcelExportPlugins = new Set([
  "title",
  "i-frame",
  "image",
  "filter",
  "radio-button-filter",
  "measure-tile",
  "selection-box",
  "button-filter",
  "gauge-chart"
])

/**
 * Main component of all dashboard components.
 * Dashboard save will be this state.
 * Includes: Modelling, grid system, plugin conf. and data, dahsboard tools.
 */
class DashboardCrud extends Component {
  constructor(props) {
    super(props);

    this.state = {
      join: [],
      plugins: [],
      readOnly: "",
      pluginAllRender: false,
      model: {},
      interactions: [],
      dashboardInformation: {},
      excelExportClicked: false,
      settings: this.initialSettings,
      dashboardDetail: {},
      thumbnail: null,
      customThumbnail: null,
      id: undefined,
      selectedPlugin: {},
      triggeringPluginInformation: {},
      drillDownTriggerInformation: {},
      labels: [],
      currentModelWithTable: {},
      containerHeight: 0,
      currentDashboardName: "",
      loadingScreenOn: true,
      newLabel: {},
      customDashboards: [],
      isCustomDashboard: false,
      isMainDashboardChangedFlag: false,
      draggableResizableStatus: false,
      forceCloseInteraction: false,
      canPluginExportAreaMustShow: false,
      excelExportProgress: undefined,
      newColumnAddedFlag: false
    };
  }

  initialSettings = {
    defaultBackgroundColor: "",
    defaultBackgroundImage: "",
    defaultBackgroundImageTemp: "",
    backgroundColor: "",
    backgroundImage: "",
    backgroundImageTemp: "",
    description: "",
    title: "",
    noDataTitle: "",
    stickyDashboardTools: false,
    grid: {
      preventCollision: true,
      isDraggableAndResizable: true,
      forceDisableDragableAndResizable: false,
      hoveredPluginTools: false,
      numberOfColumns: 24,
      rowHeight: 75,
      padding: 6,
      shadow: {
        upDown: 0,
        rightLeft: 0,
        blur: 3,
        spread: 0,
        color: "#6767679e"
      }
    },
    dashboardTitleSettings: {
      defaultTitle: "",
      defaultTitleFontWeight: "",
      defaultTitleTextDecor: "",
      defaultTitleFontStyle: "",
      defaultTitleFontSize: "",
      defaultTitleColor: "",
      defaultTitleFont: "",
      titleFontWeight: "normal",
      titleTextDecor: "none",
      titleFontStyle: "normal",
      titleFontSize: +15,
      titleColor: "black",
      titleFont: "Verdana"
    },
    isExcelExportStyled: true,
    isExcelExportBigSized: false
  }

  setSelectedModel = model => {
    let currentmodelId = this.state.modelId;

    this.setState({
      ...this.state,
      model: model,
      modelId: model.id
    }, () => {
      this.getNewColumns(model.id);

      if (currentmodelId !== model.id) {
        this.getSessionVariables(model.id, true);
      }
    });
  };

  interactionTrigger = triggeringPluginInformation => {
    this.setState({
      ...this.state,
      triggeringPluginInformation
    });
  };

  drillDownTrigger = (drillDownTriggerInformation) => {
    this.setState({
      ...this.state,
      drillDownTriggerInformation
    });
  }

  /*
  * Changes status of hovered plugin tools
  */
  changeStatusOfHoveredPluginTools = (status) => {
    let state = { ...this.state }

    state.settings.grid.hoveredPluginTools = status

    this.setState(state)
  }

  gridCalculator;

  componentWillMount() {
    console.log("Dashboard Page");
    this.checkMyAuthorizationForThisDashboard(this.props.currentDashboard)
    this.gridCalculator = new GridLocationCalculator();

    /** TODO Error handling*/
    // if (this.props.errorFallback === true) {
    //   this.setState(deepCopy(this.props.lastState))
    //   this.props.updateErrorFallbackStatus(false);
    // }
  }

  componentWillReceiveProps(nextProps) {
    let reduxState = store.getState();
    let dashboardSliderExists = typeof nextProps.sliderId === "string";
    let shouldDashboardSliderSet = dashboardSliderExists && nextProps.sliderId !== this.state.sliderId;
    let isCurrentDashboardValidAndDashboardChanged = this.isCurrentDashboardValid(nextProps.currentDashboard) && this.props.currentDashboard.dashboardId !== nextProps.currentDashboard.dashboardId ? true : false

    if (shouldDashboardSliderSet) {
      this.setState({
        ...this.state,
        sliderId: nextProps.sliderId
      }, () => {
        this.getDashboardSlider();

        store.dispatch(setCacheMode(false));
        store.dispatch(changeVisiblePopupContent(false));
      });
    } else if (isCurrentDashboardValidAndDashboardChanged) {
      this.setState({
        ...this.state,
        canPluginExportAreaMustShow: false
      }, () => {
        let isNextDashboardNavigationDashboard = (
          Object.keys(reduxState.NavigationReducer.navigation).length > 0 &&
          reduxState.NavigationReducer.navigation.dashboardId === nextProps.currentDashboard.dashboardId
        ) ? true : false

        this.getDashboard(nextProps.currentDashboard, true, false, isNextDashboardNavigationDashboard === false);

        store.dispatch(setCacheMode(false))
        store.dispatch(changeVisiblePopupContent(false))
      })
    }

    if (nextProps.selfService.modelId) {
      let succesFunc = (res) => {
        if (res.data) {
          this.setState({
            ...this.state,
            model: res.data
          })
        }
      }
      this.getModel(nextProps.selfService.modelId, succesFunc)
    }
  }

  /**
   * Stores the whole dashboard data of the active slider
   */
  sliderCache = new Map();

  /**
   * Slides to the given dashboard
   * 
   * @param {*} dashboard: Dashboard object to slide.
   * @param {*} first: This call is the first.
   * @returns 
   */
  slideDashboard = (dashboard, first = false) => {
    const state = _.cloneDeep(this.state);

    if (!dashboard || state.currentSlider.frame === dashboard.index) return;

    state.currentSlider.frame = dashboard.index;
    state.currentSlider.currentDashboard = dashboard;
    state.currentSlider.previousDashboard = state.currentSlider.dashboards[dashboard.index - 2];
    state.currentSlider.nextDashboard = state.currentSlider.dashboards[dashboard.index];

    if (first === true || this.state.currentSlider.currentDashboard?.id !== dashboard.id) {
      state.currentSlider.isBusy = true;
      
      this.setState(state, () => {
        clearAllInteractionsOnPlugins()
        clearAllDrillDownsOnPlugins()

        this.props.updateNavigationsAfterSwitchDashboard({
          dashboardId: dashboard.id,
          dashboardInformation: {}
        });
      });
    } else {
      this.setState(state, () => {
        this.setSliderBackwarded(true);
      });
    }
  };

  /**
   * Sets the slider playing status
   * 
   * @param {*} status 
   * @returns 
   */
  setSliderPlaying = (status) => {
    const state = { ...this.state };

    if (state.currentSlider.playing === status) return;

    state.currentSlider.playing = status;

    this.setState(state);
  }

  /**
   * Sets the slider backwarded status true, then false
   * 
   * @param {*} status 
   */
  setSliderBackwarded = (status = true) => {
    const state = { ...this.state };

    if (state.currentSlider.backwarded === status) return;

    state.currentSlider.backwarded = status;

    this.setState(state, () => {
      setTimeout(() => {
        if (status === true) {
          this.setSliderBackwarded(false);
        }
      })
    });
  }

  /**
   * Gets the current dashboard slider
   */
  getDashboardSlider = () => {
    const url = `${API_BASE}/dashboard-slider/slider/${this.state.sliderId}`;

    const errorFunc = (error) => {
      console.error(error);

      navigate(BASE_URL_UI);
    };

    const successFunc = (response) => {
      let slider = response.data;

      if (!slider.dashboards?.length > 0) {
        errorFunc("Dashboard slider does not contain any dashboard.");
      }

      let sortedDashboards = slider.dashboards.sort((a, b) => a.index - b.index);

      let currentSlider = {
        id: slider.id,
        name: slider.name,
        dashboards: sortedDashboards,
        playing: false,
        backwarded: false,
        currentDashboard: sortedDashboards[0]
      };

      this.setState({ currentSlider }, () => {
        this.slideDashboard(currentSlider.dashboards[0], true);
      });

      sessionStorage.setItem("currentSlider", slider.id);
    };

    get(url, successFunc, errorFunc);
  }

  /**
   * If we change dashboard left area, current dashboard name changes
   * 
   * @param {*} name 
   */
  updateDashboardNameForContainer = (name) => {
    this.setState({
      ...this.state,
      currentDashboardName: name,
      containerHeight: 0,
      draggableResizableStatus: false
    })
  }

  updateContainerHeight = height => {
    this.setState(prevState => {
      return {
        ...prevState,
        containerHeight: height
      }
    })


    $("#mainDiv").css("height", "100%")
  }

  /*
  * Gets and sets new columns to redux
  */
  getNewColumns = (modelId, callback) => {
    let url = `${API_BASE}/newColumn/${modelId}`

    let succesFunc = (res) => {
      store.dispatch(setNewColumnList(res.data));

      if (typeof callback === "function") {
        callback();
      }
    }

    let errorFunc = (err) => {
      console.log(err)
    }

    get(url, succesFunc, errorFunc)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentDashboardName !== this.state.dashboardInformation.name) {
      this.updateDashboardNameForContainer(this.state.dashboardInformation.name)
    }
    let height = 0

    if ($(".react-grid-layout")?.length !== 0) {
      height = $(".react-grid-layout")[0]?.scrollHeight
    } else {
      height = $("#mainDiv")[0]?.scrollHeight
    }

    if (this.state.containerHeight < height) {
      this.updateContainerHeight(height)
    }
  }

  /**
   * Applies the theme styles to dashboard
   */
  applyThemeToDashboard = (id, callback = () => { }) => {
    let theme = sessionStorage.getItem("theme-dashboard");

    if (!theme && id !== "") {
      theme = sessionStorage.getItem("theme-default");
    }

    if (theme) {
      theme = JSON.parse(theme);

      if (!this.state.theme || !this.state.themeObj) {
        this.setState({
          theme: theme._id,
          themeObj: theme,
          themeApplied: true
        }, callback);
      }
    } else {
      this.setState({
        theme: null,
        themeObj: null,
        themeApplied: true
      }, callback);
    }
  }

  componentDidMount() {
    let height = $("#mainDiv")[0].scrollHeight

    if (height !== undefined) {
      this.updateContainerHeight(height)
    }

    this.applyThemeToDashboard(null, () => this.getDashboard(this.props.currentDashboard, true));

    if (this.props.errorFallback === true) {
      this.props.updateErrorFallbackStatus(false);
    } else if (this.props.lastState === null || (JSON.stringify(this.props.lastState) !== JSON.stringify(this.state))) {
      this.props.addStateToHistory(this.state)
    }

    store.dispatch(setModelIdToModelsAreaCallbackFunction(this.setSelectedModel))

    // initialize visp lang for default filters
    initDefaultFilterLang();
  }

  /**
   * Decides that table data should be get
   * @param {*} plugin 
   * @returns 
   */
  decideGetTablePluginData = (plugin) => {
    if (plugin["key"] === "table") {
      if (plugin["data"] && plugin["config"]) {
        let columns = Object.values(plugin.columnMap)?.map(field => field?.data);
        columns = columns.flat();

        let isEnabledColumnControl = columns.some(c => c.isDisabledColumn !== true && c.locationFieldName !== "hidden");

        if (isEnabledColumnControl) {
          if (plugin["config"]["pagination"]) {
            return true;
          } else if (plugin["limit"] !== plugin["config"]["pageNumber"]) {
            return true
          }
        }
        return false;
      }

      return false;
    }

    return false;
  }

  countForExcludePlugins = 0

  /*
  * Counts exclude plugins if exclude plugins length equals plugin size returns error
  */
  countExcludePlugins = () => {
    this.countForExcludePlugins++

    if (this.countForExcludePlugins === this.state.plugins.length) {
      this.setState({
        ...this.state,
        excelExportClicked: false,
        excelExporting: false
      }, () => {
        this.excelExportProgress(EXCEL_EXPORT_PROGRESS.FAILED)

        showNotificationWithIcon(
          i18n.t("ERROR"),
          i18n.t("Dashboard.ExcelExport.ExportPluginCouldNotFound"),
          "warning"
        );

        this.excelExporting = false;
        this.countForExcludePlugins = 0
      })
    }
  }

  initializeLastModel = (callback) => {;
    const url = `${API_BASE}/subjectArea/nameList`;
    const successFunc = (response) => {
      if (response.data.length > 0) {
        const lastModel = response.data[response.data.length - 1];
        this.fillSelectedModel(lastModel.id, callback);

        return lastModel.id;
      }
    };

    try {
      const lastModelId = getSelectedModel();

      if (lastModelId) {
        this.fillSelectedModel(lastModelId, callback)

        return lastModelId;
      }

      get(url, successFunc);
    } catch (error) {
      Cookies.remove("selectedModel");

      get(url, successFunc);
    }

    return null;
  };
  
  

  // Fill tables etc. to the empty model
  fillSelectedModel = (modelId, callback = () => {}) => {
    let url = `${API_BASE}/subjectArea/${modelId}`;

    const successFunc = (response) => {
      const model = response.data;
      this.setSelectedModel(model);

      if (typeof callback === "function") {
        callback(model);
      }
    };

    get(url, successFunc);
  };

  /**
   * Pulls plugins with data for excel export
   */
  pluginsWithData = []
  pluginsWithDataInOrder = []
  excelExporting = false
  numOfPluginsThatCantSetData = 0 // to properly exclude plugins that cant set data from export

  setPluginsWithData = (originalPlugin, props, limit) => {
    let plugin = deepCopy(originalPlugin)
    let customExcelId = props.excelExportClicked;

    if (!this.excelExporting) {
      this.excelExporting = true;
      /* when this.state.excelExporting is set to true, user cannot export another excel file until current excel file is exported */
      this.setState(prevState => {
        return {
          excelExporting: true
        }
      })
    }

    /* isItPluginThatCantSetData control variable is not generic because there isnt any plugin variable that can help to distinguish between 
    plugins that can not set data and plugins that can set data but with no data set yet. plugin['data'] comes as undefined at both situation.*/
    let isItPluginThatCantSetData = excludeExcelExportPlugins.has(plugin.key) ? true : false;
    this.numOfPluginsThatCantSetData += (isItPluginThatCantSetData ? 1 : 0);

    if (!isItPluginThatCantSetData) {
      plugin.config.title = calculateText(plugin, plugin.config.title);

      if (plugin.key === "measure-tile") {
        for (let config of plugin.config.configArray) {
          config.measureHeader = calculateText(plugin, config.measureHeader);
        }
      }

      this.pluginsWithDataInOrder.push(plugin);
    }

    if (this.decideGetTablePluginData(plugin)) {
      for (const [key, value] of Object.entries(plugin)) {
        props.plugin[key] = value;
      }

      getAllPagesForExcel(plugin, props, this, limit);
    } else {
      if (!isItPluginThatCantSetData) {
        this.pluginsWithData.push(plugin);
      }

      if (this.pluginsWithData.length === (this.state.plugins.length - this.numOfPluginsThatCantSetData)) {
        if (customExcelId === true) {
          let exportDashboard = {};
          exportDashboard["title"] = this.state.settings.title;
          exportDashboard["plugins"] = this.pluginsWithDataInOrder;

          if (this.checkDataSizeAndExportStandartExcel(exportDashboard["plugins"])) {
            return;
          }

          generalExcelExport(exportDashboard, this.state.model, this.excelExportProgress)
        } else if (customExcelId) {
          customExcelId = this.props.mainDashboardId ? this.props.mainDashboardId : customExcelId

          exportCustomExcel(this.pluginsWithDataInOrder, this.state.dashboardDetail, customExcelId, () => this.saveExportLog(this.state.dashboardInformation.name, "XLSX"));
        }

        this.pluginsWithData = [];
        this.pluginsWithDataInOrder = [];
        this.excelExporting = false;
        this.numOfPluginsThatCantSetData = 0;

        this.setState(prevState => {
          return {
            ...prevState,
            excelExportClicked: false,
            excelExporting: false
          }
        })
      }
    }
  }


  /**
   * Big Size excel standard export 
   */
  excelExportBigSizeDownload = async () => {
    this.excelExportProgress(EXCEL_EXPORT_PROGRESS.STARTED)

    let excelExportCallback = (progres) => {
      this.excelExportProgress(progres)

      this.excelExporting = false;

      this.setState({
        ...this.state,
        excelExportClicked: false,
        excelExporting: false
      })
    }

    const successFunc = (res) => {
      excelExportCallback(EXCEL_EXPORT_PROGRESS.DONE)

      if (res?.data?.file) {
        let bytes = base64ToArrayBuffer(res?.data?.file);

        saveByteArray([bytes], res?.data?.filename);
      }

      showNotificationWithIcon("OK", i18n.t("Dashboard.ExcelExport.ExcelExportingSuccess"), "success");
    }

    const errorFunc = (err) => {
      excelExportCallback(EXCEL_EXPORT_PROGRESS.FAILED)
      showNotificationWithIcon(i18n.t("Error"), err, "error");
    }

    let queryObjList = []
    let reduxState = store.getState()
    let plugins = Array.from(reduxState.PluginTriggerReducer.plugins.values())

    for (let plugin of plugins) {
      let tempPlugin = deepCopy(plugin);
      let pluginHasError = plugin.errors?.size > 0

      if (pluginHasError) continue;

      if (excludeExcelExportPlugins.has(tempPlugin.key)) continue;

      if (getPluginsAllColumnsByField(tempPlugin.columnMap).length === 0) continue;

      tempPlugin.config.title = calculateText(tempPlugin, tempPlugin.config.title);

      if (tempPlugin.key === "measure-tile") {
        for (let config of tempPlugin.config.configArray || []) {
          config.measureHeader = calculateText(tempPlugin, config.measureHeader);
        }
      }

      let queryParameters = {
        offset: 0,
        columnMap: getPluginsAllColumnsByField(tempPlugin.columnMap)
      }
      // Get plugin query
      let queryObj = await bindData(
        { ...this.props, ...this.state, plugin: tempPlugin },
        () => { },
        undefined,
        queryParameters,
        undefined,
        undefined,
        tempPlugin.limit,
        undefined,
        true, //  isExcelExportBigSized
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      );

      if (!queryObj) continue;

      if (tempPlugin.config.pageNumber && !isNaN(+tempPlugin.config.pageNumber)) {
        queryObj.limit = +tempPlugin.config.pageNumber
      }

      let newQuery = {
        "query": queryObj,
        "plugin": tempPlugin.config
      }
      queryObjList.push(newQuery);
    }

    if (queryObjList.length === 0) {
      excelExportCallback(EXCEL_EXPORT_PROGRESS.DONE);
      showNotificationWithIcon(i18n.t("Error"), i18n.t("Dashboard.ExcelExport.ExportPluginCouldNotFound"), "error");
      return;
    }

    let url = "/api/export/excel-with-query";
    let queryObjAll =
    {
      "dashboardName": this.state.currentDashboardName,
      "dashboardId": this.props.currentDashboard.dashboardId,
      "queryList": queryObjList
    }

    post(url, queryObjAll, successFunc, errorFunc, false, false);
  }

  /**
   * Check data size and decide to navigate style or standart excel export 
   * @param {*} plugins 
   * @returns 
   */
  checkDataSizeAndExportStandartExcel = (plugins) => {
    // eslint-disable-next-line no-unused-vars
    let dataSizeLimit = 1500000; //1.5M cells - 150 chunk
    let dataCount = 0;

    for (let plugin of plugins) {
      if (plugin.data && plugin.data.length > 0) {
        let columnCount = Object.keys(plugin.data[0]).length;

        // eslint-disable-next-line no-unused-vars
        dataCount += plugin.data.length * columnCount
      }
    }

    if (false) {
      this.excelExportProgress(EXCEL_EXPORT_PROGRESS.DONE)
      this.downloadFEExcelExport(plugins)

      this.pluginsWithData = [];
      this.pluginsWithDataInOrder = [];
      this.excelExporting = false;
      this.numOfPluginsThatCantSetData = 0;

      this.setState(prevState => {
        return {
          ...prevState,
          excelExportClicked: false,
          excelExporting: false
        }
      })

      return true;
    }

    return false;
  }

  getAllDataTableForFEExport(plugin, columnMap, handleCompletion, index) {
    let exData = [];
    let selectList = columnMap.columns?.data?.filter(c => c.isDisabledColumn !== true);

    let queryParameters = {
      selectList: selectList,
      offset: 0
    };

    const successFunc = response => {
      let copiedPlugin = deepCopy(plugin)
      exData = mapResults(selectList, response.data);
      exData = mapData(plugin.columnMap, exData);

      // /* if there is drilldown, assign drilldown columns to columns of data */
      // if (plugin.drillDownColumnMap) {
      //   exData = exData.map(d => {
      //     d.columns = gridOptionsTop.rowData[0].columns;

      //     return d;
      //   });
      // }

      copiedPlugin["data"] = exData

      handleCompletion(copiedPlugin, index)
    };

    let pageNumber = plugin.config.pageNumber && plugin.config.pageNumber !== "" && plugin.config.pageNumber !== "0" && plugin.limit !== plugin.config.pageNumber
      ? plugin.config.pageNumber
      : plugin.limit
        ? plugin.limit
        : 10000

    bindData(
      { ...this.props, ...this.state, plugin: plugin },
      successFunc,
      undefined,
      queryParameters,
      undefined,
      undefined,
      +pageNumber,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
  }

  /*
  * Downloads Excel Export
  */
  downloadFEExcelExport = (exportPlugins = undefined) => {
    loadingScreen(true)

    setTimeout(() => {
      let reduxState = store.getState()
      let plugins = exportPlugins ? exportPlugins : Array.from(reduxState.PluginTriggerReducer?.plugins?.values());
      let excelPlugins = []
      let completedRequests = 0

      // Filter plugins that have at least one enabled column.      
      plugins = plugins.filter(plugin => {
        let columns = Object.values(plugin.columnMap)?.map(field => field?.data);
        columns = columns.flat();

        return columns.some(c => c.isDisabledColumn !== true && c.locationFieldName !== "hidden");
      });

      let mustCompletedRequestSize = plugins.filter(plugin => !excludeExcelExportPlugins.has(plugin.key)).length

      if (mustCompletedRequestSize === 0) {
        showNotificationWithIcon(
          i18n.t("ERROR"),
          i18n.t("Dashboard.ExcelExport.ExportPluginCouldNotFound"),
          "warning"
        );

        loadingScreen(false)

        return
      }

      for (let plugin of plugins) {
        plugin.config.title = calculateText(plugin, plugin.config.title);

        if (plugin.key === "measure-tile") {
          for (let config of plugin.config.configArray || []) {
            config.measureHeader = calculateText(plugin, config.measureHeader);
          }
        }
      }

      /*
      * When gets all data, triggers FE Export
      */
      let handleCompletion = (plugin, index) => {
        completedRequests++;
        excelPlugins[index] = plugin

        if (completedRequests === mustCompletedRequestSize) {
          exportToXLSX(excelPlugins, this.state.dashboardDetail, () => this.saveExportLog(this.state.dashboardInformation.name, "XLSX"));
        }
      }

      if (exportPlugins) {
        exportToXLSX(plugins, this.state.dashboardDetail, () => this.saveExportLog(this.state.dashboardInformation.name, "XLSX"));
      } else {
        for (let i = 0; i < plugins.length; i++) {
          if (plugins[i].key === "table") {
            this.getAllDataTableForFEExport(plugins[i], plugins[i].columnMap, handleCompletion, i)

          } else if (!excludeExcelExportPlugins.has(plugins[i].key)) handleCompletion(plugins[i], i)
        }
      }


    }, 1000)
  }

  /* if plugin is table and it has data and pagination, successFunc of getAllPagesForExcel in Table.jsx calls this setPluginsWithDataTable */
  setPluginsWithDataTable = (originalPlugin, originalData, props, limit) => {
    let plugin = deepCopy(originalPlugin)
    let data = deepCopy(originalData)

    let customExcelId = props.excelExportClicked;

    plugin.data = data;

    this.pluginsWithData.push(plugin);

    if (this.pluginsWithData.length === (this.state.plugins.length - this.numOfPluginsThatCantSetData)) {
      if (customExcelId === true) {
        let exportDashboard = {};
        exportDashboard["title"] = this.state.settings.title;
        exportDashboard["plugins"] = this.pluginsWithData;

        if (this.checkDataSizeAndExportStandartExcel(exportDashboard["plugins"])) {
          return;
        }

        generalExcelExport(exportDashboard, this.state.model, this.excelExportProgress)
      } else if (customExcelId) {
        customExcelId = this.props.mainDashboardId ? this.props.mainDashboardId : customExcelId

        exportCustomExcel(this.pluginsWithDataInOrder, this.state.dashboardDetail, customExcelId, () => this.saveExportLog(this.state.dashboardInformation.name, "XLSX"));
      }

      this.pluginsWithData = [];
      this.pluginsWithDataInOrder = [];
      this.excelExporting = false;
      this.numOfPluginsThatCantSetData = 0;

      this.setState(prevState => {
        return {
          ...prevState,
          excelExportClicked: false,
          excelExporting: false
        }
      })
    }
  }

  /**
   * Set status excel exporting loader
   * @param {*} status 
   */
  excelExportProgress = (status) => {
    const closeProgress = () => {
      if (status === EXCEL_EXPORT_PROGRESS.DONE || status === EXCEL_EXPORT_PROGRESS.FAILED) {
        setTimeout(() => {
          this.setState({
            ...this.state,
            excelExportProgress: undefined
          })
        }, 2000)
      }
    }

    this.setState({
      ...this.state,
      excelExportProgress: status
    }, () => {
      closeProgress()
    }
    )
  }

  updateModelTablesForJoin = (joinData) => {
    let selectedModel = { ...this.state.model }

    selectedModel.tables.map((table) => {
      let tableDataSourceKeyWithAlias = table.dataSourceKey + "?" + table.aliasName

      if (joinData.factTableAlias === tableDataSourceKeyWithAlias) {
        table.tableType = "Fact"
      } else if (joinData.dimensionTableAlias === tableDataSourceKeyWithAlias) {
        table.tableType = "Dimension"
      }

      return table;
    });

    this.setState({
      ...this.state,
      selectedModel: selectedModel
    })
  }

  /**
   *
   * @param {*} currentDashboard
   * Checks if the current dashboard object is valid
   */
  isCurrentDashboardValid = currentDashboard => {
    return (
      currentDashboard &&
      Object.keys(currentDashboard).length !== 0 &&
      currentDashboard.dashboardId &&
      currentDashboard.dashboardId !== ""
    );
  };

  /**
   * checks if authenticateduser is admin or editor if not checks the authorization to the given(current) dashboard id
   */
  checkMyAuthorizationForThisDashboard = currentDashboard => {
    if (isValidWriteRoles()) {
      this.setState({
        ...this.state,
        readOnly: "WRITE"
      });
    }
    else {
      let url = `${API_BASE}/dashboard/` + currentDashboard.dashboardId + `/operation-type`;

      if (this.isCurrentDashboardValid(currentDashboard)) {
        const successFunc = result => {
          this.setState({
            ...this.state,
            readOnly: result.data
          });
        };

        get(url, successFunc, undefined, false);
      }
    }
  }

  /**
   * Cleans the plugins in the Plugin Trigger Reducer from the previous report
   */
  clearPluginList = () => {
    let reduxState = store.getState();
    let updatedPlugins = reduxState.PluginTriggerReducer.plugins;
    updatedPlugins.clear();

    setUpdatedPlugins(updatedPlugins)

    this.setState({
      ...this.state,
      plugins: [],
      settings: { ...this.initialSettings },
    })
  }

  /* Sets title settings to its default (last saved). */
  setTitleSettingsToDefault = (dashboardSettings) => {
    dashboardSettings.dashboardTitleSettings.defaultTitle = dashboardSettings.title;
    dashboardSettings.dashboardTitleSettings.defaultTitleFontWeight = dashboardSettings.dashboardTitleSettings.titleFontWeight;
    dashboardSettings.dashboardTitleSettings.defaultTitleTextDecor = dashboardSettings.dashboardTitleSettings.titleTextDecor;
    dashboardSettings.dashboardTitleSettings.defaultTitleFontStyle = dashboardSettings.dashboardTitleSettings.titleFontStyle;
    dashboardSettings.dashboardTitleSettings.defaultTitleFontSize = dashboardSettings.dashboardTitleSettings.titleFontSize;
    dashboardSettings.dashboardTitleSettings.defaultTitleColor = dashboardSettings.dashboardTitleSettings.titleColor;
    dashboardSettings.dashboardTitleSettings.defaultTitleFont = dashboardSettings.dashboardTitleSettings.titleFont;
  }

  /*
  * Decide interactions for Pie-Chart to Pie-Chart-Enhanced
  */
  decideInteractions = (dashboardDetail, interactions) => {
    let copiedInteractions = deepCopy(interactions)

    for (let i = 0; i < interactions.length; i++) {
      let sourcePlugin = dashboardDetail.plugins.filter(plugin => plugin.id === interactions[i].sourceId)[0]

      if (sourcePlugin && sourcePlugin.key === "pie-chart" && interactions[i].actions[0].trigger === "hoverSlice") {
        copiedInteractions[i].actions[0].trigger = "clickSlice"
        copiedInteractions[i].actions[0].type = "click"
        copiedInteractions[i].actions[0].name = "Click Slice"
      } else if (sourcePlugin?.key === "button-filter" && interactions[i].actions[0].trigger === "select") {
        copiedInteractions[i].actions[0].trigger = "click"
        copiedInteractions[i].actions[0].type = "click"
        copiedInteractions[i].actions[0].name = "click"
      }
    }

    return copiedInteractions
  }

  /*
  * Gets and pulls custom dashboards by id
  */
  customDashboardSuccesFunc = (response, id = undefined) => {
    let THIS = this

    response.data.splice(0, 1)

    this.setState({
      ...this.state,
      customDashboards: response.data,
    }, () => {
      if (THIS.state.customDashboards.length === 0 || (id !== undefined && id === THIS.state.dashboardDetail.id)) {
        THIS.getDashboard({ ...THIS.state.mainDashboard, dashboardId: THIS.state.mainDashboard.id }, false, false)
      }
    })
  }

  /*
  * Deletes custom dashboard by id
  */
  deleteCustomDashboard = (id) => {
    let url = `${API_BASE}/customDashboard`
    let urlWithParameters = url + "/" + id;

    del(
      urlWithParameters,
      {},
      response => {
        let customDashboardUrl = `${API_BASE}/customDashboard/` + response.data.mainDashboardId

        get(customDashboardUrl, (response) => this.customDashboardSuccesFunc(response, id))

        showNotificationWithIcon(i18n.t("CustomDashboard.CustomDashboardDeleteSucces"), null, "success");
      },
      error => {
        showNotificationWithIcon(i18n.t("CustomDashboard.CustomDashboardDeleteError"), null, "error");
      }
    );
  }

  /*
  * Sets custom dashboard as main dashboard
  */
  setCustomDashboardAsMainDashboard = (customDashboardId) => {
    let mainDashboard = {}
    let url = `${API_BASE}/customDashboard/setCustomAsMainDashboard`

    mainDashboard["id"] = customDashboardId
    mainDashboard["mainDashboardId"] = this.state.mainDashboardId ? this.state.mainDashboardId : this.state.id

    let dashboardObj = {
      id: this.state.mainDashboardId ? this.state.mainDashboardId : this.state.id,
      dashboardId: this.state.mainDashboardId ? this.state.mainDashboardId : this.state.id,
    }

    const succesFunc = () => {
      this.setState({
        ...this.state,
        isDashboardSave: true
      }, () => this.getDashboard(dashboardObj))
    }

    post(url, mainDashboard, succesFunc, () => { })
  }

  /*
  * Gets dashboard or custom dashboard by isCustomDashboard parameter
  */
  getDashboard = (currentDashboard, isCustomDashboard = false, isComesFromSwitch = false, clear = true) => {
    let currentSliderFrameCache = this.sliderCache[this.state.currentSlider?.currentDashboard?.index];

    if (currentSliderFrameCache) {
      // If current dashboard data is stored in slider cache, get dashboard directly.
      this.getSelectedDashboardOrCustomDashboard(currentDashboard, isCustomDashboard, clear, null, null)
    } else if (this.isCurrentDashboardValid(currentDashboard)) {
      let mainDashboard;
      let dashboardState = this.state ? this.state : this
      let mainDashboardId = currentDashboard.comeFromSwitchDashboard
        ? currentDashboard.dashboardId
        : currentDashboard.mainDashboardId
          ? dashboardState.mainDashboardId
          : currentDashboard.dashboardId
      let newUrl = `${API_BASE}/dashboard/` + mainDashboardId
      let customDashboardUrl = `${API_BASE}/customDashboard/` + mainDashboardId

      let succesFunc = (res) => {
        let data = res.data

        mainDashboard = data[0];

        if (data.length > 1) {
          for (let i = 1; i < data.length; i++) {
            if (isCustomDashboard) {
              if ((data[i].active === true && !isComesFromSwitch) || currentDashboard.id === data[i].id) {
                newUrl = `${API_BASE}/customDashboard/perCustomDashboard/` + data[i].id;
              }
            }
          }
        }

        res.data.splice(0, 1)

        this.setState({
          ...this.state,
          customDashboards: res.data,
          canPluginExportAreaMustShow: false
        }, () => this.getSelectedDashboardOrCustomDashboard(currentDashboard, isCustomDashboard, clear, newUrl, mainDashboard))
      }

      get(customDashboardUrl, succesFunc, () => { });
    } else {
      this.getSelectedDashboardOrCustomDashboard(currentDashboard, isCustomDashboard, clear);
    }
  }

  /*
  * Sorts dashboard by position, not add order.
  */
  sortDashboardPluginsForMobile = (plugins) => {
    return plugins.sort((a, b) => (a.y - b.y || a.x - b.x));
  }

  /** Gets and pull's selected dashboard or custom dashboard by id */
  getSelectedDashboardOrCustomDashboard = (currentDashboard, isCustomDashboard = false, clear = true, url, mainDashboard) => {
    const currentSliderFrameCache = _.cloneDeep(this.sliderCache[this.state.currentSlider?.currentDashboard?.index]);

    if (currentSliderFrameCache) {
      // If current dashboard data is stored in slider cache, set cached data in order to open dashboard.

      this.clearAllInteractionsOnPlugins();
      this.clearAllDrillDownsOnPlugins();
      this.clearPluginList();

      const {
        theme,
        state,
        definedExpressionSet,
        originalPlugins,
        originalInteractions,
        cachedPluginsMap,
        savedCachedPluginsMap,
        savedCacheTime,
        disabledColumnSet,
        drillDowns,
        triggeredDrillDowns,
        pluginColumnClickedAndShouldBeRemoved,
        newColumnList,
        dashboardSessionVariables,
        sessionVariables
      } = currentSliderFrameCache;

      const {
        dashboardDetail
      } = state;

      const currentSlider = _.cloneDeep(this.state.currentSlider);

      sessionStorage.setItem("theme-dashboard", theme);

      setTimeout(() => store.dispatch(setDefinedExpression(definedExpressionSet)));
      setTimeout(() => store.dispatch(changeOriginalPlugins(originalPlugins)));
      setTimeout(() => store.dispatch(changeOriginalInteractions(originalInteractions)));
      setTimeout(() => store.dispatch(addPluginCacheMode(cachedPluginsMap)));
      setTimeout(() => store.dispatch(savedPluginsInCacheMode(savedCachedPluginsMap)));
      setTimeout(() => store.dispatch(cachedPluginsTime(savedCacheTime)));
      setTimeout(() => store.dispatch(changeDisabledColumnsStatus(disabledColumnSet)));
      setTimeout(() => store.dispatch(setPluginsDrillDowns(drillDowns)));
      setTimeout(() => store.dispatch(setTriggeredDrillDowns(triggeredDrillDowns)));
      setTimeout(() => store.dispatch(setpluginColumnClickedAndShouldBeRemoved(pluginColumnClickedAndShouldBeRemoved)));
      setTimeout(() => store.dispatch(setNewColumnList(newColumnList)))
      setTimeout(() => store.dispatch(setDashboardSessionVariables(dashboardSessionVariables)));
      setTimeout(() => store.dispatch(setSessionVariableContent(sessionVariables)));

      pushWindowHistoryIfComesFromDashboardSlider(
        this.state.sliderId,
        dashboardDetail.mainDashboardId ? dashboardDetail.mainDashboardId : dashboardDetail.id
      );

      currentSlider.isBusy = false;
      state.currentSlider = currentSlider;

      this.setState(state);

      return;
    }

    if (this.isCurrentDashboardValid(currentDashboard)) {
      const currentSliderFrameCache = {};
      const sliderExists = this.state.currentSlider?.id ? true : false;

      if (clear) {
        this.clearAllInteractionsOnPlugins();
        this.clearAllDrillDownsOnPlugins();
      }

      const successFunc = result => {
        let dashboardDetail = result.data;

        this.clearPluginList();

        const modelSuccessFunc = modelResponse => {
          //Sends dashboard name to Navigation
          this.props.setDashboardTitle(
            currentDashboard.dashboardId,
            dashboardDetail.settings.title
          );

          let dashboardInformation = {
            name: dashboardDetail.settings.title,
            id: this.props.currentDashboard.dashboardId
          };

          let dashboardSettings = deepCopy(dashboardDetail.settings);
          dashboardSettings.defaultBackgroundColor =
            dashboardSettings.backgroundColor;
          dashboardSettings.defaultBackgroundImage =
            dashboardSettings.backgroundImage;
          dashboardSettings.defaultBackgroundImageTemp =
            dashboardSettings.backgroundImageTemp;

          if (dashboardSettings["dashboardTitleSettings"] === undefined) {
            dashboardSettings.dashboardTitleSettings = { ...this.initialSettings.dashboardTitleSettings }
          } else {
            // Checks if defaults have never been setted before and if necessary sets defaults.
            for (let value of Object.values(dashboardSettings.dashboardTitleSettings)) {
              if (value === null) {
                dashboardSettings.dashboardTitleSettings = this.state.settings.dashboardTitleSettings;
              }
            };
          }

          this.setTitleSettingsToDefault(dashboardSettings);

          let reduxState = store.getState()
          let isCustomDashboardModeActive = reduxState.CustomDashboardReducer.isCustomDashboard

          if (this.state.readOnly === "WRITE" || isCustomDashboardModeActive) {
            dashboardSettings.grid.isDraggableAndResizable = true;
            dashboardSettings.grid.forceDisableDragableAndResizable = false;
          }
          else {
            dashboardSettings.grid.isDraggableAndResizable = false;
            dashboardSettings.grid.forceDisableDragableAndResizable = true;
          }

          if (dashboardSettings["dashboardTitleSettings"] === undefined) {
            dashboardSettings.dashboardTitleSettings = { ...this.initialSettings.dashboardTitleSettings }
          } else {
            // Checks if defaults have never been setted before and if necessary sets defaults.
            for (let value of Object.values(dashboardSettings.dashboardTitleSettings)) {
              if (value === null) {
                dashboardSettings.dashboardTitleSettings = this.state.settings.dashboardTitleSettings;
              }
            };
          }

          if (dashboardSettings.isExcelExportStyled === undefined) {
            dashboardSettings.isExcelExportStyled = true
            dashboardSettings.isExcelExportBigSized = false

          }

          pushWindowHistoryIfComesFromSwitchDashboard(currentDashboard.comeFromSwitchDashboard, dashboardDetail.mainDashboardId ? dashboardDetail.mainDashboardId : dashboardDetail.id)
          pushWindowHistoryIfComesFromDashboardSlider(this.state.sliderId, dashboardDetail.mainDashboardId ? dashboardDetail.mainDashboardId : dashboardDetail.id)

          /**
           * If dashboard has auto refresh, convert excepted plugins to use
           */
          let autoRefreshExceptedPlugins;
          let autoRefresh;

          if (dashboardDetail.autoRefresh) {
            autoRefreshExceptedPlugins = new Set(dashboardDetail.autoRefresh.exceptedPlugins);
            autoRefresh = dashboardDetail.autoRefresh

            autoRefresh.exceptedPlugins = autoRefreshExceptedPlugins;
          }

          let whatIf = undefined;

          if (dashboardDetail.whatIf) {
            whatIf = {};
            whatIf.parameters = dashboardDetail.whatIf.parameters === null ? [] : dashboardDetail.whatIf.parameters;

            for (let param of whatIf.parameters) {
              param.currentValue = param.defaultValue;
            }

            whatIf.scenarios = dashboardDetail.whatIf.scenarios === null ? [] : dashboardDetail.whatIf.scenarios;
          }

          /*
          * Sets disabled columns for interaction
          */
          let disabledColumnSet = new Set()
          let cachedPluginsMap = new Map()
          let savedCachedPluginsMap = new Map()
          let savedCacheTime = {
            type: "ON_THE_HALF_HOUR"
          }

          let { originalPlugins, originalInteractions } = reduxState.ContextMenuReducer;
          let { drillDowns, triggeredDrillDowns, pluginColumnClickedAndShouldBeRemoved } = reduxState.DrillDownReducer;

          let definedExpressionSet = new Map();

          for (let i = 0; i < dashboardDetail.plugins.length; i++) {
            if (dashboardDetail.plugins[i]) {
              let plugin = dashboardDetail.plugins[i]
              let pluginId = plugin.id

              if (plugin.key === "measure-tile") {

                for (let measure of plugin.columnMap.measure.data) {
                  if (measure.preserveDataAs && !measure.isDisabledColumn) {
                    definedExpressionSet.set(measure.uniqeColumnId, measure.preserveDataAs);
                  }
                }
              }

              if (originalPlugins.has(pluginId)) {
                plugin = originalPlugins.get(pluginId);

                originalPlugins.delete(pluginId);
                triggeredDrillDowns.delete(pluginId);
                pluginColumnClickedAndShouldBeRemoved.delete(pluginId);
                drillDowns.delete(pluginId);

                if (plugin.drillDowns) {
                  plugin.drillDowns = convertDrillDownStringToMap(plugin.drillDowns);

                  plugin.drillDowns?.drillDownLayerMap?.forEach(layer => {
                    layer.hasDrillDownOperation = false;
                  });

                  drillDowns.set(pluginId, plugin.drillDowns);
                }

                dashboardDetail.interactions = dashboardDetail.interactions.filter(interaction => interaction.sourceId !== pluginId);

                if (!exceptInteractionComponent.has(plugin.key)) {
                  dashboardDetail.interactions.push(...originalInteractions.get(pluginId));
                }

                originalInteractions.delete(pluginId);
              }

              if (plugin.isCached) {
                cachedPluginsMap.set(pluginId, { status: true, cacheOptions: plugin.cacheTime })
                savedCachedPluginsMap.set(pluginId, { status: true, cacheOptions: plugin.cacheTime })
                savedCacheTime = plugin.cacheTime
              } else {
                cachedPluginsMap.set(pluginId, { status: false, cacheOptions: {} })
                savedCachedPluginsMap.set(pluginId, { status: false, cacheOptions: {} })
              }
            }

            if (dashboardDetail.plugins[i] && dashboardDetail.plugins[i].columnMap) {
              let pluginColumnMapAreaKeys = Object.keys(dashboardDetail.plugins[i].columnMap)

              for (let colKey of pluginColumnMapAreaKeys) {
                for (let j = 0; j < dashboardDetail.plugins[i].columnMap[colKey].data.length; j++) {
                  let data = dashboardDetail.plugins[i].columnMap[colKey].data[j]

                  if (data.isDisabledColumn === true) {
                    disabledColumnSet.add(data.uniqeColumnId)
                  }
                }
              }
            }
          }

          store.dispatch(setDefinedExpression(definedExpressionSet));
          sessionStorage.removeItem("theme-dashboard");

          let defaultTheme = this.props.themes?.find(theme => theme.default === true);
          let dashboardTheme = dashboardDetail?.theme && this.props.themes?.find(t => t._id === dashboardDetail?.theme);
          let theme = dashboardTheme ? dashboardTheme : dashboardDetail?.theme !== "" ? defaultTheme : null;

          let pluginTheme;
          let themeFields;

          if (theme) {
            pluginTheme = theme.plugin;
            themeFields = Object.keys(pluginTheme);

            if (typeof pluginTheme.titleFontSize !== "undefined") {
              theme.plugin.changedTitleFontSize = theme.plugin.titleFontSize;
            }

            let stringifiedTheme = JSON.stringify(theme);

            sessionStorage.setItem("theme-dashboard", stringifiedTheme);

            if (sliderExists) {
              currentSliderFrameCache.theme = stringifiedTheme;
            }
          }

          for (let plugin of dashboardDetail.plugins || []) {
            if (plugin.config) {
              if (plugin.config.hasOwnProperty("showCondForm")) {
                plugin.config.condFormat = plugin.config.showCondForm;

                delete plugin.config.showCondForm;
              }

              plugin.originalConfig = deepCopy(plugin.config);
              plugin.preservedConfigFields = new Set([...(plugin.preservedConfigFields || [])]);

              if (theme) {
                for (let field of themeFields) {
                  let themeValue = pluginTheme[field];

                  if (this.controlPreservedConfigHasField(plugin, field)) continue;

                  if (typeof themeValue !== "undefined") {
                    plugin.config[field] = themeValue;

                    if (plugin.key === "age-pyramid") {
                      if (field === "legend") {
                        plugin.config["showRadarLegends"] = themeValue
                      }

                      if (field === "condFormat") {
                        plugin.config["showConditionalLegends"] = themeValue
                      }
                    }
                  }
                }
              }
            }
          }

          this.applyThemeToDashboard(dashboardDetail?.theme);

          setTimeout(() => store.dispatch(changeOriginalPlugins(originalPlugins)));
          setTimeout(() => store.dispatch(changeOriginalInteractions(originalInteractions)));
          setTimeout(() => store.dispatch(addPluginCacheMode(cachedPluginsMap)));
          setTimeout(() => store.dispatch(savedPluginsInCacheMode(savedCachedPluginsMap)));
          setTimeout(() => store.dispatch(cachedPluginsTime(savedCacheTime)));
          setTimeout(() => store.dispatch(changeDisabledColumnsStatus(disabledColumnSet)));
          setTimeout(() => store.dispatch(setPluginsDrillDowns(drillDowns)));
          setTimeout(() => store.dispatch(setTriggeredDrillDowns(triggeredDrillDowns)));
          setTimeout(() => store.dispatch(setpluginColumnClickedAndShouldBeRemoved(pluginColumnClickedAndShouldBeRemoved)));

          if (sliderExists) {
            currentSliderFrameCache.originalPlugins = _.cloneDeep(originalPlugins);
            currentSliderFrameCache.originalInteractions = _.cloneDeep(originalInteractions);
            currentSliderFrameCache.cachedPluginsMap = _.cloneDeep(cachedPluginsMap);
            currentSliderFrameCache.savedCachedPluginsMap = _.cloneDeep(savedCachedPluginsMap);
            currentSliderFrameCache.savedCacheTime = _.cloneDeep(savedCacheTime);
            currentSliderFrameCache.disabledColumnSet = _.cloneDeep(disabledColumnSet);
            currentSliderFrameCache.drillDowns = _.cloneDeep(drillDowns);
            currentSliderFrameCache.triggeredDrillDowns = _.cloneDeep(triggeredDrillDowns);
            currentSliderFrameCache.pluginColumnClickedAndShouldBeRemoved = _.cloneDeep(pluginColumnClickedAndShouldBeRemoved);
          }

          this.getJoinData(modelResponse.data.id, false, () => this.setState({
            ...this.state,
            lastUpdatedDate: dashboardDetail.lastUpdatedDate,
            creationDate: dashboardDetail.creationDate,
            createdUser: dashboardDetail.createdUser,
            description: dashboardDetail.description,
            thumbnail: dashboardDetail.thumbnail,
            customThumbnail: dashboardDetail.customThumbnail,
            viewCount: dashboardDetail.viewCount,
            plugins: this.sortDashboardPluginsForMobile(deepCopy(dashboardDetail.plugins)),
            modelId: modelResponse.data.id,
            interactions: this.decideInteractions(dashboardDetail, dashboardDetail.interactions),
            settings: dashboardSettings,
            labels: dashboardDetail.labels,
            dashboardDetail: dashboardDetail,
            id: this.props.currentDashboard.dashboardId,
            model: modelResponse.data,
            dashboardInformation: dashboardInformation,
            autoRefresh: autoRefresh,
            whatIf: whatIf,
            loadingScreenOn: false,
            isCustomDashboard: isCustomDashboard && result.data.mainDashboardId ? true : false,
            mainDashboard: mainDashboard,
            isDashboardSave: false,
            mainDashboardId: currentDashboard.comeFromSwitchDashboard
              ? currentDashboard.dashboardId
              : currentDashboard.mainDashboardId
                ? currentDashboard.mainDashboardId
                : currentDashboard.dashboardId,
            pureMainDashboardPlugin: deepCopy(dashboardDetail.plugins),
            pureMainDashboardSettings: deepCopy(dashboardSettings),
            theme: theme ? theme._id : "",
            themeObj: theme ? theme : null
          }, () => {
            let status = false;

            const state = _.cloneDeep(this.state);

            if (sliderExists) {
              status = true;

              state.currentSlider.isBusy = false;
              currentSliderFrameCache.state = { ...state };

              this.sliderCache[state.currentSlider?.currentDashboard?.index] = currentSliderFrameCache;
            }

            if (status) this.setState(_.cloneDeep(state));
          }));
        };

        if (dashboardDetail === null) {
          showNotificationWithIcon(i18n.t("Error"), i18n.t("Dashboard.DashboardNotFound"), "error");
        } else {
          let modelId = dashboardDetail.modelId;

          if (!dashboardDetail.modelId) {
            modelId = getSelectedModel();
          }

          this.getSessionVariables(modelId, true, () => {
            this.getModel(modelId, modelSuccessFunc);
          });
        }
      };

      this.cancelRequests(() => get(url, successFunc));
    } else { // if new dashboard created
      const modelSuccessFunc = (model) => {
        this.getJoinData(model.id, false)
        this.setSelectedModel(model)
        this.setTitleSettingsToDefault(this.state.settings)
        this.changeLoadingScreenOnStatus(false)
      }

      let selectedModelId = this.initializeLastModel(modelSuccessFunc);

      if (selectedModelId) {
        this.cancelRequests(() => this.getSessionVariables(selectedModelId, true));
      }
    }
  };

  /** To cancel join requests. */
  cancelRequests = (callback) => {
    if (this.joinCancelTokenSource) {
      this.joinCancelTokenSource.cancel();
    }

    this.setState({
      ...this.state,
      cancelRequests: true,
    }, () => {
      this.setState({
        ...this.state,
        cancelRequests: false,
      }, () => callback())
    })
  }

  /**
   * Get the selected model & its tables
   * 
   * @param {*} modelId 
   */
  getModelTables = (modelId) => {
    let selectedModelId = modelId || getSelectedModel();

    const modelSuccessFunc = (response) => {
      this.setState({
        currentModelWithTable: response.data,
        model: response.data
      })
    }

    this.getModel(selectedModelId, modelSuccessFunc, false);
  }

  /**
   * Get the desired model
   * 
   * If loadingStatus is true, change loadingScreenOn status to true
   *  which is used some operations such as hiding the dashboard title on loading.
   * 
   * @param {*} id 
   * @param {*} successFunc 
   * @param {*} loadingStatus 
   */
  getModel = (id, successFunc, loadingStatus = true) => {
    let callback = () => {
      let url = `${API_BASE}/subjectArea/` + id;

      if (loadingStatus) {
        this.changeLoadingScreenOnStatus(true);
      }

      if (successFunc === "comesFromNewColumn") {
        successFunc = (res) => {
          if (res.data) {
            this.setState({
              ...this.state,
              model: res.data,
              newColumnAddedFlag: true
            }, () => {
              this.setState({
                ...this.state,
                newColumnAddedFlag: false
              })
            })
          }
        }
      }

      get(url, successFunc);
    }

    this.getNewColumns(id, callback)
  };

  /*
  * Removes column
  */
  removeNewColumn = (column) => {
    let model = deepCopy(this.state.model)

    model.tables.find(table => table.aliasName === column.table.aliasName).columns = model.tables.find(table => table.aliasName === column.table.aliasName).columns.filter(col => col.aliasName !== column.column.aliasName)

    this.setState({
      model: model
    }, () => {
      loadingScreen(false)
    })
  }

  /**
   * Gets session variables of model & executes dashboard scopped variables
   * 
   * @param {*} modelId 
   * @param {*} loadingStatus 
   * @param {*} callback 
   * @returns 
   */
  getSessionVariables = (modelId, loadingStatus = true, callback = () => { }) => {
    if (typeof modelId !== "string" || modelId === "") {
      return;
    }

    let url = `${API_BASE}/session-variable/${modelId}`;

    changeStatusOfGeneralLoading("sessionVariable", true)

    const successFunc = (response) => {
      if (response?.data) {
        let sessionVariables = new Map(response.data.map(v => [v.name, v]))
        let variablesShouldExecuteNow = new Map();

        for (let v of sessionVariables.values()) {
          if (v.scope === "dashboard") {
            variablesShouldExecuteNow.set(v._id, v.defaultValue);
          }
        }

        if (variablesShouldExecuteNow.size > 0) {
          let url = `${API_BASE}/session-variable/variable/value`;
          let requestBody = Array.from(variablesShouldExecuteNow.keys());
          let dashboardSessionVariables = new Map();

          let successFunc = result => {
            if (result.data) {
              let resultList = Array.from(Object.values(result.data));

              for (let obj of resultList) {
                if (obj.status !== "success") {
                  let title = `${i18n.t("SessionVariable")}: ${obj.name}`
                  let message = obj.code
                    ? i18n.t("SessionVariables.Errors." + obj.code)
                    : obj.message
                      ? obj.message
                      : i18n.t("DataConnections.CouldNotFetch");

                  showNotificationWithIcon(title, message, "warning");
                }

                dashboardSessionVariables.set(obj.name, obj.result);
              }

              if (this.state.currentSlider) {
                let currentFrameCache = this.sliderCache[this.state.currentSlider.currentDashboard.index] || {};

                this.sliderCache[this.state.currentSlider.currentDashboard.index] = {
                  ...currentFrameCache,
                  sessionVariables,
                  dashboardSessionVariables
                }
              }

              store.dispatch(setDashboardSessionVariables(dashboardSessionVariables));
              store.dispatch(setSessionVariableContent(sessionVariables));

              changeStatusOfGeneralLoading("sessionVariable", false)

              callback instanceof Function && callback();
            }
          }

          let errorFunc = error => {
            showNotificationWithIcon(i18n.t("SessionVariables.Title"), error, "error");

            store.dispatch(setSessionVariableContent(sessionVariables));

            changeStatusOfGeneralLoading("sessionVariable", false)


            callback instanceof Function && callback();
          }

          post(url, requestBody, successFunc, errorFunc, loadingStatus);
        } else {
          store.dispatch(setSessionVariableContent(sessionVariables));

          changeStatusOfGeneralLoading("sessionVariable", false)

          callback instanceof Function && callback();
        }
      }
    }

    const errorFunc = (error) => {
      showNotificationWithIcon(i18n.t("Error"), error, "error");

      store.dispatch(setSessionVariableContent(new Map()));

      callback instanceof Function && callback();
    }

    get(url, successFunc, errorFunc, loadingStatus);
  }

  findDashboardId = () => {
    let afterDashboarUrl = window.location.pathname.split("dashboard")[1];

    if (afterDashboarUrl === undefined || afterDashboarUrl === "") {
      return "";
    }

    let dashboardIdCandidate = afterDashboarUrl.split("/")[1];
    if (this.isDashboardIdCandidateValid(dashboardIdCandidate) === false) {
      return "";
    }

    let dashboardIdWithoutQueryString = dashboardIdCandidate.split("?")[0];
    return dashboardIdWithoutQueryString;
  };

  isDashboardIdCandidateValid = dashboardIdCandidate => {
    return dashboardIdCandidate.match(/[0-9a-z]/i);
  };

  addJoinToJoinList = (addedJoin) => {
    this.setState({
      join: this.state.join.concat(addedJoin)
    })
  }

  /* Changes loadingScreenOn status to value */
  changeLoadingScreenOnStatus = (value) => {
    this.setState({
      ...this.state,
      loadingScreenOn: value
    });
  }

  joinCancelTokenSource = undefined;

  getJoinData = (modelId, showLoadingScreen = true, callback = undefined) => {
    if (!modelId) return;

    this.joinCancelTokenSource = Axios.CancelToken.source();

    const url = `/api/join/${modelId}`
    const successFunc = response => {
      this.setState({
        ...this.state,
        join: response.data
      }, () => {
        if (callback) {
          callback()
        }
      })
    }

    get(url, successFunc, undefined, showLoadingScreen, false, undefined, true, this.joinCancelTokenSource.token);
  };

  /*
  * Saves dashboard or custom dashboard
  */
  saveDashboard = (data, isCustomDashboard = false, mainDashboardId) => {
    let dashboardInformation = { ...this.state.dashboardInformation };
    let THIS = this

    if (!this.state.id || this.state.id !== data.id) {
      if (this.props.navigations.length === 0) {
        let newDashboard = { id: data.id, title: data.settings.title };
        this.props.setNewDashboard(newDashboard);
      }
    }

    dashboardInformation.name = data.settings.title;
    dashboardInformation.id = data.id;

    if (!isCustomDashboard) {
      pushWindowHistory(dashboardInformation.id);

      this.onChangeTitleMainDashboard()
    }

    store.dispatch(changeOriginalPlugins(new Map()));

    this.props.updateCurrentDashboard(dashboardInformation, isCustomDashboard, mainDashboardId)

    this.setState({
      ...this.state,
      id: data.id,
      creationDate: data.creationDate,
      lastUpdatedDate: data.lastUpdatedDate,
      labels: data.labels,
      settings: data.settings,
      dashboardInformation: dashboardInformation,
      dashboardDetail: data,
      isDashboardSave: true,
      isCustomDashboard: isCustomDashboard ? true : false
    },
      () => this.setState({
        ...this.state,
        isDashboardSave: false
      }, () => {
        if (isCustomDashboard) {
          let allCustomDashboardUrl = `${API_BASE}/customDashboard/` + THIS.state.mainDashboardId;

          get(allCustomDashboardUrl, this.customDashboardSuccesFunc)
        }
      }));
  };

  selectPlugin = plugin => {
    let tempPlugin = deepCopy(plugin);
    tempPlugin["id"] = uuid();

    this.setState({
      ...this.state,
      selectedPlugin: tempPlugin
    });
  };

  /*
  * Controls for pyramid-chart theme legend and conditional format legends
  */
  controlPreservedConfigHasField = (plugin, field) => {
    if (plugin.preservedConfigFields.has(field)) {
      return true
    } else if (field === "legend" && plugin.preservedConfigFields.has("showRadarLegends")) {
      return true
    } else if (field === "condFormat" && plugin.preservedConfigFields.has("showConditionalLegends")) {
      return true
    }

    return false
  }

  /**
   * Applies the theme to the plugin
   * 
   * @param {*} plugin 
   * @param {*} theme 
   * @returns 
   */
  applyThemeToPlugin = (plugin) => {
    let dashboardTheme = sessionStorage.getItem("theme-dashboard");
    let theme = dashboardTheme ? JSON.parse(dashboardTheme)?.plugin : null;

    if (theme && plugin.config) {
      delete theme.css;

      if (typeof theme.titleFontSize !== "undefined") {
        theme.changedTitleFontSize = theme.titleFontSize
      }

      let themeFields = Object.keys(theme);

      plugin.originalConfig = plugin.originalConfig || deepCopy(plugin.config);
      plugin.preservedConfigFields = new Set([...(plugin.preservedConfigFields || [])]);

      for (let field of themeFields) {
        let themeValue = theme[field];

        if (this.controlPreservedConfigHasField(plugin, field)) continue;

        if (typeof themeValue !== "undefined") {
          plugin.config[field] = themeValue;

          if (plugin.key === "age-pyramid") {
            if (field === "legend") {
              plugin.config["showRadarLegends"] = themeValue
            }

            if (field === "condFormat") {
              plugin.config["showConditionalLegends"] = themeValue
            }
          }
        }
      }
    } else if (plugin.originalConfig) {
      plugin.config = plugin.originalConfig;
    }

    return plugin;
  }

  /**
   * Adds a new plugin
   * 
   * @param {*} plugin 
   * @param {*} interactions 
   */
  addPlugin = (plugin, interactions = undefined) => {
    let tempPlugins = [...this.state.plugins];
    let tempPlugin = deepCopy(plugin);

    tempPlugin = this.applyThemeToPlugin(tempPlugin);

    /** Create uniqe id for new plugin and set. */
    tempPlugin["rerender"] = true;

    if (!tempPlugin.preservedConfigFields) {
      tempPlugin.preservedConfigFields = new Set();
    }

    if (tempPlugin.usedSessionVariables) {
      delete tempPlugin.usedSessionVariables;
    }

    let reduxReducers = store.getState();
    let updatedPlugins = reduxReducers.PluginTriggerReducer.plugins;

    updatedPlugins.set(plugin.id, plugin);

    store.dispatch(setUpdatedPlugins(updatedPlugins));

    tempPlugins.push(tempPlugin);

    if (interactions === undefined) {
      this.setState({
        ...this.state,
        plugins: tempPlugins
      });

      this.props.addStateToHistory({
        ...this.state,
        plugins: tempPlugins
      })
    } else {
      this.setState({
        ...this.state,
        plugins: tempPlugins,
        interactions: interactions
      });

      this.props.addStateToHistory({
        ...this.state,
        plugins: tempPlugins,
        interactions: interactions
      })
    }

  };

  /**
   * Keeps the plugin object updated in the changes made in subcomponents.
   */
  updatePluginFields = (plugin, object, interactions) => {
    if (plugin === "columnMap") {
      this.updateColumnMap(
        object.columnMap,
        object.sortedColumnList,
        object.pluginId
      );
    } else if (plugin === "data") {
      this.updateData(object.data, object.columnMapForPlugin, object.pluginId, object.isInteraction);
    } else if (plugin === "renderUpdate") {
      this.updateRender(object.pluginId);
    } else if (plugin === "conditionalFormatting") {
      this.updateConditionalFormatting(object.conditionalFormats, object.pluginId);
    } else {
      this.updatePlugin(plugin, interactions);
    }
  };

  plugins = [];
  /** Update plugin object in plugins array with id. */
  updatePlugin = (plugin, interactions) => {
    let tempPlugins = deepCopy(this.state.plugins);
    let updatedPlugin = deepCopy(plugin);

    updatedPlugin["rerender"] = true;

    let reduxReducers = store.getState();
    let updatedPlugins = reduxReducers.PluginTriggerReducer.plugins;

    updatedPlugins.set(plugin.id, updatedPlugin);

    store.dispatch(setUpdatedPlugins(updatedPlugins));

    tempPlugins = tempPlugins.map(p => {
      return p.id === updatedPlugin.id ? updatedPlugin : p
    });

    if (interactions === undefined) {
      this.setState({
        ...this.state,
        plugins: tempPlugins
      });

      this.props.addStateToHistory({
        ...this.state,
        plugins: tempPlugins
      })
    } else {
      this.setState({
        ...this.state,
        plugins: tempPlugins,
        interactions: interactions
      });

      this.props.addStateToHistory({
        ...this.state,
        plugins: tempPlugins,
        interactions: interactions
      })
    }
  };

  /** 
   * if need update to more than one plugins, use this.
   * IMPORTANT: Only plugin.rerender == true ? plugin will update : wont update!
    */
  updateMultiPluginIfNecessary = (plugins) => {
    let tempPlugins =
      this.plugins.length > 0 ? this.plugins : [...this.state.plugins];

    let mustUpdatePluginsMap = new Map();

    /** Finds updateable plugins and set to map. */
    for (let plugin of plugins) {
      if (plugin.rerender === true) {
        mustUpdatePluginsMap.set(plugin.id, plugin)
      }
    }

    for (let i in tempPlugins) {
      let plugin = tempPlugins[i];
      let mustUpdatePlugin = mustUpdatePluginsMap.get(plugin.id)

      if (mustUpdatePlugin) {
        tempPlugins[i] = { ...mustUpdatePlugin };
      }
    };

    this.plugins = tempPlugins;

    this.setState({
      ...this.state,
      plugins: tempPlugins
    });
  }

  /** After query, Set getData false and render true */
  updateRender = (id) => {
    let tempPlugins = [...this.state.plugins];

    for (let i in tempPlugins) {
      if (tempPlugins[i].id === id) {
        tempPlugins[i].rerender = true;
        tempPlugins[i].getData = false;
      }
    }

    this.setState({
      ...this.state,
      plugins: tempPlugins
    });
  }

  /** Update only config in plugin */
  updateData = (data, columnMapForPlugin, pluginId, isInteraction) => {
    let tempPlugins = [...this.state.plugins];

    for (let i in tempPlugins) {
      if (tempPlugins[i].id === pluginId) {
        tempPlugins[i].data = deepCopy(data);
        tempPlugins[i].columnMapForPlugin = deepCopy(columnMapForPlugin);
        tempPlugins[i].rerender = true;
        tempPlugins[i].getData = false;
        tempPlugins[i].isInteraction = isInteraction;
      }
    }

    this.setState({
      ...this.state,
      plugins: tempPlugins
    });
  };

  /**
   * Sets dashboar's labels by given labels
   * @param {Label} labels
   */
  updateLabels = labels => {
    this.setState({
      ...this.state,
      labels: labels
    });
  };

  /** Update only column map and sorted list in plugin */
  updateColumnMap = (columnMap, sortedColumnList, pluginId) => {
    let tempPlugins = [...this.state.plugins];

    for (let i in tempPlugins) {
      if (tempPlugins[i].id === pluginId) {
        tempPlugins[i].columnMap = deepCopy(columnMap);
        tempPlugins[i].sortedColumnList = deepCopy(sortedColumnList);
      }
    }

    this.setState({
      ...this.state,
      plugins: tempPlugins
    });

    this.props.addStateToHistory({
      ...this.state,
      plugins: tempPlugins
    })
  };

  /** Update only conditional format in plugin */
  updateConditionalFormatting = (conditionalFormats, pluginId) => {
    let tempPlugins = [...this.state.plugins];

    for (let i in tempPlugins) {
      if (tempPlugins[i].id === pluginId) {
        let plugin = tempPlugins[i];
        plugin.conditionalFormats = deepCopy(conditionalFormats);

        plugin.rerender = true;
      }
    }

    this.setState({
      ...this.state,
      plugins: tempPlugins
    });

    this.props.addStateToHistory({
      ...this.state,
      plugins: tempPlugins
    })
  }

  /** Update only config in plugin */
  updateConfig = (config, pluginId, propertyListToDelete = undefined) => {
    let tempPlugins = [...this.state.plugins];

    for (let i in tempPlugins) {
      if (tempPlugins[i].id === pluginId) {
        let plugin = tempPlugins[i];
        plugin.config = deepCopy(config);

        if (propertyListToDelete) {
          propertyListToDelete.map(property => (
            delete plugin.config[property]
          ))
        }

        plugin.rerender = true;
      }
    }

    this.setState({
      ...this.state,
      plugins: tempPlugins
    });

    this.props.addStateToHistory({
      ...this.state,
      plugins: tempPlugins
    })
  };

  /**
   * @param status true = add, false = delete
   * Update only default filters in plugin
   */
  updateDefaultFilterForPlugin = (defaultFilters, pluginId) => {
    let tempPlugins = [...this.state.plugins];

    for (let i in tempPlugins) {
      if (tempPlugins[i].id === pluginId) {
        tempPlugins[i].defaultFilters = deepCopy(defaultFilters);
      }
    }

    this.setState({
      ...this.state,
      plugins: tempPlugins
    });

    this.props.addStateToHistory({
      ...this.state,
      plugins: tempPlugins
    })
  };

  /*
  * Removes expression
  */
  removeExpressions = (plugin) => {
    let pluginColumns = getPluginsAllColumnsByField(plugin.columnMap)
    let reduxState = store.getState()
    let copiedDefinedExpressionSet = new Map(reduxState.ExpressionParameterReducer.definedExpressionSet)

    for (let i = 0; i < pluginColumns.length; i++) {
      let column = pluginColumns[i]

      if (copiedDefinedExpressionSet.has(column.uniqeColumnId)) {
        copiedDefinedExpressionSet.delete(column.uniqeColumnId)
      }
    }

    store.dispatch(setDefinedExpression(copiedDefinedExpressionSet))
  }

  /** Remove plugin from dashboard. */
  removePlugin = plugin => {
    let tempPlugins = [...this.state.plugins];
    let indexToBeDeleted = -1;

    for (let i in tempPlugins) {
      if (tempPlugins[i].id === plugin.id) {
        indexToBeDeleted = i;
        break;
      }
    }
    if (indexToBeDeleted === -1){
      return;
    }

    let reduxReducers = store.getState();
    let updatedPlugins = reduxReducers.PluginTriggerReducer.plugins;
    updatedPlugins.delete(plugin.id)

    setUpdatedPlugins(updatedPlugins)

    tempPlugins.splice(indexToBeDeleted, 1);

    this.setState({
      ...this.state,
      plugins: tempPlugins,
      canPluginExportAreaMustShow: true
    });

    this.props.addStateToHistory({
      ...this.state,
      plugins: tempPlugins
    })

    if (plugin.key === "measure-tile") {
      this.removeExpressions(plugin)
    }
  };

  /** Update dashboard settings */
  changeSettings = (changedSettings, rerenderAllPlugins = false, clearFilters = false, autoRefresh = false) => {
    if (!rerenderAllPlugins) {
      this.setState({
        ...this.state,
        settings: changedSettings
      });
    } else {
      this.rerenderAllPlugins(clearFilters, autoRefresh);
    }
  };

  /** Update dashboard title configuration. Updates settings synchronously if given. */
  changeTitleConfig = (newTitleConfig, newSettings) => {
    let dashboardSettings = (newSettings === undefined) ? { ...this.state.settings } : { ...this.state.settings, ...newSettings };
    let dashboardTitleSettings = { ...dashboardSettings.dashboardTitleSettings };

    dashboardSettings.dashboardTitleSettings = { ...dashboardTitleSettings, ...newTitleConfig };

    this.setState({
      ...this.state,
      settings: dashboardSettings
    });
  };

  /** Update dashboard what if */
  changeWhatIf = (parameters, scenarios) => {
    let whatIf = { ...this.state.whatIf };
    whatIf.parameters = [...parameters];
    whatIf.scenarios = [...scenarios];

    this.setState({
      ...this.state,
      whatIf: whatIf
    })
  }

  clearAllInteractionsOnPlugins = () => {
    let reduxState = store.getState();
    let pluginInteractionFilters = reduxState.PluginTriggerReducer.pluginInteractionFilters;
    let sourcePluginsWithValues = reduxState.PluginTriggerReducer.sourcePluginsWithValues;
    let pluginPrivateInteractionFilters = reduxState.PluginTriggerReducer.pluginPrivateInteractionFilters;
    let pluginPrivateInteractionFiltersTitle = reduxState.PluginTriggerReducer.pluginPrivateInteractionFiltersTitle;

    pluginInteractionFilters.clear();
    sourcePluginsWithValues.clear();
    pluginPrivateInteractionFilters.clear();
    pluginPrivateInteractionFiltersTitle.clear();
  }

  /**
   * When the All refresh button is clicked, it clears the triggered drill down information and sets the hasDrillDownOperation item in the object to false.
   */
  clearAllDrillDownsOnPlugins = () => {
    let reduxState = store.getState();
    let drillDowns = reduxState.DrillDownReducer.drillDowns;

    reduxState.DrillDownReducer.triggeredDrillDowns.clear();
    reduxState.DrillDownReducer.pluginColumnClickedAndShouldBeRemoved.clear();

    for (let [pluginId, pluginDrillDownInf] of drillDowns) {
      let pluginDrillDowns = pluginDrillDownInf;

      for (let [key, value] of pluginDrillDowns.drillDownLayerMap) {
        if (value.hasDrillDownOperation === true) {
          value.hasDrillDownOperation = false;
          pluginDrillDowns.drillDownLayerMap.set(key, value);
        }
      }

      drillDowns.set(pluginId, pluginDrillDowns);
    }

    store.dispatch(setPluginsDrillDowns(drillDowns));
  };

  /**
   * Rerender all plugin at same time
   */
  rerenderAllPlugins = (clearFilters = false, autoRefresh = false) => {
    if (clearFilters === true) {
      this.clearAllInteractionsOnPlugins();
      this.clearAllDrillDownsOnPlugins();
    }

    this.setState({
      ...this.state,
      pluginAllRender: true,
      autoRefreshStatus: autoRefresh
    }, () => {
      this.setState({
        ...this.state,
        pluginAllRender: false,
        autoRefreshStatus: false
      })
    })
  }

  /**
   *
   * @param {*} interactions : ineractions variable is an object array
   * State sets created interactions
   */
  setInteractions = interactions => {
    this.setState({
      ...this.state,
      interactions: interactions
    });
  };

  /*
  * If cache mode active, closes interaction panel
  */
  setStatusOfForceCloseInteraction = (status) => {
    this.setState({
      ...this.state,
      forceCloseInteraction: status
    })
  }

  gridSystemDragDropChangeStatus = (status, forceDisableDragableAndResizable = false, isComesFromCustomDashboardStatus = false, isOnlyChangedStatus = false, isCurrentDashboardChanged = false) => {
    let state = { ...this.state };

    if (forceDisableDragableAndResizable === true) {
      if (status === false) {
        state.settings.grid.forceDisableDragableAndResizable = true;
      } else {
        state.settings.grid.forceDisableDragableAndResizable = false;
      }

      state.settings.grid.isDraggableAndResizable = status;
    }

    if (state.settings.grid.forceDisableDragableAndResizable === false) {
      state.settings.grid.isDraggableAndResizable = status;
    }

    if (isComesFromCustomDashboardStatus) {
      state.settings.grid.forceDisableDragableAndResizable = false
      state.settings.grid.isDraggableAndResizable = true
    }

    if (isCurrentDashboardChanged) {
      state.draggableResizableStatus = false
    } else if (isOnlyChangedStatus) {
      state.draggableResizableStatus = !status
    }

    this.setState(state);
  };

  exportPdf = () => {
    let mainDiv = $('#mainDiv');
    let pdfName = this.state.dashboardInformation?.name + ".pdf";

    if (pdfName === ".pdf") {
      pdfName = "Dashboard.pdf"
    }

    exportToPDF(pdfName, mainDiv[0], mainDiv[0].querySelectorAll('button')).then(() => {
      this.excelExportProgress(EXCEL_EXPORT_PROGRESS.DONE)
      this.saveExportLog(this.state.dashboardInformation.name, "PDF");
    }).catch((e) => {
      this.excelExportProgress(EXCEL_EXPORT_PROGRESS.FAILED)
    });
  }

  findJoinChanges = (join, isDel = true) => {
    if (isDel === true) {
      this.setState({
        join: join
      })
    } else {
      this.getJoinData(join)
    }
  }

  excelExportClicked = (e, id = undefined) => {
    let excelExportClicked = true;

    if (id !== undefined) {
      excelExportClicked = id;
    }

    this.setState({
      excelExportClicked: excelExportClicked
    })
  }

  PNGExport = () => {
    let mainDiv = document.getElementById('mainDiv');

    exportToPNG(this.state.dashboardDetail, mainDiv, mainDiv.querySelectorAll('button')).then(() => {
      this.excelExportProgress(EXCEL_EXPORT_PROGRESS.DONE)
      this.saveExportLog(this.state.dashboardInformation.name, "PNG");
    }).catch((e) => {
      this.excelExportProgress(EXCEL_EXPORT_PROGRESS.FAILED)
    });
  }

  /** Save export log with dashboard name and export type */
  saveExportLog = (dashboardName, exportType) => {
    // eslint-disable-next-line no-unused-vars
    let url = API_BASE + "/export";
    // eslint-disable-next-line no-unused-vars
    let requestBody = { dashboardName: dashboardName, exportType: exportType }

    loadingScreen(false)
  }

  setAutoRefreshToDashboard = (autoRefresh) => {
    this.setState({
      ...this.state,
      autoRefresh: deepCopy(autoRefresh)
    })
  }

  /**
   * When created new label, add to LabelList with props 
   * @param {*} newLabel new label context
   */
  labelCreated = (newLabel) => {
    newLabel.translateName = newLabel.displayName

    this.setState({
      ...this.state,
      newLabel: newLabel,
    })
  }

  /**
   * When MainDashboard title changed, we update title of mainDashboard title for Custom Dashboard props.
   */
  onChangeTitleMainDashboard = () => {
    let newMainDashboard = { ...this.state.mainDashboard };

    if (newMainDashboard && newMainDashboard.settings) {
      newMainDashboard.settings.title = this.state.settings.title;

      this.setState({
        mainDashboard: newMainDashboard,
      });
    }
  };

  /**
   * Changes theme of the dashboard to the desired theme
   * 
   * @param {*} id 
   * @returns 
   */
  setThemeOfDashbaord = (id) => {
    let reduxState = store.getState();
    let updatedPlugins = deepCopy(reduxState.PluginTriggerReducer.plugins);

    let tempPlugins = [];
    let newTheme = this.props.themes?.find(t => t?._id === id);

    if (!newTheme && id !== "") {
      let defaultTheme = this.props.themes?.find(t => t?.default === true);

      if (!defaultTheme) return;

      newTheme = defaultTheme;
    }

    if (id === "") {
      sessionStorage.removeItem("theme-dashboard");
    } else {
      sessionStorage.setItem("theme-dashboard", JSON.stringify(newTheme));
    }

    this.applyThemeToDashboard(id);

    updatedPlugins.forEach(plugin => {
      let tempPlugin = deepCopy(plugin);

      tempPlugin = this.applyThemeToPlugin(plugin);

      tempPlugin.rerender = true;

      tempPlugins.push(tempPlugin);
    });

    tempPlugins.forEach(plugin => {
      updatedPlugins.set(plugin.id, plugin);
    });

    store.dispatch(setUpdatedPlugins(updatedPlugins));

    this.setState({
      theme: id,
      themeObj: newTheme,
      plugins: tempPlugins
    });

    this.props.addStateToHistory({
      ...this.state,
      theme: id,
      themeObj: newTheme,
      plugins: tempPlugins
    });
  }

  render() {
    this.plugins = [];

    let isDashboardSliderActive = typeof this.state.sliderId === "string";
    let isGridSystemValid = !isDashboardSliderActive || this.state.currentSlider?.isBusy === false;

    return (
      <div
        id="dashboard"
        page={"dashboard"}
      >
        {
          !isDashboardSliderActive ? (
            <TopMenu
              searchBarVisible={false}
              titleVisible={true}
              titleConfig={this.state.settings.dashboardTitleSettings}
              changeTitleConfig={this.changeTitleConfig}
              settings={this.state.settings}
              changeSettings={this.changeSettings}
              loadingScreenOn={this.state.loadingScreenOn}
              isCustomDashboard={this.state.isCustomDashboard}
              plugins={this.state.plugins}
              dashboardSettings={this.state.dashboardDetail.settings}
              themeObj={this.state.themeObj}
            />
          ) : null
        }
        <div
          style={{
            position: "relative",
            height: isDashboardSliderActive ? "calc(100dvh)" : "calc(100dvh - 57px)",
          }}
        >
          {
            !isDashboardSliderActive ? (
              <>
                <DashboardToolsSection
                  getModelTables={this.getModelTables}
                  join={this.state.join}
                  findJoinChanges={this.findJoinChanges}
                  excelExportClick={this.excelExportClicked}
                  exportPNGClick={this.PNGExport}
                  exportPDFClick={this.exportPdf}
                  modelId={this.state.modelId}
                  updateLabels={this.updateLabels}
                  saveDashboard={this.saveDashboard}
                  selectPlugin={this.selectPlugin}
                  setSelectedModel={this.setSelectedModel}
                  settings={this.state.settings}
                  changeSettings={this.changeSettings}
                  dashboard={this.state.dashboardDetail}
                  model={this.state.model}
                  readOnly={this.state.readOnly}
                  navigations={this.props.navigations}
                  saveData={{
                    id: this.state.dashboardDetail.id,
                    plugins: this.state.plugins,
                    model: this.state.model,
                    interactions: this.state.interactions,
                    settings: this.state.settings,
                    thumbnail: this.state.thumbnail,
                    customThumbnail: this.state.customThumbnail,
                    creationDate: this.state.creationDate,
                    labels: this.state.labels,
                    autoRefresh: this.state.autoRefresh,
                    whatIf: this.state.whatIf,
                    theme: this.state.theme
                  }}
                  setTitleSettingsToDefault={this.setTitleSettingsToDefault}
                  plugins={this.state.plugins}
                  gridSystemDragDropChangeStatus={this.gridSystemDragDropChangeStatus}
                  isDraggableAndResizable={this.state.settings.grid.isDraggableAndResizable}
                  setAutoRefreshToDashboard={this.setAutoRefreshToDashboard}
                  autoRefresh={this.state.autoRefresh}
                  changeWhatIf={this.changeWhatIf}
                  updateModelTablesForJoin={this.updateModelTablesForJoin}
                  whatIf={this.state.whatIf}
                  gridCalculator={this.gridCalculator}
                  labelCreated={this.labelCreated}
                  customDashboards={this.state.customDashboards}
                  getDashboard={this.getDashboard}
                  mainDashboard={this.state.mainDashboard}
                  mainDashboardId={this.state.mainDashboardId}
                  deleteCustomDashboard={this.deleteCustomDashboard}
                  setCustomDashboardAsMainDashboard={this.setCustomDashboardAsMainDashboard}
                  pureMainDashboardPlugin={this.state.pureMainDashboardPlugin}
                  pureMainDashboardSettings={this.state.pureMainDashboardSettings}
                  dashboardSettings={this.state.settings}
                  forceCloseInteraction={this.setStatusOfForceCloseInteraction}
                  isCustomDashboard={this.state.isCustomDashboard}
                  excelExportProgress={this.excelExportProgress}
                  theme={this.state.theme}
                  setTheme={this.setThemeOfDashbaord}
                  themeObj={this.state.themeObj}
                  getModel={this.getModel}
                  newColumnAddedFlag={this.state.newColumnAddedFlag}
                  removeNewColumn={this.removeNewColumn}
                  downloadFEExcelExport={this.downloadFEExcelExport}
                  excelExportBigSizeDownload={this.excelExportBigSizeDownload}
                />
                {" "}
                {
                  this.props.navigations && this.props.navigations.length > 1 ? (
                    <Row>
                      <NavigationHistory
                        goToSelectedNavigation={this.props.goToSelectedNavigation}
                        navigations={this.props.navigations}
                        themeObj={this.state.themeObj}
                      />
                    </Row>
                  ) : null
                }
              </>
            ) : null
          }

          <div>
            {
              isGridSystemValid &&
              <GridSystem
                currentDashboardId={this.state.id}
                gridSystemDragDropChangeStatus={
                  this.gridSystemDragDropChangeStatus
                }
                updateDefaultFilterForPlugin={this.updateDefaultFilterForPlugin}
                updateConfig={this.updateConfig}
                removePlugin={this.removePlugin}
                updatePlugin={this.updatePluginFields}
                updateMultiPluginIfNecessary={this.updateMultiPluginIfNecessary}
                plugins={this.sortDashboardPluginsForMobile(this.state.plugins)}
                model={this.state.model}
                interactions={this.state.interactions}
                settings={this.state.settings}
                addPlugin={this.addPlugin}
                selectedPlugin={this.state.selectedPlugin}
                gridCalculator={this.gridCalculator}
                setInteractions={this.setInteractions}
                setNavigations={this.props.setNavigations}
                readOnly={this.state.readOnly}
                join={this.state.join}
                addJoinToJoinList={this.addJoinToJoinList}
                triggeringPluginInformation={
                  this.state.triggeringPluginInformation
                }
                drillDownTriggerInformation={this.state.drillDownTriggerInformation}
                drillDownTrigger={this.drillDownTrigger}
                interactionTrigger={this.interactionTrigger}
                dashboardInformation={this.state.dashboardInformation}
                updateModelTablesForJoin={this.updateModelTablesForJoin}
                getModelTables={this.getModelTables}
                pluginAllRender={this.state.pluginAllRender}
                autoRefreshStatus={this.state.autoRefreshStatus}
                autoRefresh={this.state.autoRefresh}
                excelExportClicked={this.state.excelExportClicked}
                excelExporting={this.state.excelExporting}
                setPluginsWithData={this.setPluginsWithData}
                currentModelWithTable={this.state.currentModelWithTable}
                containerHeight={this.state.containerHeight}
                cancelRequests={this.state.cancelRequests}
                isDashboardSave={this.state.isDashboardSave}
                isDraggableAndResizable={this.state.settings.grid.isDraggableAndResizable}
                hoveredPluginTools={this.state.settings.grid.hoveredPluginTools}
                draggableResizableStatus={this.state.draggableResizableStatus}
                forceCloseInteraction={this.state.forceCloseInteraction}
                setStatusOfForceCloseInteraction={this.setStatusOfForceCloseInteraction}
                canPluginExportAreaMustShow={this.state.canPluginExportAreaMustShow}
                changeStatusOfHoveredPluginTools={this.changeStatusOfHoveredPluginTools}
                theme={this.state.theme}
                themeObj={this.state.themeObj}
                applyThemeToPlugin={this.applyThemeToPlugin}
                isExcelExportStyled={this.state.settings?.isExcelExportStyled}
                isExcelExportBigSized={this.state.settings?.isExcelExportBigSized}
                isDashboardSliderActive={isDashboardSliderActive}
                isDashboardSliderPlaying={this.state.currentSlider?.playing}
                isDashboardSliderBackwarded={this.state.currentSlider?.backwarded}
              />
            }
          </div>
        </div>

        {
          isDashboardSliderActive ? this.state.theme !== undefined && (
            <DashboardSliderPlayer
              slider={this.state.currentSlider}
              setPlaying={this.setSliderPlaying}
              setBackwarded={this.setSliderBackwarded}
              slideDashboard={this.slideDashboard}
              theme={this.state.theme}
              themeObj={this.state.themeObj}
            />
          ) : (
            <>
              {
                this.state.theme !== undefined && (
                  <SwitchDashboard
                    isDashboardSave={this.state.isDashboardSave}
                    getDashboard={this.getDashboard}
                    currentDashboardId={this.state.id}
                    updateNavigationsAfterSwitchDashboard={this.props.updateNavigationsAfterSwitchDashboard}
                    newLabel={this.state.newLabel}
                    isMainDashboardChangedFlag={this.state.isMainDashboardChangedFlag}
                    dashboard={this.state.dashboardDetail}
                    theme={this.state.theme}
                    themeObj={this.state.themeObj}
                  />
                )
              }
              <FormulaEditor model={this.state.model} />
              <FileUploadStatusMinimized />

              {this.state.excelExportProgress !== undefined ?
                <Tooltip
                  tooltip={i18n.t("Dashboard.ExcelExport.ExcelExporting")}
                  tooltipPlacement={"right"}
                >
                  <div id="excel-export-status" className="file-upload-status-minimized">
                    <div style={{
                      width: "60px",
                      height: "60px",
                      border: "3px solid #1892e1",
                      borderRadius: "50%"
                    }}>
                      {this.state.excelExportProgress === EXCEL_EXPORT_PROGRESS.DONE
                        ? <CheckOutlined
                          style={{
                            position: "absolute",
                            left: "calc(50% - 12.5px)",
                            top: "calc(50% - 12.5px)",
                            fontSize: "25px",
                            color: "#84c458"
                          }}
                        />
                        : this.state.excelExportProgress === EXCEL_EXPORT_PROGRESS.FAILED
                          ? <ExclamationOutlined
                            style={{
                              position: "absolute",
                              left: "calc(50% - 12.5px)",
                              top: "calc(50% - 12.5px)",
                              fontSize: "25px",
                              color: "red"
                            }}
                          />
                          : <CircleLoader
                            style={{
                              position: "absolute",
                              left: "calc(50% - 12.5px)",
                              top: "calc(50% - 12.5px)"
                            }}
                          />
                      }

                    </div>
                  </div>
                </Tooltip>
                : null}
            </>
          )
        }

        <style id="dashboardCSS" theme={this.state.themeObj?.name}>
          {this.state.themeObj?.dashboard?.css}
        </style>

        <style id="topBarCSS" theme={this.state.themeObj?.name}>
          {this.state.themeObj?.topBar?.css}
        </style>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selfService: state.SelfServiceReducer,
  };
};

export default connect(mapStateToProps)(DashboardCrud);
