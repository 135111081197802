import React, { Component } from "react";
import { Menu, Dropdown, Icon, Input, Popconfirm } from "antd";

export default class treeItemPanel extends Component {
  menu = item => {
    return (
      <Menu onClick={e => this.props.onSelect(e, item)}>
        <Menu.Item key="duplicated">
          <Icon type="block" className="treeItemIcons" />
          Duplicate
        </Menu.Item>
        {item && item.hasOwnProperty("whereClause") && (
          <Menu.Item key="whereClause">
            <i className={"treeItemPanelIcons fontIcon"}> ω </i>Where clause
          </Menu.Item>
        )}
        {item && item.hasOwnProperty("aggrRule") && (
          <Menu.Item key="aggrRule">
            <i className={"treeItemPanelIcons fontIcon"}> ∫ </i>Aggrigation Rule
          </Menu.Item>
        )}
        <Menu.Item key="info">
          <Icon type="info" className="treeItemIcons" />
          Info
        </Menu.Item>
      </Menu>
    );
  };

  render() {
    const { item, content, trigger } = this.props;
    return (
      <div>
        <Dropdown
          overlay={this.menu(item)}
          trigger={trigger}
          placement="bottomLeft"
        >
          {content}
        </Dropdown>
      </div>
    );
  }
}
