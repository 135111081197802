import React, { Component } from "react";
import Tooltip from "../GeneralComponents/Tooltip/Tooltip";
import BlurComponent from "../GeneralComponents/BlurComponent/BlurComponent";
import i18n from "../../Utils/i18next";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import { Popover, Checkbox } from "antd";
import Text from "../GeneralComponents/Text/Text";
import { showError, showNotificationWithIcon } from "../../Utils/Notification";
import InteractionEditPanel from "./InteractionEditPanel";
import Cookies from "js-cookie";
import $ from "jquery";
import { filterPluginsActionsAndReactions } from "../Plugins/defaultSettings";
import { store } from "../../index";
import { setAutoInteractions } from "./FilterAutoInteractionAction";
import _ from "lodash";
import { deepCopy } from "../../Utils/Global";
import { autoInteractionPlugins, exceptAutoInteractionPlugins } from "../../config";
import { defaultInteractionObjects, defaultReactionObjects, defaultTitleReactions } from "./DefaultInteractionObjects";
import { getPluginsAllColumnsByField } from "../../Utils/PluginOperations";

/**
 * This component includes creating interaction, editing, deleting. It also calls the blur component for interaction mode.
 */
export default class Interaction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedList: {},
      visible: false,
      columns: [],
      interaction: {},
      appliedAll: false,
    };
  }

  componentDidMount() {
    if (autoInteractionPlugins.has(this.props.plugin.key)) {
      this.setStateAppliedAllForAutoInteractions(this.props.plugin, plugin =>
        this.setAutoInteractionsForObject(plugin, this.props)
      );
    } else {
      this.setAppliedAll(this.props.plugin, this.props.interactions);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      autoInteractionPlugins.has(nextProps.plugin.key) &&
      (!_.isEqual(nextProps.plugin.columnMap, this.props.plugin.columnMap) ||
        (nextProps.pluginList &&
          this.props.pluginList &&
          nextProps.pluginList.length !== this.props.pluginList.length))
    ) {
      this.setStateAppliedAllForAutoInteractions(nextProps.plugin, plugin =>
        this.setAutoInteractionsForObject(plugin, nextProps)
      );
    } else if (
      nextProps.activeInteractionPluginObject &&
      nextProps.activeInteractionPluginObject.plugin &&
      nextProps.plugin.id === nextProps.activeInteractionPluginObject.plugin.id
    ) {
      if (autoInteractionPlugins.has(nextProps.plugin.key)) {
        this.setStateAppliedAllForAutoInteractions(nextProps.plugin);
      } else {
        this.setAppliedAll(nextProps.plugin, nextProps.interactions);
      }
    }
  }

  /**
   * removed interaction find by id in
   */
  hasRemovedInteraction = (removedInteractions, id) => {
    let status = false;

    if (removedInteractions) {
      for (let i = 0; i < removedInteractions.length; i++) {
        let removedId = removedInteractions[i];

        if (removedId === id) {
          status = true;

          break;
        }
      }
    }

    return status;
  };

  /*
  * Controls is measure and all columns has expression
  */
  controlIsMeasureAndAllColumnsHasExpression = (plugin) => {
    let copiedPlugin = deepCopy(plugin)

    if (copiedPlugin.key !== "measure-tile") {
      return true
    } else {
      let columns = getPluginsAllColumnsByField(copiedPlugin.columnMap)
      let filteredColumns = columns.filter(column => column.expression)

      if (columns.length === filteredColumns.length && columns.length !== 0) {
        return false
      }
    }

    return true
  }

  /**
   * Set auto interactions list
   */
  setAutoInteractionsForObject = (plugin, props) => {
    let activeSourcePlugin = deepCopy(plugin);
    let interactions = [];

    let reduxState = store.getState();
    let plugins = deepCopy(
      Array.from(reduxState.PluginTriggerReducer.plugins.values())
    );

    let removedPluginIdsSet = new Set();

    plugins.map(target => {
      if (!autoInteractionPlugins.has(target.key) && !exceptAutoInteractionPlugins.has(target.key)) {
        let isPluginTargetAndReactionUndefined = target.reaction == undefined && target.action == undefined && target.titleReaction == undefined

        if (isPluginTargetAndReactionUndefined) {
          target.reaction = [];
          target.action = [];
          target.titleReaction = [];

          if (target.reactions != undefined) {
            target.reaction.push(target.reactions[0]);
          }

          if (target.actions != undefined) {
            target.action.push(target.actions[0]);
          }
          
          if (target.titleReactions != undefined) {
            target.titleReaction.push(target.titleReactions[0]);
          } else {
            let titleReactions = this.props.plugin.titleReactions;
            
            if (titleReactions) {
              target.titleReactions = titleReactions;
              target.titleReaction = [titleReactions[0]]
            }
          }
        }

        target.columns = this.setSourceColumns(target);

        if (!this.interactionHasCheck(target)) {
          if (
            activeSourcePlugin.edittedInteractions &&
            activeSourcePlugin.edittedInteractions[target.id]
          ) {
            let interaction = activeSourcePlugin.edittedInteractions[target.id];

            interactions.push(interaction);

            removedPluginIdsSet.add(target.id);
          } else {
            let interaction = {};

            interaction.sourceId = activeSourcePlugin.id;
            interaction.targetId = target.id;
            interaction.filterPredicate = "="; // This is default predicate for interaction object to filter other plugins. In the future this may be changed in this component.
            interaction.columns = [];

            for (let item in activeSourcePlugin.columnMap) {
              activeSourcePlugin.columnMap[item].data &&
                activeSourcePlugin.columnMap[item].data.map(column =>
                  column.aggregatable != true
                    ? interaction.columns.push(column)
                    : null
                );
            }

            interaction.reactions = target.reaction;
            interaction.titleReactions = target.titleReaction;
            interaction.actions = [
              {
                ...filterPluginsActionsAndReactions.get(activeSourcePlugin.key)
                  .actions[0]
              }
            ];

            interactions.push(interaction);

            removedPluginIdsSet.add(target.id);
          }
        }
      }

      return target;
    });

    let autoInteractions = reduxState.FilterAutoInteractionReducer
      ? new Map(reduxState.FilterAutoInteractionReducer.filterAutoInteractions)
      : new Map();

    autoInteractions.set(activeSourcePlugin.id, interactions);

    this.findAndRemoveIfHasInteractions(plugin.id, removedPluginIdsSet);
    store.dispatch(setAutoInteractions(autoInteractions));
  };

  /**
   *
   * @param {*} plugin
   * Retun columns array for selected source plugin
   */
  setSourceColumns = plugin => {
    let columns = [];

    for (let category in plugin.columnMap) {
      plugin.columnMap[category].data &&
        plugin.columnMap[category].data.map(column => {
          if (column.aggregatable === false) {
            columns.push(column);
          }
        });
    }

    return columns;
  };

  /**
   * Validation for interactions
   */
  interactionIsValid = (plugin, activeInteractionPluginObject) => {
    if (activeInteractionPluginObject.plugin.id === plugin.id) {
      return false;
    }

    if (autoInteractionPlugins.has(activeInteractionPluginObject.plugin.key) && !(autoInteractionPlugins.has(plugin.key) || exceptAutoInteractionPlugins.has(plugin.key))) {
      return false;
    }

    return true;
  };

  /**
   *
   * @param {*} plugin
   * Creates the interaction object of source plugin with selected plugin and sends createInteraction method
   */
  setInteractionObject = (plugin, isRemovedInteraction) => {
    if (isRemovedInteraction) {
      this.addPluginToAutoInteraction(
        plugin,
        this.props.activeInteractionPluginObject
      );
    } else if (
      this.interactionIsValid(plugin, this.props.activeInteractionPluginObject)
    ) {
      let isNotValidAllReactionAndAction = plugin.reaction == undefined && plugin.action == undefined && plugin.titleReaction == undefined;
      let pluginReactions = plugin.reactions ? plugin.reactions[0] : defaultReactionObjects[plugin.key]
      let pluginActions = plugin.actions ? plugin.actions[0] : defaultInteractionObjects[plugin.key]

      if (isNotValidAllReactionAndAction) {
        plugin.reaction = [];
        plugin.action = [];
        plugin.reaction.push(pluginReactions);
        plugin.action.push(pluginActions);
        
        if (!exceptAutoInteractionPlugins.has(plugin.key)) {
          plugin.titleReaction = [];
          plugin.titleReaction.push(plugin.titleReactions?.[0]);
        }
      }

      plugin.columns = this.setSourceColumns(plugin);

      this.createInteraction(plugin);
    }
  };

  /**
   *
   * @param {*} target
   * @param {*} changesValue
   * Updates existing interaction information. For example, column insertion or trigger type can be action updated.
   */
  editInteraction = (target, changesValue) => {
    let interaction = deepCopy(this.state.interaction);

    if (interaction) {
      interaction.columns = changesValue.columns;
      interaction.reactions = changesValue.reactions;
      interaction.actions = changesValue.actions;
      interaction.titleReactions = changesValue.titleReactions;

      if (this.state.isAutoInteractionForActivePlugin) {
        let activePlugin = deepCopy(
          this.props.activeInteractionPluginObject.plugin
        );

        if (!activePlugin.edittedInteractions) {
          activePlugin.edittedInteractions = {};
        }

        activePlugin.edittedInteractions[target.id] = interaction;

        let reduxState = store.getState();
        let autoInteractions = reduxState.FilterAutoInteractionReducer
          ? new Map(
              reduxState.FilterAutoInteractionReducer.filterAutoInteractions
            )
          : new Map();

        let interactions = deepCopy(
          autoInteractions.get(
            activePlugin.id
          )
        );

        interactions = interactions.map(item => {
          if (item.targetId === interaction.targetId) {
            item = interaction;
          }
          return item;
        });

        autoInteractions.set(activePlugin.id, interactions);
        
        store.dispatch(setAutoInteractions(autoInteractions));

        this.props.setRemovedAutoInteractionsInPlugin(activePlugin);
      } else {
        let interactions = deepCopy(this.props.interactions);
        let interactionIndex = this.props.interactionsHashMapForSource.get(
          target.id
        );

        interactions[interactionIndex.indexInInteractionList] = interaction;

        this.props.setInteractions(interactions);
      }
    }
  };

  /**
   *
   * @param {*} target
   * Removes existing ineraction from ineractions array and updates array
   */
  deleteInteraction = target => {
    if (this.interactionHasCheck(target)) {
      let interactions = deepCopy(this.props.interactions);

      let indexInInteractionList = this.props.interactionsHashMapForSource.get(
        target.id
      ).indexInInteractionList;
      interactions.splice(indexInInteractionList, 1);

      this.setInteractions(
        this.props.activeInteractionPluginObject.plugin,
        interactions
      );
    }
  };

  /**
   * Validation for interaction
   */
  interactionValidation = interaction => {
    let validationResponse = {
      validationErrorMessage: "",
      isValid: true
    };

    if (interaction.reactions.length === 0) {
      validationResponse.isValid = false;
      validationResponse.validationErrorMessage = i18n.t(
        "Interaction.Reactions"
      );
    }

    if (interaction.actions.length === 0) {
      validationResponse.isValid = false;
      validationResponse.validationErrorMessage = i18n.t(
        "Interaction.InteractionTypes"
      );
    }

    if (!exceptAutoInteractionPlugins.has(this.props.plugin.key) && interaction.titleReactions?.length === 0) {
      validationResponse.isValid = false;
      validationResponse.validationErrorMessage = i18n.t("Interaction.TitleReactions");
    }

    if (interaction.columns.length === 0) {
      validationResponse.isValid = false;
      validationResponse.validationErrorMessage = i18n.t("Columns");
    }

    return validationResponse;
  };

  /**
   *
   * @param {*} editPlugin
   * It updates the interaction object of the source plugin with the selected plugin and sends the parent component.
   */
  updateInteractionSettings = editPlugin => {
    let interaction = {
      reactions: this.state.interaction.reactions,
      titleReactions:
        this.state.interaction.titleReactions?.length > 0
          ? this.state.interaction.titleReactions
          : this.props.plugin.titleReactions
            ? [this.props.plugin.titleReactions[0]]
            : undefined,
      actions: this.state.interaction.actions,
      columns: this.state.interaction.columns,
    };

    let validationResponse = this.interactionValidation(interaction);

    if (validationResponse.isValid === true) {
      this.setState(
        {
          visible: false
        },
        () => this.editInteraction(editPlugin, interaction)
      );
    } else {
      let validationErrorMessage = validationResponse.validationErrorMessage;

      showError(
        i18n.t("EmptyErrorMessage", {
          validationErrorMessage: validationErrorMessage,
          interpolation: { escapeValue: false }
        })
      );
    }
  };

  /**
   *
   * @param {*} value
   * @param {*} resourceItem
   * @param {*} resultItem
   *
   * Takes the objects selected from the dropdown and updates the plugin inside
   */
  handleChangeActionsDropdown = value => {
    let selectedActions = [];

    value.map(item =>
      this.props.activeInteractionPluginObject.plugin.actions.map(action => {
        if (action.name == item) {
          selectedActions.push(action);
        }
      })
    );

    let interaction = { ...this.state.interaction };
    interaction.actions = selectedActions;

    this.setState({
      ...this.state,
      interaction: interaction
    });
  };

  /**
   * Handles reaction changes
   */
  handleChangeReactionsDropdown = value => {
    let selectedReactions = [];

    value.map(item =>
      this.props.plugin.reactions.map(reaction => {
        if (reaction.name == item) {
          selectedReactions.push(reaction);
        }
      })
    );

    let interaction = { ...this.state.interaction };
    interaction.reactions = selectedReactions;

    this.setState({
      ...this.state,
      interaction: interaction
    });
  };

   /**
   * Handles Title reaction changes
   */
   handleChangeTitleReactionsDropdown = value => {
    let selectedTitleReactions = [];
    let titleReactions = this.props.plugin.titleReactions 
      ? this.props.plugin.titleReactions 
      : defaultTitleReactions[this.props.plugin.key] 
        ? defaultTitleReactions[this.props.plugin.key] 
        : []

    value.map(item =>
      titleReactions.map(titleReaction => {
        if (titleReaction.name == item) {
          selectedTitleReactions.push(titleReaction);
        }
      })
    );

    let interaction = { ...this.state.interaction };
    
    interaction.titleReactions = selectedTitleReactions;

    this.setState({
      ...this.state,
      interaction: interaction
    });
  };

  /**
   *
   * @param {*} plugin
   * Create interaction information for the updated interaction
   */
  setSelectedColumnAndActionReactions = plugin => {
    let selectedColumnsNames = [];
    let selectedColumnIds = [];
    let selectedTitleReactionsNames = [];
    let selectedReactionsNames = [];
    let selectedActionsNames = [];
    let allColumns = [];

    let reduxState = store.getState();
    let pluginsMap = reduxState.PluginTriggerReducer.plugins;
    let autoInteractions = reduxState.FilterAutoInteractionReducer
      ? new Map(reduxState.FilterAutoInteractionReducer.filterAutoInteractions)
      : new Map();

    let interactionIndex = this.props.interactionsHashMapForSource.get(
      this.props.plugin.id
    );

    let interaction = undefined;
    let isAutoInteraction = false;

    if (this.props.interactions && interactionIndex) {
      interaction = deepCopy(
        this.props.interactions[interactionIndex.indexInInteractionList]
      );

      isAutoInteraction = false;
    } else if (
      autoInteractions.has(this.props.activeInteractionPluginObject.plugin.id)
    ) {
      let interactions = autoInteractions.get(
        this.props.activeInteractionPluginObject.plugin.id
      );

      interactions.map(item => {
        if (item.targetId === this.props.plugin.id) {
          interaction = deepCopy(item);

          isAutoInteraction = true;
        }
      });

      if (interaction) {
        if (
          this.props.activeInteractionPluginObject.plugin.edittedInteractions &&
          this.props.activeInteractionPluginObject.plugin.edittedInteractions[
            this.props.plugin.id
          ]
        ) {
          interaction = deepCopy(
            this.props.activeInteractionPluginObject.plugin.edittedInteractions[
              this.props.plugin.id
            ]
          );
        }
      }
    }

    if (interaction !== undefined) {
      interaction.columns.map(column => {
        selectedColumnsNames.push(column.uniqeColumnId);
        selectedColumnIds.push(column.uniqeColumnId)
      });

      if (interaction.reactions != undefined && interaction.reactions.length > 0) {
        interaction.reactions.map(reaction => {

          if (this.props.plugin.key == "title") {
            selectedReactionsNames.push(i18n.t("Interaction." + reaction.name));
          } else {
            selectedReactionsNames.push(reaction.name)
          }
        });
      } else {
        let targetPlugin = pluginsMap.get(interaction.targetId);
        let hasPluginReaction = targetPlugin != undefined && targetPlugin.reactions != undefined && targetPlugin.reactions.length > 0;

        if (hasPluginReaction) {
          selectedReactionsNames = targetPlugin.reactions[0].name;
        } 
      }

      if (interaction.titleReactions?.length > 0 && interaction.titleReactions[0]) {
        interaction.titleReactions.map(titleReaction => {
          selectedTitleReactionsNames.push(titleReaction.name);
        });
      } else {
        let targetPlugin = pluginsMap.get(interaction.targetId);
        let hasPluginTitleReaction = targetPlugin?.titleReactions?.length > 0;

        if (hasPluginTitleReaction) {
          selectedTitleReactionsNames = [targetPlugin.titleReactions[0].name];
          interaction.titleReactions = [targetPlugin.titleReactions[0]];
        } 
      }
  
      interaction.actions.map(action => {
        selectedActionsNames.push(action.name);
      });

      let activeSourcePlugin = this.props.activeInteractionPluginObject.plugin;
      let columnsHashMap = new Map();

      for (let item in activeSourcePlugin.columnMap) {
        if (activeSourcePlugin.columnMap[item].data && item !== "hidden") {
          activeSourcePlugin.columnMap[item].data.map(column =>
            column.aggregatable != true
              ? (allColumns.push(column),
                columnsHashMap.set(column.uniqeColumnId, column))
              : null
          );
        }
      }

      interaction.selectedColumnIds = selectedColumnIds;
      interaction.selectedColumnsNames = selectedColumnsNames;
      interaction.selectedActionsNames = selectedActionsNames;
      interaction.selectedReactionsNames = selectedReactionsNames.length > 0 ? [selectedReactionsNames[0]] : selectedReactionsNames;
      interaction.selectedTitleReactionsNames = selectedTitleReactionsNames;
      interaction.allColumns = allColumns;
      interaction.columnsHashMap = columnsHashMap;

      return { interaction: interaction, isAutoInteraction: isAutoInteraction };
    }

    return undefined;
  };

  /**
   *
   * @param {*} target = information of the plugin selected as source
   * Checks whether the plugin that comes as a parameter in the hashmap has any interaction and returns true or false
   */
  interactionHasCheck = target => {
    return this.props.interactionsHashMapForSource.has(target.id);
  };

  /**
   *
   * @param {*} target
   * Creates an interaction object for the active source plugin and adds it to the interaction array
   */
  createInteraction = target => {
    let activeSourcePlugin = this.props.activeInteractionPluginObject.plugin;

    if (!this.interactionHasCheck(target)) {
      let interaction = {};

      interaction.sourceId = activeSourcePlugin.id;
      interaction.targetId = target.id;
      interaction.filterPredicate = "="; // This is default predicate for interaction object to filter other plugins. In the future this may be changed in this component.
      interaction.columns = [];

      for (let item in activeSourcePlugin.columnMap) {
        activeSourcePlugin.columnMap[item].data &&
          activeSourcePlugin.columnMap[item].data.map(column =>
            column.aggregatable != true
              ? interaction.columns.push(column)
              : null
          );
      }

      let actions = this.props.activeInteractionPluginObject.plugin.actions 
        ? this.props.activeInteractionPluginObject.plugin.actions[0]
        : defaultInteractionObjects[this.props.activeInteractionPluginObject.plugin.key]
          ? defaultInteractionObjects[this.props.activeInteractionPluginObject.plugin.key]
          : {}

      interaction.titleReactions = target.titleReaction;
      interaction.reactions = target.reactions.length > 0 ? [target.reactions[0]] : target.reactions;
      interaction.actions = [actions];

      let interactions = deepCopy(this.props.interactions);
      interactions.push(interaction);

      this.setInteractions(activeSourcePlugin, interactions);
    }
  };

  /**
   *
   * @param {*} checkedValues
   * Takes selected columns and sets state
   */
  onChangeSelectedColumns = checkedValues => {
    let checkedColumns = [...checkedValues];
    let columns = [];
    let interaction = { ...this.state.interaction };

    checkedColumns.map(columnAliasName => {
      let column = this.state.interaction.columnsHashMap.get(columnAliasName);

      columns.push(column);
    });

    interaction.selectedColumnsNames = checkedValues;
    interaction.selectedColumnIds = checkedValues;
    interaction.columns = columns;

    this.setState({
      ...this.state,
      columns: columns,
      interaction: interaction
    });
  };

  /**
   * Takes forced column ids and sets state
   * 
   * @param {*} filters 
   */
  onChangeIgnoredDefaultFilters = (filterId, ignoredDefaultFilters) => {
    let interaction = { ...this.state.interaction };
    let affectedDefaultFilters = interaction.affectedDefaultFilters || [];

    affectedDefaultFilters.push(filterId);

    if (ignoredDefaultFilters.includes(filterId)) {
      ignoredDefaultFilters = ignoredDefaultFilters.filter(id => id !== filterId)
    } else {
      ignoredDefaultFilters.push(filterId);
    }

    this.setState({
      ...this.state,
      interaction: {
        ...interaction,
        ignoredDefaultFilters: ignoredDefaultFilters,
        affectedDefaultFilters: affectedDefaultFilters
      }
    });
  };

  /**
   * Checks whether there are active objects for interaction. Checks whether it belongs to this plug-in if there is an active object, returns true or false.
   */
  isActiveSourcePlugin = () => {
    return (
      this.props.activeInteractionPluginObject &&
      this.props.activeInteractionPluginObject.plugin &&
      this.props.activeInteractionPluginObject.plugin.id == this.props.plugin.id
    );
  };

  /**
   *
   * @returns
   * It checks whether the plugin contains interaction,
   * if it does, it waits for at least one of its columns to be selected.
   * If none of the columns are selected, it removes the interaction.
   */
  pluginHasInteraction = () => {
    if (Object.keys(this.props.activeInteractionPluginObject).length > 0) {
      if (
        this.props.interactionsHashMapForSource.get(this.props.plugin.id) ===
        undefined
      ) {
        return true;
      } else {
        let interactionIndex = this.props.interactionsHashMapForSource.get(
          this.props.plugin.id
        ).indexInInteractionList;

        let interaction = this.props.interactions[interactionIndex];
        let sourcePluginColumnMap = this.props.activeInteractionPluginObject.plugin.columnMap;
        let interactionHasSelectedColumn = true;
        let pluginsColumnMapWithCols = [];
        let columns = Object.values(sourcePluginColumnMap);

        try {
          interaction.columns.map(column => {
            sourcePluginColumnMap[column.locationFieldName].data.map(
              sourceColumn => {
                if (sourceColumn.uniqeColumnId === column.uniqeColumnId) {
                  interactionHasSelectedColumn = false;
                } else if (sourceColumn.locationFieldName === "hidden") {
                  interactionHasSelectedColumn = false;
                }
              }
            );
          });
        } catch (err) {
          if (err) {
            columns.map(col => {
              col.data.map(data => {
                pluginsColumnMapWithCols.push(data);
              });
            });

            interaction.columns.map(column => {
              pluginsColumnMapWithCols.map(data => {
                if (data.uniqeColumnId === column.uniqeColumnId) {
                  interactionHasSelectedColumn = false;
                }
              });
            });
          }
        }

        if (interactionHasSelectedColumn === true) {
          this.deleteInteraction(this.props.plugin);
        }

        return interactionHasSelectedColumn;
      }
    }
  };

  /**
   *
   * @param {*} visible
   *
   * Visible change for edit panel
   */
  handleVisibilityChange = visible => {
    let interactionWithType = this.setSelectedColumnAndActionReactions();

    if (interactionWithType) {
      this.setState({
        ...this.state,
        visible: visible,
        interaction: interactionWithType.interaction,
        isAutoInteractionForActivePlugin: interactionWithType.isAutoInteraction
      });
    }
  };

  /*
   * Interaction source text joint style
   */
  interactionSourceJointStyle = {
    marginBottom: "3px",
    padding: "0px 0px",
    maxWidth: "100%",
    fontSize: "12px"
  };

  /*
   * Returns interaction main div's style with language
   */
  interactionSourceDivStyle = (
    isPluginHeightEqualOne,
    isPluginWidthEqualOne,
    isSelectedLanguageTR
  ) => {
    if (isPluginHeightEqualOne) {
      if (isSelectedLanguageTR) {
        if (isPluginWidthEqualOne) {
          return { padding: "0px", fontSize: "12px" };
        } else {
          return { ...this.interactionSourceJointStyle, fontSize: "10px" };
        }
      } else {
        if (isPluginWidthEqualOne) {
          return { padding: "0px", fontSize: "12px" };
        } else {
          return { ...this.interactionSourceJointStyle };
        }
      }
    } else if (isSelectedLanguageTR) {
      if (isPluginWidthEqualOne) {
        return { padding: "0px", fontSize: "12px" };
      } else {
        return { ...this.interactionSourceJointStyle, fontSize: "10px" };
      }
    } else {
      if (isPluginWidthEqualOne) {
        return { padding: "0px", fontSize: "12px" };
      } else {
        return { ...this.interactionSourceJointStyle };
      }
    }
  };

  /*
   * Returns interaction's close button style
   */
  closeButtonStyle = (isPluginHeightEqualOne, isPluginWidthEqualOne) => {
    if (isPluginHeightEqualOne) {
      if (isPluginWidthEqualOne) {
        return { fontSize: "20px !important" };
      } else {
        return { fontSize: "20px !important" };
      }
    } else {
      return { fontSize: "20px !important" };
    }
  };

  /*
   * Interaction selected source plugin's style
   */
  interactionSourceSelectedStyle = (
    isPluginHeightEqualOne,
    isPluginWidthEqualOne,
    isSelectedLanguageTR
  ) => {
    if (isPluginHeightEqualOne) {
      if (isSelectedLanguageTR) {
        if (isPluginWidthEqualOne) {
          return { padding: "0px", fontSize: "12px" };
        } else {
          return { ...this.interactionSourceJointStyle, fontSize: "10px" };
        }
      } else {
        if (isPluginWidthEqualOne) {
          return { padding: "0px", fontSize: "12px" };
        } else {
          return { ...this.interactionSourceJointStyle };
        }
      }
    } else if (isSelectedLanguageTR) {
      if (isPluginWidthEqualOne) {
        return { padding: "0px", fontSize: "12px" };
      } else {
        return { ...this.interactionSourceJointStyle, fontSize: "10px" };
      }
    } else {
      if (isPluginWidthEqualOne) {
        return { padding: "0px", fontSize: "12px" };
      } else {
        return { ...this.interactionSourceJointStyle };
      }
    }
  };

  /*
   * Returns interaction's edit button style
   */
  interactionEditButtonStyle = (
    isPluginHeightEqualOne,
    isPluginWidthEqualOne
  ) => {
    if (isPluginHeightEqualOne) {
      if (isPluginWidthEqualOne) {
        return { marginBottom: "0px", fontSize: "0.4em" };
      } else {
        return { marginBottom: "15px", fontSize: "0.4em" };
      }
    } else if (isPluginWidthEqualOne) {
      return { fontSize: "0.7em", marginRight: "10px" };
    } else {
      return { fontSize: "40px", marginRight: "15px" };
    }
  };

  /*
   * Returns interaction target button style
   */
  interactionTargetButtonStyle = (
    isPluginHeightEqualOne,
    isPluginWidthEqualOne
  ) => {
    if (isPluginHeightEqualOne) {
      if (isPluginWidthEqualOne) {
        return { fontSize: "0.6em", position: "inherit" };
      } else {
        return { top: "0px", fontSize: "0.7em" };
      }
    } else if (isPluginWidthEqualOne) {
      return { fontSize: "0.7em", padding: "0px" };
    } else {
      return { top: "0px" };
    }
  };

  /*
   * Returns selected interactions style
   */
  interactionTargetSelectionStyle = (
    isPluginHeightEqualOne,
    isPluginWidthEqualOne
  ) => {
    if (isPluginHeightEqualOne) {
      if (isPluginWidthEqualOne) {
        return { fontSize: "7px", marginBottom: "15px", padding: "0px" };
      } else {
        return { fontSize: "12px", marginBottom: "15px", padding: "0px" };
      }
    } else if (isPluginWidthEqualOne) {
      return { fontSize: "7px", marginBottom: "15px", padding: "0px" };
    } else {
      return { fontSize: "15px", marginBottom: "15px", padding: "0px" };
    }
  };

  pluginWidth = 0;

  /*
   * If active plugin has any interactions return tooltip message or new icon for props value
   */
  activePluginHasAnyInteractions = (props, closeButtonId) => {
    let reduxState = store.getState();
    let checked = false;
    let autoInteractions = reduxState.FilterAutoInteractionReducer
      ? new Map(reduxState.FilterAutoInteractionReducer.filterAutoInteractions)
      : new Map();

    if (autoInteractions.has(this.props.plugin.id)) {
      let interactPlugins = autoInteractions.get(this.props.plugin.id);
      let removedInteractPlugins = new Set(
        this.props.plugin.removedInteractions
      );

      let removedCount = 0;

      interactPlugins.map(interaction => {
        if (removedInteractPlugins.has(interaction.targetId)) {
          removedCount++;
        }
      });

      if (removedCount < interactPlugins.length) {
        checked = true;
      }
    }

    let iconStyle = { position: "absolute", top: "4px", right: "2px" };

    if (this.isActiveSourcePlugin()) {
      if (this.props.interactionsHashMapForSource.size > 0 || checked) {
        if (props === "tooltip") {
          return i18n.t("Interaction.InteractionModeCloseWithInteraction");
        } else if (props === "icon") {
          return (
            <>
              {!this.controlIsMeasureAndAllColumnsHasExpression(this.props.plugin) ?             
                  <Tooltip tooltip={i18n.t("Dashboard.Data.CantPerformAnyActionError")}>
                    <ExclamationCircleOutlined className={"interaction-error-icon"} />
                  </Tooltip> :             
                  <Tooltip tooltip={i18n.t("Interaction.InteractionTargetSelected")}>
                      <CheckOutlined               
                        name={"closePluginInteraction"}
                        onClick={this.props.closeActiveInteractionPlugin}
                        className={"circle-icon interaction-close-icon"}
                        id={closeButtonId}
                        style={iconStyle} />
                  </Tooltip>}
            </>
          );
        }
      } else {
        if (props === "tooltip") {
          return i18n.t("Interaction.InteractionModeClose");
        } else if (props === "icon") {
          return (
            <CloseOutlined
              name={"closePluginInteraction"}
              onClick={this.props.closeActiveInteractionPlugin}
              className={"circle-icon interaction-close-icon"}
              id={closeButtonId}
              style={iconStyle}
            />
          );
        }
      }
    }
  };

  /**
   * Checks status of applied all and sets with callback
   */
  setStateAppliedAllForAutoInteractions = (plugin, callback = undefined) => {
    let appliedAllCheckStatus = false;
    let updatedPlugin = deepCopy(plugin);

    if (
      updatedPlugin.removedInteractions == undefined ||
      updatedPlugin.removedInteractions.length === 0
    ) {
      appliedAllCheckStatus = true;
    }

    this.setState(
      {
        ...this.state,
        appliedAll: appliedAllCheckStatus
      },
      () => {
        if (callback) {
          callback(updatedPlugin);
        }
      }
    );
  };

  /**
   * Checks status of applied all and sets removed auto interactions with
   */
  setRemovedAutoInteractionsInPlugin = plugin => {
    this.setStateAppliedAllForAutoInteractions(
      plugin,
      this.props.setRemovedAutoInteractionsInPlugin
    );
  };

  /**
   * If interaction list has this plugins remove
   */
  findAndRemoveIfHasInteractions = (activePluginId, pluginIdSet) => {
    let interactions = deepCopy(this.props.interactions);

    interactions = interactions.filter(
      interaction =>
        !(
          interaction.sourceId == activePluginId &&
          pluginIdSet.has(interaction.targetId)
        )
    );

    if (interactions.length != this.props.interactions.length) {
      this.props.setInteractions(interactions);
    }
  };

  /**
   * Reset interaction object for remove interaction
   */
  resetInteractionObjectForTargetPlugin = (
    activeSourcePlugin,
    status,
    target
  ) => {
    let interaction = deepCopy(this.state.interaction);

    if (status) {
      interaction.selectedActionsNames = [];
      interaction.selectedReactionsNames = [];
      interaction.selectedTitleReactionsNames = [];
      interaction.selectedColumnsNames = [];
      interaction.actions = [];
      interaction.reactions = [];
      interaction.titleReactions = [];
      interaction.columns = [];
      
      for (let item in activeSourcePlugin.columnMap) {
        activeSourcePlugin.columnMap[item].data &&
          activeSourcePlugin.columnMap[item].data.map(column => {
            if (column.aggregatable != true) {
              interaction.columns.push(column);
              interaction.selectedColumnsNames.push(column.aliasName);
            }

            return column;
          });
      }

      interaction.selectedActionsNames.push(activeSourcePlugin.actions[0].name);
      interaction.actions.push(activeSourcePlugin.actions[0]);

      interaction.selectedReactionsNames.push(target.reactions[0].name);
      interaction.reactions.push(target.reactions[0]);

      interaction.selectedTitleReactionsNames.push(target.titleReactions[0].name);
      interaction.titleReactions.push(target.titleReactions[0]);

      let reduxState = store.getState();
      let autoInteractions = reduxState.FilterAutoInteractionReducer
        ? new Map(
            reduxState.FilterAutoInteractionReducer.filterAutoInteractions
          )
        : new Map();

      let interactions = deepCopy(autoInteractions.get(activeSourcePlugin.id));

      interactions = interactions.map(item => {
        if (item.targetId === interaction.targetId) {
          item = interaction;
        }
        return item;
      });

      autoInteractions.set(activeSourcePlugin.id, interactions);

      store.dispatch(setAutoInteractions(autoInteractions));
    }

    return interaction;
  };

  /**
   * Removes plugins for auto interactions
   * Adds removed interaction list to active interaction plugin
   */
  removePluginFromAutoInteraction = (plugin, activeInteractionPluginObject) => {
    if (activeInteractionPluginObject != undefined) {
      let isRemoved = false;
      let activeInteractionPlugin = deepCopy(
        activeInteractionPluginObject.plugin
      );

      if (activeInteractionPlugin.removedInteractions == undefined) {
        activeInteractionPlugin.removedInteractions = [];
      }

      if (
        !this.hasRemovedInteraction(
          activeInteractionPlugin.removedInteractions,
          plugin.id
        )
      ) {
        activeInteractionPlugin.removedInteractions.push(plugin.id);
      }

      if (
        activeInteractionPlugin.edittedInteractions &&
        activeInteractionPlugin.edittedInteractions[plugin.id]
      ) {
        delete activeInteractionPlugin.edittedInteractions[plugin.id];
        isRemoved = true;
      }

      this.findAndRemoveIfHasInteractions(
        activeInteractionPlugin.id,
        new Set([plugin.id])
      );

      this.resetInteractionObjectForTargetPlugin(
        activeInteractionPlugin,
        isRemoved,
        plugin
      );
      this.setRemovedAutoInteractionsInPlugin(activeInteractionPlugin);
    }
  };

  /**
   * Add plugins for auto interactions
   * Removes selected plugin id in removed interaction list from active interaction plugin
   */
  addPluginToAutoInteraction = (plugin, activeInteractionPluginObject) => {
    if (activeInteractionPluginObject != undefined) {
      let activeInteractionPlugin = deepCopy(
        activeInteractionPluginObject.plugin
      );

      if (activeInteractionPlugin.removedInteractions == undefined) {
        activeInteractionPlugin.removedInteractions = [];
      }

      activeInteractionPlugin.removedInteractions = activeInteractionPlugin.removedInteractions.filter(
        id => id != plugin.id
      );

      this.findAndRemoveIfHasInteractions(
        activeInteractionPlugin.id,
        new Set([plugin.id])
      );

      this.setRemovedAutoInteractionsInPlugin(activeInteractionPlugin);
    }
  };

  /**
   * Deciedes auto interaction or normal interaction for delete interaction
   */
  deleteInteractionFactory = (
    isAutoInteraction,
    activeInteractionPluginObject
  ) => {
    if (isAutoInteraction) {
      return this.removePluginFromAutoInteraction(
        this.props.plugin,
        activeInteractionPluginObject
      );
    } else {
      return this.deleteInteraction(this.props.plugin);
    }
  };

  /**
   * Interaction content
   */
  interactionSelectedContent = (
    isPluginHeightEqualOne,
    isPluginWidthEqualOne,
    buttonsDivStyle,
    isAutoInteraction = false,
    activeInteractionPluginObject = undefined
  ) => {
    return (
      <>
        <p
          style={this.interactionTargetSelectionStyle(
            isPluginHeightEqualOne,
            isPluginWidthEqualOne
          )}
          className={"container-filter-text"}
        >
          {isPluginHeightEqualOne ? null : (
            <>
              {!this.controlIsMeasureAndAllColumnsHasExpression(this.props.plugin) ?             
                  <Tooltip tooltip={i18n.t("Dashboard.Data.CantPerformAnyActionError")}>
                    <ExclamationCircleOutlined className={"interaction-error-icon"} />
                  </Tooltip> :             
                  <Tooltip tooltip={i18n.t("Interaction.InteractionTargetSelected")}>
                      <CheckOutlined className={"interaction-check-icon"} />
                  </Tooltip>}
              </>
          )}
        </p>
        <div style={buttonsDivStyle}>
          <Popover
            content={
              <InteractionEditPanel
                interaction={this.state.interaction}
                onChangeSelectedColumns={this.onChangeSelectedColumns}
                onChangeIgnoredDefaultFilters={this.onChangeIgnoredDefaultFilters}
                handleChangeReactionsDropdown={
                  this.handleChangeReactionsDropdown
                }
                handleChangeTitleReactionsDropdown={this.handleChangeTitleReactionsDropdown}
                handleChangeActionsDropdown={this.handleChangeActionsDropdown}
                plugin={this.props.plugin}
                updateInteractionSettings={this.updateInteractionSettings}
                activeInteractionPluginObject={
                  this.props.activeInteractionPluginObject.plugin
                }
                interactionListForDisabledColumn={this.props.interactionListForDisabledColumn}
              />
            }
            title={<Text>{i18n.t("Interaction.EditInteraction")}</Text>}
            trigger="click"
            visible={this.state.visible}
            style={{ width: "100px", height: "100px" }}
            onVisibleChange={this.handleVisibilityChange}
          >
            {isPluginHeightEqualOne ? (
              <Tooltip
                tooltip={i18n.t("Interaction.InteractionTargetSelected")}
                style={{ fontSize: "24px" }}
              >
              {!this.controlIsMeasureAndAllColumnsHasExpression(this.props.plugin) ?             
                  <Tooltip tooltip={i18n.t("Dashboard.Data.CantPerformAnyActionError")}>
                    <ExclamationCircleOutlined className={"interaction-error-icon"} style={this.interactionTargetButtonStyle(isPluginHeightEqualOne, isPluginWidthEqualOne)}/>
                  </Tooltip> :             
                  <Tooltip tooltip={i18n.t("Interaction.InteractionTargetSelected")}>
                      <CheckOutlined                   
                      className={"interaction-check-icon"}
                      style={this.interactionTargetButtonStyle(
                      isPluginHeightEqualOne,
                      isPluginWidthEqualOne
                  )} />
                  </Tooltip>}
              </Tooltip>
            ) : null}
            <Tooltip tooltip={i18n.t("Edit")}>
              <EditOutlined
                onClick={() =>
                  isAutoInteraction && this.handleVisibilityChange(true)
                }
                className={"circle-icon interaction-buttons"}
                style={this.interactionEditButtonStyle(
                  isPluginHeightEqualOne,
                  isPluginWidthEqualOne
                )}
              ></EditOutlined>
            </Tooltip>
          </Popover>

          <Tooltip tooltip={i18n.t("Delete")}>
            <DeleteOutlined
              onClick={() =>
                this.deleteInteractionFactory(
                  isAutoInteraction,
                  activeInteractionPluginObject
                )
              }
              className={"circle-icon interaction-buttons"}
              style={this.interactionEditButtonStyle(
                isPluginHeightEqualOne,
                isPluginWidthEqualOne
              )}
            />
          </Tooltip>
        </div>
      </>
    );
  };

  /**
   * apply all auto interaction for plugins by status
   */
  applyAutoInteractionToAllPluginsByStatus = (plugin, status) => {
    plugin.removedInteractions = [];

    let removedPluginIdsSet = new Set();

    if (status === false) {
      let reduxState = store.getState();

      Array.from(reduxState.PluginTriggerReducer.plugins.values()).map(p => {
        if (
          !autoInteractionPlugins.has(p.key) &&
          !exceptAutoInteractionPlugins.has(p.key)
        ) {
          plugin.removedInteractions.push(p.id);

          removedPluginIdsSet.add(p.id);
        }
      });

      this.props.interactions.map(interaction => {
        if (interaction.sourceId === plugin.id) {
          removedPluginIdsSet.add(interaction.targetId);
        }
      });
    }

    this.findAndRemoveIfHasInteractions(plugin.id, removedPluginIdsSet);

    this.setRemovedAutoInteractionsInPlugin(plugin);
  };

  /**
   * apply all interaction for plugins by status
   */
  applyInteractionToAllPluginsByStatus = (plugin, status) => {
    let interactions = deepCopy(this.props.interactions);

    if (status === false) {
      if (
        this.props.interactionsHashMapForSource &&
        this.props.interactionsHashMapForSource.size > 0
      ) {
        let indexList = Array.from(
          this.props.interactionsHashMapForSource.values()
        );

        interactions = interactions.filter(
          interaction =>
            !this.props.interactionsHashMapForSource.has(interaction.targetId)
        );
      }
    } else if (status === true) {
      let reduxState = store.getState();
      let plugins = deepCopy(
        Array.from(reduxState.PluginTriggerReducer.plugins.values())
      );

      let source = plugin;
      let pluginsHasData = false;

      Object.keys(source.columnMap).map(key => {
        if (
          source.columnMap[key].data &&
          source.columnMap[key].data.length > 0
        ) {
          pluginsHasData = true;
        }
      });

      if (pluginsHasData === false) {
        return;
      }

      plugins.map(target => {
        if (
          target.id !== source.id &&
          !this.props.interactionsHashMapForSource.get(target.id) &&
          !autoInteractionPlugins.has(target.key) &&
          !exceptAutoInteractionPlugins.has(target.key)
        ) {
          let isNotValidAllReactionAndAction = target.reaction == undefined && target.action == undefined && target.titleReaction == undefined;

          if (isNotValidAllReactionAndAction) {
            target.reaction = [];
            target.action = [];
            target.titleReaction = [];
            target.reaction.push(target.reactions[0]);
            target.action.push(target.actions[0]);

            if (target.titleReactions) {
              target.titleReaction.push(target.titleReactions[0]);
            } else {
              let titleReactions = this.props.plugin.titleReactions;

              if(titleReactions){
                target.titleReactions = titleReactions;
                target.titleReaction = [titleReactions[0]];
              }
            }
          }

          target.columns = this.setSourceColumns(target);

          if (!this.interactionHasCheck(target)) {
            let interaction = {};

            interaction.sourceId = source.id;
            interaction.targetId = target.id;
            interaction.filterPredicate = "="; // This is default predicate for interaction object to filter other plugins. In the future this may be changed in this component.
            interaction.columns = [];

            for (let item in source.columnMap) {
              source.columnMap[item].data &&
                source.columnMap[item].data.map(column =>
                  column.aggregatable != true
                    ? interaction.columns.push(column)
                    : null
                );
            }

            interaction.titleReactions = target.titleReaction;
            interaction.reactions = target.reaction;
            interaction.actions = [
              {
                ...source.actions[0]
              }
            ];

            interactions.push(interaction);
          }
        }
      });
    }

    this.setInteractions(plugin, interactions);
  };

  /**
   * Set applied all for normal interaction plugins with callback
   */
  setAppliedAll = (source, interactions, callback = undefined) => {
    let appliedAllCheckStatus = false;
    let pluginsMap = store.getState().PluginTriggerReducer.plugins;

    let plugins = Array.from(pluginsMap.values());
    let interactablePluginCount = plugins.filter(
      p =>
        !autoInteractionPlugins.has(p.key) &&
        p.id != source.id &&
        !exceptAutoInteractionPlugins.has(p.key)
    ).length;

    let interactionCountBySource = interactions.filter(
      interaction =>
        interaction.sourceId == source.id &&
        pluginsMap.has(interaction.targetId) &&
        !exceptAutoInteractionPlugins.has(
          pluginsMap.get(interaction.targetId).key
        ) &&
        !autoInteractionPlugins.has(pluginsMap.get(interaction.targetId).key)
    ).length;

    if (interactionCountBySource === interactablePluginCount) {
      appliedAllCheckStatus = true;
    }

    this.setState(
      {
        ...this.state,
        appliedAll: appliedAllCheckStatus
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  /**
   * Sets interactions with applied all status
   */
  setInteractions = (source, interactions) => {
    this.setAppliedAll(source, interactions, () =>
      this.props.setInteractions(interactions)
    );
  };

  /**
   * On change applied all checkbox
   */
  onAppliedAllChange = (e, plugin) => {
    let isAutoInteractionPlugin = autoInteractionPlugins.has(plugin.key);
    let status = e.target.checked;

    if (isAutoInteractionPlugin) {
      this.applyAutoInteractionToAllPluginsByStatus(plugin, status);
    } else {
      this.applyInteractionToAllPluginsByStatus(plugin, status);
    }
  };

  /**
   * Content of applied all checkbox
   */
  applyInteractionToAllPluginCheckContent = plugin => {
    let label = i18n.t("Interaction.ApplyToAll");

    return (
      <p style={{ margin: 0, marginTop: "5px" }}>
        <Checkbox
          checked={this.state.appliedAll}
          onChange={e => this.onAppliedAllChange(e, plugin)}
        >
          <span style={{ color: "#fff" }}>{label}</span>
        </Checkbox>
      </p>
    );
  };

  componentDidUpdate() {
    const pluginId = this.props.plugin.id;
    let pluginWidth = $("#grid-" + pluginId).find(".holder")[0].scrollWidth;
    if (pluginWidth && this.state.pluginWidth != pluginWidth) {
      this.pluginWidth = pluginWidth;
    }
  }

  render() {
    const isPluginHeightEqualOne = this.props.plugin.h <= 2 ? true : false;
    const isPluginWidthEqualOne = this.props.plugin.w <= 2 ? true : false;

    /*
     * Returns buttons general div style
     */
    const buttonsDivStyle = isPluginHeightEqualOne
      ? { position: "relative", top: "-10px", right: "-2px" }
      : {};

    const interactionTargetTranslation = isPluginHeightEqualOne
      ? i18n.t("Interaction.InteractionTargetSelectionSmall")
      : i18n.t("Interaction.InteractionTargetSelection");

    let selectedLanguage = Cookies.get("i18next");

    const isSelectedLanguageTR = selectedLanguage === "tr" ? true : false;

    const activeSourcePluginClassName = isPluginHeightEqualOne
      ? "small-height"
      : "";
    const closeButtonId = isPluginHeightEqualOne
      ? isPluginWidthEqualOne
        ? "close-button-width"
        : "close-button"
      : "";

    let isRemovedInteraction = false;

    if (
      this.props.activeInteractionPluginObject &&
      this.props.activeInteractionPluginObject.plugin
    ) {
      isRemovedInteraction = this.hasRemovedInteraction(
        this.props.activeInteractionPluginObject.plugin.removedInteractions,
        this.props.plugin.id
      );
    }

    return (
      <div className={"interaction-" + this.props.plugin.id}>
        <BlurComponent
          holderDisplay={
            this.props.activeInteractionPluginObject.plugin ? true : false
          }
          blurColor={
            this.isActiveSourcePlugin()
              ? "background-blue"
              : activeSourcePluginClassName
          }
          onClick={() =>
            this.pluginHasInteraction()
              ? this.setInteractionObject(
                  this.props.plugin,
                  isRemovedInteraction
                )
              : null
          }
        >
          {/*Plugin selected as source START*/}
          {this.isActiveSourcePlugin() ? (
            <>
              <p
                style={this.interactionSourceSelectedStyle(
                  isPluginHeightEqualOne,
                  isPluginWidthEqualOne,
                  isSelectedLanguageTR
                )}
                className={"container-filter-text"}
              >
                {i18n.t("Interaction.InteractionSourceSelected")}
              </p>
              {this.applyInteractionToAllPluginCheckContent(this.props.plugin)}
              <Tooltip
                tooltip={this.activePluginHasAnyInteractions(
                  "tooltip",
                  closeButtonId
                )}
                style={this.closeButtonStyle(
                  isPluginHeightEqualOne,
                  isPluginWidthEqualOne
                )}
              >
                {this.activePluginHasAnyInteractions("icon", closeButtonId)}
              </Tooltip>
            </>
          ) : (
            <>
              {" "}
              {this.props.activeInteractionPluginObject.plugin &&
              autoInteractionPlugins.has(
                this.props.activeInteractionPluginObject.plugin.key
              ) &&
              !(
                autoInteractionPlugins.has(this.props.plugin.key) ||
                exceptAutoInteractionPlugins.has(this.props.plugin.key)
              ) ? (
                this.props.activeInteractionPluginObject.plugin
                  .removedInteractions && isRemovedInteraction ? (
                  <>
                    {/*Plugin not selected as target START*/}
                    <p
                      style={this.interactionTargetSelectionStyle(
                        isPluginHeightEqualOne,
                        isPluginWidthEqualOne
                      )}
                      className={"container-filter-text"}
                    >
                      {interactionTargetTranslation}
                    </p>
                    {/*Plugin not selected as target END */}{" "}
                  </>
                ) : (
                  this.interactionSelectedContent(
                    isPluginHeightEqualOne,
                    isPluginWidthEqualOne,
                    buttonsDivStyle,
                    true,
                    this.props.activeInteractionPluginObject
                  )
                )
              ) : this.pluginHasInteraction() ? (
                <>
                  <p
                    style={this.interactionTargetSelectionStyle(
                      isPluginHeightEqualOne,
                      isPluginWidthEqualOne
                    )}
                    className={"container-filter-text"}
                  >
                    {interactionTargetTranslation}
                  </p>
                </>
              ) : (
                this.interactionSelectedContent(
                  isPluginHeightEqualOne,
                  isPluginWidthEqualOne,
                  buttonsDivStyle,
                  false
                )
              )}
            </>
          )}
        </BlurComponent>
      </div>
    );
  }
}