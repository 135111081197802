import React, { Component } from "react";
import PivotTableEnhance from "./PivotTableEnhance";
import PivotTable from "./PivotTable";
import i18n from "../../../../Utils/i18next";
import $ from "jquery";

/**
 * Base component of pivot table and pivot table enhance
 */
export default class PivotTableBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      renderPivot: false
    };
  }

  initialStatesForPlugins = {
    enhanceMode: this.props.plugin.key === "pivot-table" ? false : true
  };

  /**
   * Checks plugin attributes for the control of content component
   */
  control = () => {
    let isPluginEnhanceModeExist = this.props.plugin && this.props.plugin.config && this.props.plugin.config.enhanceMode
    
    if (isPluginEnhanceModeExist) { 
      return true;
    }

    return false;
  };

  /**
   * Discuss and prepare true plugin by control.
   */
  content = () => {
    let isEnhanceMode = this.control();
    let props = { ...this.props };

    if (props.plugin !== undefined) {
      if (props.plugin.config !== undefined) {
        props.plugin.config = {
          ...this.initialStatesForPlugins,
          ...props.plugin.config
        };
      }
    }

    if (isEnhanceMode) {
      $("#" + props.plugin.id).html("");
      return <PivotTableEnhance {...props} />;
    } else {
      return <PivotTable {...props} />;
    }
  };

  render() {
    return (
      <div style={{height: "100%"}}>
        <div id={this.props.plugin.id}></div>
        {this.content()}
      </div>
    )
  }
}
