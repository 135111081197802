export const SET_EXPRESSION = "SET_EXPRESSION";
export const SET_DEFINED_EXPRESSION = "SET_DEFINED_EXPRESSION";
export const SET_PROCESSING_LIST = "SET_PROCESSING_LIST";
export const SET_LAST_OPENED_KEY = "SET_LAST_OPENED_KEY"

/**
 * Actions for store all expression parameters.
 * 
 * @param {*} expressionMap
 * @returns
 */
export function setExpression(expressionMap) {
  return dispatch => {
    dispatch({
      type: SET_EXPRESSION,
      payload: expressionMap
    });
  };
}

/**
 * Actions for store all defined expression parameters.
 * 
 * @param {*} definedExpressionSet 
 * @returns 
 */
export function setDefinedExpression(definedExpressionSet) {
  return dispatch => {
    dispatch({
      type: SET_DEFINED_EXPRESSION,
      payload: definedExpressionSet
    });
  };
}

/**
 * Actions for store all processing list.
 * 
 * @param {*} processingList 
 * @returns 
 */
export function setProcessingList(processingList) {
  return dispatch => {
    dispatch({
      type: SET_PROCESSING_LIST,
      payload: processingList
    });
  };
}

/*
* Sets last opened key.
*/
export function setLastOpenedKey(key) {
  return dispatch => {
    dispatch({
      type: SET_LAST_OPENED_KEY,
      payload: key
    })
  }
}
