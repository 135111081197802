export const CHANGE_PLUGIN_CONTEXT_MENU = "CHANGE_PLUGIN_CONTEXT_MENU"
export const CHANGE_PLUGIN_CONTEXT_MENU_VISIBILITY = "CHANGE_PLUGIN_CONTEXT_MENU_VISIBILITY"
export const ADD_PLUGIN_FUNCTION = "ADD_PLUGIN_FUNCTION"
export const PASTE_PLUGIN_FUNCTION = "PASTE_PLUGIN_FUNCTION"
export const CHANGE_RIGHT_CLICKED_PLUGIN = "CHANGE_RIGHT_CLICKED_PLUGIN"
export const CHANGE_ORIGINAL_PLUGINS = "CHANGE_ORIGINAL_PLUGINS"
export const CHANGE_ORIGINAL_INTERACTIONS = "CHANGE_ORIGINAL_INTERACTIONS"

/**
 * 
 * Changes context menu and copied plugin props
 * 
 */
export function rightClickCopiedPluginStatus(copiedPluginObject) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_PLUGIN_CONTEXT_MENU,
      payload: copiedPluginObject,
    });
  };
}

/*
* Closes context menu
*/
export function closePluginCopyPastePopup(status) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_PLUGIN_CONTEXT_MENU_VISIBILITY,
      payload: status,
    });
  };
}

/*
* Add plugin function's callback
*/
export function addPluginFunction(func) {
  return (dispatch) => {
    dispatch({
      type: ADD_PLUGIN_FUNCTION,
      payload: func,
    });
  };
}

/*
* Paste plugin function's callback
*/
export function pastePluginFunction(func) {
  return (dispatch) => {
    dispatch({
      type: PASTE_PLUGIN_FUNCTION,
      payload: func,
    });
  };
}

/*
* Changes when click plugin and ctrl+c
*/
export function changeRightClickedPlugin(func) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_RIGHT_CLICKED_PLUGIN,
      payload: func,
    });
  };
}

/**
 * Changes original plugins map
 * 
 * @param {*} obj 
 * @returns 
 */
export function changeOriginalPlugins(obj) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_ORIGINAL_PLUGINS,
      payload: obj,
    });
  };
}

/**
 * Changes original interactions map
 * 
 * @param {*} obj 
 * @returns 
 */
export function changeOriginalInteractions(obj) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_ORIGINAL_INTERACTIONS,
      payload: obj,
    });
  };
}
