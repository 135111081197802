import React from "react";
import i18n from "../../../Utils/i18next";
import Text from "../Text/Text";
import { setNewColumnEditorContent } from "./NewColumnEditorAction";
import { connect } from "react-redux";

/**
 * This component consists of:
 *  - Description and usage of the selected formula from `FormulaList`
 */
class NewColumnFormulaDescription extends React.Component {
    constructor() {
        super();
        this.state = {
            selectedFormula: {}
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.state.NewColumnEditorReducer === this.props.state.NewColumnEditorReducer) {
            return;
        }

        let flag = false;
        let newState = { ...this.state };

        if (nextProps.state.NewColumnEditorReducer.contentObject.selectedFormula !== this.state.selectedFormula) {
            newState.selectedFormula = nextProps.state.NewColumnEditorReducer.contentObject.selectedFormula;
            flag = true;
        }

        if (
            flag === true &&
            nextProps.state.NewColumnEditorReducer.contentObject.visible === true
        ) {
            this.setState(newState);
        }
    }

    render() {
        return (
            <div style={{ width: "100%", padding: "8px 16px" }}>
                <Text
                    style={{
                        fontSize: "1rem",
                        display: "block",
                        width: "100%",
                        marginBottom: "8px",
                        color: "#225f8f"
                    }}
                >
                    {
                        this.state.selectedFormula && this.state.selectedFormula.name
                            ? this.state.selectedFormula.name
                            : i18n.t("FormulaEditor.Titles.DescriptionArea")
                    }
                </Text>
                {
                    this.state.selectedFormula && this.state.selectedFormula.name ? (
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                paddingBottom: "8px"
                            }}
                        >

                            {
                                this.state.selectedFormula.description ? (
                                    <Text
                                        style={{
                                            marginBottom: "16px",
                                            color: "#555"
                                        }}
                                    >
                                        <span dangerouslySetInnerHTML={{ __html: this.state.selectedFormula.description }} />
                                    </Text>
                                ) : null
                            }
                            {
                                this.state.selectedFormula.usages && this.state.selectedFormula.usages.length > 0
                                    ? (
                                        <>
                                            <Text
                                                style={{
                                                    fontSize: "1rem",
                                                    display: "block",
                                                    width: "100%",
                                                    marginBottom: "8px",
                                                    color: "#225f8f"
                                                }}
                                            >
                                                {i18n.t("FormulaEditor.Usage")}
                                            </Text>
                                            {
                                                this.state.selectedFormula.usages.map((usage, index) => (
                                                    <>
                                                        {
                                                            this.state.selectedFormula.usages.length > 1 ? (
                                                                <Text
                                                                    style={{
                                                                        marginBottom: "4px",
                                                                        color: "#225f8f"
                                                                    }}
                                                                >
                                                                    {i18n.t("FormulaEditor.Type")} {(index + 1)}
                                                                </Text>
                                                            ) : null
                                                        }
                                                        <Text
                                                            style={{
                                                                color: "#555",
                                                                marginBottom: "8px"
                                                            }}
                                                        >
                                                            <span dangerouslySetInnerHTML={{ __html: usage }} />
                                                        </Text>
                                                    </>
                                                ))
                                            }
                                        </>
                                    ) : null
                            }
                        </div>
                    ) : (
                        <Text
                            style={{
                                display: "block",
                                width: "100%",
                                color: "#555"
                            }}
                        >
                            {i18n.t("FormulaEditor.SelectFormula")}
                        </Text>
                    )
                }
            </div>
        )
    }
}

/**
 * Set action method to props
 */
const mapDispatchToProps = {
    setNewColumnEditorContent,
};

/**
 * Set store objects to state
 * 
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
    return {
        state: state,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewColumnFormulaDescription)