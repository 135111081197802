/* eslint-disable */

import React, { Component } from "react";
import i18n from "../../../../../Utils/i18next";
import Button from "../../../../GeneralComponents/Button/Button";
import { InfoOutlined, SaveOutlined } from "@ant-design/icons";
import { showNotificationWithIcon } from "../../../../../Utils/Notification";
import { API_BASE, RESOURCE_API_URL } from "../../../../../config";
import { post } from "../../../../../Utils/WebService";
import { Popover, Input, Typography, Tooltip } from "antd";
import Text from "../../../../GeneralComponents/Text/Text";
import { store } from "../../../../../index";
import "./dashboardSave.css";
import $ from "jquery";
import { getIcon, loadingScreen } from "../../../../../Utils/Global";
import { createImage } from "./createImage";
import { getPluginImages } from "../../../../Plugins/PluginComponents/common";
import { deepCopy } from "../../../../../Utils/Global";
import { connect } from "react-redux";
import { changeCustomDashboardStatus } from "../../../../GeneralComponents/CustomDashboard/CustomDashboardAction"
import _ from "lodash";
import CustomDashboard from "../../../../GeneralComponents/CustomDashboard/CustomDashboard";
import { addPluginCacheMode, savedPluginsInCacheMode } from "../../../../CacheMode/CacheModeAction";
import WebSocketApp from "../../../../WebSocket/WebSocketApp";
import Cookies from "js-cookie";
import { BiErrorCircle } from "react-icons/bi";
import { AiOutlineInfo } from "react-icons/ai";
import { Modal } from "antd";
import { setDefinedExpression, setExpression } from "../../../../ExpressionParameter/ExpressionParameterAction";

const { Paragraph } = Typography;

/**
 * Include dashboard save button and method.
 */
class DashboardSave extends Component {
  constructor(props) {
    super(props);

    let defaultTitle = this.props.data.settings.title
      ? deepCopy(this.props.data.settings.title)
      : "";

    this.state = {
      showSaveAsArea: false,
      showNecessaryFieldsArea: false,
      hasChange: false,

      title: defaultTitle,
      titleOnTheFly: defaultTitle,
      onSaveAsCustomDashboard: false,
      onSaveAsForCustomDashboard: false,
      topics: [],
      authenticatedUser: Cookies.get("authenticatedUser"),
      wereDashboardSavedAfterOpenDashboard: false,
      visibilityOfSaveProcessPopup: false
    };

    this.wrapperRef = React.createRef();
    this.necessaryFieldsAreaRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.mainDashboardId) {
      this.setState({
        ...this.state,
        topics: [
          `/topic/dashboard-save-${this.props.mainDashboardId}`,
        ]
      })
    }

    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  //If click outside hide switcher area.
  handleClickOutside = event => {
    let clickOutNecessaryFieldsAreaCheck =
      this.state.showNecessaryFieldsArea != false &&
      this.necessaryFieldsAreaRef &&
      !this.necessaryFieldsAreaRef.current.contains(event.target);
    let isWrapperAndWrapperCurrentAvailable = this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target) ? true : false

    if (isWrapperAndWrapperCurrentAvailable) {
      if (!$(event.target).closest("#loginPopup").length > 0) {
        this.closeSaveOperations();
      }
    }

    if (this.necessaryFieldsAreaRef.current != null) {
      if (clickOutNecessaryFieldsAreaCheck) {
        this.cancelNecessaryFieldsArea();
      }
    }
  };

  /**
 * Check title and set state if necessary
 */
  componentWillReceiveProps(nextProps) {
    let newState = { ...this.state }
    let flag = false

    if (this.props.data.settings.title !== nextProps.data.settings.title) {
      newState["title"] = nextProps.data.settings.title
      newState["titleOnTheFly"] = nextProps.data.settings.title

      flag = true
    }

    if (nextProps.mainDashboardId !== this.props.mainDashboardId && nextProps.mainDashboardId !== undefined) {
      newState["topics"] = [`/topic/dashboard-save-${nextProps.mainDashboardId}`]

      flag = true
    }

    if (flag) {
      this.setState(newState)
    }
  }

  saveDashboardThumbnail = (data, isCustomDashboard, mainDashboardId) => {
    let reduxState = store.getState();
    let pluginImages = reduxState.DefaultPluginsReducer?.pluginsMap;
    let isRequested = reduxState.DefaultPluginsReducer?.isRequested;
    let isPluginImagesNotExists = (!pluginImages || pluginImages.size === 0) && isRequested === false;

    const uploadThumbnail = (thumbnail, save) => {
      let requestBody = {
        content: thumbnail,
        secure: true,
        cacheable: true
      };

      if (data?.thumbnail && !data.onSaveAs) {
        requestBody["_id"] = data.thumbnail;
      }

      post(RESOURCE_API_URL, requestBody, response => save(response.data), (error) => {console.info(error)}, true, false, undefined, true);
    }

    if (isPluginImagesNotExists) {
      getPluginImages(pluginsObject => {
        createImage(data.settings, data.plugins, pluginsObject.pluginsMap, () => {
          let thumbnail = document
            .getElementById("dashboardThumbnail")
            .toDataURL("image/png")
            .split(",")[1];

          uploadThumbnail(thumbnail, (thumbnail) => this.save(data, thumbnail, isCustomDashboard, mainDashboardId));
        });
      });
    } else {
      createImage(data.settings, data.plugins, pluginImages, () => {
        let thumbnail = document
          .getElementById("dashboardThumbnail")
          .toDataURL("image/png")
          .split(",")[1];

          uploadThumbnail(thumbnail, (thumbnail) => this.save(data, thumbnail, isCustomDashboard, mainDashboardId));
      });
    }
  }

  /**
   * Validate title
   * Saves given dashboard by save and saveas condition
   */
  saveProcess = (isCustomDashboard = false, mainDashboardId, onSaveAs = false) => {
    loadingScreen(true);

    let data = { ...this.props.data };

    let reduxState = store.getState();
    data.plugins = Array.from(reduxState.PluginTriggerReducer.plugins.values());

    if (data.plugins.length === 0) {
      this.showErrorOnScreen("Dashboard.Save.NoPlugins");
      return;
    }

    if (this.hasValue(this.state.titleOnTheFly) === false) {
      this.showErrorOnScreen("Dashboard.Save.TitleEmptyError");
      return;
    }

    data.onSaveAs = onSaveAs ? onSaveAs : this.state.onSaveAs;

    this.setState({
      ...this.state,
      showNecessaryFieldsArea: false,
      showSaveAsArea: false,
      onSaveAs: false,
      wereDashboardSavedAfterOpenDashboard: false,
      visibilityOfSaveProcessPopup: false
    });

    let pluginImages = reduxState.DefaultPluginsReducer
      ? reduxState.DefaultPluginsReducer.pluginsMap
      : undefined;
    let isRequested = reduxState.DefaultPluginsReducer.isRequested;
    let isPluginImagesNotExists =
      (!pluginImages || pluginImages.size === 0) && isRequested === false;

    // Sets expression name
    const setExpressionName = column => {
      if (column.preserveDataAs) {
        let reduxState = store.getState();
        let definedExpressionSet = new Map(reduxState.ExpressionParameterReducer.definedExpressionSet);
        let expressionMap = new Map(reduxState.ExpressionParameterReducer.expressionMap);
        let oldValue = definedExpressionSet.get(column.uniqeColumnId);

        if (column.preserveDataAs === oldValue) return;

        definedExpressionSet.delete(column.uniqeColumnId);

        let expressionNameBeforeRenaming = column.preserveDataAs;
        let allExpressionNames = Array.from(definedExpressionSet.values());

        while (allExpressionNames.includes(column.preserveDataAs)) {
          column.preserveDataAs = column.preserveDataAs + "_";
        }

        if (expressionNameBeforeRenaming !== column.preserveDataAs) {
          let message = i18n.t("Errors.ExpressionNameAlreadyExists")
            .replace("[newExpressionName]", column.preserveDataAs)
            .replace("[oldExpressionName]", expressionNameBeforeRenaming);

          showNotificationWithIcon(
            i18n.t("Warning"),
            message,
            "warning"
          );
        }

        definedExpressionSet.set(column.uniqeColumnId, column.preserveDataAs)

        if (oldValue !== undefined) {
          expressionMap.delete(oldValue);

          store.dispatch(setExpression(expressionMap));
        }

        store.dispatch(setDefinedExpression(definedExpressionSet));
      }
    }

    data?.plugins?.forEach(p => {
      p.columnMap?.measure?.data?.forEach(c => setExpressionName(c));
    });

    this.saveDashboardThumbnail(data, isCustomDashboard, mainDashboardId);
  };

  /**
   *
   * @param {*} drillDowns
   * @returns
   *
   * Converts drilldown object to string and sends it to be saved
   */
  convertDrillDownMapToString = (drillDowns, pluginId) => {
    let reduxState = store.getState()
    let allDrillDownsMap = reduxState.DrillDownReducer.drillDowns
    let currentDrillObject = allDrillDownsMap.has(pluginId) ? allDrillDownsMap.get(pluginId) : {}
    let currentDrillType = currentDrillObject.drillDownTypes ? currentDrillObject.drillDownTypes : "normal"

    let isNotStringVariable =
      typeof drillDowns.drillDownLayerMap !== "string" &&
      typeof drillDowns.allDrillDownColumnsInPlugin !== "string" &&
      typeof drillDowns.allDrillDownColumnsInPlugin !== "string" &&
      typeof drillDowns.preserveDefaultFilters !== "string";

    if (drillDowns && isNotStringVariable) {
      for (let [key, value] of drillDowns.drillDownLayerMap) {
        if (value.hasDrillDownOperation === true) {
          value.hasDrillDownOperation = false;
          drillDowns.drillDownLayerMap.set(key, value);
        }
      }

      drillDowns.drillDownLayerMap = JSON.stringify(
        Array.from(drillDowns.drillDownLayerMap.entries())
      );
      drillDowns.allDrillDownColumnsInPlugin = JSON.stringify(
        Array.from(drillDowns.allDrillDownColumnsInPlugin.entries())
      );
      drillDowns.drillDownColumnsForParentColumns = JSON.stringify(
        Array.from(drillDowns.drillDownColumnsForParentColumns.entries())
      );
      drillDowns.preserveDefaultFilters = currentDrillObject.preserveDefaultFilters instanceof Map
        ? JSON.stringify(Array.from(currentDrillObject.preserveDefaultFilters.entries()))
        : JSON.stringify([]);
      drillDowns.drillDownTypes = currentDrillType
    }

    return drillDowns;
  };

  /*
  * Controls is plugin has cache mode or else
  */
  setIsPluginHasCacheMode = plugins => {
    let cachedAndSavedPlugins = new Map(this.props.cachedPluginsReducer.savedCachedPluginsMap)

    for (let i = 0; i < plugins.length; i++) {
      let plugin = plugins[i]
      let pluginId = plugin.id
      let cacheTime = this.props.cachedPluginsReducer.cachedOptions

      if (this.props.cachedPluginsReducer.cachedPluginsMap.has(pluginId) && this.props.cachedPluginsReducer.cachedPluginsMap.get(pluginId).status) {
        cachedAndSavedPlugins.set(pluginId, { status: true, cacheOptions: cacheTime })

        plugins[i]["isCached"] = true
        plugins[i]["cacheTime"] = cacheTime
      } else if (this.props.cachedPluginsReducer.savedCachedPluginsMap.has(pluginId)) {
        cachedAndSavedPlugins.set(pluginId, { status: false, cacheOptions: {} })

        plugins[i]["isCached"] = false
        plugins[i]["cacheTime"] = undefined
      }
    }

    store.dispatch(savedPluginsInCacheMode(cachedAndSavedPlugins))
    store.dispatch(addPluginCacheMode(cachedAndSavedPlugins))

    return plugins
  }

  /**
   * Saves dashboard
   */
  save = (data, thumbnail, isCustomDashboard, mainDashboardId) => {
    let onSaveAs = data.onSaveAs;
    let dashboardId = isCustomDashboard ? data.id : data.mainDashboardId || data.id;

    data.thumbnail = thumbnail;

    /** set plugins */
    let plugins = [];
    data.plugins.map(p => {
      if (p.config && p.config["allTopoJsons"]) {
        p.config.allTopoJsons = [];
      }

      let preservedConfigFieldsControl = p.preservedConfigFields instanceof Set;

      let plugin = {
        id: p.id,
        key: p.key,
        config: deepCopy(p.originalConfig || p.config),
        preservedConfigFields: preservedConfigFieldsControl ? [...p.preservedConfigFields?.values()] : [new Set()],
        columnMap: deepCopy(p.columnMap),
        conditionalFormats: deepCopy(p.conditionalFormats),
        navigations: deepCopy(p.navigations),
        drillDowns: p.drillDowns
          ? deepCopy(this.convertDrillDownMapToString(p.drillDowns, p.id))
          : "",
        sortedColumnList: deepCopy(p.sortedColumnList),
        x: p.x, // plugin x axis coordinate
        y: p.y, // plugin y axis coordinate
        w: p.w, // plugin width
        h: p.h, // plugin height
        minH: p.minH, // plugin minimum height
        minW: p.minW, // plugin minimum width
        actions: p.actions,
        reactions: p.reactions,
        defaultFilters: p.defaultFilters,
        removedInteractions: deepCopy(p.removedInteractions),
        edittedInteractions: deepCopy(p.edittedInteractions),
        limit: p.limit
      };

      plugins.push(plugin);
    });

    data.settings.title = this.state.titleOnTheFly;

    let autoRefresh = undefined;

    if (data.autoRefresh) {
      autoRefresh = deepCopy(data.autoRefresh);
      autoRefresh.exceptedPlugins = Array.from(autoRefresh.exceptedPlugins);
    }

    let requestBody = {
      id: onSaveAs ? null : dashboardId,
      plugins: this.setIsPluginHasCacheMode(plugins),
      modelId: data.model.id,
      interactions: data.interactions,
      settings: deepCopy(data.settings),
      thumbnail: data.thumbnail,
      customThumbnail: data.customThumbnail,
      creationDate: data.creationDate,
      labels: deepCopy(data.labels),
      autoRefresh: autoRefresh,
      whatIf: data.whatIf,
      theme: data.theme,
      mainDashboardId: this.props.mainDashboardId ? this.props.mainDashboardId : null
    };

    let url = !isCustomDashboard ? `${API_BASE}/dashboard` : `${API_BASE}/customDashboard`;

    const successFunc = result => {
      showNotificationWithIcon(
        i18n.t("Dashboard.Save.SuccessMessage"),
        null,
        "success"
      );

      let data = { ...this.props.data };
      data.id = result.data.id;
      data.creationDate = result.data.creationDate;
      data.lastUpdatedDate = result.data.lastUpdatedDate;
      data.labels = result.data.labels;
      data.settings = {
        ...data.settings,
        ...result.data.dashboardSettings,
        grid: { ...data.settings.grid },
        dashboardTitleSettings: {
          ...result.data.dashboardSettings.dashboardTitleSettings
        }
      };
      data.settings.defaultBackgroundColor = data.settings.backgroundColor;
      data.settings.defaultBackgroundImage = data.settings.backgroundImage;
      data.settings.defaultBackgroundImageTemp =
        data.settings.backgroundImageTemp;

      this.props.setTitleSettingsToDefault(data.settings);

      this.props.saveDashboard(data, isCustomDashboard, mainDashboardId);

      this.setState({
        ...this.state,
        showNecessaryFieldsArea: false,
        showSaveAsArea: false,
        title: this.state.titleOnTheFly,
        wereDashboardSavedAfterOpenDashboard: false
      }, () => {
        if (onSaveAs) {
          this.props.cloneCustomExcelTemplates(
            dashboardId,
            result.data.id
          );
        }
      });
    };

    post(url, requestBody, successFunc, undefined, true);
  };

  showErrorOnScreen = field => {
    loadingScreen(false);
    showNotificationWithIcon(i18n.t(field), null, "error");
  };

  hasValue = value => {
    if (value === undefined || value === "") {
      return false;
    }

    return true;
  };

  changeTitleSetting = e => {
    this.setState({
      ...this.state,
      titleOnTheFly: e.target.value
    });
  };

  closeSaveOperations = () => {
    this.setState({
      ...this.state,
      showSaveAsArea: false
    });
  };

  cancelNecessaryFieldsArea = () => {
    this.setState({
      ...this.state,
      showNecessaryFieldsArea: false,
      showSaveAsArea: false,
      onSaveAs: false,
      titleOnTheFly: this.state.title
    });
  };

  /**
   * Returns component that is shown when dashboard is saved
   */
  contentSaveWithoutTitle = () => {
    let isCustomDashboard = this.state.onSaveAsForCustomDashboard

    if (isCustomDashboard) {
      return (
        <div ref={this.necessaryFieldsAreaRef} className={"save-as-popup"}>
          <Text>{i18n.t("Dashboard.Save.TitleEmptyMessage")}</Text>
          <Input
            id={"title"}
            style={{ display: "block", margin: "10px 0" }}
            defaultValue={this.state.titleOnTheFly}
            value={this.state.titleOnTheFly}
            onChange={this.changeTitleSetting}
          />
          <Button
            className={"btn-sm"}
            onClick={() => this.cancelNecessaryFieldsArea()}
          >
            {i18n.t("Cancel")}
          </Button>

          <Button
            style={{ float: "right" }}
            className={"btn-primary btn-sm"}
            onClick={() => this.saveProcess(true, this.props.mainDashboardId)}
          >
            {i18n.t("Dashboard.Save.Save")}
          </Button>
        </div>
      );
    }

    return (
      <div ref={this.necessaryFieldsAreaRef} className={"save-as-popup"}>
        <Text>{i18n.t("Dashboard.Save.TitleEmptyMessage")}</Text>
        <Input
          id={"title"}
          style={{ display: "block", margin: "10px 0" }}
          defaultValue={this.state.titleOnTheFly}
          value={this.state.titleOnTheFly}
          onChange={this.changeTitleSetting}
        />
        <Button
          className={"btn-sm"}
          onClick={() => this.cancelNecessaryFieldsArea()}
        >
          {i18n.t("Cancel")}
        </Button>

        <Button
          style={{ float: "right" }}
          className={"btn-primary btn-sm"}
          onClick={() => this.saveProcess(false)}
        >
          {i18n.t("Dashboard.Save.Save")}
        </Button>
      </div>
    );
  };

  prepareForSaveAs = (isCustomDashboardSave = false) => {
    this.setState({
      ...this.state,
      showNecessaryFieldsArea: true,
      showSaveAsArea: false,
      onSaveAs: true,
      onSaveAsForCustomDashboard: isCustomDashboardSave
    });
  };

  /**
   * If dashboard has title then tries to save dashboard otherwise opens title popup
   */
  makeDecisionForSave = () => {
    this.setState({
      ...this.state,
      visibilityOfSaveProcessPopup: false
    }, () => {
      if (this.hasValue(this.state.titleOnTheFly)) {
        if (this.props.data.id) {
          this.setState({
            ...this.state,
            showSaveAsArea: true
          });
        } else {
          this.saveProcess();
        }
      } else {
        this.setState({ ...this.state, showNecessaryFieldsArea: true });
      }
    })
  };

  /*
  * Listens dashboard save
  */
  listenDashboardSave = (topic) => {
    if (topic !== this.state.authenticatedUser) {
      this.setState({
        ...this.state,
        wereDashboardSavedAfterOpenDashboard: true
      })
    }
  }

  /*
  * Change status of save process popup
  */
  changeStatusOfSaveProcessPopup = (status) => {
    this.setState({
      ...this.state,
      visibilityOfSaveProcessPopup: status
    })
  }

  /*
  * Gets dashboard and sets false visibility of process popup and save error
  */
  getDashboard = () => {
    this.props.getDashboard({ dashboardId: this.props.dashboard.id })

    this.setState({
      ...this.state,
      visibilityOfSaveProcessPopup: false,
      wereDashboardSavedAfterOpenDashboard: false
    })
  }

  /*
  * Controls is dashboard saved another user
  */
  controlSaveProcesses = () => {
    if (this.state.wereDashboardSavedAfterOpenDashboard) {
      this.changeStatusOfSaveProcessPopup(true)
    } else {
      this.saveProcess(false);

      this.changeStatusOfSaveProcessPopup(false)
    }
  }

  buttonStyle = () => {
    return {
      fontSize: "12px",

    }
  }

  render() {
    let buttonTheme = this.props.theme?.buttons?.saveButton;
    let isCustomDashboardMode = store.getState().CustomDashboardReducer.isCustomDashboard
    let isCurrentDashboardIsMainDashboard = this.props.mainDashboardId === this.props.dashboard.id ? true : false
    let leftPosition = Cookies.get("i18next") === "tr" ? "145px" : "130px"

    return (
      <>
        <WebSocketApp
          listenTopic={this.listenDashboardSave}
          topics={this.state.topics}
          dashboardId={this.props.mainDashboardId}
        />

        <Modal
          visible={this.state.visibilityOfSaveProcessPopup}
          title={
            <span style={{ fontSize: "13px", fontWeight: "bold", color: "rgba(0, 0, 0, 0.65)" }}>
              {i18n.t("Notification.UnsavedChanges")}
            </span>}
          onCancel={() => this.changeStatusOfSaveProcessPopup(false)}
          footer={[
            <Button style={this.buttonStyle()} type="primary" onClick={this.getDashboard}>
              {i18n.t("Notification.ReloadDashboard")}
            </Button>,
            <Button style={this.buttonStyle()} type="primary" onClick={() => this.makeDecisionForSave()}>
              {i18n.t("Notification.Continue")}
            </Button>,

            <Button style={this.buttonStyle()} onClick={() => this.changeStatusOfSaveProcessPopup(false)}>
              {i18n.t("Cancel")}
            </Button>,
          ]}
        >
          <div>
            <span style={{ fontSize: "13px" }}>
              {i18n.t("Notification.UnsavedChangesContent")}
            </span>
          </div>
          <div style={{ marginTop: "15px" }}>
            <span style={{ fontWeight: "bold", marginRight: "3px", fontSize: "13px" }}>
              {i18n.t("Notification.ReloadDashboard")}:
            </span>
            <span style={{ fontSize: "13px" }}>
              {i18n.t("Notification.UnsavedChangesContentReloadDashboard")}
            </span>
          </div>
          <div style={{ marginTop: "7px" }}>
            <span style={{ fontWeight: "bold", marginRight: "3px", fontSize: "13px" }}>
              {i18n.t("Notification.Continue")}:
            </span>
            <span style={{ fontSize: "13px" }}>
              {i18n.t("Notification.UnsavedChangesContentContinue")}
            </span>
          </div>
        </Modal>

        {isCustomDashboardMode || this.props.readOnly === "WRITE" ? (
          <div ref={this.wrapperRef} className={"hide-on-mobile"}>
            <Popover
              content={this.contentSaveWithoutTitle()}
              title={
                this.state.onSaveAs
                  ? i18n.t("Dashboard.Save.SaveAs")
                  : i18n.t("Dashboard.Save.Save")
              }
              trigger="click"
              visible={this.state.showNecessaryFieldsArea}
            >
              <Button
                id="saveButton"
                className={"dashboard-button btn btn-coloured circle-button active"}
                onClick={() => this.state.wereDashboardSavedAfterOpenDashboard ? this.changeStatusOfSaveProcessPopup(true) : this.makeDecisionForSave()}
                style={{ marginLeft: 0, fontSize: this.state.wereDashboardSavedAfterOpenDashboard && "25px" }}
              >
                <style>
                  {buttonTheme && `
                    #saveButton {
                      background-color: ${buttonTheme.backgroundColor};
                      border-color: ${buttonTheme.textColor}33;
                      color: ${buttonTheme.textColor};
                    }

                    #saveButton:hover,
                    #saveButton:focus,
                    #saveButton:active {
                      background-color: ${buttonTheme.backgroundColorActive} !important;
                      color: ${buttonTheme.textColorActive} !important;
                      border-color: ${buttonTheme.textColorActive}33 !important;
                    }
                  `}
                </style>
                {
                  getIcon(
                    buttonTheme?.icon,
                    <SaveOutlined style={{ fontSize: "25px" }} />,
                    { fontSize: "25px" }
                  )
                }
              </Button>
            </Popover>

            <div style={{ display: "none" }}>
              <canvas id="dashboardThumbnail" width={"900"} height={"300"}></canvas>
            </div>

            {this.state.showSaveAsArea ? (
              <div id="saveAsArea" className={"saveAsArea"} style={{ left: `calc(50% - ${leftPosition})` }}>
                <style>
                  {buttonTheme && `
                    .saveAsArea {
                      background-color: ${buttonTheme.backgroundColor};
                      border-color: ${buttonTheme.textColor}33;
                      color: ${buttonTheme.textColor};
                    }

                    .saveAsArea .item {
                      border-bottom-color: ${buttonTheme.textColor}33;
                    }

                    .saveAsArea .item:hover {
                      background-color: ${buttonTheme.backgroundColorActive};
                      color: ${buttonTheme.textColorActive};
                    }
                  `}
                </style>
                {this.props.readOnly === "WRITE" ?
                  <>
                    {isCurrentDashboardIsMainDashboard ?
                      <>
                        <div className={this.state.wereDashboardSavedAfterOpenDashboard ? "item danger-with-save" : "item"} onClick={() => this.saveProcess()}>
                          {this.state.wereDashboardSavedAfterOpenDashboard
                            ? <>
                              <Tooltip title={i18n.t("Notification.UnsavedChanges")}>
                                <span><BiErrorCircle style={{ marginRight: "5px", fontSize: "17px", marginBottom: "2px" }} /></span>
                                {i18n.t("Dashboard.Save.Save")}
                              </Tooltip>
                            </>
                            : <>{i18n.t("Dashboard.Save.Save")}</>}
                        </div>
                        <div className={"item"} onClick={() => this.prepareForSaveAs()}>
                          {i18n.t("Dashboard.Save.SaveAs")}
                        </div></> : null}
                  </> : null}
                {!isCurrentDashboardIsMainDashboard ?
                  <div className={"item"} onClick={() => this.saveProcess(true, this.props.mainDashboardId)}>
                    {i18n.t("CustomDashboard.Save")}
                  </div> : null}
                <div className={"item"} onClick={() => this.prepareForSaveAs(true, this.props.mainDashboardId)}>
                  {i18n.t("CustomDashboard.SaveAs")}
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </>);
  }
}

const mapDispatchToProps = {
  changeCustomDashboardStatus,
};

const mapStateToProps = (state) => {
  return {
    state: state,
    cachedPluginsReducer: state.CacheModeReducer
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSave);
