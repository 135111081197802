import { SET_PLUGIN_UTILS } from './MapChoroplethDetailAction';

const initialState = {
    pluginLayers: {},
    detailCallback: "",
    detailCallbackForInteraction: "",
    event: "",
    listOfActionsVisibility: false,
    mousePositionLayer: {},
    pluginId: "",
    currentIndex: 1
};

/**
 * Reducer for managing map choropleth action states
 */
const setChoroplethUtils = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLUGIN_UTILS:
      return { 
        pluginLayers: action.payload.pluginLayers ? {...state.pluginLayers, ...action.payload.pluginLayers} : state.pluginLayers,
        detailCallback: action.payload.detailCallback ? action.payload.detailCallback : state.detailCallback, 
        event: action.payload.event ? action.payload.event : state.event, 
        listOfActionsVisibility: action.payload.listOfActionsVisibility ? action.payload.listOfActionsVisibility : state.listOfActionsVisibility, 
        mousePositionLayer: action.payload.mousePositionLayer ? action.payload.mousePositionLayer : state.mousePositionLayer, 
        pluginId: action.payload.pluginId ? action.payload.pluginId : state.pluginId,
        currentIndex: action.payload.currentIndex ? action.payload.currentIndex : state.currentIndex,
        detailCallbackForInteraction: action.payload.detailCallbackForInteraction ? action.payload.detailCallbackForInteraction : state.detailCallbackForInteraction
    };
    default:
      return state;
  }
};

export default setChoroplethUtils;