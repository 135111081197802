import React, { Component } from "react";
import "./cacheMode.css"
import { store } from "../..";
import { addPluginCacheMode, setCacheMode } from "./CacheModeAction";
import { CloseOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { connect } from "react-redux";
import { BiErrorAlt } from "react-icons/bi";
import i18n from "../../Utils/i18next";
import { AiOutlineCheck } from "react-icons/ai";

class CacheMode extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    /*
    * Adds plugin to cache mode
    */
    addPluginToCacheMode = () => {
        let plugin = this.props.plugin
        let copiedCachedPlugins = new Map(this.props.cachedPluginsMap)
        let status = true
        let time = this.props.cacheModeReducer.cachedOptions

        copiedCachedPlugins.set(plugin.id, {status: status, cacheOptions: time})

        store.dispatch(addPluginCacheMode(copiedCachedPlugins))
    }

    /*
    * Removes plugin from cache mode
    */
    removePluginToCacheMode = () => {
        let plugin = this.props.plugin
        let copiedCachedPlugins = new Map(this.props.cachedPluginsMap)
        let status = false

        copiedCachedPlugins.set(plugin.id, {status: status, cacheOptions: {}})

        store.dispatch(addPluginCacheMode(copiedCachedPlugins))
    }

    /*
    * Checks plugin status cache mode
    */
    checkStatusIsChangeAvailable = () => {
        let pluginId = this.props.plugin.id
        let beforeStatus = this.props.cacheModeReducer.cachedPluginsMap.has(pluginId) && this.props.cacheModeReducer.cachedPluginsMap.get(pluginId).status
        let afterStatus = this.props.cacheModeReducer.savedCachedPluginsMap.has(pluginId) && this.props.cacheModeReducer.savedCachedPluginsMap.get(pluginId).status
        let beforeCacheModeOption = this.props.cacheModeReducer.cachedPluginsMap.has(pluginId) && this.props.cacheModeReducer.cachedPluginsMap.get(pluginId).cacheOptions.type
        let afterCacheModeOption = this.props.cacheModeReducer.savedCachedPluginsMap.has(pluginId) && this.props.cacheModeReducer.savedCachedPluginsMap.get(pluginId).cacheOptions.type
        let beforeStatusOfMinute = this.props.cacheModeReducer.cachedPluginsMap.has(pluginId) && this.props.cacheModeReducer.cachedPluginsMap.get(pluginId).cacheOptions.type === "MINUTE" 
            ? this.props.cacheModeReducer.cachedPluginsMap.get(pluginId).cacheOptions.minute
            : ""
        let afterStatusOfMinute = this.props.cacheModeReducer.savedCachedPluginsMap.has(pluginId) && this.props.cacheModeReducer.savedCachedPluginsMap.get(pluginId).cacheOptions.type === "MINUTE" 
            ? this.props.cacheModeReducer.savedCachedPluginsMap.get(pluginId).cacheOptions.minute
            : ""
        let isStatusChanged = beforeStatus !== afterStatus || beforeCacheModeOption !== afterCacheModeOption || beforeStatusOfMinute !== afterStatusOfMinute

        if (isStatusChanged) {
            return true
        }

        return false
    }

    /*
    * Gets cache mode's text style
    */
    getCacheModeTextStyle = (plugin) => {
        let pluginH = plugin.h
        let pluginW = plugin.w

        if (pluginH <= 2 && pluginW <= 5) {
            return {
                fontSize: "12px"
            }
        }

        return {}
    }

    /*
    * Gets cache mode's text style
    */
    getCacheModeIconStyle = (plugin) => {
        let pluginH = plugin.h
        let pluginW = plugin.w

        if (pluginH <= 2 && pluginW <= 5) {
            return {
                width: "30px",
                height: "30px"
            }
        }

        return {}
    }

    /*
    * Gets cache mode's text style
    */
    getCacheModeErrorIconStyle = (plugin) => {
        let pluginH = plugin.h
        let pluginW = plugin.w

        if (pluginH <= 2 && pluginW <= 5) {
            return {
                fontSize: "25px"
            }
        }

        return {}
    }

    /*
    * Gets cache mode's text style
    */
    getCacheModeErrorIconDivStyle = (plugin) => {
        let pluginH = plugin.h
        let pluginW = plugin.w

        if (pluginH <= 2 && pluginW <= 5) {
            return {
                left: "8px",
                bottom: "8px"
            }
        }

        return {}
    }

    render() {
        let isPluginInCacheMode = this.props.cachedPluginsMap.has(this.props.plugin.id) && this.props.cachedPluginsMap.get(this.props.plugin.id).status ? true : false
        return (
            <div className="cache-mode-main-container" style={{ display: this.props.isCacheMode ? "" : "none" }} onClick={isPluginInCacheMode ? () => this.removePluginToCacheMode() : () => this.addPluginToCacheMode()}>
                {this.props.cachedPluginsMap.has(this.props.plugin.id) && this.props.cachedPluginsMap.get(this.props.plugin.id).status ?
                    <div className="cache-mode-text">
                        <span className="cache-check-icon" style={this.getCacheModeIconStyle(this.props.plugin)}>
                            <AiOutlineCheck style={{fontSize: "35px"}}/>
                        </span>
                    </div>
                    : <div style={this.getCacheModeTextStyle(this.props.plugin)} className="cache-mode-text">
                        {i18n.t("Cache.AddCacheMode")}
                    </div>}
                {this.checkStatusIsChangeAvailable() 
                    ? <div className="unsaved-changes" style={this.getCacheModeErrorIconDivStyle(this.props.plugin)}>
                        <Tooltip title={i18n.t("Cache.UnsavedChanges")} placement="topLeft">
                            <BiErrorAlt style={this.getCacheModeErrorIconStyle(this.props.plugin)} className="unsaved-changes-icon"/> 
                        </Tooltip>
                    </div>
                    : null}
                
            </div>
        );
    }
}
  
  const mapStateToProps = (state) => {
    return {
      cacheModeReducer: state.CacheModeReducer,
    };
  };
  
  export default connect(mapStateToProps)(CacheMode);
