import { DEFAULT_PLUGINS, SET_IS_REQUESTED } from "./DefaultPluginsAction";

export default function DefaultPluginsReducer(
  state = {
    plugins: [],
    isRequested: false,
    pluginsMap: new Map()
  },
  action
) {
  switch (action.type) {
    case DEFAULT_PLUGINS:
      return {
        ...state,
        plugins: action.payload.plugins,
        pluginsMap: action.payload.pluginsMap
      };
    case SET_IS_REQUESTED:
      return {
        ...state,
        isRequested: action.payload
      };
    default:
      return state;
  }
}
