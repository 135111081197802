import React from "react";
import Search from "../Search/Search";
import Accordion from "../Accordion/Accordion";
import AccordionContent from "../Accordion/AccordionContent";
import PropTypes from "prop-types";
import BoxItem from "../BoxItem/BoxItem";
import { METADATA_BASE } from "../../../config";
import { abort, get } from "../../../Utils/WebService";
import Text from "../Text/Text";
import i18n from "../../../Utils/i18next";
import { sortByLanguage } from "../../../Utils/SortByLanguage";
import { deepCopy } from "../../../Utils/Global";
import { DatabaseOutlined, InfoCircleOutlined, LoadingOutlined, WarningOutlined } from "@ant-design/icons";
import { Pagination, Tooltip } from "antd";
import _ from "lodash";
import Cookies from "js-cookie";
import { showNotificationWithIcon } from "../../../Utils/Notification";
import Axios from "axios";

/**
 * This components consists of Tables & Columns list and search bar
 */
export default class SessionVariableTableAndColumnList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            searchTempData: [],
            searchTempDataSize: 0,
            currentPage: 1,
            pageSize: this.getPageSize(),
            searchCategory: {},
            loading: false,
            selectedDataSource: this.props.selectedDataSource,
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.updatePageSize);

        this.getTablesAndColumns();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.selectedDataSource?.id !== nextProps.selectedDataSource?.id) {
            this.setState({
                data: null,
                searchTempData: [],
                searchCategory: {},
                selectedDataSource: nextProps.selectedDataSource,
            }, this.getTablesAndColumns)
        }
    }

    /**
     * Calculates page size based on window height
     *
     * @returns 
     */
    getPageSize = () => {
        let height = Math.max(window.innerHeight * 0.9, 450) - 150;
        let pageSize = Math.round(height / 50);

        return pageSize > 5 ? pageSize : 5;
    }

    /**
     * Updates page size and data
     */
    updatePageSize = async () => {
        clearTimeout(this.resizeTimer);

        let pageSize = this.getPageSize();

        setTimeout(() => {
            let searchResult = this.search(this.state.searchValue, this.state.data, pageSize);

            this.setState({
                pageSize: pageSize,
                searchTempData: searchResult.data,
                searchTempDataSize: searchResult.size
            });
        }, 10)
    }

    /**
     * Fetches tables of the selected datasource
     * 
     * @returns 
     */
    getTablesAndColumns = () => {
        if (this.requestCancelToken) {
            this.requestCancelToken.cancel();
        }

        this.setState({
            data: null,
            searchTempData: [],
            searchCategory: {},
            loading: this.state.selectedDataSource ? true : false
        }, () => {
            if (this.state.selectedDataSource) {
                this.requestCancelToken = Axios.CancelToken.source();

                let dataSource = { ...this.state.selectedDataSource };
                let url = `${METADATA_BASE}/${Cookies.get("tenant")}/${dataSource.name}/${dataSource.schemaName}/tables-and-columns`

                const successFunc = (response) => {
                    let data = response?.data || [];

                    if (this.state.selectedDataSource?.id !== dataSource?.id) data = null

                    let searchResult = this.search(this.state.searchValue, data);

                    this.setState({
                        ...this.state,
                        data: data,
                        loading: false,
                        searchTempData: searchResult.data,
                        searchTempDataSize: searchResult.size
                    });
                }

                const errorFunc = (error) => {
                    showNotificationWithIcon(i18n.t("DataConnections.CouldNotFetch"), error.message, "error");

                    this.setState({
                        ...this.state,
                        data: null,
                        loading: false,
                        searchTempData: []
                    });
                }

                get(url, successFunc, errorFunc, false, false, undefined, true, this.requestCancelToken.token);
            }
        });
    }

    /**
     * Perapes array chunks based on page size
     * 
     * @param {*} data 
     * @param {*} pageSize 
     * @returns 
     */
    prepareDataForPagination = (data, pageSize = this.state.pageSize) => {
        let paginationData = [];

        for (let i = 0; i < data.length; i += pageSize) {
            paginationData.push(data.slice(i, i + pageSize));
        }

        return {
            data: paginationData,
            size: data.length
        };
    }

    /**
     * Default table and column search
     * 
     * @param {*} data 
     * @param {*} searchValue 
     */
    defaultSearch = (data, searchValue) => {
        let tempData = this.tableSearch(data, searchValue);

        if (tempData.length === 0) {
            tempData = this.columnSearch(data, searchValue);
        }

        return tempData;
    }

    /**
     * Table search
     * @param data tables for search
     * @param e event, value in input
     */
    tableSearch = (data, searchValue) => {
        let tempData = deepCopy(data);

        return tempData.filter(table => table.name.toLowerCase().includes(searchValue.toLowerCase()));
    };

    /**
     * Column search
     * @param tempTables tables for search
     * @param e event, value in input
     */
    columnSearch = (data, searchValue) => {
        let tempData = deepCopy(data);

        return tempData.filter(table => {
            table.columns = table.columns.filter(column => column.name.toLowerCase().includes(searchValue.toLowerCase()));

            return table.columns.length !== 0;
        });
    };

    /**
     * Table and Column Search
     * 
     * @param {*} searchValue 
     * @returns 
     */
    search = (searchValue = "", data = this.state.data, pageSize = this.state.pageSize) => {
        let tempData = deepCopy(data) || [];
        let result = deepCopy(data) || [];

        if (typeof searchValue === "string" && searchValue.length) {
            // search category is empty
            if (!this.state.searchCategory?.name) {
                // default search (both tables and columns)
                result = this.defaultSearch(tempData, searchValue);
            } else if (this.state.searchCategory?.name === "Table") {
                // search category is table
                result = this.tableSearch(tempData, searchValue);
            } else if (this.state.searchCategory?.name === "Column") {
                // search category is columns
                result = this.columnSearch(tempData, searchValue);
            }
        }

        result = this.sortTablesAndColumns(result);
        result = this.prepareDataForPagination(result, pageSize);

        return result;
    }

    /**
     * 
     * @param {*} e
     * 
     * Search bar change event
     */
    handleSearch = async e => {
        clearTimeout(this.inputTimer);

        let searchValue = e?.target?.value;

        this.inputTimer = setTimeout(() => {
            let searchResult = this.search(searchValue);

            this.setState({
                currentPage: 1,
                searchValue: searchValue,
                searchTempData: searchResult.data,
                searchTempDataSize: searchResult.size
            });
        }, 300);
    };

    /**
     * 
     * @param {*} item 
     * 
     * Sets selected search category
     */
    searchCategory = item => {
        this.setState({
            searchCategory: { ...item }
        });
    };

    /**
     * 
     * @param {*} event 
     * @returns 
     * 
     * Start dragging event
     */
    onDragStart = (event, data) => {
        let dragImage = event.target;
        let collapseIcon = event.target.querySelector(".collapse-icon");
        let extra = event.target.querySelector(".extra");
        let dropArea = document.getElementById("formula-editor-drop-area");

        dropArea.style.border = "dashed 2px #b7b7b7";
        dragImage.style.opacity = '0.4';

        if (collapseIcon) collapseIcon.style.visibility = "hidden";
        if (extra) extra.style.visibility = "hidden";

        event.dataTransfer.setData("text/plain", data);
        event.dataTransfer.setDragImage(dragImage, 0, 0);
    }

    /**
     * 
     * @param {*} event 
     * 
     * End dragging event
     */
    onDragEnd = (event) => {
        let dragImage = event.target;
        let collapseIcon = dragImage.querySelector(".collapse-icon");
        let extra = dragImage.querySelector(".extra");
        let dropArea = document.getElementById("formula-editor-drop-area");

        if (collapseIcon) collapseIcon.style.visibility = "visible";
        if (extra) extra.style.visibility = "visible";

        dropArea.style.border = "solid 2px transparent";
        dragImage.style.opacity = '1';
    }

    /**
     * 
     * @param {*} data 
     * 
     * Appends data to code
     */
    appendCode = (event, data) => {
        let code = this.props.code;

        code += data;

        this.props.updateCode(code);

        // Prevent the parent element's event
        if (event) {
            event.cancelBubble = true;
            event.stopPropagation();
        }
    }

    /**
     * Sorts tables and columns
     * 
     * @param {*} tables 
     * @returns 
     */
    sortTablesAndColumns = (tempTables) => {
        let data = deepCopy(tempTables);

        // Sort Columns
        let sortedTables = data.map((table) => {
            table.columns = table.columns.sort((a, b) => sortByLanguage(a.name, b.name));

            return table;
        });

        // Sort Tables
        sortedTables = sortedTables.sort((a, b) => sortByLanguage(a.name, b.name));

        return sortedTables;
    };

    /**
     * Updates current page
     * 
     * @param {*} page 
     */
    handlePageChange = page => {
        this.setState({
            currentPage: page
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePageSize);
    }

    render() {
        let data = this.state.searchTempData[this.state.currentPage - 1] || [];

        return (
            <div
                id="data-table-column-list"
                style={{
                    height: "100%",
                    overflowY: "auto",
                    overflowX: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    alignContent: "center",
                    justifyItems: "center",
                    padding: "0 2px"
                }}
            >
                <div
                    style={{
                        padding: "0 6px"
                    }}
                >
                    <Text
                        style={{
                            fontSize: "1rem",
                            margin: "16px 0 8px 0",
                            display: "block",
                            width: "100%",
                            textAlign: "center",
                            color: "#225f8f"
                        }}
                    >
                        {i18n.t("FormulaEditor.Titles.TablesAndColumns")}
                    </Text>
                    {
                        this.state.data instanceof Array && (
                            <Search
                                preventDrop={true}
                                paddingNone={true}
                                marginBottom={"8px"}
                                inputStyle={{ minWidth: "auto" }}
                                id={"searchData"}
                                width="100%"
                                searchBarVisible={true}
                                onChange={this.handleSearch}
                                searchItemList={[
                                    { name: "Table", translate: "Table", icon: "fas fa-table" },
                                    { name: "Column", translate: "Column", icon: "fas fa-columns" }
                                ]}
                                searchCategory={this.searchCategory}
                                selectedSearchCategory={this.state.searchCategory}
                            ></Search>
                        )
                    }
                </div>
                {
                    this.state.data instanceof Array ? (
                        data?.length > 0 ? (
                            <Accordion
                                style={{ height: "100%" }}
                                accordionStyle={{
                                    padding: "8px 6px",
                                }}
                                defaultActiveKey={this.props.defaultActiveKey}
                                accordionClass={"data-area-overflow"}
                                titleClass={"dataAccordionHeader"}
                                headerStyle={{
                                    background: "#fff",
                                    borderBottom: "1px solid #dadada"
                                }}
                                collapseStyle={{ background: "#fff" }}
                            >
                                {
                                    data.map(table => {
                                        let schema = table.schemaName;
                                        let name = table.name;
                                        let key = schema + "." + name;

                                        return (
                                            <AccordionContent
                                                id={key}
                                                key={key}
                                                eventKey={key}
                                                draggable="true"
                                                onDragStart={event => this.onDragStart(event, name)}
                                                onDragEnd={event => this.onDragEnd(event)}
                                                onDblClick={(event) => this.appendCode(event, name)}
                                                title={name}
                                                titleClass={"data-table-title"}
                                                headerStyle={{
                                                    background: "#fff",
                                                    borderBottom: "1px solid #dadada",
                                                    cursor: "grab",
                                                    transition: "none"
                                                }}
                                                collapseStyle={{ background: "#fff" }}
                                                icon={
                                                    <Tooltip
                                                        arrowPointAtCenter
                                                        title={key}
                                                        placement="right"
                                                    >
                                                        <InfoCircleOutlined
                                                            className="collapse-icon"
                                                            style={{
                                                                cursor: "default",
                                                                margin: 0
                                                            }}
                                                        />
                                                    </Tooltip>
                                                }
                                            >
                                                {
                                                    table.columns?.length
                                                        ? table.columns.map(column => {
                                                            let name = column.name;
                                                            let key = schema + "." + table.name + "." + name;

                                                            return (
                                                                <div
                                                                    id={key}
                                                                    key={key}
                                                                    draggable="true"
                                                                    onDragStart={event => this.onDragStart(event, name)}
                                                                    onDragEnd={event => this.onDragEnd(event)}
                                                                    onDoubleClick={(event) => this.appendCode(event, name)}
                                                                    type="column"
                                                                    style={{ position: "relative" }}
                                                                >
                                                                    <BoxItem
                                                                        tooltipTitle={name}
                                                                        title={name}
                                                                        divClassName={"noStyle"}
                                                                        style={{ cursor: "grab", position: "relative" }}
                                                                    />
                                                                </div>
                                                            )
                                                        }) : (
                                                            <Text className="empty-area-text">
                                                                <WarningOutlined className="icon" style={{ fontSize: 24 }} />
                                                                <span style={{ marginTop: "8px" }}> {i18n.t("SessionVariables.NoData")} </span>
                                                            </Text>
                                                        )
                                                }
                                            </AccordionContent>
                                        );
                                    })
                                }
                            </Accordion>
                        ) : (
                            <Text className="empty-area-text">
                                <WarningOutlined className="icon" />
                                <span> {i18n.t("SessionVariables.NoData")} </span>
                            </Text>
                        )
                    ) : (
                        <Text className="empty-area-text">
                            {
                                this.state.selectedDataSource
                                    ? this.state.loading
                                        ? <LoadingOutlined className="icon" />
                                        : <WarningOutlined className="icon" />
                                    : <DatabaseOutlined className="icon"/>
                            }

                            <span style={{ marginTop: "16px" }}>
                                {
                                    this.state.selectedDataSource
                                        ? this.state.loading
                                            ? i18n.t("Loading") + "..."
                                            : i18n.t("SessionVariables.NoData")
                                        : i18n.t("SessionVariables.SelectDataSource")
                                }
                            </span>
                        </Text>
                    )
                }
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        padding: "8px 6px 0 6px"
                    }}
                >
                    <Pagination
                        simple
                        showSizeChanger
                        hideOnSinglePage
                        defaultCurrent={1}
                        size="small"
                        total={this.state.searchTempDataSize}
                        current={this.state.currentPage}
                        pageSize={this.state.pageSize}
                        onChange={this.handlePageChange}
                        style={{ 
                            fontSize: 12,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    />
                </div>
            </div>
        )
    }
}
