import React, { Component } from "react";
import { Row, Col } from "antd";

import DataSourceList from "./DataSourceList.jsx";

class DataSourceContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSources: []
    };
  }

  onResult = result => {
    // this.props.history.push("/model");
    this.props.history.push({
      pathname: '/model',
      search: `?dataSource=${result.dataSource.name}`,
      state: { dataSource: result.dataSource}
    })
  }

  render() {
    return (
      <div>
        <Row>
          <Col span={24}>
            <DataSourceList data={this.state.dataSources} onResult={this.onResult}/>
          </Col>
        </Row>
      </div>
    );
  }
}

export default DataSourceContent;
