import React, { Component } from "react";
import $ from "jquery";
import ScatterChartConfiguration from "./ScatterChartConfiguration";
import { rmvpp } from "../../RenderJs/rmvpp";
import i18n from "../../../../Utils/i18next";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join"
import {
  onComponentWillMount,
  onComponentWillReceiveProps,
  getColumnMapping,
  getTextWidth,
  getCurrentDateTime
} from "../common";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import {
  renderConditionalFormatting,
  renderConfig,
  renderData,
  renderNavigation
} from "../PluginsCommonComponents";
import ScatterChartData from "./ScatterChartData";
import { createTrigger } from "../../../Interaction/CreateTrigger";
import { renderContent } from "../renderContent";
import NavigationContent from "../../../Navigation/NavigationContent";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";
import * as am5 from '@amcharts/amcharts5';
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import * as am5xy from "@amcharts/amcharts5/xy"
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { InsightsConfig } from "../../RenderJs/config";
import { calculatePluginInlineHeight } from "../../../DrillDown/PluginHeightWithDrilldown";
import { isNull } from "lodash";
import { aggregatableDataTypes, dateDataTypes } from "../../DataComponents/DataConfigure";
import ConditionalFormatting from "../../../ConditionalFormatting/ConditionalFormatting";
import { store } from "../../../..";
import { findColumnMapHasAnyColumn } from "../../../ConditionalFormatting/RenderConditionalListName";
import { bigNumberPrefixes, decimalSeperators, longMonths, shortMonths, thousandSeperators } from "../../../GeneralComponents/PublicSortItems";
import { vispeahenLogo } from "../Table/TablePdfContent";
import { format } from "../format";
import { compare } from "../../../ConditionalFormatting/ConditionalFormattingCommon";
import { deepCopy } from "../../../../Utils/Global";
import { setPluginsDrillDowns } from "../../../DrillDown/DrillDownAction";
import { changePluginLoaderVisibility } from "../../../GeneralComponents/PluginLoader/PluginLoaderAction";
import { loadingScreen } from "../../../../Utils/Global";
import { getFormattedValue } from "../format";

const data = JSON.parse(
  '[{"measureX":712553810,"measureY":26641311,"group":"Şubat"},{"measureX":773510096,"measureY":27535217,"group":"Aralık"},{"measureX":783056576,"measureY":29217440,"group":"Kasım"},{"measureX":790336656,"measureY":28051103,"group":"Ocak"},{"measureX":799425910,"measureY":29395781,"group":"Mart"},{"measureX":897110904,"measureY":34282854,"group":"Nisan"},{"measureX":1088652428,"measureY":38247130,"group":"Mayıs"},{"measureX":1139291828,"measureY":38413038,"group":"Ekim"},{"measureX":1156020298,"measureY":45743885,"group":"Haziran"},{"measureX":1353454028,"measureY":42060547,"group":"Eylül"},{"measureX":1358589656,"measureY":48334798,"group":"Temmuz"},{"measureX":1526820190,"measureY":47300238,"group":"Ağustos"}]'
);
const config = {
  "paletteColours": [
    "#67b7dc",
    "#6771dc",
    "#a367dc",
    "#dc67ce",
    "#dc6788",
    "#dc8c67"
  ],
  "backgroundColor": "rgb(255,255,255)",
  "colours": "Flat-UI",
  "height": 372.5,
  "width": 400,
  "lr": false,
  "maxPointSize": "6",
  "minPointSize": "2",
  "showHideButton": false,
  "title": "Title not set",
  "titleAlign": "center",
  "titleFontStyle": false,
  "titleFontWeight": false,
  "titleFontSize": 15,
  "titleFont": "Verdana",
  "titleColour": "black",
  "titleTextDecor": false,
  "changedTitleFontSize": 15,
  "scrollbarX": false,
  "scrollbarXAlignment": "top",
  "xAxisAlignment": "bottom",
  "xTitleFontStyle": false,
  "xTitleFontWeight": false,
  "xTitleFontSize": "12",
  "xTitleTextDecor": false,
  "scrollbarY": false,
  "scrollbarYAlignment": "right",
  "yAxisAlignment": "left",
  "yTitleFontStyle": false,
  "yTitleFontWeight": false,
  "yTitleFontSize": "12",
  "yTitleTextDecor": false,
  "legend": true,
  "condFormat": true,
  "animationLabel": true,
  "animationTitleFontStyle": false,
  "animationTitleFontWeight": false,
  "animationTitleFontSize": 32,
  "animationTitleFontDecor": false,
  "animationTitleOversizeBehaviour": "fit",
  "duration": 1,
  "keyForTitleSize": null,
  "showTargets": false,
  "fillTargets": "area",
  "targets": [{
    color: "#FF0000",
    axis: "y"
  }]
}

const columnMap = {
  "measureX": {
    "name": "bagajtoplam",
    "aliasName": "bagajtoplam",
    "displayName": "bagajtoplam",
    "description": null,
    "nullable": true,
    "dataType": "double",
    "aggregatable": true,
    "aggrRule": "sum(`ucus`.`bagajtoplam`)",
    "hidden": false,
    "columnType": null,
    "isEnable": null,
    "duplicated": false,
    "doubleColumn": false,
    "windowFunction": false,
    "tableAliasName": "ucus",
    "dataSourceKey": "jojyew_ycywupwt.public",
    "tableDisplayName": "ucus",
    "uniqeColumnId": "fd58063-6a32-b5c8-3a5e-b25638f5d6a",
    "locationFieldName": "measureX",
    "orderBy": null,
    "Measure": "sum(`ucus`.`bagajtoplam`)",
    "Locale": "EN",
    "DataType": "double",
    "Name": "bagajtoplam",
    "Code": "\"ucus\".\"bagajtoplam\"",
    "Type": "Column",
    "DataFormat": ".0f"
  },
  "measureY": {
    "name": "yolcutoplam",
    "aliasName": "yolcutoplam",
    "displayName": "yolcutoplam",
    "description": null,
    "nullable": false,
    "dataType": "double",
    "aggregatable": true,
    "aggrRule": "sum(`ucus`.`yolcutoplam`)",
    "hidden": false,
    "columnType": null,
    "isEnable": null,
    "duplicated": false,
    "doubleColumn": false,
    "windowFunction": false,
    "tableAliasName": "ucus",
    "dataSourceKey": "jojyew_ycywupwt.public",
    "tableDisplayName": "ucus",
    "uniqeColumnId": "43cc2c-221-3f5d-c324-28f41414653",
    "locationFieldName": "measureY",
    "orderBy": null,
    "Measure": "sum(`ucus`.`yolcutoplam`)",
    "Locale": "EN",
    "DataType": "double",
    "Name": "yolcutoplam",
    "Code": "\"ucus\".\"yolcutoplam\"",
    "Type": "Column",
    "DataFormat": ".0f"
  },
  "group": {
    "name": "ay_adi",
    "aliasName": "ay_adi",
    "displayName": "ay_adi",
    "description": null,
    "nullable": true,
    "dataType": "varchar",
    "aggregatable": false,
    "aggrRule": null,
    "hidden": false,
    "columnType": null,
    "isEnable": null,
    "duplicated": true,
    "doubleColumn": false,
    "windowFunction": false,
    "tableAliasName": "ucus",
    "dataSourceKey": "jojyew_ycywupwt.public",
    "tableDisplayName": "ucus",
    "uniqeColumnId": "4da2add-33fa-ca1a-b50f-cbfea53bc4",
    "locationFieldName": "group",
    "orderBy": null,
    "Measure": "none",
    "Locale": "EN",
    "DataType": "varchar",
    "Name": "ay_adi",
    "Code": "\"ucus\".\"ay_adi\"",
    "Type": "Column",
    "DataFormat": "%s"
  },
  "varyColour": {
    "name": "Vary Colour",
    "type": "dim",
    "desc": "Scatter Chart vary by colour desc.",
    "data": [],
    "minimumColumnSize": 0
  },
  "varySize": {
    "name": "Vary Size",
    "type": "fact",
    "desc": "Scatter Chart vary size desc.",
    "data": [],
    "minimumColumnSize": 0
  },
  "animation": {
    "name": "Plugins.scatter-chart.ColumnMap.Animation.Name",
    "type": "dim",
    "desc": "Plugins.scatter-chart.ColumnMap.Animation.Desc",
    "data": [],
    "minimumColumnSize": 0
  },
  "hidden": []
}

const condFormats = [];
const filters = [];
const pluginName = "scatter-chart";

const description =
  "Scatter chart visualisation, displaying points on a plot space of two measures against one another. Can be used as a bubble chart as well by setting a third measure to be the size variable. Also can calculate a linear regression and R-squared value indicated correlation.";

const configurationParameters = [
  {
    targetProperty: "fillTargets",
    label: "FillTargets",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "area"
    },
    desc: "desc162"
  },
  {
    targetProperty: "showTargets",
    label: "ShowTargets",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc162"
  },
  {
    targetProperty: "targets",
    label: "Targets",
    inputType: "textbox",
    inputOptions: {
      defaultValue: [{
        color: "#FF0000"
      }]
    },
    desc: "desc162"
  },
  {
    targetProperty: "width",
    label: "Width",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 400
    },
    desc: "desc89"
  },
  {
    targetProperty: "height",
    label: "Height",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 400
    },
    desc: "desc90"
  },
  {
    targetProperty: "minPointSize",
    label: "MinPointSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 2
    },
    desc: "MinPointSize"
  },
  {
    targetProperty: "maxPointSize",
    label: "MaxPointSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 6
    },
    desc: "MaxPointSize"
  },
  {
    targetProperty: "legend",
    label: "Legend",
    inputType: "checkbox",
    inputOptions: { defaultValue: true },
    desc: "Legend"
  },
  {
    targetProperty: "condFormat",
    label: "ShowConditionalFormatting",
    inputType: "checkbox",
    inputOptions: { defaultValue: true },
    desc: "desc118"
  },
  {
    targetProperty: "showHideButton",
    label: "Show Hide Button",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc230"
  },
  {
    targetProperty: "lr",
    label: "LinearRegression",
    inputType: "checkbox",
    inputOptions: { defaultValue: true },
    desc: "desc117"
  },
  {
    targetProperty: "colours",
    label: "Colours",
    inputType: "palette",
    inputOptions: { defaultValue: "Flat-UI" },
    desc: "desc133"
  },
  {
    targetProperty: "toggleCriteria",
    label: "ToggleCriteria",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc59"
  },
  {
    targetProperty: "title",
    label: "Title",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc94"
  },
  {
    targetProperty: "summary",
    label: "Summary",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc61"
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    inputType: "textbox",
    inputOptions: { defaultValue: "rgb(255,255,255)" },
    desc: "desc62"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  },
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 15
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
  {
    targetProperty: "xTitleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: { defaultValue: "12" },
    desc: "desc104"
  },
  {
    targetProperty: "xTitleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "xTitleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "xTitleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "yTitleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: { defaultValue: "12" },
    desc: "desc104"
  },
  {
    targetProperty: "yTitleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "yTitleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "yTitleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "animationTitleOversizeBehaviour",
    label: "AnimationOverSizedBehaviour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "fit"
    },
    desc: "AnimationOverSizedBehaviour"
  },
  {
    targetProperty: "animationLabel",
    label: "AnimationLabel",
    inputType: "checkbox",
    inputOptions: { defaultValue: true },
    desc: "AnimationLabel"
  },
  {
    targetProperty: "scrollbarX",
    label: "ScrollbarX",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "ScrollbarX"
  },
  {
    targetProperty: "scrollbarY",
    label: "ScrollbarY",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "ScrollbarY"
  },
  {
    targetProperty: "xAxisAlignment",
    label: "xAxisAlignment",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "bottom"
    },
    desc: "XAxisAlignment"
  },
  {
    targetProperty: "yAxisAlignment",
    label: "yAxisAlignment",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "left"
    },
    desc: "YAxisAlignment"
  },
  {
    targetProperty: "duration",
    label: "duration",
    inputType: "textbox",
    inputOptions: {
      defaultValue: 1
    },
    desc: "duration"
  }
];

const actions = [
  {
    trigger: "pointClick",
    type: "click",
    name: "Tıklama - Nokta",
    output: ["group", "varyColour", "animation"],
    description: "PointClickDesc"
  }
];

const reactions = [
  {
    id: "filter",
    name: "Filtre",
    description: "desc87",
    type: "general"
  }
];

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

const pluginConditionalFormatOptions = {
  backgroundColor: {
    title: i18n.t("Dashboard.ConditionalFormatting.BackgroundColor"),
    type: "COLOR",
    defaultValue: "#fffffe"
  }
};

const conditionalFormatColumnMap = new Set(["measureX", "measureY", "group", "varyColour", "varySize", "animation"]);
const conditionalFormatTargetMap = new Set()

/**
 * renders ScatterChart plugin in Vispeahen V3
 */
export default class ScatterChart extends Component {
  constructor(props) {
    super(props);
    this.rerenderProcessStarted = false;
    this.callBackObject = {};

    this.state = {
      defaultConditionalFormatColumn: {
        TargetName: "AllColumns",
        TargetID: "columns",
        displayName: "AllColumns"
      },
      isNecessaryAllColumns: true,
      isLockedTargetValue: false,
    };

    this.drillDownMustBeFoundArea = ["animation", "group", "varyColour"];
  }

  /**
   * Calculates the height of the plugin based on the grid's row height
   * 
   * @param {*} plugin 
   * @param {*} settings 
   * @returns 
   */
  calculatePluginHeight = (plugin, settings) => {
    let pluginHeight = settings.grid.rowHeight * plugin.h + 32; // multiply plugin.height and grid's row height
    let pluginTitleContainer = $("#title-" + plugin.id);
    let pluginContainerBorder = -2;
    let pluginMinHeightDifference = 30;
    let maxHeight =
      pluginHeight -
      (pluginMinHeightDifference +
        pluginTitleContainer.outerHeight() +
        parseInt(pluginTitleContainer.css("margin-bottom")) +
        pluginContainerBorder);
    return maxHeight;
  };

  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({})

    return tmpCallBackObject;
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );
  }

  getNavigationComponent = props => {
    return (
      <NavigationContent
        navigations={this.props.navigations}
        setNavigations={this.props.updatePlugin}
        plugin={this.props.plugin}
        dashboardInformation={this.props.dashboardInformation}
      />
    );
  };

  getConfigComponent = props => {
    if (props.config) {
      return (
        <ScatterChartConfiguration
          config={{ ...props.config }}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
          currentAppliedConfig={this.props.currentAppliedConfig}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }

    if (!columnMap["animation"]) {
      columnMap["animation"] = {
        desc: `Plugins.${props.plugin.key}.ColumnMap.Animation.Desc`,
        type: "dim",
        name: `Plugins.${props.plugin.key}.ColumnMap.Animation.Name`,
        data: [],
        minimumColumnSize: 0,
      }
    }

    return (
      <ScatterChartData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        didNotJoinedTables={checkTableJoins(this.props.join, this.props.plugin.columnMap, this.props.refreshedPluginId, this.props.plugin.id, true)}
        setInteractions={this.props.setInteractions}
        interactions={this.props.interactions}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={props.changeDoesPluginHasNotJoinedTable}
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        refreshPlugin={props.refreshPlugin}
        navigations={props.navigations}
        plugin={props.plugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  /*
  * Converts columnMap with data object for conditional format component
  */
  convertColumnMapForConditionalFormat = (cmMap) => {
    let newColumnMap = []
    let fields = ["measureX", "measureY", "group", "varyColour", "varySize", "animation"]

    for (let field of fields) {
      if (cmMap[field]?.data?.length > 0) {
        newColumnMap.push(cmMap[field].data[0])
      }
    }

    let reduxState = store.getState()
    let group = cmMap.group?.data?.length > 0 ? cmMap.group.data[0] : null

    if (reduxState.DrillDownReducer.drillDowns.has(this.props.plugin.id) && group) {
      let drillDownsReducer = reduxState.DrillDownReducer.drillDowns.get(this.props.plugin.id).drillDownColumnsForParentColumns;
      let drillCols = drillDownsReducer.has(group.uniqeColumnId)
        ? drillDownsReducer.get(group.uniqeColumnId)
        : []

      if (drillCols) {
        newColumnMap = newColumnMap.concat(drillCols)
      }
    }

    return newColumnMap
  }

  getConditionalFormattingComponent = props => {
    let columnMap = props.plugin.columnMap;

    return (
      <ConditionalFormatting
        pluginConditionalFormatOptions={pluginConditionalFormatOptions}
        conditionalFormatColumnMap={conditionalFormatColumnMap}
        pluginId={props.plugin.id}
        conditionalFormatTargetMap={conditionalFormatTargetMap}
        conditionalFormats={props.plugin.conditionalFormats}
        columnMap={columnMap}
        updateConditionalFormat={props.updatePlugin}
        isLockedTargetValue={true}
        isTargetColumnAllColumns={true}
        columnMapWithDrills={this.convertColumnMapForConditionalFormat(columnMap)}
      />
    );
  };

  /**
   * To set column map this plugin
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      measureX: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.MeasureX.Name"),
        type: "fact",
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.MeasureX.Desc"),
        required: true,
        data: [],
        minimumColumnSize: 1,
      },
      measureY: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.MeasureY.Name"),
        type: "fact",
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.MeasureY.Desc"),
        required: true,
        data: [],
        minimumColumnSize: 1,
      },
      group: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Group.Name"),
        type: "dim",
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Group.Desc"),
        required: true,
        data: [],
        minimumColumnSize: 1,
      },
      varyColour: {
        name: i18n.t(
          "Plugins." + tempPlugin.key + ".ColumnMap.VaryColour.Name"
        ),
        type: "dim",
        desc: i18n.t(
          "Plugins." + tempPlugin.key + ".ColumnMap.VaryColour.Desc"
        ),
        data: [],
        minimumColumnSize: 0,
      },
      varySize: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.VarySize.Name"),
        type: "fact",
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.VarySize.Desc"),
        data: [],
        minimumColumnSize: 0,
      },
      animation: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Animation.Name"),
        type: "dim",
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Animation.Desc"),
        data: [],
        minimumColumnSize: 0,
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  /**
   * The root element of the chart
   */
  root = undefined;

  /**
   * The contrast color of the plugin background
   */
  contrastColor = undefined;

  /**
   * Sets plugin color theme
   * 
   * @param {*} config 
   */
  setTheme = config => {
    let chartTheme = am5themes_Animated.new(this.root);
    let paletteColors = Array.isArray(config.colours) ? config.colours : InsightsConfig.Palettes[config.colours];
    let colorSet = paletteColors.map(color => am5.color(color));
    let grip = {
      icon: undefined,
      width: 14,
      height: 14
    }
    let buttonBackground = {
      fill: am5.color(config.backgroundColor),
      stroke: am5.color(this.contrastColor),
      strokeOpacity: 0.5
    }

    chartTheme.rule("ColorSet").setAll({
      colors: colorSet,
      step: 1,
    });

    chartTheme.rule("Label").setAll({
      fontSize: 12,
      fill: am5.color(this.contrastColor),
      oversizedBehavior: "truncate"
    });

    chartTheme.rule("Grid").setAll({
      stroke: am5.color(this.contrastColor),
      strokeOpacity: 0.2
    });

    chartTheme.rule("Button").setup = (button) => {
      button.get("background").setAll(buttonBackground);

      button.get("background").states.create("down", {
        fill: am5.color(config.backgroundColor),
        strokeOpacity: 1
      });

      button.get("background").states.create("active", {
        fill: am5.color(config.backgroundColor),
        strokeOpacity: 0.5
      });

      button.get("background").states.create("hover", {
        fill: am5.color(config.backgroundColor),
        strokeOpacity: 0.8
      });
    };

    chartTheme.rule("Graphics", ["button", "icon"]).setAll({
      fill: am5.color(this.contrastColor),
      stroke: am5.color(this.contrastColor),
      x: am5.p50,
      centerX: am5.p50,
      y: am5.p50,
      centerY: am5.p50
    });

    chartTheme.rule("Button", ["play"]).setAll({
      width: 24,
      height: 24,
      icon: am5.Graphics.new(this.root, {
        themeTags: ["icon"],
        fill: am5.color(this.contrastColor),
        stroke: am5.color(this.contrastColor)
      })
    });

    chartTheme.rule("Button", ["zoom"]).setAll({
      width: 32,
      height: 32,
      exportable: false,
      icon: am5.Graphics.new(this.root, {
        themeTags: ["icon"],
        fill: am5.color(this.contrastColor),
        stroke: am5.color(this.contrastColor)
      })
    });


    chartTheme.rule("Scrollbar").setup = (scrollbar) => {
      scrollbar.setAll({
        minWidth: 5,
        minHeight: 5,
        exportable: false
      });

      scrollbar.get("background").setAll({
        fill: am5.color(this.contrastColor),
        fillOpacity: 0.1
      });

      scrollbar.thumb.setAll({
        fill: am5.color(this.contrastColor),
        fillOpacity: 0.2
      });

      scrollbar.startGrip.setAll(grip);
      scrollbar.endGrip.setAll(grip);

      scrollbar.startGrip.get("background").setAll(buttonBackground);
      scrollbar.endGrip.get("background").setAll(buttonBackground);
    };

    this.root.setThemes([chartTheme]);
  }

  /**
   * Creates a date or value axis item with given column and axes item (chart.xAxes or chart.yAxes)
   * 
   * @param {*} dataType 
   * @param {*} axes 
   * @returns 
   */
  createAxis = (axes, renderer, column, opposite = false) => {
    let axis;
    let dataType = column.DataType;

    let commonAxisProperties = {
      extraMax: 0,
      extraMin: 0,
      renderer: renderer.new(this.root, {
        opposite: opposite
      }),
      tooltip: am5.Tooltip.new(this.root, {
        fontSize: "12px"
      })
    };

    if (dateDataTypes.has(dataType)) {
      axis = axes.push(am5xy.DateAxis.new(this.root, {
        ...commonAxisProperties,
        baseInterval: { timeUnit: "day", count: 1 },
        valueField: column.locationFieldName
      }));

      // Date axis formats
      let dateFormat = column.DataFormat ? column.DataFormat : "%d/%m/%Y %H:%M:%S";
      let seperators = ["-", ":", "/", ".", "_", " ", ""]

      let selectedDateFormat = "%d/%m/%Y";
      let selectedDateSeperator = "/"

      let selectedTimeFormat = "%H:%M:%S";
      let selectedTimeSeperator = ":"

      let defaultDateFormats = [
        "%d/%m/%Y",
        "%m/%d/%Y",
        "%Y/%m/%d",
        "%Y/%d/%m",
      ];
      let defaultTimeFormats = [
        "%H:%M:%S",
        "%S:%M:%H",
      ];

      // Get selected date format
      for (let format of defaultDateFormats) {
        if (dateFormat.includes(format)) {
          selectedDateFormat = format;

          break;
        }

        for (let seperator of seperators) {
          let result = format.replaceAll("/", seperator);

          if (dateFormat.includes(result)) {
            selectedDateFormat = result;
            selectedDateSeperator = seperator;

            break;
          }
        }
      }

      // Get selected time format
      for (let format of defaultTimeFormats) {
        if (dateFormat.includes(format)) {
          selectedTimeFormat = format;

          break;
        }

        for (let seperator of seperators) {
          let result = format.replaceAll(":", seperator);

          if (dateFormat.includes(result)) {
            selectedTimeFormat = result;
            selectedTimeSeperator = seperator;

            break;
          }
        }
      }

      let seperatorBetweenDateAndTime = dateFormat;
      seperatorBetweenDateAndTime = seperatorBetweenDateAndTime.replace(selectedDateFormat, "");
      seperatorBetweenDateAndTime = seperatorBetweenDateAndTime.replace(selectedTimeFormat, "");

      let firstFormatPart = (dateFormat.includes(selectedDateFormat + seperatorBetweenDateAndTime)
        ? selectedDateFormat
        : selectedTimeFormat
      );

      let formatUnits = {
        day: "dd",
        month: "MM",
        year: "yyyy",
        hour: "HH",
        minute: "mm",
        second: "ss"
      };

      let parsedDateFormat = dateFormat.split(/[_\-./: ]/);
      let dateIndex = {
        day: parsedDateFormat.indexOf("%d"),
        month: parsedDateFormat.indexOf("%m"),
        year: parsedDateFormat.indexOf("%Y"),
        hour: parsedDateFormat.indexOf("%H"),
        minute: parsedDateFormat.indexOf("%M"),
        second: parsedDateFormat.indexOf("%S")
      };

      const getPartialFormat = (keys = [], seperator = "") => {
        let parts = [];

        for (let key of Object.keys(dateIndex)) {
          if (keys.includes(key) && dateIndex[key] !== -1) {
            parts.push({
              format: formatUnits[key],
              index: dateIndex[key]
            })
          }
        }

        parts = parts.sort((p1, p2) => p1.index - p2.index);

        let format = parts.map(part => part.format).join(seperator);

        return format;
      }

      let year = getPartialFormat(["year"]);
      let month = getPartialFormat(["month", "year"], selectedDateSeperator);
      let week = getPartialFormat(["day", "month", "year"], selectedDateSeperator);
      let day = getPartialFormat(["day", "month", "year"], selectedDateSeperator);
      let hour = (
        firstFormatPart === selectedDateFormat
          ? day + seperatorBetweenDateAndTime + getPartialFormat(["hour", "minute"], selectedTimeSeperator)
          : getPartialFormat(["hour", "minute"], selectedTimeSeperator) + seperatorBetweenDateAndTime + day
      );
      let minute = (
        firstFormatPart === selectedDateFormat
          ? day + seperatorBetweenDateAndTime + getPartialFormat(["hour", "minute"], selectedTimeSeperator)
          : getPartialFormat(["hour", "minute"], selectedTimeSeperator) + seperatorBetweenDateAndTime + day
      );
      let second = (
        firstFormatPart === selectedDateFormat
          ? day + seperatorBetweenDateAndTime + getPartialFormat(["hour", "minute", "second"], selectedTimeSeperator)
          : getPartialFormat(["hour", "minute", "second"], selectedTimeSeperator) + seperatorBetweenDateAndTime + day
      );

      // Date axis formats
      axis.get("dateFormats")["second"] = second;
      axis.get("dateFormats")["minute"] = minute;
      axis.get("dateFormats")["hour"] = hour;
      axis.get("dateFormats")["day"] = day;
      axis.get("dateFormats")["week"] = week;
      axis.get("dateFormats")["month"] = month;
      axis.get("dateFormats")["year"] = year;

      // Date axis periodic formats
      axis.get("periodChangeDateFormats")["second"] = second;
      axis.get("periodChangeDateFormats")["minute"] = minute;
      axis.get("periodChangeDateFormats")["hour"] = hour;
      axis.get("periodChangeDateFormats")["year"] = year;
      axis.get("periodChangeDateFormats")["month"] = month;
      axis.get("periodChangeDateFormats")["week"] = week;
      axis.get("periodChangeDateFormats")["day"] = day;

      axis.set("dateFormattter", am5.DateFormatter.new(this.root, {
        "dateFormat": this.getFormat(column).format
      }));

    } else if (aggregatableDataTypes.has(dataType)) {
      let format = this.getFormat(column).format;
      let precision = format.includes(".") ? format.split(".")[0].length : 0

      axis = axes.push(am5xy.ValueAxis.new(this.root, {
        ...commonAxisProperties,
        valueField: column.locationFieldName,
        tooltipNumberFormat: format,
        numberFormat: format,
        extraTooltipPrecision: precision,
        maxPrecision: precision
      }));
    } else {
      axis = axes.push(am5xy.CategoryAxis.new(this.root, {
        ...commonAxisProperties,
        categoryField: column.locationFieldName
      }));
    }

    let axisRenderer = axis.get("renderer");

    axisRenderer.labels.template.setAll({
      fontSize: "12px"
    });

    let axisToolltip = axis.get("tooltip");

    axisToolltip.label.setAll({
      fontSize: "12px"
    });
    axisToolltip.get("background").setAll({
      strokeWidth: 1,
      strokeOpacity: 0.2,
      cornerRadius: 3,
      fillOpacity: 0.8
    })

    return axis;
  }

  /**
   * Set animation data
   * 
   * @param {*} columnMap 
   * @param {*} data 
   * @returns 
   */
  setAnimationData = (columnMap, data) => {
    if (Object.keys(data[0]).includes("animation")) {
      let animateData = {};

      for (let d of data) {
        if (!animateData[d.animation]) {
          animateData[d.animation] = [];
        }

        animateData[d.animation].push(d);
      }

      return animateData;
    }

    return data;
  }

  /**
   * Creates a XY chart and set zoom out button
   * 
   * @param {*} container 
   */
  setChart = (chartContainer) => {
    let chart = chartContainer.children.push(am5xy.XYChart.new(this.root, {
      panX: true,
      panY: true,
      wheelY: "zoomXY",
      pinchZoomX: true,
      pinchZoomY: true,
      layout: this.root.verticalLayout,
    }));

    chart.zoomOutButton.set("icon", am5.Graphics.new(this.root, {
      themeTags: ["icon"],
      fill: am5.color(this.contrastColor),
      stroke: am5.color(this.contrastColor)
    }));

    chart.zoomOutButton.get("background").states.create("down", {
      fill: am5.color(config.backgroundColor),
      strokeOpacity: 1
    });

    chart.zoomOutButton.get("background").states.create("active", {
      fill: am5.color(config.backgroundColor),
      strokeOpacity: 0.5
    });

    chart.zoomOutButton.events.on("pointerover", function (ev) {
      document.body.style.cursor = "pointer";
    });

    chart.zoomOutButton.events.on("pointerout", function (ev) {
      document.body.style.cursor = "default";
    });

    return chart;
  }

  /**
   * Sets chart's x and y scrollbars
   * 
   * @param {*} chart 
   */
  setScrollbars = (chart, config) => {
    if (this.props.isDashboardSliderActive) return;
    
    if (config.scrollbarX) {
      let scrollbarX = am5.Scrollbar.new(this.root, {
        orientation: "horizontal"
      });

      chart.set("scrollbarX", scrollbarX);

      if (config.xAxisAlignment === "top") {
        chart.bottomAxesContainer.children.push(scrollbarX);
      } else if (config.xAxisAlignment === "bottom") {
        chart.topAxesContainer.children.unshift(scrollbarX);
      }
    }

    if (config.scrollbarY) {
      let scrollbarY = am5.Scrollbar.new(this.root, {
        orientation: "vertical"
      });

      chart.set("scrollbarY", scrollbarY);

      if (config.yAxisAlignment === "left") {
        chart.rightAxesContainer.children.push(scrollbarY);
      } else if (config.yAxisAlignment === "right") {
        chart.leftAxesContainer.children.unshift(scrollbarY);
      }
    }
  }

  /**
   * Converts conditional format data to list of objects
   * 
   * @param {*} condFormats 
   * @param {*} columnMap 
   * @returns 
   */
  convertFormatConditionalFormatting = (condFormats, columnMap) => {
    let condFormatList = [];
    let columnsMap = new Map();

    for (let field of Object.keys(columnMap)) {
      if (Array.isArray(columnMap[field])) {
        for (let column of columnMap[field]) {
          columnsMap.set(column.uniqeColumnId, column);
        }
      } else {
        columnsMap.set(columnMap[field].uniqeColumnId, columnMap[field]);
      }
    }

    condFormats.map(condItem => {
      condItem.targetColumns.map(targetColumn => {
        let conditionalFormat = {};
        let controlTargetIsAllColumns = targetColumn.TargetName === "AllColumns" ? true : false;

        conditionalFormat.RightRule = condItem.rule.rightRule.rule;
        conditionalFormat.LeftRule = condItem.rule.leftRule.rule;
        conditionalFormat.LeftRuleColumnName = condItem.rule.leftRule.ruleColumnName;
        conditionalFormat.RightRuleColumnName = condItem.rule.rightRule.ruleColumnName;
        conditionalFormat.Columns = columnsMap;
        conditionalFormat.TargetName = controlTargetIsAllColumns ? targetColumn.TargetName : columnsMap.get(targetColumn.uniqeColumnId).displayName;
        conditionalFormat.TargetAliasName = controlTargetIsAllColumns ? targetColumn.TargetName : columnsMap.get(targetColumn.uniqeColumnId).aliasName;
        conditionalFormat.LocationFieldName = targetColumn.locationFieldName;
        conditionalFormat.Operator = condItem.rule.operator;
        conditionalFormat.id = condItem.id;

        conditionalFormat.Style = {
          background: { color: condItem.options.backgroundColor },
          textColor: condItem.options.color
        };

        condFormatList.push(conditionalFormat);
      });
    });

    return condFormatList;
  };

  /*
    * Converts data for conditional format
    */
  convertDataToColumnMap = (data, columnMap) => {
    let newData = {};

    for (let field of Object.keys(columnMap)) {
      if (Array.isArray(columnMap[field])) {
        for (let column of columnMap[field]) {
          newData[column.displayName] = data[column.displayName];
        }
      } else {
        newData[columnMap[field].displayName] = data[field];
      }
    }

    return newData;
  }

  /**
   * Renders the plugin
   * 
   * @param {*} divId 
   * @param {*} data 
   * @param {*} columnMap 
   * @param {*} config 
   * @param {*} condFormats 
   * @param {*} filters 
   */
  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {
    let legendContainer = null;
    let legendHeading = null;
    let legend = null;
    let condFormLegend = null;

    // If there a root element has been created before, dispose it.
    this.root?.dispose(); // eslint-disable-line

    // Get the container which the chart sits on it
    let plugin = $("#" + divId)
    let pluginBody = plugin[0]

    let pluginWidth = plugin.width() || am5.percent(100);
    let pluginHeight = config.height ||am5.percent(100);

    // Create a new root element
    this.root = am5.Root.new(pluginBody, {
      fps: 60
    });

    // Dispose amcharts logo
    this.root._logo.dispose();

    // Disable autoResize in order to avoid resizeObserver error
    this.root.autoResize = false;

    // Resize root when chart is ready
    this.root.events.on("frameended", () => this.root.resize());

    let isPluginBigEnough = this.props.plugin.w > 3 && (this.props.plugin.h > 3 || this.props.plugin.w >= 4) ? true : false

    // Set plugin color theme
    this.contrastColor = this.getContrastColor(am5.color(config.backgroundColor));
    this.setTheme(config);

    // Chart formats
    this.root.dateFormatter.monthsShort = shortMonths
    this.root.dateFormatter.months = longMonths
    this.root.numberFormatter.bigNumberPrefixes = bigNumberPrefixes
    this.root.locale["_decimalSeparator"] = decimalSeperators;
    this.root.locale["_thousandSeparator"] = thousandSeperators;

    // Create chart container
    let pluginContainer = this.root.container.children.push(am5.Container.new(this.root, {
      layout: this.root.verticalLayout,
      width: pluginWidth,
      height: pluginHeight,
      prewidth: pluginWidth,
      preheight: pluginHeight,
      background: am5.Rectangle.new(this.root, {
        fill: am5.color(config.backgroundColor),
      })
    }));

    // Create chart container
    let chartContainer = pluginContainer.children.push(am5.Container.new(this.root, {
      paddingRight: 5,
      paddingLeft: 5,
      paddingTop: 5,
      paddingBottom: 5
    }));

    // Set conditional format data
    if (condFormats !== undefined) {
      condFormats = this.convertFormatConditionalFormatting(condFormats, columnMap);
      this.setState({ condFormats })
    } else {
      condFormats = [];
    }

    this.condFormatsLength = condFormats ? condFormats.length : 0

    let chartContainerHeight = pluginHeight - (config.condFormat && condFormats?.length ? 30 : 0);
    
    // Set the chart container's width & height 
    chartContainer.setAll({
      width: pluginWidth,
      height: chartContainerHeight,
      prewidth: pluginWidth,
      preheight: chartContainerHeight,
      layout: this.root.horizontalLayout,
    });

    // Create xy chart
    let chart = this.setChart(chartContainer);

    // Create axis scrollbars
    if (isPluginBigEnough) {
      this.setScrollbars(chart, config);
    }

    // Create axes
    let xAxisTitle, yAxisTitle;
    let xAxis = this.createAxis(chart.xAxes, am5xy.AxisRendererX, columnMap.measureX, config.xAxisAlignment === "top");
    let yAxis = this.createAxis(chart.yAxes, am5xy.AxisRendererY, columnMap.measureY, config.yAxisAlignment === "right");
    let xAxisText = null;
    let yAxisText = null;

    // Set x and y axis labels
    if (isPluginBigEnough) {
      // Set x axis title
      xAxisText = this.truncateText(columnMap.measureX.displayName, chart.plotContainer.width() / (Number(config.xTitleFontSize) || 12) - 1);
      yAxisText = this.truncateText(columnMap.measureY.displayName, chart.plotContainer.height() / (Number(config.yTitleFontSize) || 12) - 1);

      xAxisTitle = am5.Label.new(this.root, {
        text: xAxisText,
        preText: xAxisText,
        fontSize: Number(config.xTitleFontSize) || 12,
        fontWeight: config.xTitleFontWeight === true ? "bold" : "normal",
        fontStyle: config.xTitleFontStyle === true ? "italic" : "normal",
        textDecoration: config.xTitleTextDecor === true ? "underline" : null,
        x: am5.p50,
        centerX: am5.p50,
      });

      if (config.xAxisAlignment === "top") {
        xAxis.children.unshift(xAxisTitle);
      } else {
        xAxis.children.push(xAxisTitle);
      }

      // Set y axis title
      yAxisTitle = am5.Label.new(this.root, {
        rotation: -90,
        text: yAxisText,
        preText: yAxisText,
        fontSize: Number(config.yTitleFontSize) || 12,
        fontWeight: config.yTitleFontWeight === true ? "bold" : "normal",
        fontStyle: config.yTitleFontStyle === true ? "italic" : "normal",
        textDecoration: config.yTitleTextDecor === true ? "underline" : null,
        y: am5.p50,
        centerX: am5.p50,
        textAlign: "center",
      });

      if (config.yAxisAlignment === "right") {
        yAxis.children.push(yAxisTitle);
      } else {
        yAxis.children.unshift(yAxisTitle);
      }
    }

    // Common series properties
    let seriesProperties = {
      calculateAggregates: true,
      xAxis: xAxis,
      yAxis: yAxis,
      varyColour: "varyColour",
      varySize: "varySize",
      valueXField: "measureX",
      valueYField: "measureY",
      categoryField: "group"
    };

    // Create series
    let series = chart.series.push(am5xy.LineSeries.new(this.root, seriesProperties));

    // Stroke opacity
    series.strokes.template.set("strokeOpacity", 0);

    // Add cursor
    let cursor = am5xy.XYCursor.new(this.root, {
      xAxis: xAxis,
      yAxis: yAxis,
      snapToSeries: [series]
    });

    let cursorLineProperties = {
      stroke: am5.color(this.getContrastColor(am5.color(config.backgroundColor))),
      strokeOpacity: 0.8,
    }

    chart.set("cursor", cursor);
    cursor.lineX.setAll(cursorLineProperties);
    cursor.lineY.setAll(cursorLineProperties);

    // Set data colors
    let colors = {}
    let colorSet = am5.ColorSet.new(this.root, { step: 1 });

    let varyColourItems = new Map();
    let ignoredVaryColour = new Set();
    let hoveredVaryColour = null;

    // Format vary colour and measureX data
    let formattedData = data.map(d => {
      let temp = { ...d };

      if (typeof temp.varyColour !== "undefined") {
        temp.formattedVaryColour = this.affixFormatForLegend(format(temp.varyColour, null, columnMap.varyColour), columnMap.varyColour);
      }

      return temp;
    });

    let coloredData = [...formattedData];

    // Set vary colour items, default point size, and conditional format results
    for (let d of coloredData) {
      let averagePointSize = (Number(config.minPointSize) + Number(config.maxPointSize)) / 2

      if (d.varyColour) {
        if (!varyColourItems.has(d.varyColour)) {
          colors[d.varyColour] = colorSet.getIndex(Object.keys(colors).length)

          varyColourItems.set(d.varyColour, {
            data: d.formattedVaryColour,
            color: colors[d.varyColour]
          });
        }
      } else {
        colors[d.varyColour] = colorSet.getIndex(0)
      }

      d.settings = {
        fill: colors[d.varyColour],
        radius: averagePointSize,
        width: averagePointSize,
        height: averagePointSize,
      };

      let isFillTargetsEnabled = config.fillTargets === "bubbles" || config.fillTargets === "both";

      if (config.showTargets === true && isFillTargetsEnabled) {
        for (let target of config.targets) {
          let isTargetCompleted = (
            target.name &&
            (target.startValue || target.endValue) &&
            target.axis &&
            target.color
          );

          if (isTargetCompleted) {
            let startValue = target.startValue || Number(target.startValue) === 0 ? Number(target.startValue) : NaN;
            let endValue = target.endValue || Number(target.endValue) === 0 ? Number(target.endValue) : NaN;
            let dataValue = target.axis === "x"
              ? Number(d.measureX)
              : Number(d.measureY);

            startValue = isNaN(startValue) ? endValue : startValue;
            endValue = isNaN(endValue) ? startValue : endValue;

            if (!isNaN(startValue + endValue + dataValue)) {
              let isDataInRange = (
                (startValue <= dataValue && dataValue <= endValue) ||
                (endValue <= dataValue && dataValue <= startValue)
              );

              if (isDataInRange) {
                d.settings.fill = am5.color(target.color);
              }
            }
          }
        }
      }

      if (condFormats && condFormats.length > 0) {
        for (let condFormat of condFormats) {
          let convertedData = this.convertDataToColumnMap(d, columnMap);
          let comparedData = compare(convertedData, condFormat);

          if (comparedData.status === true) {
            let color = am5.color(condFormat.Style.background.color);

            d.settings.fill = color;

            let LeftRuleColId = condFormat.LeftRule.replaceAll("_", "-");

            if (LeftRuleColId.includes(columnMap.varyColour.uniqeColumnId)) {
              let varyColourItem = varyColourItems.get(d.varyColour);

              varyColourItems.set(d.varyColour, {
                ...varyColourItem,
                color: color
              });

              colors[d.varyColour] = color;
            }
          }
        }
      }
    }

    let currentData = [...coloredData];

    // Set vary size
    currentData = this.setVarySize(currentData, columnMap, config);

    // Set animation data
    let animationData = this.setAnimationData(columnMap, coloredData);
    let animationDataKeys;
    let currentAnimation;

    // If there is animation data, set first animation part as current data
    if (typeof animationData === "object" && !Array.isArray(animationData)) {
      animationDataKeys = Object.keys(animationData);
      currentAnimation = animationDataKeys[0];
      currentData = this.setVarySize(animationData[currentAnimation], columnMap, config);
    }

    if (config.showTargets === true) {
      for (let target of config.targets) {
        let isTargetCompleted = (
          target.name &&
          (target.startValue || target.endValue) &&
          target.color &&
          target.axis
        );

        if (isTargetCompleted) {
          this.createTarget(target, target.axis === "x" ? xAxis : yAxis, config, chart.plotContainer);
        }
      }
    }

    // Linear Regression
    if (config.lr && aggregatableDataTypes.has(columnMap.measureX.DataType)) {
      // Set regression series
      var regressionSeries = chart.series.push(am5xy.LineSeries.new(this.root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: "x",
        valueYField: "y",
        stroke: am5.color(this.contrastColor)
      }));

      regressionSeries.strokes.template.setAll({
        strokeWidth: 2,
        strokeDasharray: [10, 5],
        strokeOpacity: 0.6
      });
    }

    // Set series and axes data
    this.setData(currentData, series, regressionSeries ? regressionSeries : null, ignoredVaryColour, xAxis, yAxis);

    // Set animation data
    if (animationDataKeys && currentAnimation) {
      // Set animation label
      if (config.animationLabel !== false) {
        var animationLabel = chart.plotContainer.children.push(am5.Label.new(this.root, {
          text: format(currentAnimation, null, columnMap.animation),
          fill: am5.color(this.getContrastColor(am5.color(config.backgroundColor))),
          opacity: 0.1,
          strokeOpacity: 0.1,
          x: am5.p50,
          y: am5.p50,
          textAlign: "center",
          centerY: am5.p50,
          centerX: am5.p50,
          fontSize: Number(config.animationTitleFontSize) || 32,
          fontWeight: config.animationTitleFontWeight === true ? "bold" : "normal",
          fontStyle: config.animationTitleFontStyle === true ? "italic" : "normal",
          maxWidth: chart.plotContainer.innerWidth() / 2,
          oversizedBehavior: config.animationTitleOversizeBehaviour || "fit",
        }));
      }

      // Create animation controls
      
        // Create slider container
        let animationSliderContainer = chart.children.push(am5.Container.new(this.root, {
          width: am5.percent(100),
          layout: this.root.horizontalLayout,
          paddingLeft: 70,
          paddingRight: 40,
          exportable: false,
          visible: false
        }));

        // Create play button
        this.playButton = animationSliderContainer.children.push(am5.Button.new(this.root, {
          active: false,
          themeTags: ["play"],
          centerY: am5.p50,
          marginRight: 20,
          icon: am5.Graphics.new(this.root, {
            themeTags: ["icon"],
            fill: this.contrastColor
          })
        }));

        // Starts chart animation
        this.startAnimation = async (timeout = 1) => {
          setTimeout(() => {
            slider.animate({
              key: "start",
              to: 1,
              duration: 1000 * (config.duration || 1) * animationDataKeys.length * (1 - slider.get("start"))
            });

            this.playButton.set("active", true);
          }, timeout);
        }

        // Stops chart animation
        this.stopAnimation = async () => {
          slider.set("start", slider.get("start"));
          this.playButton.set("active", false);
        }

        // Rewinds chart animation
        this.rewindAnimation = () => {
          slider.set("start", 0);
          this.playButton.set("active", false);          
        }

        // Play button on click event
        this.playButton.events.on("click", () => {
          if (slider.get("start") === 1) {
            slider.set("start", 0)
          }

          if (this.playButton.get("active")) {
            slider.set("start", slider.get("start"));
          } else {            
            this.startAnimation();
          }
        });

        // Play button pointer over event
        this.playButton.events.on("pointerover", (ev) => {
          document.body.style.cursor = "pointer";
        });

        // Play button pointer out event
        this.playButton.events.on("pointerout", (ev) => {
          document.body.style.cursor = "default";
        });

        // Create animation slider
        let slider = animationSliderContainer.children.push(am5.Slider.new(this.root, {
          orientation: "horizontal",
          start: 0,
          centerY: am5.p50
        }));

        // Slider on start event, changes the play button's active state
        slider.on("start", (start) => {
          if (start === 1) {
            this.playButton.set("active", false);
          }
        });

        // Slider on range changed event, changes the bullets' position and value
        slider.events.on("rangechanged", () => setTimeout(() => {
          let index = Math.round(slider.get("start", 0) * (animationDataKeys.length - 1));
          let key = animationDataKeys[index];

          if (currentAnimation !== key) {
            // Previous animation data
            let previousData = animationData[currentAnimation];

            // Current animation data
            let currentData = animationData[key];
            currentAnimation = key;

            // Set vary size for new animation data
            currentData = this.setVarySize(currentData, columnMap, config);

            // Animate bubbles
            if (currentData.length < previousData.length) {
              for (let i in previousData) {
                if (i < currentData.length) {
                  series.data.setIndex(i, currentData[i]);
                } else {
                  series.data.removeIndex(currentData.length);
                }
              }
            } else {
              for (let i in currentData) {
                let item = currentData[i];

                if (i < previousData.length) {
                  series.data.setIndex(i, item);
                } else {
                  series.data.insertIndex(i, item);
                }
              }
            }

            // Set new properties for bubbles
            for (let i in currentData) {
              let item = currentData[i];
              let dataItem = series.dataItems[i];

              if (dataItem) {
                let bullets = dataItem.bullets;

                if (bullets) {
                  let bullet = bullets[0]

                  if (bullet) {
                    let sprite = bullet.get("sprite");

                    if (sprite) {
                      sprite.setAll(item.settings);
                    }
                  }
                }
              }
            }

            // Hide ignored vary colour bubbles
            am5.array.each(series.dataItems, function (dataItem) {
              if (dataItem.bullets) {
                var bullet = dataItem.bullets[0];
                var sprite = bullet.get("sprite");

                if (ignoredVaryColour.has(sprite.dataItem.dataContext.varyColour)) {
                  sprite.set("forceHidden", true);
                }
                else {
                  sprite.set("forceHidden", false);
                }
              }
            });

            // Set hovered vary colour bubbles
            am5.array.each(series.dataItems, function (dataItem) {
              if (dataItem.bullets) {
                let bullet = dataItem.bullets[0];

                if (bullet) {
                  let sprite = bullet.get("sprite");

                  if (sprite) {
                    if (hoveredVaryColour === null || hoveredVaryColour === sprite.dataItem.dataContext.varyColour) {
                      sprite.states.applyAnimate("default");
                    } else {
                      sprite.states.applyAnimate("transparent");
                    }
                  }
                }
              }
            });

            // Animate regression line
            if (regressionSeries) {
              let previousLinearRegression = this.getLinearRegression(previousData);
              let currentLinearRegression = this.getLinearRegression(currentData);

              if (currentLinearRegression) {
                if (previousLinearRegression) {
                  for (let i in currentLinearRegression.data) {
                    let item = currentLinearRegression.data[i];

                    regressionSeries.data.setIndex(i, item);
                  }
                } else {
                  regressionSeries.data.setAll(currentLinearRegression.data);
                }
              } else {
                regressionSeries.data.setAll([]);
              }
            }

            if (config.animationLabel !== false) {
              animationLabel.set("text", currentAnimation);
            }
          }
        }));

        if (isPluginBigEnough && !this.props.isDashboardSliderActive) {
          animationSliderContainer.set("visible", true);
        }
    }

    // Set legend data
    let legendData = Array.from(varyColourItems.values());

    // If legend is enabled, create legend
    if (legendData.length > 0 && this.props.plugin.w > 4 && config.legend) {
      let legendContainerWidth = pluginWidth * 1.2;

      if (legendContainerWidth > 200) legendContainerWidth = 200;
      if (legendContainerWidth < 100) legendContainerWidth = 100;

      // Create legend container
      legendContainer = chartContainer.children.push(am5.Container.new(this.root, {
        height: chartContainerHeight,
        minWidth: 100,
        width: legendContainerWidth,
        prewidth: legendContainerWidth,
        preheight: chartContainerHeight,
        preMaxWidth: 200,
        maxWidth: 200,
        layout: this.root.verticalLayout
      }));

      let legendHeadingContainer = legendContainer.children.push(am5.Container.new(this.root, {
        width: legendContainerWidth,
        prewidth: legendContainerWidth,
        height: 24,
        preheight: 24,
      }));

      // Create Legend Heading consists of vary colour column name
      legendHeading = legendHeadingContainer.children.push(am5.Label.new(this.root, {
        text: columnMap.varyColour.displayName,
        fontWeight: "bold",
        paddingTop: 0,
        paddingBottom: 12,
        paddingLeft: 0,
        fontSize: 12,
        oversizedBehavior: "truncate",
        height: am5.percent(100),
        maxWidth: legendContainerWidth,
        preMaxWidth: legendContainerWidth
      }));

      // Create legends
      legend = am5.Legend.new(this.root, {
        nameField: "data",
        fillField: "color",
        strokeField: "color",
        layout: this.root.verticalLayout,
        verticalScrollbar: am5.Scrollbar.new(this.root, {
          orientation: "vertical",
          marginRight: 10
        })
      });

      legendContainer.children.push(legend);

      // Set legend properties
      legend.setAll({
        width: am5.percent(100),
        height: chartContainerHeight - 24,
        preheight: chartContainerHeight - 24,
        y: am5.percent(100),
        centerY: am5.percent(100),
        paddingRight: 10
      });

      // Set legend markers
      legend.markerRectangles.template.setAll({
        width: 14,
        height: 14
      });

      let legendLabelMaxWidth = legendContainerWidth - 50;

      // Set legend labels
      legend.labels.template.setAll({
        fontSize: 12,
        maxWidth: legendLabelMaxWidth,
        preMaxWidth: legendContainerWidth,
        oversizedBehavior: "truncate",
        textAlign: "left"
      });

      /**
       * Legend pointer over handler, draws to attention to the legend's bullets
       * 
       * @param {*} e 
       * @returns 
       */
      const handleLegendOver = (e) => {
        document.body.style.cursor = "pointer";

        let target = e.target;

        if (ignoredVaryColour.has(target.dataItem.dataContext.data)) {
          return;
        }

        hoveredVaryColour = target.dataItem.dataContext.data;

        am5.array.each(series.dataItems, function (dataItem) {
          if (dataItem.bullets) {
            let bullet = dataItem.bullets[0];

            if (bullet) {
              let sprite = bullet.get("sprite");

              if (sprite) {
                if (hoveredVaryColour === sprite.dataItem.dataContext.varyColour) {
                  sprite.states.applyAnimate("default");
                } else {
                  sprite.states.applyAnimate("transparent");
                }
              }
            }
          }
        })
      }

      /**
       * Legend click handler, toggles the disability of the legend
       * 
       * @param {*} e 
       */
      const handleLegendClick = (e) => {
        let target = e.target;

        if (target && target.dataItem) {
          if (ignoredVaryColour.has(target.dataItem.dataContext.data)) {
            ignoredVaryColour.delete(target.dataItem.dataContext.data);

            target.states.applyAnimate("default");
          } else {
            ignoredVaryColour.add(target.dataItem.dataContext.data);

            target.states.applyAnimate("disabled");
          }
        }

        am5.array.each(series.dataItems, dataItem => {
          if (dataItem.bullets) {
            let bullet = dataItem.bullets[0];
            let sprite = bullet.get("sprite");

            if (ignoredVaryColour.has(sprite.dataItem.dataContext.varyColour)) {
              sprite.states.applyAnimate("transparent");
              sprite.set("forceHidden", true);
            }
            else {
              sprite.states.applyAnimate("default");
              sprite.set("forceHidden", false);
            }
          }
        });
      }

      /**
       * Legend pointer out handler
       * 
       * @param {*} e 
       */
      const handleLegendOut = (e) => {
        document.body.style.cursor = "default";
        hoveredVaryColour = null;

        am5.array.each(series.dataItems, function (dataItem) {
          if (dataItem.bullets && !ignoredVaryColour.has(dataItem)) {
            let bullet = dataItem.bullets[0];
            if (bullet) {
              let sprite = bullet.get("sprite");
              if (sprite) {
                sprite.states.applyAnimate("default");
              }
            }
          }
        });
      }

      legend.itemContainers.template.events.on("pointerover", handleLegendOver);
      legend.itemContainers.template.events.on("click", handleLegendClick);
      legend.itemContainers.template.events.on("pointerout", handleLegendOut);

      legend.data.setAll(legendData);

      legendData = legendData.sort((a, b) => a.data.length > b.data.length ? -1 : 1);

      let maxLabelLength = Math.max(legendData[0].data.length, columnMap.varyColour.displayName.length);

      legend.set("maxLabelLength", maxLabelLength);
    }

    let bulletTemplate = am5.Template.new({ tooltipY: 0 });
    bulletTemplate.states.create("transparent", { opacity: 0.15 });

    /**
     * Bullet pointer over handler, draws attention to the bullet
     * 
     * @param {*} e 
     */
    const handleBulletOver = e => {
      let target = e.target;

      am5.array.each(series.dataItems, function (dataItem) {
        if (dataItem.bullets) {
          let bullet = dataItem.bullets[0];

          if (bullet) {
            let sprite = bullet.get("sprite");

            if (sprite && sprite !== target) {
              sprite.states.applyAnimate("transparent");
            }
          }
        }
      })
    }

    /**
     * Bullet pointer out handler
     * @param {*} e 
     */
    const handleBulletOut = e => {
      document.body.style.cursor = "default";

      am5.array.each(series.dataItems, function (dataItem) {
        if (dataItem.bullets) {
          let bullet = dataItem.bullets[0];
          if (bullet) {
            let sprite = bullet.get("sprite");
            if (sprite) {
              sprite.states.applyAnimate("default");
            }
          }
        }
      })
    }

    /**
     * Bullet click handler (Interaction, Navigation...)
     * 
     * @param {*} e 
     */
    const handleBulletClick = e => {
      let clickedColumn;
      let dataContext = e.target.dataItem.dataContext;
      let mousePosition = { x: window.event.pageX, y: window.event.pageY }

      if (this.props.plugin.drillDowns) {
        for (let field of Object.keys(columnMap)) {
          let column = columnMap[field]

          if (column && this.props.plugin.drillDowns.allDrillDownColumnsInPlugin.has(column.uniqeColumnId)) {
            if (this.drillDownMustBeFoundArea.includes(column.locationFieldName)) {
              clickedColumn = [column];
            } else {
              let reduxState = store.getState();
              let drillDowns = reduxState.DrillDownReducer.drillDowns;

              drillDowns.delete(this.props.plugin.id);

              store.dispatch(setPluginsDrillDowns(drillDowns));
            }
          }
        }
      }

      // Trigger event
      createTrigger(
        actions,
        columnMap,
        pluginBody,
        "pointClick",
        dataContext,
        this.props.plugin.id,
        this.props.interactions,
        this.props.navigations,
        mousePosition,
        null,
        this.props.plugin.drillDowns,
        clickedColumn,
        this.props.plugin,
        this.props.model,
        dataContext,
        null,
        null
      );
    }

    bulletTemplate.events.on("pointerover", handleBulletOver);
    bulletTemplate.events.on("pointerout", handleBulletOut);
    bulletTemplate.events.on("click", handleBulletClick);

    // Add bullets
    series.bullets.push(() => {
      // Create tooltip
      let truncateLimit = chart.innerWidth() / 12;
      let affixMeasureXText = this.affixFormatForLegend("{" + "measureX" + this.getFormat(columnMap.measureX).placeHolder + "}", columnMap.measureX);
      let measureXText = `${this.truncateText(columnMap.measureX.displayName, truncateLimit)}: [bold]${affixMeasureXText}[/]`;
      let affixMeasureYText = this.affixFormatForLegend("{" + "measureY" + this.getFormat(columnMap.measureY).placeHolder + "}", columnMap.measureY);
      let measureYText = `${this.truncateText(columnMap.measureY.displayName, truncateLimit)}: [bold]${affixMeasureYText}[/]`;
      let affixgroupText = this.affixFormatForLegend("{" + "group" + this.getFormat(columnMap.group).placeHolder + "}", columnMap.group);
      let groupText = `${this.truncateText(columnMap.group.displayName, truncateLimit)}: [bold]${affixgroupText}[/]`;
      let affixVaryColourText = this.affixFormatForLegend("{" + "varyColour" + this.getFormat(columnMap.varyColour).placeHolder + "}", columnMap.varyColour);
      let varyColourText = currentData[0].varyColour ? `${this.truncateText(columnMap.varyColour.displayName, truncateLimit)}: [bold]${affixVaryColourText}[/]` : null;
      let affixVarySizeText = this.affixFormatForLegend("{" + "varySize" + this.getFormat(columnMap.varySize).placeHolder + "}", columnMap.varySize);
      let varySizeText = currentData[0].varySize ? `${this.truncateText(columnMap.varySize.displayName, truncateLimit)}: [bold]${affixVarySizeText}[/]` : null;
      let existingText = [measureXText, measureYText, groupText, varyColourText, varySizeText].filter(t => !isNull(t));
      let tooltipText = existingText.join("\n");
      let dataTooltip = am5.Tooltip.new(this.root, {});

      let graphics = am5.Circle.new(this.root, {
        templateField: "settings",
        fillOpacity: 0.9,
        tooltip: dataTooltip,
        tooltipText: tooltipText
      }, bulletTemplate);

      return am5.Bullet.new(this.root, {
        sprite: graphics
      });
    });

    let isDataAvailableForCondFormatLegend = condFormats && condFormats.length > 0 && config.condFormat;

    if (isDataAvailableForCondFormatLegend && isPluginBigEnough) {
      condFormLegend = this.addCondFormLegend(condFormats, pluginContainer);
    }

    /**
     * Sprite.appear
     * 
     * Plays initial reveal animation in miliseconds regardless if element is currently hidden or visible.
     * 
     * @param {*} duration 
     * @param {*} delay 
     */

    chart.appear(100);
    series.appear(1000, 100);

    if (regressionSeries) {
      regressionSeries.appear(1000, 500);
    }

    function transformData(xlsxData, columnMap) {
      return xlsxData.map(entry => {
        const transformedEntry = {};
        for (const key in entry) {
          if (key in columnMap) {
            transformedEntry[columnMap[key].displayName] = entry[key];
          }
        }
        return transformedEntry;
      });
    }

    let xlsxData =  deepCopy(data);

    xlsxData.forEach(obj => delete obj.hidden);

    xlsxData = transformData(xlsxData, columnMap);

    var exporting = am5plugins_exporting.Exporting.new(this.root, {
      title: `${this.props.title}`,
      filePrefix: `${this.props.title}`,
      showTimeout: () => { },
      pngOptions: {
        quality: 1,
        minWidth: 4320,
        minHeight: 4320,
        pageOrientation: "portrait"
      },
      pdfOptions: {
        quality: 1,
        addURL: false,
        minWidth: 4320,
        minHeight: 4320,
        align: "center"
      },
      dataSource: xlsxData
    });

    let exportDownloadSupport = exporting.downloadSupport();

    if (exportDownloadSupport) {
      /**
       * Before the export operation handler
       */
      const exportStarted = format => {
        let container = $("#" + divId)[0];
        let headerSection = document.getElementsByClassName("header-section")[0];
        let toolsSection = document.getElementsByClassName("dashboard-setting")[0];

        headerSection.style.position = "relative";
        headerSection.style.zIndex = 1000;

        toolsSection.style.position = "relative";
        toolsSection.style.zIndex = 1000;

        container.style.position = "fixed";
        container.style.zIndex = 1000
        container.style.visibility = "hidden";

        changePluginLoaderVisibility(divId, true)
        loadingScreen(true);

        let measuredWidth = pluginWidth;
        let measuredHeight = pluginHeight;

        if (config.legend && legend != null) { 
          let legendLabelWidth;
          let legendHeadingwidth = getTextWidth(legendHeading.get('text'), `${legendHeading.get('fontWeight')} ${legendHeading.get('fontSize')} ${legendHeading.get('fontFamily')}`);
          let sizeRatio = chartContainer.get("width") / chartContainer.get("height");

          legend.labels.template._entities.forEach(label => {
            let width = getTextWidth(label.get('text'), `normal ${label.get('fontSize')} ${label.get('fontFamily')}`);

            if (!legendLabelWidth || width > legendLabelWidth) legendLabelWidth = width;
          });
          
          let contentWidth = Math.max(legendHeadingwidth, legendLabelWidth) + 70;
          let contentHeight = (legend._contentHeight / legend.itemContainers._values.length) * 20; // Bring first 20 legend

          legend.set("height", contentHeight);

          legend.labels.template.set("maxWidth", contentWidth);
          
          legendHeading.set("maxWidth", contentWidth);
          legendHeading.parent.set("width", contentWidth);
          legendContainer.set("maxWidth", contentWidth);
          legendContainer.set("width", contentWidth);
          legend.set("width", am5.percent(100));

          measuredHeight = Math.max(chartContainer.get("height"), contentHeight) + 24;
          measuredWidth = measuredHeight * sizeRatio;

          legendContainer.set("height", contentHeight + 24);
          chartContainer.set("height", measuredHeight);
          pluginContainer.set("height", measuredHeight);

          chartContainer.set("width", Math.max(xAxisTitle._contentWidth * 2, measuredWidth));
          pluginContainer.set("width", Math.max(xAxisTitle._contentWidth * 2, measuredWidth) + legendContainer.get("width"));

          if (xAxisTitle) xAxisTitle.set("text", columnMap.measureX.displayName)

          if (yAxisTitle) yAxisTitle.set("text", columnMap.measureY.displayName);
        }

        if (config.condFormat && condFormLegend) {
          let contentHeight = condFormLegend._contentHeight;

          condFormLegend.set("height", contentHeight);
          
          measuredHeight += contentHeight + 10;
        
          pluginContainer.set("height", measuredHeight);
        }

        measuredHeight += 20;
        measuredHeight += 20;

        container.style.minHeight = measuredHeight + "px";
        container.style.minWidth = legendContainer ? Math.max(xAxisTitle._contentWidth * 2, measuredWidth) + legendContainer.get("width") + "px" : Math.max(xAxisTitle._contentWidth * 2, measuredWidth);

        let pdfSettings = exporting;
        let pdfOptions = exporting._settings.pdfOptions;

        if (format === "pdf") {
          pdfSettings.set("pdfOptions", {
            ...pdfOptions,
            pageOrientation: measuredHeight > measuredWidth ? "portrait" : "landscape"
          });
        }
      };

      /**
       * After the export operation handler
       */
      const exportFinished = format => {
        setTimeout(() => {
          let container = $("#" + divId)[0];
          let headerSection = document.getElementsByClassName("header-section")[0];
          let toolsSection = document.getElementsByClassName("dashboard-setting")[0];

          headerSection.style.position = "";
          headerSection.style.zIndex = "";

          toolsSection.style.position = "";
          toolsSection.style.zIndex = "";

          container.style.position = "";
          container.style.zIndex = "";

          this.setScrollbars(chart, config);
          this.setTheme(config);

          if (format !== "xlsx") {
            if (legend != null) {
              legend.labels.template.set("maxWidth", legend.labels.template.get("preMaxWidth"));

              legend.set("height", legend.get("preheight"));
              
              legendHeading.set("maxWidth", legendHeading.get("preMaxWidth"));

              legendContainer.set("height", legendContainer.get("preheight"));
              legendContainer.set("width", legendContainer.get("prewidth"));
              legendContainer.set("maxWidth", legendContainer.get("preMaxWidth"));
            }

            if (condFormLegend != null) {
              condFormLegend.set("height", condFormLegend.get("preheight"));
            }
            

            chartContainer.set("height", chartContainer.get("preheight"));
            chartContainer.set("width", chartContainer.get("prewidth"));

            pluginContainer.set("height", pluginContainer.get("preheight"));
            pluginContainer.set("width", pluginContainer.get("prewidth"));

            if (xAxisTitle) xAxisTitle.set("text", xAxisTitle.get("preText"));
            if (yAxisTitle) yAxisTitle.set("text", yAxisTitle.get("preText"));

            xAxisText = this.truncateText(columnMap.measureX.displayName, chart.plotContainer.width() / (Number(config.xTitleFontSize) || 12) - 1);
            yAxisText = this.truncateText(columnMap.measureY.displayName, chart.plotContainer.height() / (Number(config.yTitleFontSize) || 12) - 1);

            container.style.minWidth = "";
            container.style.minHeight = "";

            this.root.resize();
          }

          store.dispatch(changePluginLoaderVisibility(divId, false));
          loadingScreen(false);

          container.style.visibility = "";
        });
      };

      let title = `${this.props.title}`;

      let exportImageOptions = exporting._settings.pngOptions;
      let exportPDFOptions = exporting._settings.pdfOptions;

      // Image exporting properties
      exportImageOptions.quality = 1;
      exportImageOptions.minWidth = 4320;
      exportImageOptions.minHeight = 4320;

      // PDF exporting properties
      exportPDFOptions.quality = 1;
      exportPDFOptions.addURL = false;
      exportPDFOptions.minWidth = 4320;
      exportPDFOptions.minHeight = 4320;
      exportPDFOptions.align = "center";

      // General exporting properties
      exporting.title = title;
      exporting.filePrefix = title;
      exporting.useRetina = true;
      exporting.showTimeout = () => {};

      let datetime = getCurrentDateTime();
      let columnNames = [
        {measureX: title},
        {measureX: columnMap.measureX.displayName, measureY: columnMap.measureY.displayName, group: columnMap.group.displayName},
      ];
      let excelDataConcat = columnNames.concat(data);

      let excelDisplay = excelDataConcat.map(row => ({
        [datetime]: row.measureX !== columnMap.measureX.Name && row.measureY ? getFormattedValue(columnMap.measureX, row.measureX) : row.measureX,
        [""]: row.measureY !== columnMap.measureY.Name ? getFormattedValue(columnMap.measureY, row.measureY) : row.measureY,
        [" "]: row.group !== columnMap.group.Name ? getFormattedValue(columnMap.group, row.group) : row.group
      }));

      let exportUpdate = [...excelDisplay]
      
      exporting.set("dataSource", exportUpdate);

      pluginBody.PNG = () => {
        exportStarted("png");
        exporting.download("png").then(() => exportFinished("png"));
      };
      pluginBody.PDF = () => {
        exportStarted("pdf");
        exporting.download("pdf").then(() => exportFinished("pdf"));
      };
      pluginBody.XLSX = () => {
        this.props.excelExport();
      };
    }

    // Set plugin renderer
    this.props.setPluginRerender(false, this.props.plugin.id, false, this.props.plugin.isInteraction);
  };

  /**
   * Set target for scatter chart
   * 
   * @param {*} target 
   * @param {*} axis 
   */
  createTarget = (target, axis, config, container) => {
    let startValue = target.startValue || Number(target.startValue) === 0 ? Number(target.startValue) : NaN;
    let endValue = target.endValue || Number(target.endValue) === 0 ? Number(target.endValue) : NaN;
    let axisPosition = target.axis === "x" ? config.xAxisAlignment : config.yAxisAlignment;

    /**
     * Adds an axis range
     * 
     * @param {*} startValue 
     * @param {*} endValue 
     * @param {*} label 
     * @param {*} color 
     * @param {*} dashed 
     */
    const setRange = (startValue, endValue, label = undefined, dashed = false) => {
      let rangeDataItem = axis.makeDataItem({
        value: startValue,
        endValue: endValue,
        above: false
      });

      let range = axis.createAxisRange(rangeDataItem);

      if (!isNaN(endValue)) {
        range.get("axisFill").setAll({
          fill: am5.color(target.color),
          fillOpacity: 0.05,
          visible: true
        });
      }
      else {
        range.get("grid").setAll({
          stroke: am5.color(target.color),
          strokeOpacity: 0.5,
          strokeWidth: 1.5,
          location: 1
        });

        if (dashed) {
          range.get("grid").set("strokeDasharray", [5, 3]);
        }
      }

      if (label) {
        let rangeLabel = range.get("label");

        rangeLabel.setAll({
          text: label,
          fill: am5.color(target.color),
          fontSize: 11,
          fontWeight: "bold",
          inside: true,
          location: 1
        });

        if (axisPosition === "top") {
          rangeLabel.setAll({
            centerY: am5.p0,
            centerX: am5.p100,
            rotation: -90,
            dy: -5
          });

          rangeLabel.adapters.add("y", (y, target) => {
            return container.height();
          });

          container.onPrivate("height", () => {
            rangeLabel.markDirtyPosition();
          });
        } else if (axisPosition === "bottom") {
          rangeLabel.setAll({
            centerY: am5.p0,
            centerX: am5.p0,
            rotation: -90,
            dy: 0
          });
        } else if (axisPosition === "left") {
          rangeLabel.setAll({
            centerX: am5.p0,
            centerY: am5.p100
          });
        } else if (axisPosition === "right") {
          rangeLabel.setAll({
            centerX: am5.p0,
            centerY: am5.p100
          });

          rangeLabel.adapters.add("x", (x, target) => {
            return -container.width();
          });

          container.onPrivate("width", () => {
            rangeLabel.markDirtyPosition();
          });
        }
      }
    }

    let shouldStartLineExist = !isNaN(startValue);
    let shouldEndLineExist = !isNaN(endValue);
    let shouldTargetFilled = (
      !isNaN(startValue + endValue) &&
      ["area", "both"].includes(config.fillTargets)
    );

    // Target Start Line
    if (shouldStartLineExist) {
      setRange(startValue, undefined, target.name);
    }

    // Target End Line
    if (shouldEndLineExist) {
      let endLineLabel = shouldStartLineExist ? undefined : target.name;

      setRange(endValue, undefined, endLineLabel);
    }

    // Target area fill
    if (shouldTargetFilled) {
      setRange(startValue, endValue, undefined);
    }
  }

  /**
   * Add conditional format legends to plugins bottom
   * 
   * @param {*} condFormats 
   * @param {*} container 
   */
  addCondFormLegend = (condFormats, container) => {
    //adds conditional format legends to plugins bottom
    let usedCondFormatIds = new Set();
    let condFormatData = [];

    let condFormLegend = container.children.push(am5.Legend.new(this.root, {
      nameField: "label",
      fillField: "fill",
      strokeField: "stroke",
      useDefaultMarker: true,
      height: 24,
      preheight: 24,
      width: am5.p100,
      paddingRight: 10,
      paddingLeft: 10,
      layout: this.root.gridLayout,
      verticalScrollbar: am5.Scrollbar.new(this.root, {
        orientation: "vertical",
        marginRight: 10
      })
    }));

    condFormLegend.markerRectangles.template.setAll({
      width: 12,
      height: 12,
      cornerRadiusTL: 12,
      cornerRadiusTR: 12,
      cornerRadiusBL: 12,
      cornerRadiusBR: 12,
    });

    condFormLegend.itemContainers.template.setAll({
      paddingTop: 2,
      paddingBottom: 2,
      paddingRight: 0,
      paddingLeft: 0,
      marginRight: 0,
      marginLeft: 0,
    });

    condFormLegend.labels.template.setAll({
      oversizedBehavior: "wrap-no-break",
      maxWidth: condFormLegend.innerWidth() - condFormLegend.get("paddingRight") - condFormLegend.get("paddingLeft"),
      fontSize: 12,
      marginLeft: 0,
    });

    condFormats.forEach((d, i) => {
      if (usedCondFormatIds.has(d.id)) {
        return;
      } else {
        usedCondFormatIds.add(d.id);
      }

      let leftRule = d.LeftRuleColumnName.includes("{") ? `{${d.LeftRuleColumnName}}` : `${d.LeftRuleColumnName}`;
      let rightRule = d.RightRuleColumnName.includes("{") ? `{${d.RightRuleColumnName}}` : `${d.RightRuleColumnName}`;
      let operator = d.Operator;
      let ruleDescription = this.props.plugin.conditionalFormats[i]?.rule?.conditionalFormatRule;

      condFormatData.push({
        fill: d.Style.background.color ? am5.color(d.Style.background.color) : am5.color("#fff"),
        stroke: am5.color("#FFFFFF"),
        label: ruleDescription ? `${ruleDescription}` : `${leftRule} ${operator} ${rightRule}`
      });
    });

    condFormatData = condFormatData.sort((a, b) => a.label.length > b.label.length ? -1 : 1);

    condFormLegend.data.setAll(condFormatData);
    
    return condFormLegend;
  }

  /**
   * Truncates the given text if it is longer than the given limit
   * 
   * @param {*} text 
   * @param {*} limit 
   * @returns 
   */
  truncateText = (text, limit) => {
    if (text.length > limit) {
      return `${text.substring(0, limit - 3)}...`
    }

    return text;
  }

  // Value is converted to affix version
  affixFormat = (value, columnMap) => {
    if (columnMap.affixValue) {
      if (columnMap.affixAlign === "right") {
        return value + "'" + columnMap.affixValue + "'";
      } else if (columnMap.affixAlign === "left") {
        return "'" + columnMap.affixValue + "'" + value;
      }
    }

    return value;
  }

  // Value is converted to affix version without '
  affixFormatForLegend = (value, columnMap) => {
    if (columnMap.affixValue) {
      if (columnMap.affixAlign === "right") {
        return value + columnMap.affixValue;
      } else if (columnMap.affixAlign === "left") {
        return columnMap.affixValue + value;
      }
    }

    return value;
  }

  /**
   * Returns the right format string of given column
   * 
   * @param {*} column 
   * @returns 
   */
  getFormat = (column) => {
    let type = column.DataType;
    let dataFormat = column.DataFormat;
    let format = column.DataFormat;

    if (format) {
      if (dateDataTypes.has(type)) {
        let parts = {
          "%Y": "yyyy",
          "%m": "MM",
          "%d": "dd",
          "%H": "HH",
          "%M": "mm",
          "%S": "ss"
        }

        for (let part of Object.keys(parts)) {
          format = format.replace(part, parts[part]);
        }

        this.root.dateFormatter.set("dateFormat", "format");

        return { format: format, placeHolder: `.formatDate('${format}')` };
      } else if (aggregatableDataTypes.has(type)) {
        dataFormat = dataFormat.toLowerCase();

        let isDataFormatDotNumberS = dataFormat[0] === "." && dataFormat[dataFormat.length - 1] === "s";
        let isDataFormatDotCommaNumberS = dataFormat[0] === "," && dataFormat[1] === "." && dataFormat[dataFormat.length - 1] === "s";

        let isDataFormatDotNumberF = dataFormat[0] === "." && dataFormat[dataFormat.length - 1] === "f";
        let isDataFormatDotCommaNumberF = dataFormat[0] === "," && dataFormat[1] === "." && dataFormat[dataFormat.length - 1] === "f";

        let format = {
          format: "#.",
          placeHolder: `.formatNumber('#.')`
        };

        if (dataFormat !== ".s" && isDataFormatDotNumberS) {
          // for .Ns
          let subString = dataFormat.substring(dataFormat.indexOf(".") + 1, dataFormat.lastIndexOf("s"))

          if (!isNaN((Number(subString)))) {
            let zeroCount = parseInt(subString);
            let zeroArr = Array(isNaN(zeroCount) ? 0 : zeroCount + 1).join("0");

            format.placeHolder = `.formatNumber('#.${zeroArr}a')`;
            format.format = this.affixFormat(`#.${zeroArr}a`, column);
          }
        } else if (isDataFormatDotCommaNumberF) {
          // for ,.Nf
          let subString = dataFormat.substring(dataFormat.indexOf(".") + 1, dataFormat.lastIndexOf("f"))

          if (!isNaN((Number(subString)))) {
            let zeroCount = parseInt(subString);
            let zeroArr = Array(isNaN(zeroCount) ? 0 : zeroCount + 1).join("0");

            format.placeHolder = `.formatNumber('#,###.${zeroArr}')`;
            format.format = this.affixFormat(`#,###.${zeroArr}`, column)
          }
        } else if (isDataFormatDotCommaNumberS) {
          // for ,.Ns
          let subString = dataFormat.substring(dataFormat.indexOf(".") + 1, dataFormat.lastIndexOf("s"));

          if (!isNaN(Number(subString))) {
            let zeroCount = parseInt(subString);
            let zeroArr = Array(isNaN(zeroCount) ? 0 : zeroCount + 1).join("0");

            format.placeHolder = `.formatNumber('#,###.${zeroArr}a')`;
            format.format = this.affixFormat(`#,###.${zeroArr}a`, column)
          }
        } else if (dataFormat === ".f") {
          //for .f
          format.placeHolder = `.formatNumber('#.')`;
          format.format = this.affixFormat('#.', column);
        } else if (dataFormat !== ".f" && isDataFormatDotNumberF) {
          // for .Nf
          let subString = dataFormat.substring(dataFormat.indexOf(".") + 1, dataFormat.lastIndexOf("f"));

          if (!isNaN(Number(subString))) {
            let zeroCount = parseInt(subString);
            let zeroArr = Array(zeroCount + 1).join("0")

            format.placeHolder = `.formatNumber('#.${zeroArr}')`;
            format.format = this.affixFormat(`#.${zeroArr}`, column)
          }
        }

        return format;
      }
    }

    return {
      placeHolder: "",
      format: null
    };
  }

  /**
   * Sets point size of each part of the given data according to:
   *  - min and max point size from config
   *  - vary size data
   * 
   * @param {*} data 
   * @param {*} columnMap 
   * @param {*} config 
   * @returns 
   */
  setVarySize = (data, columnMap, config) => {
    let sizedData = [...data];

    if (columnMap.varySize && Object.keys(sizedData[0]).includes("varySize")) {
      let minPointSize = Number(config.minPointSize);
      let maxPointSize = Number(config.maxPointSize);

      let minVarySize = Math.min(...data.map(d => d.varySize));
      let maxVarySize = Math.max(...data.map(d => d.varySize));

      for (let d of sizedData) {
        let normalizedVarySize = (d.varySize - minVarySize) / (maxVarySize - minVarySize);
        let pointSize = (normalizedVarySize * (maxPointSize - minPointSize)) + minPointSize;

        d.settings = {
          ...d.settings,
          radius: pointSize,
          width: pointSize,
          height: pointSize
        };
      }
    }

    return sizedData;
  }

  /**
   * Sets series and axes data
   * 
   * @param {*} data 
   * @param {*} series 
   * @param {*} ignoredDataItems 
   * @param {*} xAxis 
   * @param {*} yAxis 
   */
  setData = (data, series, regressionSeries, ignoredDataItems, xAxis, yAxis) => {
    if (ignoredDataItems && ignoredDataItems.size > 0) {
      let dataToIgnore = Array.from(ignoredDataItems.values());

      dataToIgnore = dataToIgnore.map(v => v.dataContext.name);
      data = data.filter(d => !dataToIgnore.includes(d.varyColour));
    }

    // Set data
    if (xAxis) {
      xAxis.data.setAll(data);
    }

    if (yAxis) {
      yAxis.data.setAll(data);
    }

    series.data.setAll(data);

    if (regressionSeries) {
      let linearRegression = this.getLinearRegression(data);

      if (linearRegression) {
        regressionSeries.data.setAll(linearRegression.data);
      }
    }
  }

  /**
   * Calculates linear regression of given data
   * 
   * @param {*} actualData 
   * @returns 
   */
  getLinearRegression = actualData => {
    let xData = actualData.map(d => d.measureX);
    let yData = actualData.map(d => d.measureY);

    let sumX = xData.reduce((acc, curr) => {
      return acc + curr;
    }, 0);

    let sumY = yData.reduce((acc, curr) => {
      return acc + curr;
    }, 0);

    let sumXY = 0;
    let sumXX = 0;

    for (let i = 0; i < actualData.length; i++) {
      sumXY += xData[i] * yData[i];
      sumXX += xData[i] * xData[i];
    }

    let slope = (actualData.length * sumXY - sumX * sumY) / (actualData.length * sumXX - sumX * sumX);
    let intercept = (sumY - slope * sumX) / actualData.length;

    let regressionData = xData.map(x => ({ "x": x, "y": slope * x + intercept }));
    let yPredicted = regressionData.map(d => d.y);

    let yMean = yData.reduce((acc, curr) => acc + curr, 0) / yData.length;

    let ssr = yData.reduce((acc, curr, index) => {
      let residual = curr - yPredicted[index];
      return acc + (residual * residual);
    }, 0);

    let sst = yData.reduce((acc, curr) => {
      let residual = curr - yMean;
      return acc + (residual * residual);
    }, 0);

    let regXMin = Math.min(...actualData.map(d => d.measureX));
    let regXMax = Math.max(...actualData.map(d => d.measureX));
    let regYmin = slope * regXMin + intercept;
    let regYMax = slope * regXMax + intercept;
    let rSquared = (1 - (ssr / sst)).toFixed(2);

    if (isNaN(regXMax + regXMin + regYMax + regYmin + ssr + sst)) {
      return false;
    }

    return {
      rSquared: rSquared,
      data: [
        { "x": regXMin, "y": regYmin },
        { "x": regXMax, "y": regYMax }
      ]
    };
  };

  currentHeight;
  lastContent = undefined;

  /**
   * Updates the last content
   * 
   * @param {*} status 
   */
  updateLastContent = (status) => {
    this.lastContent = status
  }

  /**
   * Returns the contrast color of the given RGB color
   * 
   * @param {*} colorRGB 
   * @returns 
   */
  getContrastColor = (colorRGB) => {
    const yiq = (colorRGB.r * 299 + colorRGB.g * 587 + colorRGB.b * 114) / 1000;

    return yiq >= 128 ? "#000000" : "#FFFFFF"
  };

  render() {
    // Configuration component
    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    // Data component
    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    // Navigation Component
    let navigationComponent = null;
    if (this.props.navigationComponentVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      navigationComponent = renderNavigation(
        popupPosition,
        this.props,
        this.getNavigationComponent
      );
    }

    // Conditional formatting component
    let conditionalFormatComponent = null;
    if (this.props.conditionalFormattingVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      conditionalFormatComponent = renderConditionalFormatting(
        popupPosition,
        this.props,
        this.getConditionalFormattingComponent
      );
    }

    // If there is no data, return empty div
    if (!this.props.plugin.config) {
      return (
        <div>
          <div id={this.props.plugin.id} />
        </div>
      );
    }

    let pluginContainerPadding = parseInt(
      $("#grid-" + this.props.plugin.id).css("padding")
    );

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };
    
    pluginConfig.height =
      this.calculatePluginHeight(this.props.plugin, this.props.settings) -
      pluginContainerPadding * 2;

    if (isNaN(pluginConfig.height)) {
      pluginConfig.height = this.currentHeight;
    }

    if (pluginConfig.height !== this.currentHeight) {
      this.currentHeight = pluginConfig.height;
      this.props.plugin.rerender = true;
      isRerender = true;
    }

    let shouldAnimationBePlayed = this.props.isDashboardSliderActive === true && this.props.isDashboardSliderPlaying === true && this.playButton?.get("active") === false;
    let shouldAnimationBePaused = this.props.isDashboardSliderActive === true && this.props.isDashboardSliderPlaying === false && this.playButton?.get("active") === true;
    let shouldAnimationBeRewinded = this.props.isDashboardSliderActive === true && this.props.isDashboardSliderBackwarded === true;

    if (this.startAnimation && shouldAnimationBePlayed) {
      this.startAnimation(1000);
    } else if (this.stopAnimation && shouldAnimationBePaused) {
      this.stopAnimation();
    } else if (this.rewindAnimation && shouldAnimationBeRewinded) {
      this.rewindAnimation();
    }

    return (
      <>
        <div style={{ height: "100%" }}>
          <div id={this.props.plugin.id} style={{ height: calculatePluginInlineHeight(this.props.plugin.id) }} />
          {
            renderContent(
              isRerender,
              this.pluginRender,
              this.props.plugin,
              data,
              columnMap,
              pluginConfig,
              this.props.plugin.conditionalFormats,
              this.props.setPluginRerender,
              this.lastContent,
              this.updateLastContent,
            )
          }
          {configComponent}
          {dataComponent}
          {navigationComponent}
          {conditionalFormatComponent}
        </div>
      </>
    );
  }
}
