import { PIE_CHART_SET_DATA } from "./PieChartDataAction";

/**
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns 
 * 
 * Reducer for Pie Chart Data 
 */
export default function PieChartDataReducer(
  state = {
    contentObject: {
      pieChartDatas: new Map(),
    }
  },
  action
) {
  switch (action.type) {
    case PIE_CHART_SET_DATA:
      return {
        ...state,
        contentObject: {
          ...state.contentObject,
          pieChartDatas: action.payload
        }
      };
    default:
      return state;
  }
}
