import * as d3 from "d3";
import $ from "jquery";
import {
  vispeahen
} from "./vispeahen";
import {
  InsightsConfig
} from "./config";
import {
  rmvpp
} from "./rmvpp";


/**
 * @overview RM Vispeahen OBIEE module
 * @version 1.00
 * @author Minesh Patel
 */

/**
 * OBIEE integration module containing all of the functions required to interface with the BI server. This
 * includes session management, dynamic querying, metadata management and catalogue interface.
 * @exports obiee
 */
export var obiee = (function () {
  var obiee = {};

  /** Holds the RM Analytics version number. */
  var rmVersion = '1.0'; // Version number

  /* ------ WEB SERVICE INTEGRATION ------ */

  /**
   * Holds version of OBIEE WSDL file, containing SOAP structure for all available methods.
   * 11.1.1.7 has v8 as the latest, 11.1.1.9 has v9 and 12c has v12. These are generally compatible with
   * each other but the version can be changed/overidden here if necessary.
   * @memberOf module:obiee
   */

  /* ------ INTERNAL QUERY FUNCTIONS ------ */

  /** Map column names to output result set */
  function mapResults(biQuery, outputData) {
    if (typeof (outputData) === 'undefined') {
      outputData = [];
    } else {
      for (var i = 0; i < outputData.length; i++) {
        biQuery.Criteria.forEach(function (criterium, j) {
          var key = 'Column' + j;
          if (typeof outputData[i] === 'object' && key in outputData[i]) {
            outputData[i] = renameProperty(outputData[i], key, criterium.Name);
          } else {
            outputData[i][key] = null;
          }
        });
      }
    }
    return outputData;
  }

  /** Build Logical SQL from obiee.BIQuery object */
  function buildLSQL(biQuery) {
    var lsql = 'SELECT\n';

    lsql += biQuery.Criteria.map(function (d) {
      var col = parsePresVar(d.Code);
      if (d.DataType == 'numeric' && InsightsConfig.NumericToDouble)
        col = 'CAST(' + d.Code + ' AS DOUBLE)';
      return col;
    }).join('#,#\n');
    lsql += '\n#FROM# "' + biQuery.SubjectArea + '"';

    if (biQuery.Filters.length > 0) {
      var hasValues = checkFiltersHaveValue(biQuery.Filters);
      if (hasValues) {
        // Copy filter array to prevent incorrect nesting of filter objects in next step
        var filterCopy = [];
        biQuery.Filters.forEach(function (f, i) {
          filterCopy.push($.extend(true, {}, f));
        });

        // If multiple filters exist, assemble into a group
        if (filterCopy.length > 1)
          filterCopy = [new obiee.BIFilterGroup(filterCopy, '#and#')];

        lsql += '\nWHERE\n' + buildFilterLSQL(filterCopy);
      }
    }

    if (biQuery.Sort.length > 0) {
      lsql += '\nORDER BY '
      var sortArray = [];
      for (var i = 0; i < biQuery.Sort.length; i++) {
        var position = biQuery.Criteria.map(function (d, j) {
          if (biQuery.Sort[i].Name == d.Name) return j + 1;
        }); // Get position within array
        position = position.filter(function (d) {
          return typeof (d) != 'undefined';
        })[0]; // Reduce array to single element
        sortArray.push(position + ' ' + biQuery.Sort[i].SortDirection + ' NULLS LAST'); // Build LSQL
      }
      lsql += sortArray.join(', ');
    } else {
      lsql += '\nORDER BY 1 '
    }

    lsql += '\nFETCH FIRST ' + biQuery.MaxRows + ' ROWS ONLY';

    if (biQuery.Offset > 0) {
      lsql += '\nOFFSET ' + biQuery.Offset + ' ROWS';
    }

    return lsql;
  }

  /** Build LSQL for filters */
  function buildFilterLSQL(filters, groupOp) {
    var groupOp = groupOp || '';
    var lsqlArray = [];

    for (var i = 0; i < filters.length; i++) {
      var filter = filters[i],
        lsql = "";
      if (filter.Type == 'Filter') { // For group operations
        if (filter.DataType == 'decimal') {
          if (!$.isArray(filter.Value)) {
            filter.Value = [+filter.Value];
          }
        } else if (filter.DataType == 'date') {
          if (!$.isArray(filter.Value)) {
            filter.Value = [filter.Value];
          }
        }

        // Don't filter if no values present except for some operators
        var applyFilter = filter.Value.length > 0 || $.inArray(filter.Operator, ['isNull', 'isNotNull']) > -1;
        if (filter.DataType == 'date' && !filter.Value[0])
          applyFilter = false;

        if (applyFilter) {
          // Escape single quotes in value
          var value;
          if (!$.isArray(filter.Value)) { // Allow both strings and arrays (for IN/NOT IN)
            value = filter.Value.toString().replace(/'/g, "''");
          } else {
            value = [];
            filter.Value.forEach(function (f) {
              value.push(f.toString().replace(/'/g, "''"));
            });
          }

          var valueQuoted = value; // Put quotes around string values

          // Apply quotes if filter is a plain values type
          switch (filter.ValueType) {
            case ('value'):
              switch (filter.DataType) {
                case 'string':
                  valueQuoted = "'" + value + "'";
                  break;
                case 'date':
                  if (value instanceof Date)
                    value = d3.timeFormat('%Y-%m-%d')(value)
                  valueQuoted = "date '" + value + "'";
                  break;
              }
              break;

            case "repVar":
              valueQuoted = "VALUEOF(" + value + ")";
              break;
            case "sessionVar":
              valueQuoted = "VALUEOF(NQ_SESSION." + value + ")";
              break;
          }

          switch (filter.Operator) {
            case "equal":
              lsql = filter.Code + " #=# " + valueQuoted;
              break;
            case "notEqual":
              lsql = filter.Code + " #<># " + valueQuoted;
              break;
            case "in":
              lsql = filter.Code + " #in# " + buildInString(filter);
              break;
            case "notIn":
              lsql = filter.Code + " #not in# " + buildInString(filter);
              break;
            case "greater":
              lsql = filter.Code + " #># " + buildInString(filter);
              break;
            case "greaterOrEqual":
              lsql = filter.Code + " #>=# " + buildInString(filter);
              break;
            case "less":
              lsql = filter.Code + " #<# " + buildInString(filter);
              break;
            case "lessOrEqual":
              lsql = filter.Code + " #<=# " + buildInString(filter);
              break;
            case "like":
              lsql = filter.Code + " #LIKE# " + valueQuoted;
              break;
            case "contains":
              lsql = filter.Code + " #LIKE# " + "'%" + value + "%'";
              break;
            case "starts":
              lsql = filter.Code + " #LIKE# " + "'" + value + "%'";
              break;
            case "ends":
              lsql = filter.Code + " #LIKE# " + "'%" + value + "'";
              break;
            case "top":
              lsql = "TOPN(" + filter.Code + "," + value + ") #<=# " + value;
              break;
            case "bottom":
              lsql = "BOTTOMN(" + filter.Code + "," + value + ") #<=# " + value;
              break;
            case "isNull":
              lsql = filter.Code + " #IS NULL# ";
              break;
            case "isNotNull":
              lsql = filter.Code + " #IS NOT NULL# ";
              break;
            default:
              throw 'Unexpected operator "' +
                filter.Operator +
                '". LSQL could not be generated.';
              break;
          }
        }
      } else
        lsql = "(" + buildFilterLSQL(filters[i].Filters, filters[i].Operator) + ")";
      lsqlArray.push(lsql);
    }
    lsqlArray = lsqlArray.filter(function (l) {
      return l;
    });
    lsql = lsqlArray.join(" " + groupOp + " ");
    return lsql;
  }

  /** Build LSQL string for IN/NOT IN filters */
  function buildInString(filter) {
    var valueArray = filter.Value; // Expects array of values for 'in' filters
    if (typeof (filter.Value) == 'string') // If input is a string, split by ;
      valueArray = filter.Value.split(';');

    valueArray = valueArray.map(function (d) {
      switch (filter.ValueType) {
        case ('value'):

          switch (filter.DataType) {
            case ('varchar'):
              break;
            case ('string'):
              break;
            case ('numeric'):
              break;
            case ('integer'):
              break;
            case ('float'):
              break;
            case ('double'):
              break;
            case ('decimal'):
              break;
            case ('date'):
              break;
            case ('character varying'):
              break;
            case ('timestamp'):
              break;
            default:
              alert("invalid data type");
              throw 'Invalid data type found in ';
          }

          if (filter.DataType == 'date') {
            if (d instanceof Date)
              d = d3.timeFormat('%Y-%m-%d')(d)
            return "date '" + d + "'";
          } else if (filter.DataType.includes('timestamp')) {
            return "'" + d + "'";
          } else if (filter.DataType == 'string') {
            return "'" + d.replace(/'/g, "''") + "'";
          } else if (filter.DataType == 'character varying') {
            return "'" + d + "'";
          } else
            return d;
          break;
        case ('repVar'):
          return 'VALUEOF(' + d + ')';
          break;
        case ('sessionVar'):
          return 'VALUEOF(NQ_SESSION.' + d + ')'
          break;
        default:
          return d;
          break;
      }
    });

    return '(' + valueArray.join(', ') + ')';
  }

  /** Get short operator for a filter */
  function getShortOperator(operator) {
    var op;

    switch (operator) {
      case ('equal'):
        op = '=';
        break;
      default:
        op = '';
        break;
    }

    return op;
  }

  /* ------ END OF INTERNAL QUERY FUNCTIONS ------ */

  /** Guid creator - uuid */
  function guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  /* ------ PUBLIC RMVPP FUNCTIONS ------ */

  /** Function to rename data properties based on column map for a specific visualisation */
  /* For public access */
  obiee.mapData = function (data, columnMap) {
    return mapData(data, columnMap);
  }

  /** Function to rename data properties based on column map for a specific visualisation */
  function mapData(data, columnMap) {
    for (var i = 0; i < data.length; i++) {
      for (let prop in columnMap) {
        if ($.isArray(columnMap[prop])) { // For grouped attributes, transform to sub-array
          var valueArray = [];
          for (var j = 0; j < columnMap[prop].length; j++) {
            let value = {
              'name': columnMap[prop][j].Name,
              'value': data[i][columnMap[prop][j].Name]
            };
            if ($.inArray(columnMap[prop][j].DataType, ['integer', 'double']) > -1)
              value.value = +value.value;
            valueArray.push(value);
          }
          data[i][prop] = valueArray;
        } else {
          if ('Name' in columnMap[prop])
            data[i] = renameProperty(data[i], columnMap[prop].Name, prop);
          if ($.inArray(columnMap[prop].DataType, ['integer', 'double']) > -1)
            data[i][prop] = +data[i][prop];
        }
      }
    }
    return data;
  }

  /** Cleanup any inconsistent objects in the dashboard before saving */
  function cleanupDashboard(db) {
    db.Visuals.forEach(function (vis, i) {
      vis.ID = i;
      vis.resetColumnConfig(true);
    });

    // Remove any interactions if their visualisations are not present in the dashboard page
    var removeInts = [];
    db.Interactions.forEach(function (int) {
      int.SourceNum = int.SourceVis.ID;
      int.TargetNum = int.TargetVis.ID;
      if ($.inArray(int.SourceVis, db.Visuals) == -1)
        removeInts.push(int);
      else if ($.inArray(int.TargetVis, db.Visuals) == -1)
        removeInts.push(int);
    });
    removeInts.forEach(function (int) {
      $.removeFromArray(int, db.Interactions);
    })

    // Remove any dashbaord filter values
    if (db.Prompts.Filters) {
      db.Prompts.Filters.forEach(function (filter) {
        if (filter.DataType == 'string')
          filter.Value = [];
      });
    }
  }

  /* ------ END OF PUBLIC RMVPP FUNCTIONS ------ */

  /* ------ GENERAL INTERNAL FUNCTIONS ------ */

  /** Get cookie value by name */
  function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
  }

  /** Create a cookie with a name value, time length and export path. */
  function createCookie(name, value, days, path) {
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      var expires = "; expires=" + date.toGMTString();
    } else var expires = "";
    document.cookie = name + "=" + value + expires + "; path=" + path;
  }

  /** Delete a cookie. */
  function eraseCookie(name, path) {
    createCookie(name, "", -1, path);
  }

  /** Rename a property of an object */
  function renameProperty(obj, oldName, newName) {
    // Do nothing if the names are the same
    if (oldName == newName) {
      return obj;
    }
    // Check for the old property name to avoid a ReferenceError in strict mode.
    if (obj.hasOwnProperty(oldName)) {
      obj[newName] = obj[oldName];
      delete obj[oldName];
    }
    return obj;
  };

  /** Parse information for a column recieved from the BI Server. */
  function parseColumnInfo(results) {
    var columnInfo = {
      isMeasure: false,
      hasSortKey: false,
      hasIDField: false,
      isTimeDim: false,
      partOfHierarchy: false,
      aggRule: 'none',
      description: '',
      dataType: 'varchar'
    };

    if (results.hasOwnProperty('Column1')) {
      switch (results.Column1) {
        case '1':
          columnInfo.dataType = 'varchar';
          break;
        case '2':
          columnInfo.dataType = 'numeric';
          break;
        case '4':
          columnInfo.dataType = 'integer';
          break;
        case '7':
          columnInfo.dataType = 'float';
          break;
        case '8':
          columnInfo.dataType = 'double';
          break;
        case '91':
          columnInfo.dataType = 'date';
          break;
      }
    }

    // Check if measure (column 4)
    if (results.hasOwnProperty('Column4')) {
      if (results.Column4 == '2')
        columnInfo.isMeasure = true;
    }

    // Check for special properties (column 7)
    if (results.hasOwnProperty('Column7')) {
      if (results.Column7.indexOf('sortkeypresent') > -1)
        columnInfo.hasSortKey = results.Column8;

      if (results.Column7.indexOf('codedField') > -1)
        columnInfo.hasIDField = true;

      if (results.Column7.indexOf("istimedimension") > -1)
        columnInfo.isTimeDim = true;
    }

    if (results.hasOwnProperty('Column5'))
      columnInfo.aggRule = results.Column5.toLowerCase();

    if (results.hasOwnProperty('Column13'))
      columnInfo.description = results.Column13;

    return columnInfo;
  }

  /** Check if an OBIEE response set is an error. */
  function isError(results) {
    return results.hasOwnProperty('faultcode') && results.hasOwnProperty('faultstring');
  }

  /**
   * Gets the relevant error objects from a BI Server web service response. Assumes there is an error in the input at `response.detail.Error.Error.Error`.
   * Uses RegEx to clean up response, removing some unnecessary information like HY000 codes.
   * @param {Object} response
   * @returns {Object} Object describing the error. Has two properties: `basic`, a short description and `sql`, the logical SQL executed.
   */
  obiee.getErrorDetail = function (response) {
    if (response.detail) {
      var errors = response.detail.Error.Error.Error;
      var tidyError = '';
      // Example: State: HY000. Code: 10058. [NQODBC] [SQL_STATE: HY000] [nQSError: 10058] A general error has occurred. [nQSError: 43113] Message returned from OBIS.

      var coreMessage = true;
      errors.forEach(function (e) {
        let msg = e.Message.replace(/.*]/g, '').replace(/\(HY000\)/, '').replace(/SQL Issued: .*/, '')
        var re = RegExp('SQL Issued:');
        if (re.exec(e.Message))
          coreMessage = false;

        if (coreMessage)
          tidyError += msg;
      });

      var error = {
        basic: tidyError,
        sql: errors[1].Message
      }
    } else
      var error = {
        basic: response
      }
    return error;
  }

  /* ------ GENERAL INTERNAL FUNCTIONS ------ */

  /* ------ BI OBJECT FUNCTIONS ------ */

  /**
   * Search for filter using a `BIFilter` object and replace it.
   * @param {BIFilter[]} filters Filter array to iterate over. Can include `BIFilterGroup` objects as function will recurse if necessary.
   * @param {BIFilter} filter New filter to replace if a match is found in the array/hierarchy.
   * @param {boolean} changed Indicates whether this filter array has been modified.
   * @returns {boolean} True if the filter hierarchy has been modified by the function.
   */
  obiee.replaceFilter = function (filters, newFilter, changed, protectedSelect) {
    var code = newFilter.Code,
      changed = changed || false;
    for (var j = 0; j < filters.length; j++) {
      if (filters[j].Type == "Filter") {
        if (filters[j].Code == code && filters[j].Operator == newFilter.Operator) {
          if (!filters[j].Protected) {
            filters[j] = newFilter;
            if (filters[j]["Column"].remove) {
              changed = true;
              filters.splice(j, 1);
            } else {
              filters[j] = newFilter;
              changed = true;
            }
          } else {
            changed = "protected";

            if (j == filters.length - 1 && protectedSelect == undefined) {
              filters.push(newFilter);
            }

            if (protectedSelect == 2) {
              /*Value of filter protected option button*/
              filters.push(newFilter);
              if (filters.length > 1) {
                /*adding AND filter if multiple filter exists*/
                filters = [new obiee.BIFilterGroup(filters, '#and#')];
              }
            }
            if (protectedSelect == 1) {
              /*Value of cover protected option button*/
              if (!filters[j].Value.includes(newFilter.Value[0])) {
                filters[j].Value.push(newFilter.Value[0]);
              }
            }
          }
        }
      } else {
        changed = obiee.replaceFilter(filters[j].Filters, newFilter, changed); // Recursively loop through filter groups
      }
    }
    return changed;
  };

  /**
   * Identifies if a visualisation is affected by any of the dashboard prompt filters on a page.
   * Will match if *any* filter that is a member of prompts.Filters has a matching subject area to the visualisation.
   * @param {BIVisual} vis Visualisation to check if prompted.
   * @param {BIPrompt} prompts Prompt object containing all dashboard prompt filters.
   * @returns {boolean} Indicates whether or not the visaulisation is prompted.
   */
  obiee.isPrompted = function (vis, prompts) {
    var refresh = false;
    if (!$.isEmptyObject(prompts)) {
      prompts.Filters.forEach(function (f) {
        if (f.SubjectArea == vis.Query.SubjectArea)
          refresh = true;
      });
    }
    return refresh;
  };

  /**
   * Similar to `obiee.replaceFilter` but matches filters using the `ID` property instead of the code.
   * @param {BIFilter[]} filters Filter array to iterate over. Can include `BIFilterGroup` objects as function will recurse if necessary.
   * @param {string} filterID ID of the filter to replace if a match is found in the array/hierarchy.
   * @param {string} filterOp New operator to assign if a match is found.
   * @param {string} filterValue New value to assign if a match is found.
   * @param {boolean} changed Indicates whether this filter array has been modified.
   * @returns {boolean} True if the filter hierarchy has been modified by the function.
   */
  obiee.replaceFilterByID = function (filters, filterID, filterOp, filterValue, changed) {
    changed = changed || false;
    for (var j = 0; j < filters.length; j++) {
      if (filters[j].Type == 'Filter') {
        if (filters[j].ColumnID == filterID) {
          if (!filters[j].Protected) {
            filters[j].Value = filterValue;
            filters[j].Global = false;
            if (filterOp)
              filters[j].Operator = filterOp;
            changed = true;
          } else
            changed = 'protected';
        }
      } else
        changed = obiee.replaceFilterByID(filters[j].Filters, filterID, filterOp, filterValue, changed); // Recursively loop through filter groups
    }
    return changed;
  }

  /** Check if any filter has a value */
  function checkFiltersHaveValue(filters, hasValue) {
    hasValue = hasValue || false;
    filters.forEach(function (f, i) {
      if (f.Type == 'Filter') {
        var bool = true;
        if (f.DataType != 'date' && f.DataType != 'decimal') {
          if ($.inArray(f.Operator, ['isNull', 'isNotNull']) > -1) {
            hasValue = true;
          } else if (f.Value.length > 0) {
            if ($.isArray(f.Value)) {
              f.Value.forEach(function (v) {
                if (v)
                  hasValue = true;
              });
            } else {
              if (f.Value)
                hasValue = true;
            }
          }
        } else {
          if (f.DataType == 'decimal') {
            if ($.isArray(f.Value)) {
              f.Value.forEach(function (v) {
                if (!isNaN(v)) {
                  f.Value[f.Value.indexOf(v)] = +v;
                  hasValue = true;
                }
              });
            } else {
              if (!isNaN(f.Value)) {
                f.Value = +f.Value;
                hasValue = true;
              }
            }

          } else {
            if (f.Value) {
              hasValue = true;
            }
          }
        }
      } else
        hasValue = checkFiltersHaveValue(f.Filters, hasValue);
    });
    return hasValue;
  }

  /**
   * Show visualisation unless it is included in a visual selector.
   * @param {BIVisualSelector[]} visSelectors Array of visualisation selectors
   * @param {BIVisual} vis Visualisation to check
   * @returns {boolean} True if the visualisation can be shown, false if hidden
   */
  obiee.showOrHideVis = function (visSelectors, vis) {
    var show = true;
    visSelectors.forEach(function (vs) {
      var enabled = vs.Visuals.filter(function (f) {
        return f.enabled;
      }).map(function (f) {
        return f.name;
      });

      if ($.inArray(vis.Name, enabled) > -1 && vis.Name != vs.Selected) {
        show = false;
      }
    });
    return show;
  }

  /**
   * Flattens column map object (containing a mixture of properties and arrays) to an object of just properties.
   * Multiple columns use the property suffixed with a numeric index. Excludes null columns (columns without a Code defined).
   * @param {Object} columnMap Column Map objects have properties containing `BIColumn` objects or arrays of them.
   * The precise structure is determined by the plugin itself.
   * @param {boolean} dimOnly Indicates whether or not to exclude measure attributes.
   * @returns {Object} Object where properties are column IDs (specfied by the plugin)
   */
  obiee.simplifyColumnMap = function (columnMap, dimOnly) {
    var simple = {}

    function colCheck(col) {
      var out = false;
      if (dimOnly) {
        out = col.Measure == 'none' && col.Code;
      } else {
        out = col.Code;
      }
      return out;
    }

    for (let col in columnMap) {
      if ($.isArray(columnMap[col])) {
        columnMap[col].forEach(function (c, i) {
          var check = colCheck(c)
          if (check) {
            simple[col + i] = c;
          }
        });
      } else {
        var check = colCheck(columnMap[col])
        if (check)
          simple[col] = columnMap[col];
      }
    }
    return simple;
  }

  /**
   Applies a function to all columns in column map.
   * @param {object} columnMap Column Map objects have properties containing `BIColumn` objects or arrays of them.
   * The precise structure is determined by the plugin itself.
   * @param {function} func The function to apply to each of the `BIColumn` objects.
   */
  obiee.applyToColumnMap = function (columnMap, func) {
    for (let col in columnMap) {
      if ($.isArray(columnMap[col])) {
        columnMap[col].forEach(function (c) {
          func(c, obiee.getColIDFromName(c.Name, columnMap));
        });
      } else {
        func(columnMap[col], col);
      }
    }
    return columnMap;
  }

  /**
   Removes a column from a column map - either removing from an array for a multiple, or setting to a null `BIColumn` for a single.
   * @param {object}  columnMap Column Map objects have properties containing `BIColumn` objects or arrays of them.
   * @param {BIColumn} findCol Column to remove from the map.
   */
  obiee.removeFromColumnMap = function (columnMap, findCol) {
    for (let col in columnMap) {
      if ($.isArray(columnMap[col])) {
        $.removeFromArray(findCol, columnMap[col]);
      } else {
        if (columnMap[col] == findCol)
          columnMap[col] = new obiee.BIColumn();
      }
    }
    return columnMap;
  }

  /** Gives an appropriate null value depending on the type of column, i.e. 0 for measures, '' otherwise. */
  function nullVal(col) {
    return col.Measure == 'none' ? '' : 0;
  }

  /**
   Generates a null datum based on a column map. This will have the same structure as the
   real dataset but 0s for measures and null values for attributes
   * @param {Object} columnMap Column Map objects have properties containing `BIColumn` objects or arrays of them.
   * The precise structure is determined by the plugin itself.
   * @returns {Object} Single object matching the data structure produced by the column map but with null values.
   */
  obiee.nullDatum = function (columnMap) {
    var newDatum = {};
    for (let col in columnMap) {
      if ($.isArray(columnMap[col])) {
        newDatum[col] = [];
        columnMap[col].forEach(function (c, i) {
          newDatum[c.Name] = c.Measure == 'none' ? '' : '0'; // 0 should be a string here to match dataset
          newDatum[col][i] = {
            name: c.Name,
            value: nullVal(c)
          };
        });
      } else {
        newDatum[col] = nullVal(columnMap[col]);
      }
    }
    return newDatum;
  }

  /**
   * Get true column name from a simplified column map ID.
   * @param {string} id Simplified column map ID to retrieve.
   * @param {Object} columnMap Column Map objects have properties containing `BIColumn` objects or arrays of them.
   * The precise structure is determined by the plugin itself.
   * @returns {string} Column name as specified by the `Name` attribute of the `BIColumn` object.
   * @see simplifyColumnMap
   * @see getColIDFromName
   */
  obiee.getColNameFromID = function (id, columnMap) {
    var cmByID = obiee.simplifyColumnMap(columnMap);
    if (cmByID[id])
      return cmByID[id].Name;
    else
      return '';
  }

  /**
   * Returns the property code from a column ID. Strips the index away if it is defined as a multiple.
   * @returns {string}
   */
  obiee.getPropFromID = function (id) {
    var property = id,
      re = new RegExp('(.*?)\\d').exec(id);
    if (re)
      property = re[1];
    return property;
  };

  /**
   * Get simplified column map ID from a true column name.
   * @param {string} name Column name as specified by the `Name` attribute of the `BIColumn` object.
   * @param {Object} columnMap Column Map objects have properties containing `BIColumn` objects or arrays of them.
   * The precise structure is determined by the plugin itself.
   * @returns {string} Column ID as represented by the simplified column map.
   * @see simplifyColumnMap
   * @see getColIDFromID
   */
  obiee.getColIDFromName = function (name, columnMap) {
    var sm = obiee.simplifyColumnMap(columnMap),
      output;
    for (let prop in sm) {
      if (sm[prop].Name == name)
        output = prop;
    }
    return output;
  }

  /**
   * Translation function for filter operators.
   * @param {string} op Textual operator ID, e.g. equal, less, notEqual, greater, lessOrEqual, heatmap etc.
   * @returns {string} Readable operator name, e.g. ==, <, !=, >, <=, Heatmap etc.
   */
  obiee.operatorToText = function (op) {
    var output;
    switch (op) {
      case 'equal':
        output = '==';
        break;
      case 'less':
        output = '<';
        break;
      case 'notEqual':
        output = '!='
        break;
      case 'greater':
        output = '>';
        break;
      case 'lessOrEqual':
        output = '<=';
        break;
      case 'greaterOrEqual':
        output = '>='
        break;
      case 'heatmap':
        output = 'Heatmap'
        break;
    }
    return output;
  }

  /** Get index from column shorthand */
  function columnIdx(id) {
    var index = -1,
      re = new RegExp('(\\d*)$').exec(id);
    if (re)
      index = +re[1];
    return index;
  }

  /** Get value from row based on the shorthand index */
  function getValueFromRow(row, id) {
    var re = new RegExp('(.*?)\\d'),
      col = id,
      value;
    if (re.exec(id)) {
      col = re.exec(id)[1];
      value = row[col][columnIdx(id)].value;
    } else
      value = row[col];

    return value;
  }

  /** Get columns available for an interaction */
  function getDefaultColumns(interact) {
    var cm = interact.SourceVis.ColumnMap,
      passCols = {},
      trigger = interact.Trigger
    var restrict = rmvpp.Plugins[interact.SourceVis.Plugin].actions.filter(function (a) {
      return a.trigger == trigger;
    });
    if (restrict.length > 0)
      restrict = restrict[0].output;
    else
      restrict = [];

    for (let key in cm) {
      if (restrict.length == 0 || ($.inArray(key, restrict) > -1)) {
        if ($.isArray(cm[key])) {
          cm[key].forEach(function (m, i) {
            if (m.Measure == "none" && m.Code)
              passCols[key + i] = true;
          });
        } else {
          if (cm[key].Measure == "none" && cm[key].Code)
            passCols[key] = true;
        }
      }
    }
    return passCols;
  }

  /** Checks LSQL column code for presentation variables and parses them */
  function parsePresVar(colCode) {
    var findPresVars = new RegExp("@\{.*?\}{.*?\}{.*?\}|@\{.*?\}{.*?\}|\@\{.*?\}", 'g');
    var presVars = colCode.match(findPresVars)

    // Returns either the variable value or the default
    function varOrDefault(varName, dVal, opt) {
      dVal = dVal || "''"; // Set defaults
      opt = opt || "outer";

      var presVar = obiee.getVariable(varName, 'Presentation'),
        out;
      if (presVar.Value) { // Parse presentation value according to option
        var value = presVar.Value;

        if (opt == 'outer') {
          value = value.join(',');
          value = "'" + value + "'";
        } else if (opt == 'inner') {
          value = value.join("','");
          value = "'" + value + "'";
        }
        out = value;
      } else { // Default value
        if (opt == 'outer' || opt == 'inner')
          dVal = "'" + dVal + "'";
        out = dVal;
      }
      return out;
    }

    if (presVars) {
      presVars.forEach(function (pvar) {
        pvar = $.trim(pvar);
        var numOpt = pvar.match(/\{/g);
        if (numOpt.length == 1) { // Only presentation variable defined
          var name = /@\{(.*?)\}/.exec(pvar)[1];
          colCode = colCode.replace(pvar, varOrDefault(name));
        } else if (numOpt.length == 2) { // Pres var and default defined
          var split = /@\{(.*?)\}{(.*?)\}/.exec(pvar);
          var name = split[1],
            dVal = split[2];
          colCode = colCode.replace(pvar, varOrDefault(name, dVal));
        } else if (numOpt.length == 3) {
          var split = /@\{(.*?)\}{(.*?)\}{(.*?)\}/.exec(pvar);
          var name = split[1],
            dVal = split[2],
            opt = split[3];
          colCode = colCode.replace(pvar, varOrDefault(name, dVal, opt));
        }
      });
    }

    return colCode
  }

  /**
   * Updates or adds a presentation variable to `obiee.BIVariables`.
   * @param {string} name Name for the variable.
   * @param {string} value Value the presentation variable should take. This needs to parse verbatim as logical SQL,
   * for example strings should be encased in single quotes.
   */
  obiee.updatePresVar = function (name, value) {
    var exists = obiee.getVariable(name, 'Presentation');
    if (exists) {
      exists.Value = value;
    } else {
      var newVar = new obiee.BIVariable(name, 'Presentation', value);
      obiee.BIVariables['Presentation'].push(newVar);
    }
  }

  /**
   * Get variable value by name and type.
   * @param {string} name Variable name, should be in CAPS for session/repository variables.
   * @param {string} type Variable type, should be one of 'Repository', 'Session'.
   * @returns {string|Array} Array of values for the variable. Repository variables will only have one element maximum.
   */
  obiee.getVariable = function (name, type) {
    var variable = obiee.BIVariables[type].filter(function (v) {
      return v.Name == name && type == v.VarType;
    });

    if (variable.length > 0) {
      return variable[0];
    } else
      return false;
  }

  /**
   * Activate triggers and functions for interactions.
   * @param {BIInteraction|BIDrilldown} interaction Interaction or drilldown to activate.
   * @param {scope} Angular scope to pass through for triggering dashboard wide events.
   */
  obiee.createInteraction = function (interaction, scope) {
    if (interaction.Action == 'drill')
      interaction.Handler = vispeahen.generateDrillHandler(interaction, scope);
    else
      interaction.Handler = vispeahen.generateHandler(interaction.Action, interaction.SourceVis, interaction.TargetVis, interaction.Columns, scope);

    var visElement = interaction.SourceVis.Container;
    let interactionData = {}; // Data to be stored in the event handler itself
    interactionData.Action = interaction.Action;
    interactionData.SourceNum = interaction.SourceNum;
    interactionData.Columns = interaction.Columns;

    if (interaction.Action == 'drill')
      interactionData.DrillPath = interaction.DrillPath;
    else
      interactionData.TargetNum = interaction.TargetNum;

    $(visElement).on(interaction.Trigger, '', interactionData, interaction.Handler); // Use the 'data' object in the jQuery 'on' function to store the mapping
  }

  /**
   * Remove an interaction from a visualisation container.
   * @param {BIInteraction|BIDrilldown} action Interaction or drilldown to deactivate.
   */
  obiee.removeInteraction = function (action) {
    $(action.SourceVis.Container).off(action.Trigger, action.Handler);
  }

  /* ------ END OF BI OBJECT FUNCTIONS ------ */

  /* ------ BI CLASS OBJECTS ------ */

  /**
   * @class
   * Presentation column object.
   * @param {string} code OBIEE column code, typically of the form "Table"."Column" but can also be a formula.
   * @param {string} name Column name.
   * @param {string} [dataType=varchar] OBIEE defined data type. Can be one of `varchar`, `integer`, `double`, `date`..
   * @param {string} [table=Unspecified] Table name.
   * @param {string} [aggRule=none] Aggregation rule, e.g.`none`, `sum`, `avg`.
   * @param {string} [subjectArea] Subject area this column belongs to.
   * @param {string} [dataFormat=this.getDefaultFormat] Specify a D3 data formatting string.
   * @param {object} [config={}] Column specific configuration object.
   */
  obiee.BIColumn = function (code, name, dataType, table, aggRule, subjectArea, dataFormat, config, locale, sorting, sortDirection, sortOder) {
    /** Column code. */
    this.Code = code;

    /** Column name. */
    this.Name = name;

    /** Column description. */
    this.Description = "";

    /** Data type. Can be one of `varchar`, `integer`, `double`, `date`, `timestamp`. */
    this.DataType = dataType || 'varchar';

    /** Presentation table name. */
    this.Table = table || "Unspecified";

    /** Aggregation rule, e.g.`none`, `sum`, `avg`. */
    this.Measure = aggRule || "none";

    /** Column ID, defined as Table.Name. */
    this.ID = this.Table + '.' + name;

    /**  Subject area this column belongs to. */
    this.SubjectArea = subjectArea || '';

    /** Holds the column ID of a sort column if one has been defined in OBIEE. */
    this.SortKey = false;

    /** Holds sorting values */
    this.Sorting = sorting || false;
    this.SortDirection = sortDirection || "asc";
    this.SortOrder = sortOder || 0;

    /** Convert other data types to known data types */
    const timestampTypes = ["timestamp without time zone"];
    const doubleTypes = ['float', 'double precision'];
    const integerTypes = ['bigint'];

    if ($.inArray(this.DataType, timestampTypes) != -1) {
      this.DataType = "timestamp";
    }

    if ($.inArray(this.DataType, doubleTypes) != -1) {
      this.DataType = "double";
    }
    if ($.inArray(this.DataType, integerTypes) != -1) {
      this.DataType = "integer";
    }

    /**
     Generates default D3 format string based on the OBIEE datatype via a simple switch. E.g. `double` produces a format of `.3s`.
     @returns {String} D3 format string
     */
    this.getDefaultFormat = function () {
      var formatString;
      switch (this.DataType) {
        case 'double':
          formatString = InsightsConfig.DataFormats.double;
          break;
        case 'numeric':
          formatString = InsightsConfig.DataFormats.numeric;
          break;
        case 'integer':
          formatString = InsightsConfig.DataFormats.integer;
          break;
        case 'date':
          formatString = InsightsConfig.DataFormats.date;
          break;
        case 'timestamp':
          formatString = InsightsConfig.DataFormats.timestamp;
          break;
        case 'varchar':
          formatString = InsightsConfig.DataFormats.varchar;
          break;
        default:
          formatString = '%s';
          break;
      }
      return formatString;
    }

    /** Locality of the column for formatting purposes. Defaults to `rmvpp.defaults.locale`. */
    this.Locale = locale || InsightsConfig.Locale;

    /** D3 data format string. Defaults using `getDefaultFormat`. */
    this.DataFormat = dataFormat || this.getDefaultFormat();

    /**
     Formats a value using D3
     @param value Value to be formatted
     @param {String} [formatString=this.DataFormat] D3 format string to format with
     @returns Formatted value.
     */
    this.format = function (value, formatString) {
      formatString = formatString || this.DataFormat;
      var formatted = value,
        locale = this.Locale;

      function customAbbrev(formatString, value) {
        var s = rmvpp.locales[locale].numberFormat(formatString)(value);
        switch (s[s.length - 1]) {
          case "k":
            if (InsightsConfig.SIPrefixes.hasOwnProperty('k'))
              s = s.slice(0, -1) + InsightsConfig.SIPrefixes.k;
            break;
          case "M":
            if (InsightsConfig.SIPrefixes.hasOwnProperty('M'))
              s = s.slice(0, -1) + InsightsConfig.SIPrefixes.M;
            break;
          case "G":
            if (InsightsConfig.SIPrefixes.hasOwnProperty('G'))
              s = s.slice(0, -1) + InsightsConfig.SIPrefixes.G;
            break;
        }
        return s;
      }

      function numFormat(formatString, value) {
        if (value || value == 0) {
          if (formatString.indexOf('s') > -1) {
            return customAbbrev(formatString, value);
          } else
            return rmvpp.locales[locale].numberFormat(formatString)(value);
        } else {
          return '';
        }
      }

      function formatDate(date) {
        let d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) {
          month = '0' + month;
        }
        if (day.length < 2) {
          day = '0' + day;
        }

        return [year, month, day].join('-');
      }

      switch (this.DataType) {
        case 'double':
          formatted = numFormat(formatString, value);
          break;
        case 'integer':
          formatted = numFormat(formatString, value);
          break;
        case 'numeric':
          formatted = numFormat(formatString, value);
          break;
        case 'date':
          var dateValue;

          if (value instanceof Date) {
            dateValue = value;
          } else {
            let date = new Date(value) !== "Invalid Date" ? formatDate(new Date(value)) : null; // Returns a Date - Y-m-d format

            if (date !== null) {
              dateValue = rmvpp.locales[this.Locale].timeFormat("%Y-%m-%d").parse(date); // Returns a Date
            }
          }

          formatted = rmvpp.locales[this.Locale].timeFormat(formatString)(dateValue);
          break;
        case 'timestamp':
          var dateValue;
          if (value.includes('T') == false) {
            const regex = /(.*)(\s)(.*)(\.)(.*)/;
            let parsedValue = regex.exec(value);

            if (parsedValue == null || parsedValue == undefined || parsedValue == "") {
              formatted = value;
            } else {
              // Convert timestamp without time zone format to timestamp format
              if (parsedValue.length > 2) {
                value = parsedValue[1] + 'T' + parsedValue[3];
              }
            }
          }

          if (value instanceof Date) {
            dateValue = value;
          } else {
            dateValue = rmvpp.locales[this.Locale].timeFormat("%Y-%m-%dT%H:%M:%S").parse(value); // Returns a Date
          }

          if (dateValue == null || dateValue == "") {
            dateValue = value;
          } else {
            formatted = rmvpp.locales[this.Locale].timeFormat(formatString)(dateValue);
          }
          break;
        case 'varchar':
          if (formatString != '%s' && formatString)
            formatted = formatString.replace(/%s/g, value);
          break;
        default:
          break;
      }
      return formatted;
    }

    var column = this;
    /**
     Verifies this column using a call to OBIEE. Will identify sort keys and aggregation rules for the column and also determines if the column formula is valid.
     @param {function} successFunc Callback function to execute on success
     @param {function} errorFunc Callback function to execute on failure
     @returns {Object} Object describing column, including description, sort column, aggregation rule and whether or not is part of a chronological dimension.
     */
    this.verify = function (successFunc, errorFunc) {
      var lsql = "call NQSGetQueryColumnInfo('SELECT ";
      lsql += parsePresVar(this.Code).replace(/'/g, "''");
      lsql += " FROM \"" + this.SubjectArea + "\"')";

      obiee.executeLSQL(lsql, function (results) {
        if (isError(results)) {
          console.log(obiee.getErrorDetail(results));
          throw 'Error';
        } else {
          var columnInfo = parseColumnInfo(results[0]);
          column.Verified = true;
          column.Measure = columnInfo.aggRule;
          column.SortKey = columnInfo.hasSortKey;
          if (column.DataType != 'timestamp' && column.DataType != 'timestamp without time zone') { // Timestamp not returned as a data type from this function
            column.DataType = columnInfo.dataType;
          }
          successFunc(columnInfo);
        }
      }, false, errorFunc);
    }

    /** Contains all column and plugin specific configuration information. Parameters specified by `columnMappingParameters` on plugins. */
    this.Config = config || {};

    /** Indicates whether this object has been verified. */
    this.Verified = false;

    /** Hardcoded type identifier for the object: `Column`. */
    this.Type = 'Column';
  }

  /* ------ END OF BI CLASS OBJECTS ------ */

  /* ------ PAGE INITIALISATION ------ */

  /** Stores BI server and session variables on page load based on the user's session. */
  obiee.BIVariables = {};

  /* ------ END OF PAGE INITIALISATION ------ */

  return obiee;

}());