export const configuration = {
    isMulti: true,
    hideLabel: false,
    showHideButton: false,
    selectedColor: "#333333"
};

export const actions = [
    {
        trigger: "clickFeature",
        type: "click",
        name: "Tıklama Feature",
        output: ["plaka", "title"],
        description: "ClickFeatureDesc"
    },
    {
        trigger: "doubleClickFeature",
        type: "click",
        name: "Çift Tıklama Feature",
        output: ["plaka"],
        description: "DoubleClickFeatureDesc"
    },
    {
        trigger: "hoverMapObject",
        type: "hover",
        name: "Hover",
        output: ["plaka"],
        description: "HoverMapObjectDesc"
    }
];

export const reactions = [
    {
        id: "filter",
        name: "Filter",
        description: "desc87",
        type: "general"
    }
];

export const conversionMap = {
    "table": {
        columnMap: {
            columns: {
                name: "Columns",
                from: ["value", "plaka", "title", "ilceId"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        }
    }
};