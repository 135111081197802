import React, { Component } from "react";
import { Icon } from "antd";
import i18n from "../../Utils/i18next";

/**
 * Shows user's action status in progress
 */
export default class UserActivationLoading extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={"empty-dashboard"}>
        <span>
          <Icon type="loading" className={"icon-in-text"}/>
          <span>{i18n.t("UserEntrance.Activation.Loading")}</span>
        </span>
      </div>
    );
  }
}
