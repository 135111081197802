import React, { Component } from "react";
import ColumnList from "./ColumnList";
import EditableContent from "./EditableContent";
import $ from "jquery";
import uuid from "react-uuid";
import { returnConditionalListName } from "../RenderConditionalListName";
import "../conditionalFormatting.css";

const operators = new Set(["+", "-", "*", "/", "%", "(", ")"]);

/**
 * Rule input for conditional formatting
 */
export default class RuleInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnListVisibility: false,
      rule: "",
      ruleHTML: "",
      searchIndexes: { prev: 0, next: 0 },
      columnsToBeUsedForSearch: this.props.columnsToBeUsed
        ? this.props.columnsToBeUsed
        : [],
      selection: false
    };

    this.wrapperRef = React.createRef();
  }

  componentWillMount() {
    let newState = {...this.state};
    let isStateChanged = false;

    if (this.props.rule) {
      newState.rule = this.props.rule.rule ? this.props.rule.rule : ""; 
      newState.ruleHTML = this.props.rule.ruleHTML ? this.props.rule.ruleHTML : ""; 
      newState.searchIndexes = this.props.rule.searchIndexes ? this.props.rule.searchIndexes : { prev: 0, next: 0 }; 
      
      if (newState.searchIndexes.prev > newState.searchIndexes.next) {
        newState.searchIndexes.next = newState.searchIndexes.prev;
      }
      
      isStateChanged = true;
    }

    if (isStateChanged) {
      this.setState(newState);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.rule !== this.props.rule) {
      this.setState({ ...nextProps.rule });
    }

    if (nextProps.clearRule !== this.props.clearRule && nextProps.clearRule === true) {
      this.clearRule();
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   *  On Click outside of Rule Input. Close Rule Input dropdown.
   * @param {*} event 
   */
  handleClickOutside = event => {
    let willClose = !$(event.target).closest("#loginPopup").length > 0 && !$(event.target).closest(".ant-tooltip").length > 0;

    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      if (willClose) {
        this.columnListVisibility(false);
      }
    }
  };

  clearRule = () => {
    this.setState({
      ...this.state,
      rule: "",
      ruleHTML: "",
      searchIndexes: { prev: 0, next: 0 },
    })

    $("." + this.props.type)
      .find(".rule-input-box")
      .html("");
  }

  /** Change column list visibility */
  columnListVisibility = status => {
    this.setState({
      columnListVisibility: status
    });
  };

  //Checks all ruletag and removes if anyone changed
  removeTagsIfChanged = value => {
    let newValue = value;

    value.split("<ruletag").map((rule, i) => {
      let newRule = rule.split("</ruletag>")[0];
      let oldRule = this.state.ruleHTML
        .split("<ruletag")
        [i].split("</ruletag>")[0];

      if (newRule !== oldRule) {
        newValue = newValue.replace("<ruletag" + newRule + "</ruletag>", "");
      }
    });

    return newValue;
  };

  /** If input has only <br> tag, user cannot remove that.
   * So it cannot be searched.
   * This method checks this case, and removes <br> tag if necessary.
   **/
  removeBrIfOnly = value => {
    if (value === "<br>") {
      value = "";

      this.updateInput(value);
    }

    return value;
  };

  /** Handle rule change and send base component */
  ruleOnChange = value => {
    let newState = { ...this.state };
    let newCharIndex;

    if (this.state.ruleHTML.length > value) {
      newCharIndex = this.findStringDiff(value, this.state.ruleHTML);
    } else {
      newCharIndex = this.findStringDiff(this.state.ruleHTML, value);
    }

    let valueBeforeRemoveTag = value;

    value = this.removeTagsIfChanged(value);

    if (valueBeforeRemoveTag !== value) {
      newState.selection = true;
    } else {
      newState.selection = false;
    }

    value = this.removeBrIfOnly(value);

    let columnsToBeUsedForSearchWithIndex = this.searchColumnList(
      value,
      newCharIndex
    );

    newState.columnsToBeUsedForSearch =
      columnsToBeUsedForSearchWithIndex.columnsToBeUsedForSearch;
    newState.searchIndexes = columnsToBeUsedForSearchWithIndex.indexes;
    newState.ruleHTML = value;

    this.setState(newState);

    delete newState.columnListVisibility;
    this.props.updateRule(newState, this.props.type);
  };

  /** Compare two string and return difference index */
  findStringDiff = (str1, str2) => {
    let differenceIndex = -1;

    for (let i = 0; i < str2.length; i++) {
      let val = str2.charAt(i);
      if (val != str1.charAt(i)) {
        differenceIndex = i;
        break;
      }
    }

    return differenceIndex;
  };

  /** Finds text part in editable content for search and uses this to search to column list .  */
  searchColumnList = (value, newCharIndex) => {
    if (newCharIndex == -1) {
      //no difference
      return [...this.props.columnsToBeUsed];
    }

    if (value.length < newCharIndex) {
      return [...this.props.columnsToBeUsed];
    }

    let searchPrevIndex = 0;
    for (let i = newCharIndex; i >= 0; i--) {
      if (operators.has(value.charAt(i))) {
        searchPrevIndex = i + 1;
        break;
      }
    }

    let searchNextIndex = value.length;
    for (let i = newCharIndex; i < value.length; i++) {
      if (operators.has(value.charAt(i))) {
        searchNextIndex = i;
        break;
      }
    }

    let searchValue = value.substring(searchPrevIndex, searchNextIndex);
    let spaceIndex = -1;
    
    for (let i = 0; i < searchValue.length; i++) {
      if (searchValue[i] === "&") {
        if (searchValue.substring(i, i + 6) !== "&nbsp;") {
          spaceIndex = i;
          break;
        } else {
          i = i + 6;
        }
      } else if (searchValue[i] !== " ") {
        spaceIndex = i;
        break;
      }
    }

    if (spaceIndex !== -1) {
      searchValue = searchValue.substring(spaceIndex, searchValue.length);
    }

    if (searchValue[searchValue.length - 1] == " ") {
      searchValue = searchValue.substring(0, searchValue.length - 1);
    }

    searchValue = searchValue.replace(/&nbsp;/g, "");
    searchValue = searchValue.replace(/ /g, "");

    let columnsToBeUsedForSearch;

    if (searchValue.length > 0) {
      columnsToBeUsedForSearch = this.props.columnsToBeUsed.filter(column =>
        (column["tableDisplayName"] + "." + column["displayName"]).includes(
          searchValue
        )
      );
    } else {
      columnsToBeUsedForSearch = [...this.props.columnsToBeUsed];
    }

    return {
      columnsToBeUsedForSearch: columnsToBeUsedForSearch,
      indexes: { prev: searchPrevIndex, next: searchNextIndex }
    };
  };

  /** Column tag for conditional rule. */
  createColumnTag = column => {
    let text = returnConditionalListName(column);
    let ruleId = column.uniqeColumnId ? column.uniqeColumnId : column.filterId ? column.filterId : undefined

    return (
      `&nbsp;<ruletag title-for-column-name="` +
      text +
      `" uniqueid="` +
      uuid() +
      `" class="spanColumn" type="` +
      column["dataType"] +
      `" columnid="` +
      ruleId +
      `">` +
      `<div class="columnName tooltip-for-rule">` + 
      text + 
      `<span class="tooltiptext-for-rule">` +
      text +
      `</span></div>` +
      `<label class="remove">x</label></ruletag>&nbsp;`
    );
  };

  /** Remove element in html and set new html in state */
  removeTagWithElement = element => {
    let content = $("." + this.props.type).find(".rule-input-box");
    let elementStartIndex = content
      .html()
      .indexOf($(element).parent()[0].outerHTML);

    let startIndexes = { next: elementStartIndex, prev: elementStartIndex - 6 };
    let endIndexes = { next: elementStartIndex + 6, prev: elementStartIndex };

    $(element)
      .parent()
      .remove();

    let deleteString = content
      .html()
      .substring(elementStartIndex - 6, elementStartIndex);

    let lastDeleteString = content
      .html()
      .substring(endIndexes.prev, endIndexes.next);

    if (lastDeleteString === "&nbsp;") {
      content.html(this.replaceAt(content.html(), "", endIndexes));
    }

    if (deleteString === "&nbsp;") {
      content.html(this.replaceAt(content.html(), "", startIndexes));
    }

    let ruleHTML = $("." + this.props.type)
      .find(".rule-input-box")
      .html();

    let newState = { ...this.state };
    newState.ruleHTML = ruleHTML;
    newState.selection = true;

    this.setState(newState);
    this.props.updateRule(newState, this.props.type);
  };

  /** Update input html with find jquery. */
  updateInput = html => {
    $("." + this.props.type)
      .find(".rule-input-box")
      .html(html);
  };

  /**  Change part of html with value by indexes */
  replaceAt = (html, value, indexes) => {
    return (
      html.substr(0, indexes.prev) +
      value +
      html.substr(indexes.next, html.length)
    );
  };

  /**
   * Update rule when click a column in list.
   * Add column in state
   */
  selectColumnOnList = (column, index) => {
    //if column select, update all string for now.
    let ruleHTML = this.state.ruleHTML;
    let newRuleHTML = this.state.ruleHTML;

    if (newRuleHTML === "") {
      newRuleHTML = this.createColumnTag(column);
    } else {
      if (
        this.state.searchIndexes.prev === 0 &&
        this.state.searchIndexes.next === 0
      ) {
        newRuleHTML += this.createColumnTag(column);
      } else {
        let newTag = this.createColumnTag(column);

        newRuleHTML = this.replaceAt(
          ruleHTML,
          newTag,
          this.state.searchIndexes
        );
      }
    }

    let newState = { ...this.state };

    newState.searchIndexes = { prev: 0, next: 0 };
    newState.ruleHTML = newRuleHTML;
    newState.selection = true;
    newState.columnsToBeUsedForSearch = [...this.props.columnsToBeUsed];

    this.setState(newState);

    delete newState.columnListVisibility;
    this.props.updateRule(newState, this.props.type);
  };

  //is clicked a column in list for edittable content
  setSelection = status => {
    this.setState({
      ...this.state,
      selection: status
    });
  };

  render() {
    let THIS = this;
    let disabled = false;
    let columnListStatus = true;

    $("." + this.props.type)
      .find(".rule-input-box")
      .find(".remove")
      .click(function() {
        if ($(this)) {
          THIS.removeTagWithElement(this);
        }
      });

    return (
      <div className={"rule-input-container"}>
        {this.props.disabledInput === true ? (
          <div className={"rule-input-disabled"}>
            <span>{this.props.disabledText}</span>
          </div>
        ) : null}
        <div className={"rule-input " + this.props.type} ref={this.wrapperRef}>
          <EditableContent
            minHeight={this.props.minHeight}
            content={this.state.ruleHTML}
            onFocus={() => this.columnListVisibility(true)}
            onChange={disabled ? () => {} : this.ruleOnChange}
            setSelection={this.setSelection}
            selection={this.state.selection}
            type={this.props.type}
          ></EditableContent>
          {columnListStatus ? (
            <ColumnList
              visibility={this.state.columnListVisibility}
              columnsToBeUsed={this.state.columnsToBeUsedForSearch}
              selectColumnOnList={this.selectColumnOnList}
              columnListPosition={this.props.columnListPosition}
            />
          ) : null}
        </div>
      </div>
    );
  }
}
