import React, { Component } from "react";
import * as d3 from "d3";
import $ from "jquery";
import { rmvpp } from "../../RenderJs/rmvpp";
import LiquidGaugeConfiguration from "./LiquidGaugeConfiguration";
import LiquidGaugeData from "./LiquidGaugeData";
import i18n from "../../../../Utils/i18next";
import {
  onComponentWillMount,
  onComponentWillReceiveProps,
  getColumnMapping,
  calculatePluginHeight
} from "../common";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import { renderConfig, renderData } from "../PluginsCommonComponents";
import { renderContent } from "../renderContent";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join"
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";

const data = JSON.parse(
  `[{"measure":50574964,"target":2015},{"measure":58443780,"target":2016},{"measure":63270324,"target":2014}]`
);
const config = JSON.parse(
  `{"circleThickness":0.05,"circleColor":"#178BCA","textColor":"#045681","waveTextColor":"#A4DBf8","waveColor":"#178BCA","textVertPosition":0.5,"minValue":0,"waveHeight":0.05,"circleFillGap":0.05,"waveCount":1,"waveRiseTime":1000,"waveAnimateTime":10000,"waveHeightScaling":true,"waveAnimate":true,"showHideButton":false,"textSize":1,"displayPercent":false,"refresh":0}`
);
const columnMap = JSON.parse(
  `{"measure":{"Code":"\'ucus_PBWWSJXEAR\'.\'postatoplam\'","Name":"postatoplam","DataType":"double","Table":"ucus_PBWWSJXEAR","Measure":"sum('ucus'.'postatoplam')","ID":"ucus_PBWWSJXEAR.postatoplam","SubjectArea":"deneme","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":".3s","Config":{},"Verified":true,"Type":"Column","Description":""},"target":{"Code":"\'ucus_PBWWSJXEAR\'.\'yil\'","Name":"yil","DataType":"double","Table":"ucus_PBWWSJXEAR","Measure":"none","ID":"ucus_PBWWSJXEAR.yil","SubjectArea":"deneme","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":".3s","Config":{},"Verified":false,"Type":"Column","Description":""},"hidden":{"Code":"","Name":"","DataType":"varchar","Table":"Unspecified","Measure":"none","ID":"Unspecified.","SubjectArea":"","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":"%s","Config":{},"Verified":false,"Type":"Column"}}`
);

const condFormats = [];
const filters = [];

var pluginName = "liquid-gauge";
const description =
  "Makes a circular progress graphic, used to display ratios or percentages. Also measures can be displayed as they are, with an arbitrary maximum set or one specified by another measure column. If the value exceeds the target value a second arc is drawn inside the first one. This pattern continues up to 5 times the target value.";

const configurationParameters = [
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 15
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
  {
    targetProperty: "circleThickness",
    label: "CircleThickness",
    inputType: "textbox",
    inputOptions: {
      subType: "number",
      defaultValue: 0.05
    },
    desc: "desc96"
  },
  {
    targetProperty: "circleColor",
    label: "CircleColor",
    inputType: "color",
    inputOptions: {
      defaultValue: "#178BCA"
    },
    desc: "CircleColor"
  },
  {
    targetProperty: "textColor",
    label: "TextColor",
    inputType: "color",
    inputOptions: {
      defaultValue: "#045681"
    },
    desc: "TextColor"
  },
  {
    targetProperty: "waveTextColor",
    label: "WaveTextColor",
    inputType: "color",
    inputOptions: {
      defaultValue: "#A4DBf8"
    },
    desc: "WaveTextColor"
  },
  {
    targetProperty: "waveColor",
    label: "WaveColor",
    inputType: "color",
    inputOptions: {
      defaultValue: "#178BCA"
    },
    desc: "WaveColor"
  },
  {
    targetProperty: "textVertPosition",
    label: "TextVertPosition",
    inputType: "textbox",
    inputOptions: {
      subType: "number",
      defaultValue: 0.5
    },
    desc: "TextVertPosition"
  },
  {
    targetProperty: "minValue",
    label: "MinValue",
    inputType: "textbox",
    inputOptions: {
      subType: "number",
      defaultValue: 0
    },
    desc: "MinValue"
  },
  {
    targetProperty: "waveHeight",
    label: "WaveHeight",
    inputType: "textbox",
    inputOptions: {
      subType: "number",
      defaultValue: 0.05
    },
    desc: "WaveHeight"
  },
  {
    targetProperty: "circleFillGap",
    label: "CircleFillGap",
    inputType: "textbox",
    inputOptions: {
      subType: "number",
      defaultValue: 0.05
    },
    desc: "CircleFillGap"
  },
  {
    targetProperty: "waveCount",
    label: "WaveCount",
    inputType: "textbox",
    inputOptions: {
      subType: "number",
      defaultValue: 1
    },
    desc: "WaveCount"
  },
  {
    targetProperty: "waveRiseTime",
    label: "WaveRiseTime",
    inputType: "textbox",
    inputOptions: {
      subType: "number",
      defaultValue: 1000
    },
    desc: "WaveRiseTime"
  },
  {
    targetProperty: "waveAnimateTime",
    label: "WaveAnimateTime",
    inputType: "textbox",
    inputOptions: {
      subType: "number",
      defaultValue: 10000
    },
    desc: "WaveAnimateTime"
  },
  {
    targetProperty: "waveHeightScaling",
    label: "WaveHeightScaling",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "WaveHeightScaling"
  },
  {
    targetProperty: "waveAnimate",
    label: "WaveAnimate",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "WaveAnimate"
  },
  {
    targetProperty: "showHideButton",
    label: "Show Hide Button",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc230"
  },
  {
    targetProperty: "textSize",
    label: "TextSize",
    inputType: "textbox",
    inputOptions: {
      subType: "number",
      defaultValue: 10
    },
    desc: "TextSize"
  },
  {
    targetProperty: "displayPercent",
    label: "DisplayPercent",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "DisplayPercent"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  }
];
const actions = [];

const reactions = [
  {
    id: "filter",
    name: "Filtre",
    description: "desc87",
    type: "general"
  }
];

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

/**
 * renders LiquidGauge plugin in Vispeahen V3
 */
export default class LiquidGauge extends Component {
  constructor(props) {
    super(props);
    this.rerenderProcessStarted = false;
    this.callBackObject = {};
  }

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({})

    return tmpCallBackObject;
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );
  }

  getConfigComponent = props => {
    if (props.config) {
      return (
        <LiquidGaugeConfiguration
          config={{ ...props.config }}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig= {this.props.setCurrentAppliedConfig}
          currentAppliedConfig = {this.props.currentAppliedConfig}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }

    return (
      <LiquidGaugeData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        didNotJoinedTables={checkTableJoins(this.props.join, this.props.plugin.columnMap, this.props.refreshedPluginId, this.props.plugin.id, true)}
        setInteractions={this.props.setInteractions}
        interactions={this.props.interactions}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={props.changeDoesPluginHasNotJoinedTable}
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        plugin={props.plugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  /**
   * To set column map this plugin
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      measure: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Name"),
        required: true,
        minimumColumnSize: 1,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Desc"),
        conditionalFormat: true,
        data: []
      },
      target: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Level.Name"),
        type: "measure",
        minimumColumnSize: 1,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Level.Desc"),
        data: []
      },
      hidden: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Name"),
        type: "hidden",
        minimumColumnSize: 0,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Desc"),
        conditionalFormat: true,
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {
    // Set html empty and set this html to container.
    $("#" + divId).html("");
    let container = $("#" + divId)[0];

    //If we give 0.91 or greater text exit the container
    if (config.textVertPosition >= 0.92) {
      config.textVertPosition = 0.92
    }

    //If we give 0.04 or lower text exit the container
    if (config.textVertPosition <= 0.05) {
      config.textVertPosition = 0.05
    }

    var chartId = "liquidGaugeChart_" + Math.floor(Math.random() * 10);

    let chartContainer = $(
      `<div id="${chartId}_Container" style="width:100%;text-align:center"></div>`
    );
    $(container).append(chartContainer);

    let visWidth = config.height;
    let visHeight = chartContainer.width();

    // TODO: fix height
    if (config.height <= visHeight) {
      visHeight = config.height;
    } else {
      let ratio = (config.height - visHeight) * 5;
      if (ratio < visHeight) {
        ratio += visHeight;
      }

      visHeight = ratio;
    }

    let svgAttributes = `viewBox="-20 0 ${visWidth} ${visHeight}"`;
    $(chartContainer).append(
      `<svg id="${chartId}" ${svgAttributes} style="height:${config.height}"></svg>`
    );

    function liquidFillGaugeDefaultSettings() {
      return {
        minValue: 0, // The gauge minimum value.
        maxValue: 100, // The gauge maximum value.
        circleThickness: 0.05, // The outer circle thickness as a percentage of it's radius.
        circleFillGap: 0.05, // The size of the gap between the outer circle and wave circle as a percentage of the outer circles radius.
        circleColor: "#178BCA", // The color of the outer circle.
        waveHeight: 0.05, // The wave height as a percentage of the radius of the wave circle.
        waveCount: 1, // The number of full waves per width of the wave circle.
        waveRiseTime: 1000, // The amount of time in milliseconds for the wave to rise from 0 to it's final height.
        waveAnimateTime: 18000, // The amount of time in milliseconds for a full wave to enter the wave circle.
        waveRise: true, // Control if the wave should rise from 0 to it's full height, or start at it's full height.
        waveHeightScaling: true, // Controls wave size scaling at low and high fill percentages. When true, wave height reaches it's maximum at 50% fill, and minimum at 0% and 100% fill. This helps to prevent the wave from making the wave circle from appear totally full or empty when near it's minimum or maximum fill.
        waveAnimate: true, // Controls if the wave scrolls or is static.
        waveColor: "#178BCA", // The color of the fill wave.
        waveOffset: 0, // The amount to initially offset the wave. 0 = no offset. 1 = offset of one full wave.
        textVertPosition: 0.5, // The height at which to display the percentage text withing the wave circle. 0 = bottom, 1 = top.
        textSize: 10, // The relative height of the text to display in the wave circle. 1 = 50%
        valueCountUp: true, // If true, the displayed value counts up from 0 to it's final value upon loading. If false, the final value is displayed.
        displayPercent: true, // If true, a % symbol is displayed after the value.
        textColor: "#045681", // The color of the value text when the wave does not overlap it.
        waveTextColor: "#A4DBf8" // The color of the value text when the wave overlaps it.
      };
    }

    function loadLiquidFillGauge(elementId, value, config) {
      if (config == null) config = liquidFillGaugeDefaultSettings();

      let widthRef = 315; // 4 sutun eklenmis halindeki width, radius ve X location referans degerleri. Diger durumlar bu referans değerlerine göre yerlesiyor.
      let radiusRef = 142.5;
      let locxRef = 2; //Deger artarsa gridin icinde saga dogru gider

      var gauge = d3.select("#" + elementId);
      var radius =
        Math.min(
          parseInt(gauge.style("width")),
          parseInt(gauge.style("height"))
        ) / 2;
      radius = radius - parseInt(radius / 10);
      var locationX =
        (parseInt(gauge.style("width")) / widthRef) *
        Math.floor(radius / radiusRef) *
        locxRef;
      var locationY = parseInt(gauge.style("height")) / 2 - radius;
      var fillPercent =
        Math.max(config.minValue, Math.min(config.maxValue, value)) /
        config.maxValue;

      var waveHeightScale;
      if (config.waveHeightScaling) {
        waveHeightScale = d3.scale
          .linear()
          .range([0, config.waveHeight, 0])
          .domain([0, 50, 100]);
      } else {
        waveHeightScale = d3.scale
          .linear()
          .range([config.waveHeight, config.waveHeight])
          .domain([0, 100]);
      }

      var textPixels = config.textSize;
      var textFinalValue = parseFloat(value).toFixed(2);
      var textStartValue = config.valueCountUp
        ? config.minValue
        : textFinalValue;
      var percentText = config.displayPercent ? "%" : "";
      var circleThickness = config.circleThickness * radius;
      var circleFillGap = config.circleFillGap * radius;
      var fillCircleMargin = circleThickness + circleFillGap;
      var fillCircleRadius = radius - fillCircleMargin;
      var waveHeight = fillCircleRadius * waveHeightScale(fillPercent * 100);

      var waveLength = (fillCircleRadius * 2) / config.waveCount;
      var waveClipCount = 1 + config.waveCount;
      var waveClipWidth = waveLength * waveClipCount;

      // Rounding functions so that the correct number of decimal places is always displayed as the value counts up.
      var textRounder = function (value) {
        return Math.round(value);
      };
      if (
        parseFloat(textFinalValue) != parseFloat(textRounder(textFinalValue))
      ) {
        textRounder = function (value) {
          return parseFloat(value).toFixed(1);
        };
      }
      if (
        parseFloat(textFinalValue) != parseFloat(textRounder(textFinalValue))
      ) {
        textRounder = function (value) {
          return parseFloat(value).toFixed(2);
        };
      }

      // Data for building the clip wave area.
      var data = [];
      for (var i = 0; i <= 40 * waveClipCount; i++) {
        data.push({ x: i / (40 * waveClipCount), y: i / 40 });
      }

      // Scales for drawing the outer circle.
      var gaugeCircleX = d3.scale
        .linear()
        .range([0, 2 * Math.PI])
        .domain([0, 1]);
      var gaugeCircleY = d3.scale
        .linear()
        .range([0, radius])
        .domain([0, radius]);

      // Scales for controlling the size of the clipping path.
      var waveScaleX = d3.scale
        .linear()
        .range([0, waveClipWidth])
        .domain([0, 1]);
      var waveScaleY = d3.scale
        .linear()
        .range([0, waveHeight])
        .domain([0, 1]);

      // Scales for controlling the position of the clipping path.
      var waveRiseScale = d3.scale
        .linear()
        // The clipping area size is the height of the fill circle + the wave height, so we position the clip wave
        // such that the it will overlap the fill circle at all when at 0%, and will totally cover the fill
        // circle at 100%.
        .range([
          fillCircleMargin + fillCircleRadius * 2 + waveHeight,
          fillCircleMargin - waveHeight
        ])
        .domain([0, 1]);
      var waveAnimateScale = d3.scale
        .linear()
        .range([0, waveClipWidth - fillCircleRadius * 2]) // Push the clip area one full wave then snap back.
        .domain([0, 1]);

      // Scale for controlling the position of the text within the gauge.
      var textRiseScaleY = d3.scale
        .linear()
        .range([
          fillCircleMargin + fillCircleRadius * 2,
          fillCircleMargin + textPixels * 0.7
        ])
        .domain([0, 1]);

      // Center the gauge within the parent SVG.
      var gaugeGroup = gauge
        .append("g")
        .attr("transform", "translate(" + locationX + "," + locationY + ")");

      // Draw the outer circle.
      var gaugeCircleArc = d3.svg
        .arc()
        .startAngle(gaugeCircleX(0))
        .endAngle(gaugeCircleX(1))
        .outerRadius(gaugeCircleY(radius))
        .innerRadius(gaugeCircleY(radius - circleThickness));
      gaugeGroup
        .append("path")
        .attr("d", gaugeCircleArc)
        .style("fill", config.circleColor)
        .attr("transform", "translate(" + radius + "," + radius + ")");

      // Text where the wave does not overlap.
      var text1 = gaugeGroup
        .append("text")
        .text(textRounder(textStartValue) + percentText)
        .attr("class", "liquidFillGaugeText")
        .attr("text-anchor", "middle")
        .style("font-size", function () {
          return "200%";
        })
        .style("fill", config.textColor)
        .attr(
          "transform",
          "translate(" +
          radius +
          "," +
          textRiseScaleY(config.textVertPosition) +
          ")"
        );

      // The clipping wave area.
      var clipArea = d3.svg
        .area()
        .x(function (d) {
          return waveScaleX(d.x);
        })
        .y0(function (d) {
          return waveScaleY(
            Math.sin(
              Math.PI * 2 * config.waveOffset * -1 +
              Math.PI * 2 * (1 - config.waveCount) +
              d.y * 2 * Math.PI
            )
          );
        })
        .y1(function (d) {
          return fillCircleRadius * 2 + waveHeight;
        });
      var waveGroup = gaugeGroup
        .append("defs")
        .append("clipPath")
        .attr("id", "clipWave" + elementId);
      var wave = waveGroup
        .append("path")
        .datum(data)
        .attr("d", clipArea)
        .attr("T", 0);

      // The inner circle with the clipping wave attached.
      var fillCircleGroup = gaugeGroup
        .append("g")
        .attr("clip-path", "url(#clipWave" + elementId + ")");
      fillCircleGroup
        .append("circle")
        .attr("cx", radius)
        .attr("cy", radius)
        .attr("r", fillCircleRadius)
        .style("fill", config.waveColor);

      // Text where the wave does overlap.
      var text2 = fillCircleGroup
        .append("text")
        .text(textRounder(textStartValue) + percentText)
        .attr("class", "liquidFillGaugeText")
        .attr("text-anchor", "middle")
        .style("font-size", "200%")
        .style("fill", config.waveTextColor)
        .attr(
          "transform",
          "translate(" +
          radius +
          "," +
          textRiseScaleY(config.textVertPosition) +
          ")"
        );

      // Make the value count up.
      if (config.valueCountUp) {
        var textTween = function () {
          var i = d3.interpolate(this.textContent, textFinalValue);
          return function (t) {
            this.textContent = textRounder(i(t)) + percentText;
          };
        };
        text1
          .transition()
          .duration(config.waveRiseTime)
          .tween("text", textTween);
        text2
          .transition()
          .duration(config.waveRiseTime)
          .tween("text", textTween);
      }

      // Make the wave rise. wave and waveGroup are separate so that horizontal and vertical movement can be controlled independently.
      var waveGroupXPosition =
        fillCircleMargin + fillCircleRadius * 2 - waveClipWidth;
      if (config.waveRise) {
        waveGroup
          .attr(
            "transform",
            "translate(" + waveGroupXPosition + "," + waveRiseScale(0) + ")"
          )
          .transition()
          .duration(config.waveRiseTime)
          .attr(
            "transform",
            "translate(" +
            waveGroupXPosition +
            "," +
            waveRiseScale(fillPercent) +
            ")"
          )
          .each("start", function () {
            wave.attr("transform", "translate(1,0)");
          }); // This transform is necessary to get the clip wave positioned correctly when waveRise=true and waveAnimate=false. The wave will not position correctly without this, but it's not clear why this is actually necessary.
      } else {
        waveGroup.attr(
          "transform",
          "translate(" +
          waveGroupXPosition +
          "," +
          waveRiseScale(fillPercent) +
          ")"
        );
      }

      if (config.waveAnimate) animateWave();

      function animateWave() {
        wave.attr(
          "transform",
          "translate(" + waveAnimateScale(wave.attr("T")) + ",0)"
        );
        wave
          .transition()
          .duration(config.waveAnimateTime * (1 - wave.attr("T")))
          .ease("linear")
          .attr("transform", "translate(" + waveAnimateScale(1) + ",0)")
          .attr("T", 1)
          .each("end", function () {
            wave.attr("T", 0);
            animateWave(config.waveAnimateTime);
          });
      }

      function GaugeUpdater() {
        this.update = function (value) {
          var newFinalValue = parseFloat(value).toFixed(2);
          var textRounderUpdater = function (value) {
            return Math.round(value);
          };
          if (
            parseFloat(newFinalValue) !=
            parseFloat(textRounderUpdater(newFinalValue))
          ) {
            textRounderUpdater = function (value) {
              return parseFloat(value).toFixed(1);
            };
          }
          if (
            parseFloat(newFinalValue) !=
            parseFloat(textRounderUpdater(newFinalValue))
          ) {
            textRounderUpdater = function (value) {
              return parseFloat(value).toFixed(2);
            };
          }

          var textTween = function () {
            var i = d3.interpolate(
              this.textContent,
              parseFloat(value).toFixed(2)
            );
            return function (t) {
              this.textContent = textRounderUpdater(i(t)) + percentText;
            };
          };

          text1
            .transition()
            .duration(config.waveRiseTime)
            .tween("text", textTween);
          text2
            .transition()
            .duration(config.waveRiseTime)
            .tween("text", textTween);

          var fillPercent =
            Math.max(config.minValue, Math.min(config.maxValue, value)) /
            config.maxValue;
          var waveHeight =
            fillCircleRadius * waveHeightScale(fillPercent * 100);
          var waveRiseScale = d3.scale
            .linear()
            // The clipping area size is the height of the fill circle + the wave height, so we position the clip wave
            // such that the it will overlap the fill circle at all when at 0%, and will totally cover the fill
            // circle at 100%.
            .range([
              fillCircleMargin + fillCircleRadius * 2 + waveHeight,
              fillCircleMargin - waveHeight
            ])
            .domain([0, 1]);
          var newHeight = waveRiseScale(fillPercent);
          var waveScaleX = d3.scale
            .linear()
            .range([0, waveClipWidth])
            .domain([0, 1]);
          var waveScaleY = d3.scale
            .linear()
            .range([0, waveHeight])
            .domain([0, 1]);
          var newClipArea;
          if (config.waveHeightScaling) {
            newClipArea = d3.svg
              .area()
              .x(function (d) {
                return waveScaleX(d.x);
              })
              .y0(function (d) {
                return waveScaleY(
                  Math.sin(
                    Math.PI * 2 * config.waveOffset * -1 +
                    Math.PI * 2 * (1 - config.waveCount) +
                    d.y * 2 * Math.PI
                  )
                );
              })
              .y1(function (d) {
                return fillCircleRadius * 2 + waveHeight;
              });
          } else {
            newClipArea = clipArea;
          }

          var newWavePosition = config.waveAnimate ? waveAnimateScale(1) : 0;
          wave
            .transition()
            .duration(0)
            .transition()
            .duration(
              config.waveAnimate
                ? config.waveAnimateTime * (1 - wave.attr("T"))
                : config.waveRiseTime
            )
            .ease("linear")
            .attr("d", newClipArea)
            .attr("transform", "translate(" + newWavePosition + ",0)")
            .attr("T", "1")
            .each("end", function () {
              if (config.waveAnimate) {
                wave.attr(
                  "transform",
                  "translate(" + waveAnimateScale(0) + ",0)"
                );
                animateWave(config.waveAnimateTime);
              }
            });
          waveGroup
            .transition()
            .duration(config.waveRiseTime)
            .attr(
              "transform",
              "translate(" + waveGroupXPosition + "," + newHeight + ")"
            );
        };
      }

      return new GaugeUpdater();
    }

    let gaugeConfig = {
      circleColor: config.circleColor,
      textColor: config.textColor,
      waveTextColor: config.waveTextColor,
      waveColor: config.waveColor,
      circleThickness: config.circleThickness,
      textVertPosition: config.textVertPosition,
      minValue: config.minValue,
      maxValue: data[0].target,
      circleFillGap: config.circleFillGap,
      waveHeight: config.waveHeight,
      waveCount: config.waveCount,
      waveRiseTime: config.waveRiseTime,
      waveAnimateTime: config.waveAnimateTime,
      waveHeightScaling: config.waveHeightScaling,
      waveAnimate: config.waveAnimate,
      waveOffset: 0,
      textSize: config.textSize,
      valueCountUp: true,
      waveRise: true,
      displayPercent: config.displayPercent
    };

    $(document).ready(
      loadLiquidFillGauge(chartId, parseFloat(data[0].measure), gaugeConfig)

      /*loadLiquidFillGauge(chartId, parseFloat(data[0].measure), {
            minValue: 0, // The gauge minimum value.
            maxValue: parseFloat(data[0].target), // The gauge maximum value.
            circleThickness: 0.05, // The outer circle thickness as a percentage of it's radius.
            circleFillGap: 0.05, // The size of the gap between the outer circle and wave circle as a percentage of the outer circles radius.
            circleColor: "#178BCA", // The color of the outer circle.
            waveHeight: 0.05, // The wave height as a percentage of the radius of the wave circle.
            waveCount: 1, // The number of full waves per width of the wave circle.
            waveRiseTime: 1000, // The amount of time in milliseconds for the wave to rise from 0 to it's final height.
            waveAnimateTime: 18000, // The amount of time in milliseconds for a full wave to enter the wave circle.
            waveRise: true, // Control if the wave should rise from 0 to it's full height, or start at it's full height.
            waveHeightScaling: true, // Controls wave size scaling at low and high fill percentages. When true, wave height reaches it's maximum at 50% fill, and minimum at 0% and 100% fill. This helps to prevent the wave from making the wave circle from appear totally full or empty when near it's minimum or maximum fill.
            waveAnimate: true, // Controls if the wave scrolls or is static.
            waveColor: "#178BCA", // The color of the fill wave.
            waveOffset: 0, // The amount to initially offset the wave. 0 = no offset. 1 = offset of one full wave.
            textVertPosition: .5, // The height at which to display the percentage text withing the wave circle. 0 = bottom, 1 = top.
            textSize: 1, // The relative height of the text to display in the wave circle. 1 = 50%
            valueCountUp: true, // If true, the displayed value counts up from 0 to it's final value upon loading. If false, the final value is displayed.
            displayPercent: true, // If true, a % symbol is displayed after the value.
            textColor: "#045681", // The color of the value text when the wave does not overlap it.
            waveTextColor: "#A4DBf8" // The color of the value text when the wave overlaps it.
        })*/
    );

    if (config.showHideButton) {
      let hideButtonToggleKey = [
        "#liquidGaugeChartContainer",
        "#liquidGaugeChart"
      ];
      rmvpp.hideButton(container, hideButtonToggleKey);
    }

    this.props.setPluginRerender(false, this.props.plugin.id, false, this.props.plugin.isInteraction);
  };

  currentHeight;
  lastContent = undefined;

  updateLastContent = (status) => {
    this.lastContent = status
  }

  render() {
    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };
    
    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + this.props.plugin.id).css("padding")
      );

      pluginConfig.height =
        calculatePluginHeight(this.props.plugin, this.props.settings) -
        pluginContainerPadding * 2;

      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      );
    }

    return (
      <>
        <div style={{height: "100%"}}>
          <div id={this.props.plugin.id}></div>
          {renderContent(
            isRerender,
            this.pluginRender,
            this.props.plugin,
            data,
            columnMap,
            pluginConfig,
            condFormats,
            this.props.setPluginRerender,
            this.lastContent,
            this.updateLastContent,
          )}
          {configComponent}
          {dataComponent}
        </div>
      </>
    );
  }
}
