import React, { Component } from "react";
import Button from "../../../../../GeneralComponents/Button/Button";
import { Row, Typography, Col, Switch, InputNumber, Input } from "antd";
import { deepCopy } from "../../../../../../Utils/Global";
import i18n from "../../../../../../Utils/i18next";
import { SketchPicker } from "react-color";
import {
  ShrinkOutlined,
  BorderVerticleOutlined,
  ColumnHeightOutlined,
  ColumnWidthOutlined,
  ZoomInOutlined,
  HighlightOutlined,
  BgColorsOutlined
} from "@ant-design/icons";
import Tooltip from "../../../../../GeneralComponents/Tooltip/Tooltip";

const { Text } = Typography;

export default class GridSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preventCollision: this.props.settings.grid.preventCollision,
      padding: this.props.settings.grid.padding,
      shadow: this.props.settings.grid.shadow,
      shadowColorChange: false
    };
  }

  componentWillReceiveProps(nextProps) {
    let shallIsetState = false;
    let newState = { ...this.state };
    if (
      nextProps.settings.grid.preventCollision != this.props.preventCollision
    ) {
      newState.preventCollision = nextProps.settings.grid.preventCollision;
      shallIsetState = true;
    }

    if (nextProps.settings.grid.padding != this.props.padding) {
      newState.padding = nextProps.settings.grid.padding;
      shallIsetState = true;
    }

    if (nextProps.settings.grid.shadow != this.props.shadow) {
      newState.shadow = { ...nextProps.settings.grid.shadow };
      shallIsetState = true;
    }

    if (shallIsetState === true) {
      this.setState(newState);
    }
  }

  onChange = status => {
    if (status != this.state.preventCollision) {
      this.setState({ ...this.state, preventCollision: status });
      let newSettings = deepCopy(this.props.settings);
      newSettings.grid.preventCollision = status;
      this.props.changeSettings(newSettings);
    }
  };

  onChangeInput = (value, key, objectKey = undefined) => {
    let newSettings = deepCopy(this.props.settings);

    //for object in state
    if (objectKey && newSettings.grid[objectKey]) {
      let object = { ...this.state[objectKey] };
      object[key] = value;

      if (key == "blur" && value < 0) {
        value = 0;
      }

      newSettings.grid[objectKey][key] = value;
      this.setState({ ...this.state, [objectKey]: object });
    } else {
      this.setState({ ...this.state, [key]: value });
      newSettings.grid[key] = value;
    }

    this.props.changeSettings(newSettings);
  };

  onChangeMaxPadding = value => {
    if (value > 40) {
      value = 40;
    }

    this.setState({
      ...this.state,
      padding: value
    })
    
    this.onChangeInput(value, "padding");
  }

  /**
   * Demonstrates how the choosen color will affect the interface
   */
  shadowColorChangeVisible = () => {
    this.setState({
      ...this.state,
      shadowColorChange: !this.state.shadowColorChange
    });
  };

  rerenderAllPlugins = () => {
    this.props.changeSettings(null, true);
  };

  render() {
    return (
      <div>
        <Row gutter={2} style={{ marginBottom: "11px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <ShrinkOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              type="span"
              style={{
                whiteSpace: "normal",
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Settings.StackDesign")}
              <Tooltip
                tooltip={
                  i18n.t("Dashboard.Settings.StackDesignDesc")
                }
              >
                <span name={"stackDesignDes"} style={{fontSize: "14px", color: "rgb(56, 123, 175)"}}> (?)</span>
              </Tooltip>
            </Text>
          </Col>
          <Col span={3} style={{ textAlign: "center" }}>
            <Switch
              id="preventCollisionButton"
              onChange={this.onChange}
              checked={this.state.preventCollision}
            />
          </Col>
          <Col style={{ textAlign: "right" }} span={9}>
            <Text
              type="span"
              style={{
                whiteSpace: "normal",
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Settings.FreeMovement")}
              <Tooltip
                tooltip={
                  i18n.t("Dashboard.Settings.FreeMovementDesc")
                }
              >
                <span name={"freeMovemementDes"} style={{fontSize: "14px", color: "rgb(56, 123, 175)"}}> (?)</span>
              </Tooltip>
            </Text>
          </Col>
        </Row>
        <Row gutter={2} style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BorderVerticleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={12}>
            <Text
              type="span"
              style={{
                whiteSpace: "normal",
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Settings.GridPadding")}:
            </Text>
          </Col>
          <Col span={4}>
            <InputNumber
              id="spaceBetweenPlugins"
              style={{ width: "100%" }}
              type={"number"}
              min={1}
              max={40}
              defaultValue={6}
              value={this.state.padding}
              onChange={this.onChangeMaxPadding}
            />
          </Col>
          <Col span={5} style={{ textAlign: "center" }}>
            <Button
              id="applyButton"
              className={"general-button"}
              onClick={this.rerenderAllPlugins}
              style={{
                width: "100%",
                borderBottom: "0",
                height: "fit-content",
                padding: "4px 0",
                marginBottom: "0",
                top: "1px"
              }}
            >
              {i18n.t("Apply")}
            </Button>
          </Col>
        </Row>
        <Row gutter={2} style={{ marginBottom: "5px" }}>
          <Col span={24}>
            <Text
              type="span"
              style={{
                fontSize: "15px",
                textAlign: "left",
                display: "block",
                whiteSpace: "normal",
                display: "block",
                marginTop: "10px",
                fontWeight: "bold",
                paddingLeft: "15px",
                marginBottom: "10px"
              }}
            >
              {i18n.t("Dashboard.Settings.Shadow")}:
            </Text>
          </Col>
        </Row>
        <Row gutter={2} style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <ColumnHeightOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              type="span"
              style={{
                fontSize: "15px",
                textAlign: "left",
                display: "block",
                whiteSpace: "normal"
              }}
            >
              {i18n.t("Dashboard.Settings.ShadowUpDown")}:
            </Text>
          </Col>
          <Col span={12}>
            <InputNumber
              id="upDownButton"
              style={{ width: "100%" }}
              min={-40}
              max={40}
              value={this.state.shadow.upDown}
              onChange={val => this.onChangeInput(val, "upDown", "shadow")}
            />
          </Col>
        </Row>
        <Row gutter={2} style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <ColumnWidthOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              type="span"
              style={{
                fontSize: "15px",
                textAlign: "left",
                display: "block",
                whiteSpace: "normal"
              }}
            >
              {i18n.t("Dashboard.Settings.ShadowRightLeft")}:
            </Text>
          </Col>
          <Col span={12}>
            <InputNumber
              id="rightLeftButton"
              style={{ width: "100%" }}
              min={-40}
              max={40}
              value={this.state.shadow.rightLeft}
              onChange={val => this.onChangeInput(val, "rightLeft", "shadow")}
            />
          </Col>
        </Row>
        <Row gutter={2} style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <ZoomInOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              type="span"
              style={{
                fontSize: "15px",
                textAlign: "left",
                display: "block",
                whiteSpace: "normal"
              }}
            >
              {i18n.t("Dashboard.Settings.ShadowBlur")}:
            </Text>
          </Col>
          <Col span={12}>
            <InputNumber
              id="blurButton"
              style={{ width: "100%" }}
              min={-40}
              max={40}
              value={this.state.shadow.blur}
              onChange={val => this.onChangeInput(val, "blur", "shadow")}
            />
          </Col>
        </Row>
        <Row gutter={2} style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <HighlightOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              type="span"
              style={{
                fontSize: "15px",
                textAlign: "left",
                display: "block",
                whiteSpace: "normal"
              }}
            >
              {i18n.t("Dashboard.Settings.ShadowSpread")}:
            </Text>
          </Col>
          <Col span={12}>
            <InputNumber
              id="spreadButton"
              style={{ width: "100%" }}
              min={-40}
              max={40}
              value={this.state.shadow.spread}
              onChange={val => this.onChangeInput(val, "spread", "shadow")}
            />
          </Col>
        </Row>
        <Row gutter={2} style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              type="span"
              style={{
                fontSize: "15px",
                textAlign: "left",
                display: "block",
                whiteSpace: "normal"
              }}
            >
              {i18n.t("Dashboard.Settings.ShadowColor")}:
            </Text>
          </Col>
          <Col span={12}>
            <Button
              id={
                this.state.shadowColorChange
                  ? i18n.t("colorCloseColorButton")
                  : i18n.t("colorOpenColorButton")
              }
              onClick={this.shadowColorChangeVisible}
              style={{ width: "100%" }}
            >
              {this.state.shadowColorChange
                ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
            </Button>
            {this.state.shadowColorChange ? (
              <div
                style={{
                  whiteSpace: "normal",
                  marginTop: "5px",
                  position: "absolute",
                  right: 0,
                  zIndex: 1,
                  top: "30px"
                }}
              >
                <SketchPicker
                  style={{ whiteSpace: "normal" }}
                  onChange={color =>
                    this.onChangeInput(color.hex, "color", "shadow")
                  }
                  color={this.state.shadow.color}
                  width={200}
                />
              </div>
            ) : null}
          </Col>
        </Row>

        <Button
          id="dashboardCloseButton"
          style={{
            position: "absolute",
            top: "3px",
            right: "3px",
            borderRadius: "40px",
            padding: "6px 11px",
            backgroundColor: "white",
            zIndex: 1
          }}
          type={"default"}
          onClick={this.props.closeBGColorSetting}
        >
          X
        </Button>
      </div>
    );
  }
}
