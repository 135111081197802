export const PLUGIN_TRIGGER = "PLUGIN_TRIGGER";
export const PLUGIN_PRIVATE_FILTERS = "PLUGIN_PRIVATE_FILTERS";
export const PLUGIN_PRIVATE_FILTERS_TITLE = "PLUGIN_PRIVATE_FILTERS_TITLE";
export const VISIBLE_CHANGE_POPUP_CONTENT = "VISIBLE_CHANGE_POPUP_CONTENT";
export const CLEAR_PLUGIN_CONTENT = "CLEAR_PLUGIN_CONTENT";
export const SET_SOURCE_PLUGINS = "SET_SOURCE_PLUGINS";
export const PLUGIN_FILTERS = "PLUGIN_FILTERS";
export const UPDATED_PLUGINS = "UPDATED_PLUGINS";
export const ALL_PLUGINS_ALL_FILTERS = "ALL_PLUGINS_ALL_FILTERS"

/**
 *
 * @param {*} pluginContent
 * @param {*} positions
 * @param {*} isActionListVisible
 *
 * Dispatch the clicked plugin's objects to reducer
 */
export function setPluginTriggerObject(
  pluginContent,
  positions,
  isActionListVisible,
  isAction
) {
  return (dispatch) => {
    dispatch({
      type: PLUGIN_TRIGGER,
      payload: {
        pluginContent: pluginContent,
        positions: positions,
        isActionListVisible: isActionListVisible,
        isAction: isAction
      },
    });
  };
}

/**
 * 
 * @param {*} pluginPrivateTriggerMap 
 * @returns 
 * 
 * Sets private interaction filters to store to be used when returning from navigation
 */
export function setPluginPrivateFiltersTrigger(pluginPrivateTriggerMap) {
  return (dispatch) => {
    dispatch({
      type: PLUGIN_PRIVATE_FILTERS,
      payload: pluginPrivateTriggerMap,
    });
  };
}

/**
 * 
 * @param {*} pluginPrivateTriggerMap 
 * @returns 
 * 
 * Sets private interaction filters (title) to store to be used when returning from navigation
 */
export function setPluginPrivateFiltersTitleTrigger(pluginPrivateTriggerMap) {
  return (dispatch) => {
    dispatch({
      type: PLUGIN_PRIVATE_FILTERS_TITLE,
      payload: pluginPrivateTriggerMap,
    });
  };
}

/**
 * 
 * @param {*} pluginFiltersTriggerMap 
 * @returns 
 * 
 * Writes the given interaction filters to the store
 */
export function setPluginFiltersTrigger(pluginFiltersTriggerMap) {
  return (dispatch) => {
    dispatch({
      type: PLUGIN_FILTERS,
      payload: pluginFiltersTriggerMap,
    });
  };
}

/**
 *
 * @param {*} visible
 * Popup content visible change
 */
export function changeVisiblePopupContent(visible) {
  return (dispatch) => {
    dispatch({
      type: VISIBLE_CHANGE_POPUP_CONTENT,
      payload: visible,
    });
  };
}

/**
 * Clear plugin content object
 */
export function clearPluginContent() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_PLUGIN_CONTENT,
      payload: {},
    });
  };
}

/**
 * 
 * @param {*} sourcePluginsWithValues 
 * set triggered source plugins with values
 */
export function setSourcePluginsWithValues (sourcePluginsWithValues) {
  return (dispatch) => {
    dispatch({
      type: SET_SOURCE_PLUGINS,
      payload: sourcePluginsWithValues
    })
  }
}

/**
 * 
 * @param {*} plugins 
 * @returns 
 * Updates the plugin array
 */
 export function setUpdatedPlugins (plugins) {
  return (dispatch) => {
    dispatch({
      type: UPDATED_PLUGINS,
      payload: plugins
    })
  }
}

  /**
  * @param {*} filters 
  * @returns
  * 
  * Sets a plugins all filters.
  **/
 export function setAllPluginsAllFilters (filters) {
  return (dispatch) => {
    dispatch({
      type: ALL_PLUGINS_ALL_FILTERS,
      payload: filters
    })
  }
 }