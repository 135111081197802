import { ADD_DRILL_DOWNS, TRIGGERED_DRILL_DOWNS, COLUMN_CLICKED_AND_SHOULD_BE_REMOVED } from "./DrillDownAction";

/**
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns 
 * 
 * Reducer with Drilldown information
 */
export default function DrillDownReducer(
  state = {
    drillDowns: new Map(),
    triggeredDrillDowns: new Map(),
    pluginColumnClickedAndShouldBeRemoved: new Map()
  },
  action
) {
  switch (action.type) {
    case ADD_DRILL_DOWNS:
      return {
        ...state,
        drillDowns: action.payload,
      };
    case TRIGGERED_DRILL_DOWNS:
      return {
        ...state,
        triggeredDrillDowns: action.payload,
      };
    case COLUMN_CLICKED_AND_SHOULD_BE_REMOVED:
      return {
        ...state,
        pluginColumnClickedAndShouldBeRemoved: action.payload,
      };
    default:
      return state;
  }
}
