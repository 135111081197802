/*
* Returns selection box buttons html's
*/
export const selectionBoxHTMLs = (theme, dataId, dataName, textWidth, divId, config) => {
    let font = config.font == undefined ? "Verdana" : config.font
    let fontWeight = config.fontWeight == undefined 
      ? false 
      : config.fontWeight == true 
        ? "bold"
        : ""
    let fontStyle = config.fontStyle == undefined 
      ? false 
      : config.fontStyle 
        ? "italic"
        : ""
    let textDecor = config.textDecor == undefined 
      ? false 
      : config.textDecor 
        ? "underline"
        : ""

    if (theme === "default") {
        return `<div class="checkbox-wrapper-1" style="margin-left: 3px; margin-right: 3px; margin-top: 10px;">
            <input id="example-${dataId}-${divId}" class="substituted input-selection-box" type="checkbox" aria-hidden="true" />
            <label class="label-style" for="example-${dataId}-${divId}">
            <p class="default-p" style="width: ${textWidth}; color: ${config.textColor}; font-family: ${font}; font-weight: ${fontWeight}; font-style: ${fontStyle}; text-decoration: ${textDecor};">${dataName}</p></label>
        </div>`
    } else if (theme === "4") {
        return `<div class="checkbox-wrapper-4" style="margin-left: 10px; margin-right: 10px;">
        <input class="inp-cbx input-selection-box" id="${dataId}-${divId}" type="checkbox" />
        <label class="cbx" for="${dataId}-${divId}"
          ><span>
            <svg width="12px" height="10px">
              <use xlink:href="#check-4"></use></svg></span
          ><span class="default-p" style="font-weight: ${fontWeight}; width: ${textWidth}; color: ${config.textColor}; font-family: ${font}; font-weight: ${fontWeight}; font-style: ${fontStyle}; text-decoration: ${textDecor};">${dataName}</span></label
        >
        <svg class="inline-svg">
          <symbol id="check-4" viewbox="0 0 12 10" >
            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
          </symbol>
        </svg>
      </div>`
    } else if (theme === "28") {
      return `<div class="checkbox-wrapper-28" style="margin-left: 10px; margin-right: 10px;">
        <input id="${dataId}-${divId}" type="checkbox" class="promoted-input-checkbox input-selection-box" />
        <svg><use xlink:href="#checkmark-28" /></svg>
        <label for="${dataId}-${divId}">
          <p style="width: ${textWidth}; color: ${config.textColor}; font-family: ${font}; font-weight: ${fontWeight}; font-style: ${fontStyle}; text-decoration: ${textDecor};" class="default-p">${dataName}</p>
        </label>
        <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
          <symbol id="checkmark-28" viewBox="0 0 24 24">
            <path
              stroke-linecap="round"
              stroke-miterlimit="10"
              fill="none"
              d="M22.9 3.7l-15.2 16.6-6.6-7.1"
            ></path>
          </symbol>
        </svg>
      </div>`
    } else if (theme === "29") {
      return `<div class="checkbox-wrapper-29" style="margin-left: 10px; margin-right: 10px;">
        <label class="checkbox">
          <input type="checkbox" class="checkbox__input input-selection-box" />
          <span class="checkbox__label"></span>
          <span class="default-p" style="width: ${textWidth}; color: ${config.textColor}; font-family: ${font}; font-weight: ${fontWeight}; font-style: ${fontStyle}; text-decoration: ${textDecor};">${dataName}</span>
        </label>
      </div>`
    } else if (theme === "30") {
      return `<div class="checkbox-wrapper-30" style="margin-left: 10px; margin-right: 10px;">
        <span class="checkbox">
          <input type="checkbox" class="input-selection-box">
          </input>
          <svg>
            <use xlink:href="#checkbox-30" class="checkbox"></use>
          </svg>
        </span>
        <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
          <symbol id="checkbox-30" viewBox="0 0 22 22">
            <path
              fill="none"
              stroke="currentColor"
              d="M5.5,11.3L9,14.8L20.2,3.3l0,0c-0.5-1-1.5-1.8-2.7-1.8h-13c-1.7,0-3,1.3-3,3v13c0,1.7,1.3,3,3,3h13 c1.7,0,3-1.3,3-3v-13c0-0.4-0.1-0.8-0.3-1.2"
            />
          </symbol>
        </svg>
        <p style="width: ${textWidth}; color: ${config.textColor}; margin: 0px; margin-left: 10px!important; font-family: ${font}; font-weight: ${fontWeight}; font-style: ${fontStyle}; text-decoration: ${textDecor};" class="default-p">${dataName}</p>
      </div>`
    }
}

/*
* Returns radio buttons html's
*/
export const radioButtonHTMLs = (theme, dataId, dataName, textWidth, divId, config) => {
  let font = config.font == undefined ? "Verdana" : config.font
  let fontWeight = config.fontWeight == undefined 
    ? false 
    : config.fontWeight == true 
      ? "bold"
      : ""
  let fontStyle = config.fontStyle == undefined 
    ? false 
    : config.fontStyle 
      ? "italic"
      : ""
  let textDecor = config.textDecor == undefined 
    ? false 
    : config.textDecor 
      ? "underline"
      : ""
  
  if (theme === "12") {
      return `<div class="checkbox-wrapper-12" style="margin-left: 10px; margin-right: 10px;">
        <div class="cbx">
          <input id="${dataId}-${divId}" type="checkbox" class="input-radio-button" />
          <label for="${dataId}-${divId}"></label>
          <svg width="15" height="14" viewbox="0 0 15 14" fill="none">
            <path d="M2 8.36364L6.23077 12L13 2"></path>
          </svg>
        </div>
        <!-- Gooey-->
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
          <defs>
            <filter id="goo-12">
              <fegaussianblur
                in="SourceGraphic"
                stddeviation="4"
                result="blur"
              ></fegaussianblur>
              <fecolormatrix
                in="blur"
                mode="matrix"
                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7"
                result="goo-12"
              ></fecolormatrix>
              <feblend in="SourceGraphic" in2="goo-12"></feblend>
            </filter>
          </defs>
        </svg>
        <p style="width: ${textWidth}; color: ${config.textColor}; margin-right: 10px; margin-left: 10px!important" class="default-p">${dataName}</p>
      </div>`
    } else if (theme === "18" || theme === "default") {
      return `<div class="checkbox-wrapper-18" style="margin-left: 10px; margin-right: 10px;">
        <div class="round" style="display: flex">
          <input type="checkbox" id="${dataId}-${divId}" class="input-radio-button"/>
          <label for="${dataId}-${divId}"></label>
          <p style="width: ${textWidth}; margin-right: 10px!important; margin-left: 10px!important; margin: 0px; color: ${config.textColor}; font-family: ${font}; font-weight: ${fontWeight}; font-style: ${fontStyle}; text-decoration: ${textDecor};" class="default-p">${dataName}</p>
        </div>
      </div>`
    }
}

/*
* Parses a color a darker color based first color
*/
export function shadeColor(color, percent, opacity = false) {

  var R = parseInt(color.substring(1,3),16);
  var G = parseInt(color.substring(3,5),16);
  var B = parseInt(color.substring(5,7),16);

  R = parseInt(R * (100 + percent) / 100);
  G = parseInt(G * (100 + percent) / 100);
  B = parseInt(B * (100 + percent) / 100);

  R = (R<255)?R:255;  
  G = (G<255)?G:255;  
  B = (B<255)?B:255;  

  R = Math.round(R)
  G = Math.round(G)
  B = Math.round(B)

  var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
  var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
  var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

  if (opacity) {
    return "#"+RR+GG+BB+`${opacity}`;
  }

  return "#"+RR+GG+BB;
}