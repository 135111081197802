import React, { Component } from "react";
import i18n from "../../../../Utils/i18next";
import { Dropdown, Menu, Modal, Tabs } from "antd";
import Button from "../../../GeneralComponents/Button/Button";
import GeneralSettings from "./XLSX/GeneralSettings";
import PageSettings from "./XLSX/PageSettings";
import HeaderSettings from "./XLSX/HeaderSettings";
import FooterSettings from "./XLSX/FooterSettings";
import SheetSettings from "./XLSX/SheetSettings";
import { deepCopy } from "../../../../Utils/Global";

export default class FormatSettings extends Component {
    /**
     * Sets format option
     */
    setOption = (field, value) => {
        let options = deepCopy(this.props.options);

        options[field] = value;

        this.props.setOptions(options);
    }

    render() {
        let options = this.props.options;
        let measurementUnit = options.measurementUnit

        return (
            <Modal
                visible={this.props.outputSettings}
                title={i18n.t("Designer.AdvancedFormatSettings")}
                closable={true}
                maskClosable={false}
                onCancel={() => this.props.cancelFormatSettings()}
                destroyOnClose={true}
                centered={true}
                width="fit-content"
                className="generate-modal"
                mask={false}
                transitionName="none"
                closeIcon={(
                    <Button
                        style={{
                            borderRadius: "40px",
                            padding: "6px 11px",
                            backgroundColor: "white",
                        }}
                        type={"default"}
                        onClick={() => this.props.cancelFormatSettings()}
                    >
                        X
                    </Button>
                )}
                footer={(
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "4px"
                        }}
                    >
                        <button
                            type="button"
                            class="ant-btn ant-btn-default"
                            onClick={() => this.props.setFormatFromTemplate()}
                        >
                            <span>{i18n.t("Designer.SetFromTemplate")}</span>
                        </button>

                        <Dropdown
                            placement="topRight"
                            trigger={["hover"]}
                            overlay={() => (
                                <Menu>
                                    <Menu.Item
                                        onClick={() => this.props.applyFormatSettingsForOnce()}
                                    >
                                        {i18n.t("Designer.SetOnce")}
                                    </Menu.Item>

                                    <Menu.Item
                                        onClick={() => this.props.applyFormatSettingsAsDefault()}
                                    >
                                        {i18n.t("Designer.SetAsDefault")}
                                    </Menu.Item>
                                </Menu>
                            )}
                        >
                            <button
                                type="button"
                                className="ant-btn ant-btn-primary"
                            >
                                {i18n.t("Apply")}
                            </button>
                        </Dropdown>
                    </div>
                )}
            >
                <div
                    id="file-properties"
                    style={{
                        height: "100%",
                        width: "100%",
                        overflowY: "auto",
                        display: "flex",
                        flexDirection: "column"
                    }}
                >
                    <Tabs
                        className="output-settings"
                        animated={false}
                        size="small"
                    >
                        <Tabs.TabPane tab={i18n.t("Designer.GeneralSettings")} key="0">
                            <GeneralSettings
                                options={options}
                                extensions={this.props.extensions}
                                setOption={this.setOption}
                                setOptions={this.props.setOptions}
                            />
                        </Tabs.TabPane>

                        <Tabs.TabPane tab={i18n.t("Designer.AdvancedPage.Page")} key="1">
                            <PageSettings 
                                options={options}
                                setOption={this.setOption}
                                setOptions={this.props.setOptions}
                                measurementUnit={measurementUnit}
                            />
                        </Tabs.TabPane>

                        <Tabs.TabPane tab={i18n.t("Designer.AdvancedPage.Header")} key="2">
                            <HeaderSettings
                                options={options}
                                setOption={this.setOption}
                                measurementUnit={measurementUnit}
                            />
                        </Tabs.TabPane>

                        <Tabs.TabPane tab={i18n.t("Designer.AdvancedPage.Footer")} key="3">
                            <FooterSettings
                                options={options}
                                setOption={this.setOption}
                                measurementUnit={measurementUnit}
                            />
                        </Tabs.TabPane>

                        <Tabs.TabPane tab={i18n.t("Designer.AdvancedPage.Sheet")} key="4">
                            <SheetSettings 
                                options={options}
                                setOption={this.setOption}
                                measurementUnit={measurementUnit}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </Modal>
        );
    }
}