import React, { Component } from "react";
import { Tooltip, Input, Popover, Icon, Spin, Button } from "antd";
import "./modelItemOperations.css";
import ModelItemPanel from "./ModelItemPanel";
import { MoreOutlined } from "@ant-design/icons";
import i18n from "../../Utils/i18next";
import { showError, showNotificationWithIcon } from "../../Utils/Notification";
import { API_BASE } from "../../config";
import { post } from "../../Utils/WebService";
import { deepCopy, loadingScreen } from "../../Utils/Global";
import { store } from "../..";
import { setNewColumnEditorContent } from "../GeneralComponents/NewColumn/NewColumnEditorAction";

const inputValidationStatus = {
  INFO: "INFO",
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};
//In this component, operations such as aggRule, where clause, duplicate etc. are added to the column or table.
export default class ModelItemOperations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputText: "",
      inputValidation: {
        status: inputValidationStatus.INFO,
        message: "",
      },
      selectedItem: {},
      selectedPanelItem: "",
      popoverContent: {
        icon: "",
        title: "",
        isInputDisabled: false,
      },
      action: "",
      isPopoverVisible: false,
    };
  }

  clearState = () => {
    this.setState({
      inputText: "",
      inputValidation: {
        status: inputValidationStatus.INFO,
        message: "",
      },
      selectedItem: {},
      selectedPanelItem: "",
      popoverContent: {
        icon: "",
        title: "",
        isInputDisabled: false,
      },
      action: "",
      isPopoverVisible: false,
    });

    if (this.props.setPopoverContentVisible) {
      this.props.setPopoverContentVisible(false)
    }
  };

  /*
  * Removes new column
  */
  removeNewColumn = (selectedItem) => {
    let modelId = this.props.model.id
    let column = selectedItem
    let table = this.props.table

    let postObj = {
      modelId,
      column,
      table
    }

    loadingScreen(true)

    let url = `${API_BASE}/newColumn/remove`;

    let successFunc = () => {      
      this.props.removeNewColumn(postObj)
    }

    let errorFunc = () => {
      loadingScreen(false)
    }

    post(url, postObj, successFunc, errorFunc, false)
  }

  /*
  * Edits new column
  */
  editNewColumn = (selectedItem) => {
    let reduxState = store.getState()

    let table = this.props.table
    let contentObject = {
      visible: true,
      column: selectedItem,
      selectedTable: table.dataSourceKey + "_" + table.aliasName,
      mode: "edit",
      tableKey: table.index,
      edittedColumn: deepCopy(selectedItem)
    }

    let columnList = reduxState.NewColumnEditorReducer.contentObject.newColumnList
    let edittedColumn = columnList.find(cl => table.aliasName + "." + selectedItem.aliasName === cl.key)

    contentObject.code = edittedColumn?.value ? edittedColumn.value : ""
  
    store.dispatch(setNewColumnEditorContent(contentObject))
  }

  handleSelectPanelItem = (e, selectedItem) => {
    let action, icon, title, inputText, placeholder, isPopoverVisible;

    switch (e.key) {
      case "whereClause":
        //For popover content
        icon = (
          <i 
            style={{ fontSize: "14px", color: "green" }}
            className={"fontIcon"}
          >
            ω
          </i>
        );
        action = "whereClause";
        title = i18n.t("Model.WhereClauseColumn");
        placeholder = i18n.t("Model.WhereClauseColumn");
        inputText = selectedItem.whereClause ? selectedItem.whereClause : "";
        isPopoverVisible = true;
        break;
      //For popover content
      case "duplicate":
        action = "duplicate";
        title = i18n.t("Model.DuplicateColumnName");
        placeholder = "Enter new name";
        inputText = selectedItem.displayName;
        isPopoverVisible = true;
        break;
      case "info":
        action = "info";
        title = i18n.t("Model.About") + " " + selectedItem.displayName;
        isPopoverVisible = true;
        break;
      case "custom":
        icon = (
          <i
            style={{ fontSize: "14px", color: "green" }}
            className={"fontIcon"}
          >
            ∫
          </i>
        );
        action = "aggrRule";
        title = i18n.t("Model.EnterAggriagationRule");
        placeholder = "Default value: 'none'";
        inputText = selectedItem.aggrRule ? selectedItem.aggrRule : "";
        isPopoverVisible = true;
        break;
      case "rename":
        action = "rename";
        title = i18n.t("Model.EnterRenameColumnName");
        placeholder = "Enter new name";
        inputText = selectedItem.displayName;
        isPopoverVisible = true;
        break;
      case "clear":
        selectedItem.aggrRule = "";
        selectedItem.aggrRuleShow = "";

        icon = (
          <i style={{ fontSize: "14px", color: "red" }} className={"fontIcon"}>
            x
          </i>
        );
        action = "aggrRule";
        title = "Enter Aggrigation Rule";
        placeholder = "Default value: 'none'";
        inputText = selectedItem.aggrRule ? selectedItem.aggrRule : "";
        isPopoverVisible = false;
        break;
      case "deleteTable":
        icon = (
          <i style={{ fontSize: "14px", color: "red" }} className={"fontIcon"}>
            x
          </i>
        );

        this.props.deleteTableInModel(this.props.item);
        break;
      case "remove": {
        this.removeNewColumn(selectedItem);
        break;
      }
      case "edit": {
        this.editNewColumn(selectedItem);
        break;
      }
      case `${e.key}`:
        selectedItem.aggregatable = false;
        let table = this.props.table;

        if (`${e.key}` == "countdistinct") {
          selectedItem.aggrRule =
            `${e.key}`.substring(0, 5) +
            "(" +
            `${e.key}`.substring(5, 13) +
            "(`" +
            table.name +
            "`.`" +
            selectedItem.name +
            "`))";
          selectedItem.aggrRuleShow =
            `${e.key}`.substring(0, 5) +
            "(" +
            `${e.key}`.substring(5, 13) +
            "(" +
            table.displayName +
            "." +
            selectedItem.displayName +
            "))";
        } else if (`${e.key}` == "rename") {
          isPopoverVisible = false;
        } else {
          selectedItem.aggrRule =
            `${e.key}` + "(`" + table.name + "`.`" + selectedItem.name + "`)";
          selectedItem.aggrRuleShow =
            `${e.key}` +
            "(" +
            table.displayName +
            "." +
            selectedItem.displayName +
            ")";
        }

        icon = (
          <i
            style={{ fontSize: "14px", color: "green" }}
            className={"fontIcon"}
          >
            ∫
          </i>
        );
        action = "aggrRule";
        inputText = selectedItem.aggrRule ? selectedItem.aggrRule : "";
        isPopoverVisible = false;
        break;
      default:
        action = "";
        icon = "";
        title = "";
        placeholder = "";
        isPopoverVisible = false;
        break;
    }

    this.setState({
      selectedItem,
      inputText,
      placeholder,
      selectedPanelItem: action,
      popoverContent: {
        icon,
        title,
      },
      action,
      isPopoverVisible,
    });

    if (this.props.setPopoverContentVisible) {
      this.props.setPopoverContentVisible(isPopoverVisible)
    }
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      inputText: e.target.value,
    });
  };

  handlePopoverConfirm = (e) => {
    var targetItem = e.target;
    const action = targetItem.id;

    if(targetItem.value !== "" || action === "whereClause") {
    this.setState({
      inputValidation: {
        status: inputValidationStatus.LOADING,
        message: action + " has been testing..",
        isInputDisabled: true,
      },
    });

    const { selectedItem, selectedPanelItem } = this.state;
    const { data } = this.props;

    selectedItem.aggrRuleShow = targetItem.value;
    var updatedItem = JSON.parse(JSON.stringify(selectedItem));
    var duplicatedObject = {};
    let displayNameControl = targetItem.value;

    if (action === "duplicate") {
      let hashColumnsObject = {};

      data.map((column) => {
        hashColumnsObject[column.displayName] = true;
      });

      if (hashColumnsObject[displayNameControl] !== true) {
        updatedItem[selectedPanelItem] = true;
        duplicatedObject = JSON.parse(JSON.stringify(updatedItem));
        duplicatedObject.displayName = displayNameControl;
        duplicatedObject.duplicated = true;
      } else {
        showNotificationWithIcon(
          "error",
          "not duplicate with the same name",
          "error"
        );
      }
    } else if (action == "rename") {
      updatedItem["displayName"] = targetItem.value;
      this.props.updateData(updatedItem, action);
    } else if (action === "aggrRule") {
      updatedItem[selectedPanelItem] = targetItem.value;
      updatedItem[selectedPanelItem] == ""
        ? (updatedItem.aggregatable = false)
        : (updatedItem.aggregatable = true);
      this.props.updateData(updatedItem, action);
    } else {
      updatedItem[selectedPanelItem] = targetItem.value;
      this.props.updateData(updatedItem, action);
    }

    //TODO: backend formula control
    const that = this;
    that.setState({
      inputValidation: {
        status: inputValidationStatus.SUCCESS,
        message: targetItem.value + " is successfully applied.",
        isInputDisabled: false,
      },
    });

    setTimeout(function () {
      that.clearState();
    }, 500);

    if (duplicatedObject && Object.keys(duplicatedObject).length > 0) {
      that.props.updateData(duplicatedObject, action);
    }
  } else {
    showError(i18n.t("EmptyFieldMessage"))
  }
  };

  /*
  * toggles the visibility the popover and sends parent component visibility state
  */
  handleVisibleChange = (visible) => {
    this.setState({
      isPopoverVisible: visible
    });

    if (this.props.setPopoverContentVisible) {
      this.props.setPopoverContentVisible(visible)
    }
  };

  handleConfirm() {
    const { selectedItem, action } = this.state;

    if (
      action === "aggrRule" &&
      selectedItem.aggregatable == false &&
      selectedItem.aggrRule != ""
    ) {
      selectedItem.aggregatable = true;
      let updatedItem = JSON.parse(JSON.stringify(selectedItem));
      this.props.updateData(updatedItem, action);
    } else if (
      selectedItem.aggrRule === "" &&
      selectedItem.aggregatable == true
    ) {
      selectedItem.aggregatable = false;
      let updatedItem = JSON.parse(JSON.stringify(selectedItem));
      this.props.updateData(updatedItem, action);
    }
  }

  getPopoverContent = () => {
    const {
      popoverContent,
      isPopoverVisible,
      inputValidation,
      action,
      selectedItem,
    } = this.state;
    const loadingIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

    return (
      <div>
        {action == "info" ? (
          <div>
            {this.state.selectedItem.name &&
              i18n.t("Model.Name") + " " + this.state.selectedItem.name}
            {this.state.selectedItem.name && <br />}
            {this.state.selectedItem.dataSourceKey &&
              i18n.t("Model.DataSource") + " " + this.state.selectedItem.dataSourceKey}
            <br />
            {this.state.selectedItem.duplicated &&
              i18n.t("Model.Duplicated") + " " + this.state.selectedItem.duplicated}
            <br />
            {this.state.selectedItem.whereClause &&
              i18n.t("Model.WhereClause") + " " + this.state.selectedItem.whereClause}
            <br />
          </div>
        ) : action == "aggrRule" && isPopoverVisible == false ? (
          console.log(`${selectedItem.aggrRule}`)
        ) : (
          <Input
            id={action}
            placeholder={popoverContent.placeholder}
            value={this.state.inputText}
            suffix={
              <Tooltip
                title={popoverContent.title}
                content={popoverContent.message}
                overlayClassName={"model-item-operations-tooltip"}
              >
                {inputValidation.status == inputValidationStatus.INFO && (
                  <Icon
                    type="info-circle"
                    style={{ color: "rgba(0,0,0,.45)", verticalAlign: "middle" }}
                  />
                )}
                {inputValidation.status == inputValidationStatus.LOADING && (
                  <Spin indicator={loadingIcon} />
                )}
                {inputValidation.status == inputValidationStatus.SUCCESS && (
                  <Icon type="check-circle" style={{ color: "green" }} />
                )}
                {inputValidation.status == inputValidationStatus.ERROR && (
                  <Icon type="close-circle" style={{ color: "red" }} />
                )}
              </Tooltip>
            }
            disabled={popoverContent.isInputDisabled}
            onChange={this.handleChange}
            onPressEnter={this.handlePopoverConfirm}
          />
        )}
      </div>
    );
  };

  render() {
    const { selectedPanelItem, isPopoverVisible, popoverContent } = this.state;
    const { data, item, itemKey, info } = this.props;

    const popoverTitle = (
      <div>
        <div style={{marginRight: '14px' }}>
          {popoverContent.icon} {popoverContent.title}
        </div>
        <Button
          id="tablePopoverCloseButton"
          style={{
            position: "absolute",
            top: "5px",
            right: "3px",
            borderRadius: "50%",
            padding: "8px 8px",
            backgroundColor: "white",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: "18px",
            height: "18px",
            fontSize: '9px',
          }}
          type={"default"}
          onClick={() => this.handleVisibleChange(false)}
        >
          X
        </Button>
      </div>
    );
    return (
      <div>
        <ModelItemPanel
          content={
            <div className="treeItem">
              <Popover
                title={popoverTitle}
                content={this.getPopoverContent()}
                visible={isPopoverVisible}
                onVisibleChange={this.handleVisibleChange}
                trigger="click"
                overlayClassName={"model-item-panel"}
              />
              <div className="treeItemSuffix">
                {item.aggrRule && item.aggrRule != "none" && (
                  <Tooltip placement="topLeft" title={item.aggrRule} overlayClassName={"model-item-operation-icons-tooltip"}>
                    <p className="treeItemIcons">
                      <i> ∫ </i>
                    </p>
                  </Tooltip>
                )}
                {item.whereClause && (
                  <Tooltip  placement="topLeft" title={item.whereClause} overlayClassName={"model-item-operation-icons-tooltip"}>
                    <p name={"whereClauseSeen" + item.name} className="treeItemIcons">
                      <i> ω </i>
                    </p>
                  </Tooltip>
                )}
                {item.duplicated && item.columnType !== "newColumn" && (
                  <Tooltip placement="topLeft" title="Duplicated" overlayClassName={"model-item-operation-icons-tooltip"}>
                    <p name={"duplicated" + item.name} className="treeItemIcons">
                      <i> ₯ </i>
                    </p>
                  </Tooltip>
                )}
                <div name={"tableContent" + item.name} className="treeItemPanel">
                  <ModelItemPanel
                    content={<MoreOutlined style={{ cursor: "pointer" }} />}
                    item={item}
                    trigger={["click"]}
                    select={info}
                    onSelect={this.handleSelectPanelItem}
                  />
                </div>
              </div>
            </div>
          }
          item={item}
          trigger={["contextMenu"]}
          select={info}
          onSelect={this.handleSelectPanelItem}
        >
          {this.handleConfirm()}
        </ModelItemPanel>
      </div>
    );
  }
}
