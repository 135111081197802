import { Button, Col, Divider, Empty, Pagination, Row, Tooltip } from "antd";
import $ from "jquery";
import _ from "lodash";
import React, { Component } from "react";
import { deepCopy, loadingScreen } from "../../Utils/Global";
import { randomStringGenerator } from "../../Utils/RandomStringGenerator";
import { get, post } from "../../Utils/WebService";
import i18n from "../../Utils/i18next";
import { API_BASE } from "../../config";
import Accordion from "../GeneralComponents/Accordion/Accordion";
import AccordionContent from "../GeneralComponents/Accordion/AccordionContent";
import NewColumnEditor from "../GeneralComponents/NewColumn/NewColumnEditor";
import Search from "../GeneralComponents/Search/Search";
import Text from "../GeneralComponents/Text/Text";
import Column from "./Column";
import ModelItemOperations from "./ModelItemOperations";
import RemoveTableModal from "./RemoveTableModal";
import { store } from "../..";
import { setNewColumnEditorContent, setNewColumnList } from "../GeneralComponents/NewColumn/NewColumnEditorAction";
import { showNotificationWithIcon } from "../../Utils/Notification";

const tableCountInPage = 12; //Table count for a page.

/**
 * This component creates accordion for each table by returning the data of the table list that comes to it as props
 * It also fetches the selected table's data and loads it into the relevant table accordion.
 */
export default class TablesPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tables: props.showTables,
      columnDisplay: "none",
      accordionActiveKey: 0,
      info: "",
      isCloseCollapse: false,
      selectedTablesMap: new Map(),
      page: 1,
      columnSearchData: {}
    };
  }

  componentWillMount() {
    let tablesInPage = this.getTablesInSelectedPage(
      this.state.page,
      this.props.showTables
    );

    this.setState({
      ...this.state,
      tables: this.props.showTables,
      tablesInPage: tablesInPage,
    });
  }

  /**
   * Decides to switch first page for model tables
   * @param {*} props 
   * @param {*} nextProps 
   * @returns 
   */
  isShouldGoFirstPage = (props, nextProps) => {
    if (props.modelId !== nextProps.modelId) {
      return true;
    }

    if (props.searchModelValue !== nextProps.searchModelValue) {
      return true;
    }

    return false;
  }

  componentWillReceiveProps(nextProps) {
    let newState = { ...this.state };
    let isStateChanged = false;

    if (!_.isEqual(this.props.showTables, nextProps.showTables)) {
      newState.tables = nextProps.showTables;

      if (this.isShouldGoFirstPage(this.props, nextProps)) {
        newState.page = 1;
      }

      newState.tablesInPage = this.getTablesInSelectedPage(
        newState.page,
        newState.tables
      );

      isStateChanged = true;
    }

    if (nextProps.tableAccordionActiveKeyControl) {
      newState.accordionActiveKey = 0;
      isStateChanged = true;
    }

    if (nextProps.modelId !== this.props.modelId) {
      newState.selectedTablesMap = new Map();
    }

    if (this.props.hasDeselectTable !== nextProps.hasDeselectTable) {
      if (nextProps.hasDeselectTable.table) {
        if (newState.selectedTablesMap.has(nextProps.hasDeselectTable.table.dataSourceKey + "_" + nextProps.hasDeselectTable.table.aliasName)) {
          newState.selectedTablesMap.delete(nextProps.hasDeselectTable.table.dataSourceKey + "_" + nextProps.hasDeselectTable.table.aliasName);
        }
      }
    }

    if (this.props.newColumnAddedFlag !== nextProps.newColumnAddedFlag && nextProps.newColumnAddedFlag) {
      loadingScreen(true)

      let reduxState = store.getState()
      let column = reduxState.NewColumnEditorReducer
      let table = this.state.tables[column.contentObject.tableKey]

      this.onLoadColumnsForTable(table, table.index)

      newState.selectedTablesMap = new Map()

      newState.selectedTablesMap.set(column.contentObject.selectedTable, true)
    }

    if (isStateChanged === true) {
      this.setState(newState);
    }
  }

  /**
   * Change selected page
   * @param {*} value
   */
  onPageChange = (value) => {
    let tablesInPage = this.getTablesInSelectedPage(value, this.state.tables);

    this.setState({
      ...this.state,
      page: value,
      tablesInPage: tablesInPage,
      columnSearchData: {}
    });
  };

  /**
   * Calculates page count by table size
   * @param {*} tables
   * @returns
   */
  calculatePageSize = (tables) => {
    if (tables && tables.length > 0) {
      return Math.ceil(tables.length / tableCountInPage);
    }

    return 1; //set 1 page size
  };

  /**
   * Get tables in table list by page
   * @param {*} state
   * @returns
   */
  getTablesInSelectedPage = (page, tables) => {
    let startIndex = (page - 1) * tableCountInPage;
    let endIndex = startIndex + tableCountInPage;

    if (tables) {
      return tables.slice(startIndex, endIndex);
    }

    return [];
  };

  /**
   * @param key selected collapse key.
   * To set active key from accordion component.
   * the accordion content should know which collapse is open
   */
  setActiveKey = (key) => {
    this.setState({
      ...this.state,
      accordionActiveKey: key,
    });
  };

  getTableIndexInAllTableData = (allTables, item) => {
    let itemIndex = -1;
    let searchKey = item.columns ? item.aliasName : item.tableAliasName;

    allTables.map((table, index) => {
      if (table.aliasName === searchKey) {
        itemIndex = index;
      }
    });

    return itemIndex;
  };

  /*
  * Controls new column changes
  */
  controlNewColumnChanges = (col) => {
    let column = col
    let hasError = false

    if (column.aliasName == undefined || column.aliasName === "") {
      hasError = true

      showNotificationWithIcon(i18n.t("NewColumn.NewColumnCantBeEmpty"), null, "warning")
    }

    if (column.aliasName && column.aliasName !== "") {
      let tableKey = column.tableAliasName
      let tables = this.props.model.tables
      let currentTable = tables.find(tb => tb.dataSourceKey + "_" + tb.aliasName === tableKey)
      let canNameChange = currentTable && currentTable.columns.filter(col => col.aliasName === this.props.newColumn.column.aliasName).length > 0
      let isEditMode = this.props.newColumn?.column?.aliasName !== this.props.newColumn?.edittedColumn?.aliasName

      if (canNameChange && isEditMode) {
        hasError = true

        showNotificationWithIcon(i18n.t("NewColumn.ColumnNamedBefore", { column: this.props.newColumn.column.aliasName }), null, "warning")
      }
    }

    if (column.aliasName.includes(".")) {
      hasError = true

      showNotificationWithIcon(i18n.t("NewColumn.CantUseDot", { column: this.props.newColumn.column.aliasName }), null, "warning")
    }

    return hasError
  }

  // When column update, gets newColumList and sets Redux
  getAndSetColumnsToFormula = (modelId) => {
    let url = `${API_BASE}/newColumn/${modelId}`

    let succesFunc = (res) => {
      store.dispatch(setNewColumnList(res.data))
    }

    let errorFunc = (err) => {
      console.log(err)
    }

    get(url, succesFunc, errorFunc)
  }

  /**
   * Sets column code, resets and closes formula editor
   */
  applyChanges = (col, colCode, copiedColumn) => {
    let hasError = this.controlNewColumnChanges(col, colCode)
    let reduxState = store.getState()
    let newColumnList = reduxState.NewColumnEditorReducer.contentObject.newColumnList;
    let columnCode = newColumnList.find(clmn => clmn.key === copiedColumn.tableAliasName + "." + copiedColumn.aliasName)?.value

    if (hasError) {
      return
    }

    let url = `${API_BASE}/newColumn/add`;
    let column = deepCopy(col)

    column["columnDefaultFormula"] = columnCode

    let index = +column.index.split("-")[0]

    let postObj = {
      modelId: this.props.model.id,
      column: column,
      table: this.props.model.tables[index]
    }

    let succesFunc = (response) => {
      this.getAndSetColumnsToFormula(this.props.model.id)
    }

    let errorFunc = (e) => {
      console.log(e)
    }

    post(url, postObj, succesFunc, errorFunc, false)
  }

  /**
   *
   * @param {*} updatedItem the item processed
   * @param {*} action action to be taken
   *
   * updating the table or column by adding an aggreation rule, where clause, etc.
   */
  updateData = (updatedItem, action) => {
    let copiedUpdatedItem = deepCopy(updatedItem)
    let indexes = updatedItem.index.split("-");
    let columnIndex = indexes[1];
    let hasNewDuplicatedTable = false;
    let tables = deepCopy(this.props.allTables);
    let tablesInPage = deepCopy(this.state.tablesInPage);
    let tableIndex = this.getTableIndexInAllTableData(tables, updatedItem);
    let isDuplicatedNewColumn = updatedItem.columnType === "newColumn" ? true : false

    if (action === "duplicate") {
      updatedItem.aliasName = null;
      hasNewDuplicatedTable = true;

      if (indexes.length == 2) {
        updatedItem.aliasName = !isDuplicatedNewColumn ? updatedItem.name + "_" + randomStringGenerator(10).toLocaleLowerCase() : updatedItem.displayName
        updatedItem.index =
          tableIndex + "-" + tables[tableIndex].columns.length;
        tables[tableIndex].columns[columnIndex].duplicated = true;
        tables[tableIndex].columns.push(updatedItem);
      } else if (indexes.length == 1) {
        updatedItem.aliasName =
          updatedItem.name + "_" + randomStringGenerator(10);
        updatedItem.tableType = "None";
        updatedItem.index = tables.length;

        let filteredNewColumns = updatedItem.columns.filter(col => col.columnType === "newColumn")
        
        if (filteredNewColumns.length > 0) {
          updatedItem.columns = updatedItem.columns.filter(col => col.columnType !== "newColumn")

          showNotificationWithIcon(i18n.t("NewColumn.NewColumnsCantDuplicate"), null, "warning")
        }

        tables[tableIndex].duplicated = true;
        tables.push(updatedItem);
      }
    } else {
      if (indexes.length == 2) {
        tables[tableIndex].columns[columnIndex] = updatedItem;
      } else if (indexes.length == 1) {
        tables[tableIndex] = updatedItem;
      }
    }

    tablesInPage[tableIndex % tableCountInPage] = tables[tableIndex];

    this.setState({
      ...this.state,
      tables: tables,
      tablesInPage: tablesInPage
    });

    if (isDuplicatedNewColumn && action !== "aggrRule") {
      this.applyChanges(updatedItem, updatedItem.columnDefaultFormula, copiedUpdatedItem)

      this.props.updateModel(tables, hasNewDuplicatedTable);
    } else {
      this.props.updateModel(tables, hasNewDuplicatedTable);
    }
  };

  /**
   *
   * @param {Array} columns Column object list
   * Loaded columns are set to true if they are both or local.
   */
  setColumnsSelected = (columns, isManuelTable) => {
    let columnsNameHashSet = new Set();
    columns.map((column) => {
      if (
        column.source &&
        (column.source == "Local" || column.source == "Both")
      ) {
        columnsNameHashSet.add(column.name);

        column.selected = true;

        if (isManuelTable === true) {
          column.isEnable = true;
        }
      } else {
        column.selected = false;

        if (isManuelTable === true) {
          column.isEnable = false;
        }
      }
    });

    return { columns: columns, columnsNameHashSet: columnsNameHashSet };
  };

  /**
   *
   * @param {Object} table
   * @param {String} key
   *
   * Loading the columns of the selected table
   */
  onLoadColumnsForTable = (table, index) => {
    let url;
    const { modelId } = this.props;

    if (table.aliasName) {
      url = `${API_BASE}/subjectAreaMerged/${modelId}/tables/${table.dataSourceKey}/${table.aliasName}/columns`;
    } else {
      url = `${API_BASE}/subjectAreaMerged/${modelId}/tables/${table.dataSourceKey}/${table.name}/columns`;
    }

    const successFunc = (response) => {
      let isManuelTable = table.manuel === true ? true : false;
      let columnsInformation = this.setColumnsSelected(
        response.data,
        isManuelTable
      );
      table.columns = columnsInformation.columns;
      table.columnsNameHashSet = columnsInformation.columnsNameHashSet;

      let tables = deepCopy(this.state.tables);
      let tablesInPage = deepCopy(this.state.tablesInPage);

      tables[index] = table;
      tablesInPage[index % tableCountInPage] = table;

      this.setState({
        ...this.state,
        tables: tables,
        tablesInPage: tablesInPage,
        columnDisplay: "block",
      });

      this.props.updateTables(table);
    };

    get(url, successFunc);
  };

  /**
   *
   * @param {*} key
   * Active accordion select method
   */
  onSelectAccordion = (key, tableIndex) => {
    var selectedTablesMap = this.state.selectedTablesMap;
    let columnSearchData = this.state.columnSearchData;

    if (key !== undefined && key !== null) {
      let table = this.state.tables[tableIndex];
      let isTableSelected = selectedTablesMap.get(table.dataSourceKey + "_" + table.aliasName);
      isTableSelected = isTableSelected ? isTableSelected : false;

      selectedTablesMap.set(table.dataSourceKey + "_" + table.aliasName, !isTableSelected);

      this.onLoadColumnsForTable(table, tableIndex);
    } else {
      let table = this.state.tables[tableIndex];
      let isTableSelected = selectedTablesMap.get(table.dataSourceKey + "_" + table.aliasName);

      selectedTablesMap.set(table.dataSourceKey + "_" + table.aliasName, !isTableSelected);
    }

    delete columnSearchData[key]

    this.setState({
      ...this.state,
      selectedTablesMap: selectedTablesMap,
      columnSearchData: columnSearchData
    });
  };
  /**
   *
   * @param {*} status
   * Sets whether collapse is on or off
   */
  setCloseCollapses = (status) => {
    if (this.state.isCloseCollapse !== status) {
      this.setState({
        ...this.state,
        isCloseCollapse: status,
      });
    }
  };

  /**
   *
   * @param {Object} table
   * @param {String} tableIndex
   *
   * Popup for aggregation rule, duplicate info, rename etc.
   */
  getExtra = (table, tableIndex) => {
    table.index = tableIndex.toString();

    return (
      <div
        style={{
          position: "absolute",
          top: "10px",
          right: 0,
        }}
      >
        <ModelItemOperations
          data={this.state.tables}
          item={table}
          key={table.id}
          itemKey={table.id}
          info={this.state.info}
          table={table}
          updateData={this.updateData}
          deleteTableInModel={this.deleteTableInModel}
          model={this.props.model}
          removeNewColumn={this.props.removeNewColumn}
        />
      </div>
    );
  };

  //Updating in tables list when column select or unselect
  updateColumn = (tableIndex, column) => {
    let tables = deepCopy(this.state.tables);
    let tablesInPage = deepCopy(this.state.tablesInPage);

    if (column.selected === true) {
      tables[tableIndex].columns.map((item) => {
        if (column.name === item.name && column.aliasName !== item.aliasName) {
          item.duplicated = true;
          column.duplicated = true;
        }
      });
    }

    tables[tableIndex].columns[column.index] = column;
    tablesInPage[tableIndex % tableCountInPage] = tables[tableIndex];

    this.setState({
      ...this.state,
      tables: tables,
      tablesInPage: tablesInPage
    });

    this.props.updateTables(tables[tableIndex]);

    let allTables = deepCopy(this.props.allTables);
    let preparedAllTables = [];

    for (let i = 0; i < allTables.length; i++) {
      if (allTables[i].dataSourceKey + "_" + allTables[i].aliasName === tables[tableIndex].dataSourceKey + "_" + tables[tableIndex].aliasName) {
        preparedAllTables.push(tables[tableIndex]);
      } else {
        preparedAllTables.push(allTables[i]);
      }
    }

    this.props.updateModel(preparedAllTables);
  };

  //Checks join list for table
  hasJoinForTable = (table) => {
    let hasJoin = false;

    if (this.props.joins && this.props.joins.length > 0) {
      this.props.joins.every(function (join) {
        if (join.dimensionTableAlias === table.aliasName) {
          hasJoin = true;
          return false;
        }

        if (join.factTableAlias === table.aliasName) {
          hasJoin = true;
          return false;
        }

        return true;
      });
    }

    return hasJoin;
  };

  /**
   *
   * @param {Object} table Selected table object to delete
   * This method takes the selected table object and passes it to the parent component for deletion.
   */
  deleteTableInModel = (table) => {
    if (this.hasJoinForTable(table) > 0) {
      this.setState({
        ...this.state,
        removeTableModelVisibility: true,
        removeTable: table,
      });
    } else {
      this.deleteTableInModelSuccess(table);
    }
  };

  //Close remove table modal
  closeRemoveTableModelVisibility = () => {
    this.setState({
      ...this.state,
      removeTableModelVisibility: false,
      removeTable: undefined,
    });
  };

  //Close and continiue remove table modal
  applyRemoveTableInModel = (table) => {
    this.setState(
      {
        ...this.state,
        removeTableModelVisibility: false,
        removeTable: undefined,
      },
      () => this.deleteTableInModelSuccess(table)
    );
  };

  //Deletes table in model
  deleteTableInModelSuccess = (table) => {
    this.props.deleteTableInModel(table, false, "usedTableData");

    let selectedTablesMap = this.state.selectedTablesMap;

    if (selectedTablesMap.has(table.dataSourceKey + "_" + table.aliasName)) {
      selectedTablesMap.delete(table.dataSourceKey + "_" + table.aliasName);
    }
  };

  /**
   * Sets the column search text for the selected table
   * 
   * @param {*} id 
   * @param {*} value 
   */
  setcolumnSearchData = (id, value) => {
    let columnSearchData = { ...this.state.columnSearchData };

    columnSearchData[id] = value

    this.setState({
      ...this.state,
      columnSearchData: columnSearchData
    });
  }

  /**
   * Filters the columns on the search text
   * 
   * @param {*} id 
   * @param {*} columns 
   * @returns 
   */
  filterColumns = (id, columns) => {
    let filteredColumns = [];
    let searchTerm = deepCopy(this.state.columnSearchData[id])

    if (searchTerm && searchTerm !== "") {
      //escapes special characters in the search term for regex use.
      const escapeRegExp = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      };

      //normalizes Turkish specific characters for case-insensitive comparison.
      const normalizeString = (str) => {
        return str
          .replace(/İ/g, 'i')
          .replace(/I/g, 'i')
          .replace(/ı/g, 'i')
      }

      searchTerm = normalizeString(escapeRegExp(searchTerm));

      let regex = new RegExp(searchTerm, "gi");

      filteredColumns = columns?.map(column => {
        let displayName = normalizeString(column.displayName);

        if (regex.test(displayName)) {
          return column
        }

        return null
      }); 
    } else {
      filteredColumns = columns;
    }

    return filteredColumns;
  }

  addNewColumn = (tableId, key) => {
    let newColumnObject = {}

    newColumnObject.table = tableId
    newColumnObject.visible = true
    newColumnObject.selectedTable = tableId
    newColumnObject.mode = "add"
    newColumnObject.column = {
      "name": "",
      "aliasName": "",
      "value": null,
      "displayName": "",
      "description": null,
      "nullable": false,
      "dataType": "varchar",
      "aggregatable": false,
      "aggrRule": null,
      "hidden": false,
      "columnType": null,
      "format": null,
      "isEnable": false,
      "source": "Both",
      "doubleColumn": false,
      "duplicated": false,
      "windowFunction": false,
      "columnType": "newColumn"
    }
    newColumnObject.tableKey = key

    store.dispatch(setNewColumnEditorContent(newColumnObject))
  }

  render() {
    let generalWidth = $("body").innerWidth();
    let calcContainerWidth = (generalWidth * 53) / 100;
    //If Container's width 900 or bigger, models name fit the div at %35
    const isContainerBiggerThan900 = calcContainerWidth >= 900 ? true : false;
    //If Container's width 750 or lower, models name fit the div at %20, If 750 < container's width < 900 we can give %20 width
    const isContainerLowerThan750 = calcContainerWidth < 750 ? true : false;

    return (
      <div style={{ position: "absolute", width: "100%" }}>
        <Row gutter={6} style={{ marginBottom: "30px", height: "375px", overflow: 'scroll' }}>
          {this.state.tablesInPage && this.state.tablesInPage.length > 0 ? (
            this.state.tablesInPage.map((table, tableIndex) => {
              let filteredColumns = [];
              let filteredColumnsLength = 0;
              let columnsLength = table.columns?.length || 0;
              let tableId = table.dataSourceKey + "_" + table.aliasName;
              let isTableActive = this.state.selectedTablesMap.get(tableId) ? true : false;

              if (isTableActive) {
                filteredColumns = this.filterColumns(tableId, table.columns);
                filteredColumnsLength = filteredColumns.filter(c => c).length;
              }

              return (
                <Col span={12} key={"col-" + tableIndex}>
                  <Accordion
                    defaultActiveKey={"0"}
                    key={tableId}
                    activeKey={
                      isTableActive
                        ? tableId
                        : 0
                    }
                    onSelect={(key) => this.onSelectAccordion(key, tableIndex + ((this.state.page - 1) * tableCountInPage))}
                  >
                    <AccordionContent
                      margin={false}
                      isCloseCollapse={this.state.isCloseCollapse}
                      setCloseCollapses={this.setCloseCollapses}
                      extra={this.getExtra(table, tableIndex)}
                      title={table.displayName}
                      headerStyle={{ background: "#fff" }}
                      collapseStyle={{ background: "#fff" }}
                      holderStyle={{
                        border: "1px solid #f1f1f1",
                        marginBottom: "10px",
                      }}
                      eventKey={tableId}
                      key={"content-" + tableId}
                    >
                      <div
                        style={{
                          padding: "0 10px",
                          display: this.state.columnDisplay,
                        }}
                      >
                        <Row>
                          <Col span={16}>
                            {
                              isTableActive && columnsLength > 0 && (
                                <Search
                                  id={"search-" + tableId}
                                  paddingNone={true}
                                  searchBarVisible={true}
                                  width="100%"
                                  marginBottom="10px"
                                  onChange={(event) => this.setcolumnSearchData(tableId, event.target.value)}
                                />
                              )
                            }
                          </Col>
                          <Col span={8} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: "6px" }}>
                          <Tooltip title={i18n.t("NewColumn.AddCalculatedColumn")}>
                          <Button onClick={() => this.addNewColumn(tableId, tableIndex + ((this.state.page - 1) * tableCountInPage))}>
                              <span>{i18n.t("Add")}</span>
                              <span> +</span>
                            </Button>
                          </Tooltip>
                          </Col>
                        </Row>

                        <div class="row">
                          {
                            (filteredColumnsLength > 0 && filteredColumns.map((column, colIndex) => {
                              if (column && column.columnType !== "newColumn") {
                                return (
                                  <Column
                                    table={table}
                                    column={column}
                                    columnIndex={(tableIndex + ((this.state.page - 1) * tableCountInPage)) + "-" + colIndex}
                                    divClassName={"col-lg-6"}
                                    textStyle={
                                      isContainerBiggerThan900
                                        ? { width: "35%" }
                                        : isContainerLowerThan750
                                          ? { width: "10%", left: "-5px" }
                                          : { width: "20%" }
                                    }
                                    updateData={this.updateData}
                                    updateColumn={this.updateColumn}
                                    model={this.props.model}
                                    removeNewColumn={this.props.removeNewColumn}
                                  />
                                );
                              }

                              return null;
                            })) || (
                              <Empty
                                style={{ width: "100%" }}
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={i18n.t(columnsLength > 0 ? "NoDataContent.NoData" : "Model.EmptyTable")}
                              />
                            )
                          }
                        </div>
                        {filteredColumns.filter(col => col && col.columnType === "newColumn").length > 0 ? <Divider><span style={{fontSize: "13px", color: "grey"}}>{i18n.t("NewColumn.NewColumn")}</span></Divider> : null}
                        <div class="row">
                          {
                            (filteredColumnsLength > 0 && filteredColumns.map((column, colIndex) => {
                              if (column && column.columnType === "newColumn") {
                                return (
                                  <Column
                                    table={table}
                                    column={column}
                                    columnIndex={(tableIndex + ((this.state.page - 1) * tableCountInPage)) + "-" + colIndex}
                                    divClassName={"col-lg-6"}
                                    textStyle={
                                      isContainerBiggerThan900
                                        ? { width: "35%" }
                                        : isContainerLowerThan750
                                          ? { width: "10%", left: "-5px" }
                                          : { width: "20%" }
                                    }
                                    updateData={this.updateData}
                                    updateColumn={this.updateColumn}
                                    model={this.props.model}
                                    removeNewColumn={this.props.removeNewColumn}
                                  />
                                );
                              }

                              return null;
                            }))
                          }
                        </div>
                      </div>
                    </AccordionContent>
                  </Accordion>
                </Col>
              )
            })
          ) : (
            <Text
              type="span"
              style={{
                fontSize: "14px",
                display: "block",
                marginTop: "75px",
                marginBottom: "75px",
                color: "#afafaf",
                textAlign: "center",
                whiteSpace: "normal",
                padding: "0 120px",
              }}
            >
              {i18n.t("Model.Tables.TablesContentDescription")}
            </Text>
          )}
        </Row>

        {this.state.tables && this.state.tables.length > 0 ? (
          <Pagination
            className="model-tables-pagination"
            size={"small"}
            onChange={(page) => this.onPageChange(page)}
            defaultCurrent={this.state.page}
            current={this.state.page}
            total={this.state.tables ? this.state.tables.length : 0}
            pageSize={tableCountInPage}
          />
        ) : null}

        <RemoveTableModal
          visible={this.state.removeTableModelVisibility}
          table={this.state.removeTable}
          onCancel={this.closeRemoveTableModelVisibility}
          onOk={this.applyRemoveTableInModel}
        />
      </div>
    );
  }
}
