import React from "react";
import { Modal, Input, Select } from "antd";
import SessionVariableCodeEditor from "./SessionVariableCodeEditor"
import i18n from "../../../Utils/i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import SessionVariableFooter from "./SessionVariableFooter";
import SessionVariableConnectionList from "./SessionVariableConnectionList"
import { showNotificationWithIcon } from "../../../Utils/Notification";
import _ from "lodash";
import SessionVariableTableAndColumnList from "./SessionVariableTableColumnList";
import Text from "../Text/Text";
import initSessionVariableLang from "../../../Utils/VispLanguage/session-variable";
import { post } from "../../../Utils/WebService";
import { API_BASE } from "../../../config";
import Axios from "axios";
import moment from "moment"

const { Option } = Select;

/**
 * Session Variable Editor's main component
 */
export default class SessionVariableEditor extends React.Component {
    constructor(props) {
        super(props);

        let selectedVariable = { ...this.props.selectedVariable };

        this.state = {
            selectedVariable: selectedVariable,
            dataSource: selectedVariable?.dataSource !== undefined ? selectedVariable.dataSource : null,
            defaultValue: selectedVariable?.defaultValue !== undefined ? selectedVariable.defaultValue : "",
            name: selectedVariable?.name !== undefined ? selectedVariable.name : "",
            scope: selectedVariable?.scope !== undefined ? selectedVariable.scope : "dashboard",
            code: selectedVariable?.code !== undefined ? selectedVariable.code : "",
            dataType: selectedVariable?.dataType !== undefined ? selectedVariable.dataType : "text",
            languageId: initSessionVariableLang(this.props.model, selectedVariable?.dataSource),
            showConfirm: false,
            queryTestResut: null,
            changes: false
        };
    }

    componentWillReceiveProps(nextProps) {
        let { selectedVariable } = nextProps;

        if (_.isEqual(selectedVariable, this.state.selectedVariable)) return;

        let newState = {
            ...this.state,
            selectedVariable: selectedVariable,
            dataSource: selectedVariable?.dataSource ? selectedVariable.dataSource : null,
            defaultValue: selectedVariable?.defaultValue ? selectedVariable.defaultValue : "",
            name: selectedVariable?.name ? selectedVariable.name : "",
            scope: selectedVariable?.scope ? selectedVariable.scope : "dashboard",
            code: selectedVariable?.code ? selectedVariable.code : "",
            dataType: selectedVariable?.dataType ? selectedVariable.dataType : "text",
            languageId: initSessionVariableLang(nextProps.model, selectedVariable?.dataSource),
            queryTestResut: null,
            changes: false
        };

        this.setState(newState);
    }

    /**
     * Prepares the variable object
     * 
     * @returns 
     */
    parepareVariable = () => {
        return {
            ...this.state.selectedVariable,
            code: this.state.code,
            dataSource: this.state.dataSource,
            defaultValue: this.state.defaultValue,
            name: this.state.name,
            scope: this.state.scope,
            model: this.props.model.id,
            dataType: this.state.dataType
        }
    }

    /**
     * On apply handler
     */
    onApply = () => {
        let error = this.checkValidationError();

        if (error === false) {
            let variable = this.parepareVariable();

            if (this.props.selectedVariable?._id) {
                this.props.updateSessionVariable(variable)
            } else {
                this.props.createSessionVariable(variable)
            }
        } else {
            showNotificationWithIcon(i18n.t("SessionVariable"), error, "error");
        }
    }

    /**
     * Resets editor content
     */
    resetEditor = () => {
        this.setState({
            selectedVariable: null,
            dataSource: null,
            defaultValue: "",
            name: "",
            scope: "dashboard",
            code: "",
            dataType: "text",
            languageId: "",
            showConfirm: false,
            queryTestResut: null,
            changes: false
        });
    }

    /**
     * Shows confirm cancel modal
     */
    showConfirm = () => {
        Modal.confirm({
            title: i18n.t("FormulaEditor.Titles.AreYouSureCancel"),
            icon: <ExclamationCircleOutlined />,
            content: i18n.t("FormulaEditor.YourChangesWillBeLost"),
            centered: true,
            zIndex: "1001",
            cancelText: i18n.t("Cancel"),
            cancelButtonProps: {
                className: "general-button-outlined",
                style: {
                    padding: "4px 12px",
                    marginRight: "6px",
                }
            },
            okText: i18n.t("OK"),
            okButtonProps: {
                className: "general-button",
                style: {
                    padding: "4px 12px !important",
                    margin: "0",
                    marginLeft: "6px",
                    width: "fit-content"
                }
            },
            onOk: () => {
                Modal.destroyAll();
                this.props.closeModal();
                this.resetEditor();
            }
        });
    }

    /**
     * Checks for changes
     * It opens the confirm cancel modal if there is any change
     */
    exitSessionVariableEditor = (showConfirm = true) => {
        if (this.state.changes && showConfirm) {
            this.showConfirm();
        } else {
            Modal.destroyAll();
            this.props.closeModal();
            this.resetEditor();
        }
    }

    /**
     * Variable validation check
     * 
     * @returns 
     */
    checkValidationError = () => {
        let errors = [];

        if (!this.state.name) {
            errors.push(i18n.t("SessionVariables.SessionName"))
        }

        if (!this.state.defaultValue) {
            errors.push(i18n.t("SessionVariables.SessionDefaultVariable"))
        }

        if (!this.state.scope) {
            errors.push(i18n.t("SessionVariables.Scope"))
        }

        if (!this.state.dataType) {
            errors.push(i18n.t("SessionVariables.DataType"))
        }

        if (errors.length === 1) {
            let field = errors[0];

            return i18n.t("SessionVariables.PleaseCheckField").replace("[field]", field);
        } else if (errors.length > 1) {
            let fields = errors.join(", ");

            return i18n.t("SessionVariables.PleaseCheckFields").replace("[fields]", fields);
        }

        return false;
    }

    /**
     * Sets name
     * 
     * @param {*} e 
     */
    sessionNameOnChange = (e) => {
        let value = e.target.value;

        value = value.replace(/[^a-zA-Z0-9_]/g, '');

        this.setState({
            ...this.state,
            name: value,
            changes: true
        });
    }

    /**
     * Sets scope
     * 
     * @param {*} value 
     */
    scopeOnChange = (value) => {
        this.setState({
            ...this.state,
            scope: value,
            changes: true
        });
    }

    /**
     * Sets data type
     * 
     * @param {*} value 
     */
    dataTypeOnChange = (value) => {
        this.setState({
            ...this.state,
            dataType: value,
            changes: true
        });
    }

    /**
     * Sets default value
     * 
     * @param {*} value 
     */
    defaultValueOnChange = (e) => {
        this.setState({
            ...this.state,
            defaultValue: e.target.value,
            changes: true
        });
    }

    /**
     * Sets selected connection
     * 
     * @param {*} connection 
     */
    selectConnection = (connection) => {
        this.setState({
            ...this.state,
            dataSource: connection,
            changes: true
        });
    }

    /**
     * Sets code
     * 
     * @param {*} value 
     */
    updateCode = (value) => {
        this.setState({
            code: value,
            changes: true
        });
    }

    /**
     * Executes the variable to test query.
     */
    testQuery = () => {
        if (this.testCancelToken?.cancel) {
            this.testCancelToken.cancel();
        }

        let variable = this.parepareVariable();
        let url = `${API_BASE}/session-variable/variable/test`;

        if (typeof variable.code !== "string" || variable.code?.length === 0) {
            return this.setState({
                ...this.state,
                queryTestResut: {
                    status: "success",
                    message: "",
                    result: variable.defaultValue
                }
            });
        } else if (!this.state.dataSource) {
            return this.setState({
                ...this.state,
                queryTestResut: {
                    status: "error",
                    message: "MISSING DATASOURCE",
                    result: variable.defaultValue
                }
            });
        }

        let successFunc = result => {
            if (result.data) {
                let queryTestResut = { ...result.data };
                let value = queryTestResut.result;

                switch (variable.dataType) {
                    case "text":
                        queryTestResut.result = value instanceof Array
                            ? value.map(v => v === null ? "''" : v)
                            : value === null ? "''" : value;

                        break;

                    case "date":
                        let format = "YYYY-MM-DD";

                        if (value instanceof Array) {
                            value = value.filter(v => v !== null);
                            queryTestResut.result = value.map(v => `'${moment(v).format(format)}'`);
                        } else {
                            queryTestResut.result = value === null ? "NULL" : `'${moment(value).format(format)}'`;
                        }

                        break;

                    case "number":
                        if (value instanceof Array) {
                            value = value.filter(v => v !== null);
                            queryTestResut.result = value.map(v => isNaN(v) ? v : Number(v));
                        } else {
                            queryTestResut.result = value === null ? "NULL" : isNaN(value) ? value : Number(value);
                        }

                        break;

                    default:
                        break;
                }

                this.setState({
                    ...this.state,
                    queryTestResut: queryTestResut
                });
            }
        }

        let errorFunc = error => {
            this.setState({
                ...this.state,
                queryTestResut: {
                    status: "error",
                    message: error.message,
                    result: variable.defaultValue
                }
            });
        }

        this.testCancelToken = Axios.CancelToken.source();

        this.setState({
            ...this.state,
            queryTestResut: {
                status: "processing"
            }
        }, () => post(
            url,
            variable,
            successFunc,
            errorFunc,
            false,
            false,
            undefined,
            true,
            this.testCancelToken.token
        ));
    }

    /**
     * Aborts the test query execution
     */
    abortTestQuery = () => {
        this.testCancelToken.cancel();

        this.testCancelToken = null;

        this.setState({
            ...this.state,
            queryTestResut: null
        });
    }

    render() {
        return (
            <Modal
                visible={this.props.visible}
                closable={false}
                maskClosable={false}
                onCancel={this.exitSessionVariableEditor}
                destroyOnClose={true}
                width="90%"
                centered={true}
                footer={false}
                className="formula-editor-modal"
                style={{
                    maxWidth: 2560
                }}
                bodyStyle={{
                    height: "90dvh",
                    maxHeight: 1440,
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "center",
                    padding: "0",
                    overflow: "hidden",
                }}
            >
                {
                    this.props.visible &&
                    <div
                        style={{
                            minHeight: "450px",
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        <div
                            style={{
                                order: "1",
                                width: "20%",
                                height: "100%",
                                borderRight: "solid 1px #dadada"
                            }}
                        >
                            <div style={{ height: "100%", paddingBottom: "8px" }}>
                                <SessionVariableTableAndColumnList
                                    selectedDataSource={this.state.dataSource}
                                    code={this.state.code}
                                    updateCode={this.updateCode}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                order: "2",
                                width: "60%",
                                height: "100%"
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    height: 130,
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    padding: "8px 16px 8px 8px",
                                    borderRight: "solid 1px #dadada",
                                    borderBottom: "solid 1px #dadada",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        marginTop: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "end",
                                            width: "50%",
                                            paddingRight: "8px"
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontSize: 11
                                            }}
                                        >
                                            {i18n.t("SessionVariables.SessionName")}
                                        </Text>
                                        <Input
                                            value={this.state.name}
                                            onChange={this.sessionNameOnChange}
                                            style={{ width: "100%" }}
                                        />
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "end",
                                            width: "50%",
                                            paddingLeft: "8px"
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontSize: 11
                                            }}
                                        >
                                            {i18n.t("SessionVariables.DataType")}
                                        </Text>
                                        <Select
                                            id={"Scope"}
                                            value={
                                                typeof this.state.dataType !== "string"
                                                    ? "text"
                                                    : this.state.dataType
                                            }
                                            defaultValue={"text"}
                                            onChange={this.dataTypeOnChange}
                                            style={{ width: "100%" }}
                                            getPopupContainer={(trigger) => trigger.parentNode}
                                        >
                                            <Option value={"text"} key={"text"}>{i18n.t("SessionVariables.DataTypes.Text")}</Option>
                                            <Option value={"number"} key={"number"}>{i18n.t("SessionVariables.DataTypes.Number")}</Option>
                                            <Option value={"date"} key={"date"}>{i18n.t("SessionVariables.DataTypes.Date")}</Option>
                                        </Select>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        marginTop: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "end",
                                            width: "50%",
                                            paddingRight: "8px"
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontSize: 11
                                            }}
                                        >
                                            {i18n.t("SessionVariables.SessionDefaultVariable")}
                                        </Text>
                                        <Input
                                            value={this.state.defaultValue}
                                            onChange={this.defaultValueOnChange}
                                            style={{ width: "100%" }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "end",
                                            alignItems: "start",
                                            width: "50%",
                                            paddingLeft: "8px"
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontSize: 11,
                                                display: "flex",
                                                alignItems: "center"
                                            }}
                                        >
                                            {i18n.t("SessionVariables.Scope")}
                                        </Text>
                                        <Select
                                            id={"Scope"}
                                            value={
                                                typeof this.state.scope !== "string"
                                                    ? "query"
                                                    : this.state.scope
                                            }
                                            defaultValue={"query"}
                                            onChange={this.scopeOnChange}
                                            style={{ width: "100%" }}
                                            getPopupContainer={(trigger) => trigger.parentNode}
                                        >
                                            <Option value={"query"} key={"query"}>{i18n.t("SessionVariables.Scopes.Query")}</Option>
                                            <Option value={"dashboard"} key={"dashboard"}>{i18n.t("SessionVariables.Scopes.Dashboard")}</Option>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                    height: "calc((100% - 130px) * 0.75)",
                                    borderRight: "solid 1px #dadada",
                                    position: "relative"
                                }}
                            >
                                <SessionVariableCodeEditor
                                    height="100%"
                                    onCancel={this.exitSessionVariableEditor}
                                    code={this.state.code}
                                    onApply={this.onApply}
                                    language={this.state.languageId}
                                    updateCode={this.updateCode}
                                    dataSource={this.state.dataSource}
                                    testResult={this.state.queryTestResut}
                                    testQuery={this.testQuery}
                                    abortTestQuery={this.abortTestQuery}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    height: "calc((100% - 130px) * 0.25)",
                                    borderRight: "solid 1px #dadada",
                                    borderTop: "solid 1px #dadada"
                                }}
                            >
                                <SessionVariableFooter
                                    dataType={this.state.dataType}
                                    testResult={this.state.queryTestResut}
                                    testQuery={this.testQuery}
                                    abortTestQuery={this.abortTestQuery}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                order: "3",
                                width: "20%",
                                height: "100%",
                                paddingBottom: 8
                            }}
                        >
                            <SessionVariableConnectionList
                                onSelect={this.selectConnection}
                                dataSource={this.state.dataSource}
                            />
                        </div>
                    </div>
                }
            </Modal>
        )
    }
}