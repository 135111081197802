import { Divider } from "antd";
import React, { Component } from "react";
import { exceptConditionalFormatComponent, exceptDataComponent, exceptInteractionComponent, exceptNavigationComponent } from "../../config";
import ConditionalFormattingButton from "./PluginToolsComponents/ConditionalFormattingButton";
import ConfigurationButton from "./PluginToolsComponents/ConfigurationButton";
import DataButton from "./PluginToolsComponents/DataButton";
import InteractionButton from "./PluginToolsComponents/InteractionButton";
import NavigationButton from "./PluginToolsComponents/NavigationButton";
import RefreshButton from "./PluginToolsComponents/RefreshButton";
import RemoveButton from "./PluginToolsComponents/RemoveButton";
import ShowTooltipButton from "./PluginToolsComponents/ShowPluginToolsButton";
import "./plugin.css";

export default class PluginTools extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPluginTools: false,
      showPluginDeletePopup: false
    };
  }

  /*
  * Changes popup visibility
  */
  changePopupVisibility = (status) => {
    this.setState({
      showPluginDeletePopup: status
    })
  }

  pluginControl = () => {
    this.setState({
      ...this.state,
      showPluginTools: !this.state.showPluginTools
    });
  };

  iconsClassNameControl = (isHeightOne, isWidthOne) => {
    if (isHeightOne) {
      if (isWidthOne) {
        return "circle-icon plugin-width-one-buttons";
      } else {
        return "circle-icon plugin-height-one-buttons";
      }
    } else if (!isHeightOne && isWidthOne) {
      return "circle-icon plugin-width-one-buttons";
    } else {
      return "circle-icon";
    }
  };

  /*
  * On mouse enter on plugin tools, disabled drag drop
  */
  onMouseEnterPluginTools = (e) => {
    this.props.changeStatusOfHoveredPluginTools(true)
  }

  /*
  * On mouse leaves on plugin tools, enabled drag drop
  */
  onMouseLeavePluginTools = (e) => {
    this.props.changeStatusOfHoveredPluginTools(false)
  }

  render() {
    const isShowPluginTools = !this.props.activeInteractionPluginObject.plugin
      ? true
      : false;
    const isPluginToolsOpen = this.state.showPluginTools == true ? true : false;
    const isPluginToolsClose =
      this.state.showPluginTools == false ? true : false;
    const closePluginToolsAnimation = "closeShowPluginToolsAnimation .5s";
    const openPluginToolsAnimation = "openShowPluginToolsAnimation .5s";
    const closePluginToolsOpacityTrue = "closeShowPluginToolsOpacityTrue .5s";
    const isPluginHeightOne = this.props.plugin.h <= 1 ? true : false;
    const isPluginWidthOne = this.props.plugin.w === 1 ? true : false;

    const classNamesByPluginSize = () => {
      //If our plugins width equal 1 and our height bigger 1 on grid system, this block is running
      if (
        (isPluginHeightOne && isPluginWidthOne) ||
        (isPluginWidthOne && !isPluginHeightOne)
      ) {
        if (isPluginToolsOpen) {
          return "showTooltipButton-open plugin-height-one plugin-width-one-open";
        } else if (isPluginToolsClose) {
          return "showTooltipButton-close plugin-height-one plugin-width-one-close";
        }
      }
      //If our plugins width greater than 1 and our height equal 1 on grid system, this block is running
      else if (isPluginHeightOne) {
        if (isPluginToolsOpen) {
          return "showTooltipButton-open plugin-height-one-open";
        } else if (isPluginToolsClose) {
          return "showTooltipButton-close plugin-height-one-close";
        }
      }
      //If our plugins both of width and height greater than 1, this block is running
      else {
        if (isPluginToolsOpen) {
          return "showTooltipButton-open";
        } else if (isPluginToolsClose) {
          return "showTooltipButton-close";
        }
      }
    };

    let deleteButtonBorderColor = "#e85252";

    if (this.state.showPluginDeletePopup) {
      deleteButtonBorderColor = "#236090";
    } else {
      deleteButtonBorderColor = "#e85252";
    }

    const configurationButtons = (
      <div
        className={
          isPluginWidthOne && isPluginHeightOne
            ? "configuration-buttons-one-height"
            : ""
        }
        style={{display: this.props.visibilityOfTooltipButtons ? "block" : "none"}}
      >
        <RemoveButton
          plugin={this.props.plugin}
          showPluginDeletePopup={this.state.showPluginDeletePopup}
          removePlugin={this.props.removePlugin}
          isPluginHeightOne={isPluginHeightOne}
          isPluginWidthOne={isPluginWidthOne}
          iconsClassNameControl={this.iconsClassNameControl}
          changePopupVisibility={this.changePopupVisibility}
          deleteButtonBorderColor={deleteButtonBorderColor}
        />
        <Divider
          type="vertical"
          className={
            isPluginWidthOne || isPluginHeightOne ? "delete-vertical" : ""
          }
        ></Divider>
        <RefreshButton
          pluginId={this.props.plugin.id}
          refresh={this.props.refresh}
          isPluginHeightOne={isPluginHeightOne}
          isPluginWidthOne={isPluginWidthOne}
          iconsClassNameControl={this.iconsClassNameControl}
          changeJoinErrorVisibility={this.props.changeJoinErrorVisibility}
          joinErrorVisibility={this.props.joinErrorVisibility}
          clickedRefresh={this.props.clickedRefresh}
          setClickedRefresh={this.props.setClickedRefresh}
          refreshedPluginId={this.props.refreshedPluginId}
          changeRefreshedPluginId={this.props.changeRefreshedPluginId}
        />
        {(!exceptNavigationComponent.has(this.props.plugin.key) && this.props.readOnly) ? (
          <NavigationButton
            changeNavigationVisibility={this.props.changeNavigationVisibility}
            isPluginHeightOne={isPluginHeightOne}
            isPluginWidthOne={isPluginWidthOne}
            iconsClassNameControl={this.iconsClassNameControl}
          />
        ) : null}
        {(!exceptInteractionComponent.has(this.props.plugin.key) && this.props.readOnly) ? (
          <InteractionButton
            plugin={this.props.plugin}
            setActiveInteractionPlugin={this.props.setActiveInteractionPlugin}
            isPluginHeightOne={isPluginHeightOne}
            isPluginWidthOne={isPluginWidthOne}
            iconsClassNameControl={this.iconsClassNameControl}
          />
        ) : null}{" "}
        {!exceptConditionalFormatComponent.has(this.props.plugin.key) ? (
          <ConditionalFormattingButton
            changeConditionalFormattingVisibility={
              this.props.changeConditionalFormattingVisibility
            }
            plugin={this.props.plugin}
            isPluginHeightOne={isPluginHeightOne}
            isPluginWidthOne={isPluginWidthOne}
            iconsClassNameControl={this.iconsClassNameControl}
          />
        ) : null}
        <ConfigurationButton
          changeConfigVisibility={this.props.changeConfigVisibility}
          plugin={this.props.plugin}
          isPluginHeightOne={isPluginHeightOne}
          isPluginWidthOne={isPluginWidthOne}
          iconsClassNameControl={this.iconsClassNameControl}
        />
        {!exceptDataComponent.has(this.props.plugin.key) ? (
          <DataButton
            changeDataVisibility={this.props.changeDataVisibility}
            plugin={this.props.plugin}
            model={this.props.model}
            isPluginHeightOne={isPluginHeightOne}
            isPluginWidthOne={isPluginWidthOne}
            iconsClassNameControl={this.iconsClassNameControl}
          />
        ) : null}{" "}
      </div>
    );

    const pluginToolsOpenControl = () => {
      if (isShowPluginTools && !isPluginToolsOpen) {
        return (
          <div
            className={
              isPluginWidthOne
                ? "configuration-button-one-height"
                : "configuration-button"
            }
            style={{
              position: "relative",
              right: "-120%",
              animation: openPluginToolsAnimation
            }}
          >
            <div
              className={
                isPluginWidthOne && isPluginHeightOne
                  ? "configuration-button-one-height"
                  : "configuration-button"
              }
              style={{
                opacity: "0",
                animation: closePluginToolsOpacityTrue
              }}
            >
              {configurationButtons}
            </div>
          </div>
        );
      }

      if (isShowPluginTools && isPluginToolsOpen) {
        return (
          <div
            style={{
              position: "relative",
              right: "0",
              animation: closePluginToolsAnimation
            }}
          >
            {configurationButtons}
          </div>
        );
      }
    };

    return (
      <div
        className={
          !this.props.activeInteractionPluginObject.plugin
            ? "plugin-tools"
            : "plugin-tools opacity-true"
        }
        id={"plugin-tools-" + this.props.plugin.id}
        onPointerOver={this.onMouseEnterPluginTools}
        onPointerLeave={this.onMouseLeavePluginTools}
        style={{
          opacity: this.state.showPluginDeletePopup? "1": ""
        }}
      >
        <div className={classNamesByPluginSize() + " hide-on-mobile"}>
          <div
            name={"tooltip"}
            style={{
              position: "absolute",
              right: "0",
              top: "0",
              zIndex: "1"
            }}
          >
            <ShowTooltipButton
              plugin={this.props.plugin}
              pluginControl={this.pluginControl}
              showPluginTools={this.state.showPluginTools}
              isPluginHeightOne={isPluginHeightOne}
              isPluginWidthOne={isPluginWidthOne}
              iconsClassNameControl={this.iconsClassNameControl}
              visibilityOfTooltipButtons={this.props.visibilityOfTooltipButtons}
            />
          </div>
          {pluginToolsOpenControl()}
        </div>

        <div className="show-on-mobile">
          <RefreshButton
            pluginId={this.props.plugin.id}
            refresh={this.props.refresh}
            isPluginHeightOne={isPluginHeightOne}
            isPluginWidthOne={isPluginWidthOne}
            iconsClassNameControl={this.iconsClassNameControl}
            changeJoinErrorVisibility={this.props.changeJoinErrorVisibility}
            joinErrorVisibility={this.props.joinErrorVisibility}
            clickedRefresh={this.props.clickedRefresh}
            setClickedRefresh={this.props.setClickedRefresh}
            refreshedPluginId={this.props.refreshedPluginId}
            changeRefreshedPluginId={this.props.changeRefreshedPluginId}
          />
        </div>
      </div>
    );
  }
}
