import React, { Component } from "react";
import "antd/dist/antd.css";
import Cookies from "js-cookie";
import { navigate, deepCopy } from "../../Utils/Global";
import Login from "./Login";
import Register from "./Register";
import Activation from "./Activation";
import PasswordForget from "./PasswordForget";
import PasswordChange from "./PasswordChange";
import SplashScreen from "../SplashScreen/SplashScreen";
import "./userEntrance.css"
import { checkLocationForRegister } from "../../config";
/**
 * UseEntrance component is a container component which decide the appropriate component between login,register,activation
 * also basic configurations which are not dependent those component will be completed here
 */
export default class UserEntrance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibilityLogin: false,
      visibilityRegister: false,
      visibilityActivated: false,
      visibilityPasswordForget: false,
      visibilityPasswordChange: false,
      customization: { logo: "", layout: "", wallpaper: "" },
    };
  }

  /**
   * pull activationCode from url if exists show activation component,
   * fetch basic theme configuration from db and set those data to the state
   */
  componentDidMount() {
    let newState = { ...this.state };
    const params = this.props.match.params;
    const showActivated = params.activationCode ? true : false;
    const showPasswordChange = params.passwordCode ? true : false;
    const showLogin = showActivated == false && showPasswordChange == false;

    newState.visibilityActivated = showActivated;
    newState.visibilityLogin = showLogin;
    newState.visibilityPasswordChange = showPasswordChange;

    let resourceURL = `/resource/api/resource/unsecured/`;

    /** if session id is not empty, locate landing page.  */
    if (sessionStorage.getItem("sessionId")) {
      navigate(`/vispeahen/ui/`);
    }
    let customizationData = Cookies.get("loginCustomization");

    if (!customizationData) {
      customizationData = {
        layout: "left",
        wallpaper: require("../../images/demo/vispeahenBG-2.jpeg"),
        logo: require("../../images/logo.webp"),
      };
    } else {
      let tempCustomizationData = JSON.parse(Cookies.get("loginCustomization"));
      let wallpaper = tempCustomizationData.wallpaperId !== null ? resourceURL + tempCustomizationData.wallpaperId : require("../../images/demo/vispeahenBG-2.jpeg");
      let logo = tempCustomizationData.logoId !== null ? resourceURL + tempCustomizationData.logoId : require("../../images/logo.webp");
      
      customizationData = {
        layout: tempCustomizationData.layout,
        wallpaper: wallpaper,
        logo: logo
      };
    }

    newState.customization = deepCopy(customizationData);

    this.setState(newState);
  }

  /**
   * Decide which of login and register component will be shown and update the state
   * @param {*} status boolean
   */
  showComponent = (prop) => {
    if (!checkLocationForRegister && prop === "visibilityRegister") {
      prop = "visibilityLogin";
    }

    let state = {
      ...this.state,
      visibilityLogin: false,
      visibilityRegister: false,
      visibilityActivated: false,
      visibilityPasswordForget: false,
      visibilityPasswordChange: false,
    };

    state[prop] = true;

    this.setState(state);
  };

  render() {
    let backgroundDiv;

    if (this.state.visibilityActivated === false) {
      backgroundDiv = (
        <div
          className={"login-background"}
          style={{
            position: "absolute",
            zIndex: 1,
            backgroundImage: "url(" + this.state.customization.wallpaper + ")",
            width: "100%",
            left: "0",
            height: "100%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundImagePosition: "left top",
          }}
        ></div>
      );
    }

    return (
      <div style={{ height: "100%" }}>
        {backgroundDiv}
        <SplashScreen />
        <Login
          customization={this.state.customization}
          visibilityLogin={this.state.visibilityLogin}
          showComponent={this.showComponent}
        ></Login>
        <Register
          customization={this.state.customization}
          visibilityRegister={this.state.visibilityRegister}
          showComponent={this.showComponent}
        ></Register>
        <Activation
          visibilityActivated={this.state.visibilityActivated}
          activationCode={this.props.match.params.activationCode}
        ></Activation>
        <PasswordForget
          customization={this.state.customization}
          visibilityPasswordForget={this.state.visibilityPasswordForget}
          showComponent={this.showComponent}
        ></PasswordForget>
        <PasswordChange
          visibilityPasswordChange={this.state.visibilityPasswordChange}
          passwordCode={this.props.match.params.passwordCode}
          showComponent={this.showComponent}
        ></PasswordChange>
      </div>
    );
  }
}
