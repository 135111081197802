import React, { Component } from "react";
import { Table, Button, Popconfirm } from "antd";
import "antd/dist/antd.css";
import { del } from "../Utils/WebService";
import { showNotificationWithIcon } from "../Utils/Notification";
import { API_BASE } from "../config";
import i18n from "../../Utils/i18next";

const host = API_BASE;
const api = "/join";
const url = host + api;

class JoinList extends Component {
  constructor(props) {
    super(props);

    this.onDelete = this.onDelete.bind(this);
  }

  onDelete(record) {

    let urlWithParameters = url + "/" + record.id;

    del(
      urlWithParameters,
      record,
      response => {
        showNotificationWithIcon("Join successfully deleted", null, "success");
        this.props.onDelete();
      },
      error => {
        showNotificationWithIcon("Join can not deleted", null, "error");
      }
    );
  }

  columnSorter(a,b) {
    if (a.toUpperCase() > b.toUpperCase())
      return -1;
    if (a.toUpperCase() < b.toUpperCase())
      return 1;
    return 0;
  }

  render() {
    const columns = [
      {
        title: "Model Name",
        dataIndex: "modelName",
        width: "20%",
        key: "modelName",
        defaultSortOrder: 'descend',        
        sorter: (a,b) => {
          return this.columnSorter(a.modelName,b.modelName);
        },
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: "Fact Table",
        dataIndex: "factTableAlias",
        width: "20%",
        key: "factTableAlias",
        sorter: (a,b) => {
          return this.columnSorter(a.factTableAlias,b.factTableAlias);
        },
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: "Fact Column",
        dataIndex: "factColumnAlias",
        key: "factColumnAlias",
        width: "20%",
        sorter: (a,b) => {
          return this.columnSorter(a.factColumnAlias,b.factColumnAlias);          
        },
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: "Dimension Table",
        dataIndex: "dimensionTableAlias",
        width: "20%",
        key: "dimensionTable",
        sorter: (a,b) => {
          return this.columnSorter(a.dimensionTableAlias,b.dimensionTableAlias);                    
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: "Dimension Column",
        dataIndex: "dimensionColumnAlias",
        width: "20%",
        key: "dimensionColumn",
        sorter: (a,b) => {
          return this.columnSorter(a.dimensionColumnAlias,b.dimensionColumnAlias);                              
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: "Join Type",
        dataIndex: "joinType",
        width: "20%",
        key: "joinType",
        sorter: (a,b) => {
          return this.columnSorter(a.joinType,b.joinType);                                        
        },
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: "",
        key: "Action",
        render: (text, record) => (
          <Popconfirm
            title={i18n.t("Model.AreYouSureDeleteJoinDefinition")}
            onConfirm={() => {
              this.onDelete(record);
            }}
            okText={i18n.t("Yes")}
            cancelText={i18n.t("No")}
          >
            <Button type="danger" icon="delete" shape="circle" />
          </Popconfirm>
        )
      }
    ];

    return <Table columns={columns} dataSource={this.props.joins} />;
  }
}

export default JoinList;
