import React from "react";
import LogoImage from "../images/vispeahen_white_2.png";

const Logo = props => (
  <div className="logo">
    <img
      className="logo-image"
      src={LogoImage}
      alt={props.alt}
      href={props.href}
    />
  </div>
);

export default Logo;
