import React, { Component } from "react";
import Text from "../../GeneralComponents/Text/Text";
import "./imageWithTextItem.css";
import { Badge } from "react-bootstrap";

export default class ImageWithTextItem extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div
        id={this.props.tooltip ? this.props.tooltip + "InConnections" : "sourceUploadButton" }
        title={this.props.tooltip}
        className={
          this.props.className
            ? "image-with-text-item " + this.props.className
            : "image-with-text-item"
        }
        style={this.props.style}
        onClick={this.props.onClick ? this.props.onClick : null}
      >
        {this.props.badge ? (
          <Badge
            style={{
              position: "absolute",
              top: "3px",
              right: "18px",
              borderRadius: "7px",
              boxShadow: "0px 0px 2px 1px",
            }}
            variant={this.props.badge.type}
          >
            {this.props.badge.title}
          </Badge>
        ) : null}
        {this.props.extra ? (
          <div className={"extra"} style={{ float: "right" }}>{this.props.extra}</div>
        ) : null}
        {this.props.imageSrc ? (
          <img
            title={this.props.tooltip ? this.props.tooltip : ""}
            style={this.props.imageStyle}
            src={this.props.imageSrc}
          ></img>
        ) : null}
        {this.props.text ? (
          <Text
            id={this.props.text + "connection"}
            type={this.props.textType ? this.props.textType : "label"}
            style={this.props.textStyle}
            className={"text-shorten " + this.props.textClass}
          >
            {this.props.text}
          </Text>
        ) : null}
      </div>
    );
  }
}
