import React, { Component } from "react";
import $ from "jquery";
import { rmvpp } from "../../RenderJs/rmvpp";
import RadioButtonFilterConfiguration from "./ButtonFilterConfiguration";
import i18n from "../../../../Utils/i18next";
import {
  onComponentWillMount,
  onComponentWillReceiveProps,
  getColumnMapping,
  calculatePluginHeight
} from "../common";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import { renderConfig, renderData } from "../PluginsCommonComponents";
import RadioButtonFilterData from "./ButtonFilterData";
import { createTrigger } from "../../../Interaction/CreateTrigger";
import { renderContent } from "../renderContent";
import { getFormattedValue } from "../format";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join"
import "./buttonFilter.css"
import { radioButtonHTMLs, selectionBoxHTMLs, shadeColor } from "./getButtonComponent";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";

const data = JSON.parse(
  `[{"filter":"Aralık"},{"filter":"Ağustos"},{"filter":"Ekim"},{"filter":"Eylül"},{"filter":"Haziran"},{"filter":"Kasım"},{"filter":"Mart"},{"filter":"Mayıs"},{"filter":"Nisan"},{"filter":"Ocak"},{"filter":"Temmuz"},{"filter":"Şubat"}]`
);
const config = JSON.parse(
  `{"width":300,"height":300,"toggleCriteria":"","title":"","summary":"","backgroundColor":"rgb(255,255,255)","showHideButton":false,"refresh":0}`
);
const columnMap = JSON.parse(
  `{"filter":{"Code":"\'ucusAlias\'.\'ay_adiAlias\'","Name":"ay adi","DataType":"varchar","Table":"ucusAlias","Measure":"none","ID":"ucusAlias.ay adi","SubjectArea":"BIGDATA","SortKey":false,"Sorting":false,"SortDirection":"","SortOrder":0,"Locale":"TR","DataFormat":"%s","Config":{},"Verified":true,"Type":"Column","Description":"","remove":true}}`
);

const condFormats = [];
const filters = [];
const pluginName = "radio-button-filter";

const description =
  "İstatistiki verilerin görselleştirilmesi için kullanılacak olan şablon."; // Description of the plugin

// Mapping between RPD columns and the visualisation
const columnMappingParameters = [
  {
    targetProperty: "filter",
    formLabel: "FilterArea",
    type: "dim",
    required: true,
    conditionalFormat: true,
    desc: "desc51" //'Attribute with which to group the slices.'
  }, 
  {
    targetProperty: "hidden",
    formLabel: "FilterArea",
    type: "dim",
    required: false,
    conditionalFormat: false,
    desc: "desc52" //'Attribute with which to group the slices.'
  }
];

// Global configuration for the plugin - must have width and height or size at minimum.
const configurationParameters = [
  {
    targetProperty: "width", // Name of the property in config object of render function
    label: "Width", // Name the user will see
    inputType: "textbox", // Indicates the UI element for setting this parameter
    inputOptions: {
      // Sub-parameters for the UI element
      subtype: "number",
      defaultValue: 300
    },
    desc: "desc89" // Description displayed to the user
  },
  {
    targetProperty: "isMultiple", // Name of the property in config object of render function
    label: "isMultiple", // Name the user will see
    inputType: "checkbox", // Indicates the UI element for setting this parameter
    inputOptions: { defaultValue: true },
    desc: "desc89" // Description displayed to the user
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: {
      defaultValue: "rgba(0,0,0,1)"
    },
    desc: "desc62"
  },  {
    targetProperty: "textColor",
    label: "textColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: {
      defaultValue: "rgba(0, 0, 0, 1)"
    },
    desc: "desc62"
  },
  {
    targetProperty: "numFont",
    label: "NumFont",
    inputType: "font",
    inputOptions: { defaultValue: "Verdana" },
    desc: "desc147"
  },
  {
    targetProperty: "theme", // Name of the property in config object of render function
    label: "theme", // Name the user will see
    inputType: "dropdown", // Indicates the UI element for setting this parameter
    inputOptions: { defaultValue: "default" },
    desc: "desc89" // Description displayed to the user
  },
  {
    // Height and width are mandatory properties unless size is specified
    targetProperty: "height",
    label: "Height",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 300
    },
    desc: "desc90"
  },
  {
    // Height and width are mandatory properties unless size is specified
    targetProperty: "wrapText",
    label: "wrapText",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "desc90"
  },
  {
    targetProperty: "toggleCriteria",
    label: "ToggleCriteria",
    inputType: "textbox",
    inputOptions: {
      defaultValue: ""
    },
    desc: "desc59"
  },
  {
    targetProperty: "flexDirection",
    label: "flexDirection",
    inputType: "dropdown",
    inputOptions: {
      defaultValue: "bottom"
    },
    desc: "desc59"
  },
  {
    targetProperty: "title",
    label: "Title",
    inputType: "textbox",
    inputOptions: {
      defaultValue: ""
    },
    desc: "desc94"
  },
  {
    targetProperty: "summary",
    label: "Summary",
    inputType: "textbox",
    inputOptions: {
      defaultValue: ""
    },
    desc: "desc61"
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: {
      defaultValue: "rgb(255,255,255)"
    },
    desc: "desc62"
  },
  {
    targetProperty: "hideLabel",
    label: "HideLabel",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "desc142"
  },
  {
    targetProperty: "fontWeight",
    label: "fontWeight",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "desc142"
  },
  {
    targetProperty: "fontStyle",
    label: "fontStyle",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "desc142"
  },  {
    targetProperty: "textDecor",
    label: "textDecor",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "desc142"
  },
  {
    targetProperty: "showHideButton",
    label: "Show Hide Button",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc230"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  },
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 15
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
];

// Interactions the user can make on this visualisation (e.g. click, highlight)
const actions = [
  {
    trigger: "click",
    type: "click",
    name: "Tıklama",
    output: ["filter"],
    description: "ActionClickDesc"
  }
];

// Reactions this visualisation can have to other interactions. Everything can use filters
const reactions = [
  {
    id: "filter",
    name: "Filter",
    description: "desc87",
    type: "general"
  }
];

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

/**
 * renders Radio Button Filter plugin in Vispeahen V3
 */
export default class ButtonFilter extends Component {
  constructor(props) {
    super(props);
    this.rerenderProcessStarted = false;
    this.callBackObject = {};
  }

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({})

    return tmpCallBackObject;
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );
  }

  getConfigComponent = props => {
    if (props.config) {
      return (
        <RadioButtonFilterConfiguration
          config={{ ...props.config }}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig= {this.props.setCurrentAppliedConfig}
          currentAppliedConfig = {this.props.currentAppliedConfig}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }
    
    return (
      <RadioButtonFilterData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        didNotJoinedTables={checkTableJoins(this.props.join, this.props.plugin.columnMap, this.props.refreshedPluginId, this.props.plugin.id, true)}
        setInteractions={this.props.setInteractions}
        interactions={this.props.interactions}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={props.changeDoesPluginHasNotJoinedTable}
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        plugin={props.plugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  /**
   * To set column map this plugin
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      filter: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Filter.Name"),
        type: "dim",
        minimumColumnSize: 1,
        required: true,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Filter.Desc"),
        conditionalFormat: "icon",
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {
    var THIS = this;
    // Set html empty and set this html to container.
    $("#" + divId).html("");
    let container = $("#" + divId);
    let randomColorList = [    
      "#67b7dc",
      "#6771dc",
      "#a367dc",
      "#dc67ce",
      "#dc6788",
      "#dc8c67",
      "#dcaf67",
      "#dcd267",
      "#c3dc67",
      "#a0dc67",
      "#7cdc67",
      "#67dc75",
      "#67dc98",
      "#67dcbb",
      "#67dadc"
    ]

    let textWidth = !config.wrapText === true 
      ? config.flexDirection === "bottom" 
        ? $("#plugin-" + divId).width() - 70 + "px"
        : "100px"
      : ""

    container.append(`<div class="classic-checkbox checkbox-main" id="classic-checkbox-${divId}"></div>`)
    container.append(`<div class="enhanced-checkbox enhanced-checkbox-main" id="enhanced-checkbox-${divId}"></div>`)
    container.find(".classic-checkbox").css("flex-direction", config.flexDirection === "bottom" ? "column" : "row").css("width", "max-content").css("max-width", "100%")
    
    /*
    * Calculates data width
    */
    let decideDataWidth = (i = data.length) => {
      let plugin = $("#plugin-" + divId)

      if (plugin.width() / i >= 95) {
        if (plugin.height() <= $("#" + divId)[0].scrollHeight) {
          plugin.find(".custom-button").css("width", (plugin.width() / i) - 3.5)

          return (plugin.width() / i) - 3.5
        } else {
          return (plugin.width() / i)
        }
      } else if (i === 0) {
        return 95
      } else {
        return decideDataWidth(i - 1)
      }
    }

    /*
    * Calculates data height
    */
    let decideDataHeight = (i = data.length) => {
      let plugin = $("#plugin-" + divId)

      if (plugin.height() / i >= 50) {
        return (plugin.height() / i)
      } else if (i === 0) {
        return 50
      } else {
        return decideDataHeight(i - 1)
      }
    }

    if (!config.customButtonFilter) {
      if (config.isMultiple || config.isMultiple == undefined) {
        for (let i = 0; i < data.length; i++) {
          let val = getFormattedValue(columnMap.filter, data[i].filter);

          if (config.theme === "default" || config.theme == undefined) {
            let gettedHtmlObj = selectionBoxHTMLs("default", i, val, textWidth, divId, config)
  
            $("#classic-checkbox-" + divId).append(gettedHtmlObj)
          } else if (config.theme) {
            let gettedHtmlObj = selectionBoxHTMLs(config.theme, i, val, textWidth, divId, config)
  
            $("#classic-checkbox-" + divId).append(gettedHtmlObj)
          }
        }
      } else if (!config.isMultiple) {
        for (let i = 0; i < data.length; i++) {
          let val = getFormattedValue(columnMap.filter, data[i].filter);

          if (config.theme === "12" || config.theme == undefined) {
            let gettedHtmlObj = radioButtonHTMLs(config.theme, i, val, textWidth, divId, config)
  
            $("#classic-checkbox-" + divId).append(gettedHtmlObj)
          } else if (config.theme) {
            let gettedHtmlObj = radioButtonHTMLs(config.theme, i, val, textWidth, divId, config)
  
            $("#classic-checkbox-" + divId).append(gettedHtmlObj)
          }
        }
      }
    }
    
    $(document).ready(function () {
      let selectionBox = $(container).find(".input-selection-box");
      let radioButton = $(container).find(".input-radio-button")
      let customButtons = $(container).find(".per-filter-button")

      customButtons.change(function () {
        let filters = [];

        for (let i = 0; i < customButtons.length; i++) {
          if (config.isMultiple || config.isMultiple == undefined) {
            if (customButtons[i].checked) {
              $(customButtons[i]).parent().find(".per-filter-obj").addClass("per-filter-obj-active")
              $(customButtons[i]).parent().find(".per-filter-obj").css("border-bottom", "4px solid " + shadeColor(randomColorList[i % randomColorList.length], -30))
  
              filters.push({filter: data[i].filter});
            } else {
              $(customButtons[i]).parent().find(".per-filter-obj").removeClass("per-filter-obj-active")
              $(customButtons[i]).parent().find(".per-filter-obj").css("border-bottom", "6px solid " + shadeColor(randomColorList[i % randomColorList.length], -30))
            }
          } else if (!config.isMultiple) {
            if (customButtons[i] === this) {
              $(customButtons[i]).parent().find(".per-filter-obj").addClass("per-filter-obj-active")
              $(customButtons[i]).parent().find(".per-filter-obj").css("border-bottom", "4px solid " + shadeColor(randomColorList[i % randomColorList.length], -30))
  
              filters.push({filter: data[i].filter});
            } else {
              $(customButtons[i]).parent().find(".per-filter-obj").removeClass("per-filter-obj-active")
              $(customButtons[i]).parent().find(".per-filter-obj").css("border-bottom", "6px solid " + shadeColor(randomColorList[i % randomColorList.length], -30))
            }
          }
        }

        let mousePosition = { x: window.event.pageX, y: window.event.pageY }

        createTrigger(
          actions,
          columnMap,
          container,
          "click",
          filters,
          THIS.props.plugin.id,
          THIS.props.interactions,
          THIS.props.navigations,
          mousePosition
        );
      });

      selectionBox.change(function () {
        let filters = [];

        for (let i = 0; i < selectionBox.length; i++) {
          if (selectionBox[i].checked) {
            filters.push({filter: data[i].filter});
          }
        }

        let mousePosition = { x: window.event.pageX, y: window.event.pageY }

        createTrigger(
          actions,
          columnMap,
          container,
          "click",
          filters,
          THIS.props.plugin.id,
          THIS.props.interactions,
          THIS.props.navigations,
          mousePosition
        );
      });

      radioButton.change(function () {
        let filters = []

        for (let i = 0; i < radioButton.length; i++) {
          if (radioButton[i] === this) {
            if (radioButton[i].checked) {
              filters.push({filter: data[i].filter});
            }
          } else {
            radioButton[i].checked = false
          }
        }

        let mousePosition = { x: window.event.pageX, y: window.event.pageY }

        createTrigger(
          actions,
          columnMap,
          container,
          "click",
          filters,
          THIS.props.plugin.id,
          THIS.props.interactions,
          THIS.props.navigations,
          mousePosition
        );
      })
    });

    /*
    * Sorts data items
    */
    function sortItems(type, data) {
      switch (type.toLocaleUpperCase()) {
        case "GUN":
          break;
        case "GÜN":
          break;
        case "GUNLER":
          break;
        case "GÜNLER":
          sortByDay(data);
          break;
        case "AY":
          break;
        case "AYLAR":
          sortByMonth(data);
          break;
        default:
          break;
      }
    }

    function sortByDay(arr) {
      // TODO By Tayfun : Günler i18next den çekilmeli
      let mon = window.i18next.t("Days.Mon");
      let tue = window.i18next.t("Days.Tue");
      let wed = window.i18next.t("Days.Wed");
      let thu = window.i18next.t("Days.Thu");
      let fri = window.i18next.t("Days.Fri");
      var days = [mon, tue, wed, thu, fri];
      arr.sort(function (a, b) {
        if (a.filter && b.filter && isNaN(a.filter) && isNaN(b.filter)) {
          return (
            days.indexOf(a.filter.toLocaleUpperCase()) -
            days.indexOf(b.filter.toLocaleUpperCase())
          );
        }
      });
    }

    function sortByMonth(arr) {
      // TODO By Tayfun : Aylar i18next den çekilmeli
      let jan = window.i18next.t("Months.Jan");
      let feb = window.i18next.t("Months.Feb");
      let mar = window.i18next.t("Months.Mar");
      let apr = window.i18next.t("Months.Apr");
      let may = window.i18next.t("Months.Jun");
      let jun = window.i18next.t("Months.Jul");
      let jul = window.i18next.t("Months.Aug");
      let aug = window.i18next.t("Months.Sep");
      let sep = window.i18next.t("Months.Oct");
      let oct = window.i18next.t("Months.Nov");
      let nov = window.i18next.t("Months.Dec");
      let dec = window.i18next.t("Days.Mon");
      var months = [jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec];
      arr.sort(function (a, b) {
        if (a.filter && b.filter && isNaN(a.filter) && isNaN(b.filter)) {
          return (
            months.indexOf(a.filter.toLocaleUpperCase()) -
            months.indexOf(b.filter.toLocaleUpperCase())
          );
        }
      });
    }

    if (config.showHideButton) {
      let hideButtonToggleKey = [".radio-button-filter-vis"];
      rmvpp.hideButton(container, hideButtonToggleKey);
    }

    this.props.setPluginRerender(false, this.props.plugin.id, false, this.props.plugin.isInteraction);
  };

  currentHeight;
  lastContent = undefined;

  updateLastContent = (status) => {
    this.lastContent = status
  }

  render() {
    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };
    
    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + this.props.plugin.id).css("padding")
      );

      pluginConfig.height =
        calculatePluginHeight(this.props.plugin, this.props.settings) -
        pluginContainerPadding * 2;

      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      );
    }

    return (
      <>
        <div style={{height: "100%"}}>
          <div id={this.props.plugin.id}></div>
          {renderContent(
            isRerender,
            this.pluginRender,
            this.props.plugin,
            data,
            columnMap,
            pluginConfig,
            condFormats,
            this.props.setPluginRerender,
            this.lastContent,
            this.updateLastContent,
          )}
          {configComponent}
          {dataComponent}
        </div>
      </>
    );
  }
}
