import { IS_CUSTOM_DASHBOARD, IS_DISABLED_COLUMNS_UPDATED } from "./CustomDashboardAction";

/**
 *
 * @param {*} state
 * @param {*} action
 * Reducer for login popup visibility
 *
 */
export default function CustomDashboardReducer(
  state = {
    isCustomDashboard: false,
    disabledColumnsMap: new Set()
  },
  action
) {
  switch (action.type) {
    case IS_CUSTOM_DASHBOARD:
      return {
        ...state,
        isCustomDashboard: action.payload.status,
      };
    case IS_DISABLED_COLUMNS_UPDATED:
      return {
        ...state,
        disabledColumnsMap: action.payload.status,
      };
    default:
      return state;
  }
}
