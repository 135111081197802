import $ from "jquery";
import { convertRule } from "./validationOperations";
import i18n from "../../Utils/i18next";
import { ColumnNotFound } from "../../config"

/**
 * Get index from column shorthand
 * @param {*} id
 */
export function columnIdx(id) {
  var index = -1,
    re = new RegExp("(\\d*)$").exec(id);
  if (re) index = +re[1];
  return index;
}

/**
 * Get value from row based on the shorthand index
 * @param {*} row
 * @param {*} id
 */
export function getValueFromRow(row, id) {
  var re = new RegExp("(.*?)\\d"),
    col = id,
    value;
  if (re.exec(id)) {
    col = re.exec(id)[1];
    value = row[col][columnIdx(id)].value;
  } else value = row[col];

  return value;
}

/**
 * 
 * @param {*} rule 
 * @returns 
 * 
 * Returns true or false by checking if the relevant column exists and its status
 */
const checkRulesColumnStatus = (rule) => {
  if (rule.status === false && rule.errorMessage === ColumnNotFound) {
    return false;
  }

  return true;
}

/**
 * Compares a given value against the rule and returns a boolean to indicate a match.
 * Alternatively, a whole row of data can be passed and the correct column will be automatically identified.
 * @param {string|number|object} value Row of data or value to match against the conditional formatting rule.
 * @returns {boolean} Indicates whether the rule has matched or not.
 */
export function compare(value, condFormat) {
  let leftRule = convertRule(condFormat.LeftRule, condFormat.Columns, value);
  let rightRule = convertRule(condFormat.RightRule, condFormat.Columns, value);

  let comparison = { status: false, error: false };
  let status = false;
  let compareStatus = true

  if (!checkRulesColumnStatus(leftRule) || !checkRulesColumnStatus(rightRule)) {
    return comparison;
  }

  try {
    eval(leftRule.value)
    eval(rightRule.value)
  } catch {
    compareStatus = false
  }

  if (compareStatus) {
    switch (condFormat.Operator) {
      case "=":
        status = eval(leftRule.value) == eval(rightRule.value);
        break;
      case "!=":
        status = eval(leftRule.value) != eval(rightRule.value);
        break;
      case "<":
        status = eval(leftRule.value) < eval(rightRule.value);
        break;
      case "<=":
        status = eval(leftRule.value) <= eval(rightRule.value);
        break;
      case ">":
        status = eval(leftRule.value) > eval(rightRule.value);
        break;
      case ">=":
        status = eval(leftRule.value) >= eval(rightRule.value);
        break;
      default:
        let error =
          '"' + condFormat.Operator + '" ' + i18n.t("OperatorIsNotValid");
        status = false;
        comparison.error = error;
        comparison.errorTitle = i18n.t(
          "Dashboard.ConditionalFormatting.ConditionalFormatting"
        );
        break;
    }
  }

  comparison.status = status;

  return comparison;
}

/**
 * Checks if the conditional formatting rule contains this column
 */
export function conditionalFormatRuleControl(ruleObject, props) {
  return ruleObject.rule.includes(
    props.column.uniqeColumnId.replace(/-/g, "_")
  );
}

/**
 *
 * @param {*} targetColumns
 * @returns
 *
 * Checks if the conditional formatting target column contains this column
 */
export function conditionalFormatTargetColumnControl(targetColumns, props) {
  for (let column in targetColumns) {
    if (targetColumns[column].uniqeColumnId === props.column.uniqeColumnId) {
      return true;
    }
  }

  return false;
}

/**
 *
 * @returns
 *
 * It checks whether the column to be deleted is used in conditional formatting.
 * If used, the relevant conditional formatting is removed along with the column.
 */
export function columnHasConditionalFormats(props) {
  let condFormats =
    props.conditionalFormats &&
    props.conditionalFormats.filter(
      conditionalFormat =>
        !conditionalFormatRuleControl(conditionalFormat.rule.leftRule, props) &&
        !conditionalFormatRuleControl(
          conditionalFormat.rule.rightRule,
          props
        ) &&
        !conditionalFormatTargetColumnControl(
          conditionalFormat.targetColumns,
          props
        )
    );

  return condFormats;
}

/**
 * Converts html content to rule object for conditional formatting
 */
export const convertHTMLRuletoRule = (ruleObject) => {
  let html = ruleObject.ruleHTML || "";
  let rule = "";
  let ruleColumnName = "";
  let hasRuleTag = html.includes("ruletag");

  html = html.replaceAll("<div><br></div>", "");

  if (hasRuleTag) {
    let htmlSeparted = html
      .replace(/&nbsp;/g, "")
    let splitHtml = htmlSeparted.split("</ruletag>");

    for (let i = 0; i < splitHtml.length; i++) {
      let currentSplitedHtml = splitHtml[i];

      if (currentSplitedHtml !== "") {
        if (currentSplitedHtml.includes("<ruletag")) {
          let preRule = currentSplitedHtml.substring(0, currentSplitedHtml.indexOf("<"));
          
          if (preRule !== "") {
            ruleColumnName += " " + preRule + " ";
            rule += preRule;
          }

          ruleColumnName += columNameSeperator(currentSplitedHtml);
          rule += columIdSeperator(currentSplitedHtml);
        } else {
          ruleColumnName += " " + currentSplitedHtml + " ";
          rule += currentSplitedHtml;
        }
      }
    }
  } else {
    ruleColumnName = html; 
    rule = html;
  }

  return { rule: rule, ruleColumnName: ruleColumnName };
};

/**
 * To take columId from html text for rule, call this function.
 * @param {*} html
 * @returns
 */
const columIdSeperator = (html) => {
  return seperator(html, "columnid=");
};

/**
 * To take column name from html text call this function.
 * @param {*} html 
 * @returns 
 */
const columNameSeperator = (html) => {
  let hasTitleForColumn = html.includes("title-for-column-name");
  let searchAttribute = hasTitleForColumn ? "title-for-column-name=" : "title=";

  return seperator(html, searchAttribute);
};

/**
 * It is seperator for paramter(item).
 * @param {*} html 
 * @param {*} item 
 * @returns 
 */
const seperator = (html, item) => {
  let columnNameFirstIndex = html.indexOf("=", html.indexOf(item)) + 2;
  let columnNameLastIndex = html.indexOf('"', columnNameFirstIndex);
  let columnName = html.substring(columnNameFirstIndex, columnNameLastIndex);
  
  return (
    "{" +
    columnName.replace("\\", "").replace(/"/g, "").replace(/-/g, "_") +
    "}"
  );
};