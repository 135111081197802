import React, { Component } from "react";
import Text from "../../../GeneralComponents/Text/Text";
import { Row, Col, Input, Select, Checkbox, Divider, Button } from "antd";
import { UpdateConfig } from "../../UpdateConfig";
import {
  FontSizeOutlined,
  NumberOutlined,
  BgColorsOutlined,
  CrownOutlined,
  HeatMapOutlined,
  EyeInvisibleOutlined,
  FileImageOutlined,
  ColumnWidthOutlined,
  ColumnHeightOutlined,
  ReloadOutlined,
  FormatPainterOutlined
} from "@ant-design/icons";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import i18n from "../../../../Utils/i18next"

const { Option } = Select;

/**
* Configuration file of MindGapper plugin
*/
export default class MindGapperConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: this.props.config.height,
      showHideButton: this.props.config.showHideButton,
      width: this.props.config.width,
      title: this.props.config.title,
      titleAlign: this.props.config.titleAlign
    };
  }

  /**
   * Plugin compenents receive it's created properties
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      this.setState({
        showHideButton: nextProps.config.showHideButton,
        title: nextProps.config.title,
        titleAlign: nextProps.config.titleAlign,
        height: nextProps.config.height,
        width: nextProps.config.width,
      });
    }
  }

  /**
   * Changes the value of text attributes in MindGapper plugin use handleChange
   */
  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.value });
  };

  /**
   * Checks whether checkbox is checked or not in MindGapper plugin use handleCheck
   */
  handleCheck = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.checked });
  };

  /**
   * Updates state attributes in MindGapper plugin
   */
   setConfigToPlugin = (changedObj, propertyListToDelete = undefined) => {
    let tempState = { ...this.state };

    if (Array.isArray(changedObj)) {
      for (let i = 0; i < changedObj.length; i++) {
        let object = changedObj[i];
        tempState[object.type] = object.value;
      }
    } else {
      tempState[changedObj.type] = changedObj.value;
    }

    if (propertyListToDelete !== undefined) {
      for (let item of propertyListToDelete) {
        delete tempState[item]
    }
  }

    this.props.updateConfig(
      tempState,
      this.props.pluginId,
      propertyListToDelete
    );
  };

  /**
   * Changes the icon attribute in MindGapper plugin use iconOnChange
   */
  iconOnChange = value => {
    this.setState({
      ...this.state,
      icon: value
    });

    this.setConfigToPlugin({ type: "icon", value: value });
  };

  /**
   * Changes the title attribute in MindGapper plugin use titleAlignOnChange
   */
  titleAlignOnChange = value => {
    this.setState({
      ...this.state,
      titleAlign: value
    });

    this.setConfigToPlugin({ type: "titleAlign", value: value });
  };

  /**
   * Changes the color attribute in MindGapper plugin use colorOnChange
   */
  colorOnChange = obj => {
    this.setState({
      ...this.state,
      colour: obj.color
    });

    this.setConfigToPlugin({ type: "colour", value: obj.color });
  };

  /**
   * Changes the background colour attribute in MindGapper plugin use backgroundColorOnChange
   */
  backgroundColorOnChange = obj => {
    this.setState({
      ...this.state,
      backgroundColor: obj.color
    });

    this.setConfigToPlugin({ type: "backgroundColor", value: obj.color });
  };

  /**
   * Demonstrates how the choosen color will affect the interface in MindGapper plugin
   */
  backgroundColorChangeVisible = () => {
    this.setState({
      ...this.state,
      backgroundColorChange: !this.state.backgroundColorChange
    });
  };

  render() {
    return (
      <div style={{ color: "#505050" }}>       
        <Divider className={"config-divider"} orientation="left">
        {i18n.t("Dashboard.Configuration.Fields.Others")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Title")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"title"}
              defaultValue={this.state.title}
              value={this.state.title}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.TitleAlign")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              showSearch
              style={{ width: "100%" }}
              getPopupContainer={(trigger) => trigger.parentNode}
              placeholder={i18n.t("Dashboard.Configuration.Fields.SelectTitleAlign")}
              optionFilterProp="children"
              onChange={this.titleAlignOnChange}
              defaultValue={this.state.titleAlign}
            >
              <Option value="center">{i18n.t("Dashboard.Configuration.Fields.Center")}</Option>
              <Option value="left">{i18n.t("Dashboard.Configuration.Fields.Left")}</Option>
              <Option value="right">{i18n.t("Dashboard.Configuration.Fields.Right")}</Option>
            </Select>
          </Col>
        </Row>
        
      </div>
    );
  }
}
