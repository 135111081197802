import React, { component } from "react";
import PopupContainer from "../../GeneralComponents/PopupContainer/PopupContainer";
import Text from "../../GeneralComponents/Text/Text";
import Button from "../../GeneralComponents/Button/Button";
import i18n from "../../../Utils/i18next";
import {
  DatabaseOutlined,
  RightOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { TbPin, TbPinnedOff } from 'react-icons/tb';
import { isValidWriteRoles } from "../../DashboardPage/RoleStore";

export const renderConfig = (popupPosition, props, getConfigComponent, hasIPin = true ) => {
  let header = {
    title: i18n.t("Dashboard.Configuration.Configuration"),
    description: i18n.t("Dashboard.Configuration.ConfigurationDescription", {
      pluginKey: i18n.t("Plugins." + props.plugin.key + ".Name"),
      interpolation: { escapeValue: false }
    })
  };

  return componentPopup(props, getConfigComponent, header, popupPosition, false, hasIPin);
};

export const renderData = (popupPosition, props, getDataComponent, sortControlErr, hasIPin = true) => {
  let header = {
    title: i18n.t("Dashboard.Data.Data"),
    icon: (
      <DatabaseOutlined
        style={{ position: "relative", top: "-5px", fontSize: "18px" }}
      />
    ),
    description: i18n.t("Dashboard.Data.DataDescription"),
  };

  return componentPopup(props, getDataComponent, header, popupPosition, sortControlErr, hasIPin, true );
};

export const renderConditionalFormatting = (popupPosition, props, getConditionalFormattingComponent) => {
  let header = {
    title: i18n.t("Dashboard.ConditionalFormatting.ConditionalFormatting"),
    description: i18n.t("Dashboard.ConditionalFormatting.ConditionalFormattingDescription", {
      pluginKey: i18n.t("Plugins." + props.plugin.key + ".Name"),
      interpolation: { escapeValue: false }
    })
  };

  return componentPopup(props, getConditionalFormattingComponent, header, popupPosition);
};

export const renderNavigation = (popupPosition, props, getNavigations) => {
  let header = {
    title: i18n.t("NavigationTitle"),
    description: i18n.t("Navigation.Descripton", {
      pluginKey: i18n.t("Plugins." + props.plugin.key + ".Name"),
      interpolation: { escapeValue: false }
    })
  };

  return componentPopup(props, getNavigations, header, popupPosition);
};

export const componentPopup = (
  props,
  content,
  header,
  popupPosition,
  sortControlErr,
  hasIPin,
  isData
) => {
  return (
    <PopupContainer
      width={!isValidWriteRoles() && isData ? "350px" : "700px"}
      constantHeight={"600px"}
      top={popupPosition.top + "px"}
      position={"left"}
      zIndex={"1000!important"}
      positionCalibration={popupPosition.left + "px"}
      background={"rgb(251, 251, 251)"}
      popupRef={props.popupsRef}
      className={"data-popup"}
    >
      <div
        className={"containerIcon"}
        style={{
          top: popupPosition.containerIconPositions["top"] + "px",
          left: popupPosition.containerIconPositions["left"] + "px",
          display: popupPosition.containerIconPositions["display"]
        }}
      >
        <RightOutlined />
      </div>

      {header && header.title ? (
        <Text style={{ textAlign: "center" }} type="h4">
          {header.icon ? header.icon : null} {header.title}
        </Text>
      ) : null}
      {header.description ? (
        <Text
          type="h6"
          style={{
            color: "rgb(162, 162, 162)",
            marginBottom: isValidWriteRoles() ? "27px" : "13px",
            textAlign: "center",
            fontSize: isValidWriteRoles() ? "1rem" : "13px"
          }}
        >
          {sortControlErr === true ? (
            <div>
              <div>{header.description} </div>
              <div style={{ marginTop: "7px" }}>
                <WarningOutlined style={{ color: "yellow", position: "relative", top: "-4px" }} />
                  {i18n.t("Errors.SortingControl")}
              </div>
            </div>
          ) : (
            header.description
          )}
        </Text>
      ) : null}
      {content(props)}
      <Button
        id={"closeTooltip"}
        style={{
          position: "absolute",
          top: "3px",
          right: "15px",
          borderRadius: "40px",
          padding: "6px 11px",
          backgroundColor: "white",
          zIndex: 2
        }}
        type={"default"}
        onClick={() => props.changeConfigVisibility(false)}
      >
        X
      </Button>
      {hasIPin ? (
        <Button
          id="pinTop"
          className="pin-button-configuration-or-data"
          type={"default"}
          onClick={() => props.pinStatusChange()}
          tooltip={i18n.t(props.isPinned ? "Model.UnPin" : "Model.Pin")}
        >
          {props.isPinned ? (
            <TbPin id="configurationAndDataPin" size={22} />
          ) : (
            <TbPinnedOff size={22} />
          )}
        </Button>
      ) : null}
    </PopupContainer>
  );
};
