/*
* Controls has node an image or not.
*/
export function filterNode(node) {
    if (node instanceof Text) {
        return true;
    }

    if (node.tagName) {
        return ![
            "img"
        ].includes(node.tagName.toLowerCase()) || /^h[123456]$/i.test(node.tagName);
    } else {
        return true
    }
}