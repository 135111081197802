import React, { Component } from "react";
import { Spin } from "antd";

import "./SplashScreen.css";

export default class SplashScreen extends Component {
  render() {
    return (
      <div id="splashScreenContainer" style={{ display: "none" }}>
        <div class="logo-animate">
          <div class="bar">
            <div class="triangle-up"></div>
            <div class="triangle-down"></div>
          </div>
          <div class="bar">
            <div class="triangle-up"></div>
            <div class="triangle-down"></div>
          </div>
          <div class="bar">
            <div class="triangle-up"></div>
            <div class="triangle-down"></div>
          </div>
          <div class="bar">
            <div class="parallelogram">
              <div></div>
            </div>
          </div>
          <div class="text">
            <span>Loading...</span>
          </div>
        </div>
      </div>
    );
  }
}
