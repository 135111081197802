import React, { Component } from "react";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import PropTypes from "prop-types";
import i18n from "../../../Utils/i18next";
import Button from "../../GeneralComponents/Button/Button";
import "./themePalette.css";
import { CloseOutlined } from "@ant-design/icons";

/** This is a component for Theme Palette's one colour. */
export default class ThemePerColour extends Component {
  constructor(props) {
    super(props);

    this.prototype = {
      index: PropTypes.number,
      currentIndex: PropTypes.number,
      paletteColourChange: PropTypes.func,
      changeCurrentIndex: PropTypes.func,
    };

    this.state = {
      newColorChange: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    /** When one color picker open and we try to open another color picker, this function close first color picker */
    if (
      nextProps.currentIndex !== this.props.currentIndex &&
      nextProps.currentIndex !== nextProps.index
    ) {
      this.perColorChangeVisible(false);
    }
  }

  /** Changing one color function. this goes to ThemePalette.paletteColourChange */
  perColorChange = (obj) => {
    this.props.paletteColourChange(obj.color, this.props.index);
  };

  /** changing current colours colour picker */
  perColorChangeVisible = (status = undefined) => {
    let newState = { ...this.state };
    if (status === false) {
      newState.newColorChange = false;
    } else {
      newState.newColorChange = !newState.newColorChange;
      this.props.changeCurrentIndex(this.props.index);
    }

    this.setState(newState);
  };

  render() {
    const isNewColourChange = this.state.newColorChange;
    if (isNewColourChange === true) {
      return (
        <>
          <div
            onClick={this.perColorChangeVisible}
            style={{
              width: 100 / this.props.coloursCount + "%",
              background: this.props.colour,
            }}
            className="colour"
          ></div>
          <div className={"palette-colour-picker"}>
          <div className={"close-colour-palette"}>
            <CloseOutlined onClick={this.perColorChangeVisible}></CloseOutlined>
          </div>
            <ColorPickerPanel
              alpha={100}
              color={this.props.colour}
              onChange={this.perColorChange}
              id={"perColor"}
              style={{marginTop: "10px"}}
            />
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <Button
                id={"removeColor"}
                className={"remove-colour-button"}
                onClick={() => this.props.removeColour(this.props.index)}
                style={{
                  width: "100%",
                  borderBottom: "0",
                  height: "fit-content",
                  padding: "4px 0",
                  marginBottom: "0",
                  top: "1px",
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.RemoveColour")}
              </Button>
            </div>
          </div>
        </>
      );
    }
    
    return (
      <div
        onClick={this.perColorChangeVisible}
        style={{
          width: 100 / this.props.coloursCount + "%",
          background: this.props.colour,
        }}
        name={this.props.colour}
        className="colour"
      ></div>
    );
  }
}
