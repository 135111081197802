import React, { Component } from "react";
import {
    Button,
  } from "antd";
import i18n from "../../Utils/i18next";

/**
 * Component that generates antd button and make decision its visibility by canISubmit
 */
export default class SubmitButton extends Component {
    render() {
        let submitButton;

        if (this.props.canISubmit) {
          submitButton = (
            <Button
              id={this.props.id}
              htmlType="submit"
              className="login-form-button circled-no-border login-button"
            >
              {this.props.text}
            </Button>
          );
        } else {
          submitButton = (
            <Button
              id={this.props.id}
              disabled
              htmlType="submit"
              className="login-form-button circled-no-border login-button"
            >
              {this.props.text}
            </Button>
          );
        }

        return (
            submitButton
        )
    }
}