import React, { Component } from "react";
import { Row, Col, Checkbox, Button } from "antd";
import {
  FontSizeOutlined,
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined
} from "@ant-design/icons";
import Text from "../GeneralComponents/Text/Text";
import i18n from "../../Utils/i18next";

// The component changes title features of plugins
export default class ChangeFontStylePlugin extends Component {
  
  //changes plugin titles fontweight to bold or normal
  fontWeightOnChange = () => {
    this.props.handleChange({
      target: {
        id: "titleFontWeight", 
        value: !this.props.titleFontWeight
      }
    })
  }

  //changes plugin title fontstyle to italic or normal 
  fontStyleOnChange = () => {
    this.props.handleChange({
      target: {
        id: "titleFontStyle", 
        value: !this.props.titleFontStyle
      }
    })
  }

  //changes plugin titles textdecor to underline or none
  textDecorOnChange = () => {
    this.props.handleChange({
      target: {
        id: "titleTextDecor", 
        value: !this.props.titleTextDecor
      } 
    })
  }

  render () {
    return (
      <Row style={{ marginBottom: "5px" }}>
        <Col
          style={{ textAlign: "center" }}
          span={this.props.titleIcon ? this.props.titleIcon : 3}
        >
          <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
        </Col>
        <Col
          style={{ textAlign: "left" }}
          span={this.props.titleColumn ? this.props.titleColumn : 9}
        >
          <Text
            style={{
              fontSize: "16px",
              display: "inline-block",
              marginTop: "2px"
            }}
          >
            {i18n.t("Dashboard.ConditionalFormatting.TitleFontStyle")}
          </Text>
        </Col>
        <Col style={{textAlign:"left"}} span={12}>
          {this.props.titleFontWeight !== undefined ? (
            <span>
              <Button
                onClick={this.fontWeightOnChange}
                style={{
                  backgroundColor: this.props.titleFontWeight ? "#9ed3ff" : "initial",
                  color: "black",
                  borderColor: "lightgrey",
                }}
              >
                <BoldOutlined
                  style={{ display: "flex", justifyContent:"center", verticalAlign: "middle" }}
                />
              </Button>
            </span>
          ) : null}

          {this.props.titleFontStyle !== undefined ? (
            <span>
              <Button
                onClick={this.fontStyleOnChange}
                style={{
                  marginLeft:"20px",
                  backgroundColor: this.props.titleFontStyle ? "#9ed3ff" : "initial",
                  color: "black",
                  borderColor: "lightgrey",
                }}
              >
                <ItalicOutlined
                  style={{ display: "flex", justifyContent:"center", verticalAlign: "middle" }}
                />
              </Button>
            </span>
          ) : null}

          {this.props.titleTextDecor!== undefined ? (
            <span>
              <Button
                onClick={this.textDecorOnChange}
                style={{
                  marginLeft:"20px",
                  backgroundColor: this.props.titleTextDecor ? "#9ed3ff" : "initial",
                  color: "black",
                  borderColor: "lightgrey",
                }}
              >
                <UnderlineOutlined
                  style={{ display: "flex", justifyContent:"center", verticalAlign: "middle" }}
                />
              </Button>
            </span>
          ) : null}
        </Col>
      </Row>
    );
  }
}
