/**
 * 
 * @param {*} columnMap 
 * @param {*} output 
 * @param {*} columnItem 
 * @param {*} realIndex 
 * @returns
 * 
 * This method returns the index of the column in the columnMap 
 */

const getIndexOfColumn = (columnMap, output, columnItem, realIndex) => {
  let index = realIndex

  if (Array.isArray(columnMap[output])) {
    for (let i = 0; i < columnMap[output].length; i++) {
      if (columnItem.uniqeColumnId === columnMap[output][i].uniqeColumnId) {
        index = i;

        break;
      }
    }
  }

  return index
}

/**
 * 
 * @param {*} pluginFiltersInformation 
 * @param {*} nextProps 
 * @param {*} plugin 
 * @returns 
 * 
 * This method arranges the objects required to trigger private methods in plugins.
 */
 export const triggerPrivateMethod = (pluginFiltersInformation, nextProps, plugin) => {
  let outputList = pluginFiltersInformation.usedInteraction.actions.filter(
    (action) => action.trigger == nextProps.triggeringPluginInformation.event
  )[0].output;
  let privateMethodData = {};
  let values;

  let column = pluginFiltersInformation.usedInteraction.columns;

  /** actions output list iterate for apply all filters. */
  outputList.map((output) => {
    let filterValue;

    if (isValidInteractionValue(pluginFiltersInformation, output)) {
      /** Set filter value object or array of value by length */
      if (pluginFiltersInformation.value.length == 1) {
        filterValue = pluginFiltersInformation.value[0][output];
      } else {
        filterValue = pluginFiltersInformation.value;
      }

      Array.isArray(filterValue)
        ? (values = filterValue)
        : (values = [filterValue]);
      privateMethodData.values = values;
      privateMethodData.config = plugin.config;
      privateMethodData.columnMap = getColumnMapWithData(
        plugin.columnMapForPlugin
      );

      if (filterValue !== undefined && filterValue !== null) {
        if (Array.isArray(column)) {
          column.map((columnItem, columnIndex) => {
            let index = getIndexOfColumn(pluginFiltersInformation.columnMap, output, columnItem, columnIndex)
            
            //if locationField didnt set find and set
            if (columnItem.locationFieldName === undefined) {
              locationFieldFindAndSet(columnItem, pluginFiltersInformation.columnMap);
            }

            if (columnItem.locationFieldName === output) {
              let columnMap = pluginFiltersInformation.columnMap[output][index];
              columnItem.col =
                columnMap !== undefined
                  ? columnMap
                  : pluginFiltersInformation.columnMap[output];

              setColumn(columnItem, privateMethodData, plugin);
              getTargetIdToColumn(plugin, columnItem);
              formattedValue(columnItem, values, output, index, pluginFiltersInformation.columnMap[output]);
            } else if (columnItem.values === undefined) {
              columnItem.values = [];
            }
          });
        } else {
          if (column.locationFieldName === output) {
            setColumn(column, privateMethodData, plugin);
            getTargetIdToColumn(plugin, column);

            column.col = pluginFiltersInformation.columnMap[output];
            column.values = column.col.remove === true ? [] : values;
            column = [column];
          }
        }
      }
    } else {
      let validColumns = [];

      column.map((item) => {
        if (item.locationFieldName !== output) {
          validColumns.push(item);
        }
      });

      column = validColumns;
    }
  });

  return column;
};

/**
 * For old dashboards.
 * Adds location field in column.
 */
function locationFieldFindAndSet(columnItem, columnMap) {
  Object.keys(columnMap).map(key => {
    if (Array.isArray(columnMap[key])) {
      columnMap[key].map(c => {
        if (c.uniqeColumnId === columnItem.uniqeColumnId) {
          columnItem.locationFieldName = key;
        }
      })
    } else {
      if (columnMap[key].uniqeColumnId === columnItem.uniqeColumnId) {
        columnItem.locationFieldName = key;
      }
    }
  })
} 

/**
 *
 * @param {*} columnItem
 * @param {*} values
 * @param {*} output
 * @param {*} index
 * @param {*} columnMap
 *
 * Converts the value to the appropriate format according to the state of remove and the state of being an array.
 */
 function formattedValue(columnItem, values, output, index, columnMap) {
  if (columnItem.col.remove === true) {
    columnItem.values = [];
  } else {
    let isValueValid = values.length === 1 && values[0].name  && columnItem.displayName !== values[0].name
    let valueNotUndefined = values[index] && values[index].value !== undefined

    if (valueNotUndefined && columnItem.displayName === values[index].name) {
      columnItem.values = [values[index].value];
    } else if (valueNotUndefined && columnItem.displayName !== values[index].name) {
      columnItem.values = findColumnValue(values, columnItem, columnMap);
    } else if (values.length === 1 && columnItem.displayName === values[0].name) {
      columnItem.values = [values[0].value];
    } else if (isValueValid === true) {
      columnItem.values = [];
    } else {
      columnItem.values = setValueInStringList(values, output);
    }
  }
}

/**
 * 
 * @param {*} values 
 * @param {*} output 
 * @returns 
 * 
 * Converts value object to string array
 */
 function setValueInStringList(values, output) {
  let valueList = [];

  values.map((value) => {
    if (typeof value === "object") {
      if (value[output]) {
        valueList.push(value[output]);
      }
    } else {
      valueList.push(value);
    }
  });

  return valueList;
}

const setColumn = (column, privateMethodData, plugin) => {
  column.config = plugin.config;
  column.columnMap = privateMethodData.columnMap;
};

const getTargetIdToColumn = (plugin, column) => {
  for (let item in plugin.columnMap) {
    plugin.columnMap[item].data.map((columnItem) => {
      if (column.aliasName === columnItem.aliasName) {
        column.targetId = item;
      }
    });
  }
};

function isValidInteractionValue(pluginFiltersInformation, output) {
  return (
    pluginFiltersInformation.value.length > 0 &&
    pluginFiltersInformation.value[0] !== undefined &&
    pluginFiltersInformation.value[0][output] !== undefined
  );
}

/**
 *
 * @param {*} columnMap
 * @returns
 * Arranges the Column Map to the desired format.
 */
const getColumnMapWithData = (columnMap) => {
  let columnMapWithData = {};

  for (let item in columnMap) {
    if (Array.isArray(columnMap[item])) {
      columnMapWithData[item] = columnMap[item];
    } else {
      columnMapWithData[item] = [columnMap[item]];
    }
  }

  return columnMapWithData;
};

/**
 *
 * @param {*} pluginFiltersInformation
 * @returns
 * Finds whether the incoming interaction will trigger the private method in the relevant plugin.
 */
export const isPrivateMethod = (pluginFiltersInformation, plugin) => {
  let isPrivateMethod = false;
  let privateMethodName = "";
  let reactionsLength =
    pluginFiltersInformation.usedInteraction.reactions.length;

  for (let i = 0; i < reactionsLength; i++) {
    let reaction = pluginFiltersInformation.usedInteraction.reactions[i];

    if (reaction.type === "private") {
      //If method couldn't found, find method in reaction list.
      if (reaction.method === undefined) {
        for (let j = 0; j < plugin.reactions.length; j++) {
          let pluginReaction = plugin.reactions[j];

          if (reaction.id === pluginReaction.id) {
            reaction.method = pluginReaction.method;
            break;
          }
        }
      }

      // If still method not found, use reaction id for method name.
      if (reaction.method === undefined) {
        reaction.method = reaction.id;
      }

      isPrivateMethod = true;
      privateMethodName = reaction.method;
    }
  }

  return {
    isPrivateMethod: isPrivateMethod,
    privateMethodName: privateMethodName,
  };
};

/**
 *
 * @param {*} pluginFiltersInformation
 * @returns
 * Finds whether the incoming interaction will trigger the private method for title in the relevant plugin.
 */
export const isPrivateMethodForTitle = (pluginFiltersInformation, plugin) => {
  let isPrivateMethodTitle = false;
  let privateMethodTitleName = "";
  let titleReactionsLength = pluginFiltersInformation.usedInteraction.titleReactions && pluginFiltersInformation.usedInteraction.titleReactions[0] ? pluginFiltersInformation.usedInteraction.titleReactions.length : 0;
  
  for (let i = 0; i < titleReactionsLength; i++) {
    let titleReaction = pluginFiltersInformation.usedInteraction.titleReactions[i];

    if (titleReaction.type === "private") {
      //If method couldn't found, find method in titleReaction list.
      if (titleReaction.method === undefined) {
        for (let j = 0; j < plugin.titleReactions.length; j++) {
          let plugintitleReaction = plugin.titleReactions[j];

          if (titleReaction.id === plugintitleReaction.id) {
            titleReaction.method = plugintitleReaction.method;
            break;
          }
        }
      }

      // If still method not found, use titleReaction id for method name.
      if (titleReaction.method === undefined) {
        titleReaction.method = titleReaction.id;
      }

      isPrivateMethodTitle = true;
      privateMethodTitleName = titleReaction.method;
    }
  }


  return {
    isPrivateMethodTitle: isPrivateMethodTitle,
    privateMethodTitleName: privateMethodTitleName,
  };
};

/**
 * 
 * @param {*} values 
 * @param {*} columnItem 
 * @param {*} columnMap
 * @returns 
 * 
 * It finds the value of the column in the value object array and returns it in the desired format.
 */
const findColumnValue = (values, columnItem, columnMap) => {
  for (let i = 0; i < columnMap.length; i++) {
    if (columnItem.uniqeColumnId === columnMap[i].uniqeColumnId) {
      return [values[i].value];
    }
  }

  return []
}