import { Input, Modal } from "antd";
import React, { Component } from "react";
import { deepCopy } from "../../Utils/Global";
import i18n from "../../Utils/i18next";
import Text from "../GeneralComponents/Text/Text";

/**
 * Table alias update component
 */
export default class UpdateTableAliasName extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableNewAlias: "",
    };
  }

  /**
   * Set new alias
   * @param {*} e
   */
  handleConfirm = (e) => {
    let nextProps = deepCopy(this.props.newProps);

    if (nextProps && nextProps.newTableInModel) {
      nextProps.newTableInModel.table.aliasName = this.state.tableNewAlias;

      this.props.tableAddOrRemoveInModel(nextProps);

    }

    this.setState({ tableNewAlias: "" });
  };

  /**
   * Cancel changes
   * @param {*} e
   */
  handleCancel = (e) => {
    this.setState({
      tableNewAlias: "",
    });

    this.props.updateAliasNameNotUnique({ status: false, tableAlias: "" });
  };

  /**
   * Set new alias for state
   * @param {*} e
   */
  handleChange = (e) => {
    this.setState({
      tableNewAlias: e.target.value,
    });
  };

  render() {
    let status = false;
    let tableAlias = "";

    if (this.props.aliasNameNotUnique) {
      status = this.props.aliasNameNotUnique.status;
      tableAlias = this.props.aliasNameNotUnique.tableAlias;
    }

    return (
      <Modal
        title={tableAlias + "" + i18n.t("Model.TableNameIsNotUniqueTitle")}
        visible={status}
        onCancel={(e) => this.handleCancel(e)}
        onOk={(e) => this.handleConfirm(e)}
      >
        <Text>{i18n.t("Model.TableNameIsNotUniqueDescription")}</Text>

        <Input
          onChange={(e) => this.handleChange(e)}
          value={this.state.tableNewAlias}
        />
      </Modal>
    );
  }
}
