import React, { Component } from "react";
import FooterVispeahenLogo from "../GeneralComponents/FooterVispeahenLogo";
import { put } from "../../Utils/WebService";
import UserActivationLoading from "./UserActivationLoading";
import UserActivated from "./UserActivated";
import UserAlreadyActivated from "./UserAlreadyActivated";
import { showError } from "../../Utils/Notification";
import { naviagateToLogin } from "../../Utils/Auth"

/**
 * Activation component is a child component of UserEntrance, if there is an activation code in the link when
 * user entrance is active Activation component will take action. Main task of this component is activating user account
 */
export default class Activation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userActivated: false,
      userAlreadyActive: false,
      loading: false,
    };
  }

  /**
   * if there is an activation code in props pass it to the api if code is valid
   * activation process will be completed and success content will be shown, if not fail content will take place
   */
  componentDidMount() {
    if (this.props.activationCode) {
      let activationCode = this.props.activationCode;

      this.showLoading();

      let url = `/api/user/activation/` + activationCode;

      const successFunc = (result) => {
        this.showUserActivated();
        naviagateToLogin(3000);
      };

      const errorFunc = (error) => {
        if (
          error === "Your account already activated !"
        ) {
          this.showAlreadyActivated();
          naviagateToLogin(3000);
          return;
        }

        showError(error);

        this.closeAll();
      };

      let headers = {
        Authorization: "Basic " + btoa("mobile:pin"),
      };

      let userObj = { activationCode };
      put(
        url,
        userObj,
        successFunc,
        errorFunc,
        false,
        headers,
        false,
        false
      );
    }
  }

  render() {
    let willBeRendered;

    if (this.state.loading === true) willBeRendered = <UserActivationLoading />;
    if (this.state.userActivated === true) willBeRendered = <UserActivated />;
    if (this.state.userAlreadyActive === true)
      willBeRendered = <UserAlreadyActivated />;

    return (
      <div>
        {willBeRendered}
        <FooterVispeahenLogo position={"absolute"} />
      </div>
    );
  }

  showLoading = () => {
    this.setState({
      ...this.state,
      userAlreadyActive: false,
      loading: true,
      userActivated: false,
    });
  };

  showAlreadyActivated = () => {
    this.setState({
      ...this.state,
      userAlreadyActive: true,
      loading: false,
      userActivated: false,
    });
  };

  showUserActivated = () => {
    this.setState({
      ...this.state,
      userAlreadyActive: false,
      loading: false,
      userActivated: true,
    });
  };

  closeAll = () => {
    this.setState({
      ...this.state,
      userAlreadyActive: false,
      loading: false,
      userActivated: false,
    });
  };
}
