import React, { Component } from "react";
import {
  ReloadOutlined,
  DownOutlined,
  ClockCircleOutlined
} from "@ant-design/icons";
import AutoRefresh from "./AutoRefresh.jsx/AutoRefresh";
import Button from "../../../GeneralComponents/Button/Button";
import "./dashboardRefreshButton.css";
import i18n from "../../../../Utils/i18next";
import $ from "jquery"
import { removeAllSelectedValuesFromStringFilter } from "../../../Plugins/PluginComponents/Filter/StringFilterRemoveInputs";
import { getIcon } from "../../../../Utils/Global";

/**
 * Dashboard refresh button with auto refresh settings
 */
export default class DashboardRefreshButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      autoRefreshVisibility: false
    };

    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  //If click outside hide switcher area.
  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      if (!$(event.target).closest("#loginPopup").length > 0) {
        this.changeAutoRefreshVisibility(false);
      }
    }
  };

  /** Open/close auto refresh popup */
  changeAutoRefreshVisibility = status => {
    this.setState({
      autoRefreshVisibility: status
    });
  };

  /*
  * Controls is dashboard has navigation. If dashboard has navigation, don't removes the interaction filters when refreshed
  */
  refreshAllPlugins = () => {
    removeAllSelectedValuesFromStringFilter()

    if (this.props.navigations.length > 1) {
      this.props.refresh(null, true, false)
    } else {
      this.props.refresh(null, true, true)
    }
  }

  render() {
    let autoRefreshStatus =
      this.props.autoRefresh &&
        this.props.autoRefresh.enable &&
        this.props.autoRefresh.second > 0
        ? true
        : false;
    let tooltip = autoRefreshStatus
      ? i18n.t("Dashboard.AutoRefresh.AutoRefreshIsActive")
      : this.props.tooltip;

    let refreshTheme = this.props.theme?.buttons?.refreshButton;
    let autoRefreshTheme = this.props.theme?.buttons?.autoRefreshButton;
    let isDoubleButton = refreshTheme?.visibility !== false && autoRefreshTheme?.visibility !== false;

    return (
      <div
        className={isDoubleButton && "double-button"}
        ref={this.wrapperRef}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          marginRight: !isDoubleButton && 5
        }}
      >
        {
          refreshTheme?.visibility !== false && (
            <Button
              onClick={this.refreshAllPlugins}
              className={`dashboard-button btn btn-new ${!isDoubleButton ? "circle-button" : ""}`}
              style={{
                overflow: "hidden",
                borderRightWidth: isDoubleButton ? 0.5 : 1
              }}
              tooltip={tooltip}
              id={this.props.id}
            >
              {
                autoRefreshStatus ? (
                  <div className={"timer-area"}>
                    <div
                      className={"timer"}
                      style={{
                        animationDuration: this.props.autoRefresh.second + "s"
                      }}
                    >
                      <style>
                        {`
                          .timer-area .timer {
                            background-color: ${refreshTheme?.textColor};
                          }

                          .timer-area .timer:hover {
                            background-color: ${refreshTheme?.textColorActive};
                          }

                          
                          .timer-area .timer:hover,
                          .timer-area:hover .timer,
                          #refreshButton:hover .timer,
                          #refreshButton:focus .timer,
                          #refreshButton:active .timer {
                              background: ${refreshTheme?.textColorActive};
                          }
                        `}
                      </style>
                    </div>
                  </div>
                ) : null
              }

              {
                getIcon(
                  refreshTheme?.icon,
                  <ReloadOutlined style={{ fontSize: 25 }} />,
                  { fontSize: 25 }
                )
              }

              <style>
                {`
                  #${this.props.id} {
                    background-color: ${refreshTheme?.backgroundColor};
                    color: ${refreshTheme?.textColor};
                    border-color: ${refreshTheme?.textColor}33;
                  }
    
                  #${this.props.id}:hover,
                  #${this.props.id}:focus,
                  #${this.props.id}:active {
                    background-color: ${refreshTheme?.backgroundColorActive};
                    color: ${refreshTheme?.textColorActive};
                    border-color: ${refreshTheme?.textColorActive}33;
                  }
                `}
              </style>
            </Button>
          )
        }

        {
          autoRefreshTheme?.visibility !== false && (
            <Button
              onClick={() =>
                this.changeAutoRefreshVisibility(!this.state.autoRefreshVisibility)
              }
              className={`dashboard-button btn btn-new ${!isDoubleButton ? "circle-button" : ""}`}
              tooltip={i18n.t("Dashboard.AutoRefresh.AutoRefresh")}
              id={"autoRefresh"}
              style={{
                overflow: "hidden",
                borderLeftWidth: isDoubleButton ? 0.5 : 1
              }}
            >
              {
                !isDoubleButton && autoRefreshStatus && (
                  <div className={"timer-area"}>
                    <div
                      className={"timer"}
                      style={{
                        animationDuration: this.props.autoRefresh.second + "s"
                      }}
                    >
                      <style>
                        {`
                          .timer-area .timer {
                            background-color: ${autoRefreshTheme?.textColor};
                          }

                          .timer-area .timer:hover {
                            background-color: ${autoRefreshTheme?.textColorActive};
                          }

                          .timer-area .timer:hover,
                          .timer-area:hover .timer,
                          #autoRefresh:hover .timer,
                          #autoRefresh:focus .timer,
                          #autoRefresh:active .timer {
                              background: ${autoRefreshTheme?.textColorActive};
                          }
                        `}
                      </style>
                    </div>
                  </div>
                )
              }

              {
                getIcon(
                  autoRefreshTheme?.icon,
                  <DownOutlined style={{ fontSize: !isDoubleButton && 25}}/>,
                  { fontSize: !isDoubleButton && 25 }
                )
              }

              <style>
                {`
                  #autoRefresh {
                    background-color: ${autoRefreshTheme?.backgroundColor};
                    color: ${autoRefreshTheme?.textColor};
                    border-color: ${autoRefreshTheme?.textColor}33;
                  }

                  #autoRefresh:hover,
                  #autoRefresh:focus,
                  #autoRefresh:active {
                    background-color: ${autoRefreshTheme?.backgroundColorActive};
                    color: ${autoRefreshTheme?.textColorActive};
                    border-color: ${autoRefreshTheme?.textColorActive}33;
                  }
                `}
              </style>
            </Button>
          )
        }

        <AutoRefresh
          plugins={this.props.plugins}
          visibility={this.state.autoRefreshVisibility}
          changeAutoRefreshVisibility={this.changeAutoRefreshVisibility}
          autoRefresh={this.props.autoRefresh}
          setAutoRefreshToDashboard={this.props.setAutoRefreshToDashboard}
          refreshAll={() => this.props.refresh(null, true, false, true)}
          dashboard={this.props.dashboard}
        />
      </div>
    );
  }
}
