import React, { Component } from "react";
import { put } from "../Utils/WebService";
import { API_BASE } from "../config";
import { showNotificationWithIcon } from "../Utils/Notification";
import {
  Tooltip,
  Button,
  Icon,
  Input,
  Form,
  Modal,
  Alert,
} from "antd";

const FormItem = Form.Item;
const { TextArea } = Input;

class EditRolesContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      visibleEdit: false,
      addStatus: "",
      roleName: "",
      roleDescription: "",
      role: []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.visible) {
      this.showEditModal(nextProps.role);
    }
  }

  showEditModal = role => {
    this.setState({
      role: role,
      roleID: role.id,
      roleName: role.name,
      roleDescription: role.description
    });
  };

  editRole = () => {
    let url = `${API_BASE}/role`;
    let roleDescription = this.state.roleDescription;
    
    if (this.state.roleDescription === "") {
      this.setState({
        roleDescription: this.state.roleName
      });
      roleDescription = this.state.roleName;
    }

    let requestBody = this.state.role;
    requestBody.name = this.state.roleName;
    requestBody.description = roleDescription;

    const successFunc = result => {
      this.setState({
        visibleEdit: false,
        roleName: "",
        roleDescription: "",
        role: []
      });

      showNotificationWithIcon("OK", "Role successfully updated.", "success");
      this.props.confirm(true);
    };

    const errorFunc = error => {
      this.setState({
        addStatus: error.response.data
      });
    };

    put(url, requestBody, successFunc, errorFunc);
  };

  handleCancel = e => {
    this.props.confirm(false);
  };

  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    });
  };

  control = () => {
    let roleName = this.state.roleName;

    if (roleName == "") {
      this.setState({
        addStatus: "Role name must'nt empty."
      });
    }
    else {
      this.editRole();
    }
  };

  render() {
    return (
      <Modal
        title="Edit role"
        visible={this.props.visible}
        onCancel={this.handleCancel}
        footer={[]}
      >
        <Form style={{ background: "#fff" }}>
          <FormItem>
            <Input
              placeholder="Role name"
              value={this.state.roleName}
              id="roleName"
              onChange={this.handleChange}
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              suffix={
                <Tooltip title="Role name must be unique.">
                  <Icon
                    type="info-circle"
                    style={{ color: "rgba(0,0,0,.45)" }}
                  />
                </Tooltip>
              }
            />
          </FormItem>
          <FormItem>
            <TextArea
              placeholder="Description"
              value={this.state.roleDescription}
              rows={3}
              id="roleDescription"
              onChange={this.handleChange}
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              suffix={
                <Tooltip title="Extra information">
                  <Icon
                    type="info-circle"
                    style={{ color: "rgba(0,0,0,.45)" }}
                  />
                </Tooltip>
              }
            />
          </FormItem>
          <FormItem>
            <Button
              type="primary"
              onClick={() => this.control()}
              style={{ float: "right" }}
            >
              Save
            </Button>
          </FormItem>
          <FormItem>
            <Alert
              message={this.state.addStatus}
              style={{
                display: this.state.addStatus == "" ? "none" : "block"
              }}
              type={this.state.addStatus == "OK" ? "success" : "error"}
            />
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default EditRolesContent;
