import {
  BgColorsOutlined,
  BoldOutlined,
  ColumnWidthOutlined,
  CrownOutlined,
  EyeInvisibleOutlined,
  FieldTimeOutlined,
  FileImageOutlined,
  FontSizeOutlined,
  InfoCircleOutlined,
  ItalicOutlined,
  NumberOutlined,
  UnderlineOutlined
} from "@ant-design/icons";
import { Button, Checkbox, Col, Divider, Input, InputNumber, Row, Select, Tooltip } from "antd";
import $ from "jquery";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import React, { Component } from "react";
import { deepCopy } from "../../../../Utils/Global";
import {
  showError,
  showNotificationWithIcon,
} from "../../../../Utils/Notification";
import i18n from "../../../../Utils/i18next";
import { post } from "../../../../admin/Utils/WebService";
import FontTypeSelector from "../../../GeneralComponents/FontTypeSelector/FontTypeSelector";
import MeasureModal from "../../../GeneralComponents/MeasureModal/MeasureModal";
import Text from "../../../GeneralComponents/Text/Text";
import TitleSettingsOfPlugin from "../../../GeneralComponents/TitleSettingsOfPlugin/TitleSettingsOfPlugin";
import { shadeColor } from "../ButtonFilter/getButtonComponent";
import ReturnToTheme from "../../PluginToolsComponents/ReturnToTheme";

const clone = require("rfdc")();

const { TextArea } = Input;
const { Option } = Select;

export default class MeasureTileConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      size: this.props.config.size,
      numFont: this.props.config.numFont,
      colour: this.props.config.colour === "red" ? "#e50606" : this.props.config.colour,
      icon: this.props.config.icon,
      font: this.props.config.font,
      animation: this.props.config.animation,
      duration: this.props.config.duration === undefined ? 1 : this.props.config.duration,
      many: this.props.config.many,
      hideLabel: this.props.config.hideLabel,
      showHideButton: this.props.config.showHideButton,
      title: this.props.config.title,
      titleAlign: this.props.config.titleAlign,
      backgroundColor: this.props.config.backgroundColor,
      isImage: this.props.config.isImage,
      imgSrc: this.props.config.imgSrc,
      imageUpload: this.props.config.imgUpload,
      imageName: this.props.config.imageName || "",
      imagePath: this.props.config.imagePath || "",
      imgWidth: this.props.config.imgWidth,
      imgHeight: this.props.config.imgHeight,
      colorChange: false,
      secondaryColourChange: false,
      backgroundColorChange: false,
      iconSelector: false,
      search: "",
      titleFontStyle: this.props.config.titleFontStyle === undefined ? false : this.props.config.titleFontStyle,
      titleFontWeight: this.props.config.titleFontWeight === undefined ? false : this.props.config.titleFontWeight,
      titleFontSize: this.props.config.titleFontSize === undefined ? 15 : this.props.config.titleFontSize,
      titleFont: this.props.config.titleFont === undefined ? "Verdana" : this.props.config.titleFont,
      titleColour: this.props.config.titleColour === undefined ? "black" : this.props.config.titleColour,
      titleTextDecor: this.props.config.titleTextDecor === undefined ? false : this.props.config.titleTextDecor,
      changedTitleFontSize: this.props.config.changedTitleFontSize === undefined ? 15 : this.props.config.changedTitleFontSize,
      keyForTitleSize: null,
      condFormat: this.props.config.condFormat,
      responsive: this.props.config.responsive,
      colour: this.props.config.colour === "red" ? "rgb(255, 0, 0)" : this.props.config.colour,
      secondaryColour: this.props.config.secondaryColour == undefined ? shadeColor(this.props.config.colour === "red" ? "rgb(255, 0, 0)" : this.props.config.colour, 0, 70) : this.props.config.secondaryColour,
      iconSize: this.props.config.iconSize == undefined ? 27 : this.props.config.iconSize,
      isPluginNewPlugin: this.props.config.isPluginNewPlugin == undefined || !this.props.config.isPluginNewPlugin ? false : true,

      compare: this.props.config.compare == undefined ? false : this.props.config.compare,
      dataType: this.props.config.dataType == undefined ? "numeric" : this.props.config.dataType,
      compareDescription: this.props.config.compareDescription == undefined ? "" : this.props.config.compareDescription,
      compareAlign: this.props.config.compareAlign == undefined ? "center" : this.props.config.compareAlign,

      configArray: this.props.config.configArray ? this.props.config.configArray : this.props.configArray,
      compareLength: this.props.compareLength,
      activeConfigurationIndex: 0,
      pagination: this.props.config.pagination == undefined ? false : this.props.config.pagination,
      showPaginationButtons: this.props.config.showPaginationButtons == undefined ? false : this.props.config.showPaginationButtons,

      measureHeader: this.props.config.measureHeader == undefined ? "" : this.props.config.measureHeader,
      measureHeaderAlign: this.props.config.measureHeaderAlign == undefined ? "left" : this.props.config.measureHeaderAlign,
      headerFontWeight: this.props.config.headerFontWeight == undefined ? true : this.props.config.headerFontWeight,
      headerFontStyle: this.props.config.headerFontStyle == undefined ? false : this.props.config.headerFontStyle,
      headerTextDecor: this.props.config.headerTextDecor == undefined ? false : this.props.config.headerTextDecor,
      headerFont: this.props.config.headerFont == undefined ? "Verdana" : this.props.config.headerFont,
      headerTextSize: this.props.config.headerTextSize == undefined || this.props.config.headerTextSize === "" ? 16 : this.props.config.headerTextSize,
      headerColor: this.props.config.headerColor == undefined ? this.props.config.colour === "red" ? "#e50606" : this.props.config.colour : this.props.config.headerColor,

      headerFontDescription: this.props.config.headerFontDescription == undefined ? "" : this.props.config.headerFontDescription,
      headerFontDescriptionPosition: this.props.config.headerFontDescriptionPosition == undefined ? "bottom" : this.props.config.headerFontDescriptionPosition,
      headerDescriptionFontWeight: this.props.config.headerDescriptionFontWeight == undefined ? false : this.props.config.headerDescriptionFontWeight,
      headerDescriptionFontStyle: this.props.config.headerDescriptionFontStyle == undefined ? false : this.props.config.headerDescriptionFontStyle,
      headerDescriptionTextDecor: this.props.config.headerDescriptionTextDecor == undefined ? false : this.props.config.headerDescriptionTextDecor,
      headerDescriptionFont: this.props.config.headerDescriptionFont == undefined ? "Verdana" : this.props.config.headerDescriptionFont,
      headerDescriptionTextSize: this.props.config.headerDescriptionTextSize == undefined || this.props.config.headerDescriptionTextSize === "" ? 11 : this.props.config.headerDescriptionTextSize,

      measurePosition: this.props.config.measurePosition == undefined ? "left" : this.props.config.measurePosition,
      measureFontWeight: this.props.config.measureFontWeight == undefined ? true : this.props.config.measureFontWeight,
      measureFontStyle: this.props.config.measureFontStyle == undefined ? false : this.props.config.measureFontStyle,
      measureTextDecor: this.props.config.measureTextDecor == undefined ? false : this.props.config.measureTextDecor,
      measureFont: this.props.config.measureFont == undefined ? "Verdana" : this.props.config.measureFont,
      measureTextSize: this.props.config.measureTextSize == undefined || this.props.config.measureTextSize === "" ? 22 : this.props.config.measureTextSize,

      measureComparePosition: this.props.config.measureComparePosition == undefined ? "left" : this.props.config.measureComparePosition,
      measureCompareFontWeight: this.props.config.measureCompareFontWeight == undefined ? false : this.props.config.measureCompareFontWeight,
      measureCompareFontStyle: this.props.config.measureCompareFontStyle == undefined ? false : this.props.config.measureCompareFontStyle,
      measureCompareTextDecor: this.props.config.measureCompareTextDecor == undefined ? false : this.props.config.measureCompareTextDecor,
      measureCompareFont: this.props.config.measureCompareFont == undefined ? "Verdana" : this.props.config.measureCompareFont,
      measureCompareTextSize: this.props.config.measureCompareTextSize == undefined || this.props.config.measureCompareTextSize === "" ? 11 : this.props.config.measureCompareTextSize,

      isMultiple: this.props.config.isMultiple == undefined ? true : this.props.config.isMultiple,
      activeConfigurationPageIndex: 0,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      this.setState({
        size: nextProps.config.size,
        numFont: nextProps.config.numFont,
        icon: nextProps.config.icon,
        font: nextProps.config.font,
        duration: nextProps.config.duration === undefined ? 1 : nextProps.config.duration,
        many: nextProps.config.many,
        hideLabel: nextProps.config.hideLabel,
        showHideButton: nextProps.config.showHideButton,
        title: nextProps.config.title,
        titleAlign: nextProps.config.titleAlign,
        backgroundColor: nextProps.config.backgroundColor,
        isImage: nextProps.config.isImage,
        imgSrc: nextProps.config.imgSrc,
        imageUpload: nextProps.config.imageUpload,
        imageName: nextProps.config.imageName || "",
        imagePath: nextProps.config.imagePath || "",
        imgWidth: nextProps.config.imgWidth,
        imgHeight: nextProps.config.imgHeight,
        condFormat: nextProps.config.condFormat,
        responsive: nextProps.config.responsive,
        titleFont: nextProps.config.titleFont === undefined ? "Verdana" : nextProps.config.titleFont,
        titleFontSize: nextProps.config.titleFontSize === undefined ? 15 : nextProps.config.titleFontSize,
        changedTitleFontSize: nextProps.config.changedTitleFontSize === undefined ? 15 : nextProps.config.changedTitleFontSize,
        titleFontStyle: nextProps.config.titleFontStyle === undefined ? false : nextProps.config.titleFontStyle,
        titleFontWeight: nextProps.config.titleFontWeight === undefined ? false : nextProps.config.titleFontWeight,
        titleTextDecor: nextProps.config.titleTextDecor === undefined ? false : nextProps.config.titleTextDecor,
        titleColour: nextProps.config.titleColour === undefined ? "black" : nextProps.config.titleColour,
        isPluginNewPlugin: nextProps.config.isPluginNewPlugin == undefined || !nextProps.config.isPluginNewPlugin ? false : true,

        configArray: nextProps.config.configArray ? nextProps.config.configArray : nextProps.configArray,
        compareLength: nextProps.compareLength,
        pagination: nextProps.config.pagination == undefined ? false : nextProps.config.pagination,
        showPaginationButtons: nextProps.config.showPaginationButtons == undefined ? false : nextProps.config.showPaginationButtons,
        compareAlign: nextProps.config.compareAlign == undefined ? "center" : nextProps.config.compareAlign,

        compare: nextProps.config.compare == undefined ? false : nextProps.config.compare,
        dataType: nextProps.config.dataType == undefined ? "numeric" : nextProps.config.dataType,
        compareDescription: nextProps.config.compareDescription == undefined ? "" : nextProps.config.compareDescription,

        headerTextSize: nextProps.config.headerTextSize == undefined ? 16 : nextProps.config.headerTextSize,
        headerFont: nextProps.config.headerFont == undefined ? "Verdana" : nextProps.config.headerFont,
        colour: nextProps.config.colour === "red" ? "#e50606" : nextProps.config.colour,
        secondaryColour: nextProps.config.secondaryColour == undefined ? shadeColor(nextProps.config.colour === "red" ? "rgb(255, 0, 0)" : nextProps.config.colour, 0, 70) : nextProps.config.secondaryColour,
        iconSize: nextProps.config.iconSize == undefined ? 27 : nextProps.config.iconSize,

        measureHeader: nextProps.config.measureHeader == undefined ? "" : nextProps.config.measureHeader,
        measureHeaderAlign: nextProps.config.measureHeaderAlign == undefined ? "left" : nextProps.config.measureHeaderAlign,
        header: nextProps.config.headerFontWeight == undefined ? true : nextProps.config.headerFontWeight,
        headerFontStyle: nextProps.config.headerFontStyle == undefined ? false : nextProps.config.headerFontStyle,
        headerTextDecor: nextProps.config.headerTextDecor == undefined ? false : nextProps.config.headerTextDecor,
        headerFont: nextProps.config.headerFont == undefined ? "Verdana" : nextProps.config.headerFont,
        headerTextSize: nextProps.config.headerTextSize == undefined ? 16 : nextProps.config.headerTextSize,
        headerColor: nextProps.config.headerColor == undefined ? nextProps.config.colour === "red" ? "#e50606" : nextProps.config.colour : nextProps.config.headerColor,

        headerFontDescription: nextProps.config.headerFontDescription == undefined ? "" : nextProps.config.headerFontDescription,
        headerFontDescriptionPosition: nextProps.config.headerFontDescriptionPosition == undefined ? "bottom" : nextProps.config.headerFontDescriptionPosition,
        headerDescriptionFontWeight: nextProps.config.headerDescriptionFontWeight == undefined ? false : nextProps.config.headerDescriptionFontWeight,
        headerDescriptionFontStyle: nextProps.config.headerDescriptionFontStyle == undefined ? false : nextProps.config.headerDescriptionFontStyle,
        headerDescriptionTextDecor: nextProps.config.headerDescriptionTextDecor == undefined ? false : nextProps.config.headerDescriptionTextDecor,
        headerDescriptionFont: nextProps.config.headerDescriptionFont == undefined ? "Verdana" : nextProps.config.headerDescriptionFont,
        headerDescriptionTextSize: nextProps.config.headerDescriptionTextSize == undefined ? 11 : nextProps.config.headerDescriptionTextSize,

        measurePosition: nextProps.config.measurePosition == undefined ? "left" : nextProps.config.measurePosition,
        measureFontWeight: nextProps.config.measureFontWeight == undefined ? true : nextProps.config.measureFontWeight,
        measureFontStyle: nextProps.config.measureFontStyle == undefined ? false : nextProps.config.measureFontStyle,
        measureTextDecor: nextProps.config.measureTextDecor == undefined ? false : nextProps.config.measureTextDecor,
        measureFont: nextProps.config.measureFont == undefined ? "Verdana" : nextProps.config.measureFont,
        measureTextSize: nextProps.config.measureTextSize == undefined || nextProps.config.measureTextSize === "" ? 22 : nextProps.config.measureTextSize,

        measureComparePosition: nextProps.config.measureComparePosition == undefined ? "left" : nextProps.config.measureComparePosition,
        measureCompareFontWeight: nextProps.config.measureCompareFontWeight == undefined ? false : nextProps.config.measureCompareFontWeight,
        measureCompareFontStyle: nextProps.config.measureCompareFontStyle == undefined ? false : nextProps.config.measureCompareFontStyle,
        measureCompareTextDecor: nextProps.config.measureCompareTextDecor == undefined ? false : nextProps.config.measureCompareTextDecor,
        measureCompareFont: nextProps.config.measureCompareFont == undefined ? "Verdana" : nextProps.config.measureCompareFont,
        measureCompareTextSize: nextProps.config.measureCompareTextSize == undefined || nextProps.config.measureCompareTextSize === "" ? 11 : nextProps.config.measureCompareTextSize,

        isMultiple: nextProps.config.isMultiple == undefined ? true : nextProps.config.isMultiple,
        activeConfigurationPageIndex: nextProps.config.activeConfigurationPageIndex ? nextProps.config.activeConfigurationPageIndex : 0,
      });
    }
  }

  /*
  * Changes both of constant and changed value 
  * constant = between 10 and 30 values
  * changed value = all of values
  */
  handleChangeBothOfValues = (constantValue, changedValue) => {
    this.setState({
      ...this.state,
      titleFontSize: constantValue,
      changedTitleFontSize: changedValue
    })

    this.setConfigToPlugin(
      [
        {
          type: "titleFontSize",
          value: constantValue
        },
        {
          type: "changedTitleFontSize",
          value: changedValue
        }
      ]);
  }

  //sets defaults we get from plugin.jsx. callback for fontsizekey because it should keep changing to trigger default revert
  setDefaultsForTitle = () => {
    let newConfig = this.props.setDefaultForPluginTitle();

    this.setState(
      {
        ...this.state,
        title: newConfig.title,
        titleAlign: newConfig.titleAlign,
        titleFont: newConfig.titleFont,
        titleFontSize: newConfig.titleFontSize,
        titleFontStyle: newConfig.titleFontStyle,
        titleFontWeight: newConfig.titleFontWeight,
        titleTextDecor: newConfig.titleTextDecor,
        titleColour: newConfig.titleColour,
        changedTitleFontSize: newConfig.titleFontSize
      }, () => {
        this.setConfigToPlugin({ type: "changedTitleFontSize", value: newConfig.titleFontSize })
      }
    );

    if (newConfig.titleFontSize > 10 || newConfig.titleFontSize < 30) {
      $("#titleFontSize").css("background", "")
    }
  }

  titleColourChangeVisible = () => {
    this.setState({
      ...this.state,
      titleColourChange: !this.state.titleColourChange
    });
  };

  // Changes measure tile config by changed indexed value
  handleChangeForMeasure = (e) => {
    let configObj = [...this.state.configArray]
    let activeConfigurationPageIndex = this.state.activeConfigurationPageIndex
    let activeConfigurationIndex = (activeConfigurationPageIndex * 8) + this.state.activeConfigurationIndex

    configObj[activeConfigurationIndex][e.target.id] = e.target.value

    this.setState({
      ...this.state,
      configArray: configObj,
    });

    this.setConfigToPlugin({ type: "configArray", value: configObj });
  };

  /*
  * Changes no data title
  */
  handleChangeForNoDataMeasure = (e) => {
    let configObj = [...this.state.configArray]
    let activeConfigurationPageIndex = this.state.activeConfigurationPageIndex
    let activeConfigurationIndex = (activeConfigurationPageIndex * 8) + this.state.activeConfigurationIndex

    configObj[activeConfigurationIndex][e.target.id] = e.target.value

    this.setState({
      ...this.state,
      configArray: configObj,
    });

    this.setConfigToPlugin({ type: "configArray", value: configObj });

    this.props.changeNoDataTitleForMeasure(this.state.activeConfigurationIndex, e.target.value)
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value,
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.value });
  };


  handleChangeButton = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: !this.state[e.target.id],
    });

    this.setConfigToPlugin({ type: e.target.id, value: !this.state[e.target.id] });
  };

  // Changes measure tile config by changed indexed value
  handleChangeButtonForMeasure = (e) => {
    let configObj = [...this.state.configArray]
    let activeConfigurationPageIndex = this.state.activeConfigurationPageIndex
    let activeConfigurationIndex = (activeConfigurationPageIndex * 8) + this.state.activeConfigurationIndex

    configObj[activeConfigurationIndex][e.target.id] = !configObj[activeConfigurationIndex][e.target.id]

    this.setState({
      ...this.state,
      configArray: configObj,
    });

    this.setConfigToPlugin({ type: "configArray", value: configObj });
  };

  /*
   * Controls is font-size greater than plugin width and height. If font-size greater than plugin width and height returns error
   */
  handleChangeFontSize = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value,
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.value });
  };

  handleReset = e => {
    this.props.setPluginRerender(true, this.props.pluginId, true);
  };

  handleCheck = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked,
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.checked });
  };

  handleCheckConditionalFormat = (e) => {
    $("#" + this.props.plugin.id).find("#showCondForm").remove()

    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked,
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.checked });
  }

  // Changes measure tile config by changed indexed value
  handleCheckButtonForMeasure = (e) => {
    let configObj = [...this.state.configArray]
    let activeConfigurationPageIndex = this.state.activeConfigurationPageIndex
    let activeConfigurationIndex = (activeConfigurationPageIndex * 8) + this.state.activeConfigurationIndex

    configObj[activeConfigurationIndex][e.target.id] = e.target.checked

    this.setState({
      ...this.state,
      configArray: configObj,
    });

    this.setConfigToPlugin({ type: "configArray", value: configObj });
  };

  // Changes measure tile config by changed indexed value
  handleCheckForMeasure = (e) => {
    let configObj = [...this.state.configArray]
    let activeConfigurationPageIndex = this.state.activeConfigurationPageIndex
    let activeConfigurationIndex = (activeConfigurationPageIndex * 8) + this.state.activeConfigurationIndex

    configObj[activeConfigurationIndex][e.target.id] = e.target.value

    this.setState({
      ...this.state,
      configArray: configObj,
    });

    this.setConfigToPlugin({ type: "configArray", value: configObj });
  };


  /**
 * Updates state attributes in RouteMap plugin
 */
  setConfigToPlugin = (changedObj, propertyListToDelete = undefined) => {
    let configObj = [...this.state.configArray]
    let activeConfigurationPageIndex = this.state.activeConfigurationPageIndex
    let activeConfigurationIndex = (activeConfigurationPageIndex * 8) + this.state.activeConfigurationIndex

    let tempState = { ...this.state };

    if (Array.isArray(changedObj)) {
      for (let i = 0; i < changedObj.length; i++) {
        let object = changedObj[i];
        tempState[object.type] = object.value;
      }
    } else {
      tempState[changedObj.type] = changedObj.value;
    }

    if (propertyListToDelete !== undefined) {
      for (let item of propertyListToDelete) {
        delete tempState[item]
      }
    }

    // Removed for img source deleted when update link
    // if (configObj[activeConfigurationIndex].imgSrc != "") {
    //   tempState.configArray[activeConfigurationIndex].imageName = "";
    //   tempState.configArray[activeConfigurationIndex].imagePath = "";
    //   tempState.configArray[activeConfigurationIndex].imageUpload = "";
    // } else if (configObj[activeConfigurationIndex].imgSrc == "" && tempState.imageName == "") {
    //   tempState.configArray[activeConfigurationIndex].imageName = "";
    //   tempState.configArray[activeConfigurationIndex].imagePath = "";
    //   tempState.configArray[activeConfigurationIndex].imageUpload = "";
    //   tempState.configArray[activeConfigurationIndex].imgSrc = "";
    // }

    this.props.updateConfig(
      tempState,
      this.props.pluginId,
      propertyListToDelete
    );
  };

  // Changes icon
  iconOnChange = (value) => {
    let configObj = [...this.state.configArray]
    let activeConfigurationPageIndex = this.state.activeConfigurationPageIndex
    let activeConfigurationIndex = (activeConfigurationPageIndex * 8) + this.state.activeConfigurationIndex

    configObj[activeConfigurationIndex]["icon"] = value

    this.setState({
      ...this.state,
      icon: value,
      configArray: configObj,
      iconSelector: false,
      search: "",
    });

    this.setConfigToPlugin({ type: "configArray", value: configObj });
  };

  // Changes color
  colorOnChange = (obj) => {
    let configObj = [...this.state.configArray]
    let activeConfigurationPageIndex = this.state.activeConfigurationPageIndex
    let activeConfigurationIndex = (activeConfigurationPageIndex * 8) + this.state.activeConfigurationIndex

    configObj[activeConfigurationIndex]["colour"] = obj.color
    configObj[activeConfigurationIndex]["secondaryColour"] = shadeColor(obj.color, 0, 70)

    this.setState({
      ...this.state,
      configArray: configObj
    });

    this.setConfigToPlugin({ type: "configArray", value: configObj });
  };

  titleAlignOnChange = (value) => {
    this.setState({
      ...this.state,
      titleAlign: value,
    });

    this.setConfigToPlugin({ type: "titleAlign", value: value });
  };

  colorChangeVisible = () => {
    this.setState({ ...this.state, colorChange: !this.state.colorChange, secondaryColourChange: false });
  };

  secondaryColourChangeVisible = () => {
    this.setState({ ...this.state, secondaryColourChange: !this.state.secondaryColourChange, colorChange: false });
  }

  // Secondary color on change
  secondaryColorOnChange = (obj) => {
    let configObj = [...this.state.configArray]
    let activeConfigurationPageIndex = this.state.activeConfigurationPageIndex
    let activeConfigurationIndex = (activeConfigurationPageIndex * 8) + this.state.activeConfigurationIndex

    configObj[activeConfigurationIndex]["secondaryColour"] = obj.color

    this.setState({
      ...this.state,
      configArray: configObj
    });

    this.setConfigToPlugin({ type: "configArray", value: configObj });
  };

  // Background color on change
  backgroundColorOnChange = (obj) => {
    this.setState({
      ...this.state,
      backgroundColor: obj.color,
    });

    this.setConfigToPlugin({ type: "backgroundColor", value: obj.color });
  };

  // Header color on change
  headerColorOnChange = (obj) => {
    this.setState({
      ...this.state,
      headerColorOnChange: obj.color,
    });

    this.setConfigToPlugin({ type: "headerColorOnChange", value: obj.color });
  };

  backgroundColorChangeVisible = () => {
    this.setState({
      ...this.state,
      backgroundColorChange: !this.state.backgroundColorChange,
    });
  };

  // Header color change visibility
  headerColorChangeVisible = () => {
    this.setState({
      ...this.state,
      headerColorChange: !this.state.headerColorChange,
    });
  };

  //added this function to change the font-family
  fontOnChange = (obj) => {
    this.setState({
      ...this.state,
      font: obj,
    });

    this.setConfigToPlugin({ type: "font", value: obj });
  };

  // Data type on change
  dataTypeOnChange = value => {
    this.setState({
      ...this.state,
      dataType: value
    });

    this.setConfigToPlugin({ type: "dataType", value: value });
  };

  // Header description font on change
  headerDescriptionFontOnChange = (obj) => {
    let configObj = [...this.state.configArray]
    let activeConfigurationPageIndex = this.state.activeConfigurationPageIndex
    let activeConfigurationIndex = (activeConfigurationPageIndex * 8) + this.state.activeConfigurationIndex

    configObj[activeConfigurationIndex]["headerDescriptionFont"] = obj

    this.setState({
      ...this.state,
      configArray: configObj
    });

    this.setConfigToPlugin({ type: "configArray", value: configObj });
  };

  // Measure font on change
  measureFontOnChange = (obj) => {
    let configObj = [...this.state.configArray]
    let activeConfigurationPageIndex = this.state.activeConfigurationPageIndex
    let activeConfigurationIndex = (activeConfigurationPageIndex * 8) + this.state.activeConfigurationIndex

    configObj[activeConfigurationIndex]["measureFont"] = obj

    this.setState({
      ...this.state,
      configArray: configObj,
    });

    this.setConfigToPlugin({ type: "configArray", value: configObj });
  };

  // Measure compare font on change
  measureCompareFontOnChange = (obj) => {
    let configObj = [...this.state.configArray]
    let activeConfigurationPageIndex = this.state.activeConfigurationPageIndex
    let activeConfigurationIndex = (activeConfigurationPageIndex * 8) + this.state.activeConfigurationIndex

    configObj[activeConfigurationIndex]["measureCompareFont"] = obj

    this.setState({
      ...this.state,
      configArray: configObj
    });

    this.setConfigToPlugin({ type: "configArray", value: configObj });
  };

  // Header font on change
  headerFontOnChange = (obj) => {
    let configObj = [...this.state.configArray]
    let activeConfigurationPageIndex = this.state.activeConfigurationPageIndex
    let activeConfigurationIndex = (activeConfigurationPageIndex * 8) + this.state.activeConfigurationIndex

    configObj[activeConfigurationIndex]["headerFont"] = obj

    this.setState({
      ...this.state,
      configArray: configObj,
    });

    this.setConfigToPlugin({ type: "configArray", value: configObj });
  };

  // Select icon modal visibility
  selectIconChangeVisible = () => {
    this.setState({
      ...this.state,
      iconSelector: !this.state.iconSelector,
      search: "",
    });
  };

  // Removes icon
  removeIcon = () => {
    let configObj = [...this.state.configArray]
    let activeConfigurationPageIndex = this.state.activeConfigurationPageIndex
    let activeConfigurationIndex = (activeConfigurationPageIndex * 8) + this.state.activeConfigurationIndex

    configObj[activeConfigurationIndex]["icon"] = ""

    this.setState({
      ...this.state,
      configArray: configObj,
      icon: "",
    });

    this.setConfigToPlugin({ type: "icon", value: "" });
  };

  // Search input on change
  searchInputOnChange = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  /**
   *
   * @param {*} e
   * @returns
   *
   * Performs validation check for the uploaded file
   */
  fileValidationCheck = (e) => {
    if (e === undefined) {
      return false;
    }

    let fileName = e.target.files[0] ? e.target.files[0].name : undefined;

    if (fileName === undefined) {
      return false;
    } else {
      let fileNameSplit = fileName.split(".");
      let fileType = fileNameSplit[fileNameSplit.length - 1];

      if (!(fileType === "jpeg" || fileType === "png" || fileType === "jpg")) {
        return false;
      }

      return true;
    }
  };

  //handle selected file and name
  onChangeFile = (e) => {
    let THIS = this;

    if (e !== undefined) {
      if (!this.fileValidationCheck(e)) {
        showError(i18n.t("PluginErrors.ImageFormatInvalid"));

        return;
      }

      /**
       * image file to base 64
       * @param {*} img
       * @param {*} callback
       */
      const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
      };

      if (e.target.files[0] && e.target.files[0].name) {
        let file = e.target.files[0];
        let fileName = e.target.files[0].name;

        getBase64(file, (imgSrc) => {
          this.state.image = imgSrc;

          imgSrc = imgSrc.split(",")[1];

          this.uploadImage(imgSrc, fileName);
        });
      }
    }
  };

  /*
   *Upload image to resource server.
   */
  uploadImage = (file, fileName) => {
    let url = "/resource/api/resource";

    let requestBody = {
      content: file,
      cacheable: false,
    };

    const successFunc = (result) => {
      let tempState = { ...this.state };
      let resourcePath = "/resource/api/resource/";
      let configArray = [...this.props.configArray]
      let activeIndexOfConfigArray = configArray[this.state.activeConfigurationIndex]

      activeIndexOfConfigArray.imageUpload = file
      activeIndexOfConfigArray.imageName = fileName
      activeIndexOfConfigArray.imagePath = resourcePath + result.data
      activeIndexOfConfigArray.imgSrc = ""

      this.setState({
        ...this.state,
        configArray: configArray
      });

      tempState.configArray = configArray

      this.props.updateConfig(tempState, this.props.pluginId);
    };

    const errorFunc = (response) => {
      let tempState = { ...this.state };

      showNotificationWithIcon(
        i18n.t("Error"),
        i18n.t("PluginErrors.ImageCouldntUpload"),
        "error"
      );

      let configArray = [...this.props.configArray]
      let activeIndexOfConfigArray = configArray[this.state.activeConfigurationIndex]

      activeIndexOfConfigArray.imageUpload = ""
      activeIndexOfConfigArray.imageName = ""
      activeIndexOfConfigArray.imagePath = ""
      activeIndexOfConfigArray.imgSrc = ""

      this.setState({
        ...this.state,
        configArray: configArray
      });

      tempState.configArray = configArray

      this.props.updateConfig(tempState, this.props.pluginId);
    };

    post(url, requestBody, successFunc, errorFunc);
  };

  // Header font position on change
  headerFontPositionOnChange = value => {
    let configObj = [...this.state.configArray]
    let activeConfigurationPageIndex = this.state.activeConfigurationPageIndex
    let activeConfigurationIndex = (activeConfigurationPageIndex * 8) + this.state.activeConfigurationIndex

    configObj[activeConfigurationIndex]["headerFontDescriptionPosition"] = value

    this.setState({
      ...this.state,
      configArray: configObj
    });

    this.setConfigToPlugin({ type: "configArray", value: configObj });
  };

  // Measure position on change
  measurePositionOnChange = value => {
    let configObj = [...this.state.configArray]
    let activeConfigurationPageIndex = this.state.activeConfigurationPageIndex
    let activeConfigurationIndex = (activeConfigurationPageIndex * 8) + this.state.activeConfigurationIndex

    configObj[activeConfigurationIndex]["measurePosition"] = value

    this.setState({
      ...this.state,
      configArray: configObj
    });

    this.setConfigToPlugin({ type: "configArray", value: configObj });
  };

  // Measure compare position on change
  measureComparePositionOnChange = value => {
    let configObj = [...this.state.configArray]
    let activeConfigurationPageIndex = this.state.activeConfigurationPageIndex
    let activeConfigurationIndex = (activeConfigurationPageIndex * 8) + this.state.activeConfigurationIndex

    configObj[activeConfigurationIndex]["measureComparePosition"] = value

    this.setState({
      ...this.state,
      configArray: configObj
    });

    this.setConfigToPlugin({ type: "configArray", value: configObj });
  };

  // Measure header align on change
  measureHeaderAlignOnChange = value => {
    let configObj = [...this.state.configArray]
    let activeConfigurationPageIndex = this.state.activeConfigurationPageIndex
    let activeConfigurationIndex = (activeConfigurationPageIndex * 8) + this.state.activeConfigurationIndex

    configObj[activeConfigurationIndex]["measureHeaderAlign"] = value

    this.setState({
      ...this.state,
      configArray: configObj
    });

    this.setConfigToPlugin({ type: "configArray", value: configObj });
  };

  // Sets active index
  setActiveIndex = index => {
    this.setState({
      ...this.state,
      activeConfigurationIndex: index
    })
  }

  // Gets col span
  getColSpan = (compareLength) => {
    if (compareLength >= 8) {
      return 3
    } else {
      if (compareLength === 1) {
        return 24
      } else if (compareLength === 2) {
        return 12
      } else if (compareLength > 2 && compareLength <= 4) {
        return 6
      } else if (compareLength > 4 && compareLength <= 6) {
        return 4
      } else if (compareLength > 6 && compareLength <= 24) {
        return 3
      } else if (compareLength > 8 && compareLength <= 12) {
        return 2
      } else if (compareLength > 12 && compareLength <= 24) {
        return 1
      }
    }
  }

  // Sets active configuration page index
  setActiveConfigurationPageIndex = (type, compareLength) => {
    if (type === "increase") {
      let newIndex = this.state.activeConfigurationPageIndex + 1

      if (newIndex * 8 <= compareLength) {
        this.setState({
          ...this.state,
          activeConfigurationPageIndex: newIndex,
          activeConfigurationIndex: 0
        })
      }
    } else if (type === "decrease") {
      let newIndex = this.state.activeConfigurationPageIndex - 1

      if (newIndex * 8 >= 0) {
        this.setState({
          ...this.state,
          activeConfigurationPageIndex: newIndex,
          activeConfigurationIndex: 0
        })
      }
    }
  }

  // Return pagination items
  returnPaginationItems = () => {
    let slicedArrays = this.state.compareLength.slice(this.state.activeConfigurationPageIndex * 8, (this.state.activeConfigurationPageIndex + 1) * 8)

    return slicedArrays
  }

  // Gets configuration buttons className
  getConfigurationButtonClassName = (index) => {
    if (this.state.activeConfigurationIndex === index) {
      if (index === 0) {
        return "measure-configuration-button first active"
      } else if (index === 7 || (this.state.activeConfigurationPageIndex * 8) + index === this.state.compareLength.length - 1) {
        return "measure-configuration-button last active"
      } else {
        return "measure-configuration-button active"
      }
    } else {
      if (index === 0) {
        return "measure-configuration-button first"
      } else if (index === 7 || (this.state.activeConfigurationPageIndex * 8) + index === this.state.compareLength.length - 1) {
        return "measure-configuration-button last"
      } else {
        return "measure-configuration-button"
      }
    }
  }

  // Return compare buttons
  returnCompareButtons = () => {
    return (
      <Row style={{ display: "flex", flexDirection: "row", gap: "5px", alignItems: "center", justifyContent: "center" }}>
        <Col span={1} onClick={() => { this.setActiveConfigurationPageIndex("decrease", this.state.compareLength.length) }} style={{ cursor: "pointer" }}>{"<"}</Col>
        <Col span={22}>
          <Row style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            {this.returnPaginationItems().map((compare, i) => (
              <Tooltip title={compare}>
                {<Col className={this.getConfigurationButtonClassName(i)} span={this.getColSpan(this.state.compareLength.length)} onClick={() => this.setActiveIndex(i)}>
                  <span className="three-dot-button" style={{ width: "95%", maxWidth: "95%", display: "flex" }}>{compare}</span>
                </Col>}
              </Tooltip>
            ))}
          </Row>
        </Col>
        <Col span={1} onClick={() => { this.setActiveConfigurationPageIndex("increase", this.state.compareLength.length) }} style={{ cursor: "pointer" }}>{">"}</Col>
      </Row>
    )
  }

  // Sets settings to another area
  setSettingsAnotherArea = (from, to, area = "single-area") => {
    if (area === "single-area") {
      let activeConfigurationIndex = this.state.activeConfigurationIndex
      let copiedConfigArray = deepCopy(this.state.configArray)
      let copiedSettingsArea = deepCopy(copiedConfigArray[activeConfigurationIndex])

      copiedSettingsArea[to + "FontStyle"] = copiedSettingsArea[from + "FontStyle"]
      copiedSettingsArea[to + "FontWeight"] = copiedSettingsArea[from + "FontWeight"]
      copiedSettingsArea[to + "TextDecor"] = copiedSettingsArea[from + "TextDecor"]
      copiedSettingsArea[to + "Font"] = copiedSettingsArea[from + "Font"]
      copiedSettingsArea[to + "TextSize"] = copiedSettingsArea[from + "TextSize"]
      copiedSettingsArea[to + "Position"] = copiedSettingsArea[from + "Position"]

      if (to === "measure") {
        copiedSettingsArea["measurePosition"] = copiedSettingsArea["measureHeaderAlign"]
      } else if (to === "header") {
        copiedSettingsArea["measureHeaderAlign"] = copiedSettingsArea["measurePosition"]
      }

      copiedConfigArray[activeConfigurationIndex] = copiedSettingsArea

      this.setConfigToPlugin({ type: "configArray", value: copiedConfigArray })
    } else if (area === "all-area") {
      if (from !== "icon" && from !== "iconSize") {
        let copiedConfigArray = deepCopy(this.state.configArray)
        let activeConfigurationIndex = this.state.activeConfigurationIndex
        let currentConfig = copiedConfigArray[activeConfigurationIndex]

        for (let i = 0; i < copiedConfigArray.length; i++) {
          copiedConfigArray[i][from + "FontStyle"] = currentConfig[from + "FontStyle"]
          copiedConfigArray[i][from + "FontWeight"] = currentConfig[from + "FontWeight"]
          copiedConfigArray[i][from + "TextDecor"] = currentConfig[from + "TextDecor"]
          copiedConfigArray[i][from + "Font"] = currentConfig[from + "Font"]
          copiedConfigArray[i][from + "TextSize"] = currentConfig[from + "TextSize"]
          copiedConfigArray[i][from + "Position"] = currentConfig[from + "Position"]

          if (from === "header") {
            copiedConfigArray[i]["measureHeaderAlign"] = currentConfig["measureHeaderAlign"]
          } else if (from === "headerDescription") {
            copiedConfigArray[i]["headerFontDescriptionPosition"] = currentConfig["headerFontDescriptionPosition"]
          }
        }

        this.setConfigToPlugin({ type: "configArray", value: copiedConfigArray })
      } else {
        let copiedConfigArray = deepCopy(this.state.configArray)
        let activeConfigurationIndex = this.state.activeConfigurationIndex
        let currentConfig = copiedConfigArray[activeConfigurationIndex]

        if (from === "icon") {
          for (let i = 0; i < copiedConfigArray.length; i++) {
            copiedConfigArray[i]["icon"] = currentConfig["icon"]
          }
        } else if (from === "iconSize") {
          for (let i = 0; i < copiedConfigArray.length; i++) {
            copiedConfigArray[i]["iconSize"] = currentConfig["iconSize"]
          }
        }

        this.setConfigToPlugin({ type: "configArray", value: copiedConfigArray })
      }
    }
  }

  handleChangeCompareAlign = (e) => {
    this.setState({
      ...this.state,
      compareAlign: e
    })

    this.setConfigToPlugin({ type: "compareAlign", value: e })
  }

  /*
  * Changes value of measure duration
  */
  handleChangeDuration = (value) => {
    if (value || value === 0 || value === 0.0) {
      this.handleChange({ target: { id: "duration", value: Number(value) ? value : 0 } })
    } else {
      return
    }
  }

  render() {
    let imageName = "";

    let activeConfigurationPageIndex = this.state.activeConfigurationPageIndex
    let activeConfigurationIndex = (activeConfigurationPageIndex * 8) + this.state.activeConfigurationIndex
    let activeState = this.state.configArray[activeConfigurationIndex]
    let isIconExist = (activeConfigurationIndex === 0 && this.props.plugin.config.icon !== "") || activeState && activeState.icon !== "" && activeState.icon !== undefined ? true : false;
    let activeIcon = activeState && activeState.icon !== "" && activeState.icon !== undefined
      ? activeState.icon
      : activeConfigurationIndex === 0 && this.props.plugin.config.icon !== ""
        ? this.props.plugin.config.icon
        : ""

    if (this.state.imageName) {
      imageName = this.state.imageName.split("\\");
      imageName = imageName[imageName.length - 1];
    }

    return (
      <>
        <ReturnToTheme
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          preservedConfigFieldsArray={this.props.plugin.preservedConfigFields ? Array.from(this.props.plugin.preservedConfigFields) : []}
          isPluginMeasure={true}
        />

        {activeState ? <div style={{ color: "#505050" }}>
          {this.state.compareLength.length > 1 ? this.returnCompareButtons() : null}
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Configuration.Fields.Theme")}
          </Divider>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px",
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.ThemeColour")}
              </Text>
            </Col>
            <Col span={12}>
              <Row>
                <Col onClick={this.colorChangeVisible} span={12} style={{ width: "50%", height: "32px", background: activeState.colour }}></Col>
                <Col onClick={this.secondaryColourChangeVisible} span={12} style={{ width: "50%", height: "32px", background: activeState.secondaryColour }}></Col>
              </Row>
            </Col>
            {this.state.colorChange ? (
              <ColorPickerPanel
                alpha={100}
                color={activeState.colour}
                onChange={this.colorOnChange}
                id={"colour"}
                style={{ marginTop: "32px", position: "absolute", right: 115 }}
              />
            ) : null}
            {this.state.secondaryColourChange ? (
              <ColorPickerPanel
                alpha={100}
                color={activeState.secondaryColour}
                onChange={this.secondaryColorOnChange}
                id={"secondaryColour"}
                style={{ marginTop: "32px", position: "absolute", right: 0 }}
              />
            ) : null}
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FileImageOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px",
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.ShowMeasureFrame")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"isFrameEnable"}
                defaultChecked={activeState.isFrameEnable}
                checked={activeState.isFrameEnable}
                onChange={this.handleCheckButtonForMeasure}
              ></Checkbox>
            </Col>
          </Row>
          <Col span={24}>
            <Divider className={"config-divider"} orientation="left">
              {i18n.t("Dashboard.Configuration.Fields.Pagination")}
            </Divider>
          </Col>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FileImageOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px",
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Pagination")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"pagination"}
                defaultChecked={this.state.pagination}
                checked={this.state.pagination}
                onChange={this.handleCheck}
                disabled={this.props.compareLength.length > 2 ? false : true}
              ></Checkbox>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FileImageOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px",
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.PaginationBottomButtons")}
                <Tooltip
                  overlayClassName={"table-plugin-links"}
                  title={i18n.t("Dashboard.Configuration.Fields.PaginationBottomButtonsInfo")}
                >
                  <span
                    className={"table-plugin-link-info"}
                  >
                    (?)
                  </span>
                </Tooltip>
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"showPaginationButtons"}
                defaultChecked={this.state.showPaginationButtons}
                checked={this.state.showPaginationButtons}
                onChange={this.handleCheck}
                disabled={this.props.compareLength.length > 2 ? false : true}
              ></Checkbox>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Divider className={"config-divider"} orientation="left">
                {i18n.t("Dashboard.Configuration.Fields.Compare")}
              </Divider>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FileImageOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px",
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Compare")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"compare"}
                defaultChecked={this.state.compare}
                checked={this.state.compare}
                onChange={this.handleCheck}
                disabled={this.state.showPaginationButtons}
              ></Checkbox>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.CompareAlign")}
              </Text>
            </Col>
            <Col span={12}>
              <Select
                showSearch
                style={{ width: "100%" }}
                getPopupContainer={(trigger) => trigger.parentNode}
                placeholder={i18n.t("Dashboard.Configuration.Fields.CompareAlign")}
                optionFilterProp="children"
                id={"compareAlign"}
                onChange={this.handleChangeCompareAlign}
                defaultValue={this.state.compareAlign}
              >
                <Option value="left">{i18n.t("Dashboard.Configuration.Fields.Left")}</Option>
                <Option value="center">{i18n.t("Dashboard.Configuration.Fields.Center")}</Option>
                <Option value="right">{i18n.t("Dashboard.Configuration.Fields.Right")}</Option>
              </Select>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px",
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.CompareDescription")}
              </Text>
            </Col>
            <Col span={12}>
              <Input
                id={"compareDescription"}
                value={this.state.compareDescription}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.dataType")}
              </Text>
            </Col>
            <Col span={12}>
              <Select
                showSearch
                style={{ width: "100%" }}
                getPopupContainer={(trigger) => trigger.parentNode}
                placeholder={i18n.t("Dashboard.Configuration.Fields.dataType")}
                optionFilterProp="children"
                onChange={this.dataTypeOnChange}
                defaultValue={this.state.dataType}
              >
                <Option value="numeric">{i18n.t("Dashboard.Configuration.Fields.Numeric")}</Option>
                <Option value="percent">{i18n.t("Dashboard.Configuration.Fields.Percentage")}</Option>
                <Option value="percent-numeric">{i18n.t("Dashboard.Configuration.Fields.NumericPercentage")}</Option>
              </Select>
            </Col>
          </Row>
          <Row>
            <Col span={13}>
              <Divider className={"config-divider"} orientation="left">
                {i18n.t("Dashboard.Configuration.Fields.HeaderTextStyling")}
              </Divider>
            </Col>
            <Col span={5}>
              <Tooltip title={i18n.t("Dashboard.Configuration.Fields.SetToMeasure")}>
                <Button
                  id="returnDefaultButton"
                  className={"general-button"}
                  onClick={() => this.setSettingsAnotherArea("header", "measure")}
                  style={{
                    fontSize: "13px",
                    height: "24px",
                    width: "145px",
                    backgroundColor: "#62b779",
                    marginTop: "25px",
                    marginBottom: "25px",
                  }}
                >
                  <span className="three-dot-button">{i18n.t("Dashboard.Configuration.Fields.SetToMeasure")}</span>
                </Button>
              </Tooltip>
            </Col>

            <Col span={5} style={{ marginLeft: "17px" }}>
              <Tooltip title={i18n.t("Dashboard.Configuration.Fields.SetAllHeaderArea")}>
                <Button
                  id="returnDefaultButton"
                  className={"general-button"}
                  onClick={() => this.setSettingsAnotherArea("header", "", "all-area")}
                  style={{
                    fontSize: "13px",
                    height: "24px",
                    width: "145px",
                    backgroundColor: "#62b779",
                    marginTop: "25px",
                    marginBottom: "25px",
                  }}
                >
                  <span className="three-dot-button">{i18n.t("Dashboard.Configuration.Fields.SetAllHeaderArea")}</span>
                </Button>
              </Tooltip>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px",
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.MeasureHeader")}
              </Text>
            </Col>
            <Col span={12}>
              <Input
                id={"measureHeader"}
                value={activeState.measureHeader == undefined ? "" : activeState.measureHeader}
                placeholder={i18n.t("Dashboard.Configuration.Fields.MeasureHeaderDescription")}
                onChange={this.handleChangeForMeasure}
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.HeaderFontDescriptionPosition")}
              </Text>
            </Col>
            <Col span={12}>
              <Select
                showSearch
                style={{ width: "100%" }}
                getPopupContainer={(trigger) => trigger.parentNode}
                placeholder={i18n.t("Dashboard.Configuration.Fields.HeaderFontDescriptionPosition")}
                optionFilterProp="children"
                onChange={this.measureHeaderAlignOnChange}
                value={activeState.measureHeaderAlign}
                defaultValue={activeState.measureHeaderAlign}
              >
                <Option value="left">{i18n.t("Dashboard.Configuration.Fields.Left")}</Option>
                <Option value="center">{i18n.t("Dashboard.Configuration.Fields.Center")}</Option>
                <Option value="right">{i18n.t("Dashboard.Configuration.Fields.Right")}</Option>
              </Select>
            </Col>
          </Row>

          <Row style={{ marginBottom: "5px" }}>
            <Col
              style={{ textAlign: "center" }}
              span={this.props.titleIcon ? this.props.titleIcon : 3}
            >
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col
              style={{ textAlign: "left" }}
              span={this.props.titleColumn ? this.props.titleColumn : 9}
            >
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.HeaderTitleFontStyle")}
              </Text>
            </Col>
            <Col style={{ textAlign: "left" }} span={12}>
              {this.state.headerFontWeight !== undefined ? (
                <span>
                  <Button
                    onClick={this.handleChangeButtonForMeasure}
                    id={"headerFontWeight"}
                    style={{
                      backgroundColor: activeState.headerFontWeight ? "#9ed3ff" : "initial",
                      color: "black",
                      borderColor: "lightgrey",
                    }}
                  >
                    <BoldOutlined
                      style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                    />
                  </Button>
                </span>
              ) : null}

              {this.state.headerFontStyle !== undefined ? (
                <span>
                  <Button
                    onClick={this.handleChangeButtonForMeasure}
                    id={"headerFontStyle"}
                    style={{
                      marginLeft: "20px",
                      backgroundColor: activeState.headerFontStyle ? "#9ed3ff" : "initial",
                      color: "black",
                      borderColor: "lightgrey",
                    }}
                  >
                    <ItalicOutlined
                      style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                    />
                  </Button>
                </span>
              ) : null}

              {this.state.headerTextDecor !== undefined ? (
                <span>
                  <Button
                    onClick={this.handleChangeButtonForMeasure}
                    id={"headerTextDecor"}
                    style={{
                      marginLeft: "20px",
                      backgroundColor: activeState.headerTextDecor ? "#9ed3ff" : "initial",
                      color: "black",
                      borderColor: "lightgrey",
                    }}
                  >
                    <UnderlineOutlined
                      style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                    />
                  </Button>
                </span>
              ) : null}
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <NumberOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px",
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.HeaderFont")}
              </Text>
            </Col>
            <Col span={12}>
              <FontTypeSelector
                placeholder={i18n.t("Dashboard.Configuration.Fields.FontStyle")}
                onChange={this.headerFontOnChange}
                value={activeState.headerFont}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px",
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Size")}
              </Text>
            </Col>
            <Col span={12}>
              <Input
                id={"headerTextSize"}
                value={activeState.headerTextSize == undefined ? 16 : activeState.headerTextSize}
                onChange={this.handleChangeForMeasure}
              />
            </Col>
          </Row>
          <Row>
            <Col span={16}>
              <Divider className={"config-divider"} orientation="left">
                {i18n.t("Dashboard.Configuration.Fields.HeaderDescriptionStyling")}
              </Divider>
            </Col>
            {/* <Col span={5}>
              <Tooltip title={i18n.t("Dashboard.Configuration.Fields.SetCompareArea")}>
                <Button
                  id="returnDefaultButton"
                  className={"general-button"}
                  onClick={() => this.setSettingsAnotherArea("headerDescription", "measureCompare")}
                  style={{
                    fontSize: "13px",
                    height: "24px",
                    width: "145px",
                    backgroundColor: "#62b779",
                    marginTop: "25px",
                    marginBottom: "25px",
                  }}
                >
                  <span className="three-dot-button">{i18n.t("Dashboard.Configuration.Fields.SetCompareArea")}</span>
                </Button>
              </Tooltip>
            </Col> */}

            <Col span={7} style={{ marginLeft: "17px" }}>
              <Tooltip title={i18n.t("Dashboard.Configuration.Fields.SetAllHeaderDescriptionArea")}>
                <Button
                  id="returnDefaultButton"
                  className={"general-button"}
                  onClick={() => this.setSettingsAnotherArea("headerDescription", "", "all-area")}
                  style={{
                    fontSize: "13px",
                    height: "24px",
                    width: "100%",
                    backgroundColor: "#62b779",
                    marginTop: "25px",
                    marginBottom: "25px",
                  }}
                >
                  <span className="three-dot-button" style={{ width: "100%" }}>{i18n.t("Dashboard.Configuration.Fields.SetAllHeaderDescriptionArea")}</span>
                </Button>
              </Tooltip>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <ColumnWidthOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px",
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.HeaderFontDescription")}
              </Text>
            </Col>
            <Col span={12}>
              <Input
                id={"headerFontDescription"}
                defaultValue={activeState.headerFontDescription}
                value={activeState.headerFontDescription}
                onChange={this.handleChangeForMeasure}
              />
            </Col>
          </Row>
          {/* <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.HeaderFontDescriptionPosition")}
              </Text>
            </Col>
            <Col span={12}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder={i18n.t("Dashboard.Configuration.Fields.HeaderFontDescriptionPosition")}
                optionFilterProp="children"
                onChange={this.headerFontPositionOnChange}
                defaultValue={activeState.headerFontDescriptionPosition}
                value={activeState.headerFontDescriptionPosition}
              >
                <Option value="right">{i18n.t("Dashboard.Configuration.Fields.Right")}</Option>
                <Option value="bottom">{i18n.t("Dashboard.Configuration.Fields.Bottom")}</Option>
              </Select>
            </Col>
          </Row> */}
          <Row style={{ marginBottom: "5px" }}>
            <Col
              style={{ textAlign: "center" }}
              span={this.props.titleIcon ? this.props.titleIcon : 3}
            >
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col
              style={{ textAlign: "left" }}
              span={this.props.titleColumn ? this.props.titleColumn : 9}
            >
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.HeaderDescriptionFontStyle")}
              </Text>
            </Col>
            <Col style={{ textAlign: "left" }} span={12}>
              {this.state.headerDescriptionFontWeight !== undefined ? (
                <span>
                  <Button
                    onClick={this.handleChangeButtonForMeasure}
                    id={"headerDescriptionFontWeight"}
                    style={{
                      backgroundColor: activeState.headerDescriptionFontWeight ? "#9ed3ff" : "initial",
                      color: "black",
                      borderColor: "lightgrey",
                    }}
                  >
                    <BoldOutlined
                      style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                    />
                  </Button>
                </span>
              ) : null}

              {this.state.headerDescriptionFontStyle !== undefined ? (
                <span>
                  <Button
                    onClick={this.handleChangeButtonForMeasure}
                    id={"headerDescriptionFontStyle"}
                    style={{
                      marginLeft: "20px",
                      backgroundColor: activeState.headerDescriptionFontStyle ? "#9ed3ff" : "initial",
                      color: "black",
                      borderColor: "lightgrey",
                    }}
                  >
                    <ItalicOutlined
                      style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                    />
                  </Button>
                </span>
              ) : null}

              {this.state.headerDescriptionTextDecor !== undefined ? (
                <span>
                  <Button
                    onClick={this.handleChangeButtonForMeasure}
                    id={"headerDescriptionTextDecor"}
                    style={{
                      marginLeft: "20px",
                      backgroundColor: activeState.headerDescriptionTextDecor ? "#9ed3ff" : "initial",
                      color: "black",
                      borderColor: "lightgrey",
                    }}
                  >
                    <UnderlineOutlined
                      style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                    />
                  </Button>
                </span>
              ) : null}
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <NumberOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px",
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.HeaderDescriptionFont")}
              </Text>
            </Col>
            <Col span={12}>
              <FontTypeSelector
                placeholder={i18n.t("Dashboard.Configuration.Fields.FontStyle")}
                onChange={this.headerDescriptionFontOnChange}
                value={activeState.headerDescriptionFont}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px",
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Size")}
              </Text>
            </Col>
            <Col span={12}>
              <Input
                id={"headerDescriptionTextSize"}
                value={activeState.headerDescriptionTextSize == undefined ? 11 : activeState.headerDescriptionTextSize}
                onChange={this.handleChangeForMeasure}
              />
            </Col>
          </Row>
          <Row>
            <Col span={13}>
              <Divider className={"config-divider"} orientation="left">
                {i18n.t("Dashboard.Configuration.Fields.MeasureStyling")}
              </Divider>
            </Col>
            <Col span={5}>
              <Tooltip title={i18n.t("Dashboard.Configuration.Fields.SetHeaderArea")}>
                <Button
                  id="returnDefaultButton"
                  className={"general-button"}
                  onClick={() => this.setSettingsAnotherArea("measure", "header")}
                  style={{
                    fontSize: "13px",
                    height: "24px",
                    width: "145px",
                    backgroundColor: "#62b779",
                    marginTop: "25px",
                    marginBottom: "25px",
                  }}
                >
                  <span className="three-dot-button">{i18n.t("Dashboard.Configuration.Fields.SetHeaderArea")}</span>
                </Button>
              </Tooltip>
            </Col>

            <Col span={5} style={{ marginLeft: "17px" }}>
              <Tooltip title={i18n.t("Dashboard.Configuration.Fields.SetAllMeasureArea")}>
                <Button
                  id="returnDefaultButton"
                  className={"general-button"}
                  onClick={() => this.setSettingsAnotherArea("measure", "", "all-area")}
                  style={{
                    fontSize: "13px",
                    height: "24px",
                    width: "145px",
                    backgroundColor: "#62b779",
                    marginTop: "25px",
                    marginBottom: "25px",
                  }}
                >
                  <span className="three-dot-button">{i18n.t("Dashboard.Configuration.Fields.SetAllMeasureArea")}</span>
                </Button>
              </Tooltip>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.MeasurePosition")}
              </Text>
            </Col>
            <Col span={12}>
              <Select
                showSearch
                style={{ width: "100%" }}
                getPopupContainer={(trigger) => trigger.parentNode}
                placeholder={i18n.t("Dashboard.Configuration.Fields.HeaderFontDescriptionPosition")}
                optionFilterProp="children"
                onChange={this.measurePositionOnChange}
                defaultValue={activeState.measurePosition}
                value={activeState.measurePosition}
              >
                <Option value="left">{i18n.t("Dashboard.Configuration.Fields.Left")}</Option>
                <Option value="center">{i18n.t("Dashboard.Configuration.Fields.Center")}</Option>
                <Option value="right">{i18n.t("Dashboard.Configuration.Fields.Right")}</Option>
              </Select>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col
              style={{ textAlign: "center" }}
              span={this.props.titleIcon ? this.props.titleIcon : 3}
            >
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col
              style={{ textAlign: "left" }}
              span={this.props.titleColumn ? this.props.titleColumn : 9}
            >
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.MeasureFontStyle")}
              </Text>
            </Col>
            <Col style={{ textAlign: "left" }} span={12}>
              {this.state.measureFontWeight !== undefined ? (
                <span>
                  <Button
                    onClick={this.handleChangeButtonForMeasure}
                    id={"measureFontWeight"}
                    style={{
                      backgroundColor: activeState.measureFontWeight ? "#9ed3ff" : "initial",
                      color: "black",
                      borderColor: "lightgrey",
                    }}
                  >
                    <BoldOutlined
                      style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                    />
                  </Button>
                </span>
              ) : null}

              {this.state.measureFontStyle !== undefined ? (
                <span>
                  <Button
                    onClick={this.handleChangeButtonForMeasure}
                    id={"measureFontStyle"}
                    style={{
                      marginLeft: "20px",
                      backgroundColor: activeState.measureFontStyle ? "#9ed3ff" : "initial",
                      color: "black",
                      borderColor: "lightgrey",
                    }}
                  >
                    <ItalicOutlined
                      style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                    />
                  </Button>
                </span>
              ) : null}

              {this.state.measureTextDecor !== undefined ? (
                <span>
                  <Button
                    onClick={this.handleChangeButtonForMeasure}
                    id={"measureTextDecor"}
                    style={{
                      marginLeft: "20px",
                      backgroundColor: activeState.measureTextDecor ? "#9ed3ff" : "initial",
                      color: "black",
                      borderColor: "lightgrey",
                    }}
                  >
                    <UnderlineOutlined
                      style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                    />
                  </Button>
                </span>
              ) : null}
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <NumberOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px",
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.MeasureFontType")}
              </Text>
            </Col>
            <Col span={12}>
              <FontTypeSelector
                placeholder={i18n.t("Dashboard.Configuration.Fields.FontStyle")}
                onChange={this.measureFontOnChange}
                value={activeState.measureFont}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px",
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Size")}
              </Text>
            </Col>
            <Col span={12}>
              <Input
                id={"measureTextSize"}
                value={activeState.measureTextSize == undefined ? 22 : activeState.measureTextSize}
                onChange={this.handleChangeForMeasure}
              />
            </Col>
          </Row>
          {/* <Row>
            <Col span={13}>
              <Divider className={"config-divider"} orientation="left">
                {i18n.t("Dashboard.Configuration.Fields.MeasureCompareStyling")}
              </Divider>
            </Col>
            <Col span={5}>
              <Tooltip title={i18n.t("Dashboard.Configuration.Fields.SetCompareArea")}>
                <Button
                  id="returnDefaultButton"
                  className={"general-button"}
                  onClick={() => this.setSettingsAnotherArea("measureCompare", "headerDescription")}
                  style={{
                    fontSize: "13px",
                    height: "24px",
                    width: "145px",
                    backgroundColor: "#62b779",
                    marginTop: "25px",
                    marginBottom: "25px",
                  }}
                >
                  <span className="three-dot-button">{i18n.t("Dashboard.Configuration.Fields.SetCompareArea")}</span>
                </Button>
              </Tooltip>
            </Col>

            <Col span={5} style={{ marginLeft: "17px" }}>
              <Tooltip title={i18n.t("Dashboard.Configuration.Fields.SetAllCompareArea")}>
                <Button
                  id="returnDefaultButton"
                  className={"general-button"}
                  onClick={() => this.setSettingsAnotherArea("measureCompare", "", "all-area")}
                  style={{
                    fontSize: "13px",
                    height: "24px",
                    width: "145px",
                    backgroundColor: "#62b779",
                    marginTop: "25px",
                    marginBottom: "25px",
                  }}
                >
                  <span className="three-dot-button">{i18n.t("Dashboard.Configuration.Fields.SetAllCompareArea")}</span>
                </Button>
              </Tooltip>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.MeasurePosition")}
              </Text>
            </Col>
            <Col span={12}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder={i18n.t("Dashboard.Configuration.Fields.HeaderFontDescriptionPosition")}
                optionFilterProp="children"
                onChange={this.measureComparePositionOnChange}
                defaultValue={activeState.measureComparePosition}
              >
                <Option value="left">{i18n.t("Dashboard.Configuration.Fields.Left")}</Option>
                <Option value="center">{i18n.t("Dashboard.Configuration.Fields.Center")}</Option>
                <Option value="right">{i18n.t("Dashboard.Configuration.Fields.Right")}</Option>
              </Select>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col
              style={{ textAlign: "center" }}
              span={this.props.titleIcon ? this.props.titleIcon : 3}
            >
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col
              style={{ textAlign: "left" }}
              span={this.props.titleColumn ? this.props.titleColumn : 9}
            >
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.MeasureCompareFontStyle")}
              </Text>
            </Col>
            <Col style={{ textAlign: "left" }} span={12}>
              {this.state.measureCompareFontWeight !== undefined ? (
                <span>
                  <Button
                    onClick={this.handleChangeButtonForMeasure}
                    id={"measureCompareFontWeight"}
                    style={{
                      backgroundColor: activeState.measureCompareFontWeight ? "#9ed3ff" : "initial",
                      color: "black",
                      borderColor: "lightgrey",
                    }}
                  >
                    <BoldOutlined
                      style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                    />
                  </Button>
                </span>
              ) : null}

              {this.state.measureCompareFontStyle !== undefined ? (
                <span>
                  <Button
                    onClick={this.handleChangeButtonForMeasure}
                    id={"measureCompareFontStyle"}
                    style={{
                      marginLeft: "20px",
                      backgroundColor: activeState.measureCompareFontStyle ? "#9ed3ff" : "initial",
                      color: "black",
                      borderColor: "lightgrey",
                    }}
                  >
                    <ItalicOutlined
                      style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                    />
                  </Button>
                </span>
              ) : null}

              {this.state.measureCompareTextDecor !== undefined ? (
                <span>
                  <Button
                    onClick={this.handleChangeButtonForMeasure}
                    id={"measureCompareTextDecor"}
                    style={{
                      marginLeft: "20px",
                      backgroundColor: activeState.measureCompareTextDecor ? "#9ed3ff" : "initial",
                      color: "black",
                      borderColor: "lightgrey",
                    }}
                  >
                    <UnderlineOutlined
                      style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                    />
                  </Button>
                </span>
              ) : null}
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <NumberOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px",
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.MeasureCompareFont")}
              </Text>
            </Col>
            <Col span={12}>
              <FontTypeSelector
                placeholder={i18n.t("Dashboard.Configuration.Fields.FontStyle")}
                onChange={this.measureCompareFontOnChange}
                value={activeState.measureCompareFont}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px",
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Size")}
              </Text>
            </Col>
            <Col span={12}>
              <Input
                id={"measureCompareTextSize"}
                value={activeState.measureCompareTextSize == undefined ? 11 : activeState.measureCompareTextSize}
                onChange={this.handleChangeForMeasure}
              />
            </Col>
          </Row> */}

          <Row>
            <Col span={13}>
              <Divider className={"config-divider"} orientation="left">
                {i18n.t("Dashboard.Configuration.Fields.AddVisual")}
              </Divider>
            </Col>
            <Col span={5}>
              <Tooltip title={i18n.t("Dashboard.Configuration.Fields.SetAllIconsToOther")}>
                <Button
                  id="returnDefaultButton"
                  className={"general-button"}
                  onClick={() => this.setSettingsAnotherArea("icon", "", "all-area")}
                  style={{
                    fontSize: "13px",
                    height: "24px",
                    width: "145px",
                    backgroundColor: "#62b779",
                    marginTop: "25px",
                    marginBottom: "25px",
                  }}
                >
                  <span className="three-dot-button">{i18n.t("Dashboard.Configuration.Fields.SetAllIconsToOther")}</span>
                </Button>
              </Tooltip>
            </Col>

            <Col span={5} style={{ marginLeft: "17px" }}>
              <Tooltip title={i18n.t("Dashboard.Configuration.Fields.SetAllIconSizeToOther")}>
                <Button
                  id="returnDefaultButton"
                  className={"general-button"}
                  onClick={() => this.setSettingsAnotherArea("iconSize", "", "all-area")}
                  style={{
                    fontSize: "13px",
                    height: "24px",
                    width: "145px",
                    backgroundColor: "#62b779",
                    marginTop: "25px",
                    marginBottom: "25px",
                  }}
                >
                  <span className="three-dot-button">{i18n.t("Dashboard.Configuration.Fields.SetAllIconSizeToOther")}</span>
                </Button>
              </Tooltip>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <CrownOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px",
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Icon")}
              </Text>
            </Col>
            <Col span={isIconExist ? 9 : 12}>
              <Button
                onClick={this.selectIconChangeVisible}
                style={{ width: "100%" }}
              >
                {this.state.iconSelector
                  ? i18n.t("Dashboard.Configuration.Fields.CloseIconSelector")
                  : i18n.t("Dashboard.Configuration.Fields.OpenIconSelector")}
              </Button>
            </Col>
            {isIconExist ? (
              <>
                <Col span={2}>
                  <i className={`${activeIcon} fa-2x`}></i>
                </Col>
                <Col span={1} style={{ marginTop: "7px" }}>
                  <a
                    style={{
                      color: "red",
                      position: "absolute",
                      right: "15px",
                      top: "-2px",
                    }}
                    onClick={this.removeIcon}
                  >
                    x
                  </a>
                </Col>
              </>
            ) : null}
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <ColumnWidthOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px",
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.IconSize")}
              </Text>
            </Col>
            <Col span={12}>
              <Input
                id={"iconSize"}
                defaultValue={activeState.iconSize}
                value={activeState.iconSize}
                onChange={this.handleChangeForMeasure}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FileImageOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px",
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.IsImage")}
                <Tooltip
                  overlayClassName={"table-plugin-links"}
                  title={i18n.t("Dashboard.Configuration.Fields.ImageAndIconsCantSeemSameTime")}
                >
                  <span
                    className={"table-plugin-link-info"}
                  >
                    (?)
                  </span>
                </Tooltip>
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"isImage"}
                defaultChecked={activeState.isImage}
                checked={activeState.isImage}
                onChange={this.handleCheckButtonForMeasure}
              ></Checkbox>
            </Col>
          </Row>
          {activeState.isImage ? (
            <>
              <Row style={{ marginBottom: "5px" }}>
                <Row style={{ marginBottom: "5px" }}>
                  <Col style={{ textAlign: "center" }} span={3}>
                    <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
                  </Col>
                  <Col style={{ textAlign: "left" }} span={9}>
                    <Text
                      style={{
                        fontSize: "16px",
                        display: "inline-block",
                        marginTop: "2px",
                      }}
                    >
                      {i18n.t("Dashboard.Configuration.Fields.ImageUpload")}
                    </Text>
                  </Col>
                  <Col span={12} style={{ textAlign: "left" }}>
                    <label className={"upload-button"}>
                      {" "}
                      {i18n.t("Dashboard.Configuration.Fields.Browse")}
                      <input
                        type="file"
                        id={"imageUpload"}
                        className={"image-upload"}
                        onChange={(e) => this.onChangeFile(e)}
                      ></input>
                    </label>
                    {activeState.imageName && activeState.imageName === "" ? (
                      i18n.t("Dashboard.Configuration.Fields.NoFileSelected")
                    ) : (
                      <span title={activeState.imageName} className={"upload-image-name"}>
                        {activeState.imageName}
                      </span>
                    )}
                  </Col>
                </Row>

                {!activeState.imagePath || activeState.imagePath === "" ? null : (
                  <Row>
                    <Col
                      span={12}
                      style={{
                        border: "1px solid #e6e6e6",
                        borderRadius: "5px",
                      }}
                    >
                      <img
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100px",
                        }}
                        src={activeState.imagePath}
                      />
                      { }
                    </Col>
                    <Col span={12}>Url: {activeState.imagePath}</Col>
                  </Row>
                )}

                <Col style={{ textAlign: "center" }} span={3}>
                  <FileImageOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col style={{ textAlign: "left" }} span={9}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px",
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.ImageSource")}
                  </Text>
                </Col>
                <Col span={12}>
                  <Input
                    id={"imgSrc"}
                    defaultValue={activeState.imgSrc}
                    value={activeState.imgSrc}
                    onChange={this.handleChangeForMeasure}
                  />
                </Col>
              </Row>
            </>
          ) : null}
          <TitleSettingsOfPlugin
            plugin={this.props.plugin}
            handleChange={this.handleChange}
            commonTitleConfig={this.props.commonTitleConfig}
            updateCommonTitleConfig={this.props.updateCommonTitleConfig}
            isReturnToDefaultforTitleVisible={this.props.isReturnToDefaultforTitleVisible}
            setDefaultsForTitle={this.setDefaultsForTitle}
            title={this.state.title}
            titleAlign={this.state.titleAlign}
            titleColour={this.state.titleColour}
            titleColourChange={this.state.titleColourChange}
            titleColourChangeVisible={this.titleColourChangeVisible}
            titleFont={this.state.titleFont}
            titleFontSize={this.state.titleFontSize}
            keyForTitleSize={this.state.keyForTitleSize}
            titleFontStyle={this.state.titleFontStyle}
            titleFontWeight={this.state.titleFontWeight}
            titleTextDecor={this.state.titleTextDecor}
            setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
            currentAppliedConfig={this.props.currentAppliedConfig}
            changedTitleFontSize={this.state.changedTitleFontSize}
            handleChangeBothOfValues={this.handleChangeBothOfValues}
          />
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Configuration.Fields.GeneralAppearance")}
          </Divider>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px",
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.BackgroundColor")}
              </Text>
            </Col>
            <Col span={12}>
              <Button
                onClick={this.backgroundColorChangeVisible}
                style={{ width: "100%" }}
              >
                {this.state.backgroundColorChange
                  ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                  : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
              </Button>
              {this.state.backgroundColorChange ? (
                <ColorPickerPanel
                  alpha={100}
                  color={this.state.backgroundColor}
                  onChange={this.backgroundColorOnChange}
                  id={"backgroundColor"}
                  style={{ marginTop: "5px", position: "absolute", right: 0 }}
                />
              ) : null}
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FieldTimeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.AnimationDuration")}
              </Text>
            </Col>
            <Col span={12}>
              <InputNumber
                id={"duration"}
                defaultValue={this.state.duration}
                value={this.state.duration}
                style={{ width: "100%" }}
                min={0}
                step={0.1}
                formatter={value => `${value}`.replace(/[^0-9.]/g, '')}
                parser={value => `${value}`.replace(/[^0-9.]/g, '')}
                onChange={(value) => this.handleChangeDuration(value)}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.ShowConditionalFormatting")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"condFormat"}
                defaultChecked={this.state.condFormat}
                checked={this.state.condFormat}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Configuration.Fields.Others")}
          </Divider>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left", display: "flex" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Settings.NoDataConfiguration")}
              </Text>
              <Tooltip
                title={
                  <>
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Info")} <br />
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.GraphicConfiguration")} <br />
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Settings")} <br />
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.DefaultText")} <br />
                  </>
                }
              >
                <InfoCircleOutlined style={{ position: 'relative', marginTop: '5px', marginLeft: "10px" }} />
              </Tooltip>
            </Col>
            <Col span={12}>
              <TextArea
                allowClear
                id={"noDataTitle"}
                value={activeState.noDataTitle}
                onChange={this.handleChangeForNoDataMeasure}
                autoSize={{ minRows: 3, maxRows: 5 }}
                placeholder={i18n.t("NoDataContent.NoData") + " " +
                  i18n.t("NoDataContent.PluginCouldNotBeVisualized")}
              />
            </Col>
          </Row>

          <MeasureModal
            iconSelector={this.state.iconSelector}
            selectIconChangeVisible={this.selectIconChangeVisible}
            pluginId={this.props.pluginId}
            icon={activeState.icon}
            iconOnChange={this.iconOnChange}
            searchInputOnChange={this.searchInputOnChange}
            search={this.state.search}
          />
        </div> : null}
      </>
    );
  }
}
