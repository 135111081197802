import { CHANGE_PLUGIN_LIST_VISIBILITY } from "./OpenPluginListAction";

export default function OpenPluginListReducer(
  state = {
    pluginListVisibility: false,
  },
  action
) {
  switch (action.type) {
    case CHANGE_PLUGIN_LIST_VISIBILITY:
      return {
        ...state,
        pluginListVisibility: !state.pluginListVisibility,
      };
    default:
      return state;
  }
}
