import React, { Component } from "react";
import DashboardChangeButton from "./DashboardChangeButton";
import { get } from "../../../Utils/WebService";
import { API_BASE } from "../../../config.js";
import Text from "../../GeneralComponents/Text/Text";
import i18n from "../../../Utils/i18next";
import Search from "../../GeneralComponents/Search/Search";
import { DashboardOutlined } from "@ant-design/icons";
import Select, { components } from "react-select";
import $ from "jquery";
import { Row, Col } from "antd";
import { deepCopy } from "../../../Utils/Global";
import Tooltip from "../../GeneralComponents/Tooltip/Tooltip";
import _ from "lodash";

const dashboardListOptions = [
  { value: "A_Z", label: i18n.t("LandingPage.DashboardLabel.NameAscending"), icon: [{icon: "fa fa-sort-alpha-down"}] },
  { value: "Z_A", label: i18n.t("LandingPage.DashboardLabel.NameDescending"), icon: [{icon: "fa fa-sort-alpha-down-alt"}] },
  { value: "lastUpdatedDate_desc", label: i18n.t("LandingPage.DashboardLabel.NewestUpdated"), icon: [{icon: "fa fa-long-arrow-alt-down"}, {icon: "fa fa-edit"}], active: false },
  { value: "lastUpdatedDate_asc", label: i18n.t("LandingPage.DashboardLabel.OldestUpdated"), icon: [{icon: "fa fa-long-arrow-alt-up"}, {icon: "fa fa-edit"}], active: false }
];

/**
 * Dashboard list on switch dashboard area
 */
export default class DashboardList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardList: [],
      searchedDashboardList: [],
      dashboardListAscDes: { value: "A_Z", label: i18n.t("LandingPage.DashboardLabel.NameAscending"), icon: [{icon: "fa fa-sort-alpha-down"}] },
      orjinalDashboardList: [],
      searchText: ''
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.selectedLabel, this.props.selectedLabel)) {
      if (nextProps.selectedLabel.value) {
        this.getDashboardListByLabel(nextProps.selectedLabel.value);
      }
    } else if (nextProps.isDashboardSave === true) {
      if (nextProps.selectedLabel.value) {
        this.getDashboardListByLabel(nextProps.selectedLabel.value);
      }
    }
  }

  /**
   * Fill dashboards to switch dashboard area by label id
   */
  getDashboardListByLabel = selectedLabel => {
    let url =
      `${API_BASE}/dashboard/dashboardSummary/?labelId=` + selectedLabel;

    const successFunc = response => {
      let data = [];
      let orjinalData = []; 
      if (response.data) {
        data = response.data.filter(dashboard => dashboard.type !== "report")
      }
      
      orjinalData = deepCopy(data);
      
      if (this.state.dashboardListAscDes.value === "A_Z") {
        data = data.sort(function(a, b){return a.name.localeCompare(b.name)});
      } else if (this.state.dashboardListAscDes.value === "Z_A") {
        data = data.sort(function(a, b){return a.name.localeCompare(b.name)}).reverse();
      } else if (this.state.dashboardListAscDes.value === "lastUpdatedDate_desc") {
        data = data.reverse();
      }

      this.setState({
        ...this.state,
        dashboardList: data,
        searchedDashboardList: data,
        orjinalDashboardList: orjinalData
      });
    };

    get(url, successFunc);
  };

  /**
   * Dashboard search
   */
  search = e => {
    let copySearchedDashboardList = [...this.state.searchedDashboardList];

    copySearchedDashboardList = this.state.dashboardList.filter(dashboard =>
      dashboard.name.toLowerCase().includes(e.target.value.toLowerCase())
    );

    this.setState({
      ...this.state,
      searchedDashboardList: copySearchedDashboardList,
      searchText: e.target.value
    });
  };

  /**
   * Dashoard order on change
   */
  dashboardListAscDescOnChange = e => {
    let copyOrjinalDashboardList = [...this.state.orjinalDashboardList];
    let dashboardList = deepCopy(copyOrjinalDashboardList);

    if (e.value === "A_Z") {
      dashboardList = dashboardList.sort(function(a, b){return a.name.localeCompare(b.name)});
    } else if (e.value === "Z_A") {
      dashboardList = dashboardList.sort(function(a, b){return a.name.localeCompare(b.name)}).reverse();
    } else if (e.value === "lastUpdatedDate_desc") {
      dashboardList = dashboardList.reverse();
    }

    let searchedDashboardList = deepCopy(dashboardList);

    if (['', null, undefined].includes(this.state.searchText) === false) {
      searchedDashboardList = searchedDashboardList.filter(dashboard =>
        dashboard.name.toLowerCase().includes(this.state.searchText.toLowerCase())
      );

      this.setState({
        ...this.state,
        dashboardListAscDes: e,
        searchedDashboardList: searchedDashboardList
      });
    }
    else {
      this.setState({
        ...this.state,
        dashboardListAscDes: e,
        dashboardList: dashboardList
      });
    }
  }

  render() {
    const textColor = this.props.textColor || "#d2d2d2";
    const backgroundColor = this.props.backgroundColor || "#3a4044";
    const borderColor = this.props.textColor ? this.props.textColor + "55" : "#d2d2d255";

    const colourStyles = {
      control: styles => ({
        ...styles,
        backgroundColor: backgroundColor,
        color: textColor,
        borderColor: borderColor,
        boxShadow: "none",
        ":hover": {
          borderColor: borderColor,
          boxShadow: "null"
        },
        ":active": {
          borderColor: borderColor,
          boxShadow: "null"
        },
        ":focus": {
          borderColor: borderColor,
          boxShadow: "null"
        }
      }),
      singleValue: styles => {
        return {
          ...styles,
          backgroundColor: backgroundColor,
          color: textColor,
          borderColor: borderColor,
          ":hover": {
            borderColor: borderColor
          },
          ":active": {
            borderColor: borderColor
          },
          ":focus": {
            borderColor: borderColor
          }
        };
      },
      menu: styles => {
        return {
          ...styles,
          backgroundColor: backgroundColor,
          color: textColor,
          width: "196px",
          left: "-123px",
          top: "26px",
          ":hover": {
            borderColor: borderColor
          },
          ":active": {
            borderColor: borderColor
          },
          ":focus": {
            borderColor: borderColor
          }
        };
      },
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          backgroundColor: isDisabled
            ? null
            : isSelected
              ? backgroundColor
              : isFocused,
          color: isDisabled
            ? textColor
            : isSelected
              ? textColor
              : isFocused,
          ":hover": {
            backgroundColor: textColor + "11",
            color: textColor
          },
          ":active": {
            backgroundColor: textColor + "11",
            color: textColor
          }
        };
      },
      dropdownIndicator: (styles) => {
        return {
          ...styles,
          paddingLeft: "0px",
          color: textColor,
          ":hover": {
            color: textColor
          },
          ":active": {
            color: textColor
          },
          ":focus": {
            color: textColor
          }
        }
      },
      indicatorSeparator: (styles) => {
        return {
          ...styles,
          backgroundColor: borderColor,
        }
      },
      valueContainer: (styles) => ({
        ...styles,
        paddingRight: '0px',
        color: textColor,
      }),
    };

    const Option = (props) => (
      <components.Option {...props} className="country-option" style={{width:"150px"}}>
        {props.data.icon.map((d) => {
          return <i className={d.icon} style={{fontSize: "13px", marginLeft: "1px"}}></i>
        })}
        {" " + props.data.label}
      </components.Option>
    );

    const SingleValue = ({ children, ...props }) => (
      <components.SingleValue {...props}>
        {props.data.icon.map((d) => {
          return <Tooltip tooltip={this.state.dashboardListAscDes.label} tooltipPlacement="top">
            <i className={d.icon} style={props.data.icon.length === 1 
              ? {fontSize: "16px", marginLeft: "1px"} 
              : {fontSize: "13px", marginLeft: "1px"}}>
            </i>
          </Tooltip>
        })}
      </components.SingleValue>
    );

    let dashboardListSize = this.state.dashboardList.length;
    let searchInputStyle = {
      color: textColor,
      border: "1px solid " + borderColor,
      borderRadius: "4px",
      boxShadow: "none",
      minWidth: "auto"
    };

    //If dashboard list is not empty, show list in switcher area.
    if (dashboardListSize > 0) {
      return (
        <div className="switch-dashboard-list">
          <div className="switch-dashboard-tools">
            <Row>
              <Col span={15}>
                <DashboardOutlined className="subtitle-icon" style={{left:"2px"}} />
                <Text className="switch-dashboard-label-list-title">
                  {i18n.t("Dashboard.DashboardList")}
                </Text>
              </Col>
                <Col span={9} style={{marginTop:"-7px"}}>
                  <Select
                    id={"dashboardListAscDes"}
                    isSearchable={false}
                    value={this.state.dashboardListAscDes}
                    onChange={this.dashboardListAscDescOnChange}
                    options={dashboardListOptions}
                    styles={colourStyles}
                    components={{
                      IndicatorSeparator: () => null,
                      Option,
                      SingleValue
                    }}
                    className="switch-dashboard-label-list2"
                  ></Select>
                </Col>        
            </Row>
            <Search
              clearCondition={this.state.dashboardList}
              name={"switchAreaDashboardSearch"}
              disablePlaceHolder={true}
              searchBarVisible={true}
              width="100%"
              onChange={this.search}
              paddingNone={true}
              inputStyle={searchInputStyle}
              iconStyle={{color: textColor}}
            />
          </div>
          <div className="switch-dashboard-items switch-dashboard-scroll">
            {this.state.searchedDashboardList.map(dashboard => (
              <DashboardChangeButton
                currentDashboardId={this.props.currentDashboardId}
                getDashboard={this.props.getDashboard}
                dashboard={dashboard}
                defaultLabelId={this.props.defaultLabelId}
                updateNavigationsAfterSwitchDashboard={this.props.updateNavigationsAfterSwitchDashboard}
                currDashboard={this.props.dashboard}
              />
            ))}
          </div>
        </div>
      );
    }

    return null;
  }
}
