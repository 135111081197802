import { Col, Tooltip } from "antd";
import { Component } from "react";
import React from "react"
import { Row } from "react-bootstrap";
import $ from "jquery"
import { shadeColor } from "../../ButtonFilter/getButtonComponent";
import { getFormattedValue } from "../../format";
import { calculateText } from "../../common";

// Measure Body, includes icons and values
export default class MeasureDefaultBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            count: 0
        };
    }

    // Returns measure style 
    measureStyle = () => {
        let config = this.props.config
        let configObj = {}

        configObj["color"] = config.colour
        configObj["fontSize"] = this.props.pluginH === 1
            ? config && (config.title === "" || config.title == null || config.title == undefined)
                ? "15px"
                : "17px"
            : config.measureTextSize == undefined
                ? "22px"
                : config.measureTextSize + "px"
        configObj["display"] = "inline-block"
        configObj["width"] = "100%"
        configObj["whiteSpace"] = "nowrap"
        configObj["overflow"] = "hidden"
        configObj["textOverflow"] = "ellipsis"
        configObj["cursor"] = "pointer"
        configObj["paddingLeft"] = "5px"
        configObj["paddingRight"] = "5px"

        configObj["fontFamily"] = config.measureFont == undefined ? "Verdana" : config.measureFont
        configObj["textAlign"] = config.measurePosition == undefined ? "left" : config.measurePosition
        configObj["fontWeight"] = config.measureFontWeight == undefined ? "bold" : config.measureFontWeight ? "bold" : ""
        configObj["fontStyle"] = config.measureFontStyle == undefined ? "" : config.measureFontStyle ? "italic" : ""
        configObj["textDecoration"] = config.measureTextDecor == undefined ? "" : config.measureTextDecor ? "underline" : ""

        return configObj
    }

    // Returns icon div 
    iconDivStyle = () => {
        let isPluginHOrWLowerThanThree = this.props.pluginH < 3
            ? true
            : this.props.pluginW < 3
                ? true
                : false

        let config = this.props.config
        let configObj = {}

        configObj["background"] = !isPluginHOrWLowerThanThree && config.isFrameEnable ? config.secondaryColour == undefined ? shadeColor(config.colour === "red" ? "#ff0000" : config.colour, 0, 70) : config.secondaryColour : null
        configObj["borderRadius"] = "50%"
        configObj["width"] = !isPluginHOrWLowerThanThree ? "50%" : "100%"
        configObj["display"] = "flex"
        configObj["alignItems"] = "center"
        configObj["justifyContent"] = "center"

        if (!isPluginHOrWLowerThanThree) {
            configObj["textAlign"] = "center"
            configObj["aspectRatio"] = "1 / 1"
        }


        return configObj
    }

    componentWillReceiveProps(nextProps) {
        let newState = {...this.state};
        let isStateChanged = false;

        if (nextProps.pluginData[0].measureValue !== this.state.count) {
            isStateChanged = true;
            newState.count = nextProps.pluginData[0].measureValue;
        }

        if (this.props.config?.measureHeader !== nextProps.config?.measureHeader) {
            isStateChanged = true;
            newState.header = calculateText(
                this.props.plugin,
                nextProps.config
                    ? (nextProps.config.measureHeader === undefined || nextProps.config.measureHeader === "")
                        ? this.props.pluginData[0]
                            ? this.props.pluginData[0].categoryValue
                            : null
                        : nextProps.config.measureHeader
                    : null
            );
        }

        if (isStateChanged) {
            this.setState(newState);
        }
    }

    componentDidMount() {
        let isMeasureAvailable = this.props.pluginData[0] && this.props.pluginData[0] && !isNaN(+this.props.pluginData[0].measurePureValue) && this.props.pluginData[0].column ? true : false
        let isMeasureAnimationAvailable = this.props.animation && this.props.animation !== 0 && this.props.animation !== ""
        let header = calculateText(
            this.props.plugin,
            this.props.config
                ? (this.props.config.measureHeader === undefined || this.props.config.measureHeader === "")
                    ? this.props.pluginData[0]
                        ? this.props.pluginData[0].categoryValue
                        : null
                    : this.props.config.measureHeader
                : null
        );

        if (isMeasureAvailable && isMeasureAnimationAvailable) {
            let start = 0;
            const end = +this.props.pluginData[0].measurePureValue
    
            let duration = this.props.animation && this.props.animation !== null && this.props.animation !== undefined && this.props.animation !== "" ? +this.props.animation : 1
            let animationCountRate = end / duration

            if (start === end) return;

            let totalMilSecDur = parseInt(duration);
            let incrementTime = (totalMilSecDur / end) * duration;
    
            let timer = setInterval(() => {
                start += animationCountRate;
                let column = Array.isArray(this.props.pluginData[0].column) ? this.props.pluginData[0].column[0] : this.props.pluginData[0].column

                let formattedValue = getFormattedValue(column, +start)
                
                this.setState({ ...this.state, count: formattedValue })

                if (start >= end) {
                    clearInterval(timer)
                    let column = Array.isArray(this.props.pluginData[0].column) ? this.props.pluginData[0].column[0] : this.props.pluginData[0].column
                    let formattedValue = getFormattedValue(column, +this.props.pluginData[0].measurePureValue)

                    this.setState({
                        ...this.state,
                        header: header,
                        count: formattedValue
                    })
                }

            }, incrementTime);
        } else {
            this.setState({
                ...this.state,
                header: header,
                count: this.props.pluginData[0].measureValue
            })
        }
    }

    // Bottom text style
    bottomTextStyle = () => {
        let config = this.props.config

        let configObj = {}

        configObj["color"] = config.secondaryColour == undefined ? shadeColor(config.colour === "red" ? "#ff0000" : config.colour, 0, 70) : config.secondaryColour
        configObj["display"] = "inline-block"
        configObj["maxWidth"] = "100%"
        configObj["whiteSpace"] = "nowrap"
        configObj["overflow"] = "hidden"
        configObj["textOverflow"] = "ellipsis"
        configObj["paddingLeft"] = "5px"
        configObj["paddingRight"] = "5px"

        configObj["fontFamily"] = config.measureCompareFont == undefined ? "Verdana" : config.measureCompareFont
        configObj["textAlign"] = config.measureComparePosition == undefined ? "left" : config.measureComparePosition
        configObj["fontWeight"] = config.measureCompareFontWeight == undefined ? "" : config.measureCompareFontWeight ? "bold" : ""
        configObj["fontStyle"] = config.measureCompareFontStyle == undefined ? "" : config.measureCompareFontStyle ? "italic" : ""
        configObj["textDecoration"] = config.measureCompareTextDecor == undefined ? "" : config.measureCompareTextDecor ? "underline" : ""
        configObj["fontSize"] = config.measureCompareTextSize == undefined ? 11 : config.measureCompareTextSize + "px"

        return configObj
    }

    // Icon's div style
    iconStyle = () => {
        let config = this.props.config

        let configObj = {}

        configObj["fontSize"] = config.iconSize == undefined ? "27px" : config.iconSize + "px"
        configObj["color"] = config.colour
        configObj["display"] = "inline-block"
        configObj["maxWidth"] = "100%"
        configObj["whiteSpace"] = "nowrap"
        configObj["overflow"] = "hidden"
        configObj["textOverflow"] = "ellipsis"

        return configObj
    }

    // General row style
    rowStyle = (isPluginNotSmall) => {
        let config = this.props.config
        let configObj = {}

        configObj["height"] = this.props.pluginH >= 2
            ? (this.props.config.pagination || this.props.config.compare) && this.props.pluginW > 4
                ? ((this.props.pluginHeight * 35 / 100) - 5) + "px"
                : ((this.props.pluginHeight * 35 / 100) - 5) + "px"
            : ((this.props.pluginHeight) - 5) + "px"
        configObj["maxHeight"] = "75px"
        configObj["margin"] = "3px 0px 0px"
        configObj["marginTop"] = isPluginNotSmall ? "3px" : "0px"
        configObj["alignItems"] = "center"
        configObj["alignContent"] = "center"

        return configObj
    }

    // Controls is div image or icon
    returnIsDivImgOrIcon = (isIconMustShow) => {
        let config = this.props.config
        let maxHeight = $("#measure-" + this.props.pluginId).find(".measure-body").height()
        let isPluginHGreaterThanTwo = this.props.pluginH > 2 ? true : false

        if (config.isImage && config.imgSrc && config.imgSrc !== "" && !config.isConfigComesFromConditional) {
            return <Col span={6} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img style={{ maxWidth: "100%", maxHeight: maxHeight }} src={config.imgSrc} alt="text" />
            </Col>
        } else if (config.isImage && config.imagePath && config.imagePath !== "" && !config.isConfigComesFromConditional) {
            return <Col span={6} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img style={{ maxWidth: "100%", maxHeight: maxHeight }} src={config.imagePath} alt="text" />
            </Col>
        }

        return isIconMustShow ?
            <Col span={isPluginHGreaterThanTwo ? 6 : 4} className={"measure-icon-div"} style={{ display: "flex", alignItems: "center", justifyContent: "center", maxHeight: maxHeight }}>
                <div style={this.iconDivStyle()}>
                    <i style={this.iconStyle()} className={config.icon}></i>
                </div>
            </Col> : null
    }

    render() {
        let config = this.props.config

        let isDataNoData = this.props.pluginData[0].isMeasureNoData ? true : false
        let noDataTitle = this.props.pluginData[0].noDataTitle

        let isTitleNotAvailable = config && (config.title === "" || config.title == null || config.title == undefined) ? true : false
        let isPluginHEqualOne = this.props.pluginH === 1 ? true : false
        let isIconExist = config && config.icon !== "" && config.icon
        let isImgAvailable = config && (config.imagePath || config.imgSrc) ? true : false

        let isPluginNotSmall = !(this.props.pluginH === 1 && !isTitleNotAvailable) ? true : false

        let isIconMustShow = isIconExist && isPluginNotSmall ? true : false

        let measureContainerStyle = { marginLeft: "10px", width: "90%" }
        let isPluginHGreaterThanTwo = this.props.pluginH > 2 ? true : false
        let measureDivSpan = isIconMustShow || isImgAvailable
            ? isPluginHGreaterThanTwo
                ? 18
                : isImgAvailable
                    ? 16
                    : 20
            : 24

        if (isPluginNotSmall) {
            measureContainerStyle = { marginLeft: "0", width: "100%" }
        }

        return (
            <Row style={this.rowStyle(isPluginNotSmall)} className="measure-body">
                {this.returnIsDivImgOrIcon(isIconMustShow)}
                <Col onClick={(e) => this.props.triggerNavigation(e, this.props.pluginData[0].categoryValue)} span={measureDivSpan} style={{ display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column" }}>
                    <div style={{ ...measureContainerStyle, display: "flex", flexDirection: "column" }}>
                        <div style={this.measureStyle(isPluginNotSmall)} id={this.props.pluginData[0]?.id}>
                            {!isPluginHEqualOne
                                ? isDataNoData ? noDataTitle : this.state.count
                                : this.state.header
                                    ? <Tooltip placement="top" title={this.state.header}>
                                        {isDataNoData ? noDataTitle : this.state.count}
                                    </Tooltip>
                                    : isDataNoData ? noDataTitle : this.state.count}
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }
}
