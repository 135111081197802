export const AUTO_INTERACTIONS = "AUTO_INTERACTIONS";
export const AUTO_INTERACTION_APPLIED_PLUGIN = "AUTO_INTERACTION_APPLIED_PLUGIN";

/**
 * 
 * @param {*} filterAutoInteractions 
 * @returns 
 * 
 * Sets the auto interaction to filter plugins
 */
export function setAutoInteractions(filterAutoInteractions) {
  return dispatch => {
    dispatch({
      type: AUTO_INTERACTIONS,
      payload: filterAutoInteractions
    });
  };
}

/**
 * Set auto interaction applied plugin
 * @param {*} appliedPluginId 
 */
export function setAutoInteractionAppliedPluginId(appliedPluginId) {
  return dispatch => {
    dispatch({
      type: AUTO_INTERACTION_APPLIED_PLUGIN,
      payload: appliedPluginId
    });
  };
}
