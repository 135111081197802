import { Col, Icon, Row, Tooltip } from "antd";
import "rc-color-picker/assets/index.css";
import { Component, default as React } from 'react';
import i18n from "../../../Utils/i18next";
import { themeFields, themeFieldsTitleSettings } from "../PluginComponents/common";

export default class ReturnToTheme extends Component {
    constructor(props) {
        super(props);
    }

    getTooltipItems = () => {
        let themeKeysArray = this.isReturnToThemeButtonMustShow()

        return <div>
            <span style={{ marginBottom: "5px" }}>{i18n.t("ReturnToThemeDescription")}</span>
            {themeKeysArray.map(key => (
                <>
                    {key !== "paletteColours" ? <div>- {i18n.t(key)}</div> : null}
                </>
            ))}
        </div>
    }

    themeFieldsTitleSettings = new Set([
        "titleFontStyle", 
        "titleFontWeight", 
        "titleTextDecor"
      ])

    /*
    * Checks return to theme button must show
    */
    isReturnToThemeButtonMustShow = () => {
        if (sessionStorage.getItem("theme-dashboard") == undefined) {
            return []
        }

        let preservedConfigFieldsArray = this.props.preservedConfigFieldsArray ? Array.from(this.props.preservedConfigFieldsArray) : []
        let themeFieldsArray = []

        for (let config of preservedConfigFieldsArray) {
            if (config === "showRadarLegends") {
                config = "legend"
            } else if (config === "showConditionalLegends") {
                config = "condFormat"
            }
            
            let hasThemesConfigField = themeFields.has(config) && config !== "paletteColours"

            if (hasThemesConfigField) {
                if (this.themeFieldsTitleSettings.has(config) && themeFieldsArray.filter(themeField => themeField === "titleFontStyle").length === 0) {
                    themeFieldsArray.push("titleFontStyle")
                } else if (!this.themeFieldsTitleSettings.has(config)) {
                    themeFieldsArray.push(config)
                }
            }
        }

        return themeFieldsArray
    }

    render() {
        let themeKeysArray = this.isReturnToThemeButtonMustShow()

        return (
            themeKeysArray.length > 0 ?
                <Row className="set-preserved-button-row" style={{marginBottom: this.props.isPluginMeasure ? "25px" : "0px"}}>
                    <Col span={3} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Tooltip title={this.getTooltipItems()} overlayClassName="reset-config-tooltip">
                            <Icon
                                type="info-circle"
                                style={{ color: "rgba(140, 151, 211, 1)", cursor: "pointer", fontSize: "16px" }}
                            />
                        </Tooltip>
                    </Col>
                    <Col span={9} className="set-preserved-button-row-text">
                        {i18n.t("ReturnConfigToTheme")}
                    </Col>
                    <Col span={7}></Col>
                    <Col span={5}>
                        <button className="set-preserved-button" onClick={this.props.reReturnThemeSettings}>{i18n.t("ReturnToTheme")}</button>
                    </Col>
                </Row>
                : <Row className="set-preserved-button-row"></Row>
        )
    }
}
