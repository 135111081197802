import { SET_GENERAL_LOADING } from "./GeneralLoadingAction";

/**
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns 
 * 
 * Reducer for General Loading content
 */
export default function GeneralLoading(
  state = {
    generalLoaderSet: new Set()
  },
  action
) {
  switch (action.type) {
    case SET_GENERAL_LOADING:
      return {
        ...state,
        generalLoaderSet: action.payload
      };
    default:
      return state;
  }
}
