/**
 * updates the given value of the selected parameter
 */
export const updateParameterValue = (valueObj) => {
    if (typeof valueObj !== 'object') {
        return {
            label: valueObj,
            value: valueObj
        };
    } else if (Object.keys(valueObj).includes("name")){
        valueObj.label = valueObj.name;
        delete valueObj.name;
    }

    return valueObj;
}

/**
 * updates the given parameter values list
 */
export const updateParameterAllValues = (values) => {
    values =  values.map(valueObj => {
        return updateParameterValue(valueObj);
    });

    return values;
}