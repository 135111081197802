import React from "react";
import Search from "../Search/Search";
import Accordion from "../Accordion/Accordion";
import AccordionContent from "../Accordion/AccordionContent";
import EmptyDataContent from "../../Plugins/DataComponents/DataConstantContent/EmptyDataContent";
import PropTypes from "prop-types";
import BoxItem from "../BoxItem/BoxItem";
import { store } from "../../..";
import Text from "../Text/Text";
import i18n from "../../../Utils/i18next";
import { sortByLanguage } from "../../../Utils/SortByLanguage";
import { deepCopy } from "../../../Utils/Global";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { setNewColumnEditorContent } from "./NewColumnEditorAction";

/**
 * This components consists of Tables & Columns list and search bar
 */
export default class NewColumnDataTableList extends React.Component {
    constructor(props) {
        super(props);

        this.prototypes = {
            model: PropTypes.object,
        };

        this.state = {
            searchTempData: this.props.model.tables,
            searchCategory: {},
        };
    }

    componentWillReceiveProps(nextProps) {
        let newState = { ...this.state }

        if (this.props.model.tables !== nextProps.model.tables) {
            newState.searchTempData = nextProps.model.tables
        }

        if (newState !== this.state) {
            this.setState(newState)
        }
    }

    /**
     * Table search
     * @param tempTables tables for search
     * @param e event, value in input
     */
    tableSearch = (tempTables, e) => {
        let data = deepCopy(tempTables);

        return data.filter(table => {
            let displayName = table.displayName.toLowerCase().includes(e.target.value.toLowerCase());
            let name = table.name.toLowerCase().includes(e.target.value.toLowerCase());

            return displayName || name;
        });
    };

    /**
     * Column search
     * @param tempTables tables for search
     * @param e event, value in input
     */
    columnSearch = (tempTables, e) => {
        let data = deepCopy(tempTables);

        return data.filter(table => {
            table.columns = table.columns.filter(column => {
                let displayName = column.displayName.toLowerCase().includes(e.target.value.toLowerCase());
                let name = column.name.toLowerCase().includes(e.target.value.toLowerCase());

                return displayName || name;
            });

            return table.columns.length !== 0;
        });
    };

    /**
     * 
     * @param {*} e
     * 
     * Search bar change event
     */
    handleSearch = e => {
        let tempTables = deepCopy(this.props.model.tables);
        let searchedTables = deepCopy(this.props.model.tables);

        if (e.target.value && e.target.value !== "") {
            //search category is empty
            if (this.state.searchCategory.name === undefined) {
                //first check table display names
                searchedTables = this.tableSearch(tempTables, e);

                //if data is empty, second check column display names
                if (searchedTables.length === 0) {
                    tempTables = deepCopy(this.props.model.tables);
                    searchedTables = this.columnSearch(tempTables, e);
                }
            } else if (this.state.searchCategory.name === "Table") {
                //search category is table
                searchedTables = this.tableSearch(tempTables, e);
            } else if (this.state.searchCategory.name === "Column") {
                //search category is data
                searchedTables = this.columnSearch(tempTables, e);
            }
        }

        this.setState({
            ...this.state,
            searchTempData: searchedTables
        });
    };

    /**
     * 
     * @param {*} item 
     * 
     * Sets selected search category
     */
    searchCategory = item => {
        this.setState({
            searchCategory: { ...item }
        });
    };

    /**
     * 
     * @param {*} event 
     * @returns 
     * 
     * Start dragging event
     */
    onDragStart = (event, data) => {
        let dragImage = event.target;
        let collapseIcon = event.target.querySelector(".collapse-icon");
        let extra = event.target.querySelector(".extra");
        let dropArea = document.getElementById("formula-editor-drop-area");

        dropArea.style.border = "dashed 2px #b7b7b7";
        dragImage.style.opacity = '0.4';

        if (collapseIcon) collapseIcon.style.visibility = "hidden";
        if (extra) extra.style.visibility = "hidden";

        event.dataTransfer.setData("text/plain", data);
        event.dataTransfer.setDragImage(dragImage, 0, 0);
    }

    /**
     * 
     * @param {*} event 
     * 
     * End dragging event
     */
    onDragEnd = (event) => {
        let dragImage = event.target;
        let collapseIcon = dragImage.querySelector(".collapse-icon");
        let extra = dragImage.querySelector(".extra");
        let dropArea = document.getElementById("formula-editor-drop-area");

        if (collapseIcon) collapseIcon.style.visibility = "visible";
        if (extra) extra.style.visibility = "visible";

        dropArea.style.border = "solid 2px transparent";
        dragImage.style.opacity = '1';
    }

    /**
     * 
     * @param {*} data 
     * 
     * Appends data to code
     */
    appendCode = (event, data) => {
        const reduxState = store.getState();
        const { contentObject } = reduxState.NewColumnEditorReducer;

        contentObject.code += data;

        store.dispatch(setNewColumnEditorContent(contentObject));

        // Prevent the parent element's event
        if (event) {
            event.cancelBubble = true;
            event.stopPropagation();
        }
    }

    /**
     * Sorts tables and columns
     * 
     * @param {*} tables 
     * @returns 
     */
    sortTablesAndColumns = (tempTables) => {
        let data = deepCopy(tempTables);

        // Sort Columns
        let sortedTables = data.map((table) => {
            return table.columns.sort((a, b) => sortByLanguage(a.displayName, b.displayName));
        });

        // Sort Tables
        sortedTables = data.sort((a, b) => sortByLanguage(a.displayName, b.displayName));

        return sortedTables;
    };

    render() {
        if (this.props.model.id === "defaultModel") {
            return (
                <EmptyDataContent />
            )
        }

        return (
            <div
                id="data-table-column-list"
                style={{
                    height: "100%",
                    overflowY: "auto",
                    overflowX: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    alignContent: "center",
                    justifyItems: "center"
                }}
            >
                <div style={{ padding: "0 8px" }}>
                    <Text
                        style={{
                            fontSize: "1rem",
                            margin: "16px 0 8px 0",
                            display: "block",
                            width: "100%",
                            textAlign: "center",
                            color: "#225f8f"
                        }}
                    >
                        {i18n.t("FormulaEditor.Titles.TablesAndColumns")}
                    </Text>
                    <Search
                        preventDrop={true}
                        paddingNone={true}
                        marginBottom={"8px"}
                        inputStyle={{ minWidth: "auto" }}
                        id={"searchData"}
                        width="100%"
                        searchBarVisible={true}
                        onChange={this.handleSearch}
                        searchItemList={[
                            { name: "Table", translate: "Table", icon: "fas fa-table" },
                            { name: "Column", translate: "Column", icon: "fas fa-columns" }
                        ]}
                        searchCategory={this.searchCategory}
                        selectedSearchCategory={this.state.searchCategory}
                    ></Search>
                </div>
                <Accordion
                    accordionStyle={{
                        padding: "8px",
                    }}
                    defaultActiveKey={this.props.comesFromNewColumn ? "1" : this.props.defaultActiveKey}
                    activeKey={"1"}
                    accordionClass={"data-area-overflow"}
                    titleClass={"dataAccordionHeader"}
                    headerStyle={{
                        background: "#fff",
                        borderBottom: "1px solid #dadada"
                    }}
                    collapseStyle={{ background: "#fff" }}
                >
                    {
                        this.props.model && this.state.searchTempData
                            ? this.sortTablesAndColumns(this.state.searchTempData)
                                .map((table, index) => {
                                    let tableName = table.duplicated ? table.name : table.aliasName;
                                    let tableData = `"${tableName}"`;
                                    let key = tableName + index;

                                    return (
                                        <AccordionContent
                                            id={key}
                                            key={key}
                                            draggable="true"
                                            onDragStart={event => this.onDragStart(event, tableData)}
                                            onDragEnd={event => this.onDragEnd(event)}
                                            onDblClick={(event) => this.appendCode(event, tableData)}
                                            pluginId={this.props.pluginId}
                                            headerClass={"new-column-header"}
                                            icon={
                                                <Tooltip
                                                    arrowPointAtCenter
                                                    title={tableName}
                                                    placement="right"
                                                >
                                                    <InfoCircleOutlined
                                                        className="collapse-icon"
                                                        style={{
                                                            cursor: "default",
                                                            margin: 0
                                                        }}
                                                    />
                                                </Tooltip>
                                            }
                                            title={table.displayName}
                                            titleClass={"data-table-title"}
                                            headerStyle={{
                                                background: "#fff",
                                                borderBottom: "1px solid #dadada",
                                                cursor: "grab",
                                                transition: "none"
                                            }}
                                            collapseStyle={{ background: "#fff" }}
                                            eventKey={"1"}
                                        >
                                            {
                                                table.columns
                                                    .map((column, index) => {
                                                        let columnName = column.duplicated ? column.name : column.aliasName;
                                                        let columnData = `${tableData}."${column.duplicated ? column.name : column.aliasName}"`;
                                                        let key = tableName + + "." + columnName + "." + index;

                                                        return (
                                                            <div
                                                                id={key}
                                                                key={key}
                                                                draggable="true"
                                                                onDragStart={event => this.onDragStart(event, columnData)}
                                                                onDragEnd={event => this.onDragEnd(event)}
                                                                onDoubleClick={(event) => this.appendCode(event, columnData)}
                                                                type="column"
                                                                style={{ position: "relative" }}
                                                            >
                                                                <BoxItem
                                                                    tooltipTitle={column.displayName}
                                                                    title={column.displayName}
                                                                    divClassName={"noStyle"}
                                                                    style={{ cursor: "grab", position: "relative" }}
                                                                    extra={
                                                                        <div style={{ height: "100%" }}>
                                                                            <Tooltip
                                                                                arrowPointAtCenter
                                                                                title={columnName}
                                                                                placement="right"
                                                                            >
                                                                                <InfoCircleOutlined
                                                                                    style={{
                                                                                        cursor: "default",
                                                                                        fontSize: "12px",
                                                                                        color: "#484848",
                                                                                        padding: "4px 8px",
                                                                                    }}
                                                                                />
                                                                            </Tooltip>
                                                                        </div>
                                                                    }
                                                                />
                                                            </div>
                                                        )
                                                    })
                                            }
                                        </AccordionContent>
                                    );
                                })
                            : null
                    }
                </Accordion>
            </div>
        )
    }
}
