import React, { Component } from "react";
import { Form, Icon, Input, Button, Progress, Drawer, Alert } from "antd";
import FooterVispeahenLogo from "../GeneralComponents/FooterVispeahenLogo";
import i18n from "../../Utils/i18next";
import { showNotificationWithIcon, showError } from "../../Utils/Notification";
import { eraseCookie } from "../../Utils/Global";
import { post } from "../../Utils/WebService";
import SubmitButton from "./SubmitButton";
import { getPasswordScore } from "./PasswordValidator";

/**
 * Main task of Register component is user register, if all the informations that user provide are valid
 * those informations will be saved to the db and confirmation mail will be transmitted to the user email address by api.
 */
export default class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fullname: "",
      username: "",
      email: "",
      password: "",
      rePassword: "",
      score: "",
      canISubmit: true,
    };
  }

  /**
   * Handle state inputs change.
   */
  onValueChange = (event) => {
    if (event.target.id === "fullname" && event.target.value[0] === " ") {
      event.preventDefault();
      return false;
    }

    this.setState({
      ...this.state,
      [event.target.id]: event.target.value,
    });
  };

  /**
   * Calculates password's strength
   * @param {*} e
   */
  onChangePassword = (e) => {
    let score = getPasswordScore(e.target.value);

    this.setState({
      ...this.state,
      [e.target.id]: e.target.value,
      score: score,
    });
  };

  /**
   * This function will take action depends on the register button click, in this function all the informations which user provided, and stored
   * will be transmitted to the api and register process will be completed
   */
  saveUser = () => {
    const THIS = this;
    eraseCookie("ORA_BIPS_NQID", "/"); // Remove cookie if it exists (Only possible if HttpOnlyCookies set to false)

    let url = `/v3/api/user`;
    let userObj = {
      username: this.state.username,
      password: this.state.password,
      email: this.state.email,
      ldapUser: false,
      tenant: null,
      enabled: false,
      accountNonExpired: true,
      accountNonLocked: true,
      credentialsNonExpired: true,
      fullName: this.state.fullname,
      preferredLanguage: i18n.language,
    };

    const successFunc = (result) => {
      showNotificationWithIcon(
        i18n.t("UserEntrance.Information"),
        i18n.t("UserEntrance.Register.Registered"),
        "success"
      );

      this.setState({
        fullname: "",
        username: "",
        email: "",
        password: "",
        rePassword: "",
        score: "",
        canISubmit: true,
      });

      this.props.showComponent("visibilityLogin");
    };

    const errorFunc = (error) => {
      showError(error);

      this.setState({
        ...this.state,
        canISubmit: true,
      });
    };

    let headers = {
      Authorization: "Basic " + btoa("mobile:pin"),
    };

    post(url, userObj, successFunc, errorFunc, false, false, headers);

    this.setState({
      ...this.state,
      canISubmit: false,
    });
  };

  handleKeyDown = (event) => {
    // if user presses enter button on keyboard
    if (event.key === "Enter") {
      this.onEnterClicked(event);
    } else {
      this.validateInput(event);
    }
  };

  isUserNameValueOnlyContainsDigitAndLetter = (event) => {
    let val = event.key;

    // for non-letter characters toLowerCase() and toUpperCase() is same ( tricky haa:) )
    const isLetter = val.toLowerCase() != val.toUpperCase();
    const isDigit = /[0-9]/.test(val);
    const isUnderScore = val === "_";

    return (
      event.target.id === "username" &&
      isLetter === false &&
      isDigit === false &&
      isUnderScore === false
    );
  };

  validateInput = (event) => {
    if (this.isUserNameValueOnlyContainsDigitAndLetter(event)) {
      event.preventDefault();
      return false;
    }

    let val = event.key;
    const isLetterOrDot = val.toLowerCase() != val.toUpperCase() || val === ".";
    const isSpace = val === " ";

    if (event.target.id === "fullname") {
      if (isSpace) {
        /**
         * check length of the entire string given by user. If its 0 than there is only a space and ignore.
         * */
        const isValidSpace = event.target.value.length > 0;

        if (isValidSpace === false) {
          event.preventDefault();
          return false;
        }
      } else if (isLetterOrDot === false) {
        event.preventDefault();
        return false;
      }
    }
  };

  /**
   * This function is the last information validation check it will take action right before saveUser
   * if all the information that user provided are valid navigate to saveUser, if not prompt fail message
   */
  validateAndSave = (e) => {
    e.preventDefault();

    // There is still a register process on the way.
    // Do nothing till that process is finished
    if (this.state.canISubmit === false) return;

    if (
      this.validateEmail() &&
      this.validateUserName() &&
      this.validatePassword() &&
      this.validatePasswordScore() &&
      this.validateRePassword() &&
      this.validatePasswordMatch()
    ) {
      this.saveUser();
    }
  };

  validateUserName = () => {
    if (!this.state.username) {
      showError(i18n.t("UserEntrance.Register.UserNameEmpty"));
      return false;
    }

    if (this.state.username.length < 4) {
      showError(i18n.t("UserEntrance.Register.UserNameLength"));
      return false;
    }

    return true;
  };

  validatePassword = () => {
    if (!this.state.password) {
      showError(i18n.t("UserEntrance.Register.PasswordEmpty"));
      return false;
    }

    return true;
  };

  validatePasswordScore = () => {
    if (this.state.score < 100) {
      showError(i18n.t("UserEntrance.Register.PasswordStrength"));
      return false;
    }

    return true;
  };

  validateRePassword = () => {
    if (!this.state.rePassword) {
      showError(i18n.t("UserEntrance.Register.RepasswordEmpty"));
      return false;
    }

    return true;
  };

  validatePasswordMatch = () => {
    if (this.state.rePassword !== this.state.password) {
      showError(i18n.t("UserEntrance.Register.PasswordDoesNotMatch"));
      return false;
    }

    return true;
  };

  validateEmail = () => {
    var regEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (!this.state.email || regEmail.test(this.state.email) === false) {
      showError(i18n.t("UserEntrance.Register.InvalidEmail"));
      return false;
    }

    return true;
  };

  render() {
    return (
      <Drawer
        style={{ display: this.props.visibilityRegister ? "block" : "none" }}
        className={"register-noBackground"}
        placement={"center"}
        closable={false}
        visible={true}
        width={370}
      >
        <Form className="login-form" onSubmit={this.validateAndSave}>
          <div style={{ width: "100%", textAlign: "center" }}>
            <img
              src={require("../../images/logo.webp")}
              alt="Image"
              class="login-logo"
            />
          </div>
          <Form.Item>
            <Input
              id="fullname"
              value={this.state.fullname}
              onChange={this.onValueChange}
              onKeyDown={this.handleKeyDown}
              prefix={
                <Icon type="idcard" style={{ color: "rgba(0,0,0,.25)" }} />
              }
              className={"circled-no-border login-input"}
              maxLength={100}
              placeholder={i18n.t("UserEntrance.Fullname")}
            />
          </Form.Item>
          <div className="password-progress">
            <span style={{ fontSize: "10px", lineHeight: "1px" }}>
              {i18n.t("UserEntrance.Register.FullNameRules")}
            </span>
          </div>
          <Form.Item>
            <Input
              id="email"
              value={this.state.email}
              onChange={this.onValueChange}
              onKeyDown={this.handleKeyDown}
              prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
              className={"circled-no-border login-input"}
              maxLength={100}
              placeholder={i18n.t("UserEntrance.Email")}
            />
          </Form.Item>
          <Form.Item>
            <Input
              id="username"
              value={this.state.username}
              onChange={this.onValueChange}
              onKeyDown={this.handleKeyDown}
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              className={"circled-no-border login-input"}
              maxLength={50}
              name={"userNameRegister"}
              placeholder={i18n.t("UserEntrance.Username")}
            />
          </Form.Item>
          <div className="password-progress">
            <span style={{ fontSize: "10px", lineHeight: "1px" }}>
              {i18n.t("UserEntrance.Register.UserNameRules")}
            </span>
          </div>
          <Form.Item>
            <Input.Password
              id="password"
              value={this.state.password}
              onChange={this.onChangePassword}
              onKeyDown={this.handleKeyDown}
              className={"circled-no-border login-input test_passwordRegister"}
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              maxLength={25}
              name={"passwordRegister"}
              placeholder={i18n.t("UserEntrance.Password")}
            />
          </Form.Item>
          <div className="password-progress">
            <span style={{ fontSize: "10px", lineHeight: "1px" }}>
              {i18n.t("UserEntrance.Register.PasswordStrength")}
            </span>
            <Progress
              id="progressBar"
              percent={this.state.score}
              status={
                this.state.score < 100
                  ? this.state.score == 0
                    ? "info"
                    : "exception"
                  : "success"
              }
            />
          </div>
          <Form.Item>
            <Input.Password
              id="rePassword"
              value={this.state.rePassword}
              onChange={this.onValueChange}
              onKeyDown={this.handleKeyDown}
              className={"circled-no-border login-input test_rePasswordRegister"}
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              maxLength={25}
              name={"rePasswordRegister"}
              placeholder={i18n.t("UserEntrance.RePassword")}
            />
          </Form.Item>
          <div
            style={{
              display: this.state.rePassword.length == 0 ? "none" : "block",
              marginTop: "-15px",
            }}
          >
            <Alert
              id={
                this.state.password === this.state.rePassword
                  ? i18n.t("UserEntrance.PasswordMatch")
                  : i18n.t("UserEntrance.PasswordDontMatch")
              }
              style={{ backgroundColor: "#00000000", border: "0px" }}
              message={
                this.state.password === this.state.rePassword
                  ? i18n.t("UserEntrance.PasswordMatch")
                  : i18n.t("UserEntrance.PasswordDontMatch")
              }
              type={
                this.state.password === this.state.rePassword
                  ? "success"
                  : "error"
              }
              showIcon
            ></Alert>
          </div>
          <Form.Item>
            <SubmitButton
              id="registerButton"
              canISubmit={this.state.canISubmit}
              text={i18n.t("UserEntrance.Register.Button")}
            />
            <div
              style={{ paddingLeft: "5px", float: "right", marginTop: "-5px" }}
            >
              <span
                id={"backToLoginRegister"}
                style={{
                  color: "#1b6094",
                  cursor: "pointer",
                  fontSize: "11px",
                }}
                onClick={() => this.props.showComponent("visibilityLogin")}
              >
                {i18n.t("UserEntrance.GoBackToLogin")}
              </span>
            </div>
          </Form.Item>
        </Form>
        <FooterVispeahenLogo position={"absolute"} />
      </Drawer>
    );
  }
}
