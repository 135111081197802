import React, { Component } from "react";
import * as d3 from "d3";
import $ from "jquery";
import { rmvpp } from "../../RenderJs/rmvpp";
import { obiee } from "../../RenderJs/obiee";
import { createTrigger } from "../../../Interaction/CreateTrigger";
import LineBarConfiguration from "./LineBarConfiguration";
import i18n from "../../../../Utils/i18next";
import LineBarData from "./LineBarData";
import { onComponentWillMount, onComponentWillReceiveProps, getColumnMapping, calculatePluginHeight } from "../common";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import { renderConfig, renderData } from "../PluginsCommonComponents";
import { showError } from "../../../../Utils/Notification";
import { renderContent } from "../renderContent";
import { getFormattedValue } from "../format";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join"
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";

const data = JSON.parse(
  `[{"kargotoplam":"1690930948","bagajtoplam":"4045081712","category":2014,"measure":[{"name":"kargotoplam","value":1690930948,"category":2014,"measureName":"kargotoplam","colour":"#3598DC"},{"name":"bagajtoplam","value":4045081712,"category":2014,"measureName":"bagajtoplam","colour":"#2FCC71"}],"hidden":[],"displayCategory":"2014"},{"kargotoplam":"1843979628","bagajtoplam":"4296005250","category":2015,"measure":[{"name":"kargotoplam","value":1843979628,"category":2015,"measureName":"kargotoplam","colour":"#3598DC"},{"name":"bagajtoplam","value":4296005250,"category":2015,"measureName":"bagajtoplam","colour":"#2FCC71"}],"hidden":[],"displayCategory":"2015"},{"kargotoplam":"2075162350","bagajtoplam":"4037735418","category":2016,"measure":[{"name":"kargotoplam","value":2075162350,"category":2016,"measureName":"kargotoplam","colour":"#3598DC"},{"name":"bagajtoplam","value":4037735418,"category":2016,"measureName":"bagajtoplam","colour":"#2FCC71"}],"hidden":[],"displayCategory":"2016"}]`
);
const config = JSON.parse(
  `{"width":400,"height":300,"pointSize":2,"strokeWidth":2,"horizontal":false,"legend":false,"secondAxis":false,"showHideButton":false,"dragSelect":true,"brushNav":true,"shadeArea":false,"colours":"Flat-UI","toggleCriteria":"","title":"","summary":"","backgroundColor":"rgb(255,255,255)","remarkData":[],"refresh":0}`
);
const columnMap = JSON.parse(
  `{"category":{"Code":"\'ucusAlias\'.\'yilAlias\'","Name":"yil","DataType":"integer","Table":"ucusAlias","Measure":"none","ID":"ucusAlias.yil","SubjectArea":"BIGDATA","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":".0f","Config":{},"Verified":false,"Type":"Column","Description":""},"measure":[{"Code":"\'ucusAlias\'.\'kargotoplamDataAlias\'","Name":"kargotoplam","DataType":"double","Table":"ucusAlias","Measure":"sum(ucus.kargotoplam)","ID":"ucusAlias.kargotoplam","SubjectArea":"BIGDATA","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":".3s","Config":{},"Verified":false,"Type":"Column","Description":""},{"Code":"\'ucusAlias\'.\'bagajtoplamAlias\'","Name":"bagajtoplam","DataType":"double","Table":"ucusAlias","Measure":"sum(ucus.bagajtoplam)","ID":"ucusAlias.bagajtoplam","SubjectArea":"BIGDATA","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":".3s","Config":{},"Verified":false,"Type":"Column","Description":""}],"vary":{"Code":"","Name":"","DataType":"varchar","Table":"Unspecified","Measure":"none","ID":"Unspecified.","SubjectArea":"","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":"%s","Config":{},"Verified":false,"Type":"Column"},"hidden":[]}`
);

const condFormats = [];
const filters = [];

var chart;
var legend;
var horizontal;
var animate;

var pluginName = "line";
const description =
  "Line chart visualisation for continuous data, can be used as an area chart by setting the appropriate configuration setting. Secondary Y axes can be used when there are two measures to scale them appropriately regarding their values. When a date column is used as the category property the dataset will be modified to be continuous. This means that if OBIEE is missing records for certain days, they will be assigned a value of 0.";

const configurationParameters = [
  {
    targetProperty: "width",
    label: "Width",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 400
    },
    desc: "desc89"
  },
  {
    targetProperty: "height",
    label: "Height",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 300
    },
    desc: "desc90"
  },
  {
    targetProperty: "pointSize",
    label: "PointSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 2
    },
    desc: "desc95"
  },
  {
    targetProperty: "strokeWidth",
    label: "StrokeWidth",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 2
    },
    desc: "StrokeWidth"
  },
  {
    targetProperty: "horizontal",
    label: "Horizontal",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "Horizontal"
  },
  {
    targetProperty: "legend",
    label: "Legend",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "Legend"
  },
  {
    targetProperty: "secondAxis",
    label: "SecondAxis",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "desc108"
  },
  {
    targetProperty: "showHideButton",
    label: "Show Hide Button",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc230"
  },
  {
    targetProperty: "dragSelect",
    label: "DragSelect",
    inputType: "checkbox",
    inputOptions: { defaultValue: true },
    desc: "desc109"
  },
  {
    targetProperty: "brushNav",
    label: "BrushNav",
    inputType: "checkbox",
    inputOptions: { defaultValue: true },
    desc: "desc107"
  },
  {
    targetProperty: "shadeArea",
    label: "ShadeArea",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "desc110"
  },
  {
    targetProperty: "colours",
    label: "Colours",
    inputType: "palette",
    inputOptions: {
      defaultValue: "Flat-UI"
    },
    desc: "desc111"
  },
  {
    targetProperty: "toggleCriteria",
    label: "ToggleCriteria",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc59"
  },
  {
    targetProperty: "title",
    label: "Title",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc94"
  },
  {
    targetProperty: "summary",
    label: "Summary",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc61"
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: { defaultValue: "rgb(255,255,255)" },
    desc: "desc62"
  },
  {
    targetProperty: "remarkData",
    label: "RemarkData",
    inputType: "hidden",
    inputOptions: { defaultValue: [] },
    desc: "desc137"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  },
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 15
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
];

const actions = [
  {
    trigger: "sectionClick",
    type: "click",
    name: "Tıklama - Bölüm",
    output: ["category"],
    description: "SectionClickDesc"
  },
  {
    trigger: "sectionHover",
    type: "hover",
    name: "Hover - Bölüm",
    output: ["category"],
    description: "SectionHover2Desc"
  },
  {
    trigger: "pointClick",
    type: "click",
    output: ["category", "vary"],
    name: "Tıklama - Nokta",
    description: "PointClickDesc"
  },
  {
    trigger: "pointHover",
    type: "hover",
    output: ["category", "vary"],
    name: "Hover - Nokta",
    description: "PointHoverDesc"
  },
  {
    trigger: "brushSelect",
    type: "brush",
    name: "Fırça Seçimi",
    output: ["category"],
    description: "BrushSelectDesc"
  }
];

const reactions = [
  {
    id: "filter",
    name: "Filtre",
    description:
      "Accepts a column map and value and filters the report if the subject area matches.",
    type: "general"
  }
];

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

let mapForLineBar = new Map();
let getKeyForMap = "";

/**
 * renders Sunburst plugin in Vispeahen V3
 */
export default class LineBar extends Component {
  constructor(props) {
    super(props);
    this.rerenderProcessStarted = false;
    this.callBackObject = {};
  }

  calculatePluginHeight = (plugin, settings) => {
    let containerHeight = settings.grid.rowHeight * plugin.h;
    let pluginTitleContainer = $("#title-" + plugin.id);
    let pluginContainerBorder = -2;
    let pluginConstantNavigatorHeight = 60;
    let pluginConstantSortHeight = 24 + 5;
    let maxHeight =
      containerHeight -
      (pluginTitleContainer.outerHeight() +
        parseInt(pluginTitleContainer.css("margin-bottom")) +
        pluginContainerBorder +
        pluginConstantNavigatorHeight +
        pluginConstantSortHeight);
    return maxHeight;
  };

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({})

    return tmpCallBackObject;
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );
  }


  getConfigComponent = props => {
    if (props.config) {
      return (
        <LineBarConfiguration
          config={{ ...props.config }}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig= {this.props.setCurrentAppliedConfig}
          currentAppliedConfig = {this.props.currentAppliedConfig}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  getDataComponent = (props) => {
    let columnMap = getColumnMapping(this.props, props, this.prepareColumnMapping)
    return (
      <LineBarData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        didNotJoinedTables={checkTableJoins(this.props.join, this.props.plugin.columnMap, this.props.refreshedPluginId, this.props.plugin.id, true)}
        setInteractions={this.props.setInteractions}
        interactions={this.props.interactions}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={props.changeDoesPluginHasNotJoinedTable}
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  /**
   * To set column map this plugin
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      category: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Category.Name"),
        type: "dim",
        minimumColumnSize: 1,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Category.Desc"),
        required: true,
        data: []
      },
      measure: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Name"),
        multiple: true,
        minimumColumnSize: 1,
        type: "fact",
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Desc"),
        required: true,
        conditionalFormat: true,
        data: []
      }, vary: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Vary.Name"),
        type: "dim",
        minimumColumnSize: 0,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Vary.Desc"),
        data: []
      },
      hidden: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Hidden.Name"),
        multiple: true,
        minimumColumnSize: 0,
        type: "fact",
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Hidden.Desc"),
        type: "hidden",
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  isValidColumnMap = (columnMap) => {
    return columnMap.vary.Code &&
      columnMap.vary.Code !== "" && columnMap.measure.length > 1
  }

  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {
    if (this.isValidColumnMap(columnMap)) {
      showError(i18n.t("Errors.LineBarVaryError"))
    } else {
      var THIS = this;

      // Set html empty and set this html to container.
      $("#" + divId).html("");
      let container = $("#" + divId)[0];
      let vis;

      // Change background color
      $(container).css("background-color", config.backgroundColor);

      var hideXLabels = false,
        hideYLabels = false,
        navigator = config.brushNav;

      //Mapping the Line Columns
      if (columnMap) {
        let categoryName = columnMap.category.Name;
        let measureName = columnMap.measure[0].Name;
        let varyName = columnMap.vary.Name;
        let hiddenName = "";
        if (columnMap.hidden.length != 0) {
          for (let i = 0; i < columnMap.hidden.length; i++) {
            hiddenName = hiddenName + "_" + columnMap.hidden[i].Name;
          }
        }

        getKeyForMap =
          categoryName + "_" + measureName + "_" + varyName + hiddenName;

        let getMapValue = mapForLineBar.get(getKeyForMap);
        if (getMapValue != undefined) {
          config.remarkData = getMapValue;
        } else {
          config.remarkData = [];
        }
      }

      if (config.secondAxis && columnMap.measure.length != 2)
        rmvpp.displayError("ErrorMessages.error9");

      // If category is a date, fill in missing values so that data is continuous
      if (columnMap.category.DataType == "date") {
        var maxX = new Date(
          d3.max(data, function (d) {
            return d.category;
          })
        );
        var minX = new Date(
          d3.min(data, function (d) {
            return d.category;
          })
        );
        var dateRange = rmvpp.dateRange(minX, maxX);

        if (dateRange.length > data.length) {
          var newData = [];
          dateRange.forEach(function (dt, i) {
            // Loop over the date range
            var parseDate = new Date(data[i].category);
            parseDate.setHours(0); // Avoids comparison issues with daylight savings
            if (parseDate.getTime() == dt.getTime()) {
              // If the date matches, parse the format
              data[i].category = parseDate;
              newData.push(data[i]);
            } else {
              // Otherwise fill in with a null
              var newDatum = obiee.nullDatum(columnMap);
              newDatum.category = dt;
              newData.push(newDatum);
              data.splice(i, 0, newDatum);
            }
          });
          data = newData;
        }
      }

      let numberOfLines = 0;

      // Compile column name list for Y
      let LegendTitle = "Measures";
      var colNames = [],
        legendTitle = LegendTitle;
      for (var i = 0; i < columnMap.measure.length; i++) {
        colNames.push(columnMap.measure[i].Name);
      }

      // Denormalise X label onto Y values for tooltip later
      data.map(function (d) {
        d.displayCategory = getFormattedValue(columnMap.category, d.category);  // Format category labels

        for (var i = 0; i < d.measure.length; i++) {
          d.measure[i].category = d.category;
        }
      });

      var varyColour = false;
      if (columnMap.vary.Code && columnMap.vary.Code != "") {
        varyColour = true;
      }

      // Restructure data frame if vary by colour being used
      if (varyColour) {
        var pivotData = rmvpp.pivotData(
          data,
          columnMap,
          "vary",
          "category",
          "measure",
          ["hidden"]
        );
        data = pivotData.data;
        colNames = pivotData.colNames;
        legendTitle = columnMap.vary.Name;
      }

      // Set X and Y titles
      var xTitle = "default",
        yTitle = "default";
      if (xTitle == "default") xTitle = columnMap.category.Name;
      if (yTitle == "default") {
        if (varyColour || config.secondAxis) yTitle = columnMap.measure[0].Name;
        else yTitle = "";
      }
      var y2Title = config.secondAxis ? columnMap.measure[1].Name : "";

      // Chart container
      var chartContainer = d3
        .select(container)
        .append("div")
        .classed("lineBar", true);
      // Render line chart
      renderLine(
        data,
        config.remarkData,
        $(chartContainer[0])[0],
        true,
        navigator
      );

      // Main rendering function
      function renderLine(
        chartData,
        remarkData,
        chartContainer,
        animate,
        navigator
      ) {
        numberOfLines++;
        let preventTooltip = false;

        $(chartContainer)
          .find(".main")
          .remove(); // Clear existing chart

        // Define height and width from config
        var width = config.horizontal ? +config.height : config.width;
        var height = config.horizontal ? config.width : +config.height;

        if (columnMap.category.DataType == "date") {
          var xDate = true; // Indicator for X axis as a continuous date
          var extent = d3.extent(
            chartData.map(function (d) {
              return new Date(d.category);
            })
          );

          // X Axis from subset, chartData
          var x = d3.time
            .scale()
            .domain(extent)
            .range([0, width]);

          var extent = d3.extent(
            data.map(function (d) {
              return new Date(d.category);
            })
          );

          // X scale from full dataset, data
          var fullX = d3.time
            .scale()
            .domain(extent)
            .range([0, width]);

          var rangeBand = 0,
            rectBand = width / chartData.length;
        } else {
          var xDate = false; // Indicator for X axis as an ordinal scale

          // X Axis from subset, chartData
          var x = d3.scale
            .ordinal()
            .domain(
              chartData.map(function (d) {
                return d.category;
              })
            )
            .rangeBands([0, width], 0.1);

          // X scale from full dataset, data
          var fullX = d3.scale
            .ordinal()
            .domain(
              data.map(function (d) {
                return d.category;
              })
            )
            .rangeBands([0, width], 0.1);

          var rangeBand = x.rangeBand() / 2,
            rectBand = x.rangeBand();
        }

        var y2, y2Col;
        if (config.secondAxis) {
          var maxY = d3.max(
            chartData.map(function (d) {
              return +d.measure[0].value ? +d.measure[0].value : 0;
            })
          );
          var minY = d3.min(
            chartData.map(function (d) {
              return +d.measure[0].value ? +d.measure[0].value : 0;
            })
          );
          var y = d3.scale
            .linear()
            .domain([d3.min([0, minY]), d3.max([0, maxY])]); // From 0 to maximum

          var maxY2 = d3.max(
            chartData.map(function (d) {
              return +d.measure[1].value ? +d.measure[1].value : 0;
            })
          );
          var minY2 = d3.min(
            chartData.map(function (d) {
              return +d.measure[1].value ? +d.measure[1].value : 0;
            })
          );
          y2 = d3.scale.linear().domain([d3.min([0, minY2]), d3.max([0, maxY2])]); // From 0 to maximum
          y2Col = columnMap.measure[1];
        } else {
          // Y Axis (Full)
          var maxY = d3.max(chartData, function (d) {
            return d3.max(d.measure, function (d) {
              return +d.value ? +d.value : 0;
            });
          });
          var minY = d3.min(chartData, function (d) {
            return d3.min(d.measure, function (d) {
              return +d.value ? +d.value : 0;
            });
          });
          var y = d3.scale
            .linear()
            .domain([d3.min([0, minY]), d3.max([0, maxY])]); // From 0 to maximum
        }

        // Define colour palette
        var colour = rmvpp.colourScale(
          chartData[0].measure.map(function (d) {
            return d.name;
          }),
          config.colours
        ); // Set colour scale

        // Apply conditional formatting to generate colours
        rmvpp.applyColours(
          chartData,
          columnMap,
          colour,
          condFormats,
          "category",
          "measure",
          "vary"
        );

        // Create chart object
        var chartObj = new rmvpp.Chart(chartContainer, width, height);
        if (config.horizontal) chartObj.Horizontal = true;

        // Set axes and margin
        chartObj.setX(xTitle, x, columnMap.category);
        chartObj.setY(yTitle, y, columnMap.measure[0]);
        if (config.secondAxis) chartObj.setY2(y2Title, y2, columnMap.measure[1]);

        var margin = chartObj.setMargin();
        chart = chartObj.createSVG(vis, ".navigator"); // Create chart SVG to standard size
        chartObj.drawAxes(); // Draw axes

        chart.parent().classed("main", true);
        var tooltip = new rmvpp.Tooltip(chartContainer); // Create tooltip object

        if (config.legend) {
          var legend = new rmvpp.Legend(
            chart,
            colNames,
            legendTitle,
            width + margin.right,
            margin,
            true
          ); // Legend
          legend.addColourKey(colNames, colour); // Legend Colour Key
        }

        var sectionPath = chart
          .append("path")
          .attr("stroke", "#CCC")
          .attr("fill", "none")
          .attr("opacity", "0")
          .attr("d", "M0,0");

        // Define sections
        var xGroups = chart
          .selectAll(".categoryGroups")
          .data(chartData)
          .enter()
          .append("g")
          .attr("transform", function (d) {
            return "translate(" + x(d.category) + ",0)";
          });

        // Define remark sections
        var yGroups = chart
          .selectAll(".categoryGroups")
          .data(remarkData)
          .enter()
          .append("g")
          .attr("transform", function (d) {
            return "translate(" + x(d.category) + ",0)";
          });

        // Invisible remark section rectangle
        yGroups
          .append("rect")
          .style("opacity", 0)
          .attr("x", 0)
          .attr("y", 0)
          .attr("height", y(d3.min([0, minY])))
          .attr("width", rectBand)
          .attr("pointer-events", "none"); // Disable pointer events until animation complete

        // Invisible section rectangle
        xGroups
          .append("rect")
          .style("opacity", 0)
          .attr("x", 0)
          .attr("y", 0)
          .attr("height", y(d3.min([0, minY])))
          .attr("width", rectBand)
          .attr("pointer-events", "none") // Disable pointer events until animation complete
          .on("mouseover", function (d, i, event) {
            highlightGroup(this); // Highlight markers

            if (!preventTooltip) {
              // Show tooltip
              tooltip.displayList(
                d,
                "category",
                "measure",
                columnMap,
                d3.event,
                "colour",
                d.name
              );
            }

            // Display section line
            var sectionLine = [
              { x: x(d.category) + rangeBand, y: y.range()[1] },
              { x: x(d.category) + rangeBand, y: y.range()[0] }
            ];

            rmvpp.renderLine(sectionPath, sectionLine);
            createTrigger(
              actions,
              columnMap,
              container,
              "sectionHover",
              d,
              THIS.props.plugin.id,
              THIS.props.interactions,
              THIS.props.navigations
            ); // Trigger hover section event
          })
          .on("mouseout", function (d, i) {
            revertColour(this); // Revert colours
            if (!preventTooltip) tooltip.hide(); // Hide tooltip
            sectionPath.attr("opacity", 0); // Hide section line
          })
          .on("click", function (d, i) {
            // Trigger click section event
            createTrigger(
              actions,
              columnMap,
              container,
              "sectionClick",
              d,
              THIS.props.plugin.id,
              THIS.props.interactions,
              THIS.props.navigations
            );
          })
          .on("contextmenu", function (d, i) {
            d3.event.preventDefault();

            var datum = d3
              .selectAll(
                $(this)
                  .parent()
                  .find("rect")
                  .toArray()
              )
              .datum();
            if (!config.remarkData.includes(datum)) {
              datum.data = [];
              config.remarkData.push(datum);
              renderLine(
                data,
                config.remarkData,
                chartContainer,
                false,
                navigator
              );
            } else {
              displayRemarkTooltip(d3.event);
              preventTooltip = preventTooltip == false;
            }
          })
          .transition()
          .duration(500)
          .transition()
          .attr("pointer-events", ""); // Enable point events when animation complete

        if (!config.secondAxis) {
          colNames.forEach(function (m, i) {
            plotChart(
              chartData.map(function (d) {
                return { measure: [d.measure[i]] };
              }),
              remarkData,
              [colNames[i]],
              i,
              y
            );
          });
        } else {
          plotChart(
            chartData.map(function (d) {
              return { measure: [d.measure[0]] };
            }),
            remarkData,
            [colNames[0]],
            0,
            y
          );
          plotChart(
            chartData.map(function (d) {
              return { measure: [d.measure[1]] };
            }),
            remarkData,
            [colNames[1]],
            1,
            y2
          );
        }

        function plotChart(chartDataMeasures, remarkData, colNames, idx, y) {
          // Draw lines between points
          var line = d3.svg
            .line()
            .x(function (d) {
              return x(d.category) + rangeBand;
            })
            .y(function (d) {
              return y(+d.value);
            });
          line.defined(function (d) {
            return !isNaN(d.value);
          });

          var flatLine = d3.svg
            .line()
            .x(function (d) {
              return x(d.category) + rangeBand;
            })
            .y(function (d) {
              return y(0);
            });

          var yVals = chartDataMeasures.map(function (d) {
            return +d.value;
          });

          // Loop through series and plot lines between points
          for (var i = 0; i < colNames.length; i++) {
            if (config.shadeArea) {
              // Shade area under line
              var measure = chartDataMeasures.map(function (d) {
                return d.measure[i];
              });
              measure.push({
                category: measure[measure.length - 1].category,
                name: colNames[i],
                value: 0
              });
              measure.unshift({
                category: measure[0].category,
                name: colNames[i],
                value: 0
              });
              var areaPath = chart
                .append("path")
                .datum(measure)
                .attr("fill", colour(chartDataMeasures[0].measure[i].name))
                .attr("fill-opacity", 0.2)
                .attr("pointer-events", "none");

              if (animate) {
                // Animate depending on the situation
                areaPath
                  .attr("d", flatLine)
                  .transition()
                  .attr("d", line)
                  .duration(500);
              } else areaPath.attr("d", line);
            }

            var measure = chartDataMeasures.map(function (d) {
              return d.measure[i];
            });
            var linePath = chart
              .insert("path", ":first-child")
              .datum(measure)
              .attr("class", "line")
              .attr("fill", "none")
              .attr("stroke-width", config.strokeWidth)
              .attr("stroke", colour(chartDataMeasures[0].measure[i].name));

            if (animate) {
              // Animate depending on the situation
              linePath
                .attr("d", flatLine)
                .transition()
                .attr("d", line)
                .duration(500);
            } else linePath.attr("d", line);
          }

          // Dashed path for trackerline
          var trackerPath = chart
            .append("path")
            .attr("stroke", "#666")
            .attr("stroke-dasharray", "5, 5")
            .attr("fill", "none")
            .attr("opacity", "0")
            .attr("d", "M0,0");

          // Create markers
          if (idx == 0) {
            var remarkMarkers = yGroups
              .selectAll("g")
              .data(function (d) {
                let marker = d.measure.filter(function (m) {
                  return m.vary == d.vary;
                });
                return marker;
              })
              .enter()
              //.append("rect")
              .append("text")
              .attr("text-anchor", "middle")
              .attr("dominant-baseline", "middle")
              .attr("class", "fa")
              .text("\uf192")
              .attr("width", function (d) {
                return isNaN(d.value) ? 2 : config.pointSize + 5;
              })
              .attr("height", function (d) {
                return isNaN(d.value) ? 2 : config.pointSize + 5;
              })
              .attr("x", function (d, i) {
                return rangeBand;
              })
              .attr("fill", function (d) {
                return d.colour;
              })
              .classed("marker", true)
              .on("mouseover", function (d, i) {
                highlightGroup(this); // Highlight markers

                if (!preventTooltip) {
                  // Show tooltip
                  displayRemarkTooltip(d3.event);
                }

                // Display tracklines
                var trackerLine = [
                  { x: 0, y: y(d.value) },
                  { x: x(d.category) + rangeBand, y: y(d.value) },
                  { x: x(d.category) + rangeBand, y: y.range()[0] }
                ];

                trackerPath.attr("stroke", d.colour);
                rmvpp.renderLine(trackerPath, trackerLine);
                createTrigger(
                  actions,
                  columnMap,
                  container,
                  "pointHover",
                  d,
                  THIS.props.plugin.id,
                  THIS.props.interactions,
                  THIS.props.navigations
                ); // Trigger hover point action
              })
              .on("mouseout", function (d, i) {
                revertColour(this); // Revert colours
                if (!preventTooltip) tooltip.hide(); // Hide tooltip
                trackerPath.attr("opacity", 0); // Hide trackerline
              })
              .on("click", function (d, i) {
                createTrigger(
                  actions,
                  columnMap,
                  container,
                  "pointClick",
                  d,
                  THIS.props.plugin.id,
                  THIS.props.interactions,
                  THIS.props.navigations
                );
              })
              .on("contextmenu", function (d, i) {
                d3.event.preventDefault();
                displayRemarkTooltip(d3.event);

                preventTooltip = preventTooltip == false;
              });

            if (animate) {
              remarkMarkers
                .attr("y", function (d, i) {
                  return y(0);
                })
                .transition()
                .attr("y", function (d, i) {
                  return y(d.value ? d.value : 0);
                })
                .duration(500);
            } else {
              remarkMarkers.attr("y", function (d, i) {
                return y(d.value ? d.value : 0);
              });
            }
          }

          // Create Points
          var points = xGroups
            .selectAll("g")
            .data(function (d) {
              return [d.measure[idx]];
            })
            .enter()
            .append("circle")
            .attr("r", function (d) {
              return isNaN(d.value) ? 0 : config.pointSize;
            })
            .attr("cx", function (d, i) {
              return rangeBand;
            })
            .attr("fill", function (d) {
              return d.colour;
            })
            .classed("marker", true)
            .on("mouseover", function (d, i) {
              highlightGroup(this); // Highlight markers

              if (!preventTooltip) {
                // Show tooltip
                var datum = d3
                  .selectAll(
                    $(this)
                      .parent()
                      .find("rect")
                      .toArray()
                  )
                  .datum();
                tooltip.displayList(
                  datum,
                  "category",
                  "measure",
                  columnMap,
                  d3.event,
                  "colour",
                  d.name
                ); // Show tooltip
              }

              // Display tracklines
              var trackerLine = [
                { x: 0, y: y(d.value) },
                { x: x(d.category) + rangeBand, y: y(d.value) },
                { x: x(d.category) + rangeBand, y: y.range()[0] }
              ];

              trackerPath.attr("stroke", d.colour);
              rmvpp.renderLine(trackerPath, trackerLine);
              createTrigger(
                actions,
                columnMap,
                container,
                "pointHover",
                d,
                THIS.props.plugin.id,
                THIS.props.interactions,
                THIS.props.navigations
              ); // Trigger hover point action
            })
            .on("mouseout", function (d, i) {
              revertColour(this); // Revert colours
              if (!preventTooltip) tooltip.hide(); // Hide tooltip
              trackerPath.attr("opacity", 0); // Hide trackerline
            })
            .on("click", function (d, i) {
              createTrigger(
                actions,
                columnMap,
                container,
                "pointClick",
                d,
                THIS.props.plugin.id,
                THIS.props.interactions,
                THIS.props.navigations
              );
            })
            .on("contextmenu", function (d, i) {
              d3.event.preventDefault();

              var datum = d3
                .selectAll(
                  $(this)
                    .parent()
                    .find("rect")
                    .toArray()
                )
                .datum();
              datum.vary = d.vary;
              if (!config.remarkData.includes(datum)) {
                datum.data = [];
                config.remarkData.push(datum);
                renderLine(
                  data,
                  config.remarkData,
                  chartContainer,
                  false,
                  navigator
                );
              } else {
                displayRemarkTooltip(d3.event);
                preventTooltip = preventTooltip == false;
              }
            });

          if (animate) {
            points
              .attr("cy", function (d, i) {
                return y(0);
              })
              .transition()
              .attr("cy", function (d, i) {
                return y(d.value ? d.value : 0);
              })
              .duration(500);
          } else {
            points.attr("cy", function (d, i) {
              return y(d.value ? d.value : 0);
            });
          }
        }

        function displayRemarkTooltip(mouseEvent) {
          let remarkIndex; // Remark
          let lineNum;

          for (let i = 0; i < numberOfLines + 1; i++) {
            let selectedLine = remarkData.findIndex(
              currentData => currentData.measure[i] == mouseEvent.target.__data__
            ); // Find selected remark

            if (selectedLine != -1) {
              remarkIndex = selectedLine;
              lineNum = i;
              break;
            }
          }

          if (remarkIndex != -1) {
            let remarklistItems = "";
            let remarkInputField =
              "<br><input id='remarkInput' type='text' placeholder='' autofocus=true>";
            let remarkRemoveItemIcon =
              "<span class='removeIcon'> <i class='fa fa-minus-circle'></i> </span> ";
            let remarkRemoveAllItem =
              "<span class='removeAllIcon'><i class='fa fa-times-circle'></i></span>";

            if (remarkData[remarkIndex]["data"][lineNum] != undefined)
              remarkData[remarkIndex]["data"][lineNum].forEach(
                (currentData, index) =>
                (remarklistItems +=
                  "<li id='" +
                  index +
                  "'>" +
                  remarkRemoveItemIcon +
                  currentData +
                  "</li>")
              ); // Get remark's items

            let remarkTooltipHtml =
              remarkRemoveAllItem +
              "<ul>" +
              remarklistItems +
              "</ul>" +
              remarkInputField; //Create remark tootlip HTML
            tooltip.displayHTML(remarkTooltipHtml, mouseEvent); // Show tooltip

            document.getElementById("remarkInput").focus();

            var input = document.getElementById("remarkInput");
            input.addEventListener("keyup", function (event) {
              // Number 13 is the "Enter" key on the keyboard
              if (event.keyCode === 13 && input.value != "") {
                addItemToRemarkData(input.value, mouseEvent, lineNum);
                displayRemarkTooltip(mouseEvent);
              }
            });

            let removeIcons = document.getElementsByClassName("removeIcon");
            if (removeIcons) {
              for (let removeIcon of removeIcons) {
                removeIcon.addEventListener("click", function (event) {
                  removeItemFromRemarkData(
                    remarkIndex,
                    removeIcon.parentNode,
                    mouseEvent,
                    lineNum
                  );
                });
              }
            }

            let removeAllIcons = document.getElementsByClassName("removeAllIcon");
            if (removeAllIcons) {
              for (let removeAllIcon of removeAllIcons) {
                removeAllIcon.addEventListener("click", function (event) {
                  removeAllItemFromRemarkData(remarkIndex, lineNum);
                });
              }
            }
          } else {
            tooltip.hide(); // Hide tooltip
          }
          getRemarkDataForMapValue(remarkData);

          document.addEventListener("click", function (event) {
            if (
              event.target.id !== "remarkInput" &&
              event.target.className != "fa fa-minus-circle"
            )
              tooltip.hide();
          });
        }

        function addItemToRemarkData(inputValue, mouseEvent, lineNum) {
          Event.preventDefault();
          config.remarkData.map(currentRemark => {
            if (currentRemark.measure[lineNum] == mouseEvent.target.__data__) {
              let inputString = inputValue;
              let inputArray = inputString.split();
              try {
                currentRemark.data[lineNum].push(inputArray);
              } catch {
                currentRemark.data[lineNum] = inputArray;
              }
            }
          });
        }

        function removeItemFromRemarkData(
          remarkIndex,
          listItem,
          mouseEvent,
          lineNum
        ) {
          if (remarkData[remarkIndex]) {
            remarkData[remarkIndex]["data"][lineNum].splice([listItem.id], 1);
            if (remarkData[remarkIndex]["data"].length == 0) {
              remarkData.splice(remarkIndex, 1);
              renderLine(
                chartData,
                config.remarkData,
                chartContainer,
                false,
                navigator
              );
            } else {
              displayRemarkTooltip(mouseEvent);
            }
          }
        }

        function removeAllItemFromRemarkData(remarkIndex, lineNum) {
          if (remarkData[remarkIndex]) {
            remarkData[remarkIndex]["data"][lineNum] = [];
            renderLine(
              chartData,
              config.remarkData,
              chartContainer,
              false,
              navigator
            );
          }
        }

        function getRemarkDataForMapValue(remarkData) {
          mapForLineBar.set(getKeyForMap, remarkData);
        }

        if (config.horizontal) {
          chartObj.rotate();
          if (config.legend) legend.rotate();
        }

        // Brush function for navigation panel
        var brushNav = d3.svg
          .brush()
          .x(fullX)
          .on("brush", function () {
            var selected = getNavSelection();
            var selectedRemark = getNavRemarkSelection(selected);
            if (selected.length > 0)
              renderLine(selected, selectedRemark, chartContainer);
          })
          .on("brushend", function () {
            var selected = getNavSelection();

            if (xDate) {
              // Convert back to 'YYYY-MM-DD' for interactions
              /* var selectConverted = angular.copy(selected);
                      selectConverted.forEach(function (d) {
                          d.category = rmvpp.locales[columnMap.category.Locale].timeFormat('%Y-%m-%d')(d.category);
                      });
                      rmvpp.createTrigger(actions, columnMap, container, 'brushSelect', selectConverted); */
            } else {
              createTrigger(
                actions,
                columnMap,
                container,
                "brushSelect",
                selected,
                THIS.props.plugin.id,
                THIS.props.interactions,
                THIS.props.navigations
              );
            }
          });

        // Fethes selected records by the extend of the navigator selection
        function getNavSelection() {
          var extent = brushNav.extent();

          if (!xDate) {
            // Ordinal X axis
            var selected = rmvpp.filterOrdinalScale(
              data,
              extent[0],
              extent[1],
              fullX
            );
          } else {
            // Continuous date X axis
            var selected = data.filter(function (d) {
              return (
                d.category.getTime() >= extent[0].getTime() &&
                d.category.getTime() <= extent[1].getTime()
              );
            });
          }
          return selected;
        }

        // Fethes selected remark records by the extend of the navigator selection
        function getNavRemarkSelection(selected) {
          var extent = brushNav.extent();

          /* if (!xDate) { // Ordinal X axis
                  var selectedMap = _.keyBy(selected, o => o.category);
                  var selectedRemark = [];
                  for (let i = 0; i < config.remarkData.length; i++) {
                     if(selectedMap[config.remarkData[i].category] != undefined && config.remarkData[i].category == selectedMap[config.remarkData[i].category].category) {                           
                          selectedRemark.push(config.remarkData[i]);
                     }
                  }
              } else { // Continuous date X axis
                  var selectedRemark = config.remarkData.filter(function (d) {
                      return d.category.getTime() >= extent[0].getTime() && d.category.getTime() <= extent[1].getTime()
                  });
              }
              return selectedRemark; */
        }

        // Navigator code
        if (navigator) {
          $(chartContainer)
            .find(".navigator")
            .remove();
          var navHeight = 60;
          var navFrac = navHeight / height;
          var navContainer = d3
            .select(chartContainer)
            .append("div")
            .classed("navigator do-not-print", true);
          var navChartObj = new rmvpp.Chart(navContainer[0][0], width, navHeight); // New chart object

          navChartObj.Margin = {
            // Scale margin by navigator fraction
            top: margin.top * navFrac,
            bottom: margin.bottom * navFrac,
            left: margin.left,
            right: margin.right
          };

          navChartObj.setX("", fullX); // Set X axis
          var navChart = navChartObj.createSVG(vis); // Draw SVG

          // Loop through series and plot lines between points
          for (var i = 0; i < colNames.length; i++) {
            var measure = chartData.map(function (d) {
              return d.measure[i];
            });

            measure.push({
              category: measure[measure.length - 1].category,
              name: colNames[i],
              value: 0
            });
            measure.unshift({
              category: measure[0].category,
              name: colNames[i],
              value: 0
            });

            // Draw lines between points
            if (!config.secondAxis) {
              var navLine = d3.svg
                .line()
                .x(function (d) {
                  return fullX(d.category) + rangeBand;
                })
                .y(function (d) {
                  return navFrac * y(d.value);
                });
            } else {
              var navY = maxY >= maxY2 ? y : y2;
              var navLine = d3.svg
                .line()
                .x(function (d) {
                  return fullX(d.category) + rangeBand;
                })
                .y(function (d) {
                  return navFrac * navY(d.value);
                });
            }
            navLine.defined(function (d) {
              return !isNaN(d.value);
            });

            // Draw lines between points
            var navFlatline = d3.svg
              .line()
              .x(function (d) {
                return fullX(d.category) + rangeBand;
              })
              .y(function (d) {
                return navFrac * y(0);
              });

            navChart
              .append("path")
              .datum(measure)
              .attr("d", navFlatline)
              .attr("fill", "#CCC")
              .transition()
              .duration(500)
              .attr("d", navLine);
          }
          navChartObj.drawAxes(); // Draw axes
          navChart.selectAll(".x.axis .tick").remove(); // Remove X labels

          var navViewport = navChart
            .append("g")
            .attr("class", "viewport")
            .call(brushNav)
            .selectAll("rect")
            .attr("height", navHeight - navChartObj.Margin.bottom - 2);

          if (config.horizontal) navChartObj.rotate();
        }

        if (config.horizontal) {
          var mainDiv = $(container).find(".main");
          $(container)
            .find(".navigator")
            .css("display", "inline")
            .insertBefore(mainDiv);
        }

        // Create select box
        if (config.dragSelect) {
          // Create select box
          rmvpp.selectBox(
            chart,
            function () {
              // Mouse down
            },
            /* function (boxAttrs, box) {// Mouse move
                      boxAttrs.y = 0, boxAttrs.height = height - margin.bottom - 10;
                      box.attr(boxAttrs);
                  }, */
            function (boxAttrs, box) {
              // Mouse up
              var minRange = boxAttrs.x - margin.right,
                maxRange = boxAttrs.x - margin.right + boxAttrs.width;

              if (!xDate) {
                // Ordinal X axis
                var selected = rmvpp.filterOrdinalScale(
                  chartData,
                  minRange,
                  maxRange,
                  x
                );
                var selectedRemark = config.remarkData.filter(d =>
                  selected.includes(d)
                );
                var extent = [
                  fullX(selected[0].category),
                  fullX(selected[selected.length - 1].category)
                ];
              } else {
                // Continuous date X axis
                var extent = [x.invert(minRange), x.invert(maxRange)];
                var selected = data.filter(function (d) {
                  return (
                    d.category.getTime() >= extent[0].getTime() &&
                    d.category.getTime() <= extent[1].getTime()
                  );
                });
                var selectedRemark = config.remarkData.filter(function (d) {
                  return (
                    d.category.getTime() >= extent[0].getTime() &&
                    d.category.getTime() <= extent[1].getTime()
                  );
                });
              }

              if (selected.length > 0) {
                d3.select(chartContainer)
                  .selectAll(".navigator .viewport")
                  .call(brushNav.extent(extent));

                // Trigger for brush select
                var intMap = rmvpp.actionColumnMap(
                  ["category"],
                  columnMap,
                  selected
                );
                $(chartContainer)
                  .parents(".visualisation")
                  .trigger("brushSelect", intMap);

                renderLine(selected, selectedRemark, chartContainer); // Redraw chart
              }
            },
            true
          );
        }
        // Add Zoom Out button if drilled
        d3.select(chartContainer)
          .selectAll(".zoomOut")
          .remove();
        if (chartData.length != data.length) {
          d3.select(chartContainer)
            .insert("div", ":first-child")
            .style("text-align", "center")
            .classed("zoomOut", true)
            .append("i")
            .attr("class", "fa fa-2x fa-search-minus")
            .on("click", function () {
              d3.select(chartContainer)
                .selectAll(".navigator .viewport")
                .call(brushNav.clear());
              d3.select(chartContainer)
                .selectAll(".main .viewport")
                .call(brushNav.clear());
              createTrigger(
                actions,
                columnMap,
                container,
                "brushSelect",
                data,
                THIS.props.plugin.id,
                THIS.props.interactions,
                THIS.props.navigations
              );
              renderLine(
                data,
                config.remarkData,
                chartContainer,
                true,
                config.navigator
              );
            });
        }

        // Highlight markers in a group
        function highlightGroup(element) {
          d3.selectAll(
            $(element)
              .parent()
              .find("circle.marker")
              .toArray()
          )
            .transition()
            .attr("fill", function (d) {
              return rmvpp.reduceBrightness(d.colour, 15);
            })
            .attr("r", function (d) {
              return isNaN(d.value) ? 0 : d3.max([3, +config.pointSize + 2]);
            })
            .duration(200);

          d3.selectAll(
            $(element)
              .parent()
              .find("rect.marker")
              .toArray()
          )
            .transition()
            .attr("fill", function (d) {
              return rmvpp.reduceBrightness(d.colour, 15);
            })
            .attr("width", function (d) {
              return isNaN(d.value) ? 0 : d3.max([2, +config.pointSize + 15]);
            })
            .attr("height", function (d) {
              return isNaN(d.value) ? 0 : d3.max([2, +config.pointSize + 15]);
            })
            .duration(200);
        }

        // Revert colour of markers
        function revertColour(element) {
          d3.selectAll(
            $(element)
              .parent()
              .find("circle.marker")
              .toArray()
          )
            .transition()
            .attr("fill", function (d) {
              return d.colour;
            })
            .attr("r", function (d) {
              return isNaN(d.value) ? 0 : +config.pointSize;
            })
            .duration(200);

          d3.selectAll(
            $(element)
              .parent()
              .find("rect.marker")
              .toArray()
          )
            .transition()
            .attr("fill", function (d) {
              return d.colour;
            })
            .attr("width", function (d) {
              return isNaN(d.value) ? 2 : +config.pointSize + 5;
            })
            .attr("height", function (d) {
              return isNaN(d.value) ? 2 : +config.pointSize + 5;
            })
            .duration(200);
        }
      }

      $(document).ready(function () {
        if (config.toggleCriteria) {
          var splitCriteria = config.toggleCriteria.split("&");
          var show = true;

          for (var j = 0; j < splitCriteria.length; j++) {
            var splittedCriterium = splitCriteria[j].split(/(<|>|=)/);
            var filterField = splittedCriterium[0];
            var operator = splittedCriterium[1];
            var criteriaLength = parseInt(splittedCriterium[2]);

            var length = 0;

            for (var i = 0; i < filters.length; i++) {
              if (filters[i].Name === filterField) {
                length = filters[i].Value.length;
                break;
              }
            }

            if (
              (operator === ">" && length > criteriaLength) ||
              (operator === "=" && length === criteriaLength) ||
              (operator === "<" && length < criteriaLength)
            ) {
            } else {
              show = false;
              break;
            }
          }

          if (show) {
            $(container)
              .find(".lineBar")
              .parent()
              .show();
          } else {
            $(container)
              .find(".lineBar")
              .parent()
              .hide();
          }
        }
      });

      if (config.showHideButton) {
        let hideButtonToggleKey = [".lineBar"];
        rmvpp.hideButton(container, hideButtonToggleKey);
      }
    }

    this.props.setPluginRerender(false, this.props.plugin.id, false, this.props.plugin.isInteraction);
  };

  currentHeight;
  lastContent = undefined;

  updateLastContent = (status) => {
    this.lastContent = status
  }

  render() {
    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(popupPosition, this.props, this.getConfigComponent);
    }

    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(popupPosition, this.props, this.getDataComponent);
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };
    
    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + this.props.plugin.id).css("padding")
      );

      pluginConfig.height =
        this.calculatePluginHeight(this.props.plugin, this.props.settings) -
        pluginContainerPadding * 2;

      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      )
    }

    return (
      <>
        <div style={{height: "100%"}}>
          <div id={this.props.plugin.id}></div>
          {renderContent(
            isRerender,
            this.pluginRender,
            this.props.plugin,
            data,
            columnMap,
            pluginConfig,
            condFormats,
            this.props.setPluginRerender,
            this.lastContent,
            this.updateLastContent,
          )}
          {configComponent}
          {dataComponent}
        </div>
      </>
    );
  }
}
