import React, { Component } from "react";
import { Card, Row, Col } from "antd";
import { API_BASE } from "../config";
import { showNotificationWithIcon } from "../Utils/Notification";
import { get } from "../Utils/WebService";
import RolesUserCard from "../RoleAssignToUser/RolesUserCard";
import CatalogObjectList from "./CatalogObjectList";
import AssignActionToUser from "./AssignActionToUser";

class AssignPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listRoles: true,
      dataList: [],
      currentAccessType: "",
      catalogData: [],
      selectedCatalogObject: "",
      userRightOnObject: []
    };
  }

  selectedUsersData = [];

  confirmAccessTypeChange = (data, accessType) => {
    this.setState({
      dataList: [...data],
      currentAccessType: accessType,
      catalogData: [...this.state.catalogData],
      selectedCatalogObject: this.state.selectedCatalogObject
    });

    if (this.state.selectedCatalogObject != "") {
      this.getUserRightOnObject(this.state.selectedCatalogObject, accessType);
    }
  };

  confirmCatalogData = data => {
    this.setState({
      dataList: [...this.state.dataList],
      currentAccessType: this.state.currentAccessType,
      catalogData: [...data],
      selectedCatalogObject: this.state.selectedCatalogObject
    });
  };

  confirmSetSelectedCatalogObject = id => {
    this.setState({
      dataList: [...this.state.dataList],
      currentAccessType: this.state.currentAccessType,
      catalogData: [...this.state.catalogData],
      selectedCatalogObject: id
    });

    if (this.state.currentAccessType != "") {
      this.getUserRightOnObject(id, this.state.currentAccessType);
    }
  };

  confirmAssignActionToUser = () => {
    this.getUserRightOnObject(
      this.state.selectedCatalogObject,
      this.state.currentAccessType
    );
  };

  getUserRightOnObject = (objectId, accessType) => {
    let url =
      `${API_BASE}/userRight?objectId=` +
      objectId +
      `&accessType=` +
      accessType;

    const successFunc = result => {
      this.setState({
        userRightOnObject: [...result.data]
      });
    };

    const errorFunc = error => {
      showNotificationWithIcon("ERROR!", error.message, "error");
    };

    get(url, successFunc, errorFunc);
  };

  render() {
    const catalogObjectList = (
      <CatalogObjectList
        selectedId={this.state.selectedCatalogObject}
        confirm={this.confirmCatalogData}
        setSelectedObject={this.confirmSetSelectedCatalogObject}
        path={"/"}
        componentWidth={100}
      />
    );
    const assignActionToUser = (
      <AssignActionToUser
        data={this.state.dataList}
        currentAccessType={this.state.currentAccessType}
        currentCatalogObjectId={this.state.selectedCatalogObject}
        selectedData={this.state.userRightOnObject}
        confirm={this.confirmAssignActionToUser}
      />
    );

    return (
      <div id="authorizationPage">
        <Card title="Assign Catalog Object to Users & Roles" bordered={false}>
          <Row gutter={16}>
            <Col span={10}>
              <RolesUserCard
                title={"Catalog Objects"}
                content={catalogObjectList}
                list={this.state.listRoles}
                confirm={this.confirmCatalogData}
              />
            </Col>
            <Col span={14}>
              <RolesUserCard
                title={"Assignment Panel"}
                content={assignActionToUser}
                list={this.state.listRoles}
                confirm={this.confirmAccessTypeChange}
                dropdown={true}
                dropdownValues={["ROLE", "USER"]}
                selectedId={this.state.selectedCatalogObject}
              />
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

export default AssignPage;
