import React from "react";
import i18n from "../../../Utils/i18next";
import Text from "../Text/Text";
import { setFormulaEditorContent } from "./FormulaEditorAction";
import { connect } from "react-redux";

/**
 * This component consists of:
 *  - Description and usage of expression parameters
 */
class ExpressionParametersDecription extends React.Component {
    constructor() {
        super();
        this.state = {
            selectedFormula: {}
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.state.FormulaEditorReducer === this.props.state.FormulaEditorReducer) {
            return;
        }

        let flag = false;
        let newState = { ...this.state };

        if (nextProps.state.FormulaEditorReducer.contentObject.selectedFormula !== this.state.selectedFormula) {
            newState.selectedFormula = nextProps.state.FormulaEditorReducer.contentObject.selectedFormula;
            flag = true;
        }

        if (
            flag === true &&
            nextProps.state.FormulaEditorReducer.contentObject.visible === true
        ) {
            this.setState(newState);
        }
    }

    render() {
        return (
            <div style={{ width: "100%", padding: "8px 16px" }}>
                <Text
                    style={{
                        fontSize: "1rem",
                        display: "block",
                        width: "100%",
                        marginBottom: "8px",
                        color: "#225f8f"
                    }}
                >
                    {
                        this.state.selectedFormula && this.state.selectedFormula.name
                            ? this.state.selectedFormula.name
                            : i18n.t("FormulaEditor.Titles.DescriptionArea")
                    }
                </Text>
                <Text
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        paddingBottom: "8px"
                    }}
                >
                    {i18n.t("FormulaEditor.ExpressionParametersDescription")}
                </Text>
                <Text
                    style={{
                        fontSize: "1rem",
                        display: "block",
                        width: "100%",
                        marginBottom: "8px",
                        color: "#225f8f"
                    }}
                >
                    {
                        this.state.selectedFormula && this.state.selectedFormula.name
                            ? this.state.selectedFormula.name
                            : i18n.t("FormulaEditor.Usage")
                    }
                </Text>
                <Text
                    style={{
                        display: "block",
                        width: "100%",
                        color: "#555"
                    }}
                >
                    <span dangerouslySetInnerHTML={{ __html: i18n.t("FormulaEditor.ExpressionParametersUsageDesc") }} />
                </Text>

                
            </div>
        )
    }
}

/**
 * Set action method to props
 */
const mapDispatchToProps = {
    setFormulaEditorContent,
};

/**
 * Set store objects to state
 * 
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
    return {
        state: state,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpressionParametersDecription)