import React, { Component } from "react";

class TitleWithIcon extends Component {
  render() {
    return (
      <h4
        className={this.props.titleStyle}
        style={this.props.style}
      >
        {
          this.props.icon ? (
            <i
              class={this.props.icon ? "far fa-" + this.props.icon : ""}
              style={{
                fontSize: "18px",
                position: "relative",
                color: this.props.color ? this.props.color : ""
              }}
            ></i>
          ) : this.props.color ? (
            <span id={this.props.id} className={this.props.className} style={{ backgroundColor: this.props.color }}></span>
          ) : null
        }
        <span style={{ marginLeft: "5px" }}>{this.props.title}</span>
      </h4>
    );
  }
}

export default TitleWithIcon;
