import { store } from "../../index";
import $ from "jquery"

/*
 * Controls is plugin drilldowned
 */
export const isPluginDrilldowned = (pluginId) => {
  let reduxState = store.getState();
  let triggeredDrillDownPlugin =
    reduxState.DrillDownReducer.triggeredDrillDowns.get(pluginId);
  let isPluginDrilldowned = triggeredDrillDownPlugin ? true : false;
  let hasPluginHasBreadcrumb = isPluginDrilldowned
    ? triggeredDrillDownPlugin.filters.length > 0
      ? true
      : false
    : false;

  return hasPluginHasBreadcrumb;
};

/*
* Calculates title max row size by plugins grid height
*/
export const calculateTitleSize = (plugin) => {
  let pluginGridHeight = plugin.h;
  let pluginId = plugin.id;

  if (pluginGridHeight === 1) {
    $(`#title-${pluginId}`).addClass("filter-title-h1");
    $(`#title-${pluginId}`).removeClass("filter-title-h2");
  } else if (pluginGridHeight === 2) {
    $(`#title-${pluginId}`).addClass("filter-title-h2");
    $(`#title-${pluginId}`).removeClass("filter-title-h1");
  } else {
    $(`#title-${pluginId}`).removeClass("filter-title-h1").removeClass("filter-title-h2");
  }
};

/*
* Calculates plugin's inline height without title and margins
*/
export const calculatePluginInlineHeight = (divId) => {
  let holder = $("[index=" + divId + "]"); //main div
  let pluginKey = holder.attr("pluginKey");
  let title = $("#title-" + divId);
  let isTitleNotAvailable = title.length === 0 && $(title[0]).height() !== "0" ? true : false
  let titleHeight = isTitleNotAvailable 
    ? 0 
    : title.height() === 0 
      ? 0
      : title.height();
  let isTitleHasMargin = $("#title-" + divId).css("margin-bottom") === "10px" ? true : false
  let titleHeightMargin = isTitleNotAvailable 
    ? 0 
    : isTitleHasMargin 
      ? 15
      : 2
  let isPluginHasBreadcrumbs = holder.find(".left-tools").length > 0 ? true : false
  let pluginBreadcrumbs = isPluginHasBreadcrumbs ? holder.find(".left-tools").height() + 16 : 0
  let pluginInlineHeight = holder.height() - titleHeight - titleHeightMargin - pluginBreadcrumbs; //Holder height - title height - 19 px for margins and paddings
  let pluginsCauseOverflow = ["pivot-table", "scatter-chart", "table"];

  return pluginInlineHeight - (pluginsCauseOverflow.includes(pluginKey) && 5);
}

/*
* Appends calculated plugin's height
*/
export const calculateAndAppendPluginHeight = (divId) => {
  let height = calculatePluginInlineHeight(divId)

  $("#plugin-" + divId).css("height", height)
}

/*
* Prepares plugin container etc before plugin error and no data
*/
export const preparePluginsContainerBeforePluginError = (divId) => {
  let pluginInlineHeight = calculatePluginInlineHeight(divId)

  $("#" + divId).css("display", "none");
  $("#pluginDateFilter-" + divId).css("display", "none");
  $("#pivot-enhance-" + divId).css("display", "none");
  $("#plugin-" + divId).find(".pivot-sub-area").css("height", "100%");
  $("#plugin-" + divId).css("height", pluginInlineHeight)
}

/*
* Prepares plugin container etc after plugin error and no data
*/
export const preparePluginsContainerAfterPluginError = (divId) => {
  let pluginInlineHeight = calculatePluginInlineHeight(divId)

  $("#" + divId).css("display", "block");
  $("#pluginDateFilter-" + divId).css("display", "block");
  $("#pivot-enhance-" + divId).css("display", "block");
  $("#plugin-" + divId).find(".pivot-sub-area").css("height", "0");
  $("#plugin-" + divId).css("height", pluginInlineHeight)
}
