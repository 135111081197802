import uuid from "react-uuid";
import { store } from "../..";
import { API_BASE } from "../../config";
import {
  compare,
  convertHTMLRuletoRule,
} from "../../ui/ConditionalFormatting/ConditionalFormattingCommon";
import { InsightsConfig } from "../../ui/Plugins/RenderJs/config";
import { base64ToArrayBuffer, saveByteArray } from "../Download";
import { deepCopy } from "../Global";
import { showNotificationWithIcon } from "../Notification";
import { get, post, put } from "../WebService";
import i18n from "../i18next";
import axios from "axios";
import Cookies from "js-cookie";

export const EXCEL_EXPORT_PROGRESS = {
  DONE: "DONE",
  STARTED: "STARTED",
  FAILED: "FAILED",
};

const lockedCondFormatTarget = new Set([
  "tree-map",
  "pie-chart",
  "pie-chart-enhanced",
  "multi-axis-line-chart",
]);
let columnsInModel;

export const generalExcelExport = (
  originalDashboard,
  model = undefined,
  excelExportProgress,
) => {
  columnsInModel = prepareColumnsInModel(model);

  let dashboard = deepCopy(originalDashboard);
  let payload = {};

  if (dashboard?.title === "") {
    dashboard.title = "dashboard";
  }

  payload["dashboardTitle"] = dashboard?.title;
  payload["pluginList"] = [];

  // Adds the excel export data of plugins to the payload if the plugin's columns are not all disabled.
  let addedPlugins = new Set();

  dashboard.plugins.forEach((plugin) => {
    if (!addedPlugins.has(plugin.id)) {
      addedPlugins.add(plugin.id);
      
      let columns = Object.values(plugin.columnMap)?.map(field => field?.data);
      columns =  columns.flat();

      let isDisabledColumnControl = columns.some(c => c.isDisabledColumn !== true && c.locationFieldName !== "hidden")

      if (isDisabledColumnControl) {
        let sheet = pluginExcelSheet(plugin);

        if (sheet !== undefined) {
          payload["pluginList"].push(sheet);
        }
      }
    }
  });

  if (payload["pluginList"].length > 0) {
    generateAndDownloadExcel(payload, excelExportProgress);
  } else {
    excelExportProgress(EXCEL_EXPORT_PROGRESS.FAILED);
    showNotificationWithIcon(
      i18n.t("ERROR"),
      i18n.t("Dashboard.ExcelExport.ExportPluginCouldNotFound"),
      "warning"
    );
  }
};

const pluginExcelSheet = (plugin) => {
  let result = {};

  let columns = [];
  let resultData = [];

  let useColumnMap = plugin.columnMapForPlugin;

  if (plugin.drillDownColumnMap) {
    useColumnMap = plugin.drillDownColumnMap;
  }

  if (
    useColumnMap === undefined ||
    plugin.data === undefined ||
    plugin.data.length === 0
  ) {
    return undefined;
  }

  getColumns(columns, useColumnMap);

  let pieChartKeys = new Set(["pie-chart", "pie-chart-enhanced"]);
  let pluginId = plugin.id;
  let reduxState = store.getState();
  let hasPluginDrilldown = reduxState.DrillDownReducer.drillDowns.has(pluginId)
    ? true
    : false;
  let drillDownsReducer =
    hasPluginDrilldown && reduxState.DrillDownReducer.drillDowns.get(pluginId)
      ? reduxState.DrillDownReducer.drillDowns.get(pluginId)
      : {};

  if (
    pieChartKeys.has(plugin["key"]) &&
    drillDownsReducer?.drillDownTypes === "explode-pie-chart"
  ) {
    createHeaderColumns(plugin.config, resultData, columns);
    preparePieChartDoublePie(plugin, resultData, columns);
  } else if (plugin["key"] === "map-choropleth") {
    let pluginLayer = { layer: 1, value: undefined };
    let pluginLayers = reduxState.MapChoroplethDetailReducer?.pluginLayers;
    if (pluginLayers && pluginLayers[pluginId]) {
      pluginLayer = pluginLayers[pluginId] || 1;
    }

    prepareMapChoropleth(plugin, resultData, columns, pluginLayer, result);
  } else if (!pieChartKeys.has(plugin["key"])) {
    createHeaderColumns(plugin.config, resultData, columns);
    createPluginData(
      plugin.data,
      plugin.config,
      plugin.conditionalFormats,
      resultData,
      columns,
      plugin.key
    );
  } else if (pieChartKeys.has(plugin["key"])) {
    let reduxState = store.getState()
    let data = reduxState.PieChartDataReducer.contentObject.pieChartDatas.has(plugin.id) ? deepCopy(reduxState.PieChartDataReducer.contentObject.pieChartDatas.get(plugin.id)) : []
    let pluginDataWithSelectedSlices = data
    let categoryColumn = columns.find(col => col.locationFieldName === "category")

    for (let i = 0; i < data.length; i++) {
      if (data[i].category == undefined && categoryColumn !== undefined) {
        data[i].category = data[i].formattedCategory
        data[i][categoryColumn.displayName] = data[i].formattedCategory
      }
    }

    createHeaderColumns(plugin.config, resultData, columns);
    createPluginData(
      pluginDataWithSelectedSlices,
      plugin.config,
      plugin.conditionalFormats,
      resultData,
      columns,
      plugin.key
    );
  }

  result["title"] = plugin.config.title || "";
  result["titleFontSize"] = plugin.config.titleFontSize || 15;
  result["titleFont"] = plugin.config.titleFont || "Verdana";
  result["titleColour"] = plugin.config.titleColour;
  result["titleFontStyle"] = plugin.config.titleFontStyle;
  result["titleFontWeight"] = plugin.config.titleFontWeight;
  result["titleTextDecor"] = plugin.config.titleTextDecor;
  result["timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;

  result["columnCount"] = result["columnCount"]
    ? result["columnCount"]
    : columns.length;
  result["data"] = resultData;

  return result;
};

//Finds column count for title and date merge
const findColumnCount = (columnMap) => {
  let columnMapKeys = Object.keys(columnMap);
  let columnCount = 0;

  for (let i = 0; i < columnMapKeys.length; i++) {
    columnCount += columnMap[columnMapKeys[i]].length;
  }

  return columnCount;
};

const getColumns = (columns, columnMap) => {
  let columnMapKeys = Object.keys(columnMap);

  for (let i = 0; i < columnMapKeys.length; i++) {
    let columnMapKey = columnMapKeys[i];

    if (columnMapKey !== "hidden") {
      if (Array.isArray(columnMap[columnMapKey])) {
        columnMap[columnMapKey].forEach((column, index) => {
          columns.push(column);
        });
      } else {
        if (Object.keys(columnMap[columnMapKey]).length > 0) {
          let newColumn = { ...columnMap[columnMapKey] };

          if (!(newColumn["data"] && newColumn["data"].length === 0)) {
            newColumn["columnMapKey"] = columnMapKey;
            columns.push(newColumn);
          }
        }
      }
    }
  }
};

// Column title
const createHeaderColumns = (
  config,
  pluginData,
  columns,
  shiftColumn = 0,
  clickedCategory = undefined
) => {
  if (clickedCategory !== undefined) {
    let cell = { r: 1, c: shiftColumn, value: clickedCategory, mergeCell: 2 };
    let format = {};

    format["bold"] = true;
    format["fontName"] = config.columnHeaderTextFont;
    format["fontSize"] = config.columnHeaderTextFontSize;
    format["textAlign"] = "center";

    cell["format"] = format;

    pluginData.push(cell);
  }

  let hiddenColumnCount = 0;

  columns.forEach((column, index) => {
    if (!column["hide"]) {
      let cell = {
        r: 2,
        c: index + shiftColumn - hiddenColumnCount,
        value: column.displayName,
      };
      let format = {};

      let headerBackground = config.themeColour
        ? config.themeColour
        : config.paletteColours && config.paletteColours.length > 0
        ? config.paletteColours[0]
        : InsightsConfig.Palettes[config.colours]
        ? InsightsConfig.Palettes[config.colours][0]
        : "#ffffff";

      format["color"] = config.columnHeaderTextColor
        ? config.columnHeaderTextColor
        : "#000000";
      format["backgroundColor"] = headerBackground;
      format["bold"] = true;
      format["fontName"] = config.columnHeaderTextFont;
      format["fontSize"] = config.columnHeaderTextFontSize;

      cell["format"] = format;
      pluginData.push(cell);
    } else {
      hiddenColumnCount++;
    }
  });
};

// Row data
const createPluginData = (
  data,
  config,
  condFormats,
  pluginData,
  columns,
  pluginKey,
  shiftColumn = 0
) => {
  let linkField =
    !config.linkField || config.linkField === ""
      ? []
      : config.linkField.split(";");
  let linkDesc =
    !config.linkDesc || config.linkDesc === ""
      ? []
      : config.linkDesc.split(";");
  let linkFieldMap = createHashMap(linkField, linkDesc);
  let imgField = config.imgField;
  let hashDescription =
    config.tableDescription && config.tableDescription.split("#");
  let totalByColumns = new Map();

  if (condFormats !== undefined) {
    condFormats = convertFormatConditionalFormatting(condFormats, columns);
  } else {
    condFormats = [];
  }

  data.forEach((d, r) => {
    //For add column displayname field to data
    columns.forEach((column) => {
      if (!column["hide"] && d[column["displayName"]] === undefined) {
        d[column["displayName"]] = d[column["locationFieldName"]];
      }
    });

    let hiddenColumnCount = 0;

    columns.forEach((column, c) => {
      if (!column["hide"]) {
        let cell = {
          r: r + 3,
          c: c + shiftColumn - hiddenColumnCount,
          dataType: column.dataType,
          dataFormat: column.DataFormat,
          affixAlign: column["affixAlign"] || undefined,
          affixValue: column["affixValue"] || undefined,
        };

        let format = {};
        let condFormatStyles = {};

        condFormats.forEach((cf) => {
          let compareObject = compare(d, cf);

          if (lockedCondFormatTarget.has(pluginKey)) {
            cf.TargetName = "AllColumns";
          }

          if (
            compareObject.status === true &&
            (cf.TargetName === undefined ||
              cf.TargetName === "AllColumns" ||
              column.name === cf.TargetName ||
              column.displayName === cf.TargetName)
          ) {
            condFormatStyles["color"] = cf.Style.colour;
            condFormatStyles["background"] = cf.Style.background.colour;
            condFormatStyles["italic"] = cf.Style.text.italic ? true : false;
            condFormatStyles["bold"] = cf.Style.text.bold ? true : false;
            condFormatStyles["underline"] = cf.Style.text.underline
              ? true
              : false;
          }
        });

        if (Object.keys(condFormatStyles).length > 0) {
          format["color"] = condFormatStyles.color;
          format["backgroundColor"] = condFormatStyles.background;
          format["italic"] = condFormatStyles.italic;
          format["bold"] = condFormatStyles.bold;
          format["underline"] = condFormatStyles.underline;
        }

        format["align"] = column.align ? column.align : "left";
        format["fontName"] = config.font || config.valueFontFamily;
        format["fontSize"] = config.fontSize || config.valueFontSize;

        if (!linkField.includes(column.displayName)) {
          if (imgField !== column.displayName) {
            let value = d[column.displayName];

            if (column.columnMapKey) {
              value = d[column.columnMapKey];
            }

            if (["date", "datetime", "timestamp"].includes(column.dataType)) {
              value = new Date(value).getTime();
            }

            d[column.displayName] = value;
            //cell["value"] = getFormattedValue(column, value);
            cell["value"] = value;

            let total = totalByColumns.get(column.displayName);

            if (total !== undefined) {
              let totalValue =
                total +
                parseFloat(
                  d[column.displayName] === "" ? 0 : d[column.displayName]
                );
              totalByColumns.set(column.displayName, totalValue);
            } else {
              let totalValue = parseFloat(
                d[column.displayName] === "" ? 0 : d[column.displayName]
              );
              totalByColumns.set(column.displayName, totalValue);
            }
          }
          // else {
          //     let imgSrc = d[column.displayName] == "" ? "" : '<img src=' + d[column.displayName] + ' width=' + column.colWidth + ' height=' + config.RowHeight + '></img>';

          //     td.append(imgSrc);
          // }
        } else {
          let linkData = d[column.displayName];
          let linkDesc = linkFieldMap[column.displayName];

          if (linkData === null || linkData === undefined || linkData === "") {
            cell["value"] = "";
          } else {
            cell["link"] = linkData;
            cell["value"] = linkDesc;
            cell["isLink"] = true;
          }
        }

        cell["format"] = { ...format };
        pluginData.push(cell);
      } else {
        hiddenColumnCount++;
      }
    });
  });

  let lastCell = pluginData[pluginData.length - 1];
  let lastRowCount = lastCell.r;

  const hasAggRule = (column) => {
    if (column.Code?.includes("sum(") || column.value?.includes("sum(")) {
      return true;
    }

    if (!column.aggrRule) {
      let columnKey = `${column.aliasName}-${column.tableAliasName}`;

      if (columnsInModel.has(columnKey)) {
        let modelColumn = columnsInModel.get(columnKey);

        column["aggrRule"] = modelColumn["aggrRule"];
        column["aggregatable"] = modelColumn["aggregatable"];
      }
    }

    if (column.aggrRule?.includes("sum(")) {
      return true;
    }

    return false;
  };

  let hiddenColumnCount = 0;

  columns.forEach((column, c) => {
    if (!column["hide"]) {
      let total = totalByColumns.get(column.displayName);
      let isTableTotal = false;
      let isNumber = !Number.isNaN(total);
      let isIncludeTotal =
        column.isDataIncludeTotal === true || hasAggRule(column);

      let tableTotalControl = (pluginKey && pluginKey === "table" && config.enableTotal !== "None" && config.enableTotal !== false)
      let pivotTableTotalControl = (pluginKey && pluginKey === "pivot-table" && config.showSum !== "None" && config.showSum !== false)
      let isPluginNotPivotOrTable = pluginKey && (pluginKey === "pivot-table" || pluginKey === "table")

      if (tableTotalControl) {
        isTableTotal = true;
      }

      if (pivotTableTotalControl) {
        isTableTotal = true
      }

      if (!isPluginNotPivotOrTable) {
        isTableTotal = true
      }

      if (isTableTotal && isNumber && isIncludeTotal) {
        let format = {};

        format["color"] = "#000000";
        format["bold"] = true;
        format["align"] = column.align ? column.align : "left";
        format["fontName"] = config.font || config.valueFontFamily;
        format["fontSize"] = config.fontSize || config.valueFontSize;

        //let totalValue = getFormattedValue(column, total);
        let totalValue = total;
        let cell = {
          r: lastRowCount + 1,
          c: c + shiftColumn - hiddenColumnCount,
          value: totalValue,
          format: format,
          dataType: column.dataType,
          dataFormat: column.DataFormat,
          affixAlign: column["affixAlign"] || undefined,
          affixValue: column["affixValue"] || undefined,
        };

        pluginData.push(cell);
      } else {
        let cell = {
          r: lastRowCount + 1,
          c: c + shiftColumn - hiddenColumnCount,
          value: "",
          format: {},
        };
        pluginData.push(cell);
      }
    } else {
      hiddenColumnCount++;
    }
  });
};

const generateAndDownloadExcel = (payload, excelExportProgress) => {
  excelExportProgress(EXCEL_EXPORT_PROGRESS.STARTED);

  let url = `${API_BASE}/export/excel`;

  const success = (response) => {
    let excel = response.data.file;
    let excelBytes = base64ToArrayBuffer(excel);

    saveByteArray([excelBytes], payload["dashboardTitle"] + ".xlsx");
    excelExportProgress(EXCEL_EXPORT_PROGRESS.DONE);
  };

  const error = (err) => {
    excelExportProgress(EXCEL_EXPORT_PROGRESS.FAILED);
    console.log(err);
  };

  const sendDataInChunks = async (payload, endpoint) => {
    let payloadStruct = deepCopy(payload);
    payloadStruct["transactionId"] = uuid();

    for (let index = 0; index < payloadStruct.pluginList.length; index++) {
      payloadStruct.pluginList[index]["pluginIndex"] = index;
      payloadStruct.pluginList[index]["totalChunk"] =
        payloadStruct.pluginList[index].data.length;
      payloadStruct.pluginList[index].data = [];
    }

    let chunkCount = 0;
    let successChunkCount = 0;

    const chunkDone = (transactionId) => {
      successChunkCount += 1;

      if (successChunkCount === chunkCount) {
        getExcel(transactionId)
      }
    };

    const getExcel = (transactionId) => {
      get(url + "/" + transactionId, success, error, false)
    }

    const mainSuccess = async () => {
      const chunkSize = 10000;

      let pluginIndex = 0;
      let promises = [];

      for (let plugin of payload.pluginList) {
        let largeData = plugin.data;
        let largeDataLength = largeData.length;

        for (let i = 0; i < largeDataLength; i += chunkSize) {
          const chunk = largeData.slice(i, i + chunkSize);
          const body = {
            pluginIndex: pluginIndex,
            transactionId: payloadStruct["transactionId"],
            chunkIndex: i / chunkSize,
            chunk: chunk,
          };

          chunkCount += 1

          promises.push(
            axios
              .put(endpoint, body, {
                Authorization: `Bearer ${Cookies.get("ORA_BIPS_NQID")}`,
                "Content-Type": "application/json",
              })
              .then(() => chunkDone(payloadStruct["transactionId"]))
              .catch(() => chunkDone(payloadStruct["transactionId"]))
          );
        }

        pluginIndex++;
      }

      await Promise.all(promises);
    };

    post(url, payloadStruct, mainSuccess, error, false);
  };

  sendDataInChunks(payload, url);
};

// Create hashmap
const createHashMap = (keys, values) => {
  const hashMap = {};

  if (keys.length !== values.length) {
    console.log("Arrays should have the same length to create a hash map.");
  }

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = values[i] ? values[i] : values[values.length - 1];

    hashMap[key] = value;
  }

  return hashMap;
};

/**
 * To convert general conditional format object to plugin requirements.
 */
const convertFormatConditionalFormatting = (condFormats, columns) => {
  let condFormatList = [];

  condFormats.map((condItem) => {
    condItem.targetColumns.map((targetColumn) => {
      let conditionalFormat = {};
      let columnsMap = new Map();
      let condFormatTargetId = "columns";
      let columnsFieldNameMap = new Map(); //to set column-locationFieldName pairs so we can get locationFieldName correctly

      columns.map((column) => {
        columnsMap.set(column.uniqeColumnId, column);
        columnsFieldNameMap.set(column.uniqeColumnId, column.locationFieldName);
      });

      let targetIndex = columns
        .map((e) => {
          return e.uniqeColumnId;
        })
        .indexOf(targetColumn.uniqeColumnId);

      if (targetIndex !== -1) {
        condFormatTargetId = "columns" + targetIndex;
      }

      let condItemLeftRuleColumnId = condItem.rule.leftRule.rule
        .replace("{", "")
        .replace("}", "")
        .replaceAll("_", "-");

      conditionalFormat.RightRule = condItem.rule.rightRule.rule;
      conditionalFormat.LeftRule = condItem.rule.leftRule.rule;
      conditionalFormat.LeftRuleColumnName =
        condItem.rule.leftRule.ruleColumnName === "{undefined}"
          ? convertHTMLRuletoRule(condItem.rule.leftRule).ruleColumnName
          : condItem.rule.leftRule.ruleColumnName;
      conditionalFormat.RightRuleColumnName =
        condItem.rule.rightRule.ruleColumnName;
      conditionalFormat.Columns = columnsMap;
      conditionalFormat.TargetID = condFormatTargetId;
      conditionalFormat.TargetName =
        targetColumn.TargetName || targetColumn.Name;
      conditionalFormat.Operator = condItem.rule.operator;
      conditionalFormat.id = condItem.id;
      conditionalFormat.locationFieldName = columnsFieldNameMap.get(
        condItemLeftRuleColumnId
      );
      conditionalFormat.leftRuleColumnId = condItemLeftRuleColumnId;

      conditionalFormat.Style = {
        colour: condItem.options.color,
        icon: condItem.options.icon,
        background: { colour: condItem.options.backgroundColor },
      };

      if (condItem.options.text) {
        conditionalFormat.Style.text = {
          bold: condItem.options.text.bold,
          italic: condItem.options.text.italic,
        };
      } else {
        conditionalFormat.Style.text = {
          bold: false,
          italic: false,
        };
      }

      condFormatList.push(conditionalFormat);
    });
  });

  return condFormatList;
};

/** Prepare columns and datas for double pie chart */
const preparePieChartDoublePie = (plugin, resultData, columns) => {
  let data = [...plugin.dataForExport];
  let mainData = [];
  let subData = [];
  let clickedCategory = "";

  data.forEach((datum) => {
    if (datum["isDataProcess"]) {
      mainData.push(datum);

      if (subData?.length === 0) {
        subData = [...datum.subs];
      }
    }

    if (datum?.category === plugin?.clickedData?.category) {
      subData = [...plugin.clickedData.subs];
      clickedCategory = datum?.category;
    }
  });

  createPluginData(
    mainData,
    plugin.config,
    plugin.conditionalFormats,
    resultData,
    columns,
    plugin.key
  );

  let shiftColumn = columns.length + 1;

  let drillColumn = plugin.drillDowns.drillDownColumnsForParentColumns.get(
    plugin.columnMap.category.data[0].uniqeColumnId
  )[0];
  let drillColumns = [...columns];

  drillColumns = drillColumns.map((d) => {
    if (d.locationFieldName === "category") {
      d = drillColumn;
    }

    return d;
  });

  createHeaderColumns(
    plugin.config,
    resultData,
    drillColumns,
    shiftColumn,
    clickedCategory
  );
  createPluginData(
    subData,
    plugin.config,
    plugin.conditionalFormats,
    resultData,
    drillColumns,
    plugin.key,
    shiftColumn
  );
};

/**
 * Prepares data and columns for map choropleth
 * @param {*} plugin
 * @param {*} resultData
 * @param {*} columns
 * @param {*} pluginLayer
 * @param {*} result
 */
const prepareMapChoropleth = (
  plugin,
  resultData,
  columns,
  pluginLayer,
  result
) => {
  const checkColumnValidationAndAddHeader = (headerColumns, column) => {
    if (column && Object.keys(column).length > 0 && !column.isDisabledColumn) {
      headerColumns.push(column);
    }
  };

  /**
   * Checks column for add row
   * @param {*} layerDataRow
   * @param {*} column
   * @param {*} datum
   * @param {*} parse
   */
  const checkColumnValidationAndAddDataRow = (
    layerDataRow,
    column,
    datum,
    parse = false
  ) => {
    if (column && Object.keys(column).length > 0 && !column.isDisabledColumn) {
      let data = datum[column.displayName];

      if (parse) {
        data = parseFloat(data);
      }

      layerDataRow[column.displayName] = data;
    }
  };

  let currIndex = pluginLayer.layer;
  let layerValue = pluginLayer.value;

  if (!currIndex || currIndex === 1) {
    let codeColumns = plugin?.columnMap?.code?.data;

    if (codeColumns && codeColumns.length > 0) {
      if (codeColumns.length === 1) {
        createHeaderColumns(plugin.config, resultData, columns);
        createPluginData(
          plugin.data,
          plugin.config,
          plugin.conditionalFormats,
          resultData,
          columns,
          plugin.key
        );
      } else {
        let layerCode = codeColumns[0];
        let layerDesc = plugin?.columnMap?.desc?.data
          ? plugin?.columnMap?.desc?.data[0] || {}
          : {};
        let layerMeasureList = plugin?.columnMap?.measure?.data
          ? plugin?.columnMap?.measure?.data
          : [];
        let layerHidden = plugin?.columnMap?.hidden?.data
          ? plugin?.columnMap?.hidden?.data[0] || {}
          : {};
        let layerVary = plugin?.columnMap?.vary?.data
          ? plugin?.columnMap?.vary?.data[0] || {}
          : {};

        let headerColumns = [];

        checkColumnValidationAndAddHeader(headerColumns, layerCode);
        checkColumnValidationAndAddHeader(headerColumns, layerDesc);

        layerMeasureList.forEach((layerMeasure) => {
          checkColumnValidationAndAddHeader(headerColumns, layerMeasure);
        });

        checkColumnValidationAndAddHeader(headerColumns, layerHidden);
        checkColumnValidationAndAddHeader(headerColumns, layerVary);

        createHeaderColumns(plugin.config, resultData, headerColumns);

        result["columnCount"] = headerColumns.filter((c) => !c["hide"]).length;

        let layerData = new Map();

        for (let datum of plugin.data) {
          let layerDataRow = {};

          if (layerData.has(datum[layerCode.displayName])) {
            layerDataRow = layerData.get(datum[layerCode.displayName]);

            layerMeasureList.forEach((layerMeasure) => {
              layerDataRow[layerMeasure.displayName] =
                parseFloat(layerDataRow[layerMeasure.displayName]) +
                parseFloat(datum[layerMeasure.displayName]);
            });

            layerData.set(layerCode.displayName, layerDataRow);
          } else {
            checkColumnValidationAndAddDataRow(layerDataRow, layerCode, datum);
            checkColumnValidationAndAddDataRow(layerDataRow, layerDesc, datum);

            layerMeasureList.forEach((layerMeasure) => {
              checkColumnValidationAndAddDataRow(
                layerDataRow,
                layerMeasure,
                datum,
                true
              );
            });

            checkColumnValidationAndAddDataRow(
              layerDataRow,
              layerHidden,
              datum
            );
            checkColumnValidationAndAddDataRow(layerDataRow, layerVary, datum);

            delete layerDataRow[undefined];

            layerData.set(datum[layerCode.displayName], layerDataRow);
          }
        }

        plugin.data = [...layerData.values()];

        createPluginData(
          plugin.data,
          plugin.config,
          plugin.conditionalFormats,
          resultData,
          headerColumns,
          plugin.key
        );
      }
    }
  } else {
    let codeColumns = plugin?.columnMap?.code?.data;

    if (codeColumns && codeColumns.length > 0) {
      let drilledDataCode = layerValue[codeColumns[0].displayName];

      let baseLayerCode = codeColumns[0];
      let baseLayerDesc = plugin?.columnMap?.desc?.data
        ? plugin?.columnMap?.desc?.data[0] || {}
        : {};

      let layerCode = codeColumns[1];
      let layerDesc = plugin?.columnMap?.desc?.data
        ? plugin?.columnMap?.desc?.data[1] || {}
        : {};
      let layerMeasureList = plugin?.columnMap?.measure?.data
        ? plugin?.columnMap?.measure?.data
        : [];
      let layerHidden = plugin?.columnMap?.hidden?.data
        ? plugin?.columnMap?.hidden?.data[1] || {}
        : {};
      let layerVary = plugin?.columnMap?.vary?.data
        ? plugin?.columnMap?.vary?.data[1] || {}
        : {};

      let headerColumns = [];

      baseLayerCode["hide"] = true;
      baseLayerDesc["hide"] = true;

      checkColumnValidationAndAddHeader(headerColumns, baseLayerCode);
      checkColumnValidationAndAddHeader(headerColumns, baseLayerDesc);

      checkColumnValidationAndAddHeader(headerColumns, layerCode);
      checkColumnValidationAndAddHeader(headerColumns, layerDesc);

      layerMeasureList.forEach((layerMeasure) => {
        checkColumnValidationAndAddHeader(headerColumns, layerMeasure);
      });

      checkColumnValidationAndAddHeader(headerColumns, layerHidden);
      checkColumnValidationAndAddHeader(headerColumns, layerVary);

      createHeaderColumns(plugin.config, resultData, headerColumns);

      result["columnCount"] = headerColumns.filter((c) => !c["hide"]).length;

      let layerData = [];

      for (let datum of plugin.data) {
        //Double eq for datum string - drill data code numeric.
        if (datum[codeColumns[0].displayName] == drilledDataCode) {
          let layerDataRow = {};

          checkColumnValidationAndAddDataRow(
            layerDataRow,
            baseLayerCode,
            datum
          );
          checkColumnValidationAndAddDataRow(
            layerDataRow,
            baseLayerDesc,
            datum
          );

          checkColumnValidationAndAddDataRow(layerDataRow, layerCode, datum);
          checkColumnValidationAndAddDataRow(layerDataRow, layerDesc, datum);

          layerMeasureList.forEach((layerMeasure) => {
            checkColumnValidationAndAddDataRow(
              layerDataRow,
              layerMeasure,
              datum,
              true
            );
          });

          checkColumnValidationAndAddDataRow(layerDataRow, layerHidden, datum);
          checkColumnValidationAndAddDataRow(layerDataRow, layerVary, datum);

          delete layerDataRow[undefined];

          layerData.push(layerDataRow);
        }
      }

      plugin.data = [...layerData];

      createPluginData(
        plugin.data,
        plugin.config,
        plugin.conditionalFormats,
        resultData,
        headerColumns,
        plugin.key
      );
    }
  }
};

/**
 * Prepares columns-table key and column objects in model.
 * @param {*} model
 * @returns
 */
const prepareColumnsInModel = (model) => {
  let columnsInModel = new Map();

  if (model.tables && model.tables.length > 0) {
    model.tables.forEach((table) => {
      if (table.columns && table.columns.length > 0) {
        table.columns.forEach((column) => {
          let columnKey = `${column.aliasName}-${table.aliasName}`;

          columnsInModel.set(columnKey, column);
        });
      }
    });
  }

  return columnsInModel;
};
