import React, { Component } from "react";
import * as d3 from "d3";
import $ from "jquery";
import { rmvpp } from "../../RenderJs/rmvpp";
import GlassedTitleConfiguration from "./GlassedTitleConfiguration";
import i18n from "../../../../Utils/i18next";
import {
  onComponentWillMount,
  onComponentWillReceiveProps,
  getColumnMapping,
  calculatePluginHeight
} from "../common";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import {
  renderConfig,
  renderData,
  renderNavigation
} from "../PluginsCommonComponents";
import GlassedTitleData from "./GlassedTitleData";
import { renderContent } from "../renderContent";
import NavigationContent from "../../../Navigation/NavigationContent";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";

const data = JSON.parse(
  `[{"value":"Aralık"},{"value":"Ağustos"},{"value":"Ekim"},{"value":"Eylül"},{"value":"Haziran"},{"value":"Kasım"},{"value":"Mart"},{"value":"Mayıs"},{"value":"Nisan"},{"value":"Ocak"},{"value":"Temmuz"},{"value":"Şubat"}]`
);
const config = JSON.parse(
  `{"width":800,"height":300,"glassWidth":"80%","glassHeight":"50%","title_":"deneme","fontStyle":"color: black; font-size: 3em; letter-spacing: 0.50em;","showLastValue":false,"toggleCriteria":false,"showHideButton":false,"summary":"","backgroundColor":"rgb(255,255,255)","refresh":0}`
);
const columnMap = JSON.parse(
  `{"value":{"Code":"\'ucusAlias\'.\'ay_adiAlias\'","Name":"ay adi","DataType":"varchar","Table":"ucusAlias","Measure":"none","ID":"ucusAlias.ay adi","SubjectArea":"BIGDATA","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":"%s","Config":{},"Verified":false,"Type":"Column","Description":""}}`
);

const condFormats = [];
const filters = [];
const pluginName = "glassed-title";
let originalTitle = new Map();

const columnMappingParameters = [
  {
    targetProperty: "value", // ID of the property in columnMap object of render function
    formLabel: "Value", // Name the user will see
    type: "fact", // Either dim, fact or hidden - dimension, measure or hidden
    multiple: false, // Boolean for whether you can have more than one column in this section
    conditionalFormat: true, // Allow this to be conditionally formatted
    required: true, // Indicate to the user that this is required
    desc: "ShowLastValue" // Description that is displayed to the user
  }
];

const configurationParameters = [
  {
    targetProperty: "width",
    label: "Width",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 800
    },
    desc: "desc89"
  },
  {
    targetProperty: "height",
    label: "Height",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 300
    },
    desc: "desc90"
  },
  {
    targetProperty: "glassWidth",
    label: "GlassWidth",
    inputType: "textbox",
    inputOptions: {
      subtype: "text",
      defaultValue: "80%"
    },
    desc: "GlassWidth"
  },
  {
    targetProperty: "glassHeight",
    label: "GlassHeight",
    inputType: "textbox",
    inputOptions: {
      subtype: "text",
      defaultValue: "50%"
    },
    desc: "GlassHeight"
  },
  {
    targetProperty: "title_",
    label: "Title",
    inputType: "textbox",
    inputOptions: {
      subtype: "text"
    },
    desc: "desc94"
  },
  {
    targetProperty: "backgroundImageUrl",
    label: "BackgroundImageUrl",
    inputType: "textbox",
    inputOptions: {
      subtype: "text"
    },
    desc: "BackgroundImageUrl"
  },
  {
    targetProperty: "fontStyle",
    label: "FontStyle",
    inputType: "textbox",
    inputOptions: {
      subtype: "text",
      defaultValue: "color: black; font-size: 3em; letter-spacing: 0.50em;" //changed black for see the title in first render 
    },
    desc: "FontStyle"
  },
  {
    targetProperty: "showLastValue",
    label: "ShowLastValue",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "ShowLastValueDesc"
  },
  {
    targetProperty: "toggleCriteria",
    label: "ToggleCriteria",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "desc59"
  },
  {
    targetProperty: "showHideButton",
    label: "Show Hide Button",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc230"
  },
  {
    targetProperty: "summary",
    label: "Summary",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc61"
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: { defaultValue: "rgb(255,255,255)" },
    desc: "desc62"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  }
];

const actions = [];

// Reactions this visualisation can have to other interactions. Everything can use filters
const reactions = [
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "UpdateTitle",
    type: "general"
  }
];
let toggleCriteria;
const updateTitle = function(output, container) {
  const divId = $(container).children("div")[0].id;
  const span = $(container)
    .children(`#${divId}`)
    .children("span");
  const titleString = originalTitle.get(divId);
  let fullPostfix = "";
  if ($("#mapTitle").length > 0) {
    fullPostfix = $("#mapTitle").text();
  } else {
    fullPostfix = output[0].values.join(", ");
  }
  let title = `${titleString} ${fullPostfix}`;
  const tooltip = title;
  title = `${titleString} ${fullPostfix}`;

  if (output[0].values.length > 40) {
    if (toggleCriteria) {
      //Clean title plugin if toggleCriteria is enabled and no filter
      title = ``;
    } else {
      title = originalTitle.get(divId);
    }
  } else if (output[0].values.length > 2) {
    //const shortenedPrefix = `${fullPrefix.split(" ").slice(0, 2).join(" ")} +${output[0].values.length - 2}`;
    //title = `${shortenedPrefix} ${titleString}`;
    const shortenedPostfix = `${fullPostfix
      .split(" ")
      .slice(0, 2)
      .join(" ")} +${output[0].values.length - 2}`;
    title = `${titleString} ${shortenedPostfix}`;
  }
  span.text(title);
  span.attr("title", tooltip);
  // $(container).tooltip();
};

/**
 * renders GlassedTitle plugin in Vispeahen V3
 */
export default class GlassedTitle extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.rerenderProcessStarted = false;
    this.callBackObject = {};
  }

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping
    );
  }

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = {...this.callBackObject};
    this.setCallBackObject({})
    
    return tmpCallBackObject;
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );
  }

  getNavigationComponent = props => {
    return (
      <NavigationContent
        navigations={this.props.navigations}
        setNavigations={this.props.updatePlugin}
        plugin={this.props.plugin}
        dashboardInformation={this.props.dashboardInformation}
      />
    );
  };

  getConfigComponent = props => {
    if (props.config) {
      return (
        <GlassedTitleConfiguration
          config={{ ...props.config }}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
        />
      );
    }

    return null;
  };

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }

    return (
      <GlassedTitleData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        plugin={props.plugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  /**
   * To set column map this plugin
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      value: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Value.Name"),
        type: "dim",
        required: true,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Value.Desc"),
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {
    // Set html empty and set this html to container.
    $("#" + divId).html("");
    let container = $("#" + divId)[0];

    const randomMapId = Math.floor(Math.random() * 1000);
    divId = `glassedTitle${randomMapId}`;

    // Get width and height variables based on gridScope
    let visWidth = "100%";
    let visHeight = config.height + "px";

    toggleCriteria = config.toggleCriteria;
    originalTitle.set(divId, config.title_);

    let titleLabel = config.title_;

    let containerStyle = `height: ${visHeight}; width: ${visWidth}; font-family: 'Rajdhani', sans-serif; background-color: ${config.backgroundColor}; background-image: url('${config.backgroundImageUrl}');`;
    let glassStyle = `height: ${config.glassHeight}; width: ${config.glassWidth}; background-image: url('${config.backgroundImageUrl}');`;
    let spanStyle = config.fontStyle;

    if (config.showLastValue) {
      titleLabel
        ? (titleLabel += data[data.length - 1].value)
        : (titleLabel = data[data.length - 1].value);
    }

    if (!config.hideLabel) {
      if (toggleCriteria)
        $(container).append(
          `<div class="container" style="${containerStyle}"> <div class="drop-shadow" id="${divId}"><div class="glass" style="${glassStyle}" /><span class="glassedTitle" style="${spanStyle}"></span></div></div>`
        );
      else
        $(container).append(
          `<div class="container" style="${containerStyle}"> <div class="drop-shadow" id="${divId}"><div class="glass" style="${glassStyle}" /><span class="glassedTitle" style="${spanStyle}">${titleLabel}</span></div></div>`
        );
    }

    if (config.showHideButton) {
      let hideButtonToggleKey = ["#divId"];
      rmvpp.hideButton(container, hideButtonToggleKey);
    }
    
    this.props.setPluginRerender(false, this.props.plugin.id, false, this.props.plugin.isInteraction);
  };

  currentHeight;
  lastContent = undefined;

  updateLastContent = (status) => {
    this.lastContent = status
  } 

  render() {
    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(popupPosition, this.props, this.getConfigComponent);
    }

    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(popupPosition, this.props, this.getDataComponent);
    }

    let navigationComponent = null;
    if (this.props.navigationComponentVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      navigationComponent = renderNavigation(
        popupPosition,
        this.props,
        this.getNavigationComponent
      );
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };

    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + this.props.plugin.id).css("padding")
      );

      pluginConfig.height =
        calculatePluginHeight(this.props.plugin, this.props.settings) -
        pluginContainerPadding * 2;
    
      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      )
    }

      return (
      <div style={{height: "100%"}}>
        <div id={this.props.plugin.id}></div>
        {renderContent(
          isRerender,
          this.pluginRender,
          this.props.plugin,
          data,
          columnMap,
          pluginConfig,
          condFormats,
          this.props.setPluginRerender,
          this.lastContent,
          this.updateLastContent,
        )}
        {configComponent}
        {dataComponent}
        {navigationComponent}
      </div>
    );
}
}
