import { getPluginsAllColumnsByField } from "../../Utils/PluginOperations";
import i18n from "../../Utils/i18next";
import { store } from "../../index";
import { changePluginLoaderVisibility } from "../GeneralComponents/PluginLoader/PluginLoaderAction";
import { setExpression } from "./ExpressionParameterAction";

/**
 * Calculate column expression with current parameter values.
 * @param {*} column
 * @param {*} pluginId
 * @returns
 */
export const calculateExpression = (column, pluginId, comesFromWillMount = false, plugin) => {
  let reduxState = store.getState();
  let pluginAllColumns = []
  let expressionMap = new Map(
    reduxState.ExpressionParameterReducer.expressionMap
  );
  let definedExpressionSet = new Map(
    reduxState.ExpressionParameterReducer.definedExpressionSet
  );

  let pluginMap = reduxState.PluginTriggerReducer?.plugins;

  let expression = column.Code;
  let calculateExp = column.Code;
  let tempCalculateExp = column.Code;
  let status = "success";
  let message = "";

  const regex = /\${exp\.([A-Za-z0-9_\-çÇğĞıİöÖşŞüÜ]+)}/g;
  let match;
  let changedKeys = new Set();
  let value = 0;

  let data = [
    {
      hidden: [],
      [column.displayName]: value,
      measure: [{ name: column.displayName, value: value }],
    },
  ];

  if (pluginMap && pluginMap.size > 0) {
    let plugin = pluginMap.get(pluginId);
    let isCompared = false;

    if (plugin && plugin.defaultFilters) {
      for (let filter of plugin.defaultFilters) {
        if (filter.compared === true) {
          isCompared = true;
          break;
        }
      }
    }

    if (isCompared) {
      let message = i18n.t("Dashboard.Data.ExpressionParameterCompareError");
      let status = "error";
      console.error(message);

      return { data: data, status: status, message: message };
    }
  }

  while ((match = regex.exec(expression)) !== null) {
    let key = match[1];
    let definedExpressions = new Set(definedExpressionSet.values())

    if (!definedExpressions.has(key)) {
      message =
        i18n.t("Dashboard.Data.ExpressionParameterNotFound") +
        ': "' +
        key +
        '"';
      status = "error";
      console.error(message);

      return { data: data, status: status, message: message };
    } else {
      let sampleValue = "string";

      if (column.dataType === "double" || column.dataType === "integer") {
        sampleValue = 1;
      }

      tempCalculateExp = tempCalculateExp.replaceAll(
        "${exp." + key + "}",
        sampleValue
      );
    }

    if (expressionMap.has(key)) {
      calculateExp = calculateExp.replaceAll(
        "${exp." + key + "}",
        expressionMap.get(key)
      );
      changedKeys.add(key);
    }
  }

  try {
    eval(tempCalculateExp);

    try {
      value = eval(calculateExp);

      data = [
        {
          hidden: [],
          [column.displayName]: value,
          measure: [{ name: column.displayName, value: value }],
        },
      ];

      if (value === Infinity) {
        store.dispatch(changePluginLoaderVisibility(pluginId, false));
        status = "error";
        message = i18n.t("Dashboard.Data.ExpressionCouldNotBeEvaluated");

        return { data: data, status: status, message: message };
      }

      if (!comesFromWillMount) {
        store.dispatch(changePluginLoaderVisibility(pluginId, false));
      }
    } catch (error) {
      if (calculateExp.includes("${exp")) {
        store.dispatch(changePluginLoaderVisibility(pluginId, true));
        status = "loading";
        message = "loading";
      } else {
        store.dispatch(changePluginLoaderVisibility(pluginId, false));
        status = "error";
        message = i18n.t("Dashboard.Data.ExpressionParametersMustBeNumeric");
      }
    }
  } catch (error) {
    store.dispatch(changePluginLoaderVisibility(pluginId, false));
    status = "error";
    message = i18n.t("Dashboard.Data.ExpressionCouldNotBeEvaluated");
  }

  if (column.preserveDataAs && status === "success") {
    let reduxState = store.getState();
    let expressionMap = new Map(
      reduxState.ExpressionParameterReducer.expressionMap
    );

    expressionMap.set(column.preserveDataAs, value);

    store.dispatch(setExpression(expressionMap));
  }

  let allPluginsColumns = []
  let plugins = Array.from(reduxState.PluginTriggerReducer?.plugins.values())

  for (let i = 0; i < plugins.length; i++) {
    let plugin = plugins[i]
    let pluginColumns = getPluginsAllColumnsByField(plugin.columnMap)
    
    allPluginsColumns = allPluginsColumns.concat(pluginColumns)
  }

  let definedExpressions = Array.from(definedExpressionSet.keys())
  let counterForMissingExpressions = 0

  for (let i = 0; i < definedExpressions.length; i++) {
    let definedExpressionColumn = allPluginsColumns.find(col => col.uniqeColumnId === definedExpressions[i])

    if (definedExpressionColumn == undefined) {
      counterForMissingExpressions++
    }
  }

  if (counterForMissingExpressions > 0) {
    store.dispatch(changePluginLoaderVisibility(pluginId, false));

    status = "error";
    message = i18n.t("Dashboard.Data.ExpressionCouldNotBeEvaluated");
  }

  return { data: data, status: status, message: message };
};
