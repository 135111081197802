import React, { Component } from "react";
import { Form, Icon, Input, Drawer } from "antd";
import FooterVispeahenLogo from "../GeneralComponents/FooterVispeahenLogo";
import i18n from "../../Utils/i18next";
import { showNotificationWithIcon, showError } from "../../Utils/Notification";
import { post } from "../../Utils/WebService";
import SubmitButton from "./SubmitButton";
import { isUserEntranceInputValid } from "./UserValidator"

/**
 * User make request to change her password via this component
 */
export default class PasswordForget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      canISubmit: true,
    };
  }

  /**
   * Handle state inputs change.
   */
  onValueChange = (event) => {
    if(isUserEntranceInputValid(event) === false) {
      event.preventDefault();
      return;
    }

    this.setState({
      ...this.state,
      [event.target.id]: event.target.value,
    });
  };

  /**
   * This function will take action depends on the change password button click and send new password request to backend api 
   */
  saveUser = () => {
    const THIS = this;

    let url = `/api/user/forgotPassword`;

    let userObj = {
      email: this.state.email,
      preferredLanguage: i18n.language,
    };

    const successFunc = (result) => {
      showNotificationWithIcon(
        i18n.t("UserEntrance.Information"),
        i18n.t("UserEntrance.ForgotPassword.MailSend"),
        "success"
      );

      this.setState({
        email: "",
        canISubmit: true,
      });

      this.props.showComponent("visibilityLogin");
    };

    const errorFunc = (error) => {
      showError(error);

      this.setState({
        ...this.state,
        canISubmit: true,
      });
    };

    let headers = {
      Authorization: "Basic " + btoa("mobile:pin"),
    };

    post(url, userObj, successFunc, errorFunc, false, false, headers);

    this.setState({
      ...this.state,
      canISubmit: false,
    });
  };

  handleKeyDown = (e) => {
    /**
     * if user presses enter button on keyboard
     */
    if (e.key === "Enter") {
      this.validateAndSave(e);
    }
  };

  /**
   * This function is the last information validation check it will take action right before saveUser
   * if all the information that user provided are valid navigate to saveUser, if not prompt fail message
   */
  validateAndSave = (e) => {
    e.preventDefault();

    // There is still a register process on the way.
    // Do nothing till that process is finished
    if (this.state.canISubmit === false) return;

    if (this.validateEmail()) {
      this.saveUser();
    }
  };

  validateEmail = () => {
    var regEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (!this.state.email || regEmail.test(this.state.email) === false) {
      showError(i18n.t("UserEntrance.Register.InvalidEmail"));
      return false;
    }

    return true;
  };

  render() {
    return (
      <Drawer
        style={{
          display: this.props.visibilityPasswordForget ? "block" : "none",
        }}
        className={"noBackground"}
        placement={"center"}
        closable={false}
        visible={true}
        width={370}
      >
        <Form className="login-form" onSubmit={this.validateAndSave}>
          <div style={{ width: "100%", textAlign: "center" }}>
            <img
              src={this.props.customization.logo}
              alt="Image"
              class="login-logo"
            />
          </div>
          <div className="password-progress">
            <span style={{ fontSize: "12px", lineHeight: "1px" }}>
              {i18n.t("UserEntrance.ForgotPassword.Description")}
            </span>
          </div>
          <Form.Item>
            <Input
              id={"email"}
              value={this.state.email}
              onChange={this.onValueChange}
              onKeyDown={this.handleKeyDown}
              prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
              className={"circled-no-border login-input"}
              maxLength={100}
              name={"emailForgottenPassword"}
              placeholder={i18n.t("UserEntrance.Email")}
            />
          </Form.Item>
          <Form.Item>
            <SubmitButton
              id={"sendPasswordButton"}
              canISubmit={this.state.canISubmit}
              text={i18n.t("UserEntrance.ForgotPassword.Button")}
            />
            <div
              style={{ paddingLeft: "5px", float: "right", marginTop: "-5px" }}
            >
              <span
                id={"backToLoginForgottenPassword"}
                style={{
                  color: "#1b6094",
                  cursor: "pointer",
                  fontSize: "11px",
                }}
                onClick={() => this.props.showComponent("visibilityLogin")}
              >
                {i18n.t("UserEntrance.GoBackToLogin")}
              </span>
            </div>
          </Form.Item>
        </Form>
        <FooterVispeahenLogo position={"absolute"} />
      </Drawer>
    );
  }
}
