import Cookies from "js-cookie";
import i18n from "./i18next";
import moment from "moment";
import React from "react";
import { store } from "..";
import { setGeneralLoadingSet } from "../ui/GeneralComponents/GeneralLoading/GeneralLoadingAction";
import { BASE_URL_UI } from "../config";
import { setPluginsDrillDowns } from "../ui/DrillDown/DrillDownAction";

/**
 * Absobates the event
 * 
 * @param {*} event 
 */
export const absorbEvent = (event = window.event) => {
  if (event) {
    event.preventDefault && event.preventDefault();
    event.stopPropagation && event.stopPropagation();
    event.cancelBubble = true;
    event.returnValue = false;
  }
}

export const navigate = (link, delayAsMilliseconds = 250) => {
  setTimeout(function () {
    window.location.href = link;
  }, delayAsMilliseconds);
};

/*
* Sets status of general loading
*/
export const changeStatusOfGeneralLoading = (key, status) => {
  let reduxState = store.getState()
  let copiedGeneralLoadingSet = new Set(reduxState.GeneralLoadingReducer.generalLoaderSet)

  if (status === true) {
    if (!copiedGeneralLoadingSet.has(key)) {
      copiedGeneralLoadingSet.add(key)

      store.dispatch(setGeneralLoadingSet(copiedGeneralLoadingSet))

      loadingScreen(status)
    }
  } else if (status !== true) {
    if (copiedGeneralLoadingSet.has(key)) {
      copiedGeneralLoadingSet.delete(key)

      store.dispatch(setGeneralLoadingSet(copiedGeneralLoadingSet))

      loadingScreen(status)
    }
  }
}

export const loadingScreen = status => {
  let reduxState = store.getState()
  let mustWaitedForAnotherProcesses = reduxState.GeneralLoadingReducer.generalLoaderSet.size > 0 ? true : false

  if (document.getElementById("splashScreenContainer")) {
    if (status == true) {
      document.getElementById("splashScreenContainer").style.display =
        "initial";
    } else if (status == false && !mustWaitedForAnotherProcesses) {
      return document.getElementById("splashScreenContainer")
        ? (document.getElementById("splashScreenContainer").style.display =
          "none")
        : null;
    } else if (!mustWaitedForAnotherProcesses) {
      return document.getElementById("splashScreenContainer")
        ? document.getElementById("splashScreenContainer").style.display
        : "";
    }
  }
};

/** Delete a cookie. */
export const eraseCookie = (name, path) => {
  createCookie(name, "", -1, path);
};

export const createCookie = (name, value, days, path) => {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    var expires = "; expires=" + date.toGMTString();
  } else var expires = "";
  document.cookie = name + "=" + value + expires + "; path=" + path;
};

export const parseJwt = token => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const dateFormat = (date, splitter = "-") => {
  let formattedDateByLocalZone = new Date(date)
  let pureFormattedDate = moment(formattedDateByLocalZone).format()
  let splittedDate = pureFormattedDate.split("T")

  let dateParse = splittedDate[0].split("-");
  let timeParse = splittedDate[1].split(".")[0].split("+")[0]

  return dateParse[2] + splitter + dateParse[1] + splitter + dateParse[0] + " " + timeParse;
};

/**
 * Take tenant and return
 */
export const getTenant = () => {
  return Cookies.get("tenant");
};

export const dateBeforeCalculation = date => {
  let incomeDate = new Date(date);
  let today = new Date(Date.now());
  let difference = parseInt(
    (today.getTime() - incomeDate.getTime()) / (1000 * 3600 * 24)
  );
  let suffix = i18n.t("DateCalculation.Day");

  if (difference > 365) {
    difference /= 365;
    suffix = i18n.t("DateCalculation.Year");
  } else if (difference > 30) {
    difference /= 30;
    suffix = i18n.t("DateCalculation.Month");
  } else if (difference > 7) {
    difference /= 7;
    suffix = i18n.t("DateCalculation.Week");
  }

  if (difference > 1 && i18n.language === 'en') {
    suffix += "s";
  }

  return parseInt(difference) + suffix;
};

const clone = require("rfdc")();

export const deepCopy = obj => {
  return clone(obj);
};

export const getIcon = (icon, defaultIcon, style={}, className) => {
  if (typeof icon === "string") {
    return <i style={style} class={`${icon} ${className || ""}`} />;
  }

  if (typeof icon === "object") {
    let name = icon.name;
    let type = icon.type;
    let value = icon.value;

    if (type === "font-awesome") {
      return <i style={style} class={`${value} ${className || ""}`} />;
    }

    if (type === "svg") {
      return (
        <span
          role="img"
          aria-label={name}
          class={`anticon ${className || ""}`}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...style
          }}
          dangerouslySetInnerHTML={{ __html:value }}
        />
      )
    }
  }

  return defaultIcon;
}

/* 
* Changes the URL if the report is changed with the switcher and the report is get successfully.
*/
export function pushWindowHistoryIfComesFromDashboardSlider(
  sliderId,
  dashboardId
) {
  if (sliderId) {
    let newUrl =
      BASE_URL_UI +
      "/dashboard/" +
      dashboardId +
      "?sliderId=" +
      sliderId;

    window.history.pushState({}, "", newUrl);
  }
}

/* 
* Changes the URL if the report is changed with the switcher and the report is get successfully.
*/
export function pushWindowHistoryIfComesFromSwitchDashboard(
  labelId,
  dashboardId
) {
  if (labelId) {
    let newUrl =
      BASE_URL_UI +
      "/dashboard/" +
      dashboardId +
      "?labelId=" +
      labelId;

    window.history.pushState({}, "", newUrl);
  }
}

/*
* Clears all interactions from plugin
*/
export function clearAllInteractionsOnPlugins() {
  let reduxState = store.getState();
  let pluginInteractionFilters = reduxState.PluginTriggerReducer.pluginInteractionFilters;
  let sourcePluginsWithValues = reduxState.PluginTriggerReducer.sourcePluginsWithValues;
  let pluginPrivateInteractionFilters = reduxState.PluginTriggerReducer.pluginPrivateInteractionFilters;

  pluginInteractionFilters.clear();
  sourcePluginsWithValues.clear();
  pluginPrivateInteractionFilters.clear();
}

/*
* Clears all drilldowns from plugin
*/
export function clearAllDrillDownsOnPlugins () {
  let reduxState = store.getState();
  let drillDowns = reduxState.DrillDownReducer.drillDowns;

  reduxState.DrillDownReducer.triggeredDrillDowns.clear();
  reduxState.DrillDownReducer.pluginColumnClickedAndShouldBeRemoved.clear();
  reduxState.DrillDownReducer.drillDowns.clear()

  store.dispatch(setPluginsDrillDowns(drillDowns));
};