import React, { Component } from "react";
import * as d3 from "d3";
import $ from "jquery";
import cloud from "d3-cloud";
import { rmvpp } from "../../RenderJs/rmvpp";
import WordCloudConfiguration from "./WordCloudConfiguration";
import i18n from "../../../../Utils/i18next";
import { createTrigger } from "../../../Interaction/CreateTrigger";
import {
  onComponentWillMount,
  onComponentWillReceiveProps,
  getColumnMapping,
  calculatePluginHeight
} from "../common";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import { renderConfig, renderData } from "../PluginsCommonComponents";
import WordCloudData from "./WordCloudData";
import { renderContent } from "../renderContent";
import { getFormattedValue } from "../format";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join"
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";

const data = JSON.parse(
  `[{"word":"Aralık","freq":502385200},{"word":"Ağustos","freq":488440780},{"word":"Ekim","freq":493789318},{"word":"Eylül","freq":478800672},{"word":"Haziran","freq":476167436},{"word":"Kasım","freq":500703756},{"word":"Mart","freq":479405830},{"word":"Mayıs","freq":485081738},{"word":"Nisan","freq":466192048},{"word":"Ocak","freq":384458844},{"word":"Temmuz","freq":452360534},{"word":"Şubat","freq":402286770}]`
);
const config = JSON.parse(
  `{"width":"400","height":400,"spiral":"Archimedean","scale":"Linear","minSize":12,"maxSize":36,"font":"Open Sans","orientations":5,"fromAngle":-60,"toAngle":60,"colours":"Flat-UI","toggleCriteria":"","title":"","summary":"","backgroundColor":"rgb(255,255,255)","showHideButton":false,"refresh":0}`
);
const columnMap = JSON.parse(
  `{"word":{"Code":"\'ucus_PBWWSJXEAR\'.\'ay_adi\'","Name":"ay_adi","DataType":"varchar","Table":"ucus_PBWWSJXEAR","Measure":"none","ID":"ucus_PBWWSJXEAR.ay_adi","SubjectArea":"deneme","SortKey":false,"Sorting":false,"SortDirection":"","SortOrder":0,"Locale":"TR","DataFormat":"%s","Config":{},"Verified":true,"Type":"Column","Description":""},"freq":{"Code":"\'ucus_PBWWSJXEAR\'.\'kargotoplam\'","Name":"kargotoplam","DataType":"double","Table":"ucus_PBWWSJXEAR","Measure":"sum('ucus'.'kargotoplam')","ID":"ucus_PBWWSJXEAR.kargotoplam","SubjectArea":"deneme","SortKey":false,"Sorting":false,"SortDirection":"","SortOrder":0,"Locale":"TR","DataFormat":".3s","Config":{},"Verified":false,"Type":"Column","Description":""}}`
);

const condFormats = [];
const filters = [];

var pluginName = "word-cloud";
const description =
  "Word cloud (also known as tag cloud or weighted) list visualisation. Here the attribute word will be sized based on the equivalent measure value. The positioning on the canvas is determined by one of two configurable algorithms. The word colours are also configurable.";

const configurationParameters = [
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 15
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
  {
    targetProperty: "width",
    label: "Width",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 400
    },
    desc: "desc132"
  },
  {
    targetProperty: "height",
    label: "Height",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 400
    },
    desc: "desc131"
  },
  {
    targetProperty: "spiral",
    label: "Spiral",
    inputType: "radio",
    inputOptions: {
      values: ["Archimedean", "Rectangular"],
      defaultValue: "Archimedean"
    },
    desc: "desc130"
  },
  {
    targetProperty: "scale",
    label: "Scale",
    inputType: "radio",
    inputOptions: {
      values: ["Linear", "Log"],
      defaultValue: "Linear"
    },
    desc: "desc129"
  },
  {
    targetProperty: "minSize",
    label: "MinFontSize",
    inputType: "textbox",
    inputOptions: {
      subType: "number",
      defaultValue: 12
    },
    desc: "desc128"
  },
  {
    targetProperty: "maxSize",
    label: "MaxFontSize",
    inputType: "textbox",
    inputOptions: {
      subType: "number",
      defaultValue: 36
    },
    desc: "desc127"
  },
  {
    targetProperty: "font",
    label: "Font",
    inputType: "font",
    inputOptions: {
      defaultValue: "Open Sans"
    },
    desc: "desc126"
  },
  {
    targetProperty: "orientations",
    label: "Orientations",
    inputType: "textbox",
    inputOptions: {
      subType: "number",
      defaultValue: 5
    },
    desc: "desc125"
  },
  {
    targetProperty: "fromAngle",
    label: "FromAngle",
    inputType: "range",
    inputOptions: {
      subType: "number",
      min: -90,
      max: 90,
      defaultValue: -60
    },
    desc: "desc124"
  },
  {
    targetProperty: "toAngle",
    label: "ToAngle",
    inputType: "range",
    inputOptions: {
      subType: "number",
      min: -90,
      max: 90,
      defaultValue: 60
    },
    desc: "desc123"
  },
  {
    targetProperty: "colours",
    label: "Colours",
    inputType: "palette",
    inputOptions: { defaultValue: "Flat-UI" },
    desc: "desc122"
  },
  {
    targetProperty: "toggleCriteria",
    label: "ToggleCriteria",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc59"
  },
  {
    targetProperty: "title",
    label: "Title",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc94"
  },
  {
    targetProperty: "titleAlign",
    label: "Title Align",
    inputType: "dropdown",
    inputOptions: {
      defaultValue: "center",
      multiSelect: false,
      values: ["center", "left", "right"]
    },
    desc: "desc94"
  },
  {
    targetProperty: "summary",
    label: "Summary",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc61"
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: { defaultValue: "rgb(255,255,255)" },
    desc: "desc62"
  },
  {
    targetProperty: "hideLabel",
    label: "HideLabel",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "desc142"
  },
  {
    targetProperty: "showHideButton",
    label: "Show Hide Button",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc230"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  }
];

const actions = [
  {
    trigger: "wordClick",
    type: "click",
    output: ["word"],
    name: "Tıklama - Kelime",
    description: "WordClickDesc"
  },
  {
    trigger: "wordHover",
    type: "hover",
    output: ["word"],
    name: "Hover - Kelime",
    description: "WordHoverDesc"
  }
];

const reactions = [
  {
    id: "filter",
    name: "Filtre",
    description: "desc87",
    type: "general"
  }
];

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

/**
 * renders WordCloud plugin in Vispeahen V3
 */
export default class WordCloud extends Component {
  constructor(props) {
    super(props);
    this.rerenderProcessStarted = false;
    this.callBackObject = {};
  }

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({})

    return tmpCallBackObject;
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );
  }

  getConfigComponent = props => {
    if (props.config) {
      return (
        <WordCloudConfiguration
          config={{ ...props.config }}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig= {this.props.setCurrentAppliedConfig}
          currentAppliedConfig = {this.props.currentAppliedConfig}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }

    return (
      <WordCloudData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        didNotJoinedTables={checkTableJoins(this.props.join, this.props.plugin.columnMap, this.props.refreshedPluginId, this.props.plugin.id, true)}
        setInteractions={this.props.setInteractions}
        interactions={this.props.interactions}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={props.changeDoesPluginHasNotJoinedTable}
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        plugin={props.plugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  /**
   * To set column map this plugin
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      word: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Word.Name"),
        type: "dim",
        required: true,
        minimumColumnSize: 1,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Word.Desc"),
        data: []
      },
      freq: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Freq.Name"),
        type: "fact",
        minimumColumnSize: 1,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Freq.Desc"),
        required: true,
        conditionalFormat: true,
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {
    var THIS = this;
    // Set html empty and set this html to container.
    $("#" + divId).html("");
    let container = $("#" + divId)[0];

    // Get width and height variables based on gridScope
    let visWidth = "100%";
    let visHeight = config.height + "px";
    let visBackgroundColor = config.backgroundColor;

    var d3Scale = d3.scale.linear(); //, fill = d3.scale.category20();
    var palette = rmvpp.getPalette(config.colours);
    var fill = d3.scale.ordinal().range(palette);
    d3Scale
      .domain([0, config.orientations - 1])
      .range([config.fromAngle, config.toAngle]);

    var min = d3.min(data, function (d) {
      return +d.freq;
    });
    var max = d3.max(data, function (d) {
      return +d.freq;
    });

    var fontSize = d3.scale[config.scale.toLowerCase()]()
      .domain([min, max])
      .range([config.minSize, config.maxSize]); // Scale fonts based on config parameters

    var layout = cloud()
      // var layout = d3.layout.cloud()
      .size([container.offsetWidth, config.height])
      .fontSize(function (d) {
        return fontSize(+d.freq);
      })
      .text(function (d) {
        return d.word;
      })
      .rotate(function () {
        return d3Scale(~~(Math.random() * config.orientations));
      })
      .on("end", draw);

    var wordContainer = d3
      .select(container)
      .append("div")
      .classed("word-cloud", true)
      .style("background-color", visBackgroundColor);

    var svg;
    svg = wordContainer
      .append("svg")
      .attr("width", visWidth)
      .attr("height", visHeight)
      .attr("viewBox", `0 0 ${config.width} ${config.height}`);

    var vis = svg
      .append("g")
      .attr(
        "transform",
        "translate(" + [config.width >> 1, config.height >> 1] + ")"
      );

    var tooltip = new rmvpp.Tooltip(wordContainer[0][0]); // Create tooltip object

    // Draw cloud function
    function draw(data, bounds) {
      var scale = bounds
        ? Math.min(
          container.offsetWidth /
          Math.abs(bounds[1].x - container.offsetWidth / 2),
          container.offsetWidth /
          Math.abs(bounds[0].x - container.offsetWidth / 2),
          config.height / Math.abs(bounds[1].y - config.height / 2),
          config.height / Math.abs(bounds[0].y - config.height / 2)
        ) / 2
        : 1;

      var words = data;

      var text = vis.selectAll("text").data(words, function (d) {
        if (typeof d.text === "number") {
          return d.text
        }

        return d.text.toLowerCase();
      });
      text.classed("nonSelect", true);
      text
        .transition()
        .duration(1000)
        .attr("transform", function (d) {
          return "translate(" + [d.x, d.y] + ") rotate(" + d.rotate + ")";
        })
        .style("font-size", function (d) {
          return d.size + "px";
        });
      text
        .enter()
        .append("text")
        .attr("text-anchor", "middle")
        .attr("transform", function (d) {
          return "translate(" + [d.x, d.y] + ") rotate(" + d.rotate + ")";
        })
        .style("font-size", "1px")
        .transition()
        .duration(1000)
        .style("font-size", function (d) {
          return d.size + "px";
        });
      text
        .style("font-family", function (d) {
          return d.font;
        })
        .style("fill", function (d) {
          Math.floor(Math.random() * 5);
          return fill(typeof d.text === "number" ? d.text : d.text.toLowerCase());
        })
        .style("cursor", "pointer")
        .text(function (d) {
          return getFormattedValue(columnMap.word, d.text);
        })
        .on("mouseover", function (d, i) {
          // Custom wordHover trigger
          tooltip.displayHTML(
            "<b>" + columnMap.freq.Name + ": </b>" + (getFormattedValue(columnMap.freq, d.freq)),
            d3.event
          );
          createTrigger(
            actions,
            columnMap,
            container,
            "wordHover",
            d,
            THIS.props.plugin.id,
            THIS.props.interactions,
            THIS.props.navigations
          );
        })
        .on("mouseout", function (d, i) {
          // Custom wordHover trigger
          tooltip.hide();
          createTrigger(
            actions,
            columnMap,
            container,
            "wordHover",
            d,
            THIS.props.plugin.id,
            THIS.props.interactions,
            THIS.props.navigations
          );
        })
        .on("click", function (d, i, x) {
          // Custom wordClick trigger
          createTrigger(
            actions,
            columnMap,
            container,
            "wordClick",
            d,
            THIS.props.plugin.id,
            THIS.props.interactions,
            THIS.props.navigations
          );
        });

      vis
        .transition()
        .delay(1000)
        .duration(750)
        .attr(
          "transform",
          "translate(" +
          [config.width >> 1, config.height >> 1] +
          ") scale(" +
          scale +
          ")"
        );
    }

    // Generate/regenerate function
    function generate() {
      layout.font(config.font).spiral(config.spiral.toLowerCase());
      var min = d3.min(data, function (d) {
        return +d.freq;
      });
      var max = d3.max(data, function (d) {
        return +d.freq;
      });
      var fontSize = d3.scale[config.scale.toLowerCase()]()
        .domain([min, max])
        .range([config.minSize, config.maxSize]); // Scale fonts based on config parameters
      var complete = 0;
      var words = [];
      layout
        .stop()
        .words(data)
        .start();
    }

    generate();

    if (config.hideLabel) {
      let hideLabel = $(".word-cloud");
      hideLabel.hide();
    }

    this.props.setPluginRerender(false, this.props.plugin.id, false, this.props.plugin.isInteraction);
  };

  currentHeight;
  lastContent = undefined;

  updateLastContent = (status) => {
    this.lastContent = status
  }

  render() {
    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };

    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + this.props.plugin.id).css("padding")
      );

      pluginConfig.height =
        calculatePluginHeight(this.props.plugin, this.props.settings) -
        pluginContainerPadding * 2;

      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      );
    }

    return (
      <>
        <div style={{height: "100%"}}>
          <div id={this.props.plugin.id}></div>
          {renderContent(
            isRerender,
            this.pluginRender,
            this.props.plugin,
            data,
            columnMap,
            pluginConfig,
            condFormats,
            this.props.setPluginRerender,
            this.lastContent,
            this.updateLastContent,
          )}
          {configComponent}
          {dataComponent}
        </div>
      </>
    );
  }
}
