import React, { Component } from "react";
import PopupContainer from "../GeneralComponents/PopupContainer/PopupContainer";
import DataSourcesArea from "../Datasources/DataSourcesArea";
import Button from "../GeneralComponents/Button/Button";
import $ from "jquery"

/**
 * This component has a popup containing the data source area component.
 */
export default class DataSourcesPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleDataConnections: false,
      createDatasourceModalVisible: false,
      newConnection: {},
    };
  }

  render() {
    let generalWidth = $("body").innerWidth();
    let dataSourcesPopupWidth = generalWidth * 40/100 //Data source popup width must be equal screen width %40
    let modelsPopupWidth = (generalWidth * 53/100) //Models popup width must be equal screen width %53
    let dataSourcesPopupPosition = (-modelsPopupWidth) + (-dataSourcesPopupWidth) -11 //We calculated data sources popup position here. We gave this position left, so this value must be negative, -((Data source popup width) + (models popup width) + margin-left)

    return (
      <div style={{ display: this.props.visible ? "block" : "none" }}>
        <PopupContainer
          width={dataSourcesPopupWidth}
          additionalPosition="1653%"
          constantHeight="650px"
          className={"model-creation"}
          position="absolute"
          right={dataSourcesPopupPosition}
        >
          <div>
            <DataSourcesArea
              model={this.props.model}
              visible={this.props.visible}
              newConnection={this.props.newConnection}
              editModel={this.props.editModel}
              joins={this.props.joins}
              changeSelectedDataSource={this.props.changeSelectedDataSource}
              visibleDataConnections={this.props.visibleDataConnections}
              selectedTables={this.props.selectedTables}
              modelTableSaveResponse={this.props.modelTableSaveResponse}
              fileUploadModalVisible={this.props.fileUploadModalVisible}
              changeFileUploadModalVisibity={this.props.changeFileUploadModalVisibity}
              setVisibleDataUploadStatus={this.props.setVisibleDataUploadStatus}
              datasourceEditMode={this.props.datasourceEditMode}
              dataSourceEditModeId={this.props.dataSourceEditModeId}
              dataSourceEditForWithoutDatabase={this.props.dataSourceEditForWithoutDatabase}
              editedSelfService={this.props.editedSelfService}
              isNotUpdatedSelfService={this.props.isNotUpdatedSelfService}
            />
          </div>
          <Button
            id="closeConnectionPopUp"
            style={{
              position: "absolute",
              top: "3px",
              right: "15px",
              borderRadius: "40px",
              padding: "6px 11px",
              backgroundColor: "white",
              zIndex: 2,
            }}
            type={"default"}
            onClick={this.props.cancel}
          >
            X
          </Button>
        </PopupContainer>
      </div>
    );
  }
}
