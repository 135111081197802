export const PAGE_POPUP_CONTENT = "PAGE_POPUP_CONTENT";

export function setPagePopupContent(contentObject) {
  return dispatch => {
    dispatch({
      type: PAGE_POPUP_CONTENT,
      payload: contentObject
    });
  };
}
