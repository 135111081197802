import React, { Component } from "react";
import Text from "../../../GeneralComponents/Text/Text";
import BoxItem from "../../../GeneralComponents/BoxItem/BoxItem";
import VerticalDragDrop from "../../../GeneralComponents/VerticalDragDrop";
import Tooltip from "../../../GeneralComponents/Tooltip/Tooltip";
import i18n from "../../../../Utils/i18next";
import { Row, Col, InputNumber, Divider } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import CustomMultipleSwitch from "../../../GeneralComponents/CustomMultipleSwitch/CustomMultipleSwitch";

const clone = require("rfdc")();

/**
 * Draggable sort area for data component.
 * Get original sortable data and set sorted list.
 */
class SortArea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortedColumnList: this.props.sortedColumnList
        ? this.props.sortedColumnList
        : []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.sortedColumnList !== nextProps.sortedColumnList) {
      this.setState({
        sortedColumnList: nextProps.sortedColumnList
      });
    }
  }

  /**
   * Handle sort direction
   * @param status // asc, desc or null
   * @param extra // has column and index.
   */
  sortDirectionChange = (status, extra) => {
    let object = extra.item;
    let index = extra.index;
    let column = clone(object);
    let tempSortedColumnList = clone(this.state.sortedColumnList);

    column.sortDir = status;
    tempSortedColumnList[index] = clone(column);

    this.setState({
      ...this.state,
      sortedColumnList: tempSortedColumnList
    });

    this.props.updateSortedList(tempSortedColumnList);
  };

  /** Render dynamic draggable object */
  verticalDragDropItem = object => {
    let displayName = object.item.displayName;
    let tableAliasName = object.item.tableAliasName;

    return (
        <Row style={{ padding: "0 3px" }}>
          <Col span="2">
            <MenuOutlined style={{ marginTop: "10px" }} />
          </Col>
          <Col span="12">
            <BoxItem
              title={displayName}
              divClassName={"noStyle"}
              className={"onColumnMap"}
              style={{ marginBottom: 0, cursor: "grab" }}
              tooltipTitle={tableAliasName + " - " + displayName}
            ></BoxItem>
          </Col>
          <Col span="10" style={{ textAlign: "right", marginTop: "3px" }}>
            <CustomMultipleSwitch
              data={[
                { name:displayName + "Off", value: null, title: "OFF", color: "btn-danger"},
                { name:displayName + "Asc", value: "ASC", title: i18n.t("Dashboard.Data.ASC"), color: "btn-success" },
                { name:displayName + "Desc", value: "DESC", title: i18n.t("Dashboard.Data.DESC"), color: "btn-primary" }
              ]}
              defaultValue={null}
              selectedValue={object.item.sortDir}
              hideOffButton={false}
              changeValueExtraParameters={{
                item: object.item,
                index: object.index
              }}
              changeValue={this.sortDirectionChange}
            />
          </Col>
        </Row>
    );
  };

  /** update sorted list by new and post upper components. */
  updateSortedList = list => {
    let tempList = clone(list);
    this.setState({
      sortedColumnList: tempList
    });

    this.props.updateSortedList(tempList);
  };

  render() {
    return (
      <div>
        <Row gutter={16} style={{ marginBottom: '-12px', alignItems: 'center', marginTop: '-8px' }}>
          <Col span={12} style={{ textAlign: 'left' }}>
            <Text 
            style ={{
              fontSize: '16px',
              display: 'inline-block',
              marginTop: '2px',
              marginLeft: '26px',
              color: '#000000D9'
            }}
            >Limit</Text>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <InputNumber
              min={1}
              value={this.props.limit}
              onChange={this.props.setDataLimitForPlugin}
              style={{ width: '80%', height: '28px', lineHeight: '28px' }}
              size="small"
            />
          </Col>
        </Row>
        <Divider />
        <Text type={"h5"} style={{ textAlign: "center", marginTop: "-14px", fontSize: "16px" }}>
          {i18n.t("Dashboard.Data.SortYourColumns")}
        </Text>
        <Text
          type={"p"}
          style={{
            textAlign: "center",
            color: "rgb(162, 162, 162)",
            fontSize: "12px"
          }}
        >
          {i18n.t("Dashboard.Data.SortColumnsDesc")}
        </Text>
        <div className={""}>
          {this.state.sortedColumnList.length > 0 ? (
            <VerticalDragDrop
              content={this.verticalDragDropItem}
              type={"sort-area"}
              data={this.state.sortedColumnList}
              updateColumnMapData={this.updateSortedList}
              sortArea={true}
            ></VerticalDragDrop>
          ) : (
            <Text type={"span"} className={"warning-description-text"}>
              {i18n.t("Dashboard.Data.SortColumnsEmptyDesc")}
            </Text>
          )}
        </div>
      </div>
    );
  }
}

export default SortArea;
