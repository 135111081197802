import { API_BASE } from "../../config";
import { get, post } from "../../Utils/WebService";
import { showNotificationWithIcon } from "../../Utils/Notification";
export const GET_MODEL = "GET_MODEL";

function organizing(result) {
  var model = result;
  var resultObject = [];
  var checkedItems = [];
  var dataSourceGroupList = {};
  var dataSourceIndexList = {};
  var newDataSourceIndex = -1;
  var dataSourceIndex;

  if (Object.keys(model).length !== 0) {
    model.tables.map(table => {
      if (dataSourceGroupList[table.dataSourceKey] == undefined) {
        dataSourceGroupList[table.dataSourceKey] = [];
        newDataSourceIndex++;
        dataSourceIndex = newDataSourceIndex;
        dataSourceIndexList[table.dataSourceKey] = dataSourceIndex;
      } else {
        dataSourceIndex = dataSourceIndexList[table.dataSourceKey];
      }

      //If display name is empty, name set operation
      if (table.displayName == null) {
        table.displayName = table.name;
      }

      let tableIndex = dataSourceGroupList[table.dataSourceKey].length;

      if (table.columns != null) {
        for (let i = 0; i < table.columns.length; i++) {
          table.columns[i].key = `${dataSourceIndex},${tableIndex},${i}`;
          if (
            table.columns[i].source == "Local" ||
            table.columns[i].source == "Both"
          ) {
            checkedItems.push(table.columns[i].key);
          }
          if (table.columns[i].displayName == null) {
            table.columns[i].displayName = table.columns[i].name;
          }
        }
      }

      dataSourceGroupList[table.dataSourceKey].push({
        ...table,
        key: `${dataSourceIndex},${tableIndex}`
      });
    });

    var dataSourceIndex = 0;
    for (let dataSource in dataSourceGroupList) {
      let obj = {
        displayName: dataSource,
        tables: dataSourceGroupList[dataSource],
        key: dataSourceIndex
      };
      resultObject.push(obj);
      dataSourceIndex++;
    }
    model.tables = resultObject;
    model.checkedItems = checkedItems;
    return model;
  }
}

export function getModel(selectedModelId) {
  return dispatch => {
    const successFunk = result => {
      let rsp = organizing(result.data);
      console.log("rsp = ", rsp);
      dispatch({
        type: GET_MODEL,
        payload: rsp
      });
    };

    const errorFunk = response => {
      showNotificationWithIcon(
        "Model not found",
        response.data,
        "error"
      );
    };

    let url = `${API_BASE}/subjectAreaMerged/${selectedModelId}`;
    get(url, successFunk, errorFunk);
  };
}
