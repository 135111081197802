export const SET_NAVIGATION = "SET_NAVIGATION";
/**
 * 
 * @param {*} navigation 
 * Action for triggered navigation
 */
export function setTriggeredNavigation(navigation) {
  return (dispatch) => {
    dispatch({
      type: SET_NAVIGATION,
      payload: navigation,
    });
  };
}
