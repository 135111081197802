import React, { Component } from "react";
import SliderDashboard from "./SliderDashboard";
import ListAllDashboard from "./ListAllDashboard";
import DashboardLabelHeader from "./DashboardLabelHeader";
import { sortByLanguage } from "../../Utils/SortByLanguage";
import $ from "jquery";

export default class DashboardLabel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAll: false,
      dataLabel: this.props.dataLabel || {},
      data: this.props.data || [],
      sortedDashboardList: this.props.data || []
    };
  }

  componentDidMount() {
    this.addFirstChildClass();
  }

  // Called when the SliderDashboard view opens
  componentDidUpdate(prevProps, prevState) {
    if (prevState.showAll !== this.state.showAll && !this.state.showAll) {
      this.addFirstChildClass();
    }
  }

  // Adds first-child class to dashboards first child element
  addFirstChildClass = () => {
    $(".dashboard").children().first().addClass("first-child");
  };

  componentWillReceiveProps(nextProps) {
    if (this.props === nextProps) {
      return;
    }

    if (nextProps.data !== this.state.data) {
      this.sortDashboardBy(nextProps.data, nextProps.dataLabel)
    }
  }

  /**
   * Decide dashboards will be shown as list or slider
   */
  switchListMode = () => {
    this.setState({ ...this.state, showAll: !this.state.showAll });
  };

  componentWillMount() {
    this.sortDashboardBy(this.props.data, this.props.dataLabel);
  }

  /**
   * sort dashboards by given parameters
   * @param {*} data
   * @param {*} dataLabel
   */
  sortDashboardBy = (data, dataLabel) => {
    let dashboards = [...data]
    let tempDataLabel = {...dataLabel};
    let selectedSortKey = tempDataLabel.sortKey;
    let selectedSortType = tempDataLabel.sortType;

    if (selectedSortKey === "name") {
      dashboards.sort((a, b) => sortByLanguage(a[selectedSortKey], b[selectedSortKey]) * (selectedSortType === "desc" ? -1 : 1));
    } else if (selectedSortKey === "lastUpdatedDate") {
      dashboards.sort((a, b) => {
        a = a[selectedSortKey] ? Date.parse(a[selectedSortKey]) : Date.parse(a["creationDate"]);
        b = b[selectedSortKey] ? Date.parse(b[selectedSortKey]) : Date.parse(b["creationDate"]);
        return (a - b) * (selectedSortType === "desc" ? -1 : 1);
      });
    } else {
      dashboards.sort((a, b) => (a[selectedSortKey] - b[selectedSortKey]) * (selectedSortType === "desc" ? -1 : 1));
    }

    tempDataLabel.sortKey = selectedSortKey;
    tempDataLabel.sortType = selectedSortType;

    this.setState({
      ...this.state,
      dataLabel: {...tempDataLabel},
      data: data,
      sortedDashboardList: dashboards,
    });
  };

  render() {
    return (
      <div class="section">
        <DashboardLabelHeader
          dataLabel={this.state.dataLabel}
          data={this.props.data}
          sortDashboardBy={this.sortDashboardBy}
          switchListMode={this.switchListMode}
        />

        {this.state.showAll ? (
          <ListAllDashboard
            refresh={this.props.refresh}
            selectReport={this.props.selectReport}
            dataLabel={this.state.dataLabel}
            data={this.state.sortedDashboardList}
            dashboardDelete={this.props.dashboardDelete}
            dashboardReloaded={this.props.dashboardReloaded}
            dashboardDeletedPermanently={this.props.dashboardDeletedPermanently}
          />
        ) : (
          <SliderDashboard
            refresh={this.props.refresh}
            selectReport={this.props.selectReport}
            dataLabel={this.state.dataLabel}
            data={this.state.sortedDashboardList}
            dashboardDelete={this.props.dashboardDelete}
            dashboardReloaded={this.props.dashboardReloaded}
            dashboardDeletedPermanently={this.props.dashboardDeletedPermanently}
          />
        )}
      </div>
    );
  }
}
