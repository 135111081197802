import $ from "jquery";
import { store } from "../index";
import { setPagePopupContent } from "../ui/GeneralComponents/PagePopup/PagePopupAction";

/**
 * Page popup component configuration method
 *
 * container: The container to set the position of the popup to
 * popupId: Which page popup to set
 * content: Content to be pressed in the popup container
 * header: Popup header object - title and description
 */

export const calculatePopupPosition = (container, popupWidth, popupHeight) => {
  let location;
  const L = "left";
  const R = "right";
  const I = "inside";

  let width = container.width();
  let height = container.height();
  let top = container.offset().top;
  let left = container.offset().left;

  let margin = {
    left: 15,
    right: 15
  };

  let padding = {
    left: 10,
    right: 10
  };

  let documentWidth = $(document).width();
  let documentHeight = $(document).height();

  let popupPosition = {
    top: 0,
    left: 0
  };

  let containerIconPosition = {
    top: 0,
    left: 0,
    display: "none",
    height: 57
  };

  const popupLeftPadding = 8 //padding popup between plugin

  /**
   * Will the pop up on the right or left.
   */

  if (documentWidth > width + left + popupWidth + margin.right) {
    location = R;
    popupPosition.left = -width - popupLeftPadding - 11; //11 means, popupContainer's icon width - margins
    popupPosition.top = -2; // -2 fix top
    containerIconPosition.left = -17; // -17 for calibrate margin-left
    containerIconPosition.top =
      18 - containerIconPosition.height / 2 + height / 2; // 18 for calibrate margin-top
    containerIconPosition.display = "flex";
  } else {
    if (left - margin.left - popupWidth >= 0) {
      location = L;
      popupPosition.left = popupWidth + 5 + popupLeftPadding;
      popupPosition.top = -2; // -2 fix top
      containerIconPosition.left = popupWidth + 17 - 19; // -19 for calibrate icon div
      containerIconPosition.top =
        18 - containerIconPosition.height / 2 + height / 2;
      containerIconPosition.display = "flex";
    } else {
      location = I;

      //inside but overflow from field
      if (left + width < popupWidth) {
        if (left + popupWidth > documentWidth && popupWidth + 10 < documentWidth) { //overflow from right of field
          popupPosition.left = left + popupWidth - documentWidth + 10;
        } else {
          popupPosition.left = 0;
        }
      } else {
        popupPosition.left = popupWidth + 15 - width; // 15 inside padding
      }
      
      popupPosition.top = 37; // inside margin top from plugin tools buttons
      containerIconPosition.display = "none";
    }
  }

  popupPosition.containerIconPositions = {...containerIconPosition}

  return popupPosition;
};

export const pagePopupConfigure = (
  container,
  popupId,
  content,
  header,
  popupWidth,
  pluginId
) => {
  let popupHeight = parseInt($("#" + popupId).attr("height"));

  let location;
  const L = "left";
  const R = "right";
  const I = "inside";

  let width = container.outerWidth();
  let height = container.outerHeight();
  let top = container.offset().top;
  let left = container.offset().left;

  let margin = {
    left: 15,
    right: 15
  };

  let padding = {
    left: 10,
    right: 10
  };

  let documentWidth = $(document).width();
  let documentHeight = $(document).height();

  let popupPosition = {
    top: 0,
    left: 0
  };

  let containerIconPosition = {
    top: 0,
    left: 0,
    display: "none",
    height: 57
  };

  /**
   * Will the pop up on the right or left.
   */

  if (documentWidth > width + left + popupWidth + margin.right) {
    location = R;
    popupPosition.left = left + width + margin.left;
    containerIconPosition.left = -17; // -17 for calibrate margin-left
    containerIconPosition.top =
      18 - containerIconPosition.height / 2 + height / 2; // 18 for calibrate margin-top
    containerIconPosition.display = "flex";
  } else {
    if (left - margin.left - popupWidth >= 0) {
      location = L;
      popupPosition.left = left - margin.left - popupWidth;
      containerIconPosition.left = popupWidth + 17 - 19; // -19 for calibrate icon div
      containerIconPosition.top =
        18 - containerIconPosition.height / 2 + height / 2;
      containerIconPosition.display = "flex";
    } else {
      location = I;
      popupPosition.left = left + width - popupWidth - padding.right;
      containerIconPosition.display = "none";
    }
  }

  let toPluginToolsBottomSetTopPx = 52; // 52px for plugin tools calibrate margin top/bottom

  if (location == I) {
    popupPosition.top = top + toPluginToolsBottomSetTopPx;
  } else {
    popupPosition.top = top;
  }

  /**
   * Will the pop up on the right or left.
   */

  if (location !== I) {
    let newDocumentHeight = popupPosition.top + popupHeight;

    if (documentHeight < newDocumentHeight) {
      popupPosition.top = top - popupHeight + height;
      containerIconPosition.top =
        popupHeight + 15 - height / 2 - containerIconPosition.height / 2; //15 for calibrate icon
    }
  }

  store.dispatch(
    setPagePopupContent({
      pluginId: pluginId,
      header: header,
      content: content,
      popupPositions: {
        left: popupPosition.left,
        top: popupPosition.top
      },
      containerIconPositions: {
        left: containerIconPosition.left,
        top: containerIconPosition.top,
        display: containerIconPosition.display
      },
      visible: true,
      popupId: popupId,
      width: popupWidth
    })
  );
};

export const pagePopupContentUpdate = (content, pluginId) => {
  if (content.props.pluginId === pluginId) {
    store.dispatch(
      setPagePopupContent({
        content: content,
        visible: false
      })
    );
  }
};

export const pagePopupVisible = status => {
  store.dispatch(
    setPagePopupContent({
      visible: status
    })
  );
};
