export const configuration = {
    size: 300
};

export const actions = [
    {
        trigger: "sectionClick",
        type: "click",
        name: "Tıklama - Bölüm",
        output: ["level"],
        description: "SectionClick2Desc"
    },
    {
        trigger: "sectionHover",
        type: "hover",
        name: "Hover - Bölüm",
        output: ["level"],
        description: "SectionHover3Desc"
    }
];

export const reactions = [
    {
        id: "filter",
        name: "Filtre",
        description: "desc87",
        type: "general"
    }
];

export const conditionalFormatColumnMap = ["level", "measure"];
export const conditionalFormatTargetMap = ["level"];
export const conditionalFormatOptions = ["backgroundColor"];

export const conversionMap = {
    "table": {
        columnMap: {
            columns: {
                name: "Columns",
                from: ["level", "measure"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        },
    },
    "pie-chart": {
        columnMap: {
            category: {
                name: "Category",
                from: ["level"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["measure"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
    "chord-diagram": {
        columnMap: {
            entities: {
                name: "Entities",
                from: ["level"],
                multiple: true,
                minimumColumnSize: 2,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["measure"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
    "sankey-chart": {
        columnMap: {
            level: {
                name: "Level",
                from: ["level"],
                multiple: true,
                minimumColumnSize: 2,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["measure"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
    "cylinder-bar": {
        columnMap: {
            label: {
                name: "Label",
                from: {"level": [0]},
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            values: {
                name: "Values",
                from: ["measure"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
    "age-pyramid": {
        columnMap: {
            category: {
                name: "Category",
                from: {"level": [0]},
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            either: {
                name: "Either",
                from: ["measure"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            other: {
                name: "Other",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            }
        }
    },
    "line-bar": {
        columnMap: {
            category: {
                name: "Category",
                from: {"level": [0]},
                multiple: false,
                minimumColumnSize: 1,
            },
            columns: {
                name: "Columns",
                from: [],
                dependentColumns: ["Lines"],
                multiple: true,
                minimumColumnSize: 1,
            },
            lines: {
                name: "Lines",
                from: ["measure"],
                dependentColumns: ["Columns"],
                multiple: true,
                minimumColumnSize: 1,
            },
            vary: {
                name: "Vary",
                from: {"level": [1]},
                multiple: false,
                minimumColumnSize: 0,
            }
        }
    },
    "bar-chart": {
        columnMap: {
            category: {
                name: "Category",
                from: {"level": [0]},
                multiple: false,
                minimumColumnSize: 1,
            },
            columns: {
                name: "Columns",
                from: ["measure"],
                dependentColumns: ["Lines"],
                multiple: true,
                minimumColumnSize: 1,
            },
            lines: {
                name: "Lines",
                from: [],
                dependentColumns: ["Columns"],
                multiple: true,
                minimumColumnSize: 1,
            },
            vary: {
                name: "Vary",
                from: {"level": [1]},
                multiple: false,
                minimumColumnSize: 0,
            }
        }
    },
    "measure-tile": {
        columnMap: {
            measure: {
                name: "Measure",
                from: ["measure"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
            hidden: {
                from: ["level"]
            }
        }
    },
    "gauge-chart": {
        columnMap: {
            measure: {
                name: "Measure",
                from: ["measure"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            minimum: {
                name: "Minimum",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            },
            maximum: {
                name: "Maximum",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            },
            target: {
                name: "Target",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            },
            hidden: {
                from: ["level"]
            }
        }
    },
    "liquid-gauge": {
        columnMap: {
            measure: {
                name: "Measure",
                from: ["measure"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            target: {
                name: "Target",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            },
            hidden: {
                from: ["level"]
            }
        }
    },
    "radar": {
        columnMap: {
            category: {
                name: "Category",
                from: ["level"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["measure"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
    "tree-map": {
        columnMap: {
            group: {
                name: "Group",
                from: {"level": [0]},
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["measure"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            vary: {
                name: "Vary",
                from: {"level": [1]},
                multiple: false,
                minimumColumnSize: 0,
                required: false
            }
        }
    },
    "word-cloud": {
        columnMap: {
            word: {
                name: "Word",
                from: {"level": [0]},
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            freq: {
                name: "Frequency",
                from: ["measure"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
    "pivot-table": {
        columnMap: {
            measures: {
                name: "Measures",
                from: ["measure"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
            rows: {
                name: "Rows",
                from: ["level"],
                multiple: true,
                minimumColumnSize: 0,
                required: false
            },
            columns: {
                name: "Columns",
                from: [],
                multiple: true,
                minimumColumnSize: 0,
                required: false
            }
        }
    }
};