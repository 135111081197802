import React, { Component } from "react";
import SettingsArea from "../DashboardPage/SettingsArea";
import DashboardList from "../DashboardPage/DashboardList";
import { get, post } from "../../Utils/WebService";
import { API_BASE, DELETED_LABEL } from "../../config";
import EmptyLandingPage from "./EmptyLandingPage";
import PagePopup from "../GeneralComponents/PagePopup/PagePopup";
import { deepCopy } from "../../Utils/Global";
import { removeSelectedLabels } from "./LabelStore"
import { labelLoadingTranslateNameSet, labelTranslateNameSet } from "../../Utils/LabelOperations";
import TopMenu from "../TopMenu/TopMenu";
export default class DashboardArea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedLabels: [],
      dashboardsByLabel: [],
      loadingDashboards: [],
    };
  }

  /**
   * Refresh dashboards pass false as parameter because no need to fetch dashboards from api again
   */
  componentWillMount() {
    removeSelectedLabels();
    this.refreshDashboardBySelectedLabels(false);
  }

  /**
   * Fill loading list when labels get
   */
  addNewLabelInLoadingDashboard = (loadingDashboards, selectedLabel) => {
    let loadingDasboardsByLabel = {};

    loadingDasboardsByLabel["label"] = selectedLabel;
    loadingDasboardsByLabel["labelId"] = selectedLabel.id;
    loadingDasboardsByLabel["labelColor"] = selectedLabel.color;
    loadingDasboardsByLabel["visible"] = true;
    loadingDasboardsByLabel["labelDisplayName"] = selectedLabel.displayName;
    loadingDasboardsByLabel["loading"] = true;
    loadingDasboardsByLabel["dashboards"] = [];

    loadingDashboards.push(loadingDasboardsByLabel);
  }

  /**
   * get selected labels from cookies if parameter is true fetch dashboard from api check if it is not only check
   * @param {*} shouldIgetRefreshData
   */
  refreshDashboardBySelectedLabels = (shouldIgetRefreshData) => {
    let url = `${API_BASE}/userlabels`;

    const successFunc = (result) => {
      let copySelectedLabels = result.data.filter(label => label !== null && label !== undefined);
      let loadingDashboards = [...this.state.loadingDashboards];

      for (let i = 0; i < copySelectedLabels.length; i++) {
        let copySelectedLabel = copySelectedLabels[i];

        if (shouldIgetRefreshData === true) {
          this.getDashboardByLabel(copySelectedLabel);
        } else {
          this.addNewLabelInLoadingDashboard(loadingDashboards, copySelectedLabel)
          this.checkDashboardByLabel(copySelectedLabel);
        }
      }

      this.setState({
        ...this.state,
        selectedLabels: labelTranslateNameSet(copySelectedLabels),
        loadingDashboards: labelLoadingTranslateNameSet(loadingDashboards)
      });
    };

    get(url, successFunc, undefined, false);
  };

  /** Remove loaded label from loading dashboard list by filter */
  removeLoadedLabelFromLoadingDashboardList = (loadingDashboards, label) => {
    return loadingDashboards.filter(d => d.labelId != label.id)
  }

  /**
   * manage dashboard visibility if dashboardsbylabel array doesn't include label which is a parameter fetch dashboards from api
   * @param {*} label
   * @param {*} labelDisplayName
   * @param {*} labelColor
   */
  checkDashboardByLabel = (label, loadingDashboards = undefined) => {
    let isDashboard = false;
    let copyDashboardsByLabel = deepCopy(this.state.dashboardsByLabel);

    for (let i = 0; i < copyDashboardsByLabel.length; i++) {
      let dashboard = copyDashboardsByLabel[i];

      if (dashboard.label.displayName === label.displayName) {
        dashboard.visible = !dashboard.visible;
        isDashboard = true;

        if (loadingDashboards) {
          loadingDashboards = this.removeLoadedLabelFromLoadingDashboardList(loadingDashboards, label)
        }
      }
    }

    if (!isDashboard) {
      this.getDashboardByLabel(label);
    }

    return { copyDashboardsByLabel: copyDashboardsByLabel, isDashboard: isDashboard, loadingDashboards: loadingDashboards };
  };

  /**
   * fetch dashboards from api by given label and group dashboards by their labels
   * @param {*} label
   * @param {*} labelDisplayName
   * @param {*} labelColor
   */
  getDashboardByLabel = (label) => {
    let url = `${API_BASE}/dashboard/?labelId=` + label.id;

    let labelsWillNotSort = new Set([
      "2222-1212-2212-2212",  // Most view (week)
      "3333-1212-2212-2212",  // Most view (month)
      "7777-1212-2212-2212"   // Recent popular
    ]);

    const successFunc = (result) => {
      let newDashboardList = {};
      newDashboardList["label"] = label;
      newDashboardList["labelId"] = label.id;
      newDashboardList["labelDisplayName"] = label.displayName;
      newDashboardList["labelColor"] = label.color;
      newDashboardList["visible"] = true;
      newDashboardList["dashboards"] = result.data == "" ? [] : result.data;
      newDashboardList["sortKey"] = !labelsWillNotSort.has(label.name) ? !label.sortKey ? "name" : label.sortKey : null;
      newDashboardList["sortType"] = !labelsWillNotSort.has(label.name) ? !label.sortType ? "asc" : label.sortType : null;

      let copyDashboardsByLabel = [];
      let found = false;
      let copyLoadingDashboards = [...this.state.loadingDashboards];

      for (var i = 0; i < this.state.dashboardsByLabel.length; i++) {
        if (this.state.dashboardsByLabel[i].label.id === label.id) {
          found = true;
          copyLoadingDashboards = this.removeLoadedLabelFromLoadingDashboardList(copyLoadingDashboards, newDashboardList.label)
          copyDashboardsByLabel.push(newDashboardList);
        } else {
          let cloneOfDashboardsByLabel = deepCopy(this.state.dashboardsByLabel);
          copyLoadingDashboards = this.removeLoadedLabelFromLoadingDashboardList(copyLoadingDashboards, newDashboardList.label)
          copyDashboardsByLabel.push(cloneOfDashboardsByLabel[i]);
        }
      }

      if (found === false) {
        copyLoadingDashboards = this.removeLoadedLabelFromLoadingDashboardList(copyLoadingDashboards, newDashboardList.label)
        copyDashboardsByLabel.push(newDashboardList);
      }

      this.setState({
        dashboardsByLabel: copyDashboardsByLabel,
        loadingDashboards: copyLoadingDashboards
      });
    };

    get(url, successFunc, undefined, false);
  };

  /**
   * select or unselect label push it to selectedlabels list or splice it from that list
   * @param {*} label
   * @param {*} labelDisplayName
   * @param {*} labelColor
   */
  changeSelectedLabels = (label) => {
    let processingSelectedLabels = deepCopy(this.state.selectedLabels);

    let index = processingSelectedLabels.findIndex(
      (item) => item.id === label.id
    );

    let loadingDashboards = [...this.state.loadingDashboards];
    let copyDashboardsByLabel;

    let copySelectedLabel = { ...label };
    let resultCheckDashboardByLabel = {};

    if (index == -1) {
      processingSelectedLabels.push(copySelectedLabel);

      this.addNewLabelInLoadingDashboard(loadingDashboards, copySelectedLabel);
      resultCheckDashboardByLabel = this.checkDashboardByLabel(copySelectedLabel, loadingDashboards);

      if (resultCheckDashboardByLabel.isDashboard) {
        loadingDashboards = this.removeLoadedLabelFromLoadingDashboardList(loadingDashboards, copySelectedLabel)
      }
    } else {
      loadingDashboards = this.removeLoadedLabelFromLoadingDashboardList(loadingDashboards, copySelectedLabel)
      resultCheckDashboardByLabel = this.checkDashboardByLabel(copySelectedLabel, loadingDashboards);
      processingSelectedLabels.splice(index, 1);
    }

    this.setState({
      ...this.state,
      dashboardsByLabel: resultCheckDashboardByLabel.copyDashboardsByLabel,
      selectedLabels: deepCopy(processingSelectedLabels),
      loadingDashboards: resultCheckDashboardByLabel.loadingDashboards
    });

    let url = `${API_BASE}/userlabels`;

    post(url, processingSelectedLabels, () => { });
  };

  /**
   * Removes gives dashboard from deleted list
   */
  dashboardReloaded = (dashboard) => {
    let newDashboardsByLabel = [...this.state.dashboardsByLabel];

    for (let i = 0; i < newDashboardsByLabel.length; i++) {
      let dashboardByLabel = { ...newDashboardsByLabel[i] };

      if (dashboardByLabel.label.name === DELETED_LABEL) {
        let dashboards = [...dashboardByLabel.dashboards];
        let dashboardIndex = dashboards.findIndex(d => d.id === dashboard.id);

        if (dashboardIndex >= 0) {
          dashboards.splice(dashboardIndex, 1);
          dashboardByLabel.dashboards = dashboards;
          newDashboardsByLabel[i] = dashboardByLabel;

          break;
        }
      }
    }

    for (let i = 0; i < newDashboardsByLabel.length; i++) {
      let dashboardByLabel = { ...newDashboardsByLabel[i] };

      if (dashboardByLabel.label.name !== DELETED_LABEL) {
        if (dashboard.labels.some(l => l.displayName === dashboardByLabel.labelDisplayName)) {
          let dashboards = [...dashboardByLabel.dashboards];

          dashboards.push(dashboard);
          dashboardByLabel.dashboards = dashboards;
          newDashboardsByLabel[i] = dashboardByLabel;
        }
      }
    }

    this.setState({
      ...this.state,
      dashboardsByLabel: newDashboardsByLabel,
    });
  };

  /**
   * Mark as deleted for given dashboard and reorganize dashboardsByLabel
   * @param {*} dashboard
   */
  dashboardDelete = (dashboard) => {
    let newDashboardsByLabel = [...this.state.dashboardsByLabel];

    for (let i = 0; i < newDashboardsByLabel.length; i++) {
      let dashboardByLabel = { ...newDashboardsByLabel[i] };

      if (dashboardByLabel.label.name !== DELETED_LABEL) {
        let dashboards = [...dashboardByLabel.dashboards];
        let dashboardIndex = dashboards.findIndex(d => d.id === dashboard.id);

        if (dashboardIndex >= 0) {
          dashboards.splice(dashboardIndex, 1);
          dashboardByLabel.dashboards = dashboards;
          newDashboardsByLabel[i] = dashboardByLabel;
        }
      }
    }

    for (let i = 0; i < newDashboardsByLabel.length; i++) {
      let dashboardByLabel = { ...newDashboardsByLabel[i] };

      if (dashboardByLabel.label.name === DELETED_LABEL) {
        let dashboards = [...dashboardByLabel.dashboards];

        dashboards.push(dashboard);
        dashboardByLabel.dashboards = dashboards;
        newDashboardsByLabel[i] = dashboardByLabel;

        break;
      }
    }

    this.setState({
      ...this.state,
      dashboardsByLabel: newDashboardsByLabel,
    });
  };

  /**
   * Deletes given dashboard from all lists
   */
  dashboardDeletedPermanently = (dashboard) => {
    let newDashboardsByLabel = this.removeDashboardFromRelatedLabels(dashboard);

    this.dashboardDelete(dashboard)

    this.setState({
      ...this.state,
      dashboardsByLabel: newDashboardsByLabel,
    });
  };

  removeDashboardFromRelatedLabels = (dashboard) => {
    let newDashboardsByLabel = [...this.state.dashboardsByLabel];

    for (let i = 0; i < this.state.dashboardsByLabel.length; i++) {
      let dashboards = this.state.dashboardsByLabel[i].dashboards;
      let dashboardIndex = dashboards.findIndex((d) => d.id == dashboard.id);

      // if greater then -1 than there is a dashboard
      if (dashboardIndex > -1) {
        let dashboardByLabel = { ...this.state.dashboardsByLabel[i] };
        dashboards.splice(dashboardIndex, 1); // 1 number of items that will be deleted
        dashboardByLabel.dashboards = dashboards;
        newDashboardsByLabel[i] = dashboardByLabel;
      }
    }

    return newDashboardsByLabel;
  };

  /**
   * Creates a set of selected labels for use in DashboardList 
   */
  setSelectedLabelsToSet = () => {
    let selectedLabelsHasSet = new Set()

    for (let i = 0; i < this.state.selectedLabels.length; i++) {
      selectedLabelsHasSet.add(this.state.selectedLabels[i].id)
    }

    return selectedLabelsHasSet;
  }

  updateCustomThumbnail = (id, thumbnail) => {
    let newDashboardsByLabel = [...this.state.dashboardsByLabel];

    for (let i = 0; i < this.state.dashboardsByLabel.length; i++) {
      let dashboardsReports = this.state.dashboardsByLabel[i].dashboards;
      let dashboardIndex = dashboardsReports.findIndex((d) => d.id === id);

      if (dashboardIndex > -1) {
        let dashboardByLabel = { ...this.state.dashboardsByLabel[i] };
        
        dashboardsReports[dashboardIndex].customThumbnail = thumbnail;

        dashboardByLabel.dashboards = dashboardsReports;
        newDashboardsByLabel[i] = dashboardByLabel;
      }
    }

    this.setState({
      dashboardsByLabel: newDashboardsByLabel
    });
  };

  render() {
    let selectedLabelsHasSet = this.setSelectedLabelsToSet();

    return (
      <div style={{ width: "100%", height: "calc(100dvh - 30px)", overflow: "auto" }}>
        <TopMenu
          searchBarVisible={true}
          titleVisible={false}
          themeObj={this.props.themeObj}
          showLabels={true}
          selectedLabels={this.state.selectedLabels}
          changeSelectedLabels={(data, labelDisplayName, labelColor) =>
            this.changeSelectedLabels(data, labelDisplayName, labelColor)
          }
        />

        <section class="dashboard-section" style={{ position: "relative", height: "calc(100% - 57px)" }}>
          <div class="container-fluid" style={{ padding: "0", overflow: "auto", height: "100%", position: "relative" }}>
            <div class="dashboard-area" >
              <SettingsArea
                selectedLabels={this.state.selectedLabels}
                changeSelectedLabels={(data, labelDisplayName, labelColor) =>
                  this.changeSelectedLabels(data, labelDisplayName, labelColor)
                }
                themeObj={this.props.themeObj}
              />
              {this.state.selectedLabels.length > 0 ? (
                <DashboardList
                  refresh={this.refreshDashboardBySelectedLabels}
                  loadingDashboards={this.state.loadingDashboards}
                  dashboardsByLabel={this.state.dashboardsByLabel}
                  selectedLabels={selectedLabelsHasSet}
                  dashboardDelete={this.dashboardDelete}
                  dashboardReloaded={this.dashboardReloaded}
                  dashboardDeletedPermanently={this.dashboardDeletedPermanently}
                  updateCustomThumbnail={this.updateCustomThumbnail}
                />
              ) : (
                <EmptyLandingPage />
              )}
            </div>
          </div>
          <PagePopup
            id={"landingPagePagePopup"}
            title={""}
            description={""}
            width={350}
            constantHeight={445}
          ></PagePopup>
        </section>

      </div>
    );
  }
}
