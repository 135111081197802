import i18n from "../../Utils/i18next";

const filterPlugin = {
  actions: [
    {
      trigger: "select",
      type: "click",
      name: "select",
      output: ["filter"],
      description: ""
    }
  ],
  reactions: [
    {
      id: "filter",
      name: "Filtre",
      description: "desc78",
      type: "general"
    }
  ],
  titleReactions: [
    {
      id: "none",
      name: i18n.t("Dashboard.Configuration.Fields.None"),
      description: "desc232",
      type: "private",
      method: "none"
    },
  ]
};

const selectionBoxPlugin = {
  actions: [
    {
      trigger: "click",
      type: "click",
      name: "Tıklama",
      output: ["filter"],
      description: "ActionClickDesc"
    }
  ],
  reactions: [
    {
      id: "filter",
      name: "Filter",
      description: "desc215",
      type: "general"
    }
  ],
  titleReactions: [
    {
      id: "none",
      name: i18n.t("Dashboard.Configuration.Fields.None"),
      description: "desc232",
      type: "private",
      method: "none"
    },
  ]
};

const dateFilterPlugin = {
  actions: [
    {
      trigger: "select",
      type: "click",
      name: "select",
      output: ["filter"],
      description: ""
    }
  ],
  reactions: [
    {
      id: "filter",
      name: "Filtre",
      description: "desc78",
      type: "general"
    }
  ],
  titleReactions: [
    {
      id: "none",
      name: i18n.t("Dashboard.Configuration.Fields.None"),
      description: "desc232",
      type: "private",
      method: "none"
    },
  ]
};

const radioButtonFilter = {
  actions: [
    {
      trigger: "click",
      type: "click",
      name: "Tıklama",
      output: ["filter"],
      description: "ActionClickDesc"
    }
  ],
  reactions: [
    {
      id: "filter",
      name: "Filter",
      description: "desc87",
      type: "general"
    }
  ],
  titleReactions: [
    {
      id: "none",
      name: i18n.t("Dashboard.Configuration.Fields.None"),
      description: "desc232",
      type: "private",
      method: "none"
    },
  ]
};

const buttonFilter = {
  actions: [
    {
      trigger: "click",
      type: "click",
      name: "Tıklama",
      output: ["filter"],
      description: "ActionClickDesc"
    }
  ],
  reactions: [
    {
      id: "filter",
      name: "Filter",
      description: "desc87",
      type: "general"
    }
  ],
  titleReactions: [
    {
      id: "none",
      name: i18n.t("Dashboard.Configuration.Fields.None"),
      description: "desc232",
      type: "private",
      method: "none"
    },
  ]
};

/**
 * Set filter plugins actions and reactions 
 */
export const filterPluginsActionsAndReactions = new Map([
  ["filter", filterPlugin],
  ["selection-box", selectionBoxPlugin],
  ["date-filter", dateFilterPlugin],
  ["radio-button-filter", radioButtonFilter],
  ["button-filter", buttonFilter]
]);
