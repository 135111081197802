import Cookies from "js-cookie";

const currentLanguage = Cookies.get("i18next")

export const decimalSeperators = currentLanguage === "tr" ? "," : "."

export const thousandSeperators = currentLanguage === "tr" ? "." : ","

export const bigNumberPrefixes = currentLanguage === "tr" ? [
    { number: 1e3, suffix: "B" },
    { number: 1e6, suffix: "M" },
    { number: 1e9, suffix: "Mr" },
  ] : [
    { number: 1e3, suffix: "K" },
    { number: 1e6, suffix: "M" },
    { number: 1e9, suffix: "B" },
  ]

export const longMonths = currentLanguage === "tr" ? 
    [
        "Ocak",
        "Şubat",
        "Mart",
        "Nisan",
        "Mayıs",
        "Haziran",
        "Temmuz",
        "Ağustos",
        "Eylül",
        "Ekim",
        "Kasım",
        "Aralık",
    ] : [
        "January",
        "February",
        "March",
        "April",
        "May",
        "Jun",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ]

export const shortMonths = currentLanguage === "tr" ? 
    [
        "Ocak",
        "Şubat",
        "Mart",
        "Nisan",
        "Mayıs",
        "Haziran",
        "Temmuz",
        "Ağustos",
        "Eylül",
        "Ekim",
        "Kasım",
        "Aralık",
    ] : [
        "January",
        "February",
        "March",
        "April",
        "May",
        "Jun",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ]
