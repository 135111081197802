import React, { Component } from "react";
import Text from "../../GeneralComponents/Text/Text";
import Tooltip from "../../GeneralComponents/Tooltip/Tooltip";
import { Row, Col, Input, Select, Divider, Button, InputNumber } from "antd";
import {
  FontSizeOutlined,
  BgColorsOutlined,
} from "@ant-design/icons";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import $ from "jquery";
import i18n from "../../../Utils/i18next";
import ChangeFontStylePlugin from "../../ConditionalFormatting/ChangeFontStylePlugin";
import FontTypeSelector from "../../GeneralComponents/FontTypeSelector/FontTypeSelector";

const { Option } = Select;

/**This component configures title features of plugin.*/
export default class TitleSettingsOfPlugin extends Component {
  //changes titles font-family
  titleFontOnChange = obj => {
    this.props.handleChange({
      target: {
        id: "titleFont", 
        value: obj
      }
    });
  };

  /* Changes title's font size to typed value if its in the right interval 
  if not sets max or min value to size and warns user by making background slightly red and giving notification. */
  titleFontSizeOnChange = (e) => {
    let size;

    if (Number(e.target.value) > 30) {
      size = 30;

      $("#titleFontSize").css("background", "#ff000042");
    } else if (Number(e.target.value) < 10) {
      size = 10;

      $("#titleFontSize").css("background", "#ff000042");
    } else {
      size = Number(e.target.value);

      $("#titleFontSize").css("background", "");
    }

    this.props.handleChangeBothOfValues(size, e.target.value)
  };

  /* Shows warning text according to users typed font size value. 
  document.getElementById used because this warning based on input value (which user can type out of bound size values). So this warning 
  is NOT based on this.props.titleFontSize because titleFontSize is controlled (cannot be out of the bounds: 10px <= this.props.titleFontSize <= 30px) */
  sizeWarning = () => {
    let titleFontSize = this.props.titleFontSize
    let isFontSizeInWrongInterval = titleFontSize !== null && (titleFontSize === "" || Number(titleFontSize) < 10 || Number(titleFontSize) > 30);
    let tooSmallWarning = "* " + i18n.t("Dashboard.Configuration.Fields.CantReduceMeasureSize", { size: `10` });
    let tooBigWarning = "* " + i18n.t("Dashboard.Configuration.Fields.CantChangeMeasureSize", { size: `30` });
    
    return ( isFontSizeInWrongInterval ? (
      <Row style={{height:"11px"}}>
        <Col span={12}></Col>
        <Col span={12} style={{fontSize:"11px", textAlign:"left", color:"red"}}>
          { Number(titleFontSize) < 10 ?  tooSmallWarning : tooBigWarning }
        </Col>
      </Row>
    ): null );
  }

  // changes title colour
  titleColourOnChange = obj => {
    this.props.handleChange({
      target: {
        id: "titleColour", 
        value: obj.color
      }
    });
  };

  //changes title align
  titleAlignOnChange = value => {
    this.props.handleChange({
      target: {
        id: "titleAlign", 
        value: value
      }
    });
  };

  //apply title features to other plugins at dashboard
  handleApplyToOtherPlugins = () => {
    this.props.updateCommonTitleConfig(this.props.plugin, this.props.plugin.id);
  }

  //removes applied title features from other plugins at dashboard
  removeAppliedFromOtherPlugins = () => {
    this.props.updateCommonTitleConfig("none");
    /* Sends "none" because the default value of commonTitleConfig is null.
    *  I need to distinguish values that comes when page is refreshed 
    *  from the values that comes when remove features button pressed
    *  so that i can process them correctly. */
  }
  
  //checks if remove features button should be seen or not
  checkRemoveButtonVisibility = () => {
    let isCommonTitleConfigExist = this.props.commonTitleConfig && this.props.commonTitleConfig !== null && this.props.commonTitleConfig !== "none";
    let thisPluginIsCommonTitleConfig = isCommonTitleConfigExist && this.props.commonTitleConfig.id === this.props.plugin.id;

    return thisPluginIsCommonTitleConfig;
  }

  render() {
    let isPluginTitle = this.props.plugin.key === "title" ? true : false

    const returnTitleFontSize = () => {
      let defaultVal = this.props.titleFontSize || this.props.titleFontSize === "" ? this.props.titleFontSize : "15"

      return (      
      <Col span={12}>
        <InputNumber
          id={"titleFontSize"}
          value={defaultVal}
          style={{ width: "100%" }}
          formatter={value => `${value}`.replace(/[^0-9]/g, '')}
          parser={value => `${value}`.replace(/[^0-9]/g, '')}
          onChange={(value) => value || value === 0 ? this.titleFontSizeOnChange({ target: { id: "titleFontSize", value: value } }) : null}
        />
      </Col>)
    }

    return (
      <div>
        <Row>
          <Col span={24}>
            <Divider className={"config-divider"} orientation="left">
              {i18n.t("Dashboard.Settings.TitleSettings")}
            </Divider>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Title")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              name={"titleOfTable"}
              id={isPluginTitle ? "title_" : "title"}
              defaultValue={isPluginTitle ? this.props.title_ : this.props.title}
              value={isPluginTitle ? i18n.t(this.props.title_) : this.props.title.includes(":") ? this.props.title : i18n.t(this.props.title)}
              onChange={this.props.handleChange}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.TitleAlign")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              showSearch
              id={"titleAlign"}
              style={{ width: "100%" }}
              placeholder={i18n.t(
                "Dashboard.Configuration.Fields.SelectTitleAlign"
              )}
              optionFilterProp="children"
              onChange={this.titleAlignOnChange}
              key={this.props.titleAlign} //to trigger defaultValue reverting when returnToDefault button clicked
              defaultValue={this.props.titleAlign || "center"}
              getPopupContainer={trigger => trigger.parentNode}
              dropdownMenuStyle={{textAlign:"left"}}
            >
              <Option name={"centerTitleAlign"} value="center">
                {i18n.t("Dashboard.Configuration.Fields.Center")}
              </Option>
              <Option name={"leftTitleAlign"} value="left">
                {i18n.t("Dashboard.Configuration.Fields.Left")}
              </Option>
              <Option name={"rightTitleAlign"} value="right">
                {i18n.t("Dashboard.Configuration.Fields.Right")}
              </Option>
            </Select>
          </Col>
        </Row>
        <ChangeFontStylePlugin
          titleFontStyle={this.props.titleFontStyle}
          titleFontWeight={this.props.titleFontWeight}
          titleTextDecor={this.props.titleTextDecor}
          handleChange={this.props.handleChange}
          titleColumn={9}
          commonTitleConfig={this.props.commonTitleConfig}
          updateCommonTitleConfig={this.props.updateCommonTitleConfig}
        />
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <i
              class="fa fa-font"
              aria-hidden="true"
              style={{ position: "relative", bottom: "-5px" }}
            >
              {" "}
            </i>
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Title") +
                " " +
                i18n.t("Dashboard.Configuration.Fields.Font")}
            </Text>
          </Col>
          <Col span={12}>
            <FontTypeSelector
              placeholder={this.props.titleFont || "Arial"}
              onChange={this.titleFontOnChange}
              value={this.props.titleFont || "Arial"}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Title") +
                " " +
                i18n.t("Dashboard.Configuration.Fields.FontSize")}
            </Text>
          </Col>
          {returnTitleFontSize()}
        </Row>
        {this.sizeWarning()}
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Title") +
                " " +
                i18n.t("Dashboard.Configuration.Fields.FontColour")}
            </Text>
          </Col>
          <Col style={{ textAlign: "left" }} span={10}>
            <Button
              onClick={this.props.titleColourChangeVisible}
              style={{ width: "100%" }}
            >
              {this.props.titleColourChange
                ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
            </Button>
            {this.props.titleColourChange ? (
              <ColorPickerPanel
                alpha={100}
                color={this.props.titleColour}
                onChange={this.titleColourOnChange}
                id={"titleColour"}
                style={{ marginTop: "5px", position: "absolute", right: 0 }}
              />
            ) : null}
          </Col>
          <Col span={2}>
            {/* selected Color */}
            <div
              onClick={this.props.titleColourChangeVisible}
              style={{
                marginLeft: "5px",
                height: "32px",
                border: "1px solid #d7d6d6",
                boxShadow: "0 2px 0 rgba(0, 0, 0,}",
                borderRadius: "4px",
                cursor: "pointer",
                backgroundColor: this.props.titleColour || "black",
              }}
            ></div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center", marginTop: "10px" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
                maxWidth: "200px"
              }}
            >
              {i18n.t(
                "Dashboard.Configuration.Fields.ApplyTitleFeaturesToOthers"
              )}
            </Text>
          </Col>
          <Col
            style={{
              textAlign: this.checkRemoveButtonVisibility() ? "left" : "center",
              marginTop: "9px",
            }}
            span={12}
          >
            <Button
              onClick={this.handleApplyToOtherPlugins}
              style={{
                width: "100%",
              }}
              title={i18n.t("Dashboard.Configuration.Fields.ApplyFeatures")}
            >
              <span
                style={{
                  width: "100%",
                  verticalAlign: "bottom",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.ApplyFeatures")}
              </span>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
