import React, { Component } from "react";
import { put, get } from "../Utils/WebService";
import { API_BASE } from "../config";
import { showNotificationWithIcon } from "../Utils/Notification";
import {
  Tooltip,
  Button,
  Icon,
  Input,
  Form,
  Layout,
  Modal,
  Progress,
  Alert,
  Switch,
  Row,
  Col
} from "antd";

const FormItem = Form.Item;

class EditUserContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      visibleEdit: false,
      score: 0,
      addStatus: "",
      editUserName: "",
      editLdapUser: false,
      editEmail: "",
      editPassword: "",
      editRePassword: "",
      editID: "",
      searchText: "",
      editEnable: true,
      editAccountNonExpired: true,
      editAccountNonLocked: true,
      editCredentialsNonExpired: true,
      user: []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.visible) {
      this.showEditModal(nextProps.id);
    }
  }

  showEditModal = id => {
    let url = `${API_BASE}/user/` + id;

    const successFunc = response => {
      this.setState({
        user: response.data,
        score: 0,
        editPassword: "",
        editRePassword: "",
        editID: id,
        editUserName: response.data.username,
        editLdapUser: response.data.ldapUser,
        editEmail: response.data.email,
        editEnable: response.data.enabled,
        editAccountNonExpired: response.data.accountNonExpired,
        editAccountNonLocked: response.data.accountNonLocked,
        editCredentialsNonExpired: response.data.credentialsNonExpired
      });
    };

    const errorFunc = error => {
      showNotificationWithIcon("ERROR!", error.response.data.message, "error");
    };

    get(url, successFunc, errorFunc);
  };

  editUser = id => {
    let url = `${API_BASE}/user/` + id;

    let requestBody = this.state.user;
    requestBody.username = this.state.editUserName;
    requestBody.email = this.state.editEmail;
    requestBody.accountNonExpired = this.state.editAccountNonExpired;
    requestBody.accountNonLocked = this.state.editAccountNonLocked;
    requestBody.enabled = this.state.editEnable;
    requestBody.credentialsNonExpired = this.state.editCredentialsNonExpired;
    requestBody.password = "";
    requestBody.ldapUser = this.state.editLdapUser

    if (this.state.editPassword != "") {
      requestBody.password = this.state.editPassword;
    }

    if (this.state.editLdapUser) {
      requestBody.password = null;
    }

    const successFunc = result => {
      this.setState({
        visibleEdit: false,
        editUserName: "",
        editEmail: "",
        editPassword: "",
        editRePassword: "",
        editEnable: true,
        editAccountNonExpired: true,
        editAccountNonLocked: true,
        editCredentialsNonExpired: true,
        editLdapUser: false,
        score: 0,
        user: []
      });

      showNotificationWithIcon("OK", "User successfully updated.", "success");
      this.props.confirm(true);
    };

    const errorFunc = error => {
      this.setState({
        addStatus: error.response.data.message
      });
    };

    put(url, requestBody, successFunc, errorFunc);
  };

  handleCancel = e => {
    this.props.confirm(false);
  };

  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    });
  };

  control = (type, id) => {
    let typeStr = "input";
    if (type == "edit") {
      typeStr = "edit";
    }
    let pass = this.state[typeStr + "Password"];
    let rePass = this.state[typeStr + "RePassword"];
    let score = this.state.score;
    let email = this.state[typeStr + "Email"];
    let username = this.state[typeStr + "UserName"];
    let isLdap = this.state.editLdapUser;

    if (username == "") {
      this.setState({
        addStatus: "User name must'nt empty."
      });
    } else if (email == "") {
      this.setState({
        addStatus: "Email must'nt empty."
      });
    } else if (isLdap == false && pass == "" && type == "add") {
      this.setState({
        addStatus: "Password must'nt empty."
      });
    } else if (isLdap == false && pass != rePass) {
      this.setState({
        addStatus: "Passwords not match."
      });
    } else if (score < 5 && pass != "") {
      this.setState({
        addStatus: "Password strength is not enough."
      });
    } else {
      if (type == "add") {
        this.addUser();
      } else if (type == "edit") {
        this.editUser(id);
      }
    }
  };

  ldapChange = () => {
    if (this.state.editLdapUser == false) {
      this.setState({ editPassword: "", editLdapUser: !this.state.editLdapUser, score: 5 })
    } else {
      this.setState({ editPassword: "", editLdapUser: !this.state.editLdapUser, score: 0 })
    }
  }

  score = e => {
    var currentScore = this.state.score;
    var value = e.target.value,
      passwordLength = 8;
    var containsLowerLetter = /[a-z]/.test(value),
      containsUpperLetter = /[A-Z]/.test(value),
      containsDigit = /\d/.test(value),
      containsSpecial = /[^a-zA-Z\d]/.test(value);
    var containsAll =
      containsLowerLetter &&
      containsUpperLetter &&
      containsDigit &&
      containsSpecial;
    currentScore = 0;

    if (value.length == 0) {
      currentScore = 0;
    } else {
      if (containsAll) {
        currentScore += 4;
      } else {
        if (containsLowerLetter) currentScore += 1;
        if (containsUpperLetter) currentScore += 1;
        if (containsDigit) currentScore += 1;
        if (containsSpecial) currentScore += 1;
      }
      if (value.length >= passwordLength) currentScore += 1;
    }
    this.setState({
      score: currentScore
    });
  };

  render() {
    const formItemLayout = {
      labelCol: { span: 16 },
      wrapperCol: { span: 8 }
    };
    return (
      <Modal
        title="Edit account"
        visible={this.props.visible}
        onCancel={this.handleCancel}
        footer={[]}
      >
        <Form style={{ background: "#fff" }}>
          <FormItem>
            <Input
              placeholder="Username"
              value={this.state.editUserName}
              id="editUserName"
              onChange={this.handleChange}
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              suffix={
                <Tooltip title="Extra information">
                  <Icon
                    type="info-circle"
                    style={{ color: "rgba(0,0,0,.45)" }}
                  />
                </Tooltip>
              }
            />
          </FormItem>
          <FormItem>
            <Input
              type="email"
              placeholder="Email"
              id="editEmail"
              value={this.state.editEmail}
              onChange={this.handleChange}
              prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
              suffix={
                <Tooltip title="Extra information">
                  <Icon
                    type="info-circle"
                    style={{ color: "rgba(0,0,0,.45)" }}
                  />
                </Tooltip>
              }
            />
          </FormItem>
          <Row>
            <Col span={12}></Col>
            <Col span={12}>
              <FormItem label="Ldap User" {...formItemLayout}>
                <Switch
                  checked={this.state.editLdapUser}
                  onChange={this.ldapChange}
                />
              </FormItem>
            </Col>
          </Row>
          <div
            style={{
              display: this.state.editLdapUser == true ? "none" : "block"
            }}
          >
            <FormItem style={{ margin: 0 }}>
              <Input.Password
                value={this.state.editPassword}
                id="editPassword"
                onChange={this.handleChange}
                onKeyUp={this.score}
                placeholder="Password"
                prefix={
                  <Icon type="key" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                suffix={
                  <Tooltip title="Extra information">
                    <Icon
                      type="info-circle"
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  </Tooltip>
                }
                type="password"
              />
            </FormItem>
            <span
              style={{ display: "block", marginTop: "10px", color: "#a0a0a0" }}
            >
              To conform with our Strong Password policy; use at least one lower
              letter, one upper letter, one numeral, one special character, and
              eight characters.
            </span>
            <FormItem style={{ margin: 0 }}>
              <Progress
                percent={this.state.score * 20}
                status={
                  this.state.score <= 2
                    ? "exception"
                    : this.state.score == 5
                    ? "success"
                    : "active"
                }
              />
            </FormItem>
            <FormItem>
              <Input.Password
                placeholder="Re-password"
                id="editRePassword"
                value={this.state.editRePassword}
                onChange={this.handleChange}
                prefix={
                  <Icon type="key" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                suffix={
                  <Tooltip title="Extra information">
                    <Icon
                      type="info-circle"
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  </Tooltip>
                }
                type="password"
              />
            </FormItem>
          </div>
          <Row>
            <Col span={12}>
              <FormItem label="Enable" {...formItemLayout}>
                <Switch
                  onChange={() => {
                    this.setState({ editEnable: !this.state.editEnable });
                  }}
                  checked={this.state.editEnable}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Account Non Expired" {...formItemLayout}>
                <Switch
                  onChange={() => {
                    this.setState({
                      editAccountNonExpired: !this.state.editAccountNonExpired
                    });
                  }}
                  checked={this.state.editAccountNonExpired}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Account Non Locked" {...formItemLayout}>
                <Switch
                  onChange={() => {
                    this.setState({
                      editAccountNonLocked: !this.state.editAccountNonLocked
                    });
                  }}
                  checked={this.state.editAccountNonLocked}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Credentials Non Expired" {...formItemLayout}>
                <Switch
                  onChange={() => {
                    this.setState({
                      editCredentialsNonExpired: !this.state
                        .editCredentialsNonExpired
                    });
                  }}
                  checked={this.state.editCredentialsNonExpired}
                />
              </FormItem>
            </Col>
          </Row>

          <FormItem>
            <Button
              type="primary"
              onClick={() => this.control("edit", this.state.editID)}
              disabled={
                this.state.score != 5 && this.state.editPassword != ""
                  ? "disabled"
                  : ""
              }
              style={{ float: "right" }}
            >
              Save
            </Button>
          </FormItem>
          <FormItem>
            <Alert
              message={this.state.addStatus}
              style={{
                display: this.state.addStatus == "" ? "none" : "block"
              }}
              type={this.state.addStatus == "OK" ? "success" : "error"}
            />
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default EditUserContent;
