import $ from "jquery";
import { getCurrentDateTime } from "../common";

const specialCharCodes = {
  İ: "&#304;",
  ı: "&#305;",
  Ş: "&#350;",
  ş: "&#351;",
  Ğ: "&#286;",
  ğ: "&#287;",
  Ç: "&#199;",
  ç: "&#231;"
};

var divStyles = [];

/** Pivot table excel export main method */
export const pivotExcelExport = (tableID, filename) => {
  let tableSelect = $("#" + tableID).find("table[class=pvtTable]");

  /*
  * Adds <table>{html}<table/> to pivot table header and appends row, other row count times.
  */
  if (tableSelect.find(".header-table").length === 0) {
    tableSelect.find(".firstTd").append("<table class='header-table'></table>")

    let rowSize = tableSelect.find(".colHeader").children().children().length

    for (let i = 0; i < rowSize; i++) {
      tableSelect.find(".header-table").append(`<tr headerColSpan='${i}'></tr>`)
    }
  }

  /*
  * Changes div to th element so we can export headers too.
  * with-no-background class name for delete th elements style.
  */
  tableSelect.find(".firstTd").children().each(function() {
    if (!$(this).hasClass("header-table")) {
      let headerTable = tableSelect.find("[headerColSpan='0']")

      headerTable.css("display", "flex")

      $(this).addClass("with-no-background")

      let thisOuterHtml = this.outerHTML.replaceAll("div", "th")

      headerTable.append(thisOuterHtml);
      $(this).remove()
    }
  })
  
  filename = filename ? filename : "Pivot Table Excel";

  htmlStyleRemove(tableSelect.find("div"));
  prepareHTMLForExport(tableSelect, filename);

  let tableHTML = tableSelect[0].outerHTML;

  tableHTML = tableHTML.replace(/İ|ı|Ş|ş|Ğ|ğ|Ç|ç/gi, function(matched) {
    return specialCharCodes[matched];
  });

  downloadFile(tableHTML, filename)

  removeTHeadFromTable(tableSelect, tableHTML, filename);
  htmlStyleRewrite(tableSelect.find("div"));
};

/** Download html to excel */
const downloadFile = (tableHTML, filename) => {
  let downloadLink;
  let dataType = "application/vnd.ms-excel;charset=UTF-8;";

  let uriContent = "data:" + dataType + ", " + escape(tableHTML);
  let event = new MouseEvent("click");

  // Create download link element
  downloadLink = document.createElement("a");
  downloadLink.setAttribute("href", uriContent);
  downloadLink.setAttribute("download", filename + ".xls");
  downloadLink.dispatchEvent(event);
};

/** Removes styles div in table */
const htmlStyleRemove = content => {
  divStyles = [];
  content.each(function() {
    divStyles.push($(this).attr("style"));
    $(this).removeAttr("style");
  });
};

/** Rewrite styles div in table */
const htmlStyleRewrite = content => {
  content.each(function(index) {
    $(this).attr("style", divStyles[index]);
  });
};

/** Add title for excel */
const addTitleToHTML = (content, title) => {
  content.prepend(
    '<tr><th style="text-align: center" colspan="4">' +
      '<span style="font-weight: bold; text-align: center; font-size: 16px; width: 100%; height: 35px">' +
      title +
      "</span>" +
      "</th></tr>"
  );
};

/** Add time for excel */
const addTimeToHTML = content => {
  let datetime = getCurrentDateTime();

  content.prepend(
    '<tr><th colspan="4">' +
      '<span style="display: inline-block; text-align: right; font-size: 13px; width: 100%; height: 25px;">' +
      datetime +
      "</span>" +
      "</th></tr>"
  );
};

/** Add thead element for table */
const addTHeadToTable = content => {
  content.prepend("<thead id='head-for-export'></thead>");
};

/** Remove thead element from table */
const removeTHeadFromTable = content => {
  content.find("#head-for-export").remove();
};

/** Prepare table html for excel export */
const prepareHTMLForExport = (content, title) => {
  content
    .children()
    .detach()
    .appendTo(content.prepend("<tbody></tbody>"));

  addTHeadToTable(content);
  let thead = content.find("#head-for-export");

  addTimeToHTML(thead);
  addTitleToHTML(thead, title);
};
