import React, { Component } from "react";
import { DatabaseOutlined } from "@ant-design/icons";
import i18n from "../../../../Utils/i18next";
import ReactHtmlParser from 'react-html-parser';

export default class EmptyDataContent extends Component {
  constructor(props) {
    super(props);
  }

  /**
   * To change Style of bold part of Empty Data Sentece
   * @param {*} sentence 
   * @returns 
   */
  convertTextBold = (sentence) => { 
    let hasBoldPart = sentence.indexOf("b#");

    if (hasBoldPart !== -1) {
      let firstIndex = hasBoldPart
      let lastIndex = sentence.lastIndexOf("/b#");
      let boldPart = sentence.substring(firstIndex + 2, lastIndex);
      let newbold = `<b style="color : #84c459c4">${boldPart}</b>`;

      sentence = sentence.replace(sentence.substring(firstIndex, lastIndex + 3), newbold);
    }

    return ReactHtmlParser(sentence);
  }
  
  render() {
    const context = i18n.t("Dashboard.Data.EmptyDataContentText").split("#i");

    return (
      <div className={"empty-dashboard"} style={{ height: "400px" }}>
        <span style={{ fontSize: "20px", color:"#434144c4"}}>
          {this.convertTextBold(context[0])}
          <DatabaseOutlined
            style={{ margin: "0px 5px" }}
            className={"icon-in-text"}
          />
          {this.convertTextBold(context[1])}
        </span>
      </div>
    );
  }
}
