import React, { Component } from "react";
import Text from "../../../GeneralComponents/Text/Text";
import { Row, Col, Input, Select, Checkbox, Divider, Button } from "antd";
import { UpdateConfig } from "../../UpdateConfig";
import {
  FontSizeOutlined,
  NumberOutlined,
  BgColorsOutlined,
  CrownOutlined,
  HeatMapOutlined,
  EyeInvisibleOutlined,
  FileImageOutlined,
  ColumnWidthOutlined,
  ColumnHeightOutlined,
  ReloadOutlined,
  FormatPainterOutlined
} from "@ant-design/icons";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import i18n from "../../../../Utils/i18next"

const { Option } = Select;

/**
* Configuration file of GlassedTitle plugin
*/
export default class GlassedTitleConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: this.props.config.title,
      titleAlign: this.props.config.titleAlign,
      width: this.props.config.width,
      height: this.props.config.height,
      glassWidth: this.props.config.glassWidth,
      glassHeight: this.props.config.glassHeight,
      fontStyle: this.props.config.fontStyle,
      backgroundImageUrl: this.props.config.backgroundImageUrl,
      backgroundColor: this.props.config.backgroundColor,
      colours: this.props.config.colours,
      showHideButton: this.props.config.showHideButton,
      hideLabel: this.props.config.hideLabel,
      fontSize: this.props.config.fontSize,
      summary: this.props.config.summary,
      title_: this.props.config.title_,
      showLastValue: this.props.config.showLastValue,
      toggleCriteria: this.props.config.toggleCriteria
    };
  }

  /**
   * Plugin compenents receive it's created properties
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      this.setState({
        title: nextProps.config.title,
        titleAlign: nextProps.config.titleAlign,
        width: nextProps.config.width,
        height: nextProps.config.height,
        glassWidth: nextProps.config.glassWidth,
        glassHeight: nextProps.config.glassHeight,
        fontStyle: nextProps.config.fontStyle,
        backgroundImageUrl: nextProps.config.backgroundImageUrl,
        backgroundColor: nextProps.config.backgroundColor,
        colours: nextProps.config.colours,
        showHideButton: nextProps.config.showHideButton,
        hideLabel: nextProps.config.hideLabel,
        fontSize: nextProps.config.fontSize,
        summary: nextProps.config.summary,
        title_: nextProps.config.title_,
        showLastValue: nextProps.config.showLastValue,
        toggleCriteria: nextProps.config.toggleCriteria
      });
    }
  }

  /**
   * Changes the value of text attributes in GlassedTitle plugin use handleChange
   */
  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.value });
  };

  /**
   * Checks whether checkbox is checked or not in GlassedTitle plugin use handleCheck
   */
  handleCheck = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.checked });
  };

  /**
   * Updates state attributes in GlassedTitle plugin
   */
   setConfigToPlugin = (changedObj, propertyListToDelete = undefined) => {
    let tempState = { ...this.state };

    if (Array.isArray(changedObj)) {
      for (let i = 0; i < changedObj.length; i++) {
        let object = changedObj[i];
        tempState[object.type] = object.value;
      }
    } else {
      tempState[changedObj.type] = changedObj.value;
    }

    if (propertyListToDelete !== undefined) {
      for (let item of propertyListToDelete) {
        delete tempState[item]
    }
  }

    this.props.updateConfig(
      tempState,
      this.props.pluginId,
      propertyListToDelete
    );
  };

  /**
   * Changes the background colour attribute in GlassedTitle plugin use backgroundColorOnChange
   */
  backgroundColorOnChange = obj => {
    this.setState({
      ...this.state,
      backgroundColor: obj.color
    });

    this.setConfigToPlugin({ type: "backgroundColor", value: obj.color });
  };

  /**
   * Demonstrates how the choosen color will affect the interface in GlassedTitle plugin
   */
  backgroundColorChangeVisible = () => {
    this.setState({
      ...this.state,
      backgroundColorChange: !this.state.backgroundColorChange
    });
  };
  //added title align change function
  titleAlignOnChange = value => {
    this.setState({
      ...this.state,
      titleAlign: value
    });

    this.setConfigToPlugin({ type: "titleAlign", value: value });
  };
 
  render() {
    return (
      <div style={{ color: "#505050" }}>
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.GeneralAppearance")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <ColumnWidthOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.GlassWidth")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"glassWidth"}
              defaultValue={this.state.glassWidth}
              value={this.state.glassWidth}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <ColumnHeightOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.GlassHeight")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"glassHeight"}
              defaultValue={this.state.glassHeight}
              value={this.state.glassHeight}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.BackgroundColor")}
            </Text>
          </Col>
          <Col span={12}>
            <Button
              onClick={this.backgroundColorChangeVisible}
              style={{ width: "100%" }}
            >
              {this.state.backgroundColorChange
                ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
            </Button>
            {this.state.backgroundColorChange ? (
              <ColorPickerPanel
                alpha={100}
                color={this.state.backgroundColor}
                onChange={this.backgroundColorOnChange}
                id={"backgroundColor"}
                style={{ marginTop: "5px", position: "absolute", right: 0 }}
              />
            ) : null}
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FileImageOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.BackgroundImageUrl")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"backgroundImageUrl"}
              defaultValue={this.state.backgroundImageUrl}
              value={this.state.backgroundImageUrl}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <NumberOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.FontStyle")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"fontStyle"}
              defaultValue={this.state.fontStyle}
              value={this.state.fontStyle}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Others")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Title")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"title"}
              defaultValue={this.state.title}
              value={this.state.title}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.TitleAlign")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              showSearch
              style={{ width: "100%" }}
              getPopupContainer={(trigger) => trigger.parentNode}
              placeholder={i18n.t("Dashboard.Configuration.Fields.SelectTitleAlign")}
              optionFilterProp="children"
              onChange={this.titleAlignOnChange}
              defaultValue={this.state.titleAlign}
            >
              <Option value="center">{i18n.t("Dashboard.Configuration.Fields.Center")}</Option>
              <Option value="left">{i18n.t("Dashboard.Configuration.Fields.Left")}</Option>
              <Option value="right">{i18n.t("Dashboard.Configuration.Fields.Right")}</Option>
            </Select>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowLastValue")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"showLastValue"}
              defaultChecked={this.state.showLastValue}
              checked={this.state.showLastValue}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        
      </div>
    );
  }
}
