import React, { Component } from "react";
import "./label.css";
import { Row, Col, Button, Input, Typography, Tag } from "antd";
import { SliderPicker, CirclePicker } from "react-color";
import i18n from "../../../../../Utils/i18next";
import { showNotificationWithIcon } from "../../../../../../src/Utils/Notification";

const { Text } = Typography;

export default class CreateLabel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colors: [
        "#f44336",
        "#e91e63",
        "#9c27b0",
        "#673ab7",
        "#3f51b5",
        "#2196f3",
        "#03a9f4",
        "#00bcd4",
        "#009688",
        "#4caf50",
        "#8bc34a",
        "#cddc39",
        "#ffeb3b",
        "#ffc107",
        "#ff9800",
        "#ff5722",
        "#795548",
        "#607d8b",
      ],
      selectedLabelColor: "#ff0000",
      seletedLabelTitle: "",
    };
  }

  /**
   * Change color of label which will be created
   * @param {object} color 
   */
  onColorChange = (color) => {
    this.setState({ selectedLabelColor: color.hex });
  };

  /**
   * Change title of label which will be created
   * @param {object} event 
   */
  onTitleChange = (event) => {
    this.setState({ seletedLabelTitle: event.target.value });

    if (this.props.isDisplayNameError) {
      this.props.setIsDisplayNameError(false)
    }
  };

  // Reset selected label title and color to the default value
  onCancel = () => {
    this.setState({
      seletedLabelTitle: "",
      selectedLabelColor: "#ff0000", 
    });

    this.props.changeShownComponent(true);
  };

  /**
   * Create new label object with the values which stored before description must be included
   */
  prepareLabelForCreate = () => {
    let isTitleNull = this.state.seletedLabelTitle !== null ? true : false
    let isTitleEmptyString = this.state.seletedLabelTitle !== "" ? true : false
    let isTitleUndefined = this.state.seletedLabelTitle !== undefined ? true : false
    let isTitleAvailable = isTitleNull && isTitleEmptyString && isTitleUndefined ? true : false

    if (isTitleAvailable) {
      let newLabel = {
        displayName: this.state.seletedLabelTitle,
        color: this.state.selectedLabelColor,
        description: "",
        seletedLabelTitle: "",
        selectedLabelColor: "#ff0000",
      };
      
      this.props.createLabel(newLabel);
  
      this.setState({
        ...this.state,
        seletedLabelTitle: "",
        selectedLabelColor: "#ff0000",
      })
    } else {
      showNotificationWithIcon(i18n.t("Dashboard.Label.Create.LabelCantBeEmpty"), null, "error");
    }
  };

  render() {
    return (
      <div
        style={{
          display: this.props.visibleCreateLabel ? "block" : "none",
        }}
      >
        <Row className={"ant-row-padding-full"}>
          <Text>{i18n.t("Dashboard.Label.Create.Title")}</Text>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Text
            type="span"
            style={{
              fontSize: "13px",
              color: "#afafaf",
              textAlign: "center",
              whiteSpace: "normal",
            }}
          >
            {i18n.t("Dashboard.Label.Create.Description")}
          </Text>
        </Row>
        <Row style={{}}>
          <Input 
            name="labelTitleInput" 
            placeholder={i18n.t("Dashboard.Label.Create.SelectedTitle")} 
            onChange={this.onTitleChange}
            value={this.state.seletedLabelTitle}
            style={this.props.isDisplayNameError ? {border: "1px solid red"} : {}}>
          </Input>
        </Row>
        <Row style={{ marginTop: "16px", marginBottom: "16px" }}>
          <CirclePicker
            id={this.state.id}
            circleSize={22}
            circleSpacing={13}
            colors={this.state.colors}
            onChange={this.onColorChange}
            className="circle-picker-custom"
          ></CirclePicker>
        </Row>
        <Row style={{ marginTop: "16px", marginBottom: "16px" }}>
          <SliderPicker
            style={{ marginTop: "15px" }}
            color={this.state.selectedLabelColor}
            onChange={this.onColorChange}
          ></SliderPicker>
        </Row>
        <Row style={{ marginTop: "25px", marginBottom: "25px" }}>
          <Col span={24}>
            <Tag
              className="tag-base-preview"
              color={this.state.selectedLabelColor}
            >
              <span>
                {this.state.seletedLabelTitle == ""
                  ? i18n.t("Dashboard.Label.Create.SelectedTitle")
                  : this.state.seletedLabelTitle}
              </span>
            </Tag>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Button
              id="labelCancelledButton"
              shape="round"
              size={"default"}
              onClick={() => {
                this.onCancel()
              }}
            >
              {i18n.t("Dashboard.Label.Create.CancelButton")}
            </Button>
          </Col>
          <Col span={8} offset={8}>
            <Button
              id="labelCreatedButton"
              type="primary"
              shape="round"
              size={"default"}
              onClick={this.prepareLabelForCreate}
            >
              {i18n.t("Dashboard.Label.Create.CreateButton")}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
