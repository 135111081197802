import { get } from "../../../../../Utils/WebService";

const ext = "data:image/jpeg;base64,";
const factor = 50;
const constImageSize = 150;
const canvasMaxWidth = 1200;
const canvasMaxHeight = 750;
const margin = 8;
const padding = 16;

/**
 * Creates canvas for thumbnail with plugin types
 * @param {*} plugins
 * @param {*} pluginImages
 * @param {*} callback
 */
export const createImage = (settings, plugins, pluginImages, callback) => {
  let canvas = document.getElementById("dashboardThumbnail");
  let context = canvas.getContext("2d");
  let sources = Object.fromEntries(pluginImages);
  let canvasW = canvasMaxWidth;
  let canvasH = canvasMaxHeight;
  let pluginKeySet = new Set();

  plugins.map((plugin) => {
    let x = plugin.x * factor;
    let y = plugin.y * factor;

    let w = plugin.w * factor;
    let h = plugin.h * factor;

    if (canvasW < x + w) {
      canvasW = x + w;
    }

    if (canvasH < y + h) {
      canvasH = y + h;
    }

    let controlledPluginKey = plugin.key
    
    controlledPluginKey = plugin.key === "radio-button-filter" || plugin.key === "selection-box" 
      ? "button-filter" 
      : plugin.key === "pie-chart-enhanced" 
        ? "pie-chart"
        : plugin.key

    pluginKeySet.add(controlledPluginKey);
  });

  canvas.setAttribute("width", canvasW);
  canvas.setAttribute(
    "height",
    canvasH > canvasMaxHeight ? canvasMaxHeight : canvasH
  );
  canvas.setAttribute("style", "border: 1px solid #dedede; display: none");

  if (settings && settings["backgroundColor"]) {
    context.fillStyle = settings["backgroundColor"];
  } else {
    context.fillStyle = "#fcfcfc";
  }

  context.fillRect(0, 0, canvasW, canvasH);

  context.shadowBlur = 1;
  context.shadowColor = "#dedede";

  loadImages(sources, pluginKeySet, function (images) {
    plugins.map((plugin) => {
      let x = plugin.x * factor;
      let y = plugin.y * factor;

      let w = plugin.w * factor;
      let h = plugin.h * factor;

      let imageSize = constImageSize;

      if (w - padding < constImageSize) {
        imageSize = w - padding - 8;
      }

      if (h - padding < constImageSize) {
        imageSize = h - padding - 8;
      }

      let posX = x + (w - imageSize) / 2;
      let posY = y + (h - imageSize) / 2;

      context.fillStyle = "#FFF";
      context.strokeStyle = "#dedede";
      context.lineJoin = "bevel";
      context.lineWidth = 3;
      context.strokeRect(x + margin, y + margin, w - padding, h - padding);
      context.fillRect(x + margin, y + margin, w - padding, h - padding);

      let pluginImage = images[plugin.key];

      if (!pluginImage) {
        pluginImage = new Image();
      }

      context.drawImage(pluginImage, posX, posY, imageSize, imageSize);
    });

    callback();
  });
};

/**
 * Load images from base 64
 * @param {*} sources
 * @param {*} pluginKeySet
 * @param {*} callback
 */
const loadImages = (sources, pluginKeySet, callback) => {
  let images = {};
  let loadedImages = 0;
  let numImages = 0;

  // get num of sources
  for (let src in sources) {
    if (pluginKeySet.has(src)) {
      numImages++;
    }
  }

  for (let src in sources) {
    let imageSrc = "/api/plugin/base64/" + src;

    if (pluginKeySet.has(src)) {
      const succesFunc = (response) => {
        images[src] = new Image();
        images[src].onload = function () {
          if (++loadedImages >= numImages) {
            callback(images);
          }
        };
        images[src].src = ext + response.data.image;
      };

      get(imageSrc, succesFunc);
    }
  }
};
