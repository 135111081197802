import { Checkbox, Input, message } from "antd";
import React, { Component } from "react";
import { FiCheck, FiEdit2, FiX } from "react-icons/fi";
import i18n from "../../Utils/i18next";
import { showNotificationWithIcon } from "../../Utils/Notification";
import { AiOutlineTool } from "react-icons/ai";
import Tooltip from "../GeneralComponents/Tooltip/Tooltip";

/**
 * Items in sheet list
 */
export default class SheetItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      newDisplayName: "",
    };
  }

  sheetNameClass = (sheet, selectedSheet) => {
    let className = "sheet-item";

    if (sheet.name === selectedSheet) {
      className += " selected";
    }

    if (!sheet.status) {
      className += " disabled";
    }

    return className;
  };

  nameValidation = () => {
    let name = this.state.newDisplayName;

    if (name === "") {
      return { status: false, message: i18n.t("FileUpload.TableNameCouldNotBeEmpty") };
    }

    if (
      name.includes('"') ||
      name.includes(".") ||
      name.includes("”") ||
      name.includes("'") ||
      name.includes("%") ||
      name.includes("&") ||
      name.includes("[") ||
      name.includes("]") ||
      name.includes("|") ||
      name.includes("?") ||
      name.includes("=") ||
      name.includes("/") ||
      name.includes("+") ||
      name.includes("^")
    ) {
      return {
        status: false,
        message: i18n.t("FileUpload.TableNameCouldNotIncludesSpecialChars"),
      };
    }

    return { status: true };
  };

  applyNewNameToSheet = (sheet) => {
    let newNameIsValid = this.nameValidation();

    if (newNameIsValid.status) {
      this.props.applyChangesToSheet({
        sheetName: sheet.name,
        changes: { displayName: this.state.newDisplayName, validation: true },
      });

      this.setState({ editMode: false, newDisplayName: "" });
    } else {
      showNotificationWithIcon(
        i18n.t("Error"),
        newNameIsValid.message,
        "error"
      );
    }
  };

  applyStatusToSheet = (sheet, status) => {
    this.props.applyChangesToSheet({
      sheetName: sheet.name,
      changes: { status: status },
    });
  };

  /**
   * Replace special chars in sheet name to _
   * @param {*} sheet 
   */
  fixSheetName = (sheet) => {
    let newDisplayName = sheet.displayName;

    newDisplayName = newDisplayName
      .replaceAll('"', "_")
      .replaceAll(".", "_")
      .replaceAll("”", "_")
      .replaceAll("'", "_")
      .replaceAll("%", "_")
      .replaceAll("&", "_")
      .replaceAll("[", "_")
      .replaceAll("]", "_")
      .replaceAll("|", "_")
      .replaceAll("?", "_")
      .replaceAll("=", "_")
      .replaceAll("/", "_")
      .replaceAll("+", "_")
      .replaceAll("^", "_");

    this.setState(
      {
        ...this.state,
        newDisplayName: newDisplayName,
      },
      () => this.applyNewNameToSheet(sheet)
    );
  };

  render() {
    const { sheet, selectedSheet } = this.props;
    const { editMode, newDisplayName } = this.state;

    let isValidSheetName = sheet.validation;

    return (
      <div>
        <p
          className={this.sheetNameClass(sheet, selectedSheet)}
          onClick={() => this.props.selectSheet(sheet.name)}
        >
          <Checkbox
            style={{ marginRight: "6px" }}
            checked={sheet.status}
            onChange={
              (e) => this.applyStatusToSheet(sheet, e.target.checked)
              //   this.props.onChangeSheetStatus(sheet.name, e.target.checked)
            }
          />
          {editMode ? (
            <>
              <Input
                className="sheet-name-input"
                value={newDisplayName}
                onPressEnter={() => this.applyNewNameToSheet(sheet)}
                onChange={(e) => {
                  this.setState({ newDisplayName: e.target.value });
                }}
              />

              <FiX
                className="sheet-item-edit"
                style={{ color: "#c86666" }}
                onClick={() => {
                  this.setState({ editMode: false, newDisplayName: "" });
                }}
              />

              <FiCheck
                className="sheet-item-edit"
                onClick={() => this.applyNewNameToSheet(sheet)}
              />
            </>
          ) : (
            <>
              <span className="sheet-item-text">{sheet.displayName}</span>

              {!isValidSheetName && (
                <Tooltip tooltip={i18n.t("FileUpload.FixSheetName")}>
                  <AiOutlineTool
                    style={{color: "red"}}
                    className="sheet-item-edit"
                    onClick={() =>
                      this.fixSheetName(sheet)
                    }
                  />
                </Tooltip>
              )}
              
              <FiEdit2
                className="sheet-item-edit"
                onClick={() => {
                  this.setState({
                    editMode: true,
                    newDisplayName: sheet.displayName,
                  });
                }}
              />
            </>
          )}
        </p>
      </div>
    );
  }
}
