import React, { Component } from "react";
import { Button } from "antd";
import Tooltip from '../Tooltip/Tooltip'
import "./button.css";

export default class ButtonVispeahen extends Component {
  render() {
    let overlayClassName = this.props.overlayClassName ? this.props.overlayClassName : "";
    let tooltipPlacement = this.props.tooltipPlacement ? this.props.tooltipPlacement : "top";
    let tooltip = this.props.tooltip ? this.props.tooltip : "";
    
    return (
      <Tooltip tooltipPlacement={tooltipPlacement} tooltip={tooltip} overlayClassName={overlayClassName}>
        <Button
          id={this.props.id}
          style={this.props.style}
          className={
            this.props.className
              ? this.props.className
              : "" + " " + this.props.active
              ? this.props.active
              : ""
          }
          disabled={this.props.disabled ? this.props.disabled : null}
          shape={this.props.shape}
          onClick={this.props.onClick}
          icon={this.props.icon}
          loading={this.props.loading}
          type={this.props.type}
          ghost={this.props.ghost}
        >
          {this.props.children}
        </Button>
      </Tooltip>
    );
  }
}
