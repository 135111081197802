import { Col, Row, Tooltip } from "antd";
import React, { Component } from "react";
import Button from "../GeneralComponents/Button/Button";
import Text from "../GeneralComponents/Text/Text";
import { RESULT_STATUS } from "./UploadOperation";
import i18n from "../../Utils/i18next";

export default class UploadResultByTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorShownStatus: false,
    };
  }

  generateDetail = (status, detail) => {
    if (status.key === RESULT_STATUS.ERROR.key) {
      return (
        <Button
          style={{
            background: "rgb(255, 152, 1)",
            color: "rgb(255, 255, 255)",
            height: "22px",
            fontSize: "13px",
          }}
          onClick={() => {
            this.setState({ errorShownStatus: !this.state.errorShownStatus });
          }}
        >
          Show Error Message
        </Button>
      );
    } else if (status.key === RESULT_STATUS.WARNING.key && detail?.count) { 
      return (
        <Text>
          <span style={{ fontWeight: "bold" }}>{detail.count}</span> 
          {" "}
          <span>{i18n.t("FileUpload." + detail.type)}</span>
            
        </Text>
      );
    } else {
      return null;
    }
  };

  render() {
    const { tableName, status, detail } = this.props;
    const { errorShownStatus } = this.state;

    return (
      <div className="file-validation-item">
        <Row gutter={6}>
          <Col span={8}>
            <Text type={"span"} style={{ fontWeight: "bold" }}>
              {tableName}
            </Text>
          </Col>
          <Col span={8}>
            <Text
              className={"sheet-status-label"}
              style={{ background: status.color }}
            >
              {status.text}
            </Text>
          </Col>
          <Col span={8}>{this.generateDetail(status, detail)}</Col>
        </Row>
        {errorShownStatus && detail && (
          <Row gutter={6}>
            <Col span={24}>
              <Text
                className={"upload-result-error"}
                style={{ color: RESULT_STATUS.ERROR.color }}
              >
                {detail["message"]}
              </Text>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}
