import React, { Component } from "react";
import Text from "../Text/Text";
import i18n from "../../../Utils/i18next";
import Tooltip from "../Tooltip/Tooltip";
import PropTypes from "prop-types";
import { deepCopy } from "../../../Utils/Global";

/**
 * Search component.
 * Includes input area and search category.
 * Search functions.
 */
export default class Search extends Component {
  constructor(props) {
    super(props);

    this.prototype = {
      paddingNone: PropTypes.string, //to reset spaces.
      marginBottom: PropTypes.string,
      inputStyle: PropTypes.object,
      id: PropTypes.string,
      width: PropTypes.string,
      searchBarVisible: PropTypes.bool,
      onChange: PropTypes.func,
      searchItemList: PropTypes.object, //object array object
      searchCategory: PropTypes.func,
      selectedSearchCategory: PropTypes.string
    };

    this.state = {
      placeholder: this.props.placeholder
        ? this.props.placeholder
        : i18n.t("Search"),
      width: this.props.width, // search input width = default value %70
      searchData: this.props.value !== undefined ? this.props.value : "",
      searched: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.clearCondition !== this.props.clearCondition) {
      this.onChange({ target: { value: "" } })
    }

    if (nextProps.value !== undefined && nextProps.value !== this.state.value) {
      this.setState({ searchData: nextProps.value });
    }
  }

  onChange = async (e) => {
    let event = { ...e };

    if (this.inputTimer) {
      clearTimeout(this.inputTimer);
      this.inputTimer = null;
    }

    let searched = false;
    if (e.target.value !== "") {
      searched = true;
    }

    this.setState({
      ...this.state,
      searched: searched,
      searchData: e.target.value
    });

    this.inputTimer = setTimeout(() => {
      this.props.onChange(event);
    }, this.props.inputTimeout);
  };

  onKeyDown = e => {
    //keyCode == 8 is backspace
    if (
      this.state.searchData === "" &&
      this.props.searchCategory &&
      e.keyCode === 8
    ) {
      this.props.searchCategory({});
    }
  };

  onFocus = (e) => {
    if (this.props.onFocus instanceof Function) {
      this.props.onFocus(e);
    }
  };

  onBlur = (e) => {
    if (this.props.onBlur instanceof Function) {
      this.props.onBlur(e);
    }
  };

  render() {
    return this.props.searchBarVisible === true ? (
      <div
        class="search-area"
        style={{
          padding: this.props.paddingNone ? 0 : "0 25px",
          marginBottom: this.props.marginBottom ? this.props.marginBottom : 0
        }}
      >
        <div className={"search-input-area"} style={{ width: this.state.width, position: "relative" }}>
          <input
            onDrop={this.props.preventDrop ? ((event) => { event.preventDefault() }) : null}
            name={this.props.name}
            style={this.props.inputStyle ? this.props.inputStyle : null}
            autocomplete="off"
            className={
              this.props.selectedSearchCategory &&
                this.props.selectedSearchCategory.name !== undefined
                ? "form-control selected-category"
                : "form-control"
            }
            type="search"
            placeholder={this.props.disablePlaceHolder ? "" : this.state.placeholder}
            aria-label={this.state.placeholder}
            value={this.state.searchData}
            id={this.props.id}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            autoFocus={this.props.autoFocus}
          ></input>
          {this.props.searchItemList !== undefined && this.state.searched === false ? (
            <div
              className={
                this.props.selectedSearchCategory !== undefined &&
                  this.props.selectedSearchCategory.name == undefined
                  ? this.state.searched === false
                    ? "search-item-list"
                    : "search-item-list selected"
                  : "search-item-list selected"
              }
            >
              {this.props.searchItemList.map(item => (
                <p onClick={() => this.props.searchCategory(item)}>
                  {item.icon ? <i className={item.icon}></i> : null}{" "}
                  {i18n.t(item.translate)}
                </p>
              ))}
            </div>
          ) : this.props.children}
        </div>
        <Text>{this.props.disablePlaceHolder ? "" : this.state.placeholder}</Text>
        <a
          href="javascript:;"
          class="btn btn-rounded btn-rounded-sm btn-search"
          style={{
            left: this.props.paddingNone ? "5px" : "30px",
            ...this.props.iconStyle
          }}
        >
          <i class="fa fa-search"></i>
        </a>

        {this.props.selectedSearchCategory &&
          this.props.selectedSearchCategory.name !== undefined ? (
          <div className={"selected-category-on-input"}>
            <Tooltip tooltip={this.props.selectedSearchCategory.translate}>
              <Text type="p" style={{ marginBottom: 0 }}>
                {i18n.t(this.props.selectedSearchCategory.translate)}
              </Text>
            </Tooltip>
          </div>
        ) : null}
      </div>
    ) : (
      <div class="search-area"></div>
    );
  }
}
