import React, { Component } from "react";
import FilterSelection from "./FilterSelection";
import $ from "jquery";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import { renderContent } from "../renderContent";
import { renderConfig, renderData } from "../PluginsCommonComponents";
import { createTrigger } from "../../../Interaction/CreateTrigger";
import i18n from "../../../../Utils/i18next";
import {
  onComponentWillMount,
  onComponentWillReceiveProps,
  getColumnMapping,
  calculatePluginHeight
} from "../common";
import DateFilterData from "./DateFilterData";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join";
import { Row } from "antd";
import FilterConfiguration from "../Filter/FilterConfiguration/FilterConfiguration";
import { calculateTitleSize, calculatePluginInlineHeight } from "../../../DrillDown/PluginHeightWithDrilldown"
import moment from "moment";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";
import { deepCopy } from "../../../../Utils/Global";
import _ from "lodash";

const data = JSON.parse(
  `[{"filter":"Adana"},{"filter":"Adıyaman"},{"filter":"Amasya Merzifon"},{"filter":"Ankara Esenboğa"},{"filter":"Antalya"},{"filter":"Aydın Çıldır"},{"filter":"Ağrı Ahmed-i Hani"},{"filter":"Balıkesir Koca Seyit"},{"filter":"Balıkesir Merkez"},{"filter":"Batman"},{"filter":"Bingöl"},{"filter":"Bursa Yenişehir"},{"filter":"Denizli Çardak"},{"filter":"Diyarbakır"},{"filter":"Elazığ"},{"filter":"Erzincan"},{"filter":"Erzurum"},{"filter":"Eskişehir Hasan Polatkan"},{"filter":"Gaziantep"},{"filter":"Gazipaşa Alanya"},{"filter":"Hakkari Yüksekova Selahaddin Eyyubi"},{"filter":"Hatay"},{"filter":"Isparta Süleyman Demirel"},{"filter":"Iğdır Şehit Bülent Aydın"},{"filter":"Kahramanmaraş"},{"filter":"Kapadokya"},{"filter":"Kars Harakani"},{"filter":"Kastamonu"},{"filter":"Kayseri"},{"filter":"Kocaeli Cengiz Topel"},{"filter":"Konya"},{"filter":"Malatya"},{"filter":"Mardin"},{"filter":"Muğla Dalaman"},{"filter":"Muğla Milas-Bodrum"},{"filter":"Muş"},{"filter":"Ordu-Giresun"},{"filter":"Samsun Çarşamba"},{"filter":"Siirt"},{"filter":"Sinop"},{"filter":"Sivas Nuri Demirağ"},{"filter":"Tekirdağ Çorlu"},{"filter":"Tokat"},{"filter":"Trabzon"},{"filter":"Uşak"},{"filter":"Van Ferit Melen"},{"filter":"Zafer"},{"filter":"Zonguldak Çaycuma"},{"filter":"Çanakkale"},{"filter":"Çanakkale Gökçeada"},{"filter":"İstanbul Atatürk"},{"filter":"İstanbul Sabiha Gökçen"},{"filter":"İzmir Adnan Menderes"},{"filter":"Şanlıurfa GAP"},{"filter":"Şırnak Şerafettin Elçi"}]`
);
const columnMap = JSON.parse(
  `{"filter":{"Code":"\'ucusAlias\'.\'meydan_adiAlias\'","Name":"meydan_adi","DataType":"varchar","Table":"ucusAlias","Measure":"none","ID":"ucusAlias.meydan_adi","SubjectArea":"BIGDATA","SortKey":false,"Sorting":false,"SortDirection":"","SortOrder":0,"Locale":"TR","DataFormat":"%s","Config":{},"Verified":false,"Type":"Column","Description":""}}`
);
const condFormats = [];

const columnMappingParameters = [
  {
    targetProperty: "filter",
    formLabel: "FilterArea",
    type: "dim",
    required: true,
    desc: "FilterArea"
  }
];

const configurationParameters = [
  {
    targetProperty: "width",
    label: "Width",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 400
    },
    desc: "desc89"
  },
  {
    targetProperty: "title",
    label: "Title",
    inputType: "textbox",
    inputOptions: {
      defaultValue: ""
    },
    desc: "desc94"
  },
  {
    targetProperty: "filterDefaultOperator",
    label: "filterDefaultOperator",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "between"
    },
    desc: "filterDefaultOperator"
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "rgb(255,255,255)"
    },
    desc: "desc62"
  },
  {
    targetProperty: "arrowColor",
    label: "ArrowColor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "rgb(255, 255, 255)"
    },
    desc: "ArrowColor"
  },
  {
    targetProperty: "isMulti",
    label: "IsMulti",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "IsMulti"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  },
  {
    targetProperty: "dropdownAppearance",
    label: "DropdownAppearance",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "DropdownAppearance"
  },
  {
    targetProperty: "showOperatorOptions",
    label: "showOperatorOptions",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "showOperatorOptions"
  },
  {
    targetProperty: "showDefaultFilterIcon",
    label: "showDefaultFilterIcon",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "showDefaultFilterIcon"
  }
];

const actions = [
  {
    trigger: "select",
    type: "click",
    name: "select",
    output: ["filter"],
    description: ""
  }
];

const reactions = [
  {
    id: "filter",
    name: "Filtre",
    description: "desc78",
    type: "general"
  }
];

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

/**
 * Creates and return map for configuration
 */
const createConfiguraitonMap = () => {
  let configurationMap = new Map();

  configurationMap.set("backgroundColor", {});
  configurationMap.set("title", {});
  configurationMap.set("titleAlign", {});
  configurationMap.set("filterDefaultOperator", { defaultValue: "<=>" });
  configurationMap.set("titleFontWeight", {});
  configurationMap.set("titleFontStyle", {});
  configurationMap.set("dropdownAppearance", {});
  configurationMap.set("showOperatorOptions", { defaultValue: true });
  configurationMap.set("showDefaultFilterIcon", { defaultValue: true });

  return configurationMap;
};

/**
 * Creates and return map for configuration categories
 */
const createConfigurationCategoryMap = () => {
  let categoryMap = new Map();

  categoryMap.set("generalAppearance", {});
  categoryMap.set("others", {});

  return categoryMap;
};

const configurationMap = createConfiguraitonMap();
const configurationCategoryMap = createConfigurationCategoryMap();

export default class DateFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      titleChangeStatus: false,
    };
    this.rerenderProcessStarted = false;
  }

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  /**
   * Change status for rerender process started
   * 
   * @param {*} status 
   */
  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  /**
   * Set call back object
   * 
   * @param {*} callBackObject 
   */
  setCallBackObject = callBackObject => {
    this.callBackObject = callBackObject;
  };

  /**
   * Get call back object
   * 
   * @returns 
   */
  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({});

    return tmpCallBackObject;
  };

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );
  }

  /**
   * Update configuration
   * 
   * @param {*} config 
   * @returns 
   */
  updateConfig = (config) => {
    if (!config) {
      return;
    }

    let plugin = deepCopy(this.props.plugin);
    let column = plugin?.columnMap?.filter?.data[0];

    if (column && config?.dropdownAppearance !== this.props.plugin.config?.dropdownAppearance) {
      let dataType = column.dataType;
      let dataFormatType = column.DataFormatType;
      let dataFormatTypeSubTitle = column.DataFormatTypeSubTitle;

      let timestampType = dataType === "timestamp";
      let shouldFormatContainTime = dataType === "timestamp";

      if (dataFormatType !== "custom") {
        if (dataFormatTypeSubTitle) {
          timestampType = timestampType && dataFormatTypeSubTitle === "timestamp";
        }

        if (column.DataFormat) {
          let hasFormatGotAnyTimePart = /%[HMSs]/.test(column.DataFormat?.trim());

          shouldFormatContainTime = shouldFormatContainTime && hasFormatGotAnyTimePart;
        }
      }

      column.DataFormatType = "time";
      column.DataFormatTypeSubTitle = timestampType ? "timestamp" : "date";
      column.DataFormat = shouldFormatContainTime ? "%d/%m/%Y %H:%M:%S" : "%d/%m/%Y";

      delete column.SelectedDateFormat;
      delete column.SelectedTimestampFormat;

      plugin.columnMap.filter.data[0] = column;
      plugin.columnMapForPlugin.filter = column;

      sessionStorage.removeItem(this.props.plugin.id + "-preData");
    }

    for (let field of Object.keys(config)) {
      if (!_.isEqual(plugin.config[field], config[field])) {
        plugin.preservedConfigFields.add(field);
        plugin.originalConfig[field] = config[field];
      }
    }

    plugin.config = config;
    plugin.rerender = true;

    this.props.updatePlugin(plugin);
  }

  /**
   * Get configuration component
   * 
   * @param {*} props 
   * @returns 
   */
  getConfigComponent = props => {
    if (props.config) {
      return (
        <FilterConfiguration
          config={{ ...props.config }}
          pluginId={props.plugin.id}
          updateConfig={this.updateConfig}
          configurationTemplate={configurationMap}
          configurationCategoryTemplate={configurationCategoryMap}
          updateCommonTitleConfig={this.props.updateCommonTitleConfig}
          plugin={this.props.plugin}
          commonTitleConfig={this.props.commonTitleConfig}
          setDefaultForPluginTitle={this.props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={this.props.isReturnToDefaultforTitleVisible}
          setPluginRerender={this.props.setPluginRerender}
          setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
          currentAppliedConfig={this.props.currentAppliedConfig}
          setConfigToPlugin={this.setConfigToPlugin}
          setConfigsToPlugin={this.setConfigsToPlugin}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  /**
   * Get data component
   * 
   * @param {*} props 
   * @returns 
   */
  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }

    return (
      <DateFilterData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        didNotJoinedTables={checkTableJoins(
          this.props.join,
          this.props.plugin.columnMap,
          this.props.refreshedPluginId,
          this.props.plugin.id,
          true
        )}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={
          props.changeDoesPluginHasNotJoinedTable
        }
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        plugin={props.plugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  /**
   * To set column map this plugin
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      filter: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Filter.Name"),
        type: "dim",
        minimumColumnSize: 1,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Filter.Desc"),
        required: true,
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  /**
   * Get title align
   * 
   * @param {*} props 
   * @returns 
   */
  getTitleAlign = props => {
    return props.plugin.config && props.plugin.config.titleAlign
      ? props.plugin.config.titleAlign
      : "center";
  };

  /**
   * Returns last day of months.
   */
  lastDayofMonth = (dateString) => {
    let month = parseInt(dateString.slice(5, 7))
    let year = parseInt(dateString.slice(0, 4))
    let monthsWithThirtyDays = [4, 6, 9, 11];

    if (month === 2) {
      if (year % 4 === 0) {
        return "-29";
      } else {
        return "-28";
      }
    }

    return monthsWithThirtyDays.includes(month) ? "-30" : "-31";
  }

  /**
   * Sets current operator to the local storage, prepares filter and applies it
   * 
   * @param {*} dates 
   * @param {*} dateStrings 
   * @param {*} filterOperator 
   * @param {*} format 
   */
  selectedDate = (dates, dateStrings, filterOperator, format = undefined, isSelectedDateEmpty = false) => {
    if (isSelectedDateEmpty) {
      createTrigger(
        actions,
        this.props.plugin.columnMapForPlugin,
        $("#pluginDateFilter-" + this.props.plugin.id)[0],
        "select",
        [],
        this.props.plugin.id,
        this.props.interactions,
        this.props.navigations,
        undefined,
        filterOperator
      );

      return
    }

    if (!this.props.plugin?.columnMapForPlugin?.filter) return;

    let isFilterOpetorNullOrNotNull = filterOperator === "is null" || filterOperator === "is not null";
    let areDateStringsValid = (
      Array.isArray(dateStrings) &&
      dateStrings.length === 2 &&
      dateStrings.every(d => d && d !== "Invalid date")
    ) || (
        typeof dateStrings === "string" &&
        dateStrings !== "" &&
        dateStrings !== "Invalid date"
      );

    if (!isFilterOpetorNullOrNotNull && !areDateStringsValid) return;

    let filter;
    let previousData = [filterOperator, dateStrings];

    sessionStorage.setItem(this.props.plugin.id + "-preData", JSON.stringify(previousData));

    if (Array.isArray(dateStrings)) {
      filter = [];

      for (let date of dateStrings) {
        filter.push({ filter: date });
      }
    } else {
      if (filterOperator === "is null") {
        filter = { filter: "is null" };
      } else if (filterOperator === "is not null") {
        filter = { filter: "is not null" };
      } else if (typeof dateStrings !== "string" && format !== undefined) {
        let stringDate = moment(dateStrings).format(format)

        filter = { filter: stringDate };
      } else {
        filter = { filter: dateStrings };
      }
    }

    let isCustom = this.props.plugin.columnMapForPlugin.filter.DataFormatType === "custom" && this.props.plugin.config.dropdownAppearance;

    if (isCustom) {
      let format = this.props.plugin.columnMapForPlugin.filter.DataFormat.trim().toLowerCase()
      let spaceIndex = format.indexOf(" ")
      let dateFormat = spaceIndex !== -1 ? format.slice(0, spaceIndex) : format
      let timestampFormat = spaceIndex !== -1 ? format.slice(spaceIndex + 1) : ""
      let isOnlyYear = dateFormat.includes("%y") && !(dateFormat.includes("%m") || dateFormat.includes("%d"))
      let isOnlyYearandMonth = dateFormat.includes("%m") && !dateFormat.includes("%d")
      let isYear_Month_Day = dateFormat.includes("%d")
      let date = [];
      let timestamp = [];

      if (dateStrings.length === 2) {
        date[0] = dateStrings[0].slice(0, dateStrings[0].indexOf(" "));
        date[1] = dateStrings[1].slice(0, dateStrings[1].indexOf(" "));
        timestamp[0] = dateStrings[0].slice(dateStrings[0].indexOf(" ") + 1);
        timestamp[1] = dateStrings[1].slice(dateStrings[1].indexOf(" ") + 1)
      } else {
        date = dateStrings.slice(0, dateStrings.indexOf(" "));
        timestamp = dateStrings.slice(dateStrings.indexOf(" ") + 1);
      }

      format = format.slice(format.indexOf(" "))

      let isOnlyHour = timestampFormat.includes("%h") && !(timestampFormat.includes("%m") || timestampFormat.includes("%s"))
      let isOnlyHourandMinute = timestampFormat.includes("%m") && !timestampFormat.includes("%s")
      let isHour_Minute_Second = timestampFormat.includes("%s")
      let isTimestamp = timestampFormat.includes("%h")
      let start = date;
      let end = date;
      let startTimestamp = timestamp;
      let endTimestamp = timestamp;

      if (filterOperator === "=") {
        if (isOnlyYear) {
          start = date + "-01-01";
          end = date + "-12-31";
        } else if (isOnlyYearandMonth) {
          start = date + "-01";
          end = date + this.lastDayofMonth(date);
        }

        if (isOnlyHour) {
          startTimestamp = timestamp + ":00:00";
          endTimestamp = timestamp + ":59:59"
        } else if (isOnlyHourandMinute) {
          startTimestamp = timestamp + ":00";
          endTimestamp = timestamp + ":59"
        } else {
          endTimestamp = "23:59:59";
        }

        if (isTimestamp) {
          if (isHour_Minute_Second && isYear_Month_Day) {
            filterOperator = "="
            filter = { filter: date + " " + timestamp }
          } else {
            filterOperator = "between"
            filter = [];

            let time = [start + " " + startTimestamp, end + " " + endTimestamp];

            time.map(date => {
              filter.push({ filter: date });
            });
          }
        } else {
          if (isYear_Month_Day) {
            filterOperator = "="
            filter = { filter: date }
          } else {
            filterOperator = "between"
            filter = [];

            let time = [start, end];

            time.map(date => {
              filter.push({ filter: date });
            });
          }
        }
      } else if (filterOperator === ">" || filterOperator === "<=") {
        if (isOnlyYear) {
          date += "-12-31"
        } else if (isOnlyYearandMonth) {
          date += this.lastDayofMonth(date)
        }

        if (isOnlyHour) {
          timestamp += ":59:59"
        } else if (isOnlyHourandMinute) {
          timestamp += ":59"
        }

        filter = { filter: date + " " + timestamp }
      } else if (filterOperator === "<" || filterOperator === ">=") {
        if (isOnlyYear) {
          date += "-01-01"
        } else if (isOnlyYearandMonth) {
          date += "-01"
        }
        if (isOnlyHour) {
          timestamp += ":00:00"
        } else if (isOnlyHourandMinute) {
          timestamp += ":00"
        }

        filter = { filter: date + " " + timestamp }
      } else if (filterOperator === "between") {
        date = date.sort((t1, t2) => moment(t1) - moment(t2));

        if (isOnlyYear) {
          date = [date[0] + "-01-01", date[1] + "-12-31"]
        } else if (isOnlyYearandMonth) {
          date = [date[0] + "-01", date[1] + this.lastDayofMonth(date[1])]
        } else {
          date = [date[0], date[1]]
        }

        if (isOnlyHour) {
          timestamp = [timestamp[0] + ":00:00", timestamp[1] + ":59:59"]
        } else if (isOnlyHourandMinute) {
          timestamp = [timestamp[0] + ":00", timestamp[1] + ":59"]
        } else {
          timestamp = [timestamp[0], timestamp[1]]
        }

        let time = [date[0] + " " + timestamp[0], date[1] + " " + timestamp[1]]

        filter = [];
        filterOperator = "between"

        time.map(date => {
          filter.push({ filter: date });
        });
      }
    }

    createTrigger(
      actions,
      this.props.plugin.columnMapForPlugin,
      $("#pluginDateFilter-" + this.props.plugin.id)[0],
      "select",
      filter,
      this.props.plugin.id,
      this.props.interactions,
      this.props.navigations,
      undefined,
      filterOperator
    );
  };

  /**
   * Returns data type of the filter column
   * 
   * @returns 
   */
  getDataType = () => {
    let pluginData = this.props.plugin.columnMap.filter.data;
    let dataType = undefined;

    if (pluginData.length > 0) {
      dataType = pluginData[0].dataType;
    }

    return dataType;
  };

  /*
  * Changes plugin's refresh status state
  */
  changeRefreshStatus = status => {
    this.rerenderProcessStarted = status
  }

  /**
   * Renders plugin
   * 
   * @param {*} divId 
   * @param {*} data 
   * @param {*} columnMap 
   * @param {*} config 
   * @param {*} condFormats 
   * @param {*} filters 
   */
  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {
    calculateTitleSize(this.props.plugin)

    let pluginInlineHeight = calculatePluginInlineHeight(divId)
    let container = $("#pluginDateFilter-" + divId)[0];
    let plugin = $("#plugin-" + divId);

    plugin
      .parent()
      .parent()
      .css("min-height", "50px");
    $("#pluginDateFilter-" + divId)
      .css("top", "47%")
      .css("position", "absolute")
      .css("width", "100%");
    plugin.css("height", pluginInlineHeight);

    this.props.setPluginRerender(
      false,
      this.props.plugin.id,
      false,
      this.props.plugin.isInteraction
    );
  };

  currentHeight;
  lastContent = undefined;
  updateLastContent = status => {
    this.lastContent = status;
  };

  render() {
    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    let isRerender = this.props.plugin.rerender;

    let pluginConfig = { ...this.props.plugin.config };

    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + this.props.plugin.id).css("padding")
      );

      pluginConfig.height =
        calculatePluginHeight(this.props.plugin, this.props.settings) -
        pluginContainerPadding * 2;

      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      );
    }

    return (
      <>
        <div
          id={"pluginDateFilter-" + this.props.plugin.id}
          className="filterClass"
          onMouseOver={(e) => this.props.onHoverInputClass(e, this.props.plugin)}
          onMouseLeave={(e) => this.props.offHoverInputClass(e, this.props.plugin)}>
          <div>
            <Row>
              <FilterSelection
                dataType={this.getDataType()}
                selectedDate={this.selectedDate}
                columnMap={columnMap}
                filterDefaultOperator={this.props.plugin.config.filterDefaultOperator ? this.props.plugin.config.filterDefaultOperator : "between"}
                plugin={this.props.plugin}
                refreshPluginStatus={this.props.rerender}
                dropdownAppearance={pluginConfig.dropdownAppearance}
                clickedRefresh={this.props.clickedRefresh}
                config={this.props.config}
                columnMapForPlugin={this.props.plugin.columnMapForPlugin}
              />
            </Row>
          </div>
        </div>
        {renderContent(
          isRerender,
          this.pluginRender,
          this.props.plugin,
          data,
          columnMap,
          pluginConfig,
          condFormats,
          this.props.setPluginRerender,
          this.lastContent,
          this.updateLastContent
        )}
        {configComponent}
        {dataComponent}
      </>
    );
  }
}
