import React, { Component } from "react";

class FooterVispeahenLogo extends Component {
  render() {
    return (
      <section id="footer" style={{position: this.props.position ? this.props.position : "fixed", zIndex: 0}}>
        <div
          class="footer-logo"
          style={{backgroundImage: "url(" + require("../../images/logo.webp") + ")"}}
        ></div>
      </section>
    );
  }
}

export default FooterVispeahenLogo;
