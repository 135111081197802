const colors = [
  "#0e2792",
  "#036ab1",
  "#0eb958",
  "#0ba787",
  "#0aa18a",
  "#0af56b",
  "#06d780",
  "#0d17d9",
  "#05920d",
  "#0287c8",
  "#0de6d5",
  "#06d15b",
  "#0351aa",
  "#00a766",
  "#08a74b",
  "#0277de",
  "#01d0f4",
  "#0d433d",
  "#093361",
  "#00e5a9",
  "#0e4bea",
  "#03723f",
  "#07021d",
  "#066016",
  "#00f35a",
  "#0af232",
  "#02e34e",
  "#00c7f9",
  "#06fd5e",
  "#0e4494",
  "#049c15",
  "#0344ff",
  "#04cdf7",
  "#010750",
  "#018ac2",
  "#0304c3",
  "#0c00ab",
  "#0f137e",
  "#0f1bbe",
  "#06be45",
  "#083d6e",
  "#05c671",
  "#01f677",
  "#04e1d0",
  "#00a0de",
  "#06a2b0",
  "#08cdb1",
  "#056b07",
  "#017f17",
  "#02a1ed",
  "#0c1552",
  "#0d73c5",
  "#08aea7",
  "#004fbc",
  "#03580c",
  "#06ea57",
  "#0a5079",
  "#03f02e",
  "#069f95",
  "#011c45",
  "#0a3dae",
  "#02fcef",
  "#0651c3",
  "#0e5e5a",
  "#0d3a53",
  "#0ef5de",
  "#0d6eca",
  "#01a74f",
  "#05f5a0",
  "#0e265d",
  "#0ec2ce",
  "#0b3801",
  "#0526f4",
  "#029478",
  "#0ddedc",
  "#0b34b2",
  "#05e4c2",
  "#08a63a",
  "#0e805f",
  "#05ab35",
  "#09e86e",
  "#00d4ce",
  "#0c189f",
  "#0cc1f9",
  "#08c995",
  "#096745",
  "#099526",
  "#081e0d",
  "#03921c",
  "#074aa6",
  "#0b45f6",
  "#05f56d",
  "#0dd80c",
  "#0341a5",
  "#071693",
  "#0b0f55",
  "#04dca0",
  "#0c5fd9",
  "#0d2270",
  "#04f790",
];

/*
* Generates and return hashmap [key: username, value: color]
*/
export const generateAndAppendUserColors = users => {
    let userListMap = new Map();

    for (let i = 0; i < users.length; i++) {
        if (i < colors.length) {
            userListMap.set(users[i].username, colors[i])
        } else {
            userListMap.set(users[i].username, colors[colors.length % i])
        }
    } 

    return userListMap;
}

/*
* Checks is comment empty
*/
export const checkIsCommentOnlySpace = comment => {
    if (!comment.replace(/\s/g, '').length) {
        return false
    }

    return true
}