import i18n from "../../Utils/i18next";
import { SET_MINIMIZE, SET_OPERATION_STATUS } from "./FileUploadAction";
import { STATUS } from "./UploadOperation";

/**
 *
 * @param {*} state
 * @param {*} action
 * Reducer for dashboard what if
 *
 */
export default function FileUploadReducer(
  state = {
    minimize: false,
    operationStatus: {
      status: STATUS.NOT_STARTED,
      progress: 0,
    },
  },
  action
) {
  switch (action.type) {
    case SET_MINIMIZE:
      return {
        ...state,
        minimize: action.payload,
      };
    case SET_OPERATION_STATUS:
      return {
        ...state,
        operationStatus: action.payload,
      };
    default:
      return state;
  }
}
