/**
 * Every time that password input's value is changed this function will take action,
 * password strength has 5 different rule
 * 1-At least 8 character
 * 2-At least 1 uppercase letter
 * 3-At least 1 lowercase letter
 * 4-At least 1 numeric value
 * 5-At least 1 special character
 * These rules enforced tot the user, when password includes 5 of them user will good to continue
 * @param {*} e
 */
export const getPasswordScore = (value) => {
    let passwordLength = 8,
        containsLowerLetter = /[a-z]/.test(value),
        containsUpperLetter = /[A-Z]/.test(value),
        containsDigit = /\d/.test(value),
        containsSpecial = /[^a-zA-Z\d]/.test(value);

    let containsAll =
        containsLowerLetter &&
        containsUpperLetter &&
        containsDigit &&
        containsSpecial;

    let score = 0;

    if (value.length == 0) {
        score = 0;
    } else {
        if (containsAll) {
            score += 80;
        } else {
            if (containsLowerLetter) score += 20;
            if (containsUpperLetter) score += 20;
            if (containsDigit) score += 20;
            if (containsSpecial) score += 20;
        }
        if (value.length >= passwordLength) score += 20;
    }

    return score;
}