import React, { Component } from "react";
import "../GeneralComponents/PopupContainer/PopupContainer.css";
import { Divider, Empty } from "antd";
import PluginItem from "./PluginItem";
import Text from "../GeneralComponents/Text/Text";
import { sendDataToGoogleAnalytics } from "../../Utils/GoogleAnalytics"
import { pluginGroupTranslateNameSet } from "../../Utils/PluginOperations";
import i18n from 'i18next';

export default class PluginGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: []
    };
  }

  componentWillMount() {
    if (this.props.data.length > 0) {
      this.setGrouping(this.props.data);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.data != nextProps.data) {
      this.setGrouping(nextProps.data);
    }
  }

  setGrouping = dataArray => {
    let groupedData = {};

    dataArray.map(item => {
      let data = pluginGroupTranslateNameSet(item);
      if (groupedData[data.translateGroupName] == undefined) {
        groupedData[data.translateGroupName] = [];
      }

      groupedData[data.translateGroupName].push(data);
    });

    this.setState({
      ...this.state,
      data: groupedData
    });
  };

  selectPlugin = plugin => {
    //When user click one of plugins, user datas sending to google.
    let pluginName = plugin.name
    sendDataToGoogleAnalytics("Clicked Plugin", pluginName)
    this.props.selectPlugin(plugin);
  };

  render() {
    const { data } = this.state;
    const THIS = this;

    const containerStyle = Object.keys(data).length > 0 ? 
    {paddingTop: "10px"} : 
    {height: "100%", maxHeight: "500px"};

    return (
      <div className="container-fluid" style={containerStyle}>
        {Object.keys(data).length > 0 ?
          Object.keys(data).map(function (item, indexGroup) {
            return (
              <div key={indexGroup}>
                <Divider orientation="left" style={{ width: "100%" }}>
                  <Text
                    id={item}
                    type="label"
                    style={{
                      color: "#585858"
                    }}
                  >
                    {item}
                  </Text>
                </Divider>
                <div className="row">
                  {data[item].map((item2, index) => (
                    <PluginItem
                      key={indexGroup+"-"+index}
                      onClick={() => THIS.selectPlugin(item2)}
                      data={item2}
                    ></PluginItem>
                  ))}
                </div>
              </div>
            );
          }) : (
            <Empty
              description={i18n.t("NoDataContent.NoData")}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%"
              }}>
            </Empty>
          )}
      </div>
    );
  }
}
