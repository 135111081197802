import React, { Component } from "react";
import Text from "../Text/Text";
import Tooltip from "../Tooltip/Tooltip" 
import "./boxItem.css";
import PropTypes from "prop-types";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export default class BoxItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTooltipVisible: true,
    };
  }

  // Hides tooltip when click on BoxItem
  handleTooltipHide = () => {
    this.setState({
      isTooltipVisible: false
    });
  }; 

  // Shows tooltip when hover on BoxItem
  handleTooltipShow = () => {
    this.setState({
      isTooltipVisible: true
    });
  };

  render() {
    let style = this.props.style ? this.props.style : {}
    let colStyle = this.props.className === "onColumnMap"
      ? this.props.isDisabledColumn === true
        ? { ...style, backgroundColor: "#d5e8f780" }
        : { ...style, backgroundColor: "#d5e8f7" }
      : { ...style }

    return (
      <div
        id={this.props.id}
        className={
          this.props.divClassName
            ? "box-item-component " + this.props.divClassName
            : "box-item-component col-lg-4 col-sm-6 col-xs-12"
        }
        onClick={this.handleTooltipHide}
        onMouseEnter={this.handleTooltipShow}
      >
        <Tooltip
          tooltipPlacement={this.props.tooltipPlacement || "left"}
          tooltip={this.state.isTooltipVisible && this.props.showTooltip !== false && !this.props.isPopoverContentVisible && (
            <>
              <div>{this.props.tooltipTitle}</div>
              <div style={{ color: "rgb(255, 141, 141)", fontSize: "11px" }}>{this.props.error}</div>
            </>
          )}
          overlayClassName={"box-item-tooltip"}
        >
          <div
            className={
              this.props.contentItem && this.props.contentItem == true
                ? "box-item-content"
                : ""
            }
          >
            <span
              id={this.props.id !== undefined ? this.props.id + " color" : this.props.title}
              style={colStyle}
              className={
                this.props.className !== undefined
                  ? "box-item " + this.props.className + (this.props.columnType === "newColumn" ? " newColumn" : "")
                  : "box-item"
              }
              onClick={this.props.onClick ? this.props.onClick : null}
            >
              {this.props.image ?
                <img
                  alt=""
                  src={this.props.image}
                  style={{
                      padding: "0",
                      borderRadius: "50%",
                      height: "24px",
                      width: "24px",
                      marginRight: "8px"
                  }}
                />
                : 
                <></>
              }
              <Text 
                style={{
                  display: 'inline-block',
                  width: '90%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  alignItems: 'center'
                }} 
                className={this.props.onColumnMap === true ? "box-text onColumnMap Active" : "box-text"}>
                {
                  this.props.error && <ExclamationCircleOutlined style={{ marginRight: 4, color: "rgb(255, 141, 141)" }} />
                }
                {this.props.title}
              </Text>
            </span>
            {this.props.isReadOnlyAndCustomDashboardMode ? null :
              <div className={"extra box-item-extra"}>
                {this.props.extra ? this.props.extra : null}
              </div>}
            {this.props.children}
          </div>
        </Tooltip>
      </div>
    );
  }
}

BoxItem.prototypes = {
  onClick: PropTypes.func,
  divClassName: PropTypes.string,
  contentItem: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.object.isRequired,
  className: PropTypes.string,
  extra: PropTypes.obj,
};
