import $ from "jquery";
import React, { Component } from "react";
import i18n from "../../../Utils/i18next";
import { API_BASE } from "../../../config";
import Button from "../../GeneralComponents/Button/Button";
import PopupContainer from "../../GeneralComponents/PopupContainer/PopupContainer";
import Text from "../../GeneralComponents/Text/Text";
import SelfServiceConnection from "./SelfServiceConnection";

const host = API_BASE;
const api = "/dataSource";
const url = host + api;

/**
 * This component contains database components that are supported and can be connected, and also makes test connection and save connection for all of them.
 */
export default class AddDataWithoutDataSourceForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSourceName: ""
    };
  }

  /*
  * On Change Data Source Name
  */
  onChangeDataSourceName = (value) => {
    this.setState({
      ...this.state,
      dataSourceName: value
    })
  }

  onCloseDataSource = () => {
    this.setState({
      ...this.state,
      dataSourceName: ""
    }, () => {
      this.props.justCloseDataUploadStatus()
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.visibleModelContentView !== nextProps.visibleModelContentView && nextProps.visibleModelContentView === false) {
      this.onCloseDataSource()
    }
  }

  render() {
    let generalWidth = $("body").innerWidth();
    let dataSourcesPopupWidth = generalWidth * 40 / 100 //Data source popup width must be equal screen width %40
    let modelsPopupWidth = (generalWidth * 53 / 100) //Models popup width must be equal screen width %53
    let dataSourcesPopupPosition = (-modelsPopupWidth) + (-dataSourcesPopupWidth) - 11 //We calculated data sources popup position here. We gave this position left, so this value must be negative, -((Data source popup width) + (models popup width) + margin-left)

    return (
      <div style={{ display: this.props.visible ? "block" : "none" }}>
        <PopupContainer
          width={dataSourcesPopupWidth}
          constantHeight="650px"
          additionalPosition="1653%"
          position="absolute"
          right={dataSourcesPopupPosition}
        >
          <Text type="h4">{i18n.t("DataConnections.ConnectDataStore")}</Text>
          <Text type="h6" style={{ color: "#a2a2a2", marginBottom: "27px" }}>
            {" "}
            {i18n.t("DataConnections.ChooseConnectionAndCreate")}
          </Text>
          <div className={"container"}>
            <SelfServiceConnection
              setVisibleDataUploadStatus={this.props.setVisibleDataUploadStatus}
              dataSourceEditForWithoutDatabase={this.props.dataSourceEditForWithoutDatabase}
              datasourceEditMode={this.props.datasourceEditMode}
              dataSourceEditModeId={this.props.dataSourceEditModeId}
              editedSelfService={this.props.editedSelfService}
              justCloseDataUploadStatus={this.props.justCloseDataUploadStatus}
              onChangeDataSourceName={this.onChangeDataSourceName}
              dataSourceName={this.state.dataSourceName} />
          </div>
          <Button
            id="closeDatabaseConnectionButton"
            style={{
              position: "absolute",
              top: "3px",
              right: "14px",
              borderRadius: "40px",
              padding: "6px 11px",
              backgroundColor: "white",
              zIndex: 1,
            }}
            type={"default"}
            onClick={() => this.onCloseDataSource()}
          >
            X
          </Button>
        </PopupContainer>
      </div>
    );
  }
}