import { Select, Tooltip } from "antd";
import React, { Component } from "react";
import i18n from "../../../../Utils/i18next";
import $ from "jquery"

const { Option } = Select;

const filterOperators = [
  "=",
  "!=",
  "CONTAINS",
  "ENDS_WITH",
  "STARTS_WITH",
];

/*
* Returns string filter operator select options
*/
export default class FilterOperatorsForStringFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.defaultValue ? this.props.defaultValue : "<=>",
    };
  }

  onChange = (value) => {
    this.setState({
      value,
    });

    $("#" + this.props.pluginId).find("select").val("")
    $('#select-' + this.props.pluginId).trigger('select2.select');

    this.props.setFilterOperator(value);
  };

  render() {
    return (
      <div>
        <Select
          showSearch
          id={"filterOperators"}
          style={this.props.style}
          getPopupContainer={(trigger) => trigger.parentNode}
          value={
            this.props.value
          }
          placeholder={i18n.t("Model.SelectModel.PlaceHolder")}
          optionFilterProp="children"
          onChange={this.onChange}
          dropdownClassName={this.props.pluginId ? this.props.pluginId : ""}
          filterOption={(input, option) =>
            option.props.children
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          menuPlacement="auto"
        >
          {filterOperators.map((operator, key) => (
            <Option value={operator} key={key}>
              <Tooltip
                placement="topLeft"
              >
                {i18n.t(`FilterOperators.${operator}`)}
              </Tooltip>
            </Option>
          ))}
        </Select>
      </div>
    );
  }
}
