export const checkTableJoins = (
  join,
  columnMap,
  refreshedPluginId,
  pluginId,
  mustWork = false
) => {
  /* If this function is running from DataConstantContent we cannot reach doesPluginHasNotJoinedTable props.
     Because DataConstantContent's lifecycle runs before to setrefreshedPluginId function.
     So, We can't reach refreshedPluginId. And this function not run. 
     If this function didn't run, our plugin refreshed. 
     But we want check data need or not to join before refresh.
     mustWork comes true only DataConstantContent component, In this way we can check plugin need join before plugin refresh.
  */
  if (
    (refreshedPluginId === pluginId && pluginId !== undefined) ||
    (mustWork === true && columnMap !== undefined)
  ) {
    if (columnMap !== undefined) {
      let columnMapArr = Object.values(columnMap);

      let controlledColumns = [];
      let columnsWithNotConnected = [];

      //checks sended columns already check
      const alreadyCheck = (a, b) => {
        let checked = true;
        for (let i = 0; i < controlledColumns.length; i++) {
          if (
            controlledColumns[i] == `${a}, ${b}` ||
            controlledColumns[i] == `${b}, ${a}`
          ) {
            checked = false;
          }
        }

        return checked;
      };

      //checks joinlist has any join
      const controlJoinList = (column1, column2) => {
        for (let i = 0; i < join.length; i++) {
          if (
            (join[i].factTableAlias === column1.tableAliasName ||
              join[i].factTableAlias === column2.tableAliasName) &&
            (join[i].dimensionTableAlias === column2.tableAliasName ||
              join[i].dimensionTableAlias === column1.tableAliasName)
          ) {
            return true;
          }
        }
      };

      //pushes all data in columnMap to array
      const findColumns = (findForTableCheck = false) => {
        let columnTableAliasNameSet = new Set();
        let columnTableAliasNameArray = [];

        for (let i = 0; i < columnMapArr.length; i++) {
          for (let j = 0; j < columnMapArr[i].data.length; j++) {
            if (findForTableCheck === false) {
              columnTableAliasNameSet.add(
                columnMapArr[i].data[j].tableAliasName
              );
              columnTableAliasNameArray.push(columnMapArr[i].data[j]);
            } else {
              columnTableAliasNameSet.add(
                columnMapArr[i].data[j].tableAliasName
              );
            }
          }
        }
        return {
          data: columnTableAliasNameArray,
          tableAliasName: columnTableAliasNameSet,
        };
      };

      //detects not joined data and pushes a array not joined table columns.
      let notJoinedTableList = findColumns().data;
      //columnTableAliasName.tableAliasName

      for (let i = 0; i < notJoinedTableList.length - 1; i++) {
        for (let j = i + 1; j < notJoinedTableList.length; j++) {
          if (
            // [a1, a2, a3]
            notJoinedTableList[i].tableAliasName !==
            notJoinedTableList[j].tableAliasName
          ) {
            if (
              alreadyCheck(
                //columnTableAliasName.tableAliasName
                notJoinedTableList[i].tableAliasName,
                notJoinedTableList[j].tableAliasName
              ) ||
              controlledColumns.length === 0
            ) {
              controlledColumns.push(
                `${notJoinedTableList[i].tableAliasName}, ${notJoinedTableList[j].tableAliasName}`
              );
              if (
                !controlJoinList(notJoinedTableList[i], notJoinedTableList[j])
              ) {
                columnsWithNotConnected.push(
                  notJoinedTableList[i],
                  notJoinedTableList[j]
                );
              }
            }
          }
        }
      }
      //we don't want to duplicated columns. so we need unique columns.
      const uniqueColumns = [
        ...new Set(columnsWithNotConnected.map((item) => item)),
      ];

      let factAndDimensionColumnList = [];
      let isJoined = false;
      let allAddedColumnsUnique = findColumns(true).tableAliasName;

      const allAddedColuniqueColumns = [...allAddedColumnsUnique];
      let usedColumnsWithJoin = new Set(allAddedColumnsUnique);

      //check columns have join
      for (let i = 0; i < join.length; i++) {
        if (allAddedColumnsUnique.has(join[i].dimensionTableAlias)) {
          factAndDimensionColumnList.push({
            dim: join[i].dimensionTableAlias,
            fact: join[i].factTableAlias,
          });

          usedColumnsWithJoin.delete(join[i].dimensionTableAlias)
          usedColumnsWithJoin.delete(join[i].factTableAlias)
        }
      }

      //Controls has table same fact when fact table is not added
      const isTablesHasSameFactTable = (columns) => {
        for (let i = 0; i < columns.length; i++) {
          let sameFactedTables = columns.filter(
            (col) => col.fact === columns[i].fact
          );

          if (sameFactedTables.length > 1) {
            return false;
          }
        }
        
        return true;
      };

      //we have a,b,c columns and a is fact, b and c dimension. checks a is added
      for (let i = 0; i < factAndDimensionColumnList.length; i++) {
        for (let j = 0; j < factAndDimensionColumnList.length; j++) {
          if (
            factAndDimensionColumnList[i].fact ===
              factAndDimensionColumnList[j].fact &&
            factAndDimensionColumnList[i].dim !==
              factAndDimensionColumnList[j].dim
          ) {
            if (allAddedColumnsUnique.has(factAndDimensionColumnList[i].fact)) {
              isJoined = true;
            } else {
              isJoined = false;
            }
          }
        }
      }

      let hasAnyColumnIsNotExistInJoin = usedColumnsWithJoin?.size > 0 ? true : false
      let isTableHasSameFactTable = isTablesHasSameFactTable(factAndDimensionColumnList) ? true : false
      let isJoinColsLengthEqualOrGreater = uniqueColumns.length >= allAddedColuniqueColumns.length ? true : false
      let hasJoinedTables = isJoined !== true ? true : false
      let couldShowJoinError = ((hasJoinedTables || isJoinColsLengthEqualOrGreater) && isTableHasSameFactTable) || hasAnyColumnIsNotExistInJoin

      if (couldShowJoinError) {
        return uniqueColumns;
      } else {
        return [];
      }
    }
  } else {
    return [];
  }
};
