import $ from "jquery";

var autoRefreshInterval;

/** timer start method */
export const startTimer = (timerFunction, second) => {
    let milisecond = second * 1000;
    autoRefreshInterval = setInterval(timerFunction, milisecond);

    //Animation must start.
    setTimeout(function(){ $(".timer-area").find(".timer").removeClass("animation-none"); }, 1);
}

/** timer stop method */
export const stopTimer = () => {
    clearInterval(autoRefreshInterval)

    //Animation must stop
    $(".timer-area").find(".timer").addClass("animation-none");
}