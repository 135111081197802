import React, { Component } from "react";
import Text from "../../../GeneralComponents/Text/Text";
import { Row, Col, Input, Select, Checkbox, Divider, Button, Tooltip } from "antd";
import { UpdateConfig } from "../../UpdateConfig";
import {
  FontSizeOutlined,
  NumberOutlined,
  BgColorsOutlined,
  CrownOutlined,
  HeatMapOutlined,
  EyeInvisibleOutlined,
  FileImageOutlined,
  ColumnWidthOutlined,
  ColumnHeightOutlined,
  ReloadOutlined,
  FormatPainterOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import i18n from "../../../../Utils/i18next";
import ChangeFontStylePlugin from "../../../ConditionalFormatting/ChangeFontStylePlugin";

const { TextArea } = Input;
const { Option } = Select;

/**
 * Configuration file of RouteMap2 plugin
 */
export default class RouteMap2Configuration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: this.props.config.height,
      theme: this.props.config.theme,
      width: this.props.config.width,
      toggleCriteria: this.props.config.toggleCriteria,
      backgroundColor: this.props.config.backgroundColor,
      summary: this.props.config.summary,
      title: this.props.config.title,
      titleAlign: this.props.config.titleAlign,
      titleFontStyle: this.props.config.titleFontStyle,
      titleFontWeight: this.props.config.titleFontWeight,
      noDataTitle: this.props.config.noDataTitle === undefined ? "" : this.props.config.noDataTitle
    };
  }

  /**
   * Plugin compenents receive it's created properties
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      this.setState({
        height: nextProps.config.height,
        theme: nextProps.config.theme,
        width: nextProps.config.width,
        toggleCriteria: nextProps.config.toggleCriteria,
        backgroundColor: nextProps.config.backgroundColor,
        summary: nextProps.config.summary,
        title: nextProps.config.title,
        titleAlign: nextProps.config.titleAlign,
        noDataTitle: nextProps.config.noDataTitle === undefined ? "" : nextProps.config.noDataTitle,
      });
    }
  }

  /**
   * Changes the value of text attributes in RouteMap2 plugin use handleChange
   */
  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value,
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.value });
  };

  /**
   * Checks whether checkbox is checked or not in RouteMap2 plugin use handleCheck
   */
  handleCheck = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked,
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.checked });
  };

  /**
   * Updates state attributes in RouteMap2 plugin
   */
  setConfigToPlugin = (changedObj) => {
    let tempState = { ...this.state };
    tempState[changedObj.type] = changedObj.value;

    this.props.updateConfig(tempState, this.props.pluginId);
  };

  /**
   * Changes the title attribute in RouteMap2 plugin use titleAlignOnChange
   */
  titleAlignOnChange = (value) => {
    this.setState({
      ...this.state,
      titleAlign: value,
    });

    this.setConfigToPlugin({ type: "titleAlign", value: value });
  };

  /**
   * Changes the theme attribute in RouteMap2 plugin use colorOnChange
   */
  themeOnChange = (obj) => {
    this.setState({
      ...this.state,
      theme: obj,
    });

    this.setConfigToPlugin({ type: "theme", value: obj });
  };

  render() {
    return (
      <div style={{ color: "#505050" }}>
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.GeneralAppearance")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FormatPainterOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Theme")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              showSearch
              style={{ width: "100%" }}
              getPopupContainer={(trigger) => trigger.parentNode}
              placeholder="Select a theme"
              optionFilterProp="children"
              onChange={this.themeOnChange}
              defaultValue={this.state.theme}
            >
              <Option value="None">
                {i18n.t("Dashboard.Configuration.Fields.None")}
              </Option>
              <Option value="Dark">
                {i18n.t("Dashboard.Configuration.Fields.Dark")}
              </Option>
              <Option value="Chalk">
                {i18n.t("Dashboard.Configuration.Fields.Chalk")}
              </Option>
              <Option value="Light">
                {i18n.t("Dashboard.Configuration.Fields.Light")}
              </Option>
              <Option value="Patterns">
                {i18n.t("Dashboard.Configuration.Fields.Patterns")}
              </Option>
              <Option value="Black">
                {i18n.t("Dashboard.Configuration.Fields.Black")}
              </Option>
            </Select>
          </Col>
        </Row>
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Others")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Title")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"title"}
              defaultValue={this.state.title}
              value={this.state.title}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.TitleAlign")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              showSearch
              style={{ width: "100%" }}
              getPopupContainer={(trigger) => trigger.parentNode}
              placeholder={i18n.t(
                "Dashboard.Configuration.Fields.SelectTitleAlign"
              )}
              optionFilterProp="children"
              onChange={this.titleAlignOnChange}
              defaultValue={this.state.titleAlign}
            >
              <Option value="center">
                {i18n.t("Dashboard.Configuration.Fields.Center")}
              </Option>
              <Option value="left">
                {i18n.t("Dashboard.Configuration.Fields.Left")}
              </Option>
              <Option value="right">
                {i18n.t("Dashboard.Configuration.Fields.Right")}
              </Option>
            </Select>
          </Col>
        </Row>
        <ChangeFontStylePlugin   
          titleFontStyle={this.state.titleFontStyle || false} 
          titleFontWeight={this.state.titleFontWeight || false}
          handleChange={this.handleChange}
          titleColumn={9} 
        />
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left", display: "flex" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Settings.NoDataConfiguration")}
            </Text>
            <Tooltip 
              title={
                <>
                {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Info")} <br />
                {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.GraphicConfiguration")} <br />
                {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Settings")} <br />
                {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.DefaultText")} <br />
                </>
              }
            >
              <InfoCircleOutlined style={{ position: 'relative', marginTop: '5px', marginLeft: "10px" }} /> 
            </Tooltip>
          </Col>
          <Col span={12}>
            <TextArea
              allowClear
              id={"noDataTitle"}
              defaultValue={this.state.noDataTitle}
              value={this.state.noDataTitle}
              onChange={this.handleChange}
              autoSize={{ minRows: 3, maxRows: 5 }}
              placeholder={i18n.t("NoDataContent.NoData") + " " +
                i18n.t("NoDataContent.PluginCouldNotBeVisualized")}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
