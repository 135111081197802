import i18n from "./i18next";


//translate join table layout names depends on i18n
export const joinLayoutOperations = (data) => {
    let labels = [];
    
    data.map(d => {
        d.translateName = i18n.t("JoinTable." + d.joinType)
        labels.push(d);
    })
    return labels;
}
