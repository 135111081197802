import { Select, Tooltip } from "antd";
import React, { Component } from "react";
import i18n from "../../../../Utils/i18next";

const { Option } = Select;

const filterOperators = [
  "=",
  ">",
  "<",
  ">=",
  "<=",
  "<=>",
  "isNull",
  "isNotNull",
];

export default class FilterOperators extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.defaultValue ? this.props.defaultValue : "<=>",
    };
  }

  onChange = (value) => {
    this.setState({
      value,
    });

    if (value === "<=>") {
      value = "between";
    }

    this.props.setFilterOperator(value);
  };

  isBetweenOperator = (value) => {
    if (value === "between") {
      return "<=>";
    }

    return value;
  };

  getTooltip = (operator) => {
    if (operator === "<=>") {
      return "between";
    }

    return operator;
  };

  render() {
    return (
      <div>
        <Select
          showSearch
          id={"filterOperators"}
          style={this.props.style}
          value={
            this.props.value
              ? this.isBetweenOperator(this.props.value)
              : this.state.value
          }
          placeholder={i18n.t("Model.SelectModel.PlaceHolder")}
          optionFilterProp="children"
          onChange={this.onChange}
          dropdownClassName={this.props.pluginId ? this.props.pluginId : ""}
          filterOption={(input, option) =>
            option.props.children
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          menuPlacement="auto"
        >
          {filterOperators.map((operator, key) => (
            <Option value={operator} key={key}>
              <Tooltip
                placement="topLeft"
                title={this.getTooltip(operator)}
                overlayClassName="default-filter-operator-tooltip"
                dropdownClassName={`default-filter-operator-tooltip`}
              >
                {operator}
              </Tooltip>
            </Option>
          ))}
        </Select>
      </div>
    );
  }
}
