import Cookies from "js-cookie";
import _ from "lodash";
import React, { Component } from "react";
import SockJsClient from "react-stomp";

const SOCKET_URL = "/websocket/vispeahen";

const customHeaders = {
  Authorization: "Bearer " + Cookies.get("ORA_BIPS_NQID"),
};

/*
* General Web Socket App Component.
* Listens topic (Gets from props) type Array
* Triggers related function (Gets from props) type Function
*/
class WebSocketApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: undefined,
      destination: "",
      changeStatus: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.dashboardId !== nextProps.dashboardId && this.props.dashboardId) {
      this.setState({
        ...this.state,
        changeStatus: true
      }, () => {
        this.setState({
          ...this.state,
          changeStatus: false
        })
      })
    }
  }

  /*
  * On Connected
  */
  onConnected = () => {
    console.log("Connected");
  };

  /*
  * On Disconnected
  */
  onDisconnect = () => {
    console.log("Disconnected")
  }

  /*
  * On message received, triggers props listenTopic function
  */
  onMessageReceived = (message, destination) => {
    this.props.listenTopic(message, destination);

    this.setState({
      destination: destination,
      message: message,
    });
  };

  render() {
    return (
      <>
        {!this.state.changeStatus ? <SockJsClient
          url={SOCKET_URL}
          topics={this.props.topics}
          onConnect={this.onConnected}
          onDisconnect={this.onDisconnect}
          onMessage={(msg, destination) =>
            this.onMessageReceived(msg, destination)
          }
          headers={customHeaders}
          debug={false}
        /> : null}
      </>
    );
  }
}

export default WebSocketApp;
