import { CloudUploadOutlined, FileExcelTwoTone } from "@ant-design/icons";
import React, { Component } from "react";
import i18n from "../../Utils/i18next";
import Button from "../GeneralComponents/Button/Button";
import Text from "../GeneralComponents/Text/Text";

const  supportedFileFormats = ["xlsx", "xls"];

export default class CustomUploader extends Component {
    /**
   * Triggers file selection window
   */
  fileSelection = () => {
    let input = document.getElementById("template-upload");

    input.click();
  }

  
  /**
   * Converts byte-formatted file size to kilobyte-formatted or megabyte-formatted
   */
  formattedFileSize = (size) => {
    if (size <= 100) {
      return `${size} ${i18n.t("Designer.Bytes")}`
    }

    size = (size / 1024).toFixed(1);

    if (size >= 1024) {
      return `${(size / 1024).toFixed(1)} MB`;
    }

    return `${size} KB`;
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          border: "solid 1px #dadada",
          borderRadius: "4px",
          background: "#f3f3f3",
          marginTop: "8px",
          padding: "16px",
        }}
      >
        <input
          id="template-upload"
          type="file"
          accept={this.props.accept}
          style={{ display: "none" }}
          onChange={(event) => this.props.onFileUpload(event)}
        />
        {this.props.file ? (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #d3d3d3",
                  borderRadius: "8px",
                  background: "#f3f3f3",
                  marginBottom: "8px",
                  height: "66px",
                  width: "66px",
                  padding: "16px",
                }}
              >
                <FileExcelTwoTone style={{ fontSize: "48px" }} />
              </div>

              <Text style={{ fontWeight: "bold" }}>
                {i18n.t("Designer.FileName")}
              </Text>
              <Text style={{ marginBottom: "4px" }}>
                {this.props.file.name}
              </Text>

              <Text style={{ fontWeight: "bold" }}>
                {i18n.t("Designer.FileSize")}
              </Text>
              <Text>{this.formattedFileSize(this.props.file.size)}</Text>

              {/* {this.state.edit ? (
                <Button
                  style={{ marginTop: "16px" }}
                  onClick={() => this.fileSelection()}
                >
                  {i18n.t("Designer.ChangeFile")}
                </Button>
              ) : null} */}
            </div>
          </>
        ) : (
          <>
            <Text
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "16px",
              }}
            >
              <CloudUploadOutlined style={{ fontSize: "48px" }} />
              <span style={{ fontSize: "16px" }}>
                {i18n.t("Designer.SelectTemplateFile")}
              </span>
              <span style={{ fontSize: "12px" }}>
                {i18n.t("Designer.SupportedFileFormats")}:{" "}
                {this.props.accept}
              </span>
            </Text>
            <Button onClick={() => this.fileSelection()}>
              {i18n.t("Designer.SelectFile")}
            </Button>
          </>
        )}
      </div>
    );
  }
}
