import React, { Component } from "react"
import { Modal, Input } from "antd"
import "./measureModal.css"
import { brandIcons } from "../../Plugins/brandIconList"
import { icons } from "./icons"
import i18n from "../../../Utils/i18next"

export default class MeasureModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectIconChangeVisible: this.props.selectIconChangeVisible,
            iconSelector: this.props.iconSelector,
            icon: this.props.icon,
            iconOnChange: this.props.iconOnChange,
            searchInputOnChange: this.props.searchInputOnChange,
        }

        this.iconArrayList = []
    }

    convertIconName = (value) => {
        let iconValue = "";
        let prefix = "fa";
        let words = value.match(/[A-Z][a-z]+/g);
        let str = "";
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i].toLowerCase();

            if (words[i] === "reg") {
                prefix = "far";
            } else {
                str += words[i];

                if (i != words.length - 1) {
                    str += "-";
                }
            }
        }
        let searchBrand = str.replace("fa-", "")

        if (brandIcons.includes(searchBrand)) {
            prefix = "fab";
        }

        iconValue = str;
        return [prefix, iconValue];
    }

    prepareIcons = () => {
        icons.map((icon, i) => {
            let [prefix, iconValue] = this.convertIconName(icon)
            this.iconArrayList[i] = prefix + " " + iconValue
        })
    }

    render() {
        return (
            <Modal
                visible={this.props.iconSelector}
                onCancel={this.props.selectIconChangeVisible}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                width="80%"
            >
                <div className={"measure-general-body"}>
                <div className={"select-icon-text"}>{i18n.t("Dashboard.Configuration.Fields.SelectIcon")}</div>
                <div className={"modal-header"}>
                    <Input className={"modal-header-search-input"}
                        style={{ boxShadow: "none" }}
                        placeholder={i18n.t("Dashboard.Configuration.Fields.SearchIcon")}
                        onChange={this.state.searchInputOnChange}
                        value={this.props.search}></Input>
                </div>
                <div className="modal-body">
                    {this.prepareIcons()}
                    {this.iconArrayList.filter(icon => icon.includes(this.props.search.toLocaleLowerCase())).map((icon, i) => (
                        <div className={"per-icon"} key={i} onClick={() => this.state.iconOnChange(icon)}>
                            <i className={`${icon} fa-3x`}></i>
                        </div>
                    ))}
                </div>
                </div>
            </Modal>
        )
    }
}
