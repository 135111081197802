import { Divider } from "antd";
import React, { Component } from "react";
import { optionsSelector } from "./OptionsSelector";
import i18n from "../../Utils/i18next";
export default class OptionArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: {
        bold: false,
        italic: false,
        iconSetting: "Right"
      }
    };
  }

  componentWillMount() {
    if (this.props.options) {
      this.setState({ ...this.state, ...this.props.options });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.clickedApply === true) {
      this.props.getOptions(this.state, "options");
    }

    if (nextProps.options !== this.props.options) {
      this.setState({ ...nextProps.options });
    }
  }

  /** Handle change in options */
  handleChange = obj => {
    this.setState({
      ...this.state,
      [obj.type]: obj.value
    });
  };

  /** Handle changes for Icon align  */
  handleChangeAlign = obj => {
    this.setState({
      ...this.state,
      iconSetting: obj
    });
  }

  /** Decide which option and return necessary component */
  getOptionsComponents = options => {
    let option;
    let optionComponent = [];

    Object.keys(options).map((key, index) => {
      let value = undefined;
      let isPluginMeasureAndCompareEnabled = this.props.measureCompareType !== undefined && this.props.measureCompareType !== "single-column" ? true : false
      let isKeyBackgroundColor = key === "backgroundColor" ? true : false
      let isBackgroundColorAreaMustDisabled = isPluginMeasureAndCompareEnabled && isKeyBackgroundColor ? true : false

      if (this.state[key] && this.state[key] !== "") {
        value = this.state[key];
      }

      if (isBackgroundColorAreaMustDisabled) {
        option = optionsSelector(options[key], key, this.handleChange, value, true, this.handleChangeAlign, this.state);
      } else {
        option = optionsSelector(options[key], key, this.handleChange, value, false, this.handleChangeAlign, this.state);
      }

      optionComponent.push(option);
    });

    return optionComponent;
  };

  render() {
    const options = this.props.pluginConditionalFormatOptions;
    let optionsComponents = this.getOptionsComponents(options);

    return (
      <div style={{ marginBottom: "50px" }}>
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("TextOptions")}
        </Divider>
        {optionsComponents.map(option => option)}
      </div>
    );
  }
}
