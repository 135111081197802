import React, { Component } from "react";
import { Modal, Button, Row, Col } from "antd";
// import PropTypes from 'prop-types'

export default class SaveSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
      modelName: ""
    };
  }

  handleCancel = e => {
    this.setState({
      visible: false
    });
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleClick = e => {
    this.setState({
      visible: false
    });
    this.props.onClick(e.target.id);
  };

  render() {
    const { dataSource } = this.props;
    return (
      <div>
        <Modal
          title={`Which would you like to do for datasource "${dataSource.name}"`}
          visible={this.state.visible}
          maskClosable="false"
          footer={null}
          onCancel={this.handleCancel}
        >
          <Row className="centeredItemRow" >
            <Col span={10}>
              <Button id="createModelToAdd" type="primary" ghost="true" onClick={this.handleClick}>
                Create model to add
              </Button>
            </Col>
            <Col span={4}>
              <p>or</p>
            </Col>
            <Col span={10}>
              <Button id="addToExistingModel" type="primary" ghost="true" onClick={this.handleClick}>
                Add to existing model
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}
