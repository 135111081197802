import i18n from 'i18next';
import {
    initReactI18next
} from 'react-i18next';
import {
    en
} from "./translation/en";
import {
    tr
} from "./translation/tr";
import Cookies from "js-cookie";
// for passing in lng and translations on init
i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    .init({
        lng: Cookies.get("i18next"),
        fallbackLng: "en",
        debug: false,
        resources: {
            "en": en,
            "tr": tr
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;