import React, { Component } from "react";
import { Tooltip, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { pagePopupVisible } from "../../../Utils/PagePopupConfigure";
import i18n from "../../../Utils/i18next"
import $ from "jquery"

/**
 * Plugin tools remove button component
 */
export default class RemoveButton extends Component {
  /*
  * Removes plugin
  */
  removePlugin = plugin => {
    this.props.removePlugin(plugin);
    pagePopupVisible(false);
  };

  /*
  * Handles click outside popup
  */
  handleClickPopup = (event) => {
    let target = $(event.target)
    let isTargetRemovePluginPopup = target.closest(".ant-popover").length > 0 ? true : false

    if (!isTargetRemovePluginPopup && this.props.showPluginDeletePopup) {
      this.props.changePopupVisibility(false)
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickPopup);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickPopup);
  }

  render() {
    const classNameControl = this.props.iconsClassNameControl
    let isPluginHeightOne = this.props.isPluginHeightOne
    let isPluginWidthOne = this.props.isPluginWidthOne

    return (
      <Tooltip
        title={i18n.t("Dashboard.Configuration.Fields.Delete")}
        style={{}}>
        <Popconfirm
          title={i18n.t("Dashboard.Plugin.DoYouWantToDeleteThisPlugin")}
          onConfirm={() => this.removePlugin(this.props.plugin)}
          onCancel={() => this.props.changePopupVisibility(false)}
          visible={this.props.showPluginDeletePopup}
          okText={i18n.t("Yes")}
          cancelText={i18n.t("Cancel")}
          placement="bottomLeft"
        >
          <DeleteOutlined
            name={"removePluginButton"}
            className={classNameControl(isPluginHeightOne, isPluginWidthOne)}
            style={{
              cursor: "pointer",
              color: "#f92f2f",
              borderColor: this.props.deleteButtonBorderColor,
            }}
            onClick={() => this.props.changePopupVisibility(true)}
          />
        </Popconfirm>
      </Tooltip>
    );
  }
}
