import React, { Component } from "react";
import { get, del } from "../Utils/WebService";
import { API_BASE } from "../config";
import EditUserContent from "./EditUserContent";
import { showNotificationWithIcon } from "../Utils/Notification";
import { Button, Icon, Input, Tag, Table, Divider } from "antd";

const { Column } = Table;

class ListUserContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modelList: [],
      addStatus: "",
      visibleEdit: false,
      searchText: "",
      propsEditId: ""
    };
  }

  componentWillMount() {
    this.getUser();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.list === true) {
      this.getUser();
    }
  }

  getUser = () => {
    let url = `${API_BASE}/user/tenant`;

    const successFunc = result => {
      this.setState({
        modelList: result.data
      });
    };

    const errorFunc = error => {
      showNotificationWithIcon("ERROR!", error.response.data, "error");
    };

    get(url, successFunc, errorFunc);
  };

  deleteUser = id => {
    let url = `${API_BASE}/user/` + id;
    let requestBody = {};

    const successFunc = result => {
      showNotificationWithIcon("OK", "User successfully deleted.", "success");
      this.getUser();
    };

    const errorFunc = error => {
      showNotificationWithIcon("ERROR!", error.response.data, "error");
    };

    del(url, requestBody, successFunc, errorFunc);
  };

  confirmEdit = result => {
    if (result === true) {
      this.setState({
        visibleEdit: false
      });
      this.getUser();
    } else {
      this.setState({
        visibleEdit: false
      });
    }
  };

  //For Table
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    }
  });

  showEditModal = id => {
    this.setState({
      visibleEdit: true,
      propsEditId: id
    });
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  //--For Table

  render() {
    return (
      <div id="listUserContent">
        <Table dataSource={this.state.modelList}>
          <Column
            style={{ fontWeight: "bold" }}
            title="User Name"
            dataIndex="username"
            key="username"
            {...this.getColumnSearchProps("username")}
          />
          <Column
            title="Email"
            dataIndex="email"
            key="email"
            {...this.getColumnSearchProps("email")}
          />
          <Column
            title="User Level"
            dataIndex="tenant"
            key="tenant"
            {...this.getColumnSearchProps("tenant")}
            render={(text, record) => (
              <div>
                <Tag color="magenta">{record.tenant}</Tag>
              </div>
            )}
          />
          <Column
            title="Edit/Delete"
            key="process"
            render={(text, record) => (
              <span>
                <a
                  key="list-loadmore-edit"
                  onClick={() => this.showEditModal(record.id)}
                >
                  <Icon type="edit"></Icon>
                </a>
                <Divider type="vertical" />
                {record.tenant != record.username ? (
                  <a
                    key="list-loadmore-more"
                    onClick={() => this.deleteUser(record.id)}
                  >
                    <Icon type="delete"></Icon>
                  </a>
                ) : (
                  ""
                )}
              </span>
            )}
          />
        </Table>

        <EditUserContent
          visible={this.state.visibleEdit}
          confirm={this.confirmEdit}
          id={this.state.propsEditId}
        />
      </div>
    );
  }
}

export default ListUserContent;
