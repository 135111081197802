import React, { Component } from "react"
import { Row, Col, Button, Select } from "antd";
import i18n from "../../../Utils/i18next";
import { CrownOutlined} from "@ant-design/icons";
import Text from "../Text/Text";

/**
 * Select component for conditional formatting icon align
 */
export default class Selecting extends Component {
    render() {
        return (
            <Row>
                <Col style={{ textAlign: "center" }} span={3}>
                    <CrownOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col style={{ textAlign: "left" }} span={9}>
                    <Text
                    style={{
                        fontSize: "16px",
                        display: "inline-block",
                        marginTop: "2px",
                    }}
                    >
                    {i18n.t("Dashboard.ConditionalFormatting.IconProperties")}
                    </Text>
                </Col>
                <Col span={12}>
                    <Select 
                        onChange={this.props.handleChangeAlign} 
                        type={this.props.type} style={{width: "100%"}} 
                        disabled={!this.props.icon} 
                        value={this.props.value ? this.props.value : "Right"} 
                        getPopupContainer={(trigger) => trigger.parentNode}
                    >
                    {this.props.option.values.map((align) => (
                        <Select.Option value={align} key={align}>{i18n.t(align)}</Select.Option>
                    ))}
                    </Select>
                </Col>
            </Row>
        )
    }
}