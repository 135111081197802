import {
  FontSizeOutlined,
} from "@ant-design/icons";
import { Col, Divider, Input, Row, Select } from "antd";
import $ from "jquery";
import "rc-color-picker/assets/index.css";
import React, { Component } from "react";
import {
  showError,
  showNotificationWithIcon
} from "../../../../Utils/Notification";
import i18n from "../../../../Utils/i18next";
import { post } from "../../../../admin/Utils/WebService";
import Text from "../../../GeneralComponents/Text/Text";
import ReturnToTheme from "../../PluginToolsComponents/ReturnToTheme";

const { Option } = Select;

/**
 * Configuration file of Image plugin
 */
export default class ImageConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: this.props.config.height,
      linkUrl: this.props.config.linkUrl,
      imageUpload: this.props.config.imageUpload,
      imageName: this.props.config.imageName || "",
      imagePath: this.props.config.imagePath || "",
      showHideButton: this.props.config.showHideButton,
      width: this.props.config.width,
      title: this.props.config.title,
      titleAlign: this.props.config.titleAlign,
      titleFontStyle: this.props.config.titleFontStyle === undefined ? false : this.props.config.titleFontStyle,
      titleFontWeight: this.props.config.titleFontWeight === undefined ? false : this.props.config.titleFontWeight,
      titleFontSize: this.props.config.titleFontSize === undefined ? 15 : this.props.config.titleFontSize,
      titleFont: this.props.config.titleFont === undefined ? "Verdana" : this.props.config.titleFont,
      titleColour: this.props.config.titleColour === undefined ? "black" : this.props.config.titleColour,
      titleTextDecor: this.props.config.titleTextDecor === undefined ? false : this.props.config.titleTextDecor,
      changedTitleFontSize: this.props.config.changedTitleFontSize === undefined ? 15 : this.props.config.changedTitleFontSize,
      keyForTitleSize: null
    };
  }

  /**
   * Plugin compenents receive it's created properties
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      this.setState({
        height: nextProps.config.height,
        linkUrl: nextProps.config.linkUrl,
        imageUpload: nextProps.config.imageUpload,
        imageName: nextProps.config.imageName,
        imagePath: nextProps.config.imagePath,
        showHideButton: nextProps.config.showHideButton,
        width: nextProps.config.width,
        title: nextProps.config.title,
        titleAlign: nextProps.config.titleAlign,
        titleFont: nextProps.config.titleFont === undefined ? "Verdana" : nextProps.config.titleFont,
        titleFontSize: nextProps.config.titleFontSize === undefined ? 15 : nextProps.config.titleFontSize,
        changedTitleFontSize: nextProps.config.changedTitleFontSize === undefined ? 15 : nextProps.config.changedTitleFontSize,
        titleFontStyle: nextProps.config.titleFontStyle === undefined ? false : nextProps.config.titleFontStyle,
        titleFontWeight: nextProps.config.titleFontWeight === undefined ? false : nextProps.config.titleFontWeight,
        titleTextDecor: nextProps.config.titleTextDecor === undefined ? false : nextProps.config.titleTextDecor,
        titleColour: nextProps.config.titleColour === undefined ? "black" : nextProps.config.titleColour
      });
    }
  }

  //sets defaults we get from plugin.jsx. callback for fontsizekey because it should keep changing to trigger default revert
  setDefaultsForTitle = () => {
    let newConfig = this.props.setDefaultForPluginTitle();

    this.setState(
      {
        ...this.state,
        title: newConfig.title,
        titleAlign: newConfig.titleAlign,
        titleFont: newConfig.titleFont,
        titleFontSize: newConfig.titleFontSize,
        titleFontStyle: newConfig.titleFontStyle,
        titleFontWeight: newConfig.titleFontWeight,
        titleTextDecor: newConfig.titleTextDecor,
        titleColour: newConfig.titleColour,
        changedTitleFontSize: newConfig.titleFontSize
      }, () => {
        this.setConfigToPlugin({ type: "changedTitleFontSize", value: newConfig.titleFontSize })
      }
    );

    if (newConfig.titleFontSize > 10 || newConfig.titleFontSize < 30) {
      $("#titleFontSize").css("background", "")
    }
  }

  /*
  * Changes both of constant and changed value 
  * constant = between 10 and 30 values
  * changed value = all of values
  */
  handleChangeBothOfValues = (constantValue, changedValue) => {
    this.setState({
      ...this.state,
      titleFontSize: constantValue,
      changedTitleFontSize: changedValue
    })

    this.setConfigToPlugin(
      [
        {
          type: "titleFontSize",
          value: constantValue
        },
        {
          type: "changedTitleFontSize",
          value: changedValue
        }
      ]);
  }

  titleColourChangeVisible = () => {
    this.setState({
      ...this.state,
      titleColourChange: !this.state.titleColourChange
    });
  };

  /**
   * Changes the value of text attributes in Image plugin use handleChange
   */
  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.value });
  };

  /**
   * Checks whether checkbox is checked or not in Image plugin use handleCheck
   */
  handleCheck = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.checked });
  };

  /**
   * Updates state attributes in Image plugin
   */
  setConfigToPlugin = (changedObj, propertyListToDelete = undefined) => {
    let tempState = { ...this.state };

    if (Array.isArray(changedObj)) {
      for (let i = 0; i < changedObj.length; i++) {
        let object = changedObj[i];
        tempState[object.type] = object.value;
      }
    } else {
      tempState[changedObj.type] = changedObj.value;
    }

    if (propertyListToDelete !== undefined) {
      for (let item of propertyListToDelete) {
        delete tempState[item]
      }
    }

    tempState.imageName = "";
    tempState.imagePath = "";
    tempState.imageUpload = "";

    this.props.updateConfig(
      tempState,
      this.props.pluginId,
      propertyListToDelete
    );
  };

  /**
   * Changes the icon attribute in Image plugin use iconOnChange
   */
  iconOnChange = value => {
    this.setState({
      ...this.state,
      icon: value
    });

    this.setConfigToPlugin({ type: "icon", value: value });
  };

  /**
   * Changes the title attribute in Image plugin use titleAlignOnChange
   */
  titleAlignOnChange = value => {
    this.setState({
      ...this.state,
      titleAlign: value
    });

    this.setConfigToPlugin({ type: "titleAlign", value: value });
  };

  /**
   * Changes the color attribute in Image plugin use colorOnChange
   */
  colorOnChange = obj => {
    this.setState({
      ...this.state,
      colour: obj.color
    });

    this.setConfigToPlugin({ type: "colour", value: obj.color });
  };

  /**
   * Changes the background colour attribute in Image plugin use backgroundColorOnChange
   */
  backgroundColorOnChange = obj => {
    this.setState({
      ...this.state,
      backgroundColor: obj.color
    });

    this.setConfigToPlugin({ type: "backgroundColor", value: obj.color });
  };

  /**
   * Demonstrates how the choosen color will affect the interface in Image plugin
   */
  backgroundColorChangeVisible = () => {
    this.setState({
      ...this.state,
      backgroundColorChange: !this.state.backgroundColorChange
    });
  };

  /**
   *
   * @param {*} e
   * @returns
   *
   * Performs validation check for the uploaded file
   */
  fileValidationCheck = e => {
    if (e === undefined) {
      return false;
    }

    let fileName = e.target.files[0] ? e.target.files[0].name : undefined;

    if (fileName === undefined) {
      return false;
    } else {
      let fileNameSplit = fileName.split(".");
      let fileType = fileNameSplit[fileNameSplit.length - 1];
      let isFileType = !(fileType === "jpeg" || fileType === "png" || fileType === "jpg");

      if (isFileType) {
        return false;
      }

      return true;
    }
  };

  //handle selected file and name
  onChangeFile = e => {
    let THIS = this;

    if (e !== undefined) {
      if (!this.fileValidationCheck(e)) {
        showError(i18n.t("PluginErrors.ImageFormatInvalid"));

        return;
      }

      /**
       * image file to base 64
       * @param {*} img 
       * @param {*} callback 
       */
      const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
      };

      if (e.target.files[0] && e.target.files[0].name) {
        let file = e.target.files[0];
        let fileName = e.target.value;

        getBase64(file, imageUrl => {
          this.state.image = imageUrl;
          imageUrl = imageUrl.split(",")[1];
          this.uploadImage(imageUrl, fileName);
        });
      }
    }
  };

  /**
   * Upload image to resource server.
   */
  uploadImage = (file, fileName) => {
    let url = "/resource/api/resource";

    let requestBody = {
      content: file,
      cacheable: false
    };

    const successFunc = result => {
      let tempState = { ...this.state };
      let resourcePath = "/resource/api/resource/";

      this.setState({
        ...this.state,
        imageUpload: file,
        imageName: fileName,
        imagePath: resourcePath + result.data
      });

      tempState.imageName = fileName;
      tempState.imagePath = resourcePath + result.data;
      tempState.linkUrl = "";
      this.props.updateConfig(tempState, this.props.pluginId);
    };

    const errorFunc = response => {
      let tempState = { ...this.state };

      showNotificationWithIcon(i18n.t("Error"), i18n.t("PluginErrors.ImageCouldntUpload"), "error");

      this.setState({
        ...this.state,
        imageUpload: "",
        imageName: "",
        imagePath: ""
      });

      tempState.imageName = "";
      tempState.imagePath = "";

      this.props.updateConfig(tempState, this.props.pluginId);
    };

    post(url, requestBody, successFunc, errorFunc);
  };

  render() {
    let imageName = "";

    if (this.state.imageName) {
      imageName = this.state.imageName.split("\\");
      imageName = imageName[imageName.length - 1];
    }

    return (
      <div style={{ color: "#505050" }}>
        <ReturnToTheme
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          preservedConfigFieldsArray={this.props.plugin.preservedConfigFields ? Array.from(this.props.plugin.preservedConfigFields) : []}
        />

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.GeneralAppearance")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.LinkURL")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"linkUrl"}
              defaultValue={this.state.linkUrl}
              value={this.state.linkUrl}
              onChange={this.handleChange}
            />
          </Col>
        </Row>

        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ImageUpload")}
            </Text>
          </Col>
          <Col span={12} style={{ textAlign: "left" }}>
            <label className={"upload-button"}>
              {" "}
              {i18n.t("Dashboard.Configuration.Fields.Browse")}
              <input
                type="file"
                id={"imageUpload"}
                className={"image-upload"}
                onChange={e => this.onChangeFile(e)}
              ></input>
            </label>
            {imageName === "" ? (
              i18n.t("Dashboard.Configuration.Fields.NoFileSelected")
            ) : (
              <span title={imageName} className={"upload-image-name"}>
                {imageName}
              </span>
            )}
          </Col>
        </Row>

        {imageName === "" ? null : (
          <Row>
            <Col
              span={12}
              style={{
                border: "1px solid #e6e6e6",
                borderRadius: "5px"
              }}
            >
              <img
                style={{
                  maxWidth: "100%",
                  maxHeight: "100px"
                }}
                src={this.state.imagePath}
              />
              { }
            </Col>
            <Col span={12}>Url: {this.state.imagePath}</Col>
          </Row>
        )}
      </div>
    );
  }
}
