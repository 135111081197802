import React, { Component } from "react";
import { Card, Row, Col } from "antd";
import RolesUserCard from "./RolesUserCard";
import RoleList from "./RoleList";
import UserList from "./UserList";

class AssignPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listRoles: true,
      userData: []
    };
  }

  selectedUsersData = [];

  confirmUsersByRole = data => {
    this.setState({
      userData: {
        role: data.role,
        users: data.users.map(item => ({ ...item }))
      },
      listRoles: this.state.listRoles
    });
  };

  render() {
    const roleList = <RoleList confirm={this.confirmUsersByRole} />;
    const userList = <UserList selectedUsers={this.state.userData} />;

    return (
      <div id="assignPage">
        <Card title="Assign Roles to Users" bordered={false}>
          <Row gutter={16}>
            <Col span={12}>
              <RolesUserCard
                title={"Roles"}
                content={roleList}
                list={this.state.listRoles}
              />
            </Col>
            <Col span={12}>
              <RolesUserCard
                title={"Users"}
                content={userList}
                list={this.state.listRoles}
              />
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

export default AssignPage;
