import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import PopupContainer from "./PopupContainer/PopupContainer";
import { Popconfirm } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons"
import Tooltip from "../GeneralComponents/Tooltip/Tooltip";

const typeOfButtons = {
  roundedButton: React.forwardRef(({ children, onClick }, ref) => (
    <a
      href="javascript:;"
      ref={ref}
      class="btn btn-new"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  )),

  circleButton: React.forwardRef(({ children, onClick }, ref) => (
    <a
      href="javascript:;"
      ref={ref}
      class="btn btn-rounded btn-new"
      style={{ padding: "3px 0", textAlign: "center", fontSize: "18px" }}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  )),

  singleIcon: React.forwardRef(({ children, onClick }, ref) => (
    <a
      href="javascript:;"
      ref={ref}
      style={{ color: "#474747" }}
      class={"default"}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  )),

  transparentIcon: React.forwardRef(({ children, onClick }, ref) => (
    <a
      href="javascript:;"
      ref={ref}
      class="btn btn-outline-light btn-sm"
      style={{ borderRadius: "3px" }}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  )),

  blueButton: React.forwardRef(({ children, onClick }, ref) => (
    <a
      href="javascript:;"
      ref={ref}
      class="btn btn-rounded blue-button"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <span style={{ fontWeight: "bold" }}>{children}</span>
    </a>
  ))
};

class DropdownMenu extends Component {
  onSelectDropdown = (eventKey, obj) => {
    let event = eventKey;
    if (this.props.clickField != null) {
      let fields = this.props.clickField.split("|");
      event = {};
      eventKey = JSON.parse(eventKey);
      for (let i = 0; i < fields.length; i++) {
        event[fields[i]] = eventKey[fields[i]];
      }
    }

    if (this.props.report) {
      event["report"] = this.props.report;
    }

    this.props.responseFunc({ event: event, obj: obj });
  };

  /*
  * Returns icons sort area
  */
  returnIcons = (icons) => {
    if (Array.isArray(icons)) {
      return (
        <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "10%"}}>
          {
            icons.map((icon) => {
              return <i className={icon.icon} style={icons.length > 1 ? {fontSize: "13px", marginLeft: icon !== icons[0] ? "1px" : null} : null}></i>
            })
          }
        </div>
      );
    } else {
      return <i class={icons}></i>;
    }
  };

  render() {
    return (
      <Dropdown
        autoClose={this.props.autoClose}
        show={this.props.show}
        className={this.props.className}
        id={this.props.id}
        drop={this.props.drop ? this.props.drop : "right"}
        style={{
          display: "inline-block",
          float: this.props.pull == "right" ? "right" : null,
        }}
        onToggle={this.props.onToggle}
        onSelect={(e, label) =>
          this.onSelectDropdown(e, this.props.requestObject)
        }
      >
        <Tooltip tooltip={this.props.tooltip}>
          <div style={{ display: "inline-block"}}>
            <Dropdown.Toggle
              variant={this.props.variant}
              as={typeOfButtons[this.props.toggleType]}
              id={this.props.prefix + " &nbsp;&nbsp;" + this.props.toggle.title}
            >
              <div
                id={this.props.toggle.id}
                style={{ display: "inline-block" }}
              >
                { this.props.toggle.iconPosition === "right" ? this.props.toggle.title + " " : null }

                {
                  this.props.toggle.icon instanceof Array ? this.props.toggle.icon.map(i => (
                    <i
                      className={ i.icon }
                      style={{ margin: "0 5px" }} 
                    />
                  )) : <i class={ this.props.toggle.icon } />
                }

                { this.props.toggle.iconPosition !== "right" ? " " + this.props.toggle.title : null }
              </div>
            </Dropdown.Toggle>
          </div>
        </Tooltip>
        <Dropdown.Menu>
          <PopupContainer
            before={this.props.before}
            width={this.props.width}
            position={this.props.position}
            height={this.props.height}
          >
            {this.props.data.map((d) => {
              return (
                <Dropdown.Item
                  eventKey={
                    this.props.clickField == null ? d.title : JSON.stringify(d)
                  }
                  id={d.id}
                  href={d.href ? d.href : "javascript:;"}
                  active={
                    this.props.selectedList
                      ? this.props.selectedList.includes(d.id)
                      : d.active
                      ? d.active
                      : false
                  }
                >
                  <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                    {this.returnIcons(d.icon)} &nbsp;{" "}
                    {this.props.showField == null
                      ? d.title
                      : d[this.props.showField]}
                  </div>
                </Dropdown.Item>
              );
            })}
          </PopupContainer>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default DropdownMenu;
