import React, { Component } from "react";

export default class Text extends Component {
  render() {
    switch (this.props.type) {
      case "span":
        return (
          <span id={this.props.id} title={this.props.title} onClick={this.props.onClick} onDoubleClick={this.props.onDoubleClick} style={this.props.style} className={this.props.className}>
            {this.props.children}
          </span>
        );

      case "p":
        return (
          <p
            title={this.props.title}
            onClick={this.props.onClick}
            onDoubleClick={this.props.onDoubleClick}
            style={this.props.style}
            className={this.props.className}
          >
            {this.props.children}
          </p>
        );

      case "h1":
        return (
          <h1 id={this.props.id} title={this.props.title} onClick={this.props.onClick} onDoubleClick={this.props.onDoubleClick} style={this.props.style} className={this.props.className}>
            {this.props.children}
          </h1>
        );

      case "h2":
        return (
          <h2 id={this.props.id} title={this.props.title} onClick={this.props.onClick} onDoubleClick={this.props.onDoubleClick} style={this.props.style} className={this.props.className}>
            {this.props.children}
          </h2>
        );

      case "h3":
        return (
          <h3 id={this.props.id} title={this.props.title} onClick={this.props.onClick} onDoubleClick={this.props.onDoubleClick} style={this.props.style} className={this.props.className}>
            {this.props.children}
          </h3>
        );

      case "h4":
        return (
          <h4 id={this.props.id} title={this.props.title} onClick={this.props.onClick} onDoubleClick={this.props.onDoubleClick} style={this.props.style} className={this.props.className}>
            {this.props.children}
          </h4>
        );

      case "h5":
        return (
          <h5
            id={this.props.id}
            title={this.props.title}
            onClick={this.props.onClick}
            onDoubleClick={this.props.onDoubleClick}
            style={this.props.style}
            className={this.props.className}
          >
            {this.props.children}
          </h5>
        );

      case "h6":
        return (
          <h6
            id={this.props.id}
            title={this.props.title}
            onClick={this.props.onClick}
            onDoubleClick={this.props.onDoubleClick}
            style={this.props.style}
            className={this.props.className}
          >
            {this.props.children}
          </h6>
        );

      case "label":
        return (
          <label 
            id={this.props.id}
            title={this.props.title}
            onClick={this.props.onClick}
            onDoubleClick={this.props.onDoubleClick}
            style={this.props.style}
            className={this.props.className}
          >
            {this.props.children}
          </label>
        );

      default:
        return (
          <span id={this.props.id} title={this.props.title} onClick={this.props.onClick} onDoubleClick={this.props.onDoubleClick} style={this.props.style} className={this.props.className}>
            {this.props.children}
          </span>
        );
    }
  }
}
