import uuid from "react-uuid";
import { store } from "../../..";
import { deepCopy } from "../../../Utils/Global";
import { changeRightClickedPlugin, closePluginCopyPastePopup } from "../ContextMenu/ContextMenuAction";
import { autoInteractionPlugins, exceptAutoInteractionPlugins } from "../../../config";

/*
* Reformats copied plugin conditional format column
*/
export const reFormatConditional = (rule, columnHashMap, drilldownKeysHashMap) => {
  let n;
  let m;
  let regexColsForLeftRule = []
  let regexColsForLeftRuleHTML = []
  let changedRuleColToBeUsedForSearch = []
  let changedRule = rule.rule
  let ruleHTML = rule.ruleHTML
  let changedLeftHTMLRule = rule.ruleHTML
  let regexForRule = /{([0-9a-zA-Z_]+)}/gm;
  let regexForHTML = new RegExp('(?<=columnid=")([^\\s]+)(?="><)', '')
  /*
  * for leftRule's column to be used for search array
  */
  for (let j = 0; j < rule?.columnsToBeUsedForSearch?.length; j++) {
    let colToBeUsedForSearch = rule.columnsToBeUsedForSearch[j]

    colToBeUsedForSearch.uniqeColumnId = columnHashMap.has(colToBeUsedForSearch.uniqeColumnId) ? columnHashMap.get(colToBeUsedForSearch.uniqeColumnId) : drilldownKeysHashMap.get(colToBeUsedForSearch.uniqeColumnId)

    changedRuleColToBeUsedForSearch.push(colToBeUsedForSearch)
  }

  /*
  * for leftRule's rule changes
  */
  while ((m = regexForRule.exec(rule.rule)) !== null) {
    if (m.index === regexForRule.lastIndex) {
      regexForRule.lastIndex++;
    }

    m.forEach((match, groupIndex) => {
      if (groupIndex === 1) regexColsForLeftRule.push(match.replaceAll("_", "-"))
    });
  }

  /*
  * for leftRule's rule changes
  */
  for (let j = 0; j < regexColsForLeftRule.length; j++) {
    let gettedId = columnHashMap.has(regexColsForLeftRule[j]) ? columnHashMap.get(regexColsForLeftRule[j]) : drilldownKeysHashMap.get(regexColsForLeftRule[j])

    if (gettedId) {
      changedRule = changedRule.replaceAll(regexColsForLeftRule[j].replaceAll("-", "_"), gettedId.replaceAll("-", "_"))
    }
  }

  if ((n = regexForHTML.exec(ruleHTML)) !== null) {
    n.forEach((match, groupIndex) => {
      if (groupIndex === 1) {
        regexColsForLeftRuleHTML.push(match.replaceAll("_", "-"))
      }
    });
  }

  for (let j = 0; j < regexColsForLeftRuleHTML.length; j++) {
    let gettedId = columnHashMap.has(regexColsForLeftRuleHTML[j]) ? columnHashMap.get(regexColsForLeftRuleHTML[j]) : drilldownKeysHashMap.get(regexColsForLeftRuleHTML[j])

    if (gettedId) {
      changedLeftHTMLRule = changedLeftHTMLRule.replaceAll(regexColsForLeftRuleHTML[j], gettedId)
    }
  }

  return {
    ...rule,
    rule: changedRule,
    columnsToBeUsedForSearch: changedRuleColToBeUsedForSearch,
    ruleHTML: changedLeftHTMLRule
  }
}

/*
* Reformats copied plugin conditional format target column
*/
export const reFormatTargetColumnsForConditionalFormatting = (columns, columnHashMap, drilldownKeysHashMap) => {
  let copiedColumns = deepCopy(columns)

  for (let i = 0; i < copiedColumns.length; i++) {
    copiedColumns[i].uniqeColumnId = columnHashMap.has(copiedColumns[i].uniqeColumnId) ? columnHashMap.get(copiedColumns[i].uniqeColumnId) : drilldownKeysHashMap.get(copiedColumns[i].uniqeColumnId)
  }

  return copiedColumns
}

/**
 * Get copied plugin object from local storage
 * 
 * @returns 
 */
export const getCopiedPluginObj = () => {
  let copiedPluginObj = localStorage.getItem("copiedPlugin");

  try {
    copiedPluginObj = JSON.parse(copiedPluginObj);

    if (copiedPluginObj?.plugin && copiedPluginObj.plugin.preservedConfigFields === undefined) {
      copiedPluginObj.plugin.preservedConfigFields = new Set();
    } else if (typeof copiedPluginObj?.plugin?.preservedConfigFields === "string") {
      let preservedConfigFields = copiedPluginObj.plugin.preservedConfigFields;
      let setString = preservedConfigFields.slice(4, -1);
      
      preservedConfigFields = new Set(setString.split(','));
      copiedPluginObj.plugin.preservedConfigFields = preservedConfigFields;
    } else if (typeof copiedPluginObj?.plugin?.preservedConfigFields === "object" && Object.keys(copiedPluginObj?.plugin?.preservedConfigFields).length === 0) {
      copiedPluginObj.plugin.preservedConfigFields = new Set()
    } 

  } catch {
    copiedPluginObj = {};
  }

  return copiedPluginObj;
}

/*
  * Paste plugin function and processes
  */
export const pastePlugin = (interactions = [], pluginId = "", interactionSelection = true, drilldownSelection = true, navigationSelection = true, defaultFilterSelection = true, conditionalFormatSelection = true) => {
  let reduxState = store.getState()
  let copiedPluginObj = getCopiedPluginObj();
  let copiedPluginState = reduxState.ContextMenuReducer
  let pasteCopiedPlugin = copiedPluginState.addPluginFunction
  let plugin = !pluginId ? copiedPluginObj?.plugin : reduxState.PluginTriggerReducer.plugins.get(pluginId)
  let copiedPlugin = deepCopy(plugin)

  if (copiedPlugin) {
    let copiedPluginColumnMap = copiedPlugin.columnMap || {}
    let columnMapKeys = Object.keys(copiedPluginColumnMap)
    let columnMapColumnsWithOldId = new Map()
    let conditionalFormats = deepCopy(copiedPlugin.conditionalFormats)
    let copiedNavigations = copiedPlugin.navigations
    let columnHashMap = new Map()
    let oldPluginId = copiedPlugin.id
    let newPluginId = uuid();
    let mergedInteractionObject = deepCopy(interactions)
    let oldPluginInteractions = interactions.filter(interaction => interaction.sourceId === oldPluginId)

    /*
    * Starts of interaction processes
    */
    for (let colKey = 0; colKey < columnMapKeys.length; colKey++) {
      for (let colKeyData = 0; colKeyData < copiedPluginColumnMap[columnMapKeys[colKey]].data.length; colKeyData++) {
        let column = copiedPluginColumnMap[columnMapKeys[colKey]].data[colKeyData]
        let oldColumnId = column.uniqeColumnId
        let newColumnId = uuid();

        columnMapColumnsWithOldId.set(oldColumnId, column)
        columnHashMap.set(oldColumnId, newColumnId)

        copiedPlugin.columnMap[columnMapKeys[colKey]].data[colKeyData]["uniqeColumnId"] = newColumnId
      }
    }

    if (interactionSelection) {
      if (oldPluginInteractions.length > 0) {
        let filteredInteractions = deepCopy(oldPluginInteractions)

        for (let i = 0; i < filteredInteractions.length; i++) {
          let copiedInteraction = filteredInteractions[i]

          copiedInteraction.sourceId = newPluginId

          for (let i = 0; i < copiedInteraction.columns.length; i++) {
            let col = copiedInteraction.columns[i]
            let colId = copiedInteraction.columns[i].uniqeColumnId

            col["uniqeColumnId"] = columnHashMap.get(colId)
          }

          mergedInteractionObject.push(copiedInteraction)
        }
      }
    } else {
      copiedPlugin["removedInteractions"] = []

      Array.from(reduxState.PluginTriggerReducer.plugins.values()).map(p => {
        if (
          !autoInteractionPlugins.has(p.key) &&
          !exceptAutoInteractionPlugins.has(p.key)
        ) {
          copiedPlugin.removedInteractions.push(p.id);
        }
      });
    }

    if (copiedPlugin.sortedColumnList) {
      for (let i = 0; i < copiedPlugin.sortedColumnList.length; i++) {
        let sortedCol = copiedPlugin.sortedColumnList[i]

        sortedCol["uniqeColumnId"] = columnHashMap.get(sortedCol["uniqeColumnId"])
      }
    }

    /*
    * Starts of drilldown processes
    */
    let drilldown = reduxState.DrillDownReducer.drillDowns.has(oldPluginId) ? reduxState.DrillDownReducer.drillDowns.get(oldPluginId) : copiedPluginObj?.drillDowns || null;
    let drilldownKeysHashMap = new Map()

    if (drilldown !== null && drilldownSelection) {
      for (let field of Object.keys(drilldown)) {
        try {
          let newField = new Map();

          drilldown[field] = JSON.parse(drilldown[field]);

          for (let [key, value] of Object.entries(drilldown[field])) {
            newField.set(key, deepCopy(value));
          }

          drilldown[field] = newField;
        } catch {
          continue;
        }
      }

      let copiedDrilldown = deepCopy(drilldown)
      let drilldownKeys = Array.from(copiedDrilldown.allDrillDownColumnsInPlugin?.keys())
      let allDrillDownColumnsInPluginMap = new Map()
      let preserveDefaultFilters = new Map()

      for (let i = 0; i < drilldownKeys.length; i++) {
        let oldDrillId = drilldownKeys[i]
        let newDrillId = columnHashMap.has(drilldownKeys[i]) ? columnHashMap.get(drilldownKeys[i]) : uuid()

        let oldDrillObj = copiedDrilldown.allDrillDownColumnsInPlugin.get(oldDrillId)

        if (copiedDrilldown.preserveDefaultFilters instanceof Map && copiedDrilldown.preserveDefaultFilters.has(oldDrillId)) {
          let oldPreserveDefaultFilters = copiedDrilldown.preserveDefaultFilters.get(oldDrillId)

          preserveDefaultFilters.set(newDrillId, oldPreserveDefaultFilters)
        }

        oldDrillObj["uniqeColumnId"] = newDrillId
        oldDrillObj["drillDownParentColumnId"] = columnHashMap.get(oldDrillObj.drillDownParentColumnId)

        allDrillDownColumnsInPluginMap.set(newDrillId, oldDrillObj)

        drilldownKeysHashMap.set(oldDrillId, newDrillId)

      }

      let drillDownColumnsForParentColumnsCopy = deepCopy(copiedDrilldown.drillDownColumnsForParentColumns)
      let drillDownParentColumnsKey = Array.from(drillDownColumnsForParentColumnsCopy.keys())
      let newDrilldownParentsColumn = new Map()

      for (let i = 0; i < drillDownParentColumnsKey.length; i++) {
        let drilldownChildColumns = drillDownColumnsForParentColumnsCopy.get(drillDownParentColumnsKey[i])
        let childColumnsArr = []

        for (let j = 0; j < drilldownChildColumns.length; j++) {
          let childCol = drilldownChildColumns[j]

          childCol["uniqeColumnId"] = drilldownKeysHashMap.get(childCol["uniqeColumnId"])
          childCol["drillDownParentColumnId"] = columnHashMap.get(childCol.drillDownParentColumnId)

          childColumnsArr.push(childCol)
        }

        newDrilldownParentsColumn.set(columnHashMap.get(drillDownParentColumnsKey[i]), childColumnsArr)
      }

      let copiedDrilldownLayerMap = deepCopy(copiedDrilldown.drillDownLayerMap)
      let copiedDrillKeys = Array.from(copiedDrilldownLayerMap.keys())
      let newDrilldownLayerMap = new Map()

      for (let i = 0; i < copiedDrillKeys.length; i++) {
        let layer = copiedDrilldownLayerMap.get(copiedDrillKeys[i])
        let layerAliasName = copiedDrillKeys[i].split("#")[0]
        let id = columnHashMap.has(copiedDrillKeys[i].split("#")[1]) ? columnHashMap.get(copiedDrillKeys[i].split("#")[1]) : drilldownKeysHashMap.get(copiedDrillKeys[i].split("#")[1])
        let key = layerAliasName + "#" + id

        layer["nextDrillDownColumnId"] = columnHashMap.has(layer["nextDrillDownColumnId"]) ? columnHashMap.get(layer["nextDrillDownColumnId"]) : drilldownKeysHashMap.get(layer["nextDrillDownColumnId"])
        layer["parentColumnId"] = columnHashMap.get(layer["parentColumnId"])
        layer["hasDrillDownOperation"] = false

        newDrilldownLayerMap.set(key, layer)
      }

      let drilldownTypes = copiedDrilldown.drillDownTypes

      let newDrillObj = {
        allDrillDownColumnsInPlugin: allDrillDownColumnsInPluginMap,
        drillDownColumnsForParentColumns: newDrilldownParentsColumn,
        drillDownLayerMap: newDrilldownLayerMap,
        preserveDefaultFilters: preserveDefaultFilters,
        drillDownTypes: drilldownTypes
      }

      reduxState.DrillDownReducer.drillDowns.set(newPluginId, newDrillObj);
    } else {
      copiedPlugin["drilldownProcesses"] = false
    }

    /*
    * Start of Conditional Formatting processes
    */
    if (conditionalFormats && conditionalFormats.length > 0 && conditionalFormatSelection) {
      for (let i = 0; i < conditionalFormats.length; i++) {
        let condFormat = conditionalFormats[i]
        let changedCondFormat = deepCopy(condFormat)
        let rule = changedCondFormat.rule

        changedCondFormat["id"] = uuid()

        changedCondFormat.rule.leftRule = reFormatConditional(rule.leftRule, columnHashMap, drilldownKeysHashMap)
        changedCondFormat.rule.rightRule = reFormatConditional(rule.rightRule, columnHashMap, drilldownKeysHashMap)
        changedCondFormat.targetColumns = reFormatTargetColumnsForConditionalFormatting(changedCondFormat.targetColumns, columnHashMap, drilldownKeysHashMap)

        copiedPlugin.conditionalFormats[i] = changedCondFormat
      }
    } else {
      copiedPlugin.conditionalFormats = []
    }

    /*
    * Starts of Navigation Processes 
    */
    if (copiedNavigations && copiedNavigations.length > 0 && navigationSelection) {
      for (let i = 0; i < copiedNavigations.length; i++) {
        let perNavigation = deepCopy(copiedNavigations[i])
        let navigationDashboardInformation = perNavigation.dashboardInformation
        let navigationColumnMap = reFormatTargetColumnsForConditionalFormatting(navigationDashboardInformation.columns, columnHashMap, drilldownKeysHashMap)

        copiedPlugin.navigations[i].dashboardInformation.columns = navigationColumnMap
      }
    } else {
      copiedPlugin.navigations = []
    }

    if (copiedPlugin.edittedInteractions) {
      for (let i = 0; i < Object.keys(copiedPlugin.edittedInteractions).length; i++) {
        copiedPlugin.edittedInteractions[Object.keys(copiedPlugin.edittedInteractions)[i]].sourceId = newPluginId
      }
    }

    copiedPlugin["id"] = newPluginId

    delete copiedPlugin["data"];
    delete copiedPlugin["x"];
    delete copiedPlugin["y"];

    /*
    * Removes default filter selection
    */
    if (!defaultFilterSelection) {
      copiedPlugin["defaultFilters"] = []
    }

    pasteCopiedPlugin(copiedPlugin, mergedInteractionObject)

    document.documentElement.style.overscrollBehavior = "";
    document.documentElement.style.touchAction = "";
    document.documentElement.style.overflow = "";

    document.body.style.touchAction = "";
    document.body.style.overscrollBehavior = "";
    document.body.style.overflow = "";

    store.dispatch(changeRightClickedPlugin(""))
    store.dispatch(closePluginCopyPastePopup(false))
  }
}