import React, { Component } from "react";
import $ from "jquery";
import { del, get, put } from "../../Utils/WebService";
import { API_BASE, BASE_URL_UI, BASE_URL_REPORTS, FAVORITE_LABEL, excludeViewCountLabels } from "../../config";
import { dateBeforeCalculation } from "../../Utils/Global";
import { copyToClipboard } from "../../Utils/CopyToClipboard";
import { showNotificationWithIcon } from "../../Utils/Notification";
import { Popconfirm } from "antd";
import PopupContainer from "../GeneralComponents/PopupContainer/PopupContainer";
import { addToFavorite, removeFromFavorite } from "./FavoriteService";
import { navigate } from "../../Utils/Global";
import i18n from "../../Utils/i18next";
import { sendDataToGoogleAnalytics } from "../../Utils/GoogleAnalytics";
import reportThumbnail from "../../images/report-thumbnail.png";
import { isValidWriteRoles } from "./RoleStore";
import GenerateReport from "./GenerateReport/GenerateReport";

class Report extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isThreePointVisible: false,
      showDetails: false,
      showPopUp: false,
      isFavorite: false,
      visibleModal: false
    };
    this.wrapperRef = React.createRef();
  }

  componentWillMount() {
    if (this.props.data) {
      if (this.props.data.labels && this.props.data.labels.length > 0) {
        let isFavorite = this.props.data.labels.filter(x => x.name == FAVORITE_LABEL).length > 0

        this.setState({
          ...this.state,
          isFavorite: isFavorite,
          thumbnail: this.props.data?.thumbnail,
          customThumbnail: this.props.data?.customThumbnail
        })
      }
    }
  }

  /**
   * detect favorite labels and if its favorite set isFavorite as true
   * @param {*} nextProps
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.setState({
        ...this.state,
        isFavorite:
          nextProps.data.labels.filter(x => x.name === FAVORITE_LABEL).length > 0
      });
    }
  }

  /**
   * If click outside hide isthreetoggle popupcontainer area.
   */
  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        isThreePointVisible: !this.state.isThreePointVisible,
      });

      if (!this.state.isThreePointVisible) {
        this.removeEventListener();
      }
    }
  };

  removeEventListener = () => {
    document.removeEventListener("click", this.handleClickOutside);
  };

  moreList = [
    { title: i18n.t("Delete"), function: "delete" },
    //{ title: i18n.t("Public"), function: "public" },
    { title: i18n.t("CopyLink"), function: "copyLink" }
  ];

  /**
   * handle delete, make public and get copy link actions and redirect them to appropriate functions.
   * @param {*} data is dashboard
   */
  moreRedirectFunction = data => {
    switch (data.event.function) {
      case "delete":
        this.onDelete(data.event.report);
        break;
      case "public":
        this.onPublic(data.event.report);
        break;
      case "copyLink":
        this.onCopyLink(data.event.report);
        break;
      default:
        break;
    }
  };

  /**
   * hande favorite action save it to the db by api call if it has succeeded call refresh function from props
   */
  clickFavorite = () => {
    let successText = "";
    const successFunc = () => {
      showNotificationWithIcon(i18n.t("Success"), successText, "success");
      this.props.refresh(true);
    };

    if (this.state.isFavorite) {
      successText = i18n.t("Dashboard.DashboardRemovedFromFavorite");
      removeFromFavorite(this.props.data.id, successFunc);
    } else {
      successText = i18n.t("Dashboard.DashboardAddedToFavorite");
      addToFavorite(this.props.data.id, successFunc);
    }
  };

  /**
   * If click delete button on popUpContainer, call this funtion
   */
  popUpOpen = () => {
    this.setState({
      showDetails: true,
      isThreePointVisible: false,
      showPopUp: true,
    });
  };

  /**
   * If click popConfirm's "No" button, call this funtion
   */
  popConfirmCancel = () => {
    this.removeEventListener("clickOutOfReport");
    this.removeEventListener("clickOutOfPopConfirm");

    document.addEventListener("click", this.handleClickOutside);

    this.setState({
      showPopUp: false,
      isThreePointVisible: true,
    });
  };

  /**
   * On change visible of popConfirm, call this function
   */
  popConfirmVisibleChange = () => {
    this.removeEventListener("clickOutOfReport");

    document.addEventListener("click", this.handleClickOutsidePopUp);

    this.setState({
      showPopUp: !this.state.showPopUp,
    });
  };

  /**
   * If click outside of popConfirm, hide popconfirm or click on popupconfirm protect popconfirmn status
   */
  handleClickOutsidePopUp = (event) => {
    this.removeEventListener("clickOutOfReport");
    this.removeEventListener("clickOutOfPopConfirm");

    if ($(event.target).closest(".plugin-delete-popconfirm").length >= 1) {
      this.setState({
        isThreePointVisible: false,
        showDetails: true,
        showPopUp: true,
      });
    } else if (
      $(event.target).closest(".plugin-delete-popconfirm").length === 0
    ) {
      this.setState({
        isThreePointVisible: true,
        showDetails: true,
        showPopUp: false,
      });

      document.addEventListener("click", this.handleClickOutside);
    } else if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      document.addEventListener("click", this.handleClickOutside);
    }
  };

  /**
   * If click outside hide isthreetoggle popupcontainer area.
   */
  handleClickOutside = (event) => {
    if ($(event.target).closest(this.wrapperRef).length === 0) {
      this.setState({
        isThreePointVisible: !this.state.isThreePointVisible,
        showDetails: !this.state.showDetails,
      });

      if (!this.state.isThreePointVisible) {
        this.removeEventListener("clickOutOfReport");
      }
    }
  };

  /**
   * Remove event listener by type
   */
  removeEventListener = (e) => {
    switch (e) {
      case "clickOutOfReport":
        document.removeEventListener("click", this.handleClickOutside);
        break;
      case "clickOutOfPopConfirm":
        document.removeEventListener("click", this.handleClickOutsidePopUp);
        break;
    }
  };

  onPublic = () => {
    console.log("public");
  };

  /**
   * create dashboard link and call copyToClipboard function
   * @param {*} data is dashboard
   */
  onCopyLink = data => {
    let link = window.location.hostname + BASE_URL_UI + "/dashboard/" + data.id;

    if (data.type === "report") {
      link = window.location.hostname + BASE_URL_REPORTS + "/designer/" + data.id
    }

    copyToClipboard(link);
    showNotificationWithIcon(
      i18n.t("Success"),
      i18n.t("Dashboard.LinkCopiedOnClipboard"),
      "success"
    );
  };

  /**
   * calculate dashboard preview popup position and call selectReport which will set the calculated position to the state
   * @param {*} event is dashboard
   */
  selectReport = event => {
    let container = document.querySelector('.container-fluid');
    let offset = $(event.target).offset();
    let left = offset.left + 80;
    let top = offset.top + container.scrollTop - 65
    let windowHeight = window.innerHeight
    let lastRowElements = $(event.target).parents().hasClass("last-row")
    let owlCarouselControl = $(event.target).parents().hasClass("owl-carousel")
    let isFirstSection = $(event.target).parents().hasClass("first-child")
    let scrollEndControl = (windowHeight + container.scrollTop >= container.scrollHeight) && (lastRowElements || owlCarouselControl) && container.scrollTop > 0 && !isFirstSection

    if (left > $(document).width() / 2) {
      left =
        left -
        600 -
        $(event.target)
          .parent()
          .parent()
          .parent()
          .parent()
          .width() -
        53;
    }

    // Moves the popup up at the end of the screen
    if (scrollEndControl) {
      top = top - 323
    }

    $(".report").removeClass("active");

    $(event.target)
      .parent()
      .parent()
      .parent()
      .parent()
      .parent()
      .addClass("active");
    this.setState({
      isSelected: true
    });

   let mustViewCountDisable = excludeViewCountLabels.has(this.props.dataLabel.name) ? true : false

    this.props.selectReport(this.props.data, top, left, mustViewCountDisable);
  };

  /**
   * toggle three dot from dashboard
   */
  toggleThreePoints = () => {
    if (this.state.isThreePointVisible === false) {
      document.addEventListener("click", this.handleClickOutside);
    } else {
      this.removeEventListener("clickOutOfReport");
    }

    this.setState({
      showDetails: !this.state.showDetails,
      isThreePointVisible: !this.state.isThreePointVisible,
    });
  };

  /**
   * handle delete dashboard action delete from db by api call if it has succeeded call dashboardDelete function from props
   * @param {*} data
   */
  deleteDashboard = () => {
    let data = { ...this.props.data };
    let url = `${API_BASE}/dashboard/` + data.id;

    const successFunc = result => {
      showNotificationWithIcon(
        i18n.t("Success"),
        i18n.t("Dashboard.SuccessfullyDeleted"),
        "success"
      );

      this.setState({
        isThreePointVisible: false,
        showPopUp: false,
        showDetails: false,
      });

      let dashboard = { ...data };
      dashboard.deleted = true;

      this.props.dashboardDelete(dashboard);
    };

    del(url, null, successFunc);
  };

  /**
   * handle delete report action delete from db by api call if it has succeeded call dashboardDelete function from props
   * @param {*} data
   */
  deleteReport = () => {
    let data = { ...this.props.data };
    let url = `${API_BASE}/reports/` + data.id;
    let requestBody = { deleted: true };

    const successFunc = result => {
      showNotificationWithIcon(
        i18n.t("Success"),
        i18n.t("Dashboard.SuccessfullyDeleted"),
        "success"
      );

      this.setState({
        isThreePointVisible: false,
        showPopUp: false,
        showDetails: false,
      });

      let dashboard = { ...data };
      dashboard.deleted = true;

      this.props.dashboardDelete(dashboard);
    };

    put(url, requestBody, successFunc);
  };

  /**
   * Delete report or dashboard decider
   * @param {*} data 
   */
  onDelete = () => {
    if (this.props.data && this.props.data.type === "report") {
      this.deleteReport()
    } else {
      this.deleteDashboard()
    }
  }

  /**
   * Report's deleted field is set false.
   */
  recoverDashboard = () => {
    let data = { ...this.props.data };
    let url = `${API_BASE}/dashboard/` + data.id + "/recover";

    const successFunc = () => {
      showNotificationWithIcon(
        i18n.t("Success"),
        i18n.t("Dashboard.SuccessfullyReloaded"),
        "success"
      );

      let dashboard = { ...data };
      dashboard.deleted = false;

      this.props.dashboardReloaded(dashboard);
    };

    let dashboard = { ...data };
    dashboard.deleted = false;

    get(url, successFunc);
  }

  /**
   * Report's deleted field is set false.
   */
  recoverReport = () => {
    let data = { ...this.props.data };
    let url = `${API_BASE}/reports/` + data.id;
    let requestBody = { deleted: false };

    const successFunc = () => {
      showNotificationWithIcon(
        i18n.t("Success"),
        i18n.t("Dashboard.SuccessfullyReloaded"),
        "success"
      );

      let dashboard = { ...this.props.data };
      dashboard.deleted = false;

      this.props.dashboardReloaded(dashboard);
    };

    let dashboard = { ...this.props.data };
    dashboard.deleted = false;

    put(url, requestBody, successFunc);
  }

  /**
   * Reports or dashboards deleted field is set false.
   */
  onRecover = () => {
    if (this.props.data && this.props.data.type === "report") {
      this.recoverReport();
    } else {
      this.recoverDashboard();
    }
  };

  /**
   * Deletes dashboard from system permanently
   */
  deletePermanently = () => {
    let path = "dashboard";

    if (this.props.data.type === "report") {
      path = "reports";
    }

    let url = `${API_BASE}/${path}/${this.props.data.id}`;

    const successFunc = result => {
      showNotificationWithIcon(
        i18n.t("Success"),
        i18n.t("Dashboard.SuccessfullyPermanentlyDeleted"),
        "success"
      );

      this.toggleThreePoints();
      this.props.dashboardDeletedPermanently(this.props.data);
    };

    del(url, null, successFunc);
  };

  /**
   * go to dashboard page by click
   * @param {*} data is dashboard
   */
  goToDashboard = data => {
    //When user click one of dashboards, user datas sending to google.
    let dashboardTitle = data.settings.title;
    sendDataToGoogleAnalytics("Clicked Dashboard", dashboardTitle);

    navigate(
      BASE_URL_UI +
      "/dashboard/" +
      data.id +
      "?labelId=" +
      this.props.dataLabel.id
    );
  };

  /**
   * go to designer page by click
   * @param {*} data is dashboard
   */
  goToDesigner = data => {
    if (isValidWriteRoles()) {
      navigate(
        BASE_URL_REPORTS +
        "/designer/" +
        data.id
      )
    }
  };

  /*
  * return dashboard page url for report
  */
  linkToReport = (data) => {
    if (data) {
      if (data.type === "report") {
        let url = "javascript:;"

        if (isValidWriteRoles()) {
          url = BASE_URL_REPORTS + "/designer/" + data.id
        }

        return url;
      }

      return (BASE_URL_UI + "/dashboard/" + data.id + "?labelId=" + this.props.dataLabel.id);
    }
  }

  /**
   * Decides navigation url for dashboard and report according to data type
   * @param {*} data 
   */
  navigationFactory = (data) => {
    if (data.type === "report") {
      this.goToDesigner(data);
    } else {
      this.goToDashboard(data);
    }
  }

  /**
   * When VIEWER USER click on report details area this function opens generate popup Modal
   * @param {*} status
   */
  toggleGenerateModal = (status) => {
    this.setState({
      visibleModal: status
    })
  }

  render() {
    let thumbnail = "#f3f3f3";

    if (this.props.data.customThumbnail) {
      thumbnail = `url(/resource/api/resource/${this.props.data.customThumbnail}) center top / cover no-repeat`;
    } else if (this.props.data?.type === "report") {
      thumbnail = "url(" + reportThumbnail + ") center top / 150px 150px no-repeat";
    } else if (this.props.data.thumbnail) {
      thumbnail = `url(/resource/api/resource/${this.props.data.thumbnail}) center top / cover no-repeat`;
    }

    let viewCountIcon = "fa fa-eye";
    let viewCountText = i18n.t("LandingPage.DashboardFooter.Views");
    let isFavorable = true;
    let detailsStyle = {
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      zIndex: 1,
    };

    if (this.props.data && this.props.data.type === "report") {

      viewCountIcon = "fa fa-download";
      viewCountText = i18n.t("LandingPage.DashboardFooter.TimesGenerated");
      isFavorable = false;
    }

    if (excludeViewCountLabels.has(this.props.dataLabel.name)) {
      viewCountIcon = null;
      viewCountText = null;
    }

    return (
      <div className={"report"} ref={this.wrapperRef}>
        <div className="report-thumb">
          <div
            className="report-image"
            style={{ background: thumbnail }}
          >
            <div
              className="details"
              style={{
                top: this.state.showDetails ? "0px" : "",
              }}
            >
              {this.props.data.type === "report" && !isValidWriteRoles() ? (
                <div
                  onClick={() => this.toggleGenerateModal(true)}
                  style={detailsStyle}
                ></div>
              ) : (
                <a
                  style={detailsStyle}
                  href={this.linkToReport(this.props.data)}
                  onClick={() => this.navigationFactory(this.props.data)}
                ></a>
              )}
              <a
                href="javascript:;"
                className="btn btn-outline-light btn-sm preview-button show-on-mobile goDashboard"
                style={{ borderRadius: "3px", marginRight: "3px" }}
                onClick={() => this.navigationFactory(this.props.data)}
              >
                {i18n.t(`LandingPage.${this.props.data.type === "report" ? "GoReport" : "GoDashboard"}`)}
              </a>
              {
                this.props.data.deleted ? (
                  <div
                    className="details-buttons text-right"
                    style={{
                      position: "relative",
                      zIndex: 2,
                    }}
                  >
                    <Popconfirm
                      title={i18n.t("Dashboard.SureDeletePermanentlyDashboard")}
                      onConfirm={() => this.deletePermanently()}
                      onCancel={() => { }}
                      okText={i18n.t("Yes")}
                      cancelText={i18n.t("No")}
                      placement="bottom"
                    >
                      <a
                        href="javascript:;"
                        className="btn btn-outline-light btn-sm preview-button"
                        style={{ borderRadius: "3px", marginRight: "3px" }}
                      >
                        <i className="fa fa-trash"></i>
                      </a>
                    </Popconfirm>
                    <a
                      href="javascript:;"
                      className="btn btn-outline-light btn-sm preview-button"
                      style={{ borderRadius: "3px", marginRight: "3px" }}
                      onClick={this.onRecover}
                    >
                      <i className="fa fa-redo"></i>
                    </a>
                  </div>
                ) : null
              }

              <div
                className="details-buttons text-right"
                style={{
                  display: this.props.data.deleted === true ? "none" : "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  position: "relative",
                  zIndex: 2,
                  width: "100%"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left"
                  }}
                >
                  {isFavorable ?
                    <a
                      href="javascript:;"
                      onClick={this.clickFavorite}
                      title={
                        this.state.isFavorite
                          ? i18n.t("Dashboard.RemoveFromFavorite")
                          : i18n.t("Dashboard.AddToFavorite")
                      }
                      className={
                        this.state.isFavorite
                          ? "btn btn-outline-light btn-sm favorite-active-btn"
                          : "btn btn-outline-light btn-sm"
                      }
                      style={{ float: "left", borderRadius: "3px" }}
                    >
                      <i className="fa fa-star"></i>
                    </a>
                    : null
                  }
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right"
                  }}
                >
                  {
                    this.props.data.type === "report" ? (
                      <GenerateReport
                        reportId={this.props.data.id}
                        visibleModal={this.state.visibleModal}
                        toggleGenerateModal={this.toggleGenerateModal}
                      >
                        <a
                          href="javascript:;"
                          className="btn btn-outline-light btn-sm preview-button generate-button"
                          style={{ borderRadius: "3px", marginRight: "3px" }}
                        >
                          <i className="fa fa-download"></i>
                        </a>
                      </GenerateReport>
                    ) : null
                  }
                  <a
                    href="javascript:;"
                    className="btn btn-outline-light btn-sm preview-button hide-on-mobile"
                    style={{ borderRadius: "3px", marginRight: "3px" }}
                    onClick={this.selectReport}
                  >
                    <i className="fa fa-eye"></i>
                  </a>
                  <a
                    href="javascript:;"
                    className="btn btn-outline-light btn-sm dashboard-detail-button"
                    style={{ float: null, borderRadius: "3px" }}
                    onClick={this.toggleThreePoints}
                  >
                    <i className="fas fa-ellipsis-h"></i>
                  </a>
                  <div
                    style={{
                      visibility:
                        this.state.isThreePointVisible === true
                          ? "visible"
                          : "hidden"
                    }}
                  >
                    <PopupContainer
                      width="fit-content"
                      positionCalibration={"-37px"}
                    >
                      <div
                        style={{
                          textAlign: "left",
                          fontSize: 14,
                          lineHeight: 1.5,
                          marginTop: 4,
                          marginBottom: 4
                        }}
                      >
                        <a
                          href="javascript:;"
                          onClick={() => this.onCopyLink(this.props.data)}
                        >
                          <i className="fa fa-clipboard"></i> &nbsp;
                          <span>{i18n.t("CopyLink")}</span>
                        </a>
                      </div>
                      {/* <div
                        style={{
                          textAlign: "left",
                          fontSize: 14,
                          lineHeight: 1.5,
                          marginTop: 4,
                          marginBottom: 4
                        }}
                      >
                        <a href="javascript:;">
                          <i className="fa fa-globe"></i> &nbsp;
                          <span>{i18n.t("Public")}</span>
                        </a>
                      </div> */}
                      <div
                        style={{
                          textAlign: "left",
                          fontSize: 14,
                          lineHeight: 1.5,
                          marginTop: 4,
                          marginBottom: 4
                        }}
                      >
                        <Popconfirm
                          overlayClassName="plugin-delete-popconfirm"
                          title={i18n.t("Dashboard.SureDeleteDashboard")}
                          onConfirm={() => this.onDelete(this.props.data)}
                          onCancel={this.popConfirmCancel}
                          okText={i18n.t("Yes")}
                          cancelText={i18n.t("No")}
                          placement="bottom"
                          onVisibleChange={this.popConfirmVisibleChange}
                          visible={this.state.showPopUp}
                        >
                          {isValidWriteRoles() ?
                            <a href="javascript:;" onClick={this.popUpOpen}>
                              <i className="fa fa-trash"></i> &nbsp;
                              <span>{i18n.t("Delete")}</span>
                            </a> : null}
                        </Popconfirm>
                      </div>
                    </PopupContainer>
                  </div>
                </div>
              </div>
              <div className="details-inner"></div>
            </div>
          </div>
        </div>
        <div className="report-title" title={this.props.data.settings.title}>
          <span className={"text-shorten"}>
            {this.props.data.settings.title}
          </span>
          <span className="report-detail">
            <i className={viewCountIcon}></i> {viewCountIcon !== null ? this.props.data.viewCount : null} {viewCountText}
            <span>
              {" "}
              •{" "}
              {dateBeforeCalculation(
                this.props.data.lastUpdatedDate
                  ? this.props.data.lastUpdatedDate
                  : this.props.data.creationDate
              )}
            </span>
          </span>
        </div>
      </div>
    );
  }
}

export default Report;
