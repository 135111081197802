import { FilterOutlined, HomeOutlined, UserOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Col, Row, Table } from "antd";
import React, { Component } from "react";
import { tr } from "../../Utils/translation/tr";
import i18n from "../../Utils/i18next";
import PopupContainer from "../GeneralComponents/PopupContainer/PopupContainer";
import Text from "../GeneralComponents/Text/Text";
import Tooltip from "../GeneralComponents/Tooltip/Tooltip";
import { DiGitCompare } from "react-icons/di";

/**
 * Creates the headings of the columns for the table listing the filters.
 */
const withoutComparedColumns = [
  {
    
    title: i18n.t("Columns"),
    dataIndex: "displayName",
    key: "displayName",
    elipsis: true,
    render: displayName => (
      <Tooltip tooltipPlacement="topLeft" tooltip={displayName}>
        {displayName}
      </Tooltip>
    ),  
  },

  
  {
    title: i18n.t("Predicates"),
    dataIndex: "filterPredicate",
    key: "filterPredicate",
    elipsis: true,
    render: filterPredicate => (
      <Tooltip tooltipPlacement="topLeft"  tooltip={i18n.t("FilterShowPredicates." + filterPredicate)}>
          <span>
                {i18n.t("FilterShowPredicates." + filterPredicate)}
              </span>
      </Tooltip>
    ),
  },

  {
    title: i18n.t("Values"),
    dataIndex: "stringValue",
    key: "stringValue",
    elipsis: true,
    render: stringValue => (
      <Tooltip tooltipPlacement="topLeft" tooltip={stringValue}>
        {stringValue}
      </Tooltip>
    ),
  },
];

/**
 * Creates the headings of the columns for the table listing the filters.
 */
const comparedColumns = [
  {
    title: i18n.t("Columns"),
    dataIndex: "displayName",
    key: "displayName",
    elipsis: true,
    render: displayName => (
      <Tooltip tooltipPlacement="topLeft" tooltip={displayName}>
        {displayName}
      </Tooltip>
    ),  
  },
  {
    title: i18n.t("Values"),
    dataIndex: "stringValue",
    key: "stringValue",
    elipsis: true,
    render: stringValue => (
      <Tooltip tooltipPlacement="topLeft" tooltip={stringValue}>
        {stringValue}
        {}
      </Tooltip>
    ),
  },
  {
    title: "",
    dataIndex: "compared",
    key: "compared",
    elipsis: true,
    render: compared => (
      <>
        {compared ? <Tooltip tooltip={"Compare"}>
          <a
            href="javascript:;"
            style={{ position: "relative", top: "2px", marginRight: "4px" }}
          >
            <DiGitCompare style={{
              fontSize: "23px",
            }} />
          </a>
        </Tooltip> : ""}
      </>
    ),
  },
];

/**
 * Includes breadcrumb and filter list with navigation
 */
export default class NavigationHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filtersVisible: false,
    };
  }

  /**
   * Does the visibility change of the filter list
   */
  onChangeFiltersVisible = () => {
    this.setState({
      ...this.state,
      filtersVisible: !this.state.filtersVisible,
    });
  };

  render() {
    let isCompareAvailable = this.props.navigations[this.props.navigations?.length - 1]?.dashboardInformation?.filters?.filter(column => column.compared).length > 0
    let theme = this.props.themeObj?.topBar;

    return (
      <div class="dashboard-setting" style={{ display: "flex" }}>
        <Col span={18} style={{ marginTop: "8px" }}>
          <div
            className={"left-tools"}
            style={{ display: "inline-block" }}
          >
            <Breadcrumb separator=">">
              {this.props.navigations &&
                this.props.navigations.map((item, index) => (
                  <Breadcrumb.Item
                    className={
                      index === this.props.navigations.length - 1
                        ? "breadcrumb-last-item"
                        : "breadcrumb-item"
                    }
                    style={{ fontSize: "15px", textAlign: "center" }}
                    onClick={() =>
                      this.props.goToSelectedNavigation(item, index)
                    }
                  >
                    {item.dashboardInformation.title}
                  </Breadcrumb.Item>
                ))}
            </Breadcrumb>
          </div>
        </Col>
        <Col span={6}>
          <Button
            className="btn btn-new navigation-filters-used-button"
            tooltip={i18n.t("Navigation.FiltersUsed")}
            onClick={this.onChangeFiltersVisible}
          >
            <FilterOutlined style={{ fontSize: "20px" }} />
            <span style={{ marginLeft:"2px" }} className={"hide-on-mobile"}>{i18n.t("Navigation.FiltersUsed")}</span>
            <div
              style={{ display: this.state.filtersVisible ? "block" : "none" }}
            >
              <PopupContainer width="360px" position="left">
                <Text type={"h6"}>{i18n.t("Navigation.Filters")}</Text>
                <Table
                  className={"navigation-history-table"}
                  columns={isCompareAvailable ? comparedColumns : withoutComparedColumns}
                  pagination={false}
                  dataSource={this.props.navigations[this.props.navigations.length - 1].dashboardInformation.filters}
                  size="small"
                />
                <Button
                  id={"closeNavigationButton"}
                  style={{
                    position: "absolute",
                    top: "3px",
                    right: "3px",
                    borderRadius: "40px",
                    padding: "6px 11px",
                    backgroundColor: "white",
                    zIndex: 1,
                  }}
                  type={"default"}
                  onClick={this.props.closeLabels}
                >
                  X
                </Button>
              </PopupContainer>
            </div>
          </Button>
        </Col>
        <style>
            {
              theme && `
                .dashboard-setting .ant-breadcrumb-separator,
                .dashboard-setting .breadcrumb-last-item {
                  color: ${theme?.textColor}70;
                }

                .dashboard-setting .breadcrumb-item {
                  color: ${theme?.textColor}a0;
                }

                .dashboard-setting .breadcrumb-item:hover {
                  color: ${theme?.textColor};
                }

                .dashboard-setting .btn-new.navigation-filters-used-button {
                  color: ${theme.textColor};
                  background-color: ${theme.backgroundColor};
                  border-color: ${theme.textColor}33;
                }

                .dashboard-setting .btn-new.navigation-filters-used-button:hover,
                .dashboard-setting .btn-new.navigation-filters-used-button:focus,
                .dashboard-setting .btn-new.navigation-filters-used-button:active {
                  color: ${theme.backgroundColor};
                  background-color: ${theme.textColor};
                  border-color: ${theme.backgroundColor}33;
                  box-shadow: none;
                  -ms-box-shadow: none;
                }
              `
            }
        </style>
      </div>
    );
  }
}
