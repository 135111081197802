import React, { Component } from "react";
import { Tooltip } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import i18n from "../../../Utils/i18next"

/**
 * Plugin tools remove button component
 */
export default class ShowPluginToolsButton extends Component {

    render() {
        const classNameControl = this.props.iconsClassNameControl
        let isPluginHeightOne = this.props.isPluginHeightOne
        let isPluginWidthOne = this.props.isPluginWidthOne

        return (
            <>
                {
                    this.props.visibilityOfTooltipButtons ? <>
                        <Tooltip
                            title={this.props.showPluginTools
                                ? i18n.t("Dashboard.Configuration.Fields.CloseTooltip")
                                : i18n.t("Dashboard.Configuration.Fields.OpenTooltip")}>
                            <SettingOutlined
                                className={classNameControl(isPluginHeightOne, isPluginWidthOne)}
                                style={{
                                    cursor: "pointer",
                                    right: "0px",
                                    top: "0px",
                                    zIndex: "3",
                                    overflow: "hidden",
                                }}
                                onClick={() => this.props.pluginControl()}
                            />
                        </Tooltip></> : null
                }
            </>
        );
    }
}
