import React, { Component } from "react";
import { Image } from "react-bootstrap";
import logoImage from "../../images/logo.webp";
import { get, post } from "../../Utils/WebService";
import { showNotificationWithIcon } from "../../Utils/Notification";
import { navigate, createCookie, getIcon } from "../../Utils/Global";
import SearchResultArea from "../Search/SearchResultArea";
import $ from "jquery";
import { authenticatedUser, naviagateToLogin, removeAuthentication } from "../../Utils/Auth";
import UserMenu from "./UserMenu";
import { API_BASE, BASE_URL_ADMIN, fullViewWidth } from "../../config";
import Search from "../GeneralComponents/Search/Search";
import Cookies from "js-cookie";
import { getSystemRoles } from "../DashboardPage/RoleStore"
import MobileMenu from "./MobileMenu";
import { ArrowLeftOutlined } from "@ant-design/icons";
import i18n from "../../Utils/i18next";
import DashboardTitle from "../DashboardPage/DashboardTitle";
import HomeButton from "./HomeButton";
import { BASE_URL_UI } from "../../config.js";
import WebSocketApp from "../WebSocket/WebSocketApp";
import UserNotifications from "../UserNotifications/UserNotifications";
import DropdownMenu from "../GeneralComponents/DropdownMenu.jsx";
import { getContrastColor } from "../Plugins/PluginComponents/common.js";

class TopMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchResponse: [],
      searchCategory: {},
      logo: logoImage
    };
  }

  componentDidMount() {
    document.addEventListener('contextmenu', this.handleContextMenu, true);
  }

  componentWillMount() {
    this.setLogoIfCustomizationExist();
  }

  /** Set cookie customization if exist */
  setLogoIfCustomizationExist() {
    let customization = Cookies.get("loginCustomization")

    if (customization) {
      let resourceURL = `/resource/api/resource/unsecured/`;
      customization = JSON.parse(customization);

      let logo = customization.logoId !== null ? resourceURL + customization.logoId : logoImage

      this.setState({
        ...this.state,
        logo: logo
      })
    }
  }

  readOnlyMenu = [
    { title: i18n.t("Profile"), function: "profile", icon: "fa fa-user" },
    { title: i18n.t("Logout"), function: "logout", icon: "fas fa-power-off" },
  ];

  menu = [
    { id: "dashboardProfile", title: i18n.t("Profile"), function: "profile", icon: "fa fa-user" },
    { id: "dashboardAdminPage", href: BASE_URL_ADMIN, title: i18n.t("AdminPage"), function: "admin", icon: "fa fa-cog" },
    { id: "dashboardLogOut", title: i18n.t("Logout"), function: "logout", icon: "fas fa-power-off" },
  ];

  menuRedirectFunction = (data) => {
    switch (data.event.function) {
      case "profile":
        this.goProfile();
        break;
      case "admin":
        this.goToAdminPage();
        break;
      case "logout":
        this.logout();
        break;
      default:
        break;
    }
  };

  handleSearch = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value,
    });

    if (e.target.value.length > 2) {
      this.searchDashboard(e.target.value);
    } else {
      if (this.state.searchResponse != []) {
        this.setState({
          searchResponse: [],
        });

        $(".search-area").find("input").removeAttr("style");
      }
    }
  };

  searchDashboard = (data) => {
    let url = `/api/search/dashboard?s=` + data;

    const successFunc = (result) => {
      let searchResponse = [...result.data];

      if (this.state.searchCategory) {
        switch (this.state.searchCategory.name) {
          case "report":
            searchResponse = searchResponse.filter(item => item.type === "report");
            break;
          case "dashboard":
            searchResponse = searchResponse.filter(item => item.type !== "report");
            break;
          default:
            break;
        }
      }

      this.setState({
        searchResponse: searchResponse,
      });

      if (searchResponse.length > 0) {
        $(".search-area")
          .find("input")
          .css("border-bottom-left-radius", "0")
          .css("border-bottom-right-radius", "0");
      } else {
        $(".search-area").find("input").removeAttr("style");
      }
    };

    get(url, successFunc, undefined, false);
  };

  logout = (e) => {
    removeAuthentication();
    post("/security/logoff", null, () => naviagateToLogin(), (err) => naviagateToLogin())
  };

  goToAdminPage = (e) => {
    navigate(BASE_URL_ADMIN);
  };

  goProfile = () => {
  };

  openSearchForMobile = () => {
    this.setState({
      ...this.state,
      clickedSearch: true,
    })
  }

  closeSearchForMobile = () => {
    this.setState({
      ...this.state,
      clickedSearch: false,
    })
  }

  /** 
   * Sets selected search category
   */
  searchCategory = item => {
    this.setState({
      searchCategory: { ...item }
    });
  };


  searchOnFocus = (e) => {
    this.setState({
      ...this.state,
      clickedSearch: true,
    })
  }

  searchOnBlur = (e) => {
    let target = e.target;
    
    if (!$(target)?.closest(".top-menu-search")) {
      this.setState({
        ...this.state,
        clickedSearch: false,
      })
    }
  }
  
  //Allows to open context menu on top menu buttons and logo
  handleContextMenu = (e) => {
    let elementIds = ["vispeahenImage", "homeButton", "dashboardAdminPage"]
    let isButton = e.target.closest("a")
    let clickControl = isButton && elementIds.includes(isButton.id)
    
    if (clickControl) {
      document.getElementById(isButton.id).addEventListener('contextmenu', event => event.stopPropagation(), true);
    }
  }

  render() {
    let searchStyle = {};

    if (this.state.clickedSearch === true) {
      searchStyle.display = "block"
    }

    let theme = this.props.themeObj?.topBar;

    return (
      <section
        id="topMenu"
        class="header-section"
        style={{
          width: "100%",
        }}
      >
        <nav
          class="navbar navbar-expand-lg navbar-light dashboard-header"
          style={{
            flexWrap: "nowrap",
          }}
        >

            {
              theme?.showLogo !== false &&
              <a href={BASE_URL_UI}
                rel="noopener noreferrer"
                id="vispeahenImage"
                className="navbar-brand"
                style={{ marginLeft: "5px" }}>

              <Image
                src={this.state.logo}
                onClick={() => navigate(`/vispeahen/ui`)}
                className={this.state.clickedSearch === true && "hide-on-mobile"}
                style={{ cursor: "pointer" }}
              />
              </a>
            }

          <MobileMenu
            openSearchForMobile={this.openSearchForMobile}
            logout={this.logout}
            goProfile={this.goProfile}
            theme={theme}
            selectedLabels={this.props.selectedLabels}
            changeSelectedLabels={this.props.changeSelectedLabels}
            clickedSearch={this.state.clickedSearch}
            showLabels={this.props.showLabels}
          />

          {
            <div
              className={`top-menu-search`}
              style={searchStyle}
            >
              <div onClick={() => this.closeSearchForMobile()} className={"back-icon"}>
                <ArrowLeftOutlined />
              </div>

              <Search
                id={"searchData"}
                paddingNone={true}
                searchBarVisible={this.props.searchBarVisible}
                onChange={this.handleSearch}
                onFocus={this.searchOnFocus}
                searchItemList={[
                  { name: "dashboard", translate: "LandingPage.Dashboard", icon: "fas fa-th-large" },
                  { name: "report", translate: "LandingPage.Report", icon: "fas fa-file-alt" }
                ]}
                searchCategory={this.searchCategory}
                selectedSearchCategory={this.state.searchCategory}
                inputTimeout={300}
                iconStyle={{
                  zIndex: 2
                }}
              >
                {
                  this.state.clickedSearch &&
                  <SearchResultArea
                    data={this.state.searchResponse}
                    selectedSearchCategory={this.state.searchCategory}
                  />
                }
              </Search>

              {
                this.state.clickedSearch &&
                this.state.searchResponse?.length > 0 &&
                 (
                  <div
                    className="top-menu-search-overlay"
                    onClick={() => this.setState({clickedSearch: false})}
                  />
                )
              }

              <style>
              
                {`
                .top-menu-search .search-area .btn-search,
                .top-menu-search .search-area input::placeholder {
                  color: ${theme?.textColor};
                }

                .top-menu-search .search-area input {
                  color: ${theme?.textColor};
                  border-bottom: 1px solid ${theme?.textColor}4d;
                }

                .top-menu-search .search-area input:focus {
                  color: ${theme?.textColor};
                  background-color: ${theme?.backgroundColor};
                  border: 1px solid ${theme?.textColor}4d;
                  box-shadow: none;
                  -ms-box-shadow: none;
                }

                .top-menu-search .search-area input:focus+.search-item-list,
                .top-menu-search .search-area input:active+.search-item-list {
                  box-shadow: ${theme?.textColor}4d 0px 1px 2px 0px, ${theme?.textColor}22 0px 2px 6px 2px;
                  -ms-box-shadow: ${theme?.textColor}4d 0px 1px 2px 0px, ${theme?.textColor}22 0px 2px 6px 2px;
                }

                .top-menu-search .search-area .resultHolder {
                  border-color: ${theme?.textColor}4d;
                  box-shadow: 0 2px 2px 0 ${theme?.textColor}4d, 0 3px 6px 2px ${theme?.textColor}22;
                  -ms-box-shadow: 0 2px 2px 0 ${theme?.textColor}4d, 0 3px 6px 2px ${theme?.textColor}22;
                }

                .top-menu-search .resultHolder,
                .top-menu-search .searchedDashboard {
                  background-color: ${theme?.backgroundColor};
                }

                .top-menu-search .searchedDashboard {
                  color: ${theme?.textColor};
                  border-left-color: ${theme?.backgroundColor};
                  border-bottom-color: ${theme?.textColor}4d;
                }

                .top-menu-search .searchedDashboard .action,
                .top-menu-search .searchedDashboard .detail * {
                  color: ${theme?.textColor};
                }

                .top-menu-search .searchedDashboard .labels * {
                  color: white;
                }

                .top-menu-search .searchedDashboard img {
                  border-color: ${theme?.textColor}4d;
                  background-color: ${theme?.textColor}33;
                }

                .top-menu-search .searchedDashboard .action .generate-report {
                  background-color: ${theme?.backgroundColor};
                  border-color: ${theme?.textColor}4d;
                  color: ${theme?.textColor};
                }

                .top-menu-search .searchedDashboard .action .generate-report:hover,
                .top-menu-search .searchedDashboard .action .generate-report:focus,
                .top-menu-search .searchedDashboard .action .generate-report:active {
                  background-color: ${theme?.textColor};
                  border-color: ${theme?.backgroundColor}4d;
                  color: ${theme?.backgroundColor};
                }
                

                .top-menu-search .searchedDashboard:hover {
                  border-left-color: ${theme?.textColor};
                }

                .top-menu-search .resultHolder::-webkit-scrollbar,
                .top-menu-search .resultHolder::-webkit-scrollbar-track {
                  background-color: transparent !important;
                }

                .top-menu-search .resultHolder::-webkit-scrollbar-thumb {
                  background-color: ${theme?.textColor}d4 !important;
                }

                .top-menu-search .search-item-list {
                  background-color: ${theme?.backgroundColor};
                }

                .top-menu-search .search-item-list p {
                  color: ${theme?.textColor};
                  background-color: ${theme?.backgroundColor};
                  border-color: ${theme?.textColor}4d;
                }

                .top-menu-search .search-item-list p:hover {
                  color: ${theme?.backgroundColor};
                  background-color: ${theme?.textColor};
                  border-color: ${theme?.backgroundColor}4d;
                }

                .top-menu-search .search-area .selected-category-on-input {
                  color: ${theme?.textColor};
                  background-color: ${theme?.textColor}22;
                }

                @media only screen and (max-width: 1023px) {
                  .navbar .top-menu-search .search-input-area {
                      background: transparent;
                      width: 100%;
                      border-radius: 50px;
                      border: 1px solid ${theme?.textColor}4d;
                      overflow: hidden;
                  }

                  .navbar .top-menu-search .back-icon {
                    background-color: ${theme?.backgroundColor};
                    color: ${theme?.textColor};
                    border-color: ${theme?.textColor}4d;
                  }
                }
              `}
              </style>
            </div>
          }

          {
            this.props.titleVisible &&
            !this.props.loadingScreenOn &&
            <div
              style={{
                display: this.props.settings && this.props.settings.title !== undefined ? "block" : "none",
                minHeight: 35
              }}
              className={"hide-on-mobile"}
            >
              <DashboardTitle
                settings={this.props.settings}
                changeSettings={this.props.changeSettings}
                changeTitleConfig={this.props.changeTitleConfig}
                titleConfig={this.props.titleConfig}
                isCustomDashboard={this.props.isCustomDashboard}
              />
            </div>
          }

          {
            theme?.buttons?.homeButton?.visibility !== false && (
              <HomeButton theme={theme} />
            )
          }

          {
            theme?.buttons?.helpButton?.visibility !== false && (
              <a
                id="dashboardHelp"
                href="javascript:;"
                class="btn btn-rounded blue-button hide-on-mobile"
                style={{
                  marginRight: "5px"
                }}
              >
                <style>
                  {theme?.buttons?.helpButton && `
                    #dashboardHelp {
                      background-color: ${theme?.buttons?.helpButton?.backgroundColor};
                      border-color: ${theme?.buttons?.helpButton?.textColor}33;
                      color: ${theme?.buttons?.helpButton?.textColor};
                    }

                    #dashboardHelp:hover,
                    #dashboardHelp:focus,
                    #dashboardHelp:active {
                      background-color: ${theme?.buttons?.helpButton?.backgroundColorActive} !important;
                      color: ${theme?.buttons?.helpButton?.textColorActive} !important;
                      border-color: ${theme?.buttons?.helpButton?.textColorActive}33 !important;
                    }
                  `}
                </style>
                {
                  getIcon(
                    theme?.buttons?.helpButton?.icon,
                    <i class="fa fa-question" />
                  )
                }
              </a>
            )
          }

          {
            theme?.buttons?.notificationsButton?.visibility !== false && (
              <a className="notification-icon">
                <UserNotifications comesFromTopMenu={true} theme={theme} />
              </a>
            )
          }

          {
            theme?.buttons?.profileButton?.visibility !== false && (
              <UserMenu
                id="userMenu"
                className={"hide-on-mobile"}
                data={getSystemRoles() ? this.menu : this.readOnlyMenu}
                position={"left"}
                drop={"left"}
                responseFunc={(data) => this.menuRedirectFunction(data)}
                theme={theme}
              />
            )
          }
        </nav>

        <style>
          {theme && `
            #topMenu {
              background-color: ${theme.backgroundColor};
              color: ${theme.textColor};
            }

            #topMenu .dashboard-header {
              border-bottom-color: ${theme.textColor}4d;
            }
          `}
        </style>
      </section>
    );
  }
}

export default TopMenu;
