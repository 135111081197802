import React, { Component } from "react";
import { deepCopy } from "../../../../../Utils/Global";
import GeneralAppearance from "./GeneralAppearance";
import Others from "./Others";
import ReturnToTheme from "../../../PluginToolsComponents/ReturnToTheme";
import { themeFields } from "../../common";

/**
 * Configuration file of Filter plugin
 */
export default class FilterConfiguration extends Component {
  /**
   * Updates state attributes in Filter plugin
   */
  setConfigsToPlugin = (changedObj, config, propertyListToDelete = undefined) => {
    let tempState = { ...config };

    if (Array.isArray(changedObj)) {
      for (let i = 0; i < changedObj.length; i++) {
        let object = changedObj[i];
        tempState[object.type] = object.value;
      }
    } else {
      tempState[changedObj.type] = changedObj.value;
    }

    if (propertyListToDelete !== undefined) {
      for (let item of propertyListToDelete) {
        delete tempState[item]
      }
    }

    this.props.updateConfig(
      tempState,
      this.props.pluginId,
      propertyListToDelete
    );
  };

  render() {
    const { configurationTemplate, configurationCategoryTemplate } = this.props;

    return configurationTemplate !== undefined &&
      configurationCategoryTemplate !== undefined ? (
      <>
        <ReturnToTheme
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          preservedConfigFieldsArray={this.props.plugin.preservedConfigFields ? Array.from(this.props.plugin.preservedConfigFields) : []}
        />

        <div style={{ color: "#505050" }}>
          <GeneralAppearance
            config={this.props.config}
            pluginId={this.props.pluginId}
            setConfigsToPlugin={this.setConfigsToPlugin}
            configurationTemplate={this.props.configurationTemplate}
            configurationCategoryTemplate={
              this.props.configurationCategoryTemplate
            }
          />
          <Others
            updateCommonTitleConfig={this.props.updateCommonTitleConfig}
            plugin={this.props.plugin}
            commonTitleConfig={this.props.commonTitleConfig}
            setDefaultForPluginTitle={this.props.setDefaultForPluginTitle}
            isReturnToDefaultforTitleVisible={this.props.isReturnToDefaultforTitleVisible}
            updateConfig={this.props.updateConfig}
            setPluginRerender={this.props.setPluginRerender}
            setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
            currentAppliedConfig={this.props.currentAppliedConfig}
            config={this.props.config}
            pluginId={this.props.pluginId}
            setConfigsToPlugin={this.setConfigsToPlugin}
            configurationTemplate={this.props.configurationTemplate}
            configurationCategoryTemplate={
              this.props.configurationCategoryTemplate
            }
          />
        </div>
      </>

    ) : null;
  }
}
