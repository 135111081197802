import React, { Component } from "react";
import Filter from "../Filter/Filter";
import DateFilter from "../GlobalFilter/DateFilter";
import NumberFilter from "../GlobalFilter/NumberFilter/NumberFilter";
import { store } from "../../../..";
import _ from "lodash";
import { deepCopy } from "../../../../Utils/Global";
import $ from "jquery"

/**
 * It ensures that the relevant plug-in renders according to the data type of the selected column.
 */
export default class FilterFactory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      operator: "=",
      filter: deepCopy(this.props?.plugin?.columnMap?.filter?.data[0])
    };
  }

  beforeProps;
    // Sets plugin-container z-index 1 in filters on hover
  onHoverInputClass = (e, plugin) => {
    let pluginId = plugin.id
    let isAnyPopupNotAvailable = $(".data-popup").length === 0

    if (isAnyPopupNotAvailable) {
      $("#plugin-container-" + pluginId).parent().css("z-index", "1")
    }
  }
    // Sets plugin-container z-index 0 in filters on mouse leave
  offHoverInputClass = (e, plugin) => {
    let pluginId = plugin.id
    let isAnyPopupNotAvailable = $(".data-popup").length === 0

    if (isAnyPopupNotAvailable) {
      $("#plugin-container-" + pluginId).parent().css("z-index", "0")
    }

  }
  componentWillReceiveProps(nextProps) {
    if (this.props.plugin.columnMap && nextProps.plugin.columnMap && nextProps.plugin.columnMap.filter.data != this.props.plugin.columnMap.filter.data) {
      let reduxState = store.getState()

      reduxState.PluginTriggerReducer.sourcePluginsWithValues.set(this.props.plugin.id, [])
    }

    let isPluginDate = (dataType) => {
      let isDateFormat =
        dataType === "date" ||
        dataType === "timestamp" ||
        dataType === "timestamp without time zone";

      return isDateFormat
    }

    if (!_.isEqual(this.state.filter?.DataFormatType, nextProps.plugin?.columnMap?.filter?.data[0]?.DataFormatType) && this.state.filter?.DataFormatType !== undefined) {
      sessionStorage.removeItem(this.props.plugin.id + "-preData")

      this.setState({
        ...this.state,
        filter: deepCopy(nextProps.plugin.columnMap.filter.data[0])
      })

      setTimeout(this.props.refreshPlugin(true, this.props.plugin.id, true), 10)
    } else if (!_.isEqual(this.state.filter, nextProps.plugin?.columnMap?.filter?.data[0])) {
      this.setState({
        ...this.state,
        filter: deepCopy(nextProps.plugin.columnMap.filter.data[0])
      })
    }
  }

  /*
   * set Operator in NumberFilter
   */
  changeOperatorValue = value => {
    this.setState({
      ...this.state,
      operator: value
    });
  };

  createFilter = () => {
    let pluginColumnMap = this.props.plugin.columnMap;

    if (pluginColumnMap && pluginColumnMap.filter.data.length > 0) {
      let lastProps = this.props;

      if (this.beforeProps) {
        let beforePropsColumn = this.beforeProps.plugin.columnMap.filter.data;
        let thisPropsColumn = this.beforeProps.plugin.columnMap.filter.data;

        let isColumnChanged =
          thisPropsColumn[0] &&
          beforePropsColumn[0] &&
          thisPropsColumn[0].uniqeColumnId !== beforePropsColumn[0].uniqeColumnId;


        let isColumnAdded = thisPropsColumn[0] != undefined && beforePropsColumn[0] == undefined;

        if (isColumnChanged || isColumnAdded) {
          lastProps.plugin.rerender = true;
        }
      }

      this.beforeProps = lastProps;

      let pluginData = pluginColumnMap.filter.data;
      let dataType;

      if (pluginData.length > 0) {
        dataType = pluginData[0].dataType;
      }

      let isDateFormat =
        dataType === "date" ||
        dataType === "timestamp" ||
        dataType === "timestamp without time zone";

      let isNumberFormat =
        dataType === "number" ||
        dataType === "integer" ||
        dataType === "double";

      if (isDateFormat) {
        return (
          <DateFilter
            {...this.beforeProps}
            updateCommonTitleConfig={this.props.updateCommonTitleConfig}
            plugin={this.props.plugin}
            commonTitleConfig={this.props.commonTitleConfig}
            setDefaultForPluginTitle={this.props.setDefaultForPluginTitle}
            isReturnToDefaultforTitleVisible={
              this.props.isReturnToDefaultforTitleVisible
            }
            onHoverInputClass={this.onHoverInputClass}
            offHoverInputClass={this.offHoverInputClass}
            updateConfig={this.props.updateConfig}
            setPluginRerender={this.props.setPluginRerender}
            setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
            currentAppliedConfig={this.props.currentAppliedConfig}
            config={this.props.config}
            pluginId={this.props.pluginId}
            setConfigToPlugin={this.setConfigToPlugin}
            setConfigsToPlugin={this.setConfigsToPlugin}
            configurationTemplate={this.props.configurationTemplate}
            configurationCategoryTemplate={
              this.props.configurationCategoryTemplate
            }
            reReturnThemeSettings={this.props.reReturnThemeSettings}
            refreshPlugin={this.props.refreshPlugin}
          ></DateFilter>
        );
      } else if (isNumberFormat) {
        return (
          <NumberFilter
            type={"number"}
            operator={this.state.operator}
            changeOperatorValue={this.changeOperatorValue}
            {...this.props}
            config={{ ...this.props.config }}
            updateCommonTitleConfig={this.props.updateCommonTitleConfig}
            plugin={this.props.plugin}
            commonTitleConfig={this.props.commonTitleConfig}
            setDefaultForPluginTitle={this.props.setDefaultForPluginTitle}
            isReturnToDefaultforTitleVisible={
              this.props.isReturnToDefaultforTitleVisible
            }
            updateConfig={this.props.updateConfig}
            setPluginRerender={this.props.setPluginRerender}
            setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
            currentAppliedConfig={this.props.currentAppliedConfig}
            pluginId={this.props.pluginId}
            setConfigToPlugin={this.setConfigToPlugin}
            setConfigsToPlugin={this.setConfigsToPlugin}
            configurationTemplate={this.props.configurationTemplate}
            configurationCategoryTemplate={
              this.props.configurationCategoryTemplate
            }
            onHoverInputClass={this.onHoverInputClass}
            offHoverInputClass={this.offHoverInputClass}
            reReturnThemeSettings={this.props.reReturnThemeSettings}
            refreshPlugin={this.props.refreshPlugin}
          ></NumberFilter>
        );
      } else {
        return (
          <Filter
            {...this.props}
            updateCommonTitleConfig={this.props.updateCommonTitleConfig}
            plugin={this.props.plugin}
            commonTitleConfig={this.props.commonTitleConfig}
            setDefaultForPluginTitle={this.props.setDefaultForPluginTitle}
            isReturnToDefaultforTitleVisible={
              this.props.isReturnToDefaultforTitleVisible
            }
            updateConfig={this.props.updateConfig}
            setPluginRerender={this.props.setPluginRerender}
            setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
            currentAppliedConfig={this.props.currentAppliedConfig}
            config={this.props.config}
            pluginId={this.props.pluginId}
            setConfigToPlugin={this.setConfigToPlugin}
            setConfigsToPlugin={this.setConfigsToPlugin}
            configurationTemplate={this.props.configurationTemplate}
            configurationCategoryTemplate={
              this.props.configurationCategoryTemplate
            }
            onHoverInputClass={this.onHoverInputClass}
            offHoverInputClass={this.offHoverInputClass}
            reReturnThemeSettings={this.props.reReturnThemeSettings}
            refreshPlugin={this.props.refreshPlugin}
          ></Filter> 
        );
      }
    } else {
      return (
        <Filter
          {...this.props}
          updateCommonTitleConfig={this.props.updateCommonTitleConfig}
          plugin={this.props.plugin}
          commonTitleConfig={this.props.commonTitleConfig}
          setDefaultForPluginTitle={this.props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={
            this.props.isReturnToDefaultforTitleVisible
          }
          updateConfig={this.props.updateConfig}
          setPluginRerender={this.props.setPluginRerender}
          setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
          currentAppliedConfig={this.props.currentAppliedConfig}
          config={this.props.config}
          pluginId={this.props.pluginId}
          setConfigToPlugin={this.setConfigToPlugin}
          setConfigsToPlugin={this.setConfigsToPlugin}
          configurationTemplate={this.props.configurationTemplate}
          configurationCategoryTemplate={
            this.props.configurationCategoryTemplate
          }
          onHoverInputClass={this.onHoverInputClass}
          offHoverInputClass={this.offHoverInputClass}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        ></Filter>
      );
    }
  };

  render() {
    return <div style={{ height: "100%" }}>{this.createFilter()}</div>;
  }
}
