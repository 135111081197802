import React, { Component } from "react";
import * as d3 from "d3";
import $ from "jquery";
import { rmvpp } from "../../RenderJs/rmvpp";
import { obiee } from "../../RenderJs/obiee";
import LineBarChartConfiguration from "./LineBarChartConfiguration";
import LineBarChartData from "./LineBarChartData";
import i18n from "../../../../Utils/i18next";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join"
import {
  onComponentWillMount,
  onComponentWillReceiveProps,
  getColumnMapping,
} from "../common";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import {
  renderConfig,
  renderData,
  renderNavigation
} from "../PluginsCommonComponents";
import { createTrigger } from "../../../Interaction/CreateTrigger";
import { renderContent } from "../renderContent";
import NavigationContent from "../../../Navigation/NavigationContent";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";

const data = JSON.parse(
  `[{"kargotoplam":"1690930948","bagajtoplam":"4045081712","category":2014,"barMeasure":[{"name":"kargotoplam","value":1690930948,"category":2014}],"lineMeasure":[{"name":"bagajtoplam","value":4045081712,"category":2014}],"hidden":[]},{"kargotoplam":"1843979628","bagajtoplam":"4296005250","category":2015,"barMeasure":[{"name":"kargotoplam","value":1843979628,"category":2015}],"lineMeasure":[{"name":"bagajtoplam","value":4296005250,"category":2015}],"hidden":[]},{"kargotoplam":"2075162350","bagajtoplam":"4037735418","category":2016,"barMeasure":[{"name":"kargotoplam","value":2075162350,"category":2016}],"lineMeasure":[{"name":"bagajtoplam","value":4037735418,"category":2016}],"hidden":[]}]`
);
const config = JSON.parse(
  '{"width":400,"height":300,"pointSize":3,"strokeWidth":2,"xTitle":"","yTitle":"","y2Title":"","brushNav":true,"legend":false,"stacked":false,"horizontal":false,"showHideButton":false,"sortDirDefault":"Descending","sortColDefault":1,"sortControl":true,"colours":"Flat-UI","toggleCriteria":"","title":"","summary":"","backgroundColor":"rgb(255,255,255)","refresh":0}'
);
const columnMap = JSON.parse(
  `{"category":{"Code":"\'ucusAlias\'.\'yilAlias\'","Name":"yil","DataType":"integer","Table":"ucusAlias","Measure":"none","ID":"ucusAlias.yil","SubjectArea":"BIGDATA","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":".0f","Config":{},"Verified":false,"Type":"Column","Description":""},"barMeasure":[{"Code":"\'ucusAlias\'.\'kargotoplamDataAlias\'","Name":"kargotoplam","DataType":"double","Table":"ucusAlias","Measure":"sum(ucus.kargotoplam)","ID":"ucusAlias.kargotoplam","SubjectArea":"BIGDATA","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":".3s","Config":{},"Verified":false,"Type":"Column","Description":""}],"lineMeasure":[{"Code":"\'ucusAlias\'.\'bagajtoplamAlias\'","Name":"bagajtoplam","DataType":"double","Table":"ucusAlias","Measure":"sum(ucus.bagajtoplam)","ID":"ucusAlias.bagajtoplam","SubjectArea":"BIGDATA","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":".3s","Config":{},"Verified":false,"Type":"Column","Description":""}],"hidden":[]}`
);

const condFormats = [];
const filters = [];
const pluginName = "line-bar";

const description =
  "Displays a bar and line chart on the same plot, with two Y axes but the same X axis. Should be used for displaying discrete information. Bars can be displayed as stacked as well as making the whole chart horizontal by editing the configuration properties.";

const configurationParameters = [
  {
    targetProperty: "width",
    label: "Width",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 400
    },
    desc: "desc89"
  },
  {
    targetProperty: "height",
    label: "Height",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 300
    },
    desc: "desc90"
  },
  {
    targetProperty: "pointSize",
    label: "PointSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 3
    },
    desc: "desc95"
  },
  {
    targetProperty: "strokeWidth",
    label: "StrokeWidth",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 2
    },
    desc: "StrokeWidth"
  },
  {
    targetProperty: "xTitle",
    label: "XTitle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: ""
    },
    desc: "XTitle"
  },
  {
    targetProperty: "yTitle",
    label: "YTitle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: ""
    },
    desc: "YTitle"
  },
  {
    targetProperty: "y2Title",
    label: "Y2Title",
    inputType: "textbox",
    inputOptions: {
      defaultValue: ""
    },
    desc: "desc106"
  },
  {
    targetProperty: "brushNav",
    label: "BrushNav",
    inputType: "checkbox",
    inputOptions: { defaultValue: true },
    desc: "desc107"
  },
  {
    targetProperty: "legend",
    label: "Legend",
    inputType: "checkbox",
    inputOptions: { defaultValue: true },
    desc: "Legend"
  },
  {
    targetProperty: "stacked",
    label: "Stacked",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "Stacked"
  },
  {
    targetProperty: "horizontal",
    label: "Horizontal",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "Horizontal"
  },
  {
    targetProperty: "showHideButton",
    label: "Show Hide Button",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc230"
  },
  {
    targetProperty: "sortDirDefault",
    label: "SortDirDefault",
    inputType: "radio",
    inputOptions: {
      values: ["Ascending", "Descending"],
      defaultValue: "Descending"
    },
    desc: "SortDirDefault"
  },
  {
    targetProperty: "sortColDefault",
    label: "SortColDefault",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 1
    },
    desc: "SortColDefault"
  },
  {
    targetProperty: "sortControl",
    label: "SortControl",
    inputType: "checkbox",
    inputOptions: { defaultValue: true },
    desc: "SortControl"
  },
  {
    targetProperty: "colours",
    label: "Colors",
    inputType: "palette",
    inputOptions: { defaultValue: "Flat-UI" },
    desc: "Colors"
  },
  {
    targetProperty: "toggleCriteria",
    label: "ToggleCriteria",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc59"
  },
  {
    targetProperty: "title",
    label: "Title",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc94"
  },
  {
    targetProperty: "summary",
    label: "Summary",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc61"
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: { defaultValue: "rgb(255,255,255)" },
    desc: "desc62"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  },
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 15
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
];

const actions = [
  {
    trigger: "barClick",
    type: "click",
    name: "Tıklama - Bar",
    output: ["category"],
    description: "BarClickDesc"
  },
  {
    trigger: "barHover",
    type: "hover",
    name: "Hover - Bar",
    output: ["category"],
    description: "BarHoverDesc"
  },
  {
    trigger: "pointHover",
    type: "hover",
    output: ["category"],
    name: "Hover - Nokta",
    description: "PointHoverDesc"
  },
  {
    trigger: "pointClick",
    type: "click",
    output: ["category"],
    name: "Tıklama - Nokta",
    description: "PointClickDesc"
  },
  {
    trigger: "sectionClick",
    type: "click",
    name: "Tıklama - Bölüm",
    output: ["category"],
    description: "SectionClickDesc"
  },
  {
    trigger: "sectionHover",
    type: "hover",
    name: "Hover - Bölüm",
    output: ["category"],
    description: "SectionHoverDesc"
  },
  {
    trigger: "clickGroup",
    type: "select",
    name: "Tıklama - Grup",
    output: ["category"],
    description: "ClickGroupDesc"
  },
  {
    trigger: "brushSelect",
    type: "brush",
    name: "Fırça Seçimi",
    output: ["category"],
    description: "BrushSelectDesc"
  }
];

const reactions = [
  {
    id: "filter",
    name: "Filtre",
    description: "desc87",
    type: "general"
  },
  {
    id: "highlightBars",
    name: "Barları Vurgula",
    description: "desc218",
    type: "private",
    method: "highlightBars"
  }
];

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

// Highlight bars upon interaction
const highlightBars = function (output, container) {
  if (output.length > 0) {
    var bars = d3.select($(container)[0]).selectAll("rect.bar");
    var data = bars.data();
    var config = output[0].config;

    bars.each(function (d, i) {
      var filter = [];
      revertColour(d3.select(this)); // Revert any highlighted points
      output.forEach(function (criteria) {
        filter.push($.inArray(d[criteria.targetId], criteria.values) > -1);
      }); // Find matching points

      // Highlight points
      if (
        d3.set(filter).values().length == 1 &&
        d3.set(filter).values()[0] == "true"
      ) {
        d3.select(this)
          .transition()
          .attr("fill", function (d) {
            return rmvpp.increaseBrightness(d.colour, 30);
          });
      }
    });
  }
};

// Highlight selected elements
const highlight = function (element, colourScale, reduceColour) {
  element
    .transition()
    .attr("fill", function (d, i) {
      return rmvpp.reduceBrightness(d.colour, reduceColour);
    })
    .duration(100);
};

// Revert colour of bars
const revertColour = function (element) {
  element
    .transition()
    .attr("fill", function (d, i) {
      return d.colour;
    })
    .duration(100);
};

/**
 * renders Sunburst plugin in Vispeahen V3
 */

export default class LineBarChart extends Component {
  constructor(props) {
    super(props);
    this.rerenderProcessStarted = false;
    this.callBackObject = {};
  }

  calculatePluginHeight = (plugin, settings) => {
    let containerHeight = settings.grid.rowHeight * plugin.h;
    let pluginTitleContainer = $("#title-" + plugin.id);
    let pluginContainerBorder = -2;
    let pluginConstantNavigatorHeight = 82;
    let pluginConstantSortHeight = 24 + 5;
    let maxHeight =
      containerHeight -
      (pluginTitleContainer.outerHeight() +
        parseInt(pluginTitleContainer.css("margin-bottom")) +
        pluginContainerBorder +
        pluginConstantNavigatorHeight +
        pluginConstantSortHeight);
    return maxHeight;
  };

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    tempPlugin.highlightBars = highlightBars

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({})

    return tmpCallBackObject;
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );
  }

  getNavigationComponent = props => {
    return (
      <NavigationContent
        navigations={this.props.navigations}
        setNavigations={this.props.updatePlugin}
        plugin={this.props.plugin}
        dashboardInformation={this.props.dashboardInformation}
      />
    );
  };

  getConfigComponent = props => {
    if (props.config) {
      return (
        <LineBarChartConfiguration
          config={{ ...props.config }}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig= {this.props.setCurrentAppliedConfig}
          currentAppliedConfig = {this.props.currentAppliedConfig}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }

    return (
      <LineBarChartData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        didNotJoinedTables={checkTableJoins(this.props.join, this.props.plugin.columnMap, this.props.refreshedPluginId, this.props.plugin.id, true)}
        setInteractions={this.props.setInteractions}
        interactions={this.props.interactions}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={props.changeDoesPluginHasNotJoinedTable}
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        navigations={this.props.navigations}
        plugin={props.plugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  /**
   * To set column map this plugin
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      category: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Level.Name"),
        type: "dim",
        required: true,
        minimumColumnSize: 1,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Level.Desc"),
        data: []
      },
      barMeasure: {
        name: i18n.t(
          "Plugins." + tempPlugin.key + ".ColumnMap.BarMeasure.Name"
        ),
        conditionalFormat: true,
        multiple: true,
        minimumColumnSize: 1,
        dependentColumns: [i18n.t(
          "Plugins." + tempPlugin.key + ".ColumnMap.LineMeasure.Name"
        )],
        desc: i18n.t(
          "Plugins." + tempPlugin.key + ".ColumnMap.BarMeasure.Desc"
        ),
        type: "fact",
        data: []
      },
      lineMeasure: {
        name: i18n.t(
          "Plugins." + tempPlugin.key + ".ColumnMap.LineMeasure.Name"
        ),
        conditionalFormat: true,
        multiple: true,
        dependentColumns: [i18n.t(
          "Plugins." + tempPlugin.key + ".ColumnMap.BarMeasure.Name"
        )],
        minimumColumnSize: 1,
        desc: i18n.t(
          "Plugins." + tempPlugin.key + ".ColumnMap.LineMeasure.Desc"
        ),
        type: "fact",
        data: []
      },
      hidden: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Hidden.Name"),
        multiple: true,
        minimumColumnSize: 0,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Hidden.Desc"),
        type: "hidden",
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {
    var THIS = this;

    // Set html empty and set this html to container.
    $("#" + divId).html("");
    let container = $("#" + divId)[0];
    let vis;

    // Change background color
    $(container).css("background-color", config.backgroundColor);

    var hideXLabels = false,
      hideYLabels = false;

    // Compile column name list for Y
    var colNames = [],
      legendTitle = "Measures";
    var barNames = columnMap.barMeasure.map(function (m) {
      return m.Name;
    });
    var lineNames = columnMap.lineMeasure.map(function (m) {
      return m.Name;
    });
    colNames = barNames.concat(lineNames);

    // Denormalise X label onto Y values for tooltip later
    data.map(function (d) {
      for (var i = 0; i < d.barMeasure.length; i++) {
        d.barMeasure[i].category = d.category;
      }
      for (var i = 0; i < d.lineMeasure.length; i++) {
        d.lineMeasure[i].category = d.category;
      }
    });

    var sortColMap = columnMap;

    // Set X and Y titles
    var xTitle = config.xTitle,
      yTitle = config.yTitle,
      y2Title = config.y2Title;
    if (xTitle == "default") xTitle = columnMap.category.Name;
    if (yTitle == "default")
      yTitle = columnMap.barMeasure
        .map(function (m) {
          return m.Name;
        })
        .join(", ");
    if (y2Title == "default")
      y2Title = columnMap.lineMeasure
        .map(function (m) {
          return m.Name;
        })
        .join(", ");

    // Create Sort radio buttons
    var initialSort = config.sortDirDefault == "Descending" ? "desc" : "asc";
    var initialSortCol =
      config.sortColDefault >
        [columnMap.category.Name].concat(colNames).length - 1
        ? 1
        : config.sortColDefault;
    if (config.sortControl) {
      var sortControl = d3
        .select(container)
        .append("div")
        .classed("sortBar", true)
        .style("margin-bottom", "5px");

      var sortHeader = sortControl.append("span");
      sortHeader.append("b").text("SortBy");
      sortHeader
        .append("select")
        .classed("sortColumn", true)
        .style("margin", "0 10px")
        .selectAll(".sortOptions")
        .data([columnMap.category.Name].concat(colNames))
        .enter()
        .append("option")
        .text(function (d, i) {
          return d;
        })
        .attr("value", function (d, i) {
          return i;
        });

      $(sortControl[0]).append(
        '<span><i sort-dir="asc" class="fa fa-arrow-up"></i></span>'
      );
      $(sortControl[0]).append(
        '<span style="margin-left: 5px;"><i sort-dir="desc" class="fa fa-arrow-down"></span>'
      );
      if (config.sortDirDefault == "Descending")
        $(sortControl[0])
          .find(".fa-arrow-down")
          .addClass("selected");
      else
        $(sortControl[0])
          .find(".fa-arrow-up")
          .addClass("selected");
      $(sortControl[0])
        .find(".sortColumn")
        .val(initialSortCol); // Set first Y column by default

      // Render chart on sort change
      $(".sortBar select").change(function () {
        sortAndRender(data, config.brushNav);
      });

      $(".sortBar i").click(function () {
        $(this)
          .parents(".sortBar")
          .find("i")
          .css("color", "#000")
          .removeClass("selected");
        $(this)
          .css("color", "#0EBF0E")
          .addClass("selected");
        sortAndRender(data, config.brushNav);
      });
    }

    // Render container div
    var chartContainer = d3
      .select(container)
      .append("div")
      .classed("bar-chart", true);

    // Render bar chart and navigation bar
    renderBar(
      data,
      $(chartContainer[0])[0],
      { col: initialSortCol, dir: initialSort },
      true,
      config.brushNav
    ); // Sort by first Y column descending by default

    // Get sort object from UI
    function sortAndRender(data, navigator, animate) {
      animate = animate || false;
      var sortBar = $(container).find(".sortBar");
      var barContainer = $(container).find(".bar-chart")[0];

      var sortObj = {}; // Use object to define sort column and direction
      sortObj.dir = sortBar.find("i.selected").attr("sort-dir");
      sortObj.col = sortBar.find(".sortColumn").val();
      renderBar(data, barContainer, sortObj, animate, navigator);
    }

    // Sort the data frame by a column in a specific direction
    function sortData(data, sort) {
      var measure =
        $.inArray(colNames[sort.col - 1], barNames) == -1
          ? "lineMeasure"
          : "barMeasure";
      var sortCol;
      if (measure == "lineMeasure" && +sort.col > 0)
        sortCol = sort.col - barNames.length;
      else sortCol = sort.col;

      // Sort data based on input
      switch (sort.dir) {
        case "asc":
          if (sortCol != 0)
            data = data.sort(function (a, b) {
              return d3.ascending(
                +a[measure][sortCol - 1].value,
                +b[measure][sortCol - 1].value
              );
            });
          else
            data = data.sort(function (a, b) {
              if (columnMap.category.SortKey)
                return d3.ascending(
                  +a[columnMap.category.Name + " (Sort)"],
                  +b[columnMap.category.Name + " (Sort)"]
                );
              else return d3.ascending(a.category, b.category);
            });
          break;
        case "desc":
          if (sortCol != 0)
            data = data.sort(function (a, b) {
              return d3.descending(
                +a[measure][sortCol - 1].value,
                +b[measure][sortCol - 1].value
              );
            });
          else
            data = data.sort(function (a, b) {
              if (columnMap.category.SortKey)
                return d3.descending(
                  +a[columnMap.category.Name + " (Sort)"],
                  +b[columnMap.category.Name + " (Sort)"]
                );
              else return d3.descending(a.category, b.category);
            });
          break;
      }
      return data;
    }

    // Main rendering function
    function renderBar(chartData, chartContainer, sort, animate, navigator) {
      // Error if no bars selected
      if (chartData.length == 0)
        rmvpp.displayError(container, "NoDataCannotRenderData");

      chartData = sortData(chartData, sort);
      data = sortData(data, sort);

      $(chartContainer)
        .find(".main")
        .remove(); // Clear existing chart

      // Define column names and height and width from config
      var width = config.horizontal ? +config.height : +config.width;
      var height = config.horizontal ? +config.width : +config.height;

      // Minimum and maximum measure values
      if (!config.stacked) {
        var maxY = d3.max(chartData, function (d) {
          return d3.max(d.barMeasure, function (d) {
            return +d.value;
          });
        });
        var minY = d3.min(chartData, function (d) {
          return d3.min(d.barMeasure, function (d) {
            return +d.value;
          });
        });
      } else {
        var maxY = d3.max(chartData, function (d) {
          return d3.sum(d.barMeasure, function (d) {
            return +d.value;
          });
        });
        var minY = d3.min(chartData, function (d) {
          return d3.sum(d.barMeasure, function (d) {
            return +d.value;
          });
        });
        chartData.forEach(function (datum) {
          // Calculate positions for the bars for a stacked chart
          var y0 = 0;
          datum.barMeasure.forEach(function (d) {
            y0 += d.value;
            d.y0 = y0;
          });
        });
      }

      var maxY2 = d3.max(chartData, function (d) {
        return d3.max(d.lineMeasure, function (d) {
          return +d.value;
        });
      });
      var minY2 = d3.min(chartData, function (d) {
        return d3.min(d.lineMeasure, function (d) {
          return +d.value;
        });
      });

      // Define colour palette
      var colour = rmvpp.colourScale(colNames, config.colours); // Set colour scale

      // Apply conditional formatting to generate colours
      rmvpp.applyColours(
        chartData,
        columnMap,
        colour,
        condFormats,
        "category",
        "barMeasure"
      );
      rmvpp.applyColours(
        chartData,
        columnMap,
        colour,
        condFormats,
        "category",
        "lineMeasure"
      );

      // Define X axis on chart data
      var x = d3.scale
        .ordinal()
        .domain(
          chartData.map(function (d) {
            return d.category;
          })
        )
        .rangeBands([0, width], 0.1);

      // Define X axis on all data
      var fullX = d3.scale
        .ordinal()
        .domain(
          chartData.map(function (d) {
            return d.category;
          })
        )
        .rangeBands([0, width], 0.1);

      // Define category grouping
      if (config.stacked) {
        var x1 = d3.scale
          .ordinal()
          .domain(barNames[0])
          .rangeRoundBands([0, x.rangeBand()]);
      } else {
        var x1 = d3.scale
          .ordinal()
          .domain(barNames)
          .rangeRoundBands([0, x.rangeBand()]);
      }

      // Define Y axis
      var y = d3.scale.linear().domain([d3.min([0, minY]), d3.max([0, maxY])]); // From 0 to maximum

      // Define Second Y axis
      var y2 = d3.scale
        .linear()
        .domain([d3.min([0, minY2]), d3.max([0, maxY2])]); // From 0 to maximum

      // Create chart object (plot area)
      var chartObj = new rmvpp.Chart(chartContainer, width, height);
      if (config.horizontal) chartObj.Horizontal = true;

      chartObj.setX(xTitle, x, columnMap.category);
      chartObj.setY(yTitle, y, columnMap.barMeasure[0]);
      chartObj.setY2(y2Title, y2, columnMap.lineMeasure[0]);

      var margin = chartObj.setMargin();
      var chart = chartObj.createSVG(vis, ".navigator"); // Create chart SVG to standard size
      chartObj.drawAxes(); // Draw axes
      chart.parent().classed("main", true); //needs.parent to be assigned to the right part when using SVG function

      var tooltip = new rmvpp.Tooltip(chartContainer); // Create tooltip object

      if (config.legend) {
        var legend = new rmvpp.Legend(
          chart,
          colNames,
          legendTitle,
          width + margin.right,
          margin,
          true
        ); // Legend
        legend.addColourKey(colNames, colour); // Legend Colour Key
        legend.addCondFormatKey(condFormats);
      }

      // Create x partitions
      var barContainer = chart.append("g").classed("bars", true);
      var xGroupsBar = barContainer
        .selectAll()
        .data(chartData)
        .enter()
        .append("g")
        .attr("transform", function (d) {
          return "translate(" + x(d.category) + ",0)";
        });

      // Generate invisible rectangles for section hovering
      xGroupsBar
        .append("rect")
        .classed("section", true)
        .style("opacity", 0)
        .attr("x", 0)
        .attr("y", 0)
        .attr("height", y(d3.min([0, minY])))
        .attr("width", x.rangeBand())
        .attr("pointer-events", "none") // Disable pointer events until animation complete
        .on("mouseover", function (d, i, event) {
          highlightPointGroup(this); // Highlight markers
          tooltip.displayList(
            d,
            "category",
            ["barMeasure", "lineMeasure"],
            columnMap,
            d3.event,
            "colour"
          );
          revertColour(chart.selectAll("rect.bar:not(.selected)"));
          highlight(
            d3
              .select(this)
              .parent()
              .selectAll("rect.bar:not(.selected)"),
            colour,
            15
          ); // Highlight group of bars

          let mousePosition = { x: window.event.pageX, y: window.event.pageY }

          createTrigger(
            actions,
            columnMap,
            container,
            "sectionHover",
            d,
            THIS.props.plugin.id,
            THIS.props.interactions,
            THIS.props.navigations,
            mousePosition
          ); // Trigger sectionHover event
        })
        .on("mouseout", function (d, i) {
          revertPointColour(this);
          revertColour(
            d3
              .select(this)
              .parent()
              .selectAll("rect.bar:not(.selected)")
          );
          tooltip.hide(tooltip);
        })
        .on("click", function (d, i) {
          deselectBars();

          let mousePosition = { x: window.event.pageX, y: window.event.pageY }

          createTrigger(
            actions,
            columnMap,
            container,
            "clickGroup",
            data,
            THIS.props.plugin.id,
            THIS.props.interactions,
            THIS.props.navigations,
            mousePosition
          );
          createTrigger(
            actions,
            columnMap,
            container,
            "sectionClick",
            d,
            THIS.props.plugin.id,
            THIS.props.interactions,
            THIS.props.navigations,
            mousePosition
          ); // Trigger sectionClick event
        })
        .transition()
        .duration(500)
        .transition()
        .attr("pointer-events", ""); // Enable point events when animation complete

      // Create bars
      var barWidth = config.stacked ? x.rangeBand() : x1.rangeBand();
      var bars = xGroupsBar
        .selectAll("g")
        .data(function (d) {
          return d.barMeasure;
        })
        .enter()
        .append("rect")
        .classed("bar", true)
        .attr("x", function (d, i) {
          return x1(d.name);
        })
        .attr("height", function (d, i) {
          return 0;
        })
        .attr("width", barWidth)
        .attr("fill", function (d, i) {
          return d.colour;
        })
        .attr("pointer-events", "none") // Disable pointer events until animation complete
        .on("mouseover", function (d, i, event) {
          var datum = d3
            .selectAll(
              $(this)
                .parent()
                .find("rect.section")
                .toArray()
            )
            .datum();
          tooltip.displayList(
            datum,
            "category",
            "barMeasure",
            columnMap,
            d3.event,
            "colour",
            d.name
          );
          highlight(
            d3
              .select(this)
              .parent()
              .selectAll("rect.bar:not(.selected)"),
            colour,
            15,
            condFormats
          ); // Highlight group of bars

          let mousePosition = { x: window.event.pageX, y: window.event.pageY }

          createTrigger(
            actions,
            columnMap,
            container,
            "barHover",
            d,
            THIS.props.plugin.id,
            THIS.props.interactions,
            THIS.props.navigations,
            mousePosition
          ); // Trigger barHover event
        })
        .on("mouseout", function (d, i, event) {
          revertColour(
            d3
              .select(this)
              .parent()
              .selectAll("rect.bar:not(.selected)")
          );
          tooltip.hide();
        })
        .on("click", function (d, i) {
          if (!Event.ctrlKey && !d3.select(this).classed("selected"))
            // Allow control clicking to select more bars
            deselectBars();

          if (Event.ctrlKey && d3.select(this).classed("selected")) {
            d3.select(this).classed("selected", false);
            revertColour(d3.select(this));
          } else {
            // Highlight bar on click (reverting other bars)
            d3.select(this)
              .classed("selected", true)
              .attr("fill", function (d, i) {
                return rmvpp.increaseBrightness(d.colour, 30);
              });
          }

          var selectedData = chart.selectAll("rect.bar.selected").data();
          if (selectedData.length == 0) selectedData = chartData;
          else {
            // Display tooltip showing summary of selected values
            var summary = {}; // Data object for tooltip
            summary.count = selectedData.length;
            summary.barMeasure = [];

            columnMap.barMeasure.forEach(function (m) {
              // Create summary object
              var filterMeasure = selectedData.filter(function (d) {
                return d.name == m.Name;
              });
              var measureTotal =
                d3[rmvpp.convertMeasure(m.Measure)](
                  filterMeasure.map(function (d) {
                    return +d.value;
                  })
                ) || 0;
              if (measureTotal != 0)
                summary.barMeasure.push({ name: m.Name, value: measureTotal });
            });

            columnMap["count"] = new obiee.BIColumn("", "Count", "integer");
            tooltip.displayList(
              summary,
              "count",
              "barMeasure",
              columnMap,
              d3.event,
              "colour"
            );
            delete columnMap["count"];
          }

          let mousePosition = { x: window.event.pageX, y: window.event.pageY }

          createTrigger(
            actions,
            columnMap,
            container,
            "clickGroup",
            selectedData,
            THIS.props.plugin.id,
            THIS.props.interactions,
            THIS.props.navigations,
            mousePosition
          );
          createTrigger(
            actions,
            columnMap,
            container,
            "barClick",
            d,
            THIS.props.plugin.id,
            THIS.props.interactions,
            THIS.props.navigations,
            mousePosition
          );
        }); // Trigger barClick event

      // Calculate y position differently if stacked
      function yPos(d) {
        if (!config.stacked) return y(Math.max(0, d.value));
        else return y(Math.max(0, d.y0));
      }

      // Animate on load
      if (animate) {
        bars
          .attr("y", function (d, i) {
            return y(0);
          }) // Set bar height to 0 for animations
          .transition() // Animate bars on render
          .attr("y", function (d, i) {
            return yPos(d);
          })
          .attr("height", function (d, i) {
            return Math.abs(y(d.value) - y(0));
          })
          .duration(500)
          .transition()
          .duration(500)
          .attr("pointer-events", ""); // Enable point events when animation complete
      } else {
        bars
          .attr("y", function (d, i) {
            return yPos(d);
          })
          .attr("height", function (d, i) {
            return Math.abs(y(d.value) - y(0));
          })
          .attr("pointer-events", "");
      }

      var pointContainer = chart.append("g").classed("points", true);
      var xGroupsPoint = pointContainer
        .selectAll()
        .data(chartData)
        .enter()
        .append("g")
        .attr("transform", function (d) {
          return "translate(" + x(d.category) + ",0)";
        });

      // Create Points
      var points = xGroupsPoint
        .selectAll("g")
        .data(function (d) {
          return d.lineMeasure;
        })
        .enter()
        .append("circle")
        .attr("r", config.pointSize)
        .attr("cx", function (d, i) {
          return x.rangeBand() / 2;
        })
        .attr("fill", function (d) {
          return d.colour;
        })
        .classed("marker", true)
        .on("click", function (d, i) {
          let mousePosition = { x: window.event.pageX, y: window.event.pageY }

          createTrigger(
            actions,
            columnMap,
            container,
            "pointClick",
            d,
            THIS.props.plugin.id,
            THIS.props.interactions,
            THIS.props.navigations,
            mousePosition
          ); // Trigger hover point action
        })
        .on("mouseover", function (d, i) {
          highlightPointGroup(this); // Highlight markers

          // Show tooltip
          var idx = $(this)
            .parent()
            .index();
          var datum = d3
            .selectAll(
              $(
                $(container)
                  .find("g.bars")
                  .children("g")[idx]
              )
                .find("rect")
                .toArray()
            )
            .datum();
          tooltip.displayList(
            datum,
            "category",
            "lineMeasure",
            columnMap,
            d3.event,
            "colour",
            d.name
          ); // Show tooltip

          let mousePosition = { x: window.event.pageX, y: window.event.pageY }

          createTrigger(
            actions,
            columnMap,
            container,
            "pointHover",
            d,
            THIS.props.plugin.id,
            THIS.props.interactions,
            THIS.props.navigations,
            mousePosition
          ); // Trigger hover point action
        })
        .on("mouseout", function (d, i) {
          revertPointColour(this); // Revert colours
          tooltip.hide(); // Hide tooltip
        });

      if (animate) {
        points
          .attr("cy", function (d, i) {
            return y2(0);
          })
          .transition()
          .attr("cy", function (d, i) {
            return y2(d.value);
          })
          .duration(500);
      } else
        points.attr("cy", function (d, i) {
          return y2(d.value);
        });

      // Draw lines between points
      var line = d3.svg
        .line()
        .x(function (d) {
          return x(d.category) + x.rangeBand() / 2;
        })
        .y(function (d) {
          return y2(d.value);
        });

      var flatLine = d3.svg
        .line()
        .x(function (d) {
          return x(d.category) + x.rangeBand() / 2;
        })
        .y(function (d) {
          return y2(0);
        });

      // Loop through series and plot lines between points
      for (var i = 0; i < lineNames.length; i++) {
        var measure = chartData.map(function (d) {
          return d.lineMeasure[i];
        });
        var linePath = chart
          .insert("path", "g.points")
          .datum(measure)
          .attr("class", "line")
          .attr("fill", "none")
          .attr("stroke-width", config.strokeWidth)
          .attr("stroke", colour(chartData[0].lineMeasure[i].name));

        if (animate) {
          // Animate depending on the situation
          linePath
            .attr("d", flatLine)
            .transition()
            .attr("d", line)
            .duration(500);
        } else linePath.attr("d", line);
      }
      if (config.horizontal) {
        chartObj.rotate();
        if (config.legend) legend.rotate();
      }

      var brush = d3.svg
        .brush()
        .x(fullX)
        .on("brush", function () {
          var selected = getNavSelection();
          if (selected.length > 0) sortAndRender(selected, false);
        })
        .on("brushend", function () {
          var selected = getNavSelection();

          // Trigger for brush select
          var intMap = rmvpp.actionColumnMap(["category"], columnMap, selected);
          $(chartContainer)
            .parents(".visualisation")
            .trigger("brushSelect", intMap);
        });

      function getNavSelection() {
        var maxRange = d3.max(brush.extent());
        var minRange = d3.min(brush.extent());
        var selected = rmvpp.filterOrdinalScale(
          data,
          minRange,
          maxRange,
          fullX
        );
        return selected;
      }

      if (navigator) {
        $(chartContainer)
          .find(".navigator")
          .remove();
        var navHeight = 60;
        var navFrac = navHeight / height;
        var navContainer = d3
          .select(chartContainer)
          .append("div")
          .classed("navigator", true)
          .classed("do-not-print", true);
        var navChartObj = new rmvpp.Chart(navContainer[0][0], width, navHeight); // New chart object

        navChartObj.Margin = {
          // Scale margin by navigator fraction
          top: margin.top * navFrac,
          bottom: margin.bottom * navFrac,
          left: margin.left,
          right: margin.right
        };
        navChartObj.setX("", fullX); // Set X axis
        var navChart = navChartObj.createSVG(vis); // Draw SVG
        navChartObj.drawAxes(); // Draw axes
        navChart.selectAll(".x.axis .tick").remove(); // Remove X labels

        var navGroups = navChart
          .selectAll(".navGroups")
          .data(data)
          .enter()
          .append("g")
          .attr("transform", function (d) {
            return "translate(" + x(d.category) + ",0)";
          });

        // Create bars
        navGroups
          .selectAll("g")
          .data(function (d) {
            return d.barMeasure;
          })
          .enter()
          .append("rect")
          .classed("bar", true)
          .attr("x", function (d, i) {
            return x1(d.name);
          })
          .attr("width", barWidth)
          .attr("y", function (d, i) {
            return navFrac * yPos(d);
          })
          .attr("height", function (d, i) {
            return navFrac * Math.abs(y(d.value) - y(0));
          });

        var viewport = navChart
          .append("g")
          .attr("class", "viewport")
          .call(brush)
          .selectAll("rect")
          .attr("height", navHeight - navChartObj.Margin.bottom - 2);

        if (config.horizontal) navChartObj.rotate();
      }

      if (config.horizontal) {
        var mainDiv = $(container).find(".main");
        $(container)
          .find(".navigator")
          .css("display", "inline")
          .insertBefore(mainDiv);
      }

      // Add Zoom Out button if drilled
      d3.select(chartContainer)
        .selectAll(".zoomOut")
        .remove();
      if (chartData.length != data.length) {
        d3.select(chartContainer)
          .insert("div", ":first-child")
          .style("text-align", "center")
          .classed("zoomOut", true)
          .append("i")
          .attr("class", "fa fa-2x fa-search-minus")
          .on("click", function (e) {
            brush.clear();
            d3.select(chartContainer)
              .selectAll(".navigator .viewport")
              .call(brush);

            let mousePosition = { x: e.event.pageX, y: e.event.pageY }

            createTrigger(
              actions,
              columnMap,
              container,
              "brushSelect",
              data,
              THIS.props.plugin.id,
              THIS.props.interactions,
              THIS.props.navigations,
              mousePosition
            );
            sortAndRender(data, true, true);
          });
      }

      // Deselect bars
      function deselectBars() {
        var selectedBars = chart.selectAll("rect.bar.selected");
        selectedBars.classed("selected", false);
        revertColour(selectedBars);
      }

      // Highlight markers in a group
      function highlightPointGroup(element) {
        d3.selectAll(
          $(element)
            .parent()
            .find("circle.marker")
            .toArray()
        )
          .transition()
          .attr("fill", function (d) {
            return rmvpp.reduceBrightness(d.colour, 15);
          })
          .attr("r", d3.max([3, +config.pointSize + 2]))
          .duration(200);
      }

      // Revert colour of markers
      function revertPointColour(element) {
        d3.selectAll(
          $(element)
            .parent()
            .find("circle.marker")
            .toArray()
        )
          .transition()
          .attr("fill", function (d) {
            return d.colour;
          })
          .attr("r", +config.pointSize)
          .duration(200);
      }
    }

    $(document).ready(function () {
      if (config.toggleCriteria) {
        var splitCriteria = config.toggleCriteria.split("&");

        var show = true;

        for (var j = 0; j < splitCriteria.length; j++) {
          var splittedCriterium = splitCriteria[j].split(/(<|>|=)/);
          var filterField = splittedCriterium[0];
          var operator = splittedCriterium[1];
          var criteriaLength = parseInt(splittedCriterium[2]);

          var length = 0;

          for (var i = 0; i < filters.length; i++) {
            if (filters[i].Name === filterField) {
              length = filters[i].Value.length;
              break;
            }
          }

          if (
            (operator === ">" && length > criteriaLength) ||
            (operator === "=" && length === criteriaLength) ||
            (operator === "<" && length < criteriaLength)
          ) {
          } else {
            show = false;
            break;
          }
        }

        if (show) {
          $(container)
            .find(".bar-chart")
            .parent()
            .show();
          $(container)
            .find(".sortBar")
            .show();
        } else {
          $(container)
            .find(".bar-chart")
            .parent()
            .hide();
          $(container)
            .find(".sortBar")
            .hide();
        }
      }
    });

    if (config.showHideButton) {
      let hideButtonToggleKey = [".sortBar", ".bar-chart"];
      rmvpp.hideButton(container, hideButtonToggleKey);
    }

    this.props.setPluginRerender(false, this.props.plugin.id, false, this.props.plugin.isInteraction);
  };

  currentHeight;
  lastContent = undefined;

  updateLastContent = (status) => {
    this.lastContent = status
  }

  render() {
    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    let navigationComponent = null;
    if (this.props.navigationComponentVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      navigationComponent = renderNavigation(
        popupPosition,
        this.props,
        this.getNavigationComponent
      );
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };
    
    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + this.props.plugin.id).css("padding")
      );

      pluginConfig.height =
        this.calculatePluginHeight(this.props.plugin, this.props.settings) -
        pluginContainerPadding * 2;

      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      );
    }

    return (
      <>
        <div style={{height: "100%"}}>
          <div id={this.props.plugin.id} className="lineBarChart"></div>
          {renderContent(
            isRerender,
            this.pluginRender,
            this.props.plugin,
            data,
            columnMap,
            pluginConfig,
            condFormats,
            this.props.setPluginRerender,
            this.lastContent,
            this.updateLastContent,
          )}
          {configComponent}
          {dataComponent}
          {navigationComponent}
        </div>
      </>
    );
  }
}

function getAncestors(node) {
  var path = [];
  var current = node;
  while (current.parent) {
    path.unshift(current);
    current = current.parent;
  }
  return path;
}
