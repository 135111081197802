import React, { Component } from "react";
import { Tooltip } from "antd";
import { InteractionOutlined } from "@ant-design/icons";
import i18n from "../../../Utils/i18next"

/**
 * Plugin tools interaction button component
 */
export default class InteractionButton extends Component {
  interactionButtonActive = () => {
    let interactionObject = {
      plugin: this.props.plugin,
    };

    this.props.setActiveInteractionPlugin(interactionObject);
  };

  render() {
    const classNameControl = this.props.iconsClassNameControl
    let isPluginHeightOne = this.props.isPluginHeightOne
    let isPluginWidthOne = this.props.isPluginWidthOne

    return (
      <Tooltip
        title={i18n.t("Interaction.Interaction")}>
        <InteractionOutlined
          name={"interactPluginButton"}
          onClick={() => this.interactionButtonActive()}
          className={classNameControl(isPluginHeightOne, isPluginWidthOne)}
          style={{ cursor: "pointer", marginRight: "0" }}
        />
      </Tooltip>
    );
  }
}
