import { GET_MODEL } from "./ModelContentAction";

export default function ModelContentReducer(state = {
    modelObject: {}
}, action) {

    switch (action.type) {

        case GET_MODEL:
        console.log("reducer: ", state.modelObject)

            return {
                ...state,
                modelObject: action.payload
            }
        default:
            return state;
    }
}