import React, { Component } from "react";
import { Select } from "antd";
import { fontTypes } from "../Configuration/GeneralConfigurationParameters";

const { Option } = Select;

/**
 * **Font Type Selector Component**
 *  
 * **Props:**
 *  - placeholder: placeholder of the component
 *  - onChange: function for changing font type
 *  - value: stores the selected font
 * 
 * Fonts are stored in an array called {@link fontTypes}
 */
export default class FontTypeSelector extends Component {
    render() { 
        return (
            <Select
                showSearch
                style={{ width: "100%", fontFamily: this.props.value }}
                optionFilterProp="children"
                placeholder={this.props.placeholder}
                onChange={this.props.onChange}
                value={this.props.value}
                getPopupContainer={trigger => trigger.parentNode}
                dropdownMenuStyle={{textAlign:"left"}}
            >
                { 
                    fontTypes.map((fontType, index) => {
                        return (
                            <Option
                                name={fontType.name}
                                value={fontType.value}
                                key={fontType.value}
                                style={{fontFamily: fontType.value}}
                            >
                                {fontType.value}
                            </Option>
                        )
                    })
                }
            </Select>
        );
    }
}