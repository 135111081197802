import { SwapOutlined } from "@ant-design/icons";
import React, { Component } from "react";
import i18n from "../../../Utils/i18next";
import Tooltip from "../../GeneralComponents/Tooltip/Tooltip";

/**
 * Creates a button on the plugin for navigation and opens a popup for navigation crud operations
 */
export default class NavigationButton extends Component {
  /**
   * Creates the plugin's navigation popup according to the given variables
   */
  pluginNavigationOpen = () => {
    this.props.changeNavigationVisibility(true);
  };

  render() {
    const classNameControl = this.props.iconsClassNameControl;
    let isPluginHeightOne = this.props.isPluginHeightOne;
    let isPluginWidthOne = this.props.isPluginWidthOne;

    return (
      <Tooltip tooltip={i18n.t("NavigationTitle")}>
        <SwapOutlined
          name={"navigatePluginButton"}
          className={classNameControl(isPluginHeightOne, isPluginWidthOne)}
          style={{ cursor: "pointer" }}
          onClick={() => this.pluginNavigationOpen()}
        />
      </Tooltip>
    );
  }
}
