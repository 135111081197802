export const SET_MINIMIZE = "SET_MINIMIZE";
export const SET_OPERATION_STATUS = "SET_OPERATION_STATUS";
/**
 *
 * @param {*} status
 * Action for file upload minimize status
 */
export function setMinimize(status) {
  return dispatch => {
    dispatch({
      type: SET_MINIMIZE,
      payload: status
    });
  };
}

/**
 *
 * @param {*} status - NOT_STARTER - UPLOADING - PROCESSING - DONE - FAILED
 * @param {*} status
 * Action for file upload status
 */
export function setFileUploadOperationAndStatus(status, progress) {
  return dispatch => {
    dispatch({
      type: SET_OPERATION_STATUS,
      payload: {status, progress}
    });
  };
}
