import "antd/dist/antd.css";
import "../index.css";
import "./AdminPage.css";

import React, { Component } from "react";
import { Layout, Icon } from "antd";
import { Switch, Route } from "react-router-dom";

import SideBar from "../SideBar/SideBar";
import TopBar from "../TopBar/TopBar";
import DataSourcePage from "../DataSourcePage/DataSourcePage";
import HomePage from '../HomePage/HomePage'
import SplashScreen from '../SplashScreen/SplashScreen'
import UserPage from "../UserPage/UserPage";
import UserRolePage from "../UserRole/UserRolePage";
import AssignPage from "../RoleAssignToUser/AssignPage";
import CatalogObjectAuthorization from "../CatalogObjectAuthorization/AuthorizationPage";

const { Content, Footer } = Layout;

class AdminPage extends Component {
  render() {
    return (
      <Layout className="layout" hasSider={true} style={{ minHeight: "100vh" }}>
        <SplashScreen />
        <SideBar />
        <Layout>
          <TopBar />
          <Content className="content" style={{ margin: "0 16px" }}>
            <Switch>
              <Route exact path="/admin" component={HomePage} />
              <Route path="/admin/modelling" component={DataSourcePage} />
              <Route path="/admin/users" component={UserPage} />
              <Route path="/admin/user-roles" component={UserRolePage} />
              <Route path="/admin/assign-roles" component={AssignPage} />
              <Route path="/admin/catalog-object-authorization" component={CatalogObjectAuthorization} />
            </Switch>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            Vispeahen Admin Page <Icon type="copyright" /> 2019
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

export default AdminPage;
