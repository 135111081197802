import { Checkbox, Input } from "antd";
import React, { Component } from "react";
import { FiCheck, FiEdit2, FiX } from "react-icons/fi";
import i18n from "../../Utils/i18next";
import { showNotificationWithIcon } from "../../Utils/Notification";
import { AiOutlineTool } from "react-icons/ai";
import Tooltip from "../GeneralComponents/Tooltip/Tooltip";
export default class UploadTableColumn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      newDisplayName: "",
    };
  }

  headerItemClassName = (status) => {
    let className = "header-item";

    if (!status) {
      className += " disabled";
    }

    return className;
  };

  nameValidation = () => {
    let name = this.state.newDisplayName;

    if (name === "") {
      return {
        status: false,
        message: i18n.t("FileUpload.ColumnNameCouldNotBeEmpty"),
      };
    }

    if (
      name.includes('"') ||
      name.includes(".") ||
      name.includes("”") ||
      name.includes("'")
    ) {
      return {
        status: false,
        message: i18n.t("FileUpload.ColumnNameCouldNotIncludesSpecialChars"),
      };
    }

    return { status: true };
  };

  applyStatusToColumn = (sheet, columnKey, status) => {
    this.props.applyChangesToColumnInSheet({
      sheetName: sheet.name,
      columnKey: columnKey,
      changes: { status: status },
    });
  };

  applyNewNameToColumn = (sheet, columnKey) => {
    let newNameIsValid = this.nameValidation();

    if (newNameIsValid.status) {
      this.props.applyChangesToColumnInSheet({
        sheetName: sheet.name,
        columnKey: columnKey,
        changes: { displayName: this.state.newDisplayName, validation: true },
      });

      this.setState({ editMode: false, newDisplayName: "" });
    } else {
      showNotificationWithIcon(
        i18n.t("Error"),
        newNameIsValid.message,
        "error"
      );
    }
  };

  /**
   * Replace special chars in column name to _
   * @param {*} sheet 
   * @param {*} columnKey 
   * @param {*} displayName 
   */
  fixColumnName = (sheet, columnKey, displayName) => {
    let newDisplayName = displayName;

    newDisplayName = newDisplayName
      .replaceAll('"', "_")
      .replaceAll(".", "_")
      .replaceAll("”", "_")
      .replaceAll("'", "_");

    this.setState(
      {
        ...this.state,
        newDisplayName: newDisplayName,
      },
      () => this.applyNewNameToColumn(sheet, columnKey)
    );
  };

  render() {
    const { sheet, columnKey } = this.props;
    const { editMode, newDisplayName } = this.state;
    let headerStyle = {
      width: 100 / sheet.data[0].length + "%",
      backgroundColor: "#225f8f",
    };

    let isValidColumnName = sheet.columns[columnKey].validation;

    if (isValidColumnName === false) {
      headerStyle["backgroundColor"] = "#c13131";
    }

    return (
      <div
        className={this.headerItemClassName(sheet.columns[columnKey].status)}
        style={{ ...headerStyle }}
      >
        <Checkbox
          style={{ marginRight: "6px", position: "relative", top: "-3px" }}
          checked={sheet.columns[columnKey].status}
          onChange={(e) =>
            this.applyStatusToColumn(sheet, columnKey, e.target.checked)
          }
        />

        <span
          className="sheet-item-text"
          style={{
            top: "4px",
            width: isValidColumnName
              ? "calc(100% - 40px)"
              : "calc(100% - 80px)",
          }}
        >
          {sheet.columns[columnKey].displayName}
        </span>

        {editMode ? (
          <div className="update-table-columns-edit-mode">
            <Input
              className="sheet-name-input"
              style={{ width: "75%" }}
              value={newDisplayName}
              onPressEnter={() => this.applyNewNameToColumn(sheet, columnKey)}
              onChange={(e) => {
                this.setState({ newDisplayName: e.target.value });
              }}
            />

            <FiX
              className="sheet-item-edit"
              style={{ color: "#c86666" }}
              onClick={() => {
                this.setState({ editMode: false, newDisplayName: "" });
              }}
            />

            <FiCheck
              className="sheet-item-edit"
              style={{ color: "#549154", marginRight: "1px" }}
              onClick={() => this.applyNewNameToColumn(sheet, columnKey)}
            />
          </div>
        ) : (
          <>
            {!isValidColumnName && (
              <Tooltip tooltip={i18n.t("FileUpload.FixColumnName")}>
                <AiOutlineTool
                  className="sheet-item-edit"
                  onClick={() =>
                    this.fixColumnName(
                      sheet,
                      columnKey,
                      sheet.columns[columnKey].displayName
                    )
                  }
                />
              </Tooltip>
            )}

            <FiEdit2
              className="sheet-item-edit"
              onClick={() => {
                this.setState({
                  editMode: true,
                  newDisplayName: sheet.columns[columnKey].displayName,
                });
              }}
            />
          </>
        )}
      </div>
    );
  }
}
