import React, { Component } from "react";
import ThemePerColour from "./ThemePerColour";
import { Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { showNotificationWithIcon } from "../../../Utils/Notification";
import i18n from "../../../Utils/i18next";

export default class ThemePalette extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentIndex: -1,
    };
  }

  /* Changing the current index for open only one color picker */
  changeCurrentIndex = (index) => {
    this.setState({
      currentIndex: index,
    });
  };

  /** Adding colour to palette function */
  addColourToPalette = () => {
    let paletteColors = [...this.props.colours];
    //This func. returns random colour, we use this for adding a random colour to palette.
    const getRandomColor = () => {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };
    let randomColor = getRandomColor();

    paletteColors[paletteColors.length] = randomColor;
    this.props.update({ type: "colours", value: paletteColors });
  };

  /* Remove colour to palette colours array */
  removeColour = (index) => {
    let paletteColors = [...this.props.colours];
    //This control for if user tries to delete all colours.
    if (paletteColors.length < 2) {
      showNotificationWithIcon(
        i18n.t("Error"),
        i18n.t("Errors.MinimumColorValue"),
        "error"
      );
    } else if (paletteColors.length >= 2) {
      if (this.props.pluginName === "heatmap" && paletteColors.length > 5) {
        paletteColors.splice(index, 1);
        this.props.update({ type: "colours", value: paletteColors });
      } else if (this.props.pluginName == undefined) {
        paletteColors.splice(index, 1);
        this.props.update({ type: "colours", value: paletteColors });
      } else {
        showNotificationWithIcon(
          i18n.t("Error"),
          i18n.t("Errors.HeatmapNeedFiveColour"),
          "error"
        );
      }
    }
  };

  /* Changing a specific colour to palette function*/
  paletteColourChange = (color, index) => {
    let paletteColors = [...this.props.colours];

    paletteColors[index] = color;
    this.props.update({ type: "colours", value: paletteColors });
  };

  render() {
    return (
      <div className={"theme-palette"}>
        <Row gutter={6}>
          <Col span={21} style={{ paddingTop: "3.5px" }}>
            {this.props.colours.map((colour, i) => (
              <ThemePerColour
                id={i}
                colour={colour}
                index={i}
                coloursCount={this.props.colours.length}
                currentIndex={this.state.currentIndex}
                paletteColourChange={this.paletteColourChange}
                changeCurrentIndex={this.changeCurrentIndex}
                removeColour={this.removeColour}
              />
            ))}
          </Col>
          <Col
            span={3}
            name={"nextColor"}
            style={{ marginTop: "3.5px", cursor: "pointer" }}
            onClick={this.addColourToPalette}
          >
            <PlusOutlined className={"circle-icon"} />
          </Col>
        </Row>
      </div>
    );
  }
}
