import React, { Component } from "react";
import { get } from "../Utils/WebService";
import { API_BASE } from "../config";
import { showNotificationWithIcon } from "../Utils/Notification";
import { Card, Menu, Dropdown, Button, Icon, Tooltip } from "antd";

const accessTypes = { role: "ROLE", user: "USER" };
class RolesUserCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataList: [],
      currentAccessType: ""
    };
  }
  selectAccessType = e => {
    let path =
      e.key == accessTypes.role
        ? "role"
        : e.key == accessTypes.user
        ? "user"
        : "";
    this.getDataByAccessType(path, e.key);
  };

  confirm = data => {
    this.props.confirm(data);
  };

  getDataByAccessType = (path, accessType) => {
    if (path != "") {
      let url = `${API_BASE}/${path}`;

      const successFunc = result => {
        this.setState({
          dataList: [...result.data],
          currentAccessType: accessType
        });

        this.props.confirm(result.data, accessType);
      };

      const errorFunc = error => {
        showNotificationWithIcon("ERROR!", error.response.data, "error");
      };

      get(url, successFunc, errorFunc);
    } else {
      showNotificationWithIcon("Error!", "Wrong access type!", "error");
    }
  };

  menu = (
    <Menu>
      <Menu.Item onClick={this.selectAccessType} key={accessTypes.role}>
        ROLE
      </Menu.Item>
      <Menu.Item onClick={this.selectAccessType} key={accessTypes.user}>
        USER
      </Menu.Item>
    </Menu>
  );

  render() {
    return (
      <Card
        title={this.props.title}
        style={{
          overflow: "auto",
          overflowX: "hidden",
          maxHeight: "637px"
        }}
        className={"styledScrollBar"}
        extra={
          this.props.dropdown == true ? (
            <Tooltip title="Please select a catalog object">
              <Dropdown
                overlay={this.menu}
                disabled={this.props.selectedId == "" ? true : false}
                placement="bottomLeft"
              >
                <Button type={"primary"}>
                  Access Type
                  <Icon
                    type="down"
                    style={{ float: "right", marginTop: "5px" }}
                  />
                </Button>
              </Dropdown>
            </Tooltip>
          ) : (
            ""
          )
        }
      >
        {this.props.content}
      </Card>
    );
  }
}

export default RolesUserCard;
