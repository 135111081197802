
import { showNotificationWithIcon } from "./Notification"
import i18n from "./i18next"
import { aggregatableDataTypes, dateDataTypes } from "../ui/Plugins/DataComponents/DataConfigure"
import $ from "jquery";
import { deepCopy } from "../Utils/Global";
import { checkAndFindDataSourceKeyToColumn } from "../ui/Plugins/PluginComponents/columnDataSourceKeyOperations";
import { store } from "..";
import { changeDisabledColumnsStatus } from "../ui/GeneralComponents/CustomDashboard/CustomDashboardAction";
import { getPluginsAllColumnsByField } from "../Utils/PluginOperations";

export const dataColumnOnDrop = (data, mapObject, key, aggr = false, THIS) => {
  let pluginId = THIS.state.pluginId;
  let proccessingData = JSON.parse(data[key]);
  let columnMap = deepCopy(THIS.state.columnMap);
  let tableAliasName = proccessingData.tableAliasName;
  let dataSourceKey = proccessingData.dataSourceKey;
  let tableDisplayName = proccessingData.tableDisplayName;
  let column = proccessingData.column;
  
  let isCountOrCountDistinct = column.aggrRule?.toLowerCase().startsWith("count(")?true:false;
  let isDraggedColumnSuitableForMeasureArea = !((aggr === true && aggregatableDataTypes.has(column.dataType)) || aggr === false || (aggr == true && isCountOrCountDistinct))

  /** Check the valid column for dropped area. */
  if (isDraggedColumnSuitableForMeasureArea) {
    showNotificationWithIcon(
      i18n.t("Warning"),
      i18n.t("Errors.MeasureAreaMustBeMeasure"),
      "warning"
    );
  }

  /** Check the valid column for dropped area. */
  if (mapObject === "date" && !dateDataTypes.has(column.dataType)) {
    showNotificationWithIcon(
      i18n.t("Warning"),
      i18n.t("Errors.DateAreaMustBeDate"),
      "warning"
    );
  }

  let uniqeColumnId = proccessingData.uniqeColumnId;
  let sortedList = deepCopy(THIS.props.sortedColumnList);

  column.tableAliasName = tableAliasName;
  column.dataSourceKey = checkAndFindDataSourceKeyToColumn(dataSourceKey, tableAliasName, THIS.state.model);
  column.tableDisplayName = tableDisplayName;
  column.uniqeColumnId = uniqeColumnId;
  column.locationFieldName = mapObject;

  if (!columnMap[mapObject].multiple) {
    if (columnMap[mapObject].data.length > 0) {
      sortedList = deepCopy(
        THIS.fillSortedList(columnMap[mapObject].data[0], false)
      );
    }

    columnMap[mapObject].data = [];
  }

  let reduxState = store.getState();
  let drillDowns = reduxState.DrillDownReducer.drillDowns;
  let allDrillDownColumnsInPlugin = drillDowns?.get(pluginId)?.allDrillDownColumnsInPlugin?.values() || [];

  sortedList = sortedList || [];

  let columnIds = sortedList.map(c => c.uniqeColumnId);

  allDrillDownColumnsInPlugin = [...allDrillDownColumnsInPlugin].flat();
  allDrillDownColumnsInPlugin = allDrillDownColumnsInPlugin.filter(c => c.drillDownParentColumnId && columnIds.includes(c.drillDownParentColumnId));
  
  let gettedCols = getPluginsAllColumnsByField(columnMap);
  let allColumns = [...gettedCols, ...allDrillDownColumnsInPlugin];
  let columnDisplayNames = allColumns?.map(c => c.displayName);
  
  if (columnDisplayNames?.includes(column.displayName)) {
    let number = 1;

    while (columnDisplayNames.includes(`${column.displayName} (${number})`)) {
      number++;
    }

    column.displayName = `${column.displayName} (${number})`;
  }

  if (mapObject === "hidden") {
    column.isDisabledColumn = true;

    let reduxState = store.getState();
    let disabledColumnHash = new Set(reduxState.CustomDashboardReducer.disabledColumnsMap)

    disabledColumnHash.add(uniqeColumnId)

    store.dispatch(changeDisabledColumnsStatus(disabledColumnHash))
  }

  columnMap[mapObject].data.push(column);
  sortedList = deepCopy(THIS.fillSortedList(column, true, sortedList));

  /** adjusts the height of the table & data to the column map area */
  let tableColumnHeight =
    $("#column-map-area").height() + 45; /* 45px to adjust */

  THIS.setState({
    ...THIS.state,
    columnMap: columnMap,
    sortedColumnList: sortedList,
    tableColumnHeight: tableColumnHeight
  });

  THIS.props.updateColumnMap("columnMap", {
    columnMap: columnMap,
    sortedColumnList: sortedList,
    pluginId: THIS.state.pluginId
  });
}