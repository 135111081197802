export const FORMULA_EDITOR_CONTENT = "FORMULA_EDITOR_CONTENT";

/**
 * 
 * @param contentObject 
 * @returns 
 * 
 * Sets Formula Editor content
 */
export const setFormulaEditorContent = (contentObject) => {
  return (dispatch) => {
    dispatch({
      type: FORMULA_EDITOR_CONTENT,
      payload: contentObject,
    });
  };
}
