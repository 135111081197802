import {
  BgColorsOutlined,
  EyeInvisibleOutlined,
  FontSizeOutlined,
  HeatMapOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import { Button, Checkbox, Col, Divider, Input, Row, Select, Tooltip } from "antd";
import $ from "jquery";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import React, { Component } from "react";
import i18n from "../../../../Utils/i18next";
import Text from "../../../GeneralComponents/Text/Text";
import ThemePalette from "../../../GeneralComponents/ThemePalette/ThemePalette";
import TitleSettingsOfPlugin from "../../../GeneralComponents/TitleSettingsOfPlugin/TitleSettingsOfPlugin";
import { InsightsConfig } from "../../RenderJs/config";
import ReturnToTheme from "../../PluginToolsComponents/ReturnToTheme";
import { themeFields } from "../common";

const { TextArea } = Input;
const { Option } = Select;

export default class LineBarChartConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: this.props.config.width,
      height: this.props.config.height,
      backgroundColor: this.props.config.backgroundColor,
      title: this.props.config.title, //added title and titleAlign props
      titleAlign: this.props.config.titleAlign,
      xTitle: this.props.config.xTitle,
      yTitle: this.props.config.yTitle,
      y2Title: this.props.config.y2Title,
      colours: this.props.config.colours,
      pointSize: this.props.config.pointSize,
      strokeWidth: this.props.config.strokeWidth,
      horizontal: this.props.config.horizontal,
      stacked: this.props.config.stacked,
      brushNav: this.props.config.brushNav,
      legend: this.props.config.legend,
      sortControl: this.props.config.sortControl,
      sortDirDefault: this.props.config.sortDirDefault,
      sortColDefault: this.props.config.sortColDefault,
      showHideButton: this.props.config.showHideButton,
      colorChange: false,
      backgroundColorChange: false,
      paletteColours: InsightsConfig.Palettes[this.props.config.colours]
        ? InsightsConfig.Palettes[this.props.config.colours]
        : this.props.config.colours,
      titleFontStyle: this.props.config.titleFontStyle === undefined ? false : this.props.config.titleFontStyle,
      titleFontWeight: this.props.config.titleFontWeight === undefined ? false : this.props.config.titleFontWeight,
      titleFontSize: this.props.config.titleFontSize === undefined ? 15 : this.props.config.titleFontSize,
      titleFont: this.props.config.titleFont === undefined ? "Verdana" : this.props.config.titleFont,
      titleColour: this.props.config.titleColour === undefined ? "black" : this.props.config.titleColour,
      titleTextDecor: this.props.config.titleTextDecor === undefined ? false : this.props.config.titleTextDecor,
      changedTitleFontSize: this.props.config.changedTitleFontSize === undefined ? 15 : this.props.config.changedTitleFontSize,
      keyForTitleSize: null,
      noDataTitle: this.props.config.noDataTitle === undefined ? "" : this.props.config.noDataTitle
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      this.setState({
        size: nextProps.config.size,
        width: nextProps.config.width,
        height: nextProps.config.height,
        backgroundColor: nextProps.config.backgroundColor,
        title: nextProps.config.title, //added title and titleAlign props
        titleAlign: nextProps.config.titleAlign,
        xTitle: nextProps.config.xTitle,
        yTitle: nextProps.config.yTitle,
        y2Title: nextProps.config.y2Title,
        colours: nextProps.config.colours,
        pointSize: nextProps.config.pointSize,
        strokeWidth: nextProps.config.strokeWidth,
        horizontal: nextProps.config.horizontal,
        stacked: nextProps.config.stacked,
        brushNav: nextProps.config.brushNav,
        legend: nextProps.config.legend,
        sortControl: nextProps.config.sortControl,
        sortDirDefault: nextProps.config.sortDirDefault,
        sortColDefault: nextProps.config.sortColDefault,
        showHideButton: nextProps.config.showHideButton,
        colorChange: false,
        backgroundColorChange: false,
        paletteColours: InsightsConfig.Palettes[nextProps.config.colours]
          ? InsightsConfig.Palettes[nextProps.config.colours]
          : nextProps.config.colours,
        titleFont: nextProps.config.titleFont === undefined ? "Verdana" : nextProps.config.titleFont,
        titleFontSize: nextProps.config.titleFontSize === undefined ? 15 : nextProps.config.titleFontSize,
        changedTitleFontSize: nextProps.config.changedTitleFontSize === undefined ? 15 : nextProps.config.changedTitleFontSize,
        titleFontStyle: nextProps.config.titleFontStyle === undefined ? false : nextProps.config.titleFontStyle,
        titleFontWeight: nextProps.config.titleFontWeight === undefined ? false : nextProps.config.titleFontWeight,
        titleTextDecor: nextProps.config.titleTextDecor === undefined ? false : nextProps.config.titleTextDecor,
        titleColour: nextProps.config.titleColour === undefined ? "black" : nextProps.config.titleColour,
        noDataTitle: nextProps.config.noDataTitle === undefined ? "" : nextProps.config.noDataTitle
      });
    }
  }

  //sets defaults we get from plugin.jsx. callback for fontsizekey because it should keep changing to trigger default revert
  setDefaultsForTitle = () => {
    let newConfig = this.props.setDefaultForPluginTitle();

    this.setState(
      {
        ...this.state,
        title: newConfig.title,
        titleAlign: newConfig.titleAlign,
        titleFont: newConfig.titleFont,
        titleFontSize: newConfig.titleFontSize,
        titleFontStyle: newConfig.titleFontStyle,
        titleFontWeight: newConfig.titleFontWeight,
        titleTextDecor: newConfig.titleTextDecor,
        titleColour: newConfig.titleColour,
        changedTitleFontSize: newConfig.titleFontSize
      }, () => {
        this.setConfigToPlugin({ type: "changedTitleFontSize", value: newConfig.titleFontSize })
      }
    );

    if (newConfig.titleFontSize > 10 || newConfig.titleFontSize < 30) {
      $("#titleFontSize").css("background", "")
    }
  }

  /*
  * Changes both of constant and changed value 
  * constant = between 10 and 30 values
  * changed value = all of values
  */
  handleChangeBothOfValues = (constantValue, changedValue) => {
    this.setState({
      ...this.state,
      titleFontSize: constantValue,
      changedTitleFontSize: changedValue
    })

    this.setConfigToPlugin(
      [
        {
          type: "titleFontSize",
          value: constantValue
        },
        {
          type: "changedTitleFontSize",
          value: changedValue
        }
      ]);
  }

  titleColourChangeVisible = () => {
    this.setState({
      ...this.state,
      titleColourChange: !this.state.titleColourChange
    });
  };

  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.value });
  };

  handleCheck = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.checked });
  };

  setConfigToPlugin = (changedObj, propertyListToDelete = undefined) => {
    let tempState = { ...this.state };

    if (Array.isArray(changedObj)) {
      for (let i = 0; i < changedObj.length; i++) {
        let object = changedObj[i];
        tempState[object.type] = object.value;
      }
    } else {
      tempState[changedObj.type] = changedObj.value;
    }

    if (propertyListToDelete !== undefined) {
      for (let item of propertyListToDelete) {
        delete tempState[item]
      }
    }

    this.props.updateConfig(
      tempState,
      this.props.pluginId,
      propertyListToDelete
    );
  };

  iconOnChange = value => {
    this.setState({
      ...this.state,
      icon: value
    });

    this.setConfigToPlugin({ type: "icon", value: value });
  };

  coloursOnChange = value => {
    this.setState({
      ...this.state,
      colours: value
    });

    this.setConfigToPlugin({ type: "colours", value: value });
  };

  titleAlignOnChange = value => {
    this.setState({
      ...this.state,
      titleAlign: value
    });

    this.setConfigToPlugin({ type: "titleAlign", value: value });
  };

  colorChangeVisible = () => {
    this.setState({ ...this.state, colorChange: !this.state.colorChange });
  };

  backgroundColorOnChange = obj => {
    this.setState({
      ...this.state,
      backgroundColor: obj.color
    });

    this.setConfigToPlugin({ type: "backgroundColor", value: obj.color });
  };

  backgroundColorChangeVisible = () => {
    this.setState({
      ...this.state,
      backgroundColorChange: !this.state.backgroundColorChange
    });
  };

  sortDirDefaultOnChange = value => {
    this.setState({
      ...this.state,
      sortDirDefault: value
    });

    this.setConfigToPlugin({ type: "sortDirDefault", value: value });
  };

  render() {

    return (
      <>
        <ReturnToTheme
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          preservedConfigFieldsArray={this.props.plugin.preservedConfigFields ? Array.from(this.props.plugin.preservedConfigFields) : []}
        />

        <div style={{ color: "#505050" }}>
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Configuration.Fields.AxisConfiguration")}
          </Divider>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <HeatMapOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.XTitle")}
              </Text>
            </Col>
            <Col span={12}>
              <Input
                id={"xTitle"}
                defaultValue={this.state.xTitle}
                value={this.state.xTitle}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <HeatMapOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.YTitle")}
              </Text>
            </Col>
            <Col span={12}>
              <Input
                id={"yTitle"}
                defaultValue={this.state.yTitle}
                value={this.state.yTitle}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <HeatMapOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.SecondYTitle")}
              </Text>
            </Col>
            <Col span={12}>
              <Input
                id={"y2Title"}
                defaultValue={this.state.y2Title}
                value={this.state.y2Title}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Configuration.Fields.Sorting")}
          </Divider>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.ShowSortControl")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"sortControl"}
                defaultChecked={this.state.sortControl}
                checked={this.state.sortControl}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.DefaultSortDirection")}
              </Text>
              <Text
                style={{
                  display: "block",
                  fontSize: "13px",
                  color: "#b5b4b4",
                  position: "relative",
                  top: "-4px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.DefaultSortColumn")}
              </Text>
            </Col>
            <Col span={12}>
              <Input
                id={"sortColDefault"}
                defaultValue={this.state.sortColDefault}
                value={this.state.sortColDefault}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.DefaultSortDirection")}
              </Text>
            </Col>
            <Col span={12}>
              <Select
                showSearch
                style={{ width: "100%" }}
                getPopupContainer={(trigger) => trigger.parentNode}
                placeholder="Select a icon"
                optionFilterProp="children"
                onChange={this.sortDirDefaultOnChange}
                defaultValue={this.state.sortDirDefault}
              >
                <Option value="Ascending">{i18n.t("Dashboard.Configuration.Fields.Ascending")}</Option>
                <Option value="Descending">{i18n.t("Dashboard.Configuration.Fields.Descending")}</Option>
              </Select>
            </Col>
          </Row>
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Configuration.Fields.GeneralAppearance")}
          </Divider>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Palette")}
              </Text>
            </Col>
            <Col span={4} style={{ paddingRight: "10px" }}>
              <Select
                id={"colours"}
                defaultValue={this.state.colours}
                value={this.state.colours}
                style={{ width: "100%" }}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={this.coloursOnChange}>
                <Option value="Flat-UI">{i18n.t("Dashboard.Configuration.Fields.FlatUI")}</Option>
                <Option value="Flat-UI-Soft">{i18n.t("Dashboard.Configuration.Fields.FlatUISoft")}</Option>
                <Option value="Cool-Scale">{i18n.t("Dashboard.Configuration.Fields.CoolScale")}</Option>
                <Option value="Warm-Scale">{i18n.t("Dashboard.Configuration.Fields.WarmScale")}</Option>
                <Option value="Heatmap">{i18n.t("Dashboard.Configuration.Fields.Heatmap")}</Option>
              </Select>
            </Col>
            <Col span={8}>
              <ThemePalette
                colours={this.state.paletteColours}
                update={this.setConfigToPlugin}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <HeatMapOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.PointSize")}
              </Text>
            </Col>
            <Col span={12}>
              <Input
                id={"pointSize"}
                defaultValue={this.state.pointSize}
                value={this.state.pointSize}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Horizontal")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"horizontal"}
                defaultChecked={this.state.horizontal}
                checked={this.state.horizontal}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.StackBar")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"stacked"}
                defaultChecked={this.state.stacked}
                checked={this.state.stacked}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.BackgroundColor")}
              </Text>
            </Col>
            <Col span={12}>
              <Button
                onClick={this.backgroundColorChangeVisible}
                style={{ width: "100%" }}
              >
                {this.state.backgroundColorChange
                  ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                  : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
              </Button>
              {this.state.backgroundColorChange ? (
                <ColorPickerPanel
                  alpha={100}
                  color={this.state.backgroundColor}
                  onChange={this.backgroundColorOnChange}
                  id={"backgroundColor"}
                  style={{ marginTop: "5px", position: "absolute", right: 0 }}
                />
              ) : null}
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.BrushNavigator")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"brushNav"}
                defaultChecked={this.state.brushNav}
                checked={this.state.brushNav}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Legend")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"legend"}
                defaultChecked={this.state.legend}
                checked={this.state.legend}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>
          <TitleSettingsOfPlugin
            plugin={this.props.plugin}
            handleChange={this.handleChange}
            commonTitleConfig={this.props.commonTitleConfig}
            updateCommonTitleConfig={this.props.updateCommonTitleConfig}
            isReturnToDefaultforTitleVisible={this.props.isReturnToDefaultforTitleVisible}
            setDefaultsForTitle={this.setDefaultsForTitle}
            title={this.state.title}
            titleAlign={this.state.titleAlign}
            titleColour={this.state.titleColour}
            titleColourChange={this.state.titleColourChange}
            titleColourChangeVisible={this.titleColourChangeVisible}
            titleFont={this.state.titleFont}
            titleFontSize={this.state.titleFontSize}
            keyForTitleSize={this.state.keyForTitleSize}
            titleFontStyle={this.state.titleFontStyle}
            titleFontWeight={this.state.titleFontWeight}
            titleTextDecor={this.state.titleTextDecor}
            setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
            currentAppliedConfig={this.props.currentAppliedConfig}
            changedTitleFontSize={this.state.changedTitleFontSize}
            handleChangeBothOfValues={this.handleChangeBothOfValues}
          />
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Configuration.Fields.Others")}
          </Divider>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left", display: "flex" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Settings.NoDataConfiguration")}
              </Text>
              <Tooltip
                title={
                  <>
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Info")} <br />
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.GraphicConfiguration")} <br />
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Settings")} <br />
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.DefaultText")} <br />
                  </>
                }
              >
                <InfoCircleOutlined style={{ position: 'relative', marginTop: '5px', marginLeft: "10px" }} />
              </Tooltip>
            </Col>
            <Col span={12}>
              <TextArea
                allowClear
                id={"noDataTitle"}
                defaultValue={this.state.noDataTitle}
                value={this.state.noDataTitle}
                onChange={this.handleChange}
                autoSize={{ minRows: 3, maxRows: 5 }}
                placeholder={i18n.t("NoDataContent.NoData") + " " +
                  i18n.t("NoDataContent.PluginCouldNotBeVisualized")}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
