import React, { Component } from "react";
import { Accordion, Card } from "react-bootstrap";
import { Tooltip } from "antd";
import "./accordion.css";
import Text from "../Text/Text";
import PropTypes from "prop-types";
import AccordionToggle from "./AccordionToggle";

/**
 * AccordionContent component uses with Accordion component.
 * Inludes holder, header and collapse.
 * Holder: container of header and collapse
 * Header: title and extra buttons. Open-close collapse button.
 * Collapse: content holder. Can take component.
 */
export default class AccordionContent extends Component {
  render() {
    let tooltipClassName = `accordion-content-tooltip${this.props.pluginId ? ' accordion-content-' + this.props.pluginId : ''}`;

    let holderClass = `accordion-holder` + this.props.holderClass
      ? "accordion-holder " + this.props.holderClass
      : "accordion-holder";

    let headerClass = this.props.headerClass
      ? "accordion-header " + this.props.headerClass
      : "accordion-header";

    let titleClass = this.props.titleClass
      ? "collapse-title " + this.props.titleClass
      : "collapse-title";

    let collapseClass = this.props.collapseClass
      ? "accordion-collapse " + this.props.collapseClass
      : "accordion-collapse ";

    return (
      <div
        className={holderClass}
        style={this.props.holderStyle ? this.props.holderStyle : null}
      >
        <Tooltip
          placement={this.props.tooltipPlacement ? this.props.tooltipPlacement : "topLeft"}
          title={this.props.title}
          overlayClassName={tooltipClassName}
          className={tooltipClassName}
        >
          <AccordionToggle
            eventKey={this.props.eventKey}
            draggable={this.props.draggable}
            onDragStart={this.props.onDragStart}
            onDragEnd={this.props.onDragEnd}
            onClick={() => this.props.onClick instanceof Function && this.props.onClick()}
            onDoubleClick={this.props.onDblClick}
            className={headerClass}
            style={this.props.headerStyle ? this.props.headerStyle : null}
          >
            {
              this.props.arrow !== false ? (
                <div className={"collapse-icon"}>
                  <i className={this.props.arrowClass ? this.props.arrowClass : "fas fa-chevron-down"}></i>
                </div>
              ) : null
            }
            <div className={titleClass}>
              {this.props.icon ? this.props.icon : null}
              <Text
                id={this.props.title}
                type={"span"}
                style={{ width: "89%" }}
                className={"text-shorten"}
              >
                {this.props.title}
              </Text>
            </div>
          </AccordionToggle>
          <div className={"accordion-extra"}>
            {this.props.extra ? this.props.extra : null}
          </div>
        </Tooltip>
        <Accordion.Collapse
          className={collapseClass}
          style={this.props.collapseStyle ? this.props.collapseStyle : null}
          eventKey={this.props.eventKey}
          key={this.props.key}
        >
          <Card.Body>{this.props.children}</Card.Body>
        </Accordion.Collapse>
      </div>
    );
  }
}

AccordionContent.prototypes = {
  holderClass: PropTypes.string,
  holderStyle: PropTypes.object,
  headerClass: PropTypes.string,
  headerStyle: PropTypes.object,
  collapseClass: PropTypes.string,
  collapseStyle: PropTypes.object,
  titleClass: PropTypes.string,
  eventKey: PropTypes.string,
  arrow: PropTypes.bool,
  icon: PropTypes.object,
  title: PropTypes.string,
  extra: PropTypes.object,
};
