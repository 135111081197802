import { createCookie } from "./Global";
import { get } from "./WebService";
import { API_BASE } from "../config";

/** Set cookie customization if exist */
export const setCookieToLoginCustomization = customization => {
  if (customization) {
    customization = JSON.stringify(customization);
  }

  createCookie(
    "loginCustomization",
    customization,
    null,
    "/"
  );
};

/**
 * Get user settings for login settings.
 * Cookies are set by these settings.
 */
export const getCustomization = (token) => {
  let url = `${API_BASE}/loginCustomization`;
  let headerWithToken = {common: {"Authorization": `Bearer ` + token.access_token}};

  //GET loginCustomization and save it to the cookie for future usage purposes
  const successFunc = result => {
    setCookieToLoginCustomization(result.data);
  };

  get(url, successFunc, undefined, false, false, headerWithToken);
};
