export const SET_DATA_SOURCE_SELF_UTILS = 'SET_DATA_SOURCE_SELF_UTILS';
export const SET_MODEL_POPUP = 'SET_MODEL_POPUP';
export const SET_DATASOURCES_LIST = "SET_DATASOURCES_LIST"
export const SET_DATASOURCE_ID = "SET_DATASOURCE_ID"
export const SET_COMES_FROM_FILE_UPLOAD_STATUS = "SET_COMES_FROM_FILE_UPLOAD_STATUS"
export const SET_ADD_TABLE_CALLBACK_FUNCTION = "SET_ADD_TABLE_CALLBACK_FUNCTION"
export const SET_MODEL_ID_TO_MODELS_AREA_CALLBACK_FUNCTION = "SET_MODEL_ID_TO_MODELS_AREA_CALLBACK_FUNCTION"

/**
 * Sets data source self utils
 */
export const setDataSourceSelfUtils = (data) => ({
  type: SET_DATA_SOURCE_SELF_UTILS,
  payload: data,
});

export const setModelPopupUtils = (data) => ({
  type: SET_MODEL_POPUP,
  payload: data
})

export const setDataSourcesList = (data) => ({
  type: SET_DATASOURCES_LIST,
  payload: data
})

export const setDataSourceId = (data) => ({
  type: SET_DATASOURCE_ID,
  payload: data
})

export const setComesFromDashboardFileUpload = (data) => ({
  type: SET_COMES_FROM_FILE_UPLOAD_STATUS,
  payload: data
})

export const setAddTableCallbackFunction = (callback) => ({
  type: SET_ADD_TABLE_CALLBACK_FUNCTION,
  payload: callback
})

export const setModelIdToModelsAreaCallbackFunction = (callback) => ({
  type: SET_MODEL_ID_TO_MODELS_AREA_CALLBACK_FUNCTION,
  payload: callback
})

