import { Col, Input, Popconfirm, Popover } from "antd";
import React, { Component } from "react";
import Text from "../GeneralComponents/Text/Text";
import Tooltip from "../GeneralComponents/Tooltip/Tooltip";
import { showNotificationWithIcon } from "../../Utils/Notification";
import { del } from "../../Utils/WebService";
import { API_BASE } from "../../config";
import i18n from "../../Utils/i18next";
import {
  DeleteOutlined,
  FormOutlined,
  NodeCollapseOutlined,
  QuestionCircleOutlined,
  SelectOutlined,
  DollarOutlined
} from "@ant-design/icons";
import JoinContentModal from "../Joining/JoinContentModal/JoinContentModal";
import SessionVariableModal from "../GeneralComponents/SessionVariable/SessionVariableModal";

/**
 * If there is a selected model, the model operations bar opens.
 */
export default class ModelToolsBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableAccordionActiveKeyControl: false,
      model: this.props.model ? this.props.model : {},
      tableSearchData: this.props.model ? this.props.model.tables : [],
      renamePopupVisible: false,
      sessionVariableModalVisible: false
    };
  }

  /**
   * Visible status to join modal
   */
  joinModalVisibleChange = (status) => {
    this.props.setJoinModalPopupVisiblity(status)
  };

  /**
   * Input for model rename
   */
  getPopoverContent = () => {
    return (
      <div>
        <Input
          id="rename"
          placeholder={i18n.t("Rename")}
          value={this.props.selectedModel.displayName}
          onChange={this.handleChangeModelRename}
          onPressEnter={this.handlePopoverConfirm}
        />
      </div>
    );
  };

  /**
   * Deletes the selected model and sends it to the upper component to remove it from the modelList.
   */
  deleteModel = (model) => {
    const successFunc = (response) => {
      this.props.deleteModelInList(model);
      showNotificationWithIcon(i18n.t("SuccessfullyDeleted"), null, "success");
    };

    const errorFunc = (error) => {
      showNotificationWithIcon(i18n.t("ErrorDeleted"), error, "error");
    };

    let urlWithParameters = API_BASE + "/subjectArea/deleteByName";

    del(urlWithParameters, model.name, successFunc, errorFunc);
  };

  /**
   * Popup visible change to change model display name
   */
  handleRenamePopupVisibleChange = (status) => {
    this.setState({
      renamePopupVisible: status,
    });
  };

  changeSessionVariableModalVisibility = (status) => {
    this.setState({
      ...this.state,
      sessionVariableModalVisible: status
    });
  }
  
  render() {
    let pincheck = this.props.pinned

    return (
      <>
          <div
          className="models-toolsbar"
            style={{
              marginTop: pincheck? "2px" : "0px",
            }}
          >
            <Tooltip
              tooltip={i18n.t("SessionVariables.Title")}
              tooltipPlacement={"bottom"}
              overlayClassName={"models-toolbar-tooltip"}
            >
              <span
                id="sessionVariablesButton"
                onClick={() => this.changeSessionVariableModalVisibility(true)}
                className={"circle-icon"}
                style={{ 
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "7px",
                  width: 30,
                  height: 30,
                  fontSize: 12
                }}
              >
                SV
              </span>
            </Tooltip>
            <Tooltip
              tooltip={i18n.t("Model.ChangeSelectedModel")}
              tooltipPlacement={"bottom"}
              overlayClassName={"models-toolbar-tooltip"}
            >
            </Tooltip>
            {/* TODO By Tayfun & Cenkay SubjectArea display name depend on back-end so we can not change it right now */}
            {/* <Tooltip tooltip={i18n.t("Rename")} tooltipPlacement={"bottom"}>
              <FormOutlined
                className={"circle-icon"}
                style={{ marginRight: "7px" }}
                onClick={() => this.handleRenamePopupVisibleChange(true)}
              />
              <Popover
                title={i18n.t("Rename")}
                content={this.getPopoverContent()}
                visible={this.state.renamePopupVisible}
                onVisibleChange={this.handleRenamePopupVisibleChange}
              />
            </Tooltip> */}
            <Tooltip
              tooltip={i18n.t("Join")}
              tooltipPlacement={"bottom"}
              overlayClassName={"models-toolbar-tooltip"}
            >
              <NodeCollapseOutlined
                id="joinModelButton"
                className={"circle-icon"}
                style={{ marginRight: "7px" }}
                onClick={() => this.joinModalVisibleChange(true)}
              />
            </Tooltip>

            <Tooltip
              tooltip={i18n.t("Delete")}
              tooltipPlacement={"bottom"}
              overlayClassName={"models-toolbar-tooltip"}
            >
              <Popconfirm
                title={i18n.t("AreYouSure")}
                okButtonProps={{
                  danger: true,
                  id: "deleteModelAcceptButton",
                }}
                cancelButtonProps={{ id: "deleteModelCancelButton" }}
                onConfirm={() => this.deleteModel(this.props.selectedModel)}
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              >
                {/*  */}
                <DeleteOutlined
                  id="deleteModelButton"
                  className={"circle-icon"}
                  style={{
                    cursor: "pointer",
                    color: "#f92f2f",
                  }}
                />
              </Popconfirm>
            </Tooltip>
          </div>

        <JoinContentModal
          visible={this.props.joinPopupVisible}
          selectedModel={this.props.selectedModel}
          updateSelectedModel={this.props.updateSelectedModel}
          updateModelTablesForJoin={this.props.updateModelTablesForJoin}
          visibleChange={this.joinModalVisibleChange}
          join={this.props.join}
          findJoinChanges={this.props.findJoinChanges}
          updateModelTablesForWhenDeletedJoin={
            this.props.updateModelTablesForWhenDeletedJoin
          }
          getModelList={this.props.getModelList}
          getModelTables={this.props.getModelTables}
        ></JoinContentModal>
        <SessionVariableModal
          changeSessionVariableModalVisibility={this.changeSessionVariableModalVisibility}
          visible={this.state.sessionVariableModalVisible}
          model={this.props.selectedModel}
        >
        </SessionVariableModal>
      </>
    );
  }
}
