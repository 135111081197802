import React, { Component } from "react";
import * as d3 from "d3";
import $ from "jquery";
import { rmvpp } from "../../RenderJs/rmvpp";
import "amcharts3";
import "amcharts3/amcharts/serial";
import "amcharts3/amcharts/plugins/animate/animate";
import "amcharts3/amcharts/plugins/responsive/responsive";
import "amcharts3/amcharts/themes/light";
import "amcharts3/amcharts/themes/patterns";
import "amcharts3/amcharts/plugins/export/export";
import { UpdateConfig } from "../../UpdateConfig";
import CylinderBarConfiguration from "./CylinderBarConfiguration";
import CylinderBarData from "./CylinderBarData";
import i18n from "../../../../Utils/i18next";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join"
import JoinPopup from "../../../Joining/JoinPopup/JoinPopup"
import {
  onComponentWillMount,
  onComponentWillReceiveProps,
  getColumnMapping,
  calculatePluginHeight
} from "../common";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import { renderConfig, renderData } from "../PluginsCommonComponents";
import { renderContent } from "../renderContent";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";

const data = JSON.parse(
  `[{"yolcutoplam":"395317013","label":"Tarifeli","values":[{"name":"yolcutoplam","value":395317013}]},{"yolcutoplam":"39906329","label":"Tarifesiz","values":[{"name":"yolcutoplam","value":39906329}]}]`
);
const config = JSON.parse(
  `{"width":"100%","height":300,"showHideButton":false,"refresh":0,"backgroundColor":"white"}`
);
const columnMap = JSON.parse(
  `{"label":{"Code":"\'ucus\'.\'tarife\'","Name":"tarife","DataType":"varchar","Table":"ucus","Measure":"none","ID":"ucus.tarife","SubjectArea":"BIG_DATA","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":"%s","Config":{},"Verified":true,"Type":"Column","Description":""},"values":[{"Code":"\'ucus\'.\'yolcutoplam\'","Name":"yolcutoplam","DataType":"double","Table":"ucus","Measure":"sum(ucus.yolcutoplam)","ID":"ucus.yolcutoplam","SubjectArea":"BIG_DATA","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":".3s","Config":{},"Verified":true,"Type":"Column","Description":""}]}`
);

var container;
const condFormats = [];
const filters = [];
const pluginName = "cylinder-bar";

const description =
  "Template plugin file that can be used by developers to create new plugins or learn to modify existing ones."; // Description of the plugin

// Mapping between RPD columns and the visualisation
const columnMappingParameters = [
  {
    targetProperty: "label", // ID of the property in columnMap object of render function
    formLabel: "Label", // Name the user will see
    type: "dim", // Either dim, fact or hidden - dimension, measure or hidden
    conditionalFormat: true, // Allow this to be conditionally formatted
    required: true, // Indicate to the user that this is required
    desc: "desc10" //'This is a test' // Description that is displayed to the user
  },
  {
    targetProperty: "values", // ID of the property in columnMap object of render function
    formLabel: "Values", // Name the user will see
    type: "dim", // Either dim, fact or hidden - dimension, measure or hidden
    multiple: true, // Boolean for whether you can have more than one column in this section
    conditionalFormat: true, // Allow this to be conditionally formatted
    required: true, // Indicate to the user that this is required
    desc: "desc11" // 'This is a test' // Description that is displayed to the user
  }
];

// Global configuration for the plugin - must have width and height or size at minimum.
const configurationParameters = [
  {
    targetProperty: "width", // Name of the property in config object of render function
    label: "Width", // Name the user will see
    inputType: "textbox", // Indicates the UI element for setting this parameter
    inputOptions: {
      // Sub-parameters for the UI element
      subtype: "number",
      defaultValue: 300
    },
    desc: "desc89" // Description displayed to the user
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: { defaultValue: "rgb(255,255,255)" },
    desc: "desc62"
  },
  {
    // Height and width are mandatory properties unless size is specified
    targetProperty: "height",
    label: "Height",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 300
    },
    desc: "desc90"
  },
  {
    targetProperty: "showHideButton",
    label: "Show Hide Button",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc230"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  },
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 15
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
];

// Interactions the user can make on this visualisation (e.g. click, highlight)
const actions = [];

// Reactions this visualisation can have to other interactions. Everything can use filters
const reactions = [
  {
    id: "filter",
    name: "Filter",
    description: "desc87",
    type: "general"
  }
];

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

let chartDivSeperatorNumber = 0;

/**
 * renders CylinderBar plugin in Vispeahen V3
 */
export default class CylinderBar extends Component {
  constructor(props) {
    super(props);
    this.rerenderProcessStarted = false;
    this.callBackObject = {};
  }

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({})

    return tmpCallBackObject;
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );
  }

  getConfigComponent = props => {
    if (props.config) {
      return (
        <CylinderBarConfiguration
          config={{ ...props.config }}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig= {this.props.setCurrentAppliedConfig}
          currentAppliedConfig = {this.props.currentAppliedConfig}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }

    return (
      <CylinderBarData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        didNotJoinedTables={checkTableJoins(this.props.join, this.props.plugin.columnMap, this.props.refreshedPluginId, this.props.plugin.id, true)}
        setInteractions={this.props.setInteractions}
        interactions={this.props.interactions}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={props.changeDoesPluginHasNotJoinedTable}
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        plugin={props.plugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  /**
   * To set column map this plugin
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      label: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Label.Name"),
        type: "dim", // Either dim, fact or hidden - dimension, measure or hidden
        conditionalFormat: true, // Allow this to be conditionally formatted
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Label.Desc"),
        required: true, // Indicate to the user that this is required
        minimumColumnSize: 1,
        data: []
      },
      values: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Values.Name"),
        type: "dim", // Either dim, fact or hidden - dimension, measure or hidden
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Values.Desc"),
        multiple: true, // Boolean for whether you can have more than one column in this section
        minimumColumnSize: 1,
        conditionalFormat: true, // Allow this to be conditionally formatted
        required: true, // Indicate to the user that this is required
        data: []
      },
      hidden: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Hidden.Name"),
        multiple: true,
        minimumColumnSize: 0,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Hidden.Desc"),
        type: "hidden",
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {
    // Set html empty and set this html to container.
    $("#" + divId).html("");
    let container = $("#" + divId)[0];

    var idName = "chartdiv-" + chartDivSeperatorNumber;

    $(container).append(`<div id="${idName}" class="cylinderBar"></div>`);

    $(`#${idName}`).css({
      width: "100%",
      height: config.height,
      background: config.backgroundColor
    });

    var x = [];
    var y = [];

    data.forEach(function (_data) {
      var temp = {};
      temp.label = _data.label;
      _data.values.forEach(function (datum) {
        temp[datum.name] = datum.value;
      });
      x.push(temp);
    });

    data[0].values.forEach(function (datum) {
      var graph = {
        balloonText:
          "[[title]], [[category]] <br><span style='font-size:14px;'><b>[[value]]</b> ([[percents]]%)</span>",
        fillAlphas: 0.9,
        fontSize: 11,
        labelText: "[[percents]]% <br> [[value]]",
        lineAlpha: 0.5,
        title: datum.name,
        type: "column",
        valueField: datum.name,
        topRadius: 1
      };

      y.push(graph);
    });

    window.AmCharts.makeChart(idName, {
      type: "serial",
      theme: "light",
      depth3D: 100,
      angle: 30,
      hideCredits: true,
      legend: {
        autoMargins: false,
        borderAlpha: 0.2,
        equalWidths: false,
        horizontalGap: 10,
        markerSize: 10,
        useGraphSettings: true,
        valueAlign: "left",
        valueWidth: 0
      },
      dataProvider: x,
      valueAxes: [
        {
          stackType: "100%",
          axisAlpha: 0,
          gridAlpha: 0,
          labelsEnabled: false,
          position: "left"
        }
      ],
      graphs: y,
      marginTop: 30,
      marginRight: 0,
      marginLeft: 0,
      marginBottom: 40,
      autoMargins: false,
      categoryField: "label",
      categoryAxis: {
        gridPosition: "start",
        axisAlpha: 0,
        gridAlpha: 0
      },
      export: {
        enabled: false
      }
    });

    if (config.showHideButton) {
      let hideButtonToggleKey = [idName];
      rmvpp.hideButton(container, hideButtonToggleKey);
    }

    chartDivSeperatorNumber = chartDivSeperatorNumber + 1;

    this.props.setPluginRerender(false, this.props.plugin.id, false, this.props.plugin.isInteraction);
  };

  currentHeight;
  lastContent = undefined;

  updateLastContent = (status) => {
    this.lastContent = status
  }

  render() {
    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };
    
    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + this.props.plugin.id).css("padding")
      );

      pluginConfig.height =
        calculatePluginHeight(this.props.plugin, this.props.settings) -
        pluginContainerPadding * 2;

      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      )
    }

    return (
      <>
        <div style={{height: "100%"}}>
          <div id={this.props.plugin.id}></div>
          {renderContent(
            isRerender,
            this.pluginRender,
            this.props.plugin,
            data,
            columnMap,
            pluginConfig,
            condFormats,
            this.props.setPluginRerender,
            this.lastContent,
            this.updateLastContent,
          )}
          {configComponent}
          {dataComponent}
        </div>
      </>
    );
  }
}
