import React, { Component } from "react";
import { Tooltip, Input, Popover, Icon, Spin } from "antd";
import "./TreeModel.css";
import TreeItemPanel from "../treeItemManagmentPanel/TreeItemPanel";
import RenameNode from "./RenameNode";
import { showNotificationWithIcon } from "../../../Utils/Notification";

const inputValidationStatus = {
  INFO: "INFO",
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
};

export default class TreeModelItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputText: "",
      inputValidation: {
        status: inputValidationStatus.INFO,
        message: ""
      },
      selectedItem: {},
      selectedPanelItem: "",
      popoverContent: {
        icon: "",
        title: "",
        isInputDisabled: false
      },
      action: "",
      isPopoverVisible: false
    };
  }

  clearState = () => {
    this.setState({
      inputText: "",
      inputValidation: {
        status: inputValidationStatus.INFO,
        message: ""
      },
      selectedItem: {},
      selectedPanelItem: "",
      popoverContent: {
        icon: "",
        title: "",
        isInputDisabled: false
      },
      action: "",
      isPopoverVisible: false
    });
  };

  handleSelectPanelItem = (e, selectedItem) => {
    let action, icon, title, inputText, placeholder, isPopoverVisible;

    switch (e.key) {
      case "whereClause":
        //For popover content
        icon = (
          <i
            style={{ fontSize: "14px", color: "green" }}
            className={"fontIcon"}
          >
            ω
          </i>
        );
        action = "whereClause";
        title = "Enter Where Clause";
        placeholder = "Enter Where Clause";
        inputText = selectedItem.whereClause ? selectedItem.whereClause : "";
        isPopoverVisible = true;
        break;
      //For popover content
      case "aggrRule":
        icon = (
          <i
            style={{ fontSize: "14px", color: "green" }}
            className={"fontIcon"}
          >
            ∫
          </i>
        );
        action = "aggrRule";
        title = "Enter Aggrigation Rule";
        placeholder = "Default value: 'none'";
        inputText = selectedItem.aggrRule ? selectedItem.aggrRule : "";
        isPopoverVisible = true;
        break;
      case "duplicated":
        action = "duplicate";
        title = "Enter duplicate column name";
        placeholder = "Enter new name";
        inputText = selectedItem.displayName;
        isPopoverVisible = true;
        break;
      case "info":
        action = "info";
        title = `About ${selectedItem.displayName}`;
        isPopoverVisible = true;
        break;
      default:
        action = "";
        icon = "";
        title = "";
        placeholder = "";
        isPopoverVisible = false;
        break;
    }
    this.setState({
      selectedItem,
      inputText,
      placeholder,
      selectedPanelItem: e.key,
      popoverContent: {
        icon,
        title
      },
      action,
      isPopoverVisible
    });
  };

  handleChange = e => {
    this.setState({
      ...this.state,
      inputText: e.target.value
    });
  };

  handlePopoverConfirm = e => {
    var targetItem = e.target;
    const action = targetItem.id;

    this.setState({
      inputValidation: {
        status: inputValidationStatus.LOADING,
        message: action + " has been testing..",
        isInputDisabled: true
      }
    });

    const { selectedItem, selectedPanelItem } = this.state;
    const { data } = this.props;

    var updatedItem = JSON.parse(JSON.stringify(selectedItem));
    var duplicatedObject = {};
    let displayNameControl = targetItem.value;
    let key = selectedItem.key;
    let keyParse = key.split(",");
    if (action === "duplicate") {
      let hashColumnsObject = {};
      data.map(column => {
        hashColumnsObject[column.displayName] = true;
      });
      if (hashColumnsObject[displayNameControl] !== true) {
        updatedItem[selectedPanelItem] = true;
        duplicatedObject = JSON.parse(JSON.stringify(updatedItem));
        duplicatedObject.displayName = displayNameControl;
        duplicatedObject.duplicated = true;
      } else {
        showNotificationWithIcon(
          "error",
          "not duplicate with the same name",
          "error"
        );
      }
    } else if (action === "aggrRule") {
      updatedItem[selectedPanelItem] = targetItem.value;
      updatedItem[selectedPanelItem] == "" ? updatedItem.aggregatable = false : updatedItem.aggregatable = true;
      this.props.updateData(updatedItem, action);
    } else {
      updatedItem[selectedPanelItem] = targetItem.value;
      this.props.updateData(updatedItem, action);
    }

    //TODO: backend formula control
    const that = this;
    that.setState({
      inputValidation: {
        status: inputValidationStatus.SUCCESS,
        message: targetItem.value + " is successfully applied.",
        isInputDisabled: false
      }
    });

    setTimeout(function() {
      that.clearState();
    }, 500);

    if (duplicatedObject && Object.keys(duplicatedObject).length > 0) {
      that.props.updateData(duplicatedObject, action);
    }
  };

  handleVisibleChange = isPopoverVisible => {
    this.setState({
      isPopoverVisible
    });
  };
  getPopoverContent = () => {
    const { popoverContent, inputValidation, action } = this.state;
    const loadingIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

    return (
      <div>
        {action == "info" ? (
          <div>
            {this.state.selectedItem.name &&
              "* Name : " + this.state.selectedItem.name}
            {this.state.selectedItem.name && <br />}
            {this.state.selectedItem.dataSourceKey &&
              "* Data Source : " + this.state.selectedItem.dataSourceKey}
            <br />
            {this.state.selectedItem.duplicated &&
              "* Duplicated : " + this.state.selectedItem.duplicated}
            <br />
            {this.state.selectedItem.whereClause &&
              "* Where Clause : " + this.state.selectedItem.whereClause}
            <br />
          </div>
        ) : (
          <Input
            id={action}
            placeholder={popoverContent.placeholder}
            value={this.state.inputText}
            suffix={
              <Tooltip
                title={popoverContent.title}
                content={popoverContent.message}
              >
                {inputValidation.status == inputValidationStatus.INFO && (
                  <Icon
                    type="info-circle"
                    style={{ color: "rgba(0,0,0,.45)" }}
                  />
                )}
                {inputValidation.status == inputValidationStatus.LOADING && (
                  <Spin indicator={loadingIcon} />
                )}
                {inputValidation.status == inputValidationStatus.SUCCESS && (
                  <Icon type="check-circle" style={{ color: "green" }} />
                )}
                {inputValidation.status == inputValidationStatus.ERROR && (
                  <Icon type="close-circle" style={{ color: "red" }} />
                )}
              </Tooltip>
            }
            disabled={popoverContent.isInputDisabled}
            onChange={this.handleChange}
            onPressEnter={this.handlePopoverConfirm}
          />
        )}
      </div>
    );
  };

  render() {
    const { selectedPanelItem, isPopoverVisible, popoverContent } = this.state;
    const { data, item, itemKey, info, renamedItem } = this.props;

    const popoverTitle = (
      <div>
        {popoverContent.icon} {popoverContent.title}
      </div>
    );
    return (
      <div>
        {renamedItem == item.displayName ? (
          <div className="treeRenameItem">
            <RenameNode
              data={data}
              itemKey={itemKey}
              item={item}
              onConfirm={this.props.updateData}
              onCancel={this.props.onCancel}
            />
          </div>
        ) : (
          <TreeItemPanel
            content={
              <div className="treeItem">
                <Popover
                  title={popoverTitle}
                  content={this.getPopoverContent()}
                  visible={isPopoverVisible}
                  onVisibleChange={this.handleVisibleChange}
                />
                {item.source != undefined && item.source == "Local" ? (
                  <div className="treeItemForLocal">{item.displayName}</div>
                ) : item.source != undefined && item.source == "RealDB" ? (
                  <div className="treeItemForRealDB">{item.displayName}</div>
                ) : item.source != undefined && item.source == "Both" ? (
                  <div className="treeItemForBoth">{item.displayName}</div>
                ) : (
                  <div>{item.displayName}</div>
                )}
                <div className="treeItemSuffix">
                  {item.aggrRule && item.aggrRule != "none" && (
                    <Tooltip placement="topLeft" title={item.aggrRule}>
                      <p className="treeItemIcons">
                        <i> ∫ </i>
                      </p>
                    </Tooltip>
                  )}
                  {item.whereClause && (
                    <Tooltip placement="topLeft" title={item.whereClause}>
                      <p className="treeItemIcons">
                        <i> ω </i>
                      </p>
                    </Tooltip>
                  )}
                  {item.duplicated && (
                    <Tooltip placement="topLeft" title="Duplicated">
                      <p className="treeItemIcons">
                        <i> ₯ </i>
                      </p>
                    </Tooltip>
                  )}
                  <div className="treeItemPanel">
                    <TreeItemPanel
                      content={<Icon type="more" />}
                      item={item}
                      trigger={["click"]}
                      select={info}
                      onSelect={this.handleSelectPanelItem}
                    />
                  </div>
                </div>
              </div>
            }
            item={item}
            trigger={["contextMenu"]}
            select={info}
            onSelect={this.handleSelectPanelItem}
          />
        )}
      </div>
    );
  }
}
