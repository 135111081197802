import React, { Component } from "react";
import { Tooltip } from "antd";
import "./boxItem2.css";

export default class BoxItem2 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Sets the drag image, and style of BoxItem
   * 
   * @param {*} event 
   * @param {*} callback 
   */
  onDragStart = (event, callback) => {
    let div = document.getElementById(this.props.id);
    let temp = document.createElement("div");

    temp.id = "dragged-" + event.target.id;
    temp.key = "dragged-" + event.target.key;
    temp.style.position = "fixed";
    temp.style.top = "-100dvh";
    temp.style.left = "-100dvw";
    temp.style.backgroundColor = "white";
    temp.style.opacity = 1;
    temp.style.width = div.offsetWidth + "px";

    temp.appendChild(event.target.cloneNode(true))
    div.appendChild(temp, true);

    let box = temp.children[0];

    box.style.opacity = 1;
    box.margin = 0;
    box.padding = 0;
    box.boxShadow = "none";
    box.border = "none";

    event.target.style.opacity = 1;
    event.dataTransfer.setDragImage(temp, 0, 0);
    event.dataTransfer.effectAllowed = this.props.dragEffectAllowed || 'move';
       
    if (typeof callback === "function") callback(event);
  }

  /**
   * Clears the dragged element
   * 
   * @param {*} event 
   * @param {*} callback 
   */
  onDragEnd = (event, callback) => {      
    document.getElementById("dragged-" + event.target.id)?.remove(); // eslint-disable-line

    event.dataTransfer.clearData();

    if (typeof callback === "function") callback(event);
  }

  render() {
    return (
      <Tooltip placement={this.props.tooltipPlacement ? this.props.tooltipPlacement : "top"} title={this.props.title} arrowPointAtCenter={this.props.arrowPointAtCenter}>
        <div
          id={this.props.id}
          key={this.props.key}
          style={this.props.style}
          draggable={this.props.draggable && this.props.draggable}
          onDragStart={event => this.onDragStart(event, this.props.onDragStart)}
          onDragEnd={event => this.onDragEnd(event, this.props.onDragEnd)}
          onDragOver={this.props.onDragOver}
          onDragEnter={this.props.onDragEnter}
          onDragLeave={this.props.onDragLeave}
          onDrop={this.props.onDrop}
          onClick={this.props.onClick}
          className={`box-item ${this.props.className ? this.props.className : ""}`}
        >
          {this.props.children}
        </div>
      </Tooltip>
    );
  }
}
