import { GET_MODEL, GET_REAL_DB_MODEL} from "./TreeModelAction";

export default function treeModelReducer(
  state = {
    modelObject: {},
    realDBObject: {},
    dataSourceSchemas: [],
    schemaTables: []
  },
  action
) {
  switch (action.type) {
    case GET_MODEL:
      return {
        ...state,
        modelObject: action.payload
      };
    case GET_REAL_DB_MODEL:
      return {
        ...state,
        realDBObject: action.payload
      };
    default:
      return state;
  }
}
