import Axios from "axios";
import { loadingScreen } from "./Global";
import Cookies from "js-cookie";
import { naviagateToLogin, refreshAccessToken, removeAuthentication } from "./Auth";
import { showNotificationWithIcon } from "./Notification";
import i18n from "./i18next";
import { store } from "../index"
import { changePopupVisibility } from "../ui/GeneralComponents/LoginPopup/LoginPopupAction"
import { ECHO_URL } from "../../src/config"

Axios.defaults.headers.common["Authorization"] = `Bearer ${Cookies.get(
  "ORA_BIPS_NQID"
)}`;
Axios.defaults.headers.post["Content-Type"] = "application/json";
Axios.defaults.headers.put["Content-Type"] = "application/json";
Axios.defaults.headers.delete["Content-Type"] = "application/json";

function axiosCallsThen(successFunc, response, preventStopLoading) {
  if (preventStopLoading !== true) {
    loadingScreen(false);
  }
  
  if (successFunc) {
    return successFunc(response);
  }
}

function axiosCallsCatch(errorFunc, response, callLogoutOn401, callback, url, preventStopLoading) {
  if (preventStopLoading !== true) {
    loadingScreen(false);
  }

  /** if response cancelled by user */
  if (response && response.__CANCEL__) {
    return;
  }

  let isResponse401 = response.response && response.response.status === 401;
  let isDashboardSliderActive = sessionStorage.getItem("currentSlider") !== null;
  let showLoginPopup = isResponse401 && url !== ECHO_URL && callLogoutOn401 === true;
  let loginWithRefreshToken = isResponse401 && url !== ECHO_URL && isDashboardSliderActive;

  if (loginWithRefreshToken) {
    refreshAccessToken(callback);

    return;
  } else if (showLoginPopup) {
    store.dispatch(changePopupVisibility(true, callback));

    return;
  }

  let errorHeader = i18n.t("SomethingWentWrong");
  let errorMessage = response.message;
  let isDescription = false;

  if (response.response && response.response.data) {
    errorHeader = response.message;

    if (response.response.data.error_description) {
      isDescription = true;
      errorMessage = response.response.data.error_description;
    } else if (response.response.data.message) {
      errorMessage = response.response.data.message;
    } else if (typeof response.response.data === "string") {
      errorMessage = response.response.data;
    }
  }

  if (errorFunc) {
    errorFunc(errorMessage, isDescription);
  } else {
    showNotificationWithIcon(errorHeader, errorMessage, "error");
  }
}

/**
 * Prepare requests configs.
 * Adds header.
 * Adds cancel token if exist.
 * Adds data if exist.
 * @param {*} header
 * @param {*} cancelToken
 * @param {*} data
 */
const prepareRequestConfig = (
  header,
  cancelToken,
  responseType = "json",
  data = undefined,
  excludeAuthorizationHeader = undefined
) => {
  let config = {};

  if (excludeAuthorizationHeader) {
    config.transformRequest = headers => {
      delete headers.common["Authorization"];
    };
  }

  config.headers = header;

  if (cancelToken) {
    config.cancelToken = cancelToken;
  }

  if (data) {
    config.data = data;
  }

  config.responseType = responseType;

  return config;
};

/**
 * excludeAuthorizationHeader parameters is for deactivate all authorizations and header is for setting to header by generic usage
 **/
export const get = (
  url,
  successFunc,
  errorFunc = undefined,
  showLoadingScreen = true,
  excludeAuthorizationHeader = false,
  header = undefined,
  callLogoutOn401 = true,
  cancelToken = undefined,
  preventStopLoading = false,
  responseType = "json"
) => {
  if (showLoadingScreen) {
    loadingScreen(showLoadingScreen);
}

  let config;

  if (excludeAuthorizationHeader) {
    config = prepareRequestConfig(header, cancelToken, responseType, undefined, true);
  } else {
    config = prepareRequestConfig(header, cancelToken, responseType);
  }

  return Axios.get(url, config)
    .then(response => axiosCallsThen(successFunc, response, preventStopLoading))
    .catch(response => axiosCallsCatch(errorFunc, response, callLogoutOn401, (function callback() {
      get(url,
        successFunc,
        errorFunc = undefined,
        showLoadingScreen = true,
        excludeAuthorizationHeader = false,
        header = undefined,
        callLogoutOn401 = true,
        cancelToken = undefined)
    }), url, preventStopLoading));
};

export const post = (
  url,
  requestBody,
  successFunc,
  errorFunc = undefined,
  showLoadingScreen = true,
  excludeAuthorizationHeader = false,
  header = undefined,
  callLogoutOn401 = true,
  cancelToken = undefined,
  preventStopLoading = false,
  responseType = "json"
) => {
  if (showLoadingScreen) {
    loadingScreen(showLoadingScreen);
  }

  let config;

  if (excludeAuthorizationHeader) {
    config = prepareRequestConfig(header, cancelToken, responseType, undefined, true);
  } else {
    config = prepareRequestConfig(header, cancelToken, responseType);
  }

  return Axios.post(url, requestBody, config)
    .then(response => axiosCallsThen(successFunc, response, preventStopLoading))
    .catch(response => axiosCallsCatch(errorFunc, response, callLogoutOn401, (function callback() {
      return post(url,
        requestBody,
        successFunc,
        errorFunc = undefined,
        showLoadingScreen = true,
        excludeAuthorizationHeader = false,
        header = undefined,
        callLogoutOn401 = true,
        cancelToken = undefined)
    }), url, preventStopLoading));
};

export const put = (
  url,
  requestBody,
  successFunc,
  errorFunc = undefined,
  excludeAuthorizationHeader = false,
  header,
  callLogoutOn401 = true,
  showLoadingScreen = true,
  cancelToken = undefined,
  preventStopLoading = false,
  responseType = "json"
) => {
  if (showLoadingScreen) {
    loadingScreen(showLoadingScreen);
  }

  let config;

  if (excludeAuthorizationHeader) {
    config = prepareRequestConfig(header, cancelToken, responseType, undefined, true);
  } else {
    config = prepareRequestConfig(header, cancelToken, responseType);
  }

  return Axios.put(url, requestBody, config)
    .then(response => axiosCallsThen(successFunc, response, preventStopLoading))
    .catch(response => axiosCallsCatch(errorFunc, response, callLogoutOn401, (function callback() {
      put(url,
        requestBody,
        successFunc,
        errorFunc = undefined,
        excludeAuthorizationHeader = false,
        header,
        callLogoutOn401 = true,
        showLoadingScreen = true,
        cancelToken = undefined)
    }), url, preventStopLoading));
};

export const del = (
  url,
  requestBody,
  successFunc,
  errorFunc = undefined,
  excludeAuthorizationHeader = false,
  header,
  callLogoutOn401 = true,
  showLoadingScreen = true,
  cancelToken = undefined,
  preventStopLoading = false,
  responseType = "json"
) => {
  if (showLoadingScreen) {
    loadingScreen(showLoadingScreen);
  }

  let config;

  if (excludeAuthorizationHeader) {
    config = prepareRequestConfig(header, cancelToken, responseType, undefined, true);
  } else {
    config = prepareRequestConfig(header, cancelToken, responseType, requestBody);
  }

  return Axios.delete(url, config)
    .then(response => axiosCallsThen(successFunc, response, preventStopLoading))
    .catch(response => axiosCallsCatch(errorFunc, response, callLogoutOn401, (function callback() {
      del(url,
        requestBody,
        successFunc,
        errorFunc = undefined,
        excludeAuthorizationHeader = false,
        header,
        callLogoutOn401 = true,
        showLoadingScreen = true,
        cancelToken = undefined)
    }), url, preventStopLoading));
};
