import { PAGE_POPUP_CONTENT } from "./PagePopupAction";

export default function createModelReducer(
  state = {
    contentObject: {
      pluginId: "",
      header: {},
      content: "",
      popupPositions: {
        left: 0,
        top: 0,
      },
      containerIconPositions: {
        left: 0,
        top: 0,
        display: "none",
      },
      visible: false,
      popupId: "",
      width: 450
    },
  },
  action
) {
  switch (action.type) {
    case PAGE_POPUP_CONTENT:
      return {
        ...state,
        ...{
          contentObject: {
            pluginId: action.payload.pluginId !== undefined
              ? action.payload.pluginId
              : state.contentObject.pluginId,
            header: action.payload.header !== undefined
              ? action.payload.header
              : state.contentObject.header,
            content: action.payload.content !== undefined
              ? action.payload.content
              : state.contentObject.content,
            popupPositions: action.payload.popupPositions !== undefined
              ? action.payload.popupPositions
              : state.contentObject.popupPositions,
            containerIconPositions: action.payload.containerIconPositions !== undefined
              ? action.payload.containerIconPositions
              : state.contentObject.containerIconPositions,
            visible: action.payload.visible !== undefined
              ? action.payload.visible
              : state.contentObject.visible,
            popupId: action.payload.popupId !== undefined
              ? action.payload.popupId
              : state.contentObject.popupId,
            width: action.payload.width !== undefined
              ? action.payload.width
              : state.contentObject.width,
          },
        },
      };
    default:
      return state;
  }
}
