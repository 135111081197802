import { DELETED_LABEL, allLabels } from "../config";
import i18n from "./i18next";

//To Do - Label create sırasında isDeletable'ın setlenmemesi
export const labelTranslateNameSet = (data, withoutDeleted = false) => {
    let labels =  [];
    
    data.map(d => {
        if ((d.name !== DELETED_LABEL && withoutDeleted) || !withoutDeleted) {
            if (!d.isDeletable && allLabels.has(d.name)) {
                d.translateName = i18n.t("WellDefined." + d.name)
            } else {
                d.translateName = d.displayName
            }
            labels.push(d)
        }
    })

    return labels;
}

/*
* Translate the labels from english to turkish while page is loading
*/
export const labelLoadingTranslateNameSet = (data, withoutDeleted = false) => {
    let labels =  [];
    
    data.map(d => {
        if ((d.name !== DELETED_LABEL && withoutDeleted) || !withoutDeleted) {
            if (!d.isDeletable) {
                d.translateName = i18n.t("WellDefined." + d.label.name)
            } else {
                d.translateName = d.labelDisplayName
            }
            labels.push(d)
        }
    })

    return labels;
}

/*
* Translates navigation content labels
*/
export const labelNamesForNavigationContent = (data, withoutDeleted = false) => {
    let isDataDeleted = data.name !== DELETED_LABEL ? true : false
    let isLabelAvailable = (isDataDeleted && withoutDeleted) || !withoutDeleted

    if (isLabelAvailable) {
        if (!data.isDeletable && allLabels.has(data.name)) {
            return i18n.t("WellDefined." + data.name)
        } else {
            return data.displayName
        }
    }
}