import Axios from "axios";
import {
    logout
} from './Auth'
import {
    loadingScreen
} from "./Global"


Axios.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.obieeSessionId}`;
Axios.defaults.headers.post["Content-Type"] = "application/json";
Axios.defaults.headers.put["Content-Type"] = "application/json";
Axios.defaults.headers.delete["Content-Type"] = "application/json";

export const get = (url, successFunc, errorFunc) => {
    loadingScreen(true);

    Axios.get(url)
        .then(response => {
            loadingScreen(false);

            if (successFunc) {
                successFunc(response);
            }
        })
        .catch(response => {
            loadingScreen(false);

            if (response.response && response.response.status === 401) {
                logout()
            }
            if (errorFunc) {
                errorFunc(response);
            }
        });
};

export const post = (url, requestBody, successFunc, errorFunc, headerConfig=undefined) => {
    loadingScreen(true);
    let header = {...Axios.defaults.headers};

    if (headerConfig) {
        header.post["Content-Type"] = headerConfig["Content-Type"];
    }

    Axios.post(url, requestBody, header)
        .then(response => {
            loadingScreen(false);

            if (successFunc) {
                successFunc(response);
            }
        })
        .catch(response => {
            loadingScreen(false);

            if (errorFunc) {
                errorFunc(response);
            }
        });
};

export const put = (url, requestBody, successFunc, errorFunc, headerConfig = undefined) => {
    loadingScreen(true);
    let header = {...Axios.defaults.headers};

    if (headerConfig) {
        header.post["Content-Type"] = headerConfig["Content-Type"];
    }
    
    Axios.put(url, requestBody, header)
        .then(response => {
            loadingScreen(false);

            if (successFunc) {
                successFunc(response);
            }
        })
        .catch(response => {
            loadingScreen(false);

            if (errorFunc) {
                errorFunc(response);
            }
        });
};

export const del = (url, requestBody, successFunc, errorFunc) => {
    loadingScreen(true);

    Axios.delete(url, {
            headers: {
                "Content-Type": "application/json"
            },
            data: requestBody
        })
        .then(response => {
            if (successFunc) {
                loadingScreen(false);

                successFunc(response);
            }
        })
        .catch(response => {
            loadingScreen(false);

            if (errorFunc) {
                errorFunc(response);
            }
        });
};