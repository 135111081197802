import React, { Component } from "react";
import ChooseFile from "./ChooseFile";
import FileValidationForUpload from "./FileValidationForUpload";
import UploadedFilePreview from "./UploadedFilePreview";
import UploadOperation from "./UploadOperation";

/**
 * File Upload Steps container
 */
export default class FileUploadSteps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: undefined,
    };
  }

  /**
   * Set upload parameters
   * @param {*} state 
   */
  setUploadParameters = (state) => {
    this.setState({ ...state, sheets: undefined, selectedSheet: undefined });
  };

  /**
   * Set all sheets configurations
   * @param {*} sheets 
   * @param {*} selectedSheet 
   */
  setSheetsConfigurations = (sheets, selectedSheet) => {
    this.setState({ sheets, selectedSheet });
  };

  /**
   * Set single sheet configuration to sheet list
   * @param {*} sheet 
   */
  setSheetConfiguration = (sheet) => {
    let sheets = new Map(this.state.sheets);

    sheets.set(sheet.name, sheet);

    this.setState({ sheets });
  };

  /**
   * Set new file name for upload
   * @param {*} filename 
   */
  setNewFilename = (filename) => {
    this.setState({...this.state, filename})
  }

  render() {
    const {
      current,
      chooseFileValidation,
      previewValidation,
      validationForUploadValidation,
      validationResponse,
    } = this.props;
    const { sheets, selectedSheet } = this.state;

    if (current === 0) {
      return (
        <ChooseFile
          setUploadedFile={this.setUploadedFile}
          chooseFileValidation={chooseFileValidation}
          validationResponse={validationResponse}
          setUploadParameters={this.setUploadParameters}
          dataSource={this.props.dataSource}
          changeFileUploadDataSource={this.props.changeFileUploadDataSource}
          comesFromDashboardFileUpload={this.props.comesFromDashboardFileUpload}
          setSelectedModel={this.props.setSelectedModel}
          selectedModel={this.props.selectedModel}
        />
      );
    } else if (current === 1) {
      return (
        <UploadedFilePreview
          sheets={sheets}
          setNewFilename={this.setNewFilename}
          selectedSheet={selectedSheet}
          uploadParameters={{ ...this.state }}
          previewValidation={previewValidation}
          validationResponse={this.props.validationResponse}
          setSheetsConfigurations={this.setSheetsConfigurations}
          onFileUploadSucces={this.props.onFileUploadSucces}
          firstDataUpload={this.props.firstDataUpload}
        />
      );
    } else if (current === 2 && !this.props.firstDataUpload) {
      return (
        <FileValidationForUpload
          sheets={sheets}
          uploadParameters={{ ...this.state }}
          dataSource={this.props.dataSource}
          validationForUploadValidation={validationForUploadValidation}
          validationResponse={this.props.validationResponse}
          setSheetConfiguration={this.setSheetConfiguration}
        />
      );
    } else if (current === 3) {
      return (
        <UploadOperation
          sheets={sheets}
          uploadParameters={{ ...this.state }}
          dataSource={this.props.dataSource}
          setSheetConfiguration={this.setSheetConfiguration}
          onFileUploadSucces={this.props.onFileUploadSucces}
          firstDataUpload={this.props.firstDataUpload}
          comesFromDashboardFileUpload={this.props.comesFromDashboardFileUpload}
          selectedModel={this.props.selectedModel}
        />
      );
    } else {
      return null;
    }
  }
}
