import i18n from "../../Utils/i18next";
import { showError } from "../../Utils/Notification";
import { ColumnNotFound } from "../../config";

const hasOptionsValue = "has-options-value";
const hasTargetColumns = "has-targetColumns-value";
const hasRule = "has-rule-value";

const tempDataForColumnDataType = {
  double: 1.5,
  varchar: "Bisoft",
  integer: 2,
  date: "2021-08-06",
  timestamp: "2021-08-06T06:30:50"
};

const operatorSet = new Set(["*", "/", "%", "(", ")", "+", "-"]);

/** check all conditional format data done. */
export const checkGetAllConditionData = appliedConditionalFormat => {
  if (appliedConditionalFormat === undefined) {
    return;
  }

  return (
    appliedConditionalFormat[hasOptionsValue] &&
    appliedConditionalFormat[hasTargetColumns] &&
    appliedConditionalFormat[hasRule]
  );
};

/** validate attributes and show error   */
export const validateConditionalFormattingAttributes = (
  appliedConditionalFormat,
  columnsToBeUsed
) => {
  if (appliedConditionalFormat === undefined) {
    return;
  }

  let validate = validateTargetColumns(appliedConditionalFormat);

  if (validate.status) {
    validate = validateRule(appliedConditionalFormat, columnsToBeUsed);
    if (validate.status) {
      validate = validateOptions(appliedConditionalFormat);
    }
  }

  if (validate.status === false) {
    showError(validate.message);
    return false;
  }

  return true;
};

/** Target columns validation */
const validateTargetColumns = appliedConditionalFormat => {
  if (appliedConditionalFormat === undefined) {
    return;
  }

  if (appliedConditionalFormat["targetColumns"].length > 0) {
    return { status: true };
  }

  return {
    status: false,
    message: i18n.t("Dashboard.ConditionalFormatting.TargetColumnsCannotEmpty")
  };
};

/** Rule validations */
const validateRule = (appliedConditionalFormat, columnsToBeUsed) => {
  if (appliedConditionalFormat === undefined) {
    return;
  }

  let isRuleEmpty =
    appliedConditionalFormat["rule"].leftRule === "" ||
    appliedConditionalFormat["rule"].leftRule.rule === "";

  if (!isRuleEmpty && appliedConditionalFormat["rule"].operator !== "") {
    return runRuleForValidate(
      appliedConditionalFormat["rule"],
      columnsToBeUsed
    );
  }

  return {
    status: false,
    message: i18n.t("Dashboard.ConditionalFormatting.RuleNotValid")
  };
};

/**
 * For rule evaluate. If eval is success for both rule, no problem. But if any eval has errors, it doesn't validate rule for conditional format.
 * @param {*} rules
 * @param {*} columnsToBeUsed
 */
const runRuleForValidate = (rules, columnsToBeUsed) => {
  let columnMap = new Map();

  columnsToBeUsed.map(column => {
    let columnId = column.uniqeColumnId ? column.uniqeColumnId : column.filterId ? column.filterId : undefined

    columnMap.set(columnId, column);
  });

  let leftRule = convertRule(rules.leftRule.rule, columnMap);
  leftRule.type = "LeftRule";

  let rightRule = convertRule(rules.rightRule.rule, columnMap);
  rightRule.type = "RightRule";

  if (leftRule.status === true && rightRule.status === true) {
    try {
      eval(leftRule);
      eval(rightRule);

      return { status: true };
    } catch (error) {
      return {
        status: false,
        message: i18n.t("Dashboard.ConditionalFormatting.CouldNotRunRule")
      };
    }
  } else {
    let errorMessage =
      leftRule.status === false
        ? i18n.t("Dashboard.ConditionalFormatting." + leftRule.type) +
          " - " +
          i18n.t(leftRule.errorMessage)
        : undefined;
    errorMessage =
      errorMessage == undefined && rightRule.status === false
        ? i18n.t("Dashboard.ConditionalFormatting." + rightRule.type) +
          " - " +
          i18n.t(rightRule.errorMessage)
        : errorMessage;

    return {
      status: false,
      message: errorMessage
    };
  }
};

const isNumeric = n => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const convertRule = (rule, columnMap, evalValue = undefined) => {
  let convertedValue = "";
  let operators = [];
  let types = new Set();

  for (let i = 0; i < rule.length; i++) {
    let char = rule[i];
    if (operatorSet.has(char)) {
      operators.push(char);
    }
  }

  let splittedRule = rule.split(/[*/%()+-]/);

  for (let i = 0; i < splittedRule.length; i++) {
    let value = splittedRule[i].trim();
    let operator = operators[i];

    if (value[0] === "{" && value[value.length - 1] === "}") {
      let columnId = value.substring(1, value.length - 1).replace(/_/g, "-");
      let column = columnMap.get(columnId);
      let type = column ? column.dataType : "varchar";

      if (column) {
        if (type === undefined) {
          type = "varchar";
        }

        types.add(type);

        let data = "";

        if (evalValue !== undefined) {
          data = evalValue[column.displayName];
        } else {
          data = tempDataForColumnDataType[type];
        }

        if (data === undefined) {
          data = "";
        }

        if (type === "varchar") {
          convertedValue += "'" + data + "'";
        } else {
          convertedValue += data;
        }
      } else {
        return {
          status: false,
          value: "",
          errorMessage: ColumnNotFound
        };
      }
    } else if (isNumeric(value) || value === "") {
      convertedValue += value;
    } else {
      convertedValue += "'" + value + "'";
    }

    if (operator) {
      convertedValue += operator;
    }
  }

  if (operators.length > splittedRule.length) {
    for (let i = splittedRule.length - 1; i < operators.length; i++) {
      convertedValue += operators[i];
    }
  }

  let hasVarChar = types.has("varchar");
  let operatorCheckForVarChar = operators.filter(op => op != "+").length;
  let evalConvertedValue;
  let hasErrorInEval = false;

  try {
    evalConvertedValue = eval(convertedValue);
  } catch (error) {
    console.error(error.message);
    hasErrorInEval = true;
  }

  let isNaNEvalConvertedValue =
    typeof evalConvertedValue === "number" && isNaN(evalConvertedValue);

  let varCharTypeMismatchCheck =
    hasVarChar && isNaNEvalConvertedValue && operatorCheckForVarChar > 0;
  
    if (varCharTypeMismatchCheck || hasErrorInEval) {
    return {
      status: false,
      value: convertedValue,
      errorMessage: "Dashboard.ConditionalFormatting.TypeMismatch"
    };
  }

  return { status: true, value: convertedValue };
};

/** Options validations */
const validateOptions = appliedConditionalFormat => {
  if (appliedConditionalFormat == undefined) {
    return;
  }

  if (Object.keys(appliedConditionalFormat["options"]).length > 0) {
    return { status: true };
  }

  return {
    status: false,
    message: i18n.t("Dashboard.ConditionalFormatting.OptionNotValid")
  };
};

/** Remove flag attributes in conditional format object */
export const deleteControlFlagsForAttributes = appliedConditionalFormat => {
  if (appliedConditionalFormat === undefined) {
    return;
  }

  delete appliedConditionalFormat[hasOptionsValue];
  delete appliedConditionalFormat[hasTargetColumns];
  delete appliedConditionalFormat[hasRule];
};
