export const configuration = {
    theme: "None",
    hideLabel: false,
};

export const actions = [
    {
        trigger: "barClick",
        type: "click",
        output: ["category"],
        name: "PointClick",
        description: "barClick"
    },
];

export const reactions = [
    {
        id: "filter",
        name: "Filtre",
        description: "desc87",
        type: "general"
    }
];

export const conditionalFormatColumnMap = ["other", "either", "category", "animation"];
export const conditionalFormatTargetMap = ["other", "either"];
export const conditionalFormatOptions = ["backgroundColor"];

export const conversionMap = {
    "table": {
        columnMap: {
            columns: {
                name: "Columns",
                from: ["category", "either", "other", "animation"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
    "scatter-chart": {
        columnMap: {
            measureX: {
                name: "MeasureX",
                from: ["either"],
                multiple: false,
                required: true,
                minimumColumnSize: 1,
            },
            measureY: {
                name: "MeasureY",
                from: ["other"],
                multiple: false,
                required: true,
                minimumColumnSize: 1,
            },
            group: {
                name: "Group",
                from: ["category"],
                multiple: false,
                required: true,
                minimumColumnSize: 1,
            },
            varyColour: {
                name: "VaryColour",
                from: [],
                multiple: false,
                required: false,
                minimumColumnSize: 0,
            },
            varySize: {
                name: "VarySize",
                from: [],
                multiple: false,
                required: false,
                minimumColumnSize: 0,
            },
            animation: {
                name: "Animation",
                from: ["animation"],
                multiple: false,
                required: false,
                minimumColumnSize: 0
            }
        }
    },
    "cylinder-bar": {
        columnMap: {
            values: {
                name: "Values",
                from: ["either", "other"],
                multiple: true,
                required: true,
                minimumColumnSize: 1,
            },
            label: {
                name: "Label",
                from: ["category"],
                multiple: false,
                required: true,
                minimumColumnSize: 1,
            }
        }
    },
    "bar-chart": {
        columnMap: {
            category: {
                name: "Category",
                from: ["category"],
                multiple: false,
                minimumColumnSize: 1,
            },
            columns: {
                name: "Columns",
                from: ["either", "other"],
                dependentColumns: ["Lines"],
                multiple: true,
                minimumColumnSize: 1,
            },
            lines: {
                name: "Lines",
                from: [],
                dependentColumns: ["Columns"],
                multiple: true,
                minimumColumnSize: 1,
            },
            vary: {
                name: "Vary",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
            }
        }
    },
    "line-bar": {
        columnMap: {
            category: {
                name: "Category",
                from: ["category"],
                multiple: false,
                minimumColumnSize: 1,
            },
            columns: {
                name: "Columns",
                from: [],
                dependentColumns: ["Lines"],
                multiple: true,
                minimumColumnSize: 1,
            },
            lines: {
                name: "Lines",
                from: ["either", "other"],
                dependentColumns: ["Columns"],
                multiple: true,
                minimumColumnSize: 1,
            },
            vary: {
                name: "Vary",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
            }
        }
    },
    "multi-axis-line-chart": {
        columnMap: {
            category: {
                name: "Category",
                from: ["category"],
                multiple: false,
                minimumColumnSize: 1,
            },
            columns: {
                name: "Columns",
                from: ["other"],
                dependentColumns: ["Lines"],
                multiple: true,
                minimumColumnSize: 1,
            },
            lines: {
                name: "Lines",
                from: ["either"],
                dependentColumns: ["Columns"],
                multiple: true,
                minimumColumnSize: 1,
            },
            vary: {
                name: "Vary",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
            }
        }
    },
    "measure-tile": {
        columnMap: {
            measure: {
                name: "Measure",
                from: ["either", "other"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
            hidden: {
                from: ["category"]
            }
        }
    },
    "pie-chart": {
        columnMap: {
            category: {
                name: "Category",
                from: ["category"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["either"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            hidden: {
                from: ["other"]
            }
        }
    },
    "radial-progress": {
        columnMap: {
            measure: {
                name: "Measure",
                from: ["either"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            target: {
                name: "Target",
                from: ["other"],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            },
            hidden: {
                from: ["category"]
            }
        }
    },
    "sunburst": {
        columnMap: {
            level: {
                name: "Level",
                from: ["category", "animation"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["either"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            hidden: {
                from: ["other"]
            }
        }
    },
    "gauge-chart": {
        columnMap: {
            measure: {
                name: "Measure",
                from: ["either"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            minimum: {
                name: "Minimum",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            },
            maximum: {
                name: "Maximum",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            },
            target: {
                name: "Target",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            },
            hidden: {
                from: ["category", "other"]
            }
        }
    },
    "liquid-gauge": {
        columnMap: {
            measure: {
                name: "Measure",
                from: ["either"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            target: {
                name: "Target",
                from: ["other"],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            },
            hidden: {
                from: ["category"]
            }
        }
    },
    "radar": {
        columnMap: {
            category: {
                name: "Category",
                from: ["category"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["either"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
            hidden: {
                from: ["other"]
            }
        }
    },
    "tree-map": {
        columnMap: {
            group: {
                name: "Group",
                from: ["category"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["either"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            vary: {
                name: "Vary",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            },
            hidden: {
                from: ["other"]
            }
        }
    },
    "word-cloud": {
        columnMap: {
            word: {
                name: "Word",
                from: ["category"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            freq: {
                name: "Frequency",
                from: ["either"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            hidden: {
                from: ["other"]
            }
        }
    },
    "pivot-table": {
        columnMap: {
            measures: {
                name: "Measures",
                from: ["either", "other"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
            rows: {
                name: "Rows",
                from: ["category"],
                multiple: true,
                minimumColumnSize: 0,
                required: false
            },
            columns: {
                name: "Columns",
                from: ["animation"],
                multiple: true,
                minimumColumnSize: 0,
                required: false
            }
        }
    }
};