import React, { Component } from "react";
import TitleWithIcon from "../../../../GeneralComponents/TitleWithIcon";
import "./label.css";
import {
  Tag,
  Row,
  Col,
  Input,
  Button,
  Typography,
  Divider,
  Tooltip,
} from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import i18n from "../../../../../Utils/i18next";
import { deepCopy } from "../../../../../Utils/Global";
import { SHARED_LABEL } from "../../../../../config";

const { Search } = Input;
const { Text } = Typography;
const sharedDisplayName = i18n.t("Shared")

export default class LabelSelector extends Component {
  constructor(props) {
    super(props);
    this.state = { initialLabelList: [] };
  }

  /**
   * set initialLabelList if it never changed before
   * @param {*} nextProps
   */
  componentWillReceiveProps(nextProps) {
    if (
      this.state.initialLabelList.length == 0 &&
      nextProps.labelList.length > 0
    ) {
      this.setState({
        ...this.state,
        initialLabelList: deepCopy(nextProps.labelList),
      });
    }
  }

  /**
   * Detect whether label is selected or not return true/false
   * return value will be used for styling
   * @param {label} option - label whose selection status will be detected
   */
  detectSelection = (option) => {
    let status = false;

    this.props.selectedLabels.forEach(function (item) {
      if (item.id === option.id) {
        status = true;
        return;
      }
    });

    return status;
  };

  /**
   * Filter label list by using given value
   * @param {*} e - element which fired this function
   */
  onLabelSearch = e => {
    const THIS = this;
    /**
     * Always use initialLabelList so always filter between all of the labels
     */
    let result = deepCopy(THIS.state.initialLabelList);

    if (e.target.value) {
      result = result.filter(function(item) {
        // Since string.search function returns position of string which searched result
        // can be 0 but 0 means false so we should add 1 to it to make it true
        return (
          item.displayName.toLowerCase().search(e.target.value.toLowerCase()) +
          1
        );
      });
    }

    this.props.updateLabelList(result);
  };

  render() {
    return (
      <div
        style={{
          display: this.props.visibleLabelSelector ? "block" : "none",
        }}
      >
        <Row className={"ant-row-padding-full"}>
          <Text>{i18n.t("Dashboard.Label.Selector.Title")}</Text>
        </Row>
        <Row
          style={{
            width: "100%",
            overflowWrap: "break-word",
            whiteSpace: "normal",
            marginBottom: "20px",
          }}
        >
          {this.props.selectedLabels.map((item, index) => {
            if (item.name === SHARED_LABEL) {
              item.displayName = sharedDisplayName;
            }

            return (
              <Tooltip title={item.displayName} key={item.id}>
                <Tag
                  id={item.displayName}
                  color={item.color}
                  key={item.id}
                  className="tag-base"
                  closable
                  onClose={() => this.props.removeLabel(item)}
                >
                  <span>{item.displayName}</span>
                </Tag>
              </Tooltip>
            );
          })}
          <Row
            style={{
              display: this.props.selectedLabels.length == 0 ? "block" : "none",
            }}
          >
            <Text
              type="span"
              style={{
                fontSize: "13px",
                color: "#afafaf",
                textAlign: "center",
                whiteSpace: "normal",
              }}
            >
              {i18n.t("Dashboard.Label.Selector.Description")}
            </Text>
          </Row>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Search
            name={"searchLabel"}
            placeholder={i18n.t("Dashboard.Label.Selector.Search")}
            onChange={this.onLabelSearch}
          />
        </Row>
        <div className={"scroll-labels"}>
          <Row
            key={SHARED_LABEL}
            style={{ textAlign: "left", paddingLeft: "15px" }}
            onClick={() => this.props.assignLabel(this.props.sharedLabel)}
            className={
              this.detectSelection(this.props.sharedLabel) ? "disabled-row" : "default-row"
            }
          >
            <TitleWithIcon
              id={"SharedInScroll"}
              key={SHARED_LABEL + "-0"}
              color={"#1d941b"}
              title={sharedDisplayName}
              className="colored-shape-custom"
              titleStyle="title-style"
            ></TitleWithIcon>
            <CheckCircleFilled
              id={sharedDisplayName + "Filled"}
              style={{
                color: "#099DB2",
                float: "right",
                marginRight: "5px",
                fontSize: "16px",
                marginTop: "3px"
              }}
            />
          </Row>

          {this.props.labelList.map((item, index) => {
            return (
              <Row
                key={item.id}
                style={{ textAlign: "left", paddingLeft: "15px" }}
                onClick={() => this.props.assignLabel(item)}
                className={
                  this.detectSelection(item) ? "disabled-row" : "default-row"
                }
              >
                <TitleWithIcon
                  id={item.displayName + "InScroll"}
                  key={index}
                  color={item.color}
                  title={item.displayName}
                  className="colored-shape-custom"
                  titleStyle="title-style"
                ></TitleWithIcon>
                <CheckCircleFilled
                  id={item.displayName + "Filled"}
                  style={{
                    color: "#099DB2",
                    float: "right",
                    marginRight: "5px",
                    fontSize: "16px",
                    marginTop: "3px",
                  }}
                />
              </Row>
            );
          })}
        </div>
        <Divider
          style={{ width: "%100", marginBottom: "7px", marginTop: "7px" }}
          type="horizontal"
        />
        <Row>
          <Col span={10}>
            <Button
              id="createLabel"
              type="link"
              shape="round"
              size={"small"}
              onClick={() => this.props.changeShownComponent(false)}
            >
              {i18n.t("Dashboard.Label.Selector.CreateLink")}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
