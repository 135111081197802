import React, { Component } from "react";
import PopupContainer from "../GeneralComponents/PopupContainer/PopupContainer";
import Text from "../GeneralComponents/Text/Text";
import Button from "../GeneralComponents/Button/Button";
import ModelsArea from "./ModelsArea";
import i18n from "../../Utils/i18next";
import $ from "jquery"
import { TbPin, TbPinnedOff } from "react-icons/tb"
import { connect } from "react-redux";

/** 
 * The popup in this component contains the models area component where model operations are performed.
 */
export default class ModelsPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modelList: [],
      selectedModel: undefined,
    };
  }

  /**
   * State setting of the model list
   */
  setModelList = (modelList) => {
    this.setState({
      modelList: modelList,
    });
  };

  /**
   * State setting process of the selected model
   */
  setSelectedModel = (selectedModel) => {
    this.setState({
      ...this.state,
      selectedModel: selectedModel,
    });

    this.props.setSelectedModel(selectedModel);
  };

  /**
   *
   * @returns
   *
   * display control for model information text
   */
  displayModelInformation = () => {
    return this.state.modelList.length > 0 ||
      this.props.model.id ||
      this.state.selectedModel !== undefined
      ? "none"
      : "block";
  };

  /**
   * Changing status of pin header
   */
  isSticky = () => {
    let pin;
    if (this.state.pinned) {
      pin = false;
    } else {
      pin = true;
    }
    this.setState({ pinned: pin });
  };

  /**
   * Setting the icon of the pin button
   */
  pinIcon = () => {
    if (this.state.pinned) {
      return <TbPin size={22} />;
    } else {
      return <TbPinnedOff size={22} />;
    }
  };

  render() {
    let generalWidth = $("body").innerWidth();
    let modelsPopupWidth = (generalWidth * 53) / 100; //Models popup width must be equal screen width %53
    let modelsPopupPosition = (-generalWidth * 53) / 100 - 5; //We calculated models popup position here. We gave this position left, so this value must be negative, -((models popup width) + margin-left)
    let pinIcon = this.pinIcon();
    let pincheck = this.state.pinned
    
    return (
      <div style={{ display: this.props.visible ? "block" : "none" }}>
        <PopupContainer
          className={"soft-gray-bg"}
          background={"rgb(251, 251, 251)"}
          width={modelsPopupWidth}
          constantHeight="650px"
          height ="650px"
          position="absolute"
          right={modelsPopupPosition}
        >
          <div
             style={{
              backgroundColor: "rgb(251, 251, 251)",
              position: "sticky",
              top : "0px",
              zIndex: '3'
            }}
          >
              <Text type="h4">{i18n.t("Model.ModelsPopup")}</Text>
              <Text
                type="span"
                style={{
                  fontSize: "14px",
                  display: this.displayModelInformation(),
                  marginTop: "68px",
                  marginBottom: "68px",
                  color: "#afafaf",
                  textAlign: "center",
                  whiteSpace: "normal",
                  padding: "0 120px",
                }}
              >
                {i18n.t("Model.ModelCreationInformation")}
              </Text>
          </div>
          <ModelsArea
            autoSelectModelId={this.props.autoSelectModelId}
            updateModelTablesForJoin={this.props.updateModelTablesForJoin}
            visibleDataSourcesPopup={this.props.visibleDataSourcesPopup}
            visible={this.props.visible}
            modelTableSaveResponse={this.props.modelTableSaveResponse}
            editModel={this.props.editModel}
            newTableInModel={this.props.newTableInModel}
            selectedTables={this.props.selectedTables}
            setModelList={this.setModelList}
            modelId={this.props.modelId}
            model={this.props.model}
            selectedModel={this.setSelectedModel}
            join={this.props.join}
            findJoinChanges={this.props.findJoinChanges}
            getModelTables={this.props.getModelTables}
            joinPopupVisible={this.props.joinPopupVisible}
            setJoinModalPopupVisiblity={this.props.setJoinModalPopupVisiblity}
            getModel={this.props.getModel}
            newColumnAddedFlag={this.props.newColumnAddedFlag}
            removeNewColumn={this.props.removeNewColumn}
          ></ModelsArea> 
          <Button
            id="closeModelButton"
            style={{
              position: "absolute",
              top: "3px",
              right: "14px",
              borderRadius: "40px",
              padding: "6px 11px",
              backgroundColor: "white",
              zIndex: 4,
            }}
            type={"default"}
            onClick={this.props.cancel}
          >
            X
          </Button>
        </PopupContainer>
      </div>
    );
  }
}
