import { Button, Col, Row, Tooltip } from "antd";
import React, { Component } from "react";
import i18n from "../../Utils/i18next";
import "./userNotifications.css";
import { TbPin, TbPinnedOff } from "react-icons/tb";
import { BiBell, BiRefresh } from "react-icons/bi";

export default class UserNotificationsHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let pincheck = this.props.isPinned;

        return (
            <>
                <Row className="notification-text-container">
                    <Col span={18} style={{display: "flex", alignItems: "center", gap: "30px"}}>
                        <BiBell style={{ fontSize: "27px", marginLeft: "30px" }} />
                        <div style={{ color: "#505050", fontSize: "22px" }}>{i18n.t("Notification.Notifications")}</div>
                    </Col>
                    <Col span={6} style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                        flexDirection: "row-reverse",
                        justifyContent: "flex-end"
                    }} className="hide-on-mobile">
                        <Tooltip
                            title={i18n.t("DashboardComments.Refresh")}
                            placement={"bottom"}
                            overlayClassName={"pin-tooltip dashboard-comment-tooltip"}>
                            <Button id={"dashboard-comment-add-comment-button"}
                                style={{
                                    borderRadius: "40px",
                                    padding: "6px 11px",
                                    backgroundColor: "white",
                                    zIndex: 1,
                                    padding: 0,
                                    width: "32px",
                                    height: "30px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    position: "",
                                    fontSize: "23px"
                                }}
                                type={"default"}
                                onClick={() => {
                                    this.props.getAllNotifications()
                                }}
                            >
                                <BiRefresh />
                            </Button>
                        </Tooltip>
                        <Tooltip
                            title={i18n.t(pincheck ? "Model.UnPin" : "Model.Pin")}
                            placement={"bottom"}
                            overlayClassName={"pin-tooltip dashboard-comment-tooltip"}>
                            <Button
                                id="pinTop"
                                className="pin-icon-comment"
                                style={
                                    pincheck
                                        ? {
                                            border: "1px solid #1890ff",
                                            color: "#1890ff",
                                            position: "unset!important"
                                        }
                                        : {
                                            border: "1px solid rgb(217,217,217)",
                                            color: "rgba(0,0,0,0.65)",
                                            position: "unset!important"
                                        }
                                }
                                type={"default"}
                                onClick={this.props.setIsPinned}
                            >
                                {this.props.isPinned ? <TbPin size={22} /> : <TbPinnedOff size={22} />}
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
                <Tooltip
                    title={i18n.t("DashboardComments.Close")}
                    placement={"top"}>
                    <Button
                        id={"notification-close-button"}
                        style={{
                            borderRadius: "40px",
                            padding: "6px 11px",
                            backgroundColor: "white",
                            zIndex: 1,
                            position: "absolute",
                            right: "10px",
                            top: "15px"
                        }}
                        type={"default"}
                        onClick={() => {
                            this.props.setVisibilityOfNotificationPopup(false);
                        }}
                    >
                        X
                    </Button>
                </Tooltip>
            </>
        );
    }
}
