import { Modal } from "antd";
import React, { Component } from "react";
import i18n from "../../Utils/i18next";

/**
 * This popup appears when you want to remove any table that has a join.
 */
export default class RemoveTableModal extends Component {
  render() {
    return (
      <Modal
        title={i18n.t("Model.DeleteTable")}
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        onOk={() => this.props.onOk(this.props.table, this.props.source)}
        okText={i18n.t("OK")}
        cancelText={i18n.t("Cancel")}
      >
        {this.props.table ? (
          <div>
            <span>{i18n.t("Model.RemoveTableWarningDescription")}</span>
            <br></br>
            <span>
              {i18n.t("Model.TableName") + " "}{" "}
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {this.props.table?.displayName}
              </span>
            </span>
          </div>
        ) : null}
      </Modal>
    );
  }
}
