import {
  AimOutlined,
  BgColorsOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  FieldTimeOutlined,
  FontSizeOutlined,
  InfoCircleOutlined,
  PlusOutlined
} from "@ant-design/icons";
import { Button, Checkbox, Col, Divider, Input, InputNumber, Row, Select, Tooltip } from "antd";
import $ from "jquery";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import React, { Component } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { MdDelete, MdErrorOutline } from "react-icons/md";
import i18n from "../../../../Utils/i18next";
import Text from "../../../GeneralComponents/Text/Text";
import ThemePalette from "../../../GeneralComponents/ThemePalette/ThemePalette";
import TitleSettingsOfPlugin from "../../../GeneralComponents/TitleSettingsOfPlugin/TitleSettingsOfPlugin";
import { InsightsConfig } from "../../RenderJs/config";
import _ from "lodash";
import { deepCopy } from "../../../../Utils/Global";
import { getFormattedValue } from "../format";
import { TbHistory } from "react-icons/tb";
import uuid from "react-uuid";
import ReturnToTheme from "../../PluginToolsComponents/ReturnToTheme";

const { TextArea } = Input;
const { Option } = Select;

export default class RadarConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backgroundColor: this.props.config.backgroundColor,
      colours: this.props.config.colours,
      fillOpacity: this.props.config.fillOpacity,
      legend: this.props.config.legend,
      levels: this.props.config.levels,
      ownScale: this.props.config.ownScale,
      pointSize: this.props.config.pointSize,
      showHideButton: this.props.config.showHideButton,
      size: this.props.config.size,
      strokeOpacity: this.props.config.strokeOpacity,
      title: this.props.config.title,
      titleAlign: this.props.config.titleAlign,
      paletteColours: InsightsConfig.Palettes[this.props.config.colours]
        ? InsightsConfig.Palettes[this.props.config.colours]
        : this.props.config.colours,
      titleFontStyle: this.props.config.titleFontStyle === undefined ? false : this.props.config.titleFontStyle,
      titleFontWeight: this.props.config.titleFontWeight === undefined ? false : this.props.config.titleFontWeight,
      titleFontSize: this.props.config.titleFontSize === undefined ? 15 : this.props.config.titleFontSize,
      titleFont: this.props.config.titleFont === undefined ? "Verdana" : this.props.config.titleFont,
      titleColour: this.props.config.titleColour === undefined ? "black" : this.props.config.titleColour,
      titleTextDecor: this.props.config.titleTextDecor === undefined ? false : this.props.config.titleTextDecor,
      changedTitleFontSize: this.props.config.changedTitleFontSize === undefined ? 15 : this.props.config.changedTitleFontSize,
      keyForTitleSize: null,
      noDataTitle: this.props.config.noDataTitle === undefined ? "" : this.props.config.noDataTitle,
      distanceWithGrid: this.props.config.distanceWithGrid == undefined ? 30 : this.props.config.distanceWithGrid,
      strokeWidth: this.props.config.strokeWidth == undefined ? 2 : this.props.config.strokeWidth,
      showValuesOnRadar: this.props.config.showValuesOnRadar == undefined ? false : this.props.config.showValuesOnRadar,
      legendPosition: this.props.config.legendPosition == undefined ? "right" : this.props.config.legendPosition,
      categoryFontSize: this.props.config.categoryFontSize == undefined ? 11 : this.props.config.categoryFontSize,
      rangeFontSize: this.props.config.rangeFontSize == undefined ? 12 : this.props.config.rangeFontSize,
      ranges: this.props.config.ranges == undefined ? [] : this.props.config.ranges,
      categorySet: this.props.categorySet == undefined ? new Set() : this.props.categorySet,
      condFormat: this.props.config.condFormat == undefined ? this.props.config.showCondForm || true : this.props.config.condFormat,
      verticalScrollbar: this.props.config.verticalScrollbar == undefined ? false : this.props.config.verticalScrollbar,
      horizontalScrollbar: this.props.config.horizontalScrollbar == undefined ? false : this.props.config.horizontalScrollbar,
      showRanges: this.props.config.showRanges,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      let flag = false

      let newObject = {
        backgroundColor: nextProps.config.backgroundColor,
        colours: nextProps.config.colours,
        fillOpacity: nextProps.config.fillOpacity,
        legend: nextProps.config.legend,
        levels: nextProps.config.levels,
        ownScale: nextProps.config.ownScale,
        pointSize: nextProps.config.pointSize,
        showHideButton: nextProps.config.showHideButton,
        size: nextProps.config.size,
        strokeOpacity: nextProps.config.strokeOpacity,
        title: nextProps.config.title,
        titleAlign: nextProps.config.titleAlign,
        paletteColours: InsightsConfig.Palettes[nextProps.config.colours]
          ? InsightsConfig.Palettes[nextProps.config.colours]
          : nextProps.config.colours,
        titleFont: nextProps.config.titleFont === undefined ? "Verdana" : nextProps.config.titleFont,
        titleFontSize: nextProps.config.titleFontSize === undefined ? 15 : nextProps.config.titleFontSize,
        changedTitleFontSize: nextProps.config.changedTitleFontSize === undefined ? 15 : nextProps.config.changedTitleFontSize,
        titleFontStyle: nextProps.config.titleFontStyle === undefined ? false : nextProps.config.titleFontStyle,
        titleFontWeight: nextProps.config.titleFontWeight === undefined ? false : nextProps.config.titleFontWeight,
        titleTextDecor: nextProps.config.titleTextDecor === undefined ? false : nextProps.config.titleTextDecor,
        titleColour: nextProps.config.titleColour === undefined ? "black" : nextProps.config.titleColour,
        noDataTitle: nextProps.config.noDataTitle === undefined ? "" : nextProps.config.noDataTitle,
        distanceWithGrid: nextProps.config.distanceWithGrid == undefined ? 30 : nextProps.config.distanceWithGrid,
        strokeWidth: nextProps.config.strokeWidth == undefined ? 2 : nextProps.config.strokeWidth,
        showValuesOnRadar: nextProps.config.showValuesOnRadar == undefined ? false : nextProps.config.showValuesOnRadar,
        legendPosition: nextProps.config.legendPosition == undefined ? "right" : nextProps.config.legendPosition,
        categoryFontSize: nextProps.config.categoryFontSize == undefined ? 11 : nextProps.config.categoryFontSize,
        rangeFontSize: nextProps.config.rangeFontSize == undefined ? 12 : nextProps.config.rangeFontSize,
        ranges: nextProps.config.ranges == undefined ? [] : nextProps.config.ranges,
        categorySet: nextProps.categorySet == undefined ? new Set() : nextProps.categorySet,
        condFormat: nextProps.config.condFormat == undefined ? nextProps.config.showCondForm || true : nextProps.config.condFormat,
        verticalScrollbar: nextProps.config.verticalScrollbar == undefined ? false : nextProps.config.verticalScrollbar,
        horizontalScrollbar: nextProps.config.horizontalScrollbar == undefined ? false : nextProps.config.horizontalScrollbar,
        showRanges: nextProps.config.showRanges,
      }

      let copiedRanges = deepCopy(nextProps.config.ranges)
      let newRanges = deepCopy(nextProps.config.ranges)

      if (nextProps.config.ranges) {
        for (let i = 0; i < newRanges.length; i++) {
          let startCategory = newRanges[i].startCategory
          let endCategory = newRanges[i].endCategory
          let categoryAliasName = newRanges[i].categoryAliasName

          if (nextProps.plugin?.columnMapForPlugin?.category?.aliasName !== categoryAliasName) {
            copiedRanges = copiedRanges.filter(range => range.startCategory !== startCategory || range.endCategory !== endCategory)
          }
        }

        if (!_.isEqual(copiedRanges, newRanges)) {
          flag = true

          this.setState({
            ...newObject,
            ranges: copiedRanges,
          }, () => {
            this.setConfigToPlugin({ type: "ranges", value: copiedRanges });
          })
        }
      }

      if (!flag) {
        this.setState(newObject)
      }
    }
  }

  componentDidMount() {
    if (this.props.config.ranges) {
      let copiedRanges = deepCopy(this.props.config.ranges)
      let newRanges = deepCopy(this.props.config.ranges)

      for (let i = 0; i < newRanges.length; i++) {
        let startCategory = newRanges[i].startCategory
        let endCategory = newRanges[i].endCategory
        let categoryAliasName = newRanges[i].categoryAliasName

        if (this.props.plugin?.columnMapForPlugin?.category?.aliasName !== categoryAliasName) {
          copiedRanges = copiedRanges.filter(range => range.startCategory !== startCategory || range.endCategory !== endCategory)
        }
      }

      if (!_.isEqual(copiedRanges, newRanges)) {
        this.setState({
          ...this.state,
          ranges: copiedRanges
        }, () => {
          this.setConfigToPlugin({ type: "ranges", value: copiedRanges });
        })
      }
    }
  }

  /*
  * Changes both of constant and changed value 
  * constant = between 10 and 30 values
  * changed value = all of values
  */
  handleChangeBothOfValues = (constantValue, changedValue) => {
    this.setState({
      ...this.state,
      titleFontSize: constantValue,
      changedTitleFontSize: changedValue
    })

    this.setConfigToPlugin(
      [
        {
          type: "titleFontSize",
          value: constantValue
        },
        {
          type: "changedTitleFontSize",
          value: changedValue
        }
      ]);
  }

  //sets defaults we get from plugin.jsx. callback for fontsizekey because it should keep changing to trigger default revert
  setDefaultsForTitle = () => {
    let newConfig = this.props.setDefaultForPluginTitle();

    this.setState(
      {
        ...this.state,
        title: newConfig.title,
        titleAlign: newConfig.titleAlign,
        titleFont: newConfig.titleFont,
        titleFontSize: newConfig.titleFontSize,
        titleFontStyle: newConfig.titleFontStyle,
        titleFontWeight: newConfig.titleFontWeight,
        titleTextDecor: newConfig.titleTextDecor,
        titleColour: newConfig.titleColour,
        changedTitleFontSize: newConfig.titleFontSize
      }, () => {
        this.setConfigToPlugin({ type: "changedTitleFontSize", value: newConfig.titleFontSize })
      }
    );

    if (newConfig.titleFontSize > 10 || newConfig.titleFontSize < 30) {
      $("#titleFontSize").css("background", "")
    }
  }

  titleColourChangeVisible = () => {
    this.setState({
      ...this.state,
      titleColourChange: !this.state.titleColourChange
    });
  };

  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.value });
  };

  /*
  * Changes legend position
  */
  handleChangeLegendPosition = e => {
    this.setState({
      ...this.state,
      legendPosition: e
    });

    this.setConfigToPlugin({ type: "legendPosition", value: e });
  };

  handleCheck = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.checked });
  };

  /*
  * Sets config to plugin
  */
  setConfigToPlugin = (changedObj, propertyListToDelete = undefined) => {
    let tempState = { ...this.state };

    if (Array.isArray(changedObj)) {
      for (let i = 0; i < changedObj.length; i++) {
        let object = changedObj[i];
        tempState[object.type] = object.value;
      }
    } else {
      tempState[changedObj.type] = changedObj.value;
    }

    if (propertyListToDelete !== undefined) {
      for (let item of propertyListToDelete) {
        delete tempState[item]
      }
    }

    this.props.updateConfig(
      tempState,
      this.props.pluginId,
      propertyListToDelete
    );
  };

  iconOnChange = value => {
    this.setState({
      ...this.state,
      icon: value
    });

    this.setConfigToPlugin({ type: "icon", value: value });
  };

  colorOnChange = obj => {
    this.setState({
      ...this.state,
      colour: obj.color
    });

    this.setConfigToPlugin({ type: "colour", value: obj.color });
  };

  titleAlignOnChange = value => {
    this.setState({
      ...this.state,
      titleAlign: value
    });

    this.setConfigToPlugin({ type: "titleAlign", value: value });
  };

  colorChangeVisible = () => {
    this.setState({ ...this.state, colorChange: !this.state.colorChange });
  };

  backgroundColorOnChange = obj => {
    this.setState({
      ...this.state,
      backgroundColor: obj.color
    });

    this.setConfigToPlugin({ type: "backgroundColor", value: obj.color });
  };

  backgroundColorChangeVisible = () => {
    this.setState({
      ...this.state,
      backgroundColorChange: !this.state.backgroundColorChange
    });
  };

  coloursOnChange = value => {
    this.setState({
      ...this.state,
      colours: value
    });

    this.setConfigToPlugin({ type: "colours", value: value });
  };

  /*
  * Changes value of measure duration
  */
  handleChangeDistanceWithGrid = (value) => {
    if (value || value === 0 || value === 0.0) {
      this.handleChange({ target: { id: "distanceWithGrid", value: Number(value) ? value : 0 } })
    } else {
      return
    }
  }

  /*
  * Changes value of measure duration
  */
  handleChangePointSize = (value) => {
    if (value || value === 0 || value === 0.0) {
      this.handleChange({ target: { id: "pointSize", value: Number(value) ? value : 0 } })
    } else {
      return
    }
  }

  /*
  * Changes fill opacity
  */
  handleChangeFillOpacity = (value) => {
    if (value || value === 0 || value === 0.0) {
      this.handleChange({ target: { id: "fillOpacity", value: Number(value) ? value : 0 } })
    } else {
      return
    }
  }

  /*
  * Changes stroke width
  */
  handleChangeStrokeWidth = (value) => {
    if (value || value === 0 || value === 0.0) {
      this.handleChange({ target: { id: "strokeWidth", value: Number(value) ? value : 0 } })
    } else {
      return
    }
  }

  /*
  * Changes category font size
  */
  handleChangeCategoryFontSize = (value) => {
    if (value || value === 0 || value === 0.0) {
      this.handleChange({ target: { id: "categoryFontSize", value: Number(value) ? value : 0 } })
    } else {
      return
    }
  }

  /*
  * Changes range font size
  */
  handleChangeRangeFontSize = (value) => {
    if (value || value === 0 || value === 0.0) {
      this.handleChange({ target: { id: "rangeFontSize", value: Number(value) ? value : 0 } })
    } else {
      return
    }
  }

  /*
  * Gets random color
  */
  getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  /*
  * Adds ranges
  */
  addRange = () => {
    let copiedRanges = deepCopy(this.state.ranges)

    let newCreatedRange = {
      id: uuid(),
      startCategory: null,
      endCategory: null,
      error: false,
      visible: true,
      fill: this.getRandomColor(),
      fillOpacity: 0.3,
      categoryAliasName: this.props.plugin?.columnMap?.category?.data[0]?.aliasName
    }

    copiedRanges.push(newCreatedRange)

    this.setState({
      ...this.state,
      ranges: copiedRanges
    })

    this.setConfigToPlugin({ type: "ranges", value: copiedRanges });
  }

  /*
  * Sets category of range
  */
  setCategory = (category, index, type) => {
    let copiedRanges = deepCopy(this.state.ranges)

    copiedRanges[index][type] = category

    this.setConfigToPlugin({ type: "ranges", value: copiedRanges });
  }

  /*
  * Deletes range
  */
  deleteRange = index => {
    let copiedRanges = deepCopy(this.state.ranges)

    copiedRanges = copiedRanges.filter((range, i) => index !== i)

    this.setConfigToPlugin({ type: "ranges", value: copiedRanges });
  }

  /*
  * Changes visibility of single range
  */
  changeVisibilityOfRange = index => {
    let copiedRanges = deepCopy(this.state.ranges)

    copiedRanges[index]["visible"] = !copiedRanges[index]["visible"]

    this.setConfigToPlugin({ type: "ranges", value: copiedRanges });
  }

  /*
  * Changes fill opacity of range
  */
  handleChangeFillOpacityForRange = (value, index) => {
    if (value || value === 0 || value === 0.0) {
      let copiedRanges = deepCopy(this.state.ranges)

      copiedRanges[index]["fillOpacity"] = value

      this.setConfigToPlugin({ type: "ranges", value: copiedRanges });
    } else {
      return
    }
  }

  /*
  * Sets target color visibility
  */
  setTargetColorVisible = (index) => {
    this.setState({
      ...this.state,
      selectedTarget: index,
      targetColorVisible: index !== null ? true : false
    });
  }

  /*
  * Sets target color
  */
  setTargetColor = (index, color) => {
    let copiedRanges = deepCopy(this.state.ranges)

    copiedRanges[index]["fill"] = color

    this.setConfigToPlugin({ type: "ranges", value: copiedRanges });
  }

  /*
  * Controls is range available, if not pushes new range in first index
  */
  controlIsRangeAvailable = () => {
    if (this.state.ranges.length === 0) {
      let newCreatedRange = {
        id: uuid(),
        startCategory: null,
        endCategory: null,
        error: false,
        visible: true,
        fill: "#9e3bdd",
        fillOpacity: 0.3,
        categoryAliasName: this.props.plugin?.columnMap?.category?.data[0]?.aliasName
      }

      let newRanges = [newCreatedRange]

      this.setState({
        ...this.state,
        ranges: newRanges
      })
    }
  }

  render() {
    return (
      <div style={{ color: "#505050" }}>
        <ReturnToTheme
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          preservedConfigFieldsArray={this.props.plugin.preservedConfigFields ? Array.from(this.props.plugin.preservedConfigFields) : []}
        />
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.GeneralAppearance")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Palette")}
            </Text>
          </Col>
          <Col span={4} style={{ paddingRight: "10px" }}>
            <Select
              id={"colours"}
              defaultValue={this.state.colours}
              value={this.state.colours}
              style={{ width: "100%" }}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={this.coloursOnChange}>
              <Option value="Flat-UI">{i18n.t("Dashboard.Configuration.Fields.FlatUI")}</Option>
              <Option value="Flat-UI-Soft">{i18n.t("Dashboard.Configuration.Fields.FlatUISoft")}</Option>
              <Option value="Cool-Scale">{i18n.t("Dashboard.Configuration.Fields.CoolScale")}</Option>
              <Option value="Warm-Scale">{i18n.t("Dashboard.Configuration.Fields.WarmScale")}</Option>
              <Option value="Heatmap">{i18n.t("Dashboard.Configuration.Fields.Heatmap")}</Option>
            </Select>
          </Col>
          <Col span={8}>
            <ThemePalette
              colours={this.state.paletteColours}
              update={this.setConfigToPlugin}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.BackgroundColor")}
            </Text>
          </Col>
          <Col span={12}>
            <Button
              onClick={this.backgroundColorChangeVisible}
              style={{ width: "100%" }}
            >
              {this.state.backgroundColorChange
                ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
            </Button>
            {this.state.backgroundColorChange ? (
              <ColorPickerPanel
                alpha={100}
                color={this.state.backgroundColor}
                onChange={this.backgroundColorOnChange}
                id={"backgroundColor"}
                style={{ marginTop: "5px", position: "absolute", right: 0 }}
              />
            ) : null}
          </Col>
        </Row>
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Chart")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.PointSize")}
            </Text>
          </Col>
          <Col span={12}>
            <InputNumber
              id={"pointSize"}
              defaultValue={this.state.pointSize}
              value={this.state.pointSize}
              onChange={(value) => this.handleChangePointSize(value)}
              style={{ width: "100%" }}
              min={0}
              step={1}
              formatter={value => `${value}`.replace(/[^0-9.]/g, '')}
              parser={value => `${value}`.replace(/[^0-9.]/g, '')}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.StrokeWidth")}
            </Text>
          </Col>
          <Col span={12}>
            <InputNumber
              id={"strokeWidth"}
              defaultValue={this.state.strokeWidth}
              value={this.state.strokeWidth}
              onChange={(value) => this.handleChangeStrokeWidth(value)}
              style={{ width: "100%" }}
              min={0}
              step={1}
              formatter={value => `${value}`.replace(/[^0-9.]/g, '')}
              parser={value => `${value}`.replace(/[^0-9.]/g, '')}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.FillOpacity")}
            </Text>
          </Col>
          <Col span={12}>
            <InputNumber
              id={"fillOpacity"}
              defaultValue={this.state.fillOpacity}
              value={this.state.fillOpacity}
              onChange={(value) => this.handleChangeFillOpacity(value)}
              style={{ width: "100%" }}
              min={0}
              step={0.1}
              formatter={value => `${value}`.replace(/[^0-9.]/g, '')}
              parser={value => `${value}`.replace(/[^0-9.]/g, '')}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.CategoryFontSize")}
            </Text>
          </Col>
          <Col span={12}>
            <InputNumber
              id={"categoryFontSize"}
              defaultValue={this.state.categoryFontSize}
              value={this.state.categoryFontSize}
              onChange={(value) => this.handleChangeCategoryFontSize(value)}
              style={{ width: "100%" }}
              min={0}
              step={1}
              formatter={value => `${value}`.replace(/[^0-9.]/g, '')}
              parser={value => `${value}`.replace(/[^0-9.]/g, '')}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FieldTimeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.DistanceWithGrid")}
            </Text>
          </Col>
          <Col span={12}>
            <InputNumber
              id={"distanceWithGrid"}
              defaultValue={this.state.distanceWithGrid}
              value={this.state.distanceWithGrid}
              style={{ width: "100%" }}
              min={0}
              step={1}
              formatter={value => `${value}`.replace(/[^0-9.]/g, '')}
              parser={value => `${value}`.replace(/[^0-9.]/g, '')}
              onChange={(value) => this.handleChangeDistanceWithGrid(value)}
            />
          </Col>
        </Row>

        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowValueOnRadar")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"showValuesOnRadar"}
              defaultChecked={this.state.showValuesOnRadar}
              checked={this.state.showValuesOnRadar}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        {this.state.showValuesOnRadar ?
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.RadarValueFontSize")}
              </Text>
            </Col>
            <Col span={12}>
              <InputNumber
                id={"rangeFontSize"}
                defaultValue={this.state.rangeFontSize}
                value={this.state.rangeFontSize}
                onChange={(value) => this.handleChangeRangeFontSize(value)}
                style={{ width: "100%" }}
                min={0}
                step={1}
                formatter={value => `${value}`.replace(/[^0-9.]/g, '')}
                parser={value => `${value}`.replace(/[^0-9.]/g, '')}
              />
            </Col>
          </Row> : null}
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Ranges")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowRanges")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"showRanges"}
              defaultChecked={this.state.showRanges}
              checked={this.state.showRanges}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        {this.state.showRanges ?
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <AimOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Targets")}
              </Text>
            </Col>
          </Row> : null}
        {this.controlIsRangeAvailable()}
        {this.state.showRanges ? this.state.ranges.map((range, i) => (
          <Row style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "10px", marginTop: "10px" }}>
            <Col span={6}>
              <Select 
                value={range.startCategory} 
                style={{ width: "100%" }} 
                getPopupContainer={(trigger) => trigger.parentNode}
                placeholder="Start Category" 
                onChange={(e) => this.setCategory(e, i, "startCategory")}
              >
                {Array.from(this.state.categorySet.values()).map(category => (
                  <Option key={category}>{this.props.columnMap ? getFormattedValue(this.props.columnMap.category, category) : category}</Option>
                ))}
              </Select>
            </Col>
            <Col span={6}>
              <Select 
                value={range.endCategory} 
                style={{ width: "100%" }} 
                getPopupContainer={(trigger) => trigger.parentNode}
                placeholder="End Category" 
                onChange={(e) => this.setCategory(e, i, "endCategory")}
              >
                {Array.from(this.state.categorySet.values()).map(category => (
                  <Option key={category}>{this.props.columnMap ? getFormattedValue(this.props.columnMap.category, category) : category}</Option>
                ))}
              </Select>
            </Col>
            <Col span={3}>
              <InputNumber
                value={range.fillOpacity}
                placeholder="Fill Opacity"
                onChange={(value) => this.handleChangeFillOpacityForRange(value, i)}
                style={{ width: "100%" }}
                min={0}
                step={0.1}
                formatter={value => `${value}`.replace(/[^0-9.]/g, '')}
                parser={value => `${value}`.replace(/[^0-9.]/g, '')}>
              </InputNumber>
            </Col>
            <Col span={4} style={{ padding: "0 2px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Tooltip
                placement="right"
                overlayClassName="radar-range-tooltip"
                title={
                  !this.state.targetColorVisible || this.state.selectedTarget !== i
                    ? i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")
                    : null
                }
              >
                <div
                  style={{
                    height: "32px",
                    width: "100%",
                    border: "1px solid #d7d6d6",
                    boxShadow: "0 2px 0 rgba(0, 0, 0,}",
                    borderRadius: "4px",
                    cursor: "pointer",
                    backgroundColor: range.fill || "#FF0000",
                  }}
                  onClick={() => this.setTargetColorVisible(!this.state.targetColorVisible ? i : null)}
                />
              </Tooltip>
              {this.state.targetColorVisible && this.state.selectedTarget === i ? (
                <div className={"palette-colour-picker"}>
                  <div className={"close-colour-palette"}>
                    <CloseOutlined onClick={() => this.setTargetColorVisible(!this.state.targetColorVisible ? i : null)} />
                  </div>
                  <ColorPickerPanel
                    alpha={100}
                    color={range.fill}
                    onChange={obj => this.setTargetColor(i, obj.color)}
                    id={"perColor"}
                    style={{ marginTop: "10px" }}
                  />
                </div>
              ) : null}
            </Col>

            <Col span={2} style={{ display: "flex", gap: "10px", alignItems: "center", justifyContent: "flex-end" }}>
              {range.visible ?
                <AiOutlineEye style={{ fontSize: "19px", cursor: "pointer" }} onClick={() => this.changeVisibilityOfRange(i)} /> :
                <AiOutlineEyeInvisible style={{ fontSize: "19px", cursor: "pointer" }} onClick={() => this.changeVisibilityOfRange(i)} />}
              <MdDelete style={{ fontSize: "22px", color: "#e2605e", cursor: "pointer" }} onClick={() => this.deleteRange(i)} />
              {range.error ?
                <Tooltip title={i18n.t("Dashboard.Configuration.Fields.CategoriesNotFound")}>
                  <MdErrorOutline style={{ fontSize: "22px", color: "rgb(255, 165, 34)", cursor: "pointer" }} />
                </Tooltip> : null}
            </Col>
          </Row>
        )) : null}
        {this.state.ranges[this.state.ranges.length - 1]?.startCategory && this.state.ranges[this.state.ranges.length - 1]?.endCategory && this.state.showRanges ?
          <Row style={{ marginTop: "10px" }}>
            <Col span={24} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <PlusOutlined className={"circle-icon"}
                name={"nextColor"}
                style={{ marginTop: "3.5px", cursor: "pointer", fontSize: "14px" }}
                onClick={() => this.addRange()} />
            </Col>
          </Row> : null}
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Legends")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Legend")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"legend"}
              defaultChecked={this.state.legend}
              checked={this.state.legend}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        {this.state.legend ?
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.LegendPosition")}
              </Text>
            </Col>
            <Col span={12} style={{ paddingRight: "10px" }}>
              <Select
                id={"legendPosition"}
                defaultValue={this.state.legendPosition}
                value={this.state.legendPosition}
                style={{ width: "100%" }}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={this.handleChangeLegendPosition}>
                <Option value="right">{i18n.t("Dashboard.Configuration.Fields.Right")}</Option>
                <Option value="left">{i18n.t("Dashboard.Configuration.Fields.Left")}</Option>
              </Select>
            </Col>
          </Row> : null}
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowConditionalFormatting")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"condFormat"}
              defaultChecked={this.state.condFormat}
              checked={this.state.condFormat}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Scrollbars")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ScrollbarY")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"verticalScrollbar"}
              defaultChecked={this.state.verticalScrollbar}
              checked={this.state.verticalScrollbar}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ScrollbarX")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"horizontalScrollbar"}
              defaultChecked={this.state.horizontalScrollbar}
              checked={this.state.horizontalScrollbar}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <TitleSettingsOfPlugin
          plugin={this.props.plugin}
          handleChange={this.handleChange}
          commonTitleConfig={this.props.commonTitleConfig}
          updateCommonTitleConfig={this.props.updateCommonTitleConfig}
          isReturnToDefaultforTitleVisible={this.props.isReturnToDefaultforTitleVisible}
          setDefaultsForTitle={this.setDefaultsForTitle}
          title={this.state.title}
          titleAlign={this.state.titleAlign}
          titleColour={this.state.titleColour}
          titleColourChange={this.state.titleColourChange}
          titleColourChangeVisible={this.titleColourChangeVisible}
          titleFont={this.state.titleFont}
          titleFontSize={this.state.titleFontSize}
          keyForTitleSize={this.state.keyForTitleSize}
          titleFontStyle={this.state.titleFontStyle}
          titleFontWeight={this.state.titleFontWeight}
          titleTextDecor={this.state.titleTextDecor}
          setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
          currentAppliedConfig={this.props.currentAppliedConfig}
          changedTitleFontSize={this.state.changedTitleFontSize}
          handleChangeBothOfValues={this.handleChangeBothOfValues}
        />
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Others")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left", display: "flex" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Settings.NoDataConfiguration")}
            </Text>
            <Tooltip
              title={
                <>
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Info")} <br />
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.GraphicConfiguration")} <br />
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Settings")} <br />
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.DefaultText")} <br />
                </>
              }
            >
              <InfoCircleOutlined style={{ position: 'relative', marginTop: '5px', marginLeft: "10px" }} />
            </Tooltip>
          </Col>
          <Col span={12}>
            <TextArea
              allowClear
              id={"noDataTitle"}
              defaultValue={this.state.noDataTitle}
              value={this.state.noDataTitle}
              onChange={this.handleChange}
              autoSize={{ minRows: 3, maxRows: 5 }}
              placeholder={i18n.t("NoDataContent.NoData") + " " +
                i18n.t("NoDataContent.PluginCouldNotBeVisualized")}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
