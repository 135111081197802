import * as htmlToImage from 'html-to-image';
import { saveAs } from 'file-saver';
import { filterNode } from './FilterNodes';

/**
 * Generates the PNG output of the dashboard
 * 
 * @param {*} dashboardDetail 
 * @param {*} mainDiv 
 * @param {*} elementsToDisable 
 */
export const exportToPNG = async (dashboardDetail, mainDiv, elementsToDisable) => {

    /** @function disableAllButtons is used to not show buttons on image */
    function disableAllElements() {
        for (let i = 0 ; i < elementsToDisable.length; i++) {
            elementsToDisable[i].disable = true;
        }
    }

    function enableAllElements() {
        for (let i = 0 ; i < elementsToDisable.length; i++) {
            elementsToDisable[i].disable = false;
        }
    }

    let docName;

    /** Prepare title */
    if (dashboardDetail.settings != undefined) {
        docName = dashboardDetail.settings.title + ".png";
    } else {
        docName = "image.png"
    }

    /** If background is not chosen, background must be white. Otherwise background of png file is black. */
    if (mainDiv.style.background === "") {
        mainDiv.style.background = "white";
    }

    disableAllElements();

    /** Download image */
    let blob = await htmlToImage.toBlob(mainDiv, {filter: filterNode})
    saveAs(blob, docName);
    
    enableAllElements();
}