import React, { Component } from "react";
import i18n from "../../../Utils/i18next";
import Text from "../Text/Text";
import { ClearOutlined, DownOutlined, QuestionCircleOutlined, UpOutlined, WarningOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import Search from "../Search/Search";
import _ from "lodash";
import { deepCopy } from "../../../Utils/Global";
import BoxItem from "../BoxItem/BoxItem";
import { store } from "../../..";
import { setFormulaEditorContent } from "./FormulaEditorAction";

/**
 * This component consists of Deleted queries and parameters list and search bar.
 */
export default class SessionVariableList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sessionVariables: [],
            sessionVariablesMap: new Map(),
            searchValue: "",
            searchedTempData: []
        };
    }

    componentDidMount() {
        let sessionVariables = Array.from(this.props.sessionVariables?.values());

        this.setState({
            sessionVariables: sessionVariables,
            searchedTempData: sessionVariables
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(this.props.sessionVariables, prevState.sessionVariablesMap)) {
            let sessionVariables = Array.from(this.props.sessionVariables?.values()) || [];

            this.setState({
                sessionVariables: sessionVariables,
                sessionVariablesMap: this.props.sessionVariables,
                searchedTempData: this.search(sessionVariables, this.state.searchValue)
            })
        }
    }

    /**
     * Filters data based on search value
     * 
     * @param {*} tempData 
     * @param {*} value 
     * @returns 
     */
    search = (tempData, value) => {
        let data = deepCopy(tempData);

        if (typeof value !== "string") return data;

        return data.filter(d => d.name.toLowerCase().includes(value.toLowerCase()));
    }

    /**
     * Sets search value & filtered data
     * 
     * @param {*} event 
     */
    handleSearch = event => {
        let value = event?.target?.value || "";

        this.setState({
            searchValue: value,
            searchedTempData: this.search(this.state.sessionVariables, value)
        })
    }

    /**
     * Start dragging event
     */
    onDragStart = (event, data) => {
        let dropArea = document.getElementById("formula-editor-drop-area");

        dropArea.style.border = "dashed 2px #b7b7b7";

        event.target.style.opacity = '0.4';
        event.dataTransfer.setData("text/plain", data);
        event.dataTransfer.setDragImage(event.target, 0, 0);
    }

    /**
     * End dragging event 
     */
    onDragEnd = (event) => {
        let dropArea = document.getElementById("formula-editor-drop-area");

        dropArea.style.border = "solid 2px transparent";

        event.target.style.opacity = '1';
    }

    /**
     * Appends session variable to code 
     */
    appendCode = (insertText) => {
        const reduxState = store.getState();
        const { contentObject } = reduxState.FormulaEditorReducer;

        contentObject.code += (insertText);

        store.dispatch(setFormulaEditorContent(contentObject));
    }

    render() {
        return (
            <div
                style={{
                    position: "relative",
                    height: this.props.collapsed ? "57px" : "50%",
                    overflowY: "auto",
                    overflowX: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    alignContent: "center",
                    justifyItems: "center",
                    paddingBottom: 0,
                    borderTop: "solid 1px #dadada",
                    minHeight: !this.props.collapsed && 200
                }}
            >
                <div
                    onClick={() => this.props.collapsed ? this.props.toggleCollapse() : null}
                    style={{
                        height: "fit-content",
                        padding: "0 8px 8px 8px",
                        zIndex: 1,
                        background: "#ffffff",
                        cursor: this.props.collapsed ? "pointer" : "default"
                    }}
                >
                    <Text
                        style={{
                            fontSize: "1rem",
                            margin: "16px 0 8px 0",
                            padding: "0 8px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            position: "relative",
                            textAlign: "center",
                            color: "#225f8f"
                        }}
                    >
                        {
                            this.props.collapsed ? (
                                <UpOutlined
                                    style={{
                                        color: "#afafaf",
                                        cursor: "pointer"
                                    }}
                                />
                            ) : (
                                <DownOutlined
                                    onClick={() => this.props.toggleCollapse()}
                                    style={{
                                        color: "#afafaf",
                                        cursor: "pointer"
                                    }}
                                />
                            )
                        }
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            {i18n.t("SessionVariables.Title")}
                        </div>
                        <div />
                    </Text>
                    {
                        this.props.collapsed === false &&
                            this.state.sessionVariables?.length > 0 ? (
                            <Search
                                value={this.state.searchValue}
                                preventDrop={true}
                                paddingNone={true}
                                marginBottom={"8px"}
                                inputStyle={{ minWidth: "auto" }}
                                id={"searchSessionVariable"}
                                width="100%"
                                searchBarVisible={true}
                                onChange={this.handleSearch}
                            ></Search>
                        ) : null
                    }
                </div>
                {
                    this.props.collapsed ? null : (
                        <div
                            style={{
                                padding: "8px 16px",
                                height: "100%",
                                overflow: "hidden auto",
                            }}
                        >
                            {
                                this.state.searchedTempData?.length ? (
                                    this.state.searchedTempData?.map((v) => (
                                        <div
                                            draggable="true"
                                            onDragStart={(event) => this.onDragStart(event, "${session." + v.name + "}")}
                                            onDragEnd={(event) => this.onDragEnd(event)}
                                            onDoubleClick={() => this.appendCode("${session." + v.name + "}")}
                                        >
                                            <BoxItem
                                                id={v._id}
                                                title={v.name}
                                                tooltipTitle={v.name}
                                                divClassName={"noStyle formulaBox"}
                                                style={{ cursor: "grab" }}
                                                tooltipPlacement="topLeft"
                                            />
                                        </div>
                                    ))
                                ) : null
                            }
                        </div>
                    )
                }
            </div>
        )
    }
}