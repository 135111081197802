import React, { Component } from "react";
import PopupContainer from "../../GeneralComponents/PopupContainer/PopupContainer";
import i18n from "../../../Utils/i18next";
import $ from "jquery";
import { connect } from "react-redux";
import { Divider } from "antd";
import { InteractionOutlined } from "@ant-design/icons";
import { showNotificationWithIcon } from "../../../admin/Utils/Notification";
import {
  changeVisiblePopupContent,
  clearPluginContent,
} from "../PluginTriggerAction";
import {
  setTriggeredDrillDowns,
  setPluginsDrillDowns,
} from "../../DrillDown/DrillDownAction";
import Button from "../../GeneralComponents/Button/Button";
import { pagePopupVisible } from "../../../Utils/PagePopupConfigure";
import { deepCopy, loadingScreen } from "../../../Utils/Global";
import Text from "../../GeneralComponents/Text/Text";
import { store } from "../../../index";
import { setpluginColumnClickedAndShouldBeRemoved } from "../../DrillDown/DrillDownAction";
import { prepareDefaultFilters, prepareInteractionFilters } from "../PluginComponents/dataAndQuery";
import { getPluginsAllColumnsByField } from "../../../Utils/PluginOperations";
import { isArray } from "lodash";
import { filter } from "topojson";


const clone = require("rfdc")();

/**
 * Lists the interactions and navigations of the clicked plugin, it is directed to the desired option.
 */
class InteractionsAndNavigationsList extends Component {
  /**
   *
   * @param {*} columns
   *
   * Creates hashmap for the columns list
   */
  createHashMapForColumns = (columns) => {
    let columnsHashMap = new Map();

    columns &&
      columns.map((column) => {
        columnsHashMap.set(column.uniqeColumnId, column.value);
      });

    return columnsHashMap;
  };

  interactionTrigger = () => {
    const { pluginContent } = this.props.state.PluginTriggerReducer;

    let datum = []

    if (
      typeof pluginContent.datum === "object" &&
      Array.isArray(pluginContent.datum) === false
    ) {
      datum = pluginContent.datum.withoutDefaultFilters;
    } else {
      datum = pluginContent.datum;
    }

    let triggeredPluginInformation = {};

    triggeredPluginInformation = pluginContent;

    if (Array.isArray(datum)) {
      triggeredPluginInformation.value = datum;
    } else {
      triggeredPluginInformation.value = [datum];
    }

    if (triggeredPluginInformation.callback) {
      triggeredPluginInformation.callback(triggeredPluginInformation.value)
    }

    this.props.changeVisiblePopupContent(false);
    this.props.clearPluginContent();
    this.props.interactionTrigger(triggeredPluginInformation);

    let mapChoroplethReducer = this.props.state?.MapChoroplethDetailReducer
    let isPluginChoropleth = mapChoroplethReducer.pluginId === pluginContent?.id ? true : false
    
    if (isPluginChoropleth) {
      this.props.state.MapChoroplethDetailReducer.detailCallbackForInteraction(this.props.state.MapChoroplethDetailReducer.event, this.props.state.MapChoroplethDetailReducer?.currentIndex)
    }
  };

  /**
   *
   * @param {*} values
   * @returns
   *
   * It combines the filter that comes in the form of an array for query and converts it to the appropriate format.
   */
  joinfilterValue = (values) => {
    let joinValue = "(";

    values.map((value) => {
      joinValue = joinValue + "'" + value + "'" + ",";
    });

    joinValue = joinValue.slice(0, joinValue.length - 1) + ")";

    return joinValue;
  };

  /**
   *
   * @param {*} pluginContent
   * @returns
   *
   * Checks whether the plugin has interaction
   */
  hasPluginInteraction = (pluginContent) => {
    let hasInteraction = false;

    pluginContent.interactions &&
      pluginContent.interactions.map((interaction) => {
        if (
          interaction.sourceId === pluginContent.pluginId &&
          this.doesInteractionContainsGivenAction(pluginContent, interaction)
        ) {
          hasInteraction = true;
        }
      });

    return hasInteraction;
  };

  /**
   *
   * @param {*} triggeringPluginInformation
   * @param {*} interaction
   * Checks whether the desired action is available
   */
  doesInteractionContainsGivenAction = (
    triggeringPluginInformation,
    interaction
  ) => {
    return interaction.actions.some(
      (action) => action.trigger === triggeringPluginInformation.event
    );
  };

  /**
   *
   * @param {*} value
   * @returns
   *
   * Converts single filter value to suitable format for query
   */
  formattedSingleFilterValue = (value) => {
    let formattedValue = "'" + value + "'";

    return formattedValue;
  };

  /**
   *
   * @param {*} datumValue
   * @returns
   * Performs the datum value empty control
   */
  checkEmptyValue = (datumValue) => {
    return (
      datumValue &&
      datumValue.value !== undefined &&
      datumValue.value !== "" &&
      datumValue.value !== null &&
      datumValue.value !== "\"notCurrentCode\"" &&
      datumValue.value !== "notCurrentCode" &&
      datumValue.value !== datumValue.Code
    );
  };

  /**
   *
   * @param {*} datumValue
   * @returns
   * Returns filter string from filter values
   */
  getFilterString = (values) => {
      if (!values) return null;
      
      let stringValue = "";

      if (Array.isArray(values)) {
        if (values.length > 1) {
          values.map((value, index) => {
            stringValue = stringValue + value + (index < values.length - 1 ? ", " : "");
          });
        } else if (values.length === 1) {
          stringValue = values; 
        }
      } else {
        let isStringHasApostropheWithSingleValue = values[0] === "'" && values[values.length - 1] === "'" ? true : false
        let isStringHasBacktickWithSingleValue = values[0] === "`" && values[values.length - 1] === "`" ? true : false
        let isStringHasBrackets = values[0] === "(" && values[values.length - 1] === ")" ? true : false

        if (isStringHasApostropheWithSingleValue || isStringHasBacktickWithSingleValue) {
          stringValue = values.slice(1, values.length - 1)
        } else if (isStringHasBrackets) {
          let slicedValues = values.slice(1, values.length - 1)
          let splittedValues = slicedValues.split(",")
          let valuesArray = []

          splittedValues.map(splittedVal => {
            
            if (splittedVal[0] === "'" && splittedVal[splittedVal.length - 1] === "'")
            {
              let slicedVal = splittedVal.slice(1, splittedVal.length - 1)
              valuesArray.push(slicedVal)
            }
            else {
              valuesArray.push(splittedVal)
            }
          })

          stringValue = valuesArray.join(", ")
        } else {
          stringValue = values
        }
      }

      return stringValue
  };

  /**
   *
   * @param {*} pluginContent
   * Prepares the relevant column and filter information according to the clicked column so that drilldown can be performed.
   */
  drillDownTrigger = (pluginContent) => {
    let drillDown = this.props.state.DrillDownReducer.drillDowns.get(
      pluginContent.pluginId
    );
    let drillDownMap = drillDown.drillDownLayerMap;
    let allDrillDownColumnsInPlugin = drillDown.allDrillDownColumnsInPlugin;
    let clickedColumn = pluginContent.clickedColumn[0];
    let triggeredDrillDowns =
      this.props.state.DrillDownReducer.triggeredDrillDowns;
    let selectedTriggeredDrillDowns = triggeredDrillDowns.get(
      pluginContent.pluginId
    );
    let newSelectedFilterColumns = selectedTriggeredDrillDowns
      ? selectedTriggeredDrillDowns.filters
      : [];
    let newSelectedColumns = selectedTriggeredDrillDowns
      ? selectedTriggeredDrillDowns.selectedColumns
      : [];

    let nextDrillDownColumn = drillDownMap.get(
      clickedColumn.aliasName + "#" + clickedColumn.uniqeColumnId
    );

    if (
      nextDrillDownColumn &&
      nextDrillDownColumn.hasDrillDownOperation === false
    ) {
      let filterColumn = { ...clickedColumn };
      let selectedColumn = allDrillDownColumnsInPlugin.get(
        nextDrillDownColumn.nextDrillDownColumnId
      );

      //Filters started
      let values = [];
      let datum = [];

      if (
        typeof pluginContent.datum === "object" &&
        Array.isArray(pluginContent.datum) === false
      ) {
        datum = pluginContent.datum.withoutDefaultFilters;
      } else {
        datum = pluginContent.datum;
      }

      if (
        Array.isArray(datum[0][filterColumn.locationFieldName])
      ) {
        datum[0][filterColumn.locationFieldName].map((item) => {
          if (item.name === filterColumn.displayName) {
            if (this.checkEmptyValue(item)) {
              values.push(item.value);
            }
          }
        });
      } else {
        values.push(datum[0][filterColumn.locationFieldName]);
      }

      if (values.length > 1) {
        values = this.joinfilterValue(values);
        filterColumn.filterPredicate = "in";
      } else if (values.length === 1) {
        values = this.formattedSingleFilterValue(values[0]);
        filterColumn.filterPredicate = "=";
      }

      filterColumn.Code = clickedColumn.value;
      filterColumn.value = values;
      //Filter Finished

      newSelectedFilterColumns.push(filterColumn);
      newSelectedColumns.push(selectedColumn);

      let selectedItemAndColumn = {
        filter: filterColumn,
        selectedColumn: selectedColumn,
      };

      let triggeredDrillDownItem = {
        selectedColumns: newSelectedColumns,
        filters: newSelectedFilterColumns,
      };

      // Layer map update
      let pluginDrillDown = this.props.state.DrillDownReducer.drillDowns.get(
        pluginContent.pluginId
      );

      if (!pluginDrillDown.drillDownTypes || pluginDrillDown.drillDownTypes !== "inside-plugin") {
        nextDrillDownColumn.hasDrillDownOperation = true;
      }

      drillDownMap.set(
        clickedColumn.aliasName + "#" + clickedColumn.uniqeColumnId,
        nextDrillDownColumn
      );
      pluginDrillDown.drillDownLayerMap = drillDownMap;

      let drillDowns = this.props.state.DrillDownReducer.drillDowns;
      drillDowns.set(pluginContent.pluginId, pluginDrillDown);

      this.props.setPluginsDrillDowns(drillDowns);
      ////////Layer map update finish

      triggeredDrillDowns.set(pluginContent.pluginId, triggeredDrillDownItem);

      triggeredDrillDownItem.pluginId = pluginContent.pluginId;
      let plugin = this.props.state.PluginTriggerReducer.plugins.get(
        pluginContent.pluginId
      );
      let isTableOrPivotTable =
        plugin && plugin.key !== "pivot-table" && plugin.key !== "table";

      if (isTableOrPivotTable) {
        this.setPluginClickedColumnForDrilldown(
          pluginContent.clickedColumn,
          pluginContent.pluginId
        );
      }

      let reduxState = store.getState();
      let isDrilldownInside = reduxState.DrillDownReducer.drillDowns.get(pluginContent.pluginId).drillDownTypes === "inside-plugin" ||reduxState.DrillDownReducer.drillDowns.get(pluginContent.pluginId).drillDownTypes === "explode-pie-chart" ? true : false
  
      if (isDrilldownInside) {
        reduxState.DrillDownReducer.drillDowns.get(pluginContent.pluginId).callback()
  
        let allDrillDownsMap = reduxState.DrillDownReducer.drillDowns
        let currentDrillObject = allDrillDownsMap.get(pluginContent.pluginId)
        let settedDrillDown = allDrillDownsMap.set(pluginContent.pluginId, {...currentDrillObject, status: false})

        this.props.changeVisiblePopupContent(false);
  
        store.dispatch(setPluginsDrillDowns(settedDrillDown))
      } else {
        this.props.drillDownTrigger(triggeredDrillDownItem);
        this.props.changeVisiblePopupContent(false);
        this.props.setTriggeredDrillDowns(triggeredDrillDowns);
      }
    } else {
      let reduxState = store.getState();
      let isDrilldownInside = reduxState.DrillDownReducer.drillDowns.get(pluginContent.pluginId).drillDownTypes === "inside-plugin" || reduxState.DrillDownReducer.drillDowns.get(pluginContent.pluginId).drillDownTypes === "explode-pie-chart" ? true : false
  
      if (isDrilldownInside) {
        if (reduxState.DrillDownReducer.drillDowns.get(pluginContent.pluginId).callback) {
          reduxState.DrillDownReducer.drillDowns.get(pluginContent.pluginId).callback()
        }
  
        let allDrillDownsMap = reduxState.DrillDownReducer.drillDowns
        let currentDrillObject = allDrillDownsMap.get(pluginContent.pluginId)
        let settedDrillDown = allDrillDownsMap.set(pluginContent.pluginId, {...currentDrillObject, status: false})

        this.props.changeVisiblePopupContent(false);
  
        store.dispatch(setPluginsDrillDowns(settedDrillDown))
      }
    }

    this.props.clearPluginContent();
  };

  /*
  * Controls is plugin pie chart enhanced and triggers callback
  */
  controlAndClosePopupContent = (pluginContent) => {
    let reduxState = store.getState();
    let isDrilldownInside = reduxState.DrillDownReducer.drillDowns.has(pluginContent.pluginId) && reduxState.DrillDownReducer.drillDowns.get(pluginContent.pluginId).drillDownTypes && (reduxState.DrillDownReducer.drillDowns.get(pluginContent.pluginId).drillDownTypes === "inside-plugin" || reduxState.DrillDownReducer.drillDowns.get(pluginContent.pluginId).drillDownTypes === "explode-pie-chart") ? true : false

    if (isDrilldownInside) {
      if (reduxState.DrillDownReducer.drillDowns.get(pluginContent.pluginId).callback) {
        reduxState.DrillDownReducer.drillDowns.get(pluginContent.pluginId).callback(true)

        this.props.changeVisiblePopupContent(false);
      }
    } 

    this.props.changeVisiblePopupContent(false);
  }

  /**
   *
   * @param {*} clickedColumn
   * @param {*} THIS
   *
   * Since the column clicked in the drill down process will not be added to the selected columns,
   * the information of the clicked column is kept in the store and used to be ignored.
   */
  setPluginClickedColumnForDrilldown = (clickedColumn, pluginId) => {
    let reduxState = store.getState();
    let pluginColumnClickedAndShouldBeRemoved =
      reduxState.DrillDownReducer.pluginColumnClickedAndShouldBeRemoved;

    if (pluginColumnClickedAndShouldBeRemoved.get(pluginId)) {
      let pluginClickedColumns =
        pluginColumnClickedAndShouldBeRemoved.get(pluginId);

      pluginClickedColumns.set(
        clickedColumn[0].uniqeColumnId,
        clickedColumn[0]
      );
      pluginColumnClickedAndShouldBeRemoved.set(pluginId, pluginClickedColumns);
    } else {
      let pluginClickedColumns = new Map();

      pluginClickedColumns.set(
        clickedColumn[0].uniqeColumnId,
        clickedColumn[0]
      );
      pluginColumnClickedAndShouldBeRemoved.set(pluginId, pluginClickedColumns);
    }

    store.dispatch(
      setpluginColumnClickedAndShouldBeRemoved(
        pluginColumnClickedAndShouldBeRemoved
      )
    );
  };

  /**
   *
   * @param {*} pluginContent
   *
   * If the incoming trigger is not used in any reaction, it is deleted from the state in the store.
   */
  clearPluginContent = (pluginContent) => {
    if (Object.keys(pluginContent).length > 0) {
      this.props.clearPluginContent();
    }
  };

  /**
   *
   * @param {*} pluginContent
   * @returns
   * It checks whether there is a drill down of this plugin by looking at the clicked content.
   */
  checkDrillDowns = (pluginContent) => {
    let reduxState = store.getState();
    let drillDownMap = reduxState.DrillDownReducer.drillDowns.get(
      pluginContent.pluginId
    ).drillDownLayerMap;
    let isDrilldownPluginInside = reduxState.DrillDownReducer.drillDowns.get(
      pluginContent.pluginId
    ).drillDownTypes === "inside-plugin" || reduxState.DrillDownReducer.drillDowns.get(
      pluginContent.pluginId
    ).drillDownTypes === "explode-pie-chart"
    
    if (pluginContent?.clickedColumn) {
      let clickedColumn = pluginContent.clickedColumn[0];
      let columnHasDrillDown =
        clickedColumn && drillDownMap.get(clickedColumn.aliasName + "#" + clickedColumn.uniqeColumnId) &&
        drillDownMap.get(clickedColumn.aliasName + "#" + clickedColumn.uniqeColumnId).hasDrillDownOperation === false;

      if (columnHasDrillDown || isDrilldownPluginInside) {
        return true;
      }
    }

    return false;
  };

  /**
   *
   * @param {*} columns
   * @param {*} datum
   * @param {*} drillDownColumnMap
   * @returns
   *
   * In order not to send the columns that are not in the datum in the navigation columns as filters to the navigation,
   * it only finds the valid columns and returns them.
   */
  getValidColumnsForNavigation = (columns, datum, drillDownColumnMap) => {
    return columns.filter((column) => {
      if (datum.length === 1 && datum[0][column.displayName]) {
        return column;
      } else if (datum.length === 0) {
        return column;
      } else {
        let columnLocationContentInDatum = datum[0][column.locationFieldName];

        if (columnLocationContentInDatum) {
          if (Array.isArray(columnLocationContentInDatum)) {
            return columnLocationContentInDatum.filter(
              (item) => item.displayName === column.displayName
            );
          } else if (typeof datum === "object") {
            if (
              columnLocationContentInDatum.displayName === column.displayName
            ) {
              return column;
            }
          } else if (typeof datum === "string" || typeof datum === "number") {
            if (drillDownColumnMap[column.locationFieldName]) {
              return column;
            }
          }
        }
      }
    });
  };

  /*
  * Checks is selected columns is disabled column
  */
  checkSelectedColumns = (navigationColumns, pluginColumns) => {
      let selectedColumns = []

      for (let i = 0; i < navigationColumns.length; i++) {
        if (pluginColumns.has(navigationColumns[i].uniqeColumnId)) {
          let gettedPluginColumn = pluginColumns.get(navigationColumns[i].uniqeColumnId)

          if (!gettedPluginColumn.isDisabledColumn && gettedPluginColumn.locationFieldName !== "hidden") {
            selectedColumns.push(gettedPluginColumn)
          }
        }
      }

      return selectedColumns;
  }

  /**
   *
   * @param {*} navigation
   * @param {*} pluginContent
   *
   * Find the relevant field according to the content of the incoming data object
   * for navigation and set it to the value field of the column
   * 
   * The content of the data object varies from plugin to plugin.
   */
  setColumnsDataInNavigation = (navigation, pluginContent) => {
    loadingScreen(true);

    if (!this.isDashboardSave()) {
      loadingScreen(false);

      return false;
    }

    let datum = [];
    let pluginTriggers = this.props.state.PluginTriggerReducer
    let gettedPluginTriggers = pluginTriggers.plugins.get(pluginContent.pluginId)
    let gettedPluginColumns = getPluginsAllColumnsByField(gettedPluginTriggers.columnMap, "hash")
    let selectedColumns = this.checkSelectedColumns(navigation.dashboardInformation.columns, gettedPluginColumns);
    let transferDefaultFilters = navigation.dashboardInformation.transferDefaultFilters;
    let transferCompareValue = navigation.dashboardInformation.transferCompareValue;
    let plugin = pluginContent.plugin;
    let isPluginMeasureTile = pluginContent?.plugin?.key === "measure-tile" ? true : false

    if (pluginContent.datumForNavigation) {
      if (pluginContent.datumForNavigation.length > 0) {
        datum = pluginContent.datumForNavigation;
      }
    }
    else {
      datum = pluginContent.datum;
    }

    if (typeof datum === "object" && Array.isArray(datum) === false) {
      datum = transferDefaultFilters ? datum.withDefaultFilters : datum.withoutDefaultFilters;
    }

    let pluginId = pluginContent.pluginId;
    let columnMap = pluginContent.columnMap;

    let navigationColumns = [];
    let navigationColumnIds = [];
    
    if (pluginContent?.plugin?.key !== "measure-tile") {
      selectedColumns.map(column => 
      {
        navigationColumnIds.push(column.filterId ? column.uniqeColumnId : column.uniqeColumnId)
        navigationColumns.push(column)
      });
    }

    if (transferDefaultFilters !== true && transferCompareValue !== true) {
      navigationColumns = selectedColumns;
    } else {
      // if (plugin.defaultFilters) {
      //   plugin.defaultFilters.map(column => {
          
      //     if (!navigationColumnIds.includes(column.filterId ? column.filterId : column.uniqeColumnId) && !column.compared) {
      //       // const isFilterPredicateIsNullOrIsNotNull = column.filterPredicate === "is null" || column.filterPredicate === "is not null"
          
      //       // if ((column.lastValue || column.lsqlValue || isFilterPredicateIsNullOrIsNotNull) && transferDefaultFilters) {
      //       //   navigationColumnIds.push(column.filterId ? column.filterId : column.uniqeColumnId);
      //       //   navigationColumns.push(column);
      //       // }
      //     } else if (pluginContent?.plugin?.compareValue && ((column.value && column.lastValue) || column.lsqlValue)) {
      //       let copiedColumn = {...column}
            
      //       if (transferCompareValue) {
      //         // copiedColumn.value = [pluginContent.plugin.compareValue]
      //         // copiedColumn.lastValue = [pluginContent.plugin.compareValue]
  
      //         // navigationColumnIds.push(column.filterId ? column.filterId : column.uniqeColumnId);
      //         // navigationColumns.push(copiedColumn);
      //       } else if (transferDefaultFilters && !column.compared) {
      //         // copiedColumn.value = column.lastValue
      //         // copiedColumn.lastValue = column.lastValue

      //         // navigationColumnIds.push(column.filterId ? column.filterId : column.uniqeColumnId);
      //         // navigationColumns.push(copiedColumn);
      //       }
      //     }
      //   });
      // }

      for (let key in columnMap) {
        if (columnMap[key]) {
          if (Array.isArray(columnMap[key])) {
            columnMap[key].map((column) => {
              if (
                column.aggregatable !== true &&
                navigationColumnIds.includes(column.filterId ? column.filterId : column.uniqeColumnId) &&
                !navigationColumns.some((col) => col.displayName === column.displayName)
              ) {
                column.value = undefined;
                column.stringValue = undefined;

                navigationColumns.push(column);
              }
            });
          } else {
            if (
              columnMap[key].aggregatable !== true &&
              navigationColumnIds.includes(columnMap[key].filterId ? columnMap[key].filterId : columnMap[key].uniqeColumnId) &&
              !navigationColumns.some((col) => col.displayName === columnMap[key].displayName)
            ) {
              columnMap[key].value = undefined;
              columnMap[key].stringValue = undefined;

              navigationColumns.push(columnMap[key]);
            }
          }
        }
      }
    }

    let reduxState = store.getState();

    let triggeredDrillDownsForPlugin =
      reduxState.DrillDownReducer.triggeredDrillDowns.get(pluginId);

    if (
      triggeredDrillDownsForPlugin &&
      triggeredDrillDownsForPlugin.drillDownColumnMap
    ) {
      navigationColumns = this.getValidColumnsForNavigation(
        navigationColumns,
        datum,
        triggeredDrillDownsForPlugin.drillDownColumnMap
      );
    }

    // Process default filters
    let defaultFiltersExist = plugin && plugin.defaultFilters;
  
    if (defaultFiltersExist)
    {
        let defaultFilters =  [...plugin.defaultFilters];

        // Valid compare value transfer 
        if (transferCompareValue){

          defaultFilters.map((column)=> {
            let isDefaultFilterComparedAndHasAValue = pluginContent?.plugin?.compareValue && ((column.value && column.lastValue) || column.lsqlValue) && column.compared
            
            if (isDefaultFilterComparedAndHasAValue) {
              column.value = [pluginContent.plugin.compareValue]
              column.lastValue = [pluginContent.plugin.compareValue]
            }
          })
        }
        
        let validDefaultFilters = transferDefaultFilters ? prepareDefaultFilters(defaultFilters, plugin.usedSessionVariables) : []

        if (!transferDefaultFilters && transferCompareValue) {
          let copiedComparedDefaultFilters = deepCopy(defaultFilters.filter(defaultFilter => defaultFilter.compared))

          validDefaultFilters = prepareDefaultFilters(copiedComparedDefaultFilters, plugin.usedSessionVariables)
        }

        // Set filter string        
        if (validDefaultFilters?.length > 0){
          validDefaultFilters.map((filter) => 
          {
            filter.stringValue = this.getFilterString(filter.value)
            navigation.dashboardInformation.filters.push(filter)
          })
        }
    }

    navigationColumns.map((column) => {
      let values = [];
      {
        datum.map((datumValue) => {
          if (datumValue[column.locationFieldName] && !Array.isArray(datumValue[column.locationFieldName])) {
            if (datumValue[column.locationFieldName] !== "notCurrentTopojson" && datumValue[column.locationFieldName]!== "\"notCurrentCode\"") {
              values.push(datumValue[column.locationFieldName]);
            }
          } else if (datumValue[column.displayName] && !Array.isArray(datumValue[column.displayName])) {
            if (datumValue[column.displayName] !== "notCurrentTopojson" && datumValue[column.displayName] !== "\"notCurrentCode\"") {
              values.push(datumValue[column.displayName]);
            }
          } else if (datumValue[column.locationFieldName] && Array.isArray(datumValue[column.locationFieldName])) {
            let item = datumValue[column.locationFieldName].find((item) => item.name === (plugin && plugin.key === "measure-tile" ? column.displayName : column.name));

            if (item == undefined) {
              item = datumValue[column.locationFieldName].find((item) => item.name === (column.displayName));
            }

            if (this.checkEmptyValue(item) && !values.includes(item.value)) {
              values.push(item.value);
            }
          } else if (datumValue[column.displayName] && Array.isArray(datumValue[column.displayName])) {

          }
        });
        /**
        * If the value length is greater than or equal to 1, the query will convert the values ​​to the desired format
        */

        let stringValue = "";

        if (Array.isArray(values)) {
          if (values.length > 1) {
            values.map((value, index) => {
              stringValue = stringValue + value + (index < values.length - 1 ? ", " : "");
            });

            values = this.joinfilterValue(values);
            column.filterPredicate = "in";
          } else if (values.length === 1) {
            stringValue = values; 
            values = this.formattedSingleFilterValue(values[0]);
            column.filterPredicate = "=";
          }
        } else {
          let isStringHasApostropheWithSingleValue = values[0] === "'" && values[values.length - 1] === "'" ? true : false
          let isStringHasBacktickWithSingleValue = values[0] === "`" && values[values.length - 1] === "`" ? true : false
          let isStringHasBrackets = values[0] === "(" && values[values.length - 1] === ")" ? true : false

          if (isStringHasApostropheWithSingleValue || isStringHasBacktickWithSingleValue) {
            stringValue = values.slice(1, values.length - 1)
          } else if (isStringHasBrackets) {
            let slicedValues = values.slice(1, values.length - 1)
            let splittedValues = slicedValues.split(",")
            let valuesArray = []

            splittedValues.map(splittedVal => {
              if (
                (splittedVal[0] === "'" || splittedVal[0] === "'") &&
                (splittedVal[splittedVal.length - 1] === "'" || splittedVal[splittedVal.length - 1] === "'")
              ) {
                let slicedVal = splittedVal.slice(1, splittedVal.length - 1)

                valuesArray.push(slicedVal)
              }
            })

            stringValue = valuesArray.join(", ")
          } else {
            stringValue = values
          }
        }

        column.value = values;
        column.stringValue = stringValue !== "" ? stringValue : values;

        if (column.value !== null && column.value.length > 0) {
          navigation.dashboardInformation.filters.push(column);
        }

      }
    });

    // Inteactions filter    
    let defaultFiltersMustBeIgnored = new Set();
    const transferInteractionFilters = navigation.dashboardInformation.transferInteractionFilters;
    const getColumnKey = (column) => column.dataSourceKey + ":" + column.tableAliasName + ":" + column.aliasName;

    if (transferInteractionFilters) {
      let reduxState = store.getState()
      
      // Detect default filters must be ignored
      /* eslint-disable-next-line */
      reduxState.PluginTriggerReducer.pluginInteractionFilters
      ?.get(pluginId)
      ?.map((pluginInteractionFilter) => {
        let autoInteractionObject = reduxState.FilterAutoInteractionReducer.filterAutoInteractions.get(pluginInteractionFilter.usedInteraction.sourceId)
        let filteredInteractionObject = autoInteractionObject?.find(autoInteraction => autoInteraction.targetId === pluginId)

        for (let i = 0; i < filteredInteractionObject?.ignoredDefaultFilters?.length; i++) {
          defaultFiltersMustBeIgnored.add(filteredInteractionObject.ignoredDefaultFilters[i])
        }
      });

      let interactions = prepareInteractionFilters(pluginId);
      
      /* eslint-disable-next-line */
      interactions?.map((column) => {
          let values = column.value;
          column.stringValue = values ?  this.getFilterString(values) : null
          navigation.dashboardInformation.filters.push(column);       
      })
    }

    pagePopupVisible(false);
    this.props.changeVisiblePopupContent(false);
    this.props.setNavigations(navigation);
    this.props.clearPluginContent();
  };

  /**
   * 
   * @param {*} navigation
   * 
   * Calls setColumnsDataInNavigation
   */
  navigationTrigger = (navigation) => {
    if (navigation.dashboardInformation.filters.length === 0) {
      const { pluginContent } = this.props.state.PluginTriggerReducer;

      this.setColumnsDataInNavigation(navigation, pluginContent);
    }
  }

  /**
   * dashboard save control
   */
  isDashboardSave = () => {
    if (
      !(this.props.dashboardInformation && this.props.dashboardInformation.id)
    ) {
        showNotificationWithIcon(i18n.t("PleaseDashboardSave"), null, "info");

        return false
    }

    return true;
  };

  /*
   * set position calibration for interactions and navigations popup because popup did overflow the window size in the right edge
   */
  setPositionCalibration = (mousePosition) => {
    const popupWidth = 235;

    if (Object.keys(mousePosition).length > 0) {
      if (mousePosition.x + popupWidth > window.innerWidth) {
        // If popup window overflow the window size
        return -mousePosition.x + popupWidth + "px"; // Because popup size overflow window size
      } else {
        return -mousePosition.x + "px";
      }
    } else {
      return "0px";
    }
  };

  /*
  * Triggers navigation
  */
  triggerNavigationOnClick = (navigation) => {
    this.navigationTrigger(navigation)
  }

  /*
  * Checks is clicked plugin map choropleth
  */
  checkIsClickedPluginChoropleth = (pluginContent) => {
    if (this.props.state?.MapChoroplethDetailReducer?.pluginId === pluginContent?.id && this.props.state.MapChoroplethDetailReducer?.currentIndex === 1) {
      return <>
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("DrillDown.DrillDown")}
        </Divider>
        <div
          style={{
            textAlign: "left",
            fontSize: 14,
            lineHeight: 1.5,
            marginTop: 4,
            marginBottom: 4,
            cursor: "pointer",
          }}
          onClick={() => this.props.state.MapChoroplethDetailReducer.detailCallback(this.props.state.MapChoroplethDetailReducer.event, "detail", 1 )}
        >
          <a href="javascript:;">
            <i class="fa fa-clipboard"></i> &nbsp;{" "}
            {i18n.t("DrillDown.ApplyDrillDown")}
          </a>
        </div>
        </>
    }
  }

  render() {
    const {
      pluginContent,
      isActionListVisible,
      visiblePopupContent,
      isAction,
    } = this.props.state.PluginTriggerReducer;
    let pluginDrillDown = this.props.state.DrillDownReducer.drillDowns.get(pluginContent.pluginId);
    let isListVisibleActive = this.props.isListVisibleActive ? true : false
    let mustListVisibleConditionChange = isListVisibleActive && !isActionListVisible && !isAction ? true : false
    let widthClientScreen = window.screen.width > 768;

    if (mustListVisibleConditionChange) {
      this.props.setListVisible(false)
    }

    let popupYPosition = this.props.state.PluginTriggerReducer.positions.y;
    let windowHeight = $(window).height();

    if (windowHeight < this.props.state.PluginTriggerReducer.positions.y + 280) {
      popupYPosition = popupYPosition - 280

      if (popupYPosition < 0) {
        popupYPosition = 0
      }
    }

    return (
      <div style={{ display: visiblePopupContent ? "block" : "none" }}>
        {isActionListVisible === true && isAction === true ? (
          <PopupContainer
            height={"280px"}
            constantHeight={"280px"}
            className= {widthClientScreen ? "interaction-navigation-list" : "interation-navigation-on-mobile interaction-navigation-list"}
            width={"235px"}
            positionCalibration={this.setPositionCalibration(
              this.props.state.PluginTriggerReducer.positions
            )}
            position="left"
            textAlign="left"
            top={
              Object.keys(this.props.state.PluginTriggerReducer.positions)
                .length > 0
                ? popupYPosition
                : "0px"
            }
          >
            <Text
              type={"h5"}
              style={{
                textAlign: "center",
              }}
            >
              {i18n.t("Actions")}
            </Text>
            {this.hasPluginInteraction(pluginContent) === true ? (
              <>
                <Divider className={"config-divider"} orientation="left">
                  {i18n.t("Interaction.Interaction")}
                </Divider>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: 14,
                    lineHeight: 1.5,
                    marginTop: 4,
                    marginBottom: 4,
                    cursor: "pointer",
                  }}
                  onClick={() => this.interactionTrigger()}
                >
                  <a href="javascript:;">
                    <i class="fa fa-clipboard"></i> &nbsp;{" "}
                    {i18n.t("Interaction.ApplyFilter")}
                  </a>
                </div>
              </>
            ) : null}{" "}
            {pluginDrillDown &&
              Object.keys(pluginDrillDown).length > 0 &&
              this.checkDrillDowns(pluginContent) ? (
              <>
                <Divider className={"config-divider"} orientation="left">
                  {i18n.t("DrillDown.DrillDown")}
                </Divider>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: 14,
                    lineHeight: 1.5,
                    marginTop: 4,
                    marginBottom: 4,
                    cursor: "pointer",
                  }}
                  onClick={() => this.drillDownTrigger(pluginContent)}
                >
                  <a href="javascript:;">
                    <i class="fa fa-clipboard"></i> &nbsp;{" "}
                    {i18n.t("DrillDown.ApplyDrillDown")}
                  </a>
                </div>
              </>
            ) : null}{" "}
            {pluginContent &&
              pluginContent.navigations &&
              pluginContent.navigations.length > 0 ? (
              <Divider className={"config-divider"} orientation="left">
                {i18n.t("Navigations")}
              </Divider>
            ) : null}
            {pluginContent &&
              pluginContent.navigations &&
              pluginContent.navigations.map((navigation) => (
                <div
                  style={{
                    textAlign: "left",
                    fontSize: 14,
                    lineHeight: 1.5,
                    marginTop: 4,
                    marginBottom: 8,
                  }}
                >
                  <a
                    href="javascript:;"
                    onClick={() => this.triggerNavigationOnClick(navigation)}
                  >
                    <i class="fa fa-clipboard"></i> &nbsp;
                    <span>{navigation.dashboardInformation.dashboardName}</span>
                  </a>
                </div>
              ))}
              {pluginContent &&
              pluginContent.customActions &&
              pluginContent.customActions.length > 0 ? (
              <>
                <Divider className={"config-divider"} orientation="left">
                  { i18n.t("Custom") } &nbsp;
                </Divider>
              {pluginContent.customActions.map((action) => (
                <div
                  style={{
                    textAlign: "left",
                    fontSize: 14,
                    lineHeight: 1.5,
                    marginTop: 4,
                    marginBottom: 8,
                  }}
                >
                  <a
                    href="javascript:;"
                    onClick={() => {
                      action.trigger();
                      this.controlAndClosePopupContent(pluginContent);
                    }}
                  >
                    {action.icon} &nbsp;
                    <span>{action.title}</span>
                  </a>
                </div>
              ))}
              </>
            ) : null}
            {this.checkIsClickedPluginChoropleth(pluginContent)}
            <Button
              style={{
                position: "absolute",
                top: "3px",
                right: "3px",
                borderRadius: "40px",
                padding: "6px 11px",
                backgroundColor: "white",
                zIndex: 1,
              }}
              type={"default"}
              onClick={() => this.controlAndClosePopupContent(pluginContent)}
            >
              X
            </Button>
          </PopupContainer>
        ) : pluginContent &&
          pluginContent.navigations &&
          pluginContent.navigations.length === 1 &&
          isAction === true &&
          !this.hasPluginInteraction(pluginContent) ? (
          this.navigationTrigger(pluginContent.navigations[0])
        ) : pluginContent.interactions &&
          pluginContent.interactions.length > 0 &&
          this.hasPluginInteraction(pluginContent) === true ? (
          this.interactionTrigger()
        ) : pluginDrillDown &&
          Object.keys(pluginDrillDown).length > 0 &&
          this.checkDrillDowns(pluginContent) ? (
          this.drillDownTrigger(pluginContent)
        ) : pluginContent.customActions &&
          pluginContent.customActions.length === 1 &&
          typeof pluginContent.customActions[0].trigger === "function" ? (
            pluginContent.customActions[0].trigger()
        ) : (
          this.clearPluginContent(pluginContent)
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  changeVisiblePopupContent,
  clearPluginContent,
  setTriggeredDrillDowns,
  setPluginsDrillDowns,
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InteractionsAndNavigationsList);
