import { rmvpp } from "../RenderJs/rmvpp";
import { InsightsConfig } from "../RenderJs/config";
import Cookies from "js-cookie";
import i18n from "../../../Utils/i18next";
import { deepCopy } from "../../../Utils/Global";

/**
 * Format method for plugins measurement columns
 * @param {*} value 
 * @param {*} formatString
 * @param {*} column 
 */
export const format = (value, formatString, column) => {
    formatString = formatString || column.DataFormat || getDefaultFormat(column);
    var formatted = value,
      locale = column.Locale || Cookies.get("i18next").toLocaleUpperCase();
  
    function customAbbrev(formatString, value) {
      var s = rmvpp.locales[locale].numberFormat(formatString)(value);
      switch (s[s.length - 1]) {
        case "k":
          if (InsightsConfig.SIPrefixes.hasOwnProperty('k'))
            s = s.slice(0, -1) + InsightsConfig.SIPrefixes.k;
          break;
        case "M":
          if (InsightsConfig.SIPrefixes.hasOwnProperty('M'))
            s = s.slice(0, -1) + InsightsConfig.SIPrefixes.M;
          break;
        case "G":
          if (InsightsConfig.SIPrefixes.hasOwnProperty('G'))
            s = s.slice(0, -1) + InsightsConfig.SIPrefixes.G;
          break;
      }
      return s;
    }
  
    function numFormat(formatString, value) {
      if (value || value == 0) {
        if (formatString.indexOf('s') > -1) {
          return customAbbrev(formatString, value);
        } else
          return rmvpp.locales[locale].numberFormat(formatString)(value);
      } else {
        return '';
      }
    }
  
    function formatDate(date) {
      let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
  
      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }
  
      return [year, month, day].join('-');
    }
  
    let dataType = column.DataType || column.dataType;

    // Checks if value is "Total" or "" and changes data type to "" in order to prevent further bugs 
    dataType = (value === i18n.t("Total") || value === "") ? "" : dataType; 
    var valueControl = (value === undefined || value === null || value === "")
    switch (dataType) {
      case 'double':
        formatted = numFormat(formatString, value);
        break;
      case 'integer':
        formatted = numFormat(formatString, value);
        break;
      case 'numeric':
        formatted = numFormat(formatString, value);
        break;
      case 'date':
        var dateValue;
        
        if (valueControl) {
          formatted = "";
          
          break;
      }
  
        if (value instanceof Date || !rmvpp.locales.hasOwnProperty(column.Locale)) {
          dateValue = value;
        } else {
          let date = new Date(value) !== "Invalid Date" ? formatDate(new Date(value)) : null; // Returns a Date - Y-m-d format
  
          if (date !== null && rmvpp.locales.hasOwnProperty(column.Locale)) {
            dateValue = rmvpp.locales[column.Locale].timeFormat("%Y-%m-%d").parse(date); // Returns a Date
            formatted = rmvpp.locales[column.Locale].timeFormat(formatString)(dateValue);
          }
        }
  
        break;
      case 'timestamp':

      if (valueControl) {
        formatted = "";
        
        break;
    }

        var dateValue;
        if (value.includes('T') == false) {
          const regex = /(.*)(\s)(.*)(\.)(.*)/;
          let parsedValue = regex.exec(value);
          let parsedValueControl = (parsedValue === null || parsedValue === undefined || parsedValue === "")
  
          if (parsedValueControl) {
            formatted = value;
          } else {
            // Convert timestamp without time zone format to timestamp format
            if (parsedValue.length > 2) {
              value = parsedValue[1] + 'T' + parsedValue[3];
            }
          }
        }
  
        if (value instanceof Date || !rmvpp.locales.hasOwnProperty(column.Locale)) {
          dateValue = value;
        } else {
          dateValue = rmvpp.locales[column.Locale].timeFormat("%Y-%m-%dT%H:%M:%S").parse(value); // Returns a Date
        }
  
        if (dateValue === null || dateValue === "" || !rmvpp.locales.hasOwnProperty(column.Locale)) {
          dateValue = value;
        } else {
          formatted = rmvpp.locales[column.Locale].timeFormat(formatString)(dateValue);
        }
        break;
      case 'varchar':
        if (formatString != '%s' && formatString) {
          if (column.DataFormatType === "number") {
            formatted = numFormat(formatString, value)
          } else if (column.DataFormatType === "time") {
            if (column.DataFormatTypeSubTitle === "date") {
              var dateValue;
  
              if (value instanceof Date) {
                dateValue = value;
              } else {
                let date = new Date(value) !== "Invalid Date" ? formatDate(new Date(value)) : null; // Returns a Date - Y-m-d format
        
                if (date !== null) {
                  dateValue = rmvpp.locales[column.Locale].timeFormat("%Y-%m-%d").parse(date); // Returns a Date
                }
              }
              
              if (dateValue) {
                formatted = rmvpp.locales[column.Locale].timeFormat(formatString)(dateValue);
              } else {
                formatted = formatString
              }
            } else if (column.DataFormatTypeSubTitle === "timestamp") {
              var dateValue;
              if (value.includes('T') == false) {
                const regex = /(.*)(\s)(.*)(\.)(.*)/;
                let parsedValue = regex.exec(value);
        
                if (parsedValue == null || parsedValue == undefined || parsedValue == "") {
                  formatted = value;
                } else {
                  // Convert timestamp without time zone format to timestamp format
                  if (parsedValue.length > 2) {
                    value = parsedValue[1] + 'T' + parsedValue[3];
                  }
                }
              }
        
              if (value instanceof Date) {
                dateValue = value;
              } else {
                dateValue = rmvpp.locales[column.Locale].timeFormat("%Y-%m-%dT%H:%M:%S").parse(value); // Returns a Date
              }
        
              if (dateValue == null || dateValue == "") {
                dateValue = formatString;
                formatted = formatString
              } else {
                formatted = rmvpp.locales[column.Locale].timeFormat(formatString)(dateValue);
              }
            }
          } else if (column.DataFormatType === "varchar") {
            formatted = formatString.replace(/%s/g, value);
          }
        }

        if (formatted && (typeof formatted !== "number") && (formatted === "NaN" || formatted?.includes("NaN"))) {
          formatted = formatString
        }
        
        break;
      default:
        break;
    }

    return formatted;
  }
  
  const getDefaultFormat = (column) => {
    let formatString;
    let dataType = column.DataType || column.dataType
    switch (dataType) {
      case 'double':
        formatString = InsightsConfig.DataFormats.double;
        break;
      case 'numeric':
        formatString = InsightsConfig.DataFormats.numeric;
        break;
      case 'integer':
        formatString = InsightsConfig.DataFormats.integer;
        break;
      case 'date':
        formatString = InsightsConfig.DataFormats.date;
        break;
      case 'timestamp':
        formatString = InsightsConfig.DataFormats.timestamp;
        break;
      case 'varchar':
        formatString = InsightsConfig.DataFormats.varchar;
        break;
      default:
        formatString = '%s';
        break;
    }
    return formatString;
  }

  // if affix value is used then data will be shown with affix value otherwise just formatted value will be shown.
  export const getFormattedValue = (column, value, formatString = undefined, mustBeSuffixed = true) => {
    let formattedValue = column?.format ? column.format(value, formatString, column) : format(value, formatString, column);
    
    if (column.affixValue && value && value !== i18n.t("Total") && mustBeSuffixed) {
      if (column.affixAlign === "right" || column.affixAlign === undefined) {
        formattedValue = formattedValue + column.affixValue;
      } else if (column.affixAlign === "left") {
        formattedValue = column.affixValue + formattedValue;
      }
    } 

    return formattedValue;
  }

  export const decideToDefaultFormat = (column) => {
    return getDefaultFormat(column);
  }

  // converting dataType to format type  
  export const getFormatDataType = (dataType) => {
    let formatNumberType = ["double", "numeric", "integer", "wholeNumber", "rounding", "decimal"];

    if (formatNumberType.includes(dataType) === true) {
      return 'Number';
    } else if (["varchar", "text"].includes(dataType) === true) {
      return 'Text';
    } else if (dataType === 'date') {
      return 'Date';
    } else if (dataType === 'timestamp') {
      return 'Timestamp';
    } else {
      return '';
    }
  }

  // warning if data type is changed
  export const getDefaultFormatWarning = (column) => {
    let dataType = column.DataType || column.dataType;
    let warning = '';

    if ( [undefined, ''].includes(dataType) === false ) {
      let typeOrginal = getFormatDataType(dataType);
      let typeNew = [undefined, ''].includes(column.DataFormatTypeSubTitle) === true ? getFormatDataType(column.DataFormatType) : getFormatDataType(column.DataFormatTypeSubTitle);
      
      if (typeOrginal !== typeNew && typeNew !== '') {
        warning = i18n.t("Dashboard.Data.DataFormatType.DataType.FormatWarning", { typeOrginal: i18n.t("Dashboard.Data.DataFormatType.DataType." + typeOrginal) , typeNew: i18n.t("Dashboard.Data.DataFormatType.DataType." + typeNew)})
      } else {
        warning = '';
      }
    }

    return warning;
  }

  export const getDefaultFormatType = (column) => {
    let tempReturnFormat = [];
    let dataType = column.DataType || column.dataType
    let cloneColumn = deepCopy(column);
    
    if (column.DataFormat === undefined) {
      if (["numeric", "integer"].includes(dataType) === true) {
        cloneColumn.dataType = 'double';
        tempReturnFormat.push ({
          dataFormatType: 'number',
          dataFormatTypeSubTitle: 'wholeNumber',
          decimalPlace: '0',
          use1000Separator: false,
          roundingNumber: '0',
          dataFormat: '.0f',
          formatExample: '(' + format('12345', '.0f', cloneColumn) + ')'
        });
      }else if(["double"].includes(dataType) === true) {
        cloneColumn.dataType = 'double';
        tempReturnFormat.push({
          dataFormatType: 'number',
          dataFormatTypeSubTitle: 'decimal',
          decimalPlace: '2',
          use1000Separator: true,
          roundingNumber: '0',
          dataFormat: ',.2f',
          formatExample: '(' + format('12345', ',.2f' , cloneColumn) + ')'
        });
      }else if (dataType === 'varchar') {
        cloneColumn.dataType = 'varchar';
        tempReturnFormat.push ({
          dataFormatType: 'text',
          dataFormatTypeSubTitle: '',
          decimalPlace: '',
          use1000Separator: false,
          roundingNumber: '0',
          dataFormat: '%s',
          formatExample: '(' + format('abcde', '%s', cloneColumn) + ')'
        });
      } else if (dataType === 'date') {
        cloneColumn.dataType = 'date';
        cloneColumn.Locale = 'TR';
        tempReturnFormat.push ({
          dataFormatType: 'time',
          dataFormatTypeSubTitle: 'date',
          decimalPlace: '0',
          use1000Separator: false,
          roundingNumber: '0',
          dataFormat: '%d/%m/%Y',
          formatExample: '(' + format('01/01/2023', '%d/%m/%Y', cloneColumn) + ')'
        });
      } else if (dataType === 'timestamp') {
        cloneColumn.dataType = 'timestamp';
        cloneColumn.Locale = 'TR';
        tempReturnFormat.push ({
          dataFormatType: 'time',
          dataFormatTypeSubTitle: 'timestamp',
          decimalPlace: '',
          use1000Separator: false,
          roundingNumber: '0',
          dataFormat: '%d/%m/%Y %H:%M:%S',
          formatExample: '(' + format('01/01/2023 00:00:00','%d/%m/%Y %H:%M:%S', cloneColumn) + ')'
        });
      } else {
        cloneColumn.dataType = 'varchar';
        tempReturnFormat.push ({
          dataFormatType: 'custom',
          dataFormatTypeSubTitle: '',
          decimalPlace: '',
          use1000Separator: false,
          roundingNumber: '0',
          dataFormat: '%s',
          formatExample: '(' + format('abcde', '%s', cloneColumn) + ')'
        });
      }
    } else {
      if (["double", "numeric", "integer"].includes(dataType) === true) {
        let dataFormatNumber = column.DataFormat.replace('.', '').replace('f', '').replace(',','').replace('s', '');
        if ([".f", ".0f"].includes(column.DataFormat) === true) {
          cloneColumn.dataType = 'double';
          tempReturnFormat.push ({
            dataFormatType: 'number',
            dataFormatTypeSubTitle: 'wholeNumber',
            decimalPlace: '0',
            use1000Separator: false,
            roundingNumber: '0',
            dataFormat: column.DataFormat,
            formatExample: '(' + format('12345', column.DataFormat, cloneColumn) + ')'
          });
        } else if (column.DataFormat.indexOf('.') === 0 && column.DataFormat.indexOf('s') === (column.DataFormat.length - 1) ) {
          cloneColumn.dataType = 'double';
          tempReturnFormat.push ({
            dataFormatType: 'number',
            dataFormatTypeSubTitle: 'rounding',
            decimalPlace: '0',
            use1000Separator: false,
            roundingNumber: dataFormatNumber,
            dataFormat: column.DataFormat,
            formatExample: '(' + format('12345', column.DataFormat, cloneColumn) + ')'
          });
        } else if (column.DataFormat[0] === ',' || column.DataFormat[0] === '.') { // binlik ayraç / ondalık ayraç
          cloneColumn.dataType = 'double';
          tempReturnFormat.push ({
            dataFormatType: 'number',
            dataFormatTypeSubTitle: 'decimal',
            decimalPlace: dataFormatNumber === undefined || dataFormatNumber === '' ? '0' : dataFormatNumber,
            use1000Separator: column.DataFormat.indexOf(',') === -1 ? false : true,
            roundingNumber: '0',
            dataFormat: column.DataFormat,
            formatExample: '(' + format('12345', column.DataFormat, cloneColumn) + ')'
          });
        } else {
          cloneColumn.dataType = 'varchar';
          tempReturnFormat.push ({
            dataFormatType: 'custom',
            dataFormatTypeSubTitle: '',
            decimalPlace: '0',
            use1000Separator: false,
            roundingNumber: '0',
            dataFormat: column.DataFormat,
            formatExample: '(' + format('abcde', column.DataFormat, cloneColumn) + ')'
          });
        }
      } else if (dataType === "date") {
        cloneColumn.dataType = 'date';
        cloneColumn.Locale = 'TR';
        tempReturnFormat.push ({
          dataFormatType: 'time',
          dataFormatTypeSubTitle: 'date',
          decimalPlace: '0',
          use1000Separator: false,
          roundingNumber: '0',
          dataFormat: column.DataFormat,
          formatExample: '(' + format('01/01/2023', column.DataFormat, cloneColumn) + ')'
        });
      } else if (dataType === "timestamp") {
        cloneColumn.dataType = 'timestamp';
        cloneColumn.Locale = 'TR';
        tempReturnFormat.push ({
          dataFormatType: 'time',
          dataFormatTypeSubTitle: 'timestamp',
          decimalPlace: '0',
          use1000Separator: false,
          roundingNumber: '0',
          dataFormat: column.DataFormat,
          formatExample: '(' + format('01/01/2023 00:00:00', column.DataFormat, cloneColumn) + ')'
        });
      } else if (dataType === "varchar") {
        if (column.DataFormat === "%s") {
          cloneColumn.dataType = 'varchar';
          tempReturnFormat.push ({
            dataFormatType: 'text',
            dataFormatTypeSubTitle: '',
            decimalPlace: '0',
            use1000Separator: false,
            roundingNumber: '0',
            dataFormat: column.DataFormat,
            formatExample: '(' + format('abcde', column.DataFormat, cloneColumn) + ')'
          });
        } else {
          cloneColumn.dataType = 'varchar';
          tempReturnFormat.push ({
            dataFormatType: 'custom',
            dataFormatTypeSubTitle: '',
            decimalPlace: '0',
            use1000Separator: false,
            roundingNumber: '0',
            dataFormat: column.DataFormat,
            formatExample: '(' + format('abcde', column.DataFormat, cloneColumn) + ')'
          });
        }
      } else {
        cloneColumn.dataType = 'varchar';
        tempReturnFormat.push ({
          dataFormatType: 'custom',
          dataFormatTypeSubTitle: '',
          decimalPlace: '0',
          use1000Separator: false,
          roundingNumber: '0',
          dataFormat: column.DataFormat,
          formatExample: '(' + format('abcde', column.DataFormat, cloneColumn) + ')'
        });
      }
    }

    return tempReturnFormat[0];
  }