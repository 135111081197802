import React, { Component } from "react";
import { changeRightClickedPlugin, closePluginCopyPastePopup, pastePluginFunction, rightClickCopiedPluginStatus } from "./ContextMenuAction";
import { connect } from "react-redux";
import "./contextMenu.css"
import { store } from "../../..";
import { absorbEvent, deepCopy } from "../../../Utils/Global";
import { DeleteOutlined, CopyOutlined, SwapOutlined, BgColorsOutlined, InteractionOutlined, SettingOutlined, FileExcelOutlined, FilePdfOutlined, FileImageOutlined, DownloadOutlined, RightOutlined, CloseOutlined, LeftOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Divider, Empty, Input, Popconfirm } from "antd";
import $ from "jquery"
import i18n from "../../../Utils/i18next";
import { isValidWriteRolesOrCustomDashboard } from "../CustomDashboard/customDashboardControls";
import { TbTransform } from "react-icons/tb";
import { getCopiedPluginObj, pastePlugin } from "../PluginCopyPaste/pluginCopyPaste";
import { autoInteractionPlugins, exceptAutoInteractionPlugins } from "../../../config";
import { getConversionList, convertPlugin } from "../PluginConversion/pluginConversion";
import Text from "../Text/Text";
import _ from "lodash";

/*
* Plugin context menu component
*/
class PluginContextMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      xPos: 0,
      yPos: 0,
      calculatedXPos: 0,
      calculatedYPos: 0,
      contextMenuStatus: false,
      duplicateSettingsVisibility: false,
      exportMenuVisibility: false,
      convertMenuVisibility: false,
      checkedAllStatus: true,
      interactionSelection: true,
      navigationSelection: true,
      drilldownSelection: true,
      defaultFilterSelection: true,
      conditionalFormatSelection: true
    };

    this.mobileMenuHeight = null;
  }

  /**
   * Touch move handler
   * 
   * @param {*} event 
   */
  handleTouchMove = (event) => {
    let reduxState = store.getState()
    let contextMenuReducer = reduxState.ContextMenuReducer
    let status = contextMenuReducer.contextMenuStatus === true ? true : false;

    if (status) {
      clearTimeout(this.touchTimer);
      absorbEvent(event);
    }
  };

  componentDidMount() {
    document.addEventListener("mouseup", this.handleClickOutside);
    document.addEventListener("touchstart", this.handleClickOutside);
    document.addEventListener("touchmove", this.handleTouchMove);

    store.dispatch(pastePluginFunction(pastePlugin))
  }

  componentWillUnmount() {
    document.removeEventListener("mouseup", this.handleClickOutside);
    document.removeEventListener("touchstart", this.handleClickOutside);
    document.removeEventListener("touchmove", this.handleTouchMove);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.state.ContextMenuReducer.contextMenuStatus !== this.props.state.ContextMenuReducer.contextMenuStatus) {
      this.setState({
        ...this.state,
        contextMenuStatus: nextProps.state.ContextMenuReducer.contextMenuStatus,
        duplicateSettingsVisibility: false,
        exportMenuVisibility: false,
        convertMenuVisibility: false,
        checkedAllStatus: true,
        interactionSelection: true,
        navigationSelection: true,
        drilldownSelection: true,
        defaultFilterSelection: true,
        conditionalFormatSelection: true
      })
    }
  }

  /**
   * Detects handle click outside and closes context menu
   * 
   * @param {*} event 
   */
  handleClickOutside = (event) => {
    const handleClickOutside = _.throttle(() => {
      let contextContainer = document.getElementById("copy-paste-context-menu")
      let reduxState = store.getState()
      let contextMenuReducer = reduxState.ContextMenuReducer
      let status = contextMenuReducer.contextMenuStatus === true;

      if (status) {
        let isTargetConfirmationPopup = $(event.target).closest("#context-menu-confirmation").length > 0;

        if (contextContainer && !contextContainer.contains(event.target) && !isTargetConfirmationPopup) {
          if (!$(event.target).closest("#loginPopup").length > 0) {
            if (event.type === "touchstarted") {
              this.touchTimer = setTimeout(this.closeContextMenu, 100);
            } else {
              this.closeContextMenu();
            }

            absorbEvent(event);
          }
        }
      }
    });

    clearTimeout(this.touchTimer);
    handleClickOutside();
  };

  /*
  * Deletes plugin with context menu
  */
  removePlugin = (event) => {
    let reduxState = store.getState()
    let pluginId = reduxState.ContextMenuReducer.rightClickedPluginFunction
    let plugin = reduxState.PluginTriggerReducer.plugins.get(pluginId)

    this.props.removePlugin(plugin)

    this.closeContextMenu();
  }

  /**
   * Triggers export functions
   * 
   * @param {*} event
   * @param {*} type 
   */
  triggerExport = (event, type) => {
    let reduxState = store.getState()
    let pluginId = reduxState.ContextMenuReducer.rightClickedPluginFunction
    let pluginDiv = document.getElementById(pluginId);
    let exportFunc = pluginDiv[type];

    if (typeof exportFunc === "function") {
      exportFunc();
    }

    this.closeContextMenu();
  }

  /**
   * Opens or closes export menu visibility
   * 
   * @param {*} event
   * @param {*} status 
   */
  setExportMenuVisibility = (event, status = !this.state.exportMenuVisibility) => {
    this.setState({
      ...this.state,
      duplicateSettingsVisibility: false,
      convertMenuVisibility: false,
      exportMenuVisibility: status,
      conversionConfirmationVisible: null,
    });
  }

  /**
   * Opens or closes convert menu visibility
   * 
   * @param {*} event
   * @param {*} status 
   */
  setConvertMenuVisibility = (event, status = !this.state.convertMenuVisibility) => {
    this.setState({
      ...this.state,
      duplicateSettingsVisibility: false,
      exportMenuVisibility: false,
      convertMenuVisibility: status,
      conversionConfirmationVisible: this.state.convertMenuVisibility === status ? this.state.conversionConfirmationVisible : null
    });
  }

  /**
   * Opens or closes duplicate settings visibility
   * 
   * @param {*} event 
   * @param {*} status 
   */
  changeDuplicateSettingsVisibility = (event, status = !this.state.duplicateSettingsVisibility) => {
    this.setState({
      ...this.state,
      duplicateSettingsVisibility: status,
      exportMenuVisibility: false,
      convertMenuVisibility: false,
      conversionConfirmationVisible: null
    })
  }

  /*
  * Changes check all status
  */
  changeCheckedAllStatus = (event) => {
    this.setState({
      ...this.state,
      checkedAllStatus: !this.state.checkedAllStatus,
      interactionSelection: !this.state.checkedAllStatus,
      navigationSelection: !this.state.checkedAllStatus,
      drilldownSelection: !this.state.checkedAllStatus,
      defaultFilterSelection: !this.state.checkedAllStatus,
      conditionalFormatSelection: !this.state.checkedAllStatus,
    })
  }

  /*
  * Changes duplicate settings
  */
  changeDuplicatedSettings = (key) => {
    let state = deepCopy(this.state)

    state[key] = !state[key]

    let interactionSelection = state["interactionSelection"] ? true : false
    let navigationSelection = state["navigationSelection"] ? true : false
    let drilldownSelection = state["drilldownSelection"] ? true : false
    let defaultFilterSelection = state["defaultFilterSelection"] ? true : false
    let conditionalFormatSelection = state["conditionalFormatSelection"] ? true : false
    let isAllFilterValueTrue = interactionSelection && navigationSelection && drilldownSelection && defaultFilterSelection && conditionalFormatSelection

    if (isAllFilterValueTrue) {
      state["checkedAllStatus"] = true
    } else if (!isAllFilterValueTrue && this.state.checkedAllStatus === true) {
      state["checkedAllStatus"] = false
    }

    this.setState(state)
  }

  /**
   * Closes context menu
   */
  closeContextMenu = () => {
    let windowWidth = $(window).width();
    let reduxState = store.getState();
    let pluginId = reduxState.ContextMenuReducer.rightClickedPluginFunction;

    document.documentElement.style.overscrollBehavior = "";
    document.documentElement.style.touchAction = "";
    document.documentElement.style.overflow = "";

    document.body.style.touchAction = "";
    document.body.style.overscrollBehavior = "";
    document.body.style.overflow = "";

    if (windowWidth < 1024) {
      $("#grid-" + pluginId).find(".plugin-container").css("outline", "");
    }

    store.dispatch(changeRightClickedPlugin(""))
    store.dispatch(closePluginCopyPastePopup(false))
  };

  /**
   * Closes sub context menu
   * 
   * @param {*} event 
   */
  closeSubMenu = (event) => {
    this.setState({
      ...this.state,
      duplicateSettingsVisibility: false,
      exportMenuVisibility: false,
      convertMenuVisibility: false,
      conversionConfirmationVisible: null
    });
  };

  /**
   * Updates the search text
   * 
   * @param {*} event 
   */
  handleConversionSearch = event => {
    this.setState({
      ...this.state,
      searchText: event.target.value,
      conversionConfirmationVisible: null
    });
  };

  /**
   * Filters the plugin conversion list
   * 
   * @param {*} conversionList 
   * @returns 
   */
  filterConversionList = conversionList => {
    if (this.state.searchText?.length > 0) {
      return conversionList.filter(p => {
        let searchText = this.state.searchText.toLowerCase() || "";
        let pluginName = i18n.t("PluginsName.names." + p).toLowerCase();

        return pluginName.includes(searchText);
      });
    }

    return conversionList;
  }

  /**
   * Converts plugin
   * 
   * @param {*} updatePlugin 
   * @param {*} rightClickedPlugin 
   * @param {*} pluginKey 
   * @param {*} interactions 
   * @param {*} callback 
   */
  convertPlugin = (
    updatePlugin,
    rightClickedPlugin,
    pluginKey,
    interactions,
    confirm,
    callback
  ) => convertPlugin(
    updatePlugin,
    rightClickedPlugin,
    pluginKey,
    interactions,
    confirm,
    () => {
      this.setState({
        ...this.state,
        searchText: ""
      }, callback);
    }
  );

  /**
   * Shows confirmation popup with message and onOk callback for the given plugin key selection
   * 
   * @param {*} key 
   * @param {*} message 
   * @param {*} callback 
   */
  setConversionConfirmation = (key, message, onConfirm) => {
    this.setState({
      conversionConfirmationVisible: key,
      conversionConfirmation: {
        message: message,
        onConfirm: onConfirm
      }
    });
  }

  /**
   * Clears & closes conversion confirmation popup
   */
  clearConversionConfirmation = (e) => {
    e.stopPropagation();

    this.setState({
      conversionConfirmationVisible: null
    });
  }

  render() {
    const {
      interactions,
      updatePlugin,
      state: {
        ContextMenuReducer,
        DrillDownReducer,
        PluginTriggerReducer,
        CustomDashboardReducer,
        PluginLoaderReducer
      },
    } = this.props;

    const windowWidth = $(window).width();
    const windowHeight = $(window).height();

    const copiedPluginObj = getCopiedPluginObj();
    const copiedPlugin = copiedPluginObj?.plugin;

    const rightClickedPluginId = ContextMenuReducer.rightClickedPluginFunction;
    const rightClickedPlugin = PluginTriggerReducer.plugins.get(rightClickedPluginId);

    const pluginDiv = document.getElementById(rightClickedPlugin?.id);

    const canXLSXExportShow = pluginDiv?.XLSX instanceof Function;
    const canPDFExportShow = pluginDiv?.PDF instanceof Function;
    const canPNGExportShow = pluginDiv?.PNG instanceof Function;

    const menuType = ContextMenuReducer.contextMenuType || copiedPluginObj?.contextMenuType;
    const isValidWriteRolesOrCustomDashboardModeActive =
      windowWidth >= 1024 &&
      isValidWriteRolesOrCustomDashboard(CustomDashboardReducer.isCustomDashboard);

    const isPluginLoaderActive = PluginLoaderReducer.waitForLoadPlugins.size > 0 && PluginLoaderReducer.waitForLoadPlugins.has(rightClickedPluginId);

    const isCopyOptionValid = menuType === "copy" && isValidWriteRolesOrCustomDashboardModeActive && rightClickedPlugin;
    const isPasteOptionValid = menuType === "paste" && isValidWriteRolesOrCustomDashboardModeActive && !rightClickedPlugin;
    const isExportOptionValid = !isPluginLoaderActive && (canXLSXExportShow || canPDFExportShow || canPNGExportShow);
    const convertedOriginalPlugin = ContextMenuReducer?.originalPlugins?.get(rightClickedPluginId);
    const pluginConversionList = getConversionList(rightClickedPlugin?.key, convertedOriginalPlugin?.key);
    const isPluginConvertable = rightClickedPlugin && (pluginConversionList?.length || convertedOriginalPlugin);
    const isConvertOptionValid = menuType === "copy" && isValidWriteRolesOrCustomDashboardModeActive && isPluginConvertable;
    const isContextMenuValid = isCopyOptionValid || isPasteOptionValid || isExportOptionValid || isConvertOptionValid;

    if (!isContextMenuValid) return null;

    const filteredInteractions = interactions?.filter((interaction) => interaction.sourceId === rightClickedPlugin?.id);
    const isPluginAutoInteraction = autoInteractionPlugins.has(rightClickedPlugin?.key);
    const edittedInteractions = rightClickedPlugin?.edittedInteractions && Object.values(rightClickedPlugin?.edittedInteractions);

    let isPluginInteractionAvailable = rightClickedPlugin && (filteredInteractions?.length > 0 || edittedInteractions?.length > 0);

    const isPluginNavigationAvailable = rightClickedPlugin?.navigations?.length > 0;
    const pluginDrillDown = DrillDownReducer.drillDowns.get(rightClickedPluginId);
    const isPluginDrilldownAvailable = pluginDrillDown?.drillDownLayerMap?.size > 0;
    const isPluginDefaultFilterAvailable = rightClickedPlugin?.defaultFilters?.length > 0;
    const isPluginCondFormatAvailable = rightClickedPlugin?.conditionalFormats?.length > 0;

    if (isPluginAutoInteraction) {
      const canInteractedPluginCount = [...PluginTriggerReducer.plugins.values()].reduce((count, p) => {
        if (!autoInteractionPlugins.has(p.key) && !exceptAutoInteractionPlugins.has(p.key)) count++;

        return count;
      }, 0);

      const isInteractedPluginLengthEqualsRemovedInteractions =
        rightClickedPlugin?.removedInteractions &&
        canInteractedPluginCount === rightClickedPlugin?.removedInteractions.length;
      const isInteractedPluginLengthGreaterRemovedInteractions =
        rightClickedPlugin?.removedInteractions === undefined ||
        (rightClickedPlugin?.removedInteractions &&
          canInteractedPluginCount >
          rightClickedPlugin?.removedInteractions.length);

      isPluginInteractionAvailable = !(
        isInteractedPluginLengthEqualsRemovedInteractions ||
        isInteractedPluginLengthGreaterRemovedInteractions
      );
    }

    const filteredConversionList = pluginConversionList
      ? this.filterConversionList(pluginConversionList)
      : [];

    let canDuplicatePluginSettingsShow =
      isPluginInteractionAvailable ||
      isPluginNavigationAvailable ||
      isPluginDrilldownAvailable ||
      isPluginDefaultFilterAvailable ||
      isPluginCondFormatAvailable;

    let settingsAreaHeight = 146;
    let settingsAreaWidth = 230;

    let convertListHeight = null;
    let shouldSearchShow = false;

    if (this.state.exportMenuVisibility) {
      settingsAreaHeight = 22 + [canXLSXExportShow, canPDFExportShow, canPNGExportShow].filter(
        (s) => s
      ).length * 32;
    } else if (this.state.convertMenuVisibility) {
      const originalPluginHeight = convertedOriginalPlugin ? 32 : 0;
      const dividerHeight =
        filteredConversionList?.length && convertedOriginalPlugin ? 14 : 0;

      convertListHeight = pluginConversionList?.length ? pluginConversionList.length * 32 : 0;
      settingsAreaHeight =
        22 + convertListHeight + originalPluginHeight + dividerHeight;

      shouldSearchShow =
        settingsAreaHeight > windowHeight * 0.3 || this.state.searchText?.length > 0;
      convertListHeight -= shouldSearchShow
        ? originalPluginHeight + dividerHeight + 38
        : 0;
    } else if (this.state.duplicateSettingsVisibility) {
      settingsAreaHeight = 259;
    }

    settingsAreaHeight = Math.min(windowHeight * 0.3, settingsAreaHeight);

    const posX =
      ContextMenuReducer.xPosition || copiedPluginObj?.xPosition || 0;
    const posY =
      ContextMenuReducer.yPosition || copiedPluginObj?.yPosition || 0;

    const defaultMenuHeight = menuType === "copy" ? 80 : 48;
    const additionalMenuHeight =
      menuType === "copy" ? (isExportOptionValid ? 32 : 0) + (isConvertOptionValid ? 32 : 0) : 0;

    const contextMenuWidth = 200;
    const contextMenuHeight = defaultMenuHeight + additionalMenuHeight;

    if (this.state.yPos !== posY || this.state.xPos !== posX) {
      const scrollTop = document.getElementById("root").scrollTop - 60;

      const calculatedYPos =
      posY + contextMenuHeight > windowHeight
      ? posY - contextMenuHeight + scrollTop
      : posY + scrollTop;
    const calculatedXPos =
      posX + contextMenuWidth > windowWidth ? posX - contextMenuWidth : posX;

      this.setState({ scrollTop: scrollTop,
        xPos: posX,
        yPos: posY,
        calculatedYPos: calculatedYPos,
        calculatedXPos: calculatedXPos
       });
    }
    
    const settingsAreaTopPos =
    this.state.calculatedYPos + settingsAreaHeight > windowHeight
        ? contextMenuHeight - settingsAreaHeight
        : 0;
    const settingsAreaLeftPos =
    this.state.calculatedXPos + (contextMenuWidth + settingsAreaWidth) > windowWidth
        ? 2 + settingsAreaWidth * -1
        : contextMenuWidth - 2;

    let subMenuType = null;

    if (this.state.duplicateSettingsVisibility) {
      subMenuType = "Duplicate";
    } else if (this.state.convertMenuVisibility) {
      subMenuType = "Convert";
    } else if (this.state.exportMenuVisibility) {
      subMenuType = "Export";
    }

    return (isContextMenuValid && windowWidth > 500 && (
      <div
        id={"copy-paste-context-menu"}
        className="context-menu"
        style={{
          display: (!this.state.contextMenuStatus && "none") || "",
          position: "absolute",
          top: this.state.calculatedYPos,
          left: this.state.calculatedXPos,
          zIndex: 1
        }}
      >
        {
          (menuType === "copy" && (
            <>
              <div class="wrapper-context-menu">
                <div class="content-context-menu">
                  <ul class="menu-context-menu">
                    {
                      isValidWriteRolesOrCustomDashboardModeActive && (
                        <li
                          class="item-context-menu share-context-menu"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "space-between",
                            background: (this.state.duplicateSettingsVisibility && "rgb(247, 246, 246)") || ""
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              alignItems: "center"
                            }}
                            onClick={() => pastePlugin(
                              interactions,
                              ContextMenuReducer.rightClickedPluginFunction,
                              this.state.interactionSelection,
                              this.state.drilldownSelection,
                              this.state.navigationSelection,
                              this.state.defaultFilterSelection,
                              this.state.conditionalFormatSelection
                            )}
                            onPointerOver={(event) => this.changeDuplicateSettingsVisibility(event, false)}

                          >
                            <CopyOutlined />
                            <span className="label">{i18n.t("Dashboard.PluginContextMenu.DuplicatePlugin")}</span>
                          </div>
                          <SettingOutlined
                            style={{
                              marginLeft: "16px",
                              color: (this.state.duplicateSettingsVisibility && "#2d99cb") || "#403f3f",
                              display: (canDuplicatePluginSettingsShow && "block") || "none"
                            }}
                            onPointerOver={(event) => this.changeDuplicateSettingsVisibility(event, true)}
                          />
                        </li>
                      )
                    }
                    {
                      isConvertOptionValid && (
                        <li
                          class="item-context-menu"
                          onPointerOver={(event) => this.setConvertMenuVisibility(event, true)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "space-between",
                            background: (this.state.convertMenuVisibility && "rgb(247, 246, 246)") || "",
                            color: (convertedOriginalPlugin && "#ff9801") || "",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            <TbTransform className="anticon" />
                            <span className="label">{i18n.t("Dashboard.PluginContextMenu.ConvertPlugin")}</span>
                          </div>
                          <RightOutlined
                            style={{
                              color: (convertedOriginalPlugin && "#ff98019f") || "#d0d0d0",
                              fontSize: 11
                            }}
                          />
                        </li>
                      )
                    }
                    {
                      isExportOptionValid && (
                        <li
                          class="item-context-menu"
                          onPointerOver={(event) => this.setExportMenuVisibility(event, true)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "space-between",
                            background: (this.state.exportMenuVisibility && "rgb(247, 246, 246)") || ""
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            <DownloadOutlined />
                            <span className="label">{i18n.t("Dashboard.PluginContextMenu.Export")}</span>
                          </div>
                          <RightOutlined
                            style={{
                              color: "#d0d0d0",
                              fontSize: 11
                            }}
                          />
                        </li>
                      )
                    }
                    {
                      isValidWriteRolesOrCustomDashboardModeActive && (
                        <li class="item-context-menu delete-item-context-menu" onClick={this.removePlugin}>
                          <DeleteOutlined />
                          <span className="label">{i18n.t("Dashboard.PluginContextMenu.Delete")}</span>
                        </li>
                      )
                    }
                  </ul>
                </div>
              </div>
              {
                isExportOptionValid && this.state.exportMenuVisibility && (
                  <ul
                    class="share-menu-context-menu"
                    style={{
                      display: "block",
                      height: settingsAreaHeight,
                      width: 230,
                      left: settingsAreaLeftPos,
                      top: settingsAreaTopPos
                    }}
                  >
                    {
                      canXLSXExportShow && (
                        <li class="item-context-menu" onClick={(event) => this.triggerExport(event, "XLSX")}>
                          <FileExcelOutlined />
                          <span className="label">{i18n.t("Dashboard.ToolsSection.ExporttoXLSX")}</span>
                        </li>
                      )
                    }
                    {
                      canPDFExportShow && (
                        <li class="item-context-menu" onClick={(event) => this.triggerExport(event, "PDF")}>
                          <FilePdfOutlined />
                          <span className="label">{i18n.t("Dashboard.ToolsSection.ExporttoPDF")}</span>
                        </li>
                      )
                    }
                    {
                      canPNGExportShow && (
                        <li class="item-context-menu" onClick={(event) => this.triggerExport(event, "PNG")}>
                          <FileImageOutlined />
                          <span className="label">{i18n.t("Dashboard.ToolsSection.ExporttoPNG")}</span>
                        </li>
                      )
                    }
                  </ul>
                )
              }
              {
                isConvertOptionValid && this.state.convertMenuVisibility && (
                  <ul
                    class="share-menu-context-menu"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: 230,
                      maxHeight: settingsAreaHeight,
                      left: settingsAreaLeftPos,
                      top: settingsAreaTopPos,
                    }}
                  >
                    {
                      pluginConversionList?.length > 0 && shouldSearchShow && (
                        <Input
                          placeholder={i18n.t("Search")}
                          prefix={<SearchOutlined />}
                          value={this.state.searchText}
                          onChange={this.handleConversionSearch}
                          style={{
                            width: "100%",
                            height: 30,
                            marginBottom: 8,
                            fontSize: 13
                          }}
                        />
                      )
                    }
                    {
                      pluginConversionList?.length > 0 && (
                        <div
                          style={{
                            maxHeight: convertListHeight,
                            width: "100%",
                            overflowY: this.state.conversionConfirmationVisible === null ? "auto" : "hidden",
                            padding: "0 4px",
                          }}
                        >
                          {
                            filteredConversionList?.map(pluginKey => (

                              <li
                                class="item-context-menu"
                                onClick={() => this.convertPlugin(
                                  updatePlugin,
                                  rightClickedPlugin,
                                  pluginKey,
                                  interactions,
                                  this.setConversionConfirmation
                                )}
                              >
                                <Popconfirm
                                  arrowPointAtCenter
                                  id="context-menu-confirmation"
                                  visible={this.state.conversionConfirmationVisible === pluginKey}
                                  title={this.state.conversionConfirmation?.message}
                                  onConfirm={this.state.conversionConfirmation?.onConfirm}
                                  onCancel={this.clearConversionConfirmation}
                                  okText={i18n.t("Okay")}
                                  cancelText={i18n.t("Cancel")}
                                  placement={
                                    this.state.calculatedXPos + (contextMenuWidth + settingsAreaWidth) > windowWidth
                                      ? "left"
                                      : "right"
                                  }
                                >
                                  <span className="label" style={{width: "100%"}}>
                                    {i18n.t("PluginsName.names." + pluginKey)}
                                  </span>
                                </Popconfirm>
                              </li>
                            ))
                          }
                          {
                            filteredConversionList?.length === 0 && (
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={i18n.t("NoDataContent.NoData")}
                              />
                            )
                          }
                        </div>
                      )
                    }
                    {
                      convertedOriginalPlugin && (
                        <>
                          {
                            pluginConversionList?.length > 0 && <Divider style={{ marginTop: 5, marginBottom: 8 }} />
                          }
                          <li
                            class="item-context-menu"
                            style={{ color: "#FF9801" }}
                            onClick={() => convertPlugin(
                              updatePlugin,
                              rightClickedPlugin,
                              null,
                              interactions,
                              this.setConversionConfirmation,
                              this.clearSearchText
                            )}
                          >
                            <span className="label">
                              {i18n.t("Default")}
                              {" "}
                              {`(${i18n.t("PluginsName.names." + convertedOriginalPlugin.key)})`}
                            </span>
                          </li>
                        </>
                      )
                    }
                  </ul>
                )
              }
              {
                this.state.duplicateSettingsVisibility && (
                  <ul
                    class="share-menu-context-menu"
                    style={{
                      display: "block",
                      left: settingsAreaLeftPos,
                      top: settingsAreaTopPos
                    }}
                  >
                    <li class="item-context-menu">
                      <Checkbox onChange={this.changeCheckedAllStatus} checked={this.state.checkedAllStatus}>
                        {i18n.t("Dashboard.PluginContextMenu.DuplicateAll")}
                      </Checkbox>
                    </li>
                    <Divider style={{ margin: 0 }}></Divider>
                    <li class="item-context-menu" style={{ justifyContent: "space-between" }}>
                      <Checkbox
                        disabled={!isPluginInteractionAvailable}
                        checked={this.state.interactionSelection && isPluginInteractionAvailable}
                        onChange={() => this.changeDuplicatedSettings("interactionSelection")}
                      >
                        {i18n.t("Dashboard.PluginContextMenu.Interaction")}
                      </Checkbox>
                      <InteractionOutlined />
                    </li>
                    <li class="item-context-menu" style={{ justifyContent: "space-between" }}>
                      <Checkbox
                        disabled={!isPluginNavigationAvailable}
                        checked={this.state.navigationSelection && isPluginNavigationAvailable}
                        onChange={() => this.changeDuplicatedSettings("navigationSelection")}
                      >
                        {i18n.t("Dashboard.PluginContextMenu.Navigation")}
                      </Checkbox>
                      <SwapOutlined />
                    </li>
                    <li class="item-context-menu" style={{ justifyContent: "space-between" }}>
                      <Checkbox
                        disabled={!isPluginDrilldownAvailable}
                        checked={this.state.drilldownSelection && isPluginDrilldownAvailable}
                        onChange={() => this.changeDuplicatedSettings("drilldownSelection")}
                      >
                        {i18n.t("Dashboard.PluginContextMenu.Drilldown")}
                      </Checkbox>
                      <i className="fas fa-angle-double-down" />
                    </li>
                    <li class="item-context-menu" style={{ justifyContent: "space-between" }}>
                      <Checkbox
                        disabled={!isPluginDefaultFilterAvailable}
                        checked={this.state.defaultFilterSelection && isPluginDefaultFilterAvailable}
                        onChange={() => this.changeDuplicatedSettings("defaultFilterSelection")}
                      >
                        {i18n.t("Dashboard.PluginContextMenu.DefaultFilter")}
                      </Checkbox>
                      <i className="fas fa-filter" />
                    </li>
                    <li class="item-context-menu" style={{ justifyContent: "space-between" }}>
                      <Checkbox
                        disabled={!isPluginCondFormatAvailable}
                        checked={this.state.conditionalFormatSelection && isPluginCondFormatAvailable}
                        onChange={() => this.changeDuplicatedSettings("conditionalFormatSelection")}
                      >
                        {i18n.t("Dashboard.PluginContextMenu.ConditionalFormat")}
                      </Checkbox>
                      <BgColorsOutlined />
                    </li>
                    <Divider style={{ marginTop: 5, marginBottom: 8 }}></Divider>

                    <Button
                      style={{ width: "100%" }}
                      onClick={() => pastePlugin(
                        interactions,
                        ContextMenuReducer.rightClickedPluginFunction,
                        this.state.interactionSelection,
                        this.state.drilldownSelection,
                        this.state.navigationSelection,
                        this.state.defaultFilterSelection,
                        this.state.conditionalFormatSelection
                      )}
                    >
                      {i18n.t("Dashboard.PluginContextMenu.DuplicatePlugin")}
                    </Button>
                  </ul>
                )
              }
            </>
          )) || (isValidWriteRolesOrCustomDashboardModeActive && (
            <div class="wrapper-context-menu">
              <div class="content-context-menu">
                <ul class="menu-context-menu">
                  <li
                    class="item-context-menu"
                    style={(copiedPlugin && {}) || { cursor: "not-allowed", opacity: "0.6" }}
                    onClick={copiedPlugin && (() => pastePlugin(interactions))}
                  >
                    <CopyOutlined />
                    <span className="label">{i18n.t("Dashboard.PluginContextMenu.PastePlugin")}</span>
                  </li>
                </ul>
              </div>
            </div>
          ))
        }
      </div>
    )) || (isContextMenuValid && windowWidth <= 500 && (
      <div
        className="context-menu"
        style={{
          display: (!this.state.contextMenuStatus && "none") || "",
          position: "fixed",
          width: "100dvw",
          height: "100dvh",
          zIndex: 1002,
          top: 0,
          left: 0
        }}
      >
        <div
          id={"copy-paste-context-menu"}
          className="mobile-context-menu"
        >
          <div className="context-menu-header" >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: 52
              }}
              onClick={this.closeSubMenu}
            >
              <LeftOutlined
                style={{
                  display: (!subMenuType && "none") || "",
                  color: "#A0A0A0",
                  fontSize: 12,
                  marginRight: 4
                }}
              />
              <span
                style={{
                  display: (!subMenuType && "none") || "",
                  color: "#A0A0A0",
                  fontSize: 16,
                  fontWeight: "bold"
                }}
              >
                {i18n.t("Dashboard.PluginContextMenu.Back")}
              </span>
            </div>
            <Text
              style={{
                width: "calc(100% - 104px)",
                textAlign: "center",
                fontSize: 16,
                fontWeight: "bold"
              }}
            >
              {
                (subMenuType && i18n.t("Dashboard.PluginContextMenu." + subMenuType)) || i18n.t("Dashboard.PluginContextMenu.Options")
              }
            </Text>
            <div
              style={{
                width: 52,
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
              }}
            >
              <CloseOutlined
                onClick={this.closeContextMenu}
                style={{
                  border: "1px solid rgb(218, 218, 218)",
                  borderRadius: "100%",
                  padding: 6,
                  fontSize: 14,
                  background: "rgb(218, 218, 218)",
                  zIndex: 1,
                }}
              />
            </div>
          </div>

          <div style={{ width: "100%", height: "calc(100% - 15px)", padding: "0px 16px" }}>
            {
              this.state.exportMenuVisibility && (
                <ul class="menu-context-menu" style={{ padding: 0, height: "100%", overflowY: "auto", overscrollBehavior: "contain" }}>
                  {
                    canXLSXExportShow && (
                      <li id="exportXLSX" class="item-context-menu" onClick={(event) => this.triggerExport(event, "XLSX")}>
                        <FileExcelOutlined />
                        <span className="label">{i18n.t("Dashboard.ToolsSection.ExporttoXLSX")}</span>
                      </li>
                    )
                  }
                  {
                    canPDFExportShow && (
                      <li id="exportPDF" class="item-context-menu" onClick={(event) => this.triggerExport(event, "PDF")}>
                        <FilePdfOutlined />
                        <span className="label">{i18n.t("Dashboard.ToolsSection.ExporttoPDF")}</span>
                      </li>
                    )
                  }
                  {
                    canPNGExportShow && (
                      <li id="exportPNG" class="item-context-menu" onClick={(event) => this.triggerExport(event, "PNG")}>
                        <FileImageOutlined />
                        <span className="label">{i18n.t("Dashboard.ToolsSection.ExporttoPNG")}</span>
                      </li>
                    )
                  }
                </ul>
              )
            }
            {
              !subMenuType && (
                <ul class="menu-context-menu" style={{ padding: 0, height: "100%", overflowY: "auto", overscrollBehavior: "contain" }}>
                  {
                    isExportOptionValid && (
                      <li
                        id="pluginExport"
                        class="item-context-menu"
                        onClick={this.setExportMenuVisibility}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          justifyContent: "space-between",
                          background: (this.state.exportMenuVisibility && "rgb(247, 246, 246)") || ""
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          <DownloadOutlined />
                          <span className="label">{i18n.t("Dashboard.PluginContextMenu.Export")}</span>
                        </div>
                        <RightOutlined style={{ color: "#d0d0d0", fontSize: 11 }} />
                      </li>
                    )
                  }
                </ul>
              )
            }
          </div>
        </div>
      </div>
    ));
  }
}

const mapDispatchToProps = {
  rightClickCopiedPluginStatus,
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PluginContextMenu);
