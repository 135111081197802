import React, { Component } from "react";
import { BiBell } from "react-icons/bi";
import i18n from "../../Utils/i18next";
import "./userNotifications.css";
import NotificationList from "./NotificationList";

export default class UserNotificationsBody extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() { }

    render() {
        return (
            <>
                {this.props.notifications.length === 0 ? 
                <div className='notification-list-container' style={{minHeight: "330px"}}>
                    <div className='empty-comment-body-top'>
                        <BiBell className='empty-comment-background-icon' fontSize={"70px"} />
                        <div className="empty-comment-information-text" style={{ fontSize: "20px", color: "rgb(80, 80, 80)", opacity: "0.4" }}>
                            {i18n.t("Notification.YouCanSeeYourNotifications")}
                        </div>
                    </div>
                </div>
                    : <NotificationList notifications={this.props.notifications} usersWithColours={this.props.usersWithColours}/>}
            </>

        );
    }
}
