import React, { Component } from "react";
import { Button } from "antd";
import i18n from "../../../../../Utils/i18next";

/** auto refresh buttons */
class ButtonArea extends Component {
  /** Auto refresh apply button */
  applyButton = (
    <Button
      className={"general-button"}
      style={{ float: "right", margin: "0", width: "25%" }}
      onClick={() => this.props.apply()}
    >
      {i18n.t("Apply")}
    </Button>
  );

  /** Auto refresh set default button */
  defaultButton = (
    <Button
      onClick={() => this.props.setDefault()}
      style={{
        float: "right",
        margin: "0 10px",
        borderBottom: "2px solid",
        width: "25%"
      }}
    >
      {i18n.t("Default")}
    </Button>
  );

  render() {
    return (
      <div className={"button-area"}>
        {this.applyButton}
        {this.defaultButton}
      </div>
    );
  }
}

export default ButtonArea;
