import React, { Component } from "react";
import PopupContainer from "../GeneralComponents/PopupContainer/PopupContainer";
import { Badge, Button, Form, Upload } from "antd";
import { dateFormat, deepCopy } from "../../Utils/Global";
import i18n from "../../Utils/i18next";
import reportThumbnail from "../../images/report-thumbnail.png";
import { LoadingOutlined, PictureFilled } from "@ant-design/icons";
import Text from "../GeneralComponents/Text/Text";
import { showNotificationWithIcon } from "../../Utils/Notification";
import { API_BASE } from "../../config";
import Cookies from "js-cookie";
import { del } from "../../Utils/WebService";

/**
 * Dashbaord Preview Component
 * 
 * Contains dashboard information & custom thumbnail edit functions.
 */
class DashboardPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editThumbnail: false,
      loading: false
    };
  }

  /**
   * Closes dashboard preview window
   */
  Close = () => {
    this.props.onClose();

    this.setState({
      editThumbnail: false,
      loading: false
    })
  };

  /**
   * Renders last update date of the dashboard
   * 
   * @returns
   */
  showLastUpdatedDate = () => {
    if (this.props.data.lastUpdatedDate) {
      return (
        <>
          <div style={{ margin: "3px 0" }} className={"col-lg-4"}>
            <span className={"constant-text"}>{i18n.t("LandingPage.Preview.UpdateDate")}: </span>
          </div>
          <div style={{ margin: "3px 0" }} className={"col-lg-8"}>
            <span>{dateFormat(this.props.data.lastUpdatedDate, "/")}</span>
          </div>
        </>
      )
    }
  }

  /**
   * Opens edit window by setting editThumbnail
   */
  prepareEditThumbnail = () => {
    this.setState({
      editThumbnail: true
    });
  }

  /**
   * Custom thumbnail upload status change handler
   * - If upload process succeed, updates dashboard's custom thumbnail resource id
   * - If upload process got an error, shows an error message
   * 
   * @param {*} status 
   * @param {*} response 
   */
  onChangeUploadCustomThumbnail = (status, response) => {
    if (status === "success") {
      this.props.updateCustomThumbnail(this.props.data.id, response);

      this.setState({
        loading: false,
        editThumbnail: false
      });
    } else if ("error") {
      showNotificationWithIcon(i18n.t("Dashboard.CustomThumbnail.ImageUploadError"), response.message, "error")

      this.setState({
        loading: false
      });
    }
  }

  /**
   * Check file type is image and file size is smaller than 10MB.
   * 
   * @param {*} file 
   * @returns 
   */
  beforeUploadCustomThumbnail = (file) => {
    let isFileTypeAllowed = /(image\/(?:jpg|jpeg|png|webp))/gi.test(file.type);
    let isLessThen10M = file.size < 10 * 1024 ** 2;

    if (!isFileTypeAllowed) {
      showNotificationWithIcon(i18n.t("Dashboard.CustomThumbnail.ImageUploadError"), i18n.t("Dashboard.CustomThumbnail.ImageFileTypeError"), "error");

      return false;
    }

    if (!isLessThen10M) {
      showNotificationWithIcon(i18n.t("Dashboard.CustomThumbnail.ImageUploadError"), i18n.t("Dashboard.CustomThumbnail.ImageFileOverTheLimitError"), "error");

      return false;
    }

    return true;
  }

  /**
   * Deletes dashboard's custom thumbnail
   */
  deleteCustomThumbnail = () => {
    let url = `${API_BASE}/custom-thumbnail/${this.props.data.type || "dashboard"}/${this.props.data.id}`;

    let successFunc = () => {
      this.props.updateCustomThumbnail(this.props.data.id, null);

      this.setState({
        loading: false,
        editThumbnail: false
      });
    }

    let errorFunc = (error) => {
      showNotificationWithIcon(i18n.t("Error"), error.message, "error")
      
      this.setState({
        loading: false
      });
    }

    this.setState({
      loading: true
    }, () => del(url, {}, successFunc, errorFunc, false, undefined, true, false, undefined, false));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data?.id !== this.props.data?.id) {
      this.setState({
        loading: false,
        editThumbnail: false
      });
    }
  }

  render() {
    let viewCountText = i18n.t("LandingPage.Preview.ViewCount");
    let customThumbnail = false
    let thumbnail = "#f3f3f3";

    if (this.props.data.customThumbnail) {
      customThumbnail = true;
      thumbnail = `url(/resource/api/resource/${this.props.data.customThumbnail}) center top / contain no-repeat`;
    } else if (this.props.data?.type === "report") {
      thumbnail = "url(" + reportThumbnail + ") center top / contain no-repeat";
    } else if (this.props.data.thumbnail) {
      thumbnail = `url(/resource/api/resource/${this.props.data.thumbnail}) center top / contain no-repeat`;
    }

    return Object.keys(this.props.data).length > 0 ? (
      <PopupContainer width="fit-content" background={"#f9f9f9"} shadow={"0px 0px 4px 2px #226090"} position={this.props.position}>
        <div class="preview" style={{ width: "100%" }}>
          <span className={"preview-close"} onClick={this.Close}>
            <i className={"fa fa-times"}></i>
          </span>
          <h4 type="h4" style={{ marginBottom: "5px" }}>
            {this.props.data.settings.title}
          </h4>
          <div
            className={"preview-thumb"}
            style={{ background: thumbnail, position: "relative" }}
          >
            {
              this.state.editThumbnail === true ? (
                <>
                  <div
                    id="thumb-upload-loading"
                    key="thumb-upload-loading"
                    className="edit-thumb"
                    style={{ 
                      display: this.state.loading ? "flex" : "none", 
                      justifyContent: "center"
                    }}
                  >
                    <LoadingOutlined />
                  </div>

                  <div
                    id="thumb-upload-edit"
                    key="thumb-upload-edit"
                    className="edit-thumb"
                    style={{ display: this.state.loading ? "none" : "flex" }}
                  >
                    <div
                      className={"preview-temp-thumb"}
                      style={{ background: thumbnail, marginBottom: 16 }}
                    />

                    <Form onSubmit={this.handleSubmit} style={{ margin: "4px" }}>
                      <Upload
                        accept="image/png, image/jpeg, image/png, image/webp"
                        showUploadList={false}
                        beforeUpload={this.beforeUploadCustomThumbnail}
                        onStart={() => this.setState({ loading: true })}
                        onSuccess={(response) => this.onChangeUploadCustomThumbnail("success", response)}
                        onError={(response) => this.onChangeUploadCustomThumbnail("error", response)}
                        action={`${API_BASE}/custom-thumbnail/${this.props.data.type || "dashboard"}/${this.props.data.id}`}
                        headers={{
                          Authorization: `Bearer ${Cookies.get("ORA_BIPS_NQID")}`
                        }}

                      >
                        <Button
                          type="file"
                          disabled={this.state.loading}
                          className="btn btn-new new-button"
                          style={{ fontSize: "12px" }}
                        >
                          {i18n.t("UploadImage")}
                        </Button>
                      </Upload>
                    </Form>

                    {
                      customThumbnail && (
                        <Button
                          className="btn btn-new new-button"
                          style={{ margin: "4px", fontSize: "12px" }}
                          disabled={this.state.loading}
                          onClick={this.deleteCustomThumbnail}
                        >
                          {i18n.t("ReturnToDefault")}
                        </Button>
                      )
                    }
                    <Button
                      className="btn btn-new new-button"
                      style={{ margin: "4px", fontSize: "12px" }}
                      onClick={() => this.setState({ editThumbnail: false, tempThumbnail: null })}
                      disabled={this.state.loading}
                    >
                      {i18n.t("Cancel")}
                    </Button>
                  </div>
                </>
              ) : (
                <div className="preview-edit-thumb" onClick={this.prepareEditThumbnail}>
                  <PictureFilled style={{ fontSize: 56, marginBottom: 16 }} />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      width: "100%",
                    }}
                  >
                    <Text>{i18n.t("ChangeImage")}</Text>
                  </div>
                </div>
              )
            }
          </div>

          <div className={"row"} style={{ fontSize: "14px" }}>
            <div style={{ margin: "3px 0" }} className={"col-lg-4"}>
              <span className={"constant-text"}>{i18n.t("LandingPage.Preview.CreatedUser")}: </span>
            </div>
            <div style={{ margin: "3px 0" }} className={"col-lg-8"}>
              <span>{this.props.data.createdUser}</span>
            </div>

            <div style={{ margin: "3px 0" }} className={"col-lg-4"}>
              <span className={"constant-text"}>{i18n.t("LandingPage.Preview.Description")}: </span>
            </div>
            <div style={{ margin: "3px 0" }} className={"col-lg-8"}>
              <span className={"styledScroll"} style={{ maxHeight: "85px", display: "block", overflow: "auto" }}>{this.props.data.settings.description}</span>
            </div>

            <div style={{ margin: "3px 0" }} className={"col-lg-4"}>
              <span className={"constant-text"}>{i18n.t("LandingPage.Preview.CreationDate")}: </span>
            </div>
            <div style={{ margin: "3px 0" }} className={"col-lg-8"}>
              <span>{dateFormat(this.props.data.creationDate, "/")}</span>
            </div>

            {this.showLastUpdatedDate()}

            {!this.props.disableViewCount
              ? <>
                <div style={{ margin: "3px 0" }} className={"col-lg-4"}>
                  <span className={"constant-text"}>{viewCountText}: </span>
                </div>
                <div style={{ margin: "3px 0" }} className={"col-lg-8"}>
                  <span>{this.props.data.viewCount}</span>
                </div>
              </>
              : null}

          </div>
          <div style={{ marginTop: "10px" }}>
            {this.props.data.labels.length > 0
              ? this.props.data.labels.map(l => {
                return (
                  <Badge
                    count={i18n.t(l.displayName)}
                    style={{ backgroundColor: l.color, marginRight: "3px" }}
                  />
                );
              })
              : null}
          </div>
        </div>
      </PopupContainer>
    ) : null;
  }
}

export default DashboardPreview;
