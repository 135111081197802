import React, { Component } from "react";
import {
  Droppable as DNDDroppable,
  Draggable as DNDDraggable
} from "react-beautiful-dnd";
import PropTypes from "prop-types";
import { isColumnAggregatableDestinationColumn, isColumnsDataTypeDate } from "../GeneralComponents/ColumnMapDragDrop"

/**
 * Vertical drag and drop to reorder data.
 * Includes react dnd libs.
 * Content returns object to parent components
 */
export default class VerticalDragDrop extends Component {
  constructor(props) {
    super(props);

    this.prototype = {
      content: PropTypes.object, //react node
      type: PropTypes.string,
      data: PropTypes.array,
      drillDownFieldName: PropTypes.string,
      drillDownFieldWarning: PropTypes.bool,
      updateColumnMapData: PropTypes.func
    };

    this.state = {
      items: this.props.data,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.setState({
        ...this.state,
        items: nextProps.data
      });
    }
  }

  /*
  * Gives columns style
  */
  getItemStyle = (isDragging, draggableStyle, provided) => ({
    ...draggableStyle,
    userSelect: "none",
    marginBottom: "8px",
    borderRadius: "6px",
    border: "2px dashed",
    borderColor: isDragging ? "#b7b7b7" : "#ffffff00",
    top: isDragging ? "auto!important" : "",
    left: isDragging ? "auto!important" : "",
    position: isDragging ? "absolute" : "",
  });
  
  getListStyle = (isDraggingOver, snapshot, provided) => ({
    width: "100%",
    backgroundColor: this.changeBackgroundColor(provided, isDraggingOver, snapshot)
  });

  /*
  * Changes background color for dataType
  */
  changeBackgroundColor = (provided, isDraggingOver, snapshot) => {
    let draggedColumn = snapshot.draggingOverWith !== null ? snapshot.draggingOverWith : ""

    if (this.props.type === "measure") {
      return isColumnAggregatableDestinationColumn(provided, isDraggingOver, this.props.columnList, this.props.aggregatableDataTypes, this.props.isContainerAcceptAllColumn, draggedColumn)
    } else if (this.props.type === "date") {
      return isColumnsDataTypeDate(provided, isDraggingOver, this.props.columnList, this.props.dateDataTypes, this.props.isContainerAcceptAllColumn, draggedColumn)
    }
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    let uniqueColumnId = (colId) => this.props.sortArea ? `${colId}-sort` : colId

    return (
        <DNDDroppable droppableId={this.props.type}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={this.getListStyle(snapshot.isDraggingOver, snapshot, provided)}
            >
              {this.state.items &&
                this.state.items.map((item, index) => (
                  <DNDDraggable
                    key={uniqueColumnId(item.uniqeColumnId)}
                    draggableId={uniqueColumnId(item.uniqeColumnId)}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={this.getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                          provided
                        )}
                      >
                        {this.props.content({
                          item: item,
                          index: index,
                          type: this.props.type,
                          drillDownFieldName: this.props.drillDownFieldName,
                          drillDownFieldWarning: this.props.drillDownFieldWarning,
                        }, this.props.componentTHIS)}
                      </div>
                    )}
                  </DNDDraggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </DNDDroppable>
    );
  }
}
