import React, { Component } from "react";
import { post } from "../Utils/WebService";
import { API_BASE } from "../config";
import { showNotificationWithIcon } from "../Utils/Notification";
import { Tooltip, Button, Icon, Input, Form, Modal, Alert } from "antd";

const FormItem = Form.Item;
const { TextArea } = Input;

class AddRolesContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      addStatus: "",
      roleName: "",
      roleDescription: "",
      roleTenant: ""
    };
  }

  addRole = () => {
    let url = `${API_BASE}/role`;

    let requestBody = {
      name: this.state.roleName,
      description: this.state.roleDescription,
      tenant: null
    };

    const successFunc = result => {
      this.setState({
        roleName: "",
        roleDescription: "",
        roleTenant: ""
      });

      showNotificationWithIcon("OK", "Role successfully added.", "success");

      this.props.confirm(true);
    };

    const errorFunc = error => {
      this.setState({
        addStatus: error.response.data
      });
      showNotificationWithIcon("ERROR!", error.response.data, "error");
    };

    post(url, requestBody, successFunc, errorFunc);
  };

  handleCancel = e => {
    this.props.confirm(false);
  };

  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    });
  };

  control = () => {
    let roleName = this.state.roleName;
    if (roleName == "") {
      this.setState({
        addStatus: "Role name mustn't empty."
      });
    } else {
      this.addRole();
    }
  };

  render() {
    return (
      <Modal
        title="Add new role"
        visible={this.props.visible}
        onCancel={this.handleCancel}
        footer={[]}
      >
        <Form>
          <FormItem>
            <Input
              placeholder="Role name"
              value={this.state.roleName}
              id="roleName"
              onChange={this.handleChange}
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              suffix={
                <Tooltip title="Role name must be unique.">
                  <Icon
                    type="info-circle"
                    style={{ color: "rgba(0,0,0,.45)" }}
                  />
                </Tooltip>
              }
            />
          </FormItem>
          <FormItem>
            <TextArea
              placeholder="Description"
              value={this.state.roleDescription}
              rows={3}
              id="roleDescription"
              onChange={this.handleChange}
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              suffix={
                <Tooltip title="Extra information">
                  <Icon
                    type="info-circle"
                    style={{ color: "rgba(0,0,0,.45)" }}
                  />
                </Tooltip>
              }
            />
          </FormItem>
          <FormItem>
            <Button
              type="primary"
              onClick={() => this.control()}
              style={{ float: "right" }}
            >
              Save
            </Button>
          </FormItem>
          <FormItem>
            <Alert
              message={this.state.addStatus}
              style={{
                display: this.state.addStatus == "" ? "none" : "block"
              }}
              type={this.state.addStatus == "OK" ? "success" : "error"}
            />
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default AddRolesContent;
