import React, { Component } from "react";
import { Table, Divider, Popconfirm, Button, Row, Input } from "antd";

import { get, del } from "../Utils/WebService";
import DataSourceModal from "./DataSourceModal";
import { showNotificationWithIcon } from "../Utils/Notification";
import { API_BASE } from "../config";

const host = API_BASE;
const api = "/dataSource";
const url = host + api;

class DataSourceList extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: "Name",
        key: "name",
        dataIndex: "name"
      },
      {
        title: "Actions",
        key: "action",
        render: (item, record) => this.getActions(record)
      }
    ];

    this.state = {
      dataSources: [],
      isDataSourceModalVisible: false,
      selectedItem: {},
      password: ""
    };
  }

  componentDidMount() {
    this.fetchDataSources();
  }

  fetchDataSources = () => {
    const successFunc = response => {
      const dataSources = response.data.map((item, index) => ({
        key: index,
        name: item.name,
        config: JSON.stringify(item.config, null, "  ")
      }));

      this.setState({
        ...this.state,
        dataSources
      });
    };

    const errorFunc = error => {
      showNotificationWithIcon("Data could not fetch.", null, "error");
    };

    get(url, successFunc, errorFunc);
  };

  getActions = item => {
    return (
      <div>
        <Button
          type="default"
          icon="edit"
          shape="circle"
          onClick={() => this.handleEdit(item)}
        />
        <Divider type="vertical" />
        <Popconfirm
          title={this.getPassInput(item)}
          onConfirm={() => this.handleDelete(item)}
          onCancel={this.handleCancel}
          okText="Confirm"
          cancelText="Cancel"
        >
          <Button type="danger" icon="delete" shape="circle" />
        </Popconfirm>
      </div>
    );
  };

  getPassInput = item => {
    return (
      <div>
        <span>Enter your password to delete datasource.</span>
        <Input.Password
          id="password"
          placeholder="input password"
          value={this.state.password}
          onChange={this.handleChange}
          onPressEnter={() => this.handleDelete(item)}
        />
      </div>
    );
  };

  handleCancel = e => {
    this.setState({
      ...this.state,
      selectedItem: {},
      password: ""
    });
  };

  handleAdd = () => {
    this.setState({
      ...this.state,
      selectedItem: {},
      isDataSourceModalVisible: true
    });
  };

  handleEdit = selectedItem => {
    this.setState({
      ...this.state,
      isDataSourceModalVisible: true,
      selectedItem
    });
  };

  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    });
  };

  handleDelete = selectedItem => {
    const { password } = this.state;

    this.setState({
      ...this.state,
      selectedItem
    });

    const successFunc = response => {
      showNotificationWithIcon(
        "Datasource successfully deleted",
        null,
        "success"
      );

      this.setState({
        ...this.state,
        selectedItem: {},
        password: ""
      });

      this.fetchDataSources();
    };

    const errorFunc = error => {
      showNotificationWithIcon("Datasource can not deleted", error.response.data, "error");

      this.setState({
        ...this.state,
        selectedItem: {},
        password: ""
      });
    };

    let urlWithParameters = url + "/" + selectedItem.name;
    
    del(urlWithParameters, password, successFunc, errorFunc);
  };

  confirmDataSourceModal = result => {

    this.setState({
      ...this.state,
      isDataSourceModalVisible: false
    });

    if(result.action === "confirmAndGoModelling"){
      this.props.onResult(result)
    }
    else if(result.action !== "cancel"){
      this.fetchDataSources();
    }
  };

  render() {
    const { dataSources, isDataSourceModalVisible, selectedItem } = this.state;
    return (
      <div>
        <Row>
          <Button
            id="addDataSourceButton"
            className="addButton"
            type="dashed"
            icon="plus-circle"
            onClick={this.handleAdd}
          >
            Add
          </Button>
          <DataSourceModal
            visible={isDataSourceModalVisible}
            selectedItem={selectedItem}
            confirm={this.confirmDataSourceModal}
          />
        </Row>
        <Row>
          <Table columns={this.columns} dataSource={dataSources} />
        </Row>
      </div>
    );
  }
}

export default DataSourceList;
