import React from "react";
import Button from "../Button/Button";
import MonacoEditor from "react-monaco-editor";
import i18n from "../../../Utils/i18next";
import { store } from "../../..";
import { setFormulaEditorContent } from "./FormulaEditorAction";
import Text from "../Text/Text";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { Col, Row } from "antd";
import QueryOrExpression from "./QueryOrExpression";

/**
 * This component consists of:
 *  - `title` which contains: Plugin's title and name, Column's field name & name
 *  - `MonacoEditor` (react-monaco-editor: https://github.com/react-monaco-editor/react-monaco-editor) & its options
 *  - `Button` for apply and cancel
 */
class FormulaCodeEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expression: false,
      code: this.props.state.FormulaEditorReducer.contentObject.code || "",
      title: i18n.t("FormulaEditor.Titles.Editor")
    };
  }

  /**
   * `Monaco Editor` Options
   * 
   * More info: https://microsoft.github.io/monaco-editor/api/interfaces/monaco.editor.IEditorOptions.html
   */
  options = {
    minimap: { enabled: false },
    hover: {
      enabled: true,
      above: false,
      sticky: true
    },
    matchBrackets: "always",
    acceptSuggestionOnCommitCharacter: true,
    acceptSuggestionOnEnter: "on",
    accessibilitySupport: "auto",
    autoIndent: true,
    automaticLayout: true,
    codeLens: true,
    colorDecorators: true,
    contextmenu: true,
    cursorBlinking: "smooth",
    cursorSmoothCaretAnimation: false,
    cursorStyle: "line",
    disableLayerHinting: false,
    disableMonospaceOptimizations: false,
    dragAndDrop: true,
    fixedOverflowWidgets: false,
    folding: true,
    foldingStrategy: "auto",
    fontLigatures: true,
    formatOnPaste: true,
    formatOnType: true,
    hideCursorInOverviewRuler: false,
    highlightActiveIndentGuide: true,
    links: true,
    mouseWheelZoom: false,
    multiCursorMergeOverlapping: true,
    multiCursorModifier: "alt",
    overviewRulerBorder: true,
    overviewRulerLanes: 2,
    quickSuggestions: true,
    quickSuggestionsDelay: 0,
    readOnly: false,
    renderControlCharacters: true,
    renderFinalNewline: true,
    renderIndentGuides: true,
    renderLineHighlight: "all",
    renderWhitespace: "none",
    revealHorizontalRightPadding: 30,
    roundedSelection: true,
    scrollBeyondLastColumn: 5,
    scrollBeyondLastLine: true,
    selectOnLineNumbers: true,
    selectionClipboard: true,
    selectionHighlight: true,
    showFoldingControls: "mouseover",
    smoothScrolling: true,
    suggestOnTriggerCharacters: true,
    wordBasedSuggestions: true,
    wordSeparators: "~!@#$%^&*()-=+[{]}|;:'\",.<>/?",
    wordWrap: "off",
    wordWrapBreakAfterCharacters: "\t})]?|&,;",
    wordWrapBreakBeforeCharacters: "{([+",
    wordWrapBreakObtrusiveCharacters: ".",
    wordWrapMinified: true,
    wrappingIndent: "none",
    scrollbar: {
      vertical: 'visible',
      horizontal: 'visible'
    }
  };

  componentDidMount() {
    const { contentObject } = this.props.state.FormulaEditorReducer;

    this.setState({
      ...this.state,
      code: contentObject.code,
      title: contentObject.editorTitle,
      expression: contentObject.expression
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.state.FormulaEditorReducer === this.props.state.FormulaEditorReducer) {
      return;
    }

    let flag = false;
    let newState = { ...this.state };

    if (nextProps.state.FormulaEditorReducer.contentObject.code !== this.state.code) {
      newState.code = nextProps.state.FormulaEditorReducer.contentObject.code;
      flag = true;
    }

    if (nextProps.state.FormulaEditorReducer.contentObject.expression !== this.state.expression) {
      newState.expression = nextProps.state.FormulaEditorReducer.contentObject.expression;
      flag = true;
    }

    if (nextProps.state.FormulaEditorReducer.contentObject.editorTitle !== this.state.title) {
      newState.title = nextProps.state.contentObject.editorTitle;
      flag = true;
    }

    if (
      flag === true &&
      nextProps.state.FormulaEditorReducer.contentObject.visible === true
    ) {
      this.setState(newState);
    }
  }

  /**
   * Adds the event listener that updates editor layout on window resize.
   */
  editorDidMount = (editor) => {
    window.addEventListener("resize", () => {
      editor.layout({});
    })
  }

  updateCode = (value) => {
    let reduxState = store.getState();
    let { contentObject } = reduxState.FormulaEditorReducer;

    contentObject.code = value;

    store.dispatch(setFormulaEditorContent(contentObject));
  };

  /**
   * Updates `Formula Editor` `code` value
   * 
   * @param {*} newValue 
   * @param {*} e 
   */
  editorOnChange = async (newValue, e) => {
    clearTimeout(this.timer);
    
    this.setState({
      ...this.state,
      code: newValue
    });

    this.timer = setTimeout(() => this.updateCode(newValue), 500);
  };

  /**
   * Removes the event listener that updates editor layout on window resize.
   */
  editorWillUnmount = (editor) => {
    window.removeEventListener("resize", () => {
      editor.layout({});
    })
  }

  /**
   * Updates whether the column is an expression or a query
   * @param {*} status 
   */
  onChangeQueryExpr = (status) => {
    let reduxState = store.getState();
    let { contentObject } = reduxState.FormulaEditorReducer;

    contentObject["expression"] = status

    store.dispatch(setFormulaEditorContent(contentObject));

    this.setState({
      ...this.state,
      expression: status
    })
  }

  render() {
    return (
      <div
        style={{
          height: this.props.height,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ borderBottom: "solid 1px #dadada" }}>
          <Row gutter={8}>
            <Col span={this.props.isExpressionAvailable ? 14 : 24}>
              <Text
                style={{
                  margin: "0",
                  padding: "12px",
                  display: "block",
                  width: "fit-content",
                  textAlign: "left",
                  color: "#225f8f"
                }}
              >
                {this.state.title}
              </Text>
            </Col>
            {
              this.props.isExpressionAvailable && (
                <Col span={10}>
                  <QueryOrExpression
                    onChangeQueryExpr={this.onChangeQueryExpr}
                    expression={this.state.expression}
                  />
                </Col>
              )
            }
          </Row>
        </div>
        <div
          id="formula-editor-drop-area"
          style={{
            flex: 1,
            border: "solid 2px transparent",
            marginBottom: "-2px",
            minHeight: 0
          }}
        >
          <MonacoEditor
            style={{ background: "black !important" }}
            value={this.state.code}
            language={this.props.language}
            options={this.options}
            theme="visp"
            editorDidMount={this.editorDidMount}
            onChange={this.editorOnChange}
            editorWillUnmount={this.editorWillUnmount}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            padding: "8px 16px",
          }}
        >
          <Button
            className={"general-button-outlined"}
            style={{
              padding: "4px 12px",
              marginRight: "6px",
            }}
            onClick={() => this.props.onCancel()}
          >
            {i18n.t("Cancel")}
          </Button>

          <Button
            className={"general-button"}
            style={{
              padding: "4px 12px !important",
              margin: "0",
              marginLeft: "6px",
              width: "fit-content"
            }}
            onClick={() => this.props.onApply()}
          >
            {i18n.t("Apply")}
          </Button>
        </div>
      </div>
    );
  }
}

/**
 * Set action method to props
 */
const mapDispatchToProps = {
  setFormulaEditorContent,
};

/**
 * Set store objects to state
 * 
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormulaCodeEditor);