import React, { Component } from "react";
import UserNotificationsBody from "./UserNotificationsBody";
import UserNotificationsHeader from "./UserNotificationsHeader";
import "./userNotifications.css";

/*
* Notification Main Container
*/
export default class NotificationContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {}

    render() {
        return (
            <>
                <UserNotificationsHeader
                    setVisibilityOfNotificationPopup={this.props.setVisibilityOfNotificationPopup}
                    getAllNotifications={this.props.getAllNotifications}
                    isPinned={this.props.isPinned}
                    setIsPinned={this.props.setIsPinned}
                />
                <UserNotificationsBody
                    notifications={this.props.notifications}
                    usersWithColours={this.props.usersWithColours}
                />
            </>
        );
    }
}
