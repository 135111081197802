import React, { Component } from "react";
import $ from "jquery";
import i18n from "../../../../Utils/i18next";
import NetworkGraphConfiguration from "./NetworkGraphConfiguration"
import NetworkGraphData from "./NetworkGraphData"
import {
  onComponentWillMount,
  onComponentWillReceiveProps,
  getColumnMapping,
} from "../common";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import { renderConfig, renderData, renderNavigation } from "../PluginsCommonComponents";
import { renderContent } from "../renderContent";
import { InsightsConfig } from "../../RenderJs/config";
import * as am5 from '@amcharts/amcharts5';
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { checkTableJoins } from "../../../GeneralComponents/Join/Join";
import { calculatePluginInlineHeight } from "../../../DrillDown/PluginHeightWithDrilldown";
import { createTrigger } from "../../../Interaction/CreateTrigger";
import { deepCopy } from "../../../../Utils/Global";
import NavigationContent from "../../../Navigation/NavigationContent";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";

const pluginName = "network-graph";
const config = {
  "showHideButton": false,
  "colours": "Flat-UI",
  "toggleCriteria": "",
  "title": "",
  "summary": "",
  "backgroundColor": "rgb(255,255,255)",
  "refresh": 0,
  "minRadiusInPercent": 5,
  "minRadiusInPx": 15,
  "minRadiusUnit": "percent",
  "maxRadiusInPercent": 10,
  "maxRadiusInPx": 30,
  "maxRadiusUnit": "percent",
  "nodePadding": 10,
  "tree": false,
  "treeRadius": 15,
  "treeHorizontal": false,
  "draggability": true,
  "collapsibility": true,
  "linkHighlight": true,
  "rootName": "ROOT"
};
const columnMap = {
  "id": {
    "name": "ID",
    "aliasName": "ID",
    "displayName": "ID",
    "description": null,
    "nullable": false,
    "dataType": "integer",
    "aggregatable": false,
    "aggrRule": null,
    "hidden": false,
    "columnType": null,
    "isEnable": null,
    "duplicated": false,
    "doubleColumn": false,
    "windowFunction": false,
    "tableAliasName": "network_graph_data",
    "dataSourceKey": "jojyew_eufbxtpy.public",
    "tableDisplayName": "network_graph_data",
    "uniqeColumnId": "c03a54b-0ba-a16-cd-8a78d3ae67da",
  },
  "label": {
    "name": "LABEL",
    "aliasName": "LABEL",
    "displayName": "LABEL",
    "description": null,
    "nullable": false,
    "dataType": "varchar",
    "aggregatable": false,
    "aggrRule": null,
    "hidden": false,
    "columnType": null,
    "isEnable": null,
    "duplicated": false,
    "doubleColumn": false,
    "windowFunction": false,
    "tableAliasName": "network_graph_data",
    "dataSourceKey": "jojyew_eufbxtpy.public",
    "tableDisplayName": "network_graph_data",
    "uniqeColumnId": "763cd18-f4ac-accd-baa6-5f446e65584b",
  },
  "parentId": {
    "name": "PARENT_ID",
    "aliasName": "PARENT_ID",
    "displayName": "PARENT_ID",
    "description": null,
    "nullable": false,
    "dataType": "double",
    "aggregatable": false,
    "aggrRule": null,
    "hidden": false,
    "columnType": null,
    "isEnable": null,
    "duplicated": false,
    "doubleColumn": false,
    "windowFunction": false,
    "tableAliasName": "network_graph_data",
    "dataSourceKey": "jojyew_eufbxtpy.public",
    "tableDisplayName": "network_graph_data",
    "uniqeColumnId": "c15d654-3b0-ddbe-54e8-5c2d6b05d15",
  },
  "measure": {
    "name": "VALUE",
    "aliasName": "VALUE",
    "displayName": "VALUE",
    "description": null,
    "nullable": false,
    "dataType": "integer",
    "aggregatable": false,
    "aggrRule": null,
    "hidden": false,
    "columnType": null,
    "isEnable": null,
    "duplicated": false,
    "doubleColumn": false,
    "windowFunction": false,
    "tableAliasName": "network_graph_data",
    "dataSourceKey": "jojyew_eufbxtpy.public",
    "tableDisplayName": "network_graph_data",
    "uniqeColumnId": "b3373-4d00-8b1e-8b0c-1553e5c2bc1",
  }
};

const data = [
  {
    id: 1,
    label: "A",
    parentId: null,
    linkWith: null,
    measure: 900,

  },
  {
    id: 2,
    label: "A1",
    parentId: 1,
    linkWith: null,
    measure: 350
  },
  {
    id: 3,
    label: "A2",
    parentId: 1,
    linkWith: null,
    measure: 400
  },
  {
    id: 4,
    label: "A3",
    parentId: 1,
    linkWith: null,
    measure: 150
  },
  {
    id: 5,
    label: "B",
    parentId: null,
    linkWith: null,
    measure: 800
  },
  {
    id: 6,
    label: "B1",
    parentId: 5,
    linkWith: null,
    measure: 100
  },
  {
    id: 7,
    label: "B2",
    parentId: 5,
    linkWith: null,
    measure: 200
  },
  {
    id: 8,
    label: "B3",
    parentId: 5,
    linkWith: null,
    measure: 500
  },
  {
    id: 9,
    label: "C",
    parentId: null,
    linkWith: null,
    measure: 300
  },
  {
    id: 10,
    label: "C1",
    parentId: 9,
    linkWith: null,
    measure: 50
  },
  {
    id: 11,
    label: "C2",
    parentId: 9,
    linkWith: null,
    measure: 250
  },
  {
    id: 12,
    label: "C3",
    parentId: 11,
    linkWith: null,
    measure: 70
  },
  {
    id: 13,
    label: "C4",
    parentId: 11,
    linkWith: null,
    measure: 180
  }
];

const configurationParameters = [
  {
    targetProperty: "rootName",
    label: "RootName",
    inputType: "textbox",
    inputOptions: { defaultValue: "ROOT" },
    desc: "desc94"
  },
  {
    targetProperty: "linkHightlight",
    label: "LinkHightlight",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "desc162"
  },
  {
    targetProperty: "collapsibility",
    label: "Collapsibility",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "desc162"
  },
  {
    targetProperty: "draggability",
    label: "Draggability",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "desc162"
  },
  {
    targetProperty: "treeHorizontal",
    label: "treeHorizontal",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc162"
  },
  {
    targetProperty: "treeRadius",
    label: "TreeRadius",
    inputType: "textbox",
    inputOptions: { defaultValue: 15 },
    desc: "desc94"
  },
  {
    targetProperty: "nodePadding",
    label: "NodePadding",
    inputType: "textbox",
    inputOptions: { defaultValue: 10 },
    desc: "desc94"
  },
  {
    targetProperty: "maxRadiusInPercent",
    label: "MaximumRadius",
    inputType: "textbox",
    inputOptions: { defaultValue: 10 },
    desc: "desc94"
  },
  {
    targetProperty: "maxRadiusInPx",
    label: "MaximumRadius",
    inputType: "textbox",
    inputOptions: { defaultValue: 30 },
    desc: "desc94"
  },
  {
    targetProperty: "maxRadiusUnit",
    label: "MaximumRadius",
    inputType: "textbox",
    inputOptions: { defaultValue: "percent" },
    desc: "desc94"
  },
  {
    targetProperty: "minRadiusInPercent",
    label: "MinimumRadius",
    inputType: "textbox",
    inputOptions: { defaultValue: 5 },
    desc: "desc94"
  },
  {
    targetProperty: "minRadiusInPx",
    label: "MinimumRadius",
    inputType: "textbox",
    inputOptions: { defaultValue: 15 },
    desc: "desc94"
  },
  {
    targetProperty: "minRadiusUnit",
    label: "MinimumRadius",
    inputType: "textbox",
    inputOptions: { defaultValue: "percent" },
    desc: "desc94"
  },
  {
    targetProperty: "tree",
    label: "forceDirectedTree",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc162"
  },
  {
    targetProperty: "condFormat",
    label: "CondFormat",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "desc162"
  },
  {
    targetProperty: "showHideButton",
    label: "Show Hide Button",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc230"
  },
  {
    targetProperty: "colours",
    label: "Colours",
    inputType: "palette",
    inputOptions: {
      defaultValue: "Flat-UI"
    },
    desc: "desc208"
  },
  {
    targetProperty: "toggleCriteria",
    label: "ToggleCriteria",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc59"
  },
  {
    targetProperty: "title",
    label: "Title",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc94"
  },
  {
    targetProperty: "summary",
    label: "Summary",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc61"
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    inputType: "textbox",
    inputOptions: { defaultValue: "rgb(255,255,255)" },
    desc: "desc62"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  },
  {
    targetProperty: "selectMultiple",
    label: "Select Multiple",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "desc230"
  },
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  }
];
const reactions = [
  {
    id: "filter",
    name: "Filter",
    description: "desc87",
    type: "general"
  }
];

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

const actions = [
  {
    trigger: "click",
    type: "click",
    name: "Click",
    output: ["id", "label", "parentId", "linkWith", "measure"],
    description: ""
  }
];

/**
 * NetworkGraph plugin component
 */
export default class NetworkGraph extends Component {
  constructor(props) {
    super(props);

    this.rerenderProcessStarted = false;
    this.callBackObject = {};
  }

  calculatePluginHeight = (plugin, settings) => {
    let pluginHeight = settings.grid.rowHeight * plugin.h; // multiply plugin.height and grid's row height
    let pluginTitleContainer = $("#title-" + plugin.id);
    let pluginContainerBorder = -2;
    let pluginMinHeightDifference = 30;
    let maxHeight =
      pluginHeight -
      (pluginMinHeightDifference +
        pluginTitleContainer.outerHeight() +
        parseInt(pluginTitleContainer.css("margin-bottom")) +
        pluginContainerBorder);
    return maxHeight;
  };

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({})

    return tmpCallBackObject;
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );
  }

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }

    return (
      <NetworkGraphData
        updateColumnMap={props.updatePlugin}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        didNotJoinedTables={checkTableJoins(this.props.join, this.props.plugin.columnMap, this.props.refreshedPluginId, this.props.plugin.id, true)}
        setInteractions={this.props.setInteractions}
        interactions={this.props.interactions}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={props.changeDoesPluginHasNotJoinedTable}
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        refreshPlugin={props.refreshPlugin}
        plugin={props.plugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  getConfigComponent = props => {
    if (props.config) {
      return (
        <NetworkGraphConfiguration
          config={{ ...props.config }}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
          currentAppliedConfig={this.props.currentAppliedConfig}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  /**
   * To set column map this plugin
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      id: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Id.Name"),
        type: "dim",
        minimumColumnSize: 1,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Id.Desc"),
        required: true,
        data: []
      },
      label: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Label.Name"),
        type: "any",
        minimumColumnSize: 0,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Label.Desc"),
        required: false,
        data: []
      },
      linkWith: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.LinkWith.Name"),
        type: "any",
        minimumColumnSize: 0,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.LinkWith.Desc"),
        required: false,
        data: []
      },
      parentId: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.ParentId.Name"),
        type: "any",
        minimumColumnSize: 0,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.ParentId.Desc"),
        required: false,
        data: []
      },
      measure: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Name"),
        minimumColumnSize: 1,
        type: "fact",
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Desc"),
        required: true,
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;

    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  /**
   * The Root element of the chart
   */
  root = undefined;

  /**
   * Sets plugin color theme
   * 
   * @param {*} config 
   */
  setTheme = config => {
    let myTheme = am5themes_Animated.new(this.root);
    let paletteColors = Array.isArray(config.colours) ? config.colours : InsightsConfig.Palettes[config.colours];
    let colorSet = paletteColors.map(color => am5.color(color));

    myTheme.rule("ColorSet").setAll({
      colors: colorSet,
      step: 1,
    });

    this.root.setThemes([myTheme]);
  }

  /**
   * Consists of each node.
   */
  nodesById = new Map();

  /**
   * Consists of each node for Interactions and Navigations.
   */
  nodesForFiltering = new Map();

  /**
   * Consists of each parent id with its children.
   */
  parentMap = new Map();

  /**
   * Consists of each parent node which contains its child nodes.
   */
  nestedNodes = new Map();

  /**
   * Fills node maps and creates data nodes array
   * 
   * @param {*} data 
   * @returns 
   */
  createData = (data, columnMap) => {
    // Clear node maps
    this.nodesById.clear();
    this.nodesForFiltering.clear();
    this.parentMap.clear();
    this.nestedNodes.clear();

    // Fill nodesById
    for (let part of data) {
      if (this.nodesById.has(part.id)) {
        let node = this.nodesById.get(part.id);

        // Set or update node's linked id list
        node.linkWith = Array.isArray(node.linkWith) ? node.linkWith : [node.linkWith] || [];
        node.linkWith = node.linkWith.concat(Array.isArray(part.linkWith) ? part.linkWith : [part.linkWith] || []);

        // Set node's label if it is not set
        if (!node.label) {
          node.label = part.label;
          node.tooltipLabel = part.label || "NULL";
        }

        // Set node's value if it is not set
        if (!node.measure) {
          node.measure = Number(part.measure);
        }

        // Update node
        this.nodesById.set(part.id, node);
      } else {
        // Create node
        this.nodesById.set(part.id, {
          id: part.id,
          label: part.label,
          tooltipLabel: part.label || "NULL",
          linkWith: [part.linkWith] || [],
          measure: Number(part.measure),
          parentId: part.parentId,
          children: part.children || []
        });
      }
    }

    // Copy nodesById map to nodesForFiltering
    this.nodesForFiltering = deepCopy(this.nodesById);

    // If node has not a label value, set the id as label value
    if (this.props.plugin.columnMap.label.data.length === 0) {
      for (let [id, node] of this.nodesById) {
        if (!node.label) {
          this.nodesById.set(id, {
            ...node,
            label: id,
            tooltipLabel: id
          });
        }
      }
    }

    // Add each node contains parent IDs to the parent map
    this.nodesById.forEach(node => {
      if (node.parentId) {
        if (!this.parentMap.has(node.parentId)) {
          this.parentMap.set(node.parentId, []);
        }

        this.parentMap.get(node.parentId).push(node);
      }
    });

    // Push each node contains parent IDs to its parent's children array
    for (let [parentId, children] of this.parentMap) {
      let parent = this.nodesById.get(parentId);

      if (parent) {
        parent.children.push(...children);
      }
    }

    // Copy nodesById to nestedNodes
    this.nestedNodes = deepCopy(this.nodesById);

    // Delete children nodes from root
    for (let [id, node] of this.nestedNodes) {
      if (node.parentId && this.nodesById.has(node.parentId)) {
        this.nestedNodes.delete(id);
      }
    }

    // Create a nested nodes array
    let nodes = Array.from(this.nestedNodes.values());

    // Return the nested nodes array
    return nodes;
  }

  /**
   * Collapses or expands the target node
   * 
   * @param {*} target 
   * @param {*} nodeId 
   * @param {*} config 
   * @param {*} status 
   */
  setNodeCollapse = (target, nodeId, config, status) => {
    let isNodeRoot = nodeId === config.rootName;
    let isNodeParent = this.parentMap.has(nodeId);

    if(config.collapsibility && (isNodeParent || isNodeRoot)) {
      target.set("disabled", status);
    }
  }

  /**
   * Plugin render process
   * 
   * @param {*} divId 
   * @param {*} data 
   * @param {*} columnMap 
   * @param {*} config 
   */
  pluginRender = (divId, data, columnMap, config) => {
    // If there a root element has been created before, dispose it.
    if (this.root !== undefined) {
      this.root.dispose();
    }

    // Create a new root element
    this.root = am5.Root.new(divId, {
      fps: 60
    });

    // Dispose amcharts logo
    this.root._logo.dispose();

    // Disable autoResize in order to avoid resizeObserver error
    this.root.autoResize = false;

    // Resize root when chart is ready
    this.root.events.on("frameended", () => this.root.resize());

    // Get the container which the chart sits on it
    let pluginBody = $("#" + divId)[0];

    // Set plugin color theme
    this.setTheme(config);

    // Create chart container
    let chartContainer = this.root.container.children.push(am5.Container.new(this.root, {}));

    // Set the chart container's width & height 
    chartContainer.setAll({
      width: am5.percent(100),
      height: am5.percent(100),
    });

    // General properties of series
    let seriesProperties = {
      valueField: "measure",
      categoryField: "label",
      childDataField: "children",
      idField: "id",
      linkWithField: "linkWith",
    };

    // Create series
    let series = chartContainer.children.push(
      config.tree
        ? am5hierarchy.Tree.new(this.root, seriesProperties)
        : am5hierarchy.ForceDirected.new(this.root, seriesProperties)
    );

    // Type specific proerties: Tree Chart & Network Graph
    if (config.tree) {
      let radius = Number(config.treeRadius);

      series.circles.template.setAll({
        radius: radius
      });

      series.outerCircles.template.setAll({
        radius: radius
      });

      series.setAll({
        orientation: config.treeHorizontal ? "horizontal" : "vertical"
      });
    } else {
      let nodePadding = Number(config.nodePadding);
      let maxRadius = config.maxRadiusUnit === "percent"
        ? am5.percent(Number(config.maxRadiusInPercent))
        : Number(config.maxRadiusInPx)
      let minRadius = config.minRadiusUnit === "percent"
        ? am5.percent(Number(config.minRadiusInPercent))
        : Number(config.minRadiusInPx)

      series.setAll({
        maxRadius: maxRadius,
        minRadius: minRadius,
        nodePadding: nodePadding
      });
    }

    // Set tooltip text
    series.nodes.template.set("tooltipText", "{tooltipLabel}: [bold]{sum}[/]");

    // Set node link appearance
    series.links.template.setAll({
      strokeWidth: 4,
      strokeOpacity: 0.3
    });


    // If linkHighlight oprion is true, highlight links of the active node.
    if (config.linkHighlight) {
      // Highlighted link appearance
      series.links.template.states.create("active", {
        strokeWidth: 5,
        strokeOpacity: 1
      });

      // Set nodes' pointer over event
      series.nodes.template.events.on("pointerover", event => {
        am5.array.each(event.target.dataItem.get("links"), function (link) {
          link.set("active", true);
        });
      });

      // Set nodes' pointer out event
      series.nodes.template.events.on("pointerout", event => {
        am5.array.each(event.target.dataItem.get("links"), function (link) {
          link.set("active", false);
        });
      });
    }

    // Set nodes' click event
    series.nodes.template.events.on("click", event => {
      let nodeId = event.target.dataItem.dataContext.id
      let dataContext = deepCopy(this.nodesForFiltering.get(nodeId));
      let isNodeDisabled = event.target.get("disabled");

      if (dataContext) {
        // Get the mouse position
        let mousePosition = { x: window.event.pageX, y: window.event.pageY }

        // Set datum for interactions & navigations
        let datum = deepCopy(dataContext);

        datum.linkWith = [];

        for (let link of dataContext.linkWith) {
          if (link) {
            datum.linkWith.push({ linkWith: link });
          }
        }

        // Set custom actions for click event
        let customActions = [];

        // If collapsibility is true and the node is a parent, add collapse action to customActions
        if (config.collapsibility && this.parentMap.has(nodeId)) {
          let collapseAction = {
            title: i18n.t(isNodeDisabled ? "Expand" : "Collapse"),
            icon: isNodeDisabled ? <i class="fas fa-expand-arrows-alt" /> : <i class="fas fa-compress-arrows-alt" />,
            trigger: () => this.setNodeCollapse(event.target, nodeId, config, !isNodeDisabled)
          }

          customActions.push(collapseAction);  
        }

        // Trigger event
        createTrigger(
          actions,
          columnMap,
          pluginBody,
          "click",
          datum,
          this.props.plugin.id,
          this.props.interactions,
          this.props.navigations,
          mousePosition,
          null,
          null,
          null,
          this.props.plugin,
          this.props.model,
          datum,
          null,
          customActions
        );
      } else {
        this.setNodeCollapse(event.target, nodeId, config, !isNodeDisabled);
      }
    });

    // Set default click event & cursor style
    series.nodes.template.setAll({
      toggleKey: "none",
      cursorOverStyle: "default"
    });

    // Create series data
    let seriesData = this.createData(data, columnMap);

    // If there is more than one node, create a root node contains each node as a child
    if (seriesData.length > 1) {
      seriesData = [{
        id: config.rootName,
        label: config.rootName,
        tooltipLabel: config.rootName,
        children: seriesData
      }];
    }

    // Set series data
    series.data.setAll(seriesData);

    // If tree chart and show root options are not active, hide the root node
    if (config.tree !== true && config.showRoot !== true) {
      // The first node of series
      let firstNode = series.nodes.getIndex(0);

      // If the first node is the root node, hide it. 
      if (firstNode.dataItem.dataContext.id === config.rootName) {
        firstNode.hide();
      }
    }

    // Draggable nodes
    series.nodes.template.setAll({
      draggable: config.draggability,
    });

    // Set plugin renderer
    this.props.setPluginRerender(false, this.props.plugin.id, false, this.props.plugin.isInteraction);
  };

  /**
   * Returns black or white color based on the given RGB color
   * 
   * @param {*} colorRGB 
   * @returns 
   */
  getContrastColor = (colorRGB) => {
    const yiq = (colorRGB.r * 299 + colorRGB.g * 587 + colorRGB.b * 114) / 1000;

    return yiq >= 128 ? "#000000" : "#FFFFFF"
  };

  /**
   * Returns NavigationContent component
   * @returns 
   */
  getNavigationComponent = () => {
    return (
      <NavigationContent
        navigations={this.props.navigations}
        setNavigations={this.props.updatePlugin}
        plugin={this.props.plugin}
        dashboardInformation={this.props.dashboardInformation}
      />
    );
  };

  lastContent = undefined;

  updateLastContent = (status) => {
    this.lastContent = status
  };

  render() {
    // Configuration component
    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    // Data component
    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    // Navigation Component
    let navigationComponent = null;
    if (this.props.navigationComponentVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      navigationComponent = renderNavigation(
        popupPosition,
        this.props,
        this.getNavigationComponent
      );
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };
    
    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + this.props.plugin.id).css("padding")
      );

      pluginConfig.height =
        this.calculatePluginHeight(this.props.plugin, this.props.settings) -
        pluginContainerPadding * 2;

      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      );
    }

    return (
      <>
        <div style={{height: "100%"}}>
          <div id={this.props.plugin.id} style={{ height: calculatePluginInlineHeight(this.props.plugin.id) }} />
          {
          renderContent(
            isRerender,
            this.pluginRender,
            this.props.plugin,
            data,
            columnMap,
            pluginConfig,
            this.props.plugin.conditionalFormats,
            this.props.setPluginRerender,
            this.lastContent,
            this.updateLastContent,
          )
        }
        {configComponent}
        {dataComponent}
        {navigationComponent}
        </div>
      </>
    );
  }
}
