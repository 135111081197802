import React, { Component } from "react";
import Text from "../../../../GeneralComponents/Text/Text";
import { Row, Col, Input, Select, Checkbox, Divider, Button, Tooltip } from "antd";
import { FontSizeOutlined, EyeInvisibleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import i18n from "../../../../../Utils/i18next";
import $ from "jquery"
import FilterOperators from "../../GlobalFilter/FilterOperators";
import FilterOperatorsForStringFilter from "../../GlobalFilter/FilterOperatorsForStringFilter";
import TitleSettingsOfPlugin from "../../../../GeneralComponents/TitleSettingsOfPlugin/TitleSettingsOfPlugin";

const { TextArea } = Input
const { Option } = Select;

/**
 * Filter configuration others category component
 */
export default class Others extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMulti: this.props.config.isMulti,
      showOperatorOptions: this.props.config.showOperatorOptions === undefined ? true : this.props.config.showOperatorOptions,
      showDefaultFilterIcon: this.props.config.showDefaultFilterIcon === undefined ? true : this.props.config.showDefaultFilterIcon,
      useTextBox: this.props.config.useTextBox,
      filterDefaultOperator: this.props.config.filterDefaultOperator,
      title: this.props.config.title,
      width: this.props.config.width,
      titleAlign: this.props.config.titleAlign,
      titleFontStyle: this.props.config.titleFontStyle === undefined ? false : this.props.config.titleFontStyle,
      titleFontWeight: this.props.config.titleFontWeight === undefined ? false : this.props.config.titleFontWeight,
      titleFontSize: this.props.config.titleFontSize === undefined ? 16 : this.props.config.titleFontSize,
      titleFont: this.props.config.titleFont === undefined ? "Arial" : this.props.config.titleFont,
      titleColour: this.props.config.titleColour === undefined ? "black" : this.props.config.titleColour,
      titleTextDecor: this.props.config.titleTextDecor === undefined ? false : this.props.config.titleTextDecor,
      changedTitleFontSize: this.props.config.changedTitleFontSize === undefined ? 16 : this.props.config.changedTitleFontSize,
      keyForTitleSize: null,
      applyButtonText: this.props.config.applyButtonText,
      applyButtonBackgroundColor: this.props.config.applyButtonBackgroundColor,
      applyButtonTextColor: this.props.config.applyButtonTextColor,
      dropdownAppearance: this.props.config.dropdownAppearance === undefined ? false : this.props.config.dropdownAppearance,
      operatorsForStringFilter: this.props.config.operatorsForStringFilter == undefined ? "=" : this.props.config.operatorsForStringFilter,
      noDataTitle: this.props.config.noDataTitle === undefined ? "" : this.props.config.noDataTitle
    };
  }

  /**
   * Plugin compenents receive it's created properties
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      this.setState({
        isMulti: nextProps.config.isMulti,
        useTextBox: nextProps.config.useTextBox,
        filterDefaultOperator: nextProps.config.filterDefaultOperator,
        title: nextProps.config.title,
        width: nextProps.config.width,
        titleAlign: nextProps.config.titleAlign,
        titleFont: nextProps.config.titleFont === undefined ? "Arial" : nextProps.config.titleFont,
        titleFontSize: nextProps.config.titleFontSize === undefined ? 16 : nextProps.config.titleFontSize,
        changedTitleFontSize: nextProps.config.changedTitleFontSize === undefined ? 16 : nextProps.config.changedTitleFontSize,
        titleFontStyle: nextProps.config.titleFontStyle === undefined ? false : nextProps.config.titleFontStyle,
        titleFontWeight: nextProps.config.titleFontWeight === undefined ? false : nextProps.config.titleFontWeight,
        titleTextDecor: nextProps.config.titleTextDecor === undefined ? false : nextProps.config.titleTextDecor,
        titleColour: nextProps.config.titleColour === undefined ? "black" : nextProps.config.titleColour,
        showOperatorOptions: nextProps.config.showOperatorOptions === undefined ? true : nextProps.config.showOperatorOptions,
        showDefaultFilterIcon: nextProps.config.showDefaultFilterIcon === undefined ? true : nextProps.config.showDefaultFilterIcon,
        applyButtonText: nextProps.config.applyButtonText,
        applyButtonBackgroundColor: nextProps.config.applyButtonBackgroundColor,
        applyButtonTextColor: nextProps.config.applyButtonTextColor,
        dropdownAppearance: nextProps.config.dropdownAppearance === undefined ? false : nextProps.config.dropdownAppearance,
        operatorsForStringFilter: nextProps.config.operatorsForStringFilter == undefined ? "=" : nextProps.config.operatorsForStringFilter,
        noDataTitle: nextProps.config.noDataTitle === undefined ? "" : nextProps.config.noDataTitle
      });
    }
  }

  //sets defaults we get from plugin.jsx. callback for fontsizekey because it should keep changing to trigger default revert
  setDefaultsForTitle = () => {
    let newConfig = this.props.setDefaultForPluginTitle();

    this.setState(
      {
        ...this.state,
        title: newConfig.title,
        titleAlign: newConfig.titleAlign,
        titleFont: newConfig.titleFont,
        titleFontSize: newConfig.titleFontSize,
        titleFontStyle: newConfig.titleFontStyle,
        titleFontWeight: newConfig.titleFontWeight,
        titleTextDecor: newConfig.titleTextDecor,
        titleColour: newConfig.titleColour,
        changedTitleFontSize: newConfig.titleFontSize
      }, () => {
        this.setConfigsToPlugin({ type: "changedTitleFontSize", value: newConfig.titleFontSize }, this.props.config)
      }
    );

    if (newConfig.titleFontSize > 10 || newConfig.titleFontSize < 30) {
      $("#titleFontSize").css("background", "")
    }
  }

  /**
  * Updates state attributes in Filter plugin
  */
  setConfigsToPlugin = (changedObj, config, propertyListToDelete = undefined) => {
    let tempState = { ...config };

    if (Array.isArray(changedObj)) {
      for (let i = 0; i < changedObj.length; i++) {
        let object = changedObj[i];
        tempState[object.type] = object.value;
      }
    } else {
      tempState[changedObj.type] = changedObj.value;
    }

    if (propertyListToDelete !== undefined) {
      for (let item of propertyListToDelete) {
        delete tempState[item]
      }
    }

    this.props.updateConfig(
      tempState,
      this.props.pluginId,
      propertyListToDelete
    );
  };

  /*
  * Changes both of constant and changed value 
  * constant = between 10 and 30 values
  * changed value = all of values
  */
  handleChangeBothOfValues = (constantValue, changedValue) => {
    this.setState({
      ...this.state,
      titleFontSize: constantValue,
      changedTitleFontSize: changedValue
    })

    this.setConfigsToPlugin(
      [
        {
          type: "titleFontSize",
          value: constantValue
        },
        {
          type: "changedTitleFontSize",
          value: changedValue
        }
      ], this.props.config);
  }

  titleColourChangeVisible = () => {
    this.setState({
      ...this.state,
      titleColourChange: !this.state.titleColourChange
    });
  };

  /**
   * Changes the icon attribute in Filter plugin use iconOnChange
   */
  iconOnChange = value => {
    this.setState({
      ...this.state,
      icon: value
    });

    this.props.setConfigsToPlugin({ type: "icon", value: value }, this.props.config);
  };

  /**
   * Changes the title attribute in Filter plugin use titleAlignOnChange
   */
  titleAlignOnChange = value => {
    this.setState({
      ...this.state,
      titleAlign: value
    });

    this.props.setConfigsToPlugin({ type: "titleAlign", value: value }, this.props.config);
  };

  /**
   * Changes the title attribute in Filter plugin use titleAlignOnChange
   */
  filterDefaultOperatorOnChange = value => {
    this.setState({
      ...this.state,
      filterDefaultOperator: value
    });

    this.props.setConfigsToPlugin({
      type: "filterDefaultOperator",
      value: value
    }, this.props.config);
  };

  /*
  * Triggers when string filter operator changes
  */
  operatorsForStringFilterOnChange = value => {    
    this.setState({
      ...this.state,
      operatorsForStringFilter: value
    });

    this.props.setConfigsToPlugin({
      type: "operatorsForStringFilter",
      value: value
    }, this.props.config);
  };

  /**
   * Checks use text box. If its true, multiple value checks set false
   */
  handleCheckTextBox = e => {
    let checked = e.target.checked;
    let tempState = { ...this.state };

    tempState["useTextBox"] = checked;

    if (checked === true) {
      tempState["isMulti"] = false;
    }

    this.setState(tempState);

    this.props.setConfigsToPlugin([
      {
        type: "useTextBox",
        value: tempState["useTextBox"]
      },
      {
        type: "isMulti",
        value: tempState["isMulti"]
      }
    ], this.props.config);
  };

  /**
   * Checks is multi. If its true, use text box value checks set false
   */
  handleCheckIsMulti = e => {
    let checked = e.target.checked;
    let tempState = { ...this.state };

    tempState["isMulti"] = checked;

    if (checked === true) {
      tempState["useTextBox"] = false;
    }

    this.setState(tempState);

    this.props.setConfigsToPlugin([
      {
        type: "useTextBox",
        value: tempState["useTextBox"]
      },
      {
        type: "isMulti",
        value: tempState["isMulti"]
      }
    ], this.props.config);
  };

  /**
   * Checks whether checkbox is checked or not in Filter plugin use handleCheck
   */
  handleCheck = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked
    });

    this.props.setConfigsToPlugin({
      type: e.target.id,
      value: e.target.checked
    }, this.props.config);
  };

  /**
   * Changes the value of text attributes in Filter plugin use handleChange
   */
  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    });

    this.props.setConfigsToPlugin({ type: e.target.id, value: e.target.value }, this.props.config);
  };

  /**
   * added text color, arrow color and placeholder function
   */
  applyButtonBackgroundColorOnChange = obj => {
    this.setState({
      ...this.state,
      applyButtonBackgroundColor: obj.color
    });

    this.props.setConfigsToPlugin({
      type: "applyButtonBackgroundColor",
      value: obj.color
    }, this.props.config);
  };

  /**
   * Changes apply button background color visibility
   */
  applyButtonBackgroundColorChangeVisible = () => {
    this.setState({
      ...this.state,
      applyButtonBackgroundColorChange: !this.state
        .applyButtonBackgroundColorChange
    });
  };

  /**
   * Added text color, arrow color and placeholder function
   */
  applyButtonTextColorOnChange = obj => {
    this.setState({
      ...this.state,
      applyButtonTextColor: obj.color
    });

    this.props.setConfigsToPlugin({
      type: "applyButtonTextColor",
      value: obj.color
    }, this.props.config);
  };

  /**
   * Changes apply button text color visibility
   */
  applyButtonTextColorChangeVisible = () => {
    this.setState({
      ...this.state,
      applyButtonTextColorChange: !this.state.applyButtonTextColorChange
    });
  };

  render() {
    const { configurationTemplate, configurationCategoryTemplate } = this.props;

    return (
      <div>
        <TitleSettingsOfPlugin
          plugin={this.props.plugin}
          handleChange={this.handleChange}
          commonTitleConfig={this.props.commonTitleConfig}
          updateCommonTitleConfig={this.props.updateCommonTitleConfig}
          isReturnToDefaultforTitleVisible={this.props.isReturnToDefaultforTitleVisible}
          setDefaultsForTitle={this.setDefaultsForTitle}
          title={this.state.title}
          titleAlign={this.state.titleAlign}
          titleColour={this.state.titleColour}
          titleColourChange={this.state.titleColourChange}
          titleColourChangeVisible={this.titleColourChangeVisible}
          titleFont={this.state.titleFont}
          titleFontSize={this.state.titleFontSize}
          keyForTitleSize={this.state.keyForTitleSize}
          titleFontStyle={this.state.titleFontStyle}
          titleFontWeight={this.state.titleFontWeight}
          titleTextDecor={this.state.titleTextDecor}
          setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
          currentAppliedConfig={this.props.currentAppliedConfig}
          changedTitleFontSize={this.state.changedTitleFontSize}
          handleChangeBothOfValues={this.handleChangeBothOfValues}
        />

        {configurationCategoryTemplate.has("others") ? (
          <Divider
            key={"others"}
            className={"config-divider"}
            orientation="left"
          >
            {i18n.t("Dashboard.Configuration.Fields.Others")}
          </Divider>
        ) : null}

        {configurationTemplate.has("filterDefaultOperator") ? (
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.FilterDefaultOperator")}
              </Text>
            </Col>
            <Col span={12}>
              <FilterOperators
                defaultValue={
                  configurationTemplate.get("filterDefaultOperator")
                    .defaultValue
                }
                value={this.state.filterDefaultOperator}
                setFilterOperator={this.filterDefaultOperatorOnChange}
                style={{ width: "100%" }}
                pluginId={this.props.pluginId}
              />
            </Col>
          </Row>
        ) : null}

        {configurationTemplate.has("operatorsForStringFilter") ? (
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.FilterDefaultOperator")}
              </Text>
            </Col>
            <Col span={12}>
              <FilterOperatorsForStringFilter
                defaultValue={
                  configurationTemplate.get("operatorsForStringFilter")
                    .operatorsForStringFilter
                }
                value={this.state.operatorsForStringFilter}
                setFilterOperator={this.operatorsForStringFilterOnChange}
                style={{ width: "100%" }}
                pluginId={this.props.pluginId}
              />
            </Col>
          </Row>
        ) : null}
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.ShowOperatorOptions")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"showOperatorOptions"}
                defaultChecked={this.state.showOperatorOptions}
                checked={this.state.showOperatorOptions}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.ShowDefaultFilterIcon")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"showDefaultFilterIcon"}
                defaultChecked={this.state.showDefaultFilterIcon}
                checked={this.state.showDefaultFilterIcon}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>
        {configurationTemplate.has("useTextBox") ? (
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.UserTextBox")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"useTextBox"}
                defaultChecked={this.state.useTextBox}
                checked={this.state.useTextBox}
                onChange={this.handleCheckTextBox}
              ></Checkbox>
            </Col>
          </Row>
        ) : null}

        {configurationTemplate.has("useTextBox") && this.state.useTextBox === true ? (
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.ApplyButtonText")}
              </Text>
            </Col>
            <Col span={12}>
              <Input
                id={"applyButtonText"}
                defaultValue={this.state.applyButtonText}
                value={this.state.applyButtonText}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
        ) : null}

        {configurationTemplate.has("useTextBox") && this.state.useTextBox === true ? (
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t(
                  "Dashboard.Configuration.Fields.ApplyButtonBackgroundColor"
                )}
              </Text>
            </Col>
            <Col span={12}>
              <Button
                onClick={this.applyButtonBackgroundColorChangeVisible}
                style={{ width: "100%" }}
              >
                {this.state.applyButtonBackgroundColorChange
                  ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                  : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
              </Button>
              {this.state.applyButtonBackgroundColorChange ? (
                <ColorPickerPanel
                  alpha={100}
                  color={this.state.applyButtonBackgroundColor}
                  onChange={this.applyButtonBackgroundColorOnChange}
                  id={"applyButtonBackgroundColor"}
                  style={{ marginTop: "5px", position: "absolute", right: 0 }}
                />
              ) : null}
            </Col>
          </Row>
        ) : null}

        {configurationTemplate.has("useTextBox") && this.state.useTextBox === true ? (
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.ApplyButtonTextColor")}
              </Text>
            </Col>
            <Col span={12}>
              <Button
                onClick={this.applyButtonTextColorChangeVisible}
                style={{ width: "100%" }}
              >
                {this.state.applyButtonTextColorChange
                  ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                  : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
              </Button>
              {this.state.applyButtonTextColorChange ? (
                <ColorPickerPanel
                  alpha={100}
                  color={this.state.applyButtonTextColor}
                  onChange={this.applyButtonTextColorOnChange}
                  id={"applyButtonTextColor"}
                  style={{ marginTop: "5px", position: "absolute", right: 0 }}
                />
              ) : null}
            </Col>
          </Row>
        ) : null}

        {configurationTemplate.has("isMulti") ? (
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.IsMulti")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"isMulti"}
                defaultChecked={this.state.isMulti}
                checked={this.state.isMulti}
                onChange={this.handleCheckIsMulti}
              ></Checkbox>
            </Col>
          </Row>
        ) : null}
        {configurationTemplate.has("dropdownAppearance") ? (
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.DropdownAppearance")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"dropdownAppearance"}
                defaultChecked={this.state.dropdownAppearance}
                checked={this.state.dropdownAppearance}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>
        ) : null}
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left", display: "flex" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Settings.NoDataConfiguration")}
            </Text>
            <Tooltip 
              title={
                <>
                {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Info")} <br />
                {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.GraphicConfiguration")} <br />
                {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Settings")} <br />
                {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.DefaultText")} <br />
                </>
              }
            >
              <InfoCircleOutlined style={{ position: 'relative', marginTop: '5px', marginLeft: "10px" }} /> 
            </Tooltip>
          </Col>
          <Col span={12}>
            <TextArea
              allowClear
              id={"noDataTitle"}
              defaultValue={this.state.noDataTitle}
              value={this.state.noDataTitle}
              onChange={this.handleChange}
              autoSize={{ minRows: 3, maxRows: 5 }}
              placeholder={i18n.t("NoDataContent.NoData") + " " +
                i18n.t("NoDataContent.PluginCouldNotBeVisualized")}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
