import { PLUGIN_LOADER_VISIBILITY  } from "./PluginLoaderAction";

/**
 *
 * @param {*} state
 * @param {*} action
 * Reducer for plugin loader visibility
 *
 */
export default function PluginLoaderReducer(
  state = {
    waitForLoadPlugins: new Set(),
  },
  action
) {
  switch (action.type) {
    case PLUGIN_LOADER_VISIBILITY:
      return {
        ...state,
        waitForLoadPlugins: action.payload.waitForLoadPlugins,
      };
    default:
      return state;
  }
}
