export const LOGIN_POPUP_VISIBILITY = "LOGIN_POPUP_VISIBILITY";

/**
 * 
 * @param {*}
 * @returns 
 * 
 * Sets login popup visibility
 */
export function changePopupVisibility(status, callback) {
  let statusAndCallback = {status, callback}
  return (dispatch) => {
    dispatch({
      type: LOGIN_POPUP_VISIBILITY,
      payload: statusAndCallback,
    });
  };
}
