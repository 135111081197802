import React, { Component } from "react";
import { Table, Button, Popconfirm, Empty } from "antd";
import i18n from "../../../Utils/i18next";
import { connect } from "react-redux";
import { setSessionVariableContent } from "./SessionVariableAction";

class SessionVariableTable extends Component {
    render() {
        const columns = [
            {
                title: i18n.t("SessionVariables.SessionName"),
                dataIndex: "name",
                key: "name",
                width: "calc(50% - 50px)",
                sortDirections: ['ascend', 'descend'],
            },
            {
                title: i18n.t("SessionVariables.SessionDefaultVariable"),
                dataIndex: "defaultValue",
                key: "defaultValue",
                width: "calc(50% - 500px)",
                sortDirections: ['ascend', 'descend'],
            },
            {
                title: i18n.t("Actions"),
                key: "Action",
                width: "100px",
                align: "center",
                render: (text, record) => (
                    <div
                        style={{
                            width: "100%",
                            height: "fit-content",

                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <Button
                            id={`button-update-${record._id}`}
                            onClick={() => this.props.handleEditSessionVariable(record)}
                            ghost
                            type="primary"
                            icon="edit"
                            size="small"
                            shape="circle"
                            style={{
                                fontSize: 12,
                                marginRight: 5,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        />
                        <Popconfirm
                            title={i18n.t("SessionVariables.AreYouSureDeleteSessionVariable")}
                            onConfirm={() => {
                                this.props.deleteSessionVariable(record);
                            }}
                            okText={i18n.t("Yes")}
                            okButtonProps={{ id: "deleteJoinAcceptButton" }}
                            cancelText={i18n.t("No")}
                            cancelButtonProps={{ id: "deleteJoinCancelButton" }}
                        >
                            <Button
                                id={`button-delete-${record._id}`}
                                ghost
                                type="danger"
                                icon="delete"
                                size="small"
                                shape="circle"
                                style={{
                                    fontSize: 12,
                                    marginLeft: 5,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            />
                        </Popconfirm>
                    </div>
                )
            }
        ];

        return (
            <Table
                columns={columns}
                dataSource={Array.from(this.props.sessionVariables.values())}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                size="middle"
                bordered
                tableLayout="fixed"
                locale={{
                    emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={i18n.t("JoinTable.NoData")} />
                }}
            />
        );
    }
}

/**
 * Set action method to props
 */
const mapDispatchToProps = {
    setSessionVariableContent,
};

/**
 * Set store objects to state
 *
 * @param {*} state
 * @returns
 */
const mapStateToProps = (state) => {
    return {
        sessionVariables: state.SessionVariableReducer.sessionVariables
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionVariableTable);