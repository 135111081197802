import React, { Component } from "react";
import "./databaseStyle.css"
import { Input, Col, Row, Button } from "antd";
import { post, put } from "../../../Utils/WebService";
import { store } from "../../..";
import { setComesFromDashboardFileUpload, setDataSourceSelfUtils } from "../SelfServiceReducer/SelfServiceAction";
import i18n from "../../../Utils/i18next";
import { showNotificationWithIcon } from "../../../Utils/Notification";

/*
* Self Service Connection Component
*/
export default class SelfServiceConnection extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.datasourceEditMode !== nextProps.datasourceEditMode && nextProps.datasourceEditMode) {
            this.props.onChangeDataSourceName(nextProps.editedSelfService.title)
            this.props.setVisibleDataUploadStatus(true)
        }
    }

    /*
    * Creates Duck - Adı Değiştirilecek
    */
    createDuck = () => {
        let dataSourceName = this.props.dataSourceName === null || this.props.dataSourceName === "" || this.props.dataSourceName === undefined ? "Vispeahen" : this.props.dataSourceName
        let url = "/api/data/create-workspace/" + dataSourceName
        let postObj = {}
        var THIS = this

        let successFunc = (res) => {
            let data = {
                dataSourceId: res.data,
                openFileUpload: true,
                firstDataUpload: false
            }

            this.props.onChangeDataSourceName("")
            THIS.props.setVisibleDataUploadStatus(false)

            store.dispatch(setDataSourceSelfUtils(data))
            store.dispatch(setComesFromDashboardFileUpload(false))
        }

        let errorFunc = (err) => {
            console.log(err)
        }

        let name = this.props.dataSourceName
        let isDatasourceHasUniqueChar = name.includes('"') ||
            name.includes(".") ||
            name.includes("”") ||
            name.includes("'") ||
            name.includes("%") ||
            name.includes("&") ||
            name.includes("[") ||
            name.includes("]") ||
            name.includes("|") ||
            name.includes("?") ||
            name.includes("=") ||
            name.includes("/") ||
            name.includes("+") ||
            name.includes("^")

        if (isDatasourceHasUniqueChar) {
            showNotificationWithIcon(
                i18n.t("Error"),
                i18n.t("FileUpload.TableNameCouldNotIncludesSpecialChars"),
                "error"
            );
        } else {
            post(url, postObj, successFunc, errorFunc)
        }
    }

    /*
    * Updates duck
    */
    updateDuck() {
        let url = "/api/data/update-workspace"
        let editObj = {
            displayName: this.props.editedSelfService.title,
            ds_key: this.props.editedSelfService.name,
            displayName: this.props.dataSourceName
        }

        let successFunc = (res) => {
            this.props.onChangeDataSourceName("")
            this.props.setVisibleDataUploadStatus(false, true)
        }


        let errorFunc = (err) => {
            console.log(err)
        }

        let name = this.props.dataSourceName
        let isDatasourceHasUniqueChar = name.includes('"') ||
            name.includes(".") ||
            name.includes("”") ||
            name.includes("'") ||
            name.includes("%") ||
            name.includes("&") ||
            name.includes("[") ||
            name.includes("]") ||
            name.includes("|") ||
            name.includes("?") ||
            name.includes("=") ||
            name.includes("/") ||
            name.includes("+") ||
            name.includes("^")

        if (isDatasourceHasUniqueChar) {
            showNotificationWithIcon(
                i18n.t("Error"),
                i18n.t("FileUpload.TableNameCouldNotIncludesSpecialChars"),
                "error"
            );
        } else {
            put(url, editObj, successFunc, errorFunc)
        }
    }

    closeDataSourceMenu = () => {
        this.props.onChangeDataSourceName("")
        this.props.justCloseDataUploadStatus()
    }

    render() {
        return (
            <div className="self-service-container">
                <Row>
                    <span style={{ color: "#a2a2a2", marginBottom: "7px" }}>{i18n.t("SelfService.YouCanNameTheCollection")}</span>
                </Row>
                <Row>
                    <span style={{ color: "#a2a2a2", marginBottom: "7px" }}>{i18n.t("SelfService.YouCanStoreTheCollection")}</span>
                </Row>
                <Row style={{ marginBottom: "15px" }}>
                    <span style={{ color: "#a2a2a2" }}>{i18n.t("SelfService.YouCanEditCollection")}</span>
                </Row>

                <Row className="self-service-row">
                    <Col span={8} style={{ height: "32px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <span style={{ color: "#494949" }}>{i18n.t("SelfService.CollectionName")}</span>
                    </Col>
                    <Col span={16}>
                        <Input
                            value={this.props.dataSourceName}
                            onChange={(e) => this.props.onChangeDataSourceName(e.target.value)}
                            className="self-service-input"
                            style={{ width: "100%" }}
                            placeholder="Vispeahen"></Input>
                    </Col>
                </Row>
                <Row className="self-service-row buttons" style={{ justifyContent: "flex-end" }}>
                    <Col span={4}>
                        <Button style={{ width: "100%" }} onClick={() => this.closeDataSourceMenu()}>
                            {i18n.t("Close")}
                        </Button>
                    </Col>
                    <Col span={4} style={{ marginLeft: "10px" }}>
                        <Button
                            style={{ width: "100%" }}
                            onClick={() => this.props.datasourceEditMode ? this.updateDuck() : this.createDuck()}>
                            {this.props.datasourceEditMode ? i18n.t("Update") : i18n.t("Create")}
                        </Button>
                    </Col>
                </Row>
                {this.props.dataSourceName === "Baki ve arkadaŞları" ?
                    <Row>
                        <img style={{ maxWidth: "100%", height: "350px", marginTop: "20px" }} src="https://i.imgur.com/wO2b98o.jpg" alt="" />
                    </Row> : null}

            </div>
        );
    }
}
