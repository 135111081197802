import React, { Component } from "react";
import { Table, Checkbox, Button } from "antd";
import Column from "antd/lib/table/Column";
import { post } from "../Utils/WebService";
import { API_BASE } from "../config";
import { showNotificationWithIcon } from "../Utils/Notification";

const accessTypes = { role: "ROLE", user: "USER" };

class RoleList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roleList: [],
      userList: [],
      selectedRoleID: "",
      dataType: "",
      dataList: [],
      selectedData: [],
      postObject: []
    };
  }

  componentWillReceiveProps(nextProps) {
    let dataType = "";

    if (nextProps.currentAccessType == accessTypes.role) {
      dataType = "name";
    } else if (nextProps.currentAccessType == accessTypes.user) {
      dataType = "username";
    }

    if (nextProps.selectedData != this.props.selectedData) {
      this.checkBoxFillBySelectedData(nextProps.selectedData);
    }

    this.setState({
      dataType: dataType,
      dataList: nextProps.data
    });
  }

  checkBoxFillBySelectedData = data => {
    let dataWithActionAttrs = [];

    this.state.dataList.map(item => {
      let readIndex = this.findObjectWithAccessTypeId(
        data,
        ["accessTypeId", "actionOnObject"],
        [item.id, "READ"]
      );

      let writeIndex = this.findObjectWithAccessTypeId(
        data,
        ["accessTypeId", "actionOnObject"],
        [item.id, "WRITE"]
      );

      let notReadIndex = this.findObjectWithAccessTypeId(
        data,
        ["accessTypeId", "actionOnObject"],
        [item.id, "NOT_READ"]
      );

      let notWriteIndex = this.findObjectWithAccessTypeId(
        data,
        ["accessTypeId", "actionOnObject"],
        [item.id, "NOT_WRITE"]
      );

      if (readIndex != -1) {
        item.read = {
          checked: true,
          changed: false,
          userRightOnObjectId: data[readIndex].id
        };
      } else {
        item.read = { checked: false, changed: false };
      }

      if (writeIndex != -1) {
        item.write = {
          checked: true,
          changed: false,
          userRightOnObjectId: data[writeIndex].id
        };
      } else {
        item.write = { checked: false, changed: false };
      }

      if (notReadIndex != -1) {
        item.notRead = {
          checked: true,
          changed: false,
          userRightOnObjectId: data[notReadIndex].id
        };
      } else {
        item.notRead = { checked: false, changed: false };
      }

      if (notWriteIndex != -1) {
        item.notWrite = {
          checked: true,
          changed: false,
          userRightOnObjectId: data[notWriteIndex].id
        };
      } else {
        item.notWrite = { checked: false, changed: false };
      }

      dataWithActionAttrs.push(item);
    });

    this.setState({
      selectedData: [...data],
      dataList: [...dataWithActionAttrs]
    });
  };

  findObjectWithAccessTypeId = (arr, properties, value) => {
    let accessTypeId = properties[0];
    let actionOnObject = properties[1];

    for (var i = 0; i < arr.length; i++) {
      if (
        arr[i][accessTypeId] == value[0] &&
        arr[i][actionOnObject] == value[1]
      ) {
        return i;
      }
    }

    return -1;
  };

  handleCheckedChange = e => {
    let dataWithActionAttrs = [];

    this.state.dataList.map(item => {
      if (item.id == e.target.value[1]) {
        switch (e.target.value[0]) {
          case "READ":
            item.read = {
              ...item.read,
              checked: !item.read.checked,
              changed: !item.read.changed
            };
            break;
          case "WRITE":
            item.write = {
              ...item.write,
              checked: !item.write.checked,
              changed: !item.write.changed
            };
            break;
          case "NOT_READ":
            item.notRead = {
              ...item.notRead,
              checked: !item.notRead.checked,
              changed: !item.notRead.changed
            };
            break;
          case "NOT_WRITE":
            item.notWrite = {
              ...item.notWrite,
              checked: !item.notWrite.checked,
              changed: !item.notWrite.changed
            };
            break;
        }
      }

      dataWithActionAttrs.push(item);
    });

    this.setState({
      dataList: [...dataWithActionAttrs]
    });
  };

  insertAndDeleteObject = {};

  saveActions = () => {
    this.insertAndDeleteObject = { insertList: [], deleteList: [] };

    let userRightOnObject = {
      id: null,
      accessTypeId: null,
      accessType: this.props.currentAccessType,
      objectId: this.props.currentCatalogObjectId,
      actionOnObject: null
    };

    this.state.dataList.map(item => {
      userRightOnObject.accessTypeId = item.id;

      //READ action
      if (item.read.changed === true) {
        userRightOnObject.actionOnObject = "READ";
        this.isInsertOrDelete(item.read, userRightOnObject);
      }

      //WRITE action
      if (item.write.changed === true) {
        userRightOnObject.actionOnObject = "WRITE";
        this.isInsertOrDelete(item.write, userRightOnObject);
      }

      //NOT_READ action
      if (item.notRead.changed === true) {
        userRightOnObject.actionOnObject = "NOT_READ";
        this.isInsertOrDelete(item.notRead, userRightOnObject);
      }

      //NOT_WRITE action
      if (item.notWrite.changed === true) {
        userRightOnObject.actionOnObject = "NOT_WRITE";
        this.isInsertOrDelete(item.notWrite, userRightOnObject);
      }
    });

    this.postInsertAndDeleteObject(this.insertAndDeleteObject);
  };

  isInsertOrDelete = (obj, userRightOnObject) => {
    switch (obj.checked) {
      case true:
        this.insertAndDeleteObject.insertList.push({ ...userRightOnObject });
        break;
      case false:
        userRightOnObject.id = obj.userRightOnObjectId;
        this.insertAndDeleteObject.deleteList.push({ ...userRightOnObject });
        userRightOnObject.id = null;
        break;
    }
  };

  postInsertAndDeleteObject = obj => {
    let url = `${API_BASE}/userRight/`;
    let requestBody = obj;

    const successFunc = result => {
      showNotificationWithIcon("OK", "Successfully saved.", "success");
      this.props.confirm();
    };

    const errorFunc = error => {
      showNotificationWithIcon("ERROR!", error.message, "error");
    };

    post(url, requestBody, successFunc, errorFunc);
  };

  render() {
    return (
      <div>
        <Table pagination={{ pageSize: 7 }} dataSource={this.state.dataList}>
          <Column
            style={{ fontWeight: "bold" }}
            title={this.props.currentAccessType}
            dataIndex={this.state.dataType}
            key={this.state.dataType}
          />
          <Column
            title="READ"
            dataIndex="read"
            key="read"
            render={(text, record) => {
              return (
                <Checkbox
                  value={["READ", record.id]}
                  onChange={this.handleCheckedChange}
                  checked={
                    record.read != undefined ? record.read.checked : false
                  }
                />
              );
            }}
          />
          <Column
            title="WRITE"
            dataIndex="write"
            key="write"
            render={(text, record) => {
              return (
                <Checkbox
                  value={["WRITE", record.id]}
                  onChange={this.handleCheckedChange}
                  checked={
                    record.write != undefined ? record.write.checked : false
                  }
                />
              );
            }}
          />
          <Column
            title="NOT READ"
            dataIndex="notRead"
            key="notRead"
            render={(text, record) => {
              return (
                <Checkbox
                  value={["NOT_READ", record.id]}
                  onChange={this.handleCheckedChange}
                  checked={
                    record.notRead != undefined ? record.notRead.checked : false
                  }
                />
              );
            }}
          />
          <Column
            title="NOT WRITE"
            dataIndex="notWrite"
            key="notWrite"
            render={(text, record) => {
              return (
                <Checkbox
                  value={["NOT_WRITE", record.id]}
                  onChange={this.handleCheckedChange}
                  checked={
                    record.notWrite != undefined
                      ? record.notWrite.checked
                      : false
                  }
                />
              );
            }}
          />
        </Table>
        <Button type={"primary"} onClick={this.saveActions}>
          Save
        </Button>
      </div>
    );
  }
}

export default RoleList;
