import React, { Component } from "react";
import "./circle-loader.css";

export default class CircleLoader extends Component {
  render() {
    return (
      <span
        class="circle-loader"
        style={this.props.style ? { ...this.props.style } : null}
      ></span>
    );
  }
}
