import React, { Component } from "react";
import { Tooltip } from "antd";
import { DatabaseOutlined } from "@ant-design/icons";
import $ from "jquery";
import { pagePopupConfigure } from "../../../Utils/PagePopupConfigure";
import i18n from "../../../Utils/i18next";

const clone = require("rfdc")();

/**
 * Plugin tools data button component
 */
export default class DataButton extends Component {
  /**
   * Determine according to which element the popup will be opened
   * pagePopupConfigure method call.
   */
  pluginDataOpen = (plugin, model) => {
    this.props.changeDataVisibility(true);
  };

  render() {
    const classNameControl = this.props.iconsClassNameControl
    let isPluginHeightOne = this.props.isPluginHeightOne
    let isPluginWidthOne = this.props.isPluginWidthOne

    return (
      <Tooltip
        title={i18n.t("Dashboard.Data.Data")}>
        <DatabaseOutlined
          name={"addDataToPluginButton"}
          className={classNameControl(isPluginHeightOne, isPluginWidthOne)}
          style={{ cursor: "pointer" }}
          onClick={() =>
            this.pluginDataOpen(this.props.plugin, this.props.model)
          }
        />
      </Tooltip>
    );
  }
}
