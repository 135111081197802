import React, { Component } from "react";
import { returnConditionalListName } from "../RenderConditionalListName";
import { Tooltip } from "antd";

/**
 * Column list component for conditional formatting rules.
 */
export default class ColumnList extends Component {
  render() {
    let style = {};

    if (this.props.columnListPosition === "top") {
      style["bottom"] = "101%";
    }

    if (this.props.visibility === true) {
      return (
        <div className={"column-list"} style={style}>
          {this.props.columnsToBeUsed && this.props.columnsToBeUsed.map((column, i) => (
            <Tooltip overlayClassName="column-list-tooltip" title={returnConditionalListName(column)} placement="topLeft">
              <div
                className={"column-item"}
                onClick={() => this.props.selectColumnOnList(column, i)}
              >
                {returnConditionalListName(column)}
              </div>
            </Tooltip>
          ))}
        </div>
      );
    }

    return null;
  }
}
