import React, { Component } from "react";
import { Modal, Button, Input } from "antd";
import i18n from "../../Utils/i18next";
import { post } from "../../Utils/WebService";
import { API_BASE } from "../../config";
import Text from "../GeneralComponents/Text/Text";
import { showNotificationWithIcon } from "../../Utils/Notification";
import $ from "jquery";

const validExts = new Set(["xls", "xlsx"]);

/**
 * Splits file path for filename
 * @param {*} fileName 
 */
const getFileName = (fileName) => {
  if (fileName) {
    let splittedFileName = fileName.split("\\");
    
    return splittedFileName[splittedFileName.length - 1];
  }

  return "";
}
/**
 * Custom excel upload component
 */
export default class UploadCustomExcel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: undefined,
      fileName: "",
      aliasName: ""
    };
  }

  /**
   * Upload custom excel and refresh list.
   */
  uploadExcel = () => {
    const success = response => {
      showNotificationWithIcon(
        i18n.t("Success"),
        i18n.t("Dashboard.Settings.ExcelSuccessfullyUploaded"),
        "success"
      );

      let dashboardId = this.props.mainDashboardId ? this.props.mainDashboardId : this.props.dashboardId

      this.onCancel();
      this.props.getCustomExcels(dashboardId);
    };

    let fileName = getFileName(this.state.fileName);
    let fileExt = "";

    if (fileName && fileName !== "") {
      fileExt = fileName.split(".")[1];
      fileName = fileName.split(".")[0];
    }

    if (!validExts.has(fileExt)) {
      showNotificationWithIcon(
        i18n.t("Error"),
        i18n.t("Dashboard.Settings.UnsupportedCustomExcelExtension"),
        "error"
      );

      return false;
    }

    if (this.state.file && this.props.dashboardId) {
      let url = `${API_BASE}/custom-excel`;
      let requestBody = new FormData();

      requestBody.append("file", this.state.file);
      requestBody.append("reportId", this.props.mainDashboardId ? this.props.mainDashboardId : this.props.dashboardId);
      requestBody.append("dateFormat", "yyyy-mm-dd");
      requestBody.append(
        "alias",
        this.state.aliasName ? this.state.aliasName : fileName
      );

      post(url, requestBody, success);
    } else {
      if (!this.state.file) {
        showNotificationWithIcon(
          i18n.t("Error"),
          i18n.t("Dashboard.Settings.NoSelectedFile"),
          "error"
        );
      } else if (!this.props.dashboardId) {
        showNotificationWithIcon(
          i18n.t("Error"),
          i18n.t("Dashboard.Settings.DashboardMustBeSaved"),
          "error"
        );
      }
    }
  };

  /**
   * Handle changes excel file and set state
   */
  onChangeFile = e => {
    if (e.target.files[0] && e.target.files[0].name) {
      let file = e.target.files[0];
      let fileName = e.target.value;

      this.setState({
        file: file,
        fileName: fileName
      });
    }
  };

  /**
   * Modal close handler
   */
  onCancel = () => {
    this.props.setVisibilityOfUploadModal(false);

    $("#customExcelUploadButton").val("");

    this.setState({
      file: undefined,
      fileName: "",
      aliasName: ""
    });
  };

  render() {
    let fileName = getFileName(this.state.fileName);  

    return (
      <Modal
        title={i18n.t("Dashboard.Settings.UploadNewCustomExcelTemplate")}
        visible={this.props.uploadModalVisible}
        onCancel={() => this.onCancel()}
        footer={[
          <Button type={"primary"} onClick={() => this.uploadExcel()}>
            {i18n.t("Upload")}
          </Button>
        ]}
      >
        <Text type={"p"}>
          {i18n.t("Dashboard.Settings.CustomExcelDisplayName")}
        </Text>

        <Input
          style={{ width: "50%", marginBottom: "20px", display: "block" }}
          allowClear
          id={"aliasName"}
          defaultValue={this.state.aliasName}
          value={this.state.aliasName}
          onChange={e => {
            this.setState({ aliasName: e.target.value });
          }}
        />
        <span style={{ display: "block", color: "#6a8296", fontSize: "13px" }}>
          {i18n.t("Dashboard.Settings.SupportedFileExtensions")}:{" "}
          {Array.from(validExts).join(", ")}
        </span>

        <label className={"upload-button"}>
          {" "}
          {i18n.t("Dashboard.Configuration.Fields.Browse")}
          <input
            type="file"
            id={"customExcelUploadButton"}
            className={"image-upload"}
            onChange={e => this.onChangeFile(e)}
          ></input>
        </label>
        {fileName === "" ? (
          i18n.t("Dashboard.Configuration.Fields.NoFileSelected")
        ) : (
          <span title={fileName} className={"upload-image-name"}>
            {fileName}
          </span>
        )}
      </Modal>
    );
  }
}
