import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import VispeahenApp from "./VispeahenApp";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import RootReducer from "./admin/Moddelling/components/RootReducer";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import LoginPopup from "../src/ui/GeneralComponents/LoginPopup/LoginPopup"

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css";

import {LicenseManager} from "@ag-grid-enterprise/core";
LicenseManager.setLicenseKey("Evaluation_License-_Not_For_Production_Valid_Until_28_July_2019__MTU2NDI2ODQwMDAwMA==db32728bad1d3ee14ef344c27f0b42be");

export const store = createStore(
  RootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

ReactDOM.render(
  <Provider store={store}>
    <LoginPopup />
    <BrowserRouter basename="/">
      <VispeahenApp />
    </BrowserRouter>
  </Provider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
