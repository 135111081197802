import { AUTO_INTERACTIONS, AUTO_INTERACTION_APPLIED_PLUGIN } from "./FilterAutoInteractionAction";

/**
 * Filter plugins auto interaction operation reducer
 * @param {*} state 
 * @param {*} action 
 */
export default function FilterAutoInteractionReducer(
  state = {
    filterAutoInteractions: new Map(),
  },
  action
) {
  switch (action.type) {
    case AUTO_INTERACTIONS:
      return {
        ...state,
        filterAutoInteractions: action.payload,
      };
    case AUTO_INTERACTION_APPLIED_PLUGIN:
      return {
        ...state,
        appliedPluginId: action.payload,
      }
    default:
      return state;
  }
}
