import React, { Component } from "react";
import { Col, Row, Modal, Button } from "antd";
import i18n from "../../../Utils/i18next";
import { EditOutlined, DeleteOutlined, DashboardOutlined } from "@ant-design/icons";
import { API_BASE } from "../../../config";
import { get, del } from "../../../Utils/WebService";
import { Link } from "react-router-dom";
import { showNotificationWithIcon } from "../../../Utils/Notification";

/**
 * Topojson list for show, edit and delete
 */
export default class ListTopoJson extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mapUsedDashboardList: [],
      deleteProcess: false,
      mapToBeDeleted: undefined,
      mapToBeDeletedName: ""
    };
  }

  /**
   * start delete process, and check dashboards for used maps
   * @mapId string
   * @mapName string
   */
  deleteMapProcess = (mapId, mapName) => {
    let url = `${API_BASE}/dashboard/search-map/` + mapId;

    const successFunc = response => {
      let mapUsedDashboardList = new Map();

      response.data.map(dashboard => {
        mapUsedDashboardList.set(dashboard.id, dashboard)
      });

      let dashboards = Array.from(mapUsedDashboardList.values()) 

      this.setState({
        ...this.state,
        mapUsedDashboardList: [...dashboards],
        deleteProcess: true,
        mapToBeDeleted: mapId,
        mapToBeDeletedName: mapName
      });
    };

    get(url, successFunc);
  };

  /**
   * permanently delete map
   * @mapId string
   */
  deleteMap = mapId => {
    let url = `${API_BASE}/map/` + mapId;

    const successFunc = response => {
      this.setState({
        ...this.state,
        mapUsedDashboardList: [],
        deleteProcess: false,
        mapToBeDeleted: undefined,
        mapToBeDeletedName: ""
      });

      showNotificationWithIcon(
        i18n.t("Success"),
        i18n.t("Dashboard.Settings.TopoJSON.MapSuccessfullyDeleted"),
        "success"
      );

      this.props.getTopoJsonListForMap();
    };

    del(url, undefined, successFunc);
  };

  /**
   * close popup
   */
  handleCancel = e => {
    this.setState({
      ...this.state,
      mapUsedDashboardList: [],
      deleteProcess: false,
      mapToBeDeleted: undefined,
      mapToBeDeletedName: ""
    });
  };

  render() {
    return (
      <div>
        <Row className={"topojson-list-header"}>
          <Col span={16}>{i18n.t("Map")}</Col>
          <Col span={4} style={{ textAlign: "center" }}>
            {i18n.t("Edit")}
          </Col>
          <Col span={4} style={{ textAlign: "center" }}>
            {i18n.t("Delete")}
          </Col>
        </Row>

        <div
          style={{ maxHeight: "470px", overflowX: "auto", overflowY: "scroll" }}
        >
          {this.props.allTopoJsons.map(topoJson => (
            <Row className={"topojson-list-items"}>
              <Col span={16}>{topoJson.name}</Col>
              <Col style={{ textAlign: "center" }} span={4}>
                <EditOutlined
                  onClick={() =>
                    this.props.topoJsonEditMode(
                      true,
                      topoJson.id + "#" + topoJson.name
                    )
                  }
                />
              </Col>
              <Col style={{ textAlign: "center" }} span={4}>
                <DeleteOutlined
                  style={{ color: "red" }}
                  onClick={() =>
                    this.deleteMapProcess(topoJson.id, topoJson.name)
                  }
                />
              </Col>
            </Row>
          ))}
        </div>

        <Modal
          title={
            i18n.t("Dashboard.Settings.TopoJSON.DeleteMap") +
            " -> " +
            this.state.mapToBeDeletedName
          }
          visible={this.state.deleteProcess}
          onCancel={this.handleCancel}
          footer={[]}
        >
          {this.state.mapUsedDashboardList.length > 0 ? (
            <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
              {i18n.t("Dashboard.Settings.TopoJSON.MapUsedDashboards")}
            </div>
          ) : null}

          <div
            style={{
              color: "#c54a4a",
              margin: "10px 0",
            }}
          >
            {this.state.mapUsedDashboardList.length == 0
              ? i18n.t("Dashboard.Settings.TopoJSON.MapDeleteWarning")
              : i18n.t(
                  "Dashboard.Settings.TopoJSON.MapDeleteWarningWithDashboard"
                )}
          </div>

          <div className={"map-used-dashboards"}>
            {this.state.mapUsedDashboardList.map(dashboard => (
              <div className="map-used-dashboard-item">
                <DashboardOutlined style={{marginRight: "6px", top: "-2px", position: "relative"}} />
                <Link
                  to={location => ({ ...location, pathname: "/ui/dashboard/" + dashboard.id })}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {dashboard.settings.title}
                </Link>
              </div>
            ))}
          </div>

          <div>
            <Button
              type="primary"
              style={{ float: "right" }}
              onClick={() => this.deleteMap(this.state.mapToBeDeleted)}
            >
              {i18n.t("DeleteItAnyway")}
            </Button>

            <Button
              style={{ float: "right", marginRight: "10px" }}
              onClick={this.handleCancel}
            >
              {i18n.t("Cancel")}
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}