import React, { Component } from "react";
import Button from "../../../../GeneralComponents/Button/Button";
import { SlidersOutlined } from "@ant-design/icons";
import PopupContainer from "../../../../GeneralComponents/PopupContainer/PopupContainer";
import Text from "../../../../GeneralComponents/Text/Text";
import i18n from "../../../../../Utils/i18next";
import "./whatIf.css";
import { Tabs } from "antd";
import CreateUpdate from "./CreateUpdate";
import $ from "jquery";
import { showNotificationWithIcon } from "../../../../../Utils/Notification";
import List from "./List";
import WhatIfScenarios from "./WhatIfScenarios";
import WhatIfButtonWithParameters from "./WhatIfButtonWithParameters";
import { store } from "../../../../../index";
import { setWhatIf } from "./WhatIfAction";
import { getIcon } from "../../../../../Utils/Global";

export const TYPE_INPUT = "INPUT";
export const TYPE_SLIDER = "SLIDER";

const { TabPane } = Tabs;

/**
 * What if base component
 * Includes what if parameters, scenarios, current scenario
 * When parameter changed, refreshs all plugins.
 */
export default class WhatIf extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibility: false,
      parameters: [],
      whatIfActiveTabKey: "2",
      scenarios: [],
      selectedParameter: undefined,
      currentScenario: undefined
    };

    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentWillReceiveProps(nextProps) {
    let newState = { ...this.state };
    let isStateChanged = false;

    if (this.props.whatIf !== nextProps.whatIf) {
      if (nextProps.whatIf.parameters.length > 0) {
        newState.whatIfActiveTabKey = "1";
      } else {
        newState.whatIfActiveTabKey = "2";
      }

      newState.parameters = [...nextProps.whatIf.parameters];
      newState.scenarios = [...nextProps.whatIf.scenarios];

      isStateChanged = true;
      this.setWhatIfReducer(newState.parameters, newState.scenarios);
    }

    if (isStateChanged) {
      this.setState(newState);
    }
  }

  //If click outside close area.
  handleClickOutside = event => {
    let isTargetDropdownItem = $(event.target).hasClass(
      "ant-select-dropdown-menu-item"
    );
    let isTargetDropdown =
      $(".scenario-list-dropdown")[0] &&
      $(".scenario-list-dropdown")[0].contains(event.target);
    let isTargetModelItem =
      $(".ant-modal-wrap")[0] && $(".ant-modal-wrap")[0].contains(event.target);
    let shouldIClose = !(
      isTargetDropdownItem ||
      isTargetModelItem ||
      isTargetDropdown
    );
    let isOutside =
      this.wrapperRef &&
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target);

    if (isOutside && shouldIClose) {
      if (!$(event.target).closest("#loginPopup").length > 0) {
        this.whatIfOnClick(false);
      }
    }
  };

  /** Changes visibility on click what if button.*/
  whatIfOnClick = status => {
    this.setState({
      ...this.state,
      visibility: status
    });
  };

  // For change active tab (list - add new)
  changeActiveTab = key => {
    let newState = { ...this.state };

    newState.whatIfActiveTabKey = key;

    if (key === "1") {
      newState.selectedParameter = undefined;
    }

    this.setState(newState);
  };

  //Handles edited or created parameter and adds parameters list.
  applyWhatIfParameter = parameter => {
    let parameters = [];
    let hasParameter = false;

    this.state.parameters.map(w => {
      if (w.id === parameter.id) {
        parameters.push(parameter);
        hasParameter = true;
      } else {
        parameters.push(w);
      }
    });

    if (hasParameter === false) {
      parameters.push(parameter);
    }

    this.setState(
      {
        ...this.state,
        parameters: [...parameters],
        whatIfActiveTabKey: "1"
      },
      () => this.props.changeWhatIf(parameters, this.state.scenarios)
    );

    let successMessage = i18n.t("Dashboard.Data.WhatIf.SuccessfullyAdded");

    if (hasParameter) {
      successMessage = i18n.t("Dashboard.Data.WhatIf.SuccessfullyUpdated");
    }

    showNotificationWithIcon(i18n.t("Success"), successMessage, "success");

    this.setWhatIfReducer(parameters, this.state.scenarios);
  };

  // To et updated what if object in store object for use when get query
  setWhatIfReducer = (parameters, scenarios) => {
    let whatIf = {};
    whatIf.parameters = parameters;
    whatIf.scenarios = scenarios;
    whatIf.currentScenario = this.state.currentScenario;

    store.dispatch(setWhatIf(whatIf));
  };

  /** If any parameter value changes, update current value for this parameters */
  updateCurrentValue = (id, currentValue) => {
    let parameters = [...this.state.parameters];

    parameters.map(w => {
      if (w.id === id) {
        w.currentValue = currentValue;
      }
    });

    this.setState({
      ...this.state,
      currentScenario: undefined,
      parameters: [...parameters]
    });

    this.setWhatIfReducer(parameters, this.state.scenarios);
    this.props.refresh(null, true);
  };

  /** Remove parameter in parameters list */
  removeParameter = id => {
    let parameters = [...this.state.parameters];
    let newState = { ...this.state };

    parameters = parameters.filter(p => p.id !== id);
    newState.parameters = [...parameters];

    if (parameters.length === 0) {
      newState.scenarios = [];
      newState.selectedParameter = undefined;
    }

    this.setState(newState, () =>
      this.props.changeWhatIf(parameters, newState.scenarios)
    );
    this.setWhatIfReducer(parameters, newState.scenarios);
  };

  /** Handle parameter by id for edition, and change tab to edit  */
  setSelectedParameters = id => {
    let parameter = this.state.parameters.filter(w => w.id === id)[0];

    this.setState({
      ...this.state,
      selectedParameter: { ...parameter },
      whatIfActiveTabKey: "2"
    });
  };

  /** Close edit screen after cancel */
  cancelEditParameter = () => {
    this.setState({
      ...this.state,
      selectedParameter: undefined,
      whatIfActiveTabKey: "1"
    });
  };

  //Add new scenario in scenario list for what if object.
  addScenario = scenario => {
    let scenarios = [...this.state.scenarios];

    scenarios.push(scenario);

    this.setState(
      {
        ...this.state,
        currentScenario: scenario,
        scenarios: scenarios
      },
      () => this.props.changeWhatIf(this.state.parameters, scenarios)
    );

    this.setWhatIfReducer(this.state.parameters, scenarios);
  };

  //Remove scenario in scenario list for what if object.
  removeScenario = id => {
    let scenarios = [...this.state.scenarios];
    let currentScenario = { ...this.state.currentScenario };

    scenarios = scenarios.filter(s => s.id !== id);

    if (currentScenario && currentScenario.id === id) {
      currentScenario = undefined;
    }

    this.setState(
      {
        ...this.state,
        currentScenario: currentScenario,
        scenarios: scenarios
      },
      () => this.props.changeWhatIf(this.state.parameters, scenarios)
    );

    this.setWhatIfReducer(this.state.parameters, scenarios);
  };

  /** If clicked any scenario, updates all parameters value. */
  updateCurrentValuesByScenario = scenario => {
    let parameters = [];
    let parameterValues = new Map();

    scenario.scenarioParametersList.map(param => {
      parameterValues.set(param.id, param.currentValue);
    });

    for (let i = 0; i < this.state.parameters.length; i++) {
      let value = parameterValues.get(this.state.parameters[i].id);
      parameters[i] = { ...this.state.parameters[i] };

      if (value) {
        parameters[i].currentValue = value;
      }
    }

    this.setState({
      ...this.state,
      currentScenario: scenario,
      parameters: parameters
    });

    this.setWhatIfReducer(parameters, this.state.scenarios);
    this.props.refresh(null, true);
  };

  render() {
    let buttonTheme = this.props.theme?.buttons?.whatIfButton;
    let hasAnyParameter = this.state.parameters.length > 0 ? true : false;

    return (
      <div className={"whatIf"} ref={this.wrapperRef}>
        {!hasAnyParameter ? (
          <Button
            id="whatIfButton"
            className="dashboard-button btn btn-new circle-button plugin-button"
            style={{
              marginLeft: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
            onClick={() => this.whatIfOnClick(!this.state.visibility)}
            tooltip={i18n.t("Dashboard.Data.WhatIf.Title")}
          >
            <style>
              {buttonTheme && `
                #whatIfButton {
                    background-color: ${buttonTheme?.backgroundColor};
                    color: ${buttonTheme?.textColor};
                    border-color: ${buttonTheme?.textColor}33;
                }

                #whatIfButton:hover,
                #whatIfButton:focus,
                #whatIfButton:active {
                    background-color: ${buttonTheme?.backgroundColorActive};
                    color: ${buttonTheme?.textColorActive};
                    border-color: ${buttonTheme?.textColorActive}33;
                }
              `}
            </style>
            {
              getIcon(
                buttonTheme?.icon,
                <SlidersOutlined className={"whatIf-icon"} />,
                { fontSize: 25, position: "relative" }
              )
            }
          </Button>
        ) : (
          <WhatIfButtonWithParameters
            whatIfOpen={() => this.whatIfOnClick(!this.state.visibility)}
            parameters={this.state.parameters}
            scenarios={this.state.scenarios}
            updateCurrentValuesByScenario={this.updateCurrentValuesByScenario}
            removeScenario={this.removeScenario}
            readOnly={this.props.readOnly}
            buttonTheme={buttonTheme}
          />
        )
        }

        {
          this.state.visibility === true ? (
            <PopupContainer
              width="600px"
              constantHeight="400px"
              textAlign="left"
              background={"rgb(251, 251, 251)"}
              color="#505050"
              overflow={"hidden"}
            >
              <Text style={{ textAlign: "center" }} type="h5">
                {i18n.t("Dashboard.Data.WhatIf.Title")}
              </Text>
              <Text
                type="span"
                style={{
                  color: "rgb(162, 162, 162)",
                  marginBottom: "18px",
                  textAlign: "center",
                  whiteSpace: "normal",
                  fontSize: "13px",
                  display: "block",
                  lineHeight: "15px"
                }}
              >
                {i18n.t("Dashboard.Data.WhatIf.Description")}
              </Text>

              {this.state.whatIfActiveTabKey === "1" &&
                this.state.parameters.length > 0 &&
                this.props.readOnly === "WRITE" ? (
                <WhatIfScenarios
                  parameters={this.state.parameters}
                  addScenario={this.addScenario}
                  currentScenario={this.state.currentScenario}
                />
              ) : null}

              <Tabs
                defaultActiveKey={hasAnyParameter ? "1" : "2"}
                size={"small"}
                className={"what-if-tab"}
                activeKey={this.state.whatIfActiveTabKey}
                onChange={this.changeActiveTab}
              >
                {hasAnyParameter ? (
                  <TabPane tab={i18n.t("Dashboard.Data.WhatIf.List")} key="1">
                    <List
                      readOnly={this.props.readOnly}
                      setSelectedParameters={this.setSelectedParameters}
                      removeParameter={this.removeParameter}
                      updateCurrentValue={this.updateCurrentValue}
                      parameters={this.state.parameters}
                    />
                  </TabPane>
                ) : null}

                {this.props.readOnly === "WRITE" ? (
                  <TabPane tab={i18n.t("Dashboard.Data.WhatIf.AddNew")} key="2">
                    <CreateUpdate
                      cancelEditParameter={this.cancelEditParameter}
                      applyWhatIfParameter={this.applyWhatIfParameter}
                      parameter={this.state.selectedParameter}
                    />
                  </TabPane>
                ) : null}
              </Tabs>
            </PopupContainer>
          ) : null
        }
      </div >
    );
  }
}
