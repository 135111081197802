import React, { Component } from "react";
import { Form, Icon, Input, Progress, Drawer, Alert } from "antd";
import FooterVispeahenLogo from "../GeneralComponents/FooterVispeahenLogo";
import i18n from "../../Utils/i18next";
import { showNotificationWithIcon, showError } from "../../Utils/Notification";
import { post } from "../../Utils/WebService";
import SubmitButton from "./SubmitButton";
import { isUserEntranceInputValid } from "./UserValidator"
import { getPasswordScore } from "./PasswordValidator"

/**
 * User can chage her forgotten password via this component
 */
export default class PasswordForget extends Component {
  constructor(props) {
    super(props);

    this.state = {
        password: "",
        rePassword: "",
        score: 0,
        canISubmit: true,
      };
  }

  /**
   * Handle state inputs change.
   */
  onValueChange = (event) => {
    if(isUserEntranceInputValid(event) === false) {
      event.preventDefault();
      return;
    }

    this.setState({
      ...this.state,
      [event.target.id]: event.target.value,
    });
  };

  /**
   * Calculates password's strength
   * @param {*} e
   */
  onChangePassword = (e) => {
    let score = getPasswordScore(e.target.value);

    this.setState({
      ...this.state,
      [e.target.id]: e.target.value,
      score: score,
    });
  };

  /**
   * This function will take action depends on the register button click, in this function all the informations which user provided, and stored
   * will be transmitted to the api and register process will be completed
   */
  saveUser = () => {
    const THIS = this;

    let url = `/api/user/changePassword`;

    let userObj = {
      password: this.state.password,
      rePassword: this.state.repassword,
      activationCode: this.props.passwordCode,
    };

    const successFunc = (result) => {
      showNotificationWithIcon(
        i18n.t("UserEntrance.Information"),
        i18n.t("UserEntrance.ChangePassword.Success"),
        "success"
      );

      this.setState({
        password: "",
        repassword: "",
        score: 0,
        canISubmit: true,
      });

      this.props.showComponent("visibilityLogin");
    };

    const errorFunc = (error) => {
      if(error.includes("InvalidActivationCode") || error.includes("InvalidLdap")) {
        const key = "UserEntrance.ChangePassword." + error;
        showError(i18n.t(key));
      } else {
        showError(error);
      }

      this.setState({
        ...this.state,
        canISubmit: true,
      });
    };

    let headers = {
      Authorization: "Basic " + btoa("mobile:pin"),
    };

    post(url, userObj, successFunc, errorFunc, false, false, headers);

    this.setState({
      ...this.state,
      canISubmit: false,
    });
  };

  handleKeyDown = (e) => {
    // if user presses enter button on keyboard
    if (e.key === "Enter") {
      this.validateAndSave(e);
    }
  };

  /**
   * This function is the last information validation check it will take action right before saveUser
   * if all the information that user provided are valid navigate to saveUser, if not prompt fail message
   */
  validateAndSave = (e) => {
    e.preventDefault();

    // There is still a register process on the way.
    // Do nothing till that process is finished
    if (this.state.canISubmit === false) return;

    if (this.validatePassword() &&
        this.validatePasswordScore() &&
        this.validateRePassword() &&
        this.validatePasswordMatch()
      ) {
        this.saveUser();
      }
  };

  validatePassword = () => {
    if (!this.state.password) {
      showError(i18n.t("UserEntrance.Register.PasswordEmpty"));
      return false;
    }

    return true;
  };

  validatePasswordScore = () => {
    if (this.state.score < 100) {
      showError(i18n.t("UserEntrance.Register.PasswordStrength"));
      return false;
    }

    return true;
  };

  validateRePassword = () => {
    if (!this.state.rePassword) {
      showError(i18n.t("UserEntrance.Register.RepasswordEmpty"));
      return false;
    }

    return true;
  };

  validatePasswordMatch = () => {
    if (this.state.rePassword !== this.state.password) {
      showError(i18n.t("UserEntrance.Register.PasswordDoesNotMatch"));
      return false;
    }

    return true;
  };

  render() {
    return (
      <Drawer
        style={{
          display: this.props.visibilityPasswordChange ? "block" : "none",
        }}
        className={"noBackground"}
        placement={"center"}
        closable={false}
        visible={true}
        width={370}
      >
        <Form className="login-form" onSubmit={this.validateAndSave}>
          <div style={{ width: "100%", textAlign: "center" }}>
            <img
              src={require("../../images/logo.webp")}
              alt="Image"
              class="login-logo"
            />
          </div>
          <div className="password-progress">
            <span style={{ fontSize: "12px", lineHeight: "1px" }}>
              {i18n.t("UserEntrance.ChangePassword.Description")}
            </span>
          </div>
          <br />
          <Form.Item>
            <Input.Password
              id={"password"}
              value={this.state.password}
              onChange={this.onChangePassword}
              onKeyDown={this.handleKeyDown}
              className={"circled-no-border login-input"}
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              maxLength={25}
              name={"passForgottenPassword"}
              placeholder={i18n.t("UserEntrance.Password")}
            />
          </Form.Item>
          <div className="password-progress">
            <span style={{ fontSize: "10px", lineHeight: "1px" }}>
              {i18n.t("UserEntrance.Register.PasswordStrength")}
            </span>
            <Progress
              id="progressBarPasswordChange"
              percent={this.state.score}
              status={
                this.state.score < 100
                  ? this.state.score == 0
                    ? "info"
                    : "exception"
                  : "success"
              }
            />
          </div>
          <Form.Item>
            <Input.Password
              id={"rePassword"}
              value={this.state.rePassword}
              onChange={this.onValueChange}
              onKeyDown={this.handleKeyDown}
              className={"circled-no-border login-input"}
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              maxLength={25}
              name={"rePassForgottenPassword"}
              placeholder={i18n.t("UserEntrance.RePassword")}
            />
          </Form.Item>
          <div
            style={{
              display: this.state.rePassword.length == 0 ? "none" : "block",
              marginTop: "-15px",
            }}
          >
            <Alert
              id={
                this.state.password === this.state.rePassword
                  ? i18n.t("UserEntrance.PasswordMatch")
                  : i18n.t("UserEntrance.PasswordDontMatch")
              }
              style={{ backgroundColor: "#00000000", border: "0px" }}
              message={
                this.state.password === this.state.rePassword
                  ? i18n.t("UserEntrance.PasswordMatch")
                  : i18n.t("UserEntrance.PasswordDontMatch")
              }
              type={
                this.state.password === this.state.rePassword
                  ? "success"
                  : "error"
              }
              showIcon
            ></Alert>
          </div>
          <Form.Item>
            <SubmitButton
              id={"changedPasswordButton"}
              canISubmit={this.state.canISubmit}
              text={i18n.t("UserEntrance.ChangePassword.Button")}
            />
            <div
              style={{ paddingLeft: "5px", float: "right", marginTop: "-5px" }}
            >
              <span
                id={"backToLoginForgottenPassword"}
                style={{
                  color: "#1b6094",
                  cursor: "pointer",
                  fontSize: "11px",
                }}
                onClick={() => this.props.showComponent("visibilityLogin")}
              >
                {i18n.t("UserEntrance.GoBackToLogin")}
              </span>
            </div>
          </Form.Item>
        </Form>
        <FooterVispeahenLogo position={"absolute"} />
      </Drawer>
    );
  }
}
