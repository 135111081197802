import Cookies from "js-cookie";

/**
 * Retunes the selected model written in the cookie
 */
export const getSelectedModel = () => {
   const selectedModels = Cookies.get("selectedModel");
   if (selectedModels) {
      const modelsArray = JSON.parse(selectedModels);
      
      const matchingModel = modelsArray.find(selectedModel => {
         return selectedModel.tenant === Cookies.get("tenant") &&
                selectedModel.authenticatedUser === Cookies.get("authenticatedUser");
      });

      return matchingModel ? matchingModel.modelId : null;
   }
   return null;
};

/**
 * 
 * @param {*} id 
 * Sets the id of the selected model, which comes as a parameter, to the cookie.
 */
export const setSelectedModel = (id) => {
   const selectedModel = {
      modelId: id,
      tenant: Cookies.get('tenant'),
      authenticatedUser: Cookies.get('authenticatedUser')
   };

   const existingModels = Cookies.get("selectedModel");
   let modelsArray = existingModels ? JSON.parse(existingModels) : [];

   const existingIndex = modelsArray.findIndex(model => 
      model.tenant === selectedModel.tenant && 
      model.authenticatedUser === selectedModel.authenticatedUser
   );

   if (existingIndex !== -1) {
      modelsArray[existingIndex] = selectedModel;
   } else {
      modelsArray.push(selectedModel);
   }

   Cookies.set("selectedModel", JSON.stringify(modelsArray), { expires: 365 });
};

 /**
  * selected model is delete
  */
 export const removeSelectedModel = () => {
    Cookies.remove("selectedModel")
 }
