import React, { Component } from "react";
import { Form, Input, Tooltip, Icon, Row } from "antd";
import Button from "../../GeneralComponents/Button/Button";
import { connectionStatus } from "./ConnectionStatusEnum";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import i18n from "../../../Utils/i18next";
import {setFieldForConnection} from "./DbConnection"

import {
  LaptopOutlined,
  DatabaseOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

const FormItem = Form.Item;
const clone = require("rfdc")();

/**
 * It includes the form inputs required for Mysql database connection, validation, test connection and save operation.
 */
export default class MysqlDBConnection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ip: "",
      connectionName: "",
      username: "",
      password: "",
      databaseName: "",
      schemaName: "",
      image: "mysql",
      testConnectionStatus: connectionStatus.LOADING,
      saveButton: false,
      id: "",
      name: "",
      port: "3306",
    };
  }

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value,
    });
  };

  /**
   * If test connection is successful, save button is activated, and if there is editData, setFieldForConnection method is called to set the connection information state.
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.testStatus != this.props.testStatus) {
      this.setState({
        saveButton: nextProps.testStatus,
      });
    }

    if (this.props.editData != nextProps.editData) {
      this.setFieldForConnection(nextProps);
    }

    if(this.props.visible !== nextProps.visible && nextProps.visible === false) {
      this.clearState()
    }
  }

  /**
   * Mysql connection check
   */
  isMysqlConnection = () => {
    return this.props.editData && this.props.editData.dataSourceType == "mysql";
  };

  /**
   * This method fills the input fields for the connection information requested to be updated.
  */
   setFieldForConnection = (props) => {
    if (this.isMysqlConnection()) {
      let connectionInformation = setFieldForConnection(props, "mysql")

      this.setState({
        ...this.state,
        connectionName: connectionInformation.displayName,
        schemaName: connectionInformation.schemaName,
        username: connectionInformation.username,
        password: "",
        displayName: connectionInformation.displayName,
        id: connectionInformation.id,
        name: connectionInformation.name,
        ip: connectionInformation.ip == undefined ? props.editData.ip : connectionInformation.ip,
        port: connectionInformation.port == undefined ? props.editData.port : connectionInformation.port,
        databaseName: connectionInformation.databaseName == undefined ? props.editData.serviceName : connectionInformation.databaseName,
      });
    }
  };

  componentWillMount() {
    this.setFieldForConnection(this.props);
  }

  /**
   *  Objects are created in the desired format for recording and testing.
   */
  creatingConnectionObjects = () => {
    let connection = {
      id: this.state.id !== "" ? this.state.id : null,
      name: this.state.name !== "" ? this.state.name : null,
      displayName: this.state.connectionName,
      ip: this.state.ip,
      port: this.state.port,
      serviceName: this.state.serviceName,
      img: this.state.image,
      schemaName: this.state.schemaName,
      dataSourceType: "mysql",
      connection: {
        type: "jdbc",
        driver: "com.mysql.jdbc.Driver",
        url: "jdbc:mysql://" + this.state.ip + ":" + this.state.port + "/" + this.state.databaseName,
        username: this.state.username,
        password: this.state.password,
        caseInsensitiveTableNames: false,
        enabled: true,
      },
    };

    if(Object.keys(this.props.editData).length === 0) {
      connection.badge = { title: i18n.t("New"), type: "danger" }
    } 

    return connection;
  };

  save = () => {
    let responseObj = this.creatingConnectionObjects();

    this.clearState();
    this.props.save(responseObj);
  };

  /**
   * input validation
   */
  controlConnection = () => {
    if (
      this.state.ip === "" ||
      this.state.connectionName === "" ||
      this.state.username === "" ||
      this.state.password === "" ||
      this.state.schemaName === "" ||
      this.state.databaseName === "" ||
      this.state.port === ""
    ) {
      return connectionStatus.ERROR;
    }

    return connectionStatus.SUCCESS;
  };

  /**
   * It tests the entered connection and validates the input and activates the save button depending on whether it is connected or not.
   */
  testConnection = () => {
    this.setState({
      ...this.state,
      testConnectionStatus: connectionStatus.LOADING,
    });

    let isConnectionValid = this.controlConnection();
    if (isConnectionValid === connectionStatus.SUCCESS) {
      let responseObj = this.creatingConnectionObjects();
      this.props.testConnection(responseObj);

      this.setState({
        ...this.state,
        testConnectionStatus: isConnectionValid,
      });
    } else if (connectionStatus.ERROR) {
      this.setState({
        ...this.state,
        testConnectionStatus: isConnectionValid,
        saveButton: false,
      });
    }
  };

  clearState = () => {
    this.setState({
      ip: "",
      connectionName: "",
      username: "",
      password: "",
      databaseName: "",
      schemaName: "",
      image: "mysql",
      testConnectionStatus: connectionStatus.LOADING,
      saveButton: false,
      port: "3306"
    });
  }

  render() {
    if (!this.props.visible) {
      return false;
    }

    return (
      <div style={{ paddingBottom: "30px" }}>
        <Form>
          <FormItem>
            <Row>
              <Input
                placeholder={i18n.t("DataConnections.ConnectionName")}
                value={this.state.connectionName}
                id="connectionName"
                name={"connectionNameMySql"}
                disabled={
                  Object.keys(this.props.editData).length > 0
                    ? "disabled"
                    : false
                }
                onChange={this.handleChange}
                style={{
                  marginBottom: "13px",
                }}
              />
            </Row>
            <Row style={{ textAlign: "left" }}>
              {this.state.testConnectionStatus === connectionStatus.ERROR &&
              this.state.connectionName === "" ? (
                <label
                  id="emptyConnectionName"
                  style={{
                    color: "red",
                    textAlign: "left",
                    margin: "0",
                    left: "10px",
                  }}
                >
                  {i18n.t("DataConnections.EmptyFieldMessage")}
                </label>
              ) : null}
            </Row>
          </FormItem>
          <FormItem>
          <Row>
              <Input
                placeholder={i18n.t("DataConnections.IpAdress")}
                value={this.state.ip}
                id="ip"
                name={"ipMySql"}
                onChange={this.handleChange}
                prefix={<LaptopOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                suffix={
                  <Tooltip>
                    <Icon
                      type="info-circle"
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  </Tooltip>
                }
              />
            </Row>
            <Row>
              <Input
                placeholder={i18n.t("Port")}
                value={this.state.port}
                id="port"
                name={"portMySql"}
                onChange={this.handleChange}
                prefix={<LaptopOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                suffix={
                  <Tooltip>
                    <Icon
                      type="info-circle"
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  </Tooltip>
                }
              />
            </Row>
            <Row style={{ textAlign: "left" }}>
              {this.state.testConnectionStatus ===
                connectionStatus.ERROR && this.state.port === "" ? (
                <label
                  id="emptyIpMySql"
                  style={{
                    color: "red",
                    textAlign: "left",
                    margin: "0",
                    left: "10px",
                  }}
                >
                  {i18n.t("DataConnections.EmptyFieldMessage")}
                </label>
              ) : null}
            </Row>
          </FormItem>
          <FormItem style={{ marginBottom: "3px" }}>
            <Row>
              <Input
                placeholder={i18n.t("DataConnections.DatabaseName")}
                value={this.state.databaseName}
                id="databaseName"
                name={"databaseNameMySql"}
                onChange={this.handleChange}
                prefix={
                  <DatabaseOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                suffix={
                  <Tooltip>
                    <Icon
                      type="info-circle"
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  </Tooltip>
                }
              />
            </Row>
            <Row style={{ textAlign: "left" }}>
              {this.state.testConnectionStatus ===
                connectionStatus.ERROR &&
              this.state.databaseName === "" ? (
                <label
                  id="emptyDatabaseNameMySql"
                  style={{
                    color: "red",
                    textAlign: "left",
                    margin: "0",
                    left: "10px",
                  }}
                >
                  {i18n.t("DataConnections.EmptyFieldMessage")}
                </label>
              ) : null}
            </Row>
          </FormItem>
          <FormItem style={{ marginBottom: "3px" }}>
            <Row>
              <Input
                placeholder={i18n.t("DataConnections.SchemaName")}
                value={this.state.schemaName}
                id="schemaName"
                name={"schemaNameMySql"}
                onChange={this.handleChange}
                prefix={
                  <DatabaseOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                suffix={
                  <Tooltip>
                    <Icon
                      type="info-circle"
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  </Tooltip>
                }
              />
            </Row>
            <Row style={{ textAlign: "left" }}>
              {this.state.testConnectionStatus ===
                connectionStatus.ERROR && this.state.schemaName === "" ? (
                <label
                  id="emptyScheNameMySql"
                  style={{
                    color: "red",
                    textAlign: "left",
                    margin: "0",
                    left: "10px",
                  }}
                >
                  {i18n.t("DataConnections.EmptyFieldMessage")}
                </label>
              ) : null}
            </Row>
          </FormItem>
          <FormItem>
            <Input
              placeholder={i18n.t("DataConnections.Username")}
              id="username"
              name={"usernameMySql"}
              value={this.state.username}
              onChange={this.handleChange}
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              suffix={
                <Tooltip>
                  <Icon
                    type="info-circle"
                    style={{ color: "rgba(0,0,0,.45)" }}
                  />
                </Tooltip>
              }
            />
          </FormItem>
          <FormItem>
            <Input.Password
              placeholder={i18n.t("DataConnections.Password")}
              id="password"
              name={"passwordMySql"}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              value={this.state.password}
              onChange={this.handleChange}
              prefix={<Icon type="key" style={{ color: "rgba(0,0,0,.25)" }} />}
              suffix={
                <Tooltip>
                  <Icon
                    type="info-circle"
                    style={{ color: "rgba(0,0,0,.45)" }}
                  />
                </Tooltip>
              }
            />
          </FormItem>
          <Button
            id="testConnectionMySql"
            onClick={this.testConnection}
            type="warning"
            style={{ float: "left" }}
          >
            {i18n.t("DataConnections.TestConnection")}
            {this.state.testConnectionStatus ===
            connectionStatus.LOADING ? (
              ""
            ) : this.state.testConnectionStatus ===
                connectionStatus.SUCCESS &&
              this.state.saveButton === true ? (
              <CheckCircleOutlined
                style={{
                  display: "inline",
                  position: "relative",
                  top: "-3px",
                  right: "-1px",
                  color: "#85c558",
                }}
              />
            ) : this.state.testConnectionStatus ===
                connectionStatus.ERROR ||
              this.state.saveButton === false ? (
              <CloseCircleOutlined
                style={{
                  display: "inline",
                  position: "relative",
                  top: "-3px",
                  right: "-1px",
                  color: "red",
                }}
              />
            ) : this.state.testConnectionStatus === 
              connectionStatus.SUCCESS ? (
              <LoadingOutlined
                style={{
                  display: "inline",
                  position: "relative",
                  top: "-3px",
                  right: "-1px",
                  color: "#2d92c3",
                }}
              />
            ) : (
              ""
            )}
          </Button>

          {this.state.saveButton ? (
            <Button
              id="saveConnectionMySql"
              onClick={() => this.save()}
              className={"general-button"}
              style={{ float: "right", width: "fit-content" }}
            >
              {i18n.t("Save")}
            </Button>
          ) : (
            <Button type="primary" disabled style={{ float: "right" }}>
              {i18n.t("Save")}
            </Button>
          )}
        </Form>
      </div>
    );
  }
}
