import React, { Component } from "react";
import { Icon } from "antd";
import i18n from "../../Utils/i18next";

/**
 * Shows user has been activated
 */
export default class UserActivationLoading extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={"empty-dashboard"}>
        <span>
          <Icon type="check-circle" className={"icon-in-text"} style={{fontSize: 24}} theme="twoTone" twoToneColor="#52c41a" /> 
          <span>{i18n.t("UserEntrance.Activation.UserActivated")}</span>
        </span>
      </div>
    );
  }
}
