import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Col, Divider, List, Row, Tooltip } from "antd";
import React, { Component } from "react";
import i18n from "../../Utils/i18next";

export default class NavigationList extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
       { this.props.navigations && this.props.navigations.length > 0 ? (
           <>
        <div style={{ textAlign: "center" }}>{i18n.t("Navigation.NavigationList")}</div>
        <Divider style={{ marginBottom: "0px", marginTop: "12px" }}></Divider>
        <Row style={{ marginBottom: "10px" }}>
          <List
            itemLayout="horizontal"
            dataSource={this.props.navigations}
            renderItem={(item) => (
              <List.Item style={{ paddingLeft: "65px" }}>
                <Col span={20} style={{ textAlign: "left" }}>
                  <p>{item.dashboardInformation.dashboardName}</p>
                </Col>
                <Col span={2}>
                  <Tooltip tooltip={i18n.t("Edit")}>
                    <EditOutlined
                      onClick={() => this.props.setNavigationDataForEditMode(item)}
                      className={"circle-icon"}
                      style={{
                        cursor: "pointer",
                      }}
                    ></EditOutlined>
                  </Tooltip>
                </Col>
                <Col span={2}>
                  <Tooltip tooltip={i18n.t("Delete")}>
                    <DeleteOutlined
                      onClick={() => this.props.deleteNavigation(item)}
                      className={"circle-icon"}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Col>
              </List.Item>
            )}
          />
        </Row>
        </>) : null}
      </div>
    );
  }
}
