import { Col, Row } from "antd";
import React, { Component } from "react";
import { API_BASE } from "../../config";
import { post } from "../../Utils/WebService";
import SheetStatus, { OPERATIONS } from "./SheetStatus";
import i18n from "../../Utils/i18next";

/**
 * File validations for upload
 * Sheets status check
 */
export default class FileValidationForUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentWillMount() {
    const { sheets, uploadParameters } = this.props;

    if (
      sheets &&
      sheets.size > 0 &&
      uploadParameters &&
      uploadParameters.file
    ) {
      this.checkFileAndFields(sheets, uploadParameters);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.validationForUploadValidation !==
        this.props.validationForUploadValidation &&
      nextProps.validationForUploadValidation === true
    ) {
      this.validation(nextProps);
    }
  }

  validation = (props) => {
    const { sheets } = props;
    let response = { status: true };

    //Sheets - Tables
    if (response.status) {
      this.sendValidationResponseWithError(
        this.atLeastOneWriteOperation(sheets),
        response
      );
    }

    if (response.status) {
      this.props.validationResponse(response);
      return;
    }
  };

  sendValidationResponseWithError = (parameter, response) => {
    if (!parameter.status) {
      response.status = parameter.status;
      response.message = parameter.message;

      this.props.validationResponse(response);
      return;
    }
  };

  atLeastOneWriteOperation = (sheets) => {
    let isWriteOperation = false;

    [...sheets.keys()].map((sheetName, index) => {
      if (
        sheets.get(sheetName).status &&
        sheets.get(sheetName).operation !== OPERATIONS.DO_NOTHING.key
      ) {
        isWriteOperation = true;
      }
    });

    if (!isWriteOperation) {
      return {
        status: false,
        message: i18n.t("FileUpload.AtLeastOneSheetMustWrite"),
      };
    }

    return { status: true };
  };

  checkFileAndFields = (sheets, uploadParameters) => {
    let url = API_BASE + "/file-operations/check-file-and-fields";
    let requestBody = new FormData();

    // requestBody.append("file", uploadParameters.file);
    requestBody.append("sheets", JSON.stringify(Object.fromEntries(sheets)));
    requestBody.append("dataSource", JSON.stringify(this.props.dataSource));
    requestBody.append("schema", uploadParameters.schemaName);

    const success = (response) => {
      console.log(response);

      let data = response.data;

      let checkResult = {
        status: data.status,
      };

      if (data["not_unique_tables"]) {
        checkResult.notUniqueTables = new Set(data["not_unique_tables"]);
      }

      if (data["data_source_original_tables"]) {
        checkResult.originalTables = new Set(data["data_source_original_tables"]);
      }

      this.setState({
        loading: false,
        checkResult: checkResult,
      });
    };

    this.setState({
      loading: true,
      checkResult: undefined,
    });

    post(url, requestBody, success, undefined, false);
  };

  render() {
    const { loading, checkResult } = this.state;
    const { sheets, uploadParameters } = this.props;

    return (
      <div>
        <div className="file-validation-header">
          <Row gutter={6}>
            <Col span={6}>{i18n.t("FileUpload.Sheet")}</Col>
            <Col span={6}>{i18n.t("FileUpload.Status")}</Col>
            <Col span={6}>{i18n.t("FileUpload.Operation")}</Col>
          </Row>
        </div>

        {[...sheets.keys()].map((sheetName, index) => {
          if (sheets.get(sheetName).status) {
            return (
              <SheetStatus
                key={index}
                loading={loading}
                sheet={sheets.get(sheetName)}
                sheetName={sheetName}
                checkResult={checkResult}
                setSheetConfiguration={this.props.setSheetConfiguration}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  }
}
