import React, { Component } from "react";
import { Row, Button } from "antd";
import JoinList from "../JoinList/JoinList";
import JoinCreate from "../JoinCreate/JoinCreate";
import { showNotificationWithIcon } from "../../../Utils/Notification";
import { get, post } from "../../../Utils/WebService";
import { API_BASE } from "../../../config";
import i18n from "../../../Utils/i18next";
import { PlusCircleOutlined } from "@ant-design/icons"
import "./JoinLayout.css";
import { joinLayoutOperations } from "../../../Utils/JoinLayoutOperations";

const host = API_BASE;
const apiJoin = "/join";
const apiSubjectArea = "/subjectArea";

class JoinLayout extends Component {
  constructor(props) {
    super(props);

    this.onModelChanged = this.onModelChanged.bind(this);

    this.state = {
      showModal: false,
      joins: [],
      models: [],
      selectedTables: [],
      selectedModel: {},
      prevFactAlias: "",
      prevDimensionAlias: "",
    };
  }

  componentWillMount() {
    this.setState({
      ...this.state,
      lastModelCheckDate: new Date()
    })
  }

  componentDidMount() {
    if (this.props.selectedModel) {
      this.getJoinsByModel(this.props.selectedModel);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedModel != this.props.selectedModel) {
      this.getJoinsByModel(nextProps.selectedModel);
    }

    if(this.props.join != this.state.join || this.props.join != nextProps.join) {
      this.setState({
        joins: nextProps.join
      })
    }
  }

  getData() {
    get(
      host + apiSubjectArea,
      (response) => {
        this.setState({
          ...this.state,
          models: response.data,
        });
      },
      (error) => {
        showNotificationWithIcon(i18n.t("Model.JoinCannotBeFound"), null, "error");
      }
    );
  }

  getModel(model, callback = undefined) {
    let url = host + apiSubjectArea + "/query"; 
    let requestBody = {
      id: model.id,
      lastModifiedDate: this.state.lastModelCheckDate
    }

    const successFunc = (response) => {
      let selectedModel = this.props.selectedModel;
      let lastModelCheckDate = this.state.lastModelCheckDate;

      if (response.data) {
        selectedModel = response.data;
        lastModelCheckDate = new Date();
        this.props.updateSelectedModel(selectedModel.tables)
      } 

      this.setState({
        ...this.state,
        selectedModel: selectedModel,
        lastModelCheckDate: lastModelCheckDate
      }, () => {
        if (callback) {
          callback();
        }
      });
    }

    post(url, requestBody, successFunc);
  }

  getJoinsByModel = (model) => {
    get(
      host + apiJoin + "/" + model.id,
      (response) => {
        this.setState({
          ...this.state,
          joins: joinLayoutOperations(response.data),
        });

        this.props.findJoinChanges(response.data)
      },
      (error) => {
        showNotificationWithIcon(i18n.t("Model.JoinCannotBeFound"), null, "error");
      }
    );
  };

  onModelChanged(selectedTables, alias, isFact) {
    if (alias && isFact === true) {
      this.setState({ ...this.state, selectedTables, prevFactAlias: alias });
    } else if (alias && isFact === false) {
      this.setState({
        ...this.state,
        selectedTables,
        prevDimensionAlias: alias,
      });
    } else {
      this.setState({ ...this.state, selectedTables });
    }
  }

  handleCreateModel = () => {
    this.getModel(this.props.selectedModel, 
      () => this.setState({ ...this.state, showModal: true })
    )
  }

  render() {
    return (
      <div>
        <Row
          style={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <div style={{ paddingBottom: "10px" }}>
            <Button
              id="addJoinButton"
              className="addButton"
              type="dashed"
              onClick={() => this.handleCreateModel()}
            >
              <PlusCircleOutlined />
              {i18n.t("Add")}
            </Button>
          </div>
          <JoinCreate
            onModelChanged={this.onModelChanged}
            selectedModel={this.props.selectedModel}
            onCancel={() =>
              this.setState({
                ...this.state,
                showModal: false,
                selectedTables: [],
                prevDimensionAlias: "",
                prevFactAlias: "",
              })
            }
            models={this.state.models}
            tables={this.props.selectedModel.tables}
            updateModelTablesForJoin={this.props.updateModelTablesForJoin}
            getModelTables={this.props.getModelTables}
            onSave={(joins) => this.getJoinsByModel(this.props.selectedModel)}
            visible={this.state.showModal}
            operationType="create"
            prevFactAlias={this.state.prevFactAlias}
            prevDimensionAlias={this.state.prevDimensionAlias}
            join={this.state.joins}
          />
        </Row>
        <Row className="join-list-area">
          <JoinList
            joinComesPlugin={this.props.join}
            isJoinlistAwake={this.props.isJoinlistAwake}
            updateIsJoinListAwake={this.props.updateIsJoinListAwake}
            joins={this.state.joins}
            onDelete={() => this.getJoinsByModel(this.props.selectedModel)}
            onEdit={() => this.onEdit(this.props.selectedModel)}
            onSave={(joins) => this.getJoinsByModel(this.props.selectedModel)}
            getModelList={this.props.getModelList}
            getModelTables={this.props.getModelTables}
            updateModelTablesForJoin={this.props.updateModelTablesForJoin}
            visible={this.props.visible}
            selectedModel={this.props.selectedModel}
            models={this.state.models}
            onModelChanged={this.onModelChanged}
          />
        </Row>
      </div>
    );
  }
}

export default JoinLayout;
