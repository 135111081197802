import React from "react";
import MonacoEditor from "react-monaco-editor";

/**
 * This component consists of:
 *  - `title` which contains: Plugin's title and name, Column's field name & name
 *  - `MonacoEditor` (react-monaco-editor: https://github.com/react-monaco-editor/react-monaco-editor) & its options
 *  - `Button` for apply and cancel
 */
export default class FilterCodeEditor extends React.Component {
    /**
     * `Monaco Editor` Options
     * 
     * More info: https://microsoft.github.io/monaco-editor/api/interfaces/monaco.editor.IEditorOptions.html
     */
    options = {
        minimap: { enabled: false },
        hover: {
            enabled: true,
            above: false,
            sticky: true
        },
        matchBrackets: "always",
        acceptSuggestionOnCommitCharacter: true,
        acceptSuggestionOnEnter: "on",
        accessibilitySupport: "auto",
        autoIndent: true,
        automaticLayout: true,
        codeLens: true,
        colorDecorators: true,
        contextmenu: false,
        cursorBlinking: "smooth",
        cursorSmoothCaretAnimation: false,
        cursorStyle: "line",
        disableLayerHinting: false,
        disableMonospaceOptimizations: false,
        dragAndDrop: false,
        fixedOverflowWidgets: false,
        folding: true,
        foldingStrategy: "auto",
        fontLigatures: true,
        fontSize: 14,
        formatOnPaste: true,
        formatOnType: true,
        glyphMargin: false,
        hideCursorInOverviewRuler: false,
        highlightActiveIndentGuide: true,
        links: true,
        mouseWheelZoom: false,
        multiCursorMergeOverlapping: true,
        multiCursorModifier: "alt",
        overviewRulerBorder: true,
        overviewRulerLanes: 2,
        quickSuggestions: true,
        quickSuggestionsDelay: 0,
        readOnly: false,
        renderControlCharacters: true,
        renderFinalNewline: true,
        renderIndentGuides: true,
        renderLineHighlight: "all",
        renderLineHighlightOnlyWhenFocus: false,
        renderWhitespace: "none",
        revealHorizontalRightPadding: 0,
        roundedSelection: true,
        scrollBeyondLastColumn: 5,
        scrollBeyondLastLine: true,
        selectOnLineNumbers: true,
        selectionClipboard: true,
        selectionHighlight: true,
        showFoldingControls: "mouseover",
        smoothScrolling: true,
        suggestOnTriggerCharacters: true,
        wordBasedSuggestions: true,
        wordSeparators: "~!@#$%^&*()-=+[{]}|;:'\",.<>/?",
        wordWrap: "off",
        wordWrapBreakAfterCharacters: "\t})]?|&,;",
        wordWrapBreakBeforeCharacters: "{([+",
        wordWrapBreakObtrusiveCharacters: ".",
        wordWrapMinified: true,
        wrappingIndent: "none",
        scrollbar: {
            vertical: 'visible',
            horizontal: 'visible'
        }
    };

    onChange = value => {
        this.props.onChange(value);
    }

    onEditorDidMount = (editor, monaco) => {
        this.editor = editor;
        this.monaco = monaco;

        // Disable command palette
        this.editor.addCommand(this.monaco.KeyCode.F1, () => { });
    }

    editorRef = React.createRef();

    render() {
        return (
            <div
                className="ant-input"
                style={{
                    borderRadius: 4,
                    padding: 4,
                    height: "fit-content"
                }}
            >
                <MonacoEditor
                    theme="visp"
                    language={this.props.language}
                    height={this.props.height}
                    value={this.props.value}
                    options={this.options}
                    onChange={this.onChange}
                    editorDidMount={this.onEditorDidMount}
                />
            </div>
        );
    }
}