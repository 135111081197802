import React, { Component } from "react";
import Search from "../../GeneralComponents/Search/Search";
import Text from "../../GeneralComponents/Text/Text";
import i18n from "../../../Utils/i18next";
import { API_BASE } from "../../../config";
import { showNotificationWithIcon } from "../../../Utils/Notification";
import { abort, get } from "../../../Utils/WebService";
import { ExclamationCircleOutlined, LoadingOutlined, WarningOutlined } from "@ant-design/icons";
import { deepCopy } from "../../../Utils/Global";
import BoxItem2 from "../BoxItem2/BoxItem2";
import _ from "lodash";
import { sortByLanguage } from "../../../Utils/SortByLanguage";
import { Pagination } from "antd";

const images = {
    postgresql: require("../../../images/postgresql.png"),
    mssql: require("../../../images/mssql.png"),
    mysql: require("../../../images/mysql.png"),
    oracle: require("../../../images/oracle.png"),
    duckdb: require("../../../images/selfService.png"),
    mongodb: require("../../../images/mongodb.png"),
};

/**
 * This components consists of datasource connection list and search bar
 */
export default class SessionVariableConnectionList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            connections: [],
            searchTempData: [],
            searchTempDataSize: 0,
            currentPage: 1,
            pageSize: this.getPageSize() - (this.props.dataSource ? 1 : 0),
            showConfiguration: false,
            editData: {},
            changeSelectedDataSourceVisible: false,
            selectedConnection: this.props.dataSource,
            style: {
                display: "flex",
                alignItems: "center",
                padding: "16px 8px",
                cursor: "pointer",
                border: "1px solid #ccc",
                borderRadius: "4px",
                marginBottom: "8px",
                backgroundColor: "rgb(240, 240, 240)"
            }
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.dataSource?.id !== this.state.selectedConnection?.id) {
            let searchResult = this.search(this.state.searchValue, this.state.connections, this.state.pageSize, nextProps.dataSource?.id);

            this.setState({
                ...this.state,
                selectedConnection: nextProps.dataSource,
                searchTempData: searchResult.data,
                searchTempDataSize: searchResult.size
            });
        }
    }

    /**
     * Get datasource connections
     */
    getDatasources = () => {
        this.setState({
            ...this.state,
            refresh: true
        });

        let url = `${API_BASE}/dataSource`;

        const successFunc = (response) => {
            let data = response.data;

            if (data) {
                let connections = data.map((item, index) => ({
                    id: item.id,
                    title: item.displayName ? item.displayName : item.name,
                    name: item.name,
                    displayName: item.displayName,
                    dataSourceType: item.dataSourceType,
                    schemaName: item.schemaName,
                    config: JSON.stringify(item.config, null, "  "),
                }));

                connections = this.sortConnections(connections);

                let searchResult = this.search(this.state.searchValue, connections, this.state.pageSize);

                this.setState({
                    ...this.state,
                    connections: connections,
                    searchTempData: searchResult.data,
                    searchTempDataSize: searchResult.size,
                    showConfiguration: false,
                    editData: {},
                    changeSelectedDataSourceVisible: false,
                    refresh: false
                })
            }
        };

        const errorFunc = (error) => {
            showNotificationWithIcon(error, null, "error");

            this.setState({
                ...this.state,
                refresh: false
            })
        }

        get(url, successFunc, errorFunc, false);
    }

    componentDidMount() {
        window.addEventListener("resize", this.updatePageSize);

        this.getDatasources();
    }

    /**
     * Sets temp object's datasource id
     */
    changeConnection = (connection) => {
        let selectedConnection = connection.id === this.state.selectedConnection?.id ? null : connection;
        let searchResult = this.search(this.state.searchValue, this.state.connections, this.state.pageSize, selectedConnection);

        this.setState({
            ...this.state,
            currentPage: 1,
            selectedConnection: selectedConnection,
            searchTempData: searchResult.data,
            searchTempDataSize: searchResult.size
        }, () => this.props.onSelect(this.state.selectedConnection));
    }

    /**
     * Prevents parent events
     */
    preventParentEvent = (event) => {
        if (event) {
            event.cancelBubble = true;
            event.stopPropagation();
        }
    }

    /**
     * Cancel configuration modal
     */
    cancel = () => {
        this.setState({
            ...this.state,
            showConfiguration: false,
            editData: {},
            changeSelectedDataSourceVisible: false,
            selectedConnection: {},
            selectedConnections: []
        });
    }

    /**
     * Refresh datasource list
     */
    refresh = () => {
        this.getDatasources();
    }

    /**
     * Search connections by title
     */
    search = (
        searchValue,
        data,
        pageSize,
        selectedConnection = this.state.selectedConnection
    ) => {
        let searchResult = deepCopy(data);

        if (searchValue && searchValue !== "") {
            searchResult = searchResult.filter(connection =>
                connection.title?.toLowerCase()?.includes(searchValue?.toLowerCase())
            );
        }

        searchResult = searchResult.filter(c => c.id !== selectedConnection?.id);
        searchResult = this.sortConnections(searchResult);
        searchResult = this.prepareDataForPagination(searchResult, pageSize);

        return searchResult;
    }

    /**
     * Searchbar change handler
     */
    handleSearch = async (searchValue, pageSize = this.state.pageSize) => {
        clearTimeout(this.inputTimer);

        this.inputTimer = setTimeout(() => {
            let searchResult = this.search(searchValue, this.state.connections, pageSize);

            this.setState({
                ...this.state,
                currentPage: 1,
                searchValue: searchValue,
                searchTempData: searchResult.data,
                searchTempDataSize: searchResult.size
            });
        }, 300);
    };

    sortConnections = (connections) => {
        let tempConnections = deepCopy(connections);

        tempConnections = tempConnections.sort((a, b) => sortByLanguage(a.title, b.title));

        return tempConnections;
    }

    /**
     * Connection box item
     * 
     * @param {*} connection 
     * @returns 
     */
    connectionItem = connection => {
        if (!connection) return null;

        let selectedExists = this.state.connections.findIndex(c => c.id === this.state.selectedConnection?.id) !== -1;
        let isSelected = this.state.selectedConnection?.id === connection?.id;

        return (
            <BoxItem2
                title={isSelected && !selectedExists ? (
                    <>
                        {connection.title}
                        <br />
                        <span style={{ color: "red" }}>
                            {i18n.t("SessionVariables.Errors.DS_NOT_FOUND")}
                        </span>
                    </>
                ) : connection.title}
                className={isSelected && selectedExists ? "selected" : ""}
                tooltipPlacement={"left"}
                style={{
                    padding: "24px 16px 24px 8px",
                    cursor: "pointer",
                    justifyContent: "space-between",
                    marginBottom: isSelected && 16
                }}
                onClick={() => this.changeConnection(connection)}
            >
                <img
                    alt=""
                    src={images[connection.dataSourceType]}
                    style={{
                        padding: "0",
                        borderRadius: "50%",
                        height: "24px",
                        width: "24px"
                    }}
                />
                <Text
                    style={{
                        margin: "0 8px",
                        width: "100%",
                        textAlign: "center",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        fontSize: "13px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: isSelected && !selectedExists && "red"
                    }}
                >
                    {
                        isSelected && !selectedExists && <ExclamationCircleOutlined style={{ marginRight: 4 }} />
                    }
                    {connection.title}
                </Text>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "right",
                    }}
                >

                </div>
            </BoxItem2>
        )
    }

    /**
     * Updates current page
     * 
     * @param {*} page 
     */
    handlePageChange = page => {
        this.setState({
            currentPage: page
        });
    }

    /**
     * Calculates page size based on window height
     *
     * @returns 
     */
    getPageSize = () => {
        let height = Math.max(window.innerHeight * 0.9, 450) - 150;
        let pageSize = Math.round(height / 60);

        return (pageSize > 5 ? pageSize : 5) - (this.state?.selectedConnection ? 1 : 0);
    }

    /**
     * Updates page size and data
     */
    updatePageSize = async () => {
        clearTimeout(this.resizeTimer);

        let pageSize = this.getPageSize();

        setTimeout(() => {
            let searchResult = this.search(this.state.searchValue, this.state.connections, pageSize);

            this.setState({
                pageSize: pageSize,
                searchTempData: searchResult.data,
                searchTempDataSize: searchResult.size
            });
        }, 10)
    }

    /**
     * Perapes array chunks based on page size
     * 
     * @param {*} data 
     * @param {*} pageSize 
     * @returns 
     */
    prepareDataForPagination = (data, pageSize = this.state.pageSize) => {
        let tempData = deepCopy(data);
        let paginationData = [];

        for (let i = 0; i < tempData.length; i += pageSize) {
            paginationData.push(tempData.slice(i, i + pageSize));
        }

        return {
            data: paginationData,
            size: tempData.length
        };
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePageSize);
    }

    render() {
        let data = this.state.searchTempData[this.state.currentPage - 1];

        return (
            <div
                style={{
                    height: "100%",
                    overflowY: "auto",
                    overflowX: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    alignContent: "center",
                    justifyItems: "center",
                    padding: "0 2px"
                }}
            >
                <div
                    style={{
                        padding: "0 6px"
                    }}
                >
                    <Text
                        style={{
                            fontSize: "1rem",
                            margin: "16px 0 8px 0",
                            display: "block",
                            width: "100%",
                            textAlign: "center",
                            color: "#225f8f"
                        }}
                    >
                        {i18n.t("DataConnections.Connections")}
                    </Text>
                    <Search
                        paddingNone={true}
                        marginBottom={"8px"}
                        inputStyle={{ minWidth: "auto" }}
                        id={"searchData"}
                        width="100%"
                        searchBarVisible={true}
                        value={this.state.searchValue}
                        onChange={event => this.handleSearch(event?.target?.value)}
                    ></Search>
                </div>

                <div
                    style={{
                        height: "100%",
                        padding: "8px 6px",
                        overflow: "hidden auto"
                    }}
                >
                    {
                        this.state.refresh ? (
                            <Text
                                className="empty-area-text"
                            >
                                <LoadingOutlined className="icon" />
                                <span>{i18n.t("Loading")}...</span>
                            </Text>
                        ) : (
                            <div
                                style={{
                                    width: "100%",
                                    height: "100%"
                                }}
                            >
                                {
                                    this.state.selectedConnection && this.connectionItem(this.state.selectedConnection)
                                }
                                {
                                    data?.length > 0
                                        ? data.map(c => this.connectionItem(c))
                                        : this.state.selectedConnection?.title?.toLowerCase()?.includes(this.state.searchValue?.toLowerCase())
                                            ? null
                                            : (
                                                <Text
                                                    className="empty-area-text"
                                                    style={{
                                                        height: "calc(100% - 64px)"
                                                    }}
                                                >
                                                    <WarningOutlined className="icon" />
                                                    <span style={{ marginTop: "16px" }}>
                                                        {i18n.t("Designer.CouldNotFoundAnyDatasource")}
                                                    </span>
                                                </Text>
                                            )
                                }
                            </div>
                        )
                    }
                </div>

                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        padding: "8px 6px 0 6px"
                    }}
                >
                    <Pagination
                        simple
                        showSizeChanger
                        hideOnSinglePage
                        defaultCurrent={1}
                        size="small"
                        total={this.state.searchTempDataSize}
                        current={this.state.currentPage}
                        pageSize={this.state.pageSize}
                        onChange={this.handlePageChange}
                        style={{
                            fontSize: 12,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    />
                </div>
            </div>
        )
    }
}