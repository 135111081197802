import { Checkbox } from "antd";
import React, { Component } from "react";
import { FiEdit2 } from "react-icons/fi";
import UploadTableColumn from "./UploadTableColumn";
import i18n from "../../Utils/i18next";
import Tooltip from "../GeneralComponents/Tooltip/Tooltip";

export default class UploadTable extends Component {
  render() {
    const maxColumnCount = 7;
    const { sheets, selectedSheet, uploadParameters } = this.props;
    const hasHeader = uploadParameters ? uploadParameters.header : false;

    if (sheets.size === 0) {
      return null;
    } else {
      const sheet = sheets.get(selectedSheet);

      return (
        <div className="upload-table">
          <div className="upload-table-title">
            {selectedSheet}
            <span className="sub-title">
              ({i18n.t("FileUpload.First10RowWithHeader")})
            </span>
            <span style={{display: "inline-block", position: "absolute", right: "10px"}}>
              <Tooltip tooltip={i18n.t("FileUpload.SameColumnNamesChanged")}>
              (i)
              </Tooltip>
            </span>
          </div>

          <div className="upload-table-container">
            <div
              style={{
                width:
                  (sheet.data[0].length <= maxColumnCount
                    ? "100"
                    : sheet.data[0].length * (100 / maxColumnCount)) + "%",
              }}
            >
              <div className="upload-table-header">
                {(Object.keys(sheet.columns)).sort(customSort).map((columnKey, index) => (
                  <UploadTableColumn
                    sheet={sheet}
                    key={index}
                    columnKey={columnKey}
                    applyChangesToColumnInSheet={
                      this.props.applyChangesToColumnInSheet
                    }
                  />
                ))}
              </div>

              <div>
                {sheet.data.map(
                  (data, index) =>
                    data.length > 0 && (
                      <div className="upload-table-row" key={index}>
                        {[...data].map((datum, index) => (
                          <span
                            key={index}
                            className="upload-table-cell"
                            style={{
                              width: 100 / data.length + "%",
                            }}
                          >
                            {datum === 0 || datum ? (
                              datum
                            ) : (
                              <span
                                style={{
                                  color: "#dc9a9a",
                                  fontStyle: "italic",
                                }}
                              >
                                NULL
                              </span>
                            )}
                          </span>
                        ))}
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

/**
 * True sort for stirng includes number
 * @param {*} a 
 * @param {*} b 
 * @returns 
 */
var customSort = function (a, b) {
  return (Number(a.match(/(\d+)/g)[0]) - Number((b.match(/(\d+)/g)[0])));
}