import i18n from "../../Utils/i18next"

// Default Interaction Objects
export const defaultInteractionObjects = {
  "age-pyramid": {
    trigger: "barClick",
    type: "click",
    name: "Tıklama - Bar",
    output: ["category"],
    description: "BarClickDesc"
  },
}

// Default Reaction Objects
export const defaultReactionObjects = {
  "age-pyramid": {
    id: "filter",
    name: "Filtre",
    description: "desc87",
    type: "general"
  }
}

export const defaultTitleReactions = {
  "age-pyramid": [
    {
      id: "none",
      name: i18n.t("Dashboard.Configuration.Fields.None"),
      description: "desc232",
      type: "private",
      method: "none"
    },
    {
      id: "updateTitle",
      name: i18n.t("Interaction.UpdateTitle"),
      description: "desc232",
      type: "private",
      method: "updateTitle"
    },
    {
      id: "resetTitle",
      name: i18n.t("Interaction.ResetTitle"),
      description: "desc233",
      type: "private",
      method: "resetTitle"
    }
  ]
}