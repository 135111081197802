import React, { Component } from "react";
import Parameter from "./Parameter";

/** What parameter list component */
export default class List extends Component {
  render() {
    return (
      <div className={"whatIf-list"}>
        {this.props.parameters.map(parameter => (
          <Parameter
            readOnly={this.props.readOnly}
            setSelectedParameters={this.props.setSelectedParameters}
            removeParameter={this.props.removeParameter}
            updateCurrentValue={this.props.updateCurrentValue}
            parameter={parameter}
          />
        ))}
      </div>
    );
  }
}
