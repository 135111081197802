import React, { Component } from "react";
import $ from "jquery";

export default class EditableContent extends Component {
  onChange = e => {
    this.props.onChange(this.ruleInput.innerHTML);
  };

  componentDidMount() {
    $(this.ruleInput).html(this.props.content);
    document.addEventListener("click", e => this.isClickedTag(e, this));
  }

  componentWillUnmount() {
    document.removeEventListener("click", e => this.isClickedTag(e, this));
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.content !== this.props.content &&
      nextProps.selection === true
    ) {
      $(this.ruleInput).html(nextProps.content);
      nextProps.setSelection(false);
      setCursorPosition(this.ruleInput, undefined);
    }
  }

  ruleInput;

  /** if rule doesn't exist any space at last, adds a space. */
  addSpaceLastIfNecessary = html => {
    let length = html.length;
    let isHtmlHasNoSpacesOnEndAndEndsWithTag =
      html !== "" &&
      html.substring(length - 6) !== "&nbsp;" &&
      html.substring(length - 10) === "</ruletag>";

    if (isHtmlHasNoSpacesOnEndAndEndsWithTag) {
      let ruleInput = $("." + this.props.type).find(".rule-input-box");
      const originalChildNodes = this.findOriginalNodes(ruleInput);

      ruleInput.html(ruleInput.html() + "&nbsp;");

      setCursorPosition(ruleInput[0], originalChildNodes);
    }
  };

  /** Finds child nodes before input change */
  findOriginalNodes = ruleInput => {
    let originalChildNodes = [];
    let ruleElement = ruleInput[0];

    for (let i = 0; i < ruleElement.childNodes.length; i++) {
      let node = ruleElement.childNodes[i];
      originalChildNodes.push(node);
    }

    return originalChildNodes;
  };

  /** Checks the clicked any tag */
  isClickedTag = (e, THIS) => {
    let element = e.target;

    if (element.className === "rule-input-box") {
      this.addSpaceLastIfNecessary(element.innerHTML);
    } else {
      if (element.className === "spanColumn") {
        element = $(element).children()[0];
      }

      if (element.className === "columnName") {
        if (
          $(element)
            .closest(".rule-input")
            .hasClass(THIS.props.type)
        ) {
          let ruleInput = $("." + THIS.props.type).find(".rule-input-box");
          let clickedHTML = $(element).parent()[0].outerHTML;
          let findIndex = ruleInput
            .html()
            .indexOf($(element).parent()[0].outerHTML);
          let lastIndex = findIndex + clickedHTML.length;
          let tag = ruleInput.html();

          const originalChildNodes = this.findOriginalNodes(ruleInput);

          if (tag.substring(lastIndex, lastIndex + 6) !== "&nbsp;") {
            ruleInput.html(
              tag.substr(0, lastIndex) +
                "&nbsp;" +
                tag.substr(lastIndex, tag.length)
            );

            setCursorPosition(ruleInput[0], originalChildNodes);
            THIS.props.onChange(ruleInput.html());
          }
        }
      }
    }
  };

  render() {
    let minHeight = this.props.minHeight ? this.props.minHeight : undefined;
    let style = {}

    if (minHeight) {
      style["minHeight"] = minHeight;
    }

    return (
      <div
        ref={ruleInput => (this.ruleInput = ruleInput)}
        class="rule-input-box"
        contenteditable="true"
        onInput={e => this.onChange(e)}
        onFocus={this.props.onFocus}
        style={style}
      ></div>
    );
  }
}

function setCursorPosition(element, originalChildNodes = undefined) {
  try {
    let tag = element;

    if (tag.textContent.length == 0) {
      tag.focus();
      return;
    }

    // Creates range object
    let setpos = document.createRange();

    // Creates object for selection
    let set = window.getSelection();

    let changedNodeIndex = tag.childNodes.length - 1;

    if (originalChildNodes != undefined) {
      for (let i = 0; i < tag.childNodes.length; i++) {
        let node = tag.childNodes[i];

        if (
          originalChildNodes[i] == undefined ||
          node.textContent !== originalChildNodes[i].textContent
        ) {
          changedNodeIndex = i;
          break;
        }
      }
    }

    let childNode = tag.childNodes[changedNodeIndex];

    // Set start position of range
    setpos.setStart(childNode, childNode.textContent.length);

    // Collapse range within its boundary points
    // Returns boolean
    setpos.collapse(true);

    // Remove all ranges set
    set.removeAllRanges();

    // Add range with respect to range object.
    set.addRange(setpos);

    // Set cursor on focus
    tag.focus();
  } catch (error) {}
}
