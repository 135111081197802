import React, { Component } from 'react'
import i18n from '../../../../../Utils/i18next';
import Button from '../../../../GeneralComponents/Button/Button';
import PopupContainer from '../../../../GeneralComponents/PopupContainer/PopupContainer';
import { ExclamationCircleOutlined } from '@ant-design/icons';

/**
 * Dashboard mail footer component contains send and discard buttons
 */
class DashboardMailFooter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showConfirm: false
        };
    }

    render() {
        return (
            <div
                className="dashboard-mail-footer"
            >
                <Button
                    block={false}
                    style={{
                        borderBottom: "2px solid"
                    }}
                    onClick={() => this.setState({ ...this.state, showConfirm: true })}
                >
                    {i18n.t("DashboardMail.DeleteDraft")}
                </Button>
                <Button
                    block={false}
                    className="general-button"
                    onClick={this.props.send}
                >
                    {i18n.t("DashboardMail.Send")}
                </Button>
                {
                    this.state.showConfirm ? (
                        <div className='discard-confirm'>
                            <PopupContainer className="discard-confirm-popup">
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "start",
                                        justifyContent: "left"
                                    }}
                                >
                                    <ExclamationCircleOutlined
                                        style={{
                                            color: "#FF9800",
                                            marginRight: 8,
                                            fontSize: 25,
                                        }}
                                    />
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "left",
                                                textAlign: "left",
                                                fontSize: 19
                                            }}
                                        >
                                            {i18n.t("DashboardMail.DeleteDraftConfirm")}
                                        </div>
                                        <div
                                            style={{
                                                marginBottom: 24,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "left",
                                                fontSize: 14,
                                                textAlign: "left",
                                                color: "rgba(0, 0, 0, 0.65)"
                                            }}
                                        >
                                            {i18n.t("DashboardMail.DeleteDraftDesc")}
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "end",
                                    justifyContent: "right"
                                }}>
                                    <Button
                                        block={false}
                                        style={{
                                            borderBottom: "2px solid",
                                            marginRight: 8
                                        }}
                                        onClick={() => this.setState({ ...this.state, showConfirm: false })}
                                    >
                                        {i18n.t("Cancel")}
                                    </Button>
                                    <Button
                                        block={false}
                                        className="general-button discard-confirm-button"
                                        onClick={() => {
                                            this.props.reset();
                                            this.setState({ ...this.state, showConfirm: false })
                                        }}
                                    >
                                        {i18n.t("DashboardMail.DeleteDraft")}
                                    </Button>
                                </div>
                            </PopupContainer>
                        </div>
                    ) : null
                }
            </div>
        );
    }
}

export default DashboardMailFooter;