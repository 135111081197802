const clone = require("rfdc")();

/**
 *
 * @param {*} url
 * It splits the ip address and database name according to the incoming url
 *
 */
const ipAndDatabaseNameParse = (url, dataType) => {
  if (dataType === "oracle") {
    return ipAndDatabaseNameParseForOracle(url);
  } else if (dataType === "mssql") {
    return ipAndDatabaseNameParseForMssql(url);
  } else {
    let ipAndDatabaseName = url
      .substring("jdbc:" + dataType + "://".length, url.length)
      .split("/");

    let databaseName = ipAndDatabaseName[ipAndDatabaseName.length - 1];
    ipAndDatabaseName.pop();

    let ipAndPort = ipAndDatabaseName[2];
    let ip = ipAndPort.split(":")[0];
    let port = ipAndPort.split(":")[1];

    return { ip: ip, databaseName: databaseName, port: port };
  }
};

/**
 *
 * @param {*} url
 * It splits the ip address and database name according to the incoming url for ORACLE
 *
 */
const ipAndDatabaseNameParseForOracle = url => {
  let ipAndPortAndDatabaseName = url.split(":@")[1].split(":");

  let databaseName = ipAndPortAndDatabaseName[1].split("/")[1];
  let port = ipAndPortAndDatabaseName[1].split("/")[0];
  let ip = ipAndPortAndDatabaseName[0];

  return { ip: ip, databaseName: databaseName, port: port };
};

/**
 *
 * @param {*} url
 * It splits the ip address and database name according to the incoming url for SQL SERVER
 *
 */
const ipAndDatabaseNameParseForMssql = url => {
  let databaseName = url.split("databaseName=")[1].split(";")[0];
  let port = url.split("port=")[1].split(";")[0];
  let ip = url.split("serverName=")[1].split(";")[0];

  return { ip: ip, databaseName: databaseName, port: port };
};

/**
 * This method fills the input fields for the connection information requested to be updated.
 */
export const setFieldForConnection = (props, dataType) => {
  let connectionInformation = {};
  const editData = clone(props.editData);
  if (editData.config) {
    const config = JSON.parse(editData.config);
    connectionInformation = ipAndDatabaseNameParse(config.url, dataType);
    connectionInformation.username = config.username;
    connectionInformation.password = config.password;
  } else {
    connectionInformation = ipAndDatabaseNameParse(
      editData.connection.url,
      dataType
    );
    connectionInformation.username = editData.connection.username;
    connectionInformation.password = editData.connection.password;
  }

  return {
    connectionName: connectionInformation.displayName,
    ip: connectionInformation.ip,
    port: connectionInformation.port,
    databaseName: connectionInformation.databaseName,
    schemaName: editData.schemaName,
    username: connectionInformation.username,
    password: connectionInformation.password,
    displayName: editData.displayName,
    id: editData.id,
    name: editData.name
  };
};
