import React, { Component } from "react";
import TitleWithIcon from "../GeneralComponents/TitleWithIcon";
import DropdownMenu from "../GeneralComponents/DropdownMenu";
import i18n from "../../Utils/i18next";
import Tooltip from "../GeneralComponents/Tooltip/Tooltip";

class DashboardLabelHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAll: false,
      showSortMenu: false
    };
  }

  sortMenuData = [
    { key: "name_asc", title: i18n.t("LandingPage.DashboardLabel.NameAscending"), icon: [{icon: "fa fa-sort-alpha-down"}], active: false },
    { key: "name_desc", title: i18n.t("LandingPage.DashboardLabel.NameDescending"), icon: [{icon: "fa fa-sort-alpha-down-alt"}], active: false },
    { key: "viewCount_desc", title: i18n.t("LandingPage.DashboardLabel.MostViewed"), icon: [{icon: "fa fa-long-arrow-alt-down"}, {icon: "fa fa-eye"}], active: false },
    { key: "viewCount_asc", title: i18n.t("LandingPage.DashboardLabel.LeastViewed"), icon: [{icon: "fa fa-long-arrow-alt-up"}, {icon: "fa fa-eye"}], active: false },
    { key: "lastUpdatedDate_desc", title: i18n.t("LandingPage.DashboardLabel.NewestUpdated"), icon: [{icon: "fa fa-long-arrow-alt-down"}, {icon: "fa fa-edit"}], active: false },
    { key: "lastUpdatedDate_asc", title: i18n.t("LandingPage.DashboardLabel.OldestUpdated"), icon: [{icon: "fa fa-long-arrow-alt-up"}, {icon: "fa fa-edit"}], active: false }
  ];

  changeShowMode = () => {
    this.props.switchListMode(!this.state.showAll);
    this.setState({ ...this.state, showAll: !this.state.showAll });
  };

  changeSortMenuState = (status) => {
    this.setState({ ...this.state, showSortMenu: status });
  };

  confirmSort = data => {  
    let dataLabel = {...this.props.dataLabel}
    let sortKey = dataLabel.sortKey;
    let sortType = dataLabel.sortType;
    let isTargetSortByName = data.event === this.sortMenuData[0].title || data.event === this.sortMenuData[1].title ? true : false
    let isTargetSortByViewCount = data.event === this.sortMenuData[2].title || data.event === this.sortMenuData[3].title ? true : false
    let isTargetSortByLastUpdatedDate = data.event === this.sortMenuData[4].title || data.event === this.sortMenuData[5].title ? true : false

    if (isTargetSortByName) {
      sortKey = "name";
      sortType = data.event === this.sortMenuData[0].title ? "asc" : "desc";
    } else if (isTargetSortByViewCount) {
      sortKey = "viewCount";
      sortType = data.event === this.sortMenuData[3].title ? "asc" : "desc";
    } else if (isTargetSortByLastUpdatedDate) {
      sortKey = "lastUpdatedDate";
      sortType = data.event === this.sortMenuData[5].title ? "asc" : "desc";
    }

    this.changeSortMenuState();
    
    dataLabel.sortKey = sortKey;
    dataLabel.sortType = sortType;

    setTimeout(() => {
      this.props.sortDashboardBy(this.props.data, dataLabel);
    }, 1);
  };

  render() {
    let sortKey = this.props.dataLabel.sortKey;
    let sortType = this.props.dataLabel.sortType;
    let sortMenuToolbarTitle = i18n.t("LandingPage.SortDashboards");
    let selectedSortMenuItem = {};

    if (sortKey != null && sortType != null) {
      this.sortMenuData.map(data => {
        data.active = false;
      });

      selectedSortMenuItem = this.sortMenuData.find(
        (item) => item.key === (sortKey+"_"+sortType)
      );
      selectedSortMenuItem.active = true;
      sortMenuToolbarTitle = selectedSortMenuItem.title;
    }

    return (
      <div className="label-bar">
        <div>
          <TitleWithIcon
            title={this.props.dataLabel.translateName}
            color={this.props.dataLabel.color}
            className={"colored-shape"}
            style={{display: "flex", alignItems: "center"}}
          />
        </div>
        <div style={{minWidth: "fit-content", marginBottom: ".5rem"}}>
          {
            sortKey != null && sortType != null
              ? <DropdownMenu
                  autoClose={false}
                  show={this.state.showSortMenu}
                  drop="left"
                  before={"noBefore"}
                  data={this.sortMenuData}
                  requestObject={this.props.dataLabel}
                  width={"fit-content"}
                  prefix={"sort"}
                  toggle={{ title: sortMenuToolbarTitle, icon: [{icon: "fa fa-sort"}], iconPosition: "right"}}
                  onToggle={this.changeSortMenuState}
                  toggleType={"singleIcon"}
                  responseFunc={data => {this.confirmSort(data)}}
                  variant={"default"}
                  tooltip={i18n.t("LandingPage.SortDashboards")}
                  className={"dashboard-label-sort-icon"}
                />
              : null
          }
          <Tooltip
            tooltip={this.state.showAll ? i18n.t("LandingPage.CollapseLabel") : i18n.t("LandingPage.ExpandLabel")}
            tooltipPlacement={"left"}
          >
            <div
              href="javascript:;"
              class="show-all"
              style={{ display: "inline-block", marginLeft: "20px" }}
              onClick={() => this.changeShowMode()}
            >
              <i
                class={this.state.showAll ? "fas fa-compress-alt" : "fas fa-expand-alt"}
                style={{
                  position: "relative",
                  cursor: "pointer",
                }}
              ></i>
            </div>
          </Tooltip>
        </div>
      </div>
    );
  }
}

export default DashboardLabelHeader;
