import i18n from "./i18next";

/*
 * Sorts by language
 */
export const sortByLanguage = (a, b) => {
  let currentLanguage = i18n.language;
  let sortedNameA = a;
  let sortedNameB = b;
  let alphabet =
    currentLanguage === "tr"
      ? "0123456789AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpRrSsŞşTtUuÜüVvYyZzQqWwXx'" +
        '/[ `!@#$%^&*()_+-=[]{};:"\\|,.<>/?~]/'
      : "0123456789AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZzÇçĞğıİÖöŞşÜü'" +
        '/[ `!@#$%^&*()_+-=[]{};:"\\|,.<>/?~]/';

  if (sortedNameA.length === 0 || sortedNameB.length === 0) {
    return sortedNameA.length - sortedNameB.length;
  }

  for (let i = 0; i < sortedNameA.length && i < sortedNameB.length; i++) {
    let sortedNameAIndex = alphabet.indexOf(sortedNameA[i]);
    let sortedNameBIndex = alphabet.indexOf(sortedNameB[i]);

    if (sortedNameAIndex !== sortedNameBIndex) {
      return sortedNameAIndex - sortedNameBIndex;
    }
  }
};
