import { Radio } from "antd";
import React, { Component } from "react";
import i18n from "../../../../../Utils/i18next";
import { deepCopy } from "../../../../../Utils/Global";
import { convertUnit } from "../operations"
import Text from "../../../../GeneralComponents/Text/Text";

export default class GeneralSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            measurementUnits: [
                {
                    label: i18n.t("Designer.Unit.Inch"),
                    value: "inch"
                },
                {
                    label: i18n.t("Designer.Unit.Millimeter"),
                    value: "mm"
                },
                {
                    label: i18n.t("Designer.Unit.Centimeter"),
                    value: "cm"
                },
                {
                    label: i18n.t("Designer.Unit.Point"),
                    value: "pt"
                }
            ],
        }
    }

    /**
     * Converts all available measure units from old unit to new one
     */
    convertMeasures = (oldUnit, newUnit) => {
        let options = deepCopy(this.props.options);

        if (oldUnit !== newUnit) {
            let notAllowed = [
                "paperSize",
                "firstPageNumber",
                "fitToWidth",
                "fitToHeight",
            ];

            for (let key of Object.keys(options)) {
                let value = options[key];
                let isOptionNumber = typeof value === "number";
                let isOptionAllowed = notAllowed.includes(key) === false;

                if (isOptionNumber && isOptionAllowed) {
                    options[key] = convertUnit(value, oldUnit, newUnit);
                }
            }

            options.measurementUnit = newUnit;

            this.props.setOptions(options);
        }
    }

    render() {
        const { options } = this.props;

        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "40%"
                }}
            >
                <Text
                    style={{
                        marginBottom: 8,
                        fontWeight: 600
                    }}
                >
                    {i18n.t("Designer.FileType")}
                </Text>
                <Radio.Group
                    onChange={(event) => this.props.setOption("extension", event.target.value)}
                    value={options.extension}
                    style={{
                        marginBottom: 8,
                    }}
                >
                    {
                        this.props.extensions.map(extension => (
                            <Radio
                                value={extension.value}
                            >
                                {extension.label}
                            </Radio>
                        ))
                    }
                </Radio.Group>

                <Text
                    style={{
                        marginTop: 8,
                        marginBottom: 8,
                        fontWeight: 600
                    }}
                >
                    {i18n.t("Designer.FileLanguage")}
                </Text>
                <Radio.Group
                    onChange={(event) => this.props.setOption("language", event.target.value)}
                    value={options.language}
                    style={{
                        marginBottom: 8,
                    }}
                >
                    <Radio value={"en"}>{`${i18n.t("Designer.English")}`}</Radio>
                    <Radio value={"tr"}>{`${i18n.t("Designer.Turkish")}`}</Radio>
                </Radio.Group>

                <Text
                    style={{
                        marginTop: 8,
                        marginBottom: 8,
                        fontWeight: 600
                    }}
                >
                    {i18n.t("Designer.MeasurementUnit")}
                </Text>
                <Radio.Group
                    onChange={(event) => this.convertMeasures(options.measurementUnit, event.target.value)}
                    value={options.measurementUnit}
                    style={{
                        marginBottom: 8,
                    }}
                >
                    {
                        this.state.measurementUnits.map(unit => (
                            <Radio
                                value={unit.value}
                            >
                                {unit.label}
                            </Radio>
                        ))
                    }
                </Radio.Group>
            </div>
        );
    }
}