import React, { Component } from "react";
import PopupContainer from "../../../../GeneralComponents/PopupContainer/PopupContainer";
import Text from "../../../../GeneralComponents/Text/Text";
import i18n from "../../../../../Utils/i18next";
import { showNotificationWithIcon } from "../../../../../Utils/Notification";
import EnableSwitch from "./EnableSwitch";
import TimerInput from "./TimerInput";
import PluginList from "./PluginList";
import ButtonArea from "./ButtonArea";
import { startTimer, stopTimer } from "./autoRefreshMethods";

const clone = require("rfdc")();

/**
 * Auto refresh for dashboard - sec
 */
export default class AutoRefresh extends Component {
  constructor(props) {
    super(props);

    this.defaultState = {
      second: 1,
      exceptedPlugins: new Set(),
      enable: false,
      timerStatus: false
    };

    this.state = clone(this.defaultState);
  }

  componentWillMount() {
    if (this.props.autoRefresh != undefined) {
      let newState = clone(this.props.autoRefresh);

      if (
        this.checkDashboardHasAutoRefresh(this.props) &&
        this.autoRefreshIsEnable(this.props)
      ) {
        newState.timerStatus = true;
        stopTimer();
        startTimer(this.props.refreshAll, this.props.autoRefresh.second);
      }

      this.setState(newState);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dashboard.id !== this.props.dashboard.id) {
      let newState = clone(this.defaultState);

      stopTimer();

      if (nextProps.autoRefresh) {
        newState = clone(nextProps.autoRefresh);
        newState.timerStatus = false;

        if (nextProps.autoRefresh.second > 0 && nextProps.autoRefresh.enable) {
          startTimer(nextProps.refreshAll, nextProps.autoRefresh.second);
          newState.timerStatus = true;
        } else {
          newState.timerStatus = false;
        }
      }

      this.setState(newState);
    }
  }

  /** check valid auto refresh */
  checkDashboardHasAutoRefresh = props => {
    return props.autoRefresh && props.autoRefresh.second > 0;
  };

  /** check auto refresh is valid */
  autoRefreshIsEnable = props => {
    return props.autoRefresh.enable;
  };

  /** Switch enable or disable to auto refresh. */
  enableDisable = () => {
    this.setState({ ...this.state, enable: !this.state.enable });
  };

  /** set time on change */
  setTimeOnChange = second => {
    this.setState({
      ...this.state,
      second: second
    });
  };

  /** add or delete excepted plugin in list. */
  updateExceptedPlugin = (pluginId, status) => {
    let exceptedPlugins = this.state.exceptedPlugins;

    if (status) {
      exceptedPlugins.add(pluginId);
    } else {
      exceptedPlugins.delete(pluginId);
    }

    this.setState({
      ...this.state,
      exceptedPlugins: exceptedPlugins
    });
  };

  /** set default auto refresh state */
  setDefault = () => {
    this.setState(clone(this.defaultState));
  };

  /** apply auto refresh, start timer and post auto refresh object to dashboard crud */
  apply = () => {
    if (this.state.timerStatus) {
      stopTimer();
    }

    let timerStatus;
    if (this.state.enable && this.state.second > 0) {
      timerStatus = true;
      startTimer(this.props.refreshAll, this.state.second);
    } else {
      timerStatus = false;
      stopTimer();
    }

    this.setState({
      ...this.state,
      timerStatus: timerStatus
    });

    let autoRefresh = {
      second: this.state.second,
      exceptedPlugins: clone(this.state.exceptedPlugins),
      enable: this.state.enable
    };

    this.props.setAutoRefreshToDashboard(autoRefresh);
    this.props.changeAutoRefreshVisibility(false);

    let savedAutoRefresh = this.props.autoRefresh;

    let notification = i18n.t("Dashboard.AutoRefresh.SuccessfullyEnabled");

    if (!savedAutoRefresh) {
      notification = i18n.t(
        "Dashboard.AutoRefresh.AutoRefreshSuccessfullyAddedToDashboard"
      );
    }

    if (savedAutoRefresh && !this.state.enable) {
      notification = i18n.t("Dashboard.AutoRefresh.SuccessfullyDisabled");
    }

    showNotificationWithIcon(notification, null, "success");
  };

  render() {
    return (
      <div
        className={"auto-refresh-area"}
        style={{ display: this.props.visibility ? "block" : "none" }}
      >
        <PopupContainer
          width="400px"
          constantHeight="460px"
          textAlign="left"
          background={"rgb(251, 251, 251)"}
          color="#505050"
        >
          <Text style={{ textAlign: "center" }} type="h5">
            {i18n.t("Dashboard.AutoRefresh.AutoRefresh")}
          </Text>
          <Text
            type="span"
            style={{
              color: "rgb(162, 162, 162)",
              marginBottom: "18px",
              textAlign: "center",
              whiteSpace: "normal",
              fontSize: "13px",
              display: "block",
              lineHeight: "15px"
            }}
          >
            {i18n.t("Dashboard.AutoRefresh.Description")}
          </Text>

          <EnableSwitch
            enable={this.state.enable}
            enableDisable={this.enableDisable}
          />
          <TimerInput
            enable={this.state.enable}
            second={this.state.second}
            setTimeOnChange={this.setTimeOnChange}
          />
          <PluginList
            enable={this.state.enable}
            updateExceptedPlugin={this.updateExceptedPlugin}
            exceptedPlugins={this.state.exceptedPlugins}
          />

          <ButtonArea apply={this.apply} setDefault={this.setDefault} />
        </PopupContainer>
      </div>
    );
  }
}
