export const configuration = {
    distanceWithGrid: 30,
    showCondForm: true,
    strokeWidth: 2,
    showValuesOnRadar: false,
    ranges: [],
    legendPosition: "right",
    rangeFontSize: 12,
    categoryFontSize: 11,
    size: 400,
    levels: 5,
    pointSize: 4,
    fillOpacity: 0.15,
    strokeOpacity: 1,
    ownScale: false,
    legend: true,
};

export const radarActions = [
    {
        trigger: "clickSlice",
        type: "click",
        output: ["category"],
        name: "PointClick",
        description: "clickSlice"
    },
];

export const reactions = [
    {
        id: "filter",
        name: "Filtre",
        description: "desc87", //'Accepts a column map and value and filters the report if the subject area matches.',
        type: "general"
    },
];

export const conditionalFormatColumnMap = ["category", "measure"];
export const conditionalFormatTargetMap = ["measure"];
export const conditionalFormatOptions = ["backgroundColor"];

export const conversionMap = {
    "cylinder-bar": {
        columnMap: {
            label: {
                name: "Label",
                from: ["category"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            values: {
                name: "Values",
                from: ["measure"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
    "age-pyramid": {
        columnMap: {
            category: {
                name: "Category",
                from: ["category"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            either: {
                name: "Either",
                from: {"measure": [0]},
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            other: {
                name: "Other",
                from: {"measure": [1]},
                multiple: false,
                minimumColumnSize: 0,
                required: false
            }
        }
    },
    "line-bar": {
        columnMap: {
            category: {
                name: "Category",
                from: ["category"],
                multiple: false,
                minimumColumnSize: 1,
            },
            columns: {
                name: "Columns",
                from: [],
                dependentColumns: ["Lines"],
                multiple: true,
                minimumColumnSize: 1,
            },
            lines: {
                name: "Lines",
                from: ["measure"],
                dependentColumns: ["Columns"],
                multiple: true,
                minimumColumnSize: 1,
            },
            vary: {
                name: "Vary",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
            }
        }
    },
    "bar-chart": {
        columnMap: {
            category: {
                name: "Category",
                from: ["category"],
                multiple: false,
                minimumColumnSize: 1,
            },
            columns: {
                name: "Columns",
                from: ["measure"],
                dependentColumns: ["Lines"],
                multiple: true,
                minimumColumnSize: 1,
            },
            lines: {
                name: "Lines",
                from: [],
                dependentColumns: ["Columns"],
                multiple: true,
                minimumColumnSize: 1,
            },
            vary: {
                name: "Vary",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
            }
        }
    },
    "multi-axis-line-chart": {
        columnMap: {
            category: {
                name: "Category",
                from: ["category"],
                multiple: false,
                minimumColumnSize: 1,
            },
            columns: {
                name: "Columns",
                from: {"measure": [0]},
                dependentColumns: ["Lines"],
                multiple: true,
                minimumColumnSize: 1,
            },
            lines: {
                name: "Lines",
                from: {"measure": [1]},
                dependentColumns: ["Columns"],
                multiple: true,
                minimumColumnSize: 1,
            },
            vary: {
                name: "Vary",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
            }
        }
    },
    "measure-tile": {
        columnMap: {
            measure: {
                name: "Measure",
                from: ["measure"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
            hidden: {
                from: ["category"]
            }
        }
    },
    "pie-chart": {
        columnMap: {
            category: {
                name: "Category",
                from: ["category"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            measure: {
                name: "Measure",
                from: {"measure": [0]},
                multiple: false,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
    "sunburst": {
        columnMap: {
            level: {
                name: "Level",
                from: ["category"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["measure"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            }
        },
    },
    "gauge-chart": {
        columnMap: {
            measure: {
                name: "Measure",
                from: ["measure"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            minimum: {
                name: "Minimum",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            },
            maximum: {
                name: "Maximum",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            },
            target: {
                name: "Target",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            },
            hidden: {
                from: ["category"]
            }
        }
    },
    "tree-map": {
        columnMap: {
            group: {
                name: "Group",
                from: ["category"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["measure"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            vary: {
                name: "Vary",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            }
        }
    },
    "word-cloud": {
        columnMap: {
            word: {
                name: "Word",
                from: ["category"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            freq: {
                name: "Frequency",
                from: ["measure"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
    "table": {
        columnMap: {
            columns: {
                name: "Columns",
                from: ["category", "measure"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
    "pivot-table": {
        columnMap: {
            measures: {
                name: "Measures",
                from: ["measure"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
            rows: {
                name: "Rows",
                from: ["category"],
                multiple: true,
                minimumColumnSize: 0,
                required: false
            },
            columns: {
                name: "Columns",
                from: [],
                multiple: true,
                minimumColumnSize: 0,
                required: false
            }
        }
    }
};