import React, { Component } from "react";
import { Card, Button, Icon, Row, Col } from "antd";
import ListRolesContent from "./ListRolesContent";
import AddRolesContent from "./AddRolesContent";

class UserRolePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      modelList: [],
      listRoles: false
    };
  }

  showModal = () => {
    this.setState({
      visible: true,
      modelList: [...this.state.modelList]
    });
  };

  confirm = result => {
    if (result === true) {
      this.setState({
        visible: false,
        listRoles: true
      });
    } else {
      this.setState({
        visible: false
      });
    }
  };

  render() {
    return (
      <div id="userRolePage">
        <Card
          title="List Roles"
          bordered={false}
          extra={
            <Button
              type="primary"
              style={{ float: "right" }}
              onClick={this.showModal}
            >
              <Icon type="plus" /> Create new role
            </Button>
          }
        >
          <Row>
            <Col span={18}>
              <ListRolesContent list={this.state.listRoles} />
            </Col>
            <Col span={6}></Col>
          </Row>
        </Card>

        <AddRolesContent visible={this.state.visible} confirm={this.confirm} />
      </div>
    );
  }
}

export default UserRolePage;
