/*
*   This js for old columns hasn't some column properties. (tableDisplayName, displayName etc). 
*/

/*
*   This controls columns has property and renders column area.
*/
export const returnConditionalListName = (column) => {
    let columnTableDisplayName
    let columnDisplayName

    if (column["tableDisplayName"] !== undefined) {
      columnTableDisplayName = column["tableDisplayName"]
    } else if (column["tableAliasName"] !== undefined) {
      columnTableDisplayName = column["tableAliasName"]
    }

    if (column["displayName"] !== undefined) {
      columnDisplayName = column["displayName"]
    } else if (column["name"] !== undefined) {
      columnDisplayName = column["name"]
    } else if (column["aliasName"] !== undefined) {
      columnDisplayName = column["aliasName"]
    }

    return columnTableDisplayName + "." + columnDisplayName
}

/*
*   This function checks columns has locationFieldName property, if it hasn't, this function will add.
*/
export const checkColumnsHasLocationFieldName = (columnMap) => {
  let columnMapKeys = Object.keys(columnMap)
  let columnMapValues = Object.values(columnMap)
  let status = false

  for (let i = 0; i < columnMapValues.length; i++) {
    for (let j = 0; j < columnMapValues[i].data.length; j++) {
      if (columnMapValues[i].data[j].locationFieldName === undefined) {
        status = true
        columnMapValues[i].data[j]["locationFieldName"] = columnMapKeys[i]
      }
    }
  }

  return {
    columnMap: columnMap, 
    status: status
  }
}

/*
*   Checks is plugin runs default data
*/
export const findColumnMapHasAnyColumn = columnMap => {
  let status = false;

  if (columnMap === undefined) {
    return status;
  }

  let keys = Object.keys(columnMap);

  for (let i = 0; i < keys.length; i++) {
    let key = keys[i];

    if (columnMap[key].data && columnMap[key].data.length > 0) {
      status = true;
      break;
    }
  }

  return status;
};