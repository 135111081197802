import React, { Component } from "react";
import "./noDataContent.css";
import { connect } from "react-redux";

/**
 * If query result has no data, render this component on plugin
 */
class NoDataContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pluginImage: this.props.plugin.image ? this.props.plugin.image : ""
    };
  }

  //TODO: This code closed after plugin image performance issue.
  //TODO: If this issue passed from tests, remove this code.

  // componentWillReceiveProps(nextProps) {
  //   if (
  //     this.props.state.DefaultPluginsReducer !==
  //       nextProps.state.DefaultPluginsReducer ||
  //     this.state.pluginImage === ""
  //   ) {
  //     let pluginImages = nextProps.state.DefaultPluginsReducer
  //       ? nextProps.state.DefaultPluginsReducer.pluginsMap
  //       : undefined;
  //     let pluginKey = nextProps.plugin ? nextProps.plugin.key : "";
  //     let pluginImage = pluginImages ? pluginImages.get(pluginKey) : "";

  //     this.setState({
  //       pluginImage: pluginImage
  //     });
  //   }
  // }

  /** Decides no data text class by plugin height */
  noDataTextClass = () => {
    const isPluginHeightOne = this.props.plugin.h <= 1 ? true : false;
    let noDataTextClass = "";

    if (isPluginHeightOne) {
      noDataTextClass = "no-data-text"
    }

    return noDataTextClass;
  }

  render() {
    return (
      <div style={{ position: "relative", display: "flex", height: "100%" }}>
        <div className={"plugin-error-area-holder"}>
          <div className={"error-blur-area"}></div>
          <div className={"error-plugin-image"}>
            <img src={"/api/plugin/" + this.props.plugin.key}></img>
          </div>
          <div className="no-data-content">
            <span className={this.noDataTextClass()}>{this.props.content}</span>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = state => {
  return {
    state: state
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoDataContent);
