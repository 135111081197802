import React, { Component } from "react";
import { Row, Button, Col } from "antd";
import SelectModel from "./Moddelling/modelPage/selectModel/SelectModel";
import JoinList from "./JoinList/JoinList";
import "antd/dist/antd.css";
import JoinCreate from "./JoinCreate/JoinCreate";
import { showNotificationWithIcon } from "./Utils/Notification";
import { get } from "./Utils/WebService";
import {API_BASE} from "./config";
import "./Layout.css";

const host = API_BASE;
const apiJoin = "/join";
const apiSubjectArea = "/subjectArea";

class JoinLayout extends Component {
  constructor(props) {
    super(props);

    this.onModelChanged = this.onModelChanged.bind(this);

    this.state = {
      showModal: false,
      joins: [],
      models: [],
      selectedTables: [],
      prevFactAlias: "",
      prevDimensionAlias: ""
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData(showModal) {
    get(
      host + apiJoin,
      response => {
        this.setState({
          ...this.state,
          joins: response.data,
          showModal
        });
      },
      error => {
        showNotificationWithIcon("Join can not found", null, "error");
      }
    );

    get(
      host + apiSubjectArea,
      response => {
        this.setState({
          ...this.state,
          models: response.data
        });
      },
      error => {
        showNotificationWithIcon("Join can not found", null, "error");
      }
    );
  }

  getJoinsByModel = value => {
    get(
      host + apiJoin + "/" + value,
      response => {
        this.setState({
          ...this.state,
          joins: response.data
        });
      },
      error => {
        showNotificationWithIcon("Join can not found", null, "error");
      }
    );
  };

  onModelChanged(selectedTables, alias, isFact) {
    if (alias && isFact === true) {
      this.setState({ ...this.state, selectedTables, prevFactAlias: alias });
    } else if (alias && isFact === false) {
      this.setState({
        ...this.state,
        selectedTables,
        prevDimensionAlias: alias
      });
    } else {
      this.setState({ ...this.state, selectedTables });
    }
  }

  render() {
    return (
      <div>
        <Row>
          <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
            <Button
              id="addJoinButton"
              className="addButton"
              type="dashed"
              icon="plus-circle"
              onClick={() => this.setState({ ...this.state, showModal: true })}
            >
              Add
            </Button>
            <SelectModel
              modelList={this.state.models}
              handleSelection={this.getJoinsByModel}
            />
          </div>
          <JoinCreate
            onModelChanged={this.onModelChanged}
            onCancel={() =>
              this.setState({
                ...this.state,
                showModal: false,
                selectedTables: [],
                prevDimensionAlias: "",
                prevFactAlias: ""
              })
            }
            models={this.state.models}
            tables={this.state.selectedTables}
            onSave={joins => this.getData(true)}
            visible={this.state.showModal}
            prevFactAlias={this.state.prevFactAlias}
            prevDimensionAlias={this.state.prevDimensionAlias}
          />
        </Row>
        <Row>
          <JoinList
            joins={this.state.joins}
            onDelete={() => this.getData(false)}
          />
        </Row>
      </div>
    );
  }
}

export default JoinLayout;
