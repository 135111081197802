import {
  PLUGIN_TRIGGER,
  VISIBLE_CHANGE_POPUP_CONTENT,
  CLEAR_PLUGIN_CONTENT,
  SET_SOURCE_PLUGINS,
  PLUGIN_PRIVATE_FILTERS,
  PLUGIN_FILTERS,
  UPDATED_PLUGINS,
  ALL_PLUGINS_ALL_FILTERS,
  PLUGIN_PRIVATE_FILTERS_TITLE
} from "./PluginTriggerAction";

/**
 *
 * @param {*} state
 * @param {*} action
 *
 * gelen action a göre ilgili datayı store un state ine yazar
 */
export default function PluginTriggerReducer(
  state = {
    plugins: new Map(),
    pluginContent: {},
    pluginInteractionFilters: new Map(),
    pluginPrivateInteractionFilters: new Map(),
    pluginPrivateInteractionFiltersTitle: new Map(),
    positions: {},
    isActionListVisible: false,
    visiblePopupContent: false,
    isAction: false,
    sourcePluginsWithValues: new Map(),
    allPluginsAllFilters: new Map()
  },
  action
) {
  switch (action.type) {
    case PLUGIN_TRIGGER:
      return {
        ...state,
        pluginContent: action.payload.pluginContent,
        positions: action.payload.positions,
        isActionListVisible: action.payload.isActionListVisible,
        isAction: action.payload.isAction,
        visiblePopupContent: true,
      };
    case PLUGIN_FILTERS:
      return {
        ...state,
        pluginPrivateInteractionFilters: action.payload,
      };
    case PLUGIN_PRIVATE_FILTERS:
      return {
        ...state,
        pluginPrivateInteractionFilters: action.payload,
      };
    case PLUGIN_PRIVATE_FILTERS_TITLE:
      return {
        ...state,
        pluginPrivateInteractionFiltersTitle: action.payload,
      };
    case VISIBLE_CHANGE_POPUP_CONTENT:
      return {
        ...state,
        visiblePopupContent: action.payload,
      };
    case CLEAR_PLUGIN_CONTENT:
      return {
        ...state,
        pluginContent: action.payload,
      };
    case SET_SOURCE_PLUGINS: 
      return {
        ...state,
        sourcePluginsWithValues: action.payload
      }
    case UPDATED_PLUGINS: 
      return {
        ...state,
        plugins: action.payload
      }
    case ALL_PLUGINS_ALL_FILTERS:
      return {
        ...state,
        allPluginsAllFilters: action.payload
      }
    default:
      return state;
  }
}
