export const configuration = {
    spiral: "Archimedean",
    scale: "Linear",
    minSize: 12,
    maxSize: 36,
    font: "Open Sans",
    orientations: 5,
    fromAngle: -60,
    toAngle: 60,
    width: 400,
};

export const actions = [
    {
        trigger: "wordClick",
        type: "click",
        output: ["word"],
        name: "Tıklama - Kelime",
        description: "WordClickDesc"
    },
    {
        trigger: "wordHover",
        type: "hover",
        output: ["word"],
        name: "Hover - Kelime",
        description: "WordHoverDesc"
    }
];

export const reactions = [
    {
        id: "filter",
        name: "Filtre",
        description: "desc87",
        type: "general"
    }
];

export const conversionMap = {
    "table": {
        columnMap: {
            columns: {
                name: "Columns",
                from: ["word", "freq"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
        }
    },
    "cylinder-bar": {
        columnMap: {
            label: {
                name: "Label",
                from: ["word"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            values: {
                name: "Values",
                from: ["freq"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
    "age-pyramid": {
        columnMap: {
            category: {
                name: "Category",
                from: ["word"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            either: {
                name: "Either",
                from: ["freq"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            other: {
                name: "Other",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            }
        }
    },
    "line-bar": {
        columnMap: {
            category: {
                name: "Category",
                from: ["word"],
                multiple: false,
                minimumColumnSize: 1,
            },
            columns: {
                name: "Columns",
                from: [],
                dependentColumns: ["Lines"],
                multiple: true,
                minimumColumnSize: 1,
            },
            lines: {
                name: "Lines",
                from: ["freq"],
                dependentColumns: ["Columns"],
                multiple: true,
                minimumColumnSize: 1,
            },
            vary: {
                name: "Vary",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
            }
        }
    },
    "bar-chart": {
        columnMap: {
            category: {
                name: "Category",
                from: ["word"],
                multiple: false,
                minimumColumnSize: 1,
            },
            columns: {
                name: "Columns",
                from: ["freq"],
                dependentColumns: ["Lines"],
                multiple: true,
                minimumColumnSize: 1,
            },
            lines: {
                name: "Lines",
                from: [],
                dependentColumns: ["Columns"],
                multiple: true,
                minimumColumnSize: 1,
            },
            vary: {
                name: "Vary",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
            }
        }
    },
    "measure-tile": {
        columnMap: {
            measure: {
                name: "Measure",
                from: ["freq"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
            hidden: {
                from: ["word"]
            }
        }
    },
    "pie-chart": {
        columnMap: {
            category: {
                name: "Category",
                from: ["word"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["freq"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
    "sunburst": {
        columnMap: {
            level: {
                name: "Level",
                from: ["word"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["freq"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            }
        },
    },
    "gauge-chart": {
        columnMap: {
            measure: {
                name: "Measure",
                from: ["freq"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            minimum: {
                name: "Minimum",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            },
            maximum: {
                name: "Maximum",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            },
            target: {
                name: "Target",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            },
            hidden: {
                from: ["word"]
            }
        }
    },
    "radar": {
        columnMap: {
            category: {
                name: "Category",
                from: ["word"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["freq"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
    "tree-map": {
        columnMap: {
            group: {
                name: "Group",
                from: ["word"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["freq"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            vary: {
                name: "Vary",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            }
        }
    },
    "pivot-table": {
        columnMap: {
            measures: {
                name: "Measures",
                from: ["freq"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
            rows: {
                name: "Rows",
                from: ["word"],
                multiple: true,
                minimumColumnSize: 0,
                required: false
            },
            columns: {
                name: "Columns",
                from: [],
                multiple: true,
                minimumColumnSize: 0,
                required: false
            }
        }
    }
};