import { Col, Input, Row, Select } from "antd";
import { debounce } from "lodash";
import React from "react";
import MonacoEditor from "react-monaco-editor";
import { connect } from "react-redux";
import { store } from "../../..";
import i18n from "../../../Utils/i18next";
import Button from "../Button/Button";
import { setNewColumnEditorContent } from "./NewColumnEditorAction";

const { Option } = Select;

/**
 * This component consists of:
 *  - `title` which contains: Plugin's title and name, Column's field name & name
 *  - `MonacoEditor` (react-monaco-editor: https://github.com/react-monaco-editor/react-monaco-editor) & its options
 *  - `Button` for apply and cancel
 */
class NewColumnCodeEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      code: this.props.newColumn.code || "",
      title: i18n.t("FormulaEditor.Titles.Editor")
    };
  }

  /**
   * `Monaco Editor` Options
   * 
   * More info: https://microsoft.github.io/monaco-editor/api/interfaces/monaco.editor.IEditorOptions.html
   */
  options = {
    minimap: { enabled: false },
    hover: {
      enabled: true,
      above: false,
      sticky: true
    },
    matchBrackets: "always",
    acceptSuggestionOnCommitCharacter: true,
    acceptSuggestionOnEnter: "on",
    accessibilitySupport: "auto",
    autoIndent: true,
    automaticLayout: true,
    codeLens: true,
    colorDecorators: true,
    contextmenu: true,
    cursorBlinking: "smooth",
    cursorSmoothCaretAnimation: false,
    cursorStyle: "line",
    disableLayerHinting: false,
    disableMonospaceOptimizations: false,
    dragAndDrop: true,
    fixedOverflowWidgets: false,
    folding: true,
    foldingStrategy: "auto",
    fontLigatures: true,
    formatOnPaste: true,
    formatOnType: true,
    hideCursorInOverviewRuler: false,
    highlightActiveIndentGuide: true,
    links: true,
    mouseWheelZoom: false,
    multiCursorMergeOverlapping: true,
    multiCursorModifier: "alt",
    overviewRulerBorder: true,
    overviewRulerLanes: 2,
    quickSuggestions: true,
    quickSuggestionsDelay: 0,
    readOnly: false,
    renderControlCharacters: true,
    renderFinalNewline: true,
    renderIndentGuides: true,
    renderLineHighlight: "all",
    renderWhitespace: "none",
    revealHorizontalRightPadding: 30,
    roundedSelection: true,
    scrollBeyondLastColumn: 5,
    scrollBeyondLastLine: true,
    selectOnLineNumbers: true,
    selectionClipboard: true,
    selectionHighlight: true,
    showFoldingControls: "mouseover",
    smoothScrolling: true,
    suggestOnTriggerCharacters: true,
    wordBasedSuggestions: true,
    wordSeparators: "~!@#$%^&*()-=+[{]}|;:'\",.<>/?",
    wordWrap: "off",
    wordWrapBreakAfterCharacters: "\t})]?|&,;",
    wordWrapBreakBeforeCharacters: "{([+",
    wordWrapBreakObtrusiveCharacters: ".",
    wordWrapMinified: true,
    wrappingIndent: "none",
    scrollbar: {
      vertical: 'visible',
      horizontal: 'visible'
    }
  };

  componentDidMount() {
    const newColumn = this.props.newColumn;

    this.setState({
      ...this.state,
      code: newColumn.code,
      title: newColumn.editorTitle
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.newColumn === this.props.newColumn) {
      return;
    }

    let flag = false;
    let newState = { ...this.state };

    if (nextProps.newColumn.code !== this.state.code) {
      newState.code = nextProps.newColumn.code;
      flag = true;
    }

    if (nextProps.newColumn.editorTitle !== this.state.title) {
      newState.title = nextProps.state.contentObject.editorTitle;
      flag = true;
    }

    if (
      flag === true &&
      nextProps.newColumn.visible === true
    ) {
      this.setState(newState);
    }
  }

  /**
   * Adds the event listener that updates editor layout on window resize.
   */
  editorDidMount = (editor) => {
    window.addEventListener("resize", () => {
      editor.layout({});
    })
  }

  updateCode = debounce((value) => {
    let reduxState = store.getState();
    let { contentObject } = reduxState.NewColumnEditorReducer;

    contentObject.code = value;

    store.dispatch(setNewColumnEditorContent(contentObject));
  }, 300);

  /**
   * Updates `Formula Editor` `code` value
   * 
   * @param {*} newValue 
   * @param {*} e 
   */
  editorOnChange = async (newValue, e) => {
    this.setState({
      ...this.state,
      code: newValue
    });

    this.updateCode(newValue);
  };

  /**
   * Removes the event listener that updates editor layout on window resize.
   */
  editorWillUnmount = (editor) => {
    window.removeEventListener("resize", () => {
      editor.layout({});
    })
  }

  columnFormats = [
    "double",
    "integer",
    "varchar",
    "date"
  ]

  dataFormatTypeOnChange = (e) => {
    let column = this.props.newColumn.column;

    column.dataType = e

    let newColumn = { ...this.props.newColumn, column: column };

    store.dispatch(setNewColumnEditorContent(newColumn))
  }

  displayNameOnChange = (e) => {
    let column = this.props.newColumn.column;
    let value = e.target.value

    column.displayName = value
    column.aliasName = value
    column.name = value

    let newColumn = { ...this.props.newColumn, column: column };

    store.dispatch(setNewColumnEditorContent(newColumn))
  }

  render() {
    return (
      <div
        style={{
          height: this.props.height,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Row style={{ marginBottom: "5px" }}>
          <Col span={this.props.newColumn.mode === "edit" ? 10 : 12} style={{ height: "100%", marginTop: "5px", marginBottom: "10px", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <span style={{ fontSize: "13px", marginLeft: "15px", marginRight: "5px" }}>{i18n.t("NewColumn.NewColumnName")}</span>
            <Input style={{ width: "50%", fontSize: "13px", height: "25px", }} placeholder={"New Column Name"} value={this.props.newColumn?.column?.displayName} onChange={this.displayNameOnChange} />
          </Col>
          <Col span={this.props.newColumn.mode === "edit" ? 14 : 12} style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center", marginTop: "4px" }}>
            <span style={{ fontSize: "13px", marginLeft: "15px", marginRight: "5px" }}>{i18n.t("NewColumn.ColumnDataType")}</span>
            <Select
              style={{ width: this.props.newColumn.mode === "edit" ? "30%" : "50%", fontSize: "13px" }}
              getPopupContainer={(trigger) => trigger.parentNode}
              size={"small"}
              placeholder=""
              optionFilterProp="children"
              onChange={this.dataFormatTypeOnChange}
              value={this.props.newColumn?.column?.dataType}
            >
              {this.columnFormats.map((format) => (
                <Option
                  name={this.props.newColumn?.column?.displayName + "text"}
                  value={format}
                >{format}</Option>
              ))}
            </Select>
            {this.props.newColumn.mode === "edit" ? <span style={{marginLeft: "10px", fontSize: "10px", color: "rgb(255, 87, 34)", marginRight: "5px"}}><b>*</b></span> : null}
            {this.props.newColumn.mode === "edit" ? <span style={{fontSize: "10px", color: "rgb(255, 87, 34)", marginRight: "5px", width: "170px"}}>{i18n.t("NewColumn.DataTypeOnChange")}</span> : null}
          </Col>
        </Row>

        <div style={{ borderBottom: "solid 1px #dadada" }}>
        </div>
        <div
          id="formula-editor-drop-area"
          style={{
            height: "100%",
            border: "solid 2px transparent",
            marginBottom: "-2px"
          }}
        >
          <MonacoEditor
            style={{ background: "black !important" }}
            value={this.state.code}
            language={this.props.language}
            options={this.options}
            theme="visp"
            editorDidMount={this.editorDidMount}
            onChange={this.editorOnChange}
            editorWillUnmount={this.editorWillUnmount}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            padding: "8px 16px",
          }}
        >
          <Button
            className={"general-button-outlined"}
            style={{
              padding: "4px 12px",
              marginRight: "6px",
            }}
            onClick={() => this.props.onCancel()}
          >
            {i18n.t("Cancel")}
          </Button>
          {this.props.newColumn.mode === "add" ? <Button
            className={"general-button"}
            style={{
              padding: "4px 12px !important",
              margin: "0",
              marginLeft: "6px",
              width: "fit-content"
            }}
            onClick={() => this.props.onApply()}
          >
            {i18n.t("Apply")}
          </Button> : <Button
            className={"general-button"}
            style={{
              padding: "4px 12px !important",
              margin: "0",
              marginLeft: "6px",
              width: "fit-content"
            }}
            onClick={() => this.props.onEdit()}
          >
            {i18n.t("Edit")}
          </Button>}
        </div>
      </div>
    );
  }
}

/**
 * Set action method to props
 */
const mapDispatchToProps = {
  setNewColumnEditorContent,
};

/**
 * Set store objects to state
 * 
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {
    newColumn: state.NewColumnEditorReducer.contentObject,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewColumnCodeEditor);