export const en = { 
  translation: {
    Language: "EN",
    FilterOperators: {
      "=": "Equals",
      "!=": "Not Equals",
      "in": "In",
      "not in": "Not In",
      "CONTAINS": "Contains",
      "ENDS_WITH": "Ends With",
      "STARTS_WITH": "Starts With",
    },
    DashboardSlider: {
      Sliders: "Dashboard Sliders",
      EmptySearchResult: "Could not find any dashboard slider.",
      NoDataDescription: "There is no dashboard slider."
    },
    NewColumn: {
      NewColumn: "Calculated Column",
      NewColumnCantBeEmpty: "Calculated Column name can't be empty!",
      ColumnNamedBefore: "Already have {{column}} named column. Please change the name!",
      CantUseDot: "You can't use dot in your column name!",
      NewColumnName: "Calculated Column Name",
      ColumnDataType: "Column Data Type",
      InfiniteRecursiveError: "Calculated Column formulas has infinite relation. Please check your Calculated Column Formulas.",
      NewColumnsCantDuplicate: "Calculated Columns were not transfered to duplicated Table.",
      AddCalculatedColumn: "Add Calculated Column",
      DataTypeOnChange: "When you change datatype, you need to drop column to plugin again.",
      NewColumnCantBeEmpty: "You can't use Calculated Column with empty formula"
    },
    DashboardMail: {
      DashboardMail: "Send Mail",
      SendMail: "Send Mail",
      MailSubject: "Subject",
      MailBody: "Body",
      MailTo: "To",
      MailFrom: "From",
      MailSendSuccess: "Mail sent successfully",
      MailSendError: "Mail could not be sent",
      MailSending: "Mail sending...",
      Add: "Add",
      ExampleVispeahen: "Vispeahen",
      Receipents: "Receipents",
      DeleteAll: "Delete All",
      YouCanSeeYourMailListHere: "You can see your e-mail list here",
      SendDashboardAs: "Dashboard",
      Send: "Send",
      DeleteDraft: "Discard Draft",
      MailFromInfo: "Optional Option. It will appear as a name in the mail you send.",
      MailEnterInfo: "You can add your mail addresses with ENTER",
      DeleteDraftConfirm: "Are you sure to discard this draft?",
      DeleteDraftDesc: "All the content of this draft will be gone.",
      NoReceipentError: "At least one receipent is required.",
    },
    DashboardComments: {
      Comments: "Comments",
      ASC: "Newest Created",
      DESC: "Oldest Created",
      LUASC: "Least Recently Updated",
      LUDESC: "Most Recently Updated",
      EmptyDashboardAddText: "You can add a comment to your dashboard",
      EmptyDashboardWhoCanSeeYourComments: "Who can see comments?",
      EmptyDashboardWCSYCText: "Your comments will be visible to anyone who is able to comment on or edit this document.",
      Edited: "updated",
      Reply: "Reply",
      Edit: "Edit",
      Remove: "Delete",
      RepliedTo: "Replied to {{username}}",
      DashboardAdd: "You can add here a dashboard comment",
      DashboardComments: "Dashboard Comments",
      Refresh: "Refresh",
      Close: "Close",
      Users: "User",
      Context: "Context"
    },
    CustomDashboard: {
      MainDashboard: "Main Dashboard",
      CustomDashboard: "Customized Dashboard",
      CustomDashboards: "Customized Dashboards",
      Save: "Save Customized Dashboard",
      SaveAs: "Save Customized Dashboard As",
      SetAsMainDashboard: "Set As Main Dashboard",
      CustomDashboardDeleteSucces: "Customized Dashboard Deleted Successfully",
      CustomDashboardDeleteError: "Customized Dashboard Could Not Be Deleted",
      AreYouSureDeleteError: "Are you sure to delete Customized Dashboard?",
      CustomDashboardIsNull: "Customized Dashboard didn't add yet!"
    },
    DashboardCommentsErrors: {
      DCE00001: "Dashboard id couldn't be null.",
      DCE00002: "User not found, please try login again.",
      DCE00003: "Dashboard not found.",
      DCE00004: "Dashboard comment can not be null.",
      DCE00005: "User not found, please try login again.",
      DCE00006: "Dashboard comment reply main dashboard id can not be null",
      DCE00007: "Dashboard can not be found", 
      DCE00008: "Main comment id can not be null",
      DCE00009: "Updated comment can not be null.",
      DCE00010: "Updated comment id can not be null.",
      DCE00011: "Delete process dashboard id can not be null",
      DCE00012: "Delete process dashboard comment id can not be null",
      DCE00013: "Delete process dashboard id can not be null",
      DCE00014: "Delete process reply id can not be null",
      DCE00015: "Dashboard comment reply can not be null",
      DCE00016: "Updated comment main comment id can not be empty.",
      DCE00017: "Dashboard not found.",
      DCE00018: "Updated reply can not be empty",
      DCE00019: "Reply can not be empty",
      DCE00020: "Updated comment can not be empty",
      "Request failed with status code 401": "Please Login Again."
    },
    UserEntrance: {
      Username: "User Name",
      Password: "Password",
      Fullname: "Full Name",
      Email: "Email",
      RePassword: "RePassword",
      PasswordMatch: "Passwords match !",
      PasswordDontMatch: "Passwords do not match !",
      GoBackToLogin: "< Go Back To Login",
      Information: "Info",
      Login: {
        WrongUsernameOrPassword: "Wrong username or password",
        Username: "User Name",
        Password: "Password",
        Button: "Login",
        Unauthorized: "Unauthorized",
        RememberMe: "Remember Me",
        ForgotPassword: "Forgot Password",
        DontYouHaveAnAccountYet: "Don't you have an account yet ?",
        FreeRegister: "Free Register",
        UserDisabled: "Your account has not been activated yet! Please check your email address to active your account.",
        UserNameEmpty: "User Name can not be empty.",
        PasswordEmpty: "Password can not be empty."
      },
      Register: {
        Button: "Register",
        Registered: "Welcome to Vispeahen, we have sent you an activation email.",
        Unauthorized: "You don't have an authorization for this process!",
        InvalidEmail: "Please provide a valid email address!",
        Activated: "Email Confirmed",
        NotActivated: "Email Confirmation Failed",
        ActivatedDescription: "Thank you for confirming your email address. You are welcome to login to Vispeahen.",
        NotActivatedDescription: "Confirmation of email address failed, please contact to us if you think , there is somethings wrong !",
        AlreadyActivated: "Your Email Already Confirmed",
        AlreadyActivatedDescription: "Your email address has already been confirmed.",
        PasswordStrength: "Your password must contain at least 8 characters, at least one capital, at least one lower case, at least one number, at least one special character (!#$ etc..).",
        PasswordDoesNotMatch: "RePassword does not match with Password",
        UserNameEmpty: "User Name can not be empty.",
        UserNameLength: "User Name must contain at least 4 characters.",
        PasswordEmpty: "Password can not be empty.",
        RepasswordEmpty: "RePassword can not be empty.",
        FullNameRules: "Full Name may contain only characters.",
        UserNameRules: "User Name may contain only characters, numbers and underscore (_)"
      },
      Activation: {
        UserActivated: "Your account has been activated. We will forward you to login page.",
        Loading: "Activation progress has been started. Please wait...",
        AlreadyActivated: "User has alreay been activated. We will forward you to login page."
      },
      ForgotPassword: {
        MailSend: "We have sent you an e-mail to change your password. If this e-mail address was registered in our system.",
        Button: "Send Password via e-Mail",
        Description: "Type e-mail address of the account. We'll send you an e-mail guiding you to change your password."
      },
      ChangePassword: {
        Button: "Change Password",
        Description: "Type your new password.",
        Success: "Your password has been changed successfully.",
        InvalidActivationCode: "Invalid activation code",
        InvalidLdap: "You were registered as a LDAP user. Contact to your system administrator to change your password."
      },
      SessionExpired: {
        WantToContinue: "Do you want to continue?",
        SessionExpired: "Session Expired",
        SessionExpiredMessage: "Don't worry! You can continue your process logging in again- If you  it, you will be directed to the login page.",
        SessionDifferentUser: "To continue with a different user, you must be logged in from the login page. You can reach here with the Cancel button.",
      },
    },
    Splash: {
      Loading: "Loading"
    },
    WellDefined: {
      "1111-1212-2212-2212": "Shared",
      "0000-1212-2212-2212": "Private",
      "2222-1212-2212-2212": "Most Visited (Organization)",
      "3333-1212-2212-2212": "Most Visited (My)",
      "4444-1212-2212-2212": "Favorites",
      "5555-1212-2212-2212": "Deleted",
      "7777-1212-2212-2212": "Recently Popular"
    },
    Dashboard: {
      Label: {
        Title: "Labels",
        Create: {
          Title: "Create Label",
          SelectedTitle: "label title",
          Description: "Type a title and select a color for your new label",
          CreateButton: "Create",
          CancelButton: "Cancel",
          LabelCantBeEmpty: "Label can't be empty!",
          LabelSuccesfullyAdded: "Label successfully created.",
          LabelDisplayNameError: "You have another {{labelDisplayName}} label. Please select different name."
        },
        Selector: {
          Title: "Assign Labels",
          Description: "Assign labels to dashboard by clicking a label on the list below",
          Search: "Search",
          CreateLink: "Create Label",
          ManageLink: "Manage Labels"
        },
        SelectLabel: "Choose a tag",
      },
      GridSystem: {
        LastRow: "Delete Last Row"
      },
      EmptyDashboard: {
        EmptyDesc0: "You are here to create a dashboard.",
        EmptyDesc1: "Click the icon",
        EmptyDesc2: "in the upper left corner to add a plugin.",
        UploadYourFiles: "Upload Your Files",
        ConnectYourDataSources: "Connect Your Datasources"
      },
      ExcelExport: {
        ExportPluginCouldNotFound: "No valid plugin found to export!",
        ExcelExporting: "Excel exporting...",
        ExcelExportingSuccess: "Exporting successfully completed."
      },
      NewReportDashboardShortCut:{
        DashboardReport: "New",
        NavigateingToDashboard: "New Dashboard",
        NavigateingToReport: "New Report",
        WarningTextToDashboard: "You will be redirected to a new Dashboard. If the changes made are not saved, all the changes made will be lost.",
        WarningTextToReport: "You will be redirected to a new Report. If the changes made are not saved, all the changes made will be lost.",
        Continue: "Do you want to continue ?",
        OK: "Yes",
        Cancel: "No"
      },
      CustomThumbnail: {
        ImageFileOverTheLimitError: "The image you upload must smaller than 10MB.",
        ImageFileTypeError: "An invalid or unsupported file type. Please upload a file which is JPG, JPEG, PNG or WEBP.",
        ImageUploadError: "The image could not be uploaded."
      },
      Settings: {
        TopoJSON: {
          DeleteMap: "Delete Map",
          TopojsonNotFound: "Topojson not found.",
          MapDeleteWarning: "Are you sure you want to delete the map?",
          MapDeleteWarningWithDashboard: "The map you want to delete is used in the following reports. If you want, you can go to the report by clicking on the report name. You will need to update these reports when you delete the map.",
          MapSuccessfullyAdded: "Map successfully added.",
          MapSuccessfullyUpdated: "Map successfully updated",
          NoFileSelected: "No file selected",
          SelectMap: "Select Map",
          SelectMapForUpdate: "Map For Update",
          UploadedTopoJsons: "Uploaded Maps",
          DefaultTopoJsons: "Default Maps",
          MapUsedDashboards: "Dashboards Using The Map",
          UploadEdit: "Edit Maps",
          MapTopoJsonOperations: "Map/TopoJson Operations",
          MapSuccessfullyDeleted: "Map successfully deleted.",
          UploadMap: "Upload Map",
          EditMap: "Edit Map",
          NameOfMap: "Name of Map",
          MapWithTopoJsonDesc: "Map (TopoJson)",
          UsStates: "US States",
          UsStatesMainland: "US States (Mainland)",
          UsCountries: "US Countries",
          WorldCountries: "World Countries",
          TurkeyProvinces: "Turkey Provinces",
          TurkeyDistricts: "Turkey Districts",
          AnkaraDistricts: "Ankara Districts",
          AnkaraNeighborhood: "Ankara Neighborhood",
          US10M: "US States(Vegalite)",
          World10M: "World Countries(Vegalite)",
          UKNUTS1: "UK - NUTS 1"
        },
        Title: "Set dashboard title by input below.",
        TitleSettings: "Title Settings",
        Description: "Set dashboard description by input below.",
        SuccessfullyUpdate: "Settings successfully updated.",
        DashboardSettings: "Dashboard Settings",
        DashboardSettingsDescription: "You can make general settings that will affect the entire report here.",
        BackgroundSettings: "Background Settings",
        BackgroundColor: "Background Color",
        BackgroundImage: "Background Image",
        BackgroundColorDescription: "Set dashboard background color by using color picker at below.",
        BackgroundImageDescription: "Set dashboard background image by using upload button at below.",
        GridSettings: "Grid Settings",
        PreventCollision: "Prevent Collision",
        StackDesign: "Stack Design",
        FreeMovement: "Free Movement",
        StackDesignDesc: " In stack design mode, you cannot leave space between plugins in the vertical line. However, it provides more comfortable use when changing the location of a plugin.",
        FreeMovementDesc: "In free movement mode, you can place the plugins wherever you want. However, while changing the location of a plugin, you cannot leave it where other plugins are.",
        GridPadding: "Space Between Plugins",
        Shadow: "Shadow Settings",
        ShadowUpDown: "Up/Down",
        ShadowRightLeft: "Right/Left",
        ShadowBlur: "Blur",
        ShadowSpread: "Spread",
        ShadowColor: "Color",
        DashboardDetails: "Dashboard Details",
        RerenderAllPlugins: "Rerender all plugins",
        CustomExcel: "Excel Settings",
        StyledExcel: "Styled Excel",
        BigSizedExcel: "Big Size Excel",
        StandartExcel: "Standard Excel",
        StyledExcelDesc: "It is used to get styled Excel output. Recommended for smaller data.",
        StandartExcelDesc: "It is used to get unstyled Excel output. It is recommended for larger data and get faster output.",
        BigSizeExcelDesc: "It is used to get unstyled Excel output for big sized data. This option is available only for standard excel",
        ExcelList: "Excel List",
        UploadNewExcel: "Upload New Excel",
        UploadNewCustomExcelTemplate: "Upload New Custom Excel Template",
        CustomExcelDisplayName: "Custom Excel Display Name",
        ExcelSuccessfullyUploaded: "Excel successfully uploaded.",
        NoSelectedFile: "No selected file.",
        DashboardMustBeSaved: "Dashboard must be saved.",
        StandardExcel: "Standard Excel",
        UnsupportedCustomExcelExtension: "Unsupported custom excel extension!",
        SupportedFileExtensions: "Supported File Extensions",
        ImageFileOverTheLimitError: "Image must smaller than 1MB!",
        ImageFileTypeError: "You can only upload JPG/PNG file!!",
        NoDataConfiguration: "No Data Description",
        NoDataConfigurationInfo: {
          Info: "No data in the graphic configuration and settings description view order:",
          GraphicConfiguration: "1. Graphic Configuration",
          Settings: "2. Settings",
          DefaultText: "3. Default Text (No data in this criteria. Plugin could not be visualized.)"
        },
        Theme: "Theme",
        SelectedTheme: "Active Theme",
        NoTheme: "No Theme",
        DefaultTheme: "Default Theme",
        StickToolsToTop: "Pin Dashboard Tools to Top"
      },
      Configuration: {
        Configuration: "Configuration",
        ExcelExport: "Excel Export",
        PDFExport: "PDF Export",
        ExcelExportDisable: "Excel output cannot be obtained because there is no data. You can take the output after adding data.",
        PDFExportDisable: "PDF output cannot be obtained because there is no data. You can take the output after adding data.",
        ConfigurationDescription: "You can customize your {{pluginKey}} plugin in detail on this screen.",
        Fields: {
          RotateLabelsIfTheyDontFitDesc: "When this configuration is checked, labels will not be rotated if they fit.",
          RotateLabelsIfTheyDontFit: "Rotate Only If Labels Don't Fit",
          TruncateLabels: "Truncate Labels",
          HideOverlappingLabels: "Hide Overlapping Labels",
          AnimationTextColor: "Animation Text Color",
          AnimationFontSize: "Animation Font Size",
          ShowGrids: "Show Grids",
          LegendPosition: "Legend Position",
          LegendFontSize: "Legend Font Size",
          ShowLegends: "Show Legends",
          Bounce: "Bounce",
          Circle: "Circle",
          Cubic: "Cubic",
          Elastic: "Elastic",
          Exp: "Exp",
          Sine: "Sine",
          Measures: "Measures",
          Auto: "Auto",
          Manual: "Manual",
          ChartType: "Chart Type",
          Radial: "Radial",
          Labels: "Labels",
          LabelPosition: "Label Position",
          MeasuresOnAxis: "Show Measures On Axis",
          Target: "Target",
          ShowTarget: "Show Target",
          ShowMeasureName: "Show Measure Names",
          ShowMeasureValue: "Show Measure Values",
          ValueType: "Value Type",
          Triangle: "Triangle",
          Classic: "Classic",
          MeasureHandType: "Measure Hand",
          StaticTarget: "Static Target",
          MinMax: "Min/Max",
          ShowLabels: "Show Labels",
          DistanceWithGrid: "Distance With Grid",
          CategoryFontSize: "Category Font Size",
          ShowValueOnRadar: "Show Values On Radar",
          RadarValueFontSize: "Radar Values Font Size",
          Ranges: "Ranges",
          ShowRanges: "Show Ranges",
          RangeDescription: "You can set the ranges and you can customize between categories",
          CategoriesNotFound: "Start or End value does not exist in the current data",
          ImageAndIconsCantSeemSameTime: "Icon and Image can not be visible at same time",
          PaginationBottomButtons: "Pagination Bottom Buttons",
          CompareAlign: "Compare Align",
          IncreaseDecreaseNotAvailable: "Increase/Decrease not available.",
          Compare: "Compare",
          CompareDescription: "Compare Description",
          PaginationBottomButtonsInfo: "If you activate bottom buttons, compare area will be removed.",
          MeasureCompareStyling: "Compare Styling",
          MeasureComparePosition: "Compare Position",
          MeasureCompareFontStyle: "Compare Font Style",
          MeasureCompareFont: "Compare Font",
          IsMultiple: "Multiple",
          SetToMeasure: "Set To Measure Area",
          SetAllMeasureArea: "Set All Measure Area",
          SetHeaderArea: "Set To Header Area",
          SetAllHeaderDescriptionArea: "Set All Header Description Area",
          SetAllCompareArea: "Set All Compare Area",
          SetCompareArea: "Set Compare Area",
          SetToHeader: "Set To Header Area",
          SetAllHeaderArea: "Set All Header Area",
          MeasureStyling: "Measure Styling",
          MeasurePosition: "Measure Position",
          MeasureHeader: "Measure Header",
          MeasureFont: "Measure font style",
          MeasureHeaderDescription: "Write a header specifically",
          HeaderDescriptionStyling: "Header Description Styling",
          SetAllIconsToOther: "Set All Icons To Other",
          SetAllIconSizeToOther: "Set All Size To Other",
          HeaderDescriptionFont: "Header Description Font",
          HeaderTextSize: "Header Text Size",
          NavigationNoDataFound: "Data Can't Find For Navigation",
          MeasureTileEmptyDataError: "In the new Measure Tile plugin, since there is more than one data visualization could not be made.",
          HeaderFont: "Header Font Type",
          HeaderFontDescription: "Font Description",
          HeaderFontDescriptionPosition: "Measure Tile Header Position",
          HeaderDescriptionFontStyle: "Header Description Font Style",
          HeaderTitleFontStyle: "Header Title Font Style",
          MeasureFontType: "Measure Font Type",
          MeasureFontStyle: "Measure Font Style",
          ShowMeasureFrame: "Show Icon Frame",
          HeaderColor: "Header Color",
          IconSize: "Icon Size",
          Both: "Both",
          Label: "Label",
          StartLimit: "Start",
          EndLimit: "End",
          FillArea:  "Fill Area",
          FillBubbles: "Fill Bubbles",
          NoFill: "No Fill",
          ColorFill: "Color Fill",
          XAxis: "X Axis",
          YAxis: "Y Axis",
          Scrollbars: "Scrollbars",
          ScrollbarX: "Scrollbar X",
          ScrollbarY: "Scrollbar Y",
          LabelFontStyle: "Label Font Style",
          LabelFontSize: "Label Font Size",
          AnimationDuration: "Duration (Seconds)",
          AnimationLabel: "Show Label",
          AnimationOverSizedBehaviour: "Label Oversized Behaviour",
          OverSizedFit: "Scale font size to fit",
          OverSizedTruncate: "Truncate text",
          OverSizedWrap: "Wrap text",
          OverSizedWrapNoBreak: "Wrap text with no break",
          OverSizedHide: "Hide label",
          OverSizedNone: "Do nothing",
          ActionsWarningRoot: "The root, which collects the top-order nodes in a parent, is mandatory for the tree graph. Since it is a virtual node and contains no real data, root does not contain any interaction or navigation.",
          Root: "Root",
          ShowRoot: "Show Root",
          RootName: "Root Name",
          LinkHightlight: "Highlight Links",
          Draggability: "Draggability",
          Collapsibility: "Collapsibility",
          NodePadding: "Node Padding",
          Radius: "Radius",
          MaximumRadius: "Maximum Radius",
          MinimumRadius: "Minimum Radius",
          NetworkGraph: "Network Graph",
          TreeChart: "Tree Chart",
          GeneralSettings: "General Settings",
          StrictMinMax: "Strict Min/Max",
          InvalidAxisMin: "The minimum value cannot be greater than the maximum value.",
          InvalidAxisMax: "The maximum value cannot be less than the minimum value.",
          InvalidAxisMinMax: "The minimum and maximum values cannot be equal.",
          AxisTargetExplanation: "In cases where some targets exceed the value range of the axis, the values of this targets may be used instead of the minimum or maximum values of the axis in order to enable the visualization of the targets.",
          MinMaxValues: "Min/Max Values",
          SelectedAxis: "Selected Axis",
          SelectAxis: "Select Axis",
          Axes: "Axes",
          Axis: "Axis",
          SingleAxis: "Single Axis",
          ValueSettings: "Value Settings",
          SmoothedLinesWarning: "The continuous chart feature complements the fields with no data by estimating. Lines cannot be smoothed when using this feature, as this may cause visual disturbances.",
          AggregationRule: "Aggregation Rule",
          Sum: "Sum",
          Average: "Average",
          Maximum: "Maximum",
          Minimum: "Minimum",
          Count: "Count",
          First: "First",
          Last: "Last",
          None: "None",
          TargetsMultiColumnDescription: "You cannot set targets for multiple axes.",
          GroupCount: "Group Count",
          DataGrouping: "Data Grouping",
          GroupDataFields: "Group Data Fields",
          FillTargetArea: "Fill Target Area",
          Color: "Color",
          Targets: "Targets",
          ShowTargets: "Show Targets",
          SmoothedLines: "Smoothed Lines",
          ShowBullets: "Show Bullets",
          Chart: "Chart",
          ContinuousChart: "Continuous Chart",
          Navigator: "Navigator",
          AreaChart: "Area Chart",
          ZoomX: "X Zoom",
          ZoomY: "Y Zoom",
          ShowZoomX: "Show X Zoom",
          ShowZoomY: "Show Y Zoom",
          Alignment: "Alignment",
          BarValueAutomatic: "Automatic Calculation",
          ValueAngle:"Value Angle",
          LabelAngle: "Label Angle",
          LegendConfiguration: "Enhanced Legend Configuration",
          EnhancedMeasureConfiguration: "Enhanced Measure Configuration",
          ThreeDVisulisation: "3D Visulisation",
          VisuliateByRadius: "Size By Measurement Values",
          SelectedValues: "Selected Values",
          UserTextBox: "Use Textbox",
          ApplyButtonText: "Apply Button Text",
          ApplyButtonBackgroundColor: "Apply Button Background Color",
          ApplyButtonTextColor: "Apply Button Text Color",
          EnhanceMode: "Enhance Mode",
          Browse: "Browse",
          NoFileSelected: "No file selected",
          ImageUpload: "Image upload",
          CantChangeMeasureSize: "You can't give bigger value than {{size}}px",
          CantReduceMeasureSize: "You can't give smaller value than {{size}}px",
          PieChartDrilldownWarning: "Pie-Chart special drilldowns can be run with one column!",
          PieChartInsideDrilldown: "Explode Drilldown",
          PieChartNormalDrilldown: "General Drilldown",
          DoublePieDrilldown: "Double Pie Drilldown",
          DrilldownType: "Drilldown Type",
          SumOperations: "Sum Operations",
          Attribute: "Attribute",
          RowTotal: "Row Totals",
          After: "After From Columns",
          Before: "Before From Columns",
          GroupName: "Group Name",
          PivotTheme: "Pivot Theme",
          GroupDefaultExpanded: "Group Default Expand",
          RowGroupOperations: "Row Group Operations",
          ChangePluginType: "Vega Lite Plugin Customize",
          SelectIcon: "Icon",
          SearchIcon: "Search Icon",
          Size: "Size",
          BackgroundColor: "Background Color",
          LinkColor: "Link Color",
          Colours: "Colours",
          Title: "Title",
          TitleInPlugin: "Title In Plugin",
          TitleAlign: "Title-Align",
          YAxisAlign: "Y Axis-Align",
          ApplyTitleFeaturesToOthers: "Apply title features to other plugins at dashboard",
          ApplyFeatures: "Apply features",
          RemoveFeatures: "Remove features",
          RemoveFeaturesWarning: "Features cannot be removed after the dashboard save",
          FilterDefaultOperator: "Filter Default Operator",
          ShowOperatorOptions: "Show Operator Options",
          ShowDefaultFilterIcon: "Show Default Filter Icon",
          CurrentPluginIsAMap: "Plugin is a map",
          CloseColorPicker: "Close color picker",
          OpenColorPicker: "Open color picker",
          OpenIconSelector: "Open icon selector",
          CloseIconSelector: "Close icon selector",
          CloseTooltip: "Close tooltip",
          OpenTooltip: "Open tooltip",
          CloseUploadArea: "Close Upload Area",
          OpenUploadArea: "Open Upload Area",
          Refresh: "Refresh",
          Delete: "Delete",
          ShowHideButton: "Show Hide Button",
          ShowConditionalFormatting: "Show Conditional Formatting",
          HideLabel: "Hide Label",
          Width: "Width",
          LinkField: "Link Field",
          FontStyle: "Font Style",
          TotalFontFamily: "Total Font",
          TotalStyling: "Total Styling",
          Height: "Height",
          Others: "Others",
          GeneralAppearance: "General Appearance",
          EnhancedConfiguration: "Enhanced Configuration",
          ValueFontFamily: "Value Font",
          ColumnColor: "Column Color",
          NullValue: "Null/Empty Default Value",
          RowColumnStyle: "Row/Column Styling",
          //HeaderFont: "Row/Column Header Font",
          HeaderFontSize: "Row/Column Header Font Size",
          FitColumns: "Fit Columns",
          FitRows: "Fit Rows",
          FitCols: "Fit Cols",
          RowColor: "Row Color",
          ColColor: "Column Color",
          RowWidth: "Row Width",
          ValueColor: "Value Color",
          TotalColor: "Total Color",
          FitColsDanger: "If you select this, column width and column height will calculate automatically",
          DifferentDrillDanger: "If you select this, drilldowned rows and columns will color by drilldown color",
          DrilldownColumnHeaderColor: "Drilldown Row/Column Color",
          DifferentDrillColours: "Append Different Drilldown Color",
          LegendType: "Legend Visulisation",
          TextStyling: "Text Styling",
          HeaderTextStyling: "Header Text Styling",
          AddVisual: "Add Visual",
          SizePositioning: "Size / Positioning",
          AxisConfiguration: "Axis Configuration",
          Sorting: "Sorting",
          BarConfiguration: "Bar Configuration",
          Values: "Values",
          Center: "Center",
          Left: "Left",
          Right: "Right",
          Top: "Top",
          Bottom: "Bottom",
          CircleThickness: "Circle Thickness",
          CircleFillGap: "Circle Fill Gap",
          TextVertPosition: "Text Vert Position",
          MinValue: "Min Value",
          WaveHeight: "Wave Height",
          WaveCount: "Wave Count",
          WaveRiseTime: "Wave Rise Time",
          WaveAnimateTime: "Wave Animate Time",
          WaveHeightScaling: "Wave Height Scaling",
          DisplayPercent: "Display percent",
          WaveAnimate: "Wave Animate",
          TextSize: "Text Size",
          Spiral: "Spiral",
          Scale: "Scale",
          MinSize: "Minimum Font Size",
          MaxSize: "Maximum Font Size",
          Font: "Font",
          Orientation: "Orientation",
          FromAngle: "FromAngle",
          ToAngle: "ToAngle",
          Archimedean: "Archimedean",
          Rectangular: "Rectangular",
          Linear: "Linear",
          Log: "Calculate log of the number",
          Theme: "Themes",
          ThemeButton: "Theme",
          defaultEnhancedPalette: "Grey Theme",
          FlatUI: "Flat-UI",
          FlatUISoft: "Flat-UI-Soft",
          CoolScale: "Cool-Scale",
          WarmScale: "Warm-Scale",
          Heatmap: "Heatmap",
          SelectedColor: "Selected Color",
          Dark: "Dark",
          Chalk: "Chalk",
          Light: "Light",
          Patterns: "Patterns",
          Black: "Black",
          SelectTitleAlign: "Select Title Align",
          SelectYAxisAlign: "Select YAxis Align",
          RemoveColour: "Remove Colour",
          BackgroundImageUrl: "Background Image Url",
          GlassWidth: "Glass Width",
          GlassHeight: "Glass Height",
          ShowLastValue: "Show Last Value",
          MinPointSize: "Min Point Size",
          MaxPointSize: "Max Point Size",
          XTitle: "X Title",
          YTitle: "Y Title",
          SecondYTitle: "Second Y Axis Title",
          SecondAxis: "Second Axis",
          LR: "Linear Regression",
          SelectBox: "Select Box",
          ArrowColor: "Arrow Color",
          PlaceHolderColor: "Place Holder Color",
          TextBackgroundColor: "Text Background Color",
          PlaceHolder: "Place Holder",
          IsMulti: "Is Multi",
          DropdownAppearance: "Dropdown Appearance",
          WrapText: "Wrap Text",
          SpaceBetweenButtons: "Space Between Buttons",
          TextColor: "Text Color",
          InnerHeight: "Inner Height",
          InnerWidth: "Inner Width",
          LinkURL: "Link",
          DontShowError: "Dont Show Error",
          MaxValue: "Max Value",
          Duration: "Duration",
          FontSize: "Font Size",
          FontColour: "Font Color",
          RoundCorners: "Round Corners",
          ShowValuesOnTree: "Show Values On Tree",
          ShowValuesOnBar: "Show Values On Bar",
          ValueFontSize: "Value Font Size",
          FilterLimit: "Filter Limit",
          BubbleLow: "Minimum Bubble Radius",
          BubbleHigh: "Maximum Bubble Radius",
          Stroke: "Stroke Width",
          StyleType: "Style Type",
          SeriesPicker: "Series Picker",
          SplitSeries: "Split Series",
          SeriesScale: "Series Scale",
          Individual: "Individual",
          Share: "Share",
          MapTileSelection: "Map Tile Selection",
          AtlasMapTile: "Atlas Map Tile",
          OpenStreetMapTile: "Open Street Map Tile",
          BasarSoftTile: "Basar Soft Tile",
          Voronoi: "Highlight Close Points",
          Legends: "Legend",
          Legend: "Show Legend",
          LegendLocation: "Legend Location",
          Up: "Up",
          Down: "Down",
          Opacity: "Opacity",
          MaxZoom: "Max Zoom",
          Colour: "Colour",
          Palette: "Palette",
          MapTile: "Map Tile",
          InnerRadius: "Inner Radius",
          dataType: "Measure Visualization",
          Numeric: "Numeric",
          Percentage: "Percent",
          NumericPercentage: "Numeric-Percent",
          ShowMeasureWithLines: "Show Measure With Lines",
          ShowMeasure: "Show Measure",
          MeasureType: "Measure Type",
          SelectMultiple: "Select Pie's Multiple",
          ShowMeasureOnPie: "Show Measure On Pie",
          Levels: "Levels",
          PointSize: "Point Size",
          FillOpacity: "Fill Opacity",
          StrokeOpacity: "Stroke Opacity",
          OwnScale: "Own Scale",
          Topojson: "Topojson",
          SelectATopojson: "Select a Topojson",
          AddNewTopojson: "Add New Layer",
          RemoveLastTopojson: "Remove Last Layer",
          FeatureCode: "Feature Code",
          FeatureDesc: "Feature Desc",
          ScaleType: "Scale Type",
          Quantile: "Quantile",
          Individiual: "Individiual",
          Shared: "Shared",
          StrokeWidth: "Stroke Width",
          EmptyTile: "Empty Tile",
          SelectLegend: "Select Legend",
          SelectFeatureCode: "Select Feature Code",
          SelectFeatureDesc: "Select Feature Desc",
          ColumnWidth: "Column Width",
          ColumnHeight: "Column Height",
          SubTotal: "Sub Total (Row cannot be empty)",
          ShowSumColumns: "Show Sum Columns",
          Control: "Allow Controls",
          WrapHeader: "Wrap Header",
          ValuesAsColumns: "Values as Columns",
          Renderer: "Renderer",
          Table: "Table",
          TableBarchart: "TableBarchart",
          PivotTableDescription: "Pivot Table Description",
          AutoSize: "Auto Size",
          SizeToFit: "Size To Fit",
          ExportToPDF: "Export to PDF",
          ExportDate: "Export Date",
          AllowControls: "Allow Controls",
          ShowBoth: "Show Both",
          JustRow: "Just Row",
          JustColumn: "Just Column",
          ThemeColour: "Theme Colour",
          SelectSum: "Select Sum",
          RowHeight: "Row Height",
          //RowWidth: "Image Row Width",
          LinkDescription: "Link Description",
          ImageField: "Image Field",
          TableDescription: "Table Description",
          WrapCells: "Wrap Cells",
          EnablePaging: "Enable Pagination",
          HideFiltering: "Hide Filtering",
          ShowColumnTotal: "Show Column Total",
          Pagination: "Pagination",
          PageNumber: "The Number of Pages You Want to Export to Excel",
          PaginationPageSize: "Pagination Page Size",
          AxisNumber: "Axis Number",
          Multi: "Multi",
          Single: "Single",
          SingleStack: "Single Stack",
          ShowLineValue: "Show Line Value",
          ShowBarValue: "Show Bar Value",
          ColoredLineValue: "Colored Line Value",
          AxisType: "Axis Type",
          AxisSettings: "Axis Settings",
          YAxisStylingOption : "Show Y Axis Title",
          YAxisFontStyle : "Y Axis Font Style",
          XAxisStylingOption : "Show X Axis Title",
          XAxisFontStyle : "X Axis Font Style",
          YAxisShowColumnNameAsTitle: "Show Column Names As Title",
          DisplayName: "Display Name",
          ShowEllipsis: "Truncate the lengthy texts in tooltip",
          ShowEllipsisXAxis: "Truncate the X Axis Title",
          Icon: "Icon",
          IsImage: "Is Image?",
          ImageSource: "Image Source",
          Animation: "Animation",
          XAxisBarTitleAngle: "X Axis Bar Title Angle",
          YAxisBarTitleAngle: "Y Axis Bar Title Angle",
          XAxisBarTitleLength: "X Axis Bar Title Length",
          CharacterValue: "# of Char",
          Horizontal: "Horizontal",
          Vertical: "Vertical",
          ZeroToOnehundredEighty: "0 to 180",
          StackBar: "Stack Bar",
          ShowValueOnBar: "Show Value On Bar",
          DefaultSortDirection: "Default Sort Direction",
          VerticalDirection: "Vertical Direction",
          HorizontalDirection: "Horizontal Direction",
          Descending: "Descending",
          Ascending: "Ascending",
          ColumnNumber: "(Column Number)",
          ShadeArea: "Shade Area",
          ShowSortControl: "Show Sort Control",
          DefaultSortColumn: "Default Sort Column",
          BrushNavigator: "Brush Navigator",
          NullColour: "Null Colour",
          MinimumSize200: "You Can Only Type 200 Or Over Values",
          DragSelect: "Drag Select",
          LinkFieldInfo: "Separate multiple links with \" ; \"",
          LinkDescriptionInfo: "Separate multiple link descriptions with \" ; \"",
          ShowMeasureDescriptionInfo: "Shows pie's value on pie or with lines",
          LegendsMustNotShowed: "Some sizes causes view problems. Because of that, in under some sizes, legend config can automatically closed.",
          ShowMeasureMustNotShowed: "Some sizes causes view problems. Because of that, in under some sizes, show measure config can automatically closed.",
          ColumnHeader: "Column Settings",
          ColumnHeaderFont: "Header Font",
          ColumnHeaderFontSize: "Header Font Size",
          ColumnHeaderFontColor: "Header Font Color",
          ColumnHeaderThemeColour: "Header Theme Colour",
          ColumnHeaderHeight: "Header Height",
          ShowCornerTotal: "Show Corner Total",
          ShowCornerCheckboxInfo: "Only used with Show Both"
        }
      },
      Data: {
        DataFormatType: {
          DataType: {
            Text: "Text",
            Number: "Number",
            WholeNumber: "Whole Number",
            Decimal: "Decimal",
            Rounding: "Rounding",
            Time: "Time",
            Date: "Date",
            Timestamp: "Timestamp",
            Custom: "Custom",
            FormatWarning: "You are converting the data type {{typeOrginal}} to {{typeNew}} data type.",
            CustomFormatDropdownError: "Custom format cannot be used without dropdown appearance."
          },
          DecimalPlaces: "Decimal Places",
          Use1000Separator: "Use 1000 Separator (.)",
          RoundingNumber: "Rounding Number",
          TimestampTypeError: "Date data type cannot be converted into timestamp.", 
          NoTimestamp: "None"
        },
        ExpressionDesc: "It is result of the expression of the written formula without going to the data source.",
        QueryDesc: "It is the result of the written formula in the relevant data source.",
        ExpressionError: "Expression can't calculate or calculation take too much time.",
        ExpressionUsedError: "You can't use expressions in queries",
        Data: "Data",
        DataDescription: "You select your data here to visualize your plugins. You can drag columns from your tables to the column mappings section.",
        MultipleColumn: "Multiple Column",
        MultiColumn: "Multi Column",
        Locale: "Locale",
        Format: "Format",
        Name: "Name",
        ValueAlign: "Value Align",
        AffixAlign: "Padding",
        PreserveDataAs: "Preserve Data As",
        PreserveDataAsDesc: "Preserves data of this column for use expression parameter. You cannot use default filter compared plugins.",
        Query: "Query",
        Expression: "Expression",
        ExpressionParametersMustBeNumeric: "Expression parameters must be numeric!",
        ExpressionCouldNotBeEvaluated: "Expression could not be evaluated!",
        CantPerformAnyActionError: "You can't perform any action in Interaction when you use Expression",
        ExpressionParameterNotFound: "Expression parameter not found",
        ExpressionParameterCompareError: "You cannot use expression parameters with compare filters!",
        ExpressionParameters: "Expression Parameters",
        ExpressionCalculating: "Expression calculating...",
        RestrictedCharacters: "' (Apostrophe), [ (Opening square bracket), ] (Closing square bracket), { (Opening curly bracket) and } (Closing curly bracket) are restricted characters",
        ColumnProperties: "Column Properties",
        ExistingNameError: "Column name already exists.",
        UseConditionalFormatting: "This column was also used in conditional formatting.",
        DeleteConditionalFormatting: "If you delete it, the relevant conditional format would be deleted as well.",
        AreYouSure: "Do you want to continue?",
        Formula: "Formula",
        Value: "Value",
        FormulaDescription: "Formula Description",
        ValueTypeDescription: "You can select multiple choices with new line.",
        ValueDescription: "Value Description",
        SortYourColumns: "Sort Your Columns",
        SortColumnsDesc: 'You can sort your columns by drag.',
        SortColumnsEmptyDesc: "Drag at least one column across the column mapping to be able to sort.",
        NumericFilterError: "Non numeric value can not enter in numeric field.",
        UseColumnForTotal: "Include column in total",
        EnableTotal: "You should enable show total",
        EmptyDataContentText:"Click the #i icon in the upper left corner to visualize b#your own data/b#  in this plugin.",
        ApplyAllRows: "Apply To All Rows",
        ApplyAllColumns: "Apply To All Columns",
        ApplyAllMeasures: "Apply To All Measures",
        DefaultFilters: {
          Title: "Default Filters",
          Desc: "Default Filters description field",
          EmptyDesc: "Filters not found."
        },
        WhatIf: {
          Title: "What If",
          Status: "Status",
          Description: "What if description",
          EnableDescription: "Enable Description",
          Type: "Type",
          OutputDescription: "Output Description",
          InputDescription: "Input Description",
          Input: "Input",
          Output: "Output",
          OutputFormula: "Output Formula",
          OutputFormulaDescription: "Output Formula Description",
          FormulaDisabled: "Formula disabled",
          WhatIfSuccessfullyAdded: "What if successfully added.",
          CouldntEvaluate: "Could not evaluate",
          SetDefault: "set default",
          AddNew: "Add New",
          List: "List",
          SaveCurrentScenario: "Save current scenario",
          ScenarioNameError: "To save the scenario, you have to name it",
          ScenarioSuccessfullyAdded: "Scenario successfully added",
          ScenarioName: "Scenario Name",
          Scenarios: "Scenarios",
          Parameters: "Parameters",
          CurrentScenario: "Current Scenario",
          CreateUpdate: {
            Name: "Formula Name",
            DisplayName: "Display Name",
            DefaultValue: "Default Value",
            MinValue: "Minimum Value",
            MaxValue: "Maximum Value",
            IncrementValue: "Increment Value",
            DataType: "Parameter Data Type",
            ShowType: "Show Type",
            Slider: "Slider",
            Input: "Input",
            Integer: "Integer",
            Decimal: "Decimal",
            Percent: "Percent",
            NoBlankSpaceCanBeLeft: "No blank space can be left.",
            DefaultValueCannotBeLowerThanMinValue: "Default value cannot be lower than minimum value.",
            DefaultValueCannotBeGreaterThanMaxValue: "Default value cannot be greater than maximum value.",
            IncrementValueCannotBeGreaterThanDifferenceOfMaxValueAndMinValue: "Increment value cannot be greater than difference of max value and min value",
            IncrementValueMustBeGreaterThanZero: "Increment value must be greater than zero.",
            IncrementValueCannotBeGreaterThanMaxValue: "Increment value cannot be greater than max value"
          },
          SuccessfullyAdded: "What if parameter successfully added",
          SuccessfullyUpdated: "What if parameter successfully updated"
        },
        ASC: "ASC",
        DESC: "DESC"
      },
      ConditionalFormatting: {
        ConditionalFormatting: "Conditional Formatting",
        ConditionalFormattingDescription: "You can customize your plugin by adding conditions to columns, expressions and values.",
        NoConditionalFormatsFound: "No conditional formats found.",
        TargetColumns: "Target Columns",
        TargetColumnsDescription: "Here you can select the columns to apply conditional formatting.",
        Rule: "Rule",
        RuleDescription: "While creating the rule; You can select/write a column, expression, or value. You combine the left and right sides of the rule with the operator of your choice.",
        RuleDescriptionTitle: "Rule Description",
        RuleDescriptionInfo: {
          Info: "Conditional formatting text field to be written in the legend area."
        },
        NoData: "Rule Description",
        LeftRule: "Left Rule",
        Operator: "Operator",
        RightRule: "Right Rule",
        BackgroundColor: "Background Color",
        Color: "Color",
        ConditionalFormatSuccessFullyAdded: "Conditional format successfully added.",
        ConditionalFormatSuccessFullyDeleted: "Conditional format successfully deleted.",
        OptionNotValid: "Any option selected.",
        RuleNotValid: "Rule not valid.",
        TargetColumnsCannotEmpty: "At least one column must select.",
        Target: "Target",
        Condition: "Condition",
        Style: "Style",
        TextColor: "Text Color",
        CouldNotRunRule: "Could not run rule. Please check and enter a valid rule.",
        TypeMismatch: "Failed to execute rule due to type mismatch about column/value.",
        Icon: "Icon",
        IconProperties: "Icon Status",
        Font: "Font Style",
        TitleFontStyle: "Title Font Style",
        ValueFontStyle: "Value Font Style",
        HeaderFontStyle: "Row/Column Font Style",
        TotalStyle: "Total Style"
      },
      Save: {
        Save: "Save",
        SaveAs: "Save As",
        TitleEmptyMessage:
          "You cannot save without adding a title to your dashboard.",
        TitleEmptyError:
          "The report could not be saved because the title was not added!",
        SuccessMessage: "We have successfully saved your dashboard.",
        NoPlugins: "You cannot save reports without any plugins!"
      },
      Plugin: {
        Untitled: "Untitled",
        DoYouWantToDeleteThisPlugin: "Do you want to delete this plugin?",
        Calculations: {
          Errors: {
            "Missing": "#MISSING",
            "Error": "#ERROR"
          }
        }
      },
      PluginContextMenu: {
        DuplicatePlugin: "Duplicate",
        Delete: "Delete",
        DuplicateAll: "Select All",
        Interaction: "Interaction",
        Drilldown: "Drilldown",
        Navigation: "Navigation",
        DefaultFilter: "Default Filter",
        ConditionalFormat: "Conditional Format",
        PastePlugin: "Paste",
        ConvertPlugin: "Convert",
        Export: "Export",
        Default: "Default",
        Back: "Back",
        Options: "Options",
      },
      PluginConversion: {
        ConditionalFormatMismatchWarning: "Conditional formats containing some columns whose data fields have changed will be deleted as they may no longer be applicable."
      },
      ToolsSection: {
        CantExportXLSX: "Couldn't find a plugin to export",
        DataNotFound: "Data is not available in the following plugin:\n{{plugin}}",
        DataNotFoundMultiple: "No data available for the following plugins:\n{{pluginList}}",
        ExporttoXLSX: "Export to XLSX",
        PluginList: "Plugin List",
        ExporttoPDF: "Export to PDF",
        ExporttoPNG: "Export to PNG",
        ExportButton: "Export",
        RefreshButton: "Refresh",
      },
      AutoRefresh: {
        AutoRefresh: "Auto Refresh",
        Description: "In this field, you can ensure that your data is automatically updated by giving the time you want and excluding the plugins that you do not want to be refreshed.",
        SuccessfullyEnabled: "Auto refresh successfully enabled.",
        SuccessfullyDisabled: "Auto refresh successfully disabled.",
        EnableDescription: "You can pause or start to auto refresh in this field.",
        RefreshTime: "Refresh time (sec)",
        ExceptedPlugins: "Excepted Plugins",
        AutoRefreshSuccessfullyAddedToDashboard: "Auto refresh successfully added to dashboard.",
        AutoRefreshIsActive: "Refresh - Auto refresh is active"
      },
      SuccessfullyDeleted: "Dashboard successfully deleted.",
      SuccessfullyReloaded: "Dashboard successfully reloaded.",
      SureDeletePermanentlyDashboard: "Do you want to delete dashboard permanently?",
      SuccessfullyPermanentlyDeleted: "Dashboard permanently deleted.",
      DashboardRemovedFromFavorite: "Dashboard removed from favorite",
      RemoveFromFavorite: "Remove from favorite",
      DashboardAddedToFavorite: "Dashboard added to favorite",
      AddToFavorite: "Add to favorite",
      LinkCopiedOnClipboard: "Link copied on clipboard.",
      SureDeleteDashboard: "Are you sure want to delete this dashboard?",
      DashboardList: "Dashboards",
      SwitchDashboard: "Switch Dashboard",
      DashboardNotFound: "Dashboard not found!"
    },
    Designer: {
      Template: "Template",
      ReportTemplate: "Report Template",
      ExistingTemplate: "Existing Template",
      NewTemplate: "New Template",
      TemplateName: "Template Name",
      TemplateFile: "Template File",
      FileName: "File Name",
      FileSize: "File Size",
      UploadDate: "Upload Date",
      DownloadTemplate: "Download Template",
      SelectFile: "Select File",
      SelectTemplateFile: "Select Template File",
      SaveTemplate: "Save Template",
      ChangeFile: "Change File",
      SupportedFileFormats: "Supported file formats",
      Bytes: "Bytes",
      CannotSaveTemplateWithoutName: "You cannot save the template without a name.",
      TemplateSaveSuccess: "Template is saved successfully.",
      TemplateDownloadSuccess: "Template is downloaded successfully.",
      
      Query: "Query",
      Queries: "Queries",
      CreateNewQuery: "Create a new query",
      QueryName: "Query Name",
      YouCanCreateQuery: "You can create a query by clicking the + button on the top.",
      QueryCreatedButNotSaved: "Query is created but not saved",
      YouCannotSaveQueryWithEmptyProperty: "You cannot save a query with an empty property.",
      YouNeedToSaveQueryCreatedBefore: "A query has already been created but not saved. Please save the query and try again.",
      QuerySaveSuccess: "Query is saved successfuly.",
      QueriesAreYourSureDeleteThisPermenently: "Are you sure to delete this query permenently?",

      Datasource: "Datasource",
      Datasources: "Datasources",
      Connection: "Connection",
      NewConnection: "New Connection",
      SelectADatasource: "Select a datasource",
      CouldNotFoundAnyDatasource: "No data source found.",

      Parameter: "Parameter",
      Parameters: "Parameters",
      NewParameter: "New Parameter",
      Name: "Name",
      ParameterName: "Parameter Name",
      DataType: "Data Type",
      Text: "Text",
      Number: "Number",
      Date: "Date",
      InputType: "Input Type",
      Free: "Free",
      List: "List",
      Value: "Value",
      Values: "Values",
      ValueName: "Name",
      Static: "Static",
      Dynamic: "Dynamic",
      Single: "Single",
      Multiple: "Multiple",
      NamedParameterValues: "Named parameter values",
      NamedParameterValuesInfo: "Description...",
      ParameterValues: "Parameter Values",
      StaticValuesInfo: "Separate your values ​​with commas or write them one below the other.",
      StaticLabelsInfo: "Separate your labels ​​with commas or write them one below the other.",
      ParameterSaveSuccess: "Parameter is saved successfuly.",
      Dependency: "Dependency",
      PleaseCheckThatThisParameterHasValue: "Parameter {parameter} depends on {dependencies}. Please check that this parameter has value.",
      PleaseCheckThatTheseParametersHaveValue: "Parameter {parameter} depends on {dependencies}. Please check that these parameters have value.",
      RefreshParameters: "Refresh Parameters",
      PleaseCheckYourDependentParameters: "Please make sure the parameters do not have any nested dependencies.",

      RecycleBin: "Recycle Bin",
      RecycleBinInfo: "The items you want to delete are shown here. When you save your report, the items are deleted and your recycle bin is emptied.",
      EmptyRecycleBin: "There are no item in recycle bin.",
      MoveToRecycleBin: "Move to recycle bin",
      AreYouSureDeleteThisQueryPermenently: "Are you sure to delete this query permenently?",
      AreYouSureMoveThisQueryToRecycleBin: "Are you sure to move this query to recycle bin?",
      AreYouSureMoveThisParameterToRecycleBin: "Are you sure to move this parameter to recycle bin?",

      ThereIsUnsavedChanges: "There are unsaved changes",
      ReportNameAlreadyExists: "There is already a report with this name.",
      YouCannotSaveWithoutAnyQuery: "You cannot save the report without any query.",
      YouNeedToSaveReportBeforeQuerySave: "You need to save your report before you save the query.",
      YouNeedToSaveReportBeforeCreateParameter: "You need to save your report before you create a parameter.",
      YouNeedToUploadTemplateFile: "You need to upload a template file.",
      YouNeedToSaveReportBeforeGenerate: "You need to save your report before generate it.",
      ParameterNameRules: "The name must start with a letter, and can only contain alpha-numeric characters and underscores. (A-z, 0-9, and _ )",
      PleaseUploadCompatipleTemplateFile: "Please upload a compatiple template file.",
      YouDontHaveAnyParameter: "You don't have any parameter.",

      GenerateReport: "Generate Report",
      FileType: "File Type",
      ParameterValueSelection: "Parameter Value Selection",
      SelectValue: "Select value",
      EnterValue: "Enter value",
      EnterDate: "Enter date",
      ReportDownloadSuccess: "Report is downloaded successfully.",
      FormatSettings: "Format Settings",
      AdvancedFormatSettings: "Advanced Format Settings",
      GeneralSettings: "General",
      SetOnce: "Apply for once",
      SetAsDefault: "Apply as default",
      RemoveChanges: "Remove Changes",
      SetFromTemplate: "Get Template Format",
      SettingsSaveSuccess: "Settings are saved successfully.",
      MeasurementUnit: "Measurement Unit",
      Extensions: {
        "pdf": "PDF",
        "xlsx": "Excel",
        "docx": "Word",
      },
      Unit: {
        Inch: "Inch",
        Centimeter: "Centimeter",
        Millimeter: "Millimeter",
        Point: "Point"
      },
      Orientation: {
        Title: "Orientation",
        Portrait: "Portrait",
        Landscape: "Landscape"
      },
      PaperFormat: {
        Title: "Paper Format",
        Format: "Format",
        Letter: "Letter",
        A3: "A3",
        A4: "A4",
        A5: "A5",
        A6: "A6",
        Custom: "Custom",
        Width: "Width",
        Height: "Height"
      },
      Margins: {
        Title: "Margins",
        Left: "Left",
        Right: "Right",
        Top: "Top",
        Bottom: "Bottom",
        Header: "Header",
        Footer: "Footer"
      },
      Scale: {
        Title: "Scale",
        FitToWidth: "Fit width in pages",
        FitToHeight: "Fit height in pages"
      },
      TableAlignment: {
        Title: "Table Alignment",
        Horizontal: "Horizontal",
        Vertical: "Vertical"
      },
      PageOrder: {
        Title: "Page Order",
        Order: "Order",
        DownThenOver: "Top to bottom, then right",
        OverThenDown: "Left to right, then bottom",
        FirstPageNumber: "First Page Number"
      },
      HeaderFooter: {
        ShowHeader: "Show header",
        ShowFooter: "Show footer",
        DifferentOddEven: "Different content on odd and even pages",
        DifferentFirst: "Different content on first page",
        LeftArea: "Left Area",
        CenterArea: "Center Area",
        RightArea: "Right Area",
        LeftMargin: "Left Margin",
        RightMargin: "Right Margin",
        Spacing: "Spacing",
        Height: "Height",
        HeaderContent: "Header Content",
        FooterContent: "Footer Content",
        AllPages: "All Pages",
        FirstPage: "First Page",
        OddPages: "Odd Pages",
        EvenPages: "Even Pages",
        RestOfPages: "Other Pages"
      },
      AdvancedPage: {
        File: "File",
        Page: "Page",
        Header: "Header",
        Footer: "Footer",
        Sheet: "Sheet",
      },
      
      English: "English",
      Turkish: "Turkish",
      FileLanguage: "Language",

      Save: {
        Save: "Save",
        SaveAs: "Save As",
        TitleEmptyMessage:
          "You cannot save your report without a name.",
        TitleEmptyError:
          "The report could not be saved because the name was not added!",
        SuccessMessage: "We have successfully saved your report."
      },

      ReportNotFound: "Report not found."
    },
    FormulaEditor: {
      OpenFormulaEditor: "Open formula editor",
      Titles: {
        TablesAndColumns: "Tables & Columns",
        Parameters: "Parameters",
        Editor: "Editor",
        Formulas: "Formulas",
        DescriptionArea: "Description Area",
        AreYouSureCancel: "Are you sure you want to exit?",
        Variables: "Variables"
      },
      WhatIsExpression: "What is Expression?",
      ExpressionUsageDesc: "These are calculations that without going to the data source.",
      EmptyExpressionParameterList: "If you define any expression parameter, they will list this area.",
      TypeYourCode: "-- TYPE YOUR CODE",
      YourChangesWillBeLost: "All changes you made will be lost.",
      Advanced: "Advanced",
      Description: "Description",
      Usage: "Usage",
      Type: "Type",
      SelectFormula: "Click on any formula on the right to see its function and how to use it.",
      ExpressionParametersDescription: "These are calculations that without going to the data source.",
      ExpressionQueryError: "You can't save expression with query selection.",
      ExpressionParametersUsageDesc: "(${exp.<b>param1</b>} + ${exp.<b>param2</b>}) / ${exp.<b>param3</b>} ",
      FunctionTypes: {
        AggregateFunctions: "Aggregate Functions",
        NumericFunctions: "Numeric Functions",
        StringFunctions: "String Functions",
        DateFunctions: "Date Functions",
        TypeConversionFunctions: "Type Conversion Functions",
        ConditionalFunctions: "Conditional Functions",
      },
      FunctionType: {
        AggregateFunction: "Aggregate Function",
        NumericFunction: "Numeric Function",
        StringFunction: "String Function",
        DateFunction: "Date Function",
        TypeConversionFunction: "Type Conversion Function",
        ConditionalFunction: "Conditional Function"
      },
      Formulas: {
        // Aggregate Functions
        SUM: {
          Description: "Sum of the values (excluding <b>NULL</b>'s) in a group."
        },
        COUNT: {
          Description: "Count the number of values (excluding <b>NULL</b>'s) in a group."
        },
        AVG: {
          Description: "Average of the values (excluding <b>NULL</b>'s) in a group."
        },
        MIN: {
          Description: "Minimum value in a group (excluding <b>NULL</b>)."
        },
        MAX: {
          Description: "Maximum value in a group (excluding <b>NULL</b>)."
        },
        // Conditional Functions
        CASE: {
          Description: [
            "<b>Type 1</b> starts with an initial expression and evaluates <b>WHEN</b> expressions until the values match, and outputs the <b>THEN</b> expression. ",
            "If no <b>WHEN</b> is matched, the <b>ELSE</b> expression is output. ",
            "If no <b>WHEN</b> is matched and no <b>ELSE</b> is specified, a <b>NULL</b> literal value is output.",
            "<br /><br />",
            "<b>Type 2</b> searches the <b>WHEN</b> clauses, which specify an arbitrary criteria to evaluate. ",
            "If any criteria evaluates to <b>true</b>, the <b>THEN</b> expression is evaluated and output. ",
            "If no <b>WHEN</b> is <b>true</b>, the <b>ELSE</b> is evaluated or <b>NULL</b> is output if none exists.",
            
          ].join("")
        },
        // Numeric Functions
        SQRT: {
          Description: "Square root of <b>x</b>."
        },
        ABS: {
          Description: "Absolute value of <b>x</b>"
        },
        CEILING: {
          Description: "Ceiling of <b>x</b>"
        },
        FLOOR: {
          Description: "Floor of <b>x</b>"
        },
        ROUND: {
          Description: "Round <b>x</b> to <b>y</b> places; negative values of <b>y</b> indicate places to the left of the decimal point"
        },
        EXP: {
          Description: "<b>e</b> to the power of <b>x</b>."
        },
        LOG: {
          Description: "Natural log of <b>x</b> (base <b>e</b>)"
        },
        LOG10: {
          Description: "Log of <b>x</b> (base <b>10</b>)"
        },
        MOD: {
          Description: "Modulus (remainder of <b>x/y</b>)"
        },
        POWER: {
          Description: "<b>x</b> to the <b>y</b> power"
        },
        RADIANS: {
          Description: "Convert <b>x</b> radians to degrees"
        },
        DEGREES: {
          Description: "Convert <b>x</b> degrees to radians"
        },
        // String Functions
        ASCII: {
          Description: "Provide <b>ASCII</b> value of the left most <b>character[1]</b> in <b>x</b>"
        },
        CHR: {
          Description: "Provide the character for <b>ASCII</b> value <b>x</b>"
        },
        CHAR: {
          Description: "Provide the character for <b>ASCII</b> value <b>x</b>"
        },
        CONCAT: {
          Description: "Concatenates <b>x</b> and <b>y</b>. If <b>x</b> and/or <b>y</b> is null, returns null"
        },
        CONCAT2: {
          Description: "Concatenates <b>x</b> and <b>y</b> with <b>non-ANSI</b> null semantics. If <b>x</b> and <b>y</b> is null, returns null. If only <b>x</b> or <b>y</b> is null, returns the other value."
        },
        ENDSWITH: {
          Description: "Checks if <b>y</b> ends with <b>x</b>. If <b>x</b> or <b>y</b> is null, returns null"
        },
        INITCAP: {
          Description: "Make first letter of each word in string <b>x</b> capital and all others lowercase"
        },
        LCASE: {
          Description: "Lowercase of <b>x</b>"
        },
        LEFT: {
          Description: "Get left <b>y</b> characters of <b>x</b>"
        },
        LENGTH: {
          Description: "Length of <b>x</b> (return type is <b>integer</b>)"
        },
        CHAR_LENGTH: {
          Description: "Length of <b>x</b> (return type is <b>integer</b>)"
        },
        CHARACTER_LENGTH: {
          Description: "Length of <b>x</b> (return type is <b>integer</b>)"
        },
        LOCATE: {
          Description: "Find position of <b>x</b> in <b>y</b> starting at beginning of <b>y</b>"
        },
        LTRIM: {
          Description: "Left trim <b>x</b> of blank chars"
        },
        RTRIM: {
          Description: "Right trim <b>x</b> of blank chars"
        },
        REPEAT: {
          Description: "Repeat <b>str1</b> a specified number of times"
        },
        RIGHT: {
          Description: "Get right <b>y</b> characters of <b>x</b>"
        },
        SUBSTRING: {
          Description: "Get substring from <b>x</b>, from position <b>y</b> to the end of <b>x</b>"
        },
        TRANSLATE: {
          Description: "Translate string <b>x</b> by replacing each character in <b>y</b> with the character in <b>z</b> at the same position."
        },
        TRIM: {
          Description: "Trim the <b>leading</b>, <b>trailing</b>, or <b>both</b> ends of a string <b>y</b> of character <b>x</b>."
        },
        UCASE: {
          Description: "Uppercase of <b>x</b>"
        },
        // Type Conversation Functions
        CONVERT: {
          Description: "Convert <b>x</b> to type"
        },
        CAST: {
          Description: "Convert <b>x</b> to type"
        },
        // Date Functions
        CURDATE: {
          Description: "Returns the current <b>date</b>."
        },
        CURRENT_DATE: {
          Description: "Returns the current <b>date</b>."
        },
        CURTIME: {
          Description: "Returns the current <b>local time</b>."
        },
        CURRENT_TIME: {
          Description: "Returns the current <b>local time</b>."
        },
        NOW: {
          Description: "Returns current <b>timestamp</b> (date and time with <b>millisecond</b> precision)."
        },
        CURRENT_TIMESTAMP: {
          Description: "Returns current <b>timestamp</b> (date and time with <b>millisecond</b> precision)."
        },
        DAYNAME: {
          Description: "Returns <b>name of day</b> in the <b>default locale</b>."
        },
        DAYOFMONTH: {
          Description: " Returns <b>day</b> of <b>month</b>."
        },
        DAYOFWEEK: {
          Description: "Returns <b>day</b> of <b>week</b>."
        },
        DAYOFYEAR: {
          Description: "Returns <b>day number</b> in <b>year</b>."
        },
        EXTRACT: {
          Description: [
            "Returns the given <b>FIELD</b> value from the date value <b>x</b>.",
            "<br />",
            "Valid <b>FIELD</b>s are: <b>YEAR</b>, <b>MONTH</b>, <b>DAY</b>, <b>HOUR</b>, <b>MINUTE</b>, <b>SECOND</b>, <b>QUARTER</b>, <b>DOW</b> and <b>DOY</b>."
          ].join("")
        },
        HOUR: {
          Description: "Returns <b>hour</b>."
        },
        MINUTE: {
          Description: "Returns <b>minute</b>."
        },
        MONTH: {
          Description: "Returns <b>month</b>."
        },
        MONTHNAME: {
          Description: "Returns <b>name</b> of month in the <b>default locale</b>."
        },
        QUARTER: {
          Description: "Returns <b>quarter</b>."
        },
        SECOND: {
          Description: "Returns <b>seconds</b>."
        },
        WEEK: {
          Description: "Returns <b>week</b> in <b>year</b> 1-53."
        },
        YEAR: {
          Description: "Returns <b>four-digit</b> <b>year</b>."
        },
        TIMESTAMPADD: {
          Description: [
            "Add a specified interval amount to the timestamp.",
            "<br /><br />",
            "<b>interval</b>:",
            "<br />",
            "<ul>",
            "<li><b>SQL_TSI_FRAC_SECOND</b>: fractional second</li>",
            "<li><b>SQL_TSI_SECOND</b>: fractional second</li>",
            "<li><b>SQL_TSI_MINUTE</b>: minute</li>",
            "<li><b>SQL_TSI_HOUR</b>: hour</li>",
            "<li><b>SQL_TSI_DAY</b>: day</li>",
            "<li><b>SQL_TSI_WEEK</b>: week using Sunday as the first day</li>",
            "<li><b>SQL_TSI_MONTH</b>: month</li>",
            "<li><b>SQL_TSI_QUARTER</b>: quarter</li>",
            "<li><b>SQL_TSI_YEAR</b>: year</li>",
            "</ul>",
            "<br />",
            "<b>count</b>: Integer count of units to add to the timestamp. Negative values will subtract that number of units.",
            "<br /><br />",
            "<b>timestamp</b>: Datetime expression."
          ].join("")
        },
        TIMESTAMPDIFF: {
          Description: [
            "Calculates the number of date part intervals between the two timestamps.",
            "<br /><br />",
            "<b>interval</b>:",
            "<br />",
            "<ul>",
            "<li><b>SQL_TSI_FRAC_SECOND</b>: fractional second</li>",
            "<li><b>SQL_TSI_SECOND</b>: fractional second</li>",
            "<li><b>SQL_TSI_MINUTE</b>: minute</li>",
            "<li><b>SQL_TSI_HOUR</b>: hour</li>",
            "<li><b>SQL_TSI_DAY</b>: day</li>",
            "<li><b>SQL_TSI_WEEK</b>: week using Sunday as the first day</li>",
            "<li><b>SQL_TSI_MONTH</b>: month</li>",
            "<li><b>SQL_TSI_QUARTER</b>: quarter</li>",
            "<li><b>SQL_TSI_YEAR</b>: year</li>",
            "</ul>",
            "<br />",
            "<b>startTime</b>: Start Time (datetime).",
            "<br /><br />",
            "<b>endTime</b>: End Time (datetime)."
          ].join("")
        },
      }
    },
    Plugins: {
      "network-graph": {
        Name: "Network Graph",
        ColumnMap: {
          Id: {
            Name: "ID",
            Desc: "Network Graph ID description"
          },
          Label: {
            Name: "Label",
            Desc: "Network Graph label description"
          },
          LinkWith: {
            Name: "Link With",
            Desc: "Network Graph link with description"
          },
          ParentId: {
            Name: "Parent ID",
            Desc: "Network Graph parent ID description"
          },
          Measure: {
            Name: "Measure",
            Desc: "Network Graph measure description"
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Network Graph hidden desc."
          }
        }
      },
      "custom": {
        Name: "Vega Lite",
        ColumnMap: {
          Columns: {
            Name: "Columns",
            Desc: "Vega Lite columns desc",
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Vega Lite hidden desc."
          }
        },
      },
      "pie-chart-enhanced": {
        Name: "Pie Chart Enhanced",
        ColumnMap: {
          Measure: {
            Name: "Measure",
            Desc: "Pie Chart measure desc."
          },
          Category: {
            Name: "Category",
            Desc: "Pie chart category desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Pie chart hidden desc."
          }
        }
      },
      "measure-tile": {
        Name: "Measure Tile",
        ColumnMap: {
          Measure: {
            Name: "Measure",
            Desc: "Measure tile measure desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Measure tile hidden desc."
          }
        }
      },
      "pivot-table": {
        Name: "Pivot Table",
        ColumnMap: {
          Rows: {
            Name: "Rows",
            Desc: "Pivot table row desc."
          },
          Columns: {
            Name: "Columns",
            Desc: "Pivot table column desc."
          },
          Measures: {
            Name: "Measures",
            Desc: "Pivot table measure desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Pivot table hidden desc."
          }
        }
      },
      "table": {
        Name: "Table",
        ColumnMap: {
          Columns: {
            Name: "Columns",
            Desc: "Table columns desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Table hidden desc."
          }
        }
      },
      sunburst: {
        Name: "sunburst",
        ColumnMap: {
          Level: {
            Name: "Level",
            Desc: "Sunburst level desc."
          },
          Measure: {
            Name: "Measure",
            Desc: "Sunburst measure desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Sunburst hidden desc."
          }
        }
      },
      "liquid-gauge": {
        Name: "Liquid Gauge",
        ColumnMap: {
          Measure: {
            Name: "Measure",
            Desc: "Liquid gauge measure desc."
          },
          Target: {
            Name: "Hedef değer",
            Desc: "Liquid gauge target desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Liquid gauge hidden desc."
          }
        }
      },
      radar: {
        Name: "Radar",
        ColumnMap: {
          Category: {
            Name: "Category",
            Desc: "Radar category desc"
          },
          Measure: {
            Name: "Measure",
            Desc: "Radar measure desc"
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Radar hidden desc."
          }
        }
      },
      "pie-chart": {
        Name: "Pie Chart",
        ColumnMap: {
          Category: {
            Name: "Category",
            Desc: "Pie chart category desc."
          },
          Measure: {
            Name: "Measure",
            Desc: "Pie chart Measure desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Pie chart hidden desc."
          }
        }
      },
      "sankey-chart": {
        Name: "Sankey",
        ColumnMap: {
          Level: {
            Name: "Level",
            Desc: "Sankey level desc."
          },
          Measure: {
            Name: "Measure",
            Desc: "Sankey measure desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Sankey hidden desc."
          }
        }
      },
      "word-cloud": {
        Name: "Word Cloud",
        ColumnMap: {
          Freq: {
            Name: "Frequency",
            Desc: "Word cloud frequency description."
          },
          Word: {
            Name: "Word",
            Desc: "Word cloud word description"
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Word cloud hidden desc."
          }
        }
      },
      "map-choropleth": {
        Name: "Map Choropleth",
        ColumnMap: {
          Code: {
            Name: "Code",
            Desc: "Map choropleth code desc."
          },
          Description: {
            Name: "description",
            Desc: "Map choropleth description desc."
          },
          Measure: {
            Name: "Measure",
            Desc: "Map choropleth measure desc."
          },
          Vary: {
            Name: "Vary",
            Desc: "Map choropleth vary desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Map choropleth hidden desc."
          }
        }
      },
      image: {
        Name: "Image",
        ColumnMap: {
          URL: {
            Name: "URL",
            Desc: "Image URL desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Image hidden desc."
          }
        }
      },
      "line-bar-chart": {
        Name: "Line Bar Chart",
        ColumnMap: {
          Category: {
            Name: "Category",
            Desc: "Line Bar Chart category desc."
          },
          BarMeasure: {
            Name: "BarMeasure",
            Desc: "Line Bar Chart bar measure desc."
          },
          LineMeasure: {
            Name: "LineMeasure",
            Desc: "Line Bar Chart line measure desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Line Bar Chart hidden desc."
          }
        }
      },
      "line-bar": {
        Name: "Line Bar",
        ColumnMap: {
          Category: {
            Name: "Category",
            Desc: "Line Bar category desc."
          },
          Measure: {
            Name: "Measure",
            Desc: "Line Bar measure desc."
          },
          Vary: {
            Name: "Vary",
            Desc: "Line Bar vary desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Line Bar hidden desc."
          }
        }
      },
      "timeline-chart": {
        Name: "Timeline Chart",
        ColumnMap: {
          Date: {
            Name: "Date",
            Desc: "Timeline Chart date desc."
          },
          Measure: {
            Name: "Measure",
            Desc: "Timeline Chart measure desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Timeline Chart hidden desc."
          }
        }
      },
      "age-pyramid": {
        Name: "Pyramid Chart",
        ColumnMap: {
          Category: {
            Name: "Category",
            Desc: "Pyramid Chart category desc."
          },
          Either: {
            Name: "Measure Value 1",
            Desc: "Pyramid Chart measure value 1 desc."
          },
          Other: {
            Name: "Measure Value 2",
            Desc: "Pyramid Chart measure value 2 desc."
          },
          Animation: {
            Name: "Animation",
            Desc: "Piramit Grafiği animation açıklaması"
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Pyramid Chart hidden desc."
          }
        }
      },
      "bar-chart": {
        Name: "Bar Chart",
        ColumnMap: {
          Category: {
            Name: "Category",
            Desc: "Bar Chart category desc."
          },
          Measure: {
            Name: "Measure",
            Desc: "Bar Chart measure desc."
          },
          Vary: {
            Name: "Vary",
            Desc: "Bar Chart vary desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Bar Chart hidden desc."
          }
        }
      },
      "multi-axis-line-chart": {
        Name: "Multi Axis Line Chart",
        ColumnMap: {
          Category: {
            Name: "Category",
            Desc: "Multi Axis Line Chart category desc."
          },
          Columns: {
            Name: "Columns",
            Desc: "Multi Axis Line Chart columns desc."
          },
          Lines: {
            Name: "Lines",
            Desc: "Multi Axis Line Chart lines desc."
          },
          Vary: {
            Name: "Vary",
            Desc: "Multi Axis Line Chart vary desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Multi Axis Line Chart hidden desc."
          },
          Tooltip: {
            Months: "Months",
            ShortMonths: "Short Months",
            Days: "Days",
            ShortDays: "Short Days",
            ExportToSVG: "Export to SVG",
            ExportToPNG: "Export to PNG",
            DownloadSVC: "Download SVC",
            SelectionZoom: "Selection Zoom",
            Menu: "Menu",
            Selection: "Selection",
            ZoomIn: "Zoom In",
            ZoomOut: "Zoom Out",
            Pan: "Pan",
            Reset: "Reset"
          }
        }
      },
      "route-map": {
        Name: "Route Map",
        ColumnMap: {
          SourceId: {
            Name: "Source City Id",
            Desc: "Route map source city id desc."
          },
          SourceLat: {
            Name: "Source City Latitude",
            Desc: "Route map source city latitude desc."
          },
          SourceLon: {
            Name: "Source City Longitude",
            Desc: "Route map source city longitude desc."
          },
          DestinationId: {
            Name: "Destination City Id",
            Desc: "Route map destination city id desc."
          },
          DestinationLat: {
            Name: "Destination City Latitude",
            Desc: "Route map destination city latitude desc."
          },
          DestinationLon: {
            Name: "Destination City Longitude",
            Desc: "Route map destination city longitude desc."
          },
          Value: {
            Name: "Value",
            Desc: "Route map value desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Route map hidden desc."
          }
        }
      },
      "route-map-2": {
        Name: "Route Map 2",
        ColumnMap: {
          SourceId: {
            Name: "Source City Id",
            Desc: "Route map source city id desc."
          },
          SourceLat: {
            Name: "Source City Latitude",
            Desc: "Route map source city latitude desc."
          },
          SourceLon: {
            Name: "Source City Longitude",
            Desc: "Route map source city longitude desc."
          },
          DestinationId: {
            Name: "Destination City Id",
            Desc: "Route map destination city id desc."
          },
          DestinationLat: {
            Name: "Destination City Latitude",
            Desc: "Route map destination city latitude desc."
          },
          DestinationLon: {
            Name: "Destination City Longitude",
            Desc: "Route map destination city longitude desc."
          },
          Value: {
            Name: "Value",
            Desc: "Route map value desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Route map hidden desc."
          }
        }
      },
      "turkey-map": {
        Name: "Turkey Map",
        ColumnMap: {
          Value: {
            Name: "Value",
            Desc: "Turkey map value desc."
          },
          Plaka: {
            Name: "City Id",
            Desc: "Turkey map city id desc."
          },
          Title: {
            Name: "City Name",
            Desc: "Turkey map city name desc."
          },
          IlceId: {
            Name: "District Id",
            Desc: "Turkey map district id desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Turkey map hidden desc."
          }
        }
      },
      "tree-map": {
        Name: "Tree Map",
        ColumnMap: {
          Group: {
            Name: "Group",
            Desc: "Tree map group desc."
          },
          Measure: {
            Name: "Measure",
            Desc: "Tree map measure desc."
          },
          Vary: {
            Name: "Vary",
            Desc: "Tree map vary desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Tree map hidden desc."
          }
        },
      },
      "world-map": {
        Name: "World Map",
        ColumnMap: {
          Value: {
            Name: "Value",
            Desc: "World map value desc."
          },
          CountryCode: {
            Name: "Country Code",
            Desc: "World map country code desc."
          },
          CountryTitle: {
            Name: "Country Title",
            Desc: "World map country title desc."
          },
          CityId: {
            Name: "City Id",
            Desc: "World map city id desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "World map hidden desc."
          }
        }
      },
      "map-bubbles": {
        Name: "Map Bubbles",
        ColumnMap: {
          Description: {
            Name: "Description",
            Desc: "Map Bubbles description desc."
          },
          Longitude: {
            Name: "Longitude",
            Desc: "Map Bubbles longitude desc."
          },
          Latitude: {
            Name: "Latitude",
            Desc: "Map Bubbles latitude desc."
          },
          Measure: {
            Name: "Measure",
            Desc: "Map Bubbles measure desc."
          },
          Vary: {
            Name: "Vary",
            Desc: "Map Bubbles vary desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Map bubbles hidden desc."
          }
        }
      },
      "glassed-title": {
        Name: "Glassed Title",
        ColumnMap: {
          Value: {
            Name: "Value",
            Desc: "Glassed title value desc."
          }
        }
      },
      title: {
        Name: "Title",
        ColumnMap: {
          Hidden: {
            Name: "Hidden",
            Desc: "Title hidden desc."
          }
        }
      },
      "scatter-chart": {
        Name: "Scatter Chart",
        ColumnMap: {
          MeasureX: {
            Name: "X value",
            Desc: "Scatter Chart x value desc."
          },
          MeasureY: {
            Name: "Y value",
            Desc: "Scatter Chart y value desc."
          },
          Group: {
            Name: "Group",
            Desc: "Scatter Chart group desc."
          },
          VaryColour: {
            Name: "Vary Colour",
            Desc: "Scatter Chart vary by colour desc."
          },
          VarySize: {
            Name: "Vary Size",
            Desc: "Scatter Chart vary size desc."
          },
          Animation: {
            Name: "Animation",
            Desc: "Scatter Chart animation desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Scatter Chart hidden desc."
          }
        }
      },
      "chord-diagram": {
        Name: "Chord Diagram",
        ColumnMap: {
          Entities: {
            Name: "Entities",
            Desc: "Chord diagram entities desc."
          },
          Measure: {
            Name: "Measure",
            Desc: "Chord diagram measure desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Chord diagram hidden desc."
          }
        }
      },
      flag: {
        Name: "Flag",
        ColumnMap: {
          CountryID: {
            Name: "Country ID",
            Desc: "Flag Country ID desc."
          }
        }
      },
      "button-filter": {
        Name: "Button Filter",
        ColumnMap: {
          Filter: {
            Name: "Filter",
            Desc: "Button filter filter desc."
          },
          Hidden: {
            Name: "Filter",
            Desc: "Button filter hidden desc."
          }
        }
      },
      filter: {
        Name: "Filter",
        ColumnMap: {
          Filter: {
            Name: "Filter",
            Desc: "Filter, filter desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Filter hidden desc."
          }
        },
        RemoveAllItems: "Remove all items"
      },
      "date-filter": {
        Today: "Today",
        ThisWeek: "This Week",
        ThisMonth: "This Month",
        ThisYear: "This Year",
        CustomDate: "Custom Date"
      },
      "i-frame": {
        Name: "I-Frame",
        ColumnMap: {
          URL: {
            Name: "Url",
            Desc: "I-Frame url desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "I-Frame hidden desc."
          }
        }
      },
      "selection-box": {
        Name: "Selection Filter",
        ColumnMap: {
          Filter: {
            Name: "Filter",
            Desc: "Selection filter filter desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Selection hidden desc."
          }
        }
      },
      "selection-filter": {
        Name: "Selection Filter",
        ColumnMap: {
          Filter: {
            Name: "Filter",
            Desc: "Selection filter filter desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Selection filter hidden desc."
          }
        }
      },
      "cylinder-bar": {
        Name: "CylinderBar",
        ColumnMap: {
          Label: {
            Name: "Label",
            Desc: "Cylinder bar label desc."
          },
          Values: {
            Name: "Values",
            Desc: "Cylinder bar values desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Cylinder bar hidden desc."
          }
        }
      },
      "radial-progress": {
        Name: "Radial-Progress",
        RoundCorners: "Round Corners",
        ColumnMap: {
          Measure: {
            Name: "Measure",
            Desc: "Radial-Progress measure desc."
          },
          Target: {
            Name: "Target",
            Desc: "Radial-Progress target desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Radial-Progress hidden desc."
          }
        }
      },
      "gauge-chart": {
        Name: "Gauge Chart",
        ColumnMap: {
          Measure: {
            Name: "Measure",
            Desc: "Gauge Chart measure desc."
          },
          Maximum: {
            Name: "Maximum",
            Desc: "Gauge Chart maximum desc."
          },
          Minimum: {
            Name: "Minimum",
            Desc: "Gauge Chart minimum desc."
          },
          Target: {
            Name: "Target",
            Desc: "Gauge Chart target desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Gauge Chart hidden desc."
          }
        }
      },
      "mind-gapper": {
        Name: "mindgapper",
        ColumnMap: {
          XAxis: {
            Name: "X axis",
            Desc: "MindGapper x axis desc."
          },
          YAxis: {
            Name: "Y axis",
            Desc: "MindGapper y axis desc."
          },
          BubbleSize: {
            Name: "Bubble size",
            Desc: "MindGapper bubble size desc."
          },
          Year: {
            Name: "Year",
            Desc: "MindGapper year desc."
          },
          VaryByColor: {
            Name: "Vary by color",
            Desc: "MindGapper vary by color desc."
          },
          BubbleName: {
            Name: "Bubble name",
            Desc: "MindGapper bubble name desc."
          },
          ToggleCriteria: {
            Name: "Toggle criteria",
            Desc: "MindGapper toggle criteria desc."
          },
          Title: {
            Name: "Title",
            Desc: "MindGapper title desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "MindGapper hidden desc."
          }
        }
      },
      "map-cluster": {
        Name: "Map (Cluster)",
        ColumnMap: {
          Desc: {
            Name: "Description",
            Desc: "Map (Cluster) desc."
          },
          Longitude: {
            Name: "Longitude",
            Desc: "Map (Cluster) longitude desc."
          },
          Latitude: {
            Name: "Latitude",
            Desc: "Map (Cluster) latitude desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Map (Cluster) hidden desc."
          }
        }
      },
      "turkish-district-map": {
        Name: "Turkish District Map",
        ColumnMap: {
          Value: {
            Name: "Value",
            Desc: "Turkish district map value desc."
          },
          CityId: {
            Name: "City Id",
            Desc: "Turkish district map city id desc."
          },
          DistrictName: {
            Name: "District Name",
            Desc: "Turkish district map district name desc."
          },
          DistrictId: {
            Name: "District Id",
            Desc: "Turkish district map district id desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Turkish district map hidden desc."
          }
        }
      },
      heatmap: {
        Name: "Map (Heatmap)",
        ColumnMap: {
          Measure: {
            Name: "Measure",
            Desc: "Map (Heatmap) measure desc."
          },
          Longitude: {
            Name: "Longitude",
            Desc: "Map (Heatmap) longitude desc."
          },
          Latitude: {
            Name: "Latitude",
            Desc: "Map (Heatmap) latitude desc."
          },
          Hidden: {
            Name: "Hidden",
            Desc: "Map (Heatmap) hidden desc."
          }
        }
      }
    },
    Errors: {
      MeasureAreaMustBeMeasure: "Expected data type is numeric, but data type of column is not numeric. Please use the formul section.",
      DateAreaMustBeDate: "Expected data type is date, but data type of column is not date. Please use the formul section.",
      MinimumColorValue: "Can't delete last colour.",
      TopojsonErrorLayer: "Next layer is wrong!",
      BarChartVaryError: "Cannot render visualisation. Accepts multiple measure columns or a single measure and vary by colour dimension.",
      LineBarVaryError: "Cannot render line chart. Accepts multiple measure columns or a single measure and vary by colour dimension",
      FlagInValidColumnError: "Please select a valid type of column!",
      HeatmapNeedFiveColour: "Heatmap needs five colour.",
      SortingControl: "If you do not sort, the plugin may not render correctly.",
      CannotGetDataWithoutColumn: "You cannot visualize when no columns are selected.",
      ExpressionNameAlreadyExists: "The expression name was automatically changed to '[newExpressionName]' since an expression named '[oldExpressionName]' already exists."
    },
    Table: "Table",
    Column: "Column",
    Tables: "Tables",
    Columns: "Columns",
    ColumnMapping: "Column Mapping",
    LandingPage: {
      NewDashboard: "New Dashboard",
      NewReport: "New Report",
      ExpandLabel: 'Expand Label',
      CollapseLabel: 'Collapse Label',
      SortBy: 'Sort By',
      SortDashboards: 'Sort Dashboards',
      NoDashboard: "No dashboards found on this label.",
      Label: {
        Title: "Labels",
        Description: "Choose a label or labels to show you dashboards"
      },
      Preview: {
        CreatedUser: "Created User",
        Description: "Description",
        CreationDate: "Creation Date",
        UpdateDate: "Last Update Date",
        ViewCount: "View Count",
        TimesGenerated: "Generated Count",
      },
      GoDashboard: "Go to Dashboard",
      GoReport: "Go to Report",
      DashboardLabel: {
        Increasing: 'Increasing',
        Decreasing: 'Decreasing',
        NameAscending: 'A-Z Ascending',
        NameDescending: 'A-Z Descending',
        MostViewed: 'Most Viewed',
        LeastViewed: 'Least Viewed',
        NewestUpdated: 'Most Recently Updated',
        OldestUpdated: 'Least Recently Updated',
      },
      DashboardFooter: {
        Views: "views",
        TimesGenerated: "times generated",
      },
      EmptyLanding: {
        EmptyLandingDescription: "If you like to create b#magnificent visuals/b# that visualize your data press i# button.br#If you want to create b#reports/b# according to the template you have specified press i# button.br#If you already have some b#dashboards and reports/b# and want to display them press i# labels button and g#select labels/g# that you wish.",
        EmptyLandingDescriptionMobile: "If you already have some b#dashboards and reports/b#, and want to display them press i# labels button and g# select labels /g# that you wish."
      },
      Dashboard: "Dashboard",
      Report: "Report"
    },
    DateCalculation: {
      Day: 'day',
      Year: 'year',
      Month: 'month',
      Week: 'week'
    },
    Model: {
      Title: "Model",
      SelectModel: {
        PlaceHolder: "Select a Model"
      },
      Tables: {
        TablesContentDescription: "You have just created a model. This section is used to display selected tables. Add tables to this model, use connections window."
      },
      RemoveTableWarningDescription: "The join(s) for the table you are trying to delete have been found. Are you sure you want to delete?",
      TableName: "Table name:",
      DeleteTable: "Delete Table",
      DataUpdateSuccess: "Data upload completed successfully.",
      UnselectAllColumnInTable: "Please select at least one column!",
      ModelSelectionInformation: "Choose a model to visualize your data on plugins.",
      ModelCreationInformation: "To visualize your data on visual components you first need to create a model that is connector between your data and your visual components",
      ModelsPopup: "Models",
      ModelNameEmpty: "Model name cannot be empty!",
      CreateModelNameNotification: "The model name will guide you when choosing between your models. We recommend using a meaningful name for this. You can't change it later.",
      ModelName: "Model Name",
      CreateModel: "Add Model",
      ModelAfterCreateDescription: "Your model has been created. You can add a new resource by saying Let's Go and choose a table for your model or you can choose a table from your existing resources.",
      ModelAfterCreateButton: "Lets Go! :)",
      ModelNameUniqeError: "Model name has been used before. Model name must be uniq!",
      ChangeSelectedModel: "Change Selected Model",
      SelectedTablesForModel: "Selected tables for model",
      ModelCreateAnfFileUploadSuccess: "Model creation and csv file upload completed successfully",
      Join : "Join",
      JoinCreation: "Join Creation",
      JoinEdition: "Join Edition",
      JoinNoChange: "No change is applied",
      JoinCreateSuccessfull: "Join successfully created!",
      JoinEditSuccessfull: "Join successfully edited!",
      JoinDeletedSuccessfull: "Join successfully deleted!",
      JoinCanNotBeDeleted: "Join can not be deleted!",
      JoinCreateFailed: "Join can not be created!",
      JoinCannotBeFound: "Join cannot be found ",
      FactTableColumn: "Fact Table Column",
      FactTableColumnSelectError: "Fact Table Column should be selected!",
      PleaseSelectFactTable: "Select fact table",
      PleaseSelectDimensionTable: "Select dimension table",
      AreYouSureDeleteJoinDefinition: "Are you sure delete this join definition?",
      DimensionTablesColumns: "Dimension Tables Columns",
      AtLeastOneDimensionTableColumnSelectError: "At least one Dimension Table Column should be selected!",
      JoinType: "Join Type",
      MoreThanOneFact: "There are more than fact tables!",
      MustAddJoin: "You must add join(s)",
      JoinTypeSelectError: "Join Type should be selected!",
      JoinErrorNotJoinTwoTable: "Has not found {{table1}} and {{table2}} tables join. Do you want to add?",
      CantFoundJoinThisTables: "Has not found these tables join. Do you want to add?",
      DifferentDatasourceError: "Tables from two different data sources cannot be used.",
      DifferentDatasourceErrorTitle: "Error",
      CantFoundJoin: "Join can't found!",
      ModelAddSuccessfull: "Model successfully added.",
      ModelNamingDone: "Model naming done. I want to add a data connections!",
      CancelModelCreate: "Cancel Model Creation",
      NewDuplicatedTableSuccessMessage: "Duplicated table successfully added to model",
      ClearAggRule: "Clear Aggregation Rule",
      Create: "Create",
      Edit: "Edit",
      Duplicate: "Duplicate",
      WhereClause: "Where Clause",
      AggrigationRule: "Aggrigation Rule",
      DuplicateColumnName: "Enter Duplicate Column Name",
      WhereClauseColumn: "Enter Where Clause",
      EnterAggriagationRule: "Enter Aggrigation Rule",
      About: "About",
      Name: "Name",
      DataSource: "Data Source",
      TableNameIsNotUniqueTitle: "  name table already exist in model.",
      TableNameIsNotUniqueDescription: "You can add it by giving an unused table name.", 
      EnterRenameColumnName: "Enter rename column name",
      Pin: "Pin",
      UnPin: "Unpin",
      EmptyTable: "No Data",
      SearchNoData: "Data not found on these criteria"
    },
    FileUpload: {
      Delimiter: {
        Tab: "Tab",
        Space: "Space"
      },
      ChangeEncodingMessage: "Dosyayı yeniden yüklemeniz gerekiyor. Emin misiniz?",
      RowLoaded: " Row Loaded",
      Load: "Load",
      PreviewAndSelectDataType: "Preview And Select Data Type",
      FileTypeValid: "File type is not valid, Please check it!",
      EnterSplitter: "Splitter:",
      FileFormat: "Character Set",
      EnterLocalization: "Localization:",
      AppendData: "Append Data",
      TruncateFile: "Truncate File",
      DeleteFile: "Delete File",
      CsvNotLoad: "Csv file not loaded",
      InvalidType: "File type is not valid, Please check it!",
      EnterDateFormat: "Please add date format",
      SucceededRows: "Number of succeeded rows: ",
      FailedRows: "Number of failed rows: ",
      EmptyColumn: "There is an empty column in the csv file, the file cannot be uploaded. Delete the empty column and try again!",
      SelectDataSource: "Selected data source:",
      SelectModel: "Selected model: ",
      PleaseSelectModel: "Select a model for upload your file.",
      PleaseSelectDataSource: "Select a datasource for upload your file.",

      FileUpload: "File Upload",
      YouMustChooseAnyFile: "You must choose any file!",
      YouMustChooseAnySchema: "You must choose any schema!",
      Splitter: "Splitter",
      SelectSchemaName: "Select schema for selected data source:",
      ChooseYourFileType: "Choose your file type:",
      FileHasHeaderColumns: "File has header columns",
      ChooseYourFileTitle: "Choose your file",
      ChooseYourFileDesc: "Select file type & file.",
      PreviewAndEditTitle: "Preview & Edit",
      PreviewAndEditDesc:"Edit & choose fields.",
      CheckAcceptibilityTitle: "Check Acceptability",
      CheckAcceptibilityDesc: "File & table/column checks for upload.",
      UploadingTitle: "Uploading",
      UploadingDesc: "Starts upload & shows progress",
      FileUploadStatus: "File Upload Status",
      YourDataIsBeingReviewed: "Your data is being reviewed...",
      Cancel: "Cancel",
      Close: "Close",
      Previous: "Previous",
      Next: "Next",
      CheckTables: "Check Tables",
      Upload: "Upload",
      Minimize: "Minimize",

      NOT_STARTED: "Not Started",
      UPLOADING: "Uploading",
      DONE: "Done",
      FAILED: "Failed",
      PROCESSING: "Processing",

      ALREADY_EXIST: "Already exist",
      LOADING: "Loading...",
      ERROR: "Error",
      READY_TO_CREATE: "Ready to create",
      ORIGINAL_TABLE: "Original Table",

      SUCCESS: "Success",
      WARNING: "Warning",
      NULL_ROWS: "rows were not added because they were empty",
      
      DO_NOTHING: "Do nothing",
      OVERWRITE: "Overwrite",
      APPEND: "Append",
      CREATE: "Create",

      YouCannotChangeOriginalTable: "You cannot change original table in data source!",
      FileUploadingStatus: "File uploading. Status: ",
      AtLeastOneSheetMustWrite: "At least one sheet must write!",
      TableNameCouldNotBeEmpty:"Table name could not be empty",
      TableNameCouldNotIncludesSpecialChars: "Table names could not includes '\"', '%', '&', '[', ']', '|' or '.' characters",
      MustContainsAtLeastOneSheet: "Must contains at least one sheet.",
      MustContainsAtLeastOneColumnsPerSheets: "Must contains at least one columns per sheet. Empty sheets:",
      YouMustSelectAtLeastASheet: "You must select at least a sheet.",
      YouMustSelectAtLeastAColumnPerSheet: 'You must select at least a column per sheet. Empty sheets: "',
      SheetNamesMustBeUnique: "Sheet names must be unique. Non unique sheet name(s): ",
      ColumnNamesMustBeUniqueBySheet: "Column names must be unique by sheet.",
      ColumnNameCouldNotBeEmpty: "Column name could not be empty",
      ColumnNameCouldNotIncludesSpecialChars: "Column names could not includes '\"' or '.' characters",
      FixSheetName: "Fix sheet name",
      FixColumnName: "Fix column name",
      SameColumnNamesChanged: "Same column names changes by automatically",
      InvalidSheetNamesDetected: "Invalid sheet names detected",

      Sheet: "Sheet",
      Status: "Status",
      Operation: "Operation",
      SheetList: "Sheet List",
      DataProcessing: "Data processing...",
      Result: "Result",
      TableName: "Table Name",
      Detail: "Detail",
      First10RowWithHeader: "First 10 Rows with Header",

      TimeOutError: "The upload process timed out. But behind the scenes, your data continues to be processed into your data source."
    },
    DataConnections: {
      ConnectionsPreExplanation: "Choose an existing data connection or create a new one",
      Connections: "Connections",
      ConnectDataStore: "Connect to a Data Store",
      ChooseConnectionAndCreate: "Choose your connection type and create a new",
      TestConnection: "Test Connection",
      ConnectionName: "Give a name for connection",
      IpAdress: "IP Address",
      DatabaseName: "Database Name",
      ServiceName: "Service Name",
      UserAndSchemaName: "Username/Schema Name",
      SchemaName: "Schema Name",
      Username: "Username",
      Password: "Password",
      CouldNotFetch: "Data could not fetch.",
      EnterYourPassword: "Enter your password to delete item.",
      AddTableOrSelectConnection: "Visual components interacts with tables. To add a table to a model you need to add a data connection or choose an existing one.",
      TwoAlternatives: "You have two alternatives;",
      FileUpload: "File Upload",
      ClickToAdd: "Click to add",
      RDBMSorNosql: "RDBMS or Nosql",
      EditSource: "Edit Source",
      DeleteSource: "Delete Source",
      Used: "Used",
      DataConnectionSaved: "Data connection successfully saved",
      SuccessTestConnection: "Success. Test Connection",
      TruncateSuccess: "Truncate operation successful",
      TruncateFailed: "Truncate operation failed",
      EmptyFieldMessage: "This field cannot be empty!",
      SuccessFileUploadMessage: "File uploaded successfully"
    },
    SelfService: {
      Collection: "Workspace",
      CollectionName: "Workspace Name",
      YouCanNameTheCollection: "You can name the Workspace in which your files will be placed",
      YouCanStoreTheCollection: "You can store your files under the Workspace.",
      YouCanEditCollection: "You can edit the name of the Workspace"
    },
    Datasource: {
      CannotUpdate: "Datasource cannot be updated",
      SuccessfullyUpdated: "Datasource successfully added",
      CannotAdd: "Datasource can not be added"
    },
    Interaction: {
      Interaction: "Interaction",
      InteractionTargetSelection: "Click this plugin to filter from the selected plugin.",
      InteractionTargetSelectionSmall: "Click for filtering.",
      InteractionTargetSelected: "This plugin will be filtered by the selected plugin.\nClick on the 'EDIT' button to change the filtering details.\nClick the 'DELETE' button to disable filtering.",
      InteractionSourceSelected: "SELECTED PLUGIN",
      InteractionModeClose: "Interaction mode close",
      InteractionModeCloseWithInteraction: "Save interaction and close interaction mode",
      EditInteraction: "Edit Interaction",
      InteractionTypes: "Interaction Types ",
      Reactions: "Reactions",
      TitleReactions: "Title Interaction",
      UpdateTitle: "Update Title",
      ResetTitle: "Reset Title",
      ApplyFilter: "Apply Filter",
      ApplyToAll: "Apply To All",
      RemoveAll: "Remove From All",
      IgnoreDefaultFilters: "Ignore Default Filters",
      "Yok": "None",
      "Update Title": "Update Title",
      "Reset Title": "Reset Title",
      "Başlık Güncelle": "Update Title",
      "Başlık Sıfırla": "Reset Title",
      "None": "None"
    },
    Notification: {
      Notifications: "Notifications",
      YouCanSeeYourNotifications: "You Can See Your Notifications Here",
      YCSYNText: "You haven't got a notification yet",
      MentionedYouAComment: " mentioned you in a comment.",
      RepliedYouAComment: " replied to one of your comment.",
      UnsavedChanges: "Dashboard has unsaved changes.",
      UnsavedChangesContent: "Current dashboard has changes from another users. What do you want to do?",
      UnsavedChangesContentContinue: "Ignore other changes and continue.",
      UnsavedChangesContentReloadDashboard: "Ignore current changes and reload dashboard.",
      Overwrite: "Overwrite",
      SaveAs: "Save As",
      RefreshDashboard: "Refresh Dashboard",
      Continue: "Continue",
      ReloadDashboard: "Reload Dashboard",
    },
    DrillDown: {
      DrillDown: "Drill Downs",
      ApplyDrillDown: "Apply Drill Down",
      DrillDownColumns: "Drill Down Columns",
      RowsDesc: "Drill down row desc.",
      FieldWarning: "You can drill down in only one field at a time.",
      PreserveDefaultFilters: "Preserve Default Filters",
    },
    Navigation: {
      Descripton: "Navigation allows you to go from one report to another by taking the relevant filters.",
      Dashboards: "Dashboards",
      FiltersUsed: "Filters used on this dashboard",
      Filters: "Filters",
      NavigationList: "Navigation List",
      InvalidNavigation: "Invalid Navigation!",
      ValidColumnFieldNotFound: "Valid column field not found",
      OneColumnMustBeSelected: "At least one column must be selected",
      DashboardMustBeSelected: "Dashboard must be selected",
      EditToMakeChanges: "Registration has already been created! Edit to make changes!",
      SuccessfullyCreated: "Registration successfully created!",
      SuccessfullyUpdated: "Registration successfully updated!",
      Columns: "Columns",
      TransferDefaultFilters: "Transfer default filters to detail report",
      TransferDefaultFiltersInfo: "If you have compare columns, default filters transfered by selected compare column",
      TransferCompareValue: "Transfer only compare value",
      TransferCompareValueInfo: "Only transfers compare value",
      TransferInteractionFilters: "Transfer interaction filters to detail report",
      TransferInteractionFiltersInfo: "Interaction filters can be transfered to detail report",
      PluginCanNotTransferInteractionFilters: "Transfer interaction filters is not allowed",
    },
    AllFilters: {
      DefaultFilters: "Default Filter",
      ProtectedDefaultFilters: "Default Filter (Protected)",
      Navigation: "Navigation",
      Interaction: "Interaction",
      Drilldown: "Drilldown",
    },
    Actions: "Actions",
    NoDataContent: {
      NoData: "Data not found on these criteria",
      CouldNotFetch: "Data could not fetch",
      PluginCouldNotBeVisualized: "Plugin could not be visualized."
    },
    Cache: {
      Minute: "Minute",
      OnTheQuarterHour: "On The Quarter Hour",
      OnTheHalfHour: "On The Half Hour",
      OnTheHour: "On The Hour",
      Midnight: "Midnight",
      EnableCacheMode: "Cache Mode",
      CacheDescription: "Enable For Cache Your Data",
      UnsavedChanges: "You've Unsaved Changes In Cache",
      ExitCacheMode: "Exit Cache Mode",
      AddCacheMode: "Click Here Add Data To Cache",
      CacheModeValidTime: "Cache Valid Time",
      RemoveCacheModeInPlugin: "Remove Cache Mode In All Plugins",
      AddCacheModeInPlugin: "Add All Plugins To Cache Mode",
      EveryMin: "Every Minute",
      CacheModeDescription: "Cached according to the time period you choose"
    },
    Orientation: "Orientation",
    Landscape: "Landscape",
    Portrait: "Portrait",
    PleaseDashboardSave: "Please save the dashboard and try again",
    Navigations: "Navigations",
    NavigationTitle: "Navigation",
    SelectLanguage: "Select Language",
    Error: "ERROR!",
    Warning: "WARNING!",
    Run: "Run",
    EmptyErrorMessage: "Error {{validationErrorMessage}} field cannot be left blank",
    Success: "Success",
    Create: "Create",
    Save: "Save",
    SuccessfullyDeleted: "Successfully deleted",
    ErrorDeleted: "Could not be deleted",
    Join: "Join",
    Edit: "Edit",
    SessionVariable: "Session Variable",
    Delete: "Delete",
    DeleteItAnyway: "Delete It Anyway",
    Rename: "Rename",
    Map: "Map",
    Reset: "Reset",
    Finish: "Finish",
    Cancel: "Cancel",
    Upload: "Upload",
    Yes: "Yes",
    No: "No",
    Update: "Update",
    Add: "Add",
    AreYouSure: "Are You Sure ?",
    Search: "Search",
    ClickToOrder: "Order & Limit",
    OFF: "OFF",
    SomethingWentWrong: "Oops! Something went wrong. :(",
    Password: "Password",
    Requiredfield: "Required Field",
    OK: "OK",
    Okay: "Okay",
    Inner: "Inner",
    Outer: "Outer",
    Custom: "Custom",
    Info: "Info",
    Settings: "Settings",
    New: "New",
    Favorite: "Favorite",
    Private: "Private",
    Shared: "Shared",
    Labels: "Labels",
    BackgroundImage: "Background Image",
    Default: "Default",
    ReturnToDefault: "Return to Default",
    ChangeImage: "Change Image",
    UploadImage: "Upload Image",
    Page: "Page",
    Description: "Description",
    Title: "Title",
    ColumnMapValidError: "Required fields have missing columns in {{pluginName}}. Please fill in the missing fields",
    Close: "Close",
    EmptyFieldMessage: "This field cannot be empty!",
    Public: "Public",
    Apply: "Apply",
    Back: "Back",
    AllMeasures: "AllMeasures",
    CopyLink: "Copy Link",
    SelectAll: "Select All",
    DeselectAll: "Deselect All",
    AllOf: "All",
    Total: "Total",
    More: "more",
    Values: "Values",
    Left: "Left",
    Right: "Right",
    OnlyIcon: "Only Icon",
    Predicates: "Operators",
    Filters: "Filters",
    FilterPredicates: {
      In: "In",
      Equals: "Equals",
      EqualsIn: "Equals/In",
      GreaterThan: "Greater Than",
      GreaterThanOrEqual: "Greater Than or Equal",
      LessThan: "Less Than",
      LessThanOrEqual: "Less Than or Equal",
      NotEqualsIn: "Not Equals / Not In",
      NotEquals: "Not Equals",
      NotIn: "Not In",
      IsNull: "Is Null",
      IsNotNull: "Is Not Null",
      Like: "Like",
    },
    FilterShowPredicates: {
      "=": "equal",
      "!=": "not equal",
      ">": "greater than",
      ">=": "greater than or equal",
      "<": "less than",
      "<=": "less than or equal",
      "in": "in",
      "not in": "not in",
      "notIn": "not in",
      "like": "like",
      "not like": "not like",
      "is null": "is null",
      "is not null": "is not null",
      "CONTAINS": "contains",
      "ENDS_WITH": "ends with",
      "STARTS_WITH": "starts with"
    },
    DateFilter: {
      LastOrFutureNotEmpty: "Last/Future field cannot be left blank",
      DateInputNotEmpty: "Please enter a value in the Quantity field",
      DatePeriodNotEmpty: "Date Period cannot be left blank.",
      LastFuture: "Last/Future",
      Last: "Last",
      Future: "Future",
      Quantity: "Quantity",
      DatePeriod: "Date Period",
      Day: "Day",
      Week: "Week",
      Month: "Month",
      Year: "Year",
      Apply: "Apply"
    },
    PluginErrors: {
      CannotRenderWithoutTopoJson: "Cannot render chloropleth without a topojson feature code.",
      TopojsonNotValidFromat: "Topojson is not in valid format!",
      NameOfMapMustNotBeEmpty: "Name of Map must not be empty.Please input a name!",
      FileSelectionMustNotBeEmpty: "The file selection must not be empty. Please select a file!",
      PleaseFileInValidFormat: "Please upload file in valid format (topojon or json)",
      ImageFormatInvalid: "Invalid image format. (Supported formats: jpeg, jpg or png)",
      ImageCouldntUpload: "Image couldn't upload.",
      CannotRenderVisualisation: "Cannot render visualisation. Accepts multiple measure columns or a single measure and vary by colour dimension.",
      VisualizationError: "We encountered an error while visualizing the plugin. Check the plugin detail and try again."
    },
    Execute: "Execute",
    EXECUTING: "Executing...",
    Selected: "Selected",
    SaveSuccess: "Save success!",
    ReadOnly: "Immobile Dashboard",
    ReadOnlyDescriptionInactive: "It disables dragging and resizing plugins.",
    ReadOnlyDescriptionActive: "It enables dragging and resizing plugins.",
    TitleNotSet: "Title not set",
    "Title not set": "Title not set",
    "Başlık ayarlanmadı": "Title not set",
    ImageNotSet: "Image not set.",
    Expression: "Expression",
    ScrollToTop: "Scroll to Top",
    Expand: "Expand",
    Collapse: "Collapse",
    Dashboards: "Dashboards",
    AddNew: "Add New",
    TextOptions: "Text Options",
    AllColumns: "All Columns",
    Loading: "Loading",
    OperatorIsNotValid: "operator is not valid.",
    Disable: "Disable",
    Enable: "Enable",
    SubTotal: "Sub Total",
    GeneralTotal: "General Total",
    Group: "Group",
    Profile: "Profile",
    Logout: "Logout",
    Help: "Help",
    AdminPage: "Admin Page",
    Options: "Options",
    ClickHereToTryAgain: "Click here to try again",
    Copyright: "Copyright 2020 ® powered by Vispeahen.",
    TableCouldNotFoundInModel: "Couldn't found table in selected model!",
    PointClick: "Click - Point",
    "Tıklama - Nokta": "Click - Point",
    "Başlık Güncelle": "Update Title",
    "Başlık Sıfırla": "Reset Title",
    "backgroundColor": "Background Color", 
    "colours": "Palette", 
    "condFormat": "Conditional Format Legend", 
    "legend": "Legend", 
    "titleAlign": "Title Align", 
    "titleColour": "Title Colour", 
    "titleFont": "Title Font Type", 
    "titleFontSize": "Title Font Size", 
    "titleFontStyle": "Title Font Style", 
    "titleFontWeight": "Title Font Weight", 
    "titleTextDecor": "Title Text Decor",
    "ReturnToThemeDescription": "These configs will return to theme settings.",
    "ReturnConfigToTheme": "Reset Configuration to Theme",
    "ReturnToTheme": "Return to Theme",
    
    PluginsName: {
      names: {
        "network-graph": "Network Graph",
        "timeline-chart": "Timeline Chart",
        "scatter-chart": "Scatter Chart",
        "chord-diagram": "Chord Diagram",
        "sankey-chart": "Sankey Chart",
        "bar-chart": "Bar Chart",
        "cylinder-bar": "Cylinder Bar",
        "age-pyramid": "Pyramid Chart",
        "multi-axis-line-chart": "Multi Axis Line Chart",
        "line-bar-chart": "Line/Bar Chart",
        "filter": "Filter",
        "selection-box": "Selection Filter",
        "radio-button-filter": "Selection Filter",
        "button-filter": "Button Filter",
        "line-bar": "Line Bar",
        "map-bubbles": "Map (Bubbles)",
        "map-choropleth": "Map (Choropleth)",
        "map-cluster": "Map (Cluster)",
        "heatmap": "Map (Heatmap)",
        "route-map": "Route Map",
        "route-map-2": "Route Map 2",
        "turkey-map": "Turkey Map",
        "world-map": "World Map",
        "measure-tile": "Measure Tile",
        "flag": "Flag",
        "i-frame": "I-Frame",
        "image": "Image",
        "title": "Title",
        "pie-chart": "Pie Chart",
        "radial-progress": "Radial Progress",
        "gauge-chart": "Gauge Chart",
        "sunburst": "Sunburst",
        "liquid-gauge": "Liquid Gauge",
        "radar": "Radar",
        "pivot-table": "Pivot Table",
        "pivot-table-enhance": "Pivot Table Enhance",
        "table": "Table",
        "tree-map": "Tree Map",
        "word-cloud": "Word Cloud",
        "pie-chart-enhanced": "Pie Chart",
        "custom": "Custom",
      },
      PluginGroup: {
        "BUBBLE CHARTS": "BUBBLE CHARTS",
        "CHORD-SANKEY GRAFİKLERİ": "CHORD-SANKEY CHARTS",
        "CHORD-SANKEY CHARTS": "CHORD-SANKEY CHARTS",
        "COLUMN CHARTS": "COLUMN CHARTS",
        "COMBINED CHARTS": "COMBINED CHARTS",
        "FILTERS": "FILTERS",
        "LINE CHARTS": "LINE CHARTS",
        "MAPS": "MAPS",
        "MEASURE TILE": "MEASURE TILE",
        "OTHERS": "OTHERS",
        "PIE-RING CHARTS": "PIE-RING CHARTS",
        "RADAR-SCALE CHARTS": "RADAR-SCALE CHARTS",
        "TABLES": "TABLES",
        "TREE MAP-WORD CLOUD": "TREE MAP-WORD CLOUD",
        "CUSTOM": "CUSTOM",

      }
    },
    JoinTable: {
      FactTable: "Fact Table",
      FactColumn: "Fact Column",
      DimensionTable: "Dimension Table",
      DimensionColumn: "Dimension Column",
      JoinType: "Type",
      Inner: "Inner",
      Outer: "Outer",
      DeleteJoin: "Are you sure delete this join definition?",
      SelectPlaceholder: "Please Select",
      NoData: "No Data"
    },
    SessionVariables: {
      Title: "Session Variables",
      SessionName: "Name",
      SessionDefaultVariable: "Default Value",
      SessionVariableExtractData: "This box indicates that when data will be extracted.",
      NoData: "Data not found",
      Scope: "Execution Schedule",
      DataType: "Data Type",
      SessionVariableEditorDescription: "Session Variable Description...",
      SelectDataSource: "The tables and columns of your selected data source are shown here.",
      NoDataSourceWarning: "You must select a data source before the query can be executed. Otherwise, the variable will always get the default value.",
      DataTypes: {
        Text: "Text",
        Number: "Number",
        Date: "Date"
      },
      Scopes: {
        "Dashboard": "Loading Dashboard",
        "Login": "Logging In",
        "Query": "Executing Query"
      },
      TestQueryResult: "Query Result",
      TestQueryDescription: "You can test the query result by clicking the Execute button.",
      TypeMismatch: "The query may not be executed due to data type mismatch between column '[column]' and variable '[sessionVariable]'.",
      PleaseCheckField: "Please check the field [field]",
      PleaseCheckFields: "Please check the fields [fields]",
      NameAlreadyExists: "There is already a variable with this name.",
      AreYouSureDeleteSessionVariable: "Are you sure you want to delete this session variable?",
      Errors: {
        DS_NOT_FOUND: "Data source not found.",
        DS_UNSUPPORTED: "Unsupperted data source type.",
        NO_DS_SELECTED: "No data source selected.",
        EmptyQueryResult: "Query result is empty."
      }
    },
    TablePagination: {
      Next: "Next Page",
      Previous: "Previous Page"
    }
  }
};
