export const DEFAULT_PLUGINS = "DEFAULT_PLUGINS";
export const SET_IS_REQUESTED = "SET_IS_REQUESTED";

/**
 * 
 * @param {*} plugins 
 * @returns 
 * 
 * Sets the default plugin object
 */
export function setDefaultPlugins(plugins) {
  return dispatch => {
    dispatch({
      type: DEFAULT_PLUGINS,
      payload: plugins
    });
  };
}

/**
 *
 * @param {*} plugins
 * @returns
 *
 * Sets is request
 */
export function setIsRequested(isRequested) {
  return dispatch => {
    dispatch({
      type: SET_IS_REQUESTED,
      payload: isRequested
    });
  };
}