import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel2";
import Report from "./Report";

const options = {
  items: 6,
  nav: true,
  buttons: false,
  pagination: false,
  dots: false,
  loop: false,
  margin: 30,
  navText: [
    '<i class="fa fa-angle-left" aria-hidden="true"></i>',
    '<i class="fa fa-angle-right" aria-hidden="true"></i>',
  ],
  responsive: {
    0: {
      items: 1,
    },
    575: {
      items: 2,
    },
    767: {
      items: 3,
    },
    992: {
      items: 4,
    },
    1200: {
      items: 6
    }
  },
};

const events = {
  onDragged: function (event) {},
  onChanged: function (event) {},
};

class SliderDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <OwlCarousel ref="dashboards" options={options} events={events} style={{zIndex: "unset"}}>
        {this.props.data.map((d) => {
          return (
            <Report
              refresh={this.props.refresh}
              selectReport={this.props.selectReport}
              data={d}
              dataLabel={this.props.dataLabel}
              dashboardDelete={this.props.dashboardDelete}
              dashboardReloaded={this.props.dashboardReloaded}
              dashboardDeletedPermanently={this.props.dashboardDeletedPermanently}
            />
          );
        })}
      </OwlCarousel>
    );
  }
}

export default SliderDashboard;
