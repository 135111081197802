let dataSourceKeysByTables = new Map();
let modelName = "";

/**
 * Finds who columns have not a datasource key
 * using table alias inserts if present in model
 * @param {*} dataSourceKey
 * @param {*} columntableAliasName
 * @param {*} model
 * @returns
 */
export const checkAndFindDataSourceKeyToColumn = (
  dataSourceKey,
  columntableAliasName,
  model
) => {
  if (modelName == "" || modelName !== model.name) {
    modelName = model.name;
    dataSourceKeysByTables = new Map();
  }

  if (model !== "" && model !== undefined) {
    if (!dataSourceKey) {
      if (dataSourceKeysByTables.has(columntableAliasName)) {
        dataSourceKey = dataSourceKeysByTables.get(columntableAliasName);
      } else {
        model?.tables?.map((table) => { // eslint-disable-line
          dataSourceKeysByTables.set(table.aliasName, table.dataSourceKey);

          if (table.aliasName === columntableAliasName) {
            dataSourceKey = table.dataSourceKey;
          }
        });
      }
    }
  }

  return dataSourceKey;
};
