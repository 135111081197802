import React, { Component } from "react";
import $ from "jquery";
import { rmvpp } from "../../RenderJs/rmvpp";
import ImageConfiguration from "./ImageConfiguration";
import i18n from "../../../../Utils/i18next";
import {
  onComponentWillMount,
  onComponentWillReceiveProps,
  getColumnMapping,
  calculatePluginHeight
} from "../common";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import { renderConfig, renderData } from "../PluginsCommonComponents";
import ImageData from "./ImageData";
import { renderContent } from "../renderContent";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";

const data = JSON.parse(
  `[{"url":"Aralık","hidden":[]},{"url":"Ağustos","hidden":[]},{"url":"Ekim","hidden":[]},{"url":"Eylül","hidden":[]},{"url":"Haziran","hidden":[]},{"url":"Kasım","hidden":[]},{"url":"Mart","hidden":[]},{"url":"Mayıs","hidden":[]},{"url":"Nisan","hidden":[]},{"url":"Ocak","hidden":[]},{"url":"Temmuz","hidden":[]},{"url":"Şubat","hidden":[]}]`
);
const config = JSON.parse(
  `{"width":400,"height":300,"linkUrl":"https://media-exp1.licdn.com/dms/image/C561BAQEa88QyNiD9Zg/company-background_10000/0?e=2159024400&v=beta&t=OgjjPpm3WS4rKsAudK1Vu303ix1thwzr6gY3GPllJ50","showHideButton":false,"refresh":0}`
);
const columnMap = JSON.parse(
  `{"url":{"Code":"\'ucusAlias\'.\'ay_adiAlias\'","Name":"ay adi","DataType":"varchar","Table":"ucusAlias","Measure":"none","ID":"ucusAlias.ay adi","SubjectArea":"BIGDATA","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":"%s","Config":{},"Verified":false,"Type":"Column","Description":""},"hidden":[]}`
);

const condFormats = [];
const filters = [];
const pluginName = "image";

const columnMappingParameters = [
  {
    //yapılacak
    targetProperty: "url",
    formLabel: "desc18",
    type: "dim",
    required: true,
    desc: "desc18"
  },
  {
    targetProperty: "hidden",
    formLabel: "Hidden",
    multiple: true,
    type: "hidden",
    desc: "desc6"
  }
];

const configurationParameters = [
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 15
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
  {
    targetProperty: "width",
    label: "Width",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 400
    },
    desc: "desc89"
  },
  {
    targetProperty: "height",
    label: "Height",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 300
    },
    desc: "desc90"
  },
  {
    targetProperty: "linkUrl",
    label: "Link",
    inputType: "textbox",
    inputOptions: {
      defaultValue: ""
    },
    desc: "desc90"
  },
  {
    targetProperty: "showHideButton",
    label: "Show Hide Button",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc230"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  }
];

const actions = [];

// Reactions this visualisation can have to other interactions. Everything can use filters
const reactions = [
  {
    id: "filter",
    name: "Filtre",
    description: "desc87",
    type: "general"
  }
];

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

/**
 * renders Image plugin in Vispeahen V3
 */
export default class Image extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.rerenderProcessStarted = false;
    this.callBackObject = {};
  }

  componentDidUpdate() {
    let sourceImage = $("#img-" + this.props.plugin.id);
    if (sourceImage) {
      if (calculatePluginHeight(this.props.plugin, this.props.settings) != sourceImage.height()) {
        let newImageWidth = this.calculateImageWidth(
          this.props.plugin,
          sourceImage.height()
        );
      }
    }
  }

  calculateImageWidth = (plugin, tempImageWidth) => {
    let maxHeight = calculatePluginHeight(plugin, this.props.settings);
    let newImageWidth = (maxHeight * 100) / tempImageWidth;
    return newImageWidth >= 100 ? 100 : newImageWidth;
  };

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = {...this.callBackObject};
    this.setCallBackObject({})
    
    return tmpCallBackObject;
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );
  }


  getConfigComponent = props => {
    if (props.config) {
      return (
        <ImageConfiguration
          config={{ ...props.config }}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig= {this.props.setCurrentAppliedConfig}
          currentAppliedConfig = {this.props.currentAppliedConfig}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );
    return (
      <ImageData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
      />
    );
  };

  /**
   * To set column map this plugin
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      url: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.URL.Name"),
        type: "dim",
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.URL.Desc"),
        required: true,
        minimumColumnSize: 0,
        data: []
      },
      hidden: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Hidden.Name"),
        multiple: true,
        minimumColumnSize: 0,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Hidden.Desc"),
        type: "hidden",
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {
    // Set html empty and set this html to container.
    $("#" + divId).html("");
    let container = $("#" + divId)[0];
    let pluginContainer = $("#plugin-" + divId)
    pluginContainer.css("height", "100%") //for responsive img
    pluginContainer.parent().parent().css("min-height", "0px") // for delete min-height css for main div
    $("#title-" + divId).remove() //for delete title
    $("#" + divId).css("height", "100%") //for responsive img
    $("#" + divId).parent().css("height", "100%") //for responsive img

    // Get width and height variables based on gridScope
    let visWidth = "100%";
    let visHeight2 = "100%" //changed visHeight2 'cause image was not fit the screen

    $(container).append(
      `<div class="imageVis" id="img-` +
        divId +
        `" style=" width:${visWidth}; height:100%; background-color: #ffffff; color: #fff"></div>`
    );

    let isImagePath = (config.imagePath && config.imagePath.length > 0);
    let isImageValid = isImagePath || config.linkUrl.length > 0;
    
    if (isImageValid) {
      let linkUrl = config.linkUrl;
      
      if (isImagePath) {
        linkUrl = config.imagePath;
      }
      
      $("#img-" + divId).append(
        `<img src="${linkUrl}" width="${visWidth}" height="${visHeight2}" style="display: block;"/>`
      );
    }
    else {
      $("#img-" + divId).append(
        `<div class="noImg" style="word-wrap: break-word;
        width: 100%;
        margin: auto;
        white-space: normal;
        text-align: center;
        background-color: rgb(255,255,255);
        color: rgba(0, 0, 0, .5);
        font-size: 16px;
        font-family: Open Sans;">
        ${i18n.t("ImageNotSet")}</div>`
      );

      $(".imageVis").css("display", "flex")
    }

    if (config.showHideButton) {
      let hideButtonToggleKey = [".imageVis"];
      rmvpp.hideButton(container, hideButtonToggleKey);
    }

    this.props.setPluginRerender(false, this.props.plugin.id, false, this.props.plugin.isInteraction);
  };

  currentHeight;
  lastContent = undefined;

  updateLastContent = (status) => {
    this.lastContent = status
  } 

  render() {
    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };

    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + this.props.plugin.id).css("padding")
      );

      pluginConfig.height =
        calculatePluginHeight(this.props.plugin, this.props.settings) + (pluginContainerPadding * 2) - 6;
    
      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      );
    }

    return (
      <div style={{height: "100%"}}>
        <div id={this.props.plugin.id}></div>
        {renderContent(
          isRerender,
          this.pluginRender,
          this.props.plugin,
          data,
          columnMap,
          pluginConfig,
          condFormats,
          this.props.setPluginRerender,
          this.lastContent,
          this.updateLastContent,
        )}
        {configComponent}
        {dataComponent}
      </div>
    );
  }
}
