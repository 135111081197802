import React, { Component } from "react";
import * as d3 from "d3";
import $ from "jquery";
import { rmvpp } from "../../RenderJs/rmvpp";
import RadialProgressData from "./RadialProgressData";
import RadialProgressConfiguration from "./RadialProgressConfiguration";
import i18n from "../../../../Utils/i18next";
import {
  onComponentWillMount,
  onComponentWillReceiveProps,
  getColumnMapping,
  calculatePluginHeight
} from "../common";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import { renderConfig, renderData } from "../PluginsCommonComponents";
import { renderContent } from "../renderContent";
import { getFormattedValue } from "../format";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join"
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";

const data = JSON.parse(`[{"measure":5610072926}]`);
const config = JSON.parse(
  `{"size":200,"font":"Open Sans","maxValue":1,"dontShowError":true,"duration":1000,"roundCorners":false,"showHideButton":false,"colours":"Flat-UI","toggleCriteria":"","title":"","summary":"","backgroundColor":"rgb(255,255,255)","refresh":0}`
);
const columnMap = JSON.parse(
  `{"measure":{"Code":"\'ucus_PBWWSJXEAR\'.\'kargotoplam\'","Name":"kargotoplam","DataType":"double","Table":"ucus_PBWWSJXEAR","Measure":"sum('ucus'.'kargotoplam')","ID":"ucus_PBWWSJXEAR.kargotoplam","SubjectArea":"deneme","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":".3s","Config":{},"Verified":true,"Type":"Column","Description":""},"target":{"Code":"","Name":"","DataType":"varchar","Table":"Unspecified","Measure":"none","ID":"Unspecified.","SubjectArea":"","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":"%s","Config":{},"Verified":false,"Type":"Column"},"hidden":{"Code":"","Name":"","DataType":"varchar","Table":"Unspecified","Measure":"none","ID":"Unspecified.","SubjectArea":"","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":"%s","Config":{},"Verified":false,"Type":"Column"}}`
);

const condFormats = [];
const filters = [];

var pluginName = "radial-progress";
const description =
  "Makes a circular progress graphic, used to display ratios or percentages. Also measures can be displayed as they are, with an arbitrary maximum set or one specified by another measure column. If the value exceeds the target value a second arc is drawn inside the first one. This pattern continues up to 5 times the target value.";

const columnMappingParameters = [
  {
    targetProperty: "measure",
    formLabel: "Measure",
    type: "measure",
    required: true,
    conditionalFormat: true,
    desc: "desc48" //'Aggregated measure value to display.'
  },
  {
    targetProperty: "target",
    formLabel: "Target",
    type: "measure",
    desc: "desc49" // 'Target measure to use as the maximum value for comparison.'
  },
  {
    targetProperty: "hidden",
    formLabel: "Hidden",
    type: "hidden",
    conditionalFormat: true,
    desc: "desc50" // 'Hidden measure to use for conditional formatting without display.'
  }
];

const configurationParameters = [
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 15
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
  {
    targetProperty: "size",
    label: "Size",
    inputType: "textbox",
    inputOptions: {
      subType: "number",
      defaultValue: 200
    },
    desc: "desc92"
  },
  {
    targetProperty: "font",
    label: "Font",
    inputType: "font",
    inputOptions: { defaultValue: "Open Sans" },
    desc: "desc116"
  },
  {
    targetProperty: "maxValue",
    label: "MaxValue",
    inputType: "textbox",
    inputOptions: {
      subType: "number",
      defaultValue: 1
    },
    desc: "desc115"
  },
  {
    targetProperty: "dontShowError",
    label: "DontShowError",
    inputType: "checkbox",
    inputOptions: { defaultValue: true },
    desc: "desc114"
  },
  {
    targetProperty: "duration",
    label: "AnimationDuration",
    inputType: "textbox",
    inputOptions: {
      subType: "number",
      defaultValue: 1
    },
    desc: "desc113"
  },
  {
    targetProperty: "roundCorners",
    label: "RoundCorners",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "desc112"
  },
  {
    targetProperty: "showHideButton",
    label: "Show Hide Button",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc230"
  },
  {
    targetProperty: "colours",
    label: "Colours",
    inputType: "palette",
    inputOptions: {
      defaultValue: "Flat-UI"
    },
    desc: "desc111"
  },
  {
    targetProperty: "toggleCriteria",
    label: "ToggleCriteria",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc59"
  },
  {
    targetProperty: "title",
    label: "Title",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc94"
  },
  {
    targetProperty: "summary",
    label: "Summary",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc61"
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: { defaultValue: "rgb(255,255,255)" },
    desc: "desc62"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  }
];
const actions = [];

const reactions = [
  {
    id: "filter",
    name: "Filtre",
    description: "desc87",
    type: "general"
  }
];

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

/**
 * renders RadialProgress plugin in Vispeahen V3
 */
export default class RadialProgress extends Component {
  constructor(props) {
    super(props);
    this.rerenderProcessStarted = false;
    this.callBackObject = {};
  }

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({})

    return tmpCallBackObject;
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );
  }

  getConfigComponent = props => {
    if (props.config) {
      return (
        <RadialProgressConfiguration
          config={{ ...props.config }}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig= {this.props.setCurrentAppliedConfig}
          currentAppliedConfig = {this.props.currentAppliedConfig}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

    //sets defaults we get from plugin.jsx. callback for fontsizekey because it should keep changing to trigger default revert
    setDefaultsForTitle = () => {
      let newConfig = this.props.setDefaultForPluginTitle();
  
      this.setState(
        {
          ...this.state,
          title: newConfig.title,
          titleAlign: newConfig.titleAlign,
          titleFont: newConfig.titleFont,
          titleFontSize: newConfig.titleFontSize,
          keyForTitleSize: newConfig.titleFontSize,
          titleFontStyle: newConfig.titleFontStyle,
          titleFontWeight: newConfig.titleFontWeight,
          titleTextDecor: newConfig.titleTextDecor,
          titleColour: newConfig.titleColour
        }, 
        () => {
          this.setState({keyForTitleSize: null})
        }
      );
      //did not call the setConfigToPlugin again because we already called updateConfig at setDefaultForPluginTitle() 
    }
  
    titleColourChangeVisible = () => {
      this.setState({
        ...this.state,
        titleColourChange: !this.state.titleColourChange
      });
    };
  

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }

    return (
      <RadialProgressData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        didNotJoinedTables={checkTableJoins(this.props.join, this.props.plugin.columnMap, this.props.refreshedPluginId, this.props.plugin.id, true)}
        setInteractions={this.props.setInteractions}
        interactions={this.props.interactions}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={props.changeDoesPluginHasNotJoinedTable}
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        plugin={props.plugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  /**
   * To set column map for RadialProgress
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      measure: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Name"),
        type: "measure",
        required: true,
        minimumColumnSize: 1,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Desc"),
        conditionalFormat: true,
        data: []
      },
      target: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Target.Name"),
        type: "measure",
        minimumColumnSize: 1,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Target.Desc"),
        data: []
      },
      hidden: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Hidden.Name"),
        type: "hidden",
        minimumColumnSize: 0,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Hidden.Desc"),
        conditionalFormat: true,
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {
    // Set html empty and set this html to container.
    $("#" + divId).html("");
    let container = $("#" + divId)[0];

    var width = +config.size,
      height = +config.size;
    var fontSize = Math.round(+config.size / 5);

    if (formatVal(+data[0].measure).length > 11) { // if formatted measure character count bigger then 11, text overflows the area 
      fontSize = Math.round(+config.size / 8); // In this case, font size size/5 to size/8
    }

    var font = config.font,
      duration = +config.duration * 1000;
    var colours = rmvpp.getPalette(config.colours);
    var value;

    if (data.length > 0) {
      value = data[0].measure;
    } else {
      value = 0;
    }

    if (value === 0) {
      data = [{ measure: "0" }];
    }

    var minValue = 0;
    var maxValue = columnMap.target.Code ? data[0].target : +config.maxValue;
    if (maxValue === 1) {
      maxValue = value;
    }
    var currentArc = 0,
      currentArc2 = 0,
      currentValue = 0;
    var tooltip = new rmvpp.Tooltip(container);
    var ratio = data[0].measure / maxValue;

    if (ratio !== ratio) {
      ratio = 1;
    }

    var arcs = [],
      numArcs = ratio > 5 ? 5 : Math.ceil(ratio);
    for (var i = 0; i < numArcs; i++) {
      var arc = d3.svg.arc().startAngle(0 * (Math.PI / 180));
      arcs.push(arc);
    }

    var arc = d3.svg.arc().startAngle(0 * (Math.PI / 180));
    var arc2 = d3.svg
      .arc()
      .startAngle(0 * (Math.PI / 180))
      .endAngle(0);

    arcs.forEach(function (arc, i) {
      var scale = 1 - 0.15 * i;
      arc.outerRadius((width / 2) * scale);
      arc.innerRadius((width / 2) * scale - (width / 2) * 0.15);
    });

    arc.outerRadius(width / 2);
    arc.innerRadius((width / 2) * 0.85);
    arc2.outerRadius((width / 2) * 0.85);
    arc2.innerRadius((width / 2) * 0.85 - (width / 2) * 0.15);

    if (config.roundCorners) {
      arcs.forEach(function (arc, i) {
        arc.cornerRadius(width / 2);
      });
    }

    condFormats.forEach(function (cf) {
      if (cf.compare(data[0])) {
        colours.forEach(function (colour, i) {
          colours[i] = cf.Style.colour;
        });
      }
    });

    var selection = d3.select(container);
    selection.style("text-align", "center");

    var svg = selection.selectAll("svg").data(data);
    var enter = svg
      .enter()
      .append("svg")
      .attr("class", "radial-svg")
      .style("height", config.height)
      .style("width", "100%")
      .style("padding", "0 5px")
      .style("background", config.backgroundColor) //added backgroundColor style
      .append("g");

    // Set width and height variables based on gridScope
    svg.attr("viewBox", `0 0 ${width} ${height}`);

    // Add background arc
    var background = enter.append("g").classed("component", true);
    arcs[0].endAngle(360 * (Math.PI / 180));
    background
      .append("path")
      .attr("transform", "translate(" + width / 2 + "," + width / 2 + ")")
      .attr("d", arcs[0])
      .attr("fill", "#CCCCCC");

    // Add value arc
    arcs[0].endAngle(ratio * 360 * (Math.PI / 180));
    enter.append("g").attr("class", "arcs");

    var paths = [];
    arcs.forEach(function (arc, i) {
      var path = svg
        .select(".arcs")
        .selectAll(".arc")
        .data(data);
      path
        .enter()
        .append("path")
        .attr("class", "arc" + i)
        .attr("transform", "translate(" + width / 2 + "," + width / 2 + ")")
        .attr("fill", colours[i])
        .on("mouseover", function (d) {
          tooltip.displayFull(
            ["measure", "target"],
            columnMap,
            data[0],
            d3.event
          );
        })
        .on("mouseout", function (d) {
          tooltip.hide();
        });
      paths.push(path);
    });

    // Add label in the center
    enter.append("g").attr("class", "labels");
    var label = svg
      .select(".labels")
      .selectAll(".label")
      .data(data);
    label
      .enter()
      .append("text")
      .attr("class", "label")
      .attr("y", width / 2 + fontSize / 3)
      .attr("x", function (d) {
        return width / 2;
      })
      .attr("width", width)
      .on("mouseover", function (d) {
        tooltip.displayFull(
          ["measure", "target"],
          columnMap,
          data[0],
          d3.event
        );
      })
      .on("mouseout", function (d) {
        tooltip.hide();
      })
      .text(function (d) {
        return formatVal(+data[0].measure);
      })
      .style("font-size", fontSize + "px")
      .style("font-family", font);

    arcs.forEach(function (arc, i) {
      paths[i].datum((Math.min(360 * (ratio - i), 360) * Math.PI) / 180);
      paths[i]
        .transition()
        .delay(duration * i)
        .duration(duration)
        .attrTween("d", function (d) {
          return arcTween(d, arcs[i]);
        });
    });

    // Animation function for the first arc
    function arcTween(a, arc) {
      var i = d3.interpolate(0, a);
      return function (t) {
        return arc.endAngle(i(t))();
      };
    }

    function endallTransition(transition, callback) {
      if (typeof callback !== "function")
        throw new Error("Wrong callback in endall");
      if (transition.size() === 0) {
        callback();
      }
      var n = 0;
      transition
        .each(function () {
          ++n;
        })
        .each("end", function () {
          if (!--n) callback.apply(this, arguments);
        });
    }

    // Animate label
    label.datum(ratio);
    label
      .transition()
      .duration(duration * numArcs)
      .tween("text", labelTween)
      .call(endallTransition, function () {
        if (config.showHideButton) {
          let hideButtonToggleKey = [".radial-svg"];
          rmvpp.hideButton(container, hideButtonToggleKey);
        }
      });

    // Animation function for the label
    function labelTween() {
      var i = d3.interpolate(currentValue, data[0].measure);
      currentValue = i(0);
      return function (t) {
        d3.select(this).attr("x", function (d) {
          var textWidth = d3
            .select(this)
            .node()
            .getBBox().width;
          let calculatedXPosition = width / 2 - (textWidth / 2);
          return calculatedXPosition < 0 ? 0 : calculatedXPosition;
        });
        currentValue = i(t);
        this.textContent = formatVal(i(t));
      };
    }

    function formatVal(val) {
      return getFormattedValue(columnMap.measure, val);
    }

    this.props.setPluginRerender(false, this.props.plugin.id, false, this.props.plugin.isInteraction);
  };

  currentHeight;
  lastContent = undefined;

  updateLastContent = (status) => {
    this.lastContent = status
  }

  render() {
    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };
    
    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + this.props.plugin.id).css("padding")
      );

      pluginConfig.height =
        calculatePluginHeight(this.props.plugin, this.props.settings) -
        pluginContainerPadding * 2;

      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      );
    }

    return (
      <>
        <div style={{height: "100%"}}>
          <div id={this.props.plugin.id}></div>
          {renderContent(
            isRerender,
            this.pluginRender,
            this.props.plugin,
            data,
            columnMap,
            pluginConfig,
            condFormats,
            this.props.setPluginRerender,
            this.lastContent,
            this.updateLastContent,
          )}
          {configComponent}
          {dataComponent}
        </div>
      </>
    );
  }
}
