import React, { Component } from "react";
import Button from "../../GeneralComponents/Button/Button";

/** Button showing the filter list applied to the plugin */
export default class FilterShowButton extends Component {
  render() {
    return (
      <Button
        style={this.props.style ? this.props.style : ""}
        className={"show-plugin-filters-button"}
        onClick={() => this.props.showFiltersArea(true)}
      >
        <i className={"fa fa-filter"}></i>
      </Button>
    );
  }
}