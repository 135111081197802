export const configuration = {
    size: 800,
    hideLabel: false,

};

export const actions = [
    {
        trigger: "groupClick",
        type: "click",
        output: ["entities"],
        name: "Tıklama - Grup",
        description: "GroupClickDesc"
    },
    {
        trigger: "groupHover",
        type: "hover",
        output: ["entities"],
        name: "Hover - Grup",
        scription: "GroupHoverDesc"
    }
];

export const reactions = [
    {
        id: "filter",
        name: "Filtre",
        description: "desc87",
        type: "general"
    }
];

export const conversionMap = {
    "table": {
        columnMap: {
            columns: {
                name: "Columns",
                from: ["entities", "measure"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
    "sankey-chart": {
        columnMap: {
            level: {
                name: "Level",
                from: ["entities"],
                multiple: true,
                minimumColumnSize: 2,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["measure"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
    "cylinder-bar": {
        columnMap: {
            label: {
                name: "Label",
                from: ["entities"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            values: {
                name: "Values",
                from: ["measure"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
    "age-pyramid": {
        columnMap: {
            category: {
                name: "Category",
                from: ["entities"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            either: {
                name: "Either",
                from: ["measure"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            other: {
                name: "Other",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            }
        }
    },
    "measure-tile": {
        columnMap: {
            measure: {
                name: "Measure",
                from: ["measure"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
            hidden: {
                from: ["entities"]
            }
        }
    },
    "pie-chart": {
        columnMap: {
            category: {
                name: "Category",
                from: ["entities"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["measure"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
    "sunburst": {
        columnMap: {
            level: {
                name: "Level",
                from: ["entities"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["measure"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
    "gauge-chart": {
        columnMap: {
            measure: {
                name: "Measure",
                from: ["measure"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            minimum: {
                name: "Minimum",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            },
            maximum: {
                name: "Maximum",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            },
            target: {
                name: "Target",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            },
            hidden: {
                from: ["entities"]
            }
        }
    },
    "liquid-gauge": {
        columnMap: {
            measure: {
                name: "Measure",
                from: ["measure"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            target: {
                name: "Target",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            },
            hidden: {
                from: ["entities"]
            }
        }
    },
    "radar": {
        columnMap: {
            category: {
                name: "Category",
                from: ["entities"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["measure"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
    "pivot-table": {
        columnMap: {
            measures: {
                name: "Measures",
                from: ["measure"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
            rows: {
                name: "Rows",
                from: ["entities"],
                multiple: true,
                minimumColumnSize: 0,
                required: false
            },
            columns: {
                name: "Columns",
                from: [],
                multiple: true,
                minimumColumnSize: 0,
                required: false
            }
        }
    },
    "word-cloud": {
        columnMap: {
            word: {
                name: "Word",
                from: ["entities"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            freq: {
                name: "Frequency",
                from: ["measure"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
    "bar-chart": {
        columnMap: {
            category: {
                name: "Category",
                from: {"entities": [0]},
                multiple: false,
                minimumColumnSize: 1,
            },
            columns: {
                name: "Columns",
                from: ["measure"],
                dependentColumns: ["Lines"],
                multiple: true,
                minimumColumnSize: 1,
            },
            lines: {
                name: "Lines",
                from: [],
                dependentColumns: ["Columns"],
                multiple: true,
                minimumColumnSize: 1,
            },
            vary: {
                name: "Vary",
                from: {"entities": [1]},
                multiple: false,
                minimumColumnSize: 0,
            }
        }
    },
    "line-bar": {
        columnMap: {
            category: {
                name: "Category",
                from: {"entities": [0]},
                multiple: false,
                minimumColumnSize: 1,
            },
            columns: {
                name: "Columns",
                from: [],
                dependentColumns: ["Lines"],
                multiple: true,
                minimumColumnSize: 1,
            },
            lines: {
                name: "Lines",
                from: ["measure"],
                dependentColumns: ["Columns"],
                multiple: true,
                minimumColumnSize: 1,
            },
            vary: {
                name: "Vary",
                from: {"entities": [1]},
                multiple: false,
                minimumColumnSize: 0,
            }
        }
    },
    "tree-map": {
        columnMap: {
            group: {
                name: "Group",
                from: {"entities": [0]},
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["measure"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            vary: {
                name: "Vary",
                from: {"entities": [1]},
                multiple: false,
                minimumColumnSize: 0,
                required: false
            }
        }
    }
};