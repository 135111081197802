import {
  AlignLeftOutlined,
  BgColorsOutlined,
  FileExcelOutlined,
  FormatPainterOutlined,
  InfoCircleOutlined,
  PictureOutlined,
  ShrinkOutlined,
  VerticalAlignTopOutlined
} from "@ant-design/icons";
import { Checkbox, Col, Divider, Input, Row, Select, Switch } from "antd";
import $ from "jquery";
import React, { Component } from "react";
import { SketchPicker } from "react-color";
import { connect } from "react-redux";
import { store } from "../../../../../..";
import { deepCopy } from "../../../../../../Utils/Global";
import i18n from "../../../../../../Utils/i18next";
import { setCacheMode } from "../../../../../CacheMode/CacheModeAction";
import CustomExcelSettings from "../../../../../CustomExcel/CustomExcelSettings";
import Button from "../../../../../GeneralComponents/Button/Button";
import ImageUploader from "../../../../../GeneralComponents/ImageUploader/ImageUploader";
import PopupContainer from "../../../../../GeneralComponents/PopupContainer/PopupContainer";
import Text from "../../../../../GeneralComponents/Text/Text";
import Tooltip from "../../../../../GeneralComponents/Tooltip/Tooltip";
import GridSettings from "../GridSettings/GridSettings";
import { setNoDataTitle } from "./SettingNoDataTitleAction";
import "./settingList.css";

const { TextArea } = Input;
const { Option } = Select;

const clone = require("rfdc")();

/**
 * This file contains dashboard settings configuration and functions about those settings.
 */
class SettingList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleDescriptionSetting: false,
      visibleDashboardTitle: false,
      backgroundColorChange: false,
      backgroundImageChange: false,
      customDashboardStatus: false,
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);

    let themes = sessionStorage.getItem("themes");

    if (themes) {
      themes = JSON.parse(themes);

      this.setState({
        themes: themes,
        defaultTheme: themes.find(t => t.default === true)
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.settings.noDataTitle !== nextProps.settings.noDataTitle) {
      nextProps.setNoDataTitle(nextProps.settings.noDataTitle)
    }
  }

  /**
   * If click outside hide switcher area.
   */
  handleClickOutside = event => {
    let isSettingList = $(event.target).closest("#dashboardSettingsButton").length > 0;
    let isDropdown = $(event.target).closest(".ant-select-dropdown").length > 0;
    let isLogginPopup = $(event.target).closest("#loginPopup").length > 0;
    let isTooltip = $(event.target).closest(".ant-tooltip").length > 0;
    let isModal = $(event.target).closest(".ant-modal").length > 0;
    let checkCustomExcel = $(event.target).closest(".ant-modal-wrap ").length > 0
    let isOutside = !(isSettingList || isDropdown || isLogginPopup || isTooltip || isModal);
    let clickOutsideControl = isOutside && this.props.visible && !checkCustomExcel;

    if (clickOutsideControl) this.props.closeSetting();
  };

  setBGDefault = () => {
    let newSettings = clone(this.props.settings);
    newSettings["backgroundColor"] = newSettings["defaultBackgroundColor"];
    newSettings["backgroundImage"] = newSettings["defaultBackgroundImage"];
    newSettings["backgroundImageTemp"] =
      newSettings["defaultBackgroundImageTemp"];

    this.setState({
      ...this.state,
      backgroundImageChange: false,
      backgroundColorChange: false
    });

    this.props.changeSettings(newSettings);
  };

  /**
   * Changes the dashboard background colour
   */
  backgroundColorOnChange = obj => {
    let newSettings = clone(this.props.settings);
    newSettings["backgroundColor"] = obj.hex;
    newSettings["backgroundImageTemp"] = "";
    newSettings["backgroundImage"] = "";

    this.props.changeSettings(newSettings);
  };

  /**
   * Demonstrates how the choosen color will affect the interface
   */
  backgroundColorChangeVisible = () => {
    this.setState({
      ...this.state,
      backgroundColorChange: !this.state.backgroundColorChange,
      backgroundImageChange: false
    });
  };

  /**
   * Apply and set background image to dashboard
   */
  confirmBackgroundUpload = (backgroundUploadBase64, prefix) => {
    let newSettings = clone(this.props.settings);
    newSettings["backgroundColor"] = "";
    newSettings["backgroundImage"] = backgroundUploadBase64;
    newSettings["backgroundImageTemp"] = prefix;

    this.props.changeSettings(newSettings);
  };

  /**
   * Demonstrates how the choosen color will affect the interface
   */
  backgroundImageChangeVisible = () => {
    this.setState({
      ...this.state,
      backgroundImageChange: !this.state.backgroundImageChange,
      backgroundColorChange: false
    });
  };

  setGridDefault = () => {
    let newSettings = clone(this.props.settings);
    newSettings.grid.preventCollision = true;
    this.props.changeSettings(newSettings);
  };

  setDescriptionDefault = () => {
    let newSettings = clone(this.props.settings);
    newSettings["description"] = "";
    this.props.changeSettings(newSettings);
  };

  changeDescriptionSetting = () => {
    this.setState({
      ...this.state,
      visibleDescriptionSetting: !this.state.visibleDescriptionSetting
    });
  };

  changeDashboardTitle = () => {
    this.setState({
      ...this.state,
      visibleDashboardTitle: !this.state.visibleDashboardTitle
    });
  };

  inputOnChange = e => {
    let newSettings = clone(this.props.settings);
    newSettings[e.target.id] = e.target.value;
    this.props.changeSettings(newSettings);

    if (e.target.id === "noDataTitle") {
      this.props.setNoDataTitle(e.target.value)
    }
  };

  /*
  * Sets is excel styled
  */
  setIsExcelStyled = e => {
    let newSettings = deepCopy(this.props.settings)

    newSettings["isExcelExportStyled"] = e

    if (e) 
      newSettings["isExcelExportBigSized"] = !e

    this.props.changeSettings(newSettings)
  }

    /*
  * Sets is Excel Big Size Download
  */
    setIsExcelBigSizeDownload = e => {
      let newSettings = deepCopy(this.props.settings)
  
      newSettings["isExcelExportBigSized"] = e.target.checked
  
      this.props.changeSettings(newSettings)
    }


  isDefaultBackgroundShow = () => {
    return (this.props.settings.backgroundColor !== "" ||
      this.props.settings.backgroundImage) &&
      (this.props.settings.defaultBackgroundColor !==
        this.props.settings.backgroundColor ||
        this.props.settings.defaultBackgroundImage !==
        this.props.settings.backgroundImage)
      ? true
      : false;
  };

  /** Refill matrix last positions before deleteLast row */
  deleteLastRow = () => {
    this.props.gridCalculator.resizeGrid(
      this.props.plugins,
      $("#mainDiv").height() / this.props.settings.grid.rowHeight,
      this.props.settings.grid.numberOfColumns
    );

    let deleteRowCount = this.props.gridCalculator.deleteLastRow(
      this.state.initialeRowCount
    );

    if (deleteRowCount == 0) {
      return;
    }

    $("#mainDiv").css(
      "height",
      this.props.gridCalculator.getLength() *
      this.props.settings.grid.rowHeight +
      "px"
    );
  };

  /*
  * Sets cache mode
  */
  setCacheMode = (status) => {
    if (status === true) {
      this.props.forceCloseInteraction(true)
    }

    store.dispatch(setCacheMode(status))
  }

  // Dashboard Tools should always be on top of the page or not
  toggleStickyDashboardTools = () => {
    const newSettings = clone(this.props.settings);
    
    newSettings.stickyDashboardTools = !this.props.settings.stickyDashboardTools;

    this.props.changeSettings(newSettings);
  }

  render() {
    return this.props.visible && (
      <div
        ref={this.wrapperRef}
      >
        <PopupContainer
          width="550px"
          constantHeight="600px"
          position="left"
          textAlign="left"
          background={"rgb(251, 251, 251)"}
          color="#505050"
        >
          <Text style={{ textAlign: "center" }} type="h4">
            {i18n.t("Dashboard.Settings.DashboardSettings")}
          </Text>
          <Text
            type="h6"
            style={{
              color: "rgb(162, 162, 162)",
              marginBottom: "27px",
              textAlign: "center",
              whiteSpace: "normal"
            }}
          >
            {i18n.t("Dashboard.Settings.DashboardSettingsDescription")}
          </Text>

          <Row gutter={2} style={{ marginBottom: "11px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <ShrinkOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                type="span"
                style={{
                  whiteSpace: "normal",
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Cache.EnableCacheMode")}
                <Tooltip
                  tooltip={
                    i18n.t("Cache.CacheDescription")
                  }
                >
                  <span name={"stackDesignDes"} style={{ fontSize: "14px", color: "rgb(56, 123, 175)" }}> (?)</span>
                </Tooltip>
              </Text>
            </Col>
            <Col span={3} style={{ textAlign: "center" }}>
              <Switch
                onChange={(e) => this.setCacheMode(e)}
                checked={this.props.cacheModeState.isCacheModeActive}
              />
            </Col>
          </Row>

          <div>
            <Divider className={"config-divider"} orientation="left">
              {i18n.t("Dashboard.Settings.Theme")}
            </Divider>
            <Row gutter={2} style={{ marginBottom: "11px" }}>
              <Col style={{ textAlign: "center" }} span={3}>
                <FormatPainterOutlined style={{ fontSize: "16px" }} />{" "}
              </Col>
              <Col style={{ textAlign: "left" }} span={9}>
                <Text
                  type="span"
                  style={{
                    whiteSpace: "normal",
                    fontSize: "16px",
                    display: "inline-block",
                    marginTop: "2px"
                  }}
                >
                  {i18n.t("Dashboard.Settings.SelectedTheme")}
                </Text>
              </Col>
              <Col span={12} style={{ textAlign: "center" }}>
                <Select
                  id={"dashboardTheme"}
                  value={this.props.theme ? this.props.theme : this.props.theme !== "" ? this.state.defaultTheme?._id : ""}
                  defaultValue=""
                  style={{ width: "100%" }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  onChange={(value) => {
                    this.props.setTheme(value);
                  }}
                  disabled={(!this.props.theme && !this.state.defaultTheme && !this.state.themes?.length > 0) || !this.state.themes?.length > 0}
                  optionLabelProp="label"
                >
                  {
                    this.state.themes?.map(theme => theme?._id && theme?.name && (
                      <Option
                        key={theme._id}
                        value={theme._id}
                        label={theme.name}
                      >
                        {theme.name}
                      </Option>
                    ))
                  }
                  <Option
                    label={i18n.t("Dashboard.Settings.NoTheme")}
                    className={this.props.theme === "" ? "ant-select-dropdown-menu-item-selected" : null}
                    style={{
                      marginTop: 5,
                      borderTop: "1px solid #d3d3d3",
                    }}
                    value={""}
                  >
                    {i18n.t("Dashboard.Settings.NoTheme")}
                  </Option>
                </Select>
              </Col>
            </Row>
          </div>

          <div style={{ position: "relative" }}>
            <Divider className={"config-divider"} orientation="left">
              {i18n.t("Dashboard.Settings.BackgroundSettings")}
            </Divider>
            {this.isDefaultBackgroundShow() ? (
              <Tooltip tooltip={i18n.t("ReturnToDefault")}>
                <Button
                  id="returnDefaultButton"
                  className={"general-button"}
                  onClick={this.setBGDefault}
                  style={{
                    position: "absolute",
                    top: "-1px",
                    right: "9px",
                    fontSize: "13px",
                    padding: "2px 9px",
                    height: "fit-content",
                    width: "fit-content",
                    borderBottom: "0",
                    backgroundColor: "#62b779"
                  }}
                >
                  {i18n.t("ReturnToDefault")}
                </Button>
              </Tooltip>
            ) : null}
          </div>

          <Row gutter={2} style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Settings.BackgroundColor")}
              </Text>
            </Col>
            <Col span={12}>
              <Button
                id={
                  this.state.backgroundColorChange
                    ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                    : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")
                }
                onClick={this.backgroundColorChangeVisible}
                style={{ width: "100%" }}
              >
                {this.state.backgroundColorChange
                  ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                  : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
              </Button>
              {this.state.backgroundColorChange ? (
                <div
                  style={{
                    whiteSpace: "normal",
                    marginTop: "5px",
                    position: "absolute",
                    right: 0,
                    zIndex: 3,
                    top: "30px"
                  }}
                >
                  <SketchPicker
                    onChange={this.backgroundColorOnChange}
                    color={this.props.settings.backgroundColor}
                    id={"backgroundColor"}
                    width={200}
                  />
                </div>
              ) : null}
            </Col>
          </Row>
          <Row gutter={2} style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <PictureOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("BackgroundImage")}
              </Text>
            </Col>
            <Col span={12}>
              <Button
                id={
                  this.state.backgroundImageChange
                    ? i18n.t("Dashboard.Configuration.Fields.CloseUploadArea")
                    : i18n.t("Dashboard.Configuration.Fields.OpenUploadArea")
                }
                onClick={this.backgroundImageChangeVisible}
                style={{ width: "100%" }}
              >
                {this.state.backgroundImageChange
                  ? i18n.t("Dashboard.Configuration.Fields.CloseUploadArea")
                  : i18n.t("Dashboard.Configuration.Fields.OpenUploadArea")}
              </Button>
              {this.state.backgroundImageChange ? (
                <div
                  style={{
                    whiteSpace: "normal",
                    marginTop: "5px",
                    position: "absolute",
                    right: 0,
                    zIndex: 3,
                    top: "30px",
                    width: "200px",
                    background: "#fff",
                    padding: "20px",
                    boxShadow:
                      "rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px",
                    borderRadius: "8px"
                  }}
                >
                  <ImageUploader
                    confirm={this.confirmBackgroundUpload}
                    className="imguploadd"
                    image={
                      this.props.settings.backgroundImage
                        ? this.props.settings.backgroundImageTemp +
                        "," +
                        this.props.settings.backgroundImage
                        : ""
                    }
                  />
                </div>
              ) : null}
            </Col>
          </Row>

          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Settings.DashboardDetails")}
          </Divider>
          <Row gutter={2} style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <AlignLeftOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Title")}
              </Text>
            </Col>
            <Col span={12}>
              <Input
                allowClear
                id={"title"}
                defaultValue={this.props.settings.title}
                value={this.props.settings.title}
                onChange={this.inputOnChange}
              />
            </Col>
          </Row>
          <Row gutter={2} style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <AlignLeftOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Description")}
              </Text>
            </Col>
            <Col span={12}>
              <TextArea
                placeholder=""
                allowClear
                autoSize={{ minRows: 2, maxRows: 6 }}
                defaultValue={this.props.settings.description}
                value={this.props.settings.description}
                onChange={this.inputOnChange}
                id={"description"}
              />
            </Col>
          </Row>
          <Row gutter={2} style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <AlignLeftOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.GridSystem.LastRow")}
              </Text>
            </Col>
            <Col span={12}>
              <Button
                id={"deleteLastRow"}
                onClick={this.deleteLastRow}
                style={{ width: "100%" }}
              >
                {i18n.t("Delete")}
              </Button>
            </Col>
          </Row>
          <Row gutter={2} style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <AlignLeftOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left", display: "flex" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Settings.NoDataConfiguration")}
              </Text>
              <Tooltip
                tooltip={
                  <>
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Info")} <br />
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.GraphicConfiguration")} <br />
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Settings")} <br />
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.DefaultText")} <br />
                  </>
                }
              >
                <InfoCircleOutlined style={{ position: 'relative', marginTop: '5px', textAlign: "left", marginLeft: "10px" }} />
              </Tooltip>
            </Col>
            <Col span={12}>
              <TextArea
                allowClear
                id={"noDataTitle"}
                defaultValue={this.props.settings.noDataTitle}
                value={this.props.settings.noDataTitle}
                onChange={this.inputOnChange}
                autoSize={{ minRows: 3, maxRows: 5 }}
                placeholder={i18n.t("NoDataContent.NoData") + " " +
                  i18n.t("NoDataContent.PluginCouldNotBeVisualized")}
              />
            </Col>
          </Row>
          <Row gutter={2} style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <VerticalAlignTopOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  textWrap: "auto",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Settings.StickToolsToTop")}
              </Text>
            </Col>
            <Col span={12}>
            <Switch
              id="stickToolsToTopButton"
              onChange={() => this.toggleStickyDashboardTools()}
              checked={this.props.settings.stickyDashboardTools}
            />
            </Col>
          </Row>
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Settings.GridSettings")}
          </Divider>
          <GridSettings
            settings={this.props.settings}
            changeSettings={this.props.changeSettings}
          />
          {
            this.props.readOnly === "WRITE"
              ? <>
                <Divider className={"config-divider"} orientation="left">
                  {i18n.t("Dashboard.Settings.CustomExcel")}
                </Divider>
                <Row style={{ marginBottom: "10px" }}>
                  <Col span={3} style={{ paddingLeft: "1px", paddingRight: "1px", textAlign: "center" }}>
                    <FileExcelOutlined style={{ fontSize: "16px" }} />{" "}
                  </Col>
                  <Col span={9}>
                    <Text
                      type="span"
                      style={{
                        whiteSpace: "normal",
                        fontSize: "16px",
                        display: "inline-block",
                        marginTop: "2px",
                        color: "rgba(0, 0, 0, 0.65)",
                      }}>
                      {i18n.t("Dashboard.Settings.StandartExcel")}
                      <Tooltip
                        tooltip={
                          i18n.t("Dashboard.Settings.StandartExcelDesc")
                        }
                      >
                        <span name={"stackDesignDes"} style={{ fontSize: "14px", color: "rgb(56, 123, 175)" }}> (?)</span>
                      </Tooltip>
                    </Text>
                  </Col>
                  <Col span={3} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Switch id={"styledExcelSwitch"} onChange={(e) => this.setIsExcelStyled(e)}
                      checked={this.props.settings.isExcelExportStyled} />
                  </Col>
                  <Col span={9} style={{ textAlign: "right" }}>
                    <Text
                      type="span"
                      style={{
                        whiteSpace: "normal",
                        fontSize: "16px",
                        display: "inline-block",
                        marginTop: "2px",
                        color: "rgba(0, 0, 0, 0.65)",
                      }}>{i18n.t("Dashboard.Settings.StyledExcel")}
                      <Tooltip
                        tooltip={
                          i18n.t("Dashboard.Settings.StyledExcelDesc")
                        }
                      >
                        <span name={"stackDesignDes"} style={{ fontSize: "14px", color: "rgb(56, 123, 175)" }}> (?)</span>
                      </Tooltip></Text>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "10px" }}>

                <Col span={3} style={{ paddingLeft: "1px", paddingRight: "1px", textAlign: "center" }}>
                    <FileExcelOutlined style={{ fontSize: "16px" }} />{" "}
                  </Col>
                  <Col span={9}>
                    <Text
                      type="span"
                      style={{
                        whiteSpace: "normal",
                        fontSize: "16px",
                        display: "inline-block",
                        marginTop: "2px",
                        color: "rgba(0, 0, 0, 0.65)",
                      }}>
                      {i18n.t("Dashboard.Settings.BigSizedExcel")}
                      <Tooltip
                        tooltip={
                          i18n.t("Dashboard.Settings.BigSizeExcelDesc")
                        }
                      >
                        <span name={"stackDesignDes"} style={{ fontSize: "14px", color: "rgb(56, 123, 175)" }}> (?)</span>
                      </Tooltip>
                    </Text>
                  </Col>
                  <Col span={3} style={{ display: "flex", alignItems: "left", justifyContent: "left" }}>
                    <Checkbox style={{ marginLeft: "13px" }}  defaultChecked={this.props.settings.isExcelExportBigSized}
                      checked={this.props.settings.isExcelExportBigSized} onChange={(e) => this.setIsExcelBigSizeDownload(e)} 
                      disabled= {this.props.settings.isExcelExportStyled}/>
                  </Col>
                </Row>
                <CustomExcelSettings
                  getCustomExcels={this.props.getCustomExcels}
                  dashboardId={this.props.dashboardId}
                  removeCustomExcel={this.props.removeCustomExcel}
                  downloadCustomExcel={this.props.downloadCustomExcel}
                  customExcelList={this.props.customExcelList}
                  mainDashboardId={this.props.mainDashboardId}
                /></>
              : null
          }

          <Button
            id={"dashboardCloseButton"}
            style={{
              position: "absolute",
              top: "3px",
              right: "3px",
              borderRadius: "40px",
              padding: "6px 11px",
              backgroundColor: "white",
              zIndex: 1
            }}
            type={"default"}
            onClick={() => {
              this.props.closeSetting();
            }}
          >
            X
          </Button>
        </PopupContainer>
      </div>
    );
  }
}

/**
 * Connects renderContent and SettingList. To show dashboard setting no data description in plugin no data title.
 */
const mapDispatchToProps = {
  setNoDataTitle,
};

const mapStateToProps = (state) => {
  return {
    cacheModeState: state.CacheModeReducer
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingList);