import React, { Component } from "react";
import Select from "react-select";

const filterOperators = [
  { label: "=", value: "=" },
  { label: "!=", value: "!=" },
  { label: ">", value: ">" },
  { label: "<", value: "<" },
  { label: ">=", value: ">=" },
  { label: "<=", value: "<=" },
  { label: "<=>", value: "<=>" },
  { label: "isNull", value: "isNull" },
  { label: "isNotNull", value: "isNotNull" }
];

const filterOperatorsForMulti = [
  { label: "=", value: "=" },
  { label: "!=", value: "!=" }
];

/**
 * Stylable filter predicate dropdown component
 */
export default class FilterPredicates extends Component {
  render() {
    return (
      <Select
        id={"numberFilterPredicate-" + this.props.pluginId}
        onChange={value => this.props.setFilterOperator(value.value)}
        options={this.props.isMulti ? filterOperatorsForMulti : filterOperators}
        styles={this.props.styleConfiguration}
        value={{ label: this.props.value, value: this.props.value }}
        menuPortalTarget={document.body}
        menuPlacement="auto"
      ></Select>
    );
  }
}
