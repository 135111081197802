import React from "react";
import Button from "../Button/Button";
import MonacoEditor from "react-monaco-editor";
import i18n from "../../../Utils/i18next";
import Text from "../Text/Text";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";

/**
 * Session Variable Code Editor Compoent
 */
export default class SessionVariableCodeEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      code: this.props.code || "",
    };
  }

  /**
   * `Monaco Editor` Options
   * 
   * More info: https://microsoft.github.io/monaco-editor/api/interfaces/monaco.editor.IEditorOptions.html
   */
  options = {
    minimap: { enabled: false },
    hover: {
      enabled: true,
      above: false,
      sticky: true
    },
    matchBrackets: "always",
    acceptSuggestionOnCommitCharacter: true,
    acceptSuggestionOnEnter: "on",
    accessibilitySupport: "auto",
    autoIndent: true,
    automaticLayout: true,
    codeLens: true,
    colorDecorators: true,
    contextmenu: true,
    cursorBlinking: "smooth",
    cursorSmoothCaretAnimation: false,
    cursorStyle: "line",
    disableLayerHinting: false,
    disableMonospaceOptimizations: false,
    dragAndDrop: true,
    fixedOverflowWidgets: false,
    folding: true,
    foldingStrategy: "auto",
    fontLigatures: true,
    formatOnPaste: true,
    formatOnType: true,
    hideCursorInOverviewRuler: false,
    highlightActiveIndentGuide: true,
    links: true,
    mouseWheelZoom: false,
    multiCursorMergeOverlapping: true,
    multiCursorModifier: "alt",
    overviewRulerBorder: true,
    overviewRulerLanes: 2,
    quickSuggestions: true,
    quickSuggestionsDelay: 0,
    readOnly: false,
    domReadOnly: false,
    renderControlCharacters: true,
    renderFinalNewline: true,
    renderIndentGuides: true,
    renderLineHighlight: "all",
    renderWhitespace: "none",
    revealHorizontalRightPadding: 30,
    roundedSelection: true,
    scrollBeyondLastColumn: 5,
    scrollBeyondLastLine: true,
    selectOnLineNumbers: true,
    selectionClipboard: true,
    selectionHighlight: true,
    showFoldingControls: "mouseover",
    smoothScrolling: true,
    suggestOnTriggerCharacters: true,
    wordBasedSuggestions: true,
    wordSeparators: "~!@#$%^&*()-=+[{]}|;:'\",.<>/?",
    wordWrap: "off",
    wordWrapBreakAfterCharacters: "\t})]?|&,;",
    wordWrapBreakBeforeCharacters: "{([+",
    wordWrapBreakObtrusiveCharacters: ".",
    wordWrapMinified: true,
    wrappingIndent: "none",
    scrollbar: {
      vertical: 'visible',
      horizontal: 'visible'
    }
  };

  /**
   * Handles the component update.
   * 
   * @param {*} prevProps 
   */
  componentDidUpdate(prevProps) {
    if (this.props.code !== prevProps.code) {
      this.setState({ code: this.props.code });
    }
  }

  /**
   * Adds the event listener that updates editor layout on window resize.
   */
  editorDidMount = (editor) => {
    window.addEventListener("resize", () => {
      editor.layout({});
    })
  }

  /**
   * Handles the `onChange` event of the editor.
   * 
   * @param {*} value 
   */
  handleChange = async (value) => {
    clearTimeout(this.timer);

    this.setState({ code: value });

    this.timer = setTimeout(() => {
      this.props.updateCode(this.state.code);
    }, 300);
  }

  /**
   * Removes the event listener that updates editor layout on window resize.
   */
  editorWillUnmount = (editor) => {
    window.removeEventListener("resize", () => {
      editor.layout({});
    })
  }

  render() {
    return (
      <div
        style={{
          height: this.props.height,
          width: "100%",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <div
          id="formula-editor-drop-area"
          style={{
            height: "100%",
            border: "solid 2px transparent",
            marginBottom: "-2px",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <MonacoEditor
            value={this.state.code}
            language={this.props.language}
            options={this.options}
            theme="session-variable"
            editorDidMount={this.editorDidMount}
            onChange={this.handleChange}
            editorWillUnmount={this.editorWillUnmount}
          />
          {
            !this.state.ignoreWarning &&
            !this.props.dataSource && (
              <div
                style={{
                  position: "absolute",
                  zIndex: 1,
                  background: "rgba(255,255,255,0.8)",
                  backdropFilter: "blur(1px)",
                  borderRadius: 8,
                  padding: "24px 24px 24px 24px",
                  minWidth: "300px",
                  width: "50%",
                  color: "rgb(85, 85, 85)",
                  textAlign: "center",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px"
                }}
              >
                <CloseOutlined
                  onClick={() => this.setState({ ignoreWarning: true })}
                  style={{
                    alignItems: "center",
                    position: "absolute",
                    top: 8,
                    right: 8,
                    color: "rgba(85, 85, 85,)"
                  }}
                />
                <Text
                  style={{
                    textAlign: "center",
                  }}
                >
                  {i18n.t("SessionVariables.NoDataSourceWarning")}
                </Text>
              </div>
            )
          }
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px 16px",
          }}
        >
          <Button
            className={"general-button-outlined"}
            style={{
              margin: "0",
              padding: "4px 12px",
              marginRight: "6px",
            }}
            onClick={this.props.testResult?.status === "processing" ? this.props.abortTestQuery : this.props.testQuery}
          >
            {
              this.props.testResult?.status === "processing" &&
              <LoadingOutlined
                spin
                style={{
                  marginRight: "8px"
                }}
              />
            }

            {i18n.t(this.props.testResult?.status === "processing" ? "Abort" : "Execute")}
          </Button>
          <div>
            <Button
              className={"general-button-outlined"}
              style={{
                margin: "0",
                padding: "4px 12px",
                marginRight: "6px",
              }}
              onClick={() => this.props.onCancel()}
            >
              {i18n.t("Cancel")}
            </Button>

            <Button
              className={"general-button"}
              style={{
                padding: "4px 12px !important",
                margin: "0",
                marginLeft: "6px",
                width: "fit-content"
              }}
              onClick={() => this.props.onApply(this.state.code)}
            >
              {i18n.t("Apply")}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}