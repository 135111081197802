import React, { Component } from "react";
import { Button, Layout, Row, Col } from "antd";
import { showNotificationWithIcon } from "../../Utils/Notification";
import { connect } from "react-redux";
import "./modelContent.css";
import SelectModel from "./selectModel/SelectModel";
import CreateModel from "./createModel/CreateModel";
import SaveSelection from "../components/saveStatusSelection/SaveSelection";
import TreeModel from "../components/treeModel/TreeModel";
import { getModel } from "../modelPage/ModelContentAction";
import { get, put } from "../../Utils/WebService";
import { API_BASE } from "../../config";

class ModelPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      createModalVisible: false,
      isCreateButtonVisible: true,
      model: {},
      checkedModelItem: [],
      checkedKeys: [],
      selectionModel: "",
      modelObject: {},
      modelDropDownList: []
    };
  }

  componentWillMount(){
    this.getModelDropDownList()
  }

  handleCreate = () => {
    this.setState({
      createModalVisible: true
    });
  };

  handleCreateModalVisible = () => {
    this.setState({
      createModalVisible: false
    });
    // this.getModelDropDownList();
  };

  selectModelInList = value => {
    console.log("selection model value = ", value);
    this.setState({
      selectionModel: value
    });

    this.props.getModel(value);
  };

  handleModalSelection = action => {
    if (action === "createModelToAdd") {
      this.setState({
        createModalVisible: true,
        isCreateButtonVisible: false
      });
    } else if (action === "addToExistingModel") {
      this.setState({
        createModalVisible: false,
        isCreateButtonVisible: false
      });
    }
  };

  handleCancel = () => {
    this.setState({
      selectionModel: ""
    })
  }

  changeTreeModelChecked = (check, model) => {
    console.log("model: ", model);

    this.setState({
      checkedModelItem: check,
      model: model
    });
  };

  handleConfirm = action => {
    if (this.state.checkedModelItem.length == 0 || this.state.model.length == 0) {
      showNotificationWithIcon(
        "No selected table or column",
        "You must select columns you want to add to the model",
        "error"
      );
    } else {
      let checkedItemList = [];
      let checkedItemObject = {};
      let checkArray = [];
      for (let obj of this.state.checkedModelItem) {
        let itemKeys = obj.split(",");
        checkArray.push(itemKeys);

        if (Object.keys(itemKeys).length === 3) {
          checkedItemObject = { ...this.state.model.tables[itemKeys[0]] };
          checkedItemObject.tables = {
            ...this.state.model.tables[itemKeys[0]].tables[itemKeys[1]]
          };
          checkedItemObject.tables.columns = {
            ...this.state.model.tables[itemKeys[0]].tables[itemKeys[1]].columns[
              itemKeys[2]
            ]
          };

          checkedItemList.push(checkedItemObject.tables);
        } else if (Object.keys(itemKeys).length === 2) {
          checkedItemObject = { ...this.state.model.tables[itemKeys[0]] };
          checkedItemObject.tables = {
            ...this.state.model.tables[itemKeys[0]].tables[itemKeys[1]]
          };
        }
      }

      let selectedArrayList = [];
      checkedItemList.map(tbl => {
        if (selectedArrayList[tbl.displayName] == undefined) {
          selectedArrayList[tbl.displayName] = { ...tbl };
          selectedArrayList[tbl.displayName].columns = [];
          selectedArrayList[tbl.displayName].columns.push(tbl.columns);
        } else {
          selectedArrayList[tbl.displayName].columns.push(tbl.columns);

        }
      });

      let modelClone = JSON.parse(JSON.stringify(this.state.model));
      modelClone.tables = [];

      for (let key in selectedArrayList) {
        modelClone.tables.push(selectedArrayList[key]);
      }
      this.setState({
        model: modelClone
      });

      const successFunc = response => {

        showNotificationWithIcon(
          "OK",
          "Changes successfully saved",
          "success"
        );

        if (action === "saveAndGoJoining") {
          this.navigateToJoining();
        }
      };

      const errorFunc = response => {
        showNotificationWithIcon(
          "Error",
          response.data,
          "error"
        );
      };

      let url = `${API_BASE}/subjectArea/${modelClone.id}`;
      put(url, modelClone, successFunc, errorFunc);

    }
  };

  navigateToJoining = () => {
    const { model } = this.state;
    this.props.history.push({
      pathname: "/join",
      search: `?model=newModel`,
      state: { model }
    });
  };

  getModelDropDownList() {

    const successFunc = response => {
      this.setState({
        modelDropDownList: response.data
      });
    };

    const errorFunc = response => {
      console.trace("response err=", response);
    };

    let url = `${API_BASE}/subjectArea/nameList`;
    get(url, successFunc, errorFunc);
  }

  handleConfirmCreateModel = () => {
    this.getModelDropDownList();
  }

  render() {
    const {modelObject} = this.props.state.ModelContentReducer;
    return (
      <div className="modellingContent">
        <CreateModel
          visible={this.state.createModalVisible}
          onChangeVisible={this.handleCreateModalVisible}
          onConfirm={this.handleConfirmCreateModel}
          history = {this.props.history}
        />
        <Row>
          <Col span={24}>
            <Row>
              <Col span={20}>
                <SelectModel 
                  modelList={this.state.modelDropDownList}
                  handleSelection={this.selectModelInList} />
              </Col>
              <Col span={4}>
                {this.props.location.state && (
                  <SaveSelection
                    dataSource={this.props.location.state.dataSource}
                    onClick={this.handleModalSelection}
                  />
                )}
                <Button
                  id="createModelButton"
                  type="dashed"
                  icon="plus-circle"
                  style={{
                    display: this.state.isCreateButtonVisible
                      ? "visible"
                      : "none"
                  }}
                  onClick={this.handleCreate}
                >
                  Create
                </Button>
              </Col>
            </Row>
            <Layout>
              <TreeModel
                ckeckedKeys={this.state.checkedKeys}
                changeTreeModel={this.changeTreeModelChecked}
                modelData = {modelObject}
              />
            </Layout>
          </Col>
        </Row>
        <Row>
          <div className="contentFooter">
            <Row>
              <Button key="cancel" onClick={this.handleCancel}>
                Cancel
              </Button>
              <Button
                key="save"
                type="primary"
                ghost="true"
                onClick={() => this.handleConfirm("save")}
              >
                Save
              </Button>
              <Button
                key="saveAndGoJoining"
                type="primary"
                onClick={() => this.handleConfirm("saveAndGoJoining")}
              >
                Save & Go Joining
              </Button>
            </Row>
          </div>
        </Row>
      </div>
    );
  }
}
const mapDispatchToProps = {
  getModel
};

const mapStateToProps = state => {
  return {
    state: state
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelPage);
