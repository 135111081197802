
export const NEW_COLUMN_EDITOR_CONTENT = "NEW_COLUMN_EDITOR_CONTENT";
export const CHANGE_NEW_COLUMN_LIST = "CHANGE_NEW_COLUMN_LIST"

/**
 * 
 * @param contentObject 
 * @returns 
 * 
 * Sets New Column Editor content
 */
export const setNewColumnEditorContent = (contentObject) => {
  return (dispatch) => {
    dispatch({
      type: NEW_COLUMN_EDITOR_CONTENT,
      payload: contentObject,
    });
  };
}

export const setNewColumnList = (contentObject) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_NEW_COLUMN_LIST,
      payload: contentObject,
    });
  };
}
