import React, { Component } from "react";
import "./contentContainer.css";

export default class ContentContainer extends Component {
  render() {
    return (
      <div className={"col-lg-12"}>
        <div
          className={
            this.props.outClass
              ? this.props.outClass + " outer-content-container"
              : "outer-content-container"
          }
        >
          <div
            id={this.props.id}
            className={
              this.props.className
                ? this.props.className + " content-container"
                : "content-container"
            }
            style={this.props.style}
          >
            {this.props.extra ? (
              <div className={"extra"} style={{ float: "right" }}>
                {this.props.extra}
              </div>
            ) : null}
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
