const clone = require("rfdc")();

/** 
 * Generates tables and columns for manual models created in data sources such as csv.
 */
export function generateTable(fileInformation) {
  let table = {};

  table.id = fileInformation.name;
  table.img = require("../../images/mongodb.png");
  table.aliasName = fileInformation.name.replace(".", "_");
  table.columns = fileInformation.columns;
  table.dataSource = null;
  table.dataSourceKey = "mongo.test";
  table.description = null;
  table.displayName = fileInformation.displayName;
  table.title = fileInformation.displayName;
  table.duplicated = false;
  table.hidden = false;
  table.name = fileInformation.name.replace(".", "_");
  table.parentTableName = null;
  table.schemaType = null;
  table.value = null;
  table.whereClause = null;
  table.manuel = true;
  table.columnsDataType = fileInformation.columnsDataType;
  table.columns = clone(createColumnForManuelModel(fileInformation));

  return table;
}

/** 
 * Parses the data type saved as Stringfy in hash map format
 */
function parseDataTypeHashSetForColumns(dataType) {
  return new Map(JSON.parse(dataType));
}

/** 
 * Generate column
 */
export function createColumnForManuelModel(table) {
  let columnsDataType = parseDataTypeHashSetForColumns(table.columnsDataType);

  let columns = [];

  table.columns.map((item) => {
    let column = {};

    column.aggrRule = "";
    column.aggregatable = false;
    column.aliasName = item;
    column.columnType = null;
    column.dataType = columnsDataType.get(item);
    column.description = null;
    column.displayName = item;
    column.doubleColumn = false;
    column.duplicated = false;
    column.hidden = false;
    column.name = item;
    column.nullable = true;
    column.value = null;

    columns.push(column);
  });

  return columns;
}
