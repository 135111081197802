export const configuration = {
    condFormat: true,
    condFormatLegend: true,
    topojson: "turkey/Iller.topojson",
    featuresForSelectedTopoJson: [
        "NAME",
        "PLAKA",
        "ILKOD",
        "UserID",
        "ID",
        "FEATTYP",
        "ORDER00",
        "FEATAREA",
        "FEATPERIM"
    ],
    featureCode: "ILKOD",
    featureDesc: "NAME",
    scaleType: "Linear",
    opacity: 0.75,
    legend: true,
    showHideButton: false,
    legendLocation: "down",
    styleType: "Series Picker",
    seriesScale: "Individual",
    nullColour: "#CCCCCC",
    StrokeWidth: 2,
    colours: "Flat-UI",
    mapTile: "Default",
    mapTileSelection: "Atlas Map Tile",
};

export const actions = [
    {
        trigger: "clickFeature",
        type: "click",
        name: "Tıklama Feature",
        output: ["code", "desc"],
        description: "ClickFeatureDesc"
    },
    {
        trigger: "hoverFeature",
        type: "hover",
        name: "Hover Feature",
        output: ["code"],
        description: "HoverMapObjectDesc"
    }
];

export const reactions = [
    {
        id: "filter",
        name: "Filtre",
        description: "desc87",
        type: "general"
    },
    {
        id: "highlightFeatures",
        name: "Featureları Vurgula",
        description: "desc220",
        type: "private",
        method: "highlightFeatures"
    }
];

export const conditionalFormatColumnMap = ["measure", "desc", "code", "hidden"];
export const conditionalFormatTargetMap = ["measure"];
export const conditionalFormatOptions = ["backgroundColor"];

export const conversionMap = {
    "table": {
        columnMap: {
            columns: {
                name: "Columns",
                from: ["code", "desc", "measure", "vary"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        }
    }
};