import * as htmlToImage from 'html-to-image';
import jsPDF from 'jspdf';
import i18n from "../../Utils/i18next";
import { rmvpp } from "../../ui/Plugins/RenderJs/rmvpp";
import { filterNode } from './FilterNodes';
import { ROBOTOREGULAR } from "../../ui/Plugins/PluginComponents/Table/TablePdfContent";

/**
 * Generates the PDF output of the dashboard
 * 
 * @param {*} pdfName 
 * @param {*} elementThatWillBeExported 
 * @param {*} excludedElements 
 */
export const exportToPDF = async (pdfName, elementThatWillBeExported, excludedElements) => {
    
    function changeDisabilityOfElements(value) {
        for (let i = 0; i < excludedElements.length; i++) {
            excludedElements[0].style.display = value;
        }
    }

    changeDisabilityOfElements("none");

    let canvas = await htmlToImage.toCanvas(elementThatWillBeExported, {filter: filterNode});

    const imgData = canvas.toDataURL("image/png", 1.0);
    const width = elementThatWillBeExported.clientWidth;
    const height = elementThatWillBeExported.clientHeight;
    const pdf = new jsPDF("l", "mm", [height + 25, width]);
    const exportDate = i18n.t('Dashboard.Configuration.Fields.ExportDate') + ": " + rmvpp.generateDate();

    pdf.addFileToVFS("Roboto-Regular.ttf", ROBOTOREGULAR);
    pdf.addFont("Roboto-Regular.ttf", "Roboto-Regular-normal", "normal");
    pdf.setFont("Roboto-Regular-normal");
    pdf.setFontSize(50);
    pdf.text(exportDate, 4, pdf.internal.pageSize.height - 10);
    pdf.addImage(imgData, 'PNG', 0, 0, width, height);
    pdf.save(pdfName);

    changeDisabilityOfElements("block");
}