import {
  DeleteOutlined,
  DeleteRowOutlined,
  EditOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Input, Popconfirm, Tabs } from "antd";
import Cookies from "js-cookie";
import React, { Component } from "react";
import { connect } from "react-redux";
import { store } from "../..";
import { showNotificationWithIcon } from "../../Utils/Notification";
import { del, get, post } from "../../Utils/WebService";
import i18n from "../../Utils/i18next";
import { API_BASE } from "../../config";
import { setAddTableCallbackFunction, setDataSourceId, setDataSourceSelfUtils } from "../../ui/Datasources/SelfServiceReducer/SelfServiceAction";
import { setMinimize } from "../FileUpload/FileUploadAction";
import FileUploadContent from "../FileUpload/FileUploadContent";
import { generateTable } from "../FileUpload/GenerateManuelTableAndColumn";
import Text from "../GeneralComponents/Text/Text";
import Tooltip from "../GeneralComponents/Tooltip/Tooltip";
import DataSourceConnectionList from "./DataSourceConnectionList";
import UsedDataSources from "./UsedDataSources";
import WithoutDataSourceConnectionList from "./WithoutDataSourceConnectionList";
import RemoveTableModal from "../Modeling/RemoveTableModal";

const host = API_BASE;
const api = "/dataSource";
const withoutDataBaseApi = "/data"
const fileUploadUrl = host + "/fileUpload";
const url = host + api;
const withoutDataBaseUrl = host + withoutDataBaseApi
const { TabPane } = Tabs;

var usedSourcesSet = new Set();

const clone = require("rfdc")();

/**
 * This component file upload list contains different data connection lists. It also includes operations on data connection lists.
 */
class DataSourceList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTables: [],
      selectedData: {},
      tableData: [],
      usedTableData: [],
      fileUploadTableData: [],
      usedFileUploadTableData: [],
      searchTempTableData: [],
      usedSearchTempTableData: [],
      connections: [],
      viewedConnectionsForRDBMS: [],
      viewedConnectionsForDatasource: [],
      newConnection: {},
      fileUploadModalVisible: false,
      handleCsvResult: [],
      fileUploads: [],
      viewedFileUploads: [],
      usedSources: [],
      password: "",
      dataSourceTabActiveKey: "1",
      fileUploadRef: {},
      comesFromDashboardFileUpload: false
    };
  }

  /**
   *
   * @param {*} connection
   *Check if the incoming connection exists in the connection list
   */
  hasConnectionInConnectionsList = (connection) => {
    for (let index in this.state.connections) {
      if (this.state.connections[index].id === connection.id) {
        return index;
      }
    }

    return false;
  };

  /**
   *
   * @param {*} nextProps
   * Adds image information when new connection is added and adds to connections
   */
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.newConnection &&
      Object.keys(nextProps.newConnection).length !== 0 &&
      nextProps.newConnection.title != this.state.newConnection.title
    ) {
      let hasConnectionIndex = this.hasConnectionInConnectionsList(
        nextProps.newConnection
      );
      let connections = this.state.connections;
      nextProps.newConnection.img = require("../../images/" +
        nextProps.newConnection.img +
        ".png");

      if (hasConnectionIndex === false) {
        nextProps.newConnection.indexId =
          connections[connections.length - 1].indexId + 1;
        connections.push(nextProps.newConnection);
      } else {
        nextProps.newConnection.indexId = hasConnectionIndex;
        connections[hasConnectionIndex] = nextProps.newConnection;
      }

      let viewedConnectionsForRDBMS = connections.filter(connection => connection.dataSourceType !== "duckdb")
      let viewedConnectionsForDatasource = connections.filter(connection => connection.dataSourceType === "duckdb")

      if (viewedConnectionsForDatasource.filter(db => db.indexId === "plusButton").length === 0) {
        viewedConnectionsForDatasource = this.addDatasourceWithoutDataBase.concat(viewedConnectionsForDatasource)
      }

      this.setState({
        ...this.state,
        connections: connections,
        viewedConnectionsForRDBMS: viewedConnectionsForRDBMS,
        viewedConnectionsForDatasource: viewedConnectionsForDatasource,
        viewedConnections: viewedConnectionsForDatasource,
        newConnection: nextProps.newConnection,
      });
    }

    if (this.props.selfService.openFileUpload === false && nextProps.selfService.openFileUpload) {
      let callback = (datasources) => {
        let findedDataSource = datasources.find(d => d.id === this.props.selfService.dataSourceId)
        let data = {
          openFileUpload: false,
          dataSourceId: findedDataSource ? findedDataSource.id : "",
          firstDataUpload: findedDataSource ? false : true
        }

        this.fileUploadVisibilityChange(true, findedDataSource, true)
        store.dispatch(setDataSourceSelfUtils(data))
      }

      this.getDataConnectionsList(callback)
    }

    //Calls related methods when the model is edited
    if (
      nextProps.editModel !== this.props.editModel &&
      Object.keys(nextProps.editModel).length > 0
    ) {
      this.getDataFileUploadList();
      this.getDataConnectionsList();
      usedSourcesSet = new Set();
      this.usedData(nextProps.editModel);
    }

    //Calls the relevant method according to the response after saving the table in the model
    if (
      nextProps.modelTableSaveResponse !== this.props.modelTableSaveResponse
    ) {
      this.modelTableSaveResponse(nextProps.modelTableSaveResponse);
    }

    if (nextProps.datasourceEditMode !== this.props.datasourceEditMode && this.props.datasourceEditMode === true && !nextProps.isNotUpdatedSelfService) {
      this.getDataConnectionsList(false, true);
    }
  }

  componentWillMount() {
    this.getData();
  }

  /**
   *
   * @param {*} status
   * @param {*} updateFile
   * File Upload visible change and set upload fileUploadUpdateData
   */
  changeFileUploadModalVisible = (status, updateFile = undefined) => {
    this.setState({
      ...this.state,
      fileUploadUpdateData: updateFile,
    });

    this.props.changeFileUploadModalVisibity(status);
  };

  /**
   *
   * @param {*} dataSourceType
   * Retuns imagery of supported datasources
   */
  setImageForDataSources = (dataSourceType) => {
    switch (dataSourceType) {
      case "postgresql":
        return require("../../images/postgresql.png");
        break;
      case "mongodb":
        return require("../../images/mongodb.png");
        break;
      case "oracle":
        return require("../../images/oracle.png");
        break;
      case "mssql":
        return require("../../images/mssql.png");
        break;
      case "mysql":
        return require("../../images/mysql.png");
        break;
      case "duckdb":
        return require("../../images/selfService.png");
        break;
      default:
        break;
    }
  };

  /**
   * It pulls the connection list and sets the state by creating the desired object format
   */
  getDataConnectionsList = (customDataSourceCallback = undefined, getUsedSource = false) => {
    const successFunc = (response) => {
      const dataSources = response.data.map((item, index) => ({
        indexId: index + 1,
        id: item.id,
        title: item.displayName,
        name: item.name,
        img: this.setImageForDataSources(item.dataSourceType),
        displayName: item.name.split(/_(.+)/)[1],
        dataSourceType: item.dataSourceType,
        schemaName: item.schemaName,
        config: JSON.stringify(item.config, null, "  "),
      }));

      let connections = this.additionalData.concat(dataSources)
      let viewedConnections = this.additionalData.concat(dataSources)
      let viewedConnectionsForRDBMS = viewedConnections.filter(connection => connection.dataSourceType !== "duckdb")
      let viewedConnectionsForDatasource = this.addDatasourceWithoutDataBase.concat(viewedConnections.filter(connection => connection.dataSourceType === "duckdb"))

      if (viewedConnectionsForDatasource.filter(db => db.indexId === "plusButton").length === 0) {
        viewedConnectionsForDatasource = this.addDatasourceWithoutDataBase.concat(viewedConnectionsForDatasource)
      }

      this.setState({
        ...this.state,
        connections: connections,
        viewedConnections: viewedConnections,
        viewedConnectionsForDatasource: viewedConnectionsForDatasource,
        viewedConnectionsForRDBMS: viewedConnectionsForRDBMS
      }, () => {
        if (customDataSourceCallback) {
          customDataSourceCallback(dataSources)
        }

        // if (getUsedSource) {
        //   let usedSource = this.getUsedData()

        //   this.setState({
        //     ...this.state,
        //     usedSource: usedSource
        //   })
        // }
      });
    };

    const errorFunc = (error) => {
      showNotificationWithIcon(
        i18n.t("DataConnections.CouldNotFetch"),
        error,
        "error"
      );
    };

    get(url, successFunc, errorFunc);
  };

  /**
   *
   * @param {*} item
   *
   * Generates manual model for incoming item as parameter
   */
  generateManuelTables = (item) => {
    let tables = [];
    tables.push(generateTable(item));

    return tables;
  };

  /**
   * Brings a list of previously loaded files and sets state by converting them to the appropriate object format
   */
  getDataFileUploadList = () => {
    const successFunc = (response) => {
      const fileUploads = response.data.map((item, index) => ({
        indexId: index + 1,
        id: item.id,
        title: item.displayName,
        img: require("../../images/csv.png"),
        name: item.name,
        displayName: item.displayName,
        columnsDataType: item.columnsDataType,
        columns: item.columns,
        size: item.size,
        tables: this.generateManuelTables(item),
      }));

      this.setState({
        ...this.state,
        fileUploads: this.fileUploadPlus.concat(fileUploads),
        viewedFileUploads: this.fileUploadPlus.concat(fileUploads),
      });
    };

    get(fileUploadUrl, successFunc);
  };

  componentDidMount() {
    this.setState({
      ...this.state,
      dataSourceTabActiveKey: "0"
    }, () => {
      this.getDataConnectionsList();
      this.getDataFileUploadList();
    })

    store.dispatch(setAddTableCallbackFunction(this.beforeOnLoadTableForConnection))
  }

  /*
  * Before on load table for connection
  */
  beforeOnLoadTableForConnection = (datasource, schemaName) => {
    let mergedDataSourceKey = datasource.name + "." + schemaName

    usedSourcesSet.add(mergedDataSourceKey)
    
    this.onLoadTableForConnection(datasource, false, false)
  }

  /**
   *Combines file upload and data connection lists with the additional list
   */
  getData = () => {
    //success functionda
    let response = { data: this.state.connections };
    let responseFileUpload = { fileUploads: this.state.fileUploads };
    let connections = this.additionalData.concat(response.data)
    let viewedConnections = this.additionalData.concat(response.data)
    let viewedConnectionsForRDBMS = viewedConnections.filter(connection => connection.dataSourceType !== "duckdb")
    let viewedConnectionsForDatasource = this.addDatasourceWithoutDataBase.concat(viewedConnections.filter(connection => connection.dataSourceType === "duckdb"))

    if (viewedConnectionsForDatasource.filter(db => db.indexId === "plusButton").length === 0) {
      viewedConnectionsForDatasource = this.addDatasourceWithoutDataBase.concat(viewedConnectionsForDatasource)
    }

    this.setState({
      connections: connections,
      viewedConnections: viewedConnections,
      viewedConnectionsForRDBMS: viewedConnectionsForRDBMS,
      viewedConnectionsForDatasource: viewedConnectionsForDatasource,
      fileUploads: this.fileUploadPlus.concat(responseFileUpload.fileUploads),
      viewedFileUploads: this.fileUploadPlus.concat(
        responseFileUpload.fileUploads
      ),
    });
  };

  /**
   * Calls the methods to trigger for the plus button
   */
  onClickDataSourceAdditionalData = () => {
    this.props.visibleDataConnections(true, undefined);
    this.props.changeSelectedDataSource(true);
  };

  /*
  * Creates default duck named Vispeahen
  */
  createDefaultDuckNamedVispeahen = () => {
    let dataSourceName = "Vispeahen"
    let url = "/api/data/create-workspace/" + dataSourceName
    let postObj = {}

    let successFunc = (res) => {
      let data = {
        dataSourceId: res.data,
        openFileUpload: true,
        firstDataUpload: true
      }

      this.props.setVisibleDataUploadStatus(false)

      store.dispatch(setDataSourceSelfUtils(data))
    }

    let errorFunc = (err) => {
      console.log(err)
    }

    post(url, postObj, successFunc, errorFunc)
  }

  /*
  * Controls datasource list length
  */
  controlDataSourceListLength = () => {
    let url = host + api

    let successFunc = (response) => {
      let isDuckdbDataSourceLengthGreaterThanZero = response.data.filter(dataSource => dataSource.dataSourceType === "duckdb").length > 0 ? true : false

      if (isDuckdbDataSourceLengthGreaterThanZero) {
        this.props.setVisibleDataUploadStatus(true, undefined);
        this.props.changeSelectedDataSource(true);
      } else {
        this.createDefaultDuckNamedVispeahen()
      }
    }

    let errorFunc = (err) => {
      console.log(err)
    }

    get(url, successFunc, errorFunc)
  }

  /*
  * On Click Additional Data Without Data Source
  */
  onClickAdditionalDataWithoutDataSource = () => {
    this.controlDataSourceListLength()
  }

  additionalData = [
    {
      img: require("../../images/plusButton.png"),
      title: "",
      indexId: "plusButton",
      onClick: this.onClickDataSourceAdditionalData,
    },
  ];

  fileUploadPlus = [
    {
      img: require("../../images/plusButton.png"),
      title: "",
      indexId: "plusButton",
      onClick: () => this.changeFileUploadModalVisible(true),
    },
  ];

  addDatasourceWithoutDataBase = [
    {
      img: require("../../images/plusButton.png"),
      title: "",
      indexId: "plusButton",
      onClick: () => this.onClickAdditionalDataWithoutDataSource(true),
    },
  ]

  /**
   *
   * @param {*} tables
   * @param {*} selectedSource
   * @param {*} stateField
   * @param {*} stateSearchField
   * Sets tables according to incoming fields
   */
  setTables = (tables, selectedSource, stateField, stateSearchField) => {
    this.setState({
      ...this.state,
      [stateField]: tables,
      [stateSearchField]: tables,
      selectedData: selectedSource,
    });
  };

  /**
   *
   * @param {*} selectedSource
   * @param {*} tables
   * @param {*} stateField
   * @param {*} stateSearchField
   * Pulls all the tables of the selected connection
   */
  getAllTables = (selectedSource, tables, stateField, stateSearchField) => {
    let tableUrl = `${url}/${selectedSource.name}.${selectedSource.schemaName}/tables`;

    const successFunc = (response) => {
      tables = response.data;

      this.setTables(tables, selectedSource, stateField, stateSearchField);
    };

    get(tableUrl, successFunc);
  };

  /**
   *
   * @param {*} selectedSource
   * @param {*} merged
   *
   * Bringing and setting the tables of the selected connection
   */
  onLoadTableForConnection = (selectedSource, merged, showLoadingScreen = true) => {
    merged = this.state.dataSourceTabActiveKey === "1" ? true : false;

    let stateField;
    let stateSearchField;

    if (merged === true) {
      stateField = "usedTableData";
      stateSearchField = "usedSearchTempTableData";
    } else if (!selectedSource.tables) {
      stateField = "tableData";
      stateSearchField = "searchTempTableData";
    } else {
      stateField = "fileUploadTableData";
      stateSearchField = "searchTempTableData";
    }

    let tableUrl = `${url}/${selectedSource.name}.${selectedSource.schemaName}/tables`;
    let schemaNames = selectedSource.schemaName
      ? selectedSource.schemaName.split(",")
      : undefined;
    let usedStatus = false;

    if (schemaNames) {
      schemaNames.map((schema) => {
        let dataSourceKey = selectedSource.name + "." + schema.trim();

        if (usedSourcesSet.has(dataSourceKey)) {
          usedStatus = true;
        }
      });
    } else {
      if (usedSourcesSet.has(selectedSource.name)) {
        usedStatus = true;
      }
    }

    if (merged || usedStatus) {
      tableUrl = `${API_BASE}/subjectAreaMerged/${this.props.model.id}/${selectedSource.name}.${selectedSource.schemaName}/tables`;
    }

    const successFunc = (response) => {
      let data = response.data;
      let tables = clone(data);

      if (merged || usedStatus) {
        tables = [];
        data.map((table) => {
          if (table.source === "Local" || table.source === "Both") {
            table.selected = true;
          }

          if (!table.manuel) {
            let dsName = table.dataSourceKey.split(".")[0];
            let schema = table.dataSourceKey.split(".")[1];

            if (
              dsName === selectedSource.name &&
              selectedSource.schemaName.includes(schema)
            ) {
              tables.push(table);
            }
          } else {
            if (table.name === selectedSource.name) {
              tables.push(table);
            }
          }
        });
      }

      if (tables.length === 0) {
        if (selectedSource.tables) {
          selectedSource.tables[0].selected = false;
          tables = selectedSource.tables;

          this.setTables(tables, selectedSource, stateField, stateSearchField);
        } else {
          this.getAllTables(
            selectedSource,
            tables,
            stateField,
            stateSearchField
          );
        }
      } else {
        this.setTables(tables, selectedSource, stateField, stateSearchField);
      }
    };

    let errorFunc = (err) => {
      console.log(err)
    }

    get(tableUrl, successFunc, errorFunc, showLoadingScreen);
  };

  /**
   *
   * @param {*} data
   *
   * Calls related methods to edit the data source
   */
  dataSourceEdit = (data) => {
    this.props.changeSelectedDataSource(false);
    this.props.visibleDataConnections(true, data);
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value,
    });
  };

  /**
   *
   * @param {*} item
   * Returns input field for password in related operations
   */
  getPassInput = (item) => {
    return (
      <div>
        <span>{i18n.t("DataConnections.EnterYourPassword")}</span>
        <Input
          autocomplete="new-password"
          id="password"
          name={"passwordDatasource"}
          type="password"
          placeholder={i18n.t("Password")}
          value={this.state.password}
          onChange={(e) => this.handleChange(e)}
        />
      </div>
    );
  };

  /**
   *
   * @param {*} connection
   * Adds extra properties for the called location e.g. delete and edit fields
   */
  getExtra = (connection) => {
    return (
      <div>
        <Tooltip
          tooltip={i18n.t("FileUpload.FileUpload")}
          overlayClassName={"file-upload-tooltip"}
          tooltipPlacement="top"
        >
          <UploadOutlined
            name={"fileUploadToDataSource"}
            onClick={() => this.fileUploadVisibilityChange(true, connection)}
            className={"circle-icon"}
            id={"fileUploadButton"}
            style={{ cursor: "pointer" }}
          />
          &nbsp;
        </Tooltip>
        <Tooltip
          tooltip={i18n.t("DataConnections.EditSource")}
          overlayClassName={"file-upload-tooltip"}
          tooltipPlacement="top"
        >
          <EditOutlined
            name={"editDatabaseConnection"}
            onClick={() => this.dataSourceEdit(connection)}
            className={"circle-icon"}
            id={"editDatabaseButton"}
            style={{ cursor: "pointer" }}
          />
          &nbsp;
        </Tooltip>
        <Tooltip
          tooltip={i18n.t("DataConnections.DeleteSource")}
          overlayClassName={"file-upload-tooltip"}
          tooltipPlacement="top"
        >
          <Popconfirm
            id="deleteDatabaseConnection"
            style={{ weight: "25px" }}
            title={this.getPassInput(connection)}
            onConfirm={() => this.deleteConnection(connection)}
            okText={i18n.t("Yes")}
            okButtonProps={{
              disabled: this.state.password !== "" ? false : true,
              id: "deleteDatabaseConnectionAccept",
            }}
            cancelText={i18n.t("No")}
            cancelButtonProps={{ id: "deleteDatabaseConnectionCancel" }}
          >
            <DeleteOutlined
              className={"circle-icon"}
              id={"deleteDatabaseButton"}
              style={{
                marginRight: "7px",
                cursor: "pointer",
                color: "#f92f2f",
              }}
            />
          </Popconfirm>
        </Tooltip>
      </div>
    );
  };

  /**
  *
  * @param {*} connection
  * Adds extra properties for the called location e.g. delete and edit fields
  */
  getExtraForWithoutDatabase = (connection) => {
    return (
      <div>
        <Tooltip
          tooltip={i18n.t("FileUpload.FileUpload")}
          overlayClassName={"file-upload-tooltip"}
          tooltipPlacement="top"
        >
          <UploadOutlined
            name={"fileUploadToDataSource"}
            onClick={() => this.fileUploadVisibilityChange(true, connection)}
            className={"circle-icon"}
            id={"fileUploadButton"}
            style={{ cursor: "pointer" }}
          />
          &nbsp;
        </Tooltip>
        <Tooltip
          tooltip={i18n.t("DataConnections.EditSource")}
          overlayClassName={"file-upload-tooltip"}
          tooltipPlacement="top"
        >
          <EditOutlined
            name={"editDatabaseConnection"}
            onClick={() => this.props.dataSourceEditForWithoutDatabase(connection, true)}
            className={"circle-icon"}
            id={"editDatabaseButton"}
            style={{ cursor: "pointer" }}
          />
          &nbsp;
        </Tooltip>
        <Tooltip
          tooltip={i18n.t("DataConnections.DeleteSource")}
          overlayClassName={"file-upload-tooltip"}
          tooltipPlacement="top"
        >
          <Popconfirm
            id="deleteDatabaseConnection"
            style={{ weight: "25px" }}
            title={this.getPassInput(connection)}
            onConfirm={() => this.deleteConnectionForWithoutDatabase(connection)}
            okText={i18n.t("Yes")}
            okButtonProps={{
              disabled: this.state.password !== "" ? false : true,
              id: "deleteDatabaseConnectionAccept",
            }}
            cancelText={i18n.t("No")}
            cancelButtonProps={{ id: "deleteDatabaseConnectionCancel" }}
          >
            <DeleteOutlined
              className={"circle-icon"}
              id={"deleteDatabaseButton"}
              style={{
                marginRight: "7px",
                cursor: "pointer",
                color: "#f92f2f",
              }}
            />
          </Popconfirm>
        </Tooltip>
      </div>
    );
  };

  /**
   * Allows to delete the connection
   */
  deleteConnection = (data) => {
    const { password } = this.state;
    let urlWithParameters = url + "/" + data.name;

    const successFunc = (response) => {
      let connections = this.deleteItemInList(data, this.state.connections);
      let usedSources = clone(this.state.usedSources);
      let sourceName = data.name + "." + data.schemaName;

      if (usedSourcesSet.has(sourceName)) {
        usedSources = this.deleteItemInList(data, this.state.usedSources);
      }

      let viewedConnectionsForRDBMS = connections.filter(connection => connection.dataSourceType !== "duckdb")
      let viewedConnectionsForDatasource = connections.filter(connection => connection.dataSourceType === "duckdb")

      if (viewedConnectionsForRDBMS.filter(vc => vc.indexId === "plusButton").length === 0) {
        viewedConnectionsForRDBMS = this.additionalData.concat(viewedConnectionsForRDBMS)
      }

      if (viewedConnectionsForDatasource.filter(vc => vc.indexId === "plusButton").length === 0) {
        viewedConnectionsForDatasource = this.addDatasourceWithoutDataBase.concat(viewedConnectionsForDatasource)
      }

      this.setState({
        connections: connections,
        viewedConnections: connections,
        viewedConnectionsForRDBMS: viewedConnectionsForRDBMS,
        viewedConnectionsForDatasource: viewedConnectionsForDatasource,
        usedSources: usedSources,
        password: "",
      });
      showNotificationWithIcon(i18n.t("SuccessfullyDeleted"), null, "success");
    };

    const errorFunc = (error) => {
      showNotificationWithIcon(i18n.t("ErrorDeleted"), error, "error");
    };

    del(urlWithParameters, password, successFunc, errorFunc);
  };

  /**
 * Allows to delete the connection
 */
  deleteConnectionForWithoutDatabase = (data) => {
    const { password } = this.state;
    let urlWithParameters = withoutDataBaseUrl + "/delete-workspace/" + data.name;

    const successFunc = (response) => {
      let connections = this.deleteItemInList(data, this.state.connections);
      let usedSources = clone(this.state.usedSources);
      let sourceName = data.name + "." + data.schemaName;

      if (usedSourcesSet.has(sourceName)) {
        usedSources = this.deleteItemInList(data, this.state.usedSources);
      }

      let viewedConnectionsForRDBMS = connections.filter(connection => connection.dataSourceType !== "duckdb")
      let viewedConnectionsForDatasource = connections.filter(connection => connection.dataSourceType === "duckdb")

      if (viewedConnectionsForRDBMS.filter(vc => vc.indexId === "plusButton").length === 0) {
        viewedConnectionsForRDBMS = this.additionalData.concat(viewedConnectionsForRDBMS)
      }

      if (viewedConnectionsForDatasource.filter(vc => vc.indexId === "plusButton").length === 0) {
        viewedConnectionsForDatasource = this.addDatasourceWithoutDataBase.concat(viewedConnectionsForDatasource)
      }

      this.setState({
        connections: connections,
        viewedConnections: connections,
        viewedConnectionsForRDBMS: viewedConnectionsForRDBMS,
        viewedConnectionsForDatasource: viewedConnectionsForDatasource,
        usedSources: usedSources,
        password: "",
      });
      showNotificationWithIcon(i18n.t("SuccessfullyDeleted"), null, "success");
    };

    const errorFunc = (error) => {
      showNotificationWithIcon(i18n.t("ErrorDeleted"), error, "error");
    };

    del(urlWithParameters, password, successFunc, errorFunc);
  };

  /**
   *
   * @param {*} connection
   * Adds extra space for file upload tabs e.g. truncate, update
   */
  getExtraForFileUploadTab = (connection) => {
    return (
      <div>
        <Tooltip
          tooltip={i18n.t("FileUpload.FileUpload")}
          overlayClassName={"data-source-list-tooltip"}
          tooltipPlacement="top"
        >
          <UploadOutlined
            name={"updateFileButton"}
            className={"circle-icon"}
            id={"fileUploadButton"}
            onClick={() => this.changeFileUploadModalVisible(true, connection)}
            style={{
              marginRight: "7px",
              cursor: "pointer",
            }}
          />
        </Tooltip>
        <Tooltip
          tooltip={i18n.t("FileUpload.TruncateFile")}
          overlayClassName={"data-source-list-tooltip"}
        >
          <Popconfirm
            title={this.getPassInput(connection)}
            onConfirm={() => this.truncateCollectionForFile(connection)}
            okText={i18n.t("Yes")}
            cancelText={i18n.t("No")}
            okButtonProps={{
              disabled: this.state.password !== "" ? false : true,
              id: "truncateFileAccept",
            }}
            cancelButtonProps={{ id: "truncateFileCancel" }}
          >
            <DeleteRowOutlined
              name={"truncateButton"}
              className={"circle-icon"}
              style={{
                marginRight: "7px",
                cursor: "pointer",
                color: "#f92f2f",
              }}
            />
          </Popconfirm>
        </Tooltip>
        <Tooltip
          tooltip={i18n.t("FileUpload.DeleteFile")}
          overlayClassName={"data-source-list-tooltip"}
        >
          <Popconfirm
            title={this.getPassInput(connection)}
            onConfirm={() => this.dropCollectionForFile(connection)}
            okText={i18n.t("Yes")}
            okButtonProps={{
              disabled: this.state.password !== "" ? false : true,
              id: "deleteFileAccept",
            }}
            cancelText={i18n.t("No")}
            cancelButtonProps={{ id: "deleteFileCancel" }}
          >
            <DeleteOutlined
              name={"deleteFileButton"}
              className={"circle-icon"}
              style={{
                marginRight: "7px",
                cursor: "pointer",
                color: "#f92f2f",
              }}
            />
          </Popconfirm>
        </Tooltip>
      </div>
    );
  };

  deleteItemInList = (deleteItem, list) => {
    return list.filter((item) => item.name !== deleteItem.name);
  };

  /**
   *
   * @param {*} file
   * Allows to delete the file upload
   */
  deleteFileUpload = (file) => {
    const { password } = this.state;

    const successFunc = (response) => {
      let fileUploads = this.deleteItemInList(file, this.state.fileUploads);
      let usedSources = clone(this.state.usedSources);

      if (usedSourcesSet.has(file.name)) {
        usedSources = this.deleteItemInList(file, this.state.usedSources);
      }

      this.setState({
        password: "",
        fileUploads: fileUploads,
        viewedFileUploads: fileUploads,
        usedSources: usedSources,
      });
      showNotificationWithIcon(i18n.t("SuccessfullyDeleted"), null, "success");
    };

    const errorFunc = (error) => {
      showNotificationWithIcon(i18n.t("ErrorDeleted"), error, "error");
    };

    let urlWithParameters = fileUploadUrl + "/" + file.id;

    del(urlWithParameters, password, successFunc, errorFunc);
  };

  /**
   *
   * @param {*} file
   * Deletes the collection created for a deleted file.
   *
   */
  dropCollectionForFile = (file) => {
    const { password } = this.state;

    const successFunc = (response) => {
      this.deleteFileUpload(file);
    };

    const errorFunc = (error) => {
      showNotificationWithIcon(i18n.t("Error"), error, "error");
    };

    let urlWithParameters =
      "/stream/data" +
      "/" +
      file.name.substring(Cookies.get("tenant").length + 1, file.name.length);

    del(urlWithParameters, password, successFunc, errorFunc);
  };

  /**
   *
   * @param {*} file
   * Deletes the data of a deleted file
   */
  truncateCollectionForFile = (file) => {
    const { password } = this.state;

    let urlWithParameters =
      "/stream/data?sourceName=" +
      file.name.substring(Cookies.get("tenant").length + 1, file.name.length);

    const successFunc = (response) => {
      showNotificationWithIcon(
        i18n.t("OK"),
        i18n.t("DataConnections.TruncateSuccess"),
        "success"
      );
    };

    const errorFunc = (error) => {
      showNotificationWithIcon(
        i18n.t("DataConnections.TruncateFailed"),
        error,
        "error"
      );
    };

    del(urlWithParameters, password, successFunc, errorFunc);
  };

  /**
   *
   * @param {*} data
   * @param {*} merged
   *
   * Changes data etc for a different data connection selected
   */
  changeSelectedData = (data, merged = false) => {
    this.props.changeSelectedDataSource(true);

    if (Object.keys(data).length > 0) {
      this.onLoadTableForConnection(data, merged);
    }
  };

  /**
   * sets whether the table is select or unselect
   */
  tableStatusChange = (table) => {
    let selectedStatus =
      table.selected && table.selected === true ? false : true;

    if (table.selected === false) {
      usedSourcesSet.delete(table.name);
    }

    if (!table.dataSourceKey && !table.manuel) {
      table.dataSourceKey =
        this.state.selectedData.name + "." + this.state.selectedData.schemaName;
    }

    return { table: table, selectedStatus: selectedStatus };
  };

  /**
   *
   * @param {*} table
   * manually sets the columns of a table to enable deafult
   */
  setEnableStatusForManuelTableColumns = (table) => {
    if (table.columns == undefined) {
      table.columns = [];
    }

    table.columns.map((column) => {
      column.isEnable = true;
    });
  };

  //Checks join list for table
  hasJoinForTable = (table) => {
    let hasJoin = false;

    if (this.props.joins && this.props.joins.length > 0) {
      this.props.joins.every(function (join) {
        if (join.dimensionTableAlias === table.aliasName) {
          hasJoin = true;
          return false;
        }

        if (join.factTableAlias === table.aliasName) {
          hasJoin = true;
          return false;
        }

        return true;
      });
    }

    return hasJoin;
  };


  /**
   *
   * @param {*} table
   * @param {*} data
   *
   * Connection also selects a table and performs operations for the selected table.
   */
  onTableSelected = (table, data) => {
    if (table.selected === true) {
      if (this.hasJoinForTable(table) > 0) {
        this.setState({
          ...this.state,
          removeTableModelVisibility: true,
          removeTable: table,
          removeTableSource: data,
        });
      } else {
        this.selectTable(table, data);
      }
    } else {
      this.selectTable(table, data);
    }
  };

  //Close remove table modal
  closeRemoveTableModelVisibility = () => {
    this.setState({
      ...this.state,
      removeTableModelVisibility: false,
      removeTable: undefined,
      removeTableSource: undefined,
    });
  };

  //Close and continiue remove table modal
  applyRemoveTableInModel = (table, data) => {
    this.setState(
      {
        ...this.state,
        removeTableModelVisibility: false,
        removeTable: undefined,
        removeTableSource: undefined,
      },
      () => this.selectTable(table, data)
    );
  };

  //Select table operation (Add or remove)
  selectTable = (table, data) => {
    let tableStatusChange = this.tableStatusChange(table);

    if (tableStatusChange.selectedStatus === true) {
      this.setEnableStatusForManuelTableColumns(table);
    }

    this.props.selectedTables(
      tableStatusChange.table,
      tableStatusChange.selectedStatus,
      data
    );
  };

  findTableAndStatusChange = (tableList, table, status) => {
    tableList.filter((item) => {
      if (
        item.dataSourceKey + "_" + item.name ===
        table.dataSourceKey + "_" + table.name
      ) {
        item.selected = status;

        if (!status) {
          usedSourcesSet.delete(item.name);
        }

        if (!table.dataSourceKey && !table.manuel) {
          item.dataSourceKey =
            this.state.selectedData.name +
            "." +
            this.state.selectedData.schemaName;
        }
      }
    });

    return tableList;
  };

  /**
   *
   * @param {*} newTable
   * Contains the operations to be done in response after the selected table is saved on the model.
   */
  modelTableSaveResponse(newTable) {
    let status = newTable.table.status;
    let table = newTable.table.table;
    let data = newTable.table.data;
    let responseStatus = newTable.status;

    if (responseStatus) {
      let tableListUsed = this.state[data];
      let tableListRdbms = this.state.tableData;
      let tableListFileUpload = this.state.fileUploadTableData;

      tableListUsed = this.findTableAndStatusChange(
        this.state.usedTableData,
        table,
        status
      );

      let stateSearchField =
        data === "usedTableData"
          ? "usedSearchTempTableData"
          : "searchTempTableData";

      if (!table.manuel && (data == "tableData" || data == "usedTableData")) {
        tableListRdbms = this.findTableAndStatusChange(
          tableListRdbms,
          table,
          status
        );

        this.setState({
          ...this.state,
          [data]: tableListUsed,
          usedTableData: tableListUsed,
          tableData: tableListRdbms,
        });
      } else if (
        table.manuel === true &&
        (data == "fileUploadTableData" || data == "usedTableData")
      ) {
        tableListFileUpload = this.findTableAndStatusChange(
          tableListFileUpload,
          table,
          status
        );

        this.setState({
          ...this.state,
          usedTableData: tableListUsed,
          fileUploadTableData: tableListFileUpload,
        });
      }
    }
  }

  /**
   *
   * @param {*} e
   * @param {*} merged
   * Allows searching in tables in data connection
   */
  handleSearch = (e, merged = false) => {
    let searchResultValues = [];
    let stateField = merged === true ? "usedTableData" : "tableData";
    let stateSearchField =
      merged === true ? "usedSearchTempTableData" : "searchTempTableData";

    if (e.target.value && e.target.value != "") {
      searchResultValues = this.state[stateSearchField].filter(
        (item) =>
          item.aliasName.toLowerCase().includes(e.target.value.toLowerCase()) ||
          item.displayName.toLowerCase().includes(e.target.value.toLowerCase())
      );

      this.setState({
        ...this.state,
        [stateField]: searchResultValues,
      });
    } else {
      this.setState({
        ...this.state,
        [stateField]: this.state[stateSearchField],
      });
    }
  };

  /**
   *
   * @param {*} fileUploadInformation
   * Adds file upload information to fileUpload and sets state
   */
  getFileUploadInformation = (fileUploadInformation) => {
    let fileUploads = clone(this.state.fileUploads);
    fileUploadInformation.indexId = fileUploads.length;
    fileUploads.push(fileUploadInformation);

    this.setState({
      fileUploads: fileUploads,
      viewedFileUploads: fileUploads,
    });
  };

  getUsedData = (model) => {
    let tempModel = clone(model);
    let usedSources = [];

    tempModel.tables.map((table) => {
      if (table.manuel) {
        usedSourcesSet.add(table.name);
      } else {
        usedSourcesSet.add(table.dataSourceKey);
      }
    });

    this.state.connections.map((connection, index) => {
      let schemaName = connection.schemaName
        ? connection.schemaName.toLowerCase()
        : connection.schemaName;
      if (schemaName) {
        let schemaNames = schemaName.split(",");
        let isConnectionUsed = 0;

        schemaNames.map((schema) => {
          let dataSourceKey = connection.name + "." + schema.trim();

          if (usedSourcesSet.has(dataSourceKey)) {
            isConnectionUsed++;

            if (usedSources[usedSources.length - 1]) {
              connection.indexId =
                usedSources[usedSources.length - 1].indexId + 1;
            } else {
              connection.indexId = 0;
            }
          }
        });

        if (isConnectionUsed > 0) {
          usedSources.push(connection);
        }
      }
    });

    this.state.fileUploads.map((fileUpload, index) => {
      let name = fileUpload.name;
      if (usedSourcesSet.has(name)) {
        if (usedSources[usedSources.length - 1]) {
          fileUpload.indexId = usedSources[usedSources.length - 1].indexId + 1;
        } else {
          fileUpload.indexId = 0;
        }

        usedSources.push(fileUpload);
      }
    });

    return usedSources
  }

  /**
   *
   * @param {*} model
   * Model edit also performs the operations of finding datasources previously added to the model.
   */
  usedData = (model) => {
    let tempModel = clone(model);
    let usedSources = [];

    tempModel.tables.map((table) => {
      if (table.manuel) {
        usedSourcesSet.add(table.name);
      } else {
        usedSourcesSet.add(table.dataSourceKey);
      }
    });

    this.state.connections.map((connection, index) => {
      let schemaName = connection.schemaName
        ? connection.schemaName.toLowerCase()
        : connection.schemaName;
      if (schemaName) {
        let schemaNames = schemaName.split(",");
        let isConnectionUsed = 0;

        schemaNames.map((schema) => {
          let dataSourceKey = connection.name + "." + schema.trim();

          if (usedSourcesSet.has(dataSourceKey)) {
            isConnectionUsed++;

            if (usedSources[usedSources.length - 1]) {
              connection.indexId =
                usedSources[usedSources.length - 1].indexId + 1;
            } else {
              connection.indexId = 0;
            }
          }
        });

        if (isConnectionUsed > 0) {
          usedSources.push(connection);
        }
      }
    });

    this.state.fileUploads.map((fileUpload, index) => {
      let name = fileUpload.name;
      if (usedSourcesSet.has(name)) {
        if (usedSources[usedSources.length - 1]) {
          fileUpload.indexId = usedSources[usedSources.length - 1].indexId + 1;
        } else {
          fileUpload.indexId = 0;
        }

        usedSources.push(fileUpload);
      }
    });

    //Determines the active tab according to the availability of used or not.
    let dataSourceTabActiveKey = usedSources.length > 0 ? "1" : "2";
    let viewedConnections = this.state.connections
    let viewedConnectionsForRDBMS = viewedConnections.filter(connection => connection.dataSourceType !== "duckdb")
    let viewedConnectionsForDatasource = viewedConnections.filter(connection => connection.dataSourceType === "duckdb")

    if (viewedConnectionsForDatasource.filter(db => db.indexId === "plusButton").length === 0) {
      viewedConnectionsForDatasource = this.addDatasourceWithoutDataBase.concat(viewedConnectionsForDatasource)
    }

    this.setState({
      usedSources: usedSources,
      viewedConnections: viewedConnections,
      viewedConnectionsForRDBMS: viewedConnectionsForRDBMS,
      viewedConnectionsForDatasource: viewedConnectionsForDatasource,
      viewedFileUploads: this.state.fileUploads,
      dataSourceTabActiveKey: dataSourceTabActiveKey,
    })
  };

  /**
   *
   * @param {*} item
   * Includes extra field for used tab
   */
  getExtraUsed = (item) => {
    if (item.tables) {
      return this.getExtraForFileUploadTab(item);
    } else {
      return this.getExtra(item);
    }
  };

  /**
   *
   * @param {*} results
   * @param {*} fileUploadRefCurrent
   * It is triggered when csv is dragged to the file upload tab and performs the related actions
   */
  handleReadCSV = (results, fileUploadRefCurrent) => {
    this.setState({
      handleCsvResult: results,
      fileUploadRef: { ...fileUploadRefCurrent },
    });

    this.changeFileUploadModalVisible(true);
  };

  /**
   *
   * @param {*} key
   * Change active tab
   */
  changeActiveTab = (key) => {
    this.setState({
      ...this.state,
      dataSourceTabActiveKey: key,
    }, () => {
      this.changeSelectedData({}, false)
    });
  };

  /*
  * Changes file upload visibility
  */ 
  fileUploadVisibilityChange = (status, dataSource = undefined) => {
    let reduxState = store.getState()
    let comesFromDashboardFileUpload = reduxState.SelfServiceReducer.comesFromDashboardFileUpload

    this.setState({
      ...this.state,
      fileUploadVisibility: status,
      fileUploadDataSource: dataSource
        ? {
          dataSourceType: dataSource.dataSourceType,
          schemaName: dataSource.schemaName,
          id: dataSource.id,
          name: dataSource.name,
          title: dataSource.title,
          displayName: dataSource.displayName,
        }
        : undefined,
      comesFromDashboardFileUpload: comesFromDashboardFileUpload
    });

    store.dispatch(setMinimize(false));
  };

  /*
  * Change your uploaded datasources
  */
  changeFileUploadDataSource = (dataSourceId) => {
    let selectedDataSource = this.props.selfService.dataSources.find(dataSource => dataSource.id === dataSourceId)

    if (selectedDataSource) {
      this.setState({
        ...this.state,
        fileUploadDataSource: selectedDataSource
      })

      store.dispatch(setDataSourceId(selectedDataSource.id))
    }
  }

  /**
   * When file upload operation success, refresh data source tables.
   * @param {*} status 
   */
  onFileUploadSucces = (status) => {
    if (status === "DONE") {
      let merged = this.state.dataSourceTabActiveKey === "1" ? true : false;

      this.onLoadTableForConnection(this.state.selectedData, merged, false);
    }
  }

  /*
  * Updates data source list without database (Self Service Operation)
  */
  updateDataSourceListForWithoutDataBase = (updatedId, updateName) => {
    let copiedConnections = [...this.state.viewedConnectionsForDatasource]
    let updatedDS = copiedConnections.find(ds => ds.id === updatedId)

    updatedDS["displayName"] = updateName

    if (copiedConnections.filter(db => db.indexId === "plusButton").length === 0) {
      copiedConnections = this.addDatasourceWithoutDataBase.concat(copiedConnections)
    }

    this.setState({
      ...this.state,
      viewedConnectionsForDatasource: copiedConnections
    })
  }

  render() {
    return (
      <div style={{ display: "content" }}>
        <Text
          type="span"
          style={{
            fontSize: "14px",
            display: "block",
            marginBottom: "25px",
            color: "#afafaf",
            textAlign: "center",
            whiteSpace: "normal",
            paddingLeft: "31px",
            paddingRight: "27px",
          }}
        >
          {i18n.t("DataConnections.AddTableOrSelectConnection")}
        </Text>

        <Tabs
          defaultActiveKey="1"
          size={"small"}
          className={"data-source-tab"}
          activeKey={this.state.dataSourceTabActiveKey}
          onChange={this.changeActiveTab}
        >
          {this.state.usedSources.length > 0 ? (
            <TabPane tab={i18n.t("DataConnections.Used")} key="1">
              <UsedDataSources
                changeSelectedData={this.changeSelectedData}
                usedSources={this.state.usedSources}
                getExtraUsed={this.getExtraUsed}
                handleSearch={this.handleSearch}
                selectedSource={this.state.selectedData}
                usedTableData={this.state.usedTableData}
                onTableSelected={this.onTableSelected}
                activeDataSourceKey={this.state.dataSourceTabActiveKey}
                updateDataSourceListForWithoutDataBase={this.updateDataSourceListForWithoutDataBase}
                dataSourceEditForWithoutDatabase={this.props.dataSourceEditForWithoutDatabase}
              ></UsedDataSources>
            </TabPane>
          ) : null}
          {/* {this.state.fileUploads.length > 0 ? (
            <TabPane tab={i18n.t("DataConnections.FileUpload")} key="2">
              <FileUploadList
                changeSelectedData={this.changeSelectedData}
                viewedFileUploads={this.state.viewedFileUploads}
                getExtraForFileUploadTab={this.getExtraForFileUploadTab}
                fileUploadTableData={this.state.fileUploadTableData}
                onTableSelected={this.onTableSelected}
                handleReadCSV={this.handleReadCSV}
              ></FileUploadList>
            </TabPane>
          ) : null} */}
          {this.state.connections.length > 0 ? (
            <TabPane tab="RDBMS / NoSQL" key="2">
              <DataSourceConnectionList
                changeSelectedData={this.changeSelectedData}
                viewedConnections={this.state.viewedConnectionsForRDBMS}
                getExtra={this.getExtra}
                handleSearch={this.handleSearch}
                tableData={this.state.tableData}
                selectedSource={this.state.selectedData}
                onTableSelected={this.onTableSelected}
                activeDataSourceKey={this.state.dataSourceTabActiveKey}
              ></DataSourceConnectionList>
            </TabPane>
          ) : null}
          <TabPane tab={i18n.t("SelfService.Collection")} key="3">
            <WithoutDataSourceConnectionList
              changeSelectedData={this.changeSelectedData}
              viewedConnections={this.state.viewedConnectionsForDatasource}
              getExtra={this.getExtraForWithoutDatabase}
              handleSearch={this.handleSearch}
              tableData={this.state.tableData}
              selectedSource={this.state.selectedData}
              onTableSelected={this.onTableSelected}
              activeDataSourceKey={this.state.dataSourceTabActiveKey}
              editedSelfService={this.props.editedSelfService}
            />
          </TabPane>
        </Tabs>

        <FileUploadContent
          fileUploadVisibility={this.state.fileUploadVisibility}
          dataSource={this.state.fileUploadDataSource}
          fileUploadVisibilityChange={this.fileUploadVisibilityChange}
          onFileUploadSucces={this.onFileUploadSucces}
          changeFileUploadDataSource={this.changeFileUploadDataSource}
          comesFromDashboardFileUpload={this.state.comesFromDashboardFileUpload}
          model={this.props.model}
        />

        <RemoveTableModal
          visible={this.state.removeTableModelVisibility}
          table={this.state.removeTable}
          source={this.state.removeTableSource}
          onCancel={this.closeRemoveTableModelVisibility}
          onOk={this.applyRemoveTableInModel}
        />

      </div>
    );
  }
}

const mapDispatchToProps = {
  setDataSourceSelfUtils,
};

const mapStateToProps = (state) => {
  return {
    selfService: state.SelfServiceReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataSourceList);
