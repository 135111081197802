import Cookies from "js-cookie";

//Looks language and decide seperator for number inputs
export const decideSeparator = () => {
  let separator = ".";
  let isTR = Cookies.get("i18next");

  if (isTR) {
    separator = ",";
  }

  return separator;
};
