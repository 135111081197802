import { Alert, Button, DatePicker, Input, Select, Tooltip } from "antd";
import React, { Component } from "react";
import Text from "../../../GeneralComponents/Text/Text";
import i18n from "../../../../Utils/i18next";
import { FilterOutlined, LinkOutlined } from "@ant-design/icons";
import { updateParameterValue } from "./parameterControl"

export default class ParameterSettings extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            contentOnTop: false
        }
    }

    /**
 * Parameters content area on scroll handler
 * 
 * @param {*} event 
 */
    handleContentOnScroll = (event) => {
        if (event.target.id === "parametersContent") {
            const { scrollTop, scrollHeight, clientHeight } = event.target;

            this.setState({
                ...this.state,
                contentOnTop: scrollTop > 0,
                contentOnBottom: scrollHeight - clientHeight - scrollTop <= 0
            });
        }
    };

    render() {
        let maxSelectTagCount = this.props.maxSelectTagCount();

        return (
            <>
                <div
                    className={this.state.contentOnTop ? "header-shadow" : null}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: 48,
                        padding: "8px"
                    }}
                >
                    <Text
                        className="ant-modal-title"
                        style={{ color: "rgba(0, 0, 0, 0.65)" }}
                    >
                        {i18n.t("Designer.Parameters")}
                    </Text>
                    {
                        this.props.dynamicParameters && this.props.dynamicParameters.length > 0 ? (
                            <Button
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}
                                loading={this.props.refreshAllLoading}
                                onClick={() => this.props.refreshAll()}
                            >
                                <span style={{
                                    marginLeft: this.props.refreshAllLoading ? "8px" : 0
                                }}>
                                    {i18n.t("Designer.RefreshParameters")}
                                </span>
                            </Button>
                        ) : null
                    }
                </div>
                <div
                    id="parametersContent"
                    className="parametersContent"
                    onScroll={(event) => this.handleContentOnScroll(event)}
                    style={{
                        maxHeight: `calc(100% - ${this.props.isReportSpreadSheet ? 246 : 189}px)`,
                        minHeight: "100px",
                        overflowY: "auto",
                        padding: "0 8px 8px"
                    }}
                >
                    {
                        this.props.parameters.length > 0 ? this.props.parameters.map((parameter) => {
                            let parameterDependencies = null;
                            let parameterDependentWarning = null;
                            let parameterValues = this.props.parametersTemp[parameter.name];

                            if (this.props.dependentParameters.has(parameter.name)) {
                                parameterDependencies = this.props.dependentParameters.get(parameter.name);

                                parameterDependentWarning = i18n.t(
                                    parameterDependencies.length > 1
                                        ? "Designer.PleaseCheckThatTheseParametersHaveValue"
                                        : "Designer.PleaseCheckThatThisParameterHasValue"
                                );

                                parameterDependentWarning = parameterDependentWarning
                                    .replace("{parameter}", parameter.name)
                                    .replace("{dependencies}", parameterDependencies.join(", "))
                            }

                            let parameterOnDependentWarning = parameterDependencies && !parameterValues;
                            let parameterError = this.props.errors[parameter.id];

                            let inputPlaceHolder = "";
                            
                            if (parameter.input === "free") {
                                inputPlaceHolder = parameter.type === "date" ? "YYYY-MM-DD" : i18n.t("Designer.EnterValue");
                            } else if (parameter.input === "list") {
                                if (this.props.loading[parameter.id]) {
                                    inputPlaceHolder = i18n.t("Loading");
                                } else if (parameterError) {
                                    inputPlaceHolder = i18n.t("NoDataContent.CouldNotFetch");
                                } else if (!parameterOnDependentWarning && !parameter.values?.length > 0) {
                                    inputPlaceHolder = i18n.t("NoDataContent.NoData");
                                } else {
                                    inputPlaceHolder = `${i18n.t("Designer.SelectValue")} (${i18n.t(`Designer.${parameter.isMultiple ? "Multiple" : "Single"}`)})`;
                                }
                            }

                            return (
                                <>
                                    <Text
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginTop: "16px",
                                            marginBottom: "8px",
                                            color: parameterError ? "red" : parameterOnDependentWarning ? "#ff9800" : null
                                        }}
                                    >
                                        {
                                            parameterDependencies ? (
                                                <Tooltip
                                                    title={parameterDependencies.join(", ")}
                                                >
                                                    <LinkOutlined style={{ marginRight: "8px" }} />
                                                </Tooltip>
                                            ) : null
                                        }
                                        {parameter.name}
                                    </Text>
                                    {
                                        parameter.input === "free" ? (
                                            parameter.type === "date" ? (
                                                <DatePicker
                                                    format="YYYY-MM-DD"
                                                    placeholder={inputPlaceHolder}
                                                    onChange={(data, dateString) => this.props.setParameterValue(parameter, dateString)}
                                                    style={{ width: "100%" }}
                                                />
                                            ) : (
                                                <Input
                                                    value={parameterValues.value}
                                                    type="text"
                                                    autocomplete="off"
                                                    placeholder={inputPlaceHolder}
                                                    style={{ width: "100%" }}
                                                    onChange={(event) => this.props.setParameterValue(parameter, event.target.value)}
                                                />
                                            )
                                        ) : (
                                            <>
                                                <Input.Group
                                                    compact
                                                    style={{
                                                        marginBottom: "8px",
                                                        width: "100%"
                                                    }}
                                                >
                                                    <Select
                                                        mode={parameter.isMultiple ? "multiple" : "default"}
                                                        showArrow
                                                        showSearch
                                                        maxTagCount={maxSelectTagCount}
                                                        maxTagTextLength={15}
                                                        placeholder={inputPlaceHolder}
                                                        optionFilterProp="label"
                                                        optionLabelProp="label"
                                                        value={Array.isArray(parameterValues) ? parameterValues.map(row => JSON.stringify(row)) : JSON.stringify(parameterValues)}
                                                        onSelect={(value) => this.props.selectParameterValue(parameter, value, parameter.isMultiple, parameter.values)}
                                                        onDeselect={(value) => this.props.deselectParameterValue(parameter, value, parameter.isMultiple, parameter.values)}
                                                        onDropdownVisibleChange={open => this.props.onSelectDropdownVisibleChange(parameter, open)}
                                                        disabled={!parameterValues || this.props.loading[parameter.id] || !parameter.values?.length === 0}
                                                        style={{
                                                            width: parameter.valueType === "dynamic" ? "calc(100% - 48px)" : "100%",
                                                        }}
                                                        getPopupContainer={(trigger) => trigger.parentNode}
                                                    >
                                                        {
                                                            parameterValues && parameter.isMultiple ? (
                                                                parameterValues.length < parameter.values.length ? (
                                                                    <Select.Option value={"all"}>{`${i18n.t("SelectAll")}`}</Select.Option>
                                                                ) : (
                                                                    <Select.Option value={"none"}>{`${i18n.t("DeselectAll")}`}</Select.Option>
                                                                )
                                                            ) : null
                                                        }
                                                        {
                                                            parameterValues ? parameter.values.map((row) => {
                                                                row = updateParameterValue(row);

                                                                return (
                                                                    <Select.Option label={row.label} value={JSON.stringify(row)}>{row.label}</Select.Option>
                                                                )

                                                            }) : null
                                                        }
                                                    </Select>
                                                    {
                                                        parameter.valueType === "dynamic" ? (
                                                            <Button
                                                                icon="reload"
                                                                loading={this.props.loading[parameter.id]}
                                                                onClick={() => this.props.refreshParameter(parameter)}
                                                                style={{
                                                                    position: "absolute",
                                                                    height: "100%",
                                                                    width: "48px",
                                                                    display: "inline-flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center"
                                                                }}
                                                            >
                                                            </Button>
                                                        ) : null
                                                    }
                                                </Input.Group>
                                                {
                                                    parameterOnDependentWarning ? (
                                                        <Alert
                                                            className="parameter-warning"
                                                            banner={true}
                                                            showIcon={false}
                                                            message={parameterDependentWarning}
                                                            type="warning"
                                                        />
                                                    ) : null
                                                }
                                                {
                                                    parameterError ? (
                                                        <Alert
                                                            className="parameter-error"
                                                            banner={true}
                                                            showIcon={false}
                                                            message={parameterError}
                                                            type="error"
                                                        />
                                                    ) : null
                                                }
                                            </>
                                        )
                                    }

                                </>
                            );
                        }
                        ) : (
                            <Text
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    height: "150px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#afafaf"
                                }}
                            >
                                <FilterOutlined style={{ fontSize: "48px" }} />
                                <span style={{ fontSize: "14px" }}>{i18n.t("Designer.YouDontHaveAnyParameter")}</span>
                            </Text>
                        )
                    }
                </div>
            </>
        );
    }
}