
export const configuration = {
    bubbleLow: 3,
    bubbleHigh: 20,
    stroke: 0,
    opacity: 0.75,
    styleType: "Series Picker",
    seriesScale: "Individual",
    legend: true,
    showHideButton: false,
    legendLocation: "down",
    voronoi: true,
    mapTile: "Default",
    mapTileSelection: "Open Street Map Tile",
};

export const actions = [
    {
        trigger: "clickBubble",
        type: "click",
        name: "Tıklama Bubble",
        output: ["desc"],
        description: "ClickBubbleDesc"
    },
    {
        trigger: "hoverBubble",
        type: "hover",
        name: "Hover Bubble",
        output: ["desc"],
        description: "HoverBubbleDesc"
    },
    {
        trigger: "freehandSelect",
        type: "selection",
        name: "Freehand Select",
        output: ["desc"],
        description: "FreehandSelectDesc"
    }
];

export const reactions = [
    {
        id: "filter",
        name: "Filtre",
        description: "desc87",
        type: "general"
    }
];

export const conversionMap = {
    "table": {
        columnMap: {
            columns: {
                name: "Columns",
                from: ["desc", "lng", "lat", "measure", "vary"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        }
    }
};
