import React, { Component } from "react";
import { Row, Col, Popover, Popconfirm, Input } from "antd";
import "./model.css";
import { EditOutlined } from "@ant-design/icons";
import Tooltip from "../GeneralComponents/Tooltip/Tooltip";
import Button from "../GeneralComponents/Button/Button";
import TablesPanel from "./TablesPanel";
import Search from "../GeneralComponents/Search/Search";
import { showError, showNotificationWithIcon } from "../../Utils/Notification";
import { post, del, get } from "../../Utils/WebService";
import { API_BASE } from "../../config";
import i18n from "../../Utils/i18next";
import { deepCopy } from "../../Utils/Global";
import UpdateTableAliasName from "./UpdateTableAliasName";
import _ from "lodash";
import NewColumnEditor from "../GeneralComponents/NewColumn/NewColumnEditor";
import { getSelectedModel, removeSelectedModel, setSelectedModel } from "./ModelStore";

var duplicatedColumnHashMap;
var duplicatedTableHashMap;
var hasValidationError = false;

/**
 * Model operations are done on this component, the table component i and the join model are called.
 */
export default class ModelCollapseView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableAccordionActiveKeyControl: false,
      model: this.props.model ? this.props.model : {},
      tableSearchData: this.props.model ? this.props.model.tables : [],
      joinModalVisible: false,
      renamePopupVisible: false,
      hasValidationError: false,
      hasDeselectTable: {},
      searchModelValue: "",
    };
  }

  /**
   *
   * @param {*} table
   * @param {*} newTable
   * Are tables derived from each other, ie duplicated
   */
  isDuplicatedTable = (table, newTable) => {
    return (
      table.name === newTable.name &&
      table.dataSourceKey === newTable.dataSourceKey &&
      table.displayName !== newTable.displayName
    );
  };

  /**
   *
   * @param {*} model
   * @param {*} newTable
   * Adds to the model as duplicate or normally by checking whether the new table added is found in the model.
   */
  addTableInModel = (model, newTable) => {
    model.tables.map((table) => {

      if (this.isDuplicatedTable(table, newTable)) {
        table.duplicated = true;
        newTable.duplicated = true;
      }
    });

    if (this.props.join) {
      for (let join of this.props.join) {
        let tableDataSourceKeyWithAlias = newTable.dataSourceKey + "?" + newTable.aliasName

        let factTableDataSourceKeyWithAlias = join.factTableDataSourceKey + "?" + join.factTableAlias;
        let dimTableDataSourceKeyWithAlias = join.dimensionTableDataSourceKey + "?" + join.dimensionTableAlias;

        if (factTableDataSourceKeyWithAlias === tableDataSourceKeyWithAlias) {
          newTable.tableType = "Fact";
        } else if (dimTableDataSourceKeyWithAlias === tableDataSourceKeyWithAlias) {
          newTable.tableType = "Dimension";
        } else {
          newTable.tableType = "None";
        }

      }
    }

    model.tables.push(newTable);
  };

  /** To set columns of a table selected or not */
  setColumnsSelected = (columns, isManuelTable) => {
    let columnsNameHashSet = new Set();
    columns.map((column) => {
      if (
        column.source &&
        (column.source == "Local" || column.source == "Both")
      ) {
        columnsNameHashSet.add(column.name);
        column.selected = true;
        column.isEnable = isManuelTable === true;
      } else {
        column.selected = false;
        column.isEnable = !(
          isManuelTable === undefined || isManuelTable === true
        );
      }
    });

    return { columns: columns, columnsNameHashSet: columnsNameHashSet };
  };

  /** Url to get columns of a table */
  getMergedColumnUrl = (tableAliasName, tableName, dataSourceKey) => {
    const name = tableAliasName ? tableAliasName : tableName;
    const modelId = this.props.model.id;

    return `${API_BASE}/subjectAreaMerged/${modelId}/tables/${dataSourceKey}/${name}/columns`;
  };

  /** When a table is added to model, get table's column. */
  addTableColumns = (table, addTableSuccessFunction) => {
    let url = this.getMergedColumnUrl(
      table.aliasName,
      table.name,
      table.dataSourceKey
    );

    const successFunc = (response) => {
      let isManuelTable = table.manuel;
      let columnsInformation = this.setColumnsSelected(
        response.data,
        isManuelTable
      );
      table.columns = columnsInformation.columns;
      table.columnsNameHashSet = columnsInformation.columnsNameHashSet;

      addTableSuccessFunction(table);
    };

    get(url, successFunc);
  };

  /**
   *
   * @param {*} nextProps
   * @param {*} table
   * @returns
   * Checks if the new incoming table is the same as the existing one
   */
  isSameTable = (nextProps, table) => {
    return (
      table.dataSourceKey + "_" + table.name ===
      nextProps.newTableInModel.table.dataSourceKey +
      "_" +
      nextProps.newTableInModel.table.name
    );
  };

  /**
   * Set alias name not unique objects
   * @param {*} object
   * @object.status
   * @object.tableAlias
   * @object.table
   */
  updateAliasNameNotUnique = (object) => {
    let status = object.status;
    let tableAlias = object.tableAlias;
    let table = object.table;

    this.setState({
      ...this.state,
      aliasNameNotUnique: {
        status: status,
        tableAlias: tableAlias,
        table: deepCopy(table),
      },
    });
  };

  /**
   * Checks alias name is unique and update states
   * @param {*} model 
   * @param {*} table 
   * @param {*} tableAlias 
   * @returns 
   */
  checkAliasName = (model, table, tableAlias) => {
    let tableAliasHashSet = new Set();

    model.tables.map((t) => {
      tableAliasHashSet.add(t.aliasName);
    });

    if (tableAliasHashSet.has(tableAlias)) {
      this.updateAliasNameNotUnique({
        status: true,
        table: table,
        tableAlias: tableAlias,
      });

      return { status: false };
    }

    this.updateAliasNameNotUnique({
      status: false,
      table: null,
      tableAlias: "",
    });

    let newTable = deepCopy(table);

    newTable.aliasName = tableAlias;
    newTable.displayName = tableAlias;

    return { status: true, table: newTable };
  };

  /**
   * Validates table for model save
   * @param {*} aliasNameStatusWithNewTable 
   * @param {*} propsNewTableInModel 
   * @param {*} model 
   */
  addTableAndSaveModelIfValid = (aliasNameStatusWithNewTable, propsNewTableInModel, model) => {
    if (aliasNameStatusWithNewTable.status === true) {
      let newTableInModel = deepCopy(propsNewTableInModel);
      newTableInModel.table = aliasNameStatusWithNewTable.table;
      newTableInModel.status = true;

      this.addTableInModel(model, newTableInModel.table);
      this.modelSave(model, false, newTableInModel);
    }
  }

  /**
   *
   * @param {*} nextProps
   * It includes the operations performed in case of adding or removing tables to the model
   *
   */
  tableAddOrRemoveInModel = (nextProps) => {
    let model = deepCopy(this.state.model);

    if (nextProps.newTableInModel.status) {
      let aliasNameStatusWithNewTable = this.checkAliasName(
        model,
        nextProps.newTableInModel.table,
        nextProps.newTableInModel.table.aliasName
      );

      this.addTableAndSaveModelIfValid(aliasNameStatusWithNewTable, nextProps.newTableInModel, model)
    } else {
      let indexOfTable = -1;
      model.tables.map((table, index) => {
        if (this.isSameTable(nextProps, table)) {
          indexOfTable = index;
        }
      });

      if (indexOfTable !== -1) {
        model.tables.splice(indexOfTable, 1);
      }

      this.modelSave(model, false, nextProps.newTableInModel);
      this.props.updateSelectedModel(model.tables);      
    }
  };

  /**
   * If the model has changed, it updates the model variable in the state.
   */
  componentWillReceiveProps(nextProps) {
    let newState = { ...this.state };
    let isStateChanged = false;

    if (!_.isEqual(nextProps.model.tables, this.props.model.tables)) {
      newState.model = deepCopy(nextProps.model);
      newState.tableSearchData = this.filterModelTables(nextProps.model.tables);

      isStateChanged = true;
    }

    if (isStateChanged) {
      this.setState(newState);
    }

    if (this.props.newTableInModel != nextProps.newTableInModel) {
      this.tableAddOrRemoveInModel(nextProps);
    }
  }

  /*
   * Filters table by search value
   */
  filteredTable = (tables, searchValue) => {
    let filteredTables = tables.filter(
      (item) =>
        item.displayName.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.displayName.toLowerCase().includes(searchValue.toLowerCase())
    );

    return filteredTables;
  };

  /*
   * Sets model view by filter value
   */
  filterModelTables = (table) => {
    let tables = deepCopy(table);

    if (this.state.searchModelValue && this.state.searchModelValue != "") {
      tables = this.filteredTable(tables, this.state.searchModelValue);
    }

    return tables;
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value,
    });
  };

  /**
   * Change the model display name
   */
  handleChangeModelRename = (e) => {
    let model = deepCopy(this.state.model);

    model.displayName = e.target.value;

    this.setState({
      ...this.state,
      model: model,
    });
  };

  /**
   * Visible status to join modal
   */
  joinModalVisibleChange = (status) => {
    this.setState({
      joinModalVisible: status,
    });
  };

  /**
   * Deletes the selected model and sends it to the upper component to remove it from the modelList.
   */
  deleteModel = (model) => {
    const successFunc = (response) => {
      this.props.deleteModelInList(model);
      
      // Remove selectedmodel cookie if it is deleted
      if (getSelectedModel() == model.id)
        removeSelectedModel()
      
      showNotificationWithIcon(i18n.t("SuccessfullyDeleted"), null, "success");
    };

    const errorFunc = (error) => {
      showNotificationWithIcon(i18n.t("ErrorDeleted"), error, "error");
    };

    let urlWithParameters = API_BASE + "/subjectArea/deleteByName";

    del(urlWithParameters, model.name, successFunc, errorFunc);
  };

  /**
   *
   * @param {*} tables
   * Removes unselected columns from the table list
   */
  deleteUnselectColumns = (tables) => {
    tables.map((table) => {
      let columns = [];
      let hasSelectedColumns = false;
      duplicatedColumnHashMap = new Map();

      table.columns.map((column) => {
        if (
          (column.selected && column.selected === true)
        ) {
          hasSelectedColumns = true;
          if (column.duplicated === true) {
            this.setDuplicatedHashMap(
              column,
              columns.length,
              duplicatedColumnHashMap
            );
          }

          columns.push(column);
        } else if (
          (column.selected !== undefined && column.selected === false)
        ) {
          hasSelectedColumns = true;
        }
      });

      this.setIsDuplicatedFalse(columns, duplicatedColumnHashMap);

      if (columns.length > 0) {
        table.columns = columns;
      } else if (hasSelectedColumns) {
        hasValidationError = true;
      }
    });

    return tables;
  };

  /**
   *
   * @param {*} itemList
   * @param {*} duplicatedHashMap
   * sets the duplicate field to false according to the items in the incoming hashmap and the incoming list
   */
  setIsDuplicatedFalse = (itemList, duplicatedHashMap) => {
    if (duplicatedHashMap.size > 0 && itemList.length > 0) {
      for (const entry of duplicatedHashMap.entries()) {
        let value = entry[1];
        let name = entry[0];

        if (value.count === 1) {
          if (itemList[value.index[0]].name === name) {
            itemList[value.index[0]].duplicated = false;
          }
        }
      }
    }
  };

  /**
   *
   * @param {*} item
   * @param {*} index
   * @param {*} duplicatedHashMap
   * Find the column or table given as duplicate but remain odd and add it to hashmap
   */
  setDuplicatedHashMap = (item, index, duplicatedHashMap) => {
    if (duplicatedHashMap.has(item.name)) {
      let hasItem = duplicatedHashMap.get(item.name);

      hasItem.index.push(index);
      hasItem.count = hasItem.count + 1;

      duplicatedHashMap.set(hasItem.name, {
        index: hasItem.index,
        count: hasItem.count,
      });
    } else {
      duplicatedHashMap.set(item.name, { index: [index], count: 1 });
    }
  };

  /**
   * Converts the model to be saved into an object suitable for the save service
   */
  createObjectForModelSave = (model) => {
    let justTable = [];
    let tableWithColumn = [];
    let deletedList = [];
    duplicatedTableHashMap = new Map();

    for (let index in model.tables) {
      let table = model.tables[index];

      if (table.duplicated === true) {
        this.setDuplicatedHashMap(table, index, duplicatedTableHashMap);
      }
    }

    this.setIsDuplicatedFalse(model.tables, duplicatedTableHashMap);

    for (let index in model.tables) {
      let table = model.tables[index];

      if (table.columns && table.columns.length > 0) {
        tableWithColumn.push(table);
      } else {
        justTable.push(table);
      }
    }

    hasValidationError = false;
    tableWithColumn = this.deleteUnselectColumns(tableWithColumn);

    let modelObject = {
      id: model.id,
      name: model.name,
      displayName: model.displayName,
      deletedList: deletedList,
      tableList: justTable,
      tableWithColumnList: tableWithColumn,
    };

    let modelInformation = {
      modelToSave: modelObject,
      model: model,
    };

    return modelInformation;
  };

  /**
   * Closes the popup with the enter key of the rename popup and updates the model list in the parent component
   */
  handlePopoverConfirm = (e) => {
    this.props.updateModelList(this.state.model);

    this.setState({
      renamePopupVisible: false,
    });
  };

  /**
   * Searches by display names in the model list
   */
  handleSearch = (e) => {
    let tables = deepCopy(this.state.model.tables);
    let tableAccordionActiveKeyControl = false;

    if (e.target.value && e.target.value != "") {
      tableAccordionActiveKeyControl = true;
      tables = this.filteredTable(tables, e.target.value);
    } else {
      tableAccordionActiveKeyControl = false;
    }

    this.setState({
      ...this.state,
      tableSearchData: tables,
      tableAccordionActiveKeyControl: tableAccordionActiveKeyControl,
      searchModelValue: e.target.value,
    });
  };

  /**
   * Input for model rename
   */
  getPopoverContent = () => {
    return (
      <div>
        <Input
          id="rename"
          placeholder={"Rename"}
          value={this.state.model.displayName}
          onChange={this.handleChangeModelRename}
          onPressEnter={this.handlePopoverConfirm}
        />
      </div>
    );
  };

  /**
   * When the save button is clicked, it saves the selected model with changes such as aggRule whereClause etc.
   */
  modelSave = (model, hasNewDuplicatedTable = false, newTableInModel = {}) => {
    let modelInformation = this.createObjectForModelSave(deepCopy(model));

    const successFunc = (response) => {
      if (Object.keys(newTableInModel).length > 0) {
        let THIS = this;

        const addTableColumnsSuccessFunction = () => {
          for (let i = modelInformation.model.tables.length - 1; i >= 0; i--) {
            if (
              modelInformation.model.tables[i].dataSourceKey +
              "_" +
              modelInformation.model.tables[i].aliasName ===
              newTableInModel.table.dataSourceKey +
              "_" +
              newTableInModel.table.aliasName
            ) {
              modelInformation.model.tables[i] = { ...newTableInModel.table };
              break;
            }
          }

          THIS.props.modelTableAddOrDeleteResponse(
            modelInformation.model,
            response,
            newTableInModel,
            {}
          );
        };

        if (newTableInModel.status && model.id) {
          this.addTableColumns(
            newTableInModel.table,
            addTableColumnsSuccessFunction
          );
        } else {
          THIS.props.modelTableAddOrDeleteResponse(
            modelInformation.model,
            response,
            newTableInModel,
            {}
          );
        }

        if (!newTableInModel.status) {
          this.setState({
            hasDeselectTable: newTableInModel,
          });
        }
      } else if (hasNewDuplicatedTable === false) {
        showNotificationWithIcon(
          i18n.t("Model.ModelAddSuccessfull"),
          null,
          "success"
        );
      } else {
        showNotificationWithIcon(
          i18n.t("Model.NewDuplicatedTableSuccessMessage"),
          null,
          "success"
        );
      }
      // new model added and model id is empty, model list must be updated
      if (!model.id){
         let newModelId = response.data.id
         
         this.props.getModelList(newModelId);
         setSelectedModel(newModelId)
      }
    };

    const errorFunc = (error) => {
      if (Object.keys(newTableInModel).length > 0) {
        this.props.modelTableAddOrDeleteResponse(
          model,
          {},
          newTableInModel,
          error
        );
      } else {
        showError(error);
      }
    };

    if (!hasValidationError) {
      let url = `${API_BASE}/subjectAreaMerged`;
      post(url, modelInformation.modelToSave, successFunc, errorFunc);
    } else {
      showError(i18n.t("Model.UnselectAllColumnInTable"));
    }
  };

  /**
   * Popup visible change to change model display name
   */
  handleRenamePopupVisibleChange = (status) => {
    this.setState({
      renamePopupVisible: status,
    });
  };

  /**
   * After adding aggRule, whereClause, duplicated for column or table, it updates the model in the parent component.
   */
  updateModel = (tables, hasNewDuplicatedTable = false) => {
    if (hasNewDuplicatedTable == true) {
      let model = deepCopy(this.state.model);
      model.tables = tables;
      this.modelSave(model, true);
    }

    this.props.updateSelectedModel(tables);
  };

  /**
   *
   * @param {*} table
   * Updates the changes made on the table in the tables of the model.
   */
  updateTables = (table) => {
    let model = { ...this.state.model };

    for (let i = 0; i < model.tables.length; i++) {
      if (
        model.tables[i].dataSourceKey + "_" + model.tables[i].aliasName ===
        table.dataSourceKey + "_" + table.aliasName
      ) {
        model.tables[i] = table;
      }
    }

    this.setState({
      ...this.state,
      model: model,
    });

    this.props.updateSelectedModel(model.tables);
  };

  render() {
    const isRender =
      this.props.createModel === false && this.props.modelListEmpty == false;

    return isRender ? (
      <>
        <div>
          <div
            style={{
              position: "relative",
            }}
          >
            <Row gutter={6}>
              <Col span={22}>
                {this.state.model.tables ? (
                  <Search
                    paddingNone={true}
                    id={"searchData"}
                    clearCondition={this.state.model.id}
                    width="100%"
                    marginBottom="20px"
                    searchBarVisible={true}
                    onChange={this.handleSearch}
                  ></Search>
                ) : null}
              </Col>
              {this.state.model.tables && this.state.model.tables.length >= 0 ? (
                /**
                 * If there is a selected model, the model operations bar opens.
                 */
                <Col span={2}>
                  <div className="modelcollapse-edit">
                    <Tooltip
                      tooltip={i18n.t("Edit")}
                      tooltipPlacement={"bottom"}
                      overlayClassName={"edit-button-tooltip"}
                    >
                      <EditOutlined
                        id="modelEditButton"
                        onClick={() => this.props.editModel(this.state.model)}
                        className={"circle-icon"}
                      />
                    </Tooltip>
                  </div>
                </Col>
              ) : null}
            </Row>
          </div>
          <div>
            <Row style={{ marginBottom: "40px" }}>
              <TablesPanel
                updateModel={this.updateModel}
                updateTables={this.updateTables}
                modelId={this.state.model.id}
                model={this.props.model}
                searchModelValue={this.state.searchModelValue}
                showTables={this.state.tableSearchData}
                allTables={this.state.model.tables}
                joins={this.props.join}
                deleteTableInModel={this.props.deleteTableInModel}
                tableAccordionActiveKeyControl={
                  this.state.tableAccordionActiveKeyControl
                }
                removeNewColumn={this.props.removeNewColumn}
                hasDeselectTable={this.state.hasDeselectTable}
                newColumnAddedFlag={this.props.newColumnAddedFlag}
              ></TablesPanel>
            </Row>
          </div>
          {Object.keys(this.state.model).length > 0 ? (
            <div className={"save-button-area"}>
              <Button
                id="saveModelButton"
                onClick={() => this.modelSave(this.state.model)}
                className={"general-button"}
                style={{ width: "17%", float: "right", margin: "0", zIndex: 1 }}
              >
                {i18n.t("Save")}
              </Button>
            </div>
          ) : null}

          <UpdateTableAliasName
            aliasNameNotUnique={this.state.aliasNameNotUnique}
            updateAliasNameNotUnique={this.updateAliasNameNotUnique}
            newProps={this.props}
            tableAddOrRemoveInModel={this.tableAddOrRemoveInModel}
          />
        </div>
        <NewColumnEditor
          model={this.state.model}
          getModel={this.props.getModel} />
      </>
    ) : null;
  }
}
