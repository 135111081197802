import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon, Tooltip, Input } from "antd";

export class RenameNode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nameValidation: {
        notEmpty: "",
        notDublicate: ""
      },
      validationTooltip: {
        visible: false,
        text: this.getTooltipText()
      }
    };
  }

  getTooltipText = () => {
    return (
      <div>
        <h2>
          You should input new name in accordance with the following conditions
        </h2>
        <h3>press enter to confirm or esc to cancel</h3>

        <ul style="list-style-type:disc;">
          <li>
            <Icon
              type="check-circle"
              // visible={this.state.isValidateIconVisible}
              visible={true}
            />{" "}
            Name can not be empty
          </li>
          <li>
            <Icon
              type="check-circle"
              // visible={this.state.isValidateIconVisible}
              visible={true}
            />{" "}
            Name must be unique
          </li>
        </ul>
      </div>
    );
  };

  renameItem = e => {
    let nameValidation = {
      notEmpty: "OK",
      notDublicate: "OK"
    };

    let selectedItem = { ...this.props.item };
    let newDisplayName = e.target.defaultValue;

    if (
      newDisplayName == "" ||
      newDisplayName == null ||
      newDisplayName == undefined
    )
      nameValidation.notEmpty = "FAIL";

    for (let item of this.props.data) {
      if (item.displayName == newDisplayName)
        nameValidation.notDublicate = "FAIL";
    }

    if (
      nameValidation.notEmpty === "OK" &&
      nameValidation.notDublicate === "OK"
    ) {
      selectedItem.displayName = newDisplayName;
      this.props.onConfirm(selectedItem, "renameDisplayName");
    } else {
      this.setState({
        validationTooltip: {
          visible: true
        }
      });
    }
  };

  render() {
    return (
      <Input
        size="small"
        defaultValue={this.props.item.displayName}
        onPressEnter={this.renameItem}
        suffix={
          <Tooltip title="Extra information">
            <Icon type="info-circle" style={{ color: "rgba(0,0,0,.45)" }} />
          </Tooltip>
        }
      />
    );
  }
}

export default RenameNode;
