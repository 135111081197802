import React, { Component } from "react";
import { Spin } from "antd";

import "./SplashScreen.css";
import i18n from "../../Utils/i18next";

export default class SplashScreen extends Component {
  render() {
  let style = this.props.style ? {...this.props.style} : {}
  style.display = this.props.display ? {...this.props.display} : "none"
    return (
      <div id={this.props.id ? this.props.id : "splashScreenContainer"} style={style}>
        <div class="logo-animate">
          <div class="bar">
            <div class="triangle-up"></div>
            <div class="triangle-down"></div>
          </div>
          <div class="bar">
            <div class="triangle-up"></div>
            <div class="triangle-down"></div>
          </div>
          <div class="bar">
            <div class="triangle-up"></div>
            <div class="triangle-down"></div>
          </div>
          <div class="bar">
            <div class="parallelogram">
              <div></div>
            </div>
          </div>
          <div class="text">
            <span>{`${i18n.t("Splash.Loading")}...`}</span>
          </div>
        </div>
      </div>
    );
  }
}
