import React, { Component } from "react";
import Text from "../../../GeneralComponents/Text/Text";
import i18n from "../../../../Utils/i18next";
import PropTypes from "prop-types";
import { Row, Col, Select, Input, Popover } from "antd";
import SelectPredicate from "./SelectPredicate";
import { post } from "../../../../Utils/WebService";
import { QUERY_URL } from "../../../../config";
import "./defaultFilters.css";
import Button from "../../../GeneralComponents/Button/Button";
import { showNotificationWithIcon } from "../../../../Utils/Notification";
import SingleFilter from "./SingleFilter";
import { comparedPlugins } from "../../../ComparedPlugins";

const { Option } = Select;
const { TextArea } = Input;

/**
 * The component in which default filters are listed and adjusted
 */
class DefaultFilters extends Component {
  constructor(props) {
    super(props);

    this.prototypes = {
      filters: PropTypes.array,
      visible: PropTypes.bool
    };

    this.state = {
      filters: this.props.filters ? this.props.filters : [],
      visible: false
    };
  }

  componentWillReceiveProps = nextProps => {
    let newState = { ...this.state };

    if (nextProps.filters !== this.props.filters) {
      if (
        this.props.filters &&
        nextProps.filters.length > this.props.filters.length
      ) {
        newState.visible = true;
      }

      newState.filters = nextProps.filters;
    }

    if (nextProps.visible == true) {
      newState.visible = true;
      this.props.showDefaultFilters(false);
    }

    if (newState !== this.state) {
      this.setState(newState);
    }
  };

  /** Close default filter area */
  close = () => {
    this.setState({ ...this.state, visible: false });
  };

  /**
   * @param filterId
   * @param predicate
   * to set filter predicate to column
   */

  render() {
    if (this.state.visible === false) {
      return null;
    }

    return (
      <div className={"default-filters-area"}>
        <Text type={"h6"} style={{ textAlign: "center", marginBottom: "10px" }}>
          {i18n.t("Dashboard.Data.DefaultFilters.Title")}
        </Text>
        <div className={""}>
          {this.state.filters.length > 0 ? (
            this.state.filters.map((filter, index) => (
              <SingleFilter
                key={"single-" + filter.filterId}
                updateDefaultFilters={this.props.updateDefaultFilters}
                modelDisplayName={this.props.modelDisplayName}
                setPredicateToColumn={this.setPredicateToColumn}
                filter={filter}
                index={index}
                pluginId={this.props.pluginId}
                isComparePlugin={comparedPlugins.has(this.props.pluginKey)}
              />
            ))
          ) : (
            <Text type={"span"} className={"warning-description-text"}>
              {i18n.t("Dashboard.Data.DefaultFilters.EmptyDesc")}
            </Text>
          )}
        </div>
        <Button
          id={"closeFilterPopUp"}
          style={{
            position: "absolute",
            top: "3px",
            right: "15px",
            borderRadius: "40px",
            padding: "6px 11px",
            backgroundColor: "white",
            zIndex: 2
          }}
          type={"default"}
          onClick={this.close}
        >
          X
        </Button>
      </div>
    );
  }
}

export default DefaultFilters;
