import React, { Component } from "react";
import SheetItem from "./SheetItem";
import i18n from "../../Utils/i18next";

/**
 * Sheet list in excel file
 */
export default class SheetList extends Component {
  render() {
    const { sheets, selectedSheet } = this.props;

    return (
      <div className="sheet-list-container">
        <p className="sheet-list-title">{i18n.t("FileUpload.SheetList")}</p>
        <div className="sheet-item-container">
          {[...sheets.keys()].map((sheetName, index) => (
            <SheetItem
              key={index}
              sheet={sheets.get(sheetName)}
              selectedSheet={selectedSheet}
              applyChangesToSheet={this.props.applyChangesToSheet}
              onChangeSheetStatus={this.props.onChangeSheetStatus}
              selectSheet={this.props.selectSheet}
            />
          ))}
        </div>
      </div>
    );
  }
}
