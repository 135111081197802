export const configuration = {
    circleThickness: 0.05,
    circleColor: "#178BCA",
    textColor: "#045681",
    waveTextColor: "#A4DBf8",
    waveColor: "#178BCA",
    textVertPosition: 0.5,
    minValue: 0,
    waveHeight: 0.05,
    circleFillGap: 0.05,
    waveCount: 1,
    waveRiseTime: 1000,
    waveAnimateTime: 10000,
    waveHeightScaling: true,
    waveAnimate: true,
    textSize: 10,
    displayPercent: false,
};

export const actions = [];

export const reactions = [
    {
        id: "filter",
        name: "Filtre",
        description: "desc87",
        type: "general"
    }
];

export const conversionMap = {
    "table": {
        columnMap: {
            columns: {
                name: "Columns",
                from: ["measure", "target"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
        }
    }
};