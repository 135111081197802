export const API_BASE = "/api";
export const METADATA_BASE = "/metadata-operations"

// export const API_BASE = "http://130.61.20.68/api";

export const BASE_URL_UI = "/vispeahen/ui";
export const BASE_URL_ADMIN = "/vispeahen/admin-page";
export const BASE_URL_REPORTS = "/reports";
export const basename = "/vispeahen";
export const QUERY_URL = "/query";
export const ECHO_URL = "/security/api/echo/ping";
export const RESOURCE_API_URL = "/resource/api/resource";

//Well Defined Labels
export const PRIVATE_LABEL = "0000-1212-2212-2212";
export const SHARED_LABEL = "1111-1212-2212-2212";
export const POPULAR_LABEL = "2222-1212-2212-2212";
export const MOST_VISITED_LABEL = "3333-1212-2212-2212";
export const FAVORITE_LABEL = "4444-1212-2212-2212";
export const DELETED_LABEL = "5555-1212-2212-2212";

export const ColumnNotFound = "ColumnNotFound";

export const allLabels = new Set([
    "0000-1212-2212-2212", //Private Label
    "1111-1212-2212-2212", //Shared Label
    "2222-1212-2212-2212", //Popular Dashboards
    "3333-1212-2212-2212", //Most Visiteds
    "4444-1212-2212-2212", //Favorite Label
    "5555-1212-2212-2212", //Deleted Label
    "7777-1212-2212-2212" //Recently Popular Label
])

// Exclude View Count Labels
export const excludeViewCountLabels = new Set([
    POPULAR_LABEL, //Popular Dashboards
    MOST_VISITED_LABEL, //Most Visiteds
])

export const exculudeLabels = new Set([
    "7777-1212-2212-2212"
])

const location = window.location.origin;
const registerAllowedLinks = new Set([
    "http://localhost",
    "http://165.22.22.154",
    "https://v3.vispeahen.com",
    "http://v3.vispeahen.com",
]);

export const fullViewWidth = 1024;

export const checkLocationForRegister = registerAllowedLinks.has(location);

export const exceptAutoInteractionPlugins = new Set(["title"]);
export const autoInteractionPlugins = new Set([
    "filter",
    "selection-box",
    "date-filter",
    "radio-button-filter",
    "button-filter"
]);
export const exceptInteractionComponent = new Set([
    "i-frame",
    "image",
    "measure-tile",
    "flag",
    "radial-progress",
    "liquid-gauge",
    "line-bar-chart",
    "line-bar",
    "cylinder-bar",
    "map-cluster",
    "heatmap",
    "custom",
    "title",
    "timeline-chart",
    "gauge-chart"
]);

export const exceptDataComponent = new Set(["image", "i-frame", "title"]);

export const exceptConditionalFormatComponent = new Set([
    "custom",
    "chord-diagram",
    "sankey-chart",
    "bar-chart",
    "cylinder-bar",
    "line-bar-chart",
    "filter",
    "selection-box",
    "radio-button-filter",
    "line-bar",
    "map-bubbles",
    "map-cluster",
    "heatmap",
    "route-map",
    "turkey-map",
    "world-map",
    "flag",
    "i-frame",
    "image",
    "title",
    "radial-progress",
    "liquid-gauge",
    "word-cloud",
    "button-filter",
    "network-graph"
]);

export const exceptNavigationComponent = new Set([
    "cylinder-bar",
    "filter",
    "selection-box",
    "radio-button-filter",
    "line-bar",
    "map-cluster",
    "heatmap",
    "route-map",
    "route-map-2",
    "turkey-map",
    "world-map",
    "flag",
    "i-frame",
    "image",
    "title",
    "radial-progress",
    "liquid-gauge",
    "word-cloud",
    "custom",
    "button-filter",
    "timeline-chart"
]);

export const drillDownPlugins = {
    "pie-chart": ["category"],
    "pie-chart-enhanced": ["category"],
    "table": ["columns"],
    "scatter-chart": ["animation", "group", "varyColour"],
    "pivot-table": ["columns", "rows"],
    "radar": ["category"],
    "tree-map": ["group"],
    "multi-axis-line-chart": ["category"],
    "age-pyramid": ["category"]
};