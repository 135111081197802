import React, { Component } from "react";
import {
  FontSizeOutlined,
  GlobalOutlined,
  FunctionOutlined
} from "@ant-design/icons";
import { getDefaultFormatType, format, getFormatDataType, getDefaultFormatWarning } from "../../PluginComponents/format";
import { Row, Col, Input, Select, Tabs, Tooltip, Button, Checkbox } from "antd";
import Text from "../../../GeneralComponents/Text/Text";
import i18n from "../../../../Utils/i18next";
import TableWhatIf from "../../TableWhatIf/TableWhatIf";
import { store } from "../../../..";
import { setFormulaEditorContent } from "../../../GeneralComponents/FormulaEditor/FormulaEditorAction";
import { deepCopy } from "../../../../Utils/Global";
import { getPluginsAllColumnsByField } from "../../../../Utils/PluginOperations";
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { setDefinedExpression, setExpression, setLastOpenedKey } from "../../../ExpressionParameter/ExpressionParameterAction";
import { calculateText } from "../../PluginComponents/common";
const { Option } = Select;
const { TabPane } = Tabs;

const alignmentAppliedPlugins = new Set([
  "table",
  "pivot-table",
  "pivot-table-enhance"
]);

const dataFormatTypeSubTitle = [
  { type: "number", name: "wholeNumber", value: "WholeNumber" },
  { type: "number", name: "decimal", value: "Decimal" },
  { type: "number", name: "rounding", value: "Rounding" },
  { type: "time", name: "date", value: "Date" },
  { type: "time", name: "timestamp", value: "Timestamp" },
]

/**
 * Data column properties popup component
 */
export default class ColumnProperties extends Component {
  constructor(props) {
    super(props);

    let getDefaultFormatTypeResult = getDefaultFormatType(this.props.column);
    let dataFormat = this.props.column.DataFormat ? this.props.column.DataFormat : getDefaultFormatTypeResult.dataFormat;
    let dataFormatTypeSubTitle = this.props.column.DataFormatTypeSubTitle != undefined ? this.props.column.DataFormatTypeSubTitle : getDefaultFormatTypeResult.dataFormatTypeSubTitle
    let shouldDisableWarning = dataFormat === "data" && dataFormatTypeSubTitle === "timestamp"
    let dateFormat = this.getDateFormat(dataFormat)
    let timestampFormat = this.getTimestampFormat(dataFormat)

    this.state = {
      columnProperties: {
        displayName: this.props.column.displayName
          ? this.props.column.displayName
          : "",
        locale: this.props.column.locale ? this.props.column.locale : "TR",
        DataFormat: dataFormat,
        Code: this.props.column.Code
          ? this.props.column.Code
          : '"' +
          this.props.column.tableAliasName +
          '"."' +
          this.props.column.aliasName +
          '"',
        whatIf: this.props.column.whatIf ? this.props.column.whatIf : undefined,
        DataFormatType: this.props.column.DataFormatType != undefined ? this.props.column.DataFormatType : getDefaultFormatTypeResult.dataFormatType,
        DecimalPlaces: this.props.column.DecimalPlaces != undefined ? this.props.column.DecimalPlaces : getDefaultFormatTypeResult.decimalPlace,
        Use1000Separator: this.props.column.Use1000Separator != undefined ? this.props.column.Use1000Separator : getDefaultFormatTypeResult.use1000Separator,
        DataFormatTypeSubTitle: dataFormatTypeSubTitle,
        FormatExample: this.props.column.FormatExample != undefined ? this.props.column.FormatExample : getDefaultFormatTypeResult.formatExample,
        RoundingNumber: this.props.column.RoundingNumber != undefined ? this.props.column.RoundingNumber : getDefaultFormatTypeResult.roundingNumber,
        FormatWarning: shouldDisableWarning ? false : getDefaultFormatWarning(this.props.column),
        isDataIncludeTotal: this.props.column.isDataIncludeTotal != undefined ? this.props.column.isDataIncludeTotal : false,
        SelectedDateFormat: dateFormat,
        SelectedTimestampFormat: timestampFormat,
        affixValue: this.props.column.affixValue ? this.props.column.affixValue : "",
        affixAlign: this.props.column.affixAlign ? this.props.column.affixAlign : "right",
        preserveDataAs: this.props.column.preserveDataAs ? this.props.column.preserveDataAs : "",
        expression: this.props.column.expression ? this.props.column.expression : false,
        SelectedTimestampFormat: timestampFormat
      },
      DateFormats: [
        { value: "%Y/%M/%D", title: "Y/M/D" },
        { value: "%Y/%D/%M", title: "Y/D/M" },
        { value: "%M/%Y/%D", title: "M/Y/D" },
        { value: "%M/%D/%Y", title: "M/D/Y" },
        { value: "%D/%Y/%M", title: "D/Y/M" },
        { value: "%D/%M/%Y", title: "D/M/Y" },
        { value: "%Y/%M", title: "Y/M" },
        { value: "%M/%Y", title: "M/Y" },
        { value: "%Y", title: "Y" },
      ],
      TimestampFormats: [
        { value: "%H:%M:%S", title: "H:M:S" },
        { value: "%H:%S:%M", title: "H:S:M" },
        { value: "%M:%H:%S", title: "M:H:S" },
        { value: "%M:%S:%H", title: "M:S:H" },
        { value: "%S:%H:%M", title: "S:H:M" },
        { value: "%S:%M:%H", title: "S:M:H" },
        { value: "%H:%M", title: "H:M" },
        { value: "%M:%H", title: "M:H" },
        { value: "%H", title: "H" },
        { value: "", title: i18n.t("Dashboard.Data.DataFormatType.NoTimestamp") }
      ],
      columnPropertiesTabActiveKey: "1",
    };
  }

  /** Data format type input changes. */
  DataFormatTypeOnChange = e => {
    let columnProperties = deepCopy(this.state.columnProperties);
    let cloneColumn = deepCopy(this.props.column);

    if (e === "text") {
      cloneColumn.dataType = 'varchar';
      columnProperties["DataFormatTypeSubTitle"] = '';
      columnProperties["DecimalPlaces"] = '0';
      columnProperties["Use1000Separator"] = false;
      columnProperties["RoundingNumber"] = '0';
      columnProperties["DataFormat"] = '%s';
      columnProperties["FormatExample"] = '(' + format('abcde', '%s', cloneColumn) + ')';
    } else if (e === "time") {
      cloneColumn.Locale = 'TR';
      columnProperties["DecimalPlaces"] = '0';
      columnProperties["Use1000Separator"] = false;
      columnProperties["RoundingNumber"] = '0';

      if (cloneColumn.dataType === 'timestamp') {
        columnProperties["DataFormatTypeSubTitle"] = 'timestamp';
        columnProperties["DataFormat"] = '%d/%m/%Y %H:%M:%S';
        columnProperties["SelectedDateFormat"] = "%D/%M/%Y";
        columnProperties["SelectedTimestampFormat"] = "%H:%M:%S";
        columnProperties["FormatExample"] = '(' + format('01/01/2023 00:00:00', '%d/%m/%Y %H:%M:%S', cloneColumn) + ')';
      } else {
        cloneColumn.dataType = 'date'
        columnProperties["DataFormatTypeSubTitle"] = 'date';
        columnProperties["DataFormat"] = "%d/%m/%Y";
        columnProperties["SelectedDateFormat"] = "%Y/%M/%D";
        columnProperties["SelectedTimestampFormat"] = "";
        columnProperties["FormatExample"] = '(' + format('01/01/2023', '%d/%m/%Y', cloneColumn) + ')';
      }
    } else if (e === "number") {
      cloneColumn.dataType = 'double';
      columnProperties["DataFormatTypeSubTitle"] = 'wholeNumber';
      columnProperties["DecimalPlaces"] = '0';
      columnProperties["Use1000Separator"] = false;
      columnProperties["RoundingNumber"] = '0';
      columnProperties["DataFormat"] = ".0f";
      columnProperties["FormatExample"] = '(' + format('12345', '.0f', cloneColumn) + ')';
    } else {
      columnProperties["DataFormatTypeSubTitle"] = '';
      columnProperties["DecimalPlaces"] = '0';
      columnProperties["Use1000Separator"] = false;
      columnProperties["RoundingNumber"] = '0';
      columnProperties["FormatExample"] = '';

      if (cloneColumn.dataType === "date") {
        columnProperties["DataFormat"] = "%Y/%m/%d";
        columnProperties["SelectedDateFormat"] = "%Y/%M/%D";
        columnProperties["SelectedTimestampFormat"] = "";
      } else if (cloneColumn.dataType === "timestamp") {
        columnProperties["DataFormat"] = "%Y/%m/%d %H:%M:%S";
        columnProperties["SelectedDateFormat"] = "%Y/%M/%D";
        columnProperties["SelectedTimestampFormat"] = "%H:%M:%S";
      }
    }

    let TypeOrginal = getFormatDataType(this.props.column.originalDataType ? this.props.column.originalDataType : this.props.column.dataType);
    let TypeNew = getFormatDataType(cloneColumn.dataType);

    if (TypeOrginal != TypeNew) {
      columnProperties["FormatWarning"] = i18n.t("Dashboard.Data.DataFormatType.DataType.FormatWarning", { typeOrginal: i18n.t("Dashboard.Data.DataFormatType.DataType." + TypeOrginal), typeNew: i18n.t("Dashboard.Data.DataFormatType.DataType." + TypeNew) })
    } else {
      columnProperties["FormatWarning"] = '';
    }

    columnProperties["DataFormatType"] = e;

    this.setState({
      columnProperties: columnProperties
    });

    this.props.updateColumnProperties(columnProperties);
  };

  /**
   * Returns date part of format.
   */
  getDateFormat = (format) => {
    let index = format.indexOf(" ")

    if (index === -1) {
      return format.trim();
    } else {
      return format.slice(0, index).trim().toUpperCase();
    }
  }

  /**
   * Returns timestamp part of format.
   */
  getTimestampFormat = (format) => {
    let index = format.indexOf(" ")

    if (index === -1) {
      return "";
    } else {
      return format.slice(index + 1).trim().toUpperCase();
    }
  }

  /** Data format sub title type input changes. */
  DataFormatTypeSubTitleOnChange = e => {
    let columnProperties = deepCopy(this.state.columnProperties);
    let cloneColumn = deepCopy(this.props.column);

    if (e === "wholeNumber") {
      cloneColumn.dataType = 'double';
      columnProperties["DecimalPlaces"] = '0';
      columnProperties["Use1000Separator"] = false;
      columnProperties["RoundingNumber"] = '0';
      columnProperties["DataFormat"] = '.0f';
      columnProperties["FormatExample"] = '(' + format('12345', '.0f', cloneColumn) + ')';
    } else if (e === "decimal") {
      cloneColumn.dataType = 'double';
      columnProperties["DecimalPlaces"] = '2';
      columnProperties["Use1000Separator"] = true;
      columnProperties["RoundingNumber"] = '0';
      columnProperties["DataFormat"] = ',.2f';
      columnProperties["FormatExample"] = '(' + format('12345', ',.2f', cloneColumn) + ')';
    } else if (e === "rounding") {
      cloneColumn.dataType = 'double';
      columnProperties["DecimalPlaces"] = '0';
      columnProperties["Use1000Separator"] = false;
      columnProperties["RoundingNumber"] = '3';
      columnProperties["DataFormat"] = '.3s';
      columnProperties["FormatExample"] = '(' + format('12345', '.3s', cloneColumn) + ')';
    } else if (e === "date") {
      cloneColumn.dataType = 'date';
      cloneColumn.Locale = 'TR';
      columnProperties["DecimalPlaces"] = '0';
      columnProperties["Use1000Separator"] = false;
      columnProperties["RoundingNumber"] = '0';
      columnProperties["DataFormat"] = '%d/%m/%Y';
      columnProperties["FormatExample"] = '(' + format('01/01/2023', '%d/%m/%Y', cloneColumn) + ')';
    } else if (e === "timestamp") {
      cloneColumn.dataType = 'timestamp';
      cloneColumn.Locale = 'TR';
      columnProperties["DecimalPlaces"] = '0';
      columnProperties["Use1000Separator"] = false;
      columnProperties["RoundingNumber"] = '0';
      columnProperties["DataFormat"] = '%d/%m/%Y %H:%M:%S';
      columnProperties["FormatExample"] = '(' + format('01/01/2023 00:00:00', '%d/%m/%Y %H:%M:%S', cloneColumn) + ')';
    }

    let typeOrginal = getFormatDataType(this.props.column.originalDataType ? this.props.column.originalDataType : this.props.column.dataType);
    let typeNew = getFormatDataType(cloneColumn.dataType);

    if (typeOrginal !== typeNew) {
      columnProperties["FormatWarning"] = i18n.t("Dashboard.Data.DataFormatType.DataType.FormatWarning", { typeOrginal: i18n.t("Dashboard.Data.DataFormatType.DataType." + typeOrginal), typeNew: i18n.t("Dashboard.Data.DataFormatType.DataType." + typeNew) })
    } else {
      columnProperties["FormatWarning"] = '';
    }

    columnProperties["DataFormatTypeSubTitle"] = e;

    this.setState({
      columnProperties: columnProperties
    });

    this.props.updateColumnProperties(columnProperties);
  };

  inputTimer;

  /** columnProperties update with timer  */
  updateProperties = properties => {
    this.setState({
      columnProperties: properties
    });

    this.inputTimer = setTimeout(() => {
      this.props.updateColumnProperties(properties);
    }, 500);
  }

  /** Decimal Places Handle input changes. */
  decimalPlacesHandleChange = event => {
    clearTimeout(this.inputTimer);

    let decimalValue = event.target.value;
    let columnProperties = deepCopy(this.state.columnProperties);
    let cloneColumn = deepCopy(this.props.column);
    let tempDataFormat = this.state.columnProperties.Use1000Separator === false ? "." : ",.";

    if (decimalValue === '' || decimalValue === null) {
      tempDataFormat += "0f";
      decimalValue = 0;
    } else {

      if (decimalValue > 20) {
        decimalValue = 20;
      } else if (decimalValue < 0) {
        decimalValue = 0;
      } else {
        decimalValue = Number(decimalValue);
      }
      tempDataFormat += decimalValue + "f";
    }

    decimalValue = decimalValue.toString();
    cloneColumn.dataType = 'double';
    columnProperties["DecimalPlaces"] = decimalValue;
    columnProperties["DataFormat"] = tempDataFormat;
    columnProperties["FormatExample"] = '(' + format('12345', tempDataFormat, cloneColumn) + ')';

    this.updateProperties(columnProperties);
  };

  /** Use 1000 Separator input checked. */
  Use1000SeparatorHandleCheck = e => {
    let columnProperties = deepCopy(this.state.columnProperties);
    let cloneColumn = deepCopy(this.props.column);

    if (e.target.checked === false) {
      columnProperties["DataFormat"] = "." + this.state.columnProperties.DecimalPlaces + "f";
    } else {
      columnProperties["DataFormat"] = ",." + this.state.columnProperties.DecimalPlaces + "f";
    }

    cloneColumn.dataType = 'double';
    columnProperties[e.target.id] = e.target.checked;
    columnProperties["FormatExample"] = '(' + format('12345', columnProperties["DataFormat"], cloneColumn) + ')';

    this.setState({
      columnProperties: columnProperties
    });

    this.props.updateColumnProperties(columnProperties);
  };

  /** Rounding Number input changes.*/
  roundingNumberHandleChange = event => {
    let roudingNumber = event.target.value;
    let columnProperties = deepCopy(this.state.columnProperties);
    let cloneColumn = deepCopy(this.props.column);

    if (roudingNumber === '' || roudingNumber === null) {
      columnProperties["DataFormat"] = ".0s";
      roudingNumber = 0;
    } else {

      if (roudingNumber > 22) {
        roudingNumber = 22;
      } else if (roudingNumber < 0) {
        roudingNumber = 0;
      } else {
        roudingNumber = Number(roudingNumber);
      }
      columnProperties["DataFormat"] = "." + roudingNumber + "s";
    }

    roudingNumber = roudingNumber.toString();
    cloneColumn.dataType = 'double';
    columnProperties["RoundingNumber"] = roudingNumber;
    columnProperties["FormatExample"] = '(' + format('12345', columnProperties["DataFormat"], cloneColumn) + ')';

    this.setState({
      columnProperties: columnProperties
    });

    this.props.updateColumnProperties(columnProperties);
  };

  /** format input changes. */
  formatHandleChange = e => {
    let cloneColumn = deepCopy(this.props.column);
    let columnProperties = deepCopy(this.state.columnProperties);

    if (columnProperties.DataFormatType === "time") {
      let formatExample = columnProperties.DataFormatTypeSubTitle === "timestamp" ? "01/01/2023 00:00:00" : "01/01/2023";

      cloneColumn.Locale = 'TR';
      cloneColumn.dataType = 'date';
      columnProperties.FormatExample = '(' + format(formatExample, e.target.value, cloneColumn) + ')';
    }

    columnProperties[e.target.id] = e.target.value;

    this.setState({
      columnProperties: columnProperties
    }, () => {
      this.props.updateColumnProperties(columnProperties);
    });
  };

  /** Handle input changes. */
  handleChange = (e, preserveDataAs = false) => {
    let columnProperties = deepCopy(this.state.columnProperties);

    if (preserveDataAs) {
      columnProperties[e.target.id] = e.target.value.replace(/[!"#$%&'()*+,./\\:;<=>?@^`{|}~ ]/g, '');
    } else {
      columnProperties[e.target.id] = e.target.value.replaceAll(`'`, ``).replaceAll(`[`,``).replaceAll(`]`,``).replaceAll(`{`,``).replaceAll(`}`,``);
    }

    this.setState({
      columnProperties: columnProperties,
    });

    this.props.updateColumnProperties(columnProperties);
  };

  /** Handle input changes. (Data Format) */
  handleChangeDataFormat = e => {
    let columnProperties = deepCopy(this.state.columnProperties);

    columnProperties[e.target.id] = e.target.value;

    this.setState({
      columnProperties: columnProperties
    });

    this.props.updateColumnProperties(columnProperties);
  };

  /** Handle align variable change. */
  alignOnChange = value => {
    let columnProperties = deepCopy(this.state.columnProperties);

    columnProperties["align"] = value;

    this.setState({
      columnProperties: columnProperties
    });

    this.props.updateColumnProperties(columnProperties);
  };

  /** Handle affix align variable change. */
  affixAlignOnChange = value => {
    let columnProperties = deepCopy(this.state.columnProperties);
    let cloneColumn = deepCopy(this.props.column);
    
    columnProperties = {
      ...columnProperties,
      affixAlign: value,
      affixValue: cloneColumn["affixValue"]
    }
    
    this.setState({
      columnProperties: columnProperties
    })
    
    this.props.updateColumnProperties(columnProperties);
  };

  /** Handle affix value change */
  affixValueHandleChange = e => {
    let columnProperties = deepCopy(this.state.columnProperties);
    let cloneColumn = deepCopy(this.props.column);

    columnProperties = {
      ...columnProperties,
      affixValue: e.target.value,
      affixAlign: cloneColumn["affixAlign"]
    }
    
    this.setState({
      columnProperties: columnProperties
    })
    
    this.props.updateColumnProperties(columnProperties);
  };

  /**
   * Apply all columns selected align according to column location field. 
   */
  applyAlignAllColumn = () => {
    let align = this.state.columnProperties["align"];
    let locationFieldName = this.props.column.locationFieldName

    this.props.componentTHIS.applyAlignToAllColumn(align, locationFieldName);
  }

  /** Handle locale variable change. */
  localeOnChange = value => {
    let columnProperties = deepCopy(this.state.columnProperties);

    columnProperties["locale"] = value;

    this.setState({
      columnProperties: columnProperties
    });

    this.props.updateColumnProperties(columnProperties);
  };

  /**
   * Returns is column number
   * @column boolean
   */
  columnIsNumber = column => {
    return column.dataType === "integer" || column.dataType === "double";
  };

  /**
   *  Get Pivot table ApplyAll button text and tooltip content by field name.
   * @returns 
   */
  getLocationFieldName = () => {
    let locationFieldName = this.props.column.locationFieldName;

    switch (locationFieldName) {
      case "rows":
        return i18n.t("Dashboard.Data.ApplyAllRows");
      case "columns":
        return i18n.t("Dashboard.Data.ApplyAllColumns");
      case "measures":
        return i18n.t("Dashboard.Data.ApplyAllMeasures");
    }
  }

  componentWillReceiveProps(nextProps) {
    let dataType = nextProps.column.dataType
    let dataFormatType = nextProps.column.DataFormatType
    let dataFormatTypeSubTitle = nextProps.column.DataFormatTypeSubTitle
    let shouldDisableWarning = dataType === "date" && dataFormatTypeSubTitle === "timestamp"
    let dataFormat = nextProps.column.DataFormat !== undefined ? nextProps.column.DataFormat.trim() : nextProps.column.DataFormat

    if (/%[HMSs]/.test(dataFormat) && dataType === "date" && dataFormatType !== "custom") {
      this.state.columnProperties.FormatWarning = true
      shouldDisableWarning = true
    }

    if (
      nextProps.isThreePointVisible === true &&
      nextProps.isThreePointVisible !== this.props.isThreePointVisible
    ) {
      let hasColumnSetAlign = nextProps.column.align;
      let align = hasColumnSetAlign;
      let isNumber = this.columnIsNumber(nextProps.column);

      if (hasColumnSetAlign === undefined) {
        align = "left";

        if (isNumber) {
          align = "right";
        }
      }

      let { DataFormat, SelectedDateFormat, SelectedTimestampFormat } = { ...this.state.columnProperties.DataFormat }
      let getDefaultFormatTypeResult = getDefaultFormatType(nextProps.column);

      if (nextProps.column.DataFormat && nextProps.column.DataFormatType === "custom") {
        let valid = this.returnValidDataFormat(nextProps);

        DataFormat = valid.DataFormat;
        SelectedDateFormat = valid.SelectedDateFormat;
        SelectedTimestampFormat = valid.SelectedTimestampFormat;
      }

      let columnProperties = {
        displayName: nextProps.column.displayName,
        locale: nextProps.column.locale ? nextProps.column.locale : "TR",
        align: align,
        DataFormat: DataFormat ? DataFormat : getDefaultFormatTypeResult.dataFormat,
        Code: nextProps.column.Code
          ? nextProps.column.Code
          : '"' +
          nextProps.column.tableAliasName +
          '"."' +
          nextProps.column.aliasName +
          '"',
        DataFormatType: dataFormatType ? dataFormatType : getDefaultFormatTypeResult.dataFormatType,
        DecimalPlaces: nextProps.column?.DecimalPlaces ? nextProps.column.DecimalPlaces : getDefaultFormatTypeResult.decimalPlace,
        Use1000Separator: nextProps.column?.Use1000Separator ? nextProps.column.Use1000Separator : getDefaultFormatTypeResult.use1000Separator,
        DataFormatTypeSubTitle: nextProps.column?.DataFormatTypeSubTitle ? nextProps.column.DataFormatTypeSubTitle : getDefaultFormatTypeResult.dataFormatTypeSubTitle,
        FormatExample: nextProps.column?.FormatExample ? nextProps.column.FormatExample : getDefaultFormatTypeResult.formatExample,
        RoundingNumber: nextProps.column?.RoundingNumber ? nextProps.column.RoundingNumber : getDefaultFormatTypeResult.roundingNumber,
        FormatWarning: shouldDisableWarning ? false : getDefaultFormatWarning(nextProps.column),
        isDataIncludeTotal: nextProps.column?.isDataIncludeTotal ? nextProps.column.isDataIncludeTotal : false,
        SelectedDateFormat: SelectedDateFormat,
        SelectedTimestampFormat: SelectedTimestampFormat,
        affixValue: nextProps.column.affixValue ? nextProps.column.affixValue : "",
        affixAlign: nextProps.column.affixAlign ? nextProps.column.affixAlign : "right",
        preserveDataAs: nextProps.column.preserveDataAs ? nextProps.column.preserveDataAs : "",
        expression: nextProps.column.expression ? nextProps.column.expression : false,
      };

      let TypeOrginal = getFormatDataType(nextProps.column.originalDataType ? nextProps.column.originalDataType : nextProps.column.dataType);
      let TypeNew = getFormatDataType(nextProps.column.dataType);
  
      if (TypeOrginal != TypeNew) {
        columnProperties["FormatWarning"] = i18n.t("Dashboard.Data.DataFormatType.DataType.FormatWarning", { typeOrginal: i18n.t("Dashboard.Data.DataFormatType.DataType." + TypeOrginal), typeNew: i18n.t("Dashboard.Data.DataFormatType.DataType." + TypeNew) })
      } else {
        columnProperties["FormatWarning"] = '';
      }

      this.setState({
        columnProperties: columnProperties
      });
    }
  }

  /**
   * Returns valid data format when changing data format type from time to custom.
   */
  returnValidDataFormat = (props) => {
    let valid = {};

    let dataFormat = props.column.DataFormat.trim();
    let date = this.getDateFormat(dataFormat).toUpperCase();
    let timestamp = this.getTimestampFormat(dataFormat).toUpperCase();

    let dataType = props.column.dataType
    let isValid = this.isFormatValid(date, timestamp)

    if (dataType === "date") {
      valid.DataFormat = isValid ? dataFormat : "%Y/%M/%D";
      valid.SelectedDateFormat = isValid ? date : "%Y/%M/%D";
      valid.SelectedTimestampFormat = isValid ? timestamp : "";
    } else {
      valid.DataFormat = isValid ? dataFormat : "%Y/%M/%D %H:%M:%S";
      valid.SelectedDateFormat = isValid ? date : "%Y/%M/%D";
      valid.SelectedTimestampFormat = isValid ? timestamp : "%H:%M:%S";
    }

    return valid;
  }

  /**
   *
   * @param {*} key
   * Change active tab
   */
  changeActiveTab = key => {
    this.setState({
      ...this.state,
      columnPropertiesTabActiveKey: key
    });
  };

  /**
   * Sets formula editor content
   * 
   * @param {*} columnProperties
   */
  setFormulaEditor = (columnProperties) => {
    let code = columnProperties?.Code;
    let expression = columnProperties?.expression;

    let reduxState = store.getState();

    let { plugins } = reduxState.PluginTriggerReducer;
    let { locationFieldName, displayName } = this.props.column;
    let { pluginId } = this.props.componentTHIS.state;

    let isExpressionAvailable = false;
    let editorTitle = i18n.t("FormulaEditor.Titles.Editor");

    for (let [index, plugin] of plugins.entries()) {
      if (plugin.id === pluginId) {
        let pluginName = i18n.t(`PluginsName.names.${plugin.key}`)
        let fieldName = i18n.t(`Plugins.${plugin.key}.ColumnMap.${locationFieldName.charAt(0).toUpperCase() + locationFieldName.slice(1)}.Name`)

        isExpressionAvailable = plugin.key === "measure-tile" && locationFieldName === "measure";
        editorTitle = `${calculateText(plugin, plugin.config.title)} (${pluginName}) › ${fieldName} › ${displayName}`;
      }
    }

    let columnOriginalAliasNameWithTable = '"' + this.props.column.tableAliasName + '"."' + this.props.column.aliasName + '"';
    let tableOriginalName = this.props.column.tableAliasName

    if (columnOriginalAliasNameWithTable === code) {
      let tempTableOriginalName = this.props?.componentTHIS?.state?.model?.tables?.filter(t => t.aliasName === this.props.column.tableAliasName)[0]?.name
      
      if (tempTableOriginalName) {
        tableOriginalName = tempTableOriginalName
      }

      let columnName = this.props.column.columnType === "newColumn" ? this.props.column.aliasName : this.props.column.name
      let columnOriginalNameWithTable = '"' + tableOriginalName + '"."' + columnName + '"';

      code = columnOriginalNameWithTable
    }

    let contentObject = {
      visible: true,
      code: code,
      expression: expression,
      isExpressionAvailable: isExpressionAvailable,
      column: this,
      selectedFormula: {},
      editorTitle: editorTitle,
      uniqeColumnId: this.props.column.uniqeColumnId
    }

    store.dispatch(setFormulaEditorContent(contentObject));
    store.dispatch(setLastOpenedKey(columnProperties))
  }

  /**
   * To disable timestamp option when data type is date.
   */
  isValid = (formatType) => {
    return this.props.column.dataType !== "date" || formatType.name !== "timestamp" || this.props.column.originalDataType == "timestamp"
  }

  /**
   * Returns selected date type value by checking dataType instead of dataFormatTypeSubTitle.
   */
  getSelectValue = (dataFormatTypeSubTitle) => {
    let isDataTypeCustomOrTime = this.state.columnProperties.DataFormatType === "time" || this.state.columnProperties.DataFormatType === "custom"

    return this.props.column.dataType === "date" && isDataTypeCustomOrTime ? "date" : dataFormatTypeSubTitle;
  }

  /**
   * Returns date format by checking dataType.
   */
  getDefaultDateFormat = (dataFormat) => {
    let format = this.props.column.DataFormat !== undefined ? this.props.column.DataFormat.trim() : ""

    if (format === "") {
      return "";
    }

    let index = format.indexOf(" ")
    let isTimestamp = index !== -1;

    if (isTimestamp) {
      return format.slice(0, index);
    } else {
      return format;
    }
  }

  /**
   * Returns timestamp part of format.
   */
  getDefaultTimestampFormat = () => {
    let format = this.props.column.DataFormat !== undefined ? this.props.column.DataFormat.trim() : ""

    if (format === "") {
      return "";
    }

    let index = format.indexOf(" ")
    let isTimestamp = index !== -1;

    if (isTimestamp) {
      return format.slice(index + 1);
    } else {
      return "";
    }
  }

  /**
   * Checks whether custom time format is compatible with data type. 
   */
  checkFormatWarning = () => {
    let dataType = this.props.column.dataType
    let dataFormat = this.props.column.DataFormat !== undefined ? this.props.column.DataFormat.trim() : this.props.column.DataFormat
    let enableWarning = !(/%[HMSs]/.test(dataFormat) && dataType === "date")

    return enableWarning
  }

  /**
   * Handle date part of format change.
   */
  dateFormatOnChange = (e) => {
    let columnProperties = deepCopy(this.state.columnProperties);

    columnProperties["SelectedDateFormat"] = e;
    this.setState({
      columnProperties: columnProperties
    }, (e) => {
      this.props.updateColumnProperties(columnProperties)
      this.appendFormats();
    })
  }

  /**
   * Handle timestamp part of format change
   */
  timestampFormatOnChange = (e) => {
    let columnProperties = deepCopy(this.state.columnProperties);

    columnProperties["SelectedTimestampFormat"] = e;
    this.setState({
      columnProperties: columnProperties
    }, () => {
      this.props.updateColumnProperties(columnProperties);
      this.appendFormats();
    })

  }

  /**
   * Appends date and timestamp part of format.
   */
  appendFormats = () => {
    let columnProperties = deepCopy(this.state.columnProperties);
    let format = this.state.columnProperties.SelectedDateFormat;
    let selectedTimestampFormat = this.state.columnProperties.SelectedTimestampFormat

    if (selectedTimestampFormat !== undefined) {
      format += selectedTimestampFormat !== "" ? " " + selectedTimestampFormat : "";
    }

    columnProperties["DataFormat"] = format
    this.setState({
      columnProperties: columnProperties
    })
    this.props.updateColumnProperties(columnProperties)
  }

  /**
   * Should timestamp dropdown disable.
   */
  shouldDisable = () => {
    return this.props.column.dataType === "date"
  }

  /**
   * Checks and updates column properties.
   */
  checkDataFormatType = () => {
    let shouldUpdateProperties = false;
    let columnProperties = deepCopy(this.state.columnProperties);
    let dataFormatType = this.state.columnProperties.DataFormatType;
    let isDropdownSelected = this.props.plugin.config.dropdownAppearance;

    if (dataFormatType === "custom") {
      if (isDropdownSelected) {
        let isFormatValid = this.isFormatValid(columnProperties.SelectedDateFormat, columnProperties.SelectedTimestampFormat);

        if (!isFormatValid) {
          columnProperties.SelectedDateFormat = "%Y/%M/%D";
          columnProperties.SelectedTimestampFormat = this.props.column.dataType === "timestamp" ? "%H:%M:%S" : "";
          columnProperties.DataFormat = "%Y/%M/%D" + this.props.column.dataType === "timestamp" ? " %H:%M:%S" : ""

          shouldUpdateProperties = true;
        }
      } else {
        let DataFormatTypeSubTitle = this.props.column !== undefined ? this.props.column.dataType : "";

        columnProperties.DataFormatType = "time";
        columnProperties.DataFormatTypeSubTitle = DataFormatTypeSubTitle;
        columnProperties.DataFormat = DataFormatTypeSubTitle === "date" ? "%d/%m/%Y" : "%d/%m/%Y %H:%M:%S";
        columnProperties.SelectedDateFormat = "%Y/%M/%D";
        columnProperties.SelectedTimestampFormat = this.props.column.dataType === "timestamp" ? "%H:%M:%S" : "";

        shouldUpdateProperties = true;
      }
    } else {
      if (columnProperties.SelectedDateFormat || columnProperties.SelectedTimestampFormat) {
        delete columnProperties.SelectedDateFormat;
        delete columnProperties.SelectedTimestampFormat;

        shouldUpdateProperties = true;
      }
    }

    if (shouldUpdateProperties) {
      this.setState({
        columnProperties: columnProperties
      }, () => {
        this.props.updateColumnProperties(columnProperties)

        if (dataFormatType === "custom" && isDropdownSelected) {
          this.appendFormats()
        }
      })
    }
  }

  /**
   * Checks whether format is valid for custom.
   */
  isFormatValid = (dateFormat, timestampFormat) => {
    let dateFormats = this.state.DateFormats.map((format) => { return format.value });
    let timestampFormats = this.state.TimestampFormats.map((format) => { return format.value });

    if (!dateFormats.includes(dateFormat) || !timestampFormats.includes(timestampFormat)) {
      return false;
    }

    return true;
  }

  /**
   * Returns valid date format when changing data type from time to custom.
   */
  returnValidDateFormat = () => {
    let isValid = false;
    let selectedDateFormat = this.state.columnProperties.SelectedDateFormat;
    let selectedTimestampFormat = this.state.columnProperties.SelectedTimestampFormat;

    if (typeof selectedDateFormat === "string" && typeof selectedTimestampFormat === "string") {
      let dateFormat = this.state.columnProperties.SelectedDateFormat.toUpperCase();
      let timestampFormat = this.state.columnProperties.SelectedTimestampFormat.toUpperCase();

      isValid = this.isFormatValid(dateFormat, timestampFormat)

      if (isValid) return dateFormat;
    }

    return "Y/M/D";
  }

  /**
   * Returns valid timestamp format when changing data type from time to custom.
   */
  returnValidTimestampFormat = () => {
    let isValid = false;
    let selectedDateFormat = this.state.columnProperties.SelectedDateFormat;
    let selectedTimestampFormat = this.state.columnProperties.SelectedTimestampFormat;

    if (typeof selectedDateFormat === "string" && typeof selectedTimestampFormat === "string") {
      let dateFormat = this.state.columnProperties.SelectedDateFormat.toUpperCase();
      let timestampFormat = this.state.columnProperties.SelectedTimestampFormat.toUpperCase();

      isValid = this.isFormatValid(dateFormat, timestampFormat)

      if (isValid) return timestampFormat;
    }

    return "H:M:S";
  }

  /**
   * On change include total checkbox, update its state.
   */
  onChangeDataIncludeTotal = () => {
    let columnProperties = deepCopy(this.state.columnProperties);

    columnProperties["isDataIncludeTotal"] = !columnProperties["isDataIncludeTotal"];

    this.setState({
      columnProperties: columnProperties
    }, () => {
      this.props.updateColumnProperties(columnProperties);
    });
  }

  render() {
    let columnWarnings = {};
    let sortedColumnList = this.props.plugin?.sortedColumnList;
    let gettedCols = getPluginsAllColumnsByField(this.props.plugin?.columnMap);
    let { pluginId } = this.props.componentTHIS.state;
    let isTablePlugin =
      this.props.plugin && this.props.plugin.key === "table" ? true : false;
    let isAlignmentAppliedPlugin =
      this.props.plugin && alignmentAppliedPlugins.has(this.props.plugin.key)
        ? true
        : false;
    let isDataFormatErrorUndefinedOrNull = ['', undefined].includes(this.state.columnProperties.FormatWarning) === false ? true : false;
    let columnIsNumber = this.columnIsNumber(this.props.column);
    let alignForColumn = this.props.column.align
      ? this.props.column.align
      : columnIsNumber
        ? "right"
        : "left";
    let alignColumnValue = this.props.column.align != undefined ? this.props.column.align : alignForColumn;
    let affixAlign = this.props.column.affixAlign !== undefined ? this.props.column.affixAlign : "right";
    let affixValue = this.props.column.affixValue !== undefined ? this.props.column.affixValue : "";
    let locationFieldName = this.props.column.locationFieldName !== "hidden"
    let applyButtonText = this.props.plugin && this.props.plugin.key === "pivot-table" ? this.getLocationFieldName() : i18n.t("Interaction.ApplyToAll")
    let hasIncludeTotalCheckbox = isTablePlugin && columnIsNumber
    let dateType = this.props.column !== undefined ? this.props.column.dataType : ""
    let dropdownAppearance = this.props.plugin?.config?.dropdownAppearance ? this.props.plugin.config.dropdownAppearance : false
    let isCustomDisable = dropdownAppearance === false && ["date", "timestamp"].includes(dateType);
    let isDataTypeCustom = this.props.column.DataFormatType === "custom";
    let isDropdown = ["timestamp", "date"].includes(this.props.column.dataType) && dropdownAppearance
    let emptyValue = i18n.t("Dashboard.Data.DataFormatType.NoTimestamp")

    if (this.props.plugin?.key === "filter" && this.props.plugin?.config) {
      this.checkDataFormatType()
    }

    if (gettedCols) {
      for (let col of gettedCols) {
        if (col.displayName === this.props.column.displayName && col.uniqeColumnId !== this.props.column.uniqeColumnId) {
          columnWarnings.displayName = i18n.t("Dashboard.Data.ExistingNameError");
        }
      }
    }

    let isAffixAppliedPlugin = () => {
      let pluginName = this.props.plugin.key;
      let locationFieldName = this.props.column.locationFieldName;

      if (pluginName === "table") {
        return true;
      } else if (pluginName === "measure-tile") {
        return true;
      } else if (pluginName === "pie-chart") {
        return true;
      } else if (pluginName === "pivot-table") {
        return true;
      } else if (pluginName === "tree-map") {
        return true;
      } else if (pluginName === "gauge-chart") {
        return true;
      } else if (pluginName === "multi-axis-line-chart") {
        return true;
      } else if (pluginName === "scatter-chart" && locationFieldName !== "animation") {
        return true;
      }  else if (pluginName === "radial-progress") {
        return true;
      }  else if (pluginName === "radar") {
        return true;
      } else if (pluginName === "chord-diagram") {
        return true;
      } else if (pluginName === "sankey-chart") {
        return true;
      } else if (pluginName === "age-pyramid" && locationFieldName !== "animation") {
        return true;
      } else if (pluginName === "sunburst") {
        return true;
      } else if (pluginName === "word-cloud") {
        return true;
      }

      return false;
    }

    return (
      <div style={{ textAlign: "left" }}>
        <Text type={"h6"} style={{ marginBottom: "15px", textAlign: "center" }}>
          {i18n.t("Dashboard.Data.ColumnProperties")}
        </Text>
        <div style={{ position: "relative" }}>
          <Tooltip title={i18n.t("FormulaEditor.OpenFormulaEditor")} overlayClassName="formula-editor-tooltip">
            <Button
              style={{
                position: "absolute",
                top: "8px",
                right: "0",
                padding: "0 8px",
                height: "fit-content",
                zIndex: "1"
              }}
              onClick={() => this.setFormulaEditor(this.state.columnProperties)}
            >
              <FunctionOutlined style={{ fontSize: "16px" }} />
            </Button>
          </Tooltip>
          <Tabs
            defaultActiveKey="1"
            size={"small"}
            className={"column-properties-tab"}
            activeKey={this.state.columnPropertiesTabActiveKey}
            onChange={this.changeActiveTab}
          >
            <TabPane tab={i18n.t("Options")} key="1">
              <Row style={{ marginBottom: "10px" }}>
                <Col span={12} style={{ marginTop: "2px" }}>
                  <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
                  <Text
                    style={{
                      fontSize: "16px",
                      marginLeft: "4px",
                      display: "inline-block",
                      verticalAlign: "-0.25em"
                    }}
                  >
                    {i18n.t("Dashboard.Data.Name")}
                  </Text>
                </Col>
                <Col span={24} style={{ height: 50 }}>
                  <Input
                    id={"displayName"}
                    style={{
                      marginRight: "3px",
                      borderColor: columnWarnings?.displayName ? "rgb(255, 141, 141)" : "",
                      boxShadow: columnWarnings?.displayName ? "none" : "",
                      animation: "none"
                    }}
                    name={"displayName" + this.props.column.displayName}
                    defaultValue={this.state.columnProperties.displayName}
                    value={this.state.columnProperties.displayName}
                    onChange={this.handleChange}
                  />
                  <span
                    style={{
                      fontSize: "11px",
                      color: "rgb(255, 141, 141)",
                    }}
                  >
                    {columnWarnings.displayName}
                  </span>
                </Col>
              </Row>
              {hasIncludeTotalCheckbox ? (
                <Row style={{ marginBottom: "10px" }}>
                  <Col span={24} style={{ marginTop: "2px" }}>
                    {this.props.plugin?.config.enableTotal ? (
                      <Checkbox
                        style={{ float: "left", marginTop: "3px" }}
                        id={"includeTotalCheckbox"}
                        checked={this.state.columnProperties.isDataIncludeTotal}
                        onChange={this.onChangeDataIncludeTotal}
                      />
                    ) : (
                      <Tooltip title={i18n.t("Dashboard.Data.EnableTotal")}>
                        <Checkbox
                          disabled={true}
                          style={{ float: "left", marginTop: "3px" }}
                          id={"includeTotalCheckbox"}
                          checked={
                            this.state.columnProperties.isDataIncludeTotal
                          }
                        />
                      </Tooltip>
                    )}
                    <Text
                      style={{
                        fontSize: "16px",
                        marginLeft: "4px",
                        display: "inline-block",
                        verticalAlign: "-0.25em",
                      }}
                    >
                      {i18n.t("Dashboard.Data.UseColumnForTotal")}
                    </Text>
                  </Col>
                </Row>
              ) : null}
              <Row gutter={6} style={{ marginBottom: "5px" }}>
                <Col span={24} style={{ marginTop: "2px" }}>
                  <GlobalOutlined style={{ fontSize: "16px" }} />
                  <Text
                    style={{
                      fontSize: "16px",
                      marginLeft: "4px",
                      display: "inline-block",
                      verticalAlign: "-0.25em"
                    }}
                  >
                    {i18n.t("Dashboard.Data.Format")}
                  </Text>
                  <Text
                    style={{
                      fontSize: "14px",
                      marginLeft: "4px",
                      display: "inline-block",
                      verticalAlign: "-0.25em"
                    }}
                  >
                    {this.state.columnProperties.FormatExample}
                  </Text>
                </Col>
                <Col span={12}>
                  <Select
                    id={"DataFormatType" + this.props.column.displayName}
                    style={{ width: "100%" }}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    placeholder=""
                    optionFilterProp="children"
                    onChange={this.DataFormatTypeOnChange}
                    value={this.state.columnProperties.DataFormatType}
                    dropdownClassName={`default-filter-select-${pluginId}`}
                  >
                    <Option
                      name={this.props.column.displayName + "text"}
                      value="text"
                    >
                      {i18n.t("Dashboard.Data.DataFormatType.DataType.Text")}
                    </Option>
                    <Option
                      name={this.props.column.displayName + "number"}
                      value="number"
                    >
                      {i18n.t("Dashboard.Data.DataFormatType.DataType.Number")}
                    </Option>
                    <Option
                      name={this.props.column.displayName + "time"}
                      value="time"
                    >
                      {i18n.t("Dashboard.Data.DataFormatType.DataType.Time")}
                    </Option>
                    <Option
                      name={this.props.column.displayName + "custom"}
                      value="custom"
                      disabled={isCustomDisable}
                    >
                      <Tooltip title={isCustomDisable ? i18n.t("Dashboard.Data.DataFormatType.DataType.CustomFormatDropdownError") : null}>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          <span> {i18n.t("Dashboard.Data.DataFormatType.DataType.Custom")} </span>
                          {
                            isCustomDisable
                              ? <ExclamationCircleOutlined style={{ marginLeft: "auto", color: "rgb(255, 152, 1)" }} />
                              : null
                          }
                        </span>
                      </Tooltip>
                    </Option>
                  </Select>
                </Col>
                {["number", "time"].includes(this.state.columnProperties.DataFormatType) ? (
                  <Col span={12}>
                    <Select
                      id={"DataFormatTypeSubTitle"}
                      style={{ width: "100%" }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      placeholder={""}
                      optionFilterProp="children"
                      onChange={this.DataFormatTypeSubTitleOnChange}
                      value={this.getSelectValue(this.state.columnProperties.DataFormatTypeSubTitle)}
                      dropdownClassName={`default-filter-select-${pluginId}`}
                    >
                      {
                        dataFormatTypeSubTitle.map((formatType) => {
                          if (formatType.type === this.state.columnProperties.DataFormatType) {
                            return (
                              this.isValid(formatType) !== true ? (
                                <Option
                                  disabled={true}
                                  name={formatType.name}
                                  value={formatType.name}
                                >
                                  <Tooltip title={i18n.t("Dashboard.Data.DataFormatType.TimestampTypeError")}>
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                      <span>{i18n.t("Dashboard.Data.DataFormatType.DataType." + formatType.value)}</span>
                                      <ExclamationCircleOutlined style={{ marginLeft: "auto", color: "rgb(255, 152, 1)" }} />
                                    </span>
                                  </Tooltip>
                                </Option>
                              ) : (
                                <Option
                                  name={formatType.name}
                                  value={formatType.name}
                                >
                                  {i18n.t("Dashboard.Data.DataFormatType.DataType." + formatType.value)}
                                </Option>
                              )
                            )
                          }
                        })
                      }
                    </Select>
                  </Col>
                ) : null}
              </Row>
              {this.state.columnProperties.DataFormatTypeSubTitle === "decimal" ? (
                <>
                  <Row style={{ marginBottom: "5px" }}>
                    <Col style={{ textAlign: "left" }} span={14}>
                      <Text
                        style={{
                          fontSize: "14px",
                          display: "inline-block",
                          marginTop: "7px",
                          marginLeft: "2px"
                        }}
                      >
                        {i18n.t("Dashboard.Data.DataFormatType.DecimalPlaces")}
                      </Text>
                    </Col>
                    <Col style={{ paddingLeft: "3px" }} span={10}>
                      <Input
                        id={"DecimalPlaces"}
                        name={"decimalPlaces" + this.props.column.displayName}
                        defaultValue={this.state.columnProperties.DecimalPlaces}
                        value={this.state.columnProperties.DecimalPlaces}
                        onChange={(e) => this.decimalPlacesHandleChange(e)}
                        type={"number"}
                        min={0}
                        max={20}
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "5px" }}>
                    <Col style={{ textAlign: "left" }} span={14}>
                      <Text
                        style={{
                          fontSize: "14px",
                          display: "inline-block",
                          marginTop: "7px",
                          marginLeft: "2px"
                        }}
                      >
                        {i18n.t("Dashboard.Data.DataFormatType.Use1000Separator")}
                      </Text>
                    </Col>
                    <Col span={10} style={{ marginTop: "7px", paddingLeft: "3px" }}>
                      <Checkbox
                        style={{ float: "left" }}
                        id={"Use1000Separator"}
                        defaultChecked={this.state.columnProperties.Use1000Separator}
                        checked={this.state.columnProperties.Use1000Separator}
                        onChange={this.Use1000SeparatorHandleCheck}
                      ></Checkbox>
                    </Col>
                  </Row>
                </>
              ) : this.state.columnProperties.DataFormatTypeSubTitle === "rounding" ? (
                <Row style={{ marginBottom: "5px" }}>
                  <Col style={{ textAlign: "left" }} span={14}>
                    <Text
                      style={{
                        fontSize: "14px",
                        display: "inline-block",
                        marginTop: "7px",
                        marginLeft: "2px"
                      }}
                    >
                      {i18n.t("Dashboard.Data.DataFormatType.RoundingNumber")}
                    </Text>
                  </Col>
                  <Col style={{ paddingLeft: "3px" }} span={10}>
                    <Input
                      id={"RoundingNumber"}
                      name={"roundingNumber" + this.props.column.displayName}
                      defaultValue={this.state.columnProperties.RoundingNumber}
                      value={this.state.columnProperties.RoundingNumber}
                      onChange={(e) => this.roundingNumberHandleChange(e)}
                      type={"number"}
                      min={0}
                      max={22}
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
              ) : null}
              {["time"].includes(this.state.columnProperties.DataFormatType) === true ? (
                <>
                  <Row>
                    <Col span={24}>
                      <Input
                        id={"DataFormat"}
                        name={"dataFormat" + this.props.column.displayName}
                        value={this.state.columnProperties.DataFormat}
                        onChange={e => this.formatHandleChange(e, true)}
                      />
                    </Col>
                  </Row>
                </>
              ) : null}
              {isDataTypeCustom ? (
                isDropdown ? (
                  <Row>
                    <Col span={12}>
                      <Select
                        id={"DataFormatTypeSubTitle"}
                        style={{ width: "128.5px" }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        optionFilterProp="children"
                        onChange={this.dateFormatOnChange}
                        value={this.returnValidDateFormat(dateType)}
                        dropdownClassName={`default-filter-select-${pluginId}`}
                      >
                        {
                          this.state.DateFormats.map((format) => {
                            return (
                              <Option value={format.value}>
                                {format.title}
                              </Option>
                            )
                          })
                        }
                      </Select>
                    </Col>
                    <Col span={12}>
                      <Select
                        id={"DataFormatTypeSubTitle"}
                        style={{ width: "128.5px" }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        disabled={this.shouldDisable()}
                        optionFilterProp="children"
                        onChange={this.timestampFormatOnChange}
                        value={this.shouldDisable() ? emptyValue : this.returnValidTimestampFormat(dateType)}
                        dropdownClassName={`default-filter-select-${pluginId}`}
                      >
                        {
                          this.state.TimestampFormats.map((format) => {
                            return (
                              <Option value={format.value} className={format.title === "" ? "custom-empty-option" : ""}>
                                {format.title}
                              </Option>
                            )
                          })
                        }
                      </Select>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col span={24}>
                      <Input
                        id={"DataFormat"}
                        name={"dataFormat" + this.props.column.displayName}
                        value={this.state.columnProperties.DataFormat}
                        defaultValue={this.getDefaultDateFormat(this.state.columnProperties.DataFormat)}
                        onChange={this.formatHandleChange}
                      />
                    </Col>
                  </Row>
                )
              ) : null}
              {isDataFormatErrorUndefinedOrNull ? (
                <Row style={{ marginTop: "5px" }}>
                  <Col span={24} style={{ fontSize: "11px", textAlign: "left", color: "#FF9801" }}>
                    {this.checkFormatWarning() ? this.state.columnProperties.FormatWarning : i18n.t("Dashboard.Data.DataFormatType.TimestampTypeError")}
                  </Col>
                </Row>
              ) : null}
              {isAlignmentAppliedPlugin ? (
                <>
                  <Row style={{ marginTop: "10px" }}>
                    <Col span={24} style={{ marginTop: "2px" }}>
                      <GlobalOutlined style={{ fontSize: "16px" }} />
                      <Text
                        style={{
                          fontSize: "16px",
                          marginLeft: "4px",
                          display: "inline-block",
                          verticalAlign: "-0.25em"
                        }}>
                        {i18n.t("Dashboard.Data.ValueAlign")}
                      </Text>
                    </Col>
                  </Row>
                  <Row gutter={5} >
                    <Col span={locationFieldName ? 10 : 24}>
                      <Select
                        id={"align-" + this.props.column.displayName}
                        style={{ width: "100%" }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        placeholder=""
                        optionFilterProp="children"
                        onChange={this.alignOnChange}
                        defaultValue={alignForColumn}
                        value={alignColumnValue}
                      >
                        <Option
                          name={this.props.column.displayName + "-align-left"}
                          value="left"
                        >
                          {i18n.t("Dashboard.Configuration.Fields.Left")}
                        </Option>
                        <Option
                          name={this.props.column.displayName + "-align-center"}
                          value="center"
                        >
                          {i18n.t("Dashboard.Configuration.Fields.Center")}
                        </Option>
                        <Option
                          name={this.props.column.displayName + "-align-right"}
                          value="right"
                        >
                          {i18n.t("Dashboard.Configuration.Fields.Right")}
                        </Option>
                      </Select>
                    </Col>
                    {locationFieldName ? (
                      <Col span={14}>
                      <Tooltip
                        title={applyButtonText}
                      >
                        <Button
                          className="pivot-apply-to-all" 
                          style={{ width: "100%" }}
                          onClick={() => {
                            this.applyAlignAllColumn();
                          }}
                        >
                            {applyButtonText}
                        </Button>
                      </Tooltip>
                    </Col>
                  ) : null}
                </Row>
              </>
              ) : null}

              {isAffixAppliedPlugin() ? (
              <>
                <Row style={{marginTop: "10px"}}>
                  <Col span={24} style={{ marginTop: "2px" }}>
                    <GlobalOutlined style={{ fontSize: "16px" }} />
                    <Text
                      style={{
                        fontSize: "16px",
                        marginLeft: "4px",
                        display: "inline-block",
                        verticalAlign: "-0.25em"
                      }}>
                        {i18n.t("Dashboard.Data.AffixAlign")}
                    </Text>
                    <Tooltip
                      title={i18n.t("Dashboard.Data.RestrictedCharacters")}
                    >
                      <InfoCircleOutlined style={{ position: 'relative', marginTop: '5px', textAlign: "left", marginLeft: "10px" }} />
                    </Tooltip>
                  </Col>
                </Row>
                <Row gutter={5}>
                  <Col span={15}>
                    <Input
                      id={"affixValue"}
                      name={"affixValue" + this.props.column.displayName}
                      defaultValue={this.state.columnProperties.affixValue}
                      value={this.state.columnProperties.affixValue}
                      onChange={this.handleChange}
                    />
                  </Col>
                  <Col span={9}>
                    <Select
                      id={"affixAlign-" + this.props.column.displayName}
                      style={{ width: "100%" }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      placeholder=""
                      optionFilterProp="children"
                      onChange={this.affixAlignOnChange}
                      defaultValue={affixAlign}
                      value={affixAlign}
                    >
                      <Option
                        name={this.props.column.displayName + "-align-right"}
                        value="right"
                      >
                        {i18n.t("Dashboard.Configuration.Fields.Right")}
                      </Option>
                      <Option
                        name={this.props.column.displayName + "-align-left"}
                        value="left"
                      >
                        {i18n.t("Dashboard.Configuration.Fields.Left")}
                      </Option>
                    </Select>
                  </Col>
                </Row>
              </>
              ) : null}
              { this.props.plugin.key === "measure-tile" && this.props.column?.locationFieldName !== "hidden" ? 
              <>
                <Row style={{marginTop: "10px"}}>
                  <Col span={24} style={{ marginTop: "2px" }}>
                    <GlobalOutlined style={{ fontSize: "16px" }} />
                    <Text
                      style={{
                        fontSize: "16px",
                        marginLeft: "4px",
                        display: "inline-block",
                        verticalAlign: "-0.25em"
                      }}>
                        {i18n.t("Dashboard.Data.PreserveDataAs")}
                    </Text>
                    <Tooltip
                      title={i18n.t("Dashboard.Data.PreserveDataAsDesc")}
                    >
                      <InfoCircleOutlined style={{ position: 'relative', marginTop: '5px', textAlign: "left", marginLeft: "10px" }} />
                    </Tooltip>
                  </Col>
                </Row>
                <Row gutter={5}>
                  <Col span={24}>
                    <Input
                      id={"preserveDataAs"}
                      name={"preserveDataAs" + this.props.column.displayName}
                      defaultValue={this.state.columnProperties.preserveDataAs}
                      value={this.state.columnProperties.preserveDataAs}
                      onChange={(e) => this.handleChange(e, true)}
                    />
                  </Col>
                </Row>
              </>: null
              }
            </TabPane>
            {isTablePlugin ? (
              <TabPane tab={i18n.t("Dashboard.Data.WhatIf.Title")} key="2">
                <TableWhatIf
                  column={this.props.column}
                  plugin={this.props.plugin}
                  updateColumnProperties={this.props.updateColumnProperties}
                  columnProperties={this.state.columnProperties}
                />
              </TabPane>
            ) : null}
          </Tabs>
        </div>
      </div>
    );
  }
}
