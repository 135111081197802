import React, { Component } from "react";
import { Accordion, Card } from "react-bootstrap";
import "./accordion.css";
import PropTypes from "prop-types";

/**
 * Since accordion was used on react-bootstrap, we had to use AccordionComponent as a name.
 * General accordion component with default template.
 * React bootstrap accordion used
 * Style, class and children content can dynamically change
 */
export default class AccordionComponent extends Component {
  render() {
    let accordionClass = "accordion-component";

    if (this.props.accordionClass) {
      accordionClass += " " + this.props.accordionClass;
    }

    return (
      <div className={accordionClass} style={this.props.style}>
        <Accordion
          className={
            this.props.accordionClass
              ? "accordion " + this.props.accordionClass
              : "accordion"
          }
          style={this.props.accordionStyle ? this.props.accordionStyle : null}
          activeKey={this.props.activeKey}
          onSelect={this.props.onSelect ? this.props.onSelect : null}
          key={this.props.key}
        >
          {this.props.children}
        </Accordion>
      </div>
    );
  }
}

AccordionComponent.prototypes = {
  accordionClass: PropTypes.string,
  accordionStyle: PropTypes.object,
  defaultActiveKey: PropTypes.string,
  accordionActiveKey: PropTypes.func
};
