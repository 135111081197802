export const configuration = {
    isPluginNewPlugin: true,
    headerTextSize: "16",
    headerFont: "Verdana",
    headerFontDescription: "",
    secondaryColour: "#2127ad50",
    iconSize: "27",
    size: null,
    responsive: false,
    numFont: "Consolas",
    colour: "#2127ad",
    icon: "",
    animation: 0,
    many: "",
    hideLabel: false,
    showHideButton: false,
    condFormat: false,
    duration: "1",
    summary: "",
    isImage: false,
    imgSrc: "",
    imgWidth: 50,
    imgHeight: 50
};

export const actions = [
    {
        trigger: "click",
        type: "click",
        name: "Tıklama",
        output: ["measure"],
        description: ""
    }
];

export const reactions = [
    {
        id: "filter",
        name: "Filtre",
        description: "desc87",
        type: "general"
    }
];

export const conditionalFormatColumnMap = ["measure"];
export const conditionalFormatTargetMap = ["measure"];
export const conditionalFormatOptions = ["backgroundColor", "color", "icon"];

export const conversionMap = {
    "table": {
        columnMap: {
            columns: {
                name: "Columns",
                from: ["measure"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
        }
    },
    "gauge-chart": {
        columnMap: {
            measure: {
                name: "Measure",
                from: ["measure"],
                multiple: true,
                required: true,
                minimumColumnSize: 1
            },
            minimum: {
                name: "Minimum",
                from: [],
                required: false,
                minimumColumnSize: 0
            },
            maximum: {
                name: "Maximum",
                from: [],
                required: false,
                minimumColumnSize: 0
            },
            target: {
                name: "Target",
                from: [],
                required: false,
                minimumColumnSize: 0
            }
        }
    }
};