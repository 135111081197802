import React, { Component } from "react";
import Button from "../GeneralComponents/Button/Button";
import i18n from "../../Utils/i18next";
import OptionArea from "./OptionArea";
import Rule from "./Rule/Rule";
import TargetColumns from "./TargetColumns";
import { showNotificationWithIcon } from "../../Utils/Notification";
import uuid from "react-uuid";
import {
  checkGetAllConditionData,
  validateConditionalFormattingAttributes,
  deleteControlFlagsForAttributes
} from "./validationOperations";
import { findAndSetColumnsFromColumnMapForConditional } from "../GeneralComponents/SetColumnsFromColumnMap/SetColumnsFromColumnMap";

/**
 * Conditional formatting creating and updating component.
 */
export default class CreateUpdate extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      id: undefined,
      targetColumns: [], //column object.
      rule: {
        leftRule: "",
        rightRule: "",
        operator: "="
      },
      options: {},
      columnsToBeUsed: [],
      clickedApply: false
    };

    this.state = { ...this.initialState };
  }

  componentWillMount() {
    /** To prepare columns to be used. */
    let controlIsColumnsComesProps = this.props.columnMapWithDrills ? this.props.columnMapWithDrills : false
    let columnsToBeUsed = controlIsColumnsComesProps ? controlIsColumnsComesProps : findAndSetColumnsFromColumnMapForConditional(this.props);

    this.setState({
      ...this.state,
      ...this.props.edittingCondFormat,
      columnsToBeUsed: columnsToBeUsed
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.edittingCondFormat === undefined) {
      this.setState({ ...this.initialState });
    } else if (nextProps.edittingCondFormat !== this.props.edittingCondFormat) {
      this.setState({ ...this.state, ...nextProps.edittingCondFormat });
    } else if (nextProps.columnMap !== this.props.columnMap) {
      let controlIsColumnsComesProps = this.props.columnMapWithDrills ? this.props.columnMapWithDrills : false
      let columnsToBeUsed = controlIsColumnsComesProps ? controlIsColumnsComesProps : findAndSetColumnsFromColumnMapForConditional(nextProps);
      
      this.setState({ ...this.state, columnsToBeUsed: columnsToBeUsed });
    }
  }

  /** conditional format temp variable for apply process */
  appliedConditionalFormat = {};

  setTargetColumnForDefault = col => {
    //If we have default column for plugins(etc Pie Chart-Category, this sets Pie Chart's category column automatically)
    this.setState({
      ...this.state,
      targetColumns: col
    })
  }

  /**
   * Get conditional format data from sub components. //target columns, rule, option.
   */
  getConditionalFormatData = (data, type) => {
    if (data) {
      this.appliedConditionalFormat[type] = data;
      this.appliedConditionalFormat["has-" + type + "-value"] = true;

      if (checkGetAllConditionData(this.appliedConditionalFormat)) {
        if (
          validateConditionalFormattingAttributes(
            this.appliedConditionalFormat,
            this.state.columnsToBeUsed
          )
        ) {
          deleteControlFlagsForAttributes(this.appliedConditionalFormat);

          showNotificationWithIcon(
            i18n.t("Success"),
            i18n.t(
              "Dashboard.ConditionalFormatting.ConditionalFormatSuccessFullyAdded"
            ),
            "success"
          );

          this.setState({
            ...this.state,
            ...this.appliedConditionalFormat,
            clickedApply: false
          });

          let conditionalFormatObject = {
            targetColumns: this.appliedConditionalFormat.targetColumns,
            rule: this.appliedConditionalFormat.rule,
            options: this.appliedConditionalFormat.options
          };

          let conditionalFormatList = this.props.conditionalFormatList
            ? [...this.props.conditionalFormatList]
            : [];

          if (this.state.id) {
            conditionalFormatList.map(condFormat => {
              if (condFormat.id == this.state.id) {
                condFormat.targetColumns = [
                  ...conditionalFormatObject.targetColumns
                ];
                condFormat.rule = { ...conditionalFormatObject.rule };
                condFormat.options = { ...conditionalFormatObject.options };
              }
            });
          } else {
            conditionalFormatObject.id = uuid();
            conditionalFormatList.push(conditionalFormatObject);
          }

          this.appliedConditionalFormat = {};

          this.props.updateConditionalFormat("conditionalFormatting", {
            conditionalFormats: conditionalFormatList,
            pluginId: this.props.pluginId
          });

          this.props.closeEditting();
        } else {
          deleteControlFlagsForAttributes(this.appliedConditionalFormat);

          this.setState({
            ...this.state,
            clickedApply: false
          });
        }
      }
    }
  };

  /** set clicked apply for trigger sub components */
  apply = () => {
    this.setState({
      ...this.state,
      clickedApply: true
    });
  };

  /** Conditional format save button */
  saveButton = (
    <Button
      className={"general-button"}
      style={{ float: "right", margin: "0", width: "17%" }}
      onClick={this.apply}
      //onClick={() => this.props.setEdit(undefined)}
    >
      {i18n.t("Apply")}
    </Button>
  );

  /** Conditional format cancel button */
  cancelButton = (
    <Button
      onClick={() => this.props.closeEditting()}
      style={{
        float: "right",
        margin: "0 10px",
        borderBottom: "2px solid",
        width: "17%"
      }}
    >
      {i18n.t("Cancel")}
    </Button>
  );

  render() {
    return (
      <div className={"conditional-format-create-update"}>
        <TargetColumns
          getTargetColumns={this.getConditionalFormatData}
          columnsToBeUsed={this.state.columnsToBeUsed}
          clickedApply={this.state.clickedApply}
          targetColumns={this.state.targetColumns}
          conditionalFormatTargetMap={this.props.conditionalFormatTargetMap}
          isNecessaryAllColumns={this.props.isNecessaryAllColumns}
          defaultConditionalFormatColumn={this.props.defaultConditionalFormatColumn}
          setTargetColumnForDefault={this.setTargetColumnForDefault}
          isLockedTargetValue={this.props.isLockedTargetValue}
          isTargetColumnAllColumns={this.props.isTargetColumnAllColumns}
        />
        <Rule
          getRule={this.getConditionalFormatData}
          columnsToBeUsed={this.state.columnsToBeUsed}
          clickedApply={this.state.clickedApply}
          rule={this.state.rule}
        />
        <OptionArea
          pluginConditionalFormatOptions={
            this.props.pluginConditionalFormatOptions
          }
          getOptions={this.getConditionalFormatData}
          clickedApply={this.state.clickedApply}
          options={this.state.options}
          measureCompareType={this.props.measureCompareType}
        />

        <div className={"conditional-format-buttons"}>
          {this.cancelButton}
          {this.saveButton}
        </div>
      </div>
    );
  }
}
