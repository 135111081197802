import React, { Component } from "react";
import i18n from "../../../Utils/i18next";
import { API_BASE } from "../../../config";
import { get, post, put } from "../../../Utils/WebService";
import { Modal, Select, Tooltip, Radio, Divider } from "antd";
import Text from "../../GeneralComponents/Text/Text";
import { base64ToArrayBuffer, saveByteArray } from "../../../Utils/Download";
import { showNotificationWithIcon } from "../../../Utils/Notification";
import { deepCopy } from "../../../Utils/Global";
import Button from "../../GeneralComponents/Button/Button";
import "./generateReport.css"
import { isEqual } from "lodash";
import ParameterSettings from "./ParameterSettings/ParameterSettings";
import FormatSettings from "./FormatSettings/FormatSettings";

export default class GenerateReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            parameters: [],
            parametersTemp: {},
            extensions: [],
            templateExtension: "",
            availableExtensions: ["xlsx", "docx", "pdf", "html"],
            loading: {},
            refreshAllLoading: false,
            dynamicParameters: [],
            dependentParameters: new Map(),
            dependencyParameters: new Map(),
            errors: {},
            contentOnTop: false,
            contentOnBottom: false,
            options: {},
            outputSettings: false,
        }
    }

    /**
     * Input timer for onChange events
     */
    inpurTimer;

    /**
     * Shows error notification of error
     * 
     * @param {*} err 
     */
    errorMessage = (err, title = null, comingWithTitle = true, showNotification = true) => {
        let message = err

        if (comingWithTitle) {
            let parsedMessage = message.split(": ");
            let titleFromMessage = parsedMessage[0];

            title = title ? title : titleFromMessage;
            message = message.substring(titleFromMessage.length + 2, message.length);
        }

        if (showNotification) {
            showNotificationWithIcon(title ? title : message, title ? message : null, "error");
        }

        return message;
    }

    /**
     * Gets report with id
     * 
     * @param {*} id 
     */
    getReport = (id) => {
        let url = `${API_BASE}/reports/${id}`;

        const successFunc = (result) => {
            if (result.data) {
                let report = deepCopy(result.data);
                let convenience = report.template && report.template.file && report.template.file.data;

                this.setState({
                    ...this.state,
                    report: deepCopy(result.data),
                    convenience: convenience
                });
            } else {
                this.errorMessage(id, i18n.t("Designer.ReportNotFound"), false, true);
            }
        }

        const errorFunc = (error) => {
            this.errorMessage(id, error, false, true);
        }

        get(url, successFunc, errorFunc, false);
    }

    componentDidMount() {
        this.getReport(this.props.reportId);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.reportId !== this.props.reportId) {
            if (this.state.report && this.state.report["_id"] !== nextProps.reportId) {
                this.getReport(nextProps.reportId);
            }
        }

        if (nextProps.visibleModal && nextProps.visibleModal !== this.props.visibleModal) {
            this.generateButtonClick();
        }
    }

    /**
     * Downloads the xlsx formatted report which is generated from the template file, queries and parameters.
     */
    generateAndDownloadReport = () => {
        if (!this.props.reportId) {
            showNotificationWithIcon(i18n.t("Error"), i18n.t("Designer.YouNeedToSaveReportBeforeDownload"), "error");

            return;
        }

        if (!this.state.report.template.file) {
            showNotificationWithIcon(i18n.t("Error"), i18n.t("Designer.YouNeedToUploadTemplateFile"), "error");

            return;
        }

        let reportExtension = this.state.options.extension;

        if (!this.state.availableExtensions.includes(reportExtension)) {
            showNotificationWithIcon(i18n.t("Error"), i18n.t("Designer.PleaseUploadCompatipleTemplateFile"), "error");

            return;
        }

        let url = `${API_BASE}/reports/${this.props.reportId}/generate?extension=${reportExtension}&base64=true`;
        let requestBody = {
            parameters: this.state.parametersTemp,
        };

        let options = {
            fileName: this.state.options.fileName
        };

        if (["xlsx", "xls"].includes(this.state.templateExtension)) {
            options = deepCopy(this.state.options);

            delete options.extension

            requestBody.options = options;
        }

        const successFunc = (result) => {
            let report = result.data;

            if (report) {
                let reportName = `${this.state.report.name}.${reportExtension}`;
                let reportBytes = base64ToArrayBuffer(report);

                saveByteArray([reportBytes], reportName);

                showNotificationWithIcon(i18n.t("Success"), i18n.t("Designer.ReportDownloadSuccess"), "success");

                this.setState({
                    ...this.state,
                    visible: false
                });
            }
        }

        const errorFunc = (err) => {
            this.errorMessage(err, null, true, true);
        }

        post(url, requestBody, successFunc, errorFunc, true);
    }

    /**
     * Gets data of the selected parameter
     * 
     * @param {*} parameter 
     * @param {*} parametersTemp 
     * @returns 
     */
    getParameterData = async (parameter, parameters, parametersTemp) => {
        let dependencies = {};
        let url = `${API_BASE}/reports/${this.props.reportId}/parameter/${parameter.name}`;

        for (let dependencyName of this.state.dependentParameters.get(parameter.name) || []) {
            dependencies[dependencyName] = parametersTemp[dependencyName] || [];
        }

        const successFunc = (result) => {
            if (result.data) {
                let param = parameters.find(p => p.id === result.data.id);
                let index = parameters.indexOf(param);

                if (param) {
                    param = {
                        ...param,
                        ...result.data
                    };

                    if (!parametersTemp[param.name]) {
                        parametersTemp[param.name] = [];
                    }

                    parameters[index] = param;
                }
            }

            this.setState({
                ...this.state,
                loading: { ...this.state.loading, [parameter.id]: false },
                errors: { ...this.state.errors, [parameter.id]: null }
            });
        }

        const errorFunc = (err) => {
            let message = this.errorMessage(err, parameter.name, true, true);
            let param = parameters.find(p => p.id === parameter.id);
            let index = parameters.indexOf(param);

            if (param) {
                param.values = [];
                parameters[index] = param;

                if (parametersTemp[param.name]) {
                    delete parametersTemp[param.name];
                }
            }

            this.setState({
                ...this.state,
                loading: { ...this.state.loading, [parameter.id]: false },
                errors: { ...this.state.errors, [param.id]: message }
            });
        }

        this.setState({
            ...this.state,
            loading: { ...this.state.loading, [parameter.id]: true }
        });

        return post(url, dependencies, successFunc, errorFunc, false);
    }

    /**
     * Refreshes the parameter when refresh button is clicked
     * 
     * @param {*} parameter 
     */
    refreshParameter = (parameter) => {
        let parameters = deepCopy(this.state.parameters);
        let parametersTemp = deepCopy(this.state.parametersTemp);

        this.getParameterData(parameter, parameters, parametersTemp).then(() => {
            setTimeout(() => this.setState({
                ...this.state,
                parameters: parameters,
                parametersTemp: parametersTemp,
                history: this.state.parametersTemp
            }), 500)
        });
    }

    /**
     * Refreshes each parameter's data in the sent parameters list
     * 
     * @param {*} dynamicParameters 
     * @param {*} parameters 
     * @param {*} parametersTemp 
     * @param {*} index 
     */
    refreshParameters = (dynamicParameters, parameters, parametersTemp, callBack = () => { }, index = 0) => {
        let parameter = dynamicParameters[index];

        if (parameter) {
            this.getParameterData(parameter, parameters, parametersTemp)
                .then(() => {
                    if (dynamicParameters[index + 1]) {
                        this.refreshParameters(dynamicParameters, parameters, parametersTemp, callBack, index + 1);
                    } else {
                        callBack();
                    }
                });
        }
    }

    /**
     * Refreshes all the dynamic parameters
     */
    refreshAll = () => {
        this.setState({
            ...this.state,
            refreshAllLoading: true
        }, () => {
            let parameters = deepCopy(this.state.parameters);
            let parametersTemp = deepCopy(this.state.parametersTemp);
            let dynamicParameters = deepCopy(this.state.dynamicParameters);

            this.refreshParameters(dynamicParameters, parameters, parametersTemp, () => {
                this.setState({
                    ...this.state,
                    parameters: parameters,
                    parametersTemp: parametersTemp,
                    history: this.state.parametersTemp,
                    refreshAllLoading: false
                });
            });
        });
    }

    /**
    * Clears values of the parameters depends on the selected parameter
    * 
    * @param {*} parameterName 
    * @param {*} parametersTemp 
    * @returns 
    */
    clearDependents = (parameterName, parametersTemp) => {
        let dependents = this.state.dependencyParameters.get(parameterName);

        if (!dependents) {
            return;
        }

        for (let dependent of dependents) {
            delete parametersTemp[dependent];

            this.clearDependents(dependent, parametersTemp)
        }
    }

    /**
     * refresh the parameters depend on the selected parameter
     * 
     * @param {*} parameter 
     */
    refreshDependents = (parameter) => {
        let parameterName = parameter.name;
        let parameters = deepCopy(this.state.parameters);
        let parametersTemp = deepCopy(this.state.parametersTemp);
        let dependents = this.state.dependencyParameters.get(parameterName);
        let parametersWillRefresh = [];

        if (!dependents) {
            return;
        }

        this.setState({
            ...this.state,
            loading: { ...this.state.loading, [parameter.id]: true }
        }, () => {
            this.clearDependents(parameterName, parametersTemp);

            for (let dependent of dependents) {
                let dependentId = this.state.parameterIdMap[dependent];
                let dependencies = this.state.dependentParameters.get(dependent);

                let status = true;
                let dependentObj = {};

                for (let dependency of dependencies) {
                    let values = parametersTemp[dependency];

                    if (!values || values.length === 0) {
                        status = false;

                        break;
                    }

                    dependentObj[dependency] = values;
                }

                if (status) {
                    let parameter = parameters.filter(p => p.id === dependentId)[0];

                    parametersWillRefresh.push(parameter);
                }
            }

            if (parametersWillRefresh.length > 0) {
                this.refreshParameters(parametersWillRefresh, parameters, parametersTemp, () => {
                    this.setState({
                        ...this.state,
                        parameters: parameters,
                        parametersTemp: parametersTemp,
                        history: this.state.parametersTemp,
                        loading: { ...this.state.loading, [parameter.id]: false }
                    });
                });
            } else {
                this.setState({
                    ...this.state,
                    parameters: parameters,
                    parametersTemp: parametersTemp,
                    history: this.state.parametersTemp,
                    loading: { ...this.state.loading, [parameter.id]: false }
                });
            }
        })
    }

    /**
     * Sets value of single type parameter
     */
    setParameterValue = async (parameter, value) => {
        clearTimeout(this.inpurTimer);

        let parametersTemp = deepCopy(this.state.parametersTemp);

        parametersTemp[parameter.name] = { label: value, value: value };

        this.setState({
            ...this.state,
            parametersTemp: parametersTemp
        }, () => {
            if (!isEqual(this.state.history[parameter.name], parametersTemp[parameter.name])) {
                this.inpurTimer = setTimeout(() => this.refreshDependents(parameter), 500);
            }
        });
    }

    /**
     * Select value of multiple type parameter
     */
    selectParameterValue = (parameter, value, isMultiple, parameterRows) => {
        let parametersTemp = deepCopy(this.state.parametersTemp);

        if (value === "all") {
            parametersTemp[parameter.name] = [...parameterRows];
        } else if (value === "none") {
            parametersTemp[parameter.name] = [];
        } else {
            if (isMultiple) {
                parametersTemp[parameter.name].push(JSON.parse(value));
            } else {
                parametersTemp[parameter.name] = JSON.parse(value);
            }
        }

        this.setState({
            ...this.state,
            parametersTemp: parametersTemp
        }, () => {
            let isValueChanged = !isEqual(this.state.history[parameter.name], parametersTemp[parameter.name]);

            if (!isMultiple && isValueChanged) {
                this.refreshDependents(parameter);
            }
        });
    }

    /**
     * Deselect value of multiple type parameter
     */
    deselectParameterValue = (parameter, value, isMultiple) => {
        let parametersTemp = deepCopy(this.state.parametersTemp);

        if (isMultiple) {
            parametersTemp[parameter.name] = parametersTemp[parameter.name].filter(val => !isEqual(val, JSON.parse(value)));
        } else {
            parametersTemp[parameter.name] = [];
        }

        this.setState({
            ...this.state,
            parametersTemp: parametersTemp
        }, () => {
            let isValueChanged = !isEqual(this.state.history[parameter.name], parametersTemp[parameter.name]);

            if (this.state.activeDropdown !== parameter.name && isValueChanged) {
                this.refreshDependents(parameter);
            }
        });
    }

    /**
     * Sets opened dropdown id
     * Refreshes the parameters depend on the selected parameter when the "multiple" select dropdown is just closed
     * 
     * @param {*} parameter 
     * @param {*} open 
     */
    onSelectDropdownVisibleChange = (parameter, open) => {
        this.setState({
            ...this.state,
            activeDropdown: open ? parameter.name : null
        }, () => {
            let parameterValue = this.state.parametersTemp[parameter.name];
            let parameterHistory = this.state.history[parameter.name];
            let isValueChanged = !isEqual(parameterValue, parameterHistory);

            if (!open && parameter.isMultiple && isValueChanged) {
                this.refreshDependents(parameter);
            }
        });
    }

    /**
     * Gets the parameters which are in the request body
     * 
     * @param {*} requestBody 
     * @param {*} parameters 
     * @param {*} parametersTemp 
     * @param {*} loading 
     * @returns 
     */
    getParametersData = async (requestBody, parameters, parametersTemp, loading) => {
        let url = `${API_BASE}/reports/${this.props.reportId}/parameters`;

        const successFunc = (result) => {
            if (result.data) {
                for (let parameter of result.data) {
                    if (!parametersTemp[parameter.name]) {
                        parametersTemp[parameter.name] = [];
                    }
                }

                for (let index in parameters) {
                    let parameter = parameters[index]
                    let parameterFromResponse = result.data.filter(p => p.id === parameter.id);

                    if (parameterFromResponse && parameterFromResponse.length > 0) {
                        parameters[index] = {
                            ...parameters[index],
                            ...parameterFromResponse[0],
                            error: null
                        };
                    }
                }

                this.setVisible(true);
            }
        }

        const errorFunc = (err) => {
            this.errorMessage(err, null, true, true);
        }

        return post(url, requestBody, successFunc, errorFunc, loading);
    }

    /**
     * Returns each parameter that other parameters depend on
     * 
     * @param {*} dynamicParameters 
     * @returns 
     */
    getDependecyParameters = (dynamicParameters) => {
        let dependecyParameters = new Map();

        for (let dynamicParameter of dynamicParameters) {
            let seperator = new RegExp([" ", "\r\n", "\n", "=", ","].join("|"), "g");
            let parsedSQL = dynamicParameter.sql.split(seperator);
            let paramsInSQL = [];

            for (let word of parsedSQL) {
                if (word.startsWith(":")) {
                    paramsInSQL.push(word.substring(1));
                }
            }

            for (let dependency of paramsInSQL) {
                if (!dependecyParameters.has(dependency)) {
                    dependecyParameters.set(dependency, []);
                }

                if (!dependecyParameters.get(dependency).includes(dynamicParameter.name)) {
                    dependecyParameters.get(dependency).push(dynamicParameter.name);
                }
            }
        }

        return dependecyParameters;
    };

    /**
     * Returns each parameter depends on others
     * 
     * @param {*} dynamicParameters 
     * @returns 
     */
    getDependentParameters = (dynamicParameters) => {
        let dependentParameters = new Map();

        for (let dynamicParameter of dynamicParameters) {
            let seperator = new RegExp([" ", "\r\n", "\n", "=", ","].join("|"), "g");
            let parsedSQL = dynamicParameter.sql.split(seperator);
            let paramsInSQL = [];

            for (let word of parsedSQL) {
                if (word.startsWith(":")) {
                    paramsInSQL.push(word.substring(1));
                }
            }

            if (paramsInSQL && paramsInSQL.length > 0) {
                dependentParameters.set(dynamicParameter.name, paramsInSQL);
            }
        }

        return dependentParameters;
    };

    /**
     * Calculates the selected parameter's dependency level
     * 
     * @param {*} key 
     * @param {*} dependents 
     * @param {*} level 
     * @returns 
     */
    dependencyLevel(key, dependents, level = 0) {
        let dependencies = dependents.get(key);

        if (dependencies) {
            level++;

            for (let dependency of dependencies) {
                let dependencyLevel = this.dependencyLevel(dependency, dependents, level);

                level = dependencyLevel > level ? dependencyLevel : level;
            }
        }

        return level;
    }

    /**
     * Checks dependency errors
     * 
     * @param {*} key 
     * @param {*} dependents 
     * @param {*} control 
     * @returns 
     */
    checkDependencyError = (key, dependents, control = []) => {
        let dependencies = dependents.get(key);

        if (dependencies) {
            for (let dependency of dependencies) {
                if (dependency === key) {
                    return key;
                }

                if (control.includes(key)) {
                    return key;
                }

                if (this.checkDependencyError(dependency, dependents, [...control, key])) {
                    return key;
                }
            }
        }

        return null;
    }

    /**
     * Returns the format options for the report
     * If there is no saved format, returns templates default format options.
     */
    getFormatOptions = async () => {
        let url = `${API_BASE}/reports/${this.props.reportId}/template/format`;

        const successFunc = (result) => {
            if (result.data) {
                let options = result.data;
                let parsedTemplateFileName = this.state.report.template.file.name.split(".");
                let templateExtension = parsedTemplateFileName[parsedTemplateFileName.length - 1];;
                let extensions = [
                    {
                        label: i18n.t(`Designer.Extensions.${templateExtension}`),
                        value: templateExtension
                    },
                    {
                        label: i18n.t("Designer.Extensions.pdf"),
                        value: "pdf"
                    }
                ];

                let isExtensionConvenient = extensions.filter(e => e.value === options.extension).length > 0;

                options.extension = isExtensionConvenient ? options.extension : extensions[0].value;
                options.fileName = this.state.report.name;
                options.language = options.language ? options.language : i18n.language;

                this.setState({
                    options: options,
                    defaultOptions: options,
                    extensions: extensions,
                    templateExtension: templateExtension
                });
            }
        }

        return get(url, successFunc, null, true);
    }

    /**
     * Sets neccessary properties for parameter value selection
     * 
     * @param {*} extensions 
     */
    prepareToGenerateReport = () => {
        this.getFormatOptions()
            .then(() => {
                let parameters = deepCopy(this.state.report.parameters);

                if (parameters && parameters.length > 0) {
                    let staticParameters = parameters.filter(parameter => parameter.valueType === "static");
                    let dynamicParameters = parameters.filter(parameter => parameter.valueType === "dynamic");

                    let dependentParameters = this.getDependentParameters(dynamicParameters);
                    let dependencyParameters = this.getDependecyParameters(dynamicParameters);

                    let parameterIdMap = {};

                    for (let parameter of parameters) {
                        parameterIdMap[parameter.name] = parameter.id;
                    }

                    for (let parameter of dynamicParameters) {
                        let dependencyError = this.checkDependencyError(parameter.name, dependentParameters);

                        if (dependencyError) {
                            showNotificationWithIcon(i18n.t("Designer.PleaseCheckYourDependentParameters"), null, "error");

                            return;
                        }

                        parameter.level = this.dependencyLevel(parameter.name, dependentParameters);;
                    }

                    dynamicParameters = dynamicParameters.sort((p1, p2) => p1.level - p2.level);
                    parameters = [...staticParameters, ...dynamicParameters];

                    let requestBody = {};
                    let parametersTemp = {};

                    for (let parameter of parameters.filter(parameter => !dependentParameters.has(parameter.name))) {
                        requestBody[parameter.name] = {};
                    }

                    this.getParametersData(requestBody, parameters, parametersTemp, true)
                        .then(() => {
                            if (this.state.visible) {
                                let history = {};

                                for (let key of Object.keys(parametersTemp)) {
                                    history[key] = [];
                                }

                                this.setState({
                                    ...this.state,
                                    parameters: parameters,
                                    parametersTemp: parametersTemp,
                                    history: history,
                                    dynamicParameters: dynamicParameters,
                                    dependentParameters: dependentParameters,
                                    dependencyParameters: dependencyParameters,
                                    parameterIdMap: parameterIdMap,
                                    errors: {}
                                });
                            }
                        });
                } else {
                    this.setState({
                        ...this.state,
                        visible: true,
                    })
                }
            })
    }

    /**
      * Parameters content area on scroll handler
      * 
      * @param {*} event 
      */
    handleParametersContentOnScroll = (event) => {
        if (event.target.id === "parametersContent") {
            const { scrollTop } = event.target;

            this.setState({
                ...this.state,
                headerShadow: scrollTop > 0
            });
        }
    };

    /**
     * Sets modal visibility
     */
    setVisible = (status) => {
        this.setState({
            ...this.state,
            visible: status
        },
            () => {
                if (!status && this.props.toggleGenerateModal) {
                    this.props.toggleGenerateModal(false);
                }
            }
        );
    }

    /**
     * Sets selected output file type
     */
    setSelectedExtension = extension => {
        this.setState({
            ...this.state,
            selectedExtension: extension
        });
    }

    /**
     * Sets selected orientation
     */
    selectOrientation = value => {
        this.setState({
            ...this.state,
            orientation: value
        });
    }

    /**
     * Calculates the maximum tag count of selects
     * @returns 
     */
    maxSelectTagCount = () => {
        let screenWidth = document.body.clientWidth;
        let modalWidth = screenWidth / 2;
        let selectWidth = modalWidth - 96;

        return Math.round(selectWidth / 160);
    }

    /**
     * Sets options
     * 
     * @param {*} options 
     */
    setOptions = (options) => {
        this.setState({
            ...this.state,
            options: options
        });
    }

    /**
     * Sets options from template file
     */
    setFormatFromTemplate = () => {
        let url = `${API_BASE}/reports/utils/format`;
        let requestBody = {
            data: this.state.report.template.file.data,
            extension: this.state.report.template.file.name.split(".").pop()
        };

        const successFunc = (result) => {
            if (result.data) {
                let options = result.data;

                options.language = this.state.options.language ? this.state.options.language : i18n.language;

                this.setState({
                    ...this.state,
                    options: options
                });
            }
        }

        post(url, requestBody, successFunc);
    }

    /**
     * Sets output settings visible
     */
    setFormatSettingsVisible = (status) => {
        this.setState({
            ...this.state,
            outputSettings: status
        });
    }

    /**
     * Closes format settings and discards changes
     */
    cancelFormatSettings = () => {
        let options = deepCopy(this.state.defaultOptions);

        this.setState({
            ...this.state,
            options: options,
            outputSettings: false
        });
    }

    /**
     * Sets format settings for once
     */
    applyFormatSettingsForOnce = () => {
        let options = deepCopy(this.state.options);

        this.setState({
            ...this.state,
            options: options,
            defaultOptions: options,
            outputSettings: false
        });
    }

    /**
     * Saves options as default
     */
    applyFormatSettingsAsDefault = () => {
        let url = `${API_BASE}/reports/${this.props.reportId}/template/format`;

        const successFunc = () => {
            let report = deepCopy(this.state.report);

            report.template.options = this.state.options;

            this.setState({
                ...this.state,
                report: report,
                outputSettings: false
            });

            showNotificationWithIcon(i18n.t("Designer.SettingsSaveSuccess"), null, "success")
        }

        put(url, this.state.options, successFunc);
    }

    /**
     * Generate button click handler
     * 
     * If report is conveinence, starts generate process
     */
    generateButtonClick = () => {
        if (this.state.convenience) {
            this.prepareToGenerateReport();
        }
    }

    render() {
        let templateExists = this.state.report && this.state.report.template && this.state.report.template.file;
        let isReportSpreadSheet = ["xls", "xlsx"].includes(this.state.templateExtension);
        let tooltip = this.state.report ? templateExists ? "" : i18n.t("Designer.YouNeedToUploadTemplateFile") : i18n.t("Designer.ReportNotFound");

        return (
            <div className={this.props.innerClass ? this.props.innerClass : ""}>
                <Tooltip
                    title={tooltip}
                >
                    <div
                        key={this.props.reportId}
                        className={this.state.convenience ? "" : "disabled-btn"}
                        onClick={() => this.generateButtonClick()}
                    >
                        {this.props.children}
                    </div>
                </Tooltip>
                <Modal
                    title={`${i18n.t("Designer.GenerateReport")}`}
                    visible={this.state.visible}
                    closable={true}
                    maskClosable={false}
                    onCancel={() => this.setVisible(false)}
                    destroyOnClose={true}
                    centered={true}
                    className="generate-modal"
                    width="fit-content"
                    maskTransitionName="none"
                    closeIcon={(
                        <Button
                            style={{
                                borderRadius: "40px",
                                padding: "6px 11px",
                                backgroundColor: "white",
                            }}
                            type={"default"}
                            onClick={() => this.setVisible(false)}
                        >
                            X
                        </Button>
                    )}
                    footer={(
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginBottom: "4px"
                            }}
                        >
                            <button
                                type="button"
                                class="ant-btn ant-btn-default"
                                onClick={() => this.setVisible(false)}
                            >
                                <span>{i18n.t("Cancel")}</span>
                            </button>
                            <button
                                type="button"
                                class="ant-btn ant-btn-primary"
                                onClick={() => this.generateAndDownloadReport()}
                            >
                                <span>{i18n.t("Designer.GenerateReport")}</span>
                            </button>
                        </div>
                    )}
                >
                    <div style={{ width: "100%" }}>
                        <ParameterSettings
                            parameters={this.state.parameters}
                            parametersTemp={this.state.parametersTemp}
                            dependentParameters={this.state.dependentParameters}
                            dynamicParameters={this.state.dynamicParameters}
                            errors={this.state.errors}
                            loading={this.state.loading}
                            refreshAllLoading={this.state.refreshAllLoading}
                            contentOnBottom={this.state.contentOnBottom}
                            refreshAll={this.refreshAll}
                            refreshParameter={this.refreshParameter}
                            setParameterValue={this.setParameterValue}
                            selectParameterValue={this.selectParameterValue}
                            deselectParameterValue={this.deselectParameterValue}
                            onSelectDropdownVisibleChange={this.onSelectDropdownVisibleChange}
                            maxSelectTagCount={this.maxSelectTagCount}
                            isReportSpreadSheet={isReportSpreadSheet}
                        />
                        <Divider style={{ margin: "4px 0" }} />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                padding: "8px 8px 12px",
                                height: "fit-content",
                                maxHeight: "calc(100% - 157px)",
                                overflowY: "auto"
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: "8px"
                                }}
                            >
                                <Text
                                    className="ant-modal-title"
                                    style={{ color: "rgba(0, 0, 0, 0.65)" }}
                                >
                                    {i18n.t("Designer.FormatSettings")}
                                </Text>
                                {
                                    isReportSpreadSheet ? (
                                        <Button
                                            onClick={() => this.setFormatSettingsVisible(true)}
                                        >
                                            {i18n.t("Designer.AdvancedFormatSettings")}
                                        </Button>
                                    ) : null
                                }
                            </div>
                            <Text
                                style={{
                                    marginBottom: "8px"
                                }}
                            >
                                {i18n.t("Designer.FileType")}
                            </Text>
                            <Radio.Group
                                onChange={(event) => this.setOptions({
                                    ...this.state.options,
                                    extension: event.target.value
                                })}
                                value={this.state.options.extension}
                            >
                                {
                                    this.state.extensions.map(extension => (
                                        <Radio
                                            value={extension.value}
                                        >
                                            {extension.label}
                                        </Radio>
                                    ))
                                }
                            </Radio.Group>
                            {
                                isReportSpreadSheet ? (
                                    <>
                                        <Text
                                            style={{
                                                marginTop: "16px",
                                                marginBottom: "8px",
                                            }}
                                        >
                                            {i18n.t("Designer.FileLanguage")}
                                        </Text>
                                        <Radio.Group
                                            onChange={(event) => this.setOptions({
                                                ...this.state.options,
                                                language: event.target.value
                                            })}
                                            value={this.state.options.language}
                                            style={{
                                                marginBottom: 8,
                                            }}
                                        >
                                            <Radio value={"en"}>{`${i18n.t("Designer.English")}`}</Radio>
                                            <Radio value={"tr"}>{`${i18n.t("Designer.Turkish")}`}</Radio>
                                        </Radio.Group>
                                        <Text
                                            style={{
                                                marginTop: "16px",
                                                marginBottom: "8px",
                                            }}
                                        >
                                            {i18n.t("Orientation")}
                                        </Text>
                                        <Select
                                            showArrow
                                            showSearch
                                            maxTagCount={8}
                                            maxTagTextLength={10}
                                            optionParameterProp="children"
                                            optionFilterProp="label"
                                            value={this.state.options.orientation}
                                            onSelect={(value) => this.setOptions({
                                                ...this.state.options,
                                                orientation: value
                                            })}
                                            style={{
                                                width: "100%",
                                                border: "1px solid #dadada",
                                            }}
                                            getPopupContainer={(trigger) => trigger.parentNode}
                                        >
                                            <Select.Option value={"portrait"}>{`${i18n.t("Portrait")}`}</Select.Option>
                                            <Select.Option value={"landscape"}>{`${i18n.t("Landscape")}`}</Select.Option>
                                        </Select>
                                    </>
                                ) : null
                            }
                        </div>
                        <FormatSettings
                            options={this.state.options}
                            extensions={this.state.extensions}
                            templateExtension={this.state.templateExtension}
                            contentOnBottom={this.state.contentOnBottom}
                            setOptions={this.setOptions}
                            applyFormatSettingsAsDefault={this.applyFormatSettingsAsDefault}
                            applyFormatSettingsForOnce={this.applyFormatSettingsForOnce}
                            isReportSpreadSheet={isReportSpreadSheet}
                            outputSettings={this.state.outputSettings}
                            setFormatFromTemplate={this.setFormatFromTemplate}
                            setFormatSettingsVisible={this.setFormatSettingsVisible}
                            cancelFormatSettings={this.cancelFormatSettings}
                        />
                    </div>
                </Modal>
            </div>
        )
    }
}