import { SET_DATA_SOURCE_SELF_UTILS, SET_MODEL_ID_TO_MODELS_AREA_CALLBACK_FUNCTION } from './SelfServiceAction';
import { SET_MODEL_POPUP } from "./SelfServiceAction"
import { SET_DATASOURCES_LIST } from "./SelfServiceAction"
import { SET_DATASOURCE_ID } from "./SelfServiceAction"
import { SET_COMES_FROM_FILE_UPLOAD_STATUS } from "./SelfServiceAction"
import { SET_ADD_TABLE_CALLBACK_FUNCTION } from "./SelfServiceAction"

const initialState = {
  openFileUpload: false,
  dataSourceId: "",
  firstDataUpload: false,
  openModelPopup: false,
  dataSources: [],
  modelId: "",
  comesFromDashboardFileUpload: false,
  addTableCallbackFunction: () => { },
  setModelIdToModelsAreaCallbackFunction: () => { }
};

/*
* Sets data source self utils
*/
const setDataSourceSelfUtils = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA_SOURCE_SELF_UTILS:
      return {
        ...state,
        openFileUpload: action.payload.openFileUpload,
        dataSourceId: action.payload.dataSourceId,
        firstDataUpload: action.payload.firstDataUpload ? true : false,
        dataSources: action.payload.dataSources ? action.payload.dataSources : state.dataSources,
      };
    case SET_MODEL_POPUP:
      return {
        ...state,
        openModelPopup: action.payload.openModelPopup,
        modelId: action.payload.modelId
      };
    case SET_DATASOURCES_LIST:
      return {
        ...state,
        dataSources: action.payload
      }
    case SET_DATASOURCE_ID:
      return {
        ...state,
        dataSourceId: action.payload
      }
    case SET_COMES_FROM_FILE_UPLOAD_STATUS:
      return {
        ...state,
        comesFromDashboardFileUpload: action.payload
      }
    case SET_ADD_TABLE_CALLBACK_FUNCTION:
      return {
        ...state,
        addTableCallbackFunction: action.payload
      }
    case SET_MODEL_ID_TO_MODELS_AREA_CALLBACK_FUNCTION:
      return {
        ...state,
        setModelIdToModelsAreaCallbackFunction: action.payload
      }
    default:
      return state;
  }
};

export default setDataSourceSelfUtils;