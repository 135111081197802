import {
  BgColorsOutlined,
  FontSizeOutlined,
  FormatPainterOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import { Checkbox, Col, Divider, Input, Row, Select, Tooltip } from "antd";
import $ from "jquery";
import "rc-color-picker/assets/index.css";
import React, { Component } from "react";
import i18n from "../../../../Utils/i18next";
import Text from "../../../GeneralComponents/Text/Text";
import ThemePalette from "../../../GeneralComponents/ThemePalette/ThemePalette";
import TitleSettingsOfPlugin from "../../../GeneralComponents/TitleSettingsOfPlugin/TitleSettingsOfPlugin";
import { InsightsConfig } from "../../RenderJs/config";
import ReturnToTheme from "../../PluginToolsComponents/ReturnToTheme";

const { TextArea } = Input;
const { Option } = Select;

/**
* Configuration file of MapBubbles plugin
*/
export default class MapBubblesConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: this.props.config.title,
      titleAlign: this.props.config.titleAlign, //added titleAlign props
      width: this.props.config.width,
      height: this.props.config.height,
      backgroundColor: this.props.config.backgroundColor,
      colours: this.props.config.colours,
      bubbleLow: this.props.config.bubbleLow,
      bubbleHigh: this.props.config.bubbleHigh,
      stroke: this.props.config.stroke,
      opacity: this.props.config.opacity,
      styleType: this.props.config.styleType,
      seriesScale: this.props.config.seriesScale,
      mapTile: this.props.config.mapTile,
      mapTileSelection: this.props.config.mapTileSelection,
      voronoi: this.props.config.voronoi,
      legend: this.props.config.legend,
      legendLocation: this.props.config.legendLocation,
      showHideButton: this.props.config.showHideButton,
      paletteColours: InsightsConfig.Palettes[this.props.config.colours]
        ? InsightsConfig.Palettes[this.props.config.colours]
        : this.props.config.colours,
      titleFontStyle: this.props.config.titleFontStyle === undefined ? false : this.props.config.titleFontStyle,
      titleFontWeight: this.props.config.titleFontWeight === undefined ? false : this.props.config.titleFontWeight,
      titleFontSize: this.props.config.titleFontSize === undefined ? 15 : this.props.config.titleFontSize,
      titleFont: this.props.config.titleFont === undefined ? "Verdana" : this.props.config.titleFont,
      titleColour: this.props.config.titleColour === undefined ? "black" : this.props.config.titleColour,
      titleTextDecor: this.props.config.titleTextDecor === undefined ? false : this.props.config.titleTextDecor,
      changedTitleFontSize: this.props.config.changedTitleFontSize === undefined ? 15 : this.props.config.changedTitleFontSize,
      keyForTitleSize: null,
      noDataTitle: this.props.config.noDataTitle === undefined ? "" : this.props.config.noDataTitle
    };
  }

  /**
   * Plugin compenents receive it's created properties
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      this.setState({
        title: nextProps.config.title,
        titleAlign: nextProps.config.titleAlign, //added titleAlign props
        width: nextProps.config.width,
        height: nextProps.config.height,
        backgroundColor: nextProps.config.backgroundColor,
        colours: nextProps.config.colours,
        bubbleLow: nextProps.config.bubbleLow,
        bubbleHigh: nextProps.config.bubbleHigh,
        stroke: nextProps.config.stroke,
        opacity: nextProps.config.opacity,
        styleType: nextProps.config.styleType,
        seriesScale: nextProps.config.seriesScale,
        mapTile: nextProps.config.mapTile,
        mapTileSelection: nextProps.config.mapTileSelection,
        voronoi: nextProps.config.voronoi,
        legend: nextProps.config.legend,
        legendLocation: nextProps.config.legendLocation,
        showHideButton: nextProps.config.showHideButton,
        paletteColours: InsightsConfig.Palettes[nextProps.config.colours]
          ? InsightsConfig.Palettes[nextProps.config.colours]
          : nextProps.config.colours,
        titleFont: nextProps.config.titleFont === undefined ? "Verdana" : nextProps.config.titleFont,
        titleFontSize: nextProps.config.titleFontSize === undefined ? 15 : nextProps.config.titleFontSize,
        changedTitleFontSize: nextProps.config.changedTitleFontSize === undefined ? 15 : nextProps.config.changedTitleFontSize,
        titleFontStyle: nextProps.config.titleFontStyle === undefined ? false : nextProps.config.titleFontStyle,
        titleFontWeight: nextProps.config.titleFontWeight === undefined ? false : nextProps.config.titleFontWeight,
        titleTextDecor: nextProps.config.titleTextDecor === undefined ? false : nextProps.config.titleTextDecor,
        titleColour: nextProps.config.titleColour === undefined ? "black" : nextProps.config.titleColour,
        noDataTitle: nextProps.config.noDataTitle === undefined ? "" : nextProps.config.noDataTitle
      });
    }
  }

  /*
  * Changes both of constant and changed value 
  * constant = between 10 and 30 values
  * changed value = all of values
  */
  handleChangeBothOfValues = (constantValue, changedValue) => {
    this.setState({
      ...this.state,
      titleFontSize: constantValue,
      changedTitleFontSize: changedValue
    })

    this.setConfigToPlugin(
      [
        {
          type: "titleFontSize",
          value: constantValue
        },
        {
          type: "changedTitleFontSize",
          value: changedValue
        }
      ]);
  }

  //sets defaults we get from plugin.jsx. callback for fontsizekey because it should keep changing to trigger default revert
  setDefaultsForTitle = () => {
    let newConfig = this.props.setDefaultForPluginTitle();

    this.setState(
      {
        ...this.state,
        title: newConfig.title,
        titleAlign: newConfig.titleAlign,
        titleFont: newConfig.titleFont,
        titleFontSize: newConfig.titleFontSize,
        titleFontStyle: newConfig.titleFontStyle,
        titleFontWeight: newConfig.titleFontWeight,
        titleTextDecor: newConfig.titleTextDecor,
        titleColour: newConfig.titleColour,
        changedTitleFontSize: newConfig.titleFontSize
      }, () => {
        this.setConfigToPlugin({ type: "changedTitleFontSize", value: newConfig.titleFontSize })
      }
    );

    if (newConfig.titleFontSize > 10 || newConfig.titleFontSize < 30) {
      $("#titleFontSize").css("background", "")
    }
  }

  titleColourChangeVisible = () => {
    this.setState({
      ...this.state,
      titleColourChange: !this.state.titleColourChange
    });
  };

  /**
   * Changes the value of text attributes in MapBubbles plugin use handleChange
   */
  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.value });
  };

  /**
   * Checks whether checkbox is checked or not in MapBubbles plugin use handleCheck
   */
  handleCheck = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.checked });
  };

  /**
   * Updates state attributes in MapBubbles plugin
   */
  setConfigToPlugin = (changedObj, propertyListToDelete = undefined) => {
    let tempState = { ...this.state };

    if (Array.isArray(changedObj)) {
      for (let i = 0; i < changedObj.length; i++) {
        let object = changedObj[i];
        tempState[object.type] = object.value;
      }
    } else {
      tempState[changedObj.type] = changedObj.value;
    }

    if (propertyListToDelete !== undefined) {
      for (let item of propertyListToDelete) {
        delete tempState[item]
      }
    }

    this.props.updateConfig(
      tempState,
      this.props.pluginId,
      propertyListToDelete
    );
  };

  /**
   * Changes the icon attribute in MapBubbles plugin use iconOnChange
   */
  iconOnChange = value => {
    this.setState({
      ...this.state,
      icon: value
    });

    this.setConfigToPlugin({ type: "icon", value: value });
  };

  /**
   * Changes the title attribute in MapBubbles plugin use titleAlignOnChange
   */
  titleAlignOnChange = value => {
    this.setState({
      ...this.state,
      titleAlign: value
    });

    this.setConfigToPlugin({ type: "titleAlign", value: value });
  };

  /**
   * Changes the color attribute in MapBubbles plugin use colorOnChange
   */
  colorOnChange = obj => {
    this.setState({
      ...this.state,
      colour: obj.color
    });

    this.setConfigToPlugin({ type: "colour", value: obj.color });
  };

  /**
   * Changes the background colour attribute in MapBubbles plugin use backgroundColorOnChange
   */
  backgroundColorOnChange = obj => {
    this.setState({
      ...this.state,
      backgroundColor: obj.color
    });

    this.setConfigToPlugin({ type: "backgroundColor", value: obj.color });
  };

  /**
   * Demonstrates how the choosen color will affect the interface in MapBubbles plugin
   */
  backgroundColorChangeVisible = () => {
    this.setState({
      ...this.state,
      backgroundColorChange: !this.state.backgroundColorChange
    });
  };

  /**
   * Changes the selected colour attribute in MapBubbles plugin
   */
  selectedColorOnChange = obj => {
    this.setState({
      ...this.state,
      selectedColor: obj.color
    });

    this.setConfigToPlugin({ type: "selectedColor", value: obj.color });
  };

  /**
   * Demonstrates how the choosen color will affect the interface in MapBubbles plugin
   */
  selectedColorChangeVisible = () => {
    this.setState({
      ...this.state,
      selectedColorChange: !this.state.selectedColorChange
    });
  };

  /**
   * Changes the theme attribute in MapBubbles plugin
   */
  themeOnChange = obj => {
    this.setState({
      ...this.state,
      theme: obj
    });

    this.setConfigToPlugin({ type: "theme", value: obj });
  };

  /**
   * Changes the legendLocation attribute in MapBubbles plugin
   */
  legendLocationOnChange = value => {
    this.setState({
      ...this.state,
      legendLocation: value
    });

    this.setConfigToPlugin({ type: "legendLocation", value: value });
  };

  /**
   * Changes the colours attribute in MapBubbles plugin
   */
  coloursOnChange = value => {
    this.setState({
      ...this.state,
      colours: value
    });

    this.setConfigToPlugin({ type: "colours", value: value });
  };

  /**
   * Changes the mapTileSelection attribute in MapBubbles plugin
   */
  mapTileSelectionOnChange = value => {
    this.setState({
      ...this.state,
      mapTileSelection: value
    });

    this.setConfigToPlugin({ type: "mapTileSelection", value: value });
  };

  /**
   * Changes the styleType attribute in MapBubbles plugin
   */
  styleTypeOnChange = value => {
    this.setState({
      ...this.state,
      styleType: value
    });

    this.setConfigToPlugin({ type: "styleType", value: value });
  };

  /**
   * Changes the seriesScale attribute in MapBubbles plugin
   */
  seriesScaleOnChange = value => {
    this.setState({
      ...this.state,
      seriesScale: value
    });

    this.setConfigToPlugin({ type: "seriesScale", value: value });
  };

  /**
   * Changes the MapTile attribute in MapBubbles plugin use mapTileOnChange
   */
  mapTileOnChange = obj => {
    this.setState({
      ...this.state,
      mapTile: obj
    });

    this.setConfigToPlugin({ type: "mapTile", value: obj });
  };

  render() {
    return (
      <div style={{ color: "#505050" }}>
        <ReturnToTheme
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          preservedConfigFieldsArray={this.props.plugin.preservedConfigFields ? Array.from(this.props.plugin.preservedConfigFields) : []}
        />

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.GeneralAppearance")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Palette")}
            </Text>
          </Col>
          <Col span={4} style={{ paddingRight: "10px" }}>
            <Select
              id={"colours"}
              defaultValue={this.state.colours}
              value={this.state.colours}
              style={{ width: "100%" }}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={this.coloursOnChange}>
              <Option value="Flat-UI">{i18n.t("Dashboard.Configuration.Fields.FlatUI")}</Option>
              <Option value="Flat-UI-Soft">{i18n.t("Dashboard.Configuration.Fields.FlatUISoft")}</Option>
              <Option value="Cool-Scale">{i18n.t("Dashboard.Configuration.Fields.CoolScale")}</Option>
              <Option value="Warm-Scale">{i18n.t("Dashboard.Configuration.Fields.WarmScale")}</Option>
              <Option value="Heatmap">{i18n.t("Dashboard.Configuration.Fields.Heatmap")}</Option>
            </Select>
          </Col>
          <Col span={8}>
            <ThemePalette
              colours={this.state.paletteColours}
              update={this.setConfigToPlugin}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.BubbleLow")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"bubbleLow"}
              defaultValue={this.state.bubbleLow}
              value={this.state.bubbleLow}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.BubbleHigh")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"bubbleHigh"}
              defaultValue={this.state.bubbleHigh}
              value={this.state.bubbleHigh}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Stroke")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"stroke"}
              defaultValue={this.state.stroke}
              value={this.state.stroke}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Opacity")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"opacity"}
              defaultValue={this.state.opacity}
              value={this.state.opacity}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.MapTile")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FormatPainterOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.MapTile")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              showSearch
              style={{ width: "100%" }}
              getPopupContainer={(trigger) => trigger.parentNode}
              placeholder={i18n.t("Dashboard.Configuration.Fields.MapTileSelection")}
              optionFilterProp="children"
              onChange={this.mapTileOnChange}
              defaultValue={this.state.mapTile}
            >
              <Option value="Default">{i18n.t("Default")}</Option>
              <Option value="EmptyTile">EmptyTile</Option>
              <Option value="BasarSoftTile">BasarSoftTile</Option>
              <Option value="OSM">OSM</Option>
              <Option value="AtlasOSM">AtlasOSM</Option>
              <Option value="Topology">Topology</Option>
              <Option value="Hydda">Hydda</Option>
              <Option value="Toner">Toner</Option>
              <Option value="Watercolour">Watercolour</Option>
              <Option value="ESRI">ESRI</Option>
              <Option value="Satellite">Satellite</Option>
              <Option value="Night">Night</Option>
            </Select>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.MapTileSelection")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              showSearch
              style={{ width: "100%" }}
              getPopupContainer={(trigger) => trigger.parentNode}
              placeholder={i18n.t("Dashboard.Configuration.Fields.MapTileSelection")}
              optionFilterProp="children"
              onChange={this.mapTileSelectionOnChange}
              defaultValue={this.state.mapTileSelection}
            >
              <Option value="Atlas Map Tile">Atlas Map Tile</Option>
              <Option value="Open Street Map Tile">Open Street Map Tile-</Option>
              <Option value="BasarSoft Tile">BasarSoft Tile</Option>
            </Select>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Voronoi")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"voronoi"}
              defaultChecked={this.state.voronoi}
              checked={this.state.voronoi}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Legend")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"legend"}
              defaultChecked={this.state.legend}
              checked={this.state.legend}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.LegendLocation")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              showSearch
              style={{ width: "100%" }}
              getPopupContainer={(trigger) => trigger.parentNode}
              placeholder={i18n.t("Dashboard.Configuration.Fields.LegendLocation")}
              optionFilterProp="children"
              onChange={this.legendLocationOnChange}
              defaultValue={this.state.legendLocation}
            >
              <Option value="left">{i18n.t("Dashboard.Configuration.Fields.Left")}</Option>
              <Option value="right">{i18n.t("Dashboard.Configuration.Fields.Right")}</Option>
              <Option value="up">{i18n.t("Dashboard.Configuration.Fields.Up")}</Option>
              <Option value="down">{i18n.t("Dashboard.Configuration.Fields.Down")}</Option>
            </Select>
          </Col>
        </Row>
        <TitleSettingsOfPlugin
          plugin={this.props.plugin}
          handleChange={this.handleChange}
          commonTitleConfig={this.props.commonTitleConfig}
          updateCommonTitleConfig={this.props.updateCommonTitleConfig}
          isReturnToDefaultforTitleVisible={this.props.isReturnToDefaultforTitleVisible}
          setDefaultsForTitle={this.setDefaultsForTitle}
          title={this.state.title}
          titleAlign={this.state.titleAlign}
          titleColour={this.state.titleColour}
          titleColourChange={this.state.titleColourChange}
          titleColourChangeVisible={this.titleColourChangeVisible}
          titleFont={this.state.titleFont}
          titleFontSize={this.state.titleFontSize}
          keyForTitleSize={this.state.keyForTitleSize}
          titleFontStyle={this.state.titleFontStyle}
          titleFontWeight={this.state.titleFontWeight}
          titleTextDecor={this.state.titleTextDecor}
          setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
          currentAppliedConfig={this.props.currentAppliedConfig}
          changedTitleFontSize={this.state.changedTitleFontSize}
          handleChangeBothOfValues={this.handleChangeBothOfValues}
        />
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Others")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left", display: "flex" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Settings.NoDataConfiguration")}
            </Text>
            <Tooltip
              title={
                <>
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Info")} <br />
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.GraphicConfiguration")} <br />
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Settings")} <br />
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.DefaultText")} <br />
                </>
              }
            >
              <InfoCircleOutlined style={{ position: 'relative', marginTop: '5px', marginLeft: "10px" }} />
            </Tooltip>
          </Col>
          <Col span={12}>
            <TextArea
              allowClear
              id={"noDataTitle"}
              defaultValue={this.state.noDataTitle}
              value={this.state.noDataTitle}
              onChange={this.handleChange}
              autoSize={{ minRows: 3, maxRows: 5 }}
              placeholder={i18n.t("NoDataContent.NoData") + " " +
                i18n.t("NoDataContent.PluginCouldNotBeVisualized")}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
