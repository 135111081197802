import React, { Component } from "react";
import i18n from "../../../Utils/i18next";
import { TbLayoutDashboard } from "react-icons/tb";
import { Button, Col, Divider, Popconfirm, Popover, Row, Switch, Tooltip } from "antd";
import "./customDashboard.css"
import { MdOutlineDomainVerification } from "react-icons/md";
import { DeleteOutlined, ShrinkOutlined } from "@ant-design/icons";
import { changeCustomDashboardStatus } from "./CustomDashboardAction";
import Text from "../Text/Text";
import { store } from "../../../index";
import { isValidWriteRoles } from "../../DashboardPage/RoleStore";
import { getIcon } from "../../../Utils/Global";

/*
* Custom dashboard switch dashboard, set main dashboard and delete dashboard component
*/
export default class CustomDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
    }

    setVisible = () => {
        this.setState({
            visible: true
        })
    }

    onChangeCustomDashboardStatus = (status) => {
        store.dispatch(changeCustomDashboardStatus(status))

        this.setState({
            ...this.state,
            customDashboardStatus: status
        })
    }

    render() {
        let buttonTheme = this.props.theme?.buttons?.customizedDashboardsButton;
        let isMainDashboardActive = this.props.dashboard && this.props.mainDashboard && this.props.dashboard.id === this.props.mainDashboard.id;
        let mainDashboardClass = `dashboard-item ${isMainDashboardActive ? "active-custom-dashboard" : ""}`;
        let isUserValidRole = isValidWriteRoles() ? true : false

        const titleComponent = (
            <div>
                <div style={{ marginTop: 8, marginBottom: 8, display: "flex", justifyContent: "space-between" }}>
                    <Text
                        type="span"
                        style={{
                            whiteSpace: "normal",
                            fontSize: "15px",
                            display: "inline-block",
                            marginTop: "2px",
                            color: "rgba(0, 0, 0, 0.65)",
                            fontWeight: "bold"
                        }}
                    >
                        {i18n.t("CustomDashboard.CustomDashboard")}
                    </Text>
                    {
                        !isUserValidRole ? (
                            <Switch
                                id="customDashboardButton"
                                onChange={this.onChangeCustomDashboardStatus}
                                checked={this.state.customDashboardStatus}
                            />
                        ) : null
                    }
                </div>
            </div>)

        const content = this.props.mainDashboard ? (
            <div className="dashboard-item-main">
                <Divider className={"custom-dashboard-title"} style={{ margin: "5px 0" }} orientation={"center"}>
                    {i18n.t("CustomDashboard.MainDashboard")}
                </Divider>

                <div
                    onClick={() => this.props.getDashboard({ ...this.props.mainDashboard, dashboardId: this.props.mainDashboard.id }, false)}
                    className={mainDashboardClass + " dashboard-item-main-dashboard"}
                >
                    <Tooltip title={this.props.mainDashboard.settings.title}>
                        {this.props.mainDashboard.settings.title}
                    </Tooltip>
                </div>

                <Divider className={"custom-dashboard-title"} orientation={"center"} style={{ marginTop: "5px", marginBottom: "5px" }}>
                    {i18n.t("CustomDashboard.CustomDashboards")}
                </Divider>
                {this.props.customDashboards.length > 0 ? this.props.customDashboards.map((customDashboard, i) => {
                    let isDashboardActive = this.props.dashboard.id === customDashboard.id;
                    let className = `dashboard-item ${isDashboardActive ? "active-custom-dashboard" : ""}`;

                    return (
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Tooltip title={customDashboard.settings.title}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div
                                        className={className}
                                        onClick={() => this.props.getDashboard({ ...customDashboard, dashboardId: customDashboard.id }, true, true)}
                                        style={isUserValidRole ? { width: "225px" } : { width: "250px" }}
                                    >
                                        {customDashboard.settings.title}
                                    </div>
                                </div>
                            </Tooltip>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {isValidWriteRoles() ?
                                    <Tooltip title={i18n.t("CustomDashboard.SetAsMainDashboard")}>
                                        <MdOutlineDomainVerification style={{ marginLeft: "5px", fontSize: "19px", color: "#4e7da2", marginBottom: "5px" }} onClick={() => this.props.setCustomDashboardAsMainDashboard(customDashboard.id, customDashboard.settings.title)} />
                                    </Tooltip> : null}
                                <Tooltip title={i18n.t("Delete")}>
                                    <Popconfirm placement="bottomRight" title={i18n.t("CustomDashboard.AreYouSureDeleteError")} onConfirm={() => this.props.deleteCustomDashboard(customDashboard.id)} okText="Yes" cancelText="No">
                                        <DeleteOutlined style={{ color: "red", marginLeft: "5px", marginBottom: "5px" }} />
                                    </Popconfirm>
                                </Tooltip>
                            </div>
                        </div>
                    );
                })
                    : <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", height: "75px" }}>
                        <TbLayoutDashboard style={{ fontSize: "70px", position: "absolute", opacity: "0.1" }} />
                        <span style={{ fontSize: "13px" }}>{i18n.t("CustomDashboard.CustomDashboardIsNull")}</span>
                    </div>}
            </div>) : null;

        return (
            <div>
                <Tooltip title={i18n.t("CustomDashboard.CustomDashboard")}>
                    <Popover content={content} title={titleComponent} trigger="click" overlayStyle={{ width: "300px" }} placement="bottomRight" overlayClassName="custom-dashboard-popover">
                        <Button
                            id="customDashboards"
                            tooltip={i18n.t("CustomDashboard.CustomDashboard")}
                            className={"dashboard-button btn btn-new circle-button hide-on-mobile"}
                            style={{
                                float: "right",
                                marginLeft: "5px",
                                display: "inherit",
                                fontSize: "21px"
                            }}
                            onClick={() => this.setVisible}
                        >
                            <style>
                                {`
                                    #customDashboards {
                                        background-color: ${buttonTheme?.backgroundColor};
                                        color: ${buttonTheme?.textColor};
                                        border-color: ${buttonTheme?.textColor}33;
                                    }

                                    #customDashboards:hover,
                                    #customDashboards:focus,
                                    #customDashboards:active {
                                        background-color: ${buttonTheme?.backgroundColorActive};
                                        color: ${buttonTheme?.textColorActive};
                                        border-color: ${buttonTheme?.textColorActive}33;
                                    }
                                `}
                            </style>
                            {
                                getIcon(
                                    buttonTheme?.icon,
                                    <TbLayoutDashboard />
                                )
                            }
                        </Button>
                    </Popover>
                </Tooltip>
            </div>
        );
    }
}
