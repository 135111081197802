import React, { Component } from "react";
import SwitchAreaOpenCloseButton from "./SwitchAreaOpenCloseButton";
import LabelList from "./LabelList";
import DashboardList from "./DashboardList";
import "./switchDashboard.css";
import {
  AppstoreOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined
} from "@ant-design/icons";
import Text from "../../GeneralComponents/Text/Text";
import i18n from "../../../Utils/i18next";
import Tooltip from "../../GeneralComponents/Tooltip/Tooltip";
import $ from "jquery";
import _ from "lodash";

/**
 * Switch dashboard
 * List label for find dashboard.
 * To switch to another dashboard while in a dashboard.
 */
export default class SwitchDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibility: false,
      selectedLabel: "",
      defaultLabelId: "",
      expand: false
    };

    this.wrapperRef = React.createRef();
  }

  componentWillMount() {
    this.findSelectedLabelInQueryString();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * If click outside hide switcher area.
   */
  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.current?.contains(event.target)) {
      if (!$(event.target).closest("#loginPopup").length > 0) {
        this.changeVisibility(false);
      }
    }
  };

  componentDidUpdate() {
    let selectedLabel = this.state.selectedLabel
    let copiedSelectedLabel = { ...selectedLabel }
    let isSelectedLabelAvailable = selectedLabel && selectedLabel !== "" ? true : false
    let isSelectedLabelsTranslationEqualsNotTranslatedVersion = selectedLabel.label !== i18n.t(selectedLabel.label)
    let isTranslateNecessary = isSelectedLabelAvailable && isSelectedLabelsTranslationEqualsNotTranslatedVersion ? true : false

    if (isTranslateNecessary) {
      copiedSelectedLabel.label = i18n.t(selectedLabel.label)

      this.setState({
        ...this.state,
        selectedLabel: copiedSelectedLabel
      })
    }
  }

  /**
   * parse query string and find label
   */
  findSelectedLabelInQueryString = () => {
    let queryString = window.location.search.substring(1);

    let queryStringArray = queryString && queryString.split("&");
    let queryStringMap = new Map();

    for (let i = 0; i < queryStringArray.length; i++) {
      let item = queryStringArray[i];
      let keyAndValue = item.split("="); // 0 = key, 1 = value;
      queryStringMap.set(keyAndValue[0], keyAndValue[1]);
    }

    this.setState({
      ...this.state,
      defaultLabelId: queryStringMap.get("labelId")
    });
  };

  /**
   * For dashboard swither area open/close status changer
   */
  changeVisibility = status => {
    let expandStatus = this.state.expand;

    if (status === false) {
      expandStatus = false;
    }

    this.setState({
      ...this.state,
      visibility: status,
      expand: expandStatus
    });
  };

  /**
   * Select a label in a list.
   */
  updateSelectedLabel = selectedLabel => {
    this.setState({
      ...this.state,
      selectedLabel: selectedLabel,
      defaultLabelId: selectedLabel.value
    });
  };

  render() {
    let theme = this.props.themeObj?.sideBar;
    let backgroundColor = theme?.backgroundColor;
    let textColor = theme?.textColor;

    let switchAreaClass = "switch-area " + (theme?.alignment || "left");
    let switchDashboardClass = "switch-dashboard switch-dashboard-scroll";

    if (this.state.visibility === true) {
      switchAreaClass = switchAreaClass + " visible";
    }

    if (this.state.expand === true) {
      switchDashboardClass = "switch-dashboard switch-dashboard-scroll expand";
    }

    return (
      <div
        className={switchAreaClass}
        ref={this.wrapperRef}
        hidden={theme?.visibility === false}
      >
        <SwitchAreaOpenCloseButton
          expand={this.state.expand}
          visibility={this.state.visibility}
          changeVisibility={this.changeVisibility}
          alignment={theme?.alignment}
        />
        <div
          className={switchDashboardClass}
        >
          <div
            className={"expand-trigger-area"}
            onClick={() =>
              this.setState({ ...this.state, expand: !this.state.expand })
            }
          ></div>
          <div className={"switch-dashboard-title"}>
            <AppstoreOutlined className={"title-icon"} />{" "}
            <Text className="title-content" >
              {i18n.t("Dashboard.SwitchDashboard")}
            </Text>
          </div>

          <div className="strait-title">
            <div className="strait-inner-text">
              <Text >{i18n.t("Dashboards")}</Text>
            </div>
          </div>

          <LabelList
            selectedLabel={this.state.selectedLabel}
            updateSelectedLabel={this.updateSelectedLabel}
            defaultLabelId={this.state.defaultLabelId}
            newLabel={this.props.newLabel}
            backgroundColor={backgroundColor}
            textColor={textColor}
          />

          <DashboardList
            isDashboardSave={this.props.isDashboardSave}
            currentDashboardId={this.props.currentDashboardId}
            getDashboard={this.props.getDashboard}
            selectedLabel={this.state.selectedLabel}
            defaultLabelId={this.state.defaultLabelId}
            updateNavigationsAfterSwitchDashboard={this.props.updateNavigationsAfterSwitchDashboard}
            isMainDashboardChangedFlag={this.props.isMainDashboardChangedFlag}
            dashboard={this.props.dashboard}
            backgroundColor={backgroundColor}
            textColor={textColor}
          />

          <Tooltip tooltipPlacement="right" tooltip={i18n.t("Expand")}>
            <div
              className="switch-dashboard-expand-button"
              onClick={() =>
                this.setState({ ...this.state, expand: !this.state.expand })
              }
            >
              <ArrowLeftOutlined
                style={{ position: "relative", left: "1px" }}
              />
              <ArrowRightOutlined
                style={{ position: "relative", right: "1px" }}
              />
            </div>
          </Tooltip>
        </div>

        <style>
          {theme && `
          .switch-dashboard {
              background-color: ${backgroundColor};
              border-color: ${backgroundColor};
          }
        
          .switch-visible-button {
              background-color: ${backgroundColor};
              border: 2px solid ${backgroundColor};
          }
        
          .switch-dashboard-scroll::-webkit-scrollbar-track {
              background-color: ${backgroundColor};
          }
        
          .switch-dashboard-scroll::-webkit-scrollbar {
              background-color: ${backgroundColor};
          }
        
          .switch-visible-button .switch-icon {
              color: ${textColor};
          }
        
          .switch-area.visible .switch-visible-button {
              background: ${textColor}22;
          }
        
          .switch-dashboard-title {
              color: ${textColor};
          }
        
          .switch-dashboard-list {
              color: ${textColor};
          }
        
          .switch-dashboard-item:hover {
              background: ${textColor}33;
          }
        
          .switch-dashboard-item .switch-dashboard-item-circle .inner-circle {
              background: ${textColor}00;
          }
        
          .switch-dashboard-item.selected .switch-dashboard-item-circle .inner-circle {
              background: ${textColor};
          }
        
          .switch-dashboard-list-title {
              color: ${textColor};
          }
        
          .switch-dashboard-scroll::-webkit-scrollbar-thumb {
              background-color: ${textColor}88;
          }
        
          .switch-dashboard-label-list-title {
              color: ${textColor};
          }
    
          .switch-dashboard-expand-button {
              color: ${textColor}88;
          }
        
          .switch-dashboard.expand .switch-dashboard-expand-button {
              color: ${textColor};
          }
        
          .switch-dashboard .subtitle-icon {
              color: ${textColor};
          }
        
          .switch-dashboard .strait-title .strait-inner-text {
              color: ${textColor};
          }
        `}
        </style>

        <style id="sideBarCSS" theme={this.props.themeObj?.name}>
          {theme?.css}
        </style>
      </div>
    );
  }
}
