import React, { Component } from "react";
import { Modal } from "antd";
import i18n from "../../../Utils/i18next"
import SessionVariableLayout from "../SessionVariable/SessionVariableLayout"
import "./sessionVariableModal.css";

/** 
 * Join layout component in modal is called to join operation in this component.
 */
export default class SessionVariableModal extends Component {
  render() {
    return (
      <Modal
        className={"session-variable-modal"}
        title={i18n.t("SessionVariables.Title")}
        visible={this.props.visible}
        onCancel={() => this.props.changeSessionVariableModalVisibility(false)}
        width="90%"
        centered
        footer={null}
        style={{
          maxWidth: 1000
        }}
        bodyStyle={{
          maxHeight: 1000,
          padding: 10
        }}
      >
        <SessionVariableLayout
          model={this.props.model}
          visible={this.props.visible}
        />
      </Modal>
    );
  }
}
