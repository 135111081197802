import React, { Component } from 'react';
import TicTacToe from '../TicTacToe/TicTacToeApp';

import './HomePage.css'

export default class HomePage extends Component {
  render() {
    return (
      <div id="homePageContainer">
        <h1 style={{color: "green", fontSize:"24px"}}>Welcome to Vispeahen Admin Page!</h1>
        <h2>Are you tired of working?</h2>
        <h3>Come on!</h3>
        <h3>Let's play Tic Tac Toe ;)</h3>
        <br />
        <div id="TicTacToe">
          <TicTacToe />
        </div>
      </div>
    )
  }
}
