import { post } from "./WebService";
import {
  eraseCookie
} from "./Global";
import Cookies from "js-cookie";

export const authToken = Cookies.get("ORA_BIPS_NQID");
export const authenticatedUser = Cookies.get("authenticatedUser");

export const logout = (successFunc, errorFunc) => {
  const url = "/security/logoff";

  const successLogout = () => {
    removeAuthentication();

    if (successFunc) {
      successFunc();
    } else {
      naviagateToLogin();
    }
  }

  post(url, null, successLogout, successLogout);
};

/**
 * Checks whether user authenticated or not
 */
export const isAuthenticated = () => {
  const token = Cookies.get("ORA_BIPS_NQID");
  return token != undefined && token != null && token !== "";
}

/**
 * Navigates web site to login page
 */
export const naviagateToLogin = () => {
  window.location.href = "/vispeahen/ui/login";
}

/**
 * Removes all authentication related cookies
 */
export const removeAuthentication = () => {
  eraseCookie('ORA_BIPS_NQID', '/');
  eraseCookie('tenant', '/');
  eraseCookie('roles', '/');
  eraseCookie('authenticatedUser', '/');
  eraseCookie('operationType', '/');
}