/**
 * Limited stack developed by link list
 */
export class LimitedStack {
    constructor(limit = 10) {
        this.limit = limit;
        this.count = 0;
        this.tail = new DoublyNode();
        this.head = new DoublyNode();
        this.init();
    }

    /**
     * Stack initialize method.
     */
    init () {
        this.head.next = this.tail;
        this.tail.prev = this.head;
    }

    /**
     * Remove first item in stack.
     */
    removeFirst () {
        if(this.head.next === this.tail) return;

        let next = this.head.next.next;
        next.prev = this.head;
        this.head.next = next;
    }

    /**
     * Add node to stack on after last node.
     * @param {*} node 
     */
    addToLast(node) {
        let prev = this.tail.prev;
        let next = this.tail; 

        node.prev = prev;
        node.next = next;

        prev.next = node;
        next.prev = node;

        this.count++;
    }

    /**
     * Push method for limited stack. If length equals limit remove first item and add to last.
     * @param {*} value 
     */
    push (value) {
        if (this.count === this.limit) {
            this.removeFirst();
        }
        
        this.addToLast(new DoublyNode(value));
    }

    /**
     * Get last item in stack.
     */
    peek() {
        if(this.tail.prev !== this.head) {
            return this.tail.prev;
        }

        return {value: null};
    }

    /**
     * Remove last item in stack.
     */
    pop() {
        if(this.head.next === this.tail) return;
        
        let prev = this.tail.prev.prev;
        prev.next = this.tail;
        this.tail.prev = prev;

        this.count--;
    }

    /**
     * Check the stack has any node
     */
    isEmpty () {
        if (this.count === 0) {
            return true;
        }

        return false;
    }
}

class DoublyNode {
    constructor (value) {
        this.value = value;
        this.next = null;
        this.prev = null;
    }
}
