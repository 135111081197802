import React, { Component } from "react";
import { Tooltip } from "antd";
import { BgColorsOutlined } from "@ant-design/icons";
import i18n from "../../../Utils/i18next";

/**
 * Plugin tools configuration button component
 */
export default class ConfigurationButton extends Component {
  pluginConditionalFormattingOpen = () => {
    this.props.changeConditionalFormattingVisibility(true);
  };

  render() {
    const classNameControl = this.props.iconsClassNameControl
    let isPluginHeightOne = this.props.isPluginHeightOne
    let isPluginWidthOne = this.props.isPluginWidthOne
    
    return (
      <Tooltip 
        title={i18n.t("Dashboard.ConditionalFormatting.ConditionalFormatting")}>
        <BgColorsOutlined
          name={"configurePluginButton"}
          onClick={() => this.pluginConditionalFormattingOpen()}
          className={classNameControl(isPluginHeightOne, isPluginWidthOne)}
          style={{ cursor: "pointer" }}
        ></BgColorsOutlined>
      </Tooltip>
    );
  }
}
