import React, { Component } from "react";
import $ from "jquery";
import VegaLiteConfiguration from "./VegaLiteConfiguration";
import VegaData from "./VegaLiteData"
import { VegaLite } from "react-vega";
import i18n from "../../../../Utils/i18next";
import ReactDOM from "react-dom";

import {
  onComponentWillMount,
  onComponentWillReceiveProps,
  getColumnMapping,
} from "../common";

import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import { renderConfig, renderData } from "../PluginsCommonComponents";
import { renderContent } from "../renderContent";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join"
import { TOPOJSON_PATH, wellDefinedTopojsonOldPaths } from "../../../GeneralComponents/MapTopoJsonOperations/DefaultTopojsons";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";

const data = [
  { "ay": "Ocak", "deger": 28 },
  { "ay": "Şubat", "deger": 55 },
  { "ay": "Mart", "deger": 43 },
  { "ay": "Nisan", "deger": 91 },
  { "ay": "Mayıs", "deger": 81 },
  { "ay": "Haziran", "deger": 53 },
  { "ay": "Temmuz", "deger": 19 },
  { "ay": "Ağustos", "deger": 87 },
  { "ay": "Eylül", "deger": 52 }
]

const config = JSON.parse('{}');
const columnMap = JSON.parse('{}');
const condFormats = [];
const filters = [];
const pluginName = "custom";

const configurationParameters = [
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 15
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
  {
    targetProperty: "vegaSpec",
    label: "VegaSpec",
    inputType: "textbox",
    inputOptions: {
      defaultValue: '{ "$schema": "https://vega.github.io/schema/vega-lite/v5.json","description": "A simple bar chart with embedded data that fits exactly into 300px width.","width": 300,"autosize": {"type": "fit", "contains": "padding"}, "mark": "area", "encoding": { "x": {"field": "ay", "type": "ordinal"}, "y": {"field": "deger", "type": "quantitative"}}}'
    },
    desc: "desc305"
  },
  {
    targetProperty: "title",
    label: "title",
    inputType: "textbox",
    inputOptions: {
      label: "title"
    },
    desc: "desc94"
  },
  {
    targetProperty: "topoJson",
    label: "topoJson",
    inputType: "textbox",
    inputOptions: {
      label: "title"
    },
    desc: "desc94"
  },
  {
    targetProperty: "isCurrentPluginAMap",
    label: "IsCurrentPluginAMap",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc405"
  },
];
const reactions = [
  {
    id: "filter",
    name: "Filtre",
    description: "desc87",
    type: "general"
  }
];

const actions = []

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

export default class VegaLitePlugin extends Component {
  constructor(props) {
    super(props);
    this.rerenderProcessStarted = false;
    this.callBackObject = {};
  }

  mapNames = new Set(["geoshape"])

  calculatePluginHeight = (plugin, settings) => {
    let pluginHeight = settings.grid.rowHeight * plugin.h; // multiply plugin.height and grid's row height
    let pluginTitleContainer = $("#title-" + plugin.id);
    let pluginContainerBorder = -2;
    let pluginMinHeightDifference = 30;
    let maxHeight =
      pluginHeight -
      (pluginMinHeightDifference +
        pluginTitleContainer.outerHeight() +
        parseInt(pluginTitleContainer.css("margin-bottom")) +
        pluginContainerBorder);
    return maxHeight;
  };

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({})

    return tmpCallBackObject;
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );
  }

  getConfigComponent = props => {
    if (props.config) {
      return (
        <VegaLiteConfiguration
          config={{ ...props.config }}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig= {this.props.setCurrentAppliedConfig}
          currentAppliedConfig = {this.props.currentAppliedConfig}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }

    return (
      <VegaData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        didNotJoinedTables={checkTableJoins(this.props.join, this.props.plugin.columnMap, this.props.refreshedPluginId, this.props.plugin.id, true)}
        setInteractions={this.props.setInteractions}
        interactions={this.props.interactions}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={props.changeDoesPluginHasNotJoinedTable}
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        plugin={props.plugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      columns: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Columns.Name"),
        multiple: true,
        minimumColumnSize: 1,
        dependentColumns: [
          i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Lines.Name")
        ],
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Columns.Desc"),
        type: "fact",
        required: true,
        conditionalFormat: true,
        data: []
      },
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  prepareTopoJsonObject = (data, topoJson) => {
    if (wellDefinedTopojsonOldPaths[topoJson]) {
      topoJson = wellDefinedTopojsonOldPaths[topoJson];
    }
    
    return {
      "url": TOPOJSON_PATH + "/" + topoJson,
      "format": {
        "type": data.format.type,
        "feature": data.format.feature
      }
    }
  }

  doesPluginContainsTopojson = (spec) => {
    if (spec.layer && spec.layer.length > 0) {
      if (spec.layer[0].data) {
        if (spec.layer[0].data.format) {
          if (spec.layer[0].data.format.type === "topojson") {
            return true
          }
        }
      }
    } else if (spec.spec) {
      if (spec.spec.transform) {
        if (spec.spec.transform[0].from) {
          if (spec.spec.transform[0].from.data) {
            return true
          }
        }
      }
    }
    return false
  }

  changeTopoJson = (spec, topoJson, data) => {
    let newSpec = spec

    if (spec.data) {
      if (spec.data.format) {
        spec.data = this.prepareTopoJsonObject(spec.data, topoJson)
      }
    }
    //Choropleth of Unemployment Rate per County map plugininde altlık varsa data'ya gelen datayı değil, json'ı yazmamız lazım.

    if (spec.mark === "geoshape") {
      if (spec.data.format) {
        newSpec.data = this.prepareTopoJsonObject(spec.data, topoJson)
      } else {
        //Income in the U.S. by State, Faceted over Income Brackets datası burada yazılıyor.
        newSpec.data = {}
        newSpec.data.values = data
      }

      if (newSpec.transform && !newSpec.transform[0].from.data.format) {
        //Choropleth of Unemployment Rate per County map plugininde datayı buraya yazdırıyoruz.
        newSpec.transform[0].from.data = {}
        newSpec.transform[0].from.data.values = data
      } else if (newSpec.transform && newSpec.transform[0].from.data.format) {
        //Income in the U.S. by State, Faceted over Income Brackets topojson burada yazılıyor.
        newSpec.transform[0].from.data = this.prepareTopoJsonObject(spec.transform[0].from.data, topoJson)
      }
    } else if (spec.layer) {
      //One Dot per Airport in the U.S. Overlayed on Geoshape ve U.S. State Capitals Overlayed on a Map of U.S. haritasının altlığı ve datası burda yazılıyor.
      if (spec.layer[0].mark && spec.layer[0].data.format) {
        if (spec.layer[0].mark.type === "geoshape" && spec.layer[0].data.format.type === "topojson") {
          newSpec.layer[0].data = this.prepareTopoJsonObject(spec.layer[0].data, topoJson)
          if (spec.layer[1].data) {
            newSpec.layer[1].data = {}
            newSpec.layer[1].data.values = data
          }
        }
      }
    } else if (spec.spec) {
      //Three Choropleths Representing Disjoint Data from the Same Table datası ve altlığı burda yazılıyor.
      if (spec.spec.mark === "geoshape") {
        newSpec.spec.data = {}
        newSpec.spec.data.values = data
        if (this.doesPluginContainsTopojson(spec)) {
          newSpec.spec.transform[0].from.data = this.prepareTopoJsonObject(spec.spec.transform[0].from.data, topoJson)
        }
      }
    }

    return newSpec
  }

  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {
    let container = $(`[index='${divId}']`)
    let pluginHeight = config.height;
    let pluginContainer = $(`#plugin-${divId}`)
    pluginContainer.find("summary").css("z-index", "0")

    const specString = config.vegaSpec
    let spec = JSON.parse(specString);
    if (config.title === "") {
      pluginContainer.css("display", "block").css("justify-content", "center")
      $("#" + divId)
        .css("display", "flex")
        .css("align-items", "center")
        .css("height", pluginHeight + "px")
        .css("width", "100%")
        .attr("vegalite", "true")
    }

      spec.width =  "container";
    spec.height = pluginHeight
    spec.autosize = {}
    spec.autosize.type = "fit"
    spec.autosize.contains = "padding"

    if (config.topoJson !== undefined && config.isCurrentPluginAMap) {
      if (this.doesPluginContainsTopojson(spec)) {
        //U.S. State Capitals Overlayed on a Map of U.S. burada kontrol ediliyor
        spec = this.changeTopoJson(spec, config.topoJson, data)
      }

      if (spec.mark === "geoshape" || spec.spec) {
        //Income in the U.S. by State, Faceted over Income Brackets burada kontrol ediliyor.
        //Three Choropleths Representing Disjoint Data from the Same Table burada kontrol ediliyor.
        spec = this.changeTopoJson(spec, config.topoJson, data)
      }
    }

    if (!spec.data || Object.values(spec.data).length === 1) {
      //One Dot per Zipcode in the U.S.'in datası burda yazılıyor.(Altlığı yok)
      spec.data = {};
      spec.data.values = data;
    } else if (Object.values(spec.data).length > 1) {
      //Projection explorer pluginin topojson'ı burada yazılıyor
      spec.data = this.prepareTopoJsonObject(spec.data, config.topoJson)
    }


    ReactDOM.render(
      <VegaLite spec={spec} data={data} />,
      document.getElementById(`${divId}`)
    );

    this.props.setPluginRerender(false, this.props.plugin.id, false, this.props.plugin.isInteraction);
  };

  currentHeight;
  lastContent = undefined;

  updateLastContent = (status) => {
    this.lastContent = status
  }

  render() {
    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };
    
    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + this.props.plugin.id).css("padding")
      );

      pluginConfig.height =
        this.calculatePluginHeight(this.props.plugin, this.props.settings) -
        pluginContainerPadding * 2;

      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      );
    }

    return (
      <>
        <div style={{height: "100%"}}>
          <div id={this.props.plugin.id}></div>
          {renderContent(
            isRerender,
            this.pluginRender,
            this.props.plugin,
            data,
            columnMap,
            pluginConfig,
            condFormats,
            this.props.setPluginRerender,
            this.lastContent,
            this.updateLastContent,
          )}
          {configComponent}
          {dataComponent}
        </div>
      </>
    );
  }
}
