import React, { Component } from "react";
import $ from "jquery";
import RuleInput from "./RuleInput";
import { Row, Col, Divider, Select, Tooltip, Input } from "antd";
import Text from "../../GeneralComponents/Text/Text";
import i18n from "../../../Utils/i18next";
import { convertHTMLRuletoRule } from "../ConditionalFormattingCommon";
import {
  FontSizeOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input;

/**
 * Conditional format rule base component
 */
export default class Rule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leftRule: {},
      operator: "=",
      rightRule: {},
      conditionalFormatRule: ""
    };
  }

  componentWillMount() {
    /** Set rule */
    if (this.props.rule) {
      this.setState({ ...this.state, ...this.props.rule });
    }
  }

  componentWillReceiveProps(nextProps) {
    /** if clicked apply get all rules and operator for add to plugin */
    if (nextProps.clickedApply === true) {
      let newLeftRule = { ...this.state.leftRule };
      let leftRule = convertHTMLRuletoRule(newLeftRule);

      newLeftRule.rule = leftRule.rule;
      newLeftRule.ruleColumnName = leftRule.ruleColumnName;

      let newRightRule = { ...this.state.rightRule };
      let rightRule = convertHTMLRuletoRule(newRightRule);

      newRightRule.rule = rightRule.rule;
      newRightRule.ruleColumnName = rightRule.ruleColumnName;
      
      let newConditionalFormatExpressionRule = this.state.conditionalFormatRule

      let newState = { ...this.state };
      newState.leftRule = newLeftRule;
      newState.rightRule = newRightRule;
      newState.conditionalFormatRule = newConditionalFormatExpressionRule

      this.setState(newState);

      this.props.getRule(newState, "rule");
    }

    /** If rule change update state */
    if (nextProps.rule !== this.props.rule) {
      this.setState({ ...nextProps.rule });
    }
  }

  /**Rule description text area in Conditional format event handler */
  handleChangeConditionalFormatRule = (e) => {
    this.setState({
      ...this.state,
      conditionalFormatRule: e.target.value
    })
  }


  /**
   * Rule update handler
   * type = left rule or right rule
   * data = rule (rule: columnId, expression, value; ruleColumn: {column}  )
   */
  updateRule = (data, type) => {
    this.setState({
      ...this.state,
      [type]: data
    });
  };

  render() {
    return (
      <div>
        <Row gutter={6} className={"conditional-format-rule"}>
          <Col span={24}>
            <Divider className={"config-divider"} orientation="left">
              {i18n.t("Dashboard.ConditionalFormatting.Rule")}
            </Divider>
            <Text
              type="span"
              style={{
                color: "rgb(162, 162, 162)",
                fontSize: "14px",
                display: "block",
                marginBottom: "8px"
              }}
            >
              {i18n.t("Dashboard.ConditionalFormatting.RuleDescription")}
            </Text>
          </Col>

          <Col span={10}>
            <Text
              type="span"
              style={{
                color: "rgb(102, 102, 102)",
                fontSize: "14px",
                display: "block",
                marginBottom: "8px"
              }}
            >
              {i18n.t("Dashboard.ConditionalFormatting.LeftRule")}
            </Text>
            <RuleInput
              updateRule={this.updateRule}
              clickedApply={this.props.clickedApply}
              columnsToBeUsed={this.props.columnsToBeUsed}
              type={"leftRule"}
              rule={this.state.leftRule}
            />
          </Col>

          <Col span={4}>
            <Text
              type="span"
              style={{
                color: "rgb(102, 102, 102)",
                fontSize: "14px",
                display: "block",
                marginBottom: "8px"
              }}
            >
              {i18n.t("Dashboard.ConditionalFormatting.Operator")}
            </Text>
            <Select
              id={this.props.id}
              style={{ width: "100%" }}
              placeholder={i18n.t(
                "Dashboard.Configuration.Fields.SelectTitleAlign"
              )}
              getPopupContainer={(trigger) => trigger.parentNode}
              optionFilterProp="children"
              onChange={value => {
                this.setState({ ...this.state, operator: value });
              }}
              defaultValue={this.state.operator}
            >
              <Option
                name={"equals"}
                title={i18n.t("FilterPredicates.Equals")}
                value="="
              >
                {i18n.t("FilterPredicates.Equals")}
              </Option>
              <Option
                name={"notEquals"}
                title={i18n.t("FilterPredicates.NotEquals")}
                value="!="
              >
                {i18n.t("FilterPredicates.NotEquals")}
              </Option>
              <Option
                name={"greaterThan"}
                title={i18n.t("FilterPredicates.GreaterThan")}
                value=">"
              >
                {i18n.t("FilterPredicates.GreaterThan")}
              </Option>
              <Option
                name={"greaterThanOrEqual"}
                title={i18n.t("FilterPredicates.GreaterThanOrEqual")}
                value=">="
              >
                {i18n.t("FilterPredicates.GreaterThanOrEqual")}
              </Option>
              <Option
                name={"lessThan"}
                title={i18n.t("FilterPredicates.LessThan")}
                value="<"
              >
                {i18n.t("FilterPredicates.LessThan")}
              </Option>
              <Option
                name={"lessThanOrEqual"}
                title={i18n.t("FilterPredicates.LessThanOrEqual")}
                value="<="
              >
                {i18n.t("FilterPredicates.LessThanOrEqual")}
              </Option>
            </Select>
          </Col>

          <Col span={10}>
            <Text
              type="span"
              style={{
                color: "rgb(102, 102, 102)",
                fontSize: "14px",
                display: "block",
                marginBottom: "8px"
              }}
            >
              {i18n.t("Dashboard.ConditionalFormatting.RightRule")}
            </Text>
            <RuleInput
              updateRule={this.updateRule}
              clickedApply={this.props.clickedApply}
              columnsToBeUsed={this.props.columnsToBeUsed}
              type={"rightRule"}
              rule={this.state.rightRule}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px", marginTop: "25px" }}>
          <Col style={{ textAlign: "left", display: "flex" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.ConditionalFormatting.RuleDescriptionTitle")}
            </Text>
            <Tooltip 
                title={
                  <>
                  {i18n.t("Dashboard.ConditionalFormatting.RuleDescriptionInfo.Info")} <br />
                  </>
                }
              >
                <InfoCircleOutlined style={{ position: 'relative', marginTop: '5px', marginLeft: "10px" }} /> 
              </Tooltip>
          </Col>
          <Col style={{ marginLeft: "30px" }} span={12}>
            <TextArea
              allowClear
              style={{ width: "24rem" }}
              value={this.state.conditionalFormatRule}
              onChange={this.handleChangeConditionalFormatRule}
              autoSize={{ minRows: 3, maxRows: 5 }}
              placeholder={i18n.t("Dashboard.ConditionalFormatting.NoData")}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
