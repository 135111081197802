import { BackwardOutlined, CompressOutlined, ExpandOutlined, ForwardOutlined, LeftOutlined } from "@ant-design/icons";
import React from "react";
import "./player.css"
import { navigate } from "../../Utils/Global";
import { BASE_URL_UI } from "../../config";
import $ from "jquery"
import { Icon } from "antd";

/**
 * Dashboard Slider Player Component
 */
class DashboardSliderPlayer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            playing: false,
            showPlayer: true,
            firstPlay: false,
            fullscreen: false,
            slideDashboardTimer: 0,
            slideDashboardSeconds: 0
        };

        this.showPlayerTimer = null;
        this.hidePlayerTimer = null;
        this.hidePlayerInterval = null;
        this.idleMouseTimer = null;

        this.slideDashboardInterval = null;
    }

    /**
     * Shows player
     * 
     * @param {*} event 
     * @param {*} timeout 
     * @returns 
     */
    showPlayer = (event, timeout = 50) => {
        clearTimeout(this.showPlayerTimer);
        clearTimeout(this.hidePlayerTimer);
        clearInterval(this.hidePlayerInterval);

        if (this.state.showPlayer) return;

        this.showPlayerTimer = setTimeout(() => {
            this.setState({ showPlayer: true })
        }, timeout);
    }

    /**
     * Hides player
     * 
     * @param {*} event 
     * @param {*} timeout 
     * @returns 
     */
    hidePlayer = async (event, timeout = 1000) => {
        clearTimeout(this.showPlayerTimer);
        clearTimeout(this.hidePlayerTimer);
        clearInterval(this.hidePlayerInterval);

        if (!this.state.firstPlay || !this.state.showPlayer) return;

        if ($("#splashScreenContainer").css("display") !== "none") {
            this.hidePlayerInterval = setInterval(() => {
                if ($("#splashScreenContainer").css("display") === "none") {
                    this.hidePlayer(event, 2000);
                }
            });
        } else {
            this.hidePlayerTimer = setTimeout(() => {
                clearTimeout(this.idleMouseTimer);
                document.body.style.cursor = "none";

                this.setState({ showPlayer: false })
            }, timeout);
        }
    }

    /**
     * Slides to the given dashboard
     * 
     * @param {*} dashboard 
     */
    slideDashboard = (dashboard) => {
        if (this.props.slider.isBusy) return;
        
        this.setState({
            slideDashboardTimer: 0,
            slideDashboardSeconds: 0
        }, () => setTimeout(() => this.props.slideDashboard(dashboard)));
    }

    /**
     * Slides to the next dashboard
     * 
     * @returns 
     */
    stepForward = () => {
        if (this.props.slider.isBusy) return;

        let nextDashboard = this.props.slider.nextDashboard;

        if (!nextDashboard) return;

        this.slideDashboard(nextDashboard);
    }

    /**
     * Slides to the previous dashboard
     *
     * @returns 
     */
    stepBackward = () => {
        if (this.props.slider.isBusy) return;

        if (this.state.slideDashboardTimer > 0) {
            clearInterval(this.slideDashboardInterval);

            this.props.setPlaying(false);

            this.setState({
                playing: false,
                slideDashboardTimer: 0,
                slideDashboardSeconds: 0
            }, this.props.setBackwarded);
        } else {
            let previousDashboard = this.props.slider.previousDashboard;

            if (!previousDashboard) return;

            this.slideDashboard(previousDashboard);
        }
    }

    /**
     * Starts slider animation
     */
    play = (callback = () => {}) => {
        if (this.props.slider.isBusy) return;

        this.props.setPlaying(true);

        this.setState({
            playing: true,
            firstPlay: true
        }, () => {
            this.slideDashboardInterval = setInterval(() => {
                if ($("#splashScreenContainer").css("display") !== "none") {
                    return;
                } else if (this.state.slideDashboardTimer === this.props.slider.currentDashboard.duration * 100) {
                    if (this.props.slider.nextDashboard) {
                        this.slideDashboard(this.props.slider.nextDashboard);
                    } else {
                        this.slideDashboard(this.props.slider.dashboards[0]);
                    }
                } else {
                    let timer = this.state.slideDashboardTimer + 1;
                    let seconds = Math.floor(timer / 100)

                    this.setState({
                        slideDashboardSeconds: seconds,
                        slideDashboardTimer: timer
                    });
                }
            }, 10);

            if (typeof callback === "function") {
                callback();
            }
        });
    }

    /**
     * Pauses slider animation
     */
    pause = (callback = () => {}) => {
        if (this.props.slider.isBusy) return;

        clearInterval(this.slideDashboardInterval);

        this.props.setPlaying(false);

        this.setState({
            playing: false
        }, callback);
    }

    /**
     * Stops and clears slider animation
     */
    stopAndClear = () => {
        if (this.props.slider.isBusy) return;

        clearInterval(this.slideDashboardInterval);

        this.props.setPlaying(false);

        this.setState({
            playing: false
        }, () => this.slideDashboard(this.props.slider.dashboards[0]));
    }

    /**
     * Exits dashboard slider
     */
    exit = () => {
        sessionStorage.removeItem("currentSlider");

        navigate(BASE_URL_UI);
    }

    /**
     * Converts seconds to minutes:seconds format
     * 
     * @param {*} seconds 
     * @returns 
     */
    secondsToMinutes = (seconds) => {
        let minutes = Math.floor(seconds / 60);
        let remainingSeconds = seconds % 60;
        let result = `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;


        return result.includes("NaN") ? "-:--" : result;
    }

    /**
     * Shows the cursor and hides it if it does not move for 2 seconds.
     */
    showAndHideCursor = () => {
        clearTimeout(this.idleMouseTimer);

        document.body.style.cursor = "";

        this.idleMouseTimer = setTimeout(() => {
            if (!this.state.showPlayer) {
                document.body.style.cursor = "none";
            }
        }, 2000);
    }

    /**
     * Sets fullscreen mode
     * 
     * @param {*} status 
     */
    setFullscreen = (status = false) => {
        let body = document.documentElement;
        let isStatusChanged = false;

        if (status === true) {
            if (body.requestFullscreen) {
                isStatusChanged = true;

                body.requestFullscreen();
            } else if (body.webkitRequestFullscreen) {
                isStatusChanged = true;

                body.webkitRequestFullscreen();
            } else if (body.msRequestFullscreen) {
                isStatusChanged = true;

                body.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                isStatusChanged = true;

                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                isStatusChanged = true;

                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                isStatusChanged = true;

                document.msExitFullscreen();
            }
        }

        if (isStatusChanged) {
            this.setState({
                fullscreen: status
            });
        }
    }

    /**
     * Handles key down event for fullscreen mode
     * 
     * @param {*} event 
     */
    onKeyDown = event => {
        if (event.code === "Space") {
            if (this.state.playing) {
                this.pause();
            } else {
                let shouldHidePlayer = this.state.firstPlay !== true;

                this.play(() => {
                    if (shouldHidePlayer) {
                        this.hidePlayer();
                    }
                });
            }
        } else if (event.key === "F11") {
            event.preventDefault();

            this.setFullscreen(!this.state.fullscreen);
        } else if (event.key === "Escape") {
            event.preventDefault();

            if (this.state.fullscreen) {
                this.setFullscreen(false);
            }
        }
    }

    componentDidMount() {
        window.addEventListener("keydown", this.onKeyDown);
        document.body.addEventListener("mousemove", this.showAndHideCursor);
    }

    componentDidUpdate(prevProps, prevState) {
        let newState = { ...this.state };
        let isStateChanged = false;

        let isFrameChanged = this.props.slider && this.props.slider?.frame !== prevProps.slider?.frame;
        let shouldShowPlayer = isFrameChanged;

        if (!this.state.playing && shouldShowPlayer) {
            newState.showPlayer = true;

            isStateChanged = true;
        }

        if (isStateChanged) {
            this.setState(newState);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.showPlayerTimer);
        clearTimeout(this.hidePlayerTimer);

        clearInterval(this.hidePlayerInterval);
        clearInterval(this.slideDashboardInterval);

        window.removeEventListener("keydown", this.onKeyDown);
        document.body.removeEventListener("mousemove", this.showAndHideCursor);
    }

    render() {
        if (!this.props.slider || this.props.slider?.dashboards?.length === 0) return null;

        let previousDashboard = this.props.slider.previousDashboard;
        let currentDashboard = this.props.slider.currentDashboard;
        let nextDashboard = this.props.slider.nextDashboard;

        let passedTime = this.secondsToMinutes(this.state.slideDashboardSeconds);
        let remainingTime = this.secondsToMinutes(currentDashboard.duration - this.state.slideDashboardSeconds);

        let isBackwardValid = !this.props.slider.isBusy && (previousDashboard || this.state.slideDashboardTimer > 0) ? true : false;
        let isForwardValid = !this.props.slider.isBusy && nextDashboard ? true : false;
        let isPlayValid = !this.props.slider.isBusy && currentDashboard.duration > 0 ? true : false;
        let isPauseValid = !this.props.slider.isBusy && this.state.playing ? true : false;
        let isStopValid = !this.props.slider.isBusy && (previousDashboard || this.state.slideDashboardTimer > 0) ? true : false;

        let theme = this.props.themeObj?.sideBar;
        let backgroundColor = theme?.backgroundColor;
        let textColor = theme?.textColor;

        return (
            <>
                <div
                    className="dashboard-slider-top"
                    onPointerOver={this.showPlayer}
                    onPointerOut={this.hidePlayer}
                >
                    <div
                        className="dashboard-slider-title-container"
                        style={{
                            top: this.state.showPlayer && currentDashboard.title ? 8 : -70,
                        }}
                    >
                        <span className="dashboard-slider-title">
                            {currentDashboard.title}
                        </span>
                    </div>

                    <div
                        className="dashboard-slider-exit"
                        onClick={this.exit}
                        style={{
                            top: this.state.showPlayer ? 8 : -70
                        }}
                    >
                        <LeftOutlined />
                    </div>
                </div>

                <div
                    className="dashboard-slider-bottom"
                    onPointerOver={this.showPlayer}
                    onPointerOut={this.hidePlayer}
                >
                    <div
                        className="dashboard-slider-action-container"
                        style={{
                            bottom: this.state.showPlayer ? 8 : -70,
                        }}
                    >
                        <div
                            id="dashboard-slider-progress-bar-container"
                            className="dashboard-slider-progress-bar-container"
                        >
                            <span className="text-time">{passedTime}</span>
                            <div
                                id="dashboard-slider-progress-bar"
                                className="dashboard-slider-progress-bar"
                            >
                                <div
                                    id="dashboard-slider-progress"
                                    className="dashboard-slider-progress"
                                    style={{
                                        width: `${this.state.slideDashboardTimer / currentDashboard.duration}%`
                                    }}
                                />
                            </div>
                            <span className="text-time">-{remainingTime}</span>
                        </div>

                        <div
                            className="dashboard-slider-action-button-container"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                gap: 16
                            }}
                        >
                            <span
                                id="stop-button"
                                className="dashboard-slider-action-button"
                                onClick={isStopValid && this.stopAndClear}
                                style={{
                                    opacity: isStopValid ? 1 : 0.2,
                                    cursor: isStopValid ? "pointer" : "default",
                                }}
                            />

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    gap: 16
                                }}
                            >
                                <BackwardOutlined
                                    id="backward-button"
                                    className="dashboard-slider-action-button"
                                    style={{
                                        opacity: isBackwardValid ? 1 : 0.2,
                                        cursor: isBackwardValid ? "pointer" : "default",
                                    }}
                                    onClick={isBackwardValid && this.stepBackward}
                                />

                                <Icon
                                    id={this.state.playing ? "pause-button" : "play-button"}
                                    type={this.state.playing ? "pause" : "caret-right"}
                                    onClick={() => {
                                        if (this.state.playing && isPauseValid) {
                                            this.pause();
                                        } else if (isPlayValid) {
                                            this.play();
                                        }
                                    }}
                                    className="dashboard-slider-action-button"
                                    style={this.state.playing ? {
                                        opacity: isPauseValid ? 1 : 0.2,
                                        cursor: isPauseValid ? "pointer" : "default"
                                    } : {
                                        opacity: isPlayValid ? 1 : 0.2,
                                        cursor: isPlayValid ? "pointer" : "default"
                                    }}
                                />

                                <ForwardOutlined
                                    id="forward-button"
                                    className="dashboard-slider-action-button"
                                    style={{
                                        opacity: isForwardValid ? 1 : 0.2,
                                        cursor: isForwardValid ? "pointer" : "default",
                                    }}
                                    onClick={isForwardValid && this.stepForward}
                                />
                            </div>

                            {
                                this.state.fullscreen ? (
                                    <CompressOutlined
                                        id="exit-fullscreen-button"
                                        className="dashboard-slider-action-button"
                                        onClick={() => this.setFullscreen(false)}
                                    />
                                ) : (
                                    <ExpandOutlined
                                        id="enter-fullscreen-button"
                                        className="dashboard-slider-action-button"
                                        onClick={() => this.setFullscreen(true)}
                                    />
                                )
                            }
                        </div>
                    </div>
                </div>

                <style id="dashboard-slider-manipulating">
                    {`
                        div#mainDiv,
                        div#mainDiv * {
                            cursor: default !important;
                            pointer-events: none !important;
                        }
                        
                        div.plugin-tools,
                        div[plugin=true] .pvtToolbar,
                        div[plugin=true] .rm-table-toolbar,
                        div[plugin=true] .apexcharts-toolbar,
                        div[plugin=true] .plugin-error-area,
                        div[plugin=true] ::-webkit-scrollbar,
                        div[plugin=true] [role="scrollbar"],
                        div[plugin=true] [role="slider"],
                        div[plugin=true] .apexcharts-toolbar,
                        div[plugin=true] .leaflet-control-container,
                        
                        .ant-notification
                        {
                            display: none !important;
                        }
                    `}
                </style>

                <style id="playerCSS" theme={this.props.themeObj?.name}>
                    {
                        theme && `
                            .dashboard-slider-top>div,
                            .dashboard-slider-bottom>div
                            {
                                color: ${textColor}FF;
                                background: ${backgroundColor}AA;
                            }
                                
                            .dashboard-slider-bottom .dashboard-slider-action-container,
                            .dashboard-slider-action-container .dashboard-slider-action-button
                            {
                                color: ${textColor}90;
                            }
                                       
                            .dashboard-slider-action-button-container .dashboard-slider-action-button#stop-button,
                            .dashboard-slider-progress-bar-container .dashboard-slider-progress
                            {
                                background-color: ${textColor}90;
                            }

                            .dashboard-slider-action-button-container .dashboard-slider-action-button#play-button,
                            .dashboard-slider-action-button-container .dashboard-slider-action-button#pause-button,
                            .dashboard-slider-action-button-container .dashboard-slider-action-button#forward-button,
                            .dashboard-slider-action-button-container .dashboard-slider-action-button#backward-button {
                                color: ${textColor}FF;
                            }

                            .dashboard-slider-progress-bar-container .text-time
                            {
                                color: ${textColor}AA;
                            }

                            .dashboard-slider-progress-bar-container .dashboard-slider-progress-bar
                            {
                                background-color: ${textColor}1A;
                            }
                        `
                    }
                </style>

                <style id="sliderPlayerCSS" theme={this.props.themeObj?.name}>
                    {theme?.css}
                </style>
            </>
        )
    }
}

export default DashboardSliderPlayer;