import React, { Component } from "react";
import TitleWithIcon from "../TitleWithIcon";
import "./landingPageSkeleton.css"

/**
 * Dashboard label loader in landing page when get dashboards. 
 */
export default class LandingPageSkeleton extends Component {
  render() {
    return (
      <div class="section">
        <TitleWithIcon
          title={this.props.label}
          color={this.props.color}
          className={"colored-shape"}
        />
        <div class="row">
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div class="report">
              <div class="report-thumb">
                <div
                  class="report-image report-skeleton-image"
                ></div>
              </div>
              <div class="report-title" title="matrix deneme">
                <span class="text-shorten">
                  <div class="report-skeleton-text"></div>
                </span>
                <span class="report-detail" style={{ width: "100%" }}>
                  <span class="report-skeleton-mini-text"></span>
                  <span class="report-skeleton-mini-text"></span>
                </span>
              </div>
            </div>
          </div>

          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div class="report">
              <div class="report-thumb">
                <div
                  class="report-image report-skeleton-image"
                ></div>
              </div>
              <div class="report-title" title="matrix deneme">
                <span class="text-shorten">
                  <div class="report-skeleton-text"></div>
                </span>
                <span class="report-detail" style={{ width: "100%" }}>
                  <span class="report-skeleton-mini-text"></span>
                  <span class="report-skeleton-mini-text"></span>
                </span>
              </div>
            </div>
          </div>

          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div class="report">
              <div class="report-thumb">
                <div
                  class="report-image report-skeleton-image"
                ></div>
              </div>
              <div class="report-title" title="matrix deneme">
                <span class="text-shorten">
                  <div class="report-skeleton-text"></div>
                </span>
                <span class="report-detail" style={{ width: "100%" }}>
                  <span class="report-skeleton-mini-text"></span>
                  <span class="report-skeleton-mini-text"></span>
                </span>
              </div>
            </div>
          </div>

          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div class="report">
              <div class="report-thumb">
                <div
                  class="report-image report-skeleton-image"
                ></div>
              </div>
              <div class="report-title" title="matrix deneme">
                <span class="text-shorten">
                  <div class="report-skeleton-text"></div>
                </span>
                <span class="report-detail" style={{ width: "100%" }}>
                  <span class="report-skeleton-mini-text"></span>
                  <span class="report-skeleton-mini-text"></span>
                </span>
              </div>
            </div>
          </div>

          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div class="report">
              <div class="report-thumb">
                <div
                  class="report-image report-skeleton-image"
                ></div>
              </div>
              <div class="report-title" title="matrix deneme">
                <span class="text-shorten">
                  <div class="report-skeleton-text"></div>
                </span>
                <span class="report-detail" style={{ width: "100%" }}>
                  <span class="report-skeleton-mini-text"></span>
                  <span class="report-skeleton-mini-text"></span>
                </span>
              </div>
            </div>
          </div>

          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div class="report">
              <div class="report-thumb">
                <div
                  class="report-image report-skeleton-image"
                ></div>
              </div>
              <div class="report-title" title="matrix deneme">
                <span class="text-shorten">
                  <div class="report-skeleton-text"></div>
                </span>
                <span class="report-detail" style={{ width: "100%" }}>
                  <span class="report-skeleton-mini-text"></span>
                  <span class="report-skeleton-mini-text"></span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}