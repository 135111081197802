import React, { Component } from "react";
import { Button, Input } from "antd";
import i18n from "../../../../../Utils/i18next";
import { showNotificationWithIcon } from "../../../../../admin/Utils/Notification";
import uuid from "react-uuid";
import { SaveOutlined, CloseOutlined } from "@ant-design/icons";
import Tooltip from "../../../../GeneralComponents/Tooltip/Tooltip";

// What if scenario add operations
export default class WhatIfScenarios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      visibleSaveOperation: false
    };
  }

  /** To open scenario save area. */
  openSaveScenarioButton = (
    <Button
      className={"general-button"}
      style={{ float: "right", margin: "0", width: "fit-content" }}
      onClick={() => this.visibleSaveOperation(true)}
    >
      {i18n.t("Dashboard.Data.WhatIf.SaveCurrentScenario")}
    </Button>
  );

  //Save button for current scenario
  saveCurrentScenarioButton = (
    <Tooltip title={i18n.t("Save")}>
      <SaveOutlined
        name={"scenarioSaveButton"}
        onClick={() => this.saveCurrentScenario(this.props.parameters)}
        className={"circle-icon"}
        style={{
          cursor: "pointer",
          top: "-3px",
          position: "relative",
          marginLeft: "5px",
          color: "#71ab8a",
          borderColor: "#71ab8a"
        }}
      ></SaveOutlined>
    </Tooltip>
  );

  //To cancel scenario save area.
  cancelCurrentScenarioButton = (
    <Tooltip title={i18n.t("Save")}>
      <CloseOutlined
        name={"scenarioCancelButton"}
        onClick={() => this.visibleSaveOperation(false)}
        className={"circle-icon"}
        style={{
          cursor: "pointer",
          top: "-3px",
          position: "relative",
          marginLeft: "5px",
          color: "#e87d7d",
          borderColor: "#e87d7d"
        }}
      ></CloseOutlined>
    </Tooltip>
  );

  /** Visibility handler for save operations */
  visibleSaveOperation = status => {
    let newState = { ...this.state };
    newState.visibleSaveOperation = status;

    if (status === false) {
      newState.name = "";
    }
    this.setState(newState);
  };

  /** Validate and save current scenario with unique id. */
  saveCurrentScenario = parameters => {
    let scenario = {};

    scenario.id = uuid();
    scenario.name = this.state.name;

    if (scenario.name === "" || scenario.name === undefined) {
      showNotificationWithIcon(
        i18n.t("Error"),
        i18n.t("Dashboard.Data.WhatIf.ScenarioNameError"),
        "error"
      );
      return;
    }

    scenario.scenarioParametersList = [];

    parameters.map(param => {
      scenario.scenarioParametersList.push({
        id: param.id,
        currentValue: param.currentValue
      });
    });

    showNotificationWithIcon(
      i18n.t("Success"),
      i18n.t("Dashboard.Data.WhatIf.ScenarioSuccessfullyAdded"),
      "success"
    );

    this.setState({
      ...this.state,
      visibleSaveOperation: false,
      name: ""
    }, () => this.props.addScenario(scenario))
  };

  /** Set scenario name */
  setName = name => {
    this.setState({
      ...this.state,
      name: name
    });
  };

  render() {
    let saveVisibleClass = "save-scenario-area";

    if (this.state.visibleSaveOperation === true) {
      saveVisibleClass = "save-scenario-area show-scenario";
    }

    let currentScenarioName = "";

    if (this.props.currentScenario !== undefined && Object.keys(this.props.currentScenario).length > 0) {
      currentScenarioName = this.props.currentScenario.name;
    }

    return (
      <div className={"scenarios"}>
        <div className={saveVisibleClass}>
          <Input
            id={"scenarioName"}
            placeholder={i18n.t("Dashboard.Data.WhatIf.ScenarioName")}
            onChange={e => {
              this.setName(e.target.value);
            }}
            value={this.state.name}
            style={{ width: "70%" }}
          ></Input>

          {this.saveCurrentScenarioButton}
          {this.cancelCurrentScenarioButton}
        </div>
        <div className={"scenarios-area"}>
          {currentScenarioName !== "" ? (
            <div className={"current-scenario-list-view"}>
              <span style={{ fontWeight: "bold" }}>
                {i18n.t("Dashboard.Data.WhatIf.CurrentScenario")}:{" "}
              </span>
              {currentScenarioName}
            </div>
          ) : null}

          {this.openSaveScenarioButton}
        </div>
      </div>
    );
  }
}
