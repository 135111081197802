import React, { Component } from "react";
import { getIcon, navigate } from "../../Utils/Global";
import { HomeOutlined } from "@ant-design/icons";
import { BASE_URL_UI } from "../../config.js";

// Home Button Icon for top menu with style
class HomeButton extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let buttonTheme = this.props.theme?.buttons?.homeButton;

    return (
      <a
        id="homeButton"
        class="btn blue-button btn-rounded hide-on-mobile"
        style={{ marginRight: "5px" }}
        href={BASE_URL_UI}
        onClick={() => navigate(BASE_URL_UI)}
      >
        <style>
          {buttonTheme && `
            #homeButton {
              background-color: ${buttonTheme.backgroundColor};
              border-color: ${buttonTheme.textColor}33;
              color: ${buttonTheme.textColor};
            }

            #homeButton:hover,
            #homeButton:focus,
            #homeButton:active {
              background-color: ${buttonTheme.backgroundColorActive} !important;
              color: ${buttonTheme.textColorActive} !important;
              border-color: ${buttonTheme.textColorActive}33 !important;
            }
          `}
        </style>
        {
          getIcon(
            buttonTheme?.icon,
            <HomeOutlined style={{ overflow: "auto", marginTop: "4px" }} />
          )
        }
      </a>
    );
  }
}

export default HomeButton;
