import React, { Component } from "react";
import { Form, Row, Col, Upload, Icon } from "antd";
import i18n from "../../../Utils/i18next";
import { showNotificationWithIcon } from "../../../Utils/Notification";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

class ImageUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      imageUrl: "",
      image: this.props.image,
      type: "",
      wallpaper: this.props.wallpaper,
      isLt1M: ""
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.image) {
      this.setState({
        image: nextProps.image
      });
    }
  }

  beforeUpload = file => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
    this.state.isLt1M = file.size / 1024 / 1024 < 1;

    if (!isJpgOrPng) {
      showNotificationWithIcon(i18n.t("Error"), i18n.t("Dashboard.Settings.ImageFileTypeError"), "error")
    }

    if (!this.state.isLt1M) {
      showNotificationWithIcon(i18n.t("Error"), i18n.t("Dashboard.Settings.ImageFileOverTheLimitError"), "error")
    }

    return isJpgOrPng && this.state.isLt1M;
  };

  customRequest = ({ file }) => {
    const checkInfo = () => {
      setTimeout(() => {
        if (!this.imageDataAsURL) {
          if (this.state.loading == false) {
            this.setState({ loading: true });
          }

          checkInfo();
        } else {
          getBase64(file, imageUrl => {
            this.state.image = imageUrl;
            let imagePrefix = imageUrl.split(",")[0];
            imageUrl = imageUrl.split(",")[1];
            this.setState({
              imageUrl,
              loading: false
            });

            this.props.confirm(imageUrl, imagePrefix);
          });
        }
      }, 100);
    };

    checkInfo();
  };

  handleChange = info => {
    if (info.file.originFileObj) {
      const reader = new FileReader();

      reader.onloadend = obj => {
        this.imageDataAsURL = obj.srcElement.result;
      };
  
      if (this.state.isLt1M) {
        reader.readAsDataURL(info.file.originFileObj);
      }
    }
  };

  isImageEmpty = image => {
    return image === "" || image === "," || image === undefined ? true : false;
  };

  render() {
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div name={"uploadImageButton"} className="ant-upload-text">{i18n.t("Upload")}</div>
      </div>
    );

    const { image } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item label={this.props.title}>
          <Row gutter={8}>
            <Col span={24} className="uploadCol">
              <Upload
                style={{ width: "100%" }}
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={this.beforeUpload}
                onChange={this.handleChange}
                customRequest={this.customRequest}
                confirm={this.state.image}
              >
                {this.isImageEmpty(image) ? (
                  uploadButton
                ) : (
                  <img src={image} alt="avatar" style={{ width: "100%" }} />
                )}
              </Upload>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    );
  }
}

export default ImageUploader;
