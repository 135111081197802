import { floor, round } from "lodash";

const inchesToCm = (inches) => {
    return inches * 2.54;
}

const cmToInches = (cm) => {
    return cm / 2.54;
}

const inchesToMm = (inches) => {
    return inches * 25.4;
}

const mmToInches = (mm) => {
    return mm / 25.4;
}

const inchesToPoints = (inches) => {
    return inches * 72;
}

const pointsToInches = (points) => {
    return points / 72;
}

const cmToPoints = (cm) => {
    let inches = cmToInches(cm);
    return inches * 72;
}

const pointsToCm = (points) => {
    let inches = points / 72;

    return inchesToCm(inches);
}

const mmToPoints = (mm) => {
    let inches = mmToInches(mm);
    return inches * 72;
}

const pointsToMm = (points) => {
    let inches = points / 72;

    return inchesToMm(inches);
}

export const convertUnit = (value, oldUnit, newUnit) => {
    if (oldUnit === "inch") {
        if (newUnit === "cm") {
            return floor(inchesToCm(value), 2);
        }

        if (newUnit === "mm") {
            return floor(inchesToMm(value), 2);
        }

        if (newUnit === "pt") {
            return round(inchesToPoints(value));
        }
    }

    if (oldUnit === "cm") {
        if (newUnit === "inch") {
            return floor(cmToInches(value), 2);
        }

        if (newUnit === "mm") {
            return floor((value * 10), 2);
        }

        if (newUnit === "pt") {
            return round(cmToPoints(value));
        }
    }

    if (oldUnit === "mm") {
        if (newUnit === "inch") {
            return floor(mmToInches(value), 2);
        }

        if (newUnit === "cm") {
            return floor((value / 10), 2);
        }

        if (newUnit === "pt") {
            return round(mmToPoints(value));
        }
    }

    if (oldUnit === "pt") {
        if (newUnit === "inch") {
            return floor(pointsToInches(value), 2);

        }

        if (newUnit === "cm") {
            return floor(pointsToCm(value), 2);
        }

        if (newUnit === "mm") {
            return floor(pointsToMm(value), 2);
        }
    }

    return floor(value, 2);
}