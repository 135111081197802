import $ from "jquery"

/*
* Removes all selected values when clicked dashboard refresh button. 
* Removed string filter values here because we can't catch inside plugin lifecycles
*/
export const removeAllSelectedValuesFromStringFilter = () => {
    $("select").val("")
}

/*
* Removes selected plugins values when clicked dashboard refresh button. 
* Removed string filter values here because we can't catch inside plugin lifecycles
*/
export const removeSelectedValuesFromStringFilter = (pluginId) => {
    $("#" + pluginId).find("select").val("")
}