import { TagsOutlined } from "@ant-design/icons";
import React, { Component } from "react";
import i18n from "../../Utils/i18next";
import * as breakDescription from "../EmptyDashboardDescriptionBreaker/emptyDashboardDescriptionBreaker";
import { BASE_URL_REPORTS, BASE_URL_UI } from "../../config";
import { navigate } from "../../Utils/Global";
import Button from "../GeneralComponents/Button/Button";
import $ from "jquery";
import Text from "../GeneralComponents/Text/Text";
import { isValidWriteRoles } from "../DashboardPage/RoleStore";

export default class EmptyLandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emptyLandingDescription: i18n.t("LandingPage.EmptyLanding.EmptyLandingDescription"),
      emptyLandingDescriptionMobile: i18n.t("LandingPage.EmptyLanding.EmptyLandingDescriptionMobile")
    };
  }

  /**
   * Navigates to new dashboad page if you click on plus icon in emptylanding page text
   */
  newDashboadAdd() {
    navigate(BASE_URL_UI + "/dashboard");
  }

  /**
   * Opens Label's Popupcontainer if you click on label icon in emptylanding page text
   */
  openLabels() {
    $("#labelButton").click();
  }

  render() {
    return (
      <div className={"empty-dashboard"}>
        <div className="hide-on-mobile">
          <span className="text">
            {isValidWriteRoles() ? (
                <>
                  {breakDescription.beforeDashboard(
                    this.state.emptyLandingDescription
                  )}
                  <Button
                    className="btn btn-new new-button"
                    onClick={() => navigate(BASE_URL_UI + "/dashboard")}
                  >
                    {i18n.t("LandingPage.NewDashboard")}
                  </Button>
                  {breakDescription.sentenceFinisher(
                    this.state.emptyLandingDescription,
                    1
                  )}
                  {breakDescription.betweenDashboardAndReport(
                    this.state.emptyLandingDescription
                  )}
                  <Button
                    className="btn btn-new new-button"
                    onClick={() => navigate(BASE_URL_REPORTS + "/designer")}
                  >
                    {i18n.t("LandingPage.NewReport")}
                  </Button>
                  {breakDescription.sentenceFinisher(
                    this.state.emptyLandingDescription,
                    2
                  )}
                </>
              ) : null}
            {breakDescription.betweenReportAndLabel(this.state.emptyLandingDescription)}
            <Button
              className="btn btn-new new-button"
              onClick={this.openLabels}
            >
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <TagsOutlined className="label-icon" />
                <Text className="label-text">{i18n.t("Labels")}</Text>
              </span>
            </Button>
            {breakDescription.afterLabel(this.state.emptyLandingDescription)}
          </span>
        </div>
        <div className="show-on-mobile">
          <span className="text">
            {breakDescription.beforeIcon(this.state.emptyLandingDescriptionMobile)}
            <Button
              style={{
                display: "initial"
              }}
              className="btn btn-new circle-button"
              onClick={this.openLabels}
            >
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <TagsOutlined className="label-icon" style={{fontSize: "21px"}} />
                
              </span>
            </Button>
            {breakDescription.afterIcon(this.state.emptyLandingDescriptionMobile)}
          </span>
        </div>
      </div>
    );
  }
}
