import MeasureModal from "../MeasureModal/MeasureModal";
import React, { Component } from "react"
import { Row, Col, Button, Input } from "antd";
import i18n from "../../../Utils/i18next";
import $ from "jquery"
import { CloseOutlined, CrownOutlined } from "@ant-design/icons";
import Text from "../../GeneralComponents/Text/Text";

/**
 * IconSelector component is created to select icons for table plugin
 */
export default class IconSelector extends Component {
    constructor(props) {
        super(props);

        this.state = {
            iconSelector: false,
            icon: this.props.icon,
            iconOnChange: this.props.iconOnChange,
            searchInputOnChange: this.props.searchInputOnChange,
            search: ""
        }

        this.iconArrayList = []
    }

    componentDidMount() {
        if (this.state.icon !== undefined) {
            $(".icon-selector-cond-format").append(`<i class="${this.state.icon}"'></i>`)
        }
    }

    selectIconChangeVisible = () => {
        this.setState({
            ...this.state,
            iconSelector: !this.state.iconSelector,
            search: ""
        })
    }

    searchInputOnChange = (e) => {
        this.setState({
            search: e.target.value
        })
    }

    iconOnChange = value => {
        this.setState({
            ...this.state,
            icon: value,
            iconSelector: false,
            search: ""
        })

        this.props.handleChange({ type: this.props.type, value: value })
        $(".icon-selector-cond-format").find("i").remove()
        $(".icon-selector-cond-format").append(`<i class="${value}"'></i>`)
    }

    removeIcon = () => {
        //Removes icon for conditional format
        this.setState({
          ...this.state,
          icon: ""
        });
    
        this.props.handleChange({ type: this.props.type, value: "" })
        this.iconOnChange("")
      }

    render() {
        const isIconExist = this.state.icon !== "" && this.state.icon !== undefined ? true : false
        
        return (
            <>
                <Row style={{ marginBottom: "5px" }}>
                    <Col style={{ textAlign: "center" }} span={3}>
                        <CrownOutlined style={{ fontSize: "16px" }} />{" "}
                    </Col>
                    <Col style={{ textAlign: "left" }} span={9}>
                        <Text
                            style={{
                                fontSize: "16px",
                                display: "inline-block",
                                marginTop: "2px"
                            }}
                        >
                            {i18n.t("Dashboard.Configuration.Fields.Icon")}
                        </Text>
                    </Col>
                    <Col span={12}>
                        <Row style={{ display: "flex" }}> 
                            <Button
                                onClick={this.selectIconChangeVisible}
                                style={{
                                    height: 32,
                                    width: isIconExist ? "calc(100% - 74px)" : "100%",
                                }}
                            >
                                {this.state.iconSelector
                                    ? i18n.t("Dashboard.Configuration.Fields.CloseIconSelector")
                                    : i18n.t("Dashboard.Configuration.Fields.OpenIconSelector")}
                            </Button>
                            {
                                isIconExist ? (
                                    <>
                                        <i
                                            className="icon-selector-cond-format fa-2x"
                                            style={{
                                                width: 32,
                                                height: 32,
                                                marginLeft: 5,
                                                borderRadius: 4,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        />
                                        <CloseOutlined 
                                            onClick={this.removeIcon}
                                            style={{
                                                top: 0,
                                                color: "red",
                                                width: 32,
                                                height: 32,
                                                marginLeft: 5,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        />
                                    </>
                                ) : null
                            }
                        </Row>
                    </Col>
                </Row>

                <MeasureModal
                    icon={""}
                    iconOnChange={this.iconOnChange}
                    iconSelector={this.state.iconSelector}
                    search={this.state.search}
                    searchInputOnChange={this.searchInputOnChange}
                    selectIconChangeVisible={this.selectIconChangeVisible}
                />
            </>
        )
    }
}