export const IS_CUSTOM_DASHBOARD = "IS_CUSTOM_DASHBOARD";
export const IS_DISABLED_COLUMNS_UPDATED = "IS_DISABLED_COLUMNS_UPDATED"

/**
 * 
 * @param {*}
 * @returns 
 * 
 * Sets custom dashboard status
 */
export function changeCustomDashboardStatus(status, callback) {
  let statusAndCallback = {status, callback}

  return (dispatch) => {
    dispatch({
      type: IS_CUSTOM_DASHBOARD,
      payload: statusAndCallback,
    });
  };
}

/**
 * 
 * @param {*}
 * @returns 
 * 
 * Sets disabled columns
 */
export function changeDisabledColumnsStatus(status, callback) {
  let statusAndCallback = {status, callback}
  
  return (dispatch) => {
    dispatch({
      type: IS_DISABLED_COLUMNS_UPDATED,
      payload: statusAndCallback,
    });
  };
}
