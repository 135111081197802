import { Droppable as DNDDroppable } from "react-beautiful-dnd";
import React, { Component } from "react"
import Text from "../../GeneralComponents/Text/Text"
import { isColumnAggregatableDestinationColumn } from "../ColumnMapDragDrop"

/*
* Component for columnMap area length === 0 etc(if hidden area hasn't any column)
*/
export default class EmptyColumn extends Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.rerenderProcessStarted = false;
        this.callBackObject = {};
    }

    /*
    * Gets style when drag column
    */
    getListStyle = (isDraggingOver, snapshot, provided) => ({
        width: "100%",
        backgroundColor: this.changeBackgroundColor(provided, isDraggingOver, snapshot)
    });

    changeBackgroundColor = (provided, isDraggingOver, snapshot) => {
        let draggedColumn = snapshot.draggingOverWith !== null ? snapshot.draggingOverWith : ""

        isColumnAggregatableDestinationColumn(provided, isDraggingOver, this.props.columnList, this.props.aggregatableDataTypes, this.props.isContainerAcceptAllColumn, draggedColumn)
    }

    render() {
        return (
            <DNDDroppable droppableId={this.props.type}>
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={this.getListStyle(snapshot.isDraggingOver, snapshot, provided)}
                    >
                        <Text
                            type={"span"}
                            style={{
                                color: "#a2a2a2",
                                fontSize: "14px",
                                textAlign: "center",
                                display: "block",
                            }}
                        >
                            {this.props.translate}
                        </Text>
                    </div>
                )}
            </DNDDroppable>
        )
    }
}