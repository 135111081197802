import { Checkbox, InputNumber, Select } from "antd";
import React, { Component } from "react";
import Text from "../../../../GeneralComponents/Text/Text";
import i18n from "../../../../../Utils/i18next";

export default class SheetSettings extends Component {
    render() {
        const { options } = this.props;

        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%"
                }}
            >
                {/* Left Side */}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%"
                    }}
                >
                    <Text
                        style={{
                            marginBottom: 8,
                            fontWeight: 600
                        }}
                    >
                        {i18n.t("Designer.PageOrder.Title")}
                    </Text>
                    <div
                        style={{
                            display: "flex",
                            paddingLeft: 8,
                            marginBottom: 16,
                            width: "50%"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                marginRight: 8,
                                minWidth: "fit-content"
                            }}
                        >
                            <Text
                                style={{
                                    height: 32,
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: 4,
                                    marginLeft: 0
                                }}
                            >
                                {i18n.t("Designer.PageOrder.Order")}:
                            </Text>
                            <Checkbox
                                onChange={(event) => this.props.setOption("useFirstPageNumber", event.target.checked)}
                                checked={options.useFirstPageNumber}
                                style={{
                                    height: 32,
                                    width: "fit-content",
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: 4,
                                    marginLeft: 0
                                }}
                            >
                                {i18n.t("Designer.PageOrder.FirstPageNumber")}:
                            </Checkbox>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "100%"
                            }}
                        >
                            <Select
                                showArrow
                                value={options.pageOrder}
                                onSelect={(value) => this.props.setOption("pageOrder", value)}
                                style={{
                                    height: 32,
                                    marginBottom: 4,
                                    width: "100%"
                                }}
                                getPopupContainer={(trigger) => trigger.parentNode}
                            >
                                <Select.Option value={"downThenOver"}>{i18n.t("Designer.PageOrder.DownThenOver")}</Select.Option>
                                <Select.Option value={"overThenDown"}>{i18n.t("Designer.PageOrder.OverThenDown")}</Select.Option>
                            </Select>
                            <InputNumber
                                min={0}
                                disabled={!options.useFirstPageNumber}
                                value={options.firstPageNumber}
                                onChange={(value) => this.props.setOption("firstPageNumber", value)}
                                style={{
                                    marginBottom: 4
                                }}
                            />
                        </div>
                    </div>

                    <Text
                        style={{
                            margin: "8px 0",
                            fontWeight: 600
                        }}
                    >
                        {i18n.t("Designer.Scale.Title")}
                    </Text>
                    <div
                        style={{
                            display: "flex",
                            paddingLeft: 8,
                            marginBottom: 16,
                            width: "50%"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                marginRight: 8,
                                minWidth: "fit-content"
                            }}
                        >
                            <Checkbox
                                onChange={(event) => this.props.setOption("fitToWidthCheck", event.target.checked)}
                                checked={options.fitToWidthCheck}
                                style={{
                                    height: 32,
                                    width: "fit-content",
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: 4,
                                    marginLeft: 0
                                }}
                            >
                                {i18n.t("Designer.Scale.FitToWidth")}
                            </Checkbox>
                            <Checkbox
                                onChange={(event) => this.props.setOption("fitToHeightCheck", event.target.checked)}
                                checked={options.fitToHeightCheck}
                                style={{
                                    height: 32,
                                    width: "fit-content",
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: 4,
                                    marginLeft: 0
                                }}
                            >
                                {i18n.t("Designer.Scale.FitToHeight")}
                            </Checkbox>
                        </div>
                    </div>

                    <Text
                        style={{
                            margin: "8px 0",
                            fontWeight: 600
                        }}
                    >
                        {i18n.t("Designer.TableAlignment.Title")}
                    </Text>
                    <div
                        style={{
                            display: "flex",
                            paddingLeft: 8,
                            marginBottom: 16,
                            width: "50%"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                marginRight: 8,
                                minWidth: "fit-content"
                            }}
                        >
                            <Checkbox
                                onChange={(event) => this.props.setOption("horizontalCentered", event.target.checked)}
                                checked={options.horizontalCentered}
                                style={{
                                    height: 32,
                                    width: "fit-content",
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: 4,
                                    marginLeft: 0
                                }}
                            >
                                {i18n.t("Designer.TableAlignment.Horizontal")}
                            </Checkbox>
                            <Checkbox
                                onChange={(event) => this.props.setOption("verticalCentered", event.target.checked)}
                                checked={options.verticalCentered}
                                style={{
                                    height: 32,
                                    width: "fit-content",
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: 4,
                                    marginLeft: 0
                                }}
                            >
                                {i18n.t("Designer.TableAlignment.Vertical")}
                            </Checkbox>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}