import "antd/dist/antd.css";
import "../../css/bootstrap.min.css";
import React, { Component } from "react";
import PopupContainer from "../PopupContainer/PopupContainer";
import Button from "../Button/Button";
import PropTypes from "prop-types";
import Text from "../Text/Text";
import "./pagePopup.css";
import { RightOutlined } from "@ant-design/icons";
import { setPagePopupContent } from "./PagePopupAction";
import { connect } from "react-redux";
import { setPagePopupPluginId } from "../../Plugins/PluginComponents/common.js";

/**
 * General popup component.
 * The variables and props come from redux reduces.
 * Content props can be react component or children.
 * Header props should include title and description.
 * Position calculate from PagePopupConfigure.js
 */
class PagePopup extends Component {
  constructor(props) {
    super(props);

    this.prototype = {
      title: PropTypes.string,
      description: PropTypes.string,
      cancelButton: PropTypes.boolean,
      width: PropTypes.string,
      constantHeight: PropTypes.string,
      additionalPosition: PropTypes.string,
      top: PropTypes.string,
    };

    this.state = {
      visible: false,
      width: this.props.state.PagePopupReducer.contentObject.width
        ? this.props.state.PagePopupReducer.contentObject.width
        : 450,
      constantHeight: this.props.constantHeight
        ? this.props.constantHeight
        : 500,
      additionalPosition: this.props.additionalPosition
        ? this.props.additionalPosition
        : "0",
      top: this.props.top ? this.props.top : "0",
      content: this.props.state.PagePopupReducer.contentObject.content,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.state.PagePopupReducer === this.props.state.PagePopupReducer) {
      return;
    } 

    let flag = false;
    let newState = { ...this.state };

    /** Find selected popup and visible */
    if (nextProps.state.PagePopupReducer.contentObject.visible === true) {
      newState.visible = true;
      newState.width = nextProps.state.PagePopupReducer.contentObject.width;

      setPagePopupPluginId(
        nextProps.state.PagePopupReducer.contentObject.pluginId
      );

      flag = true;
    }

    if (
      nextProps.state.PagePopupReducer.contentObject.content !==
        this.props.state.PagePopupReducer.contentObject.content &&
      (!nextProps.state.PagePopupReducer.contentObject.content.props.pluginId ||
        nextProps.state.PagePopupReducer.contentObject.pluginId ==
          nextProps.state.PagePopupReducer.contentObject.content.props.pluginId)
    ) {
      newState.width = nextProps.state.PagePopupReducer.contentObject.width;
      newState.content = nextProps.state.PagePopupReducer.contentObject.content;

      flag = true;
    }

    if (
      flag === true &&
      (this.state.visible !== false || newState.visible == true)
    ) {
      this.setState(newState);
    } else if (nextProps.state.PagePopupReducer.contentObject.visible === false) {
      this.setState({
        ...this.state,
        visible: false
      })
    }
  }

  /** To make the current popup invisible */
  cancel = () => {
    this.setState({ ...this.state, visible: false });
    setPagePopupPluginId("");
  };

  render() {
    const { contentObject } = this.props.state.PagePopupReducer; // Reducer variables.
    return (
      <div
        id={this.props.id}
        width={this.state.width}
        height={this.state.constantHeight}
        className={"pagePopup"}
        style={{
          display: this.state.visible ? "block" : "none",
          top: contentObject.popupPositions["top"] + "px",
          left: contentObject.popupPositions["left"] + "px",
        }}
      >
        {this.state.visible ? (
          <PopupContainer
            width={this.state.width + "px"}
            constantHeight={this.state.constantHeight + "px"}
            additionalPosition={this.state.additionalPosition}
            top={this.state.top}
            background={"rgb(251, 251, 251)"}
          >
            <div
              className={"containerIcon"}
              style={{
                top: contentObject.containerIconPositions["top"] + "px",
                left: contentObject.containerIconPositions["left"] + "px",
                display: contentObject.containerIconPositions["display"],
              }}
            >
              <RightOutlined />
            </div>

            {contentObject.header.title ? (
              <Text style={{ textAlign: "center" }} type="h4">
                {contentObject.header.icon ? contentObject.header.icon : null}{" "}
                {contentObject.header.title}
              </Text>
            ) : null}
            {contentObject.header.description ? (
              <Text
                type="h6"
                style={{
                  color: "rgb(162, 162, 162)",
                  marginBottom: "27px",
                  textAlign: "center",
                }}
              >
                {contentObject.header.description}
              </Text>
            ) : null}

            <div className={""}>
              {this.state.content ? this.state.content : this.props.children}
            </div>

            {this.props.cancelButton !== false ? (
              <Button
                style={{
                  position: "absolute",
                  top: "3px",
                  right: "15px",
                  borderRadius: "40px",
                  padding: "6px 11px",
                  backgroundColor: "white",
                  zIndex: 2,
                }}
                type={"default"}
                onClick={this.cancel}
              >
                X
              </Button>
            ) : null}
          </PopupContainer>
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = {
  setPagePopupContent,
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PagePopup);
