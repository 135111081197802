import React, { Component } from "react";
import { Row, Col } from "antd";
import ModelCollapseView from "./ModelCollapseView";
import SelectModel from "./SelectModel/SelectModel";
import ModelCreate from "./ModelCreate";
import Button from "../GeneralComponents/Button/Button";
import { showError } from "../../Utils/Notification";
import { API_BASE } from "../../config";
import { post, get } from "../../Utils/WebService";
import i18n from "../../Utils/i18next";
import ModelToolsBar from "./ModelToolsBar";
import {
  getSelectedModel,
  setSelectedModel,
  removeSelectedModel,
} from "./ModelStore";
import _ from "lodash";
import { store } from "../..";
import { setModelPopupUtils } from "../Datasources/SelfServiceReducer/SelfServiceAction";
import "./ModelsArea.css"

const clone = require("rfdc")();

/**
 * This creates the component model creation model selection popup and model table accordions
 */
export default class ModelsArea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modelList: [],
      selectedModel: {},
      createModel: false,
      modelListEmpty: false,
      newCreatedModel: {}
    };
  }

  isModelListClicked = false

  componentDidMount() {
    this.getModelList();
  }

  componentWillReceiveProps(nextProps) {
    let newState = {...this.state}
    let flag = false

    if(nextProps.visible === true) {
      if(nextProps.model.id && this.state.selectedModel.id !== nextProps.model.id) {
        newState["selectedModel"] = nextProps.model

        flag = true
      } else if(this.isThereSetModel(nextProps)) {
        this.isModelListClicked = true;
        this.getModelList();
      } else if (nextProps.visible !== this.props.visible && (!nextProps.model || !nextProps.model.id)) {
         let modelIdFromCookie = getSelectedModel();
         let selectedModel = undefined;

         // if there is a modelId in cookie then set selectedModel else first element in the modelList
         if (modelIdFromCookie) {
          selectedModel = this.state.modelList.find(m => m.id === modelIdFromCookie);
         }
         else if (!this.state.modelListEmpty) {
          selectedModel = this.state.modelList[this.state.modelList.length-1];
         }

         if (selectedModel) {
          newState["selectedModel"] = selectedModel;
          
          this.fillSelectedModel(selectedModel);
          flag = true;
         }
      } else if (!_.isEqual(nextProps.model, this.props.model)) {
        newState["selectedModel"] = nextProps.model

        flag = true
      }
      
      if (nextProps.autoSelectModelId && nextProps.autoSelectModelId !== this.props.autoSelectModelId) {
        this.getModelList(nextProps.autoSelectModelId);

        let modelUtils = {
          openModelPopup: false,
          modelId: undefined
        }


        store.dispatch(setModelPopupUtils(modelUtils))
      }

      if (flag) {
        this.setState(newState)
      }
    }
  }

  /**
   * 
   * @param {*} nextProps 
   * @returns 
   * 
   * Checks if there is a model coming from the dashboard
   */
  isThereSetModel = (nextProps) => {
    return (
      nextProps.model.id === undefined &&
      this.state.modelList.length === 0 &&
      this.isModelListClicked === false
    );
  };

  /**
   * Model dropdown list visibility change
   */
  onChangeModelListDropdownVisibility = () => {
    this.setState({
      ...this.state
    });
  };

  /**
   *
   * @param {*} selectedModelId
   * @param {*} modelList
   * Checks whether the incoming model is in the model list
   */
  hasModelInModelList = (selectedModelId, modelList) => {
    return (
      selectedModelId &&
      modelList.filter((model) => model.id === selectedModelId)[0]
    );
  };

  /**
   * Allows models registered at startup to be fetched and added to the modelList variable
   */
  getModelList = (autoSelectId = undefined) => {
    const successFunc = (response) => {
      let modelListEmptyStatus = false;

      if (response.data.length == 0) {
        modelListEmptyStatus = true;
      }

      this.props.setModelList(response.data);

      this.setState(
        {
          modelList: response.data,
          modelListEmpty: modelListEmptyStatus
        },
        () => {
          let selectedModelId = autoSelectId ? autoSelectId : this.props.modelId;
          this.isModelListClicked = true;

        if (selectedModelId !== this.state.selectedModel.id) {
          if (this.hasModelInModelList(selectedModelId, response.data)) {
            this.fillSelectedModel(
              response.data.filter((model) => model.id === selectedModelId)[0]
            );
          } else {
            if (selectedModelId !== undefined) {
              removeSelectedModel();
            }
          }
        }
      }
    );
  };

    let url = `${API_BASE}/subjectArea/nameList`;
    get(url, successFunc);
  };

  /**
   * Deleted model is removed from ModelList and modelList and selectedModel are updated.
   */
  deleteModelInList = (model) => {
    let modelList = this.state.modelList.filter((item) => item.id !== model.id);
    let modelListEmptyStatus = false;

    if (modelList.length == 0) {
      modelListEmptyStatus = true;
    }

    this.setState({
      ...this.state,
      modelList: modelList,
      selectedModel: modelListEmptyStatus ? {} : modelList[modelList.length-1],
      modelListEmpty: modelListEmptyStatus,
    });

    this.props.setModelList(modelList);
    // model deleted selected model must be cleared
    if (modelListEmptyStatus) {
      this.props.selectedModel({})
      removeSelectedModel()
    }
    else {
      const lastModel = modelList[modelList.length - 1];

      this.props.selectedModel(lastModel)
      setSelectedModel(lastModel.id)
    }
    
    this.props.visibleDataSourcesPopup(false);
  };

  /**
   * Newly created model is added to selectedModel and modelList
   */
  addNewModel = (model) => {
    let modelList = [];
    modelList = clone(this.state.modelList);

    let hasModelStatus = modelList.find(function (item) {
      return item.displayName == model.displayName;
    });

    if (!hasModelStatus) {
      modelList.push(model);

      this.props.selectedModel(model);
      this.props.setModelList(modelList);
    }

    this.setState({
      ...this.state,
      modelList: modelList,
      selectedModel: model
    });

    this.props.editModel(model);
  };

  /**
   * 
   * @param {*} joinData 
   * When joins are added to the model, it marks the table types of the relevant tables.
   */
  updateModelTablesForJoin = (joinData) => {
    let selectedModel = {...this.state.selectedModel}

    selectedModel.tables.map((table) => {
      let tableDataSourceKeyWithAlias = table.dataSourceKey + "?" + table.aliasName 
      
      if(joinData.factTableAlias === tableDataSourceKeyWithAlias) {
        table.tableType = "Fact"
      } else if(joinData.dimensionTableAlias === tableDataSourceKeyWithAlias) {
        table.tableType = "Dimension"
      }
    })

    this.updateSelectedModel(selectedModel.tables);
  }

  updateSelectedModel = (tables) => {
    let model = clone(this.state.selectedModel);
    model.tables = tables;

    this.setState({
      selectedModel: model,
    });

    this.props.selectedModel(model);
  };

  /**
   * TODO Change displayName for model => Backend de displayname in değiştirilebilmesi için gerekli düzenlemeler yapıldıktan sonra bu kısım açılacaktır
   */
  updateModelList = model => {
    //  let modelList = clone(this.state.modelList)
    //  modelList.map((item) => {
    //   if( item.name == model.name){
    //    item = model
    //   }
    //  })
    //  this.setState({
    //    ...this.state,
    //    modelList: modelList,
    //    selectedModel: model
    //  })
    //
  };

  /**
   * Allows the selected model to be set to the selectedModel variable.
   */
  fillSelectedModel = (model, visibleDataSourcePopup = true) => {
    if (model.id) {
      let url = `${API_BASE}/subjectArea/${model.id}`;

      const successFunc = (response) => {
        model = response.data;
        this.setState({
          ...this.state,
          selectedModel: model
        });

        this.props.selectedModel(model);

        if (
          visibleDataSourcePopup === true &&
          model.id !== this.state.newCreatedModel.id
        ) {
          this.props.visibleDataSourcesPopup(false);
        } else {
          this.setState({
            newCreatedModel: {},
          });
        }
      };

      get(url, successFunc);
    } else {
      this.setState({
        ...this.state,
        selectedModel: model,
      });
    }
  };

  modelListDropDownVisibleChange = (status) => {
    if (this.state.modelList.length === 0) {
      this.getModelList();
    } else {
      this.setState({
        ...this.state
      });
    }
  };

/**
 * 
 * @param {*} model 
 * @param {*} response 
 * @param {*} newTableInModel 
 * @param {*} errorResponse 
 * It includes the operations performed in case of adding or removing tables to the model
 */
  modelTableAddOrDeleteResponse = (
    model,
    response,
    newTableInModel,
    errorResponse = {}
  ) => {
    if (Object.keys(errorResponse).length === 0) {
      let modelList = clone(this.state.modelList);
      let newCreatedModel = {};

      if (response.data.id) {
        if (!model.id) {
          model.id = response.data.id;
          newCreatedModel = model;

          modelList.map((model) => {
            if (model.name == response.data.name) {
              model.id = response.data.id;
            }
          });
        }

        this.setState({
          ...this.state,
          selectedModel: model,
          createModel: false,
          modelListEmpty: false,
          modelList: modelList,
          newCreatedModel: newCreatedModel,
        });

        this.props.selectedModel(model);
        this.props.setModelList(modelList);
        // this.props.updateSubjectAreaCount(); //TODO payment kısmı eklenince açılacak
        this.props.modelTableSaveResponse(newTableInModel, true);
      }
    } else {
      this.props.modelTableSaveResponse(newTableInModel, false);
      showError(errorResponse);
    }
  };

  /**
   * Visible status for model creation section
   */
  createModelVisibleChange = (status) => {
    this.setState({
      ...this.state,
      createModel: status,
    });

    if (status) {
      this.props.visibleDataSourcesPopup(false);
    }
  };

  /**
   * Finds the model from the list according to the model name selected from dropdown and sends it to the fillSelectedModel method to load its tables.
   */
  selectModelInList = (value, isCookieWillSet) => {
    let selectedModel = this.state.modelList.filter(
      (item) => item.id == value
    )[0];

    if (selectedModel && selectedModel.id) {
      if (isCookieWillSet) {
        setSelectedModel(value);
      }

      this.fillSelectedModel(selectedModel, true);
    }
  };

  render() {
    const showModelArea =
    this.state.selectedModel.tables &&
    this.state.selectedModel.tables.length >= 0 &&
    this.state.selectedModel?.id

    let pincheck = this.props.pinned;
    
    return this.props.visible === true ? (
      <div className={"modals-area"} id="modelSelectionArea">
        <div
          style={{
            backgroundColor: "rgb(251, 251, 251)",
            position: "sticky",
            top : "15px"
          }}
        >
          <Row gutter={6}>
            {this.state.createModel == false &&
            this.state.modelListEmpty == false ? (
              <div style={{ marginBottom: "10px", minWidth:"125px" }}>
                <div
                  style={{
                    display: showModelArea ? "block" : "none",
                  }}
                >
                </div>
                <div
                  style={{
                    position: "relative",
                    top: pincheck ? "-9px" : "0px",
                    display: "flex",
                    gap: "4px",
                    marginTop: "18px"
                  }}
                >
                    <Button
                      id="createNewModelButton"
                      onClick={() => this.createModelVisibleChange(true)}
                      className={"general-button-outlined"}
                      style={{ width: "110px", margin: "0", fontSize: "13px", fontWeight:"bold", height:"40px"}}
                    >
                      {i18n.t("Model.CreateModel")}
                    </Button>
                    <SelectModel
                      modelList={this.state.modelList}
                      modelId={this.props.modelId}
                      style={{ width: "100%"}}
                      handleSelection={this.selectModelInList}
                      findJoinChanges={this.props.findJoinChanges}
                      visible={this.state.modelListDropDownVisible}
                    />
                    <ModelToolsBar
                      modelListDropDownVisibleChange={
                        this.modelListDropDownVisibleChange
                      }
                      updateSelectedModel={this.updateSelectedModel}
                      selectedModel={this.state.selectedModel}
                      updateModelTablesForJoin={this.updateModelTablesForJoin}
                      deleteModelInList={this.deleteModelInList}
                      join={this.props.join}
                      findJoinChanges={this.props.findJoinChanges}
                      updateModelTablesForWhenDeletedJoin={
                        this.updateModelTablesForWhenDeletedJoin
                      }
                      getModelList={this.getModelList}
                      getModelTables={this.props.getModelTables}
                      joinPopupVisible={this.props.joinPopupVisible}
                      setJoinModalPopupVisiblity={
                        this.props.setJoinModalPopupVisiblity
                      }
                      pinned={this.props.pinned}
                  ></ModelToolsBar>               
                </div>
              </div>
            ) : (
              <ModelCreate
                modelList={this.state.modelList}
                createModelVisibleChange={this.createModelVisibleChange}
                addNewModel={this.addNewModel}
                editModel={this.props.editModel}
              ></ModelCreate>
            )}
          </Row>
        </div>
        <br></br>
        <ModelCollapseView
          modelListEmpty={this.state.modelListEmpty}
          createModel={this.state.createModel}
          model={this.state.selectedModel}
          updateModelList={this.updateModelList}
          deleteModelInList={this.deleteModelInList}
          deleteTableInModel={this.props.selectedTables}
          updateSelectedModel={this.updateSelectedModel}
          editModel={this.props.editModel}
          join={this.props.join}
          newTableInModel={this.props.newTableInModel}
          modelTableAddOrDeleteResponse={this.modelTableAddOrDeleteResponse}
          pinned={this.props.pinned}
          createModelPin={this.state.modelListDropDownVisible}
          getModel={this.props.getModel}
          newColumnAddedFlag={this.props.newColumnAddedFlag}
          removeNewColumn={this.props.removeNewColumn}
          getModelList = {this.getModelList}
        ></ModelCollapseView>
      </div>
    ) : (
      <div></div>
    );
  }
}
