export const navigate = (link) => {
  setTimeout(function () {
    window.location.href = link;
  }, 250);
}

var timer;

export const loadingScreen = (status) => {

  if (status == true) {
    timer = setTimeout(function () {
      document.getElementById("splashScreenContainer").style.display = "initial";
    }, 10);
  } else if (status == false) {
    clearTimeout(timer);
    document.getElementById("splashScreenContainer").style.display = "none";
  } else {
    return document.getElementById("splashScreenContainer").style.display;
  }
}

/** Delete a cookie. */
export const eraseCookie = (name, path) => {
  createCookie(name, "", -1, path);
};

export const createCookie = (name, value, days, path) => {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    var expires = "; expires=" + date.toGMTString();
  } else var expires = "";
  document.cookie = name + "=" + value + expires + "; path=" + path;
};