import {
  SET_EXPRESSION,
  SET_DEFINED_EXPRESSION,
  SET_PROCESSING_LIST,
  SET_LAST_OPENED_KEY,
} from "./ExpressionParameterAction";

/**
 *
 * @param {*} state
 * @param {*} action
 * Reducer for expression parameter
 *
 */
export default function ExpressionParameterReducer(
  state = {
    expressionMap: new Map(),
    definedExpressionSet: new Map(), //key: column unique id, value: parameter key
    processingList: new Set(),
    lastOpenedKey: ""
  },
  action
) {
  switch (action.type) {
    case SET_EXPRESSION:
      return {
        ...state,
        expressionMap: new Map(action.payload),
      };
    case SET_DEFINED_EXPRESSION:
      return {
        ...state,
        definedExpressionSet: new Map(action.payload)
      };
    case SET_PROCESSING_LIST:
      return {
        ...state,
        processingList: new Set(action.payload)
      };
    case SET_LAST_OPENED_KEY:
      return {
        ...state,
        lastOpenedKey: action.payload
      }

    default:
      return state;
  }
}
