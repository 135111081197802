import { LOGIN_POPUP_VISIBILITY } from "./LoginPopupAction";

/**
 *
 * @param {*} state
 * @param {*} action
 * Reducer for login popup visibility
 *
 */
export default function LoginPopupReducer(
  state = {
    loginPopupVisibility: false,
    callback: {}
  },
  action
) {
  switch (action.type) {
    case LOGIN_POPUP_VISIBILITY :
      return {
        ...state,
        loginPopupVisibility: action.payload.status,
        callback: action.payload.callback
      };
    default:
      return state;
  }
}
