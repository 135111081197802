export const reactions = [
    {
        id: "filter",
        name: "Filter",
        description: "desc87",
        type: "general"
    }
];

export const conversionMap = {
    "table": {
        columnMap: {
            columns: {
                name: "Columns",
                from: ["label", "values"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
};