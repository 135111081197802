import React, { Component } from "react";
import { InputNumber } from "antd";
import i18n from "../../../../../Utils/i18next";

const min = 1;
const max = 3600;

/** timer operations and components for auto refresh */
export default class TimerInput extends Component {
  onChange = value => {
    if (value > max) {
      value = max;
    }

    if (value < min) {
      value = min;
    }

    this.props.setTimeOnChange(value);
  };

  render() {
    return (
      <div style={{ margin: "10px 0" }}>
        <span style={{ marginRight: "16px" }}>
          {i18n.t("Dashboard.AutoRefresh.RefreshTime")}
        </span>
        <InputNumber
          style={{ display: "inline-block", width: "20%" }}
          onChange={e => this.onChange(e)}
          disabled={!this.props.enable}
          min={min}
          max={max}
          defaultValue={this.props.second}
          value={this.props.second}
        />
        <span
          style={{
            fontSize: "12px",
            marginLeft: "5px",
            color: "#e08c8c"
          }}
        >
          Min: {min} - Max: {max}
        </span>
      </div>
    );
  }
}
