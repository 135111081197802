
export const PIE_CHART_SET_DATA = "PIE_CHART_SET_DATA";

/**
 * 
 * @param contentObject 
 * @returns 
 * 
 * Sets Pie Chart Data for Export
 */
export const pieChartSetData = (contentObject) => {
  return (dispatch) => {
    dispatch({
      type: PIE_CHART_SET_DATA,
      payload: contentObject,
    });
  };
}
