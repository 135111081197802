import { CREATE_SUCCESS, GET_REAL_DB_MODEL, GET_SCHEMA_NAMES, GET_SCHEMA} from './CreateModelAction';

export default function createModelReducer(state = {
    modelCreateState: "",
    modelObject: {},
    dataSourceSchemaNames: [],
    schema: {}
}, action) {

    switch (action.type) {

        case CREATE_SUCCESS:
            return {
                ...state,
                modelCreateState: action.payload
            }
        case GET_REAL_DB_MODEL:
            return {
                ...state,
                modelObject: action.payload
            }
        case GET_SCHEMA_NAMES:
            return {
                ...state,
                dataSourceSchemaNames: action.payload
            };
        case GET_SCHEMA:
            return {
                ...state,
                schema: action.payload
            }
        default:
            return state;
    }
}