import React, { Component } from "react";
import Tooltip from "../../../../GeneralComponents/Tooltip/Tooltip";
import { Switch, Row, Col } from "antd";
import i18n from "../../../../../Utils/i18next";

/** enable/disable auto refresh for dashboard */
export default class EnableSwitch extends Component {
  render() {
    return (
      <div>
        <span style={{ marginRight: "16px" }}>
          <Row>
            {i18n.t("Disable")} &nbsp;
            <Switch
              id="autoRefreshEnable"
              checked={this.props.enable}
              onClick={() => this.props.enableDisable()}
            />
            <Tooltip
              id={i18n.t("Dashboard.AutoRefresh.EnableDescription")}
              tooltip={i18n.t("Dashboard.AutoRefresh.EnableDescription")}
            >
              <span
                id="autoRefreshEnableDescription"
                style={{ fontSize: "13px", color: "#226191" }}
              >
                &nbsp;(?)&nbsp;
              </span>
            </Tooltip>
            {i18n.t("Enable")}
          </Row>
        </span>
      </div>
    );
  }
}
