/* eslint-disable no-eval */
import React, { Component } from "react";
import { pluginSelector } from "../../Utils/PluginSelector";
import PluginTools from "./PluginTools";
import { checkTableJoins } from "../GeneralComponents/Join/Join";
import JoinPopup from ".././Joining/JoinPopup/JoinPopup";
import "./plugin.css";
import Text from "../GeneralComponents/Text/Text";
import { Input, Breadcrumb, message } from "antd";
import Interaction from "../Interaction/Interaction";
import {
  setPluginPrivateFiltersTrigger,
  setPluginFiltersTrigger,
  setSourcePluginsWithValues,
  setUpdatedPlugins,
  setPluginPrivateFiltersTitleTrigger,
} from "../Plugins/PluginTriggerAction";
import { store } from "../../index";
import {
  isPrivateMethod,
  triggerPrivateMethod,
  isPrivateMethodForTitle
} from "../Interaction/PrivateMethodTriggerManagement";
import $ from "jquery";
import PluginLoader from "./PluginLoader";
import PluginError from "./PluginError";
import Axios from "axios";
import PluginFilterShow from "./PluginFilterShow/PluginFilterShow";
import {
  setPluginsDrillDowns,
  setTriggeredDrillDowns,
} from "./../DrillDown/DrillDownAction";
import { checkColumnsHasLocationFieldName } from "../ConditionalFormatting/RenderConditionalListName";
import i18n from "i18next";
import { calculateText, getContrastColor, shouldIClose } from "./PluginComponents/common";
import { calculateAndAppendPluginHeight, calculatePluginInlineHeight } from "../DrillDown/PluginHeightWithDrilldown"
import _ from "lodash";
import { changePluginLoaderVisibility } from "../GeneralComponents/PluginLoader/PluginLoaderAction";
import { absorbEvent, deepCopy } from "../../Utils/Global";
import { changeRightClickedPlugin, rightClickCopiedPluginStatus } from "../GeneralComponents/ContextMenu/ContextMenuAction";
import { connect } from "react-redux";
import { changeCustomDashboardStatus } from "../GeneralComponents/CustomDashboard/CustomDashboardAction"
import { isValidWriteRolesOrCustomDashboard } from "../GeneralComponents/CustomDashboard/customDashboardControls";
import CacheMode from "../CacheMode/CacheMode";
import { getCopiedPluginObj } from "../GeneralComponents/PluginCopyPaste/pluginCopyPaste";
import { withoutPluginRenderPlugins } from "./WithoutPluginRender";
import PluginLoaderForWithoutPluginRender from "./PluginLoaderForWithoutPluginRender";
import "../../ui/Styles/theme.css"
import { EXCEL_EXPORT_PROGRESS, generalExcelExport } from "../../Utils/Exports/GeneralExcelExport";
import { getPluginsAllColumnsByField } from "../../Utils/PluginOperations";
import { setDefinedExpression, setExpression } from "../ExpressionParameter/ExpressionParameterAction";
import { showNotificationWithIcon } from "../../Utils/Notification";

const pluginsThatWontAutoRefresh = new Set([
  "title",
  "filter",
  "radio-button-filter",
  "selection-box",
  "image",
  "flag",
  "i-frame",
  "glassed-title",
]);
const excludedFromCommonTitleConfig = new Set([]);

//** TODO: When drilldown complete, temp data will remove. This variable simulates drilldown filters. */
// Drilldown filters
const drilldownFilterColumn1 = { displayName: "Ay adı", value: "Haziran" };
const drilldownFilterColumn2 = { displayName: "Cinsiyet", value: "Erkek" };
const drilldownFilterColumn3 = {
  displayName: "Meydan adı",
  value: "İstanbul Atatürk Havalimanı",
};

// eslint-disable-next-line no-unused-vars
const pluginFilterShowData = {
  drilldowns: {
    title: "Drilldown Filters",
    data: [
      drilldownFilterColumn1,
      drilldownFilterColumn2,
      drilldownFilterColumn3,
      drilldownFilterColumn1,
      drilldownFilterColumn1,
      drilldownFilterColumn2,
      drilldownFilterColumn3,
      drilldownFilterColumn1,
    ],
  },
  interactions: {
    title: "Interaction Filters",
    data: [
      drilldownFilterColumn1,
      drilldownFilterColumn2,
      drilldownFilterColumn3,
      drilldownFilterColumn1,
      drilldownFilterColumn3,
      drilldownFilterColumn1,
    ],
  },
  navigation: {
    title: "Navigation Filters",
    data: [
      drilldownFilterColumn1,
      drilldownFilterColumn2,
      drilldownFilterColumn3,
      drilldownFilterColumn1,
      drilldownFilterColumn3,
      drilldownFilterColumn1,
    ],
  },
};
// --- Drilldown filters ---

class Plugin extends Component {
  constructor(props) {
    super(props);

    let plugin = { ...this.props.plugin };

    if (this.props.plugin.drilldownProcesses === undefined) {
      plugin.drillDowns = this.convertDrillDownStringToMap(plugin.drillDowns);
    }

    this.state = {
      component: null,
      rerender: false,
      titleChangeStatus: false,
      configVisibility: false,
      dataVisibility: false,
      joinErrorVisibility: false,
      clickedRefresh: false,
      hasNotJoinedData: false,
      refreshedPluginId: "",
      conditionalFormattingVisibility: false,
      doesPluginHasNotJoinedTable: true,
      plugin: plugin,
      firstRender: true,
      stateChanged: false,
      navigationVisibility: false,
      paddingRight: "2px", // 2px is just gives enough padding to align.
      columnMapWithLocationFieldName: {},
      isNewPlugin: false,
      commonTitleSettings: this.props.commonTitleConfig,
      isSelectedPlugin: false,
      isPinned: false,
      findValidJoin: true,
      theme: this.props.theme,
      limit: this.props.plugin.limit ? this.props.plugin.limit : 10000
    };

    this.touchTimer = null;
    this.popupsRef = React.createRef();

    this.setUpdatedPlugin(plugin);
    store.dispatch(changePluginLoaderVisibility(plugin.id, true))
  }

  //sets a limit for plugins' data
  setDataLimitForPlugin = (limit) => {
    if (+limit && !isNaN(+limit)) {
      this.setState({ limit: limit })
    }
  }

  componentWillUnmount() {
    const pluginContainer = document.getElementById("plugin-container-" + this.state.plugin.id);

    if (pluginContainer) {
      pluginContainer.removeEventListener("contextmenu", this.triggerContextMenu);
      pluginContainer.removeEventListener("touchstart", this.handleTouchStart);
      pluginContainer.removeEventListener("touchend", this.handleTouchEnd);
      pluginContainer.removeEventListener("touchmove", this.handleTouchMove);
    }

    document.removeEventListener("mouseup", this.handleClickOutside);
    window.removeEventListener("storage", this.handleCopiedPluginChange);

    //To cancel requests.
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel();
    }
  }

  //If click outside hide switcher area
  handleClickOutside = (event) => {
    const isClose = shouldIClose(event);
    const isTargetCurrentPlugin = $(event.target).closest("#plugin-" + this.props.plugin.id).length > 0;
    const isTargetCurrentPluginAlternative = $(event.target).closest("#" + this.props.plugin.id).length > 0;
    const isTargetTitle = $(event.target).closest("#title-" + this.props.plugin.id).length > 0;
    const isDropdownItem = $(event.target).closest(".ant-select-dropdown-menu-item").length > 0;
    const isDropdownTitle = !$(event.target).closest(".ant-select-dropdown-menu-item-group-title").length > 0;
    const saveAsPopup = !$(event.target).closest(".ant-popover-inner").length > 0;
    const isModalPlugin = !$(event.target).closest(".ant-modal-content").length > 0;
    const ismodalWrap = !$(event.target).closest(".ant-modal-wrap").length > 0;
    const isTooltip = !$(event.target).closest(".ant-tooltip").length > 0;
    const isPluginArea = isTargetTitle || isTargetCurrentPlugin || isTargetCurrentPluginAlternative;
    const isTargetThisPlugin = isPluginArea || isDropdownItem;
    const shouldIChooseBySelectedPlugin = this.state.isSelectedPlugin === false && !isDropdownItem && saveAsPopup && isModalPlugin && ismodalWrap && isDropdownTitle && isTooltip;

    if (!isTargetThisPlugin) {
      $("#grid-" + this.state.plugin.id).find(".plugin-container").css("outline", "");

      if (isClose) {
        if (
          $(event.target).closest(".anticon-swap").length > 0 ||
          $(event.target).closest(".anticon-tool").length > 0 ||
          $(event.target).closest(".anticon-database").length > 0
        ) {
          this.setState({
            ...this.state,
            isSelectedPlugin: false,
            isPinned: false,
          });
        } else {
          if (
            (this.state.conditionalFormattingVisibility ||
              this.state.configVisibility ||
              this.state.dataVisibility ||
              this.state.navigationVisibility) &&
            !this.state.isPinned
          ) {
            if ($(event.target).closest(".holder").length > 0 && $(event.target).closest(".holder").find("#plugin-tools-" + this.state.plugin.id).length > 0) {
              this.setState({ isSelectedPlugin: false });
            } else {
              if (this.state.isSelectedPlugin) {
                this.setState({ isSelectedPlugin: true }, () => {
                  if (this.state.configVisibility || this.state.conditionalFormattingVisibility || this.state.navigationVisibility || this.state.dataVisibility) {
                    this.changeConfigVisibility(false, true);
                  }
                });
              } else {
                this.setState({ isSelectedPlugin: false }, () => {
                  if (this.state.configVisibility || this.state.conditionalFormattingVisibility || this.state.navigationVisibility || this.state.dataVisibility) {
                    this.changeConfigVisibility(false, true);
                  }
                });
              }
            }
          } else if (this.state.isPinned === false && this.state.isSelectedPlugin === true) {
            this.setState({ isSelectedPlugin: false });
          }
        }
      }
    } else if (!this.props.isDashboardSliderActive && shouldIChooseBySelectedPlugin) {
      const reduxState = store.getState();
      const pluginDiv = $("#" + this.state.plugin.id)[0];
      const widthClientScreen = window.screen.width

      const isPluginContextMenuValid = reduxState.ContextMenuReducer.rightClickedPluginFunction === this.state.plugin.id;
      const canXLSXExportShow = pluginDiv?.XLSX instanceof Function;
      const canPDFExportShow = pluginDiv?.PDF instanceof Function;
      const canPNGExportShow = pluginDiv?.PNG instanceof Function;
      const isPluginExportable = canXLSXExportShow || canPDFExportShow || canPNGExportShow;
      const isValidWriteRolesOrCustomDashboardMode = isValidWriteRolesOrCustomDashboard(this.props.isCustomDashboard);
      const isContextMenuValid = (widthClientScreen > 768 && isValidWriteRolesOrCustomDashboardMode) || (isPluginExportable && isPluginContextMenuValid)

      const hasPinnedPlugin = $("#configurationAndDataPin")[0];

      if (hasPinnedPlugin === undefined) {
        this.setState({
          isSelectedPlugin: true,
        }, () => {
          if (isContextMenuValid) {
            const copiedPlugin = getCopiedPluginObj()?.plugin;
            const outlineType = (copiedPlugin?.id === this.state.plugin.id && "dashed") || "solid";

            $("#grid-" + this.state.plugin.id).find(".plugin-container").css("outline", `2px ${outlineType} #1890ff`);
          }
        });
      }
    };
  }

  /**
   *
   * @param {*} drillDowns
   * @returns
   *
   * Converts drill down saved as string to map
   */
  convertDrillDownStringToMap = (drillDowns) => {
    if (drillDowns) {
      let reduxState = store.getState();
      let drillDownsInStore = reduxState.DrillDownReducer.drillDowns.get(
        this.props.plugin.id
      );

      if (drillDownsInStore) {
        return drillDownsInStore;
      }

      if (typeof drillDowns.drillDownColumnsForParentColumns === "string") {
        drillDowns.drillDownColumnsForParentColumns = new Map(
          JSON.parse(drillDowns.drillDownColumnsForParentColumns)
        );
      }

      if (typeof drillDowns.allDrillDownColumnsInPlugin === "string") {
        drillDowns.allDrillDownColumnsInPlugin = new Map(
          JSON.parse(drillDowns.allDrillDownColumnsInPlugin)
        );
      }

      if (typeof drillDowns.drillDownLayerMap === "string") {
        drillDowns.drillDownLayerMap = new Map(
          JSON.parse(drillDowns.drillDownLayerMap)
        );
      }

      if (typeof drillDowns.preserveDefaultFilters === "string") {
        drillDowns.preserveDefaultFilters = new Map(
          JSON.parse(drillDowns.preserveDefaultFilters)
        );
      }

      let drillDownsMap = reduxState.DrillDownReducer.drillDowns;

      drillDownsMap.set(this.props.plugin.id, deepCopy(drillDowns));
      store.dispatch(setPluginsDrillDowns(deepCopy(drillDownsMap)));
    }

    return drillDowns;
  };

  /**
   *
   * @param {*} plugin
   * Sets plugin information to store
   */
  setUpdatedPlugin = (plugin) => {
    let updatedPlugin = this.createSavePluginData(plugin);
    let reduxState = store.getState();
    let updatedPlugins = reduxState.PluginTriggerReducer.plugins;

    updatedPlugin.data = plugin.data

    updatedPlugins.set(plugin.id, updatedPlugin);

    store.dispatch(setUpdatedPlugins(updatedPlugins));
  };

  /**
   *
   * @param {*} nextProps
   * Plugin controls the ownership of the interaction
   *
   */
  hasInteraction = (nextProps) => {
    let interaction = this.getInteraction(nextProps);

    if (interaction === null) return { status: false };

    return {
      status: this.doesInteractionContainsGivenAction(
        nextProps.triggeringPluginInformation,
        interaction
      ),
      interaction: interaction,
    };
  };

  changeHasNotJoinedData = (status) => {
    //if we haven't got joined data this block updates herself. we need this for check in plugin
    this.setState({
      hasNotJoinedData: status,
    });
  };

  changeDoesPluginHasNotJoinedTable = (status) => {
    this.setState({
      doesPluginHasNotJoinedTable: status,
    });
  };

  changeJoinErrorVisibility = (status) => {
    this.setState({
      joinErrorVisibility: status,
    });
  };

  setClickedRefresh = (status) => {
    //if we haven't got joined data and clicked refresh button, this blocks run. after open add join popup, this block updates herself again.
    this.setState({
      ...this.state,
      clickedRefresh: status,
    });
  };

  /** Update only conditional format in plugin */
  updateConditionalFormatting = (conditionalFormats, pluginId) => {
    let plugin = { ...this.state.plugin };

    plugin.conditionalFormats = conditionalFormats;
    plugin.rerender = true;

    this.setUpdatedPlugin(plugin);

    this.setState({
      ...this.state,
      plugin: plugin,
    });
  };

  /** Update only default filter in plugin */
  updateDefaultFilterForPlugin = (defaultFilters) => {
    let plugin = { ...this.state.plugin };

    plugin.defaultFilters = defaultFilters;

    this.setUpdatedPlugin(plugin);

    this.setState({
      ...this.state,
      plugin: plugin,
    });
  };

  updateRender = (object) => {
    let plugin = { ...this.state.plugin };

    plugin.rerender = true;
    plugin.getData = false;
    plugin.errors = object.errors ? object.errors : plugin.errors;
    plugin.usedSessionVariables = object.usedSessionVariables;

    store.dispatch(changePluginLoaderVisibility(this.props.plugin.id, false))

    this.setState({
      plugin: plugin,
      dashboardSessionVariables: this.props.dashboardSessionVariables,
      title: calculateText(plugin, plugin.config?.title)
    });
  };

  /** Update only config in plugin */
  updateData = (
    data,
    columnMapForPlugin,
    pluginId,
    isInteraction,
    drillDownColumnMap = undefined,
    usedSessionVariables = new Map()
  ) => {
    let plugin = { ...this.state.plugin };

    plugin.data = data;
    plugin.columnMapForPlugin = columnMapForPlugin;
    plugin.rerender = true;
    plugin.getData = false;
    plugin.isInteraction = isInteraction;
    plugin.drillDownColumnMap = drillDownColumnMap;
    plugin.usedSessionVariables = usedSessionVariables;

    this.setUpdatedPlugin(plugin);

    if (plugin.key !== "measure-tile") {
      store.dispatch(changePluginLoaderVisibility(this.props.plugin.id, false))
    }

    this.setState({
      plugin: plugin,
      dashboardSessionVariables: this.props.dashboardSessionVariables,
      title: calculateText(plugin, plugin.config?.title)
    });
  };

  /** Update plugin object in plugins array with id. */
  updatePlugin = (plugin) => {
    this.setState(
      {
        plugin: plugin,
        title: calculateText(plugin, plugin.config?.title)
      },
      () => {
        this.setUpdatedPlugin(plugin);

        this.setPluginRerender(
          false,
          this.props.plugin.id,
          false,
          this.props.plugin.isInteraction,
          false,
          plugin.config
        )
      }
    );
  };

  /** Update only column map and sorted list in plugin */
  updateColumnMap = (
    columnMap,
    columnMapForPlugin,
    sortedColumnList,
    pluginId,
    conditionalFormats,
    navigations
  ) => {
    let plugin = { ...this.state.plugin } //changed deepCopy method for maximum call stack error.

    plugin.columnMap = deepCopy(columnMap);

    if (sortedColumnList !== undefined) {
      plugin.sortedColumnList = sortedColumnList;
    }

    if (conditionalFormats !== undefined) {
      plugin.conditionalFormats = conditionalFormats;
    }

    if (columnMapForPlugin !== undefined) {
      plugin.columnMapForPlugin = columnMapForPlugin;
    }

    if (navigations !== undefined) {
      plugin.navigations = navigations;
    }

    this.setUpdatedPlugin(plugin);

    this.setState({
      plugin: plugin,
    });
  };

  /**
   * Keeps the plugin object updated in the changes made in subcomponents.
   */
  updatePluginFields = (type, payload) => {
    if (type === "columnMap") {
      this.updateColumnMap(
        payload.columnMap,
        payload.columnMapForPlugin,
        payload.sortedColumnList,
        payload.pluginId,
        payload.conditionalFormats
      );
    } else if (type === "data") {
      this.updateData(
        payload.data,
        payload.columnMapForPlugin,
        payload.pluginId,
        payload.isInteraction,
        payload.drillDownColumnMap,
        payload.usedSessionVariables
      );
    } else if (type === "renderUpdate") {
      this.updateRender(payload);
    } else if (type === "conditionalFormatting") {
      this.updateConditionalFormatting(
        payload.conditionalFormats,
        payload.pluginId
      );
    } else if (type === "navigation") {
      this.setNavigations(payload);
    } else if (type === "drillDowns") {
      this.setDrillDowns(payload);
    } else {
      this.updatePlugin(type);
    }
  };

  /**
   * Checks the column map data for plugin default render or render with data.
   * @param {*} plugin
   */
  findColumnMapHasAnyColumn = plugin => {
    let status = false;

    if (plugin.columnMap === undefined) {
      return status;
    }

    let keys = Object.keys(plugin.columnMap);

    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let columns = plugin.columnMap[key].data;

      if (columns?.length > 0) {
        for (let j = 0; j < columns.length; j++) {
          let column = columns[j];

          if (!column.isDisabledColumn) {
            status = true;
            break;
          }
        }
      }
    }

    return status;
  };

  /**
   *
   * @param {*} status
   * @param {*} pluginId
   * @param {*} isGetData
   * @param {*} isInteraction
   *
   * Sets the necessary parameters to re-render the plugin
   */
  setPluginRerender = (
    status = false,
    pluginId,
    isGetData = false,
    isInteraction = false,
    autoRefreshStatus = false,
    config = undefined
  ) => {
    let newState = { ...this.state };

    let shouldItBeRefresh = true;
    let stateChanged = false;

    if (autoRefreshStatus) {
      if (this.props.autoRefresh.exceptedPlugins.has(newState.plugin.id)) {
        shouldItBeRefresh = false;
      }

      if (pluginsThatWontAutoRefresh.has(newState.plugin.key)) {
        shouldItBeRefresh = false;
      }
    }

    let reduxState = store.getState();
    let expressionMapChanged = false;
    let definedExpressionSetChanged = false;
    let definedExpressionSet = new Map(reduxState.ExpressionParameterReducer.definedExpressionSet);
    let expressionMap = new Map(reduxState.ExpressionParameterReducer.expressionMap);

    var THIS = this

    const updateExpressionName = (column) => {
      let oldValue = definedExpressionSet.has(column.uniqeColumnId) ? definedExpressionSet.get(column.uniqeColumnId) : false;

      if (!column.preserveDataAs && oldValue == undefined) {
        return;
      } else if (!column.preserveDataAs || (definedExpressionSet.has(column.uniqeColumnId) && column.isDisabledColumn)) {
        expressionMapChanged = true

        expressionMap.delete(definedExpressionSet.get(column.uniqeColumnId))
      }

      if (column.preserveDataAs && column.isDisabledColumn) {
        expressionMapChanged = true

        expressionMap.delete(column.preserveDataAs);
      }

      if ((column.preserveDataAs !== oldValue && oldValue !== false) || !THIS.state.plugin.converted) {
        definedExpressionSetChanged = true;

        definedExpressionSet.delete(column.uniqeColumnId);

        let expressionNameBeforeRenaming = column.preserveDataAs;
        let allExpressionNames = Array.from(definedExpressionSet.values());

        while (allExpressionNames.includes(column.preserveDataAs) && column.preserveDataAs) {
          column.preserveDataAs = column.preserveDataAs + "_";
        }

        if (expressionNameBeforeRenaming !== column.preserveDataAs) {
          let message = i18n.t("Errors.ExpressionNameAlreadyExists")
            .replace("[newExpressionName]", column.preserveDataAs)
            .replace("[oldExpressionName]", expressionNameBeforeRenaming);

          showNotificationWithIcon(
            i18n.t("Warning"),
            message,
            "warning"
          );

          stateChanged = true;
        }

        if (column.isDisabledColumn !== true) {
          definedExpressionSet.set(column.uniqeColumnId, column.preserveDataAs)

          store.dispatch(setDefinedExpression(definedExpressionSet))
        }

        if (oldValue !== undefined && expressionMap.has(oldValue)) {
          expressionMapChanged = true;

          expressionMap.delete(oldValue);
        }
      }
    }

    for (let i = 0; i < newState?.plugin?.columnMap?.hidden?.data?.length; i++) {
      let column = newState?.plugin?.columnMap?.hidden?.data[i]

      if (column && column.preserveDataAs) {
        if (expressionMap.has(column.preserveDataAs)) {
          expressionMapChanged = true

          expressionMap.delete(column.preserveDataAs)
        }

        if (definedExpressionSet.has(column.uniqeColumnId)) {
          definedExpressionSetChanged = true

          definedExpressionSet.delete(column.uniqeColumnId)
        }
      }
    }

    newState.plugin.columnMap?.measure?.data?.forEach(c => updateExpressionName(c)); // eslint-disable-line

    if (expressionMapChanged) store.dispatch(setExpression(expressionMap));
    if (definedExpressionSetChanged) store.dispatch(setDefinedExpression(definedExpressionSet));

    if (shouldItBeRefresh) {
      newState.plugin.rerender = status || newState.plugin.converted;
      newState.plugin.getData = isGetData;
      newState.plugin.isInteraction = isInteraction;

      let loaderVisibility = newState.plugin.getData;
      let clickedRefresh = status || newState.plugin.converted;

      if (this.state.firstRender && this.findColumnMapHasAnyColumn(this.props.plugin)) {
        loaderVisibility = true;
      }

      let isPluginPivotOrEnhancedAndDataIsUndefined = (this.state.plugin.key === "pivot-table" || this.state.plugin.key === "pivot-table-enhance") && this.state.plugin?.data == undefined ? true : false

      if (isPluginPivotOrEnhancedAndDataIsUndefined) {
        let hasPluginAnyColumnMapObject = getPluginsAllColumnsByField(this.state.plugin.columnMap)
        let hasData = this.state.plugin.data || this.state.plugin.data?.length === 0 ? true : false

        if (hasPluginAnyColumnMapObject.length > 0) {
          store.dispatch(changePluginLoaderVisibility(this.props.plugin.id, !hasData))
        } else {
          store.dispatch(changePluginLoaderVisibility(this.props.plugin.id, false))
        }
      } else {
        store.dispatch(changePluginLoaderVisibility(this.props.plugin.id, loaderVisibility))
      }

      if (config !== undefined) {
        newState.plugin.config = { ...config };
      }

      if (newState.plugin.converted) {
        delete newState.plugin.converted;
      }

      newState.rerender = status;
      newState.clickedRefresh = clickedRefresh;
      newState.firstRender = false;
      newState.stateChanged = true

      stateChanged = true;
    }

    if (stateChanged) {
      this.setState(newState, () => {
        this.setState({
          ...this.state,
          stateChanged: false
        })
      })
    }
  };

  /** Update only config in plugin */
  updateConfig = (config) => {
    if (!config) return;

    let plugin = { ...this.state.plugin };

    if (!_.isEqual(config, plugin.config) || plugin.key === "measure-tile") {
      let title = this.state.title;

      if (config.title !== plugin.config.title) {
        title = calculateText(plugin, config.title);
      }

      for (let field of Object.keys(config)) {
        if (!_.isEqual(plugin.config[field], config[field]) || field === "configArray") {
          if (!plugin.preservedConfigFields) {
            plugin.preservedConfigFields = new Set()
          }

          plugin.preservedConfigFields.add(field);

          if (!plugin.originalConfig) {
            plugin.originalConfig = {}
          }

          plugin.originalConfig[field] = config[field];
        }
      }

      plugin.config = config;
      plugin.rerender = true;

      /* let theme = sessionStorage.getItem("theme-dashboard");

      if (theme) {
        let pluginTheme = JSON.parse(theme).plugin;

        if (pluginTheme) {
          delete pluginTheme.css;

          for (let key in pluginTheme) {
            let configValue = plugin.config[key];
            let themeValue = pluginTheme[key];

            if (!_.isEqual(configValue, themeValue)) {
              plugin.preservedConfigFields.add(key);

              plugin.originalConfig[key] = configValue;
            }
          }
        }
      } */

      this.setUpdatedPlugin(plugin);

      this.setState({
        plugin,
        title,
        rerender: true
      });
    }
  };

  /**
   * Updates removed interation list in plugin
   */
  updateRemovedAutoInteractionsInPlugin = (activeInteractionPlugin) => {
    let activeInteractionPluginObject = { ...this.props.activeInteractionPluginObject }

    let reduxState = store.getState();
    let updatedPlugins = reduxState.PluginTriggerReducer.plugins;

    let plugin = updatedPlugins.get(activeInteractionPlugin.id)

    plugin.removedInteractions = activeInteractionPlugin.removedInteractions;
    plugin.edittedInteractions = activeInteractionPlugin.edittedInteractions;

    let updatedPlugin = this.createSavePluginData(plugin);

    updatedPlugins.set(activeInteractionPlugin.id, updatedPlugin);
    store.dispatch(setUpdatedPlugins(updatedPlugins));

    activeInteractionPluginObject.plugin = { ...plugin };
    this.props.setActiveInteractionPlugin(activeInteractionPluginObject);
  }

  changeConfigVisibility = (status) => {
    this.setState({
      ...this.state,
      configVisibility: status,
      dataVisibility: false,
      conditionalFormattingVisibility: false,
      navigationVisibility: false,
      isPinned: false,
    });

    let beforeStatusOfImmobileDashboard = this.props.draggableResizableStatus

    this.props.setZIndexForPopup(status, this.state.plugin.id, true, false, beforeStatusOfImmobileDashboard);
  };

  changeDataVisibility = (status) => {
    this.setState({
      ...this.state,
      dataVisibility: status,
      configVisibility: false,
      conditionalFormattingVisibility: false,
      navigationVisibility: false,
      isPinned: false,
    });

    let beforeStatusOfImmobileDashboard = this.props.draggableResizableStatus

    this.props.setZIndexForPopup(status, this.state.plugin.id, true, false, beforeStatusOfImmobileDashboard);
  };

  changeConditionalFormattingVisibility = (status) => {
    this.setState({
      ...this.state,
      conditionalFormattingVisibility: status,
      dataVisibility: false,
      configVisibility: false,
      navigationVisibility: false,
      isPinned: false,
    });

    let beforeStatusOfImmobileDashboard = this.props.draggableResizableStatus

    this.props.setZIndexForPopup(status, this.state.plugin.id, true, false, beforeStatusOfImmobileDashboard);
  };

  changeNavigationVisibility = (status) => {
    this.setState({
      ...this.state,
      navigationVisibility: status,
      conditionalFormattingVisibility: false,
      dataVisibility: false,
      configVisibility: false,
      isPinned: false,
    });

    let beforeStatusOfImmobileDashboard = this.props.draggableResizableStatus

    this.props.setZIndexForPopup(status, this.state.plugin.id, true, false, beforeStatusOfImmobileDashboard);
  };

  /**
   * To pin data and configuration plugins toggle function. 
   */
  pinStatusChange = () => {
    this.setState({
      ...this.state,
      isPinned: !this.state.isPinned,
    });
  };

  hasTriggeringPluginInformationInteractions = (triggeringPluginInformation) => {
    return triggeringPluginInformation && triggeringPluginInformation.interactions && Array.isArray(triggeringPluginInformation.interactions);
  }

  /**
   * removed interaction find by id in 
   */
  hasRemovedInteraction = (removedInteractions, id) => {
    let status = false;
    if (removedInteractions) {
      for (let i = 0; i < removedInteractions.length; i++) {
        let removedId = removedInteractions[i];

        if (removedId === id) {
          status = true;

          break;
        }
      }
    }

    return status;
  }

  /**
   * Checks plugin is removed from auto interactions
   */
  isRemovedPluginForAutoInteraction = (target, activeSourcePlugin) => {
    if (activeSourcePlugin.removedInteractions === undefined) {
      return false;
    } else if (!this.hasRemovedInteraction(activeSourcePlugin.removedInteractions, target.id)) {
      return false;
    }

    return true;
  }

  /**
   *
   * @param {*} nextProps
   * Finds and returns the interaction object from the plugin array
   */
  getInteraction = (nextProps) => {
    let reduxState = store.getState();
    let triggeringPluginInformation = nextProps.triggeringPluginInformation;
    let activePlugin = reduxState.PluginTriggerReducer.plugins.get(triggeringPluginInformation.pluginId)

    if (activePlugin && this.isRemovedPluginForAutoInteraction(nextProps.plugin, activePlugin)) {
      return null;
    }

    let fullInteractionList = nextProps.interactions;

    if (this.hasTriggeringPluginInformationInteractions(triggeringPluginInformation)) {
      fullInteractionList = [...nextProps.interactions, ...triggeringPluginInformation.interactions];
    }

    if (fullInteractionList) {
      const interactions = fullInteractionList.filter(
        (interaction) =>
          interaction.targetId === nextProps.plugin.id &&
          interaction.sourceId === nextProps.triggeringPluginInformation.pluginId
      );

      if (interactions && interactions.length > 0) {
        return interactions[0];
      }

      return null;
    }

    return null;
  };

  /**
   *
   * @param {*} triggeringPluginInformation
   * @param {*} interaction
   * Checks whether the desired action is available
   */
  doesInteractionContainsGivenAction = (
    triggeringPluginInformation,
    interaction
  ) => {
    return interaction.actions?.some(
      (action) => action?.trigger === triggeringPluginInformation?.event
    );
  };

  /**
   *
   * @param {*} nextProps
   * Checks whether the plugin triggered is not, also checks if this plugin has been triggered, returns true or false
   */
  hasNewInteraction = (nextProps) => {
    let responseHasInteraction = this.hasInteraction(nextProps);
    let status = responseHasInteraction.status;
    let interaction = responseHasInteraction.interaction;

    if (
      this.props.triggeringPluginInformation !==
      nextProps.triggeringPluginInformation &&
      status
    ) {
      const { PluginLoaderReducer } = store.getState();
      const isPluginLoaderActive = PluginLoaderReducer?.waitForLoadPlugins?.has(this.state.plugin.id)

      if (isPluginLoaderActive) return { status: false };

      return { status: true, interaction: interaction };
    }

    return { status: false };
  };

  /**
   *
   * @param {*} drillDowns
   * Sets the added drilldown information to the plugin
   */
  setDrillDowns = (drillDowns) => {
    let plugin = { ...this.state.plugin };
    plugin.drillDowns = drillDowns;

    this.setUpdatedPlugin(plugin);

    this.setState({
      ...this.state,
      plugin: plugin,
    });
  };

  /**
   *
   * @param {*} navigations
   *
   * Sets the incoming navigation array to the state and updates the plugin
   */
  setNavigations = (navigations) => {
    let plugin = { ...this.state.plugin };
    plugin.navigations = navigations;

    this.setUpdatedPlugin(plugin);

    this.setState({
      ...this.state,
      plugin: plugin,
    });
  };

  cancelTokenSource = undefined;

  /*
   * Checks current columnMap columns has locationFieldName.
   */
  checkColumnHasLocationFieldName = () => {
    if (this.props.plugin.columnMap) {
      let checkedColumnMap = checkColumnsHasLocationFieldName(
        this.props.plugin.columnMap
      );

      if (checkedColumnMap.status === true) {
        let preparedPluginObject = {
          columnMap: checkedColumnMap.columnMap,
          sortedColumnList: this.props.plugin.sortedColumnList,
          pluginId: this.props.plugin.id,
          conditionalFormats: this.props.plugin.conditionalFormats,
        };

        this.props.updatePlugin("columnMap", preparedPluginObject);

        this.setState({
          columnMapWithLocationFieldName: checkedColumnMap.columnMap,
        });
      } else {
        this.setState({
          columnMapWithLocationFieldName: this.props.plugin.columnMap,
        });
      }
    } else {
      this.setState({
        isNewPlugin: true,
      });
    }
  };

  componentWillMount() {
    this.cancelTokenSource = Axios.CancelToken.source();
  }

  /*
  * Opens the plugin context menu.
  */
  triggerContextMenu = (event) => {
    const triggerContextMenu = _.throttle(() => {
      let windowWidth = $(window).width();
      let reduxState = store.getState();
      let copiedPlugin = reduxState.ContextMenuReducer.copiedPlugin

      let target = event.target
      let pluginContainer = document.getElementById("plugin-container-" + this.state.plugin.id) || document.getElementById("pluginDateFilter-" + this.state.plugin.id)
      let pluginContainerAlternative = document.getElementById("plugin-" + this.state.plugin.id)
      let isRightClickedPluginTitle = document.getElementById("title-" + this.state.plugin.id)
      let isRightClickedPlugin = document.getElementById("grid-" + this.state.plugin.id)
      let isTargetPlugin = (pluginContainer && pluginContainer.contains(target))
        || (pluginContainerAlternative && pluginContainerAlternative.contains(target))
        || (isRightClickedPluginTitle && isRightClickedPluginTitle.contains(target))
        || (isRightClickedPlugin && isRightClickedPlugin.contains(target))
      let pluginDiv = $("#" + this.state.plugin.id)[0];
      let isTargetPluginItems = $(event.target).closest(".data-popup").length > 0;
      let isPluginExportable = pluginDiv && ["PDF", "XLSX", "PNG"].map(attr => pluginDiv[attr] && true).filter(attr => attr).length > 0;
      let isValidWriteRolesOrCustomDashboardMode = windowWidth >= 1024 && isValidWriteRolesOrCustomDashboard(this.props.isCustomDashboard);
      let isContextMenuValid =
        (isPluginExportable || isValidWriteRolesOrCustomDashboardMode) &&
        pluginContainer &&
        isTargetPlugin &&
        !isTargetPluginItems &&
        !this.props.isDashboardSliderActive

      if (!isContextMenuValid) return;

      let xPosition = event.pageX;
      let yPosition = event.pageY;
      let status = true;
      let contextMenuType = "copy"

      let copiedPluginObj = {
        xPosition,
        yPosition,
        plugin: copiedPlugin,
        status,
        contextMenuType
      }

      store.dispatch(rightClickCopiedPluginStatus(copiedPluginObj));
      store.dispatch(changeRightClickedPlugin(this.state.plugin.id));

      document.documentElement.style.overscrollBehavior = "none";
      document.documentElement.style.touchAction = "none";
      document.documentElement.style.overflow = "hidden";

      document.body.style.touchAction = "none";
      document.body.style.overscrollBehavior = "none";
      document.body.style.overflow = "hidden";

      if (isValidWriteRolesOrCustomDashboardMode) {
        this.setState({
          ...this.state,
          isSelectedPlugin: true
        });
      } else {
        $("#grid-" + this.state.plugin.id).find(".plugin-container").css("outline", `2px solid #1890ff`);
      }
    }, 500);

    absorbEvent(event);
    triggerContextMenu();
  };

  /**
   * Updates plugin outline
   * 
   * @param {*} event 
   */
  handleCopiedPluginChange = event => {
    if (event.key === "copiedPlugin" && this.state.isSelectedPlugin) {
      let widthClientScreen = $(window).width();
      let isValidWriteRolesOrCustomDashboardMode = isValidWriteRolesOrCustomDashboard(this.props.isCustomDashboard)

      if (widthClientScreen > 768 && isValidWriteRolesOrCustomDashboardMode) {
        let copiedPluginObj = getCopiedPluginObj();
        let copiedPlugin = copiedPluginObj?.plugin;
        let outlineType = copiedPlugin && copiedPlugin.id === this.state.plugin.id ? "dashed" : "solid";

        $("#grid-" + this.state.plugin.id).find(".plugin-container").css("outline", `2px ${outlineType} #1890ff`);
      }
    }
  }

  /**
   * Touch started handler
   * Opens context menu after 500ms longpress
   * 
   * @param {*} event 
   */
  handleTouchStart = event => {
    let reduxState = store.getState()
    let contextMenuReducer = reduxState.ContextMenuReducer
    let status = contextMenuReducer.contextMenuStatus === true;

    if (!status) {
      this.touchTimer = setTimeout(() => {
        this.triggerContextMenu(event)
      }, 500);
    }
  }

  /**
   * Touch ended handler
   * Cancels context menu trigger
   * 
   * @param {*} event 
   */
  handleTouchEnd = event => {
    let reduxState = store.getState()
    let contextMenuReducer = reduxState.ContextMenuReducer
    let status = contextMenuReducer.contextMenuStatus === true;

    if (this.touchTimer && !status) {
      clearTimeout(this.touchTimer);

      this.touchTimer = null;
    }
  }

  /**
   * Touch move handler
   * Cancels context menu trigger
   * 
   * @param {*} event 
   */
  handleTouchMove = event => {
    let reduxState = store.getState()
    let contextMenuReducer = reduxState.ContextMenuReducer
    let status = contextMenuReducer.contextMenuStatus === true;

    if (this.touchTimer && !status) {
      clearTimeout(this.touchTimer);

      this.touchTimer = null;
    }
  }

  // Controls is plugin has expression
  controlIsPluginHasExpression = () => {
    let data = this.state.plugin.columnMap.measure.data

    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let col = data[i]

        if (data[i].expression) {
          return true
        }
      }
    }

    return false
  }

  queryTimer

  // Adds plugin an error when expression used and expression calculation took toooooo long // 15000ms, 15 saniye, 15 second
  addPluginError = () => {
    let copiedPluginLoadingValues = deepCopy(this.props.pluginLoadingValues)
    let isPluginMeasureTileAndHasExpression = copiedPluginLoadingValues.has(this.state.plugin.id) && this.state.plugin.key === "measure-tile" && this.controlIsPluginHasExpression()

    if (isPluginMeasureTileAndHasExpression) {
      let errorSet = new Map()

      errorSet.set("expressionCalculation", {
        type: "error",
        message: i18n.t("Dashboard.Data.ExpressionError")
      })

      showNotificationWithIcon(i18n.t("Error"), i18n.t("Dashboard.Data.ExpressionError"), "error")

      store.dispatch(changePluginLoaderVisibility(this.state.plugin.id, false))

      this.queryTimer = null

      this.setState({
        ...this.state,
        plugin: {
          ...this.state.plugin,
          errors: errorSet
        }
      })
    } else {
      this.queryTimer = null
    }
  }

  // Handles plugin loader, is plugin measure tile, start 15000ms counter
  handlePluginLoader = () => {
    let plugin = this.state.plugin
    let pluginId = plugin.id
    let isPluginMeasure = plugin.key === "measure-tile" ? true : false

    if (isPluginMeasure) {
      if (this.props.pluginLoadingValues.has(pluginId) && this.queryTimer == null) {
        this.queryTimer = setTimeout(this.addPluginError, 15000)
      } else if (this.queryTimer) {
        this.queryTimer = null
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.pluginLoadingValues.has(this.state.plugin.id) && this.state.plugin.key === "measure-tile") {
      if (this.queryTimer == null) {
        this.handlePluginLoader()
      }
    } else if (!this.props.pluginLoadingValues.has(this.state.plugin.id) && this.state.plugin.key === "measure-tile") {
      if (this.queryTimer) {
        this.queryTimer = null
      }
    }
  }

  componentDidMount() {
    const pluginContainer = document.getElementById("plugin-container-" + this.state.plugin.id);

    if (pluginContainer && !this.props.isDashboardSliderActive) {
      pluginContainer.addEventListener("contextmenu", this.triggerContextMenu);
      pluginContainer.addEventListener("touchstart", this.handleTouchStart);
      pluginContainer.addEventListener("touchend", this.handleTouchEnd);
      pluginContainer.addEventListener("touchmove", this.handleTouchMove);
    }

    document.addEventListener("mouseup", this.handleClickOutside);
    window.addEventListener("storage", this.handleCopiedPluginChange);

    $(document).keydown(event => {
      let windowWidth = $(window).width();
      let isWideScreen = windowWidth >= 1024;
      let isValidWriteRolesOrCustomDashboardMode = isValidWriteRolesOrCustomDashboard(this.props.isCustomDashboard)
      let isCopyKeyPressed = (event.ctrlKey || event.metaKey) && event.keyCode === 67;
      //let isCutKeyPressed = (event.ctrlKey || event.metaKey) && event.keyCode === 88;
      const isDelKeyPressed = event.key === "Delete";

      if(isValidWriteRolesOrCustomDashboard && isDelKeyPressed && this.state.isSelectedPlugin) {
        this.props.removePlugin(this.state.plugin);
        return;
      }

      let isActionValid =
        isWideScreen &&
        this.state.isSelectedPlugin &&
        isCopyKeyPressed &&
        isValidWriteRolesOrCustomDashboardMode &&
        !this.props.isDashboardSliderActive;

      if (isActionValid !== true) return;

      let reduxState = store.getState()
      let xPosition = 0;
      let yPosition = 0;
      let status = false;
      let contextMenuType = "copy";

      let plugin = reduxState.PluginTriggerReducer.plugins.get(this.state.plugin.id);
      let drillDowns = reduxState.DrillDownReducer.drillDowns.get(plugin.id);
      let interactions = this.props.interactions.filter(interaction => interaction.sourceId === plugin.id);

      let copiedPluginObj = {
        xPosition,
        yPosition,
        status,
        contextMenuType,
        plugin,
        interactions
      };

      store.dispatch(rightClickCopiedPluginStatus(copiedPluginObj));

      if (drillDowns instanceof Object) {
        copiedPluginObj.drillDowns = {};

        for (let field of Object.keys(drillDowns)) {
          if (drillDowns[field] instanceof Map) {
            let obj = Object.fromEntries(drillDowns[field]);

            copiedPluginObj.drillDowns[field] = JSON.stringify(obj);
          } else {
            copiedPluginObj.drillDowns[field] = drillDowns[field];
          }
        };
      }

      localStorage.setItem("copiedPlugin", JSON.stringify(copiedPluginObj));

      this.handleCopiedPluginChange({ key: "copiedPlugin" });
    });

    $("#grid-" + this.props.index).css(
      "padding",
      this.props.settings.grid.padding + "px"
    );

    $("#grid-" + this.props.index)
      .find(".react-resizable-handle")
      .css("right", this.props.settings.grid.padding + "px")
      .css("bottom", this.props.settings.grid.padding + "px");

    this.checkColumnHasLocationFieldName();

    /*
    * Controls before render is config has title settings
    */
    const controlFirstConfigObject = (newConfigObject) => {
      if (newConfigObject.titleTextDecor === undefined) {
        newConfigObject.titleTextDecor = false
      }

      if (newConfigObject.titleFontStyle === undefined) {
        newConfigObject.titleFontStyle = false
      }

      if (newConfigObject.titleFontWeight === undefined) {
        newConfigObject.titleFontWeight = false
      }

      if (newConfigObject.titleFontSize === undefined) {
        newConfigObject.titleFontSize = 15
      }

      if (newConfigObject.titleColour === undefined) {
        newConfigObject.titleColour = "black"
      }

      if (newConfigObject.titleFont === undefined) {
        newConfigObject.titleFont = "Verdana"
      }

      if (newConfigObject.title === undefined) {
        newConfigObject.title = i18n.t("Title not set")
      }

      if (newConfigObject.title_ === undefined) {
        newConfigObject.title_ = i18n.t("Title not set")
      }

      if (newConfigObject.titleAlign === undefined) {
        newConfigObject.titleAlign = "center"
      }

      return newConfigObject
    }

    if (this.props.defaultPlugins.get(this.state.plugin.id) === undefined) {
      let theme = sessionStorage.getItem("theme-dashboard")

      if (theme) theme = JSON.parse(theme).plugin;

      this.props.defaultPlugins.set(this.state.plugin.id, {
        titleTextDecor: theme?.titleTextDecor ? theme.titleTextDecor : false,
        titleFontStyle: theme?.titleFontStyle ? theme.titleFontStyle : false,
        titleFontWeight: theme?.titleFontWeight ? theme.titleFontWeight : false,
        titleFontSize: theme?.titleFontSize ? theme.titleFontSize : 15,
        titleColour: theme?.titleColour ? theme.titleColour : "black",
        titleFont: theme?.titleFont ? theme.titleFont : "Verdana",
        title: theme?.title ? theme.title : i18n.t("Title not set"),
        title_: theme?.title_ ? theme.title_ : i18n.t("Title not set"),
        titleAlign: theme?.titleAlign ? theme.titleAlign : "center"
      })
    } else {
      let defaultPluginsConfig = this.props.defaultPlugins.get(this.state.plugin.id)
      let newConfigObject = deepCopy(defaultPluginsConfig)
      let controlledNewConfigObject = controlFirstConfigObject(newConfigObject)

      this.props.defaultPlugins.set(this.state.plugin.id, controlledNewConfigObject)
    }

    if (Array.from(this.props.pluginLoadingValues.keys()).length > 0) {
      this.handlePluginLoader()
    }

    this.setState({
      dashboardSessionVariables: new Map(this.props.dashboardSessionVariables),
      title: this.state.plugin?.config?.title === undefined
        ? this.state.plugin.key !== "measure-tile" ? i18n.t("Title not set") : ""
        : calculateText(this.state.plugin, this.state.plugin.config.title)
    });
  }

  /**
   * Check title changed by double click
   * @param nextProps
   * @param props this.props
   * @param newState updated state
   * @param isNewStateUpdate flag for set state
   */
  setTitleToNewStateIfChanged = (
    nextProps,
    props,
    newState,
    isNewStateUpdate
  ) => {
    if (
      nextProps.title !== props.title ||
      props.plugin.id !== nextProps.plugin.id
    ) {
      newState.titleChangeStatus = false;
      newState.title = nextProps.title;
      return true;
    }

    return isNewStateUpdate;
  };

  /**
   * Check plugin id changed for navigation
   * @param nextProps
   * @param props this.props
   * @param newState updated state
   * @param isNewStateUpdate flag for set state
   */
  setNavigationIfPluginChanged = (
    nextProps,
    props,
    newState,
    isNewStateUpdate
  ) => {
    if (props.plugin.id !== nextProps.plugin.id) {
      newState.navigations = nextProps.plugin.navigations;
      return true;
    }

    return isNewStateUpdate;
  };

  /**
   * Check plugin id changed for navigation
   * @param nextProps
   * @param props this.props
   * @param newState updated state
   * @param isNewStateUpdate flag for set state
   */
  setRemovedAutoInteractionsIfPluginChanged = (
    nextProps,
    props,
    newState,
    isNewStateUpdate
  ) => {
    let reduxState = store.getState();
    let updatedPlugins = reduxState.PluginTriggerReducer.plugins;

    let updatedPlugin = deepCopy(updatedPlugins.get(nextProps.plugin.id))
    let isValid = newState && newState.plugin && updatedPlugin;

    if (isValid && !_.isEqual(newState.plugin.removedInteractions, updatedPlugin.removedInteractions)) {
      newState.plugin.removedInteractions = updatedPlugin.removedInteractions;
      return true;
    }

    if (isValid && !_.isEqual(newState.plugin.edittedInteractions, updatedPlugin.edittedInteractions)) {
      newState.plugin.edittedInteractions = updatedPlugin.edittedInteractions;
      return true;
    }

    return isNewStateUpdate;
  };

  /**
   * Check grid padding changed for update css
   * @param nextProps
   * @param props this.props
   */
  isGridPaddingChanged = (nextProps, props) => {
    if (nextProps.settings.grid.padding !== props.settings.grid.padding) {
      $("#grid-" + props.index).css(
        "padding",
        nextProps.settings.grid.padding + "px"
      );

      $("#grid-" + props.index)
        .find(".react-resizable-handle")
        .css("right", nextProps.settings.grid.padding + "px")
        .css("bottom", nextProps.settings.grid.padding + "px");
    }
  };

  /**
   * Check popup id for close other popups
   * @param nextProps
   * @param props this.props
   * @param newState updated state
   * @param isNewStateUpdate flag for set state
   */
  closePopupIfNecessary = (nextProps, props, newState, isNewStateUpdate) => {
    if (
      nextProps.closeOtherPopup !== "" &&
      nextProps.closeOtherPopup !== props.closeOtherPopup &&
      nextProps.closeOtherPopup !== nextProps.plugin.id
    ) {
      newState.configVisibility = false;
      newState.dataVisibility = false;
      newState.conditionalFormattingVisibility = false;
      newState.navigationVisibility = false;
      return true;
    }

    return isNewStateUpdate;
  };

  componentWillReceiveProps(nextProps) {
    let newState = { ...this.state };
    let isStateChanged = false;
    let shouldItBeRefreshed = false;
    let isGridHeightChanged = this.state.plugin.h !== nextProps.plugin.h ? true : false
    let isGridWidthChanged = this.state.plugin.w !== nextProps.plugin.w

    if (isGridHeightChanged || isGridWidthChanged) {
      newState.stateChanged = !newState.stateChanged

      let isPluginCauseOverflow = this.state.plugin.key === ("pivot-table" || "scatter-chart" || "table");

      if (isPluginCauseOverflow) {
        let pluginHeight = calculatePluginInlineHeight(this.state.plugin.id)

        $("#plugin-" + this.state.plugin.id).css("height", pluginHeight - 5)
      } else {
        calculateAndAppendPluginHeight(this.state.plugin.id);
      }
    }

    isStateChanged = this.setTitleToNewStateIfChanged(
      nextProps,
      this.props,
      newState,
      isStateChanged
    );

    isStateChanged = this.setCommonTitleConfigFeatures(
      nextProps,
      this.props,
      newState,
      isStateChanged
    );

    isStateChanged = this.setNavigationIfPluginChanged(
      nextProps,
      this.props,
      newState,
      isStateChanged
    );

    isStateChanged = this.setRemovedAutoInteractionsIfPluginChanged(
      nextProps,
      this.props,
      newState,
      isStateChanged
    );

    this.isGridPaddingChanged(nextProps, this.props);

    isStateChanged = this.closePopupIfNecessary(
      nextProps,
      this.props,
      newState,
      isStateChanged
    );

    if (nextProps.pluginAllRender === true) {
      this.refreshPlugin(
        true,
        nextProps.plugin.id,
        true,
        false,
        nextProps.autoRefreshStatus
      );
    }

    if (nextProps.plugin.key !== this.state.plugin.key) {
      newState.plugin = {
        ...nextProps.plugin,
        converted: true
      };

      newState.firstRender = true;
      isStateChanged = true;
    }

    if (nextProps.cancelRequests === true && this.cancelTokenSource) {
      this.cancelTokenSource.cancel();
    }

    /**
     * It checks if the plugin has been triggered.
     */
    let responseHasNewInteraction = this.hasNewInteraction(nextProps);

    if (responseHasNewInteraction.status === true) {
      this.dispatchInteractionObject(
        nextProps,
        _.cloneDeep(responseHasNewInteraction.interaction)
      );
    }

    if (
      nextProps.drillDownTriggerInformation !==
      this.props.drillDownTriggerInformation &&
      nextProps.drillDownTriggerInformation.pluginId === this.props.plugin.id
    ) {
      this.refreshPlugin(false, nextProps.plugin.id, true, true);
    }

    if (nextProps.excelExportClicked !== false && !nextProps.excelExporting) {
      this.props.setPluginsWithData(this.state.plugin, nextProps, this.state.limit);
    }

    if (this.isPositionChanged(nextProps)) {
      this.setPluginNewPosition(nextProps, newState);

      isStateChanged = true;
    }

    if (this.state.theme !== nextProps.theme) {
      newState.theme = nextProps.theme;
      newState.plugin.config = nextProps.plugin.config;
      newState.plugin.rerender = true;
      newState.title = calculateText(newState.plugin, newState.plugin.config.title);

      isStateChanged = true;
    }

    if (!_.isEqual(this.props.sessionVariables, nextProps.sessionVariables)) {
      let usedSessionVariables = new Map(this.state.plugin.usedSessionVariables);
      let usedSessionVariableNames = Array.from(usedSessionVariables.keys());

      shouldItBeRefreshed = usedSessionVariables.size === 0 || usedSessionVariableNames.some(name => {
        return !_.isEqual(this.props.sessionVariables?.get(name), nextProps.sessionVariables?.get(name));
      });

      if (shouldItBeRefreshed) {
        this.setPluginRerender(true, nextProps.plugin.id, true, false);
      }
    }

    if (isStateChanged === true) {
      this.setState(newState);
    }
  }

  //sets new common title config features to plugin
  setCommonTitleConfigFeatures = (nextProps, thisProps, newState, isStateChanged) => {
    if (!excludedFromCommonTitleConfig.has(newState.plugin.key)) {
      let commonTitleConfigChanged = nextProps.commonTitleConfig !== null && nextProps.commonTitleConfig !== thisProps.commonTitleConfig;
      let isCommonTitleChanged = thisProps.appliedConfigStatus === true && thisProps.appliedConfigStatus !== nextProps.appliedConfigStatus
      let titleConfigFields = ["titleAlign", "titleColour", "titleFont", "titleFontSize", "changedTitleFontSize", "titleFontStyle", "titleFontWeight", "titleTextDecor"]

      if (commonTitleConfigChanged || isCommonTitleChanged) {
        let newCommonTitleConfigArrived = nextProps.commonTitleConfig !== "none";
        let oldCommonTitleConfigNeedsToBeRemoved = nextProps.commonTitleConfig === "none" && thisProps.commonTitleConfig.id !== newState.plugin.id;

        if (newCommonTitleConfigArrived && isCommonTitleChanged) {
          if (nextProps.commonTitleConfig !== null && nextProps.commonTitleConfig.id !== newState.plugin.id) { //controlled because we dont need to do all this on source plugin of common features
            let newConfig = { ...newState.plugin.config };
            let newOriginalConfig = { ...newState.plugin.originalConfig };
            let configToBeUsed = { ...nextProps.commonTitleConfig.config }
            let isPluginIdDiffThanCommonTitlePluginId = thisProps.commonTitleConfig === null || (nextProps.commonTitleConfig && nextProps.commonTitleConfig.id !== thisProps.commonTitleConfig.id);
            let preservedComesMainPlugin = nextProps.commonTitleConfig

            if (isPluginIdDiffThanCommonTitlePluginId) {
              this.props.updateBeforeApplyOrDefaultPlugins(newState.plugin, "beforeApply");
            }

            newConfig = this.updateNewTitleConfig(newConfig, configToBeUsed);
            newOriginalConfig = this.updateNewTitleConfig(newOriginalConfig, configToBeUsed);

            newState.plugin.config = newConfig;
            newState.plugin.originalConfig = newOriginalConfig;
            newState.plugin.rerender = true;

            if (preservedComesMainPlugin) {
              let preservedTitleAreas = preservedComesMainPlugin.preservedConfigFields

              for (let field of titleConfigFields) {
                if (!newState.plugin.preservedConfigFields) {
                  newState.plugin.preservedConfigFields = new Set()
                }

                if (preservedTitleAreas.has(field)) {
                  newState.plugin.preservedConfigFields.add(field)
                } else {
                  newState.plugin.preservedConfigFields.delete(field)
                }
              }
            }

            this.setUpdatedPlugin(newState.plugin);

            return true;
          }
        } else if (oldCommonTitleConfigNeedsToBeRemoved) {
          let newConfig = { ...newState.plugin.config };
          let newOriginalConfig = { ...newState.plugin.originalConfig };
          let configToBeUsed = { ...nextProps.beforeApplyPlugins.get(newState.plugin.id) }

          newConfig = this.updateNewTitleConfig(newConfig, configToBeUsed);
          newOriginalConfig = this.updateNewTitleConfig(newOriginalConfig, configToBeUsed);

          let theme = sessionStorage.getItem("theme-dashboard");

          if (theme) {
            theme = JSON.parse(theme).plugin;
          }

          let isTitleConfigDifferentFromTheme = theme instanceof Object && titleConfigFields.some(field => newConfig[field] !== theme[field]);

          if (!isTitleConfigDifferentFromTheme) {
            for (let field of titleConfigFields) {
              if (!newState.plugin.preservedConfigFields) {
                newState.plugin.preservedConfigFields = new Set()
              }

              newState.plugin.preservedConfigFields.delete(field);
            }
          }

          newState.plugin.config = newConfig;
          newState.plugin.originalConfig = newOriginalConfig;
          newState.plugin.rerender = true;

          this.setUpdatedPlugin(newState.plugin);

          return true;
        }
      }

      if (this.props.isDashboardSave === true) {
        this.props.updateBeforeApplyOrDefaultPlugins(newState.plugin, "both");
        this.props.updateCommonTitleConfig(null)
      }
    }

    return isStateChanged;
  }

  // updates and returns new title config given
  updateNewTitleConfig = (newConfig, configToBeUsed, setTitle = false) => {
    if (setTitle) {
      newConfig.title = configToBeUsed.title;
      newConfig.title_ = configToBeUsed.title_;
    }

    newConfig.titleAlign = configToBeUsed.titleAlign;
    newConfig.titleFont = configToBeUsed.titleFont;
    newConfig.titleFontWeight = configToBeUsed.titleFontWeight;
    newConfig.titleFontStyle = configToBeUsed.titleFontStyle;
    newConfig.titleTextDecor = configToBeUsed.titleTextDecor;
    newConfig.titleFontSize = configToBeUsed.titleFontSize;
    newConfig.titleColour = configToBeUsed.titleColour;

    return newConfig;
  }

  //sets last saved configs to plugins
  setDefaultForPluginTitle = () => {
    let newState = { ...this.state };
    let newConfig = { ...this.state.plugin.config };
    let configToBeUsed = { ...this.props.defaultPlugins.get(this.state.plugin.id) }

    newConfig = this.updateNewTitleConfig(newConfig, configToBeUsed, true);


    let plugin = { ...this.state.plugin };

    if (newConfig !== this.state.plugin.config) {
      plugin.config = newConfig;
      plugin.rerender = true;

      this.setUpdatedPlugin(plugin);
    }

    newConfig["keyForTitleSize"] = newConfig.titleFontSize

    newState.plugin.config = newConfig;
    newState.plugin.rerender = true;

    this.setState(newState);

    return newConfig;
  }

  /**
   *
   * @param {*} nextProps
   * @returns
   * Checks whether the position changes or not.
   */
  isPositionChanged = (nextProps) => {
    return (
      nextProps.plugin.h !== this.state.plugin.h ||
      nextProps.plugin.w !== this.state.plugin.w ||
      nextProps.plugin.x !== this.state.plugin.x ||
      nextProps.plugin.y !== this.state.plugin.y
    );
  };

  /**
   *
   * @param {*} nextProps
   * @param {*} newState
   *
   * Sets the new position to the plugin
   */
  setPluginNewPosition = (nextProps, newState) => {
    newState.plugin.h = nextProps.plugin.h;
    newState.plugin.w = nextProps.plugin.w;
    newState.plugin.x = nextProps.plugin.x;
    newState.plugin.y = nextProps.plugin.y;
    newState.plugin.minH = nextProps.plugin.minH;
    newState.plugin.minW = nextProps.plugin.minW;
    newState.plugin.containerHeight = nextProps.containerHeight;

    this.setUpdatedPlugin(newState.plugin);

    newState.plugin.rerender = true;
  };

  /**
   *
   * @param {*} updatedPlugin
   * @returns
   * Creates a new object with the required fields to save
   */
  createSavePluginData = (updatedPlugin) => {
    let plugin = { ...updatedPlugin };

    let savePluginData = {
      id: plugin.id,
      key: plugin.key,
      config: deepCopy(plugin.config),
      originalConfig: deepCopy(plugin.originalConfig),
      preservedConfigFields: plugin.preservedConfigFields ? deepCopy(plugin.preservedConfigFields) : new Set(),
      columnMap: deepCopy(plugin.columnMap),
      conditionalFormats: deepCopy(plugin.conditionalFormats),
      drillDowns: deepCopy(plugin.drillDowns),
      navigations: deepCopy(plugin.navigations),
      sortedColumnList: deepCopy(plugin.sortedColumnList),
      x: plugin.x, // plugin x axis coordinate
      y: plugin.y, // plugin y axis coordinate
      w: plugin.w, // plugin width
      h: plugin.h, // plugin height
      minH: plugin.minH, // plugin minimum height
      minW: plugin.minW, // plugin minimum width
      actions: plugin.actions,
      reactions: plugin.reactions,
      titleReactions: plugin.titleReactions,
      defaultFilters: plugin.defaultFilters,
      removedInteractions: deepCopy(plugin.removedInteractions),
      edittedInteractions: deepCopy(plugin.edittedInteractions),
      limit: this.state.limit,
      usedSessionVariables: new Map(plugin.usedSessionVariables)
    };

    return savePluginData;
  };

  /**
   *
   * @param {*} nextProps
   * @param {*} pluginFiltersInformation
   * @returns
   * If different values ​​come from the same plugin, these objects will be changed.
   */
  changedInteractionFilterValues = (nextProps, pluginFiltersInformation) => {
    let changedFilterValueStatus = true;
    let changedPluginFiltersInformation = [];

    for (let filtersInformation of pluginFiltersInformation) {
      if (
        filtersInformation.pluginId ===
        nextProps.triggeringPluginInformation.pluginId
      ) {
        filtersInformation = { ...nextProps.triggeringPluginInformation };
        changedFilterValueStatus = false;
        changedPluginFiltersInformation.push(
          nextProps.triggeringPluginInformation
        );
      } else {
        changedPluginFiltersInformation.push(filtersInformation);
      }
    }

    return {
      changedPluginFiltersInformation: changedPluginFiltersInformation,
      changedFilterValueStatus: changedFilterValueStatus,
    };
  };

  /**
   *
   * @param {*} columns
   * @param {*} privateMethodObject
   * @param {*} plugin
   *
   * Setting private interactive filters to hashmap with plugin id
   */
  setPluginPrivateInteractionInMap = (columns, privateMethodObject, plugin) => {
    let reduxState = store.getState();
    let pluginPrivateInteractionFilters =
      reduxState.PluginTriggerReducer.pluginPrivateInteractionFilters;
    privateMethodObject.columns = columns;

    pluginPrivateInteractionFilters.set(plugin.id, privateMethodObject);

    setPluginPrivateFiltersTrigger(pluginPrivateInteractionFilters);
  };

  /**
  * Setting private interactive filters to hashmap with plugin id for Plugin's title
  * 
  * @param {*} columns
  * @param {*} privateMethodObject
  * @param {*} plugin
  */
  setPluginPrivateInteractionInMapForTitle = (columns, privateMethodObject, plugin) => {
    let reduxState = store.getState();
    let pluginPrivateInteractionFiltersTitle = reduxState.PluginTriggerReducer.pluginPrivateInteractionFiltersTitle;

    privateMethodObject.columns = columns;

    pluginPrivateInteractionFiltersTitle.set(plugin.id, privateMethodObject);

    setPluginPrivateFiltersTitleTrigger(pluginPrivateInteractionFiltersTitle);
  };

  /* Bu kısımda kullanılan usedInteraction.columns kısmı referansını koruduğu için 
  * interactionların çalışmasında sıkıntı yaşanılmıştır. usedInteraction kısmını setlediğimiz
  * yerde referansını korumaması için pluginInteractionFilters'ın setlendiği her kısımda
  * cloneDeep ile kopyalanmıştır. Aksi taktirde en son eklenen interaction filtresi çalışmaktadır
  */
  dispatchInteractionObject = (nextProps, usedInteraction) => {
    let triggeredInteractionObject = {
      triggeringPluginInformation: nextProps.triggeringPluginInformation,
      targetPluginId: nextProps.plugin.id,
    };

    triggeredInteractionObject.triggeringPluginInformation.usedInteraction =
      deepCopy(usedInteraction);

    let filtersInformation =
      triggeredInteractionObject.triggeringPluginInformation;

    let privateMethodObject = isPrivateMethod(
      filtersInformation,
      this.state.plugin
    );

    if (this.state.plugin.key !== "title") {
      let privateMethodObjectForTitle = isPrivateMethodForTitle(
        filtersInformation,
        this.state.plugin
      );

      if (privateMethodObjectForTitle.isPrivateMethodTitle === true) {
        /**
         * If there is a filter, necessary actions are taken for filtering.
         */
        if (filtersInformation.value.length > 0) {
          let columns = triggerPrivateMethod(
            filtersInformation,
            nextProps,
            this.state.plugin
          );

          this.setPluginPrivateInteractionInMapForTitle(
            columns,
            privateMethodObjectForTitle,
            this.state.plugin
          );

          //if plugin has function as privateMethodTitleName
          if (
            typeof this.state.plugin[privateMethodObjectForTitle.privateMethodTitleName] ===
            "function"
          ) {
            this.state.plugin[privateMethodObjectForTitle.privateMethodTitleName](
              columns,
              "#" + this.state.plugin.id
            );
          }
        }
      }
      else {
        let reduxState = store.getState();
        let pluginInteractionFilters =
          reduxState.PluginTriggerReducer.pluginInteractionFilters;
        let pluginFiltersInformation = [];
        let pluginFilterPredicate = nextProps.triggeringPluginInformation.filterOperator ? nextProps.triggeringPluginInformation.filterOperator : "="

        for (let i = 0; i < nextProps.triggeringPluginInformation.value.length; i++) {
          if (nextProps.triggeringPluginInformation.value[i].filter !== "is null" || nextProps.triggeringPluginInformation.value[i].filter !== "is not null") {
            nextProps.triggeringPluginInformation.value[i]["filterPredicate"] = pluginFilterPredicate
          } else if (nextProps.triggeringPluginInformation.value[i].filter === "is null") {
            nextProps.triggeringPluginInformation.value[i]["filterPredicate"] = "isNull"
          } else if (nextProps.triggeringPluginInformation.value[i].filter === "is not null") {
            nextProps.triggeringPluginInformation.value[i]["filterPredicate"] = "isNotNull"
          }
        }

        let sourcePluginsWithValues =
          reduxState.PluginTriggerReducer.sourcePluginsWithValues;
        sourcePluginsWithValues.set(
          nextProps.triggeringPluginInformation.pluginId,
          nextProps.triggeringPluginInformation.value
        );

        if (!pluginInteractionFilters.has(nextProps.plugin.id)) {
          pluginFiltersInformation.push(nextProps.triggeringPluginInformation);
          pluginInteractionFilters.set(
            nextProps.plugin.id,
            _.cloneDeep(pluginFiltersInformation)
          );
        } else {
          pluginFiltersInformation = pluginInteractionFilters.get(
            nextProps.plugin.id
          );
          let changedFilterValueResponse = this.changedInteractionFilterValues(
            nextProps,
            pluginFiltersInformation
          );
          let changedFilterValueStatus =
            changedFilterValueResponse.changedFilterValueStatus;

          pluginFiltersInformation =
            changedFilterValueResponse.changedPluginFiltersInformation;

          if (changedFilterValueStatus === true) {
            pluginFiltersInformation.push(nextProps.triggeringPluginInformation);
          }
        }

        pluginInteractionFilters.set(
          nextProps.plugin.id,
          _.cloneDeep(pluginFiltersInformation)
        );

        setPluginPrivateFiltersTitleTrigger(triggeredInteractionObject);
        setSourcePluginsWithValues(sourcePluginsWithValues);

        this.refreshPlugin(false, nextProps.plugin.id, false, true);
      }
    }

    if (privateMethodObject.isPrivateMethod === true) {
      /**
       * If there is a filter, necessary actions are taken for filtering.
       */
      if (filtersInformation.value.length > 0) {
        let columns = triggerPrivateMethod(
          filtersInformation,
          nextProps,
          this.state.plugin
        );

        this.setPluginPrivateInteractionInMap(
          columns,
          privateMethodObject,
          this.state.plugin
        );

        //if plugin has function as privateMethodName
        if (
          typeof this.state.plugin[privateMethodObject.privateMethodName] ===
          "function"
        ) {
          this.state.plugin[privateMethodObject.privateMethodName](
            columns,
            "#" + this.state.plugin.id
          );
        }
      }
    } else {
      let reduxState = store.getState();
      let pluginInteractionFilters =
        reduxState.PluginTriggerReducer.pluginInteractionFilters;
      let pluginFiltersInformation = [];
      let pluginFilterPredicate = nextProps.triggeringPluginInformation.filterOperator ? nextProps.triggeringPluginInformation.filterOperator : "="

      for (let i = 0; i < nextProps.triggeringPluginInformation.value.length; i++) {
        if (nextProps.triggeringPluginInformation.value[i].filter !== "is null" || nextProps.triggeringPluginInformation.value[i].filter !== "is not null") {
          nextProps.triggeringPluginInformation.value[i]["filterPredicate"] = pluginFilterPredicate
        } else if (nextProps.triggeringPluginInformation.value[i].filter === "is null") {
          nextProps.triggeringPluginInformation.value[i]["filterPredicate"] = "isNull"
        } else if (nextProps.triggeringPluginInformation.value[i].filter === "is not null") {
          nextProps.triggeringPluginInformation.value[i]["filterPredicate"] = "isNotNull"
        }
      }

      let sourcePluginsWithValues =
        reduxState.PluginTriggerReducer.sourcePluginsWithValues;
      sourcePluginsWithValues.set(
        nextProps.triggeringPluginInformation.pluginId,
        nextProps.triggeringPluginInformation.value
      );

      if (!pluginInteractionFilters.has(nextProps.plugin.id)) {
        pluginFiltersInformation.push(nextProps.triggeringPluginInformation);
        pluginInteractionFilters.set(
          nextProps.plugin.id,
          _.cloneDeep(pluginFiltersInformation)
        );
      } else {
        pluginFiltersInformation = pluginInteractionFilters.get(
          nextProps.plugin.id
        );
        let changedFilterValueResponse = this.changedInteractionFilterValues(
          nextProps,
          pluginFiltersInformation
        );
        let changedFilterValueStatus =
          changedFilterValueResponse.changedFilterValueStatus;

        pluginFiltersInformation =
          changedFilterValueResponse.changedPluginFiltersInformation;

        if (changedFilterValueStatus === true) {
          pluginFiltersInformation.push(nextProps.triggeringPluginInformation);
        }
      }

      pluginInteractionFilters.set(
        nextProps.plugin.id,
        _.cloneDeep(pluginFiltersInformation)
      );

      setPluginFiltersTrigger(triggeredInteractionObject);
      setSourcePluginsWithValues(sourcePluginsWithValues);
      this.refreshPlugin(false, nextProps.plugin.id, true, true);
    }
  };

  updatePluginSelector = (props) => {
    return pluginSelector({
      plugin: this.state.plugin,
      model: props.model,
      interactions: props.interactions,
      navigations: this.state.plugin.navigations,
      rerender: this.state.plugin.rerender,
      setPluginRerender: this.setPluginRerender,
      updatePlugin: this.updatePluginFields,
      settings: props.settings,
      updateDefaultFilterForPlugin: this.updateDefaultFilterForPlugin,
      updateConfig: this.updateConfig,
      config: this.state.plugin.config,
      configVisibility: this.state.configVisibility,
      changeConfigVisibility: this.changeConfigVisibility,
      dataVisibility: this.state.dataVisibility,
      changeDataVisibility: this.changeDataVisibility,
      getData: this.state.getData,
      join: props.join,
      addJoinToJoinList: props.addJoinToJoinList,
      joinErrorVisibility: this.state.joinErrorVisibility,
      changeJoinErrorVisibility: this.changeJoinErrorVisibility,
      clickedRefresh: this.state.clickedRefresh,
      setClickedRefresh: this.setClickedRefresh,
      hasNotJoinedData: this.state.hasNotJoinedData,
      changeHasNotJoinedData: this.changeHasNotJoinedData,
      refreshedPluginId: props.refreshedPluginId,
      changeRefreshedPluginId: props.changeRefreshedPluginId,
      conditionalFormattingVisibility: this.state.conditionalFormattingVisibility,
      changeConditionalFormattingVisibility: this.changeConditionalFormattingVisibility,
      doesPluginHasNotJoinedTable: this.state.doesPluginHasNotJoinedTable,
      changeDoesPluginHasNotJoinedTable: this.changeDoesPluginHasNotJoinedTable,
      updateModelTablesForJoin: props.updateModelTablesForJoin,
      cancelTokenSource: this.cancelTokenSource,
      changeNavigationVisibility: this.changeNavigationVisibility,
      navigationComponentVisibility: this.state.navigationVisibility,
      dashboardInformation: props.dashboardInformation,
      popupsRef: this.popupsRef,
      mobileSize: props.mobileSize,
      columnMapWithLocationFieldName: this.state.columnMapWithLocationFieldName,
      isNewPlugin: this.state.isNewPlugin,
      updateCommonTitleConfig: props.updateCommonTitleConfig,
      commonTitleConfig: props.commonTitleConfig,
      setDefaultForPluginTitle: this.setDefaultForPluginTitle,
      setCurrentAppliedConfig: props.setCurrentAppliedConfig,
      currentAppliedConfig: props.currentAppliedConfig,
      changeConfigOptionsForMountedPlugin: this.changeConfigOptionsForMountedPlugin,
      titleChangeStatus: this.state.titleChangeStatus,
      refreshPlugin: this.refreshPlugin,
      pinStatusChange: this.pinStatusChange,
      isPinned: this.state.isPinned,
      setInteractions: props.setInteractions,
      noDataTitle: props.settingNoDataTitle,
      limit: this.state.limit,
      setDataLimitForPlugin: this.setDataLimitForPlugin,
      reReturnThemeSettings: this.reReturnThemeSettings,
      excelExport: this.excelExport,
      lastRefreshedPlugin: props.lastRefreshedPlugin,
      isExcelExportStyled: props.isExcelExportStyled,
      excelExportProgress: props.excelExportProgress,
      stateChanged: this.state.stateChanged,
      isExcelExportBigSized: props.isExcelExportBigSized,
      title: this.state.title,
      setPluginDataToEmpty: this.setPluginDataToEmpty,
      isDashboardSliderActive: props.isDashboardSliderActive,
      isDashboardSliderPlaying: props.isDashboardSliderPlaying,
      isDashboardSliderBackwarded: props.isDashboardSliderBackwarded
    });
  };

  /*
  * Sets plugin data to empty
  */
  setPluginDataToEmpty = (callback) => {
    this.setState({
      ...this.state,
      plugin: {
        ...this.state.plugin,
        data: undefined
      }
    }, () => {
      this.goToSelectedDrillDown(0, 0, 0)
      callback()
    })
  }

  /**
   * Set status excel exporting loader
   * @param {*} status 
   */
  excelExportProgress = (status) => {
    const closeProgress = () => {
      if (status === EXCEL_EXPORT_PROGRESS.DONE || status === EXCEL_EXPORT_PROGRESS.FAILED) {
        setTimeout(() => {
          this.setState({
            ...this.state,
            excelExportProgress: undefined
          })
        }, 2000)
      }
    }

    this.setState({
      ...this.state,
      excelExportProgress: status
    }, () => {
      closeProgress()
    }
    )
  }

  // General excel export is implemented for plugins
  excelExport = () => {
    let exportDashboard = {};
    let plugin = deepCopy(this.state.plugin);
    let model = this.props.model;

    plugin.config.title = this.state.title;

    exportDashboard["title"] = this.state.title;
    exportDashboard["plugins"] = [plugin];

    generalExcelExport(exportDashboard, model, this.excelExportProgress)
  }

  refreshPlugin = (
    status = false,
    pluginId,
    isGetData = false,
    isInteraction = false,
    autoRefreshStatus = false
  ) => {
    if (this.state.doesPluginHasNotJoinedTable === true) {
      if (this.state.plugin?.errors?.has("expressionCalculation")) {
        let copiedErrors = deepCopy(this.state.plugin.errors)

        copiedErrors.delete("expressionCalculation")

        this.setState({
          ...this.state,
          plugin: {
            ...this.state.plugin,
            errors: copiedErrors
          }
        }, () => {
          this.setPluginRerender(
            status,
            pluginId,
            isGetData,
            isInteraction,
            autoRefreshStatus
          );
        })
      } else {
        this.setPluginRerender(
          status,
          pluginId,
          isGetData,
          isInteraction,
          autoRefreshStatus
        );
      }
    }
  };

  titleChangeStatus = () => {
    this.setState({
      ...this.state,
      titleChangeStatus: true,
    }, () => this.updateRender(this.state.plugin));
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value,
    });
  };

  /**
   *
   * @returns
   * Reads from store to show applied filters in filter icon
   */
  getAllFiltersForPlugin = () => {
    let reduxState = store.getState();
    let triggeredFilters =
      reduxState.PluginTriggerReducer.allPluginsAllFilters.get(
        this.state.plugin.id
      );
    let filteredTriggeredFilters = triggeredFilters.filter(filter => !filter.compared || (filter.filterArea === undefined || filter.filterArea !== "DefaultFilters"))

    let pluginFilterShowData = {
      drilldowns: {
        title: "Filters",
        data: filteredTriggeredFilters,
      },
    };

    return pluginFilterShowData;
  };

  /*
   * Gets all filter and return if plugin has filter returns filter show component
   */
  showFilterSection = (triggeredFilters) => {
    if (triggeredFilters) {
      triggeredFilters = triggeredFilters.filter(filter => !filter.compared || (filter.filterArea === undefined || filter.filterArea !== "DefaultFilters"))

      if (triggeredFilters.length > 0) {
        return (
          <PluginFilterShow
            pluginId={this.state.plugin.id}
            data={this.getAllFiltersForPlugin()}
            iconVisibility={this.state.plugin?.config?.showDefaultFilterIcon}
            usedSessionVariables={this.state.plugin.usedSessionVariables}
            dashboardSessionVariables={this.state.dashboardSessionVariables}
          />
        );
      }
    }
  };

  /**
   *
   * @param {*} item
   * @param {*} index
   * @param {*} breadCrumbList
   *
   * Breadcrumb also allows to go to the desired layer
   */
  goToSelectedDrillDown = (item, index, breadCrumbList) => {
    let reduxState = store.getState();
    let triggeredDrillDowns = reduxState.DrillDownReducer.triggeredDrillDowns;
    let pluginTriggeredDrillDowns = triggeredDrillDowns.get(
      this.state.plugin.id
    );

    let pluginColumnClickedAndShouldBeRemoved =
      reduxState.DrillDownReducer.pluginColumnClickedAndShouldBeRemoved;

    //hasDrillDownOperation is status change in drillDown Layer map
    let drillDowns = reduxState.DrillDownReducer.drillDowns;
    let pluginDrillDowns = drillDowns.get(this.state.plugin.id);

    let filters = pluginTriggeredDrillDowns ? pluginTriggeredDrillDowns.filters : [];

    if (filters.length - 1 >= index) {
      for (let i = index; i < filters.length; i++) {
        let thisItemLayer = pluginDrillDowns.drillDownLayerMap.get(
          filters[i].aliasName + "#" + filters[i].uniqeColumnId
        );
        thisItemLayer.hasDrillDownOperation = false;
        pluginDrillDowns.drillDownLayerMap.set(
          filters[i].aliasName + "#" + filters[i].uniqeColumnId,
          thisItemLayer
        );
      }
      drillDowns.set(this.state.plugin.id, pluginDrillDowns);
      store.dispatch(setPluginsDrillDowns(drillDowns));

      //remove item in filters map and selected columns map
      filters =
      filters.slice(0, index);
      pluginTriggeredDrillDowns.selectedColumns =
        pluginTriggeredDrillDowns.selectedColumns.slice(0, index);

      // if it has not one item, it delete pluginTriggered in triggeredDrillDowns
      if (
        filters.length === 0 &&
        pluginTriggeredDrillDowns.selectedColumns.length === 0
      ) {
        triggeredDrillDowns.delete(this.state.plugin.id);

        if (pluginColumnClickedAndShouldBeRemoved.has(this.state.plugin.id)) {
          pluginColumnClickedAndShouldBeRemoved.delete(this.state.plugin.id);
        }
      } else {
        triggeredDrillDowns.set(
          this.state.plugin.id,
          pluginTriggeredDrillDowns
        );

        if (
          this.state.plugin.key !== "table" &&
          this.state.plugin.key !== "pivot-table"
        ) {
          this.getNewDrillDownColumnMap(
            reduxState,
            pluginTriggeredDrillDowns,
            pluginColumnClickedAndShouldBeRemoved
          );
        }
      }

      store.dispatch(setTriggeredDrillDowns(triggeredDrillDowns));
      this.refreshPlugin(false, this.props.plugin.id, true, true);
    }
  };

  /**
   * 
   * @param {*} reduxState 
   * @param {*} pluginTriggeredDrillDowns 
   * @param {*} pluginColumnClickedAndShouldBeRemoved 
   * 
   * In plugins where column Map needs to be edited, column Map is edited and set.
   */
  getNewDrillDownColumnMap = (
    reduxState,
    pluginTriggeredDrillDowns,
    pluginColumnClickedAndShouldBeRemoved
  ) => {
    let newClickedColumnsMap = new Map();
    let drillDownColumnMap =
      reduxState.DrillDownReducer.triggeredDrillDowns.get(
        this.state.plugin.id
      ).drillDownColumnMap;
    let newDrillDownColumnMap = {};

    for (let item of pluginTriggeredDrillDowns.selectedColumns) {
      if (!newDrillDownColumnMap[item.locationFieldName]) {
        newDrillDownColumnMap[item.locationFieldName] = [];
      }

      newDrillDownColumnMap[item.locationFieldName].push(item);
    }

    for (let key in newDrillDownColumnMap) {
      if (drillDownColumnMap[key]) {
        drillDownColumnMap[key].data = newDrillDownColumnMap[key];
      }
    }

    for (let item of pluginTriggeredDrillDowns.filters) {
      newClickedColumnsMap.set(item.uniqeColumnId, item);
    }

    pluginColumnClickedAndShouldBeRemoved.set(
      this.state.plugin.id,
      newClickedColumnsMap
    );
  };

  /**
   *
   * @param {*} e
   * changes the title value
   */
  titleValueChange = (e) => {
    let plugin = { ...this.state.plugin };

    plugin.config.title = e.target.value;
    plugin.originalConfig.title = e.target.value;

    if (!plugin.preservedConfigFields) {
      plugin.preservedConfigFields = new Set()
    }

    plugin.preservedConfigFields.add("title");

    this.setUpdatedPlugin(plugin);

    this.setState({
      plugin: plugin,
      title: calculateText(plugin, e.target.value)
    });
  };

  /**
   * On change title update config and set title.
   */
  titleChange = () => {
    this.setState({
      ...this.state,
      titleChangeStatus: false,
    }, () => this.updateRender(this.state.plugin));

    /**
     * TODO: This function call, about title change bug.
     */
    // pagePopupContentUpdate(this.state.plugin.configComponent);
  };

  // gets title align from plugins config return default if dont exist
  getTitleAlign = (state) => {
    return state.plugin.config && state.plugin.config.titleAlign
      ? state.plugin.config.titleAlign
      : "center"
  };

  // gets title fontweight from plugins config return default if dont exist
  getTitleFontWeight = (state) => {
    return state.plugin.config && state.plugin.config.titleFontWeight ? "bold" : "normal"
  };

  // gets title fontstyle from plugins config return default if dont exist
  getTitleFontStyle = (state) => {
    return state.plugin.config && state.plugin.config.titleFontStyle ? "italic" : "normal"
  };

  // gets title textdecor from plugins config return default if dont exist
  getTitleTextDecor = (state) => {
    return state.plugin.config && state.plugin.config.titleTextDecor ? "underline" : "none"
  };

  // gets title font size from plugins config return default if dont exist
  getTitleFontSize = (state) => {
    return state.plugin.config && state.plugin.config.titleFontSize
      ? Number(state.plugin.config.titleFontSize)
      : 16

  }

  // gets title font from plugins config return default if dont exist
  getTitleFont = (state) => {
    return state.plugin.config && state.plugin.config.titleFont
      ? state.plugin.config.titleFont : "Verdana"

  }

  // gets title colour from plugins config return default if dont exist
  getTitleColour = (state) => {
    let pluginBackgroundColor = this.getPluginBackgroundColor(state.plugin?.config);

    return state.plugin?.config && state.plugin.config.titleColour
      ? state.plugin.config.titleColour : getContrastColor(pluginBackgroundColor)
  }

  // gets plugin background color from plugin's config return default if dont exist
  getPluginBackgroundColor = (config) => {
    if (
      !config?.backgroundColor ||
      config.backgroundColor === "rgba(255,255,255,0.5)" ||
      config.backgroundColor === "#FFFFFF80"
    ) {
      return "#FFFFFF"
    }

    return config.backgroundColor;
  }

  // controls is title config parameters undefined
  controlTitleParameteres = (config) => {
    let isTitleAlignEmpty = config.titleAlign === undefined || config.titleAlign === "center" ? true : false
    let isTitleColourEmpty = config.titleColour === undefined || config.titleColour === "black" ? true : false
    let isTitleFontEmpty = config.titleFont === undefined || config.titleFont === "Verdana" ? true : false
    let istitleFontSizeEmpty = config.titleFontSize === undefined || config.titleFontSize === "15" ? true : false
    let isTitleFontStyleEmpty = config.titleFontStyle === undefined || config.titleFontStyle === false ? true : false
    let isTitleFontWeightEmpty = config.titleFontWeight === undefined || config.titleFontWeight === false ? true : false
    let isTitleTextDecorEmpty = config.titleTextDecor === undefined || config.titleTextDecor === false ? true : false

    let isAllParametersEmpty = isTitleAlignEmpty && isTitleColourEmpty && isTitleFontEmpty && istitleFontSizeEmpty && isTitleFontStyleEmpty && isTitleFontWeightEmpty && isTitleTextDecorEmpty

    return isAllParametersEmpty
  }

  /*
  * Gets title config
  */
  getTitleConfigs = () => {
    if (this.state.firstRender && Object.keys(this.props.currentAppliedConfig).length !== 0 && !this.controlTitleParameteres(this.props.currentAppliedConfig)) {
      let config = this.props.currentAppliedConfig
      let newState = { ...this.state }
      let titleConfigFields = ["titleAlign", "titleColour", "titleFont", "titleFontSize", "titleFontStyle", "titleFontWeight", "titleTextDecor"]
      let commonTitleConfig = this.props.commonTitleConfig

      for (let field of titleConfigFields) {
        if (commonTitleConfig.preservedConfigFields?.has(field)) {
          newState.plugin.config[field] = config[field]

          if (newState.plugin.originalConfig) {
            newState.plugin.originalConfig[field] = config[field]
          }

          if (!(newState.plugin.preservedConfigFields instanceof Set)) {
            newState.plugin.preservedConfigFields = new Set()
          }

          newState.plugin.preservedConfigFields.add(field)
        }
      }

      this.setState(newState)
      this.setUpdatedPlugin(newState.plugin);
    } else {
      let theme = sessionStorage.getItem("theme-dashboard")
      let config = this.state

      if (theme) {
        let isThemeAvailable = JSON.parse(theme)?.plugin

        config = isThemeAvailable ? {
          plugin: {
            config: isThemeAvailable
          }
        } : this.state
      }

      let preservedConfigFields = this.state.plugin?.preservedConfigFields instanceof Set ? this.state.plugin.preservedConfigFields : new Set()

      let titleConfigs = {
        fontWeight: preservedConfigFields?.has("titleFontWeight") ? this.getTitleFontWeight(this.state) : this.getTitleFontWeight(config),
        fontStyle: preservedConfigFields?.has("titleFontStyle") ? this.getTitleFontStyle(this.state) : this.getTitleFontStyle(config),
        textDecoration: preservedConfigFields?.has("titleTextDecor") ? this.getTitleTextDecor(this.state) : this.getTitleTextDecor(config),
        fontSize: preservedConfigFields?.has("titleFontSize") ? this.getTitleFontSize(this.state) + "px" : this.getTitleFontSize(config) + "px",
        fontFamily: preservedConfigFields?.has("titleFont") ? this.getTitleFont(this.state) : this.getTitleFont(config),
        color: preservedConfigFields?.has("titleColour") ? this.getTitleColour(this.state) : this.getTitleColour(config),
        textAlign: preservedConfigFields?.has("titleAlign") ? this.getTitleAlign(this.state) : this.getTitleAlign(config)
      }

      return titleConfigs
    }
  }

  /*
  * Rereturns theme settings
  */
  reReturnThemeSettings = () => {
    let copiedPlugin = deepCopy(this.state.plugin)

    copiedPlugin.preservedConfigFields = new Set()

    this.props.applyThemeToPlugin(copiedPlugin)

    this.setState({
      ...this.state,
      plugin: copiedPlugin
    }, () => {
      let reduxState = store.getState();
      let updatedPlugins = deepCopy(reduxState.PluginTriggerReducer.plugins)

      updatedPlugins.set(this.state.plugin.id, copiedPlugin)

      store.dispatch(setUpdatedPlugins(updatedPlugins))

      this.refreshPlugin(true, this.state.plugin.id)
    })
  }

  /**
   * Returns true or false according to interaction and navigation status
   */
  isInteractionAndNavigationPagePopup = () => {
    if (
      this.state.plugin.navigations &&
      this.state.plugin.navigations.length > 0
    ) {
      if (this.state.plugin.navigations.length > 1) {
        return true;
      } else if (
        this.state.plugin.navigations.length === 1 &&
        this.props.interactions &&
        this.props.interactions.length > 0
      ) {
        return true;
      }
    }

    return false;
  };

  /**
   *
   * @returns
   *  Checks if the title is empty
   */
  isTitleEmpty = () => {
    return (
      this.state.plugin.config &&
      (!this.state.plugin.config.title || this.state.plugin.config.title === "")
    );
  };

  setJoinErrorAndClickedRefresh = (joinError, clickedRefresh, findValidJoin = true) => {
    this.setState({
      ...this.state,
      joinErrorVisibility: joinError,
      clickedRefresh: clickedRefresh,
      findValidJoin: findValidJoin
    });
  };

  /*
  * For breadcrumbs visible after plugin loading
  */
  returnBreadCrumbListVisible = () => {
    let style = {
      marginLeft: "6px",
      marginBottom: "15px",
      position: "relative",
      zIndex: "3",
      display: "inline-block"
    }

    let reduxState = store.getState()
    let pluginLoaderReducer = reduxState.PluginLoaderReducer
    let isPluginLoaderActive = pluginLoaderReducer.waitForLoadPlugins.size > 0 && pluginLoaderReducer.waitForLoadPlugins.has(this.props.plugin.id)

    if (isPluginLoaderActive) {
      style["display"] = "none"

      return style
    }

    return style
  }

  /**
   * If Title has any interaction apply interaction on title.
   * @param {*} title 
   * @param {*} titleInteraction 
   * @returns 
   */
  titleUpdateOrResetWithInteraction = (title, titleInteraction) => {
    title = title === undefined || title === null ? "" : title;
    let titleTranslated = title.includes(":") ? title : i18n.t(title);

    if (titleInteraction.size > 0) {
      let fullPostfix = "";

      if (titleInteraction.has(this.state.plugin.id)) {
        let values = titleInteraction.get(this.state.plugin.id);
        let areValuesEmpty = true;

        //When the value comes from more than one column of the same plugin, it combines the values ​​and shows the title.
        for (let i = 0; i < values.columns?.length; i++) {
          let column = values.columns[i]

          if (column.values?.length > 0) {
            areValuesEmpty = false;
          }

          if (column.sourceName) {
            fullPostfix = column.sourceName;
          } else {
            if (fullPostfix !== "" && column.values?.length > 0) {
              fullPostfix = fullPostfix + ",";
            }

            fullPostfix = fullPostfix + column.values.join(", ");
          }
        }

        if (values.privateMethodTitleName === "resetTitle") {
          if (areValuesEmpty) {
            fullPostfix = titleTranslated;
          }

          titleTranslated = fullPostfix;
        } else if (values.privateMethodTitleName === "updateTitle") {
          titleTranslated = `${titleTranslated} ${fullPostfix}`;
        }
      }
    }

    return titleTranslated;
  }

  render() {
    let component = this.updatePluginSelector(this.props);
    let pluginContainerShadow = this.props.settings.grid.shadow;
    let isCustomDashboardModeActive = this.props.isCustomDashboard;
    let pluginContainerShadowStyle = (pluginContainerShadow && {
      boxShadow:
        pluginContainerShadow.rightLeft +
        "px " +
        pluginContainerShadow.upDown +
        "px " +
        pluginContainerShadow.blur +
        "px " +
        pluginContainerShadow.spread +
        "px " +
        pluginContainerShadow.color
    }) || {};

    let reduxState = store.getState();
    let { DrillDownReducer } = reduxState;
    let triggeredDrillDowns =
      DrillDownReducer.triggeredDrillDowns.get(this.state.plugin.id);
    let pluginsAllFilterDatas =
      reduxState.PluginTriggerReducer.allPluginsAllFilters.get(
        this.state.plugin.id
      );

    let breadCrumbList = (triggeredDrillDowns?.filters && [
      ...triggeredDrillDowns.filters,
      triggeredDrillDowns.selectedColumns[triggeredDrillDowns.selectedColumns.length - 1]
    ]) || [];

    let hasPluginDrilldown = reduxState.DrillDownReducer.drillDowns.has(this.state.plugin.id)
    let drilldownType = reduxState.DrillDownReducer.drillDowns.has(this.state.plugin.id) && reduxState.DrillDownReducer.drillDowns.get(this.state.plugin.id).drillDownTypes ? reduxState.DrillDownReducer.drillDowns.get(this.state.plugin.id).drillDownTypes : "normal"
    let isBreadcrumbsCanBeShowed = hasPluginDrilldown
      ? (drilldownType && (drilldownType === "inside-plugin" || drilldownType === "explode-pie-chart"))
        ? false
        : true
      : true

    let pluginHeight = calculatePluginInlineHeight(this.state.plugin.id);
    let hasError = typeof this.state.plugin.errors === "undefined" || this.state.plugin.errors.size === 0;

    let pluginBackgroundColor = this.getPluginBackgroundColor(this.state?.plugin?.config);
    let pluginContrastColor = getContrastColor(pluginBackgroundColor);

    let titleInteraction = this.props.pluginPrivateInteractionFiltersTitle;
    let titleOfPlugin = this.titleUpdateOrResetWithInteraction(this.state.title,
      titleInteraction
    );

    return (
      <>
        <div
          key={`plugin-container-${this.state.plugin.id}-${this.props.currentDashboardId}`}
          id={"plugin-container-" + this.state.plugin.id}
          className={"plugin-container"}
          pluginKey={this.state.plugin.key}
          index={this.props.index}
          style={{
            ...pluginContainerShadowStyle,
            backgroundColor: pluginBackgroundColor,
          }}
        >
          {!withoutPluginRenderPlugins.has(this.state.plugin.key)
            ? <PluginLoader isFirstRender={this.state.firstRender} hasError={hasError} pluginId={this.state.plugin.id} />
            : <PluginLoaderForWithoutPluginRender isFirstRender={this.state.firstRender} hasError={hasError} pluginId={this.state.plugin.id} />}

          <div className={"holder"}>
            {
              !this.props.isDashboardSliderActive ? (
                <PluginTools
                  removePlugin={this.props.removePlugin}
                  refresh={this.refreshPlugin}
                  rerender={this.state.plugin.rerender}
                  plugin={this.state.plugin}
                  updatePlugin={this.updatePluginFields}
                  model={this.props.model}
                  activeInteractionPluginObject={
                    this.props.activeInteractionPluginObject
                  }
                  setActiveInteractionPlugin={
                    this.props.setActiveInteractionPlugin
                  }
                  dashboardInformation={this.props.dashboardInformation}
                  changeConfigVisibility={this.changeConfigVisibility}
                  changeDataVisibility={this.changeDataVisibility}
                  joinErrorVisibility={this.state.joinErrorVisibility}
                  changeJoinErrorVisibility={this.changeJoinErrorVisibility}
                  clickedRefresh={this.state.clickedRefresh}
                  setClickedRefresh={this.setClickedRefresh}
                  refreshedPluginId={this.props.refreshedPluginId}
                  changeRefreshedPluginId={this.props.changeRefreshedPluginId}
                  changeConditionalFormattingVisibility={
                    this.changeConditionalFormattingVisibility
                  }
                  changeNavigationVisibility={this.changeNavigationVisibility}
                  readOnly={this.props.readOnly}
                  visibilityOfTooltipButtons={(this.props.editting && this.props.readOnly) || isCustomDashboardModeActive}
                  changeStatusOfHoveredPluginTools={this.props.changeStatusOfHoveredPluginTools}
                />
              ) : null
            }

            {!this.state.titleChangeStatus && this.state.plugin.config ? (
              <Text
                id={"title-" + this.state.plugin.id}
                className={
                  this.state.plugin.config.title === "" ? "" : "plugin-title"
                }
                onDoubleClick={!this.props.isDashboardSliderActive && this.titleChangeStatus}
                type="span"
                style={{
                  ...this.getTitleConfigs(),
                  borderColor: pluginContrastColor + "33",
                  backgroundColor: pluginBackgroundColor,
                  cursor: this.props.isDashboardSliderActive && "default"
                }}
                title={titleOfPlugin}
              >
                {titleOfPlugin}
              </Text>
            ) : null}

            <Interaction
              activeInteractionPluginObject={
                this.props.activeInteractionPluginObject
              }
              interactions={this.props.interactions}
              interactionListForDisabledColumn={this.state.plugin.columnMap}
              plugin={this.state.plugin}
              pluginList={this.props.plugins}
              setInteractions={this.props.setInteractions}
              interactionsHashMapForSource={
                this.props.interactionsHashMapForSource
              }
              closeActiveInteractionPlugin={
                this.props.closeActiveInteractionPlugin
              }
              setRemovedAutoInteractionsInPlugin={
                this.updateRemovedAutoInteractionsInPlugin
              }
            />

            <CacheMode
              isCacheMode={this.props.isCacheMode}
              cachedPluginsMap={this.props.cachedPluginsMap}
              plugin={this.state.plugin} />

            {!this.props.isDashboardSliderActive && this.state.titleChangeStatus ? (
              <Input
                className={"plugin-title-input"}
                style={{
                  ...this.getTitleConfigs(),
                  borderColor: pluginContrastColor + "33",
                  backgroundColor: pluginBackgroundColor,
                }}
                onChange={this.titleValueChange}
                id={`title-${this.state.plugin.id}`}
                onPressEnter={this.titleChange}
                value={this.state.plugin.config.title.includes(":") ? this.state.plugin.config.title : i18n.t(this.state.plugin.config.title)}
              />
            ) : null}

            {breadCrumbList.length > 0 && isBreadcrumbsCanBeShowed ? (
              <div
                className={"left-tools"}
                style={this.returnBreadCrumbListVisible()}
              >
                <Breadcrumb separator=">">
                  {breadCrumbList.length > 0 &&
                    breadCrumbList.map((item, index) => (
                      <Breadcrumb.Item
                        className={
                          index === triggeredDrillDowns.filters.length
                            ? "breadcrumb-last-item"
                            : "breadcrumb-item"
                        }
                        style={{ fontSize: "15px", textAlign: "center" }}
                        onClick={() =>
                          this.goToSelectedDrillDown(
                            item,
                            index,
                            breadCrumbList
                          )
                        }
                      >
                        {item.displayName}
                      </Breadcrumb.Item>
                    ))}
                </Breadcrumb>
              </div>
            ) : null}
            <div
              className={
                this.isInteractionAndNavigationPagePopup() === true
                  ? this.state.plugin.id
                  : ""
              }
              plugin="true"
              id={"plugin-" + this.state.plugin.id}
              style={{ overflow: "auto", height: pluginHeight, backgroundColor: pluginBackgroundColor }}
            >
              <PluginError
                errors={this.state.plugin.errors}
                plugin={this.state.plugin}
              />

              <div
                style={{
                  height: hasError ? "100%" : this.state.plugin.key === "age-pyramid" ? "100%" : "0px",
                  overflow: "auto",
                  width: hasError ? "100%" : this.state.plugin.key === "age-pyramid" ? "100%" : "0px",
                }}
              >
                {component}
              </div>
            </div>
          </div>

          {/* TODO: plugin filters show component will open after drilldown feature done.  */}
          {this.showFilterSection(pluginsAllFilterDatas)}
        </div>
        {this.props.plugin.key !== "measure-tile" ?
          <JoinPopup
            okButtonProps={{ children: "Custom OK" }}
            cancelButtonProps={{ children: "Custom cancel" }}
            visible={this.state.joinErrorVisibility}
            didNotJoinedTables={checkTableJoins(
              this.props.join,
              this.state.plugin.columnMap,
              this.props.refreshedPluginId,
              this.props.plugin.id
            )}
            model={
              this.props.model?.tables !== undefined
                ? this.props.model
                : this.props.currentModelWithTable
            }
            addJoinToJoinList={this.props.addJoinToJoinList}
            onCancelModal={() => this.changeJoinErrorVisibility(false)}
            joinErrorVisibility={this.state.joinErrorVisibility}
            changeJoinErrorVisibility={this.changeJoinErrorVisibility}
            updateModelTablesForJoin={this.props.updateModelTablesForJoin}
            getModelTables={this.props.getModelTables}
            clickedRefresh={this.state.clickedRefresh}
            setClickedRefresh={this.setClickedRefresh}
            doesPluginHasNotJoinedTable={this.state.doesPluginHasNotJoinedTable}
            changeDoesPluginHasNotJoinedTable={
              this.changeDoesPluginHasNotJoinedTable
            }
            findValidJoin={this.state.findValidJoin}
            join={this.props.join}
            columnMap={this.state.plugin.columnMap}
            refreshedPluginId={this.props.refreshedPluginId}
            pluginId={this.props.plugin.id}
            setJoinErrorAndClickedRefresh={this.setJoinErrorAndClickedRefresh}
          /> : null}
      </>
    );
  }
}

const mapDispatchToProps = {
  changeCustomDashboardStatus,
};

const mapStateToProps = (state) => {
  return {
    isCacheMode: state.CacheModeReducer.isCacheModeActive,
    cachedPluginsMap: state.CacheModeReducer.cachedPluginsMap,
    isCustomDashboard: state.CustomDashboardReducer.isCustomDashboard,
    pluginPrivateInteractionFiltersTitle: state.PluginTriggerReducer.pluginPrivateInteractionFiltersTitle,
    settingNoDataTitle: state.SettingNoDataTitleReducer.data,
    sessionVariables: state.SessionVariableReducer.sessionVariables,
    dashboardSessionVariables: state.SessionVariableReducer.dashboard,
    pluginLoadingValues: state.PluginLoaderReducer.waitForLoadPlugins
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Plugin);