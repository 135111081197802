import React, { Component } from "react";
import Text from "../../../GeneralComponents/Text/Text";
import { Row, Col, Input, Select, Checkbox, Divider, Button, Tooltip } from "antd";
import {
  BgColorsOutlined,
  EyeInvisibleOutlined,
  ColumnWidthOutlined,
  ColumnHeightOutlined,
  AreaChartOutlined,
  NodeIndexOutlined,
  LineChartOutlined,
  AimOutlined,
  PlusOutlined,
  CloseCircleFilled,
  DotChartOutlined,
  FontSizeOutlined,
  InfoCircleOutlined,
  LockOutlined
} from "@ant-design/icons";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import i18n from "../../../../Utils/i18next";
import { InsightsConfig } from "../../RenderJs/config";
import ThemePalette from "../../../GeneralComponents/ThemePalette/ThemePalette";
import TitleSettingsOfPlugin from "../../../GeneralComponents/TitleSettingsOfPlugin/TitleSettingsOfPlugin";
import $ from "jquery"
import { deepCopy } from "../../../../Utils/Global";
import ReturnToTheme from "../../PluginToolsComponents/ReturnToTheme";

const { TextArea } = Input;
const { Option } = Select;

/**
 * Custom configuration component for timeline chart
 */
export default class TimelineChartConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTarget: null,
      targetColorVisible: false,
      backgroundColor: this.props.config.backgroundColor,
      colours: this.props.config.colours,
      showHideButton: this.props.config.showHideButton,
      size: this.props.config.size,
      title: this.props.config.title,
      titleAlign: this.props.config.titleAlign,
      paletteColours: InsightsConfig.Palettes[this.props.config.colours]
        ? InsightsConfig.Palettes[this.props.config.colours]
        : this.props.config.colours,
      condFormat: this.props.config.condFormat,
      titleFontStyle: this.props.config.titleFontStyle === undefined ? false : this.props.config.titleFontStyle,
      titleFontWeight: this.props.config.titleFontWeight === undefined ? false : this.props.config.titleFontWeight,
      titleFontSize: this.props.config.titleFontSize === undefined ? 15 : this.props.config.titleFontSize,
      titleFont: this.props.config.titleFont === undefined ? "Verdana" : this.props.config.titleFont,
      titleColour: this.props.config.titleColour === undefined ? "black" : this.props.config.titleColour,
      titleTextDecor: this.props.config.titleTextDecor === undefined ? false : this.props.config.titleTextDecor,
      changedTitleFontSize: this.props.config.changedTitleFontSize === undefined ? 15 : this.props.config.changedTitleFontSize,
      keyForTitleSize: null,
      xZoom: this.props.config.xZoom === undefined ? true : this.props.config.xZoom,
      yZoom: this.props.config.yZoom === undefined ? false : this.props.config.yZoom,
      xZoomAlignment: this.props.config.xZoomAlignment === undefined ? "bottom" : this.props.config.xZoomAlignment,
      yZoomAlignment: this.props.config.yZoomAlignment === undefined ? "right" : this.props.config.yZoomAlignment,
      singleAxis: this.props.config.singleAxis === undefined ? true : this.props.config.singleAxis,
      areaChart: this.props.config.areaChart === undefined ? true : this.props.config.areaChart,
      navigator: this.props.config.navigator === undefined ? true : this.props.config.navigator,
      continuousChart: this.props.config.continuousChart === undefined ? false : this.props.config.continuousChart,
      bullets: this.props.config.bullets === undefined ? true : this.props.config.bullets,
      legend: this.props.config.legend === undefined ? true : this.props.config.legend,
      groupData: this.props.config.groupData === undefined ? true : this.props.config.groupData,
      groupCount: this.props.config.groupCount === undefined ? "200" : this.props.config.groupCount,
      groupAggrRule: this.props.config.groupAggrRule === undefined ? "average" : this.props.config.groupAggrRule,
      smoothedLines: this.props.config.smoothedLines === undefined ? false : this.props.config.smoothedLines,
      showTargets: this.props.config.showTargets === undefined ? false : this.props.config.showTargets,
      fillTargets: this.props.config.fillTargets === undefined ? false : this.props.config.fillTargets,
      targets: this.props.config.targets === undefined ? [{
        name: "",
        value: "",
        color: "red"
      }] : this.props.config.targets,
      noDataTitle: this.props.config.noDataTitle === undefined ? "" : this.props.config.noDataTitle,
      strictMinMax: this.props.config.strictMinMax === undefined ? false : this.props.config.strictMinMax,
      axisMinMax: this.props.config.axisMinMax === undefined ? {} : this.props.config.axisMinMax
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      this.setState({
        backgroundColor: nextProps.config.backgroundColor,
        colours: nextProps.config.colours,
        showHideButton: nextProps.config.showHideButton,
        size: nextProps.config.size,
        title: nextProps.config.title,
        titleAlign: nextProps.config.titleAlign,
        paletteColours: InsightsConfig.Palettes[nextProps.config.colours]
          ? InsightsConfig.Palettes[nextProps.config.colours]
          : nextProps.config.colours,
        condFormat: nextProps.config.condFormat,
        titleFontStyle: nextProps.config.titleFontStyle === undefined ? false : nextProps.config.titleFontStyle,
        titleFontWeight: nextProps.config.titleFontWeight === undefined ? false : nextProps.config.titleFontWeight,
        titleFontSize: nextProps.config.titleFontSize === undefined ? 15 : nextProps.config.titleFontSize,
        titleFont: nextProps.config.titleFont === undefined ? "Verdana" : nextProps.config.titleFont,
        titleColour: nextProps.config.titleColour === undefined ? "black" : nextProps.config.titleColour,
        titleTextDecor: nextProps.config.titleTextDecor === undefined ? false : nextProps.config.titleTextDecor,
        changedTitleFontSize: nextProps.config.changedTitleFontSize === undefined ? 15 : nextProps.config.changedTitleFontSize,
        keyForTitleSize: null,
        xZoom: nextProps.config.xZoom === undefined ? true : nextProps.config.xZoom,
        yZoom: nextProps.config.yZoom === undefined ? false : nextProps.config.yZoom,
        xZoomAlignment: nextProps.config.xZoomAlignment === undefined ? "bottom" : nextProps.config.xZoomAlignment,
        yZoomAlignment: nextProps.config.yZoomAlignment === undefined ? "right" : nextProps.config.yZoomAlignment,
        singleAxis: nextProps.config.singleAxis === undefined ? false : nextProps.config.singleAxis,
        areaChart: nextProps.config.areaChart === undefined ? true : nextProps.config.areaChart,
        navigator: nextProps.config.navigator === undefined ? true : nextProps.config.navigator,
        continuousChart: nextProps.config.continuousChart === undefined ? false : nextProps.config.continuousChart,
        bullets: nextProps.config.bullets === undefined ? true : nextProps.config.bullets,
        legend: nextProps.config.legend === undefined ? true : nextProps.config.legend,
        groupData: nextProps.config.groupData === undefined ? true : nextProps.config.groupData,
        groupCount: nextProps.config.groupCount === undefined ? "200" : nextProps.config.groupCount,
        groupAggrRule: nextProps.config.groupAggrRule === undefined ? "average" : nextProps.config.groupAggrRule,
        smoothedLines: nextProps.config.smoothedLines === undefined ? false : nextProps.config.smoothedLines,
        showTargets: nextProps.config.showTargets === undefined ? false : nextProps.config.showTargets,
        fillTargets: nextProps.config.fillTargets === undefined ? false : nextProps.config.fillTargets,
        targets: nextProps.config.targets === undefined ? [{
          name: "",
          value: "",
          color: "red"
        }] : nextProps.config.targets,
        noDataTitle: nextProps.config.noDataTitle === undefined ? "" : nextProps.config.noDataTitle,
        strictMinMax: nextProps.config.strictMinMax === undefined ? false : nextProps.config.strictMinMax,
        axisMinMax: nextProps.config.axisMinMax === undefined ? {} : nextProps.config.axisMinMax
      });
    }
  }

  /*
  * Changes both of constant and changed value 
  * constant = between 10 and 30 values
  * changed value = all of values
  */
  handleChangeBothOfValues = (constantValue, changedValue) => {
    this.setState({
      ...this.state,
      titleFontSize: constantValue,
      changedTitleFontSize: changedValue
    })

    this.setConfigToPlugin(
      [
        {
          type: "titleFontSize",
          value: constantValue
        },
        {
          type: "changedTitleFontSize",
          value: changedValue
        }
      ]);
  }

  /**
   * sets defaults we get from plugin.jsx. callback for fontsizekey because it should keep changing to trigger default revert
   */
  setDefaultsForTitle = () => {
    let newConfig = this.props.setDefaultForPluginTitle();

    this.setState(
      {
        ...this.state,
        title: newConfig.title,
        titleAlign: newConfig.titleAlign,
        titleFont: newConfig.titleFont,
        titleFontSize: newConfig.titleFontSize,
        titleFontStyle: newConfig.titleFontStyle,
        titleFontWeight: newConfig.titleFontWeight,
        titleTextDecor: newConfig.titleTextDecor,
        titleColour: newConfig.titleColour,
        changedTitleFontSize: newConfig.titleFontSize
      }, () => {
        this.setConfigToPlugin({ type: "changedTitleFontSize", value: newConfig.titleFontSize })
      }
    );

    if (newConfig.titleFontSize > 10 || newConfig.titleFontSize < 30) {
      $("#titleFontSize").css("background", "")
    }
  }

  /**
   * Sets title color visible
   */
  titleColourChangeVisible = () => {
    this.setState({
      ...this.state,
      titleColourChange: !this.state.titleColourChange
    });
  };

  /**
   * Sets the given property's value
   * 
   * @param {*} e 
   */
  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.value });
  };

  /**
   * Sets x zoom alignment
   * 
   * @param {*} value 
   */
  handleChangeXZoomAlignment = value => {
    this.setState({
      ...this.state,
      xZoomAlignment: value
    });

    this.setConfigToPlugin({ type: "xZoomAlignment", value: value });
  };

  /**
   * Sets y zoom alignment
   * 
   * @param {*} value 
   */
  handleChangeYZoomAlignment = value => {
    this.setState({
      ...this.state,
      yZoomAlignment: value
    });

    this.setConfigToPlugin({ type: "yZoomAlignment", value: value });
  };

  /**
   * Sets given property's checked value
   * 
   * @param {*} e 
   */
  handleCheck = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.checked });
  };

  /**
   * Sets the given config property to plugin config
   * 
   * @param {*} changedObj 
   * @param {*} propertyListToDelete 
   */
  setConfigToPlugin = (changedObj, propertyListToDelete = undefined) => {
    let tempState = { ...this.state };

    if (Array.isArray(changedObj)) {
      for (let i = 0; i < changedObj.length; i++) {
        let object = changedObj[i];
        tempState[object.type] = object.value;
      }
    } else {
      tempState[changedObj.type] = changedObj.value;
    }

    if (propertyListToDelete !== undefined) {
      for (let item of propertyListToDelete) {
        delete tempState[item]
      }
    }

    this.props.updateConfig(
      tempState,
      this.props.pluginId,
      propertyListToDelete
    );
  };

  /**
   * Sets title alignment
   * 
   * @param {*} value 
   */
  titleAlignOnChange = value => {
    this.setState({
      ...this.state,
      titleAlign: value
    });

    this.setConfigToPlugin({ type: "titleAlign", value: value });
  };

  /**
   * Sets plugin's background color
   * 
   * @param {*} obj 
   */
  backgroundColorOnChange = obj => {
    this.setState({
      ...this.state,
      backgroundColor: obj.color
    });

    this.setConfigToPlugin({ type: "backgroundColor", value: obj.color });
  };

  /**
   * Handle palette selection func.
   * 
   * @param {*} value 
   */
  coloursOnChange = value => {
    this.setState({
      ...this.state,
      colours: value
    });

    this.setConfigToPlugin({ type: "colours", value: value });
  };

  /**
   * Sets background color picker visibility
   */
  backgroundColorChangeVisible = () => {
    this.setState({
      ...this.state,
      backgroundColorChange: !this.state.backgroundColorChange
    });
  };

  /**
   * Sets targets
   * 
   * @param {*} targets 
   */
  updateTargets = targets => {
    this.setState({
      ...this.state,
      targets: targets
    });

    this.inputTimer = setTimeout(() => {
      this.setConfigToPlugin({ type: "targets", value: targets });
    }, 500);
  }

  /**
   * Sets selected target's value
   * 
   * @param {*} value 
   * @param {*} index 
   */
  setTargetValue = async (value, index) => {
    if (this.inputTimer) {
      clearTimeout(this.inputTimer);
    }

    let targets = deepCopy(this.state.targets);

    targets[index].value = value;

    this.updateTargets(targets);
  }

  /**
   * Sets selected target's name
   * 
   * @param {*} value 
   * @param {*} index 
   */
  setTargetName = async (value, index) => {
    if (this.inputTimer) {
      clearTimeout(this.inputTimer);
    }

    let targets = deepCopy(this.state.targets);

    targets[index].name = value;

    this.updateTargets(targets);
  }

  /**
   * Sets selected target's color
   * 
   * @param {*} index 
   * @param {*} color 
   */
  setTargetColor = async (index, color) => {
    if (this.inputTimer) {
      clearTimeout(this.inputTimer);
    }

    let targets = deepCopy(this.state.targets);

    targets[index].color = color;

    this.updateTargets(targets);
  };

  /**
   * Checks the last element of values array is filled
   * 
   * @returns 
   */
  allowedToNewTarget = () => {
    let targets = this.state.targets;

    let last = targets[targets.length - 1];

    return last && (last.name && last.value && last.color) ? true : false
  }

  /**
   * Adds an empty target object to targets array
   * 
   * @param {*} index 
   * @returns 
   */
  addTarget = async (index = undefined) => {
    if (this.inputTimer) {
      clearTimeout(this.inputTimer);
    }

    if (!this.allowedToNewTarget()) {
      return;
    }

    let targets = deepCopy(this.state.targets);
    let emptyTarget = {
      name: "",
      value: "",
      color: "red"
    };

    if (index !== undefined) {
      targets.splice(index + 1, 0, emptyTarget);
    } else {
      targets.push(emptyTarget);
    }

    this.updateTargets(targets);
  }

  /**
   * Value on press enter handler for targets
   */
  targetOnPressEnter = (index, type) => {
    switch (type) {
      case "name":
        let currentValueInput = document.getElementById(`targetValue-${index}`);

        currentValueInput.focus();

        break;

      case "value":
        if (this.state.targets.length - 1 === index) {
          if (this.allowedToNewTarget()) {
            this.addTarget();
          } else {
            let currentNameInput = document.getElementById(`targetName-${index}`);

            currentNameInput.focus();
          }
        } else {
          let nextNameInput = document.getElementById(`targetName-${index + 1}`);

          nextNameInput.focus();
        }

        break;

      default:
        break;
    }
  }

  /**
   * Removes selected target object from the targets array
   * 
   * @param {*} index 
   */
  removeTarget = async (index) => {
    if (this.inputTimer) {
      clearTimeout(this.inputTimer);
    }

    let targets = deepCopy(this.state.targets);

    targets.splice(index, 1);

    if (targets.length === 0) {
      targets.push({
        name: "",
        value: "",
        color: "red"
      });
    }

    this.updateTargets(targets);
  }

  /**
   * Toggle target color picker visible
   * 
   * @param {*} index 
   */
  setTargetColorVisible = (index) => {
    this.setState({
      ...this.state,
      selectedTarget: index,
      targetColorVisible: index !== null ? true : false
    });
  }

  /**
   * Set data field group count
   * 
   * @param {*} value 
   */
  setDataGroupCount = async (value) => {
    if (this.inputTimer) {
      clearTimeout(this.inputTimer);
    }

    this.setState({
      ...this.state,
      groupCount: value
    });

    this.inputTimer = setTimeout(() => {
      this.setConfigToPlugin({ type: "groupCount", value: value });
    }, 500);
  }

  /**
   * Sets data group aggregation rule
   * 
   * @param {*} value 
   */
  handleChangeGroupAggrRule = value => {
    this.setState({
      ...this.state,
      groupAggrRule: value
    });

    this.setConfigToPlugin({ type: "groupAggrRule", value: value });
  };

  /**
   * Sets the given id measure's maximum axis value
   * 
   * @param {*} id 
   * @param {*} value 
   */
  handleChangeAxisMax = (id, value) => {
    if (this.inputTimer) {
      clearTimeout(this.inputTimer);
    }

    let axisMinMax = this.state.axisMinMax || {};
    let selectedAxisMinMax = axisMinMax[id] || {};

    axisMinMax[id] = {
      ...selectedAxisMinMax,
      max: typeof value !== "undefined" && value.length > 0 ? value : undefined
    }

    this.setState({
      ...this.state,
      axisMinMax: axisMinMax
    });

    this.inputTimer = setTimeout(() => {
      this.setConfigToPlugin({ type: "axisMinMax", value: axisMinMax });
    }, 500);
  }

  /**
   * Sets the given id measure's minimum axis value
   * 
   * @param {*} id 
   * @param {*} value 
   */
  handleChangeAxisMin = (id, value) => {
    if (this.inputTimer) {
      clearTimeout(this.inputTimer);
    }

    let axisMinMax = this.state.axisMinMax || {};
    let selectedAxisMinMax = axisMinMax[id] || {};

    axisMinMax[id] = {
      ...selectedAxisMinMax,
      min: typeof value !== "undefined" && value.length > 0 ? value : undefined
    }

    this.setState({
      ...this.state,
      axisMinMax: axisMinMax
    });

    this.inputTimer = setTimeout(() => {
      this.setConfigToPlugin({ type: "axisMinMax", value: axisMinMax });
    }, 500);
  }

  /**
   * Sets single axis
   * 
   * @param {*} event 
   */
  handleSingleAxis = event => {
    this.setState({
      ...this.state,
      singleAxis: event.target.checked
    });

    this.setConfigToPlugin({ type: "singleAxis", value: event.target.checked });
  }

  /**
   * Renders single axis configuration part
   * 
   * @param {*} measure 
   * @returns 
   */
  renderAxisMinMax = (axes, measure = undefined) => {
    let name = measure?.displayName ? measure.displayName : i18n.t("Dashboard.Configuration.Fields.SingleAxis");
    let id = measure?.uniqeColumnId ? measure.uniqeColumnId : "single";

    if (typeof name === "undefined" || typeof id === "undefined") return null;

    let axisMinMax = axes ? axes[id] : undefined;

    let axisMin = axisMinMax?.min ? Number(axisMinMax.min) : undefined;
    let axisMax = axisMinMax?.max ? Number(axisMinMax.max) : undefined;

    let minMeasure = this.props.minMeasure[id]
    let maxMeasure = this.props.maxMeasure[id]

    let equalStatus = axisMin !== undefined && axisMax !== undefined
      ? axisMin === axisMax
      : axisMax === undefined
        ? axisMin === maxMeasure
        : axisMin === undefined
          ? axisMax === minMeasure
          : false;

    let minStatus = typeof axisMin === "undefined"
      ? true
      : typeof axisMax === "undefined"
        ? axisMin < maxMeasure
        : axisMin < axisMax;

    let maxStatus = typeof axisMax === "undefined"
      ? true
      : typeof axisMin === "undefined"
        ? axisMax > minMeasure
        : axisMax > axisMin;

    let columnColor = this.state.strictMinMax !== true
      ? "#F5F5F5"
      : id === "single"
        ? "#000000"
        : this.props.measureColors["colorOf" + id];

    return (
      <>
        <Row
          id={"axis-min-max-" + id}
          key={"axis-min-max-" + id}
          style={{
            display: "flex",
            alignItems: "center",
            textAlign: "left",
            marginBottom: 5
          }}
        >
          <Col span={3}></Col>
          <Col style={{ display: "flex", alignItems: "center" }} span={1}>
            <div
              style={{
                height: 16,
                width: 16,
                borderRadius: "50%",
                border: "1px solid #d9d9d9",
                background: columnColor
              }}
            />
          </Col>
          <Col span={8} style={{ paddingRight: "4px" }}>
            <Text
              style={{
                color: this.state.strictMinMax !== true ? "rgba(0, 0, 0, 0.25)" : "#505050",
                transition: "all 0.3s"
              }}
            >
              {name}
            </Text>
          </Col>
          <Col span={6} style={{ paddingRight: 2 }}>
            <Input
              placeholder={this.state.strictMinMax !== true ? "" : minMeasure}
              value={this.state.strictMinMax !== true ? "" : axisMin}
              type={"number"}
              disabled={this.state.strictMinMax !== true}
              style={{
                borderColor: this.state.strictMinMax && !minStatus ? "red" : "",
                color: this.state.strictMinMax && !minStatus ? "red" : "",
                transition: "all 0.3s"
              }}
              onChange={(event) => this.handleChangeAxisMin(id, event.target.value)}
            />
          </Col>
          <Col span={6} style={{ paddingLeft: 2 }}>
            <Input
              placeholder={this.state.strictMinMax !== true ? "" : maxMeasure}
              value={this.state.strictMinMax !== true ? "" : axisMax}
              type={"number"}
              disabled={this.state.strictMinMax !== true}
              style={{
                borderColor: this.state.strictMinMax && !maxStatus ? "red" : "",
                color: this.state.strictMinMax && !maxStatus ? "red" : "",
                transition: "all 0.3s"
              }}
              onChange={(event) => this.handleChangeAxisMax(id, event.target.value)}
            />
          </Col>
        </Row>
        {this.state.strictMinMax && (equalStatus || !minStatus || !maxStatus) ? (
          <Row
            id={"invalid-min-max-" + id}
            key={"invalid-min-max-" + id}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              textAlign: "left",
              marginBottom: 8,
            }}
          >
            <Col span={12}>
              <Text style={{ fontSize: 11, color: "red", width: "100%", display: "flex", flexDirection: "column" }}>
                *
                {
                  equalStatus
                    ? i18n.t("Dashboard.Configuration.Fields.InvalidAxisMinMax")
                    : !minStatus
                      ? i18n.t("Dashboard.Configuration.Fields.InvalidAxisMin")
                      : !maxStatus
                        ? i18n.t("Dashboard.Configuration.Fields.InvalidAxisMax")
                        : null
                }
              </Text>
            </Col>
          </Row>
        ) : null}
      </>
    );
  }

  render() {
    let allowedToSetTargets = this.props.measures.length === 1 || this.state.singleAxis;
    let allowedToNewTarget = this.allowedToNewTarget();

    let measureIds = this.props.measures.map(measure => measure.uniqeColumnId);
    let axisMinMax = this.state.axisMinMax;

    for (let id in axisMinMax) {
      if (!measureIds.includes(id) && id !== "single") delete axisMinMax[id];
    }

    return (
      <div style={{ color: "#505050" }}>
        <ReturnToTheme
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          preservedConfigFieldsArray={this.props.plugin.preservedConfigFields ? Array.from(this.props.plugin.preservedConfigFields) : []}
        />

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.GeneralAppearance")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Palette")}
            </Text>
          </Col>
          <Col span={4} style={{ paddingRight: "10px" }}>
            <Select
              id={"colours"}
              defaultValue={this.state.colours}
              value={this.state.colours}
              style={{ width: "100%" }}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={this.coloursOnChange}>
              <Option value="Flat-UI">{i18n.t("Dashboard.Configuration.Fields.FlatUI")}</Option>
              <Option value="Flat-UI-Soft">{i18n.t("Dashboard.Configuration.Fields.FlatUISoft")}</Option>
              <Option value="Cool-Scale">{i18n.t("Dashboard.Configuration.Fields.CoolScale")}</Option>
              <Option value="Warm-Scale">{i18n.t("Dashboard.Configuration.Fields.WarmScale")}</Option>
              <Option value="Heatmap">{i18n.t("Dashboard.Configuration.Fields.Heatmap")}</Option>
            </Select>
          </Col>
          <Col span={8}>
            <ThemePalette
              colours={this.state.paletteColours}
              update={this.setConfigToPlugin}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.BackgroundColor")}
            </Text>
          </Col>
          <Col span={12}>
            <Button
              onClick={this.backgroundColorChangeVisible}
              style={{ width: "100%" }}
            >
              {this.state.backgroundColorChange
                ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}

            </Button>
            {this.state.backgroundColorChange ? (
              <ColorPickerPanel
                alpha={100}
                color={this.state.backgroundColor}
                onChange={this.backgroundColorOnChange}
                id={"backgroundColor"}
                style={{ marginTop: "5px", position: "absolute", right: 0 }}
              />
            ) : null}
          </Col>
        </Row>
        <Row style={{ height: "11px" }}>
        </Row>
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Chart")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <AreaChartOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.AreaChart")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"areaChart"}
              defaultChecked={this.state.areaChart}
              checked={this.state.areaChart}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <NodeIndexOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ContinuousChart")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"continuousChart"}
              defaultChecked={this.state.continuousChart}
              checked={this.state.continuousChart}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <NodeIndexOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "flex",
                marginTop: "2px",
                alignItems: "center"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.SmoothedLines")}
            </Text>
          </Col>
          <Col span={12}>
            <Tooltip
              placement="right"
              title={this.state.continuousChart ? i18n.t("Dashboard.Configuration.Fields.SmoothedLinesWarning") : null}
            >
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"smoothedLines"}
                disabled={this.state.continuousChart}
                defaultChecked={this.state.smoothedLines}
                checked={this.state.smoothedLines}
                onChange={this.handleCheck}
              ></Checkbox>
            </Tooltip>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowBullets")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"bullets"}
              defaultChecked={this.state.bullets}
              checked={this.state.bullets}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Legend")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"legend"}
              defaultChecked={this.state.legend}
              checked={this.state.legend}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowConditionalFormatting")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"condFormat"}
              defaultChecked={this.state.condFormat}
              checked={this.state.condFormat}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ display: "flex", alignItems: "center", margin: "25px 0" }}>
          <Col span={this.state.showTargets && allowedToSetTargets ? 14 : 24}>
            <Divider className={"config-divider-with-desc"} style={{ margin: 0 }} orientation="left">
              {i18n.t("Dashboard.Configuration.Fields.Axes")}
            </Divider>
          </Col>
          {
            this.state.showTargets && allowedToSetTargets ? (
              <Col span={10} style={{ fontSize: "11px", color: "#FF9800" }}>
                {`${i18n.t("Dashboard.Configuration.Fields.AxisTargetExplanation")}`}
              </Col>
            ) : null
          }
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <LineChartOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.SingleAxis")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"singleAxis"}
              defaultChecked={this.state.singleAxis}
              checked={this.state.singleAxis}
              onChange={this.handleSingleAxis}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <LockOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.StrictMinMax")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"strictMinMax"}
              defaultChecked={this.state.strictMinMax}
              checked={this.state.strictMinMax}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        {
          this.state.singleAxis !== true
            ? this.props.measures.map((measure) => this.renderAxisMinMax(axisMinMax, measure))
            : this.renderAxisMinMax(axisMinMax)
        }
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.DataGrouping")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <DotChartOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.GroupDataFields")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"groupData"}
              defaultChecked={this.state.groupData}
              checked={this.state.groupData}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <DotChartOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.GroupCount")}
            </Text>
          </Col>
          <Col span={12}>
            <Row>
              <Input
                id={"groupCount"}
                disabled={!this.state.groupData}
                value={this.state.groupCount}
                onChange={(event) => this.setDataGroupCount(event.target.value)}
              />
            </Row>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <i class="treeItemPanelIcons fontIcon" style={{ fontSize: "16px" }}> ∫ </i>{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.AggregationRule")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              id={"groupAggrRule"}
              style={{ width: "100%" }}
              placeholder={i18n.t(
                "Dashboard.Configuration.Fields.AggregationRule"
              )}
              onChange={(value) => this.handleChangeGroupAggrRule(value)}
              key={this.state.groupAggrRule}
              defaultValue={this.state.groupAggrRule}
              getPopupContainer={trigger => trigger.parentNode}
              dropdownMenuStyle={{ textAlign: "left" }}
              disabled={this.state.groupData !== true}
            >
              <Option value="average">
                {i18n.t("Dashboard.Configuration.Fields.Average")}
              </Option>
              <Option name={"centerTitleAlign"} value="sum">
                {i18n.t("Dashboard.Configuration.Fields.Sum")}
              </Option>
              <Option value="high">
                {i18n.t("Dashboard.Configuration.Fields.Maximum")}
              </Option>
              <Option value="low">
                {i18n.t("Dashboard.Configuration.Fields.Minimum")}
              </Option>
              <Option value="open">
                {i18n.t("Dashboard.Configuration.Fields.First")}
              </Option>
              <Option value="close">
                {i18n.t("Dashboard.Configuration.Fields.Last")}
              </Option>
            </Select>
          </Col>
        </Row>
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.ZoomX")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowZoomX")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"xZoom"}
              defaultChecked={this.state.xZoom}
              checked={this.state.xZoom}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <LineChartOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Navigator")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"navigator"}
              defaultChecked={this.state.navigator}
              checked={this.state.navigator}
              onChange={this.handleCheck}
              disabled={this.state.xZoom !== true}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <ColumnHeightOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Alignment")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              id={"xZoomAlignment"}
              style={{ width: "100%" }}
              placeholder={i18n.t(
                "Dashboard.Configuration.Fields.Alignment"
              )}
              onChange={(value) => this.handleChangeXZoomAlignment(value)}
              key={this.state.xZoomAlignment}
              defaultValue={this.state.xZoomAlignment}
              getPopupContainer={trigger => trigger.parentNode}
              dropdownMenuStyle={{ textAlign: "left" }}
              disabled={this.state.xZoom !== true}
            >
              <Option name={"centerTitleAlign"} value="top">
                {i18n.t("Dashboard.Configuration.Fields.Top")}
              </Option>
              <Option name={"leftTitleAlign"} value="bottom">
                {i18n.t("Dashboard.Configuration.Fields.Bottom")}
              </Option>
            </Select>
          </Col>
        </Row>
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.ZoomY")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowZoomY")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"yZoom"}
              defaultChecked={this.state.yZoom}
              checked={this.state.yZoom}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <ColumnWidthOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Alignment")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              id={"yZoomAlignment"}
              style={{ width: "100%" }}
              placeholder={i18n.t(
                "Dashboard.Configuration.Fields.Alignment"
              )}
              onChange={(value) => this.handleChangeYZoomAlignment(value)}
              key={this.props.yZoomAlignment}
              defaultValue={this.state.yZoomAlignment}
              getPopupContainer={trigger => trigger.parentNode}
              dropdownMenuStyle={{ textAlign: "left" }}
              disabled={this.state.yZoom !== true}
            >
              <Option name={"centerTitleAlign"} value="left">
                {i18n.t("Dashboard.Configuration.Fields.Left")}
              </Option>
              <Option name={"leftTitleAlign"} value="right">
                {i18n.t("Dashboard.Configuration.Fields.Right")}
              </Option>
            </Select>
          </Col>
        </Row>
        <Row style={{ display: "flex", alignItems: "center", margin: "25px 0" }}>
          <Col span={allowedToSetTargets ? 14 : 24}>
            <Divider className={"config-divider-with-desc"} orientation="left">
              {i18n.t("Dashboard.Configuration.Fields.Targets")}
            </Divider>
          </Col>
          {
            allowedToSetTargets ? (
              <Col span={10} style={{ fontSize: "11px", color: "#FF9800" }}>
                {`${i18n.t("Dashboard.Configuration.Fields.AxisTargetExplanation")}`}
              </Col>
            ) : null
          }
        </Row>
        <div style={{ position: "relative", userSelect: allowedToSetTargets ? "auto" : "none" }}>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.ShowTargets")}
              </Text>
            </Col>
            <Col span={12}>
              <Row>
                <Checkbox
                  style={{ float: "left", marginTop: "9px" }}
                  id={"showTargets"}
                  defaultChecked={this.state.showTargets}
                  checked={this.state.showTargets}
                  onChange={this.handleCheck}
                  disabled={!allowedToSetTargets}
                />
              </Row>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <AimOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.FillTargetArea")}
              </Text>
            </Col>
            <Col span={12}>
              <Row>
                <Checkbox
                  style={{ float: "left", marginTop: "9px" }}
                  id={"fillTargets"}
                  defaultChecked={this.state.fillTargets}
                  checked={this.state.fillTargets}
                  onChange={this.handleCheck}
                  disabled={!allowedToSetTargets || !this.state.showTargets}
                />
              </Row>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <AimOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Targets")}
              </Text>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col span={3}></Col>
            <Col span={21}>
              {
                this.state.targets.map((target, index) => {
                  let disableInputs = !allowedToSetTargets || !this.state.showTargets;
                  let showTargets = allowedToSetTargets && this.state.showTargets;
                  let showTargetColorPicker = this.state.targetColorVisible && this.state.selectedTarget === index;
                  let allowToRemoveTarget = showTargets && (this.state.targets.length > 1 || allowedToNewTarget);

                  return (
                    <Row
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "4px"

                      }}
                    >
                      <Col span={7} style={{ paddingRight: "4px" }}>
                        <Input
                          id={`targetName-${index}`}
                          placeholder={i18n.t("Name")}
                          value={target.name}
                          onChange={(event) => this.setTargetName(event.target.value, index)}
                          onPressEnter={() => this.targetOnPressEnter(index, "name")}
                          disabled={disableInputs}
                        />
                      </Col>
                      <Col span={7} style={{ padding: "0 2px" }}>
                        <Input
                          id={`targetValue-${index}`}
                          placeholder={i18n.t("Value")}
                          value={target.value}
                          type={"number"}
                          onChange={(event) => this.setTargetValue(event.target.value, index)}
                          onPressEnter={() => this.targetOnPressEnter(index, "value")}
                          disabled={disableInputs}
                        />
                      </Col>
                      <Col span={7} style={{ padding: "0 2px" }}>
                        <Button
                          disabled={disableInputs}
                          onClick={() => this.setTargetColorVisible(!this.state.targetColorVisible ? index : null)}
                          style={{ width: "100%" }}
                        >
                          {
                            showTargetColorPicker
                              ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                              : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")
                          }
                        </Button>
                        {
                          showTargetColorPicker ? (
                            <ColorPickerPanel
                              alpha={100}
                              color={target.color}
                              onChange={obj => this.setTargetColor(index, obj.color)}
                              style={{ marginTop: "5px", position: "absolute", right: 0 }}
                            />
                          ) : null
                        }
                      </Col>
                      <Col style={{ padding: "0 2px" }}>
                        <div
                          style={{
                            height: "32px",
                            width: "32px",
                            border: "1px solid #d7d6d6",
                            boxShadow: "0 2px 0 rgba(0, 0, 0,}",
                            borderRadius: "4px",
                            backgroundColor: showTargets ? target.color || "red" : "#F5F5F5",
                            transition: "all 0.3s"
                          }}
                        />
                      </Col>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%"
                        }}
                      >
                        <CloseCircleFilled
                          onClick={() => showTargets ? this.removeTarget(index) : null}
                          style={{
                            fontSize: "18px",
                            color: allowToRemoveTarget ? "rgb(249, 47, 47)" : "#dadada",
                            cursor: allowToRemoveTarget ? "pointer" : "not-allowed",
                            transition: "all 0.3s"
                          }}
                        />
                      </div>
                    </Row>
                  )
                })
              }
              <Row>
                {
                  allowedToSetTargets && this.state.showTargets && allowedToNewTarget ? (
                    <div style={{ margin: "4px" }}>
                      <PlusOutlined
                        className={"circle-icon"}
                        onClick={() => this.addTarget()}
                      />
                    </div>
                  ) : null
                }
              </Row>
            </Col>
          </Row>
          {allowedToSetTargets
            ? null
            : (
              <Row
                style={{
                  backdropFilter: "blur(2px)",
                  marginBottom: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 1
                }}
              >
                <Text
                  type="h6"
                  style={{
                    color: "#225f8f",
                    textAlign: "center",
                  }}
                >
                  {i18n.t("Dashboard.Configuration.Fields.TargetsMultiColumnDescription")}
                </Text>
              </Row>
            )
          }
        </div>
        <TitleSettingsOfPlugin
          plugin={this.props.plugin}
          handleChange={this.handleChange}
          commonTitleConfig={this.props.commonTitleConfig}
          updateCommonTitleConfig={this.props.updateCommonTitleConfig}
          isReturnToDefaultforTitleVisible={this.props.isReturnToDefaultforTitleVisible}
          setDefaultsForTitle={this.setDefaultsForTitle}
          title={this.state.title}
          titleAlign={this.state.titleAlign}
          titleColour={this.state.titleColour}
          titleColourChange={this.state.titleColourChange}
          titleColourChangeVisible={this.titleColourChangeVisible}
          titleFont={this.state.titleFont}
          titleFontSize={this.state.titleFontSize}
          keyForTitleSize={this.state.keyForTitleSize}
          titleFontStyle={this.state.titleFontStyle}
          titleFontWeight={this.state.titleFontWeight}
          titleTextDecor={this.state.titleTextDecor}
          setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
          currentAppliedConfig={this.props.currentAppliedConfig}
          changedTitleFontSize={this.state.changedTitleFontSize}
          handleChangeBothOfValues={this.handleChangeBothOfValues}
        />
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Others")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left", display: "flex" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Settings.NoDataConfiguration")}
            </Text>
            <Tooltip
              title={
                <>
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Info")} <br />
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.GraphicConfiguration")} <br />
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Settings")} <br />
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.DefaultText")} <br />
                </>
              }
            >
              <InfoCircleOutlined style={{ position: 'relative', marginTop: '5px', marginLeft: "10px" }} />
            </Tooltip>
          </Col>
          <Col span={12}>
            <TextArea
              allowClear
              id={"noDataTitle"}
              defaultValue={this.state.noDataTitle}
              value={this.state.noDataTitle}
              onChange={this.handleChange}
              autoSize={{ minRows: 3, maxRows: 5 }}
              placeholder={i18n.t("NoDataContent.NoData") + " " +
                i18n.t("NoDataContent.PluginCouldNotBeVisualized")}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
