import React, { Component } from "react";
import Select from "react-select";
import i18n from "../../../Utils/i18next";

/**
 * Styled select-option component for switch dashboard area.
 */
export default class SwitchSelect extends Component {
  render() {
    const textColor = this.props.textColor || "#d2d2d2";
    const backgroundColor = this.props.backgroundColor || "#3a4044";
    const borderColor = this.props.textColor ? this.props.textColor + "55" : "#d2d2d255";

    const colourStyles = {
      control: styles => ({
        ...styles,
        backgroundColor: backgroundColor,
        color: textColor,
        borderColor: borderColor,
        boxShadow: "none",
        ":hover": {
          borderColor: borderColor,
          boxShadow: "null"
        },
        ":active": {
          borderColor: borderColor,
          boxShadow: "null"
        },
        ":focus": {
          borderColor: borderColor,
          boxShadow: "null"
        }
      }),
      singleValue: styles => {
        return {
          ...styles,
          backgroundColor: backgroundColor,
          color: textColor,
          borderColor: borderColor,
          ":hover": {
            borderColor: borderColor
          },
          ":active": {
            borderColor: borderColor
          },
          ":focus": {
            borderColor: borderColor
          }
        };
      },
      menu: styles => {
        return {
          ...styles,
          backgroundColor: backgroundColor,
          color: textColor,
          ":hover": {
            borderColor: borderColor
          },
          ":active": {
            borderColor: borderColor
          },
          ":focus": {
            borderColor: borderColor
          },

        }
      },
      menuList: styles => {
        return {
          ...styles,
          "::-webkit-scrollbar-track": {
            borderRadius: "10px",
            backgroundColor: backgroundColor
          },
          "::-webkit-scrollbar": {
            width: "3px",
            height: "3px",
            position: "absolute",
            backgroundColor: backgroundColor
          },
          "::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            position: "absolute",
            backgroundColor: textColor + "88"
          }
        }
      },
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          backgroundColor: isDisabled
            ? null
            : isSelected
              ? backgroundColor
              : isFocused,
          color: isDisabled
            ? textColor
            : isSelected
              ? textColor
              : isFocused,
          ":hover": {
            backgroundColor: textColor + "11",
            color: textColor
          },
          ":active": {
            backgroundColor: textColor + "11",
            color: textColor
          },
          ":focus": {
            backgroundColor: textColor + "11",
            color: textColor
          }
        };
      },
      dropdownIndicator: (styles) => {
        return {
          ...styles,
          color: textColor,
          ":hover": {
            color: textColor
          },
          ":active": {
            color: textColor
          },
          ":focus": {
            color: textColor
          }
        }
      },
      indicatorSeparator: (styles) => {
        return {
          ...styles,
          backgroundColor: borderColor,
          color: textColor
        }
      },
      placeholder: (styles) => {
        return {
            ...styles,
            color: textColor,
        }
      },
      input: (styles) => {
        return {
            ...styles,
            color: textColor,
        }
      },
      noOptionsMessage: (styles) => {
        return {
          ...styles,
          color: textColor
        }
      }
    };

    let labelOptions = [];
    this.props.labelList &&
      this.props.labelList.map(label =>
        labelOptions.push({ label: label.translateName, value: label.id })
      );

    return (
      <Select
        id={"switchDashboardLabelList"}
        value={this.props.selectedLabel}
        placeholder={i18n.t("Dashboard.Label.SelectLabel")}
        onChange={label => this.props.updateSelectedLabel(label)}
        options={labelOptions}
        styles={colourStyles}
      ></Select>
    );
  }
}