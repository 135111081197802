import ApexCharts from "apexcharts";
import * as d3 from "d3";
import $ from "jquery";
import Cookies from "js-cookie";
import React, { Component } from "react";
import { deepCopy } from "../../../../Utils/Global";
import i18n from "../../../../Utils/i18next";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import ConditionalFormatting from "../../../ConditionalFormatting/ConditionalFormatting";
import { compare, convertHTMLRuletoRule } from "../../../ConditionalFormatting/ConditionalFormattingCommon";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";
import { isPluginDrilldowned } from "../../../DrillDown/PluginHeightWithDrilldown";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join";
import { createTrigger } from "../../../Interaction/CreateTrigger";
import NavigationContent from "../../../Navigation/NavigationContent";
import { InsightsConfig } from "../../RenderJs/config";
import { rmvpp } from "../../RenderJs/rmvpp";
import {
  calculatePluginHeight,
  getColumnMapping,
  onComponentWillMount,
  onComponentWillReceiveProps
} from "../common";
import { getFormattedValue } from "../format";
import {
  renderConditionalFormatting,
  renderConfig,
  renderData,
  renderNavigation
} from "../PluginsCommonComponents";
import { renderContent } from "../renderContent";
import MultiAxisLineChartConfiguration from "./MultiAxisLineChartConfiguration";
import MultiAxisLineChartData from "./MultiAxisLineChartData";
import { toInteger } from "lodash";

const data = [
  {
    "postatoplam": "58443780",
    "category": "2016",
    "columns": [{ "name": "postatoplam", "value": 58443780 }],
    "hidden": [],
    "lines": []
  },
  {
    "postatoplam": "50574964",
    "category": "2015",
    "columns": [{ "name": "postatoplam", "value": 50574964 }],
    "hidden": [],
    "lines": []
  },
  {
    "postatoplam": "63270324",
    "category": "2014",
    "columns": [{ "name": "postatoplam", "value": 63270324 }],
    "hidden": [],
    "lines": []
  }
];

const config = JSON.parse(
  `{"ShowLineValueOption":true,"ColoredLineValues":false,"width":1000,"height":300,"pointSize":2,"strokeWidth":2,"legend":true,"axisNumber":"Multi","showHideButton":false,"colours":"Flat-UI","toggleCriteria":"","backgroundColor":"rgb(255,255,255)","refresh":0}`
);
const columnMap = JSON.parse(
  `{"category":{"Code":"\'ucusAlias\'.\'ay_noAlias\'","Name":"ay no","DataType":"integer","Table":"ucusAlias","Measure":"none","ID":"ucusAlias.ay no","SubjectArea":"BIGDATA","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":".0f","Config":{},"Verified":false,"Type":"Column","Description":""},"columns":[{"Code":"\'ucusAlias\'.\'bagajtoplamAlias\'","Name":"bagajtoplam","DataType":"double","Table":"ucusAlias","Measure":"sum(ucus.bagajtoplam)","ID":"ucusAlias.bagajtoplam","SubjectArea":"BIGDATA","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":".3s","Config":{},"Verified":true,"Type":"Column","Description":""}],"lines":[{"Code":"\'ucusAlias\'.\'kargotoplamDataAlias\'","Name":"kargotoplam","DataType":"double","Table":"ucusAlias","Measure":"sum(ucus.kargotoplam)","ID":"ucusAlias.kargotoplam","SubjectArea":"BIGDATA","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":".3s","Config":{},"Verified":true,"Type":"Column","Description":""}],"vary":{"Code":"","Name":"","DataType":"varchar","Table":"Unspecified","Measure":"none","ID":"Unspecified.","SubjectArea":"","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":"%s","Config":{},"Verified":false,"Type":"Column"},"hidden":[]}`
);

const condFormats = [];
const filters = [];
const pluginName = "multi-axis-line-chart";

const description =
  "Line chart visualisation for continuous data, can be used as an area chart by setting the appropriate configuration setting. Secondary Y axes can be used when there are two measures to scale them appropriately regarding their values. When a date column is used as the category property the dataset will be modified to be continuous. This means that if OBIEE is missing records for certain days, they will be assigned a value of 0.";

const configurationParameters = [
  {
    targetProperty: "ShowLineValueOption",
    label: "ShowLineValueOption",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "ShowLineValueOption"
  },
  {
    targetProperty: "ValueOnBarOption",
    label: "ValueOnBarOptionCheck",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "ValueOnBarOptionCheck"
  },
  {
    targetProperty: "ShowYAxisStylingOption",
    label: "ShowYAxisStylingOption",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "ShowYAxisStylingOption"
  },
  {
    targetProperty: "ShowXAxisStylingOption",
    label: "ShowXAxisStylingOption",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "ShowXAxisStylingOption"
  },
  {
    targetProperty: "condFormat",
    label: "CondFormat",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "CondFormat"
  },
  {
    targetProperty: "ColoredLineValues",
    label: "ColoredLineValues",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "ColoredLineValues"
  },
  {
    targetProperty: "width",
    label: "Width",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 1000
    },
    desc: "desc89"
  },
  {
    targetProperty: "height",
    label: "MultiAxisHeight",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 300
    },
    desc: "desc90"
  },
  {
    targetProperty: "pointSize",
    label: "PointSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 2
    },
    desc: "desc95"
  },
  {
    targetProperty: "strokeWidth",
    label: "StrokeWidth",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 2
    },
    desc: "desc96"
  },
  {
    targetProperty: "legend",
    label: "Legend",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "desc98"
  },
  {
    targetProperty: "axisNumber",
    label: "AxisNumber",
    inputType: "radio",
    inputOptions: {
      values: ["Multi", "Single", "Single_Stack"],
      defaultValue: "Multi"
    },
    desc: "desc108"
  },
  {
    targetProperty: "showHideButton",
    label: "Show Hide Button",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc230"
  },
  {
    targetProperty: "colours",
    label: "Renkler",
    inputType: "palette",
    inputOptions: {
      defaultValue: "Flat-UI"
    },
    desc: "desc208"
  },
  {
    targetProperty: "toggleCriteria",
    label: "ToggleCriteria",
    inputType: "textbox",
    inputOptions: {
      defaultValue: ""
    },
    desc: "desc59"
  },
  {
    targetProperty: "title",
    label: "title",
    inputType: "textbox",
    inputOptions: {
      label: "title"
    },
    desc: "desc94"
  },
  {
    targetProperty: "summary",
    label: "Summary",
    inputType: "textbox",
    inputOptions: {
      label: "Summary"
    },
    desc: "desc61"
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: {
      defaultValue: "rgb(255,255,255)"
    },
    desc: "desc62"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  },
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 15
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
  {
    targetProperty: "yAxisFontStyle",
    label: "yAxisFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "normal"
    },
    desc: "yAxisFontStyle"
  },
  {
    targetProperty: "yAxisFontStyle",
    label: "yAxisFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "yAxisFontStyle"
  },
  {
    targetProperty: "yAxisSingleTitleShow",
    label: "yAxisSingleTitleShow",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "yAxisSingleTitleShow"
  },
  {
    targetProperty: "showEllipsis",
    label: "showEllipsis",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "showEllipsis"
  },
  {
    targetProperty: "showEllipsisOnXAxis",
    label: "showEllipsisOnXAxis",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "showEllipsisOnXAxis"
  },
  {
    targetProperty: "yAxisSingleTitle",
    label: "yAxisSingleTitle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "yAxisSingleTitle"
  },
  {
    targetProperty: "yAxisFontWeight",
    label: "yAxisFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "yAxisFontWeight"
  },
  {
    targetProperty: "xAxisFontStyle",
    label: "xAxisFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "xAxisFontStyle"
  },
  {
    targetProperty: "xAxisTextDecoration",
    label: "xAxisTextDecoration",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "xAxisTextDecoration"
  },
  {
    targetProperty: "xAxisFontWeight",
    label: "xAxisFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "xAxisFontWeight"
  },
  {
    targetProperty: "targetSumOperation",
    label: "targetSumOperation",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "none"
    },
    desc: "targetSumOperation"
  },
  {
    targetProperty: "showXAxisLabels",
    label: "showXAxisLabels",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "showXAxisLabels"
  },
  {
    targetProperty: "xAxisRotate",
    label: "xAxisRotate",
    inputType: "textbox",
    inputOptions: {
      defaultValue: 45
    },
    desc: "xAxisRotate"
  },
  {
    targetProperty: "truncateXAxisLabels",
    label: "truncateXAxisLabels",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "truncateXAxisLabels"
  },
  {
    targetProperty: "hideOverlappingLabels",
    label: "hideOverlappingLabels",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "hideOverlappingLabels"
  },
  {
    targetProperty: "xAxisLabelFontSize",
    label: "xAxisLabelFontSize",
    inputType: "textbox",
    inputOptions: {
      defaultValue: 12
    },
    desc: "xAxisLabelFontSize"
  },
  {
    targetProperty: "xAxisLabelFont",
    label: "xAxisFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "xAxisFont"
  },
  {
    targetProperty: "xAxisLabelFontType",
    label: "xAxisLabelFontType",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "xAxisLabelFontType"
  },
  {
    targetProperty: "xAxisLabelDecoration",
    label: "xAxisDecoration",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "xAxisDecoration"
  },
  {
    targetProperty: "xAxisLabelFontWeight",
    label: "xAxisFontWeight",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "xAxisFontWeight"
  },
  {
    targetProperty: "xAxisLabelFontColor",
    label: "xAxisLabelFontColor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "xAxisLabelFontColor"
  },
  {
    targetProperty: "xAxisRotateIfDontFit",
    label: "xAxisRotateIfDontFit",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "xAxisRotateIfDontFit"
  }
];

const actions = [
  {
    trigger: "sectionClick",
    type: "click",
    name: "Tıklama - Bölüm",
    output: ["category", "vary", "lines"],
    description: "SectionClickDesc"
  },
  {
    trigger: "sectionHover",
    type: "hover",
    name: "Hover - Bölüm",
    output: ["category", "vary"],
    description: "SectionHover2Desc"
  },
  {
    trigger: "pointClick",
    type: "click",
    output: ["category", "vary"],
    name: "Tıklama - Nokta",
    description: "PointClickDesc"
  },
  {
    trigger: "pointHover",
    type: "hover",
    output: ["category", "vary"],
    name: "Hover - Nokta",
    description: "PointHoverDesc"
  },
  {
    trigger: "brushSelect",
    type: "brush",
    name: "Fırça Seçimi",
    output: ["category"],
    description: "BrushSelectDesc"
  }
];

const reactions = [
  {
    id: "filter",
    name: "Filtre",
    description: "desc87",
    type: "general"
  }
];

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

const pluginConditionalFormatOptions = {
  color: {
    title: i18n.t("Dashboard.ConditionalFormatting.Color"),
    type: "COLOR",
    defaultValue: "#000001"
  },
  backgroundColor: {
    title: i18n.t("Dashboard.ConditionalFormatting.BackgroundColor"),
    type: "COLOR",
    defaultValue: "#fffffe"
  }
};

const conditionalFormatColumnMap = new Set(["category", "columns", "lines", "vary"]);
const conditionalFormatTargetMap = new Set(["columns"])
const fixedBarValueFontSize = 50;

//calculates the width of the bar. Calculates min, max, automatic values according to the width of the bar
export const barValueFontSizeAutomaticFunction = (divId, automaticChecked, barValueFontSizeText) => {
  if (config.axisNumber === "Single_Stack") {
    automaticChecked = false;
  }

  let fontSizeConfig = [];
  let barWidth = 0;
  let id = $("#" + divId).find(".apexcharts-svg").attr("id")
  let containerNode = d3.select("#" + id);
  let barGraphs = containerNode
    .select(".apexcharts-bar-series")
    .selectAll(".apexcharts-series");
  if (barGraphs.length != 0 && barGraphs != undefined) {
    let filteredBarGraphs = [barGraphs[0].filter(g => $(g).hasClass("apexcharts-series-collapsed") !== true)];

    filteredBarGraphs[0].forEach(function (d, i) {
      let bars = Array.from(d.children).filter(
        element => $(element).hasClass("apexcharts-bar-area")
      );
      barWidth = bars[0].attributes.barWidth.nodeValue;
    });
  }

  if (barWidth <= 100) {
    fontSizeConfig.push({
      barValueFontSize: automaticChecked ? Math.round(barWidth * (0.50)) : barValueFontSizeText,
      barValueFontSizeMax: Math.round(barWidth * (0.60)),
      barValueFontSizeMin: Math.round(barWidth * (0.15)),
      showBarValueDisable: barWidth <= 5 ? true : false,
      warning: "default"
    });
  } else {
    if (Math.round(barWidth * (0.20)) <= fixedBarValueFontSize) {
      fontSizeConfig.push({
        barValueFontSize: automaticChecked ? Math.round(barWidth * (0.20)) : barValueFontSizeText,
        barValueFontSizeMax: Math.round(barWidth * (0.30)),
        barValueFontSizeMin: Math.round(barWidth * (0.10)),
        showBarValueDisable: false,
        warning: "default"
      });
    } else {
      fontSizeConfig.push({
        barValueFontSize: automaticChecked ? fixedBarValueFontSize : barValueFontSizeText,
        barValueFontSizeMax: 60,
        barValueFontSizeMin: 20,
        showBarValueDisable: false,
        warning: "default"
      });
    }
  }

  if (fontSizeConfig[0].barValueFontSize > fontSizeConfig[0].barValueFontSizeMax) {
    fontSizeConfig[0].barValueFontSize = fontSizeConfig[0].barValueFontSizeMax;
  }

  if (automaticChecked === false) {
    if (barValueFontSizeText > fontSizeConfig[0].barValueFontSizeMax) {
      fontSizeConfig[0].warning = "* " + i18n.t("Dashboard.Configuration.Fields.CantChangeMeasureSize", { size: Number(fontSizeConfig[0].barValueFontSizeMax) });
    } else if (barValueFontSizeText < fontSizeConfig[0].barValueFontSizeMin) {
      fontSizeConfig[0].warning = "* " + i18n.t("Dashboard.Configuration.Fields.CantReduceMeasureSize", { size: Number(fontSizeConfig[0].barValueFontSizeMin) });
    }
  }

  return fontSizeConfig[0];
};

export default class MultiAxisLineChart extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      defaultConditionalFormatColumn: {},
      isNecessaryAllColumns: true,
      isLockedTargetValue: false,
    })
    this.rerenderProcessStarted = false;
    this.callBackObject = {};
    this.columnMap = {};
    this.drillDowns = {};
  }

  calculatePluginHeight = plugin => {
    let containerHeight = plugin.containerHeight;
    let pluginTitleContainer = $("#title-" + plugin.id);
    let pluginContainerBorder = -2;
    let pluginMinHeightDifference = 125;
    let maxHeight =
      containerHeight -
      (pluginTitleContainer.outerHeight() +
        parseInt(pluginTitleContainer.css("margin-bottom")) +
        pluginContainerBorder +
        pluginMinHeightDifference);
    return maxHeight;
  };

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    this.drillDowns = this.props.plugin.drillDowns;

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  /**
   * 
   * @param {*} e 
   * @returns 
   * 
   * According to the incoming event, it finds the clicked column and returns it.
   */
  getClickedColumn = () => {
    let column = this.columnMap["category"];

    return [column];
  };

  /**
 *
 * @param {*} displayName
 * this method is used to convert special character to normal character
 */
  convertSpecialCharacter = (displayName) => {
    if (displayName.includes("&")) {
      displayName = displayName.replace(/&/g, "&amp;");
    }

    if (displayName.includes("'")) {
      displayName = displayName.replace(/'/g, "&#39;");
    }

    return displayName;
  };

  /**
   *
   * @param {*} data
   * @returns
   * Arranges the clicked data for trigger operation
   */
  getConvertData = (data) => {
    let columns = [];
    let rows = [];

    for (let item in data) {
      if (item.includes("columns")) {
        let index = item.split("columns")[1];
        let columnName = this.columnMap["columns"][index].name;
        let column = { name: columnName, value: data[item] };

        columns.push(column);
      } else if (item.includes("rows")) {
        if (this.columnMap["rows"].length > 0) {
          let index = item.split("rows")[1];
          let columnName = this.columnMap["rows"][index].name;
          let column = { name: columnName, value: data[item] };

          rows.push(column);
        }
      }
    }

    return { columns: columns, rows: rows };
  };

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({})

    return tmpCallBackObject;
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );

    if (this.props.plugin.drillDowns !== nextProps.plugin.drillDowns) {
      this.drillDowns = this.props.plugin.drillDowns;
    }
  }

  getNavigationComponent = props => {
    return (
      <NavigationContent
        navigations={this.props.navigations}
        setNavigations={this.props.updatePlugin}
        plugin={this.props.plugin}
        dashboardInformation={this.props.dashboardInformation}
      />
    );
  };

  getConfigComponent = props => {
    if (props.config) {
      return (
        <MultiAxisLineChartConfiguration
          config={{ ...props.config }}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
          currentAppliedConfig={this.props.currentAppliedConfig}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  getConditionalFormattingComponent = props => {
    let columnMap = this.props.plugin.columnMap

    // Eski silinen kolonlar id ile geldiği için comment yapılmıştır. #1112 issue. 
    // let hasColumnMapAnyColumn = findColumnMapHasAnyColumn(props.plugin.columnMap)
    // let isPluginNewPlugin = this.props.isNewPlugin ? true : false

    // if (hasColumnMapAnyColumn) {
    //   if (isPluginNewPlugin) {
    //     columnMap = props.plugin.columnMap
    //   } else {

    //     columnMap = this.props.columnMapWithLocationFieldName
    //   }
    // } else {
    //   columnMap = props.plugin.columnMap
    // }

    return (
      <ConditionalFormatting
        pluginConditionalFormatOptions={pluginConditionalFormatOptions}
        conditionalFormatColumnMap={conditionalFormatColumnMap}
        pluginId={props.plugin.id}
        conditionalFormatTargetMap={conditionalFormatTargetMap}
        conditionalFormats={props.plugin.conditionalFormats}
        columnMap={columnMap}
        updateConditionalFormat={props.updatePlugin}
        isNecessaryAllColumns={this.state.isNecessaryAllColumns}
        defaultConditionalFormatColumn={this.state.defaultConditionalFormatColumn}
        isLockedTargetValue={this.state.isLockedTargetValue}
      />
    );
  };

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }

    return (
      <MultiAxisLineChartData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        didNotJoinedTables={checkTableJoins(this.props.join, this.props.plugin.columnMap, this.props.refreshedPluginId, this.props.plugin.id, true)}
        setInteractions={this.props.setInteractions}
        interactions={this.props.interactions}
        navigations={this.props.navigations}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={props.changeDoesPluginHasNotJoinedTable}
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        plugin={props.plugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      category: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Category.Name"),
        type: "dim",
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Category.Desc"),
        required: true,
        minimumColumnSize: 1,
        data: []
      },
      columns: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Columns.Name"),
        multiple: true,
        minimumColumnSize: 1,
        dependentColumns: [
          i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Lines.Name")
        ],
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Columns.Desc"),
        type: "fact",
        required: true,
        conditionalFormat: true,
        data: []
      },
      lines: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Lines.Name"),
        multiple: true,
        minimumColumnSize: 1,
        dependentColumns: [
          i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Columns.Name")
        ],
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Lines.Desc"),
        type: "fact",
        required: true,
        conditionalFormat: true,
        data: []
      },
      vary: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Vary.Name"),
        type: "dim",
        minimumColumnSize: 0,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Vary.Desc"),
        data: []
      },
      hidden: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Hidden.Name"),
        multiple: true,
        minimumColumnSize: 0,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Hidden.Desc"),
        type: "hidden",
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  /**
   * Returns array,
   * Get data and find all vary with order.
   */
  createOrderedVaryList = (data, varyLength) => {
    let varyOrderedSet = new Set();

    for (let i = 0; i < data.length; i++) {
      let value = data[i];
      varyOrderedSet.add(value.vary)

      if (varyOrderedSet.size === varyLength) {
        break;
      }
    }

    return [...varyOrderedSet];
  }

  /**
   * To convert general conditional format object to plugin requirements.
   */
  convertFormatConditionalFormatting = (condFormats, columns, category, vary, lines) => {
    let condFormatList = [];
    let columnForConditionalFormatting = [...columns]

    let columnsMap = new Map();
    columnForConditionalFormatting.push(category);
    columnForConditionalFormatting.push(vary);

    lines.map(line => {
      columnForConditionalFormatting.push(line)
    })

    columnForConditionalFormatting.map(column => {
      columnsMap.set(column.uniqeColumnId, column);
    });

    condFormats.map(condItem => {
      condItem.targetColumns.map(targetColumn => {
        let column = columnsMap?.get(targetColumn?.uniqeColumnId);

        if (column) {
          let conditionalFormat = {};
          let controlTargetIsAllColumns = targetColumn.TargetName === "AllColumns" ? true : false

          conditionalFormat.RightRule = condItem.rule.rightRule.rule;
          conditionalFormat.LeftRule = condItem.rule.leftRule.rule;
          conditionalFormat.LeftRuleColumnName = condItem.rule.leftRule.ruleColumnName === "{undefined}" ? convertHTMLRuletoRule(condItem.rule.leftRule).ruleColumnName : condItem.rule.leftRule.ruleColumnName;
          conditionalFormat.RightRuleColumnName = condItem.rule.rightRule.ruleColumnName;
          conditionalFormat.Columns = columnsMap;
          conditionalFormat.TargetName = controlTargetIsAllColumns ? targetColumn.TargetName : column.displayName;
          conditionalFormat.TargetAliasName = controlTargetIsAllColumns ? targetColumn.TargetName : column.aliasName;
          conditionalFormat.LocationFieldName = targetColumn.locationFieldName;
          conditionalFormat.Operator = condItem.rule.operator;
          conditionalFormat.id = condItem.id;

          conditionalFormat.Style = {
            background: { colour: condItem.options.backgroundColor },
            textColor: condItem.options.color
          };

          condFormatList.push(conditionalFormat);
        }
      });
    });
    return condFormatList;
  };

  // Sets values by data
  setValuesByData = (calculatedDataArea, config) => {
    for (let j = 0; j < calculatedDataArea.length; j++) {
      let perDataColumn = calculatedDataArea[j]
      let targetSumOperation = config.targetSumOperation

      if (Array.isArray(perDataColumn.values)) {
        if (targetSumOperation === "average") {
          perDataColumn.value = perDataColumn.value / perDataColumn.values.length
        } else if (targetSumOperation === "min") {
          perDataColumn.value = perDataColumn.values.sort((a, b) => a - b)[0]
        } else if (targetSumOperation === "max") {
          perDataColumn.value = perDataColumn.values.sort((a, b) => b - a)[0]
        } else if (targetSumOperation === "count") {
          perDataColumn.value = perDataColumn.values.length
        }
      } else {
        if (targetSumOperation === "count") {
          perDataColumn.value = 1
        }
      }
    }
  }

  // Groups data by field
  groupDataObjectsByField = (groupDataBy, copiedData) => {
    for (let j = 0; j < groupDataBy.length; j++) {          
      if (groupDataBy[j].values == undefined) {
        groupDataBy[j].values = [groupDataBy[j].value]
        groupDataBy[j].values.push(copiedData[j].value)
      } else {
        groupDataBy[j].values.push(copiedData[j].value)
      }

      groupDataBy[j].value += copiedData[j].value
    }
  }


  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {
    let calculatedDataHashMap = new Map()
    let copiedData = deepCopy(data)

    if (config.targetSumOperation !== "none" && config.targetSumOperation !== undefined) { 
      for (let i = 0; i < copiedData.length; i++) {
        let categoryName = copiedData[i]["category"] ? copiedData[i]["category"] : ""
        let varyName = copiedData[i]["vary"] ? copiedData[i]["vary"] : ""
  
        let allName = `${categoryName} ${varyName}`
  
        if (!calculatedDataHashMap.has(allName)) {
          calculatedDataHashMap.set(allName, copiedData[i])
        } else if (calculatedDataHashMap.has(allName)) {
          let gettedObject = calculatedDataHashMap.get(allName)
  
          this.groupDataObjectsByField(gettedObject.columns, copiedData[i].columns) // Groups data by column field
          this.groupDataObjectsByField(gettedObject.lines, copiedData[i].lines) // Groups data by lines field
  
          calculatedDataHashMap.set(allName, gettedObject)
        }
      }
  
      let calculatedData = Array.from(calculatedDataHashMap.values())
  
      if (config.targetSumOperation !== "sum" && config.targetSumOperation !== undefined) {
        for (let i = 0; i < calculatedData.length; i++) {
          this.setValuesByData(calculatedData[i].columns, config) // Set and calculates data for column
          this.setValuesByData(calculatedData[i].lines, config) // Set and calculates data for line
        }
      }
  
      data = calculatedData
    }

    if (condFormats !== undefined) {
      condFormats = this.convertFormatConditionalFormatting(
        condFormats,
        columnMap.columns,
        columnMap.category,
        columnMap.vary,
        columnMap.lines
      );
      this.setState({ condFormats })
    } else {
      condFormats = [];
    }

    let isColumnsNotUndefined = columnMap.columns !== undefined ? true : false
    let isColumnsNull = columnMap.columns !== null ? true : false

    if (columnMap.columns.length > 0) {
      let isFirstColumnHasUniqueId = columnMap.columns[0].uniqeColumnId !== undefined ? true : false
      let isColumnsReadyToConditionalFormatting = isColumnsNotUndefined && isColumnsNull && isFirstColumnHasUniqueId ? true : false

      /* If columns has one column, target column locked. */
      if (isColumnsReadyToConditionalFormatting) {
        if (columnMap.columns.length === 1) {
          this.setState({
            defaultConditionalFormatColumn: columnMap.columns[0],
            isLockedTargetValue: true
          })
        } else if (columnMap.columns.length >= 2) {
          this.setState({
            defaultConditionalFormatColumn: [],
            isLockedTargetValue: false
          })
        }
      }
    }

    let dataMap = new Map()
    let dataMapForTextColour = new Map()

    /* Prepares per column name to maxis bar class name */
    let prepareColName = (colName) => {
      let strColName;

      if (!isNaN(colName)) {
        strColName = colName.toString();
      } else {
        strColName = colName;
      }

      let colNamesWithoutSpeacialCharacters = strColName.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, 'x');

      if (colNamesWithoutSpeacialCharacters.split(" ").length > 1) {
        let splittedColName = colNamesWithoutSpeacialCharacters.split(" ");
        let joinedColNameWithX = splittedColName.join("x");

        return joinedColNameWithX;
      } else {
        return colNamesWithoutSpeacialCharacters;
      }
    }

    /* Prepares map object maxis and sets by className format */
    for (let perData = 0; perData < data.length; perData++) {
      for (let perLine = 0; perLine < data[0].lines.length; perLine++) {
        data[perData][data[perData].lines[perLine].name] = data[perData].lines[perLine].value
      }

      for (let perColumn = 0; perColumn < data[0].columns.length; perColumn++) {
        data[perData][data[perData].columns[perColumn].name] = data[perData].columns[perColumn].value
        data[perData][columnMap.category.Name] = data[perData].category

        if (columnMap?.vary?.data?.length) {
          data[perData][columnMap.vary.Name] = data[perData].vary
          let preparedVaryName = prepareColName(data[perData].vary)
          let preparedColName = prepareColName(data[perData].columns[perColumn].name)
          let preparedMapName = preparedColName.concat("xxx", preparedVaryName).concat(`-${data[perData].columns[perColumn].value}`)

          dataMap.set(`${preparedMapName}`, data[perData])
        } else {
          let currentCol = data[perData].columns[perColumn]
          let preparedColName = prepareColName(currentCol.name)

          dataMap.set(`${preparedColName}-${currentCol.value}`, data[perData])
        }
      }
    }

    /* Gets per data and controls conditional formatting */
    function addCondForm() {
      let containerNode;
      let id = $("#" + divId).find(".apexcharts-svg").attr("id")
      containerNode = d3.select("#" + id);

      let barGraphs = containerNode
        .select(".apexcharts-bar-series")
        .selectAll(".apexcharts-series");

      /* Loops all bar graphs and check has conditional formatting */
      if (barGraphs.length !== 0 && barGraphs[0].length !== 0) {
        barGraphs[0].forEach(function (d, i) {
          let bars = Array.from(d.children).filter(
            element => $(element).hasClass("apexcharts-bar-area")
          );

          bars.map(function (n, j) {
            let currentBar = dataMap.get(`${$(n).parent().attr("seriesName")}-${$(n).attr("val")}`)

            condFormats.forEach(function (cf) {
              let comparedObj = compare(currentBar, cf)

              if (comparedObj.status) {
                let isSeriesEqualTarget = $(n).parent().attr("seriesName") === prepareColName(cf.TargetName) ? true : false
                let isPreparedSeriesEqualTarget = $(n).parent().attr("seriesName").split("xxx")[0] === prepareColName(cf.TargetName) ? true : false
                let isPreparedSeriesEqualTargetAlias = $(n).parent().attr("seriesName").split("xxx")[0] === prepareColName(cf.TargetAliasName) ? true : false
                let isTargetEqualAllColumns = cf.TargetName === "AllColumns" ? true : false

                if (isSeriesEqualTarget || isPreparedSeriesEqualTarget || isPreparedSeriesEqualTargetAlias) {
                  $(n).attr("fill", rmvpp.reduceOpacity(cf.Style.background.colour, 0.2))
                  $(n).attr("stroke", cf.Style.background.colour)
                  dataMapForTextColour.set(`${$(n).parent().attr("seriesName")}-${$(n).attr("val")}`, cf.Style.textColor)
                } else if (isTargetEqualAllColumns) {
                  $(n).attr("fill", rmvpp.reduceOpacity(cf.Style.background.colour, 0.2))
                  $(n).attr("stroke", cf.Style.background.colour)
                  dataMapForTextColour.set(`${$(n).parent().attr("seriesName")}-${$(n).attr("val")}`, cf.Style.textColor)
                }
              }
            })
          });
        })
      }
    }

    /** USE apexchart ~3.22.2 - IMPORTANT */
    let isStrokeWidthZero = config.strokeWidth === 0 ? true : false;
    let isStrokeWidthEmpty = config.strokeWidth === "" ? true : false;
    let isStrokeWidthNull = config.strokeWidth == null ? true : false;
    let isStrokeWidthExist = config.strokeWidth == undefined ? true : false;

    if (isStrokeWidthZero) {
      config.strokeWidth = -1; //if we give strokewidth 0, we don't want to see line.
    }

    if (isStrokeWidthEmpty || isStrokeWidthNull || isStrokeWidthExist) {
      config.strokeWidth = 1;
    }

    let isPointSizeZero = config.pointSize == 0 ? true : false;
    let isPointSizeExist = config.pointSize == undefined ? true : false;
    let isPointSizeNull = config.pointSize == null ? true : false;
    let isPointSizeEmpty = config.pointSize === "" ? true : false;

    if (
      isPointSizeZero ||
      isPointSizeExist ||
      isPointSizeNull ||
      isPointSizeEmpty
    ) {
      config.pointSize = 1;
    }

    //show bar value and line value default value
    let defaultFont = "Verdana";
    let defaultFontSize = 13;
    let defaultFontColor = "black";
    let defaultAngle = 0;

    var THIS = this;
    // Set html empty and set this html to container.
    $("#" + divId).html("");
    let container = $("#" + divId)[0];
    this.columnMap = columnMap;

    // Get width and height variables based on gridScope
    let visWidth = "100%";
    let visHeight = config.height + "px";

    // id name must be unique, 1000 indicates that percentage (%0.1) of conflict
    let idName = "multiaxisline" + Math.ceil(Math.random() * 1000);

    $(container).css("background-color", config.backgroundColor);

    let name = Cookies.get("i18next"); //get locale from cookie
    let locale = {
      name: name,
      options: {
        months: i18n.t(
          '"Plugins.multi-axis-line-chart.ColumnMap.Tooltip.Months"',
          {
            returnObjects: true
          }
        ),
        shortMonths: i18n.t(
          "Plugins.multi-axis-line-chart.ColumnMap.Tooltip.ShortMonths",
          {
            returnObjects: true
          }
        ),
        days: i18n.t("Plugins.multi-axis-line-chart.ColumnMap.Tooltip.Days", {
          returnObjects: true
        }),
        shortDays: i18n.t(
          "Plugins.multi-axis-line-chart.ColumnMap.Tooltip.ShortDays",
          {
            returnObjects: true
          }
        ),
        toolbar: {
          exportToSVG: i18n.t(
            "Plugins.multi-axis-line-chart.ColumnMap.Tooltip.ExportToSVG"
          ),
          exportToPNG: i18n.t(
            "Plugins.multi-axis-line-chart.ColumnMap.Tooltip.ExportToPNG"
          ),
          downloadCSV: i18n.t(
            "Plugins.multi-axis-line-chart.ColumnMap.Tooltip.DownloadSCV"
          ),
          menu: i18n.t("Plugins.multi-axis-line-chart.ColumnMap.Tooltip.Menu"),
          selection: i18n.t(
            "Plugins.multi-axis-line-chart.ColumnMap.Tooltip.Selection"
          ),
          selectionZoom: i18n.t(
            "Plugins.multi-axis-line-chart.ColumnMap.Tooltip.SelectionZoom"
          ),
          zoomIn: i18n.t(
            "Plugins.multi-axis-line-chart.ColumnMap.Tooltip.ZoomIn"
          ),
          zoomOut: i18n.t(
            "Plugins.multi-axis-line-chart.ColumnMap.Tooltip.ZoomOut"
          ),
          pan: i18n.t("Plugins.multi-axis-line-chart.ColumnMap.Tooltip.Pan"),
          reset: i18n.t("Plugins.multi-axis-line-chart.ColumnMap.Tooltip.Reset")
        }
      }
    };

    $(container).append(
      '<div id="' +
      idName +
      '" class="multiaxisline"' +
      ' style="height: ' +
      visHeight +
      "; width: " +
      visWidth +
      '; overflow-x: hidden;"></div>'
    );
    var chartColors = [];

    if (InsightsConfig.Palettes[config.colours] != undefined) {
      chartColors = InsightsConfig.Palettes[config.colours];
    } else {
      chartColors = config.colours;
    }

    let cat = columnMap.category;
    let formatterCounter = 0;
    let formatterCounterColumn = 0;
    let formatterCounterLine = 0;
    let varyLength =
      data[0].vary != undefined
        ? [...new Set(data.map(m => m.vary))].length
        : 1; //In order to reflect the interactions correctly, from the data [0], the number of vary is calculated by looking at all the data and returned. Returns 1 if Vary is not used

    let varyOrderedArray = this.createOrderedVaryList(data, varyLength)

    /*
    * Calculates height with drilldown or without drilldown
    */
    const calculateHeight = () => {
      let hasMultiAxisHasBreadcrumb = isPluginDrilldowned(this.props.plugin.id)
      let height = hasMultiAxisHasBreadcrumb ? config.height - (25 + 37) : config.height - 25 //-25 height for fit conditional format legends and 37 for breadcrumb height

      $(`#${idName}`).css("height", height)

      return height
    }

    let options = {
      chart: {
        height: config.condFormat ? calculateHeight() : calculateHeight() + 25,
        stacked: (config.axisNumber === "Single_Stack") ? true : false,
        locales: [locale], //localization settings
        defaultLocale: name,
        events: {
          dataPointSelection: function (event, chartContext, config) {
            // If not left click, return.
            if (event.which !== 1) return;

            let mousePosition = { x: event.pageX, y: event.pageY }
            let filterValue = getFilterValueForCreateTrigger(data, config, varyOrderedArray);
            let clickedColumn = THIS.getClickedColumn(filterValue);

            if ($(event.currentTarget).has(".apexcharts-bar-area")) {
              // Trigger section click
              createTrigger(
                actions,
                columnMap,
                container,
                "sectionClick",
                filterValue,
                THIS.props.plugin.id,
                THIS.props.interactions,
                THIS.props.navigations,
                mousePosition,
                null,
                THIS.drillDowns,
                clickedColumn,
                THIS.props.plugin,
                THIS.props.model
              );
            } else if ($(event.currentTarget).has(".apexcharts-marker")) {
              // Trigger point click
              createTrigger(
                actions,
                columnMap,
                container,
                "pointClick",
                filterValue,
                THIS.props.plugin.id,
                THIS.props.interactions,
                THIS.props.navigations,
                mousePosition,
                null,
                THIS.drillDowns,
                clickedColumn,
                THIS.props.plugin,
                THIS.props.model
              );
            }            
          },
          dataPointMouseEnter: function (event, chartContext, config) {
            let mousePosition = { x: event.pageX, y: event.pageY }
            let filterValue = getFilterValueForCreateTrigger(data, config, varyOrderedArray)

            if ($(event.currentTarget).has(".apexcharts-bar-area")) {
              // Trigger point hover
              createTrigger(
                actions,
                columnMap,
                container,
                "pointHover",
                filterValue,
                THIS.props.plugin.id,
                THIS.props.interactions,
                THIS.props.navigations,
                mousePosition
              );
            } else if ($(event.currentTarget).has(".apexcharts-marker")) {
              // Trigger section hover
              createTrigger(
                actions,
                columnMap,
                container,
                "sectionHover",
                filterValue,
                THIS.props.plugin.id,
                THIS.props.interactions,
                THIS.props.navigations,
                mousePosition
              );
            }
          },

          updated: function () {
            if (config.title === "") {
              $("#" + divId).find('.apexcharts-toolbar').css('margin-top', '30px');
            }

            valueOnLine();
            valueOnBarPos();
            addCondForm();
            addCondFormLegend();
            addCondFormLegendInTooltip();
            calculateXAxisConfig();
            calculateYAxisConfig();
          },
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: config.legend,
        showForSingleSeries: true,
        offsetX: 0,
        offsetY: 0
      },
      markers: {
        size: config.pointSize
      },
      colors: chartColors,
      xaxis: {
        title: {
          text: columnMap.category.Name,
          margin: 10,
          style: {
            color: config.xAxisFontColor,
            fontSize: config.xAxisFontSize,
            fontFamily: config.xAxisFont,
            fontWeight: config.xAxisFontWeight ? "600" : "100",
          }
        },
        type: "",
        categories: "",
        labels: {
          show: config.showXAxisLabels !== false,
          rotate: config.xAxisRotate !== undefined ? (-1 * config.xAxisRotate) : -45,
          rotateAlways: config.xAxisRotateIfDontFit === false,
          trim: config.truncateXAxisLabels !== false,
          hideOverlappingLabels: config.hideOverlappingLabels !== false,
          style: {
            fontSize: config.xAxisLabelFontSize === undefined ? 12 : Math.min(Math.max(1, config.xAxisLabelFontSize), 30),
            fontFamily: config.xAxisLabelFont,
            fontWeight: config.xAxisLabelFontWeight ? "600" : "100",
          },
          formatter: value => {
            return value
          }
        },
        tooltip: {
          enabled: false
        }
      },
      series: [],
      yaxis: {},
      tooltip: {
        theme: "dark",
        y: {},
        x: {}
      }
    };

    /* Adds conditional format legends to maxis container */
    function addCondFormLegend() {
      if (config.condFormat && (THIS.props.plugin.h >= 5 || THIS.props.plugin.w >= 5)) {
        let showCondForm = $('<div id="showCondForm" style="display:flex; position:relative; bottom:15px; flex-wrap:wrap; justify-content:center;"></div>')

        if ($(container).find("#showCondForm").length === 0) {
          condFormats.forEach(function (d, i) {
            let condLegend = document.createElement("div");
            condLegend.setAttribute("style", "margin: 0px 5px; display: flex;");

            let condLegendMarker = document.createElement("div");
            condLegendMarker.setAttribute("style", "background: " + d.Style.background.colour + "; color:" + d.Style.background.colour + "; height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px; margin-right: 2px; border: 1px solid white;");

            let condLegendMarkerForTextColor = document.createElement("div");
            condLegendMarkerForTextColor.setAttribute("style", "background: " + d.Style.textColor + "; color:" + d.Style.textColor + "; height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px; margin-right: 2px; border: 1px solid white;");

            let condLegendText = document.createElement("span");
            condLegendText.setAttribute("class", "apexcharts-legend-text");
            condLegendText.setAttribute("style", "color: rgb(55, 61, 63); font-size: 12px; font-family: Helvetica, Arial, sans-serif;");

            let ruleDescription = THIS.props.plugin.conditionalFormats[i]?.rule?.conditionalFormatRule;
            // if rule description is defined in text area, legend will use user input.

            let textNode = document.createTextNode(
              ruleDescription ? "\u00A0" + ruleDescription : "\u00A0" + d.LeftRuleColumnName + " " + d.Operator + " " + d.RightRuleColumnName + ""
            );

            condLegendText.appendChild(textNode);
            condLegend.appendChild(condLegendMarkerForTextColor);
            condLegend.appendChild(condLegendMarker);
            condLegend.appendChild(condLegendText);

            showCondForm.append(condLegend);
          })

          $(container).append(showCondForm);
        }
      }
    }

    /* Adds conditional format legends to maxis tooltip */
    function addCondFormLegendInTooltip() {
      condFormats.forEach(function (d) {
        let condLegendTooltip = document.createElement("div");
        condLegendTooltip.setAttribute("class", "apexcharts-tooltip-series-group");
        $(condLegendTooltip).css("order", "99").css("margin-bottom", "7px")

        let condLegendMarkerTooltip = document.createElement("div");
        condLegendMarkerTooltip.setAttribute("class", "apexcharts-tooltip-marker");
        condLegendMarkerTooltip.setAttribute("style", "background: " + d.Style.background.colour + "; color:" + d.Style.colour + "; height: 12px; width: 12px; left: 0px; top: 0px; border-width: 12px; border-color: rgb(255, 255, 255); border-radius: 12px; ; border: 1px solid white;");

        let condLegendTextTooltip = document.createElement("span");
        condLegendTextTooltip.setAttribute("class", "apexcharts-tooltip-text");
        condLegendTextTooltip.setAttribute("style", "color: rgb(255, 255, 255); font-size: 12px; font-family: Helvetica, sans-serif;");

        let textNodeTooltip = document.createTextNode(d.LeftRuleColumnName + " " + d.Operator + " " + d.RightRuleColumnName);
        condLegendTextTooltip.appendChild(textNodeTooltip);

        condLegendTooltip.appendChild(condLegendMarkerTooltip);
        condLegendTooltip.appendChild(condLegendTextTooltip);

        $(container).find(".apexcharts-tooltip").append(condLegendTooltip);
      })
    }

    /* returns formatted data according to timestamp dataFormat */
    function timestampFormattedData(dataFormat, year, month, day, hour, minute, second) {
      let newTime = dataFormat.replace("%Y", year);

      newTime = newTime.replace("%m", month);
      newTime = newTime.replace("%d", day);
      newTime = newTime.replace("%H", hour);
      newTime = newTime.replace("%M", minute);
      newTime = newTime.replace("%S", second);

      return newTime;
    }

    /* changes category values in data according to format type */
    function convertDataToFormattedData() {
      let cat = columnMap.category;

      if (
        cat.DataType.includes("timestamp") ||
        cat.DataType.includes("date")
      ) {
        for (let i = 0; i < data.length; i++) {
          const date = data[i].category.split(" ")[0];
          let time = data[i].category.split(" ")[1];
          let year = date.split("-")[0];
          let month = date.split("-")[1];
          let day = date.split("-")[2];

          if (time === undefined) {
            let newDate = cat.DataFormat.replace("%Y", year);

            newDate = newDate.replace("%m", month);
            newDate = newDate.replace("%d", day);
            formattedData.push(newDate);
          } else {
            isTimestamp = true;
            time = time.split(".")[0];

            let hour = time.split(":")[0];
            let minute = time.split(":")[1];
            let second = time.split(":")[2];

            formattedData.push(timestampFormattedData(cat.DataFormat, year, month, day, hour, minute, second));
          }
        }
      } else {
        for (let i = 0; i < data.length; i++) {
          formattedData.push(getFormattedValue(cat, data[i].category));
        }
      }
    }

    let dataSeries = [];
    let yAxisConfig = [];

    function setyAxisConfig(name) {
      /**
      Truncates the given text if it is longer than the given limit
      @param {*} text
      @param {*} limit
      @returns
      */
      let truncateText = (text, limit) => {
        if (text.length > limit) {
          return `${text.substring(0, limit - 3)}...`;
        }
        return text;
      }

      let truncateLimit = config.height / config.yAxisFontSize;

      if (config.axisNumber === "Multi") {
        let dataMap = columnMap.columns.filter(column => {
          return column.Name === name;
        })[0];

        if (dataMap == undefined || dataMap == null) {
          dataMap = columnMap.lines.filter(line => {
            return line.Name === name;
          })[0];
        }

        yAxisConfig.push({
          axisTicks: {
            show: false
          },
          opposite: yAxisConfig.length % 2 == 1,
          axisBorder: {
            show: true,
            color: config.colours[yAxisConfig.length]
          },
          labels: {
            style: {
              color: config.colours[yAxisConfig.length],
              top: config.height
            },
            formatter: function (value) {
              return dataMap ? getFormattedValue(dataMap, value, dataMap.DataFormat) : value;
            }
          },
          title: {
            text: truncateText(name, truncateLimit),
            style: {
              color: config.yAxisFontColor,
              fontSize: config.yAxisFontSize,
              fontFamily: config.yAxisFont,
              fontWeight: config.yAxisFontWeight ? "600" : "100",
            }
          },
          min: 0, // Same as single section, min assign zero because data not include negative column-value
          forceNiceScale: true
        });
      } else if (
        config.axisNumber === "Single" ||
        config.axisNumber === "Single_Stack"
      ) {
        let dataMap = columnMap.columns[0];

        if (dataMap == undefined || dataMap === null) {
          dataMap = columnMap.lines[0];
        }

        //To retrieve each value belonging to the name key and format them with spaces in between.
        const renderColumnNames = (array) => {
          if (array && array.length > 0) {

            return array.map((item, index) => item.Name).join(" ");
          }
          return null;
        };

        //To merge both values in one sentence and add spaces in between
        const getTitleText = () => {
          let MeasureText = renderColumnNames(columnMap.columns) + '  ' + renderColumnNames(columnMap.lines);

          return truncateText(MeasureText, truncateLimit)
        };

        yAxisConfig = {
          axisTicks: {
            show: false
          },
          opposite: false,
          axisBorder: {
            show: true,
            color: config.colours[yAxisConfig.length]
          },
          labels: {
            style: {
              color: config.colours[yAxisConfig.length]
            },
            formatter: function (value) {
              return getFormattedValue(dataMap, value, dataMap.DataFormat);
            }
          },
          title: {
            text: config.yAxisSingleTitleShow ? getTitleText() : config.yAxisSingleTitle ? truncateText(config.yAxisSingleTitle, truncateLimit) : "",
            style: {
              color: config.yAxisFontColor,
              fontSize: config.yAxisFontSize,
              fontFamily: config.yAxisFont,
              fontWeight: config.yAxisFontWeight ? "600" : "100",
            },
            min: 0,
            forceNiceScale: true,
          }
        };
      };
    }

    function setArrayToMaxLength(data) {
      //find max length data
      let maxLengthData = 0;
      data.map(element => {
        if (element.data.length > maxLengthData) {
          maxLengthData = element.data.length;
        }
      });

      //data length must be equal
      data.map(element => {
        if (element.data.length != maxLengthData) {
          let emptyArrayForEqualSize = new Array(
            maxLengthData - element.data.length
          ).fill(null);

          element.data = element.data.concat(emptyArrayForEqualSize);
        }

        for (let i = 0; i < element.data.length; i++) {
          if (element.data[i] === undefined && element.type === "bar") {
            element.data[i] = null;
          } else if (element.data[i] === undefined && element.type === "line") {
            element.data[i] = 0;
          }
        }

      });
    }

    let lineDataInfo = [];
    let columnDataInfo = [];
    function dataSerie(data, type) {
      if (columnMap.category.Name == columnMap.vary.Name) {
        data.map(element => {
          element[type].map(item => {
            columnDataInfo.push({
              name: item.name,
              data: item.value,
              cat: element.category
            });

            lineDataInfo.push({
              name: item.name,
              data: item.value,
              cat: element.category
            });
          });
        });
      } else {
        data.map(element => {
          element[type].map(item => {
            columnDataInfo.push({
              name:
                element.vary != undefined
                  ? item.name + " - " + getFormattedValue(columnMap.vary, element.vary)
                  : item.name,
              data: item.value,
              cat: element.category
            });

            lineDataInfo.push({
              name:
                element.vary != undefined
                  ? item.name + " - " + getFormattedValue(columnMap.vary, element.vary)
                  : item.name,
              data: item.value,
              cat: element.category
            });
          });
        });
      }
    }

    function setDataSeries(dataInfo, dataType) {
      let categories = [...new Set(dataInfo.map(m => m.cat))];
      let dataSet = {};

      dataInfo.map(item => {
        let emptyArray = new Array();

        if (dataSet[item.name] == undefined) {
          dataSet[item.name] = emptyArray;
        }

        let index = categories.indexOf(item.cat);
        let values = dataSet[item.name]

        values[index] = (item.data);

        dataSet[item.name] = values
      });

      Object.keys(dataSet).map(key => {
        dataSeries.push({
          name: key,
          type: dataType == "columns" ? "bar" : "line",
          data: dataSet[key]
        });

        // Exclude name of vary column
        let elementName = key.substr(0, key.indexOf(" -"));
        setyAxisConfig(elementName ? elementName : key);
      })

      setArrayToMaxLength(dataSeries);
    }

    columnDataInfo = [];
    dataSerie(data, "columns");
    setDataSeries(columnDataInfo, "columns");

    lineDataInfo = [];
    dataSerie(data, "lines");
    setDataSeries(lineDataInfo, "lines");

    let isTimestamp;
    let formattedData = [];
    convertDataToFormattedData();

    options.series = dataSeries;
    options.xaxis.type = "category";
    options.xaxis.categories = [...new Set(formattedData.map(m => m))];
    options.xaxis.tickPlacement = 'between';

    if (options.xaxis.labels.trim && options.xaxis.categories[0].length > 12) {
      options.xaxis.categories[0] = ["             " + options.xaxis.categories[0]]; //space added because the first column looks truncated
    }

    options.yaxis = yAxisConfig;
    options.stroke = {
      width: parseInt(config.strokeWidth)
    };

    options.tooltip.x = {
      formatter: value => {
        return value;
      }
    };

    function formatterCount(indexLength) {
      if (formatterCounter + 1 < indexLength) {
        formatterCounter += 1;
      } else {
        formatterCounter = 0;
      }
    }

    options.tooltip.y = {
      formatter: (value, index) => {
        if (value !== null) {
          let varyLength = data.length / index.w.config.xaxis.categories.length
          let isVaryUsed = varyLength !== 1 ? true : false

          formatterCounterColumn = formatterCounter % columnMap.columns.length;
          formatterCounterLine = isVaryUsed ? formatterCounter % columnMap.lines.length : formatterCounter;

          if (index.w.config.series[formatterCounter].type == "bar") {
            let col = columnMap.columns[formatterCounterColumn];
            formatterCount(index.w.config.series.length);

            if (col != undefined) {
              return getFormattedValue(col, value, col.DataFormat);
            } else {
              return value;
            }
          } else if (index.w.config.series[formatterCounter].type == "line") {
            let colName = index.w.config.series[formatterCounter].name
            let currentColumn = isVaryUsed ? colName.split(" -")[0] : colName
            let currentIndex = 0

            for (let j = 0; j < columnMap.lines.length; j++) {
              if (columnMap.lines[j].displayName === currentColumn) {
                currentIndex = j
              }
            }

            let line = columnMap.lines[currentIndex]
            formatterCount(index.w.config.series.length);

            if (line != undefined) {
              return getFormattedValue(line, value, line.DataFormat);
            } else {
              return value;
            }
          }
        }
      }
    };

    let varyValues = [...new Set(data.map(m => m.vary))];
    let colourMap = data[0].columns.map(function (d) {
      return d.name;
    });

    let oneUseColourMap = [];

    for (let i = 0; i < varyValues.length; i++) {
      oneUseColourMap = oneUseColourMap.concat(colourMap);
    }

    colourMap = oneUseColourMap;

    let varyCounter = -1;
    colourMap.forEach(function (element, index) {
      if (index % data[0].columns.length == 0) {
        varyCounter++;
      }

      if (varyValues[varyCounter] != undefined) {
        // Checking if there is a vary column
        colourMap[index] = element + " - " + getFormattedValue(columnMap.vary, varyValues[varyCounter]); // ColourMap assign element and vary values
      } else {
        colourMap[index] = element; // If there is no column in Vary, colourMap only assign element
      }
    });

    let chart = new ApexCharts(document.querySelector("#" + idName), options);
    chart.render();

    /*Y Axis Title Text Styling */
    const calculateYAxisConfig = () => {
      $(container).find(".apexcharts-yaxis-title-text").css("font-style", config.yAxisFontStyle ? "italic" : "");
      $(container).find(".apexcharts-yaxis-title-text").css("text-decoration", config.yAxisTextDecoration ? "underline" : "");
      $(container).find(".apexcharts-yaxis-title").attr("transform", config.yAxisAlign);
      $(container).find(".apexcharts-yaxis-title-text").css("opacity", config.ShowYAxisStylingOption ? "" : "0");
    }

    calculateYAxisConfig();

    /*Y Axis Title Text Location */
    const calculateYAxisTitleLocation = () => {
      $(container).find(".apexcharts-yaxis").each(function (e) {
        let yAttr = toInteger($($(this.children[1]).find(".apexcharts-text")).attr("y"))
  
        $($(this.children[1]).find(".apexcharts-text")).attr("y", yAttr - 7)
  
        //To fix the location bug when font size is smaller than 13
        if (config.yAxisFontSize <= 14) {
          if (config.yAxisFontSize <= 13) {
            let calc = config.yAxisFontSize
          }
  
          let calc = 3;
  
          $($(this.children[1]).find(".apexcharts-text")).attr("y", yAttr + calc)
        }
      })
    }

    calculateYAxisTitleLocation();


    let width = this.props.plugin.w;

    /*X Axis Title Text Styling */
    const calculateXAxisConfig = () => {
      $(container).find(".apexcharts-xaxis-title-text").css("font-style", config.xAxisFontStyle ? "italic" : "");
      $(container).find(".apexcharts-xaxis-title-text").css("text-decoration", config.xAxisTextDecoration ? "underline" : "");
      $(container).find(".apexcharts-xaxis-title-text").css("opacity", config.ShowXAxisStylingOption ? config.axisNumber != "Multi" ? width < 10 ? "0" : "" : width < 12 ? "0" : "" : "0");
  
      /* X Axis Labels Text Styling */
      $(container).find(".apexcharts-xaxis-label").css("font-style", config.xAxisLabelFontStyle ? "italic" : "");
      $(container).find(".apexcharts-xaxis-label").css("text-decoration", config.xAxisLabelDecoration ? "underline" : "");
      $(container).find(".apexcharts-xaxis-label").css("fill", config.xAxisLabelFontColor);
    }

    calculateXAxisConfig();

    //To calculate the limit of truncate
    let calcSpaces = $(container).find(".apexcharts-yaxis").length / 2.0
    let calc = calcSpaces * 220;
    let chartWidth = $(container).find(".apexcharts-svg").width() - calc / 2;
    let truncateLimit = chartWidth / config.xAxisFontSize;

    //To truncate lenghty texts and add ellipsis 
    let truncateText = (text, limit) => {
      if (text.length > limit) {
        return `${text.substring(0, limit - 3)}...`;
      }

      return text;
    }

    $(container).find(".apexcharts-xaxis-title-text").text(config.showEllipsisOnXAxis ? truncateText(columnMap.category.Name, truncateLimit) : columnMap.category.Name, truncateLimit);

    //To Add a class for ellipsis
    $(container).find(".apexcharts-tooltip-text").attr("class", config.showEllipsis ? "apexcharts-ellipsis-text apexcharts-tooltip-text" : "apexcharts-tooltip-text");
    $(container).find(".apexcharts-tooltip-text-label").attr("class", config.showEllipsis ? "apexcharts-ellipsis-text-label apexcharts-tooltip-text-label" : "apexcharts-tooltip-text-label");
    $(container).find(".apexcharts-tooltip-text-value").attr("class", config.showEllipsis ? "apexcharts-ellipsis-text-value apexcharts-tooltip-text-value" : "apexcharts-tooltip-text-value");
    $(container).find(".apexcharts-tooltip-y-group").attr("class", config.showEllipsis ? "apexcharts-ellipsis-y apexcharts-tooltip-y-group" : "apexcharts-tooltip-y-group");

    let graphicLocation = [];

    //function that calculates the location of the graph
    function calculateGraphicLocation() {
      graphicLocation = [];
      let containerNode;
      let id = $("#" + divId).find(".apexcharts-svg").attr("id")
      containerNode = d3.select("#" + id);

      let tempGraphic = containerNode.selectAll("g.apexcharts-grid").selectAll(".apexcharts-gridlines-horizontal").selectAll(".apexcharts-gridline");

      if (tempGraphic && tempGraphic[0] && tempGraphic[0].length != 0) {
        let tempGraphicLenght = tempGraphic[0].length - 1;

        tempGraphic[0].forEach(function (d, i) {
          if (i === tempGraphicLenght) {
            graphicLocation.push({
              StartPointX: 0,
              EndPointX: Number(d.attributes.x2.value),
              StartPointY: 0,
              EndPointY: Number(d.attributes.y2.value) + 3
            });
          }
        });
      } else {
        graphicLocation.push({
          StartPointX: 0,
          EndPointX: 0,
          StartPointY: 0,
          EndPointY: 0
        });
      }
    }

    //is it Looking Cut Off on value
    function valueLookingCutOff(value, cy, cx, angle, font, fontSize) {
      // Make angles more user friendly
      let rotateAngleInDegrees = -angle;

      // Do not rotate if input is invalid
      if (isNaN(rotateAngleInDegrees)) {
        rotateAngleInDegrees = 0;
      }

      // Math.sin() and Math.cos() functions require angle in radians
      let rotateAngleInRadians = rotateAngleInDegrees * (Math.PI / 180);
      let rotateAngleInRadians90 = -(90 - angle) * (Math.PI / 180);

      let textWidth = getWidthOfText(value, font, fontSize + "px ");
      let valuePosition = [];
      let valuePositionDifference = [];
      let differenceX = 0;
      let differenceY = 0;

      valuePositionDifference = [
        ["LeftDownX", 0],
        ["LeftDownY", 0],
        ["LeftTopX", 0],
        ["LeftTopY", 0],
        ["RightDownX", 0],
        ["RightDownY", 0],
        ["RightTopX", 0],
        ["RightTopY", 0]
      ];

      valuePosition = [
        ["LeftDownX", Number(cx)],
        ["LeftDownY", Number(cy)],
        ["LeftTopX", Number(cx) - (Math.cos(rotateAngleInRadians90) * fontSize)],
        ["LeftTopY", Number(cy) + (Math.sin(rotateAngleInRadians90) * fontSize)],
        ["RightDownX", Number(cx) + (Math.cos(rotateAngleInRadians) * textWidth)],
        ["RightDownY", Number(cy) + (Math.sin(rotateAngleInRadians) * textWidth)],
        ["RightTopX", (Number(cx) - (Math.cos(rotateAngleInRadians90) * fontSize)) + (Math.cos(rotateAngleInRadians) * textWidth)],
        ["RightTopY", (Number(cy) + (Math.sin(rotateAngleInRadians90) * fontSize)) + (Math.sin(rotateAngleInRadians) * textWidth)]
      ]

      valuePosition.map(function (d, i) {
        if (i % 2 === 0) {
          if (d[1] < graphicLocation[0].StartPointX) {
            valuePositionDifference[i][1] = Math.abs(graphicLocation[0].StartPointX - d[1]);
          } else if (d[1] > graphicLocation[0].EndPointX) {
            valuePositionDifference[i][1] = Math.abs(d[1] - graphicLocation[0].EndPointX);
          }
        } else {
          if (d[1] < graphicLocation[0].StartPointY) {
            valuePositionDifference[i][1] = Math.abs(graphicLocation[0].StartPointY - d[1]);
          } else if (d[1] > graphicLocation[0].EndPointY) {
            valuePositionDifference[i][1] = Math.abs(graphicLocation[0].EndPointY - d[1]);
          }
        }
      });

      valuePositionDifference.map(function (d, i) {
        if (i % 2 === 0) {
          differenceX = d[1] > differenceX ? d[1] : differenceX;
        } else {
          differenceY = d[1] > differenceY ? d[1] : differenceY;
        }
      });

      if (differenceX > 0 || differenceY > 0) {
        return [differenceX, differenceY, false];
      } else {
        return [differenceX, differenceY, true];
      }
    }

    if (this.props.plugin.h < 5 && this.props.plugin.w < 5) {
      $(container).find(".apexcharts-legend.apexcharts-align-center.position-bottom").css("display", "none");
    } else {
      $(container).find(".apexcharts-legend.apexcharts-align-center.position-bottom")
      $(container).find(".apexcharts-legend.apexcharts-align-center.position-bottom .apexcharts-legend-marker")
      .css("border", "1px solid #ffffff");   

    }

    function valueOnLine() {
      if (config.ShowLineValueOption) {

        calculateGraphicLocation();

        if (config.lineValueFont === undefined) {
          config.lineValueFont = defaultFont;
        }
        if (config.lineValueFontSize === undefined) {
          config.lineValueFontSize = defaultFontSize;
        }
        if (config.lineValueFontColor === undefined) {
          config.lineValueFontColor = defaultFontColor;
        }
        if (config.lineValueAngle === undefined) {
          config.lineValueAngle = defaultAngle;
        }

        //lineValueFontSize can be max 30 px and min 13 px
        if (Number(config.lineValueFontSize) > 30) {
          config.lineValueFontSize = 30;
        } else if (Number(config.lineValueFontSize) < 13) {
          config.lineValueFontSize = 13;
        }

        let containerNode;
        let id = $("#" + divId).find(".apexcharts-svg").attr("id")
        containerNode = d3.select("#" + id);

        let markerPoints = containerNode.selectAll("g.apexcharts-series-markers");
        let firstMarkerPoints = containerNode
          .selectAll(".apexcharts-series-markers-wrap")
          .select("g");

        let lineData = [];
        let lineDataValues = [];

        let firstValuesOfLine = [];
        let columnsOfLine = []
        let firstPositionsOfLine = [];

        let lineColors = [];

        // Include line data only
        dataSeries.forEach(function (d) {
          if (d.type === "line") lineData.unshift(d);
        });

        lineData = lineData.reverse()

        // Flatten lineData arrays
        for (let i = 0; i < lineData.length; i++) {
          let lineDatum = lineData[i];

          for (let j = 0; j < lineDatum.data.length; j++) {
            let currentLinePerData = lineDatum.data[j];

            if (j === 0) {
              firstValuesOfLine.push(currentLinePerData);
              columnsOfLine[currentLinePerData] = lineDatum.name
            } else {
              lineDataValues.push(currentLinePerData);
              columnsOfLine[currentLinePerData] = lineDatum.name
            }
          }
        }

        let circleCounter = 0;
        let pointPositions = [];
        containerNode.selectAll("circle")[0].forEach(function (circle) {
          if (circleCounter % dataSeries[0].data.length == 0) {
            firstPositionsOfLine.push({
              cx: circle.getAttribute("cx"),
              cy: circle.getAttribute("cy") - 5
            });
            lineColors.push(circle.attributes.fill.nodeValue);
          } else {
            pointPositions.push({
              cx: circle.getAttribute("cx"),
              cy: circle.getAttribute("cy") - 5
            });
            lineColors.push(circle.attributes.fill.nodeValue);
          }
          circleCounter++;
        });

        // Make angles more user friendly
        let rotateAngleInDegrees = -config.lineValueAngle;

        // Do not rotate if input is invalid
        if (isNaN(rotateAngleInDegrees)) {
          rotateAngleInDegrees = 0;
        }

        // Math.sin() and Math.cos() functions require angle in radians
        let rotateAngleInRadians = rotateAngleInDegrees * (Math.PI / 180);

        // First marker points of every line contains two circle elements
        // Append text to first marker points of every line separately
        firstMarkerPoints
          .append("text")
          .text(function (d, i) {
            let currentIndex = i
            let isVaryUsed = varyLength !== 1 ? true : false
            let currentColumn = isVaryUsed ? columnsOfLine[firstValuesOfLine[i]].split(" -")[0] : columnsOfLine[firstValuesOfLine[i]]

            for (let j = 0; j < columnMap.lines.length; j++) {
              if (columnMap.lines[j].displayName === currentColumn) {
                currentIndex = j
              }
            }

            return currentLineFormatter(firstValuesOfLine[i], currentIndex, -1);
          })
          .attr("style", function (d, i) {
            let result = valueLookingCutOff(this.innerHTML, firstPositionsOfLine[i].cy, firstPositionsOfLine[i].cx, config.lineValueAngle, config.lineValueFont, config.lineValueFontSize);

            if (result[2]) {
              return (
                "fill:" +
                (config.ColoredLineValues ? lineColors[i] : config.lineValueFontColor) +
                ";font-weight:700;font:" + config.lineValueFontSize + "px " + config.lineValueFont + ";"
              );
            } else {
              return (
                "display:none; fill:" +
                (config.ColoredLineValues ? lineColors[i] : config.lineValueFontColor) +
                ";font-weight:700;font:" + config.lineValueFontSize + "px " + config.lineValueFont + ";"
              );
            }
          })
          .attr("x", function (d, i) {
            // Use trigonometric functions to adjust the position of the text on new X axis
            return (
              (firstPositionsOfLine[i].cx) * Math.cos(rotateAngleInRadians) +
              (firstPositionsOfLine[i].cy) * Math.sin(rotateAngleInRadians)
            );
          })
          .attr("y", function (d, i) {
            // Use trigonometric functions to adjust the position of the text on new Y axis
            return (
              (firstPositionsOfLine[i].cy) * Math.cos(rotateAngleInRadians) -
              (firstPositionsOfLine[i].cx) * Math.sin(rotateAngleInRadians)
            );
          })
          .attr("transform", "rotate(" + rotateAngleInDegrees + ")"); // Rotate the text

        // Append text to rest of the marker points of every line
        let lineNumber = 0;
        let formatterNumber = 0;

        if (pointPositions.length > 0) {
          markerPoints
            .append("text")
            .text(function (d, i) {
              if ((i % ((data.length / varyLength) - 1)) === 0 && i !== 0) { //data lenght = a column data lenght * column count. Thats why, after every columns last data, pass to next line/column.
                formatterNumber++;
              }

              if (formatterNumber > columnMap.lines.length - 1) {
                formatterNumber = 0
              }

              return currentLineFormatter(lineDataValues[i], i, formatterNumber, lineData);
            })
            .attr("style", function (d, i) {
              if ((i % ((data.length / varyLength) - 1)) === 0 && i !== 0) {
                lineNumber++;
              }

              let result = valueLookingCutOff(this.innerHTML, pointPositions[i].cy, pointPositions[i].cx, config.lineValueAngle, config.lineValueFont, config.lineValueFontSize);

              if (result[2]) {
                return (
                  "fill:" +
                  (config.ColoredLineValues ? lineColors[i] : config.lineValueFontColor) +
                  ";font-weight:700;font:" + config.lineValueFontSize + "px " + config.lineValueFont + ";"
                );
              } else {
                return (
                  "display:none; fill:" +
                  (config.ColoredLineValues ? lineColors[i] : config.lineValueFontColor) +
                  ";font-weight:700;font:" + config.lineValueFontSize + "px " + config.lineValueFont + ";"
                );
              }
            })
            .attr("x", function (d, i) {
              return (
                (pointPositions[i].cx) * Math.cos(rotateAngleInRadians) +
                (pointPositions[i].cy) * Math.sin(rotateAngleInRadians)
              );
            })
            .attr("y", function (d, i) {
              return (
                (pointPositions[i].cy) * Math.cos(rotateAngleInRadians) -
                (pointPositions[i].cx) * Math.sin(rotateAngleInRadians)
              );
            })
            .attr("transform", "rotate(" + rotateAngleInDegrees + ")")
        }
      }
    }

    // In this function the contents of the lines are formatted and this function is called in the first MarkerPoints and MarkerPoints section.
    function currentLineFormatter(
      lineDataValues,
      index,
      formatterCounter = -1
    ) {
      let currentLine = "";

      if (columnMap.vary.ID !== "Unspecified." && columnMap.vary.ID !== undefined) {
        // If it contains vary column, line is formatted according to the vary column.
        currentLine = columnMap.vary; // currentLine assign vary colomn
      } else if (formatterCounter == -1) {
        // if formatterCounter == -1, formatter for first line
        currentLine = columnMap.lines[index];
      } else {
        // formatter for other lines
        currentLine = columnMap.lines[formatterCounter];
      }
      return getFormattedValue(currentLine, lineDataValues, currentLine.DataFormat); // returns currentLine formatted version
    }

    if (isTimestamp) {
      $(container).find(".apexcharts-xaxis-label tspan").css("font-size", "0.9em");
    }

    let result = true;
    for (let k = 0; k < dataSeries[0].data.length; k++) {
      if (dataSeries[0].data[k] < 0) {
        result = false;
      }
    }

    for (let i = 0; i < $(".apexcharts-yaxis-label").length; i++) {
      if (result && $(".apexcharts-yaxis-label")[i].innerHTML < 0) {
        $(".apexcharts-yaxis-label")[i].remove();
      }
    }

    if (config.ShowLineValueOption) {
      valueOnLine();
    }

    /* Checks condformats length */
    if (condFormats.length > 0) {
      addCondForm()
      addCondFormLegend();
      addCondFormLegendInTooltip();
    }

    function valueOnBarPos() {
      if (config.ValueOnBarOption && columnMap.columns.length > 0) {
        if (config.barValueFont === undefined) {
          config.barValueFont = defaultFont;
        }

        if (config.barValueFontColor === undefined) {
          config.backgroundColor = defaultFontColor;
        }

        if (config.barValueFontSize === undefined) {
          config.barValueFontSize = 10;
        }

        let fontSizeConfig = barValueFontSizeAutomaticFunction(divId, config.barValueFontSizeAutomatic, config.barValueFontSize);
        config.barValueFontSize = fontSizeConfig.barValueFontSize;
        config.barValueFontSizeMax = fontSizeConfig.barValueFontSizeMax;
        config.barValueFontSizeMin = fontSizeConfig.barValueFontSizeMin;

        //barValueFontSize can be max dynamic px and min 13 px
        if (Number(config.barValueFontSize) > Number(config.barValueFontSizeMax)) {
          config.barValueFontSize = Number(config.barValueFontSizeMax);
        } else if (Number(config.barValueFontSize) < Number(config.barValueFontSizeMin)) {
          config.barValueFontSize = Number(config.barValueFontSizeMin);
        }

        calculateGraphicLocation();

        let containerNode;
        let id = $("#" + divId).find(".apexcharts-svg").attr("id")
        containerNode = d3.select("#" + id);

        let labelFirstYaxisY;
        let labelFirstYaxisYZero;
        let labelYaxis = containerNode.select(".apexcharts-yaxis-texts-g");

        labelYaxis[0].forEach(function (d, i) {
          let yaxis = Array.from(d.children);

          yaxis.forEach(function (d2, j) {
            labelFirstYaxisY = d2.attributes.y.value - 34;

            let tempControl = (parseFloat(d2.children[0].innerHTML) === 0 || parseFloat(d2.children[0].innerHTML) < 0) ? true : false;

            if (labelFirstYaxisYZero === undefined && tempControl) {
              labelFirstYaxisYZero = labelFirstYaxisY;
            }
          });

          if (labelFirstYaxisYZero != undefined) {
            labelFirstYaxisY = labelFirstYaxisYZero;
          }
        });

        let barGraphs = containerNode
          .select(".apexcharts-bar-series")
          .selectAll(".apexcharts-series");

        let filteredBarGraphs = [barGraphs[0].filter(g => $(g).hasClass("apexcharts-series-collapsed") !== true)]

        let columnMapWithVaries = [];

        // Multiply columnFormats length for additional vary columns
        for (
          let multiplyCounter = 0;
          multiplyCounter < varyLength;
          multiplyCounter++
        ) {
          columnMapWithVaries = columnMapWithVaries.concat(columnMap.columns);
        }

        let formatterNumber = 0;
        if (filteredBarGraphs.length !== 0) {
          // barGraphs is an array of arrays with one element
          // d represents apexchart-series of a single data serie which contains bar objects
          filteredBarGraphs[0].forEach(function (d, i) {
            let bars = Array.from(d.children).filter(
              element => $(element).hasClass("apexcharts-bar-area")
            );

            let barValues = bars.map(function (n) {
              if (n.attributes.val !== null && n.attributes.val !== undefined) {
                return n.attributes.val.nodeValue;
              }
            });

            // Get position of bottom-left corner of every bar
            let barLocations = bars.map(function (n) {
              // Replace every "L" and "M" letters in pathTo attribute with space in order to easily extract position of bars
              let pathValues = n.attributes.pathTo.nodeValue
                .replace(/L|M/g, " ")
                .split(" ")
                .filter(function (d) {
                  return d != "";
                });

              return {
                x: parseFloat(pathValues[0]),
                y: config.axisNumber === "Single" ? parseFloat(labelFirstYaxisY) : parseFloat(pathValues[1]),
                barHeight: config.axisNumber === "Single" ? parseFloat(labelFirstYaxisY) - parseFloat(pathValues[3]) : parseFloat(pathValues[1]) - parseFloat(pathValues[3]),
                topOfTheBar: parseFloat(pathValues[3])
              };
            });

            // Add text to every bar with its value in it
            d3.select(d)
              .selectAll("text")
              .data(barValues)
              .enter()
              .append("text")

            // Every bar have same width, first one is chosen for convenience
            let barWidth = bars[0].attributes.barWidth.nodeValue;
            let counter = 0;
            let barValueLocationY = barWidth - ((barWidth - config.barValueFontSize) / 2) - 3;

            // Adjust the position of previously added texts
            $(d)
              .find("text")
              .each(function (d, i) {
                let formattedText = parseInt(barValues[counter])

                if (columnMapWithVaries[formatterNumber]) {
                  formattedText = getFormattedValue(columnMapWithVaries[formatterNumber], barValues[counter], columnMapWithVaries[formatterNumber].DataFormat);
                }

                let textContainer = $(this);
                textContainer
                  .text(formattedText)
                  .attr("pureValue", barValues[counter])
                  .attr("y", barLocations[counter].x + Number(barValueLocationY)) // Half of the bar widht and +4 is added to place the text into the middle
                  .attr(
                    "x",
                    getWidthOfText(textContainer.text(), config.barValueFont, config.barValueFontSize + "px") >
                      barLocations[counter].barHeight
                      ? -barLocations[counter++].topOfTheBar + 4
                      : -barLocations[counter++].y + 1
                  )
                  .attr("transform", "rotate(-90)")
                  .attr("style", function (d, i) {
                    let resultValueLookingCutOff = valueLookingCutOff(textContainer.text(), -Number(textContainer[0].attributes.x.value), Number(textContainer[0].attributes.y.value), 90, config.barValueFont, config.barValueFontSize);

                    if (resultValueLookingCutOff[2] && fontSizeConfig.showBarValueDisable === false) {
                      return ("fill:" + config.barValueFontColor + ";font-weight:700;font:" + config.barValueFontSize + "px " + config.barValueFont + ";");
                    } else {
                      return ("display:none; fill:" + config.barValueFontColor + ";font-weight:700;font:" + config.barValueFontSize + "px " + config.barValueFont + ";");
                    }
                  });

                let currentText = `${$(i).parent().attr("seriesName")}-${$(i).attr("pureValue")}`

                if (dataMapForTextColour.has(currentText)) {
                  let conditionalColor = dataMapForTextColour.get(currentText)

                  $(i).css("fill", conditionalColor)
                }
              });

            formatterNumber++;
          });
        }
      }
    }

    if (config.ValueOnBarOption) {
      valueOnBarPos();
    }

    function getWidthOfText(txt, fontname, fontsize) {
      if (getWidthOfText.c === undefined) {
        getWidthOfText.c = document.createElement("canvas");
        getWidthOfText.ctx = getWidthOfText.c.getContext("2d");
      }

      let fontspec = fontsize + " " + fontname;

      if (getWidthOfText.ctx.font !== fontspec) {
        getWidthOfText.ctx.font = fontspec;
      }

      return getWidthOfText.ctx.measureText(txt).width;
    }

    if (config.showHideButton) {
      let hideButtonToggleKey = ["#" + idName];
      rmvpp.hideButton(container, hideButtonToggleKey);
    }

    if (config.title === "") {
      $("#" + divId).find('.apexcharts-toolbar').css('margin-top', '30px');
    }

    this.props.setPluginRerender(false, this.props.plugin.id, false, this.props.plugin.isInteraction);
  };

  currentHeight;
  lastContent = undefined;

  updateLastContent = (status) => {
    this.lastContent = status
  }

  render() {
    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    let conditionalFormatComponent = null;
    if (this.props.conditionalFormattingVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      conditionalFormatComponent = renderConditionalFormatting(
        popupPosition,
        this.props,
        this.getConditionalFormattingComponent
      );
    }

    let navigationComponent = null;
    if (this.props.navigationComponentVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      navigationComponent = renderNavigation(
        popupPosition,
        this.props,
        this.getNavigationComponent
      );
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };

    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + this.props.plugin.id).css("padding")
      );

      let legendScale = this.props.plugin.h < 5 && this.props.plugin.w < 5 ? -1 : 2

      pluginConfig.height =
        calculatePluginHeight(this.props.plugin, this.props.settings) -
        pluginContainerPadding * legendScale

      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      );
    }

    return (
      <>
        <div style={{ height: "100%" }}>
          <div id={this.props.plugin.id}></div>
          {renderContent(
            isRerender,
            this.pluginRender,
            this.props.plugin,
            data,
            columnMap,
            pluginConfig,
            this.props.plugin.conditionalFormats,
            this.props.setPluginRerender,
            this.lastContent,
            this.updateLastContent,
          )}
          {configComponent}
          {dataComponent}
          {navigationComponent}
          {conditionalFormatComponent}
        </div>
        <div>
        </div>
      </>
    );
  }
}

function createVarySeries(data, varyOrderedArray) {
  let lines = [];
  let columns = [];

  if (data.length > 0) {
    let datum = data[0]; //every datum should have same lines and columns size. So we can check by first.

    for (let i = 0; i < varyOrderedArray.length; i++) {
      if (datum.columns) {
        for (let c = 0; c < datum.columns.length; c++) {
          columns.push(varyOrderedArray[i])
        }
      }

      if (datum.lines) {
        for (let l = 0; l < datum.lines.length; l++) {
          lines.push(varyOrderedArray[i])
        }
      }
    }
  }

  return [...columns, ...lines];
}

/** Find clicked value for filter in all data */
function getFilterValueForCreateTrigger(data, config, varyOrderedArray) {
  let dataMap = new Map();
  let varySeries = createVarySeries(data, varyOrderedArray)

  for (let i = 0; i < data.length; i++) {
    let datum = data[i];

    if (dataMap.has(datum.category)) {
      let value = dataMap.get(datum.category)
      value.push(datum);

      dataMap.set(datum.category, value);
    } else {
      dataMap.set(datum.category, [datum]);
    }
  }

  let varyValue = varySeries[0] ? varySeries[0] : varyOrderedArray[0];

  if (varySeries.length > config.seriesIndex) {
    varyValue = varySeries[config.seriesIndex];
  }

  let filterCategory = Array.from(dataMap.keys())[config.dataPointIndex];
  let filterValues = dataMap.get(filterCategory)
  let filter = undefined;

  // To select vary for interaction
  for (let i = 0; i < filterValues.length; i++) {
    let value = filterValues[i];

    if (value.vary === varyValue) {
      filter = value;
      break;
    }
  }

  return filter
}