import React, { Component } from "react";
import { del } from "../Utils/WebService";
import { API_BASE } from "../config";
import { showNotificationWithIcon } from "../Utils/Notification";
import AddUserContent from "./AddUserContent";
import ListUserContent from "./ListUserContent";
import { Card, Button, Icon, Row, Col, Form } from "antd";

const FormItem = Form.Item;

class UserPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      modelList: [],
      listUser: false
    };
  }

  deleteUser = id => {
    let url = `${API_BASE}/user/` + id;
    let requestBody = {};

    const successFunc = result => {
      showNotificationWithIcon("OK", "User successfully deleted.", "success");
      this.getUser();
    };

    const errorFunc = error => {
      showNotificationWithIcon("ERROR!", error.response.data, "error");
    };

    del(url, requestBody, successFunc, errorFunc);
  };

  showModal = () => {
    this.setState({
      visible: true,
      modelList: [...this.state.modelList]
    });
  };

  confirmAdd = result => {
    if (result === true) {
      this.setState({
        visible: false,
        listUser: true
      });
    } else {
      this.setState({
        visible: false
      });
    }
  };

  render() {
    return (
      <div id="userPage">
        <Card
          title="List Users"
          bordered={false}
          extra={
            <Button
              type="primary"
              style={{ float: "right" }}
              onClick={this.showModal}
            >
              <Icon type="plus" /> New account
            </Button>
          }
        >
          <Row>
            <Col span={18}>
              <ListUserContent list={this.state.listUser} />
            </Col>
            <Col span={6}></Col>
          </Row>
        </Card>

        <AddUserContent
          visible={this.state.visible}
          confirm={this.confirmAdd}
        />
      </div>
    );
  }
}

export default UserPage;
