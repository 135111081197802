import React, { Component } from "react";
import Button from "../GeneralComponents/Button/Button";
import i18n from "../../Utils/i18next";
import Text from "../GeneralComponents/Text/Text";
import { Row, Col, Divider } from "antd";
import Tooltip from "../GeneralComponents/Tooltip/Tooltip";
import {
  DeleteOutlined,
  EditOutlined,
  FontColorsOutlined,
  BgColorsOutlined
} from "@ant-design/icons";
import { showNotificationWithIcon } from "../../Utils/Notification";
import {convertHTMLRuletoRule} from "./ConditionalFormattingCommon";

/** Conditional format list component */
export default class List extends Component {
  /** Add button for switch the editting screen.  */
  addNewConditionalFormat = (
    <div style={{ width: "100%", textAlign: "right" }}>
      <Button
        style={{ width: "25%" }}
        className="btn general-button"
        onClick={() => this.props.setEdit(undefined)}
      >
        {i18n.t("AddNew")}
      </Button>
    </div>
  );

  /** If plugin has no conditional format, show this content. */
  noContent = (
    <div style={{ height: "300px", display: "flex" }}>
      <Text style={{ margin: "auto", color: "#8e8e8e" }}>
        {i18n.t("Dashboard.ConditionalFormatting.NoConditionalFormatsFound")}
      </Text>
    </div>
  );

  /** Header of conditional format list */
  listHeader = (
    <Row gutter={5}>
      <Col style={{ borderRight: "1px solid #e0e0e0" }} span={8}>
        {i18n.t("Dashboard.ConditionalFormatting.Target")}
      </Col>
      <Col style={{ borderRight: "1px solid #e0e0e0" }} span={8}>
        {i18n.t("Dashboard.ConditionalFormatting.Condition")}
      </Col>
      <Col style={{ borderRight: "1px solid #e0e0e0" }} span={5}>
        {i18n.t("Dashboard.ConditionalFormatting.Style")}
      </Col>
      <Col span={3}></Col>
    </Row>
  );

  /** get target columns and create a string for show in list */
  prepareTargetString = targetColumns => {
    let targetString = "";

    targetColumns.map((target, i) => {
      if (i !== 0) {
        targetString += ", ";
      }

      if (target.displayName === "AllColumns") {
        targetString += i18n.t("AllColumns");
      } else {
        targetString += target.displayName;
      }
    });

    return targetString;
  };

  /** get rule content and create rule string for show in list */
  prepareRuleString = rule => {
    if (rule.leftRule.ruleColumnName === "{undefined}") {
      rule.leftRule.ruleColumnName = convertHTMLRuletoRule(rule.leftRule).ruleColumnName;
    }

    return rule.leftRule.ruleColumnName + " " + rule.operator + " " + rule.rightRule.ruleColumnName;
  };

  /** Text content for list item */
  textItem = string => {
    return (
      <Text className="item-text">
        <Tooltip overlayClassName="conditional-format-list-tooltip" tooltip={string}>{string}</Tooltip>
      </Text>
    );
  };

  /** Remove conditional format in list */
  deleteConditionalFormat = id => {
    let conditionalFormatList = [...this.props.conditionalFormatList];

    conditionalFormatList = conditionalFormatList.filter(
      condFormat => condFormat.id != id
    );

    this.props.updateConditionalFormat("conditionalFormatting", {
      conditionalFormats: conditionalFormatList,
      pluginId: this.props.pluginId
    });

    showNotificationWithIcon(
      i18n.t("Success"),
      i18n.t(
        "Dashboard.ConditionalFormatting.ConditionalFormatSuccessFullyDeleted"
      ),
      "success"
    );
  };

  /** Delete button content */
  deleteButton = id => {
    return (
      <DeleteOutlined
        style={{ color: "#e22424" }}
        className={"conditional-format-list-button"}
        onClick={() => this.deleteConditionalFormat(id)}
      />
    );
  };

  /** Edit button content */
  editButton = conditionalFormat => {
    return (
      <EditOutlined
        style={{ color: "#4e7da2" }}
        className={"conditional-format-list-button"}
        onClick={() => this.props.setEdit(conditionalFormat)}
      />
    );
  };

  /** prepare colors in options for show in list */
  prepareOptionColors = option => {
    return { color: option.color, backgroundColor: option.backgroundColor };
  };

  /** Styling content */
  colorDisplayer = (color, icon, tooltip) => {
    if (color) {
      return (
        <Tooltip overlayClassName="color-displayer-area-tooltip" tooltip={tooltip}>
          <div className={"color-displayer-area"}>
            {icon}
            <div
              className={"color-displayer"}
              style={{ backgroundColor: color }}
            ></div>{" "}
          </div>
        </Tooltip>
      );
    }

    return "";
  };

  /** A conditional format item in list */
  conditionalFormatItem = conditionalFormat => {
    let targetString = this.prepareTargetString(
      conditionalFormat.targetColumns
    );
    let ruleString = this.prepareRuleString(conditionalFormat.rule);
    let optionColors = this.prepareOptionColors(conditionalFormat.options);

    return (
      <Row gutter={5}>
        <Col style={{ borderRight: "1px solid #e0e0e0" }} span={8}>
          {this.textItem(targetString)}
        </Col>
        <Col style={{ borderRight: "1px solid #e0e0e0" }} span={8}>
          {this.textItem(ruleString)}
        </Col>
        <Col style={{ borderRight: "1px solid #e0e0e0" }} span={5}>
          {this.colorDisplayer(
            optionColors.color,
            <FontColorsOutlined className={"color-displayer-icon"} />,
            i18n.t("Dashboard.ConditionalFormatting.TextColor")
          )}
          <Divider type="vertical" />
          {this.colorDisplayer(
            optionColors.backgroundColor,
            <BgColorsOutlined className={"color-displayer-icon"} />,
            i18n.t("Dashboard.ConditionalFormatting.BackgroundColor")
          )}
        </Col>
        <Col span={3}>
          {this.editButton(conditionalFormat)}
          <Divider type="vertical" />
          {this.deleteButton(conditionalFormat.id)}
        </Col>
      </Row>
    );
  };

  render() {
    let list = this.props.conditionalFormatList;

    if (list && list.length > 0) {
      return (
        <div>
          {this.addNewConditionalFormat}
          <div className={"conditional-format-list"}>
            <div className={"conditional-format-list-header"}>
              {this.listHeader}
            </div>
            {list.map(conditionalFormat => (
              <div className={"conditional-format-list-item"}>
                {this.conditionalFormatItem(conditionalFormat)}
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {this.addNewConditionalFormat}
          <div className={"conditional-format-list"}>
            {this.listHeader} {this.noContent}
          </div>
        </div>
      );
    }
  }
}
