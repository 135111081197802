/**
 * Converts base64 data to byte array
 */
const base64ToArrayBuffer = (base64) => {
    let binaryString =  window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);

    for (let i = 0; i < binaryLen; i++)        {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

/**
 * Creates a file from byte array and downloads it
 */
const saveByteArray = (function () {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return function (data, name) {
        var blob = new Blob(data, {type: "octet/stream"}),
            url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = name;
        a.click();
        window.URL.revokeObjectURL(url);
    };
}());

export {base64ToArrayBuffer, saveByteArray};