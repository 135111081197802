import React, { Component } from "react";
import JoinLayout from "../JoinLayout/JoinLayout";
import { Modal } from "antd";
import Text from "../../GeneralComponents/Text/Text";
import i18n from "../../../Utils/i18next"

/** 
 * Join layout component in modal is called to join operation in this component.
 */
export default class JoinContentModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Modal
          className={"join-modal"}
          title={i18n.t("Model.Join")}
          visible={this.props.visible}
          onCancel={() => this.props.visibleChange(false)}
          width={1000}
          bodyStyle={{
            maxHeight: "80dvh"
          }}
          centered={true}
          footer={null}
        >
          <div style={{ position: "relative" }}>
            <Text
              type="h4"
              style={{ textAlign: "center", marginBottom: "20px" }}
            >
              {this.props.selectedModel.displayName}
            </Text>
            <JoinLayout 
              join={this.props.join}
              findJoinChanges={this.props.findJoinChanges} 
              selectedModel={this.props.selectedModel} 
              updateSelectedModel={this.props.updateSelectedModel}
              updateModelTablesForJoin={this.props.updateModelTablesForJoin} 
              getModelList={this.props.getModelList}
              getModelTables={this.props.getModelTables}
              visible={this.props.visible}/>
          </div>
        </Modal>
      </div>
    );
  }
}
