import _ from "lodash";
import { SESSION_VARIABLE_CONTENT, DASHBOARD } from "./SessionVariableAction";

/**
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns 
 * 
 * Reducer for Formula Editor content
 */
export default function SessionVariableReducer(
  state = {
    sessionVariables: new Map(),
    sessionVariableNames: new Map(),
    dashboard: new Map()
  },
  action
) {
  switch (action.type) {
    case SESSION_VARIABLE_CONTENT:
      let newState = { ...state };
      let dashboardSessionVariables = new Map(state.dashboard);

      newState.sessionVariables = new Map(action.payload);
      newState.sessionVariableNames = new Map();

      newState.sessionVariables.forEach((value, key) => {
        newState.sessionVariableNames.set(value._id, key);
      });

      for (let name of dashboardSessionVariables.keys()) {
        let variable = newState.sessionVariables.get(name);

        if (!variable || variable.scope !== "dashboard") {
          dashboardSessionVariables.delete(name);
        }
      };

      if (!_.isEqual(state.dashboard, dashboardSessionVariables)) {
        newState.dashboard = dashboardSessionVariables;
      }

      return newState;
      
    case DASHBOARD:
      return {
        ...state,
        dashboard: new Map(action.payload),
      };
      
    default:
      return state;
  }
}