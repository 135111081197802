import React, { Component } from "react";
import * as XLSX from "xlsx";
import { Col, Row, Progress } from "antd";
import UploadTable from "./UploadTable";
import axios from "axios";
import SheetList from "./SheetList";
import CircleLoader from "../GeneralComponents/CircleLoader/CircleLoader";
import i18n from "../../Utils/i18next";
import { STATUS } from "./UploadOperation";
import { API_BASE } from "../../config";
import { setFileUploadOperationAndStatus } from "./FileUploadAction";
import { store } from "../..";
import Text from "../GeneralComponents/Text/Text";

/**
 * File preview with sheets
 */
export default class UploadedFilePreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: STATUS.NOT_STARTED,
      loading: false,
      sheets: new Map(),
      uploadProgress: 0,
    };
  }

  componentDidMount() {
    if (this.props.sheets) {
      let sheets = new Map(this.props.sheets);
      let selectedSheet = this.props.selectedSheet;

      this.setState({ sheets, selectedSheet });
    } else if (
      this.props.uploadParameters !== undefined &&
      this.props.uploadParameters.file !== undefined
    ) {
      const { file, header, fileType, csvSplitter, customSplitter } =
        this.props.uploadParameters;

      this.handleFileUpload(file, header, fileType);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.previewValidation !== this.props.previewValidation && nextProps.previewValidation === true) {
      this.validation();
    }
  }

  validation = () => {
    const { sheets, selectedSheet } = this.state;
    let response = { status: true };

    //Sheets - Tables
    if (response.status) {
      this.sendValidationResponseWithError(
        this.atLeastOneSheet(sheets),
        response
      );
    }

    if (response.status) {
      this.sendValidationResponseWithError(
        this.atLeastASelectedSheet(sheets),
        response
      );
    }

    if (response.status) {
      this.sendValidationResponseWithError(
        this.sheetNamesMustBeUnique(sheets),
        response
      );
    }

    if (response.status) {
      this.sendValidationResponseWithError(
        this.tableNameValidation(sheets),
        response
      );
    }

    //Columns
    if (response.status) {
      this.sendValidationResponseWithError(
        this.atLeastOneColumn(sheets),
        response
      );
    }

    if (response.status) {
      this.sendValidationResponseWithError(
        this.atLeastASelectedColumnPerSheet(sheets),
        response
      );
    }

    if (response.status) {
      this.sendValidationResponseWithError(
        this.columnNamesMustBeUniqueBySheet(sheets),
        response
      );
    }

    if (response.status) {
      this.sendValidationResponseWithError(
        this.columnNameValidation(sheets),
        response
      );
    }

    //TODO: Tablo name db validation will add. (space, special chars, start with number will not allow.).

    if (response.status) {
      this.props.setSheetsConfigurations(sheets, selectedSheet);
      this.props.validationResponse(response);
      return;
    }
  };

  /**
   * Common method for validation response with error.
   * @param {*} parameter
   * @param {*} response
   * @returns
   */
  sendValidationResponseWithError = (parameter, response) => {
    if (!parameter.status) {
      response.status = parameter.status;
      response.message = parameter.message;

      this.props.validationResponse(response);
      return;
    }
  };

  /**
   * Check sheet names for validation
   * @param {*} sheets
   * @returns
   */
  tableNameValidation = (sheets) => {
    let notValidSheetNames = new Set();

    [...sheets.keys()].map((key) => {
      let sheet = sheets.get(key);

      if (sheet.status) {
        let name = sheet.displayName;

        if (
          name.includes('"') ||
          name.includes(".") ||
          name.includes("”") ||
          name.includes("'") ||
          name.includes("%") ||
          name.includes("&") ||
          name.includes("[") ||
          name.includes("]") ||
          name.includes("|") ||
          name.includes("?") ||
          name.includes("=") ||
          name.includes("/") ||
          name.includes("+") ||
          name.includes("^")
        ) {
          notValidSheetNames.add(name);
        }
      }
    });

    if (notValidSheetNames.size > 0) {
      let messageBase = i18n.t(
        "FileUpload.TableNameCouldNotIncludesSpecialChars"
      );

      return {
        status: false,
        message: messageBase + '"' + [...notValidSheetNames].join(", ") + '".',
      };
    }

    return { status: true };
  };

  /**
   * Checks all sheets columns to name validation
   * @param {*} sheets
   * @returns
   */
  columnNameValidation = (sheets) => {
    let notValidColumnNames = new Set();

    [...sheets.keys()].map((key) => {
      let sheet = sheets.get(key);

      if (sheet.status) {
        Object.keys(sheet.columns).map((colKey) => {
          let name = sheet.columns[colKey].displayName;

          if (
            name.includes('"') ||
            name.includes(".") ||
            name.includes("”") ||
            name.includes("'")
          ) {
            notValidColumnNames.add(name);
          }
        });
      }
    });

    if (notValidColumnNames.size > 0) {
      let messageBase = i18n.t(
        "FileUpload.ColumnNameCouldNotIncludesSpecialChars"
      );

      return {
        status: false,
        message: messageBase + '"' + [...notValidColumnNames].join(", ") + '".',
      };
    }

    return { status: true };
  };

  /**
   * Check sheet for at least one selected
   * @param {*} sheets
   * @returns
   */
  atLeastOneSheet = (sheets) => {
    if (sheets.size === 0) {
      return {
        status: false,
        message: i18n.t("FileUpload.MustContainsAtLeastOneSheet"),
      };
    }

    return { status: true };
  };

  /**
   * Checks columns for at least one selected by sheet
   * @param {*} sheets
   * @returns
   */
  atLeastOneColumn = (sheets) => {
    let notValidSheetNames = new Set();

    [...sheets.keys()].map((key) => {
      let sheet = sheets.get(key);

      if (sheet.status) {
        let isAnyColumnExistPerSheet =
          !sheet.columns || Object.keys(sheet.columns).length === 0;

        if (isAnyColumnExistPerSheet) {
          notValidSheetNames.add(key);
        }
      }
    });

    if (notValidSheetNames.size > 0) {
      let messageBase = i18n.t(
        "FileUpload.MustContainsAtLeastOneColumnsPerSheets"
      );

      return {
        status: false,
        message: messageBase + '"' + [...notValidSheetNames].join(", ") + '".',
      };
    }

    return { status: true };
  };

  atLeastASelectedSheet = (sheets) => {
    let isAllSheetsDisabled = true;

    [...sheets.keys()].map((key) => {
      let sheet = sheets.get(key);

      if (sheet.status) {
        isAllSheetsDisabled = false;
      }
    });

    if (isAllSheetsDisabled) {
      let message = i18n.t("FileUpload.YouMustSelectAtLeastASheet");

      return { status: false, message };
    }

    return { status: true };
  };

  atLeastASelectedColumnPerSheet = (sheets) => {
    let allColumnsDisabledSheets = new Set();

    [...sheets.keys()].map((key) => {
      let sheet = sheets.get(key);
      let isAllColumnsDisabled = true;

      for (let colKey in sheet.columns) {
        let column = sheet.columns[colKey];

        if (column.status) {
          isAllColumnsDisabled = false;
          break;
        }
      }

      if (isAllColumnsDisabled) {
        allColumnsDisabledSheets.add(sheet.displayName);
      }
    });

    if (allColumnsDisabledSheets.size > 0) {
      let messageBase = i18n.t(
        "FileUpload.YouMustSelectAtLeastAColumnPerSheet"
      );

      return {
        status: false,
        message:
          messageBase + '"' + [...allColumnsDisabledSheets].join(", ") + '".',
      };
    }

    return { status: true };
  };

  sheetNamesMustBeUnique = (sheets) => {
    let sheetNames = new Set();
    let isUnique = true;
    let nonUniqueValues = new Set();

    [...sheets.keys()].map((key) => {
      let sheet = sheets.get(key);
      let displayName = sheet.displayName;

      if (sheetNames.has(displayName) && sheet.status) {
        isUnique = false;
        nonUniqueValues.add(displayName);
      } else if (sheet.status) {
        sheetNames.add(displayName);
      }
    });

    if (!isUnique) {
      let messageBase = i18n.t("FileUpload.SheetNamesMustBeUnique");

      let message = messageBase + "(" + [...nonUniqueValues].join(", ") + ")";

      return { status: false, message };
    }

    return { status: true };
  };

  columnNamesMustBeUniqueBySheet = (sheets) => {
    let isUnique = true;
    let nonUniqueValues = new Map();

    [...sheets.keys()].map((sheetKey) => {
      let sheet = sheets.get(sheetKey);

      if (sheet.status) {
        let colNames = new Set();
        let columns = sheet.columns;

        Object.keys(columns).map((columnKey) => {
          let column = columns[columnKey];

          if (colNames.has(column.displayName) && column.status) {
            isUnique = false;

            let nonUniqueColumnNames = nonUniqueValues.get(sheetKey);

            if (nonUniqueColumnNames === undefined) {
              nonUniqueColumnNames = new Set();
            }

            nonUniqueColumnNames.add(column.displayName);
            nonUniqueValues.set(sheetKey, nonUniqueColumnNames);
          } else if (column.status) {
            colNames.add(column.displayName);
          }
        });
      }
    });

    if (!isUnique) {
      let message = i18n.t("FileUpload.ColumnNamesMustBeUniqueBySheet");

      [...nonUniqueValues.keys()].map((sheetName) => {
        message += '{"' + sheetName + '": [';
        message += [...nonUniqueValues.get(sheetName)].join(", ") + "]} ";
      });

      return { status: false, message };
    }

    return { status: true };
  };

  setLoading = (status) => {
    this.setState({ loading: status });
  };

  setSheets = (sheets, firstSheetName) => {
    this.setState({ sheets, selectedSheet: firstSheetName });
  };

  /**
   * Uploads file and returns previewed 10 rows
   * @param {*} file
   * @param {*} sheetNames
   * @param {*} hasHeader
   */
  uploadFile = async (file, sheetNames, hasHeader, fileType) => {
    this.updateProgressAction(this.state.status, this.state.uploadProgress);

    let url = API_BASE + "/file-operations/upload-file-and-get-first-rows";
    let requestBody = new FormData();

    requestBody.append("file", file);
    requestBody.append("sheetNames", JSON.stringify(sheetNames));
    requestBody.append("hasHeader", hasHeader);
    requestBody.append("fileType", fileType);

    const success = (response) => {
      let newFilename = response.data.new_filename;
      let sheets = response.data.sheet_result;

      this.updateProgressAction(STATUS.DONE, this.state.uploadProgress);

      this.setState({
        status: STATUS.DONE,
        sheets: new Map(Object.entries(sheets)),
        newFilename: newFilename,
        selectedSheet: sheetNames[0],
        errorMessage: undefined,
      });

      this.setLoading(false);
      this.props.setNewFilename(newFilename);
    };

    const error = (err) => {
      this.updateProgressAction(STATUS.FAILED, this.state.uploadProgress);
      let errorMessage = undefined;

      if (err.response.status === 500) {
        errorMessage = err.response.data.message;
      }

      this.setState({
        status: STATUS.FAILED,
        success_object: undefined,
        exception_object: undefined,
        error: err,
        errorMessage: errorMessage,
      });

      this.setLoading(false);
    };

    await axios
      .post(url, requestBody, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          let status = STATUS.UPLOADING;

          if (progress === 100) {
            status = STATUS.PROCESSING;
          }

          this.updateProgressAction(status, progress);

          this.setState({
            uploadProgress: progress,
            status: status,
            loadedDataSize: progressEvent.loaded,
            totalDataSize: progressEvent.total,
          });
        },
      })
      .then((response) => success(response))
      .catch((response) => error(response));
  };

  /**
   * Updates progress status
   */
  updateProgressAction = (status, progress) => {
    store.dispatch(setFileUploadOperationAndStatus(status, progress));
  };

  /**
   * Prepare objects for upload file
   * @param {*} file
   * @param {*} hasHeader
   */
  handleFileUpload = async (file, hasHeader, fileType) => {
    this.setLoading(true);

    if (fileType === "EXCEL") {
      const reader = new FileReader();

      reader.onload = (evt) => {
        /* Parse data */
        const bstr = evt.target.result;
        const workbook = XLSX.read(bstr, { type: "binary", bookSheets: true });
        /* Get first worksheet */
        const sheetNames = workbook.SheetNames;
        let invalidSheetNames = [];

        sheetNames.forEach(sheetName => {
          if (sheetName.includes('"')) {
            invalidSheetNames.push(sheetName);
          }
        });

        if (invalidSheetNames.length === 0) {
          this.uploadFile(file, sheetNames, hasHeader, fileType);
        } else {
          
          let errorMessage = i18n.t("FileUpload.InvalidSheetNamesDetected") + ": [" + invalidSheetNames.join(", ") + "]." 

          this.setState({
            loading: false,
            status: STATUS.FAILED,
            success_object: undefined,
            exception_object: undefined,
            error: errorMessage,
            errorMessage: errorMessage,
          });
        }

      };

      reader.readAsBinaryString(file);
    } else if (fileType === "CSV" || fileType === "PARQUET" || fileType === "SPSS") {
      let sheet_name = file.name.split(".")[0];

      this.uploadFile(file, [sheet_name], hasHeader, fileType);
    } else {
      console.log("Not supported file type.");
      this.setLoading(true);
    }
  };

  selectSheet = (name) => {
    this.setState({
      ...this.state,
      selectedSheet: name,
    });
  };

  onChangeSheetStatus = (sheetName, status) => {
    let sheets = new Map(this.state.sheets);

    sheets.get(sheetName).status = status;

    this.setState({
      ...this.state,
      sheets,
    });
  };

  applyChangesToSheet = (changeObj) => {
    let sheets = new Map(this.state.sheets);
    let sheetName = changeObj.sheetName;
    let changes = changeObj.changes;

    let sheet = sheets.get(sheetName);

    Object.keys(changes).map((key) => {
      sheet[key] = changes[key];
    });

    this.setState({
      ...this.state,
      sheets,
    });
  };

  applyChangesToColumnInSheet = (changeObj) => {
    let sheets = new Map(this.state.sheets);
    let sheetName = changeObj.sheetName;
    let columnKey = changeObj.columnKey;
    let changes = changeObj.changes;

    let sheet = sheets.get(sheetName);
    let column = sheet.columns[columnKey];

    Object.keys(changes).map((key) => {
      column[key] = changes[key];
    });

    this.setState({
      ...this.state,
      sheets,
    });
  };

  render() {
    const { uploadParameters } = this.props;
    const {
      sheets,
      loading,
      selectedSheet,
      uploadProgress,
      loadedDataSize,
      totalDataSize,
    } = this.state;

    return (
      <div>
        <Row gutter={8}>
          <Col span={4}>
            <SheetList
              applyChangesToSheet={this.applyChangesToSheet}
              sheets={sheets}
              selectedSheet={selectedSheet}
              selectSheet={this.selectSheet}
            />
          </Col>
          <Col span={20}>
            {loading && uploadProgress > 0 && (
              <div>
                <Text
                  type="p"
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {i18n.t("FileUpload.FileUploadStatus")}
                </Text>

                <Progress
                  percent={uploadProgress}
                  status={uploadProgress !== 100 ? "active" : undefined}
                  strokeColor={{ from: "#108ee9", to: "#87d068" }}
                />
                <Text type="p" style={{ textAlign: "center" }}>
                  {(loadedDataSize / 1024 / 1024).toFixed(2)}MB /{" "}
                  {(totalDataSize / 1024 / 1024).toFixed(2)}MB
                </Text>
              </div>
            )}
            {loading ? (
              <div style={{ textAlign: "center", margin: "45px 0 0 0" }}>
                <CircleLoader />
                <div>
                  <Text style={{ fontStyle: "italic", fontSize: "15px" }}>
                    {i18n.t("FileUpload.YourDataIsBeingReviewed")}
                  </Text>
                </div>
              </div>
            ) : this.state.errorMessage ? (
              <Text
                type={"p"}
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: "18px",
                  color: "#bf6060",
                }}
              >
                {this.state.errorMessage}
              </Text>
            ) : (
              <UploadTable
                sheets={sheets}
                uploadParameters={uploadParameters}
                selectedSheet={selectedSheet}
                applyChangesToColumnInSheet={this.applyChangesToColumnInSheet}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
