import { Progress } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { store } from "../..";
import CircleLoader from "../GeneralComponents/CircleLoader/CircleLoader";
import Tooltip from "../GeneralComponents/Tooltip/Tooltip";
import {
  setFileUploadOperationAndStatus,
  setMinimize,
} from "./FileUploadAction";
import { STATUS } from "./UploadOperation";
import i18n from "../../Utils/i18next";

/**
 * File upload minimized loader and status component
 */
class FileUploadStatusMinimized extends Component {
  render() {
    const { FileUploadReducer } = this.props.state;
    const isMinimizedNotReady =
      FileUploadReducer === undefined ||
      FileUploadReducer.operationStatus === undefined ||
      !FileUploadReducer.minimize ||
      FileUploadReducer.operationStatus.status.key === STATUS.NOT_STARTED.key;

    if (isMinimizedNotReady) {
      return null;
    }

    let format = (percent) => {
      if (percent === 100) {
        return "";
      } else {
        return percent + "%";
      }
    };

    if (FileUploadReducer.operationStatus.status.key === STATUS.DONE.key) {
      format = undefined;
    } else {
    }

    return (
      <div className="file-upload-status-minimized">
        <Tooltip
          tooltip={
            i18n.t("FileUpload.FileUploadingStatus") +
            " " +
            FileUploadReducer.operationStatus.status.text
          }
          tooltipPlacement={"right"}
        >
          <div
            onClick={() => {
              store.dispatch(setMinimize(false));
            }}
          >
            <Progress
              type="circle"
              percent={FileUploadReducer.operationStatus.progress}
              strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
              width={60}
              format={format}
            />
            {FileUploadReducer.operationStatus.status.key ===
              STATUS.PROCESSING.key && (
              <CircleLoader
                style={{
                  position: "absolute",
                  left: "calc(50% - 12.5px)",
                  top: "calc(50% - 12.5px)",
                }}
              />
            )}
          </div>
        </Tooltip>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setFileUploadOperationAndStatus,
  setMinimize,
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileUploadStatusMinimized);
