import { Col } from "antd";
import { Component } from "react";
import React from "react"
import i18n from "../../../../../Utils/i18next";

// Measure footer, includes compares
export default class MeasureFooter extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    // Calculates percentage change
    calculatePercentageChange(initialValue, finalValue) {
        var change = finalValue - initialValue;
        var percentageChange = (change / initialValue) * 100;

        return percentageChange;
    }

    // Returns by data type
    returnByDataType = (firstVal, secondVal, percentageChange) => {
        let config = this.props.config

        if (config.dataType === "percent-numeric") {
            return <>{-percentageChange.toFixed(2)}%, {firstVal - secondVal}</>
        } else if (config.dataType === "percent") {
            return <>{-percentageChange.toFixed(2)}%</>
        } else if (config.dataType === "numeric") {
            return <>{firstVal - secondVal}</>
        }
    }

    // Calculates increments and decrease
    calculateIncrementAndDecrease = () => {
        let data = this.props.pluginData
        let paginationIndex = this.props.paginationIndex

        if (data.length > 1 && data[paginationIndex] && data[paginationIndex + 1]) {
            let firstVal = +data[paginationIndex].measurePureValue
            let secondVal = +data[paginationIndex + 1].measurePureValue
            let percentageChange = this.calculatePercentageChange(firstVal, secondVal)

            if (percentageChange < 0) {
                return <>
                    <div style={{ marginLeft: "5px", fontSize: "14px", color: "#8a8a8a" }} className="compare-three-dot">
                        {this.props.config.compareDescription == undefined ? "" : this.props.config.compareDescription}</div>
                    <div style={{ marginLeft: "5px", color: "#fa7777", fontWeight: "bold", fontSize: "14px" }}>
                        {this.returnByDataType(firstVal, secondVal, percentageChange)} <i className="fa fa-long-arrow-alt-down"></i>
                    </div>
                </>
            } else if (percentageChange > 0) {
                return <>
                    <div style={{ marginLeft: "5px", fontSize: "14px", color: "#8a8a8a" }} className="compare-three-dot">
                        {this.props.config.compareDescription == undefined ? "" : this.props.config.compareDescription}
                    </div>
                    <div style={{ marginLeft: "5px", color: "#74b774", fontWeight: "bold", fontSize: "14px" }}>
                        {this.returnByDataType(secondVal, firstVal, -percentageChange)} <i className="fa fa-long-arrow-alt-up"></i>
                    </div>
                </>
            } else if (firstVal && secondVal && percentageChange === 0) {
                return <>
                    <div style={{ color: "#8a8a8a" }} className="compare-three-dot">
                        {this.props.config.compareDescription == undefined ? "" : this.props.config.compareDescription}
                    </div>
                    <div style={{ marginLeft: "5px", color: "#8a8a8a", fontSize: "14px" }}>
                        {i18n.t("Dashboard.Configuration.Fields.IncreaseDecreaseNotAvailable")} <i className="fa fa-long-arrow-alt-right"></i>
                    </div>
                </>
            }
        }

        return null
    }

    render() {
        return (
            <Col span={20} style={{
                display: "flex", height: "19px", justifyContent:
                    this.props.config.compareAlign === "center" || this.props.config.compareAlign == undefined
                        ? "center"
                        : this.props.config.compareAlign === "left"
                            ? "flex-start"
                            : "flex-end"
            }}>
                {this.calculateIncrementAndDecrease()}
            </Col>
        );
    }
}
