import React, { Component } from "react";
import * as d3 from "d3";
import "d3-plugins-sankey";
import $ from "jquery";
import { rmvpp } from "../../RenderJs/rmvpp";
import SankeyConfiguration from "./SankeyConfiguration";
import i18n from "../../../../Utils/i18next";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join"
import {
  onComponentWillMount,
  onComponentWillReceiveProps,
  getColumnMapping,
  calculatePluginHeight
} from "../common";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import {
  renderConfig,
  renderData,
  renderNavigation
} from "../PluginsCommonComponents";
import SankeyData from "./SankeyData";
import { createTrigger } from "../../../Interaction/CreateTrigger";
import { renderContent } from "../renderContent";
import NavigationContent from "../../../Navigation/NavigationContent";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";
import { getFormattedValue } from "../format";

const data = JSON.parse(
  `[{"hat":"Dış Hat","tarife":"Tarifesiz","level":[{"name":"hat","value":"Dış Hat"},{"name":"tarife","value":"Tarifesiz"}],"measure":38134290},{"hat":"Dış Hat","tarife":"Tarifeli","level":[{"name":"hat","value":"Dış Hat"},{"name":"tarife","value":"Tarifeli"}],"measure":150191840},{"hat":"İç Hat","tarife":"Tarifeli","level":[{"name":"hat","value":"İç Hat"},{"name":"tarife","value":"Tarifeli"}],"measure":245125173},{"hat":"İç Hat","tarife":"Tarifesiz","level":[{"name":"hat","value":"İç Hat"},{"name":"tarife","value":"Tarifesiz"}],"measure":1772039}]`
);
const config = JSON.parse(
  `{"width":600,"height":300,"colours":"Flat-UI","toggleCriteria":"","title":"","summary":"","backgroundColor":"rgb(255,255,255)","showHideButton":false,"refresh":0}`
);
const columnMap = JSON.parse(
  `{"level":[{"Code":"\'ucus_PBWWSJXEAR\'.\'hat\'","name":"hat", "Name":"hat","DataType":"varchar","Table":"ucus_PBWWSJXEAR","Measure":"none","ID":"ucus_PBWWSJXEAR.hat","SubjectArea":"deneme","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":"%s","Config":{},"Verified":false,"Type":"Column","Description":""},{"Code":"\'ucus_PBWWSJXEAR\'.\'tarife\'","name":"tarife", "Name":"tarife","DataType":"varchar","Table":"ucus_PBWWSJXEAR","Measure":"none","ID":"ucus_PBWWSJXEAR.tarife","SubjectArea":"deneme","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":"%s","Config":{},"Verified":false,"Type":"Column","Description":""}],"measure":{"Code":"\'ucus_PBWWSJXEAR\'.\'yolcutoplam\'","name":"yolcutoplam", "Name":"yolcutoplam","DataType":"double","Table":"ucus_PBWWSJXEAR","Measure":"sum('ucus'.'yolcutoplam')","ID":"ucus_PBWWSJXEAR.yolcutoplam","SubjectArea":"deneme","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":".3s","Config":{},"Verified":false,"Type":"Column","Description":""}}`
);

const condFormats = [];
const filters = [];
const pluginName = "sankey";
const description =
  "Plots a [Sankey diagram](https://en.wikipedia.org/wiki/Sankey_diagram) which is a specific type of flow diagram where the width of the arrows are shown proportionally to the flow quantity. With OBIEE, this is particularly useful to show hierarchical information. A single query will be executed, with the parent levels aggregated in Javascript. Can hover over flows for tooltip information.";

const columnMappingParameters = [
  {
    targetProperty: "level",
    formLabel: "Level",
    multiple: true,
    type: "dim",
    required: true,
    desc: "desc63" //'Attribute columns representing each level in the data flow.'
  },
  {
    targetProperty: "measure",
    formLabel: "Measure",
    type: "fact",
    required: true,
    desc: "desc64" //'Single measure column, determining widths of the flow.'
  }
];

const configurationParameters = [
  {
    targetProperty: "width",
    label: "Width",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 600
    },
    desc: "desc89"
  },
  {
    targetProperty: "height",
    label: "Height",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 300
    },
    desc: "desc90"
  },
  {
    targetProperty: "colours",
    label: "Colours",
    inputType: "palette",
    inputOptions: {
      defaultValue: "Flat-UI"
    },
    desc: "desc184"
  },
  {
    targetProperty: "toggleCriteria",
    label: "ToggleCriteria",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc59"
  },
  {
    targetProperty: "title",
    label: "Title",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc94"
  },
  {
    targetProperty: "summary",
    label: "Summary",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc61"
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: { defaultValue: "rgb(255,255,255)" },
    desc: "desc62"
  },
  {
    targetProperty: "showHideButton",
    label: "Show Hide Button",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc230"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  },
  {
    targetProperty: "hideLabel",
    label: "HideLabel",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "desc142"
  },
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 15
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
];

const actions = [
  {
    trigger: "nodeClick",
    type: "click",
    name: "Click - Boğum",
    output: ["level"],
    description: "SankeyClickDesc"
  },
  {
    trigger: "nodeHover",
    type: "hover",
    name: "Hover - Boğum",
    output: ["level"],
    description: "SankeyHoverDesc"
  }
];

const reactions = [
  {
    id: "filter",
    name: "Filtre",
    description: "desc87",
    type: "general"
  }
];

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

/**
 * renders Sankey plugin in Vispeahen V3
 */
export default class Sankey extends Component {
  constructor(props) {
    super(props);
    this.rerenderProcessStarted = false;
    this.callBackObject = {};
  }

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({})

    return tmpCallBackObject;
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );
  }

  getNavigationComponent = props => {
    return (
      <NavigationContent
        navigations={props.navigations}
        setNavigations={props.updatePlugin}
        plugin={props.plugin}
        dashboardInformation={props.dashboardInformation}
      />
    );
  };

  getConfigComponent = props => {
    if (props.config) {
      return (
        <SankeyConfiguration
          config={{ ...props.config }}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig= {this.props.setCurrentAppliedConfig}
          currentAppliedConfig = {this.props.currentAppliedConfig}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }

    return (
      <SankeyData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        didNotJoinedTables={checkTableJoins(this.props.join, this.props.plugin.columnMap, this.props.refreshedPluginId, this.props.plugin.id, true)}
        setInteractions={this.props.setInteractions}
        interactions={this.props.interactions}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={props.changeDoesPluginHasNotJoinedTable}
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        navigations={props.navigations}
        plugin={props.plugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  /**
   * To set column map this plugin
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      level: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Level.Name"),
        type: "dim",
        required: true,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Level.Desc"),
        multiple: true,
        minimumColumnSize: 2,
        data: []
      },
      measure: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Name"),
        type: "fact",
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Desc"),
        required: true,
        minimumColumnSize: 1,
        conditionalFormat: true,
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {
    var THIS = this;

    // Set html empty and set this html to container.
    $("#" + divId).html("");
    let container = $("#" + divId)[0];

    var sankeyData = { nodes: [], links: [] };
    if (!columnMap.measure.Code)
      rmvpp.displayError(container, window.i18next.t("ErrorMessages.error512"));

    // var hasGridScope = vis.gridScope ? true : false;

    // Rebase data elements so that the levels match the format required for the Sankey partitioning
    data.forEach(function (d) {
      columnMap.level.forEach(function (l, i) {
        if (i < columnMap.level.length - 1) {
          if (d.level[i].value == d.level[i + 1].value) {
            var newVal =
              d.level[i + 1].value + " (" + d.level[i + 1].name + ")";
            d[columnMap.level[i + 1].Name] = newVal;
            d.level[i + 1].value = newVal;
          }
        }
      });
    });

    var datasets = [];
    for (var i = 0; i < columnMap.level.length - 1; i++) {
      // Aggregate datasets using d3.nest
      datasets.push(
        rmvpp.aggregateData(
          data,
          [columnMap.level[i].Name, columnMap.level[i + 1].Name],
          "measure",
          columnMap.measure
        )
      );
    }

    // Build up Sankey node group structure
    var nodeGroups = [];
    datasets.forEach(function (ds, i) {
      var nodeNames = [];
      ds.forEach(function (sourceDatum) {
        sankeyData.nodes.push({
          name: sourceDatum.key,
          column: i,
          columnName : columnMap.level[i].Name
        }); // Source node
        nodeNames.push(sourceDatum.key);
        sourceDatum.values.forEach(function (targetDatum) {
          sankeyData.nodes.push({
            name: targetDatum.key,
            level: i,
            column: i + 1,
            columnName : columnMap.level[i + 1].Name
          });
          sankeyData.links.push({
            source: sourceDatum.key,
            target: targetDatum.key,
            value: targetDatum.values,
            level: i
          });
          nodeNames.push(targetDatum.key);
        });
      });
      nodeGroups.push(nodeNames);
    });

    // Return only the distinct / unique nodes
    var nodeKeys = d3.keys(
      d3
        .nest()
        .key(function (d) {
          return d.name;
        })
        .map(sankeyData.nodes)
    );

    // Loop through each link replacing the text with its index from node
    sankeyData.links.forEach(function (d, i) {
      sankeyData.links[i].source = nodeKeys.indexOf(sankeyData.links[i].source);
      sankeyData.links[i].target = nodeKeys.indexOf(sankeyData.links[i].target);
    });

    // Finds a column object from a node name
    function getDatum(allNodes, nodeName) {
      var col;
      for (var i = 0; i < allNodes.length; i++) {
        col = allNodes[i].column;
        if (nodeName == allNodes[i].name) {
          break;
        }
      }

      // Build arbitrary data object to compensate for the granularity change
      var datum = { level: [] };
      for (var j = 0; j < columnMap.level.length; j++) {
        datum.level.push({ value: undefined });
      }

      datum.level[col] = { value: nodeName };
      return datum;
    }

    // Now loop through each nodes to make nodes an array of objects
    // rather than an array of strings
    sankeyData.nodes = nodeKeys.map(function (d, i) {
      return { name: d, datum: getDatum(sankeyData.nodes, d), columnName : sankeyData.nodes.find(node => node.name === d).columnName };
    });

    // Render Visual
    var margin = {
      top: 0,
      bottom: 5,
      right:
        15 +
        rmvpp.longestString(
          data.map(function (d) {
            return d.level[d.level.length - 1].value;
          }),
          columnMap.level[columnMap.level.length - 1].value,
          11
        ),
      left:
        15 +
        rmvpp.longestString(
          data.map(function (d) {
            return d.level[0].value;
          }),
          columnMap.level[0].value,
          11
        )
    },
      width = config.width - margin.left - margin.right,
      height = config.height - margin.top - margin.bottom;

    var colour = rmvpp.colourScale(
      data.map(function (d) {
        return d.level[0].value;
      }),
      config.colours
    ); // Set colour scale

    var sankeyContainer = d3
      .select(container)
      .append("div")
      .attr("class", "sankey-chart")
      .style("background-color", config.backgroundColor);
    var svg;

    let visWidth = "100%";
    let visHeight = config.height + "px";

    // if (hasGridScope) {
    svg = sankeyContainer
      .append("svg")
      .attr("width", visWidth)
      .attr("height", visHeight)
      .attr(
        "viewBox",
        `0 0 ${width + margin.left + margin.right} ${height +
        margin.top +
        margin.bottom}`
      )
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    // }
    // else {
    //     svg = sankeyContainer.append("svg")
    //         .attr("width", width + margin.left + margin.right)
    //         .attr("height", height + margin.top + margin.bottom)
    //         .append("g")
    //         .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    // }

    var sankey = d3
      .sankey()
      .nodeWidth(15)
      .nodePadding(15)
      .size([width, height]);

    var path = sankey.link();

    sankey
      .nodes(sankeyData.nodes)
      .links(sankeyData.links)
      .layout(2);

    // Draw links
    var tooltip = new rmvpp.Tooltip($(container).find(".sankey-chart")[0]);
    var link = svg
      .append("g")
      .selectAll(".link")
      .data(sankeyData.links)
      .enter()
      .append("path")
      .attr("class", "link")
      .attr("d", path)
      .style("stroke", function (d) {
        d.color = colour(d.source.name);
        return d.color;
      })
      .style({
        "stroke-width": function (d) {
          return Math.max(1, d.dy);
        },
        cursor: "pointer",
        fill: "none",
        "stroke-opacity": 0.3
      })
      .on("mouseover", function (d) {
        d3.select(this)
          .transition()
          .duration(200)
          .style({
            "stroke-opacity": 0.75,
            "z-index": 1000
          });

        // Objects for tooltip
        var datum = {
          source: d.source.name,
          target: d.target.name,
          measure: d.value
        };

        var cm = {
          source: columnMap.level[d.level],
          target: columnMap.level[d.level + 1],
          measure: columnMap.measure
        };
        tooltip.displayFull(
          ["measure", "source", "target"],
          cm,
          datum,
          d3.event
        );
      })
      .on("mouseout", function () {
        d3.select(this)
          .transition()
          .duration(200)
          .style({
            "stroke-opacity": 0.3,
            "z-index": 1
          });
        tooltip.hide();
      })
      .sort(function (a, b) {
        return b.dy - a.dy;
      });

    /* returns index of defined(not undefined) level in the levels to set name and value of the level before going createTrigger  */
    function getDefinedLevelIndex(d) {
      d.datum.level.findIndex(lev => lev.value !== undefined)
    }

    // Draw nodes
    var node = svg
      .append("g")
      .selectAll(".node")
      .data(sankeyData.nodes)
      .enter()
      .append("g")
      .attr("class", "node")
      .attr("transform", function (d) {
        return "translate(" + d.x + "," + d.y + ")";
      })
      .style({
        cursor: "move",
        "fill-opacity": 1,
        "shape-rendering": "crispEdges"
      })
      .call(
        d3.behavior
          .drag()
          .origin(function (d) {
            return d;
          })
          .on("dragstart", function (d) {
            let mousePosition = { x: window.event.pageX, y: window.event.pageY }
            let datum = []

            datum[0] = {}
            datum[0]["name"] = d.columnName
            datum[0]["value"] = d.name
            datum[0][d.columnName] = d.name

            createTrigger(
              actions,
              columnMap,
              container,
              "nodeClick",
              d.datum, // datum
              THIS.props.plugin.id,
              THIS.props.interactions,
              THIS.props.navigations,
              mousePosition,
              null, // filterOperator
              null, // drillDowns
              null, // clickedColumn
              THIS.props.plugin,
              THIS.props.model,
              datum 
            );
            this.parentNode.appendChild(this);
          })
          .on("drag", dragmove)
      )
      .on("mouseenter", function (d) {
        let mousePosition = { x: window.event.pageX, y: window.event.pageY }
        let levels = d.datum.level;
  
        levels[getDefinedLevelIndex(d)] = {
          name : d.columnName,
          value : levels.find(level => level.value !== undefined).value
        }

        createTrigger(
          actions,
          columnMap,
          container,
          "nodeHover",
          d.datum,
          THIS.props.plugin.id,
          THIS.props.interactions,
          THIS.props.navigations,
          mousePosition
        );

        var filterTargets;
        if (d.targetLinks.length > 0) {
          filterTargets = "target";
        } else {
          filterTargets = "source";
        }

        // Pass an array with all linked nodes
        var linked = d.sourceLinks.concat(d.targetLinks).map(function (d) {
          var t = filterTargets === "source" ? "target" : "source";
          return d[t].name;
        });
        linked.push(d.name);

        // Filter
        filterByEntity(d.name, filterTargets, linked);
      })
      .on("mouseleave", filterClear);

    node
      .append("rect")
      .attr("height", function (d) {
        return d.dy;
      })
      .attr("width", nodeWidth)
      .style("fill", function (d) {
        if (d.sourceLinks.length > 0) {
          d.color = colour(d.name);
          return d.color;
        }
        d.color = "#EEE";
        return d.color;
      })
      .style("stroke", function (d) {
        return d3.rgb(d.colour).darker(1);
      });

    function nodeWidth(d) {
      if (d.sourceLinks.length > 0) {
        return sankey.nodeWidth();
      }
      return sankey.nodeWidth() / 1.5;
    }

    // Label nodes
    var labels = node
      .append("text")
      .attr("x", sankey.nodeWidth() + 3)
      .attr("y", function (d) {
        return d.dy / 2;
      })
      .attr("dy", ".35em")
      .attr("text-anchor", "start")
      .attr("transform", null)
      .style({
        cursor: "pointer",
        "font-family": "sans-serif",
        "font-size": "11px"
      })
      .text(function (d) {
        let c = columnMap.level.find(level => level.aliasName === d.columnName);

        return c ? getFormattedValue(c, d.name) : d.name;
      });

    labels
      .filter(function (d) {
        return d.x < width / 2;
      })
      .attr("x", -1 * sankey.nodeWidth())
      .attr("text-anchor", "end");

    function filterByEntity(entity, targets, linked) {
      link.classed("inactive", function (d) {
        if (d[targets].name !== entity) {
          return "inactive";
        }
      });
      link.classed("active", function (d) {
        if (d[targets].name === entity) {
          return "active";
        }
      });
      link.style("stroke", function (d) {
        if (d[targets].name === entity) {
          return d.color;
        } else {
          return d.color;
        }
      });
      link
        .transition()
        .duration(200)
        .style("stroke-opacity", function (d) {
          if (d[targets].name === entity) return 1;
          else return 0.05;
        });
      link.style("z-index", function (d) {
        if (d[targets].name === entity) return 1000;
        else return 1;
      });
      node.classed("inactive", function (d) {
        return linked.indexOf(d.name) === -1;
      });
      node
        .transition()
        .duration(200)
        .style("fill-opacity", function (d) {
          if (linked.indexOf(d.name) === -1) return 0.6;
          else return 1;
        });
    }

    function filterClear() {
      link.classed("inactive", false);
      link.classed("active", false);

      link
        .transition()
        .duration(200)
        .style({
          "stroke-opacity": 0.3,
          "z-index": 1
        });
      node.classed("inactive", false);
      node
        .transition()
        .duration(200)
        .style("fill-opacity", 1);
      link.style("stroke", function (d) {
        return d.color;
      });
    }

    function dragmove(d) {
      d3.select(this).attr(
        "transform",
        "translate(" +
        (d.x = Math.max(0, Math.min(width - d.dx, d3.event.x))) +
        "," +
        (d.y = Math.max(0, Math.min(height - d.dy, d3.event.y))) +
        ")"
      );
      sankey.relayout();
      link.attr("d", path);
    }

    if (config.hideLabel) {
      let hideLabel = $(".sankey-chart");
      hideLabel.hide();
    }

    this.props.setPluginRerender(false, this.props.plugin.id, false, this.props.plugin.isInteraction);
  };

  currentHeight;
  lastContent = undefined;

  updateLastContent = (status) => {
    this.lastContent = status
  }

  render() {
    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    let navigationComponent = null;
    if (this.props.navigationComponentVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      navigationComponent = renderNavigation(
        popupPosition,
        this.props,
        this.getNavigationComponent
      );
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };
    
    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + this.props.plugin.id).css("padding")
      );

      pluginConfig.height =
        calculatePluginHeight(this.props.plugin, this.props.settings) -
        pluginContainerPadding * 2;

      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      );
    }

    return (
      <>
        <div style={{height: "100%"}}>
          <div id={this.props.plugin.id}></div>
          {renderContent(
            isRerender,
            this.pluginRender,
            this.props.plugin,
            data,
            columnMap,
            pluginConfig,
            condFormats,
            this.props.setPluginRerender,
            this.lastContent,
            this.updateLastContent,
          )}
          {configComponent}
          {dataComponent}
          {navigationComponent}
        </div>
      </>
    );
  }
}
