import React, { Component } from "react";
import { get } from "../Utils/WebService";
import { API_BASE } from "../config";
import { showNotificationWithIcon } from "../Utils/Notification";
import { Icon, Button } from "antd";

class CatalogObjectList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      subPath: {},
      componentWidth: "",
      selectedPath: ""
    };
  }

  componentWillMount() {
    if (this.props.path == "/") {
      this.getItemInfoByPath(this.props.path);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.path && nextProps.path != this.props.path) {
      if (nextProps.path[this.props.selfPath] == this.props.parentPath) {
        this.getItemInfoByPath(this.props.selfPath);
      }
    }
  }

  getItemInfoByPath = path => {
    let url = `${API_BASE}/itemInfo?parentId=` + path;

    const successFunc = result => {
      this.setState({
        data: [...result.data]
      });

      this.props.confirm(result.data);
    };

    const errorFunc = error => {
      showNotificationWithIcon("ERROR!", error.message, "error");
    };

    get(url, successFunc, errorFunc);
  };

  selectedPath = e => {
    this.props.setSelectedObject(e.target.value);
  };

  setSelectedObject = e => {
    this.selectedPath(e);
  };

  getSubFolder = e => {
    let layer = (e.target.value.match(/\//g) || []).length;
    this.setState({
      subPath: { ...this.state.subPath, [e.target.value]: this.props.path },
      componentWidth: 100 - layer * 5
    });
  };
  render() {
    return (
      <div>
        {this.state.data.map(obj => (
          <div>
            <Button
              style={{
                width: this.props.componentWidth + "%",
                float: "right",
                textAlign: "left",
                marginBottom: "5px",
                background:
                  this.props.selectedId == obj.id
                    ? "rgb(34, 97, 145)"
                    : "white",
                color:
                  this.props.selectedId == obj.id ? "white" : "rgba(0,0,0,.65)"
              }}
              type={"default"}
              value={"1"}
            >
              {obj.type == "Folder" ? (
                <Button
                  style={{
                    float: "left",
                    marginTop: "1px",
                    marginRight: "5px"
                  }}
                  shape={"circle"}
                  type={"primary"}
                  size={"small"}
                  onClick={this.getSubFolder}
                  value={obj.path}
                >
                  <Icon type="plus" />
                </Button>
              ) : (
                <Icon
                  type="file-image"
                  style={{ float: "left", marginTop: "6px" }}
                />
              )}

              <span style={{ display: "inline-block", marginTop: "3px" }}>
                {obj.name}
              </span>
              <Button
                size={"small"}
                style={{ float: "right", marginTop: "1px" }}
                onClick={this.selectedPath}
                value={obj.id}
                shape={"circle"}
              >
                <Icon type="right" />
              </Button>
            </Button>
            <div className="subFolder">
              {this.state.subPath[this.props.path] != {} ? (
                <CatalogObjectList
                  path={this.state.subPath}
                  parentPath={this.props.path}
                  selfPath={obj.path}
                  componentWidth={this.state.componentWidth}
                  confirm={this.props.confirm}
                  setSelectedObject={this.props.setSelectedObject}
                  selectedId={this.props.selectedId}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default CatalogObjectList;
