import {
  BorderTopOutlined,
  DashboardOutlined,
  DeleteOutlined,
  EditOutlined,
  TagsOutlined,
  FilterOutlined
} from "@ant-design/icons";
import { Button, Checkbox, Col, Divider, List, Row, Select } from "antd";
import React, { Component } from "react";
import { API_BASE } from "../../config";
import i18n from "../../Utils/i18next";
import { showNotificationWithIcon } from "../../Utils/Notification";
import { get } from "../../Utils/WebService";
import Text from "../GeneralComponents/Text/Text";
import Tooltip from "../GeneralComponents/Tooltip/Tooltip";
import NavigationList from "./NavigationList";
import { labelNamesForNavigationContent } from "../../Utils/LabelOperations"
import { defaultInteractionObjects } from "../Interaction/DefaultInteractionObjects";

const { Option } = Select;
const clone = require("rfdc")();

/**
 * Carries out navigation, add, delete, edit and list
 */
export default class NavigationContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      labelList: [],
      selectedLabel: "",
      dashboardList: [],
      selectedDashboard: { name: "", id: "" },
      selectedColumns: [],
      navigationsHashMap: new Map(),
      editNavigationObject: {},
      columns: [],
      isEdit: false,
      navigations: this.props.navigations ? this.props.navigations : [],
      transferDefaultFilters: this.pluginsCanTransferDefaultFilters.includes(this.props.plugin.key) ? true : false,
      transferCompareValue: this.props.plugin.key === "measure-tile" ? true : false,
      transferInteractionFilters: this.pluginsCanTransferInteractionFilters.includes(this.props.plugin.key) ? true : false,
    };
  }

  /**
   *
   * @param {*} selectedDashboard
   * @returns
   * Checks if the selected dashboard is empty
   */
  checkEmptySelectedDashboard = (selectedDashboard) => {
    return (
      Object.keys(selectedDashboard).length === 0 ||
      selectedDashboard.id === "" ||
      selectedDashboard.id === undefined
    );
  };

  /**
   * Checks the validity of the object in save operations and returns the appropriate error in case of failure
   */
  saveValidationCheck = () => {
    let validationInformation = {
      status: "success",
      message: i18n.t("SaveSuccess"),
    };

    if (
      this.state.selectedColumns.length === 0 &&
      !this.pluginsCanTransferDefaultFilters.includes(this.props.plugin.key)
    ) {
      validationInformation.status = "error";
      validationInformation.message = i18n.t(
        "Navigation.OneColumnMustBeSelected"
      );
    // check if transfer interaction filter is selected and plugin is allowed to transfer interaction filters  
    if (this.state.transferInteractionFilters && !this.pluginsCanTransferInteractionFilters(this.props.plugin.key))
      validationInformation.status = "error";
      validationInformation.message = i18n.t(
        "Navigation.PluginCanNotTransferInteractionFilters"
      );
    }

    if (this.checkEmptySelectedDashboard(this.state.selectedDashboard)) {
      validationInformation.status = "error";
      validationInformation.message = i18n.t(
        "Navigation.DashboardMustBeSelected"
      );

      return validationInformation;
    }

    return validationInformation;
  };

  /**
   * Clears the state after the desired actions
   */
  clearSelectedItems = () => {
    this.setState({
      ...this.state,
      selectedDashboard: { name: "", id: "" },
      editNavigationObject: {},
      selectedColumns: [],
      transferDefaultFilters: this.pluginsCanTransferDefaultFilters.includes(this.props.plugin.key),
      transferCompareValue: this.props.plugin.key === "measure-tile" ? true : false,
      transferInteractionFilters : this.pluginsCanTransferInteractionFilters.includes(this.props.plugin.key),
      isEdit: false,
    });
  };

  /**
   * Creates hashmap from the navigation array
   */
  createNavigationHashMap = () => {
    let navigationsHashMap = new Map();

    this.state.navigations &&
      this.state.navigations.map((navigation) => {
        navigationsHashMap.set(navigation.dashboardId, navigation);
      });

    this.setState({
      ...this.state,
      navigationsHashMap: navigationsHashMap,
    });
  };

  /**
   *
   * @param {*} navigationColumns
   * @returns
   *
   * Finds the selected navigation columns from the column list and creates the selected Column List
   */
  setSelectedNavigationColumns = (navigationColumns) => {
    let selectedColumnsSet = new Set();
    let selectedColumns = [];

    navigationColumns.map((column) => {
      selectedColumnsSet.add(column.uniqeColumnId);
    });

    this.state.columns.map((column) => {
      if (selectedColumnsSet.has(column.uniqeColumnId)) {
        selectedColumns.push(column);
      }
    });

    return selectedColumns;
  };

  /**
   * 
   * @param {*} newNavigation 
   * @param {*} navigations 
   * @param {*} navigationsHashMap 
   * @returns 
   * 
   * Create navigation and set array
   */
  createNavigation = (newNavigation, navigations, navigationsHashMap) => {
    //Save Status
    if (this.state.navigationsHashMap.has(newNavigation.dashboardId)) {
      showNotificationWithIcon(
        i18n.t("error"),
        i18n.t("Navigation.EditToMakeChanges"),
        "error"
      );
    } else {
      navigations.push(newNavigation);
      navigationsHashMap.set(newNavigation.dashboardId, newNavigation);

      showNotificationWithIcon(
        i18n.t("success"),
        i18n.t("Navigation.SuccessfullyCreated"),
        "success"
      );
    }
  };

  /**
   * 
   * @param {*} navigation 
   * @param {*} navigations 
   * @param {*} navigationsHashMap 
   * 
   * 
   * Updates existing navigation and sets array
   */
  updateNavigation = (navigation, navigations, navigationsHashMap) => {
    //Edit Status
    if (
      this.state.navigationsHashMap.has(
        this.state.editNavigationObject.dashboardId
      )
    ) {
      if (
        this.state.navigationsHashMap.has(navigation.dashboardId) &&
        navigation.dashboardId !== this.state.editNavigationObject.dashboardId
      ) {
        showNotificationWithIcon(
          i18n.t("error"),
          i18n.t("Navigation.EditToMakeChanges"),
          "error"
        );
      } else {
        navigationsHashMap.delete(this.state.editNavigationObject.dashboardId);
        navigationsHashMap.set(navigation.dashboardId, navigation);
        navigations = this.updateNavigationInArray(navigation);

        showNotificationWithIcon(
          i18n.t("success"),
          i18n.t("Navigation.SuccessfullyUpdated"),
          "success"
        );
      }
    }
  };
  /**
   *
   * @param {*} navigation
   * In edit mode, it sets the object to the form's fields
   */
  setNavigationDataForEditMode = (navigation) => {
    let selectedDashboard = {};
    selectedDashboard.id = navigation.dashboardId;
    selectedDashboard.name = navigation.dashboardInformation.dashboardName;

    this.setState(
      {
        ...this.state,
        isEdit: true,
        editNavigationObject: navigation,
        selectedDashboard: selectedDashboard,
        selectedLabel: navigation.dashboardInformation.label,
        selectedColumns: this.setSelectedNavigationColumns(
          navigation.dashboardInformation.columns
        ),
        transferDefaultFilters: navigation.dashboardInformation.transferDefaultFilters,
        transferCompareValue: navigation.dashboardInformation.transferCompareValue,
        transferInteractionFilters: navigation.dashboardInformation.transferInteractionFilters
      },
      () => this.getDashboards(navigation.dashboardInformation.label)
    );
  };

  /**
   * It does navigation save and updates operations.
   */
  save = () => {
    let validationResponse = this.saveValidationCheck();

    if (validationResponse.status === "error") {
      showNotificationWithIcon(
        validationResponse.message,
        null,
        validationResponse.status
      );
    } else {
      let actions = this.props.plugin.actions 
      ? this.props.plugin.actions[0]
      : defaultInteractionObjects[this.props.plugin.key]
        ? defaultInteractionObjects[this.props.plugin.key]
        : {}
      let navigation = {
        dashboardInformation: {
          columns: this.setSelectedNavigationColumns(this.state.selectedColumns),
          dashboardName: this.state.selectedDashboard.name,
          label: this.state.selectedLabel,
          actions: [{ ...actions }], //Set  actions
          transferDefaultFilters: this.state.transferDefaultFilters,
          transferCompareValue: this.state.transferCompareValue,
          transferInteractionFilters: this.state.transferInteractionFilters,
          filters: new Array,
        },
        dashboardId: this.state.selectedDashboard.id,
      };
      let navigationsHashMap = this.state.navigationsHashMap;
      let navigations = this.state.navigations;

      if (!this.state.isEdit) {
        //Save Status
        this.createNavigation(navigation, navigations, navigationsHashMap);
      } else {
        //Edit Status
        this.updateNavigation(navigation, navigations, navigationsHashMap);
      }

      this.setState(
        {
          ...this.state,
          isEdit: false,
          navigationsHashMap: navigationsHashMap,
          navigations: navigations,
        },
        () => this.clearSelectedItems()
      );

      this.props.setNavigations("navigation", navigations);
    }
  };

  /**
   *
   * @param {*} navigation
   * It does navigation updates operations.
   */
  updateNavigationInArray = (navigation) => {
    let navigations = this.state.navigations;

    for (let item in navigations) {
      if (
        navigations[item].dashboardId ===
        this.state.editNavigationObject.dashboardId
      ) {
        navigations[item] = navigation;
        break;
      }
    }

    return navigations;
  };

  /**
   *
   * @param {*} values
   *
   * Sets select column list to the state
   */
  columnsOnChange = (values) => {
    this.setState({
      ...this.state,
      selectedColumns: values,
    });
  };

  /**
   * Changes enable default filter state
   */
  transferDefaultFiltersOnChange = () => {
    this.setState({
      ...this.state,
      transferDefaultFilters: !this.state.transferDefaultFilters,
    });
  }

  /**
 * Changes enable tranfer interaction filter state
 */
  transferInteractionFiltersOnChange = () => {
    this.setState({
      ...this.state,
      transferInteractionFilters: !this.state.transferInteractionFilters,
    });
  }

  /*
  * Changes enable default filter state
  */
 transferCompareValue = () => {
  this.setState({
    ...this.state,
    transferCompareValue: !this.state.transferCompareValue
  })
 }

  /**
   * Creates an array from the appropriate format(i.e. non-aggregatable columns) columns of the plugin and sets the state
   */
  getColumnsArray = () => {
    let columns = [];

    for (let key in this.props.plugin.columnMap) {
      if (this.props.plugin.columnMap[key]) {
        if (this.props.plugin.columnMap[key].data) {
          this.props.plugin.columnMap[key].data.map((column) => {
            if (column.aggregatable !== true) {
              columns.push(column);
            }
          });
        } else {
          if (this.props.plugin.columnMap[key].aggregatable !== true) {
            columns.push(this.props.plugin.columnMap[key]);
          }
        }
      }
    }

    this.setState({
      ...this.state,
      columns: columns,
    });
  };

  /**
   * Retrieves labels and columns at page opening
   */
  componentWillMount() {
    this.getLabels();
    this.getColumnsArray();
  }

  /**
   * Creates a hashmap from navigation array
   */
  componentDidMount() {
    this.createNavigationHashMap();
  }

  /**
   *
   * @param {*} selectedLabel
   * It sets selected label and retrieves dashboards according to selected label.
   */
  handleChangeLabel = (selectedLabel) => {
    this.setState(
      {
        ...this.state,
        selectedLabel,
      },
      this.getDashboards(selectedLabel)
    );
  };

  /**
   *
   * @param {*} index
   * @param {*} e
   * Sets the dashboard selection
   */
  handleChangeDashboard = (index, e) => {
    let selectedDashboard = {};
    selectedDashboard.id = e.props.value;
    selectedDashboard.name = e.props.children;

    this.setState({
      ...this.state,
      selectedDashboard: selectedDashboard,
    });
  };

  /**
   *
   * @param {*} dashboardList
   * Removing the active dashboard name from the control and list
   */
  subtractOwnNameFromArray = (dashboardList) => {
    let dashboardsAndSelectedItem = {};
    let selectedDashboard = {};
    let dashboards = [];

    dashboardList.map((dashboard) => {
      if (
        this.props.dashboardInformation &&
        this.props.dashboardInformation.name !== dashboard.name
      ) {
        dashboards.push(dashboard);

        if (dashboard.id === this.state.selectedDashboard.id) {
          selectedDashboard = dashboard;
        }
      }
    });

    dashboardsAndSelectedItem.dashboards = dashboards;
    dashboardsAndSelectedItem.selectedDashboard = selectedDashboard;

    return dashboardsAndSelectedItem;
  };

  /**
   *
   * @param {*} labelId
   *
   * Retrieves the dashboard with giving labelId
   */
  getDashboards = (labelId) => {
    let url = `${API_BASE}/dashboard/dashboardSummary/?labelId=` + labelId;

    const successFunc = (result) => {
      let data = result.data.filter(d => d.type !== "report");
      let dashboardsAndSelectedItem = this.subtractOwnNameFromArray(data);

      this.setState({
        ...this.state,
        selectedDashboard: dashboardsAndSelectedItem.selectedDashboard,
        dashboardList: dashboardsAndSelectedItem.dashboards,
      });
    };

    get(url, successFunc);
  };

  /**
   * It retrieves the labels
   */
  getLabels = () => {
    let url = `${API_BASE}/labels`;

    const successFunc = (result) => {
      this.setState({
        ...this.state,
        labelList: result.data,
      });
    };

    get(url, successFunc, undefined, false);
  };

  cancelNavigation = () => {
    this.clearSelectedItems();
  };

  /**
   *
   * @param {*} navigation
   *
   * Deletes selected navigation from the list
   */
  deleteNavigation = (navigation) => {
    let navigations = this.state.navigations;
    let currentIndex = null;

    navigations.map((item, index) => {
      if (item.dashboardId === navigation.dashboardId) {
        currentIndex = index;
      }
    });

    navigations.splice(currentIndex, 1);

    let navigationsHashMap = this.state.navigationsHashMap;
    navigationsHashMap.delete(navigation.dashboardId);

    this.setState(
      {
        ...this.state,
        navigationsHashMap: navigationsHashMap,
        navigations: navigations,
      },
      this.clearSelectedItems()
    );

    this.props.setNavigations("navigation", clone(navigations));
  };

  // Plugins which can transfer default filters to navigations
  pluginsCanTransferDefaultFilters = [
    "measure-tile",
    "gauge-chart",
    "map-choropleth"
  ];

  // Plugins which can transfer interaction filters to navigations
  pluginsCanTransferInteractionFilters = [
    "measure-tile",
    "map-choropleth"
  ];

  /*
  * Controls and disables column in navigation content selection
  */
  controlIsColumnInvisible = (column) => {
    if (column.isDisabledColumn) {
      return true
    }

    return false
  }

  /*
  * Controls and disables columns value in navigation content selection.
  */
  controlIsColumnValue = (column) => {
    if (column.isDisabledColumn) {
      return null
    }

    return column
  }

  render() {
    return (
      <div className={"pluginNavigation"} id="pluginNavigationContent">
        <Row style={{ marginBottom: "10px" }}>
          <Col style={{ textAlign: "center" }} span={2}>
            <BorderTopOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={4}>
            <Text style={{ marginBottom: "5px", display: "block" }}>
              {i18n.t("Navigation.Columns")}
            </Text>
          </Col>
          <Col span={18} style={{ textAlign: "left" }}>
            <div className={"interaction-edit-columns-area"}>
              <Checkbox.Group
                value={this.state.selectedColumns}
                style={{ width: "100%", marginTop: "5px" }}
                onChange={this.columnsOnChange}
              >
                {this.state.columns &&
                  this.state.columns.map((column) => (
                    <>
                      {column.locationFieldName !== "hidden" ? <Row>
                        <Col span={8}>
                          <Checkbox
                            id={column.displayName + "InNavigationColumn"}
                            value={this.controlIsColumnValue(column)}
                            disabled={this.controlIsColumnInvisible(column)}
                          >
                            {column.displayName}
                          </Checkbox>
                        </Col>
                      </Row> : null}
                    </>
                  ))}{" "}
              </Checkbox.Group>
            </div>
            {
              this.pluginsCanTransferDefaultFilters.includes(this.props.plugin.key)
                ? <Row style={{ marginTop: "10px" }}>
                  <Col style={{ textAlign: "left" }}>
                    <Checkbox
                      id="navigationTransferDefaultFilters"
                      checked={this.state.transferDefaultFilters}
                      onChange={this.transferDefaultFiltersOnChange}
                    >
                      {i18n.t("Navigation.TransferDefaultFilters")}
                    </Checkbox>
                    <Tooltip
                      overlayClassName={"table-plugin-links"}
                      tooltip={i18n.t("Navigation.TransferDefaultFiltersInfo")}
                    >
                      <span
                        className={"table-plugin-link-info"}
                      >
                        (?)
                      </span>
                    </Tooltip>
                  </Col>
                </Row>
                : null
            }
            {
              this.pluginsCanTransferInteractionFilters.includes(this.props.plugin.key)
                ? <Row style={{ marginTop: "10px" }}>
                  <Col style={{ textAlign: "left" }}>
                    <Checkbox
                      id="navigationTransferInteractionFilters"
                      checked={this.state.transferInteractionFilters}
                      onChange={this.transferInteractionFiltersOnChange}
                    >
                      {i18n.t("Navigation.TransferInteractionFilters")}
                    </Checkbox>
                    <Tooltip
                      overlayClassName={"table-plugin-links"}
                      tooltip={i18n.t("Navigation.TransferInteractionFiltersInfo")}
                    >
                      <span
                        className={"table-plugin-link-info"}
                      >
                        (?)
                      </span>
                    </Tooltip>
                  </Col>
                </Row>
                : null
            }
            {
              this.props.plugin.key === "measure-tile"
                ? <Row style={{ marginTop: "10px" }}>
                  <Col style={{ textAlign: "left" }}>
                    <Checkbox
                      id="navigationTransferCompareValue"
                      checked={this.state.transferCompareValue}
                      onChange={this.transferCompareValue}
                      disabled={this.props.compareType === "default-filter" ? false : true}
                    >
                      {i18n.t("Navigation.TransferCompareValue")}
                    </Checkbox>
                    <Tooltip
                      overlayClassName={"table-plugin-links"}
                      tooltip={i18n.t("Navigation.TransferCompareValueInfo")}
                    >
                      <span
                        className={"table-plugin-link-info"}
                      >
                        (?)
                      </span>
                    </Tooltip>
                  </Col>
                </Row>
                : null
            }
          </Col>
        </Row>

        <Row style={{ marginBottom: "10px" }}>
          <Col style={{ textAlign: "center" }} span={2}>
            <TagsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={4}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Label.Title")}
            </Text>
          </Col>
          <Col span={18}>
            <Select
              showSearch
              id={"navigationLabels"}
              style={{ width: "100%" }}
              getPopupContainer={(trigger) => trigger.parentNode}
              value={this.state.selectedLabel}
              placeholder={i18n.t("Dashboard.Label.SelectLabel")}
              optionFilterProp="children"
              onChange={this.handleChangeLabel}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.labelList &&
                this.state.labelList.map((label) =>
                  label.displayName !== "Deleted" ? (
                    <Option name={i18n.t(label.displayName)} key={label.id}>
                      {labelNamesForNavigationContent(label)}
                    </Option>
                  ) : null
                )}
            </Select>
          </Col>
        </Row>

        <Row style={{ marginBottom: "10px" }}>
          <Col style={{ textAlign: "center" }} span={2}>
            <DashboardOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={4}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Navigation.Dashboards")}
            </Text>
          </Col>
          <Col span={18}>
            <Select
              showSearch
              id={"selectDashboard"}
              style={{ width: "100%" }}
              getPopupContainer={(trigger) => trigger.parentNode}
              value={
                this.state.selectedDashboard
                  ? this.state.selectedDashboard.id
                  : null
              }
              placeholder={i18n.t("Dashboard.Label.SelectLabel")}
              optionFilterProp="children"
              onChange={this.handleChangeDashboard}
              disabled={this.state.selectedLabel ? false : true}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.dashboardList &&
                this.state.dashboardList.map((dashboard, index) => (
                  <Option
                    id={dashboard.id}
                    value={dashboard.id}
                    key={index}
                    name={dashboard.name}
                  >
                    {dashboard.name}
                  </Option>
                ))}
            </Select>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col style={{ marginLeft: "10px" }}>
            <Button
              id={"saveNavigation"}
              className={"general-button"}
              style={{ float: "right", width: "fit-content" }}
              onClick={this.save}
            >
              {this.state.isEdit == true ? i18n.t("Update") : i18n.t("Save")}
            </Button>
          </Col>
          {this.state.isEdit == true ? (
            <Col style={{ marginLeft: "10px" }}>
              {" "}
              <Button
                className={"general-button"}
                style={{
                  float: "right",
                  right: "7px",
                  width: "fit-content",
                  backgroundColor: "rgb(255, 255, 255)",
                  color: "rgb(78, 126, 162)",
                }}
                onClick={this.cancelNavigation}
              >
                {i18n.t("Cancel")}
              </Button>
            </Col>
          ) : null}
        </Row>
        <NavigationList
          navigations={this.state.navigations}
          setNavigationDataForEditMode={this.setNavigationDataForEditMode}
          deleteNavigation={this.deleteNavigation}
        ></NavigationList>
      </div>
    );
  }
}
