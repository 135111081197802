import React, { Component } from "react";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import Tooltip from "../../GeneralComponents/Tooltip/Tooltip";
import i18n from "../../../Utils/i18next";

/**
 * Button for open or close swicth dashoard area
 */
export default class SwitchAreaOpenCloseButton extends Component {
  render() {
    let buttonClassName = "switch-visible-button";

    if (this.props.expand === true) {
      buttonClassName = "switch-visible-button expand";
    }

    return (
      <Tooltip
        tooltipPlacement="right"
        tooltip={i18n.t("Dashboard.DashboardList")}
      >
        <div
          className={buttonClassName}
          onClick={() => this.props.changeVisibility(!this.props.visibility)}
        >
          {
            this.props.alignment === "right" ? (
              <LeftOutlined className={"switch-icon"} />
            ) : (
              <RightOutlined className={"switch-icon"} />
            )
          }
        </div>
      </Tooltip>
    );
  }
}
