import React, { Component } from "react";
import { Tooltip } from "antd";

export default class TooltipVispeahen extends Component {
  render() {
    return (
      <Tooltip
        placement={
          this.props.tooltipPlacement ? this.props.tooltipPlacement : "top"
        }
        title={this.props.tooltip ? this.props.tooltip : ""}
        overlayClassName={this.props.overlayClassName ? this.props.overlayClassName : ""}
      >
        {this.props.children}
      </Tooltip>
    );
  }
}
