import React, { Component } from "react";
import { Row, Col } from "antd";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import Text from "../GeneralComponents/Text/Text";

/**
 * List of custom excels with delete button
 */
export default class CustomExcelListWithDelete extends Component {
  render() {
    return this.props.list.map(item => (
      <Row gutter={2} style={{ marginBottom: "5px" }}>
        <Col span={3} style={{ textAlign: "center" }}>
        -
        </Col>
        <Col span={19}>
          <Text
            type="span"
            style={{
              whiteSpace: "normal",
              fontSize: "16px",
              display: "inline-block",
              marginTop: "2px"
            }}
          >
            {item.alias}
          </Text>
        </Col>
        <Col span={1}>
          <DownloadOutlined style={{ fontSize: "19px"}} onClick={() => this.props.downloadCustomExcel(item.id, item.alias)} />
        </Col>
        <Col span={1} style={{ color: "red", textAlign: "right" }}>
          <DeleteOutlined onClick={() => this.props.removeExcel(item.id)} />
        </Col>
      </Row>
    ));
  }
}
