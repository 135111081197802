import { ShrinkOutlined } from "@ant-design/icons";
import { Col, Row, Switch, Typography } from "antd";
import React, { Component } from "react";
import Tooltip from "../Tooltip/Tooltip";
import i18n from "../../../Utils/i18next";

const { Text } = Typography;

/**
 * Switch for column expression status
 */
export default class QueryOrExpression extends Component {
  /**
   * Changes expression status for column properties
   * @param {*} status 
   */
  onChange = (status) => {
    if (status !== this.props.expression) {
      this.props.onChangeQueryExpr(status);
    }
  }

  render() {
    return (
      <div style={{padding: "12px"}}>
        <Row gutter={2}>
          <Col style={{ textAlign: "right" }} span={10}>
            <Text
              type="span"
              style={{
                whiteSpace: "normal",
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Data.Query")}
              <Tooltip tooltip={i18n.t("Dashboard.Data.QueryDesc")}>
                <span
                  name={"stackDesignDes"}
                  style={{ fontSize: "14px", color: "rgb(56, 123, 175)" }}
                >
                  {" "}
                  (?)
                </span>
              </Tooltip>
            </Text>
          </Col>
          <Col span={4} style={{ textAlign: "center", marginTop: "3px" }}>
            <Switch
              id="preventCollisionButton"
              onChange={(status) => this.onChange(status)}
              checked={this.props.expression}
            />
          </Col>
          <Col style={{ textAlign: "left" }} span={10}>
            <Text
              type="span"
              style={{
                whiteSpace: "normal",
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Data.Expression")}
              <Tooltip tooltip={i18n.t("Dashboard.Data.ExpressionDesc")}>
                <span
                  name={"freeMovemementDes"}
                  style={{ fontSize: "14px", color: "rgb(56, 123, 175)" }}
                >
                  {" "}
                  (?)
                </span>
              </Tooltip>
            </Text>
          </Col>
        </Row>
      </div>
    );
  }
}
