import $ from "jquery";
import { compare } from "../../../ConditionalFormatting/ConditionalFormattingCommon";
import { getFormattedValue } from "../format";
import { loadingScreen } from "../../../../Utils/Global";
import { store } from "../../../..";
import { changePluginLoaderVisibility } from "../../../GeneralComponents/PluginLoader/PluginLoaderAction";
import i18n from "../../../../Utils/i18next";
import { getCurrentDateTime } from "../common";

const specialCharCodes = {
  İ: "&#304;",
  ı: "&#305;",
  Ş: "&#350;",
  ş: "&#351;",
  Ğ: "&#286;",
  ğ: "&#287;",
  Ç: "&#199;",
  ç: "&#231;"
};

const clone = require("rfdc")();

// Create HTML for excel and calls download in the end
export const tableExcelExport = (tableID, title, data, columnMap, config, condFormats, newColumnMap) => {
    setTimeout(function() {
        let table = $("<table id='export-table-"+ tableID +"'><table>").css("border", "0.8pt solid gray").css("border-collapse", "collapse");
        let tableSelect = $("#plugin-" + tableID).find("#totalGridTop");
        let header = tableSelect.find(".ag-header-viewport");
        let style = tableSelect.find(".ag-header").find("style");
        let headerHTML = $("<thead></thead>");
        let linkField = config.linkField == "" ? [] : config.linkField.split(";")
        let linkDesc = config.linkDesc == "" ? [] : config.linkDesc.split(";")
        let linkFieldMap = createHashMap(linkField, linkDesc);
        let allDataCopy = clone(data);
        let imgField = config.imgField;
        let hashDescription = config.tableDescription.split("#");
        let totalByColumns = new Map();

        title = title ? title : "Table Excel";

        for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
            for (let colIndex = 0; colIndex < data[0].columns.length; colIndex++) {
                let column = columnMap.columns[colIndex];

                allDataCopy[rowIndex][column.name] = getFormattedValue(column, allDataCopy[rowIndex][column.name]);
            }
        }

        addTimeToHTML(headerHTML, config, columnMap.columns.length);

        addTitleToHTML(headerHTML, title, config, columnMap.columns.length);

        createColumnTitle(table, headerHTML, columnMap, config, newColumnMap);

        createData(table, data, columnMap, condFormats, totalByColumns, config, linkField, imgField, linkFieldMap);

        let enableTotalControl = (config.enableTotal == "Top" || config.enableTotal == "Bottom")
        
        if (enableTotalControl) {
            createTotalRow(table, columnMap, config, totalByColumns);
        }

        if (config.tableDescription !== null && config.tableDescription !== "") {
            createTableDescription(table, columnMap, hashDescription)
        }

        let tableHTML = table[0].outerHTML;

        tableHTML = tableHTML.replace(/İ|ı|Ş|ş|Ğ|ğ|Ç|ç/gi, function(matched) {
            return specialCharCodes[matched];
        });

        downloadFile(tableHTML, title);
        
        store.dispatch(changePluginLoaderVisibility(tableID, false));
        loadingScreen(false);
    }, 1);
}

/** Download html to excel */
const downloadFile = (tableHTML, filename) => {
    let downloadLink;
    let dataType = "application/vnd.ms-excel;charset=UTF-8;";

    let uriContent = "data:" + dataType + ", " + escape(tableHTML);
    let event = new MouseEvent("click");

    // Create download link element
    downloadLink = document.createElement("a");
    downloadLink.setAttribute("href", uriContent);
    downloadLink.setAttribute("download", filename + ".xls");
    downloadLink.dispatchEvent(event);
};

/** Add title for excel */
const addTitleToHTML = (content, title, config, size) => {
    let fontWeight = config.titleFontWeight ? "bold" : "normal";
    let fontDecor = config.titleTextDecor ? "underline" : "normal";
    let fontStyle = config.titleFontStyle ? "italic" : "normal";

    let headerStyle = 'text-align: ' + config.titleAlign + ';';
    let spanStyle = 'font-weight: ' + fontWeight + '; font-size: ' + config.titleFontSize + 'pt' +'; text-decoration: ' + fontDecor + '; font-style: ' + fontStyle + '; font-family: ' + config.titleFont + '; color: ' + config.titleColour; 
    let htmlContent = '<tr><th style="' + headerStyle + '"' + ' colspan=' + size + '>' + '<span style="' + spanStyle + '">' + title + "</span>" + "</th></tr>";
    content.prepend(
        htmlContent
    );
};

/** Add time for excel */
const addTimeToHTML = (content, config, size) => {
    let datetime = getCurrentDateTime();

    let headerStyle = 'text-align: ' + config.titleAlign + '; colspan=' + size;  
    content.prepend(
        '<tr><th style="' + headerStyle + '"' + ' colspan=' + size + '>' +
            '<span style="display: inline-block; text-align: right; font-size: 12pt; width: 100%;">' +
                datetime +
            "</span>" +
        "</th></tr>"
    );
};

// Column title
const createColumnTitle = (table, headerHTML, columnMap, config, newColumnMap) => {
    let headertr = $("<tr></tr>");

    columnMap.columns.forEach(column => {
        let head = $("<th></th>").css("border", "0.8pt solid gray");
        let width = getObjectFromArray(column.displayName, newColumnMap.columns);

        head.append(column.displayName)
            .css("background", config.themeColour)
            .css("width", width.colWidth + "pt")
            .css("font-size", config.columnHeaderTextFontSize +  "pt")
            .css("color", config.columnHeaderTextColor)
            .css("font-family",config.columnHeaderTextFont)
            .css("height", config.columnHeight + "pt");
        headertr.append(head);
    })

    headerHTML.append(headertr)
    table.append(headerHTML)
}

// Row data
const createData = (table, data, columnMap, condFormats, totalByColumns, config, linkField, imgField, linkFieldMap) => {
    data.forEach(d => {
        let bodytr = $("<tr></tr>");
        
        columnMap.columns.forEach(column => {
            let condFormatStyles = {};

            condFormats.forEach(cf => {
                let compareObject = compare(d, cf);
            
                if (compareObject.status === true && (cf.TargetName == "AllColumns" || column.name == cf.TargetName)) {
                    condFormatStyles["color"] = cf.Style.colour 
                    condFormatStyles["background"] = cf.Style.background.colour
                    condFormatStyles["italic"] = cf.Style.text.italic ? "italic" : "normal"
                    condFormatStyles["bold"] = cf.Style.text.bold ? "bold" : "normal"
                }
            })
            
            let td = $("<td></td>").css("border", "0.8pt solid gray");

            if (!linkField.includes(column.displayName)) {

                if (imgField != column.displayName) {
                    let formatedValue = getFormattedValue(column, d[column.displayName]);
                    td.append(formatedValue);
                    
                    if (Object.keys(condFormatStyles).length > 0) {
                        td.css("color", condFormatStyles.color)
                        td.css("background", condFormatStyles.background)
                        td.css("font-style", condFormatStyles.italic)
                        td.css("font-weight", condFormatStyles.bold)
                    }

                    td.css("font-family", config.font);
                    td.css("font-size", config.fontSize + "pt");
                    td.css("text-align", column.align);
                    td.css("border", "0.8pt solid gray");
                    td.css("height", config.RowHeight + "pt");
                    td.css("overflow", "hidden");

                    let enableTotalControl = (config.enableTotal == "Top" || config.enableTotal == "Bottom")

                    if (enableTotalControl) {
                        let total = totalByColumns.get(column.displayName);

                        if (column.isDataIncludeTotal) {
                            if (total !== undefined) {
                                totalByColumns.set(column.displayName, total + parseFloat(d[column.displayName] == "" ? 0 : d[column.displayName]))
                            } else {
                                totalByColumns.set(column.displayName, parseFloat(d[column.displayName] == "" ? 0 : d[column.displayName]))
                            }
                        }
                    }
                } else {
                    let imgSrc = d[column.displayName] == "" ? "" : '<img src=' + d[column.displayName] + ' width=' + column.colWidth + ' height=' + config.RowHeight + '></img>';

                    td.append(imgSrc);
                }

                bodytr.append(td);
            } else {
                if (Object.keys(condFormatStyles).length > 0) {
                    td.css("color", condFormatStyles.color)
                    td.css("background", condFormatStyles.background)
                    td.css("font-style", condFormatStyles.italic)
                    td.css("font-weight", condFormatStyles.bold)
                }

                td.css("font-family", config.font);
                td.css("font-size", config.fontSize + "pt");
                td.css("text-align", column.align);
                td.css("border", "0.8pt solid gray");
                td.css("height", config.RowHeight + "pt");
                td.css("overflow", "hidden");

                let linkData = d[column.displayName];
                let linkDesc = linkFieldMap[column.displayName];
                let link = "";

                if (linkData != "" && linkData != undefined && linkData != null) {
                    link = $("<a href='" + linkData + "'>" + linkDesc + "</a>")
                }

                td.append(link);
                bodytr.append(td);
            }
        })

        table.append(bodytr);
    })
}

// If exists create total row
const createTotalRow = (table, columnMap, config, totalByColumns) => {
    let totalRow = $("<tr></tr>");

    columnMap.columns.forEach((column,index) => {
        let totalCell = $("<td></td>");

        if (index == 0 && !totalByColumns.has(column.displayName)) {
            let tdString = '<td>' + i18n.t("Total") + '</td>';
            let totalTitle = $(tdString)
                .css("border", "0.8pt solid gray")
                .css("font-weight", "bold")
                .css("font-family", config.font)
                .css("font-size", config.fontSize + "pt")
            
            totalRow.append(totalTitle);
        } else {
            totalCell.css("border", "0.8pt solid gray");
            totalCell.css("font-weight", "bold");
            totalCell.css("font-family", config.font);
            totalCell.css("font-size", config.fontSize + "pt");
            totalCell.css("text-align", column.align);
            totalCell.css("border", "0.8pt solid gray");

            if (totalByColumns.has(column.displayName)) {
                let formatedTotal = getFormattedValue(column, totalByColumns.get(column.displayName));

                totalCell.append(formatedTotal);
            }

            totalRow.append(totalCell);
        }
    });

    table.append(totalRow);
}

// If exists create description row
const createTableDescription = (table, columnMap, hashDescription) => {
    table.append("<tr></tr>");

    hashDescription.forEach(line => {
        let desc = $(`<tr colspan='${columnMap.columns.length}'></tr>`).css('border-bottom', '0.8pt solid gray');
        let td1 = $(`<td></td>`);

        td1.append(line);
        desc.append(td1);
        table.append(desc);
    })
}

// Create hashmap
const createHashMap = (keys, values) => {
    const hashMap = {};

    if (keys.length !== values.length) {
        throw new Error("Arrays should have the same length to create a hash map.");
    }

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const value = values[i];
        hashMap[key] = value;
    }

    return hashMap;
}

// return object if exists
const getObjectFromArray = (displayName, arr) => {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].displayName === displayName) {
            return arr[i];
        }
    }
    return undefined;
}