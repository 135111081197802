import React, { Component } from "react";
import BoxItem from "../GeneralComponents/BoxItem/BoxItem";
import Tooltip from "../GeneralComponents/Tooltip/Tooltip";
import ModelItemOperations from "./ModelItemOperations";

const clone = require("rfdc")();

export default class Column extends Component {
  constructor(props) {
    super(props);

    this.state = {
      info: "",
      isPopoverContentVisible: false
    };
  }

  /*
  * Toggles the visibility of the popover content based on the passed boolean value.
  */
  setPopoverContentVisible = (visibility) => {
    this.setState({
      ...this.state,
      isPopoverContentVisible: visibility
    })
  }

  /** 
   * Change, add and send data to parent component
   */
  updateData = (updatedItem, action) => {
    updatedItem.index = this.props.columnIndex;
    updatedItem.tableAliasName = this.props.table.aliasName;
    updatedItem.tableDisplayName = this.props.table.displayName;
    updatedItem.tableDataSourceKey = this.props.table.dataSourceKey;

    this.props.updateData(updatedItem, action);
  };

  /** 
   * adds extra field for operations on column
   */
  getExtraItem = () => {
    return (
      <ModelItemOperations
        data={this.props.table.columns}
        item={this.props.column}
        key={this.props.column.id}
        itemKey={this.props.column.id}
        info={this.state.info}
        table={this.props.table}
        updateData={this.updateData}
        setPopoverContentVisible={this.setPopoverContentVisible}
        model={this.props.model}
        removeNewColumn={this.props.removeNewColumn}
        columnType={this.props.column.columnType}
      />
    );
  };

  /** 
   * Set the selected column selected and send it to the parent component
   */
  columnChecked = () => {
    let column = clone(this.props.column);
    let indexes = this.props.columnIndex.split("-");
    let tableIndex = indexes[0];
    let columnIndex = indexes[1];

    if (column.columnType === "newColumn") {
      return
    }

    if (column.selected) {
      column.selected = !column.selected;
    } else {
      column.selected = true;
    }

    if (column.isEnable !== null) {
      column.isEnable = !column.isEnable
    }

    column.index = columnIndex;
    column.tableAliasName = this.props.table.aliasName
    column.tableDisplayName = this.props.table.displayName
    column.tableDataSourceKey = this.props.table.dataSourceKey;

    this.props.updateColumn(tableIndex, column);
  };

  render() {
    return (
      <div className={this.props.divClassName}>
        <BoxItem
          id={this.props.table.dataSourceKey + "_" + this.props.table.aliasName + "_" + this.props.column.aliasName}
          title={this.props.column.displayName}
          style={{
            color:
              this.props.column.columnType !== "newColumn" ?
                this.props.column.source && this.props.column.source == "Both"
                  ? "green"
                  : this.props.column.source == "RealDB"
                    ? "blue"
                    : this.props.column.source == "Local"
                      ? "red"
                      : "black"
                : "#507577",
          }}
          columnType={this.props.column.columnType}
          dataItem={this.props.column}
          onClick={this.columnChecked}
          className={this.props.column.selected ? "selected" : null}
          extra={this.getExtraItem()}
          tooltipTitle={this.props.column.displayName}
          divClassName=" "
          textStyle={this.props.textStyle ? this.props.textStyle : null}
          isPopoverContentVisible={this.state.isPopoverContentVisible}
        ></BoxItem>{" "}
      </div>
    );
  }
}
