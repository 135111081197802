/**
 * Separates the required columns in column map for plugin for conditional formatting.
 */
export const findAndSetColumnsFromColumnMapForConditional = props => {
  let categories = props.conditionalFormatColumnMap;
  let columnMap = props.columnMap;
  let columns = [];

  if (columnMap) {
    Object.keys(columnMap).map(cat => {
      if (categories.has(cat) && columnMap[cat]["data"] !== undefined) {
        columns = [...columns, ...columnMap[cat].data];
      } else if (categories.has(cat) && columnMap[cat] !== undefined) {
        let currentCategory = columnMap[cat]

        if (Array.isArray(currentCategory)) {
          columns = [...columns, ...columnMap[cat]]
        } else {
          columns = [...columns, columnMap[cat]]
        }
      }
    });
  }

  return columns;
};

/**
 * Separates the required columns in column map for plugin.
 */
export const findAndSetColumnsFromColumnMap = props => {
  let columnMap = props.plugin.columnMap;
  let columns = [];

  if (columnMap) {
    Object.keys(columnMap).map(cat => {
      if (columnMap[cat]["data"] !== undefined) {
        columns = [...columns, ...columnMap[cat].data];
      } else if (columnMap[cat] !== undefined) {
        columns = [...columns, ...columnMap[cat]]
      }
    });
  }

  return columns;
};
