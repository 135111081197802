import React, { Component, Text } from "react";
import { get, post } from "../Utils/WebService";
import { API_BASE } from "../config";
import { showNotificationWithIcon } from "../Utils/Notification";
import { Card, Icon, Button } from "antd";

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userList: []
    };
  }

  componentWillMount() {
    this.getUsers();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedUsers) {
      this.compareAndCheckUsers(nextProps.selectedUsers.users);
    }
  }

  compareAndCheckUsers = data => {
    let comparedUsers = this.state.userList.map(user => {
      let copyUser = { ...user };
      if (data.length > 0) {
        data.find(x =>
          x.id === copyUser.id
            ? (copyUser.checked = true)
            : (copyUser.checked = false)
        );
      } else {
        copyUser.checked = false;
      }
      return copyUser;
    });

    this.setState({
      userList: comparedUsers
    });
  };

  getUsers = () => {
    let url = `${API_BASE}/user`;

    const successFunc = result => {
      this.setState({
        userList: [...result.data]
      });
    };

    const errorFunc = error => {
      showNotificationWithIcon("ERROR!", error.response.data, "error");
    };

    get(url, successFunc, errorFunc);
  };

  changeRole = e => {
    let id = e.target.value;
    if (id !== null) {
      this.setState({
        userList: this.state.userList.map(user =>
          user.id === id
            ? {
                ...user,
                checked: !user.checked,
                changed: true
              }
            : { ...user }
        )
      });
    }
  };

  save = () => {
    let updateRoleUserObj = { insertList: [], deleteList: [] };
    let url = `${API_BASE}/role/roleUser`;

    this.state.userList.map(user => {
      if (user.changed === true) {
        let isExistRole = false;
        let newRoleId = this.props.selectedUsers.role.id;
        if (user.roleIds !== null && user.roleIds !== undefined) {
          isExistRole = user.roleIds.includes(this.props.selectedUsers.role.id);
        } else {
          user.roleIds = [];
        }

        if (user.checked === true && !isExistRole) {
          updateRoleUserObj.insertList.push({
            roleId: newRoleId,
            userId: user.id
          });
          user.changed = false;
        } else if (user.checked === false && !isExistRole) {
          updateRoleUserObj.deleteList.push({
            roleId: newRoleId,
            userId: user.id
          });
          user.changed = false;
        }
      }
    });

    const successFunc = result => {
      showNotificationWithIcon(
        "OK",
        "Role successfully assigns users.",
        "success"
      );
    };

    const errorFunc = error => {
      showNotificationWithIcon("Error", error.response.data, "error");
    };

    post(url, updateRoleUserObj, successFunc, errorFunc);
  };

  render() {
    return (
      <div>
        {this.state.userList.map(user => (
          <div>
            <Button
              style={{
                width: "100%",
                textAlign: "left",
                marginBottom: "5px",
                background:
                  user.checked == true ? "rgba(34, 97, 145, 1)" : "white",
                color: user.checked ? "white" : "rgba(0,0,0,.65)"
              }}
              type={"default"}
              onClick={this.changeRole}
              value={user.id}
            >
              <Icon
                type={user.checked ? "check" : "none"}
                style={{ float: "right", marginTop: "3px" }}
              />
              {user.username}
            </Button>
          </div>
        ))}

        <Button
          style={{ float: "right", marginTop: "5px;" }}
          type={"primary"}
          onClick={this.save}
        >
          Save
        </Button>
      </div>
    );
  }
}

export default UserList;
