
import { get } from "../../../Utils/WebService";
import { API_BASE } from "../../../config";
export const CREATE_SUCCESS = "CREATE_SUCCESS";
export const CREATE_FAIL = "CREATE_FAIL";
export const GET_REAL_DB_MODEL = "GET_REAL_DB_MODEL";
export const GET_SCHEMA_NAMES = "GET_SCHEMA_NAMES";
export const GET_SCHEMA = "GET_SCHEMA";

function organizing(result) {
  var model = result;
  var resultObject = [];
  var checkedItems = [];
  var dataSourceGroupList = {};
  var dataSourceIndexList = {};
  var newDataSourceIndex = -1;
  var dataSourceIndex;

  if (Object.keys(model).length !== 0) {
    model.tables.map((table) => {
      if (dataSourceGroupList[table.dataSourceKey] == undefined) {
        dataSourceGroupList[table.dataSourceKey] = [];
        newDataSourceIndex++;
        dataSourceIndex = newDataSourceIndex;
        dataSourceIndexList[table.dataSourceKey] = dataSourceIndex;
      }
      else {
        dataSourceIndex = dataSourceIndexList[table.dataSourceKey];
      }

      //If display name is empty, name set operation
      if (table.displayName == null) {
        table.displayName = table.name;
      }

      let tableIndex = dataSourceGroupList[table.dataSourceKey].length;

      if (table.columns != null) {
        for (let i = 0; i < table.columns.length; i++) {
          table.columns[i].key = `${dataSourceIndex},${tableIndex},${i}`;
          if (table.columns[i].source == "Local" || table.columns[i].source == "Both") {
            checkedItems.push(table.columns[i].key);
          }
          if (table.columns[i].displayName == null) {
            table.columns[i].displayName = table.columns[i].name
          }
        }
      }

      dataSourceGroupList[table.dataSourceKey].push({
        ...table,
        key: `${dataSourceIndex},${tableIndex}`
      });
    });

    var dataSourceIndex = 0;
    for (let dataSource in dataSourceGroupList) {
      let obj = {
        displayName: dataSource,
        tables: dataSourceGroupList[dataSource],
        key: dataSourceIndex
      };
      resultObject.push(obj);
      dataSourceIndex++;
    }
    model.tables = resultObject;
    model.checkedItems = checkedItems;
    return model;
  }
}

export function modelSave(model) {

  model.displayName = model.modelName;
  model.name = model.modelName;

  model.tables.map((table) => {
    table.displayName = table.name;

    table.columns.map((column) => {
      column.displayName = table.name;
    })
  })
}

export function getModelRealDb() {
  return dispatch => {
    const successFunk = (response) => {
      let rsp = organizing(response.data);
      dispatch({
        type: GET_REAL_DB_MODEL,
        payload: rsp
      })
    }

    const errorFunk = (response) => {
      console.trace(response);
    }

    let url = `${API_BASE}/subjectArea/realDb`
    get(url, successFunk, errorFunk)
  };
}

export function getDataSourceSchemaNames() {
  return dispatch => {
    const successFunc = (response) => {
      console.log("get schema name list=", response)
      dispatch({
        type: GET_SCHEMA_NAMES,
        payload: response.data.rows
      })
    }

    const errorFunc = (response) => {
      console.log(response);
    }

    let url = `${API_BASE}/dataSourceSchema/getSchemaNames`
    get(url, successFunc, errorFunc)
  };
}

// function organizingSchema(columnList) {
//   let tableNames = columnList.map(item => {
//     return item.TABLE_NAME
//   })

//   // let schema = {}
//   // tableNames.map(tableName => {
//   //   schema
//   // })
// }

function addSchemaToModel(result, schema) {
  console.log("add schema model", schema)
  console.log("add schema result", result)

  if (Object.keys(schema).length != 0 && schema.tables.length > 0) {
    for (let schemaTables of schema.tables) {
      // result.tables = result.tables.concat(schemaTables.tables)
      result.tables.push(...schemaTables.tables)
    }
  }
  console.log("add result - ",result)

  return organizing(result)
}

export function getDataSourceSchema(schemaName) {
  return (dispatch, getState) => {

    const state = getState();
    const { schema } = state.CreateModelReducer;

    console.log("get schema", schema)

    const successFunc = (response) => {
      console.log("response = ", response.data);

      dispatch({
        type: GET_SCHEMA,
        payload: addSchemaToModel(response.data, schema)
      })
    }

    const errorFunc = (response) => {
      console.log(response);
    }

    let url = `${API_BASE}/dataSourceSchema/getSchemaTablesAndColumns/${schemaName}`
    get(url, successFunc, errorFunc)
  };
}

export function removeDataSourceSchemaFromModel(schemaName) {
  return (dispatch, getState) => {
    const state = getState();
    const { schema } = state.CreateModelReducer;
    let allTables = [];
    if (Object.keys(schema).length != 0 && schema.tables.length > 0) {
      let schemaTables = schema.tables.filter(item => item.displayName != schemaName);

      for (let schemaTable of schemaTables) {
        // allTables = allTables.concat(schemaTable.tables)
        allTables.push(...schemaTable.tables)
      }
      console.log("remove ALLTABLES - ",allTables)
    }

    schema.tables = allTables;
    // let schemaTables = schema.tables.filter(item => item.displayName != schemaName);
    // schema.tables = schemaTables.tables
    dispatch({
      type: GET_SCHEMA,
      payload: organizing(schema)
    })
  };
}

export function removeAllDataSourceSchemaFromModel(schemaName) {
  return dispatch => {

    dispatch({
      type: GET_SCHEMA,
      payload: []
    })
  };
}