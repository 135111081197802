import ReactGA from "react-ga";
import Cookies from "js-cookie"
/**
 * category: name of event
 * action: name of area
 * label: name of user
 */
export const sendDataToGoogleAnalytics = (category, action) => {
  ReactGA.event({
    category: category,
    action: action,
    label: `${Cookies.get("authenticatedUser")}`,
  });
};
