import { monaco } from "react-monaco-editor"
import { formulas } from "./formulas";
import i18n from "../i18next";
import { store } from "../..";

/**
 * Converts `HTML` format to `Markdown` format
 * 
 * - `html`: The `HTML` string that will be converted to `markdown`
 * - `isCodeBlock`: If it is `true`, `<b>` tags will be removed.
 */
const htmlToMarkdown = ({ html = String, isCodeBlock = Boolean, isHoverContent = Boolean }) => {
  let markdown = html;

  markdown = markdown.replace(/<b>|<\/b>/g, isCodeBlock ? "" : "`");
  markdown = markdown.replace(/<br\/>|<br \/>|<br>/g, isHoverContent && !isCodeBlock ? "<br />" : "\n");
  markdown = markdown.replace(/<\/li>/g, "\n");
  markdown = markdown.replace(/&emsp;/g, "\t");
  markdown = markdown.replace(/<\/br>/g, "");
  markdown = markdown.replace(/<ul>|<\/ul>/g, "");
  markdown = markdown.replace(/<li>/g, "- ");

  return markdown;
}

/**
 *  Returns all of the formulas in one array.
 */
const createFormulasArray = () => {
  let formulaList = [];
  for (let formulaGroup of Object.keys(formulas)) {
    formulaList = [
      ...formulaList,
      ...formulas[formulaGroup].formulas
    ];
  }

  for (let formula of formulaList) {
    let usages = "";
    let documentationContent = "";

    let hoverContents = [];

    let translateString = `FormulaEditor.Formulas.${formula.key}.Description`;
    let description = i18n.t(translateString);

    if (formula.usages && formula.usages.length > 0) {
      for (let index = 0; index < formula.usages.length; index++) {
        let usage = `${formula.usages.length > 1 ? `\n-- ${i18n.t("FormulaEditor.Type")} ${index + 1}\n` : "\n"}`;
        let formattedUsage = htmlToMarkdown({
          html: formula.usages[index],
          isCodeBlock: true
        });

        usage += formattedUsage;
        usages += `${usage}\n`;
      }
    }

    if (description !== translateString) {
      description = `__${formula.name}__\n\n${description}\n`;

      documentationContent += htmlToMarkdown({
        html: description,
        isCodeBlock: false,
        isHoverContent: false
      });

      hoverContents.push({
        value: htmlToMarkdown({
          html: description,
          isCodeBlock: false,
          isHoverContent: true
        }),
        supportHtml: true
      });
    }

    if (usages.length > 0) {
      documentationContent += `\n---\n\n__${i18n.t("FormulaEditor.Usage")}__\n\n${"```"}\n${usages}${"```"}`;

      hoverContents.push({
        value: `__${i18n.t("FormulaEditor.Usage")}__\n${"```"}\n${usages}${"```"}`,
        supportHtml: false
      });
    }

    if (documentationContent.length > 0 && hoverContents.length > 0) {
      formula.documentationContent = documentationContent
      formula.hoverContents = hoverContents
    }
  }

  return formulaList;
}

/**
 * All formulas are in one array
 */
let formulasArray = createFormulasArray();

/**
 * Language Configurations.
 */
const languageConfiguration = {
  comments: {
    lineComment: "--",
    blockComment: ["/*", "*/"]
  },

  brackets: [
    ["{", "}"],
    ["[", "]"],
    ["(", ")"]
  ],

  autoClosingPairs: [
    { open: "{", close: "}" },
    { open: "[", close: "]" },
    { open: "(", close: ")" },
    { open: '"', close: '"' },
    { open: "'", close: "'" }
  ],

  surroundingPairs: [
    { open: "{", close: "}" },
    { open: "[", close: "]" },
    { open: "(", close: ")" },
    { open: '"', close: '"' },
    { open: "'", close: "'" }
  ]
};

/**
 * Language def
 */
const languageDef = {
  ignoreCase: true,

  brackets: [
    { open: "[", close: "]", token: "delimiter.square" },
    { open: "(", close: ")", token: "delimiter.parenthesis" }
  ],

  keywords: [
    "ACCESSIBLE",
    "ADD",
    "ALL",
    "ALTER",
    "ANALYZE",
    "AND",
    "AS",
    "ASC",
    "ASENSITIVE",
    "BEFORE",
    "BETWEEN",
    "BIGINT",
    "BINARY",
    "BLOB",
    "BOTH",
    "BY",
    "CALL",
    "CASCADE",
    "CASE",
    "CHANGE",
    "CHAR",
    "CHARACTER",
    "CHECK",
    "COLLATE",
    "COLUMN",
    "CONDITION",
    "CONSTRAINT",
    "CONTINUE",
    "CONVERT",
    "CREATE",
    "CROSS",
    "CUBE",
    "CUME_DIST",
    "CURRENT_DATE",
    "CURRENT_TIME",
    "CURRENT_TIMESTAMP",
    "CURRENT_USER",
    "CURSOR",
    "DATABASE",
    "DATABASES",
    "DAY_HOUR",
    "DAY_MICROSECOND",
    "DAY_MINUTE",
    "DAY_SECOND",
    "DEC",
    "DECIMAL",
    "DECLARE",
    "DEFAULT",
    "DELAYED",
    "DELETE",
    "DENSE_RANK",
    "DESC",
    "DESCRIBE",
    "DETERMINISTIC",
    "DISTINCT",
    "DISTINCTROW",
    "DIV",
    "DOUBLE",
    "DROP",
    "DUAL",
    "EACH",
    "ELSE",
    "ELSEIF",
    "EMPTY",
    "ENCLOSED",
    "END",
    "ESCAPED",
    "EXCEPT",
    "EXISTS",
    "EXIT",
    "EXPLAIN",
    "FALSE",
    "FETCH",
    "FIRST_VALUE",
    "FLOAT",
    "FLOAT4",
    "FLOAT8",
    "FOR",
    "FORCE",
    "FOREIGN",
    "FROM",
    "FULLTEXT",
    "FUNCTION",
    "GENERATED",
    "GET",
    "GRANT",
    "GROUP",
    "GROUPING",
    "GROUPS",
    "HAVING",
    "HIGH_PRIORITY",
    "HOUR_MICROSECOND",
    "HOUR_MINUTE",
    "HOUR_SECOND",
    "IF",
    "IGNORE",
    "IN",
    "INDEX",
    "INFILE",
    "INNER",
    "INOUT",
    "INSENSITIVE",
    "INSERT",
    "INT",
    "INT1",
    "INT2",
    "INT3",
    "INT4",
    "INT8",
    "INTEGER",
    "INTERVAL",
    "INTO",
    "IO_AFTER_GTIDS",
    "IO_BEFORE_GTIDS",
    "IS",
    "ITERATE",
    "JOIN",
    "JSON_TABLE",
    "KEY",
    "KEYS",
    "KILL",
    "LAG",
    "LAST_VALUE",
    "LATERAL",
    "LEAD",
    "LEADING",
    "LEAVE",
    "LEFT",
    "LIKE",
    "LIMIT",
    "LINEAR",
    "LINES",
    "LOAD",
    "LOCALTIME",
    "LOCALTIMESTAMP",
    "LOCK",
    "LONG",
    "LONGBLOB",
    "LONGTEXT",
    "LOOP",
    "LOW_PRIORITY",
    "MASTER_BIND",
    "MASTER_SSL_VERIFY_SERVER_CERT",
    "MATCH",
    "MAXVALUE",
    "MEDIUMBLOB",
    "MEDIUMINT",
    "MEDIUMTEXT",
    "MIDDLEINT",
    "MINUTE_MICROSECOND",
    "MINUTE_SECOND",
    "MOD",
    "MODIFIES",
    "NATURAL",
    "NOT",
    "NO_WRITE_TO_BINLOG",
    "NTH_VALUE",
    "NTILE",
    "NULL",
    "NUMERIC",
    "OF",
    "ON",
    "OPTIMIZE",
    "OPTIMIZER_COSTS",
    "OPTION",
    "OPTIONALLY",
    "OR",
    "ORDER",
    "OUT",
    "OUTER",
    "OUTFILE",
    "OVER",
    "PARTITION",
    "PERCENT_RANK",
    "PRECISION",
    "PRIMARY",
    "PROCEDURE",
    "PURGE",
    "RANGE",
    "RANK",
    "READ",
    "READS",
    "READ_WRITE",
    "REAL",
    "RECURSIVE",
    "REFERENCES",
    "REGEXP",
    "RELEASE",
    "RENAME",
    "REPEAT",
    "REPLACE",
    "REQUIRE",
    "RESIGNAL",
    "RESTRICT",
    "RETURN",
    "REVOKE",
    "RIGHT",
    "RLIKE",
    "ROW",
    "ROWS",
    "ROW_NUMBER",
    "SCHEMA",
    "SCHEMAS",
    "SECOND_MICROSECOND",
    "SELECT",
    "SENSITIVE",
    "SEPARATOR",
    "SET",
    "SHOW",
    "SIGNAL",
    "SMALLINT",
    "SPATIAL",
    "SPECIFIC",
    "SQL",
    "SQLEXCEPTION",
    "SQLSTATE",
    "SQLWARNING",
    "SQL_BIG_RESULT",
    "SQL_CALC_FOUND_ROWS",
    "SQL_SMALL_RESULT",
    "SSL",
    "STARTING",
    "STORED",
    "STRAIGHT_JOIN",
    "SYSTEM",
    "TABLE",
    "TERMINATED",
    "THEN",
    "TINYBLOB",
    "TINYINT",
    "TINYTEXT",
    "TO",
    "TRAILING",
    "TRIGGER",
    "TRUE",
    "UNDO",
    "UNION",
    "UNIQUE",
    "UNLOCK",
    "UNSIGNED",
    "UPDATE",
    "USAGE",
    "USE",
    "USING",
    "UTC_DATE",
    "UTC_TIME",
    "UTC_TIMESTAMP",
    "VALUES",
    "VARBINARY",
    "VARCHAR",
    "VARCHARACTER",
    "VARYING",
    "VIRTUAL",
    "WHEN",
    "WHERE",
    "WHILE",
    "WINDOW",
    "WITH",
    "WRITE",
    "XOR",
    "YEAR_MONTH",
    "ZEROFILL"
  ],

  operators: [
    "+",
    "-",
    "*",
    "/",
    "=",
    "<",
    ">",
    "<=",
    ">=",
    "<>",
    "AND",
    "BETWEEN",
    "IN",
    "LIKE",
    "NOT",
    "OR",
    "IS",
    "NULL",
    "OUTER",
    "INNER",
    "INTERSECT",
    "UNION",
    "JOIN",
  ],

  builtinFunctions: formulasArray.map(formula => formula.name),

  tokenizer: {
    root: [
      { include: "@tables" },
      { include: "@comments" },
      { include: "@whitespace" },
      { include: "@numbers" },
      { include: "@strings" },
      { include: "@scopes" },
      [/[;,.]/, "delimiter"],
      [/[()]/, "@brackets"],
      [
        /[\w@]+/,
        {
          cases: {
            "@operators": "operator",
            "@builtinFunctions": "predefined",
            "@keywords": "keyword",
            "@default": "identifier"
          }
        }
      ],
      [/[<>=!%&+\-*/|~^]/, "operator"]
    ],

    tables: [
      [/"/, { token: "table", next: "@table" }]
    ],

    table: [
      [/[^"]+/, "table"],
      [/""/, "table"],
      [/"/, { token: "table", next: "@pop" }]
    ],

    whitespace: [[/\s+/, "white"]],

    comments: [
      [/--+.*/, "comment"],
      [/\/\*/, { token: "comment.quote", next: "@comment" }]
    ],

    comment: [
      [/[^*/]+/, "comment"],
      [/\*\//, { token: "comment.quote", next: "@pop" }],
      [/./, "comment"]
    ],

    numbers: [
      [/0[xX][0-9a-fA-F]*/, "number"],
      [/[$][+-]*\d*(\.\d*)?/, "number"],
      [/((\d+(\.\d*)?)|(\.\d+))([eE][\-+]?\d+)?/, "number"]
    ],

    strings: [
      [/'/, { token: "string", next: "@string" }]
    ],

    string: [
      [/[^']+/, "string"],
      [/''/, "string"],
      [/'/, { token: "string", next: "@pop" }]
    ],

    scopes: []
  }
};

/**
 * Creates keyword suggestions array.
 */
const createKeywordSuggestions = ({ range = Object }) => {
  let keywords = languageDef.keywords;

  if (keywords.length > 0) {
    return keywords.map((keyword) => ({
      label: keyword,
      kind: monaco.languages.CompletionItemKind.Keyword,
      insertText: keyword,
      range: range
    }));
  }

  return [];
};

/**
 * Creates formula suggestions array.
 */
const createFormulaSuggestions = ({ range = Object }) => {
  let formulaSuggestions = [];

  for (let functionType of Object.keys(formulas)) {
    let detail = i18n.t(`FormulaEditor.FunctionType.${formulas[functionType].type}`);
    let formulaList = formulas[functionType].formulas;

    if (formulaList && formulaList.length > 0) {
      let suggestions = formulaList.map((formula) => {
        let documentation = formula.documentationContent ? formula.documentationContent : "No documentation yet.";

        return {
          label: formula.name,
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: formula.insertText,
          preselect: true,
          detail: detail,
          documentation: {
            value: documentation,
          },
          range: range
        }
      });

      formulaSuggestions = [
        ...formulaSuggestions,
        ...suggestions
      ];
    }
  }

  return formulaSuggestions;
};

/**
 * Creates suggestions object that includes:
 * - Tables of the selected model
 * - Formulas
 * - Keywords
 * 
 * Suggestions are shown anywhere.
 */
const createDefaultSuggestions = ({ range = Object }) => {
  let variableSuggestions = createVariableSuggestion({ range });
  let formulaSuggestions = createFormulaSuggestions({ range });
  let keywordSuggestions = createKeywordSuggestions({ range });

  return [
    ...variableSuggestions,
    ...formulaSuggestions,
    ...keywordSuggestions,
  ];
};

/**
 * Creates suggestion object for variables such as:
 *  - ${session.___}
 *  - ${exp.___}
 */
const createVariableSuggestion = ({ range = Object, triggerType }) => {
  let suggestions = [];
  let insertText = "${label.${1}}";

  switch (triggerType) {
    case "dollar":
      insertText = "{label.${1}}";
      break;
    case "dollarAndCurlyBraces":
      insertText = "label.${1}";
      break;
    default:
      break;
  }

  suggestions.push({
    label: "session",
    kind: monaco.languages.CompletionItemKind.Variable,
    insertText: insertText.replace("label", "session"),
    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    range: range,
    command: {
      id: 'editor.action.triggerSuggest'
    }
  });

  return suggestions;
};

/**
 * Creates suggestion object for session variables.
 */
const createSessionSuggestion = ({ range = Object }) => {
  let state = store.getState();
  let sessionVariables = state.SessionVariableReducer.sessionVariables;
  let suggestions = [];

  sessionVariables?.values()?.forEach(variable => { // eslint-disable-line
    let suggestionObj = {
      label: variable.name || "",
      kind: monaco.languages.CompletionItemKind.Variable,
      insertText: variable.name || "",
      range: range
    }

    suggestions.push(suggestionObj);
  });

  return suggestions;
};

/**
 * Creates the completion item provider for autocomplete.
 */
const completionItemProvider = () => ({
  triggerCharacters: ['.', '"', '$', '{', '('],

  provideCompletionItems: (model, position) => {
    /**
     * Empty suggestions object.
     */
    let emptySuggestions = {
      suggestions: []
    };

    /**
     * Characters that can change the situation.
     */
    let characters = {
      dots: ["."],
      singleQuotationMarks: ["'", "`"],
      doubleQuotationMarks: ['"'],
      allowedForDoubleQuotionMark: ["(", "\n", "\t", " ", ""],
      dollar: "$",
      dollarAndCurlyBrace: "${",
      closedCurlyBrace: "}"
    };

    /**
     * The part of the code until cursor.
     */
    let textUntilPosition = model.getValueInRange({
      startLineNumber: 1,
      startColumn: 1,
      endLineNumber: position.lineNumber,
      endColumn: position.column
    });

    let word = model.getWordUntilPosition(position);

    let range = {
      startLineNumber: position.lineNumber,
      endLineNumber: position.lineNumber,
      startColumn: word.startColumn,
      endColumn: word.endColumn
    };

    let lastCharacter = textUntilPosition.slice(-1);
    let lastTwoCharacter = textUntilPosition.slice(-2);

    let isLastCharacterDot = characters.dots.includes(lastCharacter);
    let isLastSingleQuotationMark = characters.singleQuotationMarks.includes(lastCharacter);
    let isLastCharacterDoubleQuotionMark = characters.doubleQuotationMarks.includes(lastCharacter);
    let isLastCharacterDollar = characters.dollar === lastCharacter;
    let isLastCharacterDollarAndCurlyBrace = characters.dollarAndCurlyBrace === lastTwoCharacter;
    let isLastCharacterClosedCurlyBrace = characters.closedCurlyBrace === lastCharacter;

    let lastIndexOfClosedCurlyBrace = textUntilPosition.lastIndexOf("}");
    let lastIndexOfDoubleQuotationMark = textUntilPosition.lastIndexOf('"');
    let lastIndexOfDot = textUntilPosition.lastIndexOf('.');

    let quotationMark = false;
    let sessionSuggestion = false;

    if (isLastCharacterDollarAndCurlyBrace || isLastCharacterDollar) {
      let triggerType = isLastCharacterDollarAndCurlyBrace ? "dollarAndCurlyBraces" : "dollar";

      return {
        suggestions: createVariableSuggestion({
          range: range,
          triggerType: triggerType
        })
      };
    }

    

    if (lastIndexOfDot !== -1) {
      let sesssionVariableIdentifierBeforeDot = textUntilPosition.substring(lastIndexOfDot - 9, lastIndexOfDot) === "${session";
      let closedCurlyBraceAfterDot = lastIndexOfClosedCurlyBrace > lastIndexOfDot;

      if (
        sesssionVariableIdentifierBeforeDot &&
        !closedCurlyBraceAfterDot
      ) {
        sessionSuggestion = true;
      }
    }

    if (sessionSuggestion) {
      return {
        suggestions: createSessionSuggestion({
          range: range
        })
      };
    }

    if (
      isLastCharacterDot ||
      isLastSingleQuotationMark ||
      isLastCharacterDoubleQuotionMark ||
      isLastCharacterClosedCurlyBrace
    ) {
      return emptySuggestions;
    }

    return {
      suggestions: createDefaultSuggestions({
        range: range
      })
    }
  }
});

/**
 * Creates the hover provider for functions.
 */
const hoverProvider = () => ({
  provideHover: (model, position) => {
    /**
     * Word at the cursor position.
     */
    let wordAtPosition = model.getWordAtPosition(position);

    if (wordAtPosition) {
      for (let formula of formulasArray) {
        if (wordAtPosition.word === formula.key && formula.hoverContents) {
          return {
            contents: formula.hoverContents
          };
        }
      }
    }
  }
});

/**
 * **Init Visp Language for Default Filters**
 * 
 * Registers a visp language in monaco and
 * returns the `ID` of the `registered language`.
 *
 */
const initDefaultFilterLang = () => {
  let languageId = `visp-default-filters`;

  let registeredLangauges = monaco.languages.getLanguages();
  let filteredLanguages = registeredLangauges.filter(language => language.id === languageId);

  if (filteredLanguages.length === 0) {
    let language = {
      id: languageId,
      extensions: [],
      filenames: [],
      filenamePatterns: [],
      firstLine: "",
      aliases: [],
      mimetypes: [],
      configuration: "",
    };

    monaco.languages.register(language);
    monaco.languages.setMonarchTokensProvider(languageId, languageDef);
    monaco.languages.setLanguageConfiguration(languageId, languageConfiguration);
    monaco.languages.registerHoverProvider(languageId, hoverProvider());
    monaco.languages.registerCompletionItemProvider(
      languageId,
      completionItemProvider()
    );
  }

  return languageId;
};

export default initDefaultFilterLang;