export const ADD_DRILL_DOWNS = "ADD_DRILL_DOWNS";
export const TRIGGERED_DRILL_DOWNS = "TRIGGERED_DRILL_DOWNS";
export const COLUMN_CLICKED_AND_SHOULD_BE_REMOVED = "COLUMN_CLICKED_AND_SHOULD_BE_REMOVED";

/**
 * 
 * @param {*} drillDowns 
 * @returns 
 * 
 * Sets the drill down information of the plugins to the store
 */
export function setPluginsDrillDowns(drillDowns) {
  return (dispatch) => {
    dispatch({
      type: ADD_DRILL_DOWNS,
      payload: drillDowns,
    });
  };
}

/**
 * 
 * @param {*} triggeredDrillDowns 
 * @returns 
 * Stores triggered drilldown information
 */
export function setTriggeredDrillDowns(triggeredDrillDowns) {
  return (dispatch) => {
    dispatch({
      type: TRIGGERED_DRILL_DOWNS,
      payload: triggeredDrillDowns,
    });
  };
}

/**
 * 
 * @param {*} triggeredDrillDowns 
 * @returns 
 * Stores triggered drilldown information
 */
 export function setpluginColumnClickedAndShouldBeRemoved(clickedColumns) {
  return (dispatch) => {
    dispatch({
      type: COLUMN_CLICKED_AND_SHOULD_BE_REMOVED,
      payload: clickedColumns,
    });
  };
}

