export const configuration = {
    size: 200,
    font: "Open Sans",
    maxValue: 1,
    dontShowError: true,
    duration: 1000,
    roundCorners: false,
    showHideButton: false,
};

export const actions = [];

export const reactions = [
    {
        id: "filter",
        name: "Filtre",
        description: "desc87",
        type: "general"
    }
];

export const conversionMap = {
    "table": {
        columnMap: {
            columns: {
                name: "Columns",
                from: ["measure", "target"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
    "measure-tile": {
        columnMap: {
            measure: {
                required: true,
                minimumColumnSize: 1,
                multiple: true,
                from: ["measure", "target"]
            }
        }
    },
    "gauge-chart": {
        columnMap: {
            measure: {
                required: true,
                minimumColumnSize: 1,
                multiple: true,
                from: ["measure"]
            },
            maximum: {
                required: false,
                multiple: false,
                minimumColumnSize: 0,
                from: []
            },
            minimum: {
                required: false,
                multiple: false,
                minimumColumnSize: 0,
                from: []
            },
            target: {
                required: false,
                multiple: false,
                minimumColumnSize: 0,
                from: ["target"]
            },
        }
    }
};