import React, { Component } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import i18n from "../../Utils/i18next";
import { connect } from "react-redux";
import { changePluginLoaderVisibility } from "../../ui/GeneralComponents/PluginLoader/PluginLoaderAction";

//Plugin loader component
class PluginLoader extends Component {
  render() {
    const pluginLoaderStatus = this.props.state.PluginLoaderReducer
    const isPluginLoaderActive = pluginLoaderStatus.waitForLoadPlugins.size > 0 && pluginLoaderStatus.waitForLoadPlugins.has(this.props.pluginId)
    
    let style = this.props.style ? this.props.style : {}

     style.display = (this.props.isFirstRender || isPluginLoaderActive) && this.props.hasError ? "flex" : "none"
    return (
      <div
        style={style}
        className={"plugin-loader"}
      >
        <div className={"spin-area"}>
          <Spin
            tip={i18n.t("Loading") + "..."}
            size="large"
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          />
        </div>
      </div>
    );
  }
}

/*
* dispatchs change plugin loader visibility function
*/
const mapDispatchToProps = {
  changePluginLoaderVisibility,
};

/*
* returns change plugin loader visibility state
*/
const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PluginLoader);
