import React, { Component } from "react";
import { get } from "../../Utils/WebService";
import { API_BASE, BASE_URL_UI, exculudeLabels } from "../../config";
import { showNotificationWithIcon } from "../../Utils/Notification";
import { navigate } from "../../Utils/Global";
import Selector from "../GeneralComponents/Selector/Selector";
import PopupContainer from "../GeneralComponents/PopupContainer/PopupContainer";
import Button from "../GeneralComponents/Button/Button";
import i18n from "../../Utils/i18next";
import { isValidWriteRoles } from "../DashboardPage/RoleStore";
import { labelTranslateNameSet } from "../../Utils/LabelOperations";

class LabelFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      labelList: [],
    };
  }

  /**
   * fetch labels from api every time component mounted
   */
  componentDidMount() {
    this.getLabel();
  }

  /**
   * 
   * @param {*} labelList 
   * @returns 
   * 
   * It returns the relevant labels according to the authorization of the login user.
   */
  getValidLabels = (labelList) => {
    let validLabels = labelList;

    if (isValidWriteRoles() !== true) {
      validLabels = [];

      labelList.filter((label) => {
        if (label.displayName !== "Deleted") {
          validLabels.push(label);
        }
      });
    }

    return validLabels;
  };

  /**
   * fetch labels from api witchout any filter
   */
  getLabel = () => {
    let url = `${API_BASE}/labels`;

    const successFunc = (result) => {
      // Filter the result data  by exculudeLabels set
      result.data = result.data.filter(d => !exculudeLabels.has(d.name));
      
      let labels = labelTranslateNameSet(result.data);

      this.setState({
        labelList: this.getValidLabels(labels),
      });
    };

    get(url, successFunc);
  };

  /**
   * catch selected label and pass it to the parent components to take them required actions
   * @param {*} data
   */
  confirmSelectLabel = (label) => {
    this.props.changeSelectedLabels(label);
  };

  /**
   * update label list which will be shown in the list needed for search between labels
   * @param {*} givenList
   */
  updateList = (givenList) => {
    this.setState({ ...this.state, labelList: givenList });
  };

  render() {
    return (
      <div style={{ display: this.props.visibleSelector ? "block" : "none" }}>
        <PopupContainer width="300px" position="left">
          <Selector
            list={this.state.labelList}
            selecteds={this.props.selectedLabels}
            updateList={this.updateList}
            select={this.confirmSelectLabel}
            maxHeight={285}
          ></Selector>
          <Button
            style={{
              position: "absolute",
              top: "3px",
              right: "3px",
              borderRadius: "40px",
              padding: "6px 11px",
              backgroundColor: "white",
              zIndex: 1,
            }}
            type={"default"}
            onClick={this.props.closeFilter}
          >
            X
          </Button>
        </PopupContainer>
      </div>
    );
  }
}

export default LabelFilter;
