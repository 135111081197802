import React, { Component } from "react";
import { Modal, Tabs } from "antd";
import UploadTopoJson from "./UploadTopoJson";
import EditTopoJson from "./EditTopoJson";
import i18n from "../../../Utils/i18next";
import ListTopoJson from "./ListTopoJson";

const { TabPane } = Tabs;

/**
 * Map operations
 */
export default class MapTopoJsonOperations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: "1",
      editMode: false,
      selectedTopoJson: undefined
    };
  }

  /**
   * close popup
   */
  handleCancel = e => {
    this.props.changeUploadVisible(false);
    this.topoJsonEditMode(false, undefined)
  };

  /**
   * set topojson edit mode
   * @status boolean
   * @selectedTopoJson map object
   */
  topoJsonEditMode = (status, selectedTopoJson) => {
    this.setState({
      ...this.state,
      editMode: status,
      selectedTopoJson: selectedTopoJson
    });
  };

  /**
   * topojson list component content
   */
  listTopoJsonContent = () => {
    return (
      <ListTopoJson
        allTopoJsons={this.props.allTopoJsons}
        topoJsonEditMode={this.topoJsonEditMode}
        getTopoJsonListForMap={this.props.getTopoJsonListForMap}
      />
    );
  };

  /**
   * topojson edit component content
   */
  editTopoJsonContent = () => {
    return (
      <EditTopoJson
        allTopoJsons={this.props.allTopoJsons}
        changeUploadVisible={this.props.changeUploadVisible}
        getTopoJsonListForMap={this.props.getTopoJsonListForMap}
        selectedTopoJson={this.state.selectedTopoJson}
        topoJsonEditMode={this.topoJsonEditMode}
      />
    );
  };

  /**
   * decide to render list and edit component 
   * @editMode boolean
   */
  decideListOrEdit = editMode => {
    if (editMode) {
      return this.editTopoJsonContent();
    } else {
      return this.listTopoJsonContent();
    }
  };

  render() {
    let listOrEditTopojsonContent = this.decideListOrEdit(this.state.editMode);
    
    return this.props.visible ? (
      <Modal
        title={i18n.t("Dashboard.Settings.TopoJSON.MapTopoJsonOperations")}
        visible={this.props.visible}
        onCancel={this.handleCancel}
        footer={[]}
        className={"mapTopojsonOperator"}
      >
        <Tabs
          defaultActiveKey={"1"}
          size={"small"}
          className={"what-if-tab"}
          activeKey={this.state.activeKey}
          onChange={key => this.setState({ activeKey: key })}
        >
          <TabPane
            tab={i18n.t("Dashboard.Settings.TopoJSON.UploadMap")}
            key="1"
          >
            <UploadTopoJson
              changeUploadVisible={this.props.changeUploadVisible}
              getTopoJsonListForMap={this.props.getTopoJsonListForMap}
            />
          </TabPane>
          <TabPane tab={i18n.t("Dashboard.Settings.TopoJSON.EditMap")} key="2">
            {listOrEditTopojsonContent}
          </TabPane>
        </Tabs>
      </Modal>
    ) : (
      ""
    );
  }
}