import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import PopupContainer from "../GeneralComponents/PopupContainer/PopupContainer";
import { Divider } from "antd";
import Tooltip from "../GeneralComponents/Tooltip/Tooltip";
import Text from "../GeneralComponents/Text/Text";
import { authenticatedUser } from "../../Utils/Auth";
import { getIcon } from "../../Utils/Global";

/**
 * User Menu Component
 */
class UserMenu extends Component {
  onSelectDropdown = (eventKey, obj) => {
    let event = {
      function: JSON.parse(eventKey)?.function
    };

    if (this.props.report) {
      event["report"] = this.props.report;
    }

    this.props.responseFunc({ event, obj });
  };

  /*
   * Returns icons sort area
   */
  returnIcons = (icons) => {
    if (Array.isArray(icons)) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "10%",
          }}
        >
          {icons.map((icon) => {
            return (
              <i
                className={icon.icon}
                style={
                  icons.length > 1
                    ? {
                      fontSize: "13px",
                      marginLeft: icon !== icons[0] ? "1px" : null,
                    }
                    : null
                }
              ></i>
            );
          })}
        </div>
      );
    } else {
      return;
    }
  };

  button = React.forwardRef(({ children, onClick }, ref) => {
    let buttonTheme = this.props.theme?.buttons?.profileButton;

    return (
      <div
        id="userMenuButton"
        ref={ref}
        class="btn btn-rounded blue-button"
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
       <style>
          {buttonTheme && `
            #userMenuButton {
                background-color: ${buttonTheme.backgroundColor};
                border-color: ${buttonTheme.textColor}33;
                color: ${buttonTheme.textColor};
            }

            #userMenuButton:hover,
            #userMenuButton:focus,
            #userMenuButton:active {
                background-color: ${buttonTheme.backgroundColorActive} !important;
                color: ${buttonTheme.textColorActive} !important;
                border-color: ${buttonTheme.textColorActive}33 !important;
            }
          `}
        </style>

        {children}
      </div >
    );
  });

  render() {
    let buttonTheme = this.props.theme?.buttons?.profileButton;
    let userFirstLetter = authenticatedUser?.substring(1, 0)?.toUpperCase() || "V";

    return (
      <Dropdown
        className={this.props.className}
        id={this.props.id}
        drop={this.props.drop ? this.props.drop : "right"}
        style={{
          display: "inline-block",
          float: this.props.pull == "right" ? "right" : null,
        }}
        onSelect={(e, label) =>
          this.onSelectDropdown(e, { type: "menu" })
        }
      >
        <Tooltip tooltip={this.props.tooltip}>
          <div style={{ display: "inline-block" }}>
            <Dropdown.Toggle
              variant={this.props.variant}
              as={this.button}
              id={"menu &nbsp;&nbsp;" + userFirstLetter}
            >
              {
                getIcon(
                  buttonTheme?.icon,
                  <span style={{ fontWeight: "bold" }}>{userFirstLetter}</span>
                )
              }
            </Dropdown.Toggle>
          </div>
        </Tooltip>
        <Dropdown.Menu>
          <PopupContainer
            className="UserMenu-popupcontainer"
            before="noBefore"
            width="200px"
            position={this.props.position}
            height={this.props.height}
          >
            <div
              style={{
                position: "relative",
                top: "-10px",
              }}
            >
              {" "}
              <div style={{ marginBottom: "3px" }}>
                <i class="btn login-user">
                  {userFirstLetter}
                </i>
              </div>
              <Text className="infoUser-user-menu">{authenticatedUser}</Text>
              <Divider
                style={{
                  margin: "3px",
                  position: "relative",
                  width: "198px",
                  left: "-18px",
                  marginTop: "5px",
                }}
              />
            </div>

            {
              this.props.data.map((d) => {
                return (
                  <Dropdown.Item
                    id={d.id}
                    href={d.href ? d.href : "javascript:;"}
                    eventKey={JSON.stringify(d)}
                    active={d.active}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <i class={d.icon} />
                      &nbsp;
                      &nbsp;
                      {d.title}
                    </div>
                  </Dropdown.Item>
                );
              })
            }
          </PopupContainer>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default UserMenu;
