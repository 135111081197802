import React, { Component } from "react";
import Text from "../../GeneralComponents/Text/Text";
import Tooltip from "../../GeneralComponents/Tooltip/Tooltip";
import { clearAllDrillDownsOnPlugins, clearAllInteractionsOnPlugins } from "../../../Utils/Global";

/**
 * Buttons to switch dashboard 
 */
export default class DashboardChangeButton extends Component {
  /**
   * For display current dashboard
   */
  updateItemClass = props => {
    let itemClass = "switch-dashboard-item";

    if (props.currentDashboardId === props.dashboard.id || (props.currDashboard && (props.currDashboard.mainDashboardId === props.dashboard.id || props.currDashboard.id === props.dashboard.id))) {
      itemClass = "switch-dashboard-item selected";
    }

    return itemClass;
  }; 

  /**
   * When clicked button, prepare and change dashboard
   */
  getDashboard = dashboard => {
    if (this.props.currentDashboardId !== dashboard.id) {
      dashboard.dashboardId = dashboard.id;
      dashboard.comeFromSwitchDashboard = this.props.defaultLabelId;
      dashboard.dashboardInformation = {}

      clearAllInteractionsOnPlugins()
      clearAllDrillDownsOnPlugins()

      this.props.updateNavigationsAfterSwitchDashboard(dashboard);
    }
  };

  render() {
    return (
      <Tooltip tooltip={this.props.dashboard.name} tooltipPlacement="right">
        <div
          key={"dashboard-button-" + this.props.dashboard.id}
          className={this.updateItemClass(this.props)}
          onClick={() => this.getDashboard(this.props.dashboard)}
        >
          <div className={"switch-dashboard-item-circle"}>
            <div className="inner-circle"></div>
          </div>
          <Text className="switch-dashboard-item-text">
            {this.props.dashboard.name}
          </Text>
        </div>
      </Tooltip>
    );
  }
}