import React, { Component } from "react";
import PropTypes from "prop-types";
import { Menu, Icon } from "antd";
import { Link } from "react-router-dom";

const { SubMenu } = Menu;

class Navigation extends Component {
  handleClick = e => {
    let selectedKey = e.key.split(".");
    let selectedItem = this.props.menuItems[selectedKey.shift()];

    for (let key of selectedKey) {
      selectedItem = selectedItem.subMenuItems[key];
    }

    selectedItem["key"] = e.key;
    this.props.onChange(selectedItem);
  };

  render() {
    const {
      mode,
      theme,
      menuItems,
      defaultSelectedKeys,
      selectedItem
    } = this.props;

    const getSubMenuItems = (key, index) => {
      const subMenuItems = menuItems[key].subMenuItems;

      return Object.keys(subMenuItems).map((subKey, subIndex) => (
        <Menu.Item key={index + "." + subIndex} onClick={this.handleClick}>
          <Link to={subMenuItems[subKey].path}>
            {subMenuItems[subKey].icon && (
              <Icon type={subMenuItems[subKey].icon} />
            )}
            {subMenuItems[subKey].text}
          </Link>
        </Menu.Item>
      ));
    };

    return (
      <div>
        <Menu
          theme={theme}
          defaultSelectedKeys={defaultSelectedKeys}
          selectedKeys={selectedItem ? [selectedItem.key.toString()] : ["0"]}
          mode={mode}
        >
          {Object.keys(menuItems).map((key, index) =>
            menuItems[key].subMenuItems ? (
              <SubMenu
                key={index}
                title={menuItems[key].text}
                children={getSubMenuItems(key, index)}
              />
            ) : (
              <Menu.Item key={index} onClick={this.handleClick}>
                <Link to={menuItems[key].path}>
                  <Icon type={menuItems[key].icon} />
                  {menuItems[key].text}
                </Link>
              </Menu.Item>
            )
          )}
        </Menu>
      </div>
    );
  }
}

Navigation.prototypes = {
  theme: PropTypes.string,
  mode: PropTypes.string,
  defaultSelectedKeys: PropTypes.array,
  menuItems: PropTypes.object.isRequired
};

Navigation.defaultProps = {
  theme: "dark",
  mode: "inline",
  defaultSelectedKeys: ["0"],
  menuItems: {}
};

export default Navigation;
