export const configuration = {
    minRadiusInPercent: 5,
    minRadiusInPx: 15,
    minRadiusUnit: "percent",
    maxRadiusInPercent: 10,
    maxRadiusInPx: 30,
    maxRadiusUnit: "percent",
    nodePadding: 10,
    tree: false,
    treeRadius: 15,
    treeHorizontal: false,
    draggability: true,
    collapsibility: true,
    linkHighlight: true,
    rootName: "ROOT"
};

export const actions = [
    {
        trigger: "click",
        type: "click",
        name: "Click",
        output: ["id", "label", "parentId", "linkWith", "measure"],
        description: ""
    }
];

export const reactions = [
    {
        id: "filter",
        name: "Filter",
        description: "desc87",
        type: "general"
    }
];

export const conversionMap = {
    "table": {
        columnMap: {
            columns: {
                name: "Columns",
                from: ["id", "label", "parentId", "measure"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
    "pivot-table": {
        columnMap: {
            rows: {
                name: "Rows",
                from: ["label"],
                multiple: true,
                minimumColumnSize: 0
            },
            columns: {
                name: "Columns",
                from: [],
                multiple: true,
                minimumColumnSize: 0
            },
            measures: {
                name: "Columns",
                from: ["measure"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
            hidden: {
                from: ["id", "linkWith", "parentId"]
            }
        }
    }
};