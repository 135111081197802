import React from "react";
import { BrowserRouter, HashRouter, Router, Route, Switch } from "react-router-dom";

import Navigation from "../Navigation/Navigation";
import DataSourceContent from "./DataSourceContent";
import ModelPage from '../Moddelling/modelPage/ModelContent';
import JoinLayout from "../Layout";

class DataSourcePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // selectedItem: {},
      tabMenuItems: [
        {
          text: "Datasource",
          icon: "user",
          path: `datasource`
        },
        {
          text: "Model",
          icon: "desktop",
          path: `model`
        },
        {
          text: "Join",
          icon: "pie-chart",
          path: `join`
        }
      ]
    };
  }

  onChange = selectedItem => {
    
    this.setState({
      ...this.state,
      selectedItem
    });
  };

  render() {
    const { tabMenuItems, selectedItem } = this.state;    

    return (
      <div>
        <HashRouter >
          <Navigation
            menuItems={tabMenuItems}
            selectedItem={selectedItem}
            onChange={this.onChange}
            mode={"horizontal"}
            theme={"light"}
          />
          <Switch>
            <Route exact path="/datasource" component={DataSourceContent} />
            <Route path="/model" component={ModelPage} />
            <Route path="/join" component={JoinLayout} />
          </Switch>
        </HashRouter>
      </div>
    );
  }
}

export default DataSourcePage;
