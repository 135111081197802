import { SET_NO_DATA_TITLE } from './SettingNoDataTitleAction';

const initialState = {
  data: "",
};

/**
 * Reducer for managing noDataTitle configuration in dashboard settings section.
 */
const noDataTitleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NO_DATA_TITLE:
      return { ...state, data: action.payload };
    default:
      return state;
  }
};

export default noDataTitleReducer;
