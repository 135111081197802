import React, { Component } from "react";
import { Modal, Form, Button, Select, Cascader } from "antd";
import { showNotificationWithIcon } from "../Utils/Notification";
import { post } from "../Utils/WebService";
import { API_BASE } from "../config";
import i18n from "../../Utils/i18next";

const host = API_BASE;
const api = "/join";
const url = host + api;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class JoinCreate extends Component {
  constructor(props) {
    super(props);

    this.handleCancel = this.handleCancel.bind(this);
    this.onModelSelected = this.onModelSelected.bind(this);
    this.factTableChanged = this.factTableChanged.bind(this);
    this.dimensionTableChanged = this.dimensionTableChanged.bind(this);
  }

  onModelSelected(modelAlias) {
    let selectedTables = this.createNewTableList(
      this.props.models.filter(m => m.id === modelAlias)[0].tables
    );
    this.props.onModelChanged(selectedTables);
  }

  createNewTableList(tables) {
    let selectedTables = [];

    tables.forEach(t => {
      let newColumns = [];
      t.columns.forEach(c => {
        newColumns.push({
          ...c
        });
      });

      let newTable = {
        ...t
      };
      newTable.columns = newColumns;

      selectedTables.push(newTable);
    });

    return selectedTables;
  }

  handleCancel = () => {
    this.props.form.resetFields();
    this.props.onCancel();
  };

  componentDidMount() {
    this.props.form.validateFields();
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        let joins = [];

        let factTable = values.factColumn[1].split(":")[0];
        let factColumn = values.factColumn[1].split(":")[1];
        let dimensionTable = values.dimensionColumns[1].split(":")[0];
        let dimensionColumn = values.dimensionColumns[1].split(":")[1];

        joins.push({
          modelId: values.model,
          factTableAlias: factTable,
          factColumnAlias: factColumn,
          dimensionTableAlias: dimensionTable,
          dimensionColumnAlias: dimensionColumn,
          joinType: values.joinType
        });

        post(
          url,
          joins,
          response => {
            showNotificationWithIcon(
              i18n.t("Model.JoinCreateSuccessfull"),
              null,
              "success"
            );
            this.props.onSave();
            this.props.form.resetFields(["dimensionColumns"]);
          },
          error => {
            showNotificationWithIcon(
              i18n.t("Model.JoinCreateFailed"),
              error.response.data.toString(),
              "error"
            );
          }
        );
      }
    });
  };

  factTableChanged(factValue) {
    let factAlias = "";

    if (factValue.length > 0) {
      factAlias = factValue[0];
    }

    let selectedTables = this.createNewTableList(this.props.tables);

    selectedTables.forEach(s => {
      if (s.aliasName === factAlias && s.tableType === "None") {
        s.tempTableType = "Fact";
      }

      if (
        s.aliasName === this.props.prevFactAlias &&
        s.tempTableType !== "None"
      ) {
        s.tableType = "None";
      }
    });

    this.props.onModelChanged(selectedTables, factAlias, true);
  }

  dimensionTableChanged(dimensionValue) {
    let dimensionAlias = "";

    if (dimensionValue.length > 0) {
      dimensionAlias = dimensionValue[0];
    }

    let selectedTables = this.createNewTableList(this.props.tables);

    selectedTables.forEach(s => {
      if (s.aliasName === dimensionAlias && s.tableType === "None") {
        s.tempTableType = "Dimension";
      }

      if (
        s.aliasName === this.props.prevDimensionAlias &&
        s.tempTableType !== "None"
      ) {
        s.tableType = "None";
      }
    });

    this.props.onModelChanged(selectedTables, dimensionAlias, false);
  }

  render() {
    const { Option } = Select;

    const modelOptions = this.props.models.map(m => (
      <Option key={m.id}> {m.displayName} </Option>
    ));

    const sourceData = this.props.tables
      .filter(s => {
        if (s.tempTableType) {
          return s.tempTableType !== "Dimension";
        }
        return s.tableType !== "Dimension";
      })
      .map(s => {
        return {
          label: s.displayName,
          value: s.aliasName,
          children: s.columns.map(c => {
            return {
              label: c.displayName,
              value: s.aliasName + ":" + c.aliasName
            };
          })
        };
      });

    const targetData = this.props.tables
      .filter(s => {
        if (s.tempTableType) {
          return s.tempTableType !== "Fact";
        }

        return s.tableType !== "Fact";
      })
      .map(s => {
        return {
          label: s.displayName,
          value: s.aliasName,
          children: s.columns.map(c => {
            return {
              label: c.displayName,
              value: s.aliasName + ":" + c.aliasName
            };
          })
        };
      });

    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched
    } = this.props.form;

    const modelError = isFieldTouched("model") && getFieldError("model");
    const factColumnError =
      isFieldTouched("factColumn") && getFieldError("factColumn");
    const dimensionColumnsError =
      isFieldTouched("dimensionColumns") && getFieldError("dimensionColumns");
    const joinTypeError =
      isFieldTouched("joinType") && getFieldError("joinType");

    const filter = (inputValue, path) => {
      return path.some(
        option =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
    };

    return (
      <Modal
        title="Join Creation"
        centered
        visible={this.props.visible}
        onCancel={this.handleCancel}
        footer={null}
      >
        <Form onSubmit={this.handleSubmit}>
          <Form.Item
            label="Model"
            validateStatus={modelError ? "error" : ""}
            help={modelError || ""}
          >
            {getFieldDecorator("model", {
              rules: [
                {
                  required: true,
                  message: "Please select a model!"
                }
              ]
            })(
              <Select
                showSearch
                style={{
                  width: "100%"
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={this.onModelSelected}
              >
                {modelOptions}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label="Fact Table Column"
            validateStatus={factColumnError ? "error" : ""}
            help={factColumnError || ""}
          >
            {getFieldDecorator("factColumn", {
              rules: [
                {
                  required: true,
                  message: "Fact Table Column should be selected!"
                }
              ]
            })(
              <Cascader
                options={sourceData}
                showSearch={{
                  filter
                }}
                onChange={this.factTableChanged}
              />
            )}
          </Form.Item>
          <Form.Item
            label="Dimension Tables Columns"
            validateStatus={dimensionColumnsError ? "error" : ""}
            help={dimensionColumnsError || ""}
          >
            {getFieldDecorator("dimensionColumns", {
              rules: [
                {
                  required: true,
                  message:
                    "At least one Dimension Table Column should be selected!"
                }
              ]
            })(
              <Cascader
                options={targetData}
                showSearch={{
                  filter
                }}
                onChange={this.dimensionTableChanged}
              />
            )}
          </Form.Item>
          <Form.Item
            label="Join Type"
            validateStatus={joinTypeError ? "error" : ""}
            help={joinTypeError || ""}
          >
            {getFieldDecorator("joinType", {
              rules: [
                {
                  required: true,
                  message: "Join Type should be selected!"
                }
              ]
            })(
              <Select
                showSearch
                style={{
                  width: "100%"
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                <Option value="Inner"> Inner </Option>
                <Option value="Outer"> Outer </Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item>
            <Button
              id="createJoinButton"
              type="primary"
              htmlType="submit"
              disabled={hasErrors(getFieldsError())}
            >
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const WrappedHorizontalLoginForm = Form.create({
  name: "horizontal_login"
})(JoinCreate);

export default WrappedHorizontalLoginForm;
