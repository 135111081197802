/**
 * Grid system matrix calculator
 */
class GridLocationCalculator {
  constructor() {
    this.matrix = [];
  }

  /**
   * Resize grid system by given row and column.
   * And Neccessary cells are marked by given plugins
   * @param {Plugins that will be shown on grid system} plugins 
   * @param {Grid System row count} rowCount 
   * @param {Grid System column count} colCount 
   */
  resizeGrid(plugins, rowCount, colCount) {
    this.matrix = []

    for(let row = 0; row < rowCount; row++) {
      this.matrix[row] = []
      for(let col = 0; col < colCount; col++) {
        this.matrix[row][col] = false
      }
    }

    this.markPluginAreaTrue(plugins)
  }

  /**
   * Deletes last row from grid system if its possible
   * @param {Screen's first row count} initialeRowCount 
   */
  deleteLastRow(initialeRowCount) {
    let lastRow = this.matrix[this.matrix.length - 1];

    if(lastRow == undefined) {
      return 0;
    }

    let isThereAnyPluginOnLastRow = lastRow.find(val => { 
      if(val === true) {
        return true;
      }

      return false;
    });

    if(isThereAnyPluginOnLastRow === true) {
      return 0;
    }

    if(initialeRowCount >= this.matrix.length) {
      return 0;
    }

    this.matrix.splice(this.matrix.length - 1, 1);
    return 1;
  }

  /**
   * Row count of the grid system
   */
  getLength() {
    return this.matrix.length;
  }

  /**
   * Finds the first suitable area for given plugin
   * @param {Plugin} plugin 
   */
  getPosition = (plugin) => {
    let matrixInner = [this.matrix.length]

    for (let i = 0; i < this.matrix.length; i++) {
      matrixInner[i] = [...this.matrix[i]]
    }

    for (let row = 0; row < matrixInner.length; row++) {
      for (let col = 0; col < matrixInner[row].length; col++) {
        if (matrixInner[row][col] == false) {
          let state = this.hasEnoughSpace(matrixInner, row, col, plugin);
          if (state.status === true) {
            return { x: col, y: row }
          } else if (state.x != undefined) {
            return state
          }
        }
      }
    }

    return { status: false, x: 0, y: matrixInner.length };
  }

  hasEnoughSpace = (matrixInner, r, c, plugin) => {
    for (let row = r; row < r + plugin.h && row < matrixInner.length; row++) {
      let colCount = 0
      let colLenght = matrixInner[row].length

      for (let col = c; col < c + plugin.w && col < colLenght; col++) {
        if (matrixInner[row][col] === true) {
          return { status: false }
        }

        //matrixInner[row][col] = true;
        colCount++
      }

      if (colCount === plugin.w && row === matrixInner.length - 1) {
        return { status: false, x: c, y: r }
      }
    }

    if (
      r + plugin.h > matrixInner.length ||
      c + plugin.w > matrixInner[r].length
    ) {
      return { status: false }
    }

    return { status: true }
  };

  markPluginAreaTrue = (plugins) => {
    plugins.forEach((plugin) => {
      this.markNecessaryCells(plugin.y, plugin.x, plugin, true)
    })
  }

  markNecessaryCells = (initialRow, initialCol, plugin, status) => {
    let rowLength = initialRow + plugin.h
    for (
      var row = initialRow;
      row < this.matrix.length && row < rowLength;
      row++
    ) {
      let colLength = initialCol + plugin.w;
      for (
        var col = initialCol;
        col < this.matrix[row].length && col < colLength;
        col++
      ) {
        this.matrix[row][col] = status
      }
    }
  };
}

export default GridLocationCalculator