import { Mentions } from 'antd';
import React, { Component } from 'react';
import $ from "jquery"
import { AiOutlineSend } from "react-icons/ai"
import { Spinner } from 'react-bootstrap';
import i18n from '../../../../../Utils/i18next';

class DashboardCommentAddCommentArea extends Component {
    constructor() {
        super();
        this.state = {
            lastClicked: "",
        };
    }

    componentDidMount() {
        $(".ant-mentions").find("textarea").attr("rows", "1").attr("max-rows", "3")
    }

    render() {
        let isUserOperationWrite = this.props.operationType === "WRITE" || this.props.operationType.includes("WRITE") ? true : false
        
        return (
            <div style={isUserOperationWrite ? {display: "flex"} : {display: "none"}}>
                <div className="dashboard-add-comment-area">
                    <Mentions
                        placement={"top"}
                        onChange={this.props.onChangeComment}
                        value={this.props.comment}
                        id={"addCommentBaseArea"}
                        onKeyPress={this.props.sendCommentWithEnter}
                        listHeight={3}
                        placeholder={i18n.t("DashboardComments.DashboardAdd")}>
                        {this.props.users ? this.props.users.map((user, i) => (
                            <Mentions.Option className="antd-mention-item" value={user.username}>{user.username}</Mentions.Option>
                        )) : null}
                    </Mentions>
                    <AiOutlineSend className={"send-button"} onClick={this.props.sendComment} />
                </div>
                {this.props.loading ? <Spinner animation="border" style={{color: "#5c7c9f", marginLeft: "5px"}} /> : null}
            </div>
        );
    }
}

export default DashboardCommentAddCommentArea;