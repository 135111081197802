import React, { Component } from "react";
import Button from "../../../../GeneralComponents/Button/Button";
import { SlidersOutlined, DownOutlined } from "@ant-design/icons";
import i18n from "../../../../../Utils/i18next";
import Tooltip from "../../../../GeneralComponents/Tooltip/Tooltip";
import { Popover } from "antd";
import ScenarioList from "./ScenarioList";
import { formatter } from "./Parameter";
import { getIcon } from "../../../../../Utils/Global";

/**
 * What if button with parameters.
 * A button to open What if popup.
 * An area for show parameters.
 * An dropdown for list to saved scenarios
 */
export default class WhatIfButtonWithParameters extends Component {
  parameterText = "";

  //Get parameters and prepare for tooltip and show area.
  prepareParameters = parameters => {
    this.parameterText = "";

    return (
      <div>
        {parameters.map((param, index) => {
          let text =
            param.displayName +
            ": " +
            formatter(param.currentValue, param.dataType);

          if (index !== parameters.length - 1) {
            text += ", ";
          }

          this.parameterText += text;

          return (
            <p style={{ marginBottom: "5px" }}>
              {param.displayName}:{" "}
              <span style={{ color: "#1f6fab", fontWeight: "bold" }}>
                {formatter(param.currentValue, param.dataType)}
              </span>
            </p>
          );
        })}
      </div>
    );
  };

  render() {
    let popoverContent = this.prepareParameters(this.props.parameters);

    return (
      <div className={"what-if-button-with-parameters"}>
        <Button
          onClick={this.props.whatIfOpen}
          className="btn btn-new dashboard-button circle-button open-button"
          style={{ overflow: "hidden" }}
          tooltip={i18n.t("Dashboard.Data.WhatIf.Title")}
          id={"whatIfButton"}
        >
          <style>
            {this.props.buttonTheme && `
              #whatIfButton {
                  background-color: ${this.props.buttonTheme?.backgroundColor};
                  color: ${this.props.buttonTheme?.textColor};
                  border-color: ${this.props.buttonTheme?.textColor}33;
              }

              #whatIfButton:hover,
              #whatIfButton:focus,
              #whatIfButton:active {
                  background-color: ${this.props.buttonTheme?.backgroundColorActive};
                  color: ${this.props.buttonTheme?.textColorActive};
                  border-color: ${this.props.buttonTheme?.textColorActive}33;
              }
            `}
          </style>
          {
            getIcon(
              this.props.buttonTheme?.icon,
              <SlidersOutlined className={"whatIf-icon"} style={{ fontSize: "25px", position: "relative" }} />,
              { fontSize: 25, position: "relative" }
            )
          }
        </Button>

        <Popover
          content={popoverContent}
          title={i18n.t("Dashboard.Data.WhatIf.Parameters")}
          trigger="hover"
        >
          <div
            id={"whatIfParameters"}
            onClick={this.props.whatIfOpen}
            style={{
              borderRadius:
                this.props.scenarios.length > 0 ? "0" : "0 30px 30px 0"
            }}
            className={"what-if-parameters dashboard-button"}
          >
            <style>
              {`
                #whatIfParameters {
                    background-color: ${this.props.buttonTheme?.backgroundColor};
                    color: ${this.props.buttonTheme?.textColor};
                    border-color: ${this.props.buttonTheme?.textColor}33;
                }

                #whatIfParameters:hover,
                #whatIfParameters:focus,
                #whatIfParameters:active {
                    background-color: ${this.props.buttonTheme?.backgroundColorActive};
                    color: ${this.props.buttonTheme?.textColorActive};
                    border-color: ${this.props.buttonTheme?.textColorActive}33;
                }
            `}
            </style>
            
            {this.parameterText}
          </div>
        </Popover>

        {this.props.scenarios.length > 0 ? (
          <ScenarioList
            readOnly={this.props.readOnly}
            removeScenario={this.props.removeScenario}
            scenarios={this.props.scenarios}
            updateCurrentValuesByScenario={
              this.props.updateCurrentValuesByScenario
            }
          />
        ) : null}
      </div>
    );
  }
}
