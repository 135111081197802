import React, { Component } from "react";
import { get } from "../../Utils/WebService";
import { API_BASE, BASE_URL_UI } from "../../config";
import PopupContainer from "../GeneralComponents/PopupContainer/PopupContainer";
import Button from "../GeneralComponents/Button/Button";
import i18n from "../../Utils/i18next";
import { Empty, Input, Row, Typography } from "antd";
import TitleWithIcon from "../GeneralComponents/TitleWithIcon";
import { deepCopy } from "../../Utils/Global";
import "./sliders.css"

const { Search } = Input;
const { Text } = Typography;

/**
 * Dashboard Slider List Component
 */
class Sliders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sliders: [],
            filteredSliders: [],
            searchValue: "",
        };
    }

    /**
     * fetch labels from api every time component mounted
     */
    componentDidMount() {
        this.getSliders();
    }

    /**
     * fetch sliders of tenant
     */
    getSliders = () => {
        let url = `${API_BASE}/dashboard-slider`;

        const successFunc = (result) => {
            this.setState({
                searchValue: "",
                sliders: result.data || [],
                filteredSliders: result.data || [],
            });
        };

        get(url, successFunc);
    };

    /**
     * * Filter list by using given value
     * @param {*} e - element which fired this function
     */
    onSearch = (e) => {
        let result = deepCopy(this.state.sliders);

        if (e.target.value) {
            result = result.filter((item) => {
                return item.name.toLowerCase().includes(e.target.value.toLowerCase());
            });
        }

        this.setState({
            searchValue: e.target.value,
            filteredSliders: result,
        });
    };

    render() {
        return (
            <div style={{ display: this.props.visible ? "block" : "none" }}>
                <PopupContainer width="300px" position="left">
                    <div>
                        <Row className={"ant-row-padding-full"}>
                            <Text>{i18n.t("DashboardSlider.Sliders")}</Text>
                        </Row>
                        <Row style={{ marginBottom: "10px" }}>
                            <Search
                                value={this.state.searchValue}
                                placeholder={i18n.t("Search")}
                                onChange={this.onSearch}
                            />
                        </Row>
                        <div
                            className={"dashboard-sliders"}
                            style={{ maxHeight: 285 }}
                        >
                            {
                                this.state.filteredSliders?.length > 0 ? this.state.filteredSliders?.map((item, index) => (
                                    <a
                                        key={item.id}
                                        href={`${BASE_URL_UI}/dashboard/?sliderId=${item.id}`}
                                    >
                                        <Row
                                            className={"dashboard-slider-item"}
                                            style={{
                                                textAlign: "left",
                                                paddingLeft: "15px"
                                            }}
                                        >
                                            <TitleWithIcon
                                                key={index}
                                                color={item.color || "red"}
                                                title={item.name}
                                                className="colored-shape-custom"
                                                titleStyle="title-style"
                                            ></TitleWithIcon>
                                        </Row>
                                    </a>
                                )) : (
                                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                                        <Empty
                                            description={
                                                this.state.sliders?.length > 0
                                                    ? i18n.t("DashboardSlider.EmptySearchResult")
                                                    : i18n.t("DashboardSlider.NoDataDescription")
                                            }
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <Button
                        style={{
                            position: "absolute",
                            top: "3px",
                            right: "3px",
                            borderRadius: "40px",
                            padding: "6px 11px",
                            backgroundColor: "white",
                            zIndex: 1,
                        }}
                        type={"default"}
                        onClick={this.props.close}
                    >
                        X
                    </Button>
                </PopupContainer>
            </div>
        );
    }
}

export default Sliders;
