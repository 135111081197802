import React, { Component } from "react";
import SelectPredicate from "./SelectPredicate";
import { Row, Col, Select, Input, Popover, Radio } from "antd";
import Button from "../../../GeneralComponents/Button/Button";
import Text from "../../../GeneralComponents/Text/Text";
import i18n from "../../../../Utils/i18next";
import { post } from "../../../../Utils/WebService";
import { API_BASE, QUERY_URL } from "../../../../config";
import { showNotificationWithIcon } from "../../../../Utils/Notification";
import "./defaultFilters.css";
import Tooltip from "../../../GeneralComponents/Tooltip/Tooltip";
import { deepCopy } from "../../../../Utils/Global";
import { DiGitCompare } from "react-icons/di"
import { store } from "../../../..";
import { checkTableIsNewColumnOrHasNewColumnFormula, setSessionVariableValues } from "../../PluginComponents/dataAndQuery";
import _ from "lodash";
import evaluate from "../../../../Utils/Evaluate";
import FilterCodeEditor from "./FilterCodeEditor";

const { Option } = Select;
const { TextArea } = Input;
const clone = require("rfdc")();

export default class SingleFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lsqlValue: "",
      lsqlType: this.props.filter.lsqlType === undefined ? "value" : this.props.filter.lsqlType,
      showLsqlArea: false,
      data: [],
      whereOperator: " AND ",
      open: false
    };
  }

  //Closes the popup when click out
  checkIsTargetSingleFilter = (e) => {
    //Multiselect feature control of dropdown menu
    if (e.target.tagName !== 'LI') {
      this.setState({
        open: false
      })
    }

  }

  componentWillUnmount() {
    document.removeEventListener("mouseup", this.handleClickOutside);
  }

  componentWillMount() {
    let newState = { ...this.state };

    if (this.props.filter && this.props.filter.lsql === true) {
      let lsqlValue;

      if (this.props.filter.lsqlValue) {
        lsqlValue = this.props.filter.lsqlValue;
      } else {
        lsqlValue = this.props.filter.value;
      }

      newState.lsqlType = this.props.filter.lsqlType === "session-variable" ? "formula" : this.props.filter.lsqlType;
      newState.lsqlValue = lsqlValue;
    }

    if (this.props.filter.whereOperator) {
      newState.whereOperator = this.props.filter.whereOperator
    }

    this.setState(newState)
  }

  componentDidMount() {
    if (this.props.filter && this.props.filter.dataType === "timestamp") {
      this.getData(true, this.props.filter, true);
    }

    document.addEventListener("mouseup", this.checkIsTargetSingleFilter);
  }

  getSelectOptions = (dataType) => {
    if (dataType === "timestamp") {
      return this.state.data.map((d) => (
        <Option value={d["1"]}>{d["1"].substring(0, d["1"].indexOf("."))}</Option>
      ))
    } else {
      return this.state.data.map((d) => (
        <Option value={d["1"]}>{d["1"]}</Option>
      ))
    }
  }

  //Checks popup visibility state
  popupVisibility = (open) => {
    if (open) {
      this.setState({
        open: open
      })
    }
  };

  /*
  * Input selector for single filter
  */
  inputSelector = (filter, isLsql) => {
    if (filter.aggregatable === true) {
      return (
        <Input
          key={filter.filterId}
          style={{ width: "100%" }}
          disabled={this.predicatedIsNotNullOrIsNull(filter) ? true : false}
          type={"number"}
          value={this.predicatedIsNotNullOrIsNull(filter) === false && filter.value}
          onChange={e =>
            this.onChangeFilterValue(e.target.value, filter.filterId)
          }
        />
      );
    } else {
      let filterValue = filter.value;

      if (filter.dataType === "timestamp" && !filter.lsql) {
        if (Array.isArray(filter.value) && filter.value.length > 0) {
          filterValue = filterValue.map(d => {
            if (d.includes(".")) {
              return d.substring(0, d.indexOf("."));
            }

            return d;
          })
        } else if (!Array.isArray(filter.value) && filter.value) {
          if (filter.value.includes(".")) {
            filterValue = filter.value.substring(0, filter.value.indexOf("."));
          }
        }
      }

      if (isLsql) {
        let isFilterValueNull = filter.value === null || filter.value === ""
        let isFilterLsqlTypeValue = filter.lsqlType === "value" && !Array.isArray(filter.value) && filter.value.split("\n").length > 1

        if (isFilterValueNull) {
          filterValue = []
        } else if (isFilterLsqlTypeValue) {
          filterValue = filter.value.split("\n").filter(val => val !== "")
        }
      }

      return (
        <Select
          key={filter.filterId}
          mode={"multiple"}
          style={{ width: "100%" }}
          getPopupContainer={(trigger) => {
            const parentFound = -1;
            let parentHtmlElement = trigger.parentNode;
            while (parentFound === -1) {
              if (parentHtmlElement.className.split(" ").indexOf("innerPopup") > -1) {
                break;
              } else {
                if (parentHtmlElement.nodeName.toLowerCase() === 'body') {
                  return parentHtmlElement;
                }
                parentHtmlElement = parentHtmlElement.parentNode;
              }
            }
            return parentHtmlElement;
          }}
          open={this.state.open}
          showSearch
          disabled={this.predicatedIsNotNullOrIsNull(filter) || isLsql ? true : false}
          loading={this.state.loadingStatus}
          value={this.predicatedIsNotNullOrIsNull(filter) || filter.value === null || filter.value === "" ? [] : filterValue}
          onChange={(value) => this.onChangeFilterValue(value, filter.filterId)}
          onDropdownVisibleChange={(open) => {
            this.getData(open, filter);
            this.popupVisibility(open);
          }}
          dropdownClassName={`default-filter-select-${this.props.pluginId}`}
        >
          {
            this.getSelectOptions(filter.dataType)
          }
        </Select>
      );
    }
  };

  /**
   * @param open dropdown open/close status
   * @param column
   * Get data by column for filter
   */
  getData = (open, column, getInitialData = undefined) => {
    if (!open) return;

    let queryColumn = { ...column };
    let reduxState = store.getState();
    let sessionVariableMatchRule = /\$\s*\{\s*session\.\w+\s*\}/g;
    let sessionVariableNameMatchRule = /\$\s*\{\s*session\.(\w+)\s*\}/g;

    let list = reduxState.NewColumnEditorReducer.contentObject.newColumnList;
    let sessionVariables = new Map(reduxState.SessionVariableReducer.sessionVariables);

    let sessionVariablesShouldExecuteNow = [];
    let usedSessionVariables = new Map();
    let dashboardSessionVariables = reduxState.SessionVariableReducer.dashboard;

    if (queryColumn.Code !== undefined) {
      queryColumn.Code = queryColumn.Code.replaceAll("\"", "`")
    } else if (queryColumn.Code == undefined && queryColumn.columnDefaultFormula) {
      queryColumn.Code = queryColumn.columnDefaultFormula.replaceAll("\"", "`")
    }

    if (queryColumn.formula !== undefined) {
      queryColumn.formula = queryColumn.formula.replaceAll("\"", "`")
    } else if (queryColumn.formula == undefined && queryColumn.columnDefaultFormula) {
      queryColumn.formula = queryColumn.columnDefaultFormula.replaceAll("\"", "`")
    }

    queryColumn.value = queryColumn.Code;
    queryColumn.orderBy = null;

    let matchedSessionVariables = queryColumn.value?.match(sessionVariableMatchRule);

    for (let d of matchedSessionVariables || []) {
      let name = d.replace(sessionVariableNameMatchRule, (m, p1) => p1);
      let variable = sessionVariables.get(name);

      if (variable && !usedSessionVariables.has(variable.name)) {
        let value = variable.dataType === "text" ? `'${variable.defaultValue}'` : variable.defaultValue;
        

        if (variable.scope === "dashboard") {
          value = dashboardSessionVariables.get(variable.name);
        } else {
          sessionVariablesShouldExecuteNow.push(variable._id);
        }

        usedSessionVariables.set(variable.name, value);
      }
    }


    const getFilterData = () => {
      let url = QUERY_URL + "/v3";

      if (usedSessionVariables.size) {
        queryColumn = setSessionVariableValues(queryColumn, usedSessionVariables, false);
      }

      let queryObj = {
        modelDisplayName: this.props.modelDisplayName,
        selectColumns: checkTableIsNewColumnOrHasNewColumnFormula([queryColumn], list),
        filterColumns: []
      };

      this.setState({
        ...this.state,
        loadingStatus: getInitialData ? false : true
      });

      const successFunc = response => {
        let data = response.data && _.uniqWith(response.data, _.isEqual);
        let timestampData = new Map();

        if (this.props.filter.dataType === "timestamp") {
          data.map(d => {
            timestampData.set(d[1].substring(0, d[1].indexOf(".")), d[1]);
          });
        }

        this.setState({
          ...this.state,
          data: data,
          loadingStatus: false,
          timestampData: timestampData
        });
      };

      const error = err => {
        showNotificationWithIcon(i18n.t("Error"), err, "error");
      };

      post(url, queryObj, successFunc, error, false);
    };

    if (sessionVariablesShouldExecuteNow.length) {
      let url = `${API_BASE}/session-variable/variable/value`;

      let successFunc = result => {
        if (result.data) {
          let resultMap = new Map(Object.entries(result.data));

          resultMap.forEach((obj, id) => {
            let value = obj.result;

            if (obj.status !== "success") {
              showNotificationWithIcon(
                `${i18n.t("SessionVariable")}: ${obj.name}`,
                obj.message || i18n.t("DataConnections.CouldNotFetch"),
                "warning")
            }

            try {
              value = evaluate(value);
            } catch (e) {
              // pass
            }

            usedSessionVariables.set(obj.name, value);
          });

          getFilterData();
        }
      }

      let errorFunc = error => {
        showNotificationWithIcon(i18n.t("SessionVariables.Title"), error, "error");

        getFilterData();
      }

      return post(url, sessionVariablesShouldExecuteNow, successFunc, errorFunc, false);
    } else {
      getFilterData();
    }
  };

  onChangeLsqlValue = (filter, value) => {
    // if lsqlType is value and filter is aggregatable value must be numeric like 123 or 12.3
    this.setState({
      ...this.state,
      lsqlValue: this.state.lsqlType === "value" && filter.aggregatable === true ? value.replace(/[^0-9.]/g, "") : value
    });
  };

  showLsqlArea = status => {
    this.setState({
      showLsqlArea: status
    });
  };

  setPredicateToColumn = (filterId, predicate) => {
    let filter = { ...this.props.filter };

    if (filter.filterId === filterId) {
      filter.filterPredicate = predicate;
    }

    this.updateFilter(filter);
  };

  /**
   * Changes filter where operator
   * @param {*} operator 
   */
  onChangeWhereOperator = (operator) => {
    let filter = { ...this.props.filter };

    filter.whereOperator = operator;

    this.setState({
      ...this.state,
      whereOperator: operator
    })

    this.updateFilter(filter);
  }

  /**
   * @param filter
   * update default filter for plugin. Pass data to dashboard crud.
   */
  updateFilter = filter => {
    if (filter.filterId) {
      this.props.updateDefaultFilters(clone(filter), "UPDATE");
    }
  };

  /**
   * @param filter
   * delete filter
   */
  deleteFilter = filter => {
    this.props.updateDefaultFilters(filter, "DELETE");
  };

  /**
   * @param filterId
   * set protect to filter
   */
  changeProtectToFilter = filterId => {
    let tempFilter = { ...this.props.filter };

    if (tempFilter.filterId == filterId) {
      tempFilter.protected = !tempFilter.protected;
    }

    this.updateFilter(tempFilter);
  };

  /**
   * If value has only "," special char, value must array.
   * Checking commas, brackets and 'case when' and decide this. 
   */
  decideValue = (value) => {
    let hasComma = value.includes(",");
    let hasBracket = value.includes("(");
    let hasCase = value.includes("CASE") || value.includes("case");

    let returnValue = value;

    if (hasComma && !hasBracket && !hasCase) {
      returnValue = value.split(",");
    }

    return returnValue;
  }

  /**
   * Set lsql type
   */
  onChangeLsqlType = (e) => {
    this.setState({
      ...this.state,
      lsqlType: e.target.value
    })
  }

  /**
   * @param value
   * @param filterId
   * change filter value
   */
  onChangeFilterValue = (value, filterId, lsql = undefined) => {
    let filter = { ...this.props.filter };

    if (value && lsql) {
      filter.lsqlValue = value;
      filter.lsqlType = this.state.lsqlType;
    }

    if (value && filter.dataType === "timestamp") {
      if (!lsql) {
        if (Array.isArray(value)) {
          value = value.map(val => {
            if (!val.includes(".")) {
              return this.state.timestampData.get(val);
            }

            return val;
          })
        } else if (!value.includes(".")) {
          value = this.state.timestampData.get(value);
        }
      } else if (this.state.timestampData.get(value)) {
        value = this.state.timestampData.get(value);
      }
    }


    if (filter.filterId == filterId) {
      if (lsql === true && value !== "") {
        filter.lsql = true;

        if (filter.lsqlType !== "value") {
          value = this.decideValue(value, filter);
        }
      } else if (lsql === true && value === "") {
        filter.lsql = false;
        filter.lsqlValue = "";
        value = "";
      } else {
        filter.lsql = false;
        filter.lsqlValue = "";
      }

      if (!lsql && (filter.lsql == undefined || filter.lsql === false)) {
        filter.lastValue = value;
      }

      filter.value = value;
    }

    filter.lsqlValue = filter.lsqlValue.trim();
    if (typeof filter.value === "string") {
      filter.value = filter.value.trim();
    } else if (Array.isArray(filter.value)) {
        filter.value = filter.value.map(e => typeof e === "string" ? e.trim() : e);
    }
    
    if (typeof value === "string") {
      value = value.trim();
    } else if (Array.isArray(value)) {
        value = value.map(e => typeof e === "string" ? e.trim() : e);
    }
    
    this.processChangingForLsqlStatus(lsql, filter, value);
  };

  /**
   * process on change filter, decides operations by lsql status and lsql type
   */
  processChangingForLsqlStatus = (lsql, filter, value) => {
    this.showLsqlArea(false);

    if (lsql === true && filter.lsqlType === "value") {
      let isDataTypeNumeric = filter.dataType === "double" || filter.dataType === "integer";
      let isValueNumeric = false;

      //== for typeless equals string value and numeric value
      if (Number.parseFloat(value) == value) {
        isValueNumeric = true;
      }

      // Removed because data format change 
      // Will test and maybe uncommented this area
      // Commented cause issue number #1681, 3 number bug.
      // if (isDataTypeNumeric && !isValueNumeric) {
      //   showNotificationWithIcon(i18n.t("Error"), i18n.t("Dashboard.Data.NumericFilterError"), "error");
      // } else {
    } 
  
    this.updateFilter(filter);
  }

  /**
   * 
   * @param {*} filter 
   * @returns 
   * 
   * Checking whether is is null or is not null as predicated
   */
  predicatedIsNotNullOrIsNull = (filter) => {
    return filter.filterPredicate.toLowerCase().includes("null")
  }

  // Sets compared filter
  setComparedFilter = filter => {
    if (filter.filterId) {
      this.props.updateDefaultFilters(clone(filter), "COMPARE");
    }
  }

  // Gets color of compare icon
  getColorOfCompareObj = filter => {
    if (filter.compared) {
      return "rgb(56, 144, 34)"
    }

    return "rgb(152, 152, 152)"
  }

  /**
   * @param filter
   * Create default filter's buttons with methods
   */
  filterButtons = filter => {
    let isProtected = filter.protected ? true : false;

    return (
      <div>
        <Popover
          key={"popover-lsql-" + filter.filterId}
          placement="topRight"
          arrowPointAtCenter
          overlayClassName={`default-filter-select-${this.props.pluginId}`}
          content={this.state.showLsqlArea && (
            <div
              className={`default-filter-select-${this.props.pluginId}`}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: 550,
                height: 215
              }}
            >
              <Radio.Group
                key={"radio-lsql-" + filter.filterId}
                onChange={this.onChangeLsqlType}
                value={this.state.lsqlType}
                style={{ marginBottom: "5px" }}
              >
                <Radio value={"value"}>
                  {i18n.t("Dashboard.Data.Value")}{" "}
                  {/* <Tooltip tooltip={i18n.t("Dashboard.Data.ValueDescription")} overlayClassName={`single-filter-${this.props.pluginId}`}>
                    <span>(?)</span>
                  </Tooltip> */}
                </Radio>
                <Radio value={"formula"}>
                  {i18n.t("Dashboard.Data.Formula")}{" "}
                  {/* <Tooltip tooltip={i18n.t("Dashboard.Data.FormulaDescription")} overlayClassName={`single-filter-${this.props.pluginId}`}>
                    <span>(?)</span>
                  </Tooltip> */}
                </Radio>
              </Radio.Group>

              <div style={{ margin: "5px 0" }} key={"lsql-" + filter.filterId}>
                {
                  this.predicatedIsNotNullOrIsNull(filter) ? (
                    <TextArea
                      className="lsql-area"
                      disabled={true}
                      defaultValue={filter.lsql ? filter.lsqlValue ? filter.lsqlValue : filter.value : ""}
                      autoSize={false}
                      style={{
                        height: this.state.lsqlType === "value" ? 114 : 131
                      }}
                    />
                  ) : this.state.lsqlType === "formula" ? (
                    <FilterCodeEditor
                      value={this.state.lsqlValue}
                      onChange={(value) => this.onChangeLsqlValue(filter, value)}
                      language={"visp-default-filters"}
                      height={131}
                    />
                  ) : (
                    <FilterCodeEditor
                      value={this.state.lsqlValue}
                      onChange={(value) => this.onChangeLsqlValue(filter, value)}
                      language={""}
                      height={114}
                    />
                  )
                }
              </div>

              {
                this.state.lsqlType === "value" && (
                  <Text style={{ fontSize: "11px", color: "rgb(255, 152, 1)", marginBottom: "5px" }}>
                    {i18n.t("Dashboard.Data.ValueTypeDescription")}
                  </Text>
                )
              }

              <Button
                style={{ marginTop: 5 }}
                onClick={() =>
                  this.onChangeFilterValue(
                    this.state.lsqlValue,
                    filter.filterId,
                    true
                  )
                }
              >
                {i18n.t("Apply")}
              </Button>
            </div>
          )}
          trigger="click"
          visible={this.state.showLsqlArea}
          style={{ width: "100px", height: "100px" }}
          onVisibleChange={this.showLsqlArea}
        >
          <a
            href="javascript:;"
            style={{
              position: "relative",
              top: "4px"
            }}
            onClick={() => this.showLsqlArea(true)}
          >
            <i
              id={"searchFilter"}
              class="fa fa-search"
              style={{
                fontSize: "17px",
                marginRight: "10px",
                color: this.state.showLsqlArea
                  ? "rgb(78, 124, 162)"
                  : "rgb(152, 152, 152)"
              }}
            ></i>
          </a>
        </Popover>
        <a
          href="javascript:;"
          style={{ position: "relative", top: "4px" }}
          onClick={() => this.changeProtectToFilter(filter.filterId)}
        >
          <i
            class="fa fa-key"
            style={{
              fontSize: "17px",
              marginRight: "6px",
              color: isProtected ? "rgb(56, 144, 34)" : "rgb(152, 152, 152)"
            }}
          ></i>
        </a>

        {this.props.isComparePlugin ?
          <Tooltip tooltip={"Compare"}>
            <a
              href="javascript:;"
              style={{ position: "relative", top: "2px", marginRight: "4px" }}
              onClick={() => this.setComparedFilter(filter)}
            >
              <DiGitCompare style={{
                fontSize: "23px",
                color: this.getColorOfCompareObj(filter)
              }} />
            </a>
          </Tooltip> : null}

        <a
          href="javascript:;"
          style={{ position: "relative", top: "4px" }}
          onClick={() => this.deleteFilter(filter)}
        >
          <i
            id={"removeFilter"}
            class="fa fa-times"
            style={{
              fontSize: "17px",
              color: "rgb(152, 152, 152)"
            }}
          ></i>
        </a>
      </div>
    );
  };

  render() {
    const filter = deepCopy(this.props.filter);
    const predicatedIsNotNullOrIsNull = this.predicatedIsNotNullOrIsNull(filter)

    if (predicatedIsNotNullOrIsNull) {
      filter.value = ""
      filter.lastValue = ""
    }

    const filterContent = filter.lsql ? (
      filter.lsqlType === "value" ? this.inputSelector(filter, true) :
        <Tooltip
          tooltip={predicatedIsNotNullOrIsNull === false ? (i18n.t("Expression") + ": " + filter.lsqlValue) : ""}
          overlayClassName={`single-filter-${this.props.pluginId}`}
        >
          <div>
            <Input
              disabled
              key={filter.filterId}
              value={predicatedIsNotNullOrIsNull === false ? filter.lsqlValue : ""}
            />
          </div>
        </Tooltip>
    ) : (
      this.inputSelector(filter)
    );

    return (
      <div style={{ position: "relative" }}>
        {this.props.index > 0 ?
          <Row style={{ marginBottom: "5px", display: "flex", alignItems: "center" }} gutter={6}>
            <Col span="8"></Col>
            <Col span="4">
              <Select
                id={this.props.id + "-" + this.props.index}
                key={"default-filter-where-operator-" + this.props.id}
                style={{ width: "100%" }}
                getPopupContainer={(trigger) => trigger.parentNode}
                optionFilterProp="children"
                onChange={this.onChangeWhereOperator}
                value={this.state.whereOperator}
                dropdownClassName={`default-filter-select-${this.props.pluginId}`}
              >
                <Option key={"and"} name={"AND"} value={" AND "}>AND</Option>
                <Option key={"or"} name={"OR"} value={" OR "}>OR</Option>
              </Select>
            </Col>
          </Row>
          : null}
        <Row style={{ marginBottom: "5px", display: "flex", alignItems: "center" }} gutter={6}>
          <Col span="6" style={{ textAlign: "left", display: "flex", alignItems: "center" }}>
            <Tooltip tooltip={filter.tableAliasName + "." + filter.name} overlayClassName={`table-info-tooltip single-filter-${this.props.pluginId}`}>
              <span style={{ marginRight: "8px", color: "rgb(152, 152, 152)", marginLeft: "8px" }}>
                <i class="fas fa-info-circle"></i>
              </span>
            </Tooltip>
            <Tooltip style={{ display: "inline-block", maxWidth: "115px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }} tooltip={filter.displayName} overlayClassName={`filter-name-tooltip single-filter-${this.props.pluginId}`}>
              <span style={{ display: "flex" }}>
                <Text style={{ display: "inline-block", maxWidth: "115px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{filter.displayName}: </Text>
              </span>
            </Tooltip>
          </Col>
          <Col span="4">
            <SelectPredicate
              id={"selectPredicate " + filter.name}
              setPredicateToColumn={this.setPredicateToColumn}
              filter={filter}
              filterId={filter.filterId}
              predicate={filter.filterPredicate}
              pluginId={this.props.pluginId}
            />
          </Col>
          <Col id={"selectFilter " + filter.name} span="10">{filterContent}</Col>
          <Col span="4">{this.filterButtons(filter)}</Col>
        </Row>
      </div>
    );
  }
}
