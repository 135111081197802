import React, { Component } from "react";
import { post } from "../Utils/WebService";
import { API_BASE } from "../config";
import { showNotificationWithIcon } from "../Utils/Notification";
import {
  Tooltip,
  Button,
  Icon,
  Input,
  Form,
  Layout,
  Modal,
  Progress,
  Alert,
  Switch,
  Col,
  Row
} from "antd";

const FormItem = Form.Item;

class AddUserContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      score: 0,
      addStatus: "",
      inputUserName: "",
      inputEmail: "",
      inputPassword: "",
      inputRePassword: "",
      inputLdapUser: false,
      inputEnable: true,
      inputAccountNonExpired: true,
      inputAccountNonLocked: true,
      inputCredentialsNonExpired: true
    };
  }

  addUser = () => {
    let url = `${API_BASE}/user`;

    let requestBody = {
      username: this.state.inputUserName,
      password: this.state.inputPassword,
      email: this.state.inputEmail,
      tenant: null,
      accountNonExpired: this.state.inputAccountNonExpired,
      accountNonLocked: this.state.inputAccountNonLocked,
      enabled: this.state.inputEnable,
      credentialsNonExpired: this.state.inputCredentialsNonExpired,
      roles: [],
      ldapUser: this.state.inputLdapUser
    };

    if (this.state.inputLdapUser) {
      requestBody.password = null;
    }

    const successFunc = result => {

      this.setState({
        inputUserName: "",
        inputEmail: "",
        inputPassword: "",
        inputRePassword: "",
        score: 0,
        inputAccountNonExpired: true,
        inputAccountNonLocked: true,
        inputEnable: true,
        inputCredentialsNonExpired: true,
        inputLdapUser: false
      });

      showNotificationWithIcon("OK", "User successfully added.", "success");

      this.props.confirm(true);
    };

    const errorFunc = error => {
      this.setState({
        addStatus: error.response.data.message
      });
      showNotificationWithIcon("ERROR!", error.response.data.message, "error");
    };

    post(url, requestBody, successFunc, errorFunc);
  };

  handleCancel = e => {
    this.props.confirm(false);
  };

  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    });
  };

  control = (type, id) => {
    let typeStr = "input";
    if (type == "edit") {
      typeStr = "edit";
    }
    let pass = this.state[typeStr + "Password"];
    let rePass = this.state[typeStr + "RePassword"];
    let score = this.state.score;
    let email = this.state[typeStr + "Email"];
    let username = this.state[typeStr + "UserName"];
    let isLdap = this.state.inputLdapUser;

    if (username == "") {
      this.setState({
        addStatus: "User name must'nt empty."
      });
    } else if (email == "") {
      this.setState({
        addStatus: "Email must'nt empty."
      });
    } else if (isLdap == false && pass == "" && type == "add") {
      this.setState({
        addStatus: "Password must'nt empty."
      });
    } else if (isLdap == false && pass != rePass) {
      this.setState({
        addStatus: "Passwords not match."
      });
    } else if (score < 5 && pass != "") {
      this.setState({
        addStatus: "Password strength is not enough."
      });
    } else {
      if (type == "add") {
        this.addUser();
      } else if (type == "edit") {
        this.editUser(id);
      }
    }
  };

  ldapChange = () => {
    if (this.state.inputLdapUser == false) {
      this.setState({ inputPassword: "", inputLdapUser: !this.state.inputLdapUser, score: 5 })
    } else {
      this.setState({ inputPassword: "", inputLdapUser: !this.state.inputLdapUser, score: 0 })
    }
  }

  score = e => {
    var currentScore = this.state.score;
    var value = e.target.value,
      passwordLength = 8;
    var containsLowerLetter = /[a-z]/.test(value),
      containsUpperLetter = /[A-Z]/.test(value),
      containsDigit = /\d/.test(value),
      containsSpecial = /[^a-zA-Z\d]/.test(value);
    var containsAll =
      containsLowerLetter &&
      containsUpperLetter &&
      containsDigit &&
      containsSpecial;
    currentScore = 0;

    if (value.length == 0) {
      currentScore = 0;
    } else {
      if (containsAll) {
        currentScore += 4;
      } else {
        if (containsLowerLetter) currentScore += 1;
        if (containsUpperLetter) currentScore += 1;
        if (containsDigit) currentScore += 1;
        if (containsSpecial) currentScore += 1;
      }
      if (value.length >= passwordLength) currentScore += 1;
    }
    this.setState({
      score: currentScore
    });
  };

  render() {
    const formItemLayout = {
      labelCol: { span: 16 },
      wrapperCol: { span: 8 }
    };
    return (
      <Modal
        title="Create a new account"
        visible={this.props.visible}
        onCancel={this.handleCancel}
        footer={[]}
      >
        <Form>
          <FormItem>
            <Input
              placeholder="Username"
              value={this.state.inputUserName}
              id="inputUserName"
              onChange={this.handleChange}
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              suffix={
                <Tooltip title="Extra information">
                  <Icon
                    type="info-circle"
                    style={{ color: "rgba(0,0,0,.45)" }}
                  />
                </Tooltip>
              }
            />
          </FormItem>
          <FormItem>
            <Input
              type="email"
              placeholder="Email"
              id="inputEmail"
              value={this.state.inputEmail}
              onChange={this.handleChange}
              prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
              suffix={
                <Tooltip title="Extra information">
                  <Icon
                    type="info-circle"
                    style={{ color: "rgba(0,0,0,.45)" }}
                  />
                </Tooltip>
              }
            />
          </FormItem>
          <Row>
            <Col span={12}></Col>
            <Col span={12}>
              <FormItem label="Ldap User" {...formItemLayout}>
                <Switch
                  checked={this.state.inputLdapUser}
                  onChange={this.ldapChange}
                />
              </FormItem>
            </Col>
          </Row>
          <div style={{display: this.state.inputLdapUser == true ? "none" : "block"}}>
            <FormItem style={{ margin: 0 }}>
              <Input.Password
                value={this.state.inputPassword}
                id="inputPassword"
                onChange={this.handleChange}
                onKeyUp={this.score}
                placeholder="Password"
                prefix={<Icon type="key" style={{ color: "rgba(0,0,0,.25)" }} />}
                suffix={
                  <Tooltip title="Extra information">
                    <Icon
                      type="info-circle"
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  </Tooltip>
                }
                type="password"
              />
            </FormItem>
            <span style={{display: "block", marginTop: "10px", color: "#a0a0a0"}}>To conform with our Strong Password policy; use at least one lower letter, one upper letter, one numeral, one special character, and eight characters.</span>
            <FormItem style={{ margin: 0 }}>
              <Progress
                percent={this.state.score * 20}
                status={
                  this.state.score <= 2
                    ? "exception"
                    : this.state.score == 5
                    ? "success"
                    : "active"
                }
              />
            </FormItem>
            <FormItem>
              <Input.Password
                placeholder="Re-password"
                id="inputRePassword"
                value={this.state.inputRePassword}
                onChange={this.handleChange}
                prefix={<Icon type="key" style={{ color: "rgba(0,0,0,.25)" }} />}
                suffix={
                  <Tooltip title="Extra information">
                    <Icon
                      type="info-circle"
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  </Tooltip>
                }
                type="password"
              />
            </FormItem>
          </div>
          <Row>
            <Col span={12}>
              <FormItem label="Enable" {...formItemLayout}>
                <Switch
                  checked={this.state.inputEnable}
                  onChange={() => {
                    this.setState({ inputEnable: !this.state.inputEnable });
                  }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Account Non Expired" {...formItemLayout}>
                <Switch
                  checked={this.state.inputAccountNonExpired}
                  onChange={() => {
                    this.setState({
                      inputAccountNonExpired: !this.state.inputAccountNonExpired
                    });
                  }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Account Non Locked" {...formItemLayout}>
                <Switch
                  checked={this.state.inputAccountNonLocked}
                  onChange={() => {
                    this.setState({
                      inputAccountNonLocked: !this.state.inputAccountNonLocked
                    });
                  }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Credentials Non Expired" {...formItemLayout}>
                <Switch
                  checked={this.state.inputCredentialsNonExpired}
                  onChange={() => {
                    this.setState({
                      inputCredentialsNonExpired: !this.state
                        .inputCredentialsNonExpired
                    });
                  }}
                />
              </FormItem>
            </Col>
          </Row>
          <FormItem>
            <Button
              type="primary"
              onClick={() => this.control("add", "")}
              disabled={this.state.score != 5 ? "disabled" : ""}
              style={{ float: "right" }}
            >
              Save
            </Button>
          </FormItem>
          <FormItem>
            <Alert
              message={this.state.addStatus}
              style={{
                display: this.state.addStatus == "" ? "none" : "block"
              }}
              type={this.state.addStatus == "OK" ? "success" : "error"}
            />
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default AddUserContent;
