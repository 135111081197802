import React, { Component } from "react";
import { Col, Select, Row, Input, Button, Divider } from "antd";
import Tooltip from "../GeneralComponents/Tooltip/Tooltip";
import Text from "../GeneralComponents/Text/Text";
import {
  FontSizeOutlined,
  UnderlineOutlined,
  BoldOutlined,
  ItalicOutlined,
  FontColorsOutlined
} from "@ant-design/icons";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import PopupContainer from "../GeneralComponents/PopupContainer/PopupContainer";
import i18n from "../../Utils/i18next";
import FontTypeSelector from "../GeneralComponents/FontTypeSelector/FontTypeSelector";
import $ from "jquery";

//The popup in this component contains text editing feautures for the DashboardTitle.
export default class DashboardTitleEditorPopUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newSize: null
    }

    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  //  ** event.target.value!=0 condition added because of the behavior of dropdown menu.
  //  * When trying to click an <Option> at <Select> components dropdown menu it acts as if user clicks outside
  //  * so it triggers the handeClickOutside and closes its parent popup entirely before the change made.
  //  ** Solved by adding this condition. Found the 0 value by printing event.target.values when clicking an <Option>
  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      let clickedOnOption = event.target.value == 0 && event.target.value !== "";

      if (!clickedOnOption) {
        if (!$(event.target).closest("#loginPopup").length > 0) {
          this.props.closeTextEditor();
        }
      }
    }
  };

  /* Shows warning text according to users typed font size value. 
  document.getElementById used because this warning based on input component value (which user can type out of bound size values). So this warning 
  is NOT based on this.props.fontSize because fontSize is controlled (cannot be out of the bounds: 11px <= this.props.fontSize <= 40px) */
  sizeWarning = () => {
    let titleFontSize = document.getElementById("titleFontSize");
    let isFontSizeInWrongInterval = titleFontSize !== null && (titleFontSize.value === "" || Number(titleFontSize.value) < 11 || Number(titleFontSize.value) > 40);
    let tooSmallWarning = "* " + i18n.t("Dashboard.Configuration.Fields.CantReduceMeasureSize", { size: `11` });
    let tooBigWarning = "* " + i18n.t("Dashboard.Configuration.Fields.CantChangeMeasureSize", { size: `40` });
    
    return ( isFontSizeInWrongInterval ? (
      <Row style={{height:"11px"}}>
        <Col span={5}>
        </Col>
        <Col span={17} style={{fontSize:"11px", textAlign:"left", color:"red"}}>
          { Number(titleFontSize.value) < 11 ?  tooSmallWarning : tooBigWarning }
        </Col>
      </Row>
    ): null );
  }

  /* Sets defaults and updates newSize to trigger the element (with id "titleFontSize") key so its defaultValue can revert to last saved*/
  setDefaults = () => {
    let size = this.props.setConfigDefault();

    this.setState({newSize: size}, () => {this.setState({newSize: null})}); 
    //callback because newSize should keep updating itself at every setDefault call for to trigger defaultValue reverting
  }

   render() {
    return (
      <div ref={this.wrapperRef}>
        <PopupContainer width={"350px"} background={"#f9f9f9"}>
          <div
            style={{
              display: "block",
              boxSizing: "border-box",
              height: this.props.colorChangeVisible ? "470px" : "auto",
            }}
          >
            <div style={{ position: "relative", width:"290px"}}>
              <Divider style={{fontSize:"18px", color:"#225f8f", marginTop:"7px", marginBottom:"7px"}} orientation="left">
                {i18n.t("Dashboard.Settings.TitleSettings")}
              </Divider>
              {this.props.isDefaultConfigShow() ? (
                <Tooltip tooltip={i18n.t("ReturnToDefault")}>
                  <Button
                    id="returnDefaultButton"
                    className={"general-button"}
                    onClick={this.setDefaults}
                    style={{
                      position: "absolute",
                      top: "-1px",
                      right: "9px",
                      fontSize: "13px",
                      padding: "2px 9px",
                      height: "fit-content",
                      width: "fit-content",
                      borderBottom: "0",
                      backgroundColor: "#62b779"
                    }}
                  >
                    {i18n.t("ReturnToDefault")}
                  </Button>
                </Tooltip>
            ) : null}
          </div>
            <Row style={{ marginBottom: "7px" }}>
              <Col xs={5}>
                <Text
                  style={{
                    fontSize: "16px",
                    display: "inline-block",
                    marginTop: "2px",
                  }}
                >
                  {i18n.t("Title")}
                </Text>
              </Col>
              <Col xs={15}>
                <Input
                  placeholder={i18n.t("TitleNotSet")}
                  onChange={this.props.titleOnChange}
                  allowClear
                  id={"dashboardTitle"}
                  defaultValue={this.props.dashboardTitle}
                  value={this.props.dashboardTitle}
                />
              </Col>
            </Row>
            <Row>
              <Col span={5}>
                <FontSizeOutlined
                  title={i18n.t("Dashboard.Configuration.Fields.FontSize")}
                  style={{ marginTop: "-5px" }}
                />
              </Col>
              <Col span={15}>
                <Input
                  id={"titleFontSize"}
                  placeholder={this.props.fontSize}
                  defaultValue={this.props.fontSize} //can not use "value" instead of "defaultValue" beacuse of lower bound size control
                  key={this.state.newSize} //to revert defaultValue when returnToDefault button pressed
                  onChange={this.props.changeFontSize}
                />
              </Col>
            </Row>
            {this.sizeWarning()}
            <Row style={{ marginTop:"7px", marginBottom: "7px" }}>
              <Col span={5}>
                <i
                  title={i18n.t("Dashboard.Configuration.Fields.Font")}
                  class="fa fa-font"
                  aria-hidden="true"
                  style={{ position:"relative", bottom:"-5px"}}
                >
                  {" "}
                </i>
              </Col>
              <Col span={15}>
                <FontTypeSelector
                  placeholder={this.props.titleFont}                  
                  onChange={this.props.changeFont}
                  value={this.props.titleFont}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: "7px" }}>
              <Col xs={5}>
                <FontSizeOutlined
                  title={i18n.t("Dashboard.Configuration.Fields.FontStyle")}
                  style={{ marginTop: "-5px" }}
                />
              </Col>
              <Col xs={5} style={{ textAlign: "left" }}>
                <Button
                  onClick={this.props.changeFontWeight}
                  style={{
                    backgroundColor: this.props.isBold ? "#9ed3ff" : "initial",
                    color: "black",
                    borderColor: "lightgrey",
                  }}
                >
                  <BoldOutlined
                    style={{ display: "flex", verticalAlign: "middle" }}
                  />
                </Button>
              </Col>
              <Col xs={5}>
                <Button
                  onClick={this.props.changeFontStyle}
                  style={{
                    backgroundColor: this.props.isItalic
                      ? "#9ed3ff"
                      : "initial",
                    color: "black",
                    borderColor: "lightgrey",
                  }}
                >
                  <ItalicOutlined
                    style={{ display: "flex", verticalAlign: "middle" }}
                  />
                </Button>
              </Col>
              <Col xs={5} style={{ textAlign: "right" }}>
                <Button
                  onClick={this.props.changeTextDecor}
                  style={{
                    backgroundColor: this.props.isUnderlined
                      ? "#9ed3ff"
                      : "initial",
                    color: "black",
                    borderColor: "lightrey",
                  }}
                >
                  <UnderlineOutlined
                    style={{ display: "flex", verticalAlign: "middle" }}
                  />
                </Button>
              </Col>
            </Row>
            <Row style={{ marginBottom: "7px" }}>
              <Col span={5}>
                <FontColorsOutlined
                  title={i18n.t("Dashboard.Configuration.Fields.FontColour")}
                  style={{ marginTop: "-5px" }}
                />
              </Col>
              <Col span={15}>
                <Button
                  onClick={this.props.makeColorChangeVisible}
                  style={{ width: "100%" }}
                >
                  {this.props.colorChangeVisible
                    ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                    : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
                </Button>
                {this.props.colorChangeVisible ? (
                  <ColorPickerPanel
                    alpha={100}
                    color={this.props.color}
                    onChange={this.props.changeColor}
                    id={"titleColor"}
                    style={{ marginTop: "5px", position: "absolute", right: 0 }}
                  />
                ) : null}
              </Col>
              <Col span={4}>
                {/* selected Color */}
                <div
                  onClick={this.props.makeColorChangeVisible}
                  style={{
                    width: "32px",
                    height: "32px",
                    marginLeft: "5px",
                    border: "1px solid #d7d6d6",
                    boxShadow: "0 2px 0 rgba(0, 0, 0,}",
                    borderRadius: "4px",
                    cursor: "pointer",
                    backgroundColor: this.props.color
                  }}
                ></div>
              </Col>
            </Row>

            <Button
              style={{
                position: "absolute",
                top: "3px",
                right: "15px",
                borderRadius: "40px",
                padding: "6px 11px",
                backgroundColor: "white",
                zIndex: 1,
              }}
              type={"default"}
              onClick={this.props.closeTextEditor}
            >
              X
            </Button>
          </div>
        </PopupContainer>
      </div>
    );
  }
}
