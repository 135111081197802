import { Col, Row } from "antd";
import React, { Component } from "react";
import { store } from "../..";
import { get, post } from "../../Utils/WebService";
import i18n from "../../Utils/i18next";
import { API_BASE } from "../../config";
import Button from "../GeneralComponents/Button/Button";
import CircleLoader from "../GeneralComponents/CircleLoader/CircleLoader";
import Text from "../GeneralComponents/Text/Text";
import { setFileUploadOperationAndStatus } from "./FileUploadAction";
import { OPERATIONS } from "./SheetStatus";
import UploadResultByTable from "./UploadResultByTable";
import { setModelPopupUtils } from "../Datasources/SelfServiceReducer/SelfServiceAction";

/*
 * Returns status keys
 */
export const STATUS = {
  NOT_STARTED: { key: "NOT_STARTED", text: i18n.t("FileUpload.NOT_STARTED") },
  UPLOADING: { key: "UPLOADING", text: i18n.t("FileUpload.UPLOADING") },
  DONE: { key: "DONE", text: i18n.t("FileUpload.DONE") },
  FAILED: { key: "FAILED", text: i18n.t("FileUpload.FAILED") },
  PROCESSING: { key: "PROCESSING", text: i18n.t("FileUpload.PROCESSING") },
};

/*
 * Returns result status keys
 */
export const RESULT_STATUS = {
  SUCCESS: {
    key: "SUCCESS",
    text: i18n.t("FileUpload.SUCCESS"),
    color: "rgb(86 176 44)",
  },
  ERROR: { key: "ERROR", text: i18n.t("FileUpload.ERROR"), color: "#d45050" },
  WARNING: {key: "WARNING", text: i18n.t("FileUpload.WARNING"), color: "#ffc800"}
};

export default class UploadOperation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: STATUS.PROCESSING,
      uploadProgress: 100,
      modelList: []
    };
  }

  componentWillMount() {
    this.getModelList(this.props)
  }

  /*
  * Gets model list and saves
  */
  getModelList = (props) => {
    let successFunc = (res) => {
      this.setState({
        ...this.state,
        modelList: res.data
      }, () => {
        this.uploadFile(props);
      })
    }

    let url = `${API_BASE}/subjectArea/nameList`;

    get(url, successFunc);
  }

  uploadFile = async (obj) => {
    this.updateProgressAction(this.state.status, this.state.uploadProgress);

    const { uploadParameters, sheets, dataSource, selectedModel } = obj;

    if (this.props.firstDataUpload) {
      let keys = [...sheets.keys()]

      for (let i = 0; i < keys.length; i++) {
        let sheet = sheets.get(keys[i])
        sheet["operation"] = OPERATIONS.CREATE.key
        
        sheets.set(keys[i], sheet)
      }
    }

    let url = API_BASE + "/file-operations/upload-file";
    let requestBody = new FormData();

    requestBody.append("filename", uploadParameters.filename);
    requestBody.append("sheets", sheets ? JSON.stringify(Object.fromEntries(sheets)) : "");
    requestBody.append("dataSource", JSON.stringify(dataSource));
    requestBody.append("schema", uploadParameters.schemaName);
    requestBody.append("hasHeader", uploadParameters.header);
    requestBody.append("fileType", uploadParameters.fileType);

    const success = (response) => {
      let success_object = response.data.success_object;
      let exception_object = response.data.exception_object;
      let warning_object = response.data.warning_object;

      this.updateProgressAction(STATUS.DONE, this.state.uploadProgress);

      this.setState({
        status: STATUS.DONE,
        success_object:
          success_object && Object.keys(success_object).length > 0
            ? success_object
            : undefined,
        exception_object:
          exception_object && Object.keys(exception_object).length > 0
            ? exception_object
            : undefined,
        warning_object:
          warning_object && Object.keys(warning_object).length > 0
            ? warning_object
            : undefined,
        error: undefined,
      });

      this.addNewTableToUsedModel(dataSource, sheets, selectedModel, uploadParameters.schemaName)

      if (!this.props.comesFromDashboardFileUpload) {
        this.props.onFileUploadSucces(STATUS.DONE.key);
      }
    };

    const error = (err) => {
      this.updateProgressAction(STATUS.FAILED, this.state.uploadProgress);

      this.setState({
        status: STATUS.FAILED,
        success_object: undefined,
        exception_object: undefined,
        warning_object: undefined,
        error: err,
      });
    };

    post(url, requestBody, success, error, false);
  };

  /*
  * Prepares table list
  */
  prepareTableList = (tableList, sheets, dataSource, schemaName) => {
    let keys = [...sheets.keys()]

      for (let i = 0; i < keys.length; i++) {
        let sheet = sheets.get(keys[i])

        if (sheet.status === true) {
          let table = {
            "name": sheet.displayName,
            "aliasName": sheet.displayName,
            "value": null,
            "displayName": sheet.displayName,
            "description": null,
            "parentTableName": null,
            "hidden": false,
            "columns": [],
            "whereClause": null,
            "dataSource": null,
            "dataSourceKey": dataSource.name + "." + schemaName,
            "tableType": "None",
            "schemaName": schemaName,
            "dataSourceType": dataSource.dataSourceType,
            "manuel": false,
            "duplicated": false
          }
  
          tableList.push(table)
        }
      }
  }

  /*
  * Adds new table to used model
  */
  addNewTableToUsedModel = (dataSource, sheets, selectedModel, schemaName) => {
    let reduxState = store.getState()
    let isModelAvailable = selectedModel == undefined || selectedModel === "" || this.state.modelList.length === 0 ? true : false

    if (isModelAvailable) {
      let modelName = "Default Model";

      let modelToSave = {};

      modelToSave = {
        "name": modelName,
        "displayName": modelName,
        "deletedList": [],
        "tableWithColumnList": [],
        "tableList": []
      }

      this.prepareTableList(modelToSave.tableList, sheets, dataSource, schemaName)

      const success = (response) => {
        let modelUtils = {
          openModelPopup: true,
          modelId: response.data?.id
        }

        store.dispatch(setModelPopupUtils(modelUtils))

        reduxState.SelfServiceReducer.setModelIdToModelsAreaCallbackFunction(response.data)
      }

      let url = `${API_BASE}/subjectAreaMerged`;
      post(url, modelToSave, success, (e) => {
        console.log(e)
      });

    } else {
      let url = `${API_BASE}/subjectAreaMerged/addTables`;
      let requestBody = {id: selectedModel.id, tableList: []}

      this.prepareTableList(requestBody.tableList, sheets, dataSource, schemaName)

      const success = (response) => {
        let modelUtils = {
          openModelPopup: true,
          modelId: response.data?.id
        }

        store.dispatch(setModelPopupUtils(modelUtils))
        
        reduxState.SelfServiceReducer.addTableCallbackFunction(dataSource, schemaName)
        reduxState.SelfServiceReducer.setModelIdToModelsAreaCallbackFunction(selectedModel)
      }

      post(url, requestBody, success, (e) => {
        console.log(e)
      });
    }
  }

  updateProgressAction = (status, progress) => {
    store.dispatch(setFileUploadOperationAndStatus(status, progress));
  };

  render() {
    const { status, error, success_object, exception_object, warning_object } =
      this.state;

    return (
      <div>
        <Text
          type="p"
          style={{
            textAlign: "center",
            margin: "10px 0 5px",
            fontWeight: "bold",
            fontSize: "16px",
          }}
        >
          {i18n.t("FileUpload.FileUploadStatus")}
        </Text>

        {status.key === STATUS.PROCESSING.key && (
          <div style={{ textAlign: "center", margin: "15px 0" }}>
            <CircleLoader />
            <div>
              <Text style={{ fontStyle: "italic", fontSize: "15px" }}>
                {i18n.t("FileUpload.DataProcessing")}
              </Text>
            </div>
          </div>
        )}

        {status.key === STATUS.DONE.key && (
          <div>
            <Text
              type="p"
              style={{
                textAlign: "center",
                margin: "10px 0 5px",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              {i18n.t("FileUpload.Result")}
            </Text>

            <div className="file-validation-header">
              <Row gutter={6}>
                <Col span={8}>{i18n.t("FileUpload.TableName")}</Col>
                <Col span={8}>{i18n.t("FileUpload.Status")}</Col>
                <Col span={8}>{i18n.t("FileUpload.Detail")}</Col>
              </Row>
            </div>

            {success_object &&
              Object.keys(success_object).map((tableName) => (
                <UploadResultByTable
                  tableName={tableName}
                  status={RESULT_STATUS.SUCCESS}
                />
              ))}

            {warning_object &&
              Object.keys(warning_object).map((tableName) => (
                <UploadResultByTable
                  tableName={tableName}
                  status={RESULT_STATUS.WARNING}
                  detail={warning_object[tableName]}
                />
              ))}

            {exception_object &&
              Object.keys(exception_object).map((tableName) => (
                <UploadResultByTable
                  tableName={tableName}
                  status={RESULT_STATUS.ERROR}
                  detail={{ message: exception_object[tableName] }}
                />
              ))}
          </div>
        )}

        {status.key === STATUS.FAILED.key && (
          <>
            <Text
              type="p"
              style={{
                color: "#ff7800",
                textAlign: "center",
                fontSize: "18px",
                marginTop: "25px",
              }}
            >
              {typeof error === "string" && error.includes("404")
                ? i18n.t("FileUpload.TimeOutError")
                : error}
            </Text>
            {!error.includes("404") ? (
              <Button onClick={() => this.uploadFile(this.props)}>
                {i18n.t("ClickHereToTryAgain")}
              </Button>
            ) : null}
          </>
        )}
      </div>
    );
  }
}
