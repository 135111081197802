export const SESSION_VARIABLE_CONTENT = "SESSION_VARIABLE_CONTENT";
export const DASHBOARD = "DASHBOARD";

/**
 * 
 * @param sessionVariables 
 * @returns 
 * 
 * Sets Session Variable Content
 */
export const setSessionVariableContent = (sessionVariables) => {
  return (dispatch) => {
    dispatch({
      type: SESSION_VARIABLE_CONTENT,
      payload: sessionVariables,
    });
  };
}

/**
 * 
 * @param {*} variables 
 * @returns 
 * 
 * Sets session variable values for dashboard
 */
export const setDashboardSessionVariables = (variables) => {
  return (dispatch) => {
    dispatch({
      type: DASHBOARD,
      payload: variables,
    });
  };
}