import React, { Component } from "react";
import { Card, Icon, Form, Alert, Input, Button, Row, Col } from "antd";
import { changePopupVisibility } from "./LoginPopupAction";
import { connect } from "react-redux";
import { showNotificationWithIcon, showError } from "../../../Utils/Notification";
import i18n from "../../../Utils/i18next";
import { createCookie } from "../../../Utils/Global";
import { removeAuthentication, setAuthentication } from "../../../Utils/Auth";
import { post } from "../../../Utils/WebService";
import { getCustomization } from "../../../Utils/Customization";
import Axios from "axios";
import "./loginPopup.css"
import Cookies from "js-cookie";

/*
* When token expire, this component seems (Login Popup)
*/
class LoginPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: null,
      password: null,
      alertText: "",
      alert: false,
      isLastUserFound: true,
      loading: false,
    };
  }

  componentDidMount() {
    let userName = Cookies.get("lastUser")
    let pathName = window.location.href
    let isPathIncludeLogin = pathName.includes("/ui/login") ? true : false
    let isPathIncludeRegister = pathName.includes("/ui/register") ? true : false
    let isPathIncludeForgotPassword = pathName.includes("/ui/forgotPassword") ? true : false
    let mustNavigate = !isPathIncludeForgotPassword && !isPathIncludeLogin && !isPathIncludeRegister ? true : false

    if (userName) {
      this.setState({
        ...this.state,
        username: userName
      })
    } else if (mustNavigate) {
      this.setState({
        ...this.state,
        isLastUserFound: false
      })

      this.navigate("/vispeahen/ui/login")
    }
  }

  /*
  * Navigates other location
  */
  navigate = (link) => {
    setTimeout(function () {
      window.location.href = link;
    }, 250);
  };

  /*
  * When user click cancel, navigates login page
  */
  handleCancel = (e) => {
    removeAuthentication()

    this.navigate(`/vispeahen/ui/login`);
  };

  /*
  * When user change state value, (password)
  */
  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value,
    });
  };

  /*
  * When user sends their informations, tries to login if login succes, calls callback, else shows error
  */
  loginSubmit = (e) => {
    this.setState({
      loading: true
    })

    if (Cookies.get("lastUser") === this.state.username) {
      e.preventDefault();

      let url = `/security/oauth/token`;
      let params = new URLSearchParams();
      let lastState = this.state;

      params.append("username", this.state.username);
      params.append("password", this.state.password);
      params.append("grant_type", "password");
      params.append("client_id", "mobile");

      const successFunc = (result) => {
        let token = result.data;

        Axios.defaults.headers.common["Authorization"] = `Bearer ${token.access_token}`;

        setAuthentication(token);

        if (lastState.rememberMe) {
          createCookie("rememberMe", lastState.username, null, "/");
        }

        getCustomization(token);

        this.props.state.LoginPopupReducer.callback();
        this.props.changePopupVisibility(false);

        this.setState({
          password: null,
          alertText: "",
          alert: false,
          loading: false
        })
      };

      const errorFunc = (error, isDescription) => {
        if (isDescription) {
          if (error === "Bad credentials") {
            showError(i18n.t("UserEntrance.Login.WrongUsernameOrPassword"));
          } else if (error === "User is disabled") {
            showError(i18n.t("UserEntrance.Login.UserDisabled"));
          } else {
            showError(i18n.t("SomethingWentWrong"));
          }
        } else {
          showError(i18n.t("SomethingWentWrong"));
        }

        this.setState({
          ...this.state,
          loading: false
        })
      };

      let headers = {
        Authorization: "Basic " + btoa("mobile:pin"),
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
      };

      post(url, params, successFunc, errorFunc, false, false, headers, false);
    } else {
      this.setState({
        ...this.state,
        alert: true,
        alertText: i18n.t("UserEntrance.SessionExpired.SessionDifferentUser"),
        loading: false
      })
    }
  };

  render() {
    const loginVisibleStatus = this.props.state.LoginPopupReducer
    const isLoginPopupMustShow = loginVisibleStatus.loginPopupVisibility && this.state.isLastUserFound === true
    
    // Default z-index of Modal Component's starts with 1000 so we should give 1009 to z-index value of LoginPopup
    // because the biggest value less than error Notification component's z-index
    let zIndexForLoginPopup = 1009
 
    return (
      <div
        id="loginPopup"
        style={{ display: isLoginPopupMustShow ? "block" : "none", zIndex: zIndexForLoginPopup}}
      >
        <div style={{ width: "30%", margin: "auto", marginTop: "3%" }}>
          <Card title={i18n.t("UserEntrance.SessionExpired.WantToContinue")}>
            <span>
              {i18n.t("UserEntrance.SessionExpired.SessionExpiredMessage")}
            </span>
            <Form style={{ marginTop: "20px" }} className="login-form">
              <Form.Item>
                <Input
                  id={"username"}
                  value={this.state.username}
                  defaultValue={this.state.username}
                  disabled={false}
                  onChange={this.handleChange}
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Username"
                  onPressEnter={this.loginSubmit}
                  style={{ borderRadius: "5px" }}
                />
              </Form.Item>
              <Form.Item>
                <Input
                  id={"password"}
                  value={this.state.password}
                  onChange={this.handleChange}
                  style={{ borderRadius: "5px" }}
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="Password"
                  onPressEnter={this.loginSubmit}
                />
              </Form.Item>
              {this.state.alert ? (
                <Form.Item>
                  <Alert message={this.state.alertText} type="error" showIcon />
                </Form.Item>
              ) : null}
              <Form.Item>
                <Button
                  onClick={this.loginSubmit}
                  loading={this.state.loading}
                  type={"primary"}
                >
                  <span className={this.state.loading ? "ldng-button" : "button"}>
                    {i18n.t("Login")}
                  </span>
                </Button>
                <Button
                  style={{ float: "right", marginTop: "4px" }}
                  onClick={this.handleCancel}
                >
                  {i18n.t("Cancel")}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  changePopupVisibility,
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPopup);
