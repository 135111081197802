import React, { Component } from "react";
import $ from "jquery";
import { calculatePopupPosition } from "../../../../../Utils/PagePopupConfigure";
import { renderContent } from "../../renderContent";
import ReactDOM from "react-dom";
import { renderConfig, renderData } from "../../PluginsCommonComponents";
import i18n from "../../../../../Utils/i18next";
import {
  onComponentWillMount,
  onComponentWillReceiveProps,
  getColumnMapping,
  calculatePluginHeight
} from "../../common";
import { checkTableJoins } from "../../../../GeneralComponents/Join/Join";
import FilterConfiguration from "../../Filter/FilterConfiguration/FilterConfiguration";
import FilterData from "../../Filter/FilterData";
import NumberFilterContent from "./NumberFilterContent";
import { createTrigger } from "../../../../Interaction/CreateTrigger";
import { calculateTitleSize, calculatePluginInlineHeight } from "../../../../DrillDown/PluginHeightWithDrilldown"
import { isValidWriteRoles } from "../../../../DashboardPage/RoleStore";

const data = JSON.parse(
  `[{"filter":"Adana"},{"filter":"Adıyaman"},{"filter":"Amasya Merzifon"},{"filter":"Ankara Esenboğa"},{"filter":"Antalya"},{"filter":"Aydın Çıldır"},{"filter":"Ağrı Ahmed-i Hani"},{"filter":"Balıkesir Koca Seyit"},{"filter":"Balıkesir Merkez"},{"filter":"Batman"},{"filter":"Bingöl"},{"filter":"Bursa Yenişehir"},{"filter":"Denizli Çardak"},{"filter":"Diyarbakır"},{"filter":"Elazığ"},{"filter":"Erzincan"},{"filter":"Erzurum"},{"filter":"Eskişehir Hasan Polatkan"},{"filter":"Gaziantep"},{"filter":"Gazipaşa Alanya"},{"filter":"Hakkari Yüksekova Selahaddin Eyyubi"},{"filter":"Hatay"},{"filter":"Isparta Süleyman Demirel"},{"filter":"Iğdır Şehit Bülent Aydın"},{"filter":"Kahramanmaraş"},{"filter":"Kapadokya"},{"filter":"Kars Harakani"},{"filter":"Kastamonu"},{"filter":"Kayseri"},{"filter":"Kocaeli Cengiz Topel"},{"filter":"Konya"},{"filter":"Malatya"},{"filter":"Mardin"},{"filter":"Muğla Dalaman"},{"filter":"Muğla Milas-Bodrum"},{"filter":"Muş"},{"filter":"Ordu-Giresun"},{"filter":"Samsun Çarşamba"},{"filter":"Siirt"},{"filter":"Sinop"},{"filter":"Sivas Nuri Demirağ"},{"filter":"Tekirdağ Çorlu"},{"filter":"Tokat"},{"filter":"Trabzon"},{"filter":"Uşak"},{"filter":"Van Ferit Melen"},{"filter":"Zafer"},{"filter":"Zonguldak Çaycuma"},{"filter":"Çanakkale"},{"filter":"Çanakkale Gökçeada"},{"filter":"İstanbul Atatürk"},{"filter":"İstanbul Sabiha Gökçen"},{"filter":"İzmir Adnan Menderes"},{"filter":"Şanlıurfa GAP"},{"filter":"Şırnak Şerafettin Elçi"}]`
);
const columnMap = JSON.parse(
  `{"filter":{"Code":"\'ucusAlias\'.\'meydan_adiAlias\'","Name":"meydan_adi","DataType":"varchar","Table":"ucusAlias","Measure":"none","ID":"ucusAlias.meydan_adi","SubjectArea":"BIGDATA","SortKey":false,"Sorting":false,"SortDirection":"","SortOrder":0,"Locale":"TR","DataFormat":"%s","Config":{},"Verified":false,"Type":"Column","Description":""}}`
);
const condFormats = [];

const columnMappingParameters = [
  {
    targetProperty: "filter",
    formLabel: "FilterArea",
    type: "dim",
    required: true,
    desc: "FilterArea"
  }
];

const configurationParameters = [
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Arial"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 16
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 16
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
  {
    targetProperty: "width",
    label: "Width",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 400
    },
    desc: "desc89"
  },
  {
    targetProperty: "title",
    label: "Title",
    inputType: "textbox",
    inputOptions: {
      defaultValue: ""
    },
    desc: "desc94"
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: {
      defaultValue: "rgb(255,255,255)"
    },
    desc: "desc62"
  },
  {
    targetProperty: "arrowColor",
    label: "ArrowColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: {
      defaultValue: "rgba(0, 0, 0, 0.65)"
    },
    desc: "ArrowColor"
  },
  {
    targetProperty: "textBackgroundColor",
    label: "TextBackgroundColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: {
      defaultValue: "#fff"
    },
    desc: "textBackgroundColor"
  },
  {
    //Multiselect topojson control
    targetProperty: "isMulti",
    label: "IsMulti",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "IsMulti"
  },
  {
    //Multiselect topojson control
    targetProperty: "useTextBox",
    label: "useTextBox",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "useTextBox"
  },
  {
    targetProperty: "placeholder",
    label: "PlaceholderFilter",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: {
      defaultValue: ""
    },
    desc: "PlaceholderFilter"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  },
  {
    targetProperty: "placeholderColour",
    label: "PlaceHolderColour",
    inputType: "colour",
    inputOptions: {
      defaultValue: "rgba(0, 0, 0, 0.65)"
    },
    desc: "desc167"
  },
  {
    targetProperty: "filterDefaultOperator",
    label: "filterDefaultOperator",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "="
    },
    desc: "filterDefaultOperator"
  },
  {
    targetProperty: "showOperatorOptions",
    label: "showOperatorOptions",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "showOperatorOptions"
  },
  {
    targetProperty: "showDefaultFilterIcon",
    label: "showDefaultFilterIcon",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "showDefaultFilterIcon"
  }
];

const actions = [
  {
    trigger: "select",
    type: "click",
    name: "select",
    output: ["filter"],
    description: ""
  }
];

const reactions = [
  {
    id: "filter",
    name: "Filtre",
    description: "desc78",
    type: "general"
  }
];

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];
/**
 * Creates and return map for configuration
 */
const createConfiguraitonMap = () => {
  let configurationMap = new Map();

  configurationMap.set("width", {});
  configurationMap.set("title", {});
  configurationMap.set("titleAlign", {});
  configurationMap.set("filterDefaultOperator", { defaultValue: "=" });
  configurationMap.set("backgroundColor", {});
  configurationMap.set("textBackgroundColor", {});
  configurationMap.set("placeholder", {});
  configurationMap.set("placeholderColour", {});
  configurationMap.set("titleFontWeight", {});
  configurationMap.set("titleFontStyle", {});
  configurationMap.set("arrowColor", {});
  configurationMap.set("isMulti", {});
  configurationMap.set("useTextBox", {});
  configurationMap.set("showOperatorOptions", { defaultValue: true });
  configurationMap.set("showDefaultFilterIcon", { defaultValue: true });
  configurationMap.set("refresh", {});

  return configurationMap;
};

/**
 * Creates and return map for configuration categories
 */
const createConfigurationCategoryMap = () => {
  let categoryMap = new Map();

  categoryMap.set("generalAppearance", {});
  categoryMap.set("others", {});

  return categoryMap;
};

const configurationMap = createConfiguraitonMap();
const configurationCategoryMap = createConfigurationCategoryMap();

export default class NumberFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      titleChangeStatus: false,
      filterOperator: this.props.operator
    };

    this.rerenderProcessStarted = false;
  }

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  componentWillUnmount() {
    ReactDOM.unmountComponentAtNode($("#" + this.props.plugin.id)[0]);
  }

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = callBackObject => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };

    this.setCallBackObject({});

    return tmpCallBackObject;
  };

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );
  }

  getConfigComponent = props => {
    if (props.config) {
      return (
        <FilterConfiguration
          config={{ ...props.config }}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          configurationTemplate={configurationMap}
          configurationCategoryTemplate={configurationCategoryMap}
          updateCommonTitleConfig={this.props.updateCommonTitleConfig}
          plugin={this.props.plugin}
          commonTitleConfig={this.props.commonTitleConfig}
          setDefaultForPluginTitle={this.props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={this.props.isReturnToDefaultforTitleVisible}
          setPluginRerender={this.props.setPluginRerender}
          setCurrentAppliedConfig= {this.props.setCurrentAppliedConfig}
          currentAppliedConfig = {this.props.currentAppliedConfig}
          setConfigToPlugin={this.setConfigToPlugin}
          setConfigsToPlugin={this.setConfigsToPlugin}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }

    return (
      <FilterData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        didNotJoinedTables={checkTableJoins(
          this.props.join,
          this.props.plugin.columnMap,
          this.props.refreshedPluginId,
          this.props.plugin.id,
          true
        )}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={
          props.changeDoesPluginHasNotJoinedTable
        }
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        plugin={props.plugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  /**
   * To set column map this plugin
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      filter: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Filter.Name"),
        type: "dim",
        minimumColumnSize: 1,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Filter.Desc"),
        required: true,
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  getTitleAlign = props => {
    return props.plugin.config && props.plugin.config.titleAlign
      ? props.plugin.config.titleAlign
      : "center";
  };

  getDataType = () => {
    let pluginData = this.props.plugin.columnMap.filter.data;
    let dataType = undefined;

    if (pluginData.length > 0) {
      dataType = pluginData[0].dataType;
    }

    return dataType;
  };

  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {
    calculateTitleSize(this.props.plugin)

    let pluginInlineHeight = calculatePluginInlineHeight(divId)
    let container = $("#" + divId)[0]
    let plugin = $("#plugin-" + divId);

    plugin
      .parent()
      .parent()
      .css("min-height", "50px");
    $("#" + divId)
      .css("top", "50%")
      .css("position", "absolute")
      .css("width", "100%");
    plugin.css("height", pluginInlineHeight);

    let optionData = [];
    data &&
      data.map(datum => {
        if (datum.filter !== "") {
          optionData.push({ label: datum.filter.toString(), value: datum.filter })
        }
      })

    if (container) {
      if (this.props.operator !== this.state.filterOperator){
        this.props.changeOperatorValue(this.state.filterOperator);
        ReactDOM.unmountComponentAtNode(container);
      }

      ReactDOM.render(
        <>
          <NumberFilterContent
            clickedRefresh={this.props.clickedRefresh}
            interactions={this.props.interactions}
            navigations={this.props.navigations}
            actions={actions}
            data={optionData}
            config={config}
            plugin={this.props.plugin}
            applyCreateTrigger={this.applyCreateTrigger}
            filterOperator={this.state.filterOperator}
            changeFilterOperator={this.changeFilterOperator}
          />
        </>,
        container
      );
    }

    this.props.setPluginRerender(
      false,
      this.props.plugin.id,
      false,
      this.props.plugin.isInteraction
    );
  };

  currentHeight;
  lastContent = undefined;
  updateLastContent = status => {
    this.lastContent = status;
  };

  /**
   * Applies trigger if necessary
   */
  applyCreateTrigger = (filter, filterOperator) => {
    createTrigger(
      this.props.actions,
      this.props.plugin.columnMapForPlugin,
      $("#" + this.props.plugin.id)[0],
      "select",
      filter,
      this.props.plugin.id,
      this.props.interactions,
      this.props.navigations,
      undefined,
      filterOperator
    );
  };

  /*
  * set filterOperator in NumberFilterContent
  */
  changeFilterOperator = (operator) => {
    this.setState({
      ...this.state,
      filterOperator: operator
    });

    this.props.changeOperatorValue(operator);
  }

  render() {
    let configComponent = null;

    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );

      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let dataComponent = null;

    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };

    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + this.props.plugin.id).css("padding")
      );

      pluginConfig.height =
        calculatePluginHeight(this.props.plugin, this.props.settings) -
        pluginContainerPadding * 2;

      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      );
    }

    return (
      <>
        <div id={this.props.plugin.id} className="filterClass number-filter"
          /*Removed because of issue number #1376
           onMouseLeave={(e) => this.props.offHoverInputClass(e, this.props.plugin)}
           onMouseOver={(e) => this.props.onHoverInputClass(e, this.props.plugin)}*/></div>
        {
          renderContent(
            isRerender,
            this.pluginRender,
            this.props.plugin,
            data,
            columnMap,
            pluginConfig,
            condFormats,
            this.props.setPluginRerender,
            this.lastContent,
            this.updateLastContent
          )}
        {configComponent}
        {dataComponent}
      </>
    );
  }
}
