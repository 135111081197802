import ReactHtmlParser from 'react-html-parser';

//getting description until the icon
export const beforeIcon = (description) => {
    let partIndex = description.indexOf("i#");
    let part = description.slice(0, partIndex);
    let partDescription = replaceMarks(part);

    return ReactHtmlParser(partDescription);
}

//getting description after the icon
export const afterIcon = (description) => {
    let lengthOfDescription = description.length;
    let firstPartIndex = description.indexOf("i#");
    let secondPart = description.slice((firstPartIndex + 2), lengthOfDescription);
    let secondPartDescription = replaceMarks(secondPart);

    return ReactHtmlParser(secondPartDescription);
}

//getting description until the dashboard button
export const beforeDashboard = (description) => {
    let partIndex = description.indexOf("i#");
    let part = description.slice(0, partIndex);
    let partDescription = replaceMarks(part);

    return ReactHtmlParser(partDescription);
}

//getting description between the dashboard and report button
export const betweenDashboardAndReport = (description) => {
    let partStartPosition = getPosition(description, "br#", 1);
    let partEndPosition = getPosition(description, "i#", 2);
    let partDescription = description.slice((partStartPosition), partEndPosition);

    return ReactHtmlParser(replaceMarks(partDescription));
}


//getting description between the report and label buttons
export const betweenReportAndLabel = (description) => {
    let partStartPosition = getPosition(description, "br#", 2);
    let partEndPosition = getPosition(description, "i#", 3);
    let partDescription = description.slice((partStartPosition ), partEndPosition);

    return ReactHtmlParser(replaceMarks(partDescription));
}

//getting description after the labels button
export const afterLabel = (description) => {
    let position = getPosition(description, "i#", 3);
    let part = description.slice((position + 2), description.length);

    return ReactHtmlParser(replaceMarks(part));
}

/**
 * This function calculates the total length of characters before a given substring in the given base string.
 * @param {*} string 
 * @param {*} subString 
 * @param {*} index 
 * @returns 
 */
export const getPosition = (string, subString, index) => {
    return string.split(subString, index).join(subString).length;
}

/**
 * To apply some style attribute for str parameter
 * @param {*} str 
 * @returns 
 */
export const replaceMarks = (str) => {
    let newDesc = str.replace("b#", match => `<b style=" color: #226190c4;" }}>`)
        .replace("/b#", match => `</b>`)
        .replace("g#", match => `<b style=" color: #84c459c4"; }}>`)
        .replace("/g#", match => `</b>`)
        .replace("br#", match => `<br>`);

    return newDesc;
}

/**
 * To take last part of sentence by "which" parameter value.
 * @param {*} description 
 * @param {*} which 
 * @returns 
 */
export const sentenceFinisher = (description, which) => {
    let partStartPosition = getPosition(description, "i#", which);
    let partEndPosition = getPosition(description, "br#", which);
    let partDescription = description.slice(partStartPosition + 2, partEndPosition);

    return ReactHtmlParser(replaceMarks(partDescription));
}