import Cookies from "js-cookie";
import { getOperationTypes } from "./OperationTypesStore";

/**
 * Gets authenticated user's roles from cookies and returns true if user has a system role
 */
export const getSystemRoles = () => {
  let roles = getRoles();

  if (roles != null || roles != undefined) {
    let userRoleList = roles.split(",");
    let i = 0;

    for (i = 0; i < userRoleList.length; i++) {
      if (userRoleList[i] === "Admin" || userRoleList[i] === "Editor") {
        return true;
      }
    }
  }

  return false;
};

/**
 * Gets roles from cookies and checks administration roles
 */
export const getAdminRoles = () => {
  let roles = getRoles();

  if (roles != null || roles != undefined) {
    let userRoleList = roles.split(",");

    for (let i = 0; i < userRoleList.length; i++) {
      if (userRoleList[i] === "Admin" || userRoleList[i] === "Super_Admin") {
        return true;
      }
    }
  }

  return false;
}

/**
 * Get roles from cookie
 */
export const getRoles = () => {
  return Cookies.get("roles");
};

export const isValidWriteRoles = () => {
  let operationTypes = getOperationTypes();
  let rolesOperations = operationTypes ? operationTypes.split(",") : [];

  return getSystemRoles() || rolesOperations.includes("WRITE");
};
