export const configuration = {
    hideLabel: false,
    showHideButton: false,
};

export const actions = [
    {
        trigger: "clickFeature",
        type: "click",
        name: "Click Feature",
        output: ["sourceId", "sourceName"],
        description: "ClickFeatureDesc"
    },
    {
        trigger: "doubleClickFeature",
        type: "click",
        name: "Double Click Feature",
        output: ["sourceId", "sourceName"],
        description: "DoubleClickFeatureDesc"
    }
];

export const reactions = [
    {
        id: "filter",
        name: "Filter",
        description: "desc87",
        type: "general"
    }
];

export const conversionMap = {
    "table": {
        columnMap: {
            columns: {
                name: "Columns",
                from: ["sourceId", "sourceLat", "sourceLon", "destinationId", "destinationLat", "destinationLon", "value"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        }
    }
};