import React, { Component } from "react";
import PopupContainer from "../../GeneralComponents/PopupContainer/PopupContainer";
import Tab from "../../GeneralComponents/Tab";
import MongoDBConnection from "./MongoDBConnection";
import MysqlDBConnection from "./MysqlDBConnection";
import OracleDBConnection from "./OracleDBConnection";
import MssqlDBConnection from "./MssqlDBConnection";
import PostgreDBConnection from "./PostgreDBConnection";
import Button from "../../GeneralComponents/Button/Button";
import Text from "../../GeneralComponents/Text/Text";
import { showNotificationWithIcon } from "../../../Utils/Notification";
import { post, put } from "../../../Utils/WebService";
import { API_BASE } from "../../../config";
import i18n from "../../../Utils/i18next";
import uuid from "react-uuid";
import $ from "jquery"

const host = API_BASE;
const api = "/dataSource";
const url = host + api;

const clone = require("rfdc")();

//List of supported and connected databases
const data = [
  // {
  //   img: require("../../../images/mongodb.png"),
  //   title: "mongodb",
  //   indexId: 0,
  // },
  {
    img: require("../../../images/mysql.png"),
    title: "mysql",
    indexId: 1,
  },
  {
    img: require("../../../images/oracle.png"),
    title: "oracle",
    indexId: 2,
  },
  {
    img: require("../../../images/postgresql.png"),
    title: "postgresql",
    indexId: 3,
  },
  {
    img: require("../../../images/mssql.png"),
    title: "mssql",
    indexId: 4,
  },
];

/**
 * This component contains database components that are supported and can be connected, and also makes test connection and save connection for all of them.
 */
export default class DataConnectionCreateForms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedData: {},
      editData: {},
      testStatus: false,
      showDetailsTrigger: false,
      visible: this.props.visible ? this.props.visible : false,
      data: data,
      comesFromEmptyDashboard: this.props.comesFromEmptyDashboard ? true : false
    };
  }

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value,
    });
  };

  componentDidMount() {
    if (this.props.comesFromEmptyDashboard) {
      this.setState({
        ...this.state,
        selectedData: {
          "img": require("../../../images/postgresql.png"),
          "title": "postgresql",
          "indexId": 3
        }
      })
    }
  }

  /**
   *
   * When the recorded data connection is edited,
   * filtering is performed according to the data source type and the relevant panel is made full and selected.
   */
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.editConnectionData != undefined &&
      nextProps.visible == true &&
      this.props.changeSelectedDataSourceVisible == true
    ) {

      if (nextProps.editConnectionData === this.props.editConnectionData) {
        return null;
      }

      let dataFilter = data.filter(
        (item) => item.title == nextProps.editConnectionData.dataSourceType
      )[0]; // Returns an array but i know there is only one result

      if (nextProps.editConnectionData.dataSourceType !== "duckdb") {
        let editConnectionData = clone(nextProps.editConnectionData);

        editConnectionData.displayName = editConnectionData.title
        editConnectionData.title = editConnectionData.dataSourceType;
        editConnectionData.indexId = dataFilter.indexId

        this.setState({
          ...this.state,
          selectedData: editConnectionData,
          editData: editConnectionData,
          showDetailsTrigger: true,
          data: [dataFilter],
        });
      } else {
        let editConnectionData = clone(nextProps.editConnectionData);

        this.props.dataSourceEditForWithoutDatabase(editConnectionData, true)
      }
    } else {
      this.setState({
        ...this.state,
        editData: {},
        showDetailsTrigger: false,
        data: data,
      });
    }
  }

  changeSelectedData = (data) => {
    this.setState({
      ...this.state,
      selectedData: data,
    });
  };

  /**
   *  Tests RDBMS and NoSql connections and saves them as disabled if they do not get errors
   */
  testAndSaveConnection = (data) => {
    data.connection.enabled = false;

    let connection = {
      id: data.id,
      displayName: data.displayName,
      config: data.connection,
      dataSourceType: data.dataSourceType,
      schemaName: data.schemaName,
      name: data.name
    };

    const successFunc = (response) => {
      showNotificationWithIcon(
        i18n.t("DataConnections.SuccessTestConnection"),
        null,
        "success"
      );

      this.setState({
        ...this.state,
        testStatus: true,
        dataSource: response.data,
      });
    };

    const errorFunc = (error) => {
      showNotificationWithIcon(i18n.t("Error"), error, "error");
      this.setState({
        ...this.state,
        testStatus: false,
      });
    };

    if (!data.id) {
      connection.id = uuid();
      data.id = connection.id;

      post(url, connection, successFunc, errorFunc);
    } else {
      put(url, connection, successFunc, errorFunc);
    }
  };

  /**
   * Save RDBMS and NoSql data connections
   */
  save = (data) => {
    data.connection.enabled = true;
    data.title = data.displayName;

    if (this.state.dataSource) {
      let dataSource = this.state.dataSource;
      data.id = dataSource.id;
      data.name = dataSource.name;
      data.displayName = dataSource.displayName;
    }

    let connection = {
      id: data.id,
      name: data.name,
      displayName: data.displayName,
      config: data.connection,
      dataSourceType: data.dataSourceType,
      schemaName: data.schemaName,
    };

    const successFunc = (response) => {
      this.props.addData(data);
      showNotificationWithIcon(
        i18n.t("DataConnections.DataConnectionSaved"),
        null,
        "success"
      );

      this.setState({
        ...this.state,
        testStatus: false,
        testConnectionId: undefined,
      });
    };

    put(url, connection, successFunc);
  };

  /**
   *The status of viewing details of the selected database
   */
  changeShowDetails = () => {
    this.setState({
      ...this.state,
      showDetailsTrigger: false,
    });
  };

  cancel = () => {
    this.setState({
      ...this.state,
      testStatus: false,
      testConnectionId: undefined,
    });

    this.props.cancel();
  }

  render() {
    let generalWidth = $("body").innerWidth();
    let dataSourcesPopupWidth = generalWidth * 40 / 100 //Data source popup width must be equal screen width %40
    let modelsPopupWidth = (generalWidth * 53 / 100) //Models popup width must be equal screen width %53
    let dataSourcesPopupPosition = (-modelsPopupWidth) + (-dataSourcesPopupWidth) - 11 //We calculated data sources popup position here. We gave this position left, so this value must be negative, -((Data source popup width) + (models popup width) + margin-left)
    return (
      <div style={{ display: this.props.visible ? "block" : "none" }}>
        {!this.state.comesFromEmptyDashboard ? <PopupContainer
          width={dataSourcesPopupWidth}
          constantHeight="650px"
          additionalPosition="1653%"
          position="absolute"
          right={dataSourcesPopupPosition}
        >
          <Text type="h4">{i18n.t("DataConnections.ConnectDataStore")}</Text>
          <Text type="h6" style={{ color: "#a2a2a2", marginBottom: "27px" }}>
            {" "}
            {i18n.t("DataConnections.ChooseConnectionAndCreate")}
          </Text>
          <div className={"container"}>
            <Tab
              changeSelectedData={this.changeSelectedData}
              editData={this.state.editData}
              col={3}
              imageStyle={{
                border: "1px solid #ccc",
                padding: "3px",
                borderRadius: "50%",
                boxShadow: "0px 0px 2px #e2e2e2",
                maxWidth: "100%",
                maxHeight: "90px",
              }}
              data={this.state.data}
              hiddenText={true}
              textStyle={{
                fontSize: "12px",
                display: "block",
                width: "100%",
                color: "black",
                textAlign: "center",
                marginTop: "3px",
              }}
              changeShowDetails={this.changeShowDetails}
              showDetailsTrigger={this.state.showDetailsTrigger}
            >
              {this.state.selectedData.title == "mongodb" ? (
                <MongoDBConnection
                  visible={this.props.visible}
                  save={this.save}
                  testConnection={this.testAndSaveConnection}
                  testStatus={this.state.testStatus}
                  editData={this.state.editData}
                />
              ) : this.state.selectedData.title == "mysql" ? (
                <MysqlDBConnection
                  visible={this.props.visible}
                  save={this.save}
                  testConnection={this.testAndSaveConnection}
                  testStatus={this.state.testStatus}
                  editData={this.state.editData}
                />
              ) : this.state.selectedData.title == "oracle" ? (
                <OracleDBConnection
                  visible={this.props.visible}
                  save={this.save}
                  testConnection={this.testAndSaveConnection}
                  testStatus={this.state.testStatus}
                  editData={this.state.editData}
                />
              ) : this.state.selectedData.title == "postgresql" ? (
                <PostgreDBConnection
                  visible={this.props.visible}
                  save={this.save}
                  testConnection={this.testAndSaveConnection}
                  testStatus={this.state.testStatus}
                  editData={this.state.editData}
                />
              ) : this.state.selectedData.title == "mssql" ? (
                <MssqlDBConnection
                  visible={this.props.visible}
                  save={this.save}
                  testConnection={this.testAndSaveConnection}
                  testStatus={this.state.testStatus}
                  editData={this.state.editData}
                />
              ) : null}
            </Tab>
          </div>
          <Button
            id="closeDatabaseConnectionButton"
            style={{
              position: "absolute",
              top: "3px",
              right: "14px",
              borderRadius: "40px",
              padding: "6px 11px",
              backgroundColor: "white",
              zIndex: 1,
            }}
            type={"default"}
            onClick={this.cancel}
          >
            X
          </Button>
        </PopupContainer> : <div className={"container"}>
            <Tab
              changeSelectedData={this.changeSelectedData}
              editData={this.state.editData}
              col={4}
              imageStyle={{
                border: "1px solid #ccc",
                padding: "3px",
                borderRadius: "50%",
                boxShadow: "0px 0px 2px #e2e2e2",
                maxWidth: "100%",
                maxHeight: "90px",
              }}
              data={this.state.data}
              hiddenText={true}
              textStyle={{
                fontSize: "12px",
                display: "block",
                width: "100%",
                color: "black",
                textAlign: "center",
                marginTop: "3px",
              }}
              changeShowDetails={this.changeShowDetails}
              showDetailsTrigger={this.state.showDetailsTrigger}
              comesFromEmptyDashboard={true}
            >
              {this.state.selectedData.title == "mongodb" ? (
                <MongoDBConnection
                  visible={this.props.visible}
                  save={this.save}
                  testConnection={this.testAndSaveConnection}
                  testStatus={this.state.testStatus}
                  editData={this.state.editData}
                />
              ) : this.state.selectedData.title == "mysql" ? (
                <MysqlDBConnection
                  visible={this.props.visible}
                  save={this.save}
                  testConnection={this.testAndSaveConnection}
                  testStatus={this.state.testStatus}
                  editData={this.state.editData}
                />
              ) : this.state.selectedData.title == "oracle" ? (
                <OracleDBConnection
                  visible={this.props.visible}
                  save={this.save}
                  testConnection={this.testAndSaveConnection}
                  testStatus={this.state.testStatus}
                  editData={this.state.editData}
                />
              ) : this.state.selectedData.title == "postgresql" ? (
                <PostgreDBConnection
                  visible={this.props.visible}
                  save={this.save}
                  testConnection={this.testAndSaveConnection}
                  testStatus={this.state.testStatus}
                  editData={this.state.editData}
                />
              ) : this.state.selectedData.title == "mssql" ? (
                <MssqlDBConnection
                  visible={this.props.visible}
                  save={this.save}
                  testConnection={this.testAndSaveConnection}
                  testStatus={this.state.testStatus}
                  editData={this.state.editData}
                />
              ) : null}
            </Tab>
          </div>}
      </div>
    );
  }
}