import React, { Component } from "react";
import * as d3 from "d3";
import $ from "jquery";
import { rmvpp } from "../../RenderJs/rmvpp";
import { InsightsConfig } from "../../RenderJs/config";
import L from "leaflet";
import leafletPip from "leaflet-pip";
import geojson2svg from "geojson2svg";
import * as topojson from "topojson";
import "leaflet-easybutton";
import "leaflet/dist/leaflet.css";
import "amcharts3";
import "amcharts3/amcharts/serial";
import "amcharts3/amcharts/themes/light";
import "amcharts3/amcharts/themes/black";
import "amcharts3/amcharts/themes/chalk";
import "amcharts3/amcharts/themes/dark";
import "amcharts3/amcharts/themes/patterns";
import "ammap3";
import "ammap3/ammap/ammap";
import "ammap3/ammap/maps/js/worldHigh";
import "ammap3/ammap/maps/js/turkeyHigh";
import "ammap3/ammap/plugins/export/export";
import { UpdateConfig } from "../../UpdateConfig";
import TurkishDistrictMapConfiguration from "./TurkishDistrictMapConfiguration";
import i18n from "../../../../Utils/i18next";
import {
  onComponentWillMount,
  onComponentWillReceiveProps,
  getColumnMapping,
  calculatePluginHeight
} from "../common";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import {
  renderConfig,
  renderData,
  renderNavigation
} from "../PluginsCommonComponents";
import TurkishDistrictMapData from "./TurkishDistrictMapData";
import { createTrigger } from "../../../Interaction/CreateTrigger";
import { renderContent } from "../renderContent";
import NavigationContent from "../../../Navigation/NavigationContent";
import { TOPOJSON_PATH } from "../../../GeneralComponents/MapTopoJsonOperations/DefaultTopojsons";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";

const data = JSON.parse(
  `[{"value":934558,"id":"EVREN"},{"value":1301080,"id":"ALTINDAĞ"},{"value":1350733,"id":"AKYURT"},{"value":1406979,"id":"AYAŞ"},{"value":1538973,"id":"ETİMESGUT"},{"value":1618409,"id":"GÜDÜL"},{"value":1669087,"id":"ELMADAĞ"},{"value":2354704,"id":"KEÇİÖREN"},{"value":2453182,"id":"KAHRAMANKAZAN"},{"value":2489577,"id":"ÇAMLIDERE"},{"value":2735774,"id":"GÖLBAŞI"},{"value":2867359,"id":"YENİMAHALLE"},{"value":3046497,"id":"BALA"},{"value":3094421,"id":"Ş.KOÇHİSAR"},{"value":3224517,"id":"KALECİK"},{"value":3388747,"id":"MAMAK"},{"value":3411723,"id":"SİNCAN"},{"value":3798081,"id":"NALLIHAN"},{"value":3894953,"id":"HAYMANA"},{"value":4020621,"id":"ÇUBUK"},{"value":4238077,"id":"BEYPAZARI"},{"value":4822028,"id":"POLATLI"},{"value":5021340,"id":"KIZILCAHAMAM"},{"value":6745172,"id":"ÇANKAYA"}]`
);
const config = JSON.parse(
  `{"width":300,"height":300,"isMulti":true,"theme":"None","toggleCriteria":"","title":"","summary":"","backgroundColor":"rgb(255,255,255)","selectedColor":"#333333","showHideButton":false,"refresh":0}`
);
const columnMap = JSON.parse(
  `{"value":{"Code":"\'ankara_mahalle_ilce\'.\'deger\'","Name":"deger","DataType":"double","Table":"ankara_mahalle_ilce","Measure":"sum(ankara_mahalle_ilce.deger)","ID":"ankara_mahalle_ilce.deger","SubjectArea":"harita","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":".3s","Config":{},"Verified":false,"Type":"Column","Description":""},"id":{"Code":"\'ankara_mahalle_ilce\'.\'ilce\'","Name":"ilce","DataType":"varchar","Table":"ankara_mahalle_ilce","Measure":"none","ID":"ankara_mahalle_ilce.ilce","SubjectArea":"harita","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":"%s","Config":{},"Verified":true,"Type":"Column","Description":""},"ilId":{"Code":"","Name":"","DataType":"varchar","Table":"Unspecified","Measure":"none","ID":"Unspecified.","SubjectArea":"","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":"%s","Config":{},"Verified":false,"Type":"Column"},"title":{"Code":"","Name":"","DataType":"varchar","Table":"Unspecified","Measure":"none","ID":"Unspecified.","SubjectArea":"","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":"%s","Config":{},"Verified":false,"Type":"Column"}}`
);

const condFormats = [];
const filters = [];

let pluginName = "districtMap"; // ID of the plugin, should be the same as the sub-directory underneath /vispeahen/plugins
const description =
  "Template plugin file that can be used by developers to create new plugins or learn to modify existing ones."; // Description of the plugin

// Mapping between RPD columns and the visualisation
const columnMappingParameters = [
  {
    targetProperty: "value", // ID of the property in columnMap object of render function
    formLabel: "Value", // Name the user will see
    type: "fact", // Either dim, fact or hidden - dimension, measure or hidden
    multiple: false, // Boolean for whether you can have more than one column in this section
    conditionalFormat: true, // Allow this to be conditionally formatted
    required: true, // Indicate to the user that this is required
    desc: "desc12" //'Harita renklenmesi için değer' // Description that is displayed to the user
  },
  {
    targetProperty: "id", // ID of the property in columnMap object of render function
    formLabel: "DistrictId", // Name the user will see
    type: "dim", // Either dim, fact or hidden - dimension, measure or hidden
    multiple: false, // Boolean for whether you can have more than one column in this section
    conditionalFormat: true, // Allow this to be conditionally formatted
    required: true, // Indicate to the user that this is required
    desc: "desc13" // 'İlçe numarası' // Description that is displayed to the user
  },
  {
    targetProperty: "ilId", // ID of the property in columnMap object of render function
    formLabel: "CityId", // Name the user will see
    type: "dim", // Either dim, fact or hidden - dimension, measure or hidden
    multiple: false, // Boolean for whether you can have more than one column in this section
    conditionalFormat: true, // Allow this to be conditionally formatted
    required: false, // Indicate to the user that this is required
    desc: "desc14" //'İl kodları' // Description that is displayed to the user
  },
  {
    targetProperty: "title", // ID of the property in columnMap object of render function
    formLabel: "DistrictName", // Name the user will see
    type: "dim", // Either dim, fact or hidden - dimension, measure or hidden
    multiple: false, // Boolean for whether you can have more than one column in this section
    conditionalFormat: true, // Allow this to be conditionally formatted
    required: false, // Indicate to the user that this is required
    desc: "desc15" //'İlçe adı' // Description that is displayed to the user
  }
];

// Global configuration for the plugin - must have width and height or size at minimum.
const configurationParameters = [
  {
    targetProperty: "width", // Name of the property in config object of render function
    label: "Width", // Name the user will see
    inputType: "textbox", // Indicates the UI element for setting this parameter
    inputOptions: {
      // Sub-parameters for the UI element
      subtype: "number",
      defaultValue: 300
    },
    desc: "desc89" // Description displayed to the user
  },
  {
    // Height and width are mandatory properties unless size is specified
    targetProperty: "height",
    label: "Height",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 300
    },
    desc: "desc90"
  },
  {
    // Height and width are mandatory properties unless size is specified
    targetProperty: "isMulti",
    label: "IsMulti",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "IsMulti"
  },

  {
    // Height and width are mandatory properties unless size is specified
    targetProperty: "theme",
    label: "Theme",
    inputType: "dropdown",
    inputOptions: {
      multiSelect: false,
      values: ["None", "Dark", "Crimson", "Chalk", "Light", "Patterns"],
      defaultValue: "None"
    },
    desc: "desc92"
  },
  {
    targetProperty: "toggleCriteria",
    label: "ToggleCriteria",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc59"
  },
  {
    targetProperty: "title",
    label: "Title",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc94"
  },
  {
    targetProperty: "summary",
    label: "Summary",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc61"
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: { defaultValue: "rgb(255,255,255)" },
    desc: "desc62"
  },
  {
    targetProperty: "selectedColor",
    label: "SelectedColor",
    inputType: "colour",
    inputOptions: {
      defaultValue: "#333333"
    },
    desc: "desc134"
  },
  {
    targetProperty: "showHideButton",
    label: "Show Hide Button",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc230"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  }
];

// Interactions the user can make on this visualisation (e.g. click, highlight)
const actions = [
  {
    trigger: "clickFeature",
    type: "click",
    name: "Tıklama Feature",
    output: ["id", "ilId", "title"],
    description: "ClickFeatureDesc"
  },
  {
    trigger: "doubleClickFeature",
    type: "click",
    name: "Çift Tıklama Feature",
    output: ["id"],
    description: "DoubleClickFeatureDesc"
  },
  {
    trigger: "hoverMapObject",
    type: "hover",
    name: "Hover",
    output: ["id"],
    description: "HoverMapObjectDesc"
  }
];

// Reactions this visualisation can have to other interactions. Everything can use filters
const reactions = [
  {
    id: "filter",
    name: "Filter",
    description: "desc215",
    type: "general"
  }
];

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

function getNumberSeparators() {
  // default
  const res = {
    decimal: ".",
    thousand: ""
  };

  // convert a number formatted according to locale
  const str = parseFloat(1234.56).toLocaleString();

  // if the resulting number does not contain previous number
  // (i.e. in some Arabic formats), return defaults
  if (!str.match("1")) return res;

  // get decimal and thousand separators
  res.decimal = str.replace(/.*4(.*)5.*/, "$1");
  res.thousand = str.replace(/.*1(.*)2.*/, "$1");

  // return results
  return res;
}

/**
 * Get locale settings
 */
const separators = getNumberSeparators();

// This is provided all of the information required to make the visualisation at run-time.
// Your code to make the visualisation in the container DOM element should go here.

/**
 * renders TurkishDistrictMap plugin in Vispeahen V3
 */
export default class TurkishDistrictMap extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.rerenderProcessStarted = false;
    this.callBackObject = {};
  }

  calculatePluginHeight = plugin => {
    let containerHeight = plugin.containerHeight;
    let pluginTitleContainer = $("#title-" + plugin.id);
    let pluginContainerBorder = -2;
    let maxHeight =
      containerHeight -
      (pluginTitleContainer.outerHeight() +
        parseInt(pluginTitleContainer.css("margin-bottom")) +
        pluginContainerBorder);
    return maxHeight;
  };

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = {...this.callBackObject};
    this.setCallBackObject({})
    
    return tmpCallBackObject;
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );
  }

  getNavigationComponent = props => {
    return (
      <NavigationContent
        navigations={this.props.navigations}
        setNavigations={this.props.updatePlugin}
        plugin={this.props.plugin}
        dashboardInformation={this.props.dashboardInformation}
      />
    );
  };

  getConfigComponent = props => {
    if (props.config) {
      return (
        <TurkishDistrictMapConfiguration
          config={{ ...props.config }}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
        />
      );
    }

    return null;
  };

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }

    return (
      <TurkishDistrictMapData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        plugin={props.plugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  /**
   * To set column map this plugin
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      value: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Value.Name"),
        type: "dim",
        required: true,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Value.Desc"),
        data: []
      },
      ilId: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.CityId.Name"),
        type: "fact",
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.CityId.Desc"),
        required: true,
        conditionalFormat: true,
        data: []
      },
      title: {
        name: i18n.t(
          "Plugins." + tempPlugin.key + ".ColumnMap.DistrictName.Name"
        ),
        type: "fact",
        desc: i18n.t(
          "Plugins." + tempPlugin.key + ".ColumnMap.DistrictName.Desc"
        ),
        required: true,
        conditionalFormat: true,
        data: []
      },
      id: {
        name: i18n.t(
          "Plugins." + tempPlugin.key + ".ColumnMap.DistrictId.Name"
        ),
        type: "fact",
        desc: i18n.t(
          "Plugins." + tempPlugin.key + ".ColumnMap.DistrictId.Desc"
        ),
        required: true,
        conditionalFormat: true,
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {
    const THIS = this;

    // Set html empty and set this html to container.
    $("#" + divId).html("");
    let container = $("#" + divId)[0];

    rmvpp.leafletExtension(L, topojson);

    let map;
    const randomMapId = Math.floor(Math.random() * 10);

    let width = +config.width;
    let height = +config.height;
    let visWidth = "100%";
    let visHeight = height + "px";
    let visBackgroundColor = config.backgroundColor;

    $(container).append(`
            <div class="district-map-vis" id="ilceMap${randomMapId}" style="  height: ${visHeight}; width: ${visWidth}; background-color: ${visBackgroundColor}; color: #fff"></div>`);
    $.getJSON(TOPOJSON_PATH + "/ilceler_detayli.json", geoJson => {
      const mapVar = window.AmCharts.parseGeoJSON(geoJson);
      map = window.AmCharts.makeChart(`ilceMap${randomMapId}`, {
        type: "map",
        theme: config.theme.toLowerCase(),
        decimalSeparator: separators.decimal,
        thousandsSeparator: separators.thousand,
        dataProvider: {
          mapVar: mapVar,
          // "getAreasFromMap": true
          areas: data
        },
        areasSettings: {
          autoZoom: false,
          selectedColor: config.isMulti ? config.selectedColor : undefined,
          balloonText: "[[title]]: <b>[[value]]</b>",
          selectable: true,
          outlineColor: "#000000"
        },
        valueLegend: {
          right: 10,
          minValue: "A little",
          maxValue: "A lot!"
        },
        listeners: [
          {
            event: "init",
            method: function(e) {}
          }
        ]
      });

      const set = new Set();
      if (set.size > 0) {
        set.forEach(elem => {
          const id = JSON.parse(elem).id;
          const mapObject = map.dataProvider.areas.find(area => area.id === id);

          if (mapObject) {
            map.selectedObject = map.dataProvider;
            mapObject.showAsSelected = !mapObject.showAsSelected;
            map.returnInitialColor(mapObject);
          }
        });
      }

      map.clickedObject = false;
      map.clickedObjectTimeout = false;
      map.addListener("clickMapObject", event => {
        const datum = {
          id: data.find(d => d.id === event.mapObject.id).id,
          ilId: data.find(d => d.id === event.mapObject.id).ilId,
          title: data.find(d => d.id === event.mapObject.id).title
        };
        let mousePosition = {x: event.pageX, y: event.pageY}

        if (
          false !== map.clickedObject &&
          map.clickedObject === event.mapObject
        ) {
          map.clickedObject = false;
          createTrigger(
            actions,
            columnMap,
            container,
            "doubleClickFeature",
            datum,
            THIS.props.plugin.id,
            THIS.props.interactions,
            THIS.props.navigations,
            mousePosition
          );
        } else {
          const set = config.isMulti ? new Set() : new Set();
          const stringified = JSON.stringify(datum);

          if (set.has(stringified)) {
            set.delete(stringified);
          } else {
            set.add(stringified);
          }

          const array =
            set.size > 0 ? Array.from(set).map(d => JSON.parse(d)) : data;
          createTrigger(
            actions,
            columnMap,
            container,
            "clickFeature",
            array,
            THIS.props.plugin.id,
            THIS.props.interactions,
            THIS.props.navigations,
            mousePosition
          );

          clearTimeout(map.clickedObjectTimeout);
          map.clickedObject = event.mapObject;
          map.clickedObjectTimeout = setTimeout(function() {
            map.clickedObject = false;
          }, 1000);

          if (config.isMulti) {
            map.selectedObject = map.dataProvider;
            event.mapObject.showAsSelected = !event.mapObject.showAsSelected;
            map.returnInitialColor(event.mapObject);
          }

          // İlçe seçince ildeki ilçelerin görünmesi
          // map.dataProvider.areas.forEach(area => {
          //     if (setIl.size === 0 || setIl.has(area.ilId)) {
          //         area.alpha = 1;
          //     } else {
          //         area.alpha = 0;
          //     }
          // });
          // map.validateData();
        }
      });
      map.addListener("rollOverMapObject", event => {
        const datum = data.find(d => d.id === event.mapObject.id);
        let mousePosition = {x: event.pageX, y: event.pageY}

        createTrigger(
          actions,
          columnMap,
          container,
          "hoverMapObject",
          datum,
          THIS.props.plugin.id,
          THIS.props.interactions,
          THIS.props.navigations,
          mousePosition
        );
      });
    });

    window.AmCharts.parseGeoJSON = function(geojson, fieldMap) {
      // init field map
      if (typeof fieldMap !== "object") fieldMap = {};

      // init calibration
      const bounds = {
        left: -180,
        bottom: -90,
        right: 180,
        top: 90
      };

      // init empty map
      const mapVar = {
        svg: {
          defs: {
            "amcharts:ammap": {
              projection: "mercator",
              leftLongitude: "-180",
              topLatitude: "90",
              rightLongitude: "180",
              bottomLatitude: "-90"
            }
          },
          g: {
            path: []
          }
        }
      };

      // convert GeoJSON to SVG paths
      const converter = geojson2svg({
        output: "svg",
        explode: false,
        attributes: [
          {
            property: "properties.NAME_2",
            type: "dynamic",
            key: "title"
          },
          {
            property: "properties.NAME_1",
            type: "dynamic",
            key: "city"
          }
        ],
        mapExtent: bounds,
        viewportSize: {
          width: 800,
          height: 800
        }
      });
      const svg = converter.convert(geojson, {});

      // parse each path into JavaScript Maps data structure
      for (let i = 0; i < svg.length; i++) {
        const path = svg[i];
        const attrs = path.match(/\w+=".*?"/g);
        const area = {};
        for (let x = 0; x < attrs.length; x++) {
          const parts = attrs[x].replace(/"/g, "").split("=");
          const key = fieldMap[parts[0]] || parts[0];
          area[key] = parts[1];
        }
        mapVar.svg.g.path.push(area);
      }

      return mapVar;
    };

    if (config.hideLabel) {
      let hideLabel = $(container).find(".district-map-vis");
      hideLabel.hide();
    }

    this.props.setPluginRerender(false, this.props.plugin.id, false, this.props.plugin.isInteraction);
  };

  currentHeight;
  lastContent = undefined;

  updateLastContent = (status) => {
    this.lastContent = status
  } 

  render() {
    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    let navigationComponent = null;
    if (this.props.navigationComponentVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      navigationComponent = renderNavigation(
        popupPosition,
        this.props,
        this.getNavigationComponent
      );
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };

    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + this.props.plugin.id).css("padding")
      );

      pluginConfig.height =
        this.calculatePluginHeight(this.props.plugin) -
        pluginContainerPadding * 2;
    
      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      );
    }

    return (
      <div style={{height: "100%"}}>
        <div id={this.props.plugin.id}></div>
        {renderContent(
          isRerender,
          this.pluginRender,
          this.props.plugin,
          data,
          columnMap,
          pluginConfig,
          condFormats,
          this.props.setPluginRerender,
          this.lastContent,
          this.updateLastContent,
        )}
        {configComponent}
        {dataComponent}
        {navigationComponent}
      </div>
    );
  }
}
