import { deepCopy } from "../../../Utils/Global";
import { CHANGE_NEW_COLUMN_LIST, NEW_COLUMN_EDITOR_CONTENT } from "./NewColumnEditorAction";

/**
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns 
 * 
 * Reducer for Formula Editor content
 */
export default function NewColumnEditorReducer(
  state = {
    contentObject: {
      visible: false,
      code: "",
      column: {},
      selectedFormula: {},
      selectedTableObject: {},
      selectedTable: "",
      mode: "add",
      tableKey: 0,
      edittedColumn: {},
      newColumnList: []
    }
  },
  action
) {
  switch (action.type) {
    case NEW_COLUMN_EDITOR_CONTENT:
      return {
        ...state,
        contentObject: {
          ...state.contentObject,
          visible: action.payload.visible !== undefined ? action.payload.visible : state.contentObject.visible,
          code: action.payload.code || action.payload.code === "" ? action.payload.code : state.contentObject.code,
          column: action.payload.column ? deepCopy(action.payload.column) : deepCopy(state.contentObject.column),
          selectedTable: action.payload.selectedTable ? action.payload.selectedTable : state.contentObject.selectedTable,
          mode: action.payload.mode ? action.payload.mode : state.contentObject.mode,
          selectedFormula: action.payload.selectedFormula ? action.payload.selectedFormula : state.contentObject.selectedFormula,
          tableKey: action.payload.tableKey !== undefined ? action.payload.tableKey : state.contentObject.tableKey,
          edittedColumn: deepCopy(action.payload.edittedColumn) !== undefined ? deepCopy(action.payload.edittedColumn) : deepCopy(state.contentObject.edittedColumn)
        }
      };
    case CHANGE_NEW_COLUMN_LIST:
      return {
        ...state,
        contentObject: {
          ...state.contentObject,
          newColumnList: action.payload
        }
      }
    default:
      return state;
  }
}
