import React, { Component } from 'react';
import { Button, Tooltip } from "antd";
import { TbPin, TbPinnedOff } from 'react-icons/tb';
import i18n from '../../../../../Utils/i18next';
import { MailOutlined } from '@ant-design/icons';

/**
 * Dashboard mail header component contains close and pin buttons
 */
class DashboradMailHeader extends Component {
  render() {
    let pincheck = this.props.isPinned;

    return (
      <div className="dashboard-mail-header-area">
        <div className="mail-text-area">
          <MailOutlined className="send-mail-button-mobile" />
          <p className="mail-text">
            <MailOutlined className="send-mail-button" />
            {i18n.t("DashboardMail.SendMail")}
          </p>
        </div>
        <div className="dashboard-mail-buttons">
          <Tooltip
            title={i18n.t("DashboradMail.Close")}
            placement={"bottom"}
            overlayClassName={"pin-tooltip dashboard-mail-tooltip"}>
            <Button
              id={"dashboard-mail-send-mail-button"}
              style={{
                borderRadius: "40px",
                padding: "6px 11px",
                backgroundColor: "white",
                zIndex: 1
              }}
              type={"default"}
              onClick={() => {
                this.props.closeMailPopup(false);
              }}
            >
              X
            </Button>
          </Tooltip>
          <Tooltip
            title={i18n.t(pincheck ? "Model.UnPin" : "Model.Pin")}
            placement={"bottom"}
            overlayClassName={"pin-tooltip dashboard-mail-tooltip"}>
            <Button
              id="pinTop"
              className="pin-icon-mail"
              style={
                pincheck
                  ? {
                    border: "1px solid #1890ff",
                    color: "#1890ff",
                    position: "unset!important"
                  }
                  : {
                    border: "1px solid rgb(217,217,217)",
                    color: "rgba(0,0,0,0.65)",
                    position: "unset!important"
                  }
              }
              type={"default"}
              onClick={this.props.changePinStatus}
            >
              {this.props.isPinned ? <TbPin size={22} /> : <TbPinnedOff size={22} />}
            </Button>
          </Tooltip>
        </div>
      </div>
    );
  }
}

export default DashboradMailHeader;