import React, { Component } from 'react';
import { FaRegComments } from "react-icons/fa"
import moment from "moment"
import "moment/locale/tr"
import Cookies from 'js-cookie';
import PerDashboardComment from './PerDashboardComment';
import i18n from '../../../../../Utils/i18next';

class DashboardCommentsBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeCommentId: ""
        };
    }

    componentDidMount() {
        let currLang = Cookies.get("i18next")

        moment.locale(currLang)
    }

    /*
    * Changes hovered div status
    */
    setActiveCommentId = id => {
        this.setState({
            ...this.state,
            activeCommentId: id
        })
    }

    render() {
        return (
            <div className='dashboard-comment-body'>
                {this.props.dashboardComments.length === 0
                    ?
                    <div className='dashboard-empty-comment-body'>
                        <div className='empty-comment-body-top'>
                            <FaRegComments className='empty-comment-background-icon' fontSize={"70px"} />
                            <div className="empty-comment-information-text">{i18n.t("DashboardComments.EmptyDashboardAddText")}</div>
                        </div>
                        <div className='empty-comment-information-bottom-main'>
                            <div className='empty-comment-information-text'>{i18n.t("DashboardComments.EmptyDashboardWhoCanSeeYourComments")}</div>
                            <div className='empty-comment-information-bottom-text'>{i18n.t("DashboardComments.EmptyDashboardWCSYCText")}</div>
                        </div>
                    </div>
                    :
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                        {this.props.dashboardComments.map(dbComment => (
                            <>
                                {dbComment.username ? <div className={"db-comment-wrapper"} style={this.state.activeCommentId === dbComment.id ? { borderColor: this.props.usersWithColours.get(dbComment.username) } : {}} onMouseEnter={() => { this.setActiveCommentId(dbComment.id) }} onMouseLeave={() => { this.setActiveCommentId("") }}>
                                    <PerDashboardComment
                                        editComment={this.props.editComment}
                                        dashboard={this.props.dashboard}
                                        sendReply={this.props.sendReply}
                                        users={this.props.users}
                                        removeComment={this.props.removeComment}
                                        usersWithColours={this.props.usersWithColours}
                                        dbComment={dbComment}
                                        mainComment={true}
                                        mainId={dbComment.id}
                                        operationType={this.props.operationType}
                                        setActiveCommentId={this.setActiveCommentId}
                                        mainDashboardId={this.props.mainDashboardId}>
                                        {dbComment.replies.map((reply, i) => (
                                            <>
                                                {reply.username ? 
                                                <PerDashboardComment
                                                    editComment={this.props.editComment}
                                                    removeComment={this.props.removeComment}
                                                    usersWithColours={this.props.usersWithColours}
                                                    dbComment={reply} mainComment={false}
                                                    mainId={dbComment.id}
                                                    users={this.props.users}
                                                    setActiveCommentId={this.setActiveCommentId}
                                                    dashboard={this.props.dashboard}
                                                    operationType={this.props.operationType}
                                                    lastElement={dbComment.replies.length - 1 === i ? true : false} 
                                                    mainDashboardId={this.props.mainDashboardId}
                                                /> 
                                                : null}
                                            </>
                                        ))}
                                    </PerDashboardComment>
                                </div> : null}
                            </>
                        ))}
                    </div>}
            </div>
        );
    }
}

export default DashboardCommentsBody;