import React, { Component } from "react";
import { Row, Col } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import Text from "../GeneralComponents/Text/Text";
import i18n from "../../Utils/i18next";
import Button from "../GeneralComponents/Button/Button";
import CustomExcelListWithDelete from "./CustomExcelListWithDelete";
import UploadCustomExcel from "./UploadCustomExcel";

/**
 * Dashboard custom excel settings base component
 */
export default class CustomExcelSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadModalVisible: false
    };
  }

  /**
   * Removes custom excel by id
   */
  removeExcel = id => {
    this.props.removeCustomExcel(id);
  };

  /**
   * Updates visibility of upload modal
   */
  setVisibilityOfUploadModal = status => {
    this.setState({
      ...this.state,
      uploadModalVisible: status
    });
  };

  render() {
    return (
      <>
        <Row gutter={2} style={{ marginBottom: "15px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FileExcelOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Button
              id="uploadExcelButton"
              className={"general-button"}
              onClick={() => this.setVisibilityOfUploadModal(true)}
              style={{
                width: "100%",
                borderBottom: "0",
                height: "fit-content",
                padding: "4px 0",
                marginBottom: "0",
                top: "1px"
              }}
            >
              {i18n.t("Dashboard.Settings.UploadNewExcel")}
            </Button>
          </Col>
        </Row>
        {this.props.customExcelList.length > 0 ? (
          <>
            <Row gutter={2} style={{ marginBottom: "5px" }}>
              <Col span={24}>
                <Text
                  type="span"
                  style={{
                    fontSize: "15px",
                    textAlign: "left",
                    display: "block",
                    whiteSpace: "normal",
                    display: "block",
                    marginTop: "10px",
                    fontWeight: "bold",
                    paddingLeft: "15px",
                    marginBottom: "10px"
                  }}
                >
                  {i18n.t("Dashboard.Settings.ExcelList")}:
                </Text>
              </Col>
            </Row>

            <CustomExcelListWithDelete
              removeExcel={this.removeExcel}
              downloadCustomExcel={this.props.downloadCustomExcel}
              list={this.props.customExcelList}
            />
          </>
        ) : null}
        <UploadCustomExcel
          dashboardId={this.props.dashboardId}
          getCustomExcels={this.props.getCustomExcels}
          uploadModalVisible={this.state.uploadModalVisible}
          mainDashboardId={this.props.mainDashboardId}
          setVisibilityOfUploadModal={this.setVisibilityOfUploadModal}
        />
      </>
    );
  }
}
