import React, { Component } from "react";
import { Row, Col, Pagination, Dropdown, Menu, Tooltip } from "antd";
import DataColumn from "../../DataComponents/DataColumn/DataColumn";
import Accordion from "../../../GeneralComponents/Accordion/Accordion";
import Search from "../../../GeneralComponents/Search/Search";
import AccordionContent from "../../../GeneralComponents/Accordion/AccordionContent";
import i18n from "../../../../Utils/i18next";
import { Draggable } from "react-drag-and-drop";
import Text from "../../../GeneralComponents/Text/Text";
import uuid from "react-uuid";
import SortArea from "../../DataComponents/SortItemContent/SortArea";
import PropTypes from "prop-types";
import { aggregatableDataTypes, dateDataTypes } from "../../DataComponents/DataConfigure";
import EmptyDataContent from "./EmptyDataContent";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join"
import { deepCopy } from "../../../../Utils/Global";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";
import { sortByLanguage } from "../../../../Utils/SortByLanguage";

const tableCountInPage = 7; //Table count for a page.

/**
 * This component, every data components common content.
 * Includes: search, selected model data, search area.
 * this.props.children (right side of data component = column mapping side)
 */
export default class DataConstantContent extends Component {
  constructor(props) {
    super(props);

    this.prototypes = {
      model: PropTypes.object,
      columnMap: PropTypes.object,
      sortedColumnList: PropTypes.array,
      updateSortedList: PropTypes.func,
      limit: PropTypes.number,
      onLimitChange: PropTypes.func,
      defaultActiveKey: PropTypes.string,
      onDragStart: PropTypes.func,
      onDragEnd: PropTypes.func
    };

    this.state = {
      iconIndex: 0,
      dropdownSort: "ASC",
      sortedTables: [],
      search: "",
      searchTempData: props.model.tables,
      searchCategory: {},
      dropContainerVisible: false,
      page: 1
    };
  }

  sortMenuData = [
    { key: "ASC", title: i18n.t("LandingPage.DashboardLabel.NameAscending"), icon: "fa fa-sort-alpha-down"},
    { key: "DESC", title: i18n.t("LandingPage.DashboardLabel.NameDescending"), icon: "fa fa-sort-alpha-down-alt"},
    { key: "DEFAULT", title: i18n.t("Default"), icon: "fas fa-random"}
  ];

  /**
   * On change Sorting value, call this function.
   * @param {*} value "ASC, DESC, DEFAULT"
   */
  onChangeSortingPreference = (value,i) => {
    this.setState(
      {
        ...this.state,
        iconIndex: i,
        dropdownSort: value,
        sortedTables: this.sortTablesAndColumnsWithPreference(
          this.state.searchTempData,
          value
        ),
      },
      () => {
        this.onPageChange(1);
        this.handleSearch(this.state.search);
      }
    );
  }

/**
 * Returns Table & Columns sorting dropdown context.
 * @returns 
 */
  tableColumSorttingMenu = () => {
    return (
      <Menu getPopupContainer={(trigger) => trigger.parentNode}>
        {this.sortMenuData.map((item, i) => {
          return (
            <Menu.Item onClick={() => this.onChangeSortingPreference(item.key, i)}>
              <i className={item.icon} style={{ marginRight: 5 }}></i>
             <Text style={{ fontWeight: this.state.iconIndex === i ? "bold" : "" }}>{item.title}</Text> 
            </Menu.Item>
          );
        })}
      </Menu>
    );
  }

  /**
   * Sorting Tables with given direction and Return Sorted or default Tables data
   * @param {*} data 
   * @param {*} direction 
   * @returns 
   */
  sortTablesAndColumnsWithPreference = (data, direction) => {
    switch (direction) {
      case "ASC":
        return this.sortTablesAndColumns(data);
      case "DESC":
        return this.sortTablesAndColumns(data, true);
      default:
        return data;
    }
  }

  componentWillMount() {
    let sortedTables = this.sortTablesAndColumnsWithPreference(this.state.searchTempData, this.state.dropdownSort);
    let tablesInPage = this.getTablesInSelectedPage(this.state.page, sortedTables);

    this.setState({
      ...this.state,
      searchTempData: this.props.model.tables,
      sortedTables: sortedTables,
      tablesInPage: tablesInPage,
    })
  }

  componentWillReceiveProps(nextProps) {
    let newState = { ...this.state }
    let isStateChanged = false;

    if (this.props.model.tables !== nextProps.model.tables) {
      newState.searchTempData =  this.sortTablesAndColumnsWithPreference(nextProps.model.tables, this.state.dropdownSort);
      newState.page = 1;
      newState.tablesInPage = this.getTablesInSelectedPage(
        newState.page,
        newState.searchTempData
      );

      isStateChanged = true;
    }

    if (isStateChanged === true) {
      this.setState(newState)
    }

    if (this.props.sortedColumnList !== nextProps.sortedColumnList || nextProps.clickedRefresh === true) {
      let data = checkTableJoins(this.props.join, this.props.columnMap, nextProps.refreshedPluginId, this.props.pluginId)

      if (data.length >= 2 && this.props.hasNotJoinedData != true) {
        this.props.changeHasNotJoinedData(true)
      }

      if (data.length >= 2 && nextProps.clickedRefresh === true) {
        this.props.setClickedRefresh(false)
      } else if (data.length < 2 && nextProps.clickedRefresh === true) {
        this.props.changeHasNotJoinedData(false)
        this.props.setClickedRefresh(false)
      } else if (data.length < 2 && this.props.hasNotJoinedData === true) {
        this.props.changeHasNotJoinedData(false)
      }
    }
  }

  /**
   * Change selected page
   * @param {*} value
   */
  onPageChange = (value) => {
    let willSortedTable = this.sortTablesAndColumnsWithPreference(this.state.sortedTables, this.state.dropdownSort); 
    let tablesInPage = this.getTablesInSelectedPage(value, willSortedTable);

    this.setState({
      ...this.state,
      page: value,
      tablesInPage: tablesInPage,
    });
  };

  /**
   * Calculates page count by table size
   * @param {*} tables
   * @returns
   */
  calculatePageSize = (tables) => {
    if (tables && tables.length > 0) {
      return Math.ceil(tables.length / tableCountInPage);
    }

    return 1; //set 1 page size
  };

  /**
   * Get tables in table list by page
   * @param {*} state
   * @returns
   */
  getTablesInSelectedPage = (page, tables) => {
    let startIndex = (page - 1) * tableCountInPage;
    let endIndex = startIndex + tableCountInPage;

    if (tables) {
      return tables.slice(startIndex, endIndex);
    }

    return [];
  };

  /**
   * Table search
   * @param tempTables tables for search
   * @param input value of input
   */
  tableSearch = (tempTables, input) => {
    let data = deepCopy(tempTables);

    data = this.sortTablesAndColumnsWithPreference(data, this.state.dropdownSort);

    return data?.filter(table => {
      return table.displayName
        .toLowerCase()
        .includes(input.toLowerCase());
    });
  };

  // To sort all tables & columns 
  sortTablesAndColumns = (tempTables, reverse = false) => {
    let data = deepCopy(tempTables);

    // Sort Columns
    let sortedTables = data?.map((table) => {
      if (reverse){
        return table.columns.sort((a, b) => sortByLanguage(a.displayName, b.displayName)).reverse()
      } else {
        return table.columns.sort((a, b) => sortByLanguage(a.displayName, b.displayName));
      }
    });

    // Sort Tables
    if (reverse){
      sortedTables = data?.sort((a, b) => sortByLanguage(a.displayName, b.displayName)).reverse();
    } else {
      sortedTables = data?.sort((a, b) => sortByLanguage(a.displayName, b.displayName));
    }

    return sortedTables;
};

  /**
   * Column search
   * @param tempTables tables for search
   * @param input value of input
   */
  columnSearch = (tempTables, input) => {
    let data = deepCopy(tempTables);

    data = this.sortTablesAndColumnsWithPreference(data, this.state.dropdownSort);

    return data?.filter(table => {
      table.columns = table.columns.filter(column => {
        return column.displayName
          .toLowerCase()
          .includes(input.toLowerCase());
      });

      return table.columns.length != 0;
    });
  };

  /**
   * On search change, take input value.
   * @param {*} value 
   */
  handleSearch = value => {
    let tempTables = deepCopy(this.props.model.tables);
    let searchedTables = deepCopy(this.props.model.tables);

    if (value && value != "") {
      //search category is empty
      if (this.state.searchCategory.name == undefined) {
        //first check table display names
        searchedTables = this.tableSearch(tempTables, value);

        //if data is empty, second check column display names
        if (searchedTables?.length == 0) {
          tempTables = deepCopy(this.props.model.tables);
          searchedTables = this.columnSearch(tempTables, value);
        }
      } else if (this.state.searchCategory.name == "Table") {
        //search category is table
        searchedTables = this.tableSearch(tempTables, value);
      } else if (this.state.searchCategory.name == "Column") {
        //search category is data
        searchedTables = this.columnSearch(tempTables, value);
      }
    }

    searchedTables = searchedTables && this.sortTablesAndColumnsWithPreference(searchedTables, this.state.dropdownSort);

    let tablesInPage = this.getTablesInSelectedPage(1, searchedTables);

    this.setState({
      ...this.state,
      search: value,
      sortedTables: searchedTables,
      page: 1,
      tablesInPage: tablesInPage
    });
  };

  searchCategory = item => {
    this.setState({
      searchCategory: { ...item }
    });
  };

  render() {
    let isRoleIsWrite = isValidWriteRoles() === true ? true : false

    if (this.props.model.id === "defaultModel" || this.props.model?.id == undefined) {
      return (
        <EmptyDataContent />
      )
    }

    return (
      <div>
        <Row gutter={12}>
          {isRoleIsWrite ?
            <>
              <Col span={12}>
                <Text
                  style={{
                    display: "block",
                    width: "100%",
                    textAlign: "center",
                    color: "#225f8f"
                  }}
                >
                  {i18n.t("Tables")} & {i18n.t("Columns")}
                </Text>
                <Row>
                  <Col span={21} style={{ marginTop: 2 }}>
                    <Search
                      paddingNone={true}
                      marginBottom={"10px"}
                      inputStyle={{ minWidth: "auto" }}
                      id={"searchData"}
                      width="100%"
                      searchBarVisible={true}
                      onChange={(event)=> this.handleSearch(event.target.value)}
                      searchItemList={[
                        { name: "Table", translate: "Table", icon: "fas fa-table" },
                        { name: "Column", translate: "Column", icon: "fas fa-columns" }
                      ]}
                      searchCategory={this.searchCategory}
                      selectedSearchCategory={this.state.searchCategory}
                    ></Search>
                  </Col>
                  <Col span={3}>
                    <Tooltip title={i18n.t(this.sortMenuData[this.state.iconIndex].title)}>
                      <Dropdown
                        overlay={this.tableColumSorttingMenu}
                        trigger={["click"]}
                        placement="bottomRight"
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        <i
                        className={this.sortMenuData[this.state.iconIndex].icon} 
                        id="table-columns-sort-dropdown"/>
                      </Dropdown>
                    </Tooltip>
                  </Col>
                </Row>
                <Accordion
                  accordionStyle={{
                    minHeight: "300px",
                    maxHeight: "375px",
                    padding: "2px"
                  }}
                  defaultActiveKey={this.props.defaultActiveKey}
                  accordionClass={"data-area-overflow"}
                >
                  {this.props.model && this.state.tablesInPage 
                    ? this.state.tablesInPage.map((table, index) => (
                      <AccordionContent
                        pluginId={this.props.pluginId}
                        titleClass={"dataAccordionHeader"}
                        key={"constant-acc-" + index}
                        title={table.displayName}
                        headerStyle={{
                          background: "#fff",
                          borderBottom: "1px solid #dadada"
                        }}
                        collapseStyle={{ background: "#fff" }}
                        eventKey={table.dataSourceKey + "_" + table.aliasName}
                      >
                        {table.columns
                          .filter(c => c.selected !== false)
                          .map((column, colIndex) => (
                            <Draggable
                              key={"draggable-col-data-constant-" + colIndex}
                              onDragStart={(event) => {
                                event.target.style.opacity = "0.4";
                                this.props.onDragStart(
                                  aggregatableDataTypes.has(column.dataType) || column.aggrRule?.toLowerCase().startsWith("count(")
                                    ? "aggr"
                                    : dateDataTypes.has(column.dataType)
                                      ? "date"
                                      : "column"
                                )
                              }
                              }
                              onDragEnd={(event) => {
                                event.target.style.opacity = "1";
                                this.props.onDragEnd()
                              }
                              }
                              type={"column"}
                              data={JSON.stringify({
                                tableAliasName: table.aliasName,
                                dataSourceKey: table.dataSourceKey,
                                tableDisplayName: table.displayName,
                                column: column,
                                uniqeColumnId: uuid()
                              })}
                            >
                              <DataColumn
                                column={column}
                                columnIndex={index + "-" + colIndex}
                                filter={this.props.setDefaultFilter(
                                  column,
                                  table.aliasName,
                                  table.displayName
                                )}
                                conditionalFormats={this.props.conditionalFormats}
                                remove={false}
                              />
                            </Draggable>
                          ))}
                      </AccordionContent>
                    ))
                    : null}
                </Accordion>
              </Col></> : null}
          <Col span={isRoleIsWrite ? 12 : 24}>
            <div>
              <Text
                style={{
                  display: "block",
                  width: "100%",
                  textAlign: "center",
                  color: "#225f8f"
                }}
              >
                {i18n.t("ColumnMapping")}
              </Text>
              {
                this.props.sortable !== false ? (
                  <Accordion
                    accordionStyle={{
                      marginBottom: "10px"
                    }}
                  >
                    <AccordionContent
                      pluginId={this.props.pluginId}
                      arrowClass="fas fa-sort-numeric-down"
                      titleClass={"dataAccordionHeader"}
                      title={i18n.t("ClickToOrder")}
                      eventKey={"1"}
                      headerStyle={{
                        background: "#fff",
                        borderBottom: "1px solid #dadada"
                      }}
                      collapseStyle={{ background: "#fff" }}
                    >
                      <SortArea
                        updateSortedList={this.props.updateSortedList}
                        sortedColumnList={this.props.sortedColumnList}
                        limit={this.props.limit}
                        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
                      ></SortArea>
                    </AccordionContent>
                  </Accordion>
                ) : null
              }
              {this.props.children}
            </div>
          </Col>
        </Row>

        {this.state.sortedTables && this.state.sortedTables.length > 0 && isRoleIsWrite ? (
          <Pagination
            className="data-constant-tables-pagination"
            size={"small"}
            onChange={(page) => this.onPageChange(page)}
            defaultCurrent={this.state.page}
            current={this.state.page}
            total={this.state.sortedTables ? this.state.sortedTables.length : 0}
            pageSize={tableCountInPage}
          />
        ) : null}
      </div>
    );
  }
}
