export const tr = {
  translation: {
    Language: "TR",
    FilterOperators: {
      "=": "Eşittir",
      "!=": "Eşit Değil",
      "CONTAINS": "İçeren",
      "ENDS_WITH": "Biten",
      "STARTS_WITH": "Başlayan"
    },
    DashboardSlider: {
      Sliders: "Kumanda Tablosu Gösterileri",
      EmptySearchResult: "Gösteri bulunamadı.",
      NoDataDescription: "Hiç gösteri yok."
    },
    NewColumn: {
      NewColumn: "Hesaplanmış Kolon",
      NewColumnCantBeEmpty: "Hesaplanmış Kolon ismi boş olamaz!",
      ColumnNamedBefore: "{{column}} adında mevcut bir kolon var. Lütfen ismi değiştirin!",
      CantUseDot: "Kolon isminde nokta (.) olamaz!",
      NewColumnName: "Hesaplanmış Kolon İsmi",
      ColumnDataType: "Kolon Data Tipi",
      InfiniteRecursiveError: "Hesaplanmış Kolon formülleri birbirlerine sınırsız kez bağlı. Lütfen kolonları kontrol edin.",
      NewColumnsCantDuplicate: "Hesaplanmış Kolonlar, klonladığınız Tablo'ya aktarılmayacaktır.",
      AddCalculatedColumn: "Hesaplanmış Kolon Ekle",
      DataTypeOnChange: "Veri tipi değiştirildiğinde plugine kolonu tekrar atmalısınız",
      NewColumnCantBeEmpty: "Hesaplanmış Kolon özelliğini boş formül ile kullanamazsınız"
    },
    DashboardMail: {
      DashboardMail: "E-posta Gönder",
      SendMail: "E-posta Gönder",
      MailSubject: "Konu",
      MailBody: "İçerik",
      MailTo: "Gönderilen",
      MailFrom: "Gönderen",
      MailSendSuccess: "E-posta başarıyla gönderildi",
      MailSendError: "E-posta gönderilemedi",
      MailSending: "E-posta gönderiliyor...",
      Add: "Ekle",
      ExampleVispeahen: "Vispeahen",
      Receipents: "Alıcılar",
      DeleteAll: "Tümünü Sil",
      YouCanSeeYourMailListHere: "İçeriği yollayacağınız e-posta adreslerini burada görebilirsiniz",
      SendDashboardAs: "Kumanda Tablosu",
      Send: "Gönder",
      DeleteDraft: "Taslağı Sil",
      MailFromInfo: "Opsiyonel Seçenektir. Yollayacağınız e-postada isim olarak gözükecektir.",
      MailEnterInfo: "E-posta adreslerini ENTER tuşu ile ekleyebilirsiniz.",
      DeleteDraftConfirm: "Taslağı silmekten emin misiniz?",
      DeleteDraftDesc: "Taslağın tüm içeriği yok olacak.",
      NoReceipentError: "En az bir alıcı gerekli.",
    },
    CustomDashboard: {
      MainDashboard: "Ana Kumanda Tablosu",
      CustomDashboard: "Özel Kumanda Tablosu",
      CustomDashboards: "Özel Kumanda Tabloları",
      Save: "Özel Kumanda Tablosu'nu Kaydet",
      SaveAs: "Özel Kumanda Tablosu olarak kaydet",
      SetAsMainDashboard: "Ana Dashboard Olarak Ata",
      CustomDashboardDeleteSucces: "Özel Kumanda Tablosu Başarılı Biçimde Silindi",
      CustomDashboardDeleteError: "Özel Kumanda Tablosu Silinemedi",
      AreYouSureDeleteError: "Özel Kumanda Tablosu'nu silmek istediğinize emin misiniz?",
      CustomDashboardIsNull: "Henüz bir özel kumanda tablosu eklenmedi."
    },
    DashboardCommentsErrors: {
      DCE00001: "Dashboard ID boş olamaz",
      DCE00002: "Kullanıcı bulunamadı, lütfen tekrar giriş yapınız",
      DCE00003: "Dashboard bulunamadı",
      DCE00004: "Dashboard yorumu boş olamaz",
      DCE00005: "Kullanıcı bulunamadı, lütfen tekrar giriş yapınız",
      DCE00006: "Dashboard yorumu, ana yorumun ID'si boş olamaz",
      DCE00007: "Dashboard bulunamadı", 
      DCE00008: "Ana yorum ID'si boş olamaz",
      DCE00009: "Güncellenen yorum boş olamaz",
      DCE00010: "Güncellenen yorum ID'si boş olamaz",
      DCE00011: "Dashboard ID'si boş olamaz",
      DCE00012: "Dashboard ID'si boş olamaz",
      DCE00013: "Dashboard ID'si boş olamaz",
      DCE00014: "Yorum ID'si boş olamaz",
      DCE00015: "Dashboard yorum cevabı boş olamaz",
      DCE00016: "Güncellenen yanıtın, ana ID'si boş olamaz",
      DCE00017: "Dashboard bulunamadı",
      DCE00018: "Güncellenen yanıt boş olamaz",
      DCE00019: "Yanıt boş olamaz",
      DCE00020: "Güncellenen yorum boş olamaz",
      "Request failed with status code 401": "Lütfen yeniden giriş yapınız."
    },
    DashboardComments: {
      Comments: "Yorumlar",
      ASC: "En Eski Oluşturulan",
      DESC: "En Yeni Oluşturulan",
      LUASC: "En Eski Güncellenen",
      LUDESC: "En Yeni Güncellenen",
      EmptyDashboardAddText: "Buradan Dashboardunuza bir yorum ekleyebilirsiniz",
      EmptyDashboardWhoCanSeeYourComments: "Yorumları kimler görür?",
      EmptyDashboardWCSYCText: "Yorumlarınız bu dokümanda yorum yapabilen veya bu dokümanı düzenleyebilen herkes tarafından görülür.",
      Edited: "düzenlendi",
      Reply: "Yanıtla",
      Edit: "Düzenle",
      Remove: "Sil",
      RepliedTo: "{{username}} adlı kullanıcıya yanıt veriyorsunuz",
      DashboardAdd: "Buradan dashboard yorumunuzu ekleyebilirsiniz",
      DashboardComments: "Rapor Yorumları",
      Refresh: "Yenile",
      Close: "Kapat",
      Users: "Kullanıcı adı",
      Context: "İçerik"
    },
    UserEntrance: {
      Username: "Kullanıcı Adı",
      Password: "Şifre",
      Fullname: "Ad Soyad",
      Email: "E Posta",
      RePassword: "Şifre Tekrar",
      PasswordMatch: "Şifreniz eşleşiyor !",
      PasswordDontMatch: "Şifreleriniz eşleşmiyor !",
      GoBackToLogin: "< Giriş Ekranına Dön",
      Information: "Bilgi",
      Login: {
        WrongUsernameOrPassword: "Hatalı kullanıcı adı veya şifre",
        Username: "Kullanıcı adı",
        Password: "Şifre",
        Button: "Giriş",
        Unauthorized: "Yetki yok",
        RememberMe: "Beni Hatırla",
        ForgotPassword: "Şifremi Unuttum",
        DontYouHaveAnAccountYet: "Hala bir hesabın yok mu?",
        FreeRegister: "Ücretsiz kayıt ol",
        Information: "Bilgi",
        UserDisabled: "Kullanıcınız henüz aktive edilmemiş.! Kullanıcınızı aktive etmek için Lütfen mail adresinizi kontrol ediniz.",
        UserNameEmpty: "Kullanıcı Adı boş olamaz.",
        PasswordEmpty: "Şifre boş olamaz."
      },
      Register: {
        Button: "Kayıt Ol",
        Registered: "Vispeahen'a hoşgeldin, sana bir aktivasyon e postası ilettik.",
        Unauthorized: "Bu işlem için gerekli yetkiye sahip değilsin !",
        InvalidEmail: "Lütfen geçerli bir e posta adresi giriniz !",
        Activated: "E-Posta Doğrulandı",
        NotActivated: "E-Posta Doğrulanmadı",
        ActivatedDescription: "E-Posta adresini doğruladığın teşekkürler, Vispeahen'ı kullanmaya başlayabilirsin.",
        NotActivatedDescription: "E-Posta adresini doğrulayamadık, bir sorun olduğunu düşünyorsan lütfen bizimle iletişime geç.",
        AlreadyActivated: "E-Posta Adresiniz Doğrulanmış",
        AlreadyActivatedDescription: "E-Posta adresiniz daha önce doğrulanmış.",
        PasswordStrength: "Şifreniz, A-Z en az bir büyük, bir küçük harf, 0-9 en az bir rakam, en az bir özel karakter ve toplamda minimum 8 karakterden oluşmalıdır.",
        PasswordDoesNotMatch: "Şifre Tekrar, Şifre ile eşleşmiyor.",
        UserNameEmpty: "Kullanıcı adı boş olamaz.",
        UserNameLength: "Kullanıcı adı en az 4 karakter olmalı.",
        PasswordEmpty: "Şifre boş olamaz.",
        RepasswordEmpty: "Şifre Tekrar boş olamaz.",
        FullNameRules: "Ad Soyad sadece karakter içerebilir.",
        UserNameRules: "Kullanıcı Adı sadece karakter, sayı ve alt çizgi (_) içerebilir."
      },
      Activation: {
        UserActivated: "Hesabınız aktif hale getirildi. Sizi kullanıcı girişi sayfasına yönlendiriyoruz.",
        Loading: "Hesabınız aktive ediliyor. Lütfen bekleyin...",
        AlreadyActivated: "Kullanıcınız zaten akftif durumda. Sizi kullanıcı girişi sayfasına yönlendiriyoruz."
      },
      ForgotPassword: {
        MailSend: "Şİfrenizi değiştirebilmeniz için size bir e-posta gönderdik. Eğer bu e-posta sistemimizde kayıtlı ise.",
        Button: "Şifre e-Postasını Gönder",
        Description: "Sisteme kayıt olurken kullandığınız e-posta adresini giriniz. E-posta şifrenizi değiştirmek için sizi yönlendirecktir."
      },
      ChangePassword: {
        Button: "Şifremi değiştir",
        Description: "Yeni şifrenizi giriniz.",
        Success: "Şifreniz başaryıla değiştirildi.",
        InvalidActivationCode: "Aktivasyon kodu geçerli değil",
        InvalidLdap: "Sistem de LDAP kullanıcısı olarak tanımlısınız. Şifre değiştirmek için sistem yöneticiniz ile görüşünüz"
      },
      SessionExpired: {
        WantToContinue: "Devam mı edeceksin?",
        SessionExpired: "Oturum Süresi Doldu!",
        SessionExpiredMessage: "Endişelenme İşlemlerine devam etmek için tekrar giriş yapmalısın. Eğer iptal edersen, giriş sayfasına yönlendirileceksin.",
        SessionDifferentUser: "Başka bir kullanıcıyla devam etmek istiyorsan, vazgeç tuşuna basabilirsin.",
      },
    },
    Splash: {
      Loading: "Yükleniyor"
    },
    WellDefined: {
      "1111-1212-2212-2212": "Herkese açık",
      "0000-1212-2212-2212": "Özel",
      "2222-1212-2212-2212": "Popüler Kumanda Tabloları",
      "3333-1212-2212-2212": "Sık Ziyaret Ettiklerim",
      "4444-1212-2212-2212": "Favori",
      "5555-1212-2212-2212": "Silinmiş",
      "7777-1212-2212-2212": "Son Zamanlarda Popüler"
    },
    Dashboard: {
      Label: {
        Title: "Etiketler",
        Create: {
          Title: "Etiket Ekle",
          SelectedTitle: "etiket başlık",
          Description: "Yeni etiketiniz için başlık ve renk belirleyin.",
          CreateButton: "Oluştur",
          CancelButton: "Vazgeç",
          LabelCantBeEmpty: "Etiket boş olamaz!",
          LabelSuccesfullyAdded: "Etiket başarıyla oluşturuldu.",
          LabelDisplayNameError: "Mevcutta {{labelDisplayName}} etiketi var. Lütfen farklı bir isim seçin."
        },
        Selector: {
          Title: "Etiket Atama",
          Description: "Listeden etiketleri seçerek ön panelinize atama yapın. ",
          Search: "Ara",
          CreateLink: "Etiket Oluştur",
          ManageLink: "Etiketleri Yönet"
        },
        SelectLabel: "Bir etiket seçin",
      },
      GridSystem: {
        LastRow: "Son Satırı Sil"
      },
      EmptyDashboard: {
        EmptyDesc0: "Kumanda Tablosu oluşturmak için buradasınız.",
        EmptyDesc1: "Plugin eklemek için sol üstteki",
        EmptyDesc2: "butonuna basın.",
        UploadYourFiles: "Verilerinizi yükleyin",
        ConnectYourDataSources: "Veri kaynağınızı bağlayın."
      },
      ExcelExport: {
        ExportPluginCouldNotFound: "Çıktı alınacak geçerli plugin bulunamadı!",
        ExcelExporting: "Excel çıktısı alınıyor...",
        ExcelExportingSuccess: "İndirme işlemi tamamlandı."
      },
      NewReportDashboardShortCut:{
        DashboardReport: "Yeni", 
        NavigateingToDashboard: "Yeni Kumanda Tablosu",
        NavigateingToReport: "Yeni Rapor",
        WarningTextToDashboard: "Yeni bir Kumanda Tablosuna yönlendirileceksiniz. Eğer yapılan değişikliler kaydedilmediyse, yapılan tüm değişiklikler kaybolacaktır.",
        WarningTextToReport: "Yeni bir Rapor Sayfasına yönlendirileceksiniz. Eğer yapılan değişikliler kaydedilmediyse, yapılan tüm değişiklikler kaybolacaktır.",
        Continue: "Devam etmek istiyor musunuz ?",
        OK: "Evet",
        Cancel: "Hayır"
      },
      CustomThumbnail: {
        ImageFileOverTheLimitError: "Yüklediğiniz görsel 10MB'tan küçük olmalıdır.",
        ImageFileTypeError: "Geçersiz veya desteklenmeyen bir dosya türü. Lütfen JPG, JPEG, PNG veya WEBP türünde bir dosya yükleyin.",
        ImageUploadError: "Görsel yüklenemedi."
      },
      Settings: {
        TopoJSON: {
          DeleteMap: "Haritayı Sil", 
          TopojsonNotFound: "Topojson bulunamadı.",
          MapDeleteWarning: "Haritayı silmek istediğine emin misin?",
          MapDeleteWarningWithDashboard: "Aşağıdaki raporlarda silmek istediğin harita kullanılıyor. İstersen rapor adına tıklayarak rapora gidip bakabilirsin. Haritayı sildiğinde bu raporlarda güncelleme yapman gerekecek.",
          MapSuccessfullyAdded: "Harita başarıyla eklendi",
          MapSuccessfullyUpdated: "Harita başarıyla güncellendi",
          NoFileSelected: "Seçili dosya yok",
          SelectMap: "Harita Seç",
          SelectMapForUpdate: "Değiştirilecek Harita",
          UploadedTopoJsons: "Yüklenen Haritalar",
          DefaultTopoJsons: "Varsayılan Haritalar",
          MapUsedDashboards: "Haritanın Kullanıldığı Raporlar",
          UploadEdit: "Haritaları Düzenle",
          MapTopoJsonOperations: "Harita/TopoJson İşlemleri",
          MapSuccessfullyDeleted: "Harita başarıyla silindi.",
          UploadMap: "Harita Yükle",
          EditMap: "Harita Düzenle",
          NameOfMap: "Harita Adı",
          MapWithTopoJsonDesc: "Harita (TopoJson)",
          UsStates: "ABD Eyaletleri",
          UsStatesMainland: "ABD Anakara Eyaletleri",
          UsCountries: "ABD Ülkeleri",
          WorldCountries: "Dünya Ülkeleri",
          TurkeyProvinces: "Türkiye İller",
          TurkeyDistricts: "Türkiye İlçeler",
          AnkaraDistricts: "Ankara İlçeler",
          AnkaraNeighborhood: "Ankara Mahalle",
          US10M: "ABD Bölgeler(Vegalite)",
          World10M: "Dünya Ülkeler(Vegalite)",
          UKNUTS1: "UK - NUTS 1"
        },
        Title: "Rapor başlığını aşağıdan düzenleyebilirsin.",
        TitleSettings: "Başlık Ayarları",
        Description: "Rapor açıklamasını aşağıdan düzenleyebilirsin.",
        SuccessfullyUpdate: "Ayarlar başarıyla güncellendi.",
        DashboardSettings: "Rapor Ayarları",
        DashboardSettingsDescription: "Raporun tamamını etkileyecek genel ayarları buradan yapabilirsin.",
        BackgroundSettings: "Arkaplan Ayarları",
        BackgroundColor: "Arka plan rengi",
        BackgroundImage: "Arka plan resmi",
        BackgroundColorDescription: "Renk seçiciyi kullanarak arkaplan rengini ayarlayabilirsin.",
        BackgroundImageDescription: "Dosya yükleme alanını kullanarak arkaplan resmini ayarlayabilirsin.",
        GridSettings: "Grid Ayarları",
        PreventCollision: "Plugin Çarpışmalarını Engelle",
        StackDesign: "Toplu Tasarım Modu",
        FreeMovement: "Serbest Hareket Modu",
        StackDesignDesc: "Toplu tasarım modunda dikey hatta pluginler arasında boşluk bırakamazsın. Fakat bir pluginin yerini değiştirirken daha rahat kullanım sağlar.",
        FreeMovementDesc: "Serbest hareket modunda pluginleri istediğin yere yerleştirebilirsin. Fakat bir pluginin yerini değiştirirken diğer pluginlerin olduğu yere bırakamazsın.",
        GridPadding: "Pluginler Arası Boşluk",
        Shadow: "Gölge Ayarları",
        ShadowUpDown: "Yukarı/Aşağı",
        ShadowRightLeft: "Sağ/Sol",
        ShadowBlur: "Bulanıklık",
        ShadowSpread: "Yayılma",
        ShadowColor: "Renk",
        DashboardDetails: "Rapor Detayları",
        RerenderAllPlugins: "Tüm pluginleri yeniden yükle",
        CustomExcel: "Excel Seçenekleri",
        StyledExcel: "Stillenmiş Excel",
        BigSizedExcel: "Büyük Veri Excel",
        StandartExcel: "Standart Excel",
        StyledExcelDesc: "Stil verilmiş Excel çıktısı almak için kullanılır. Daha küçük verilerde önerilir.",
        StandartExcelDesc: "Stil verilmemiş Excel çıktısı almak için kullanılır. Daha büyük verilerde daha hızlı çıktı almak için önerilir.",
        BigSizeExcelDesc: "Stil verilmemiş Excel çıktısı almak için çok büyük verilerde daha hızlı çıktı almak için önerilir. Bu seçenek sadece standart excel için mevcuttur.",
        ExcelList: "Excel Listesi",
        UploadNewExcel: "Yeni Excel Yükle",
        UploadNewCustomExcelTemplate: "Yeni Özel Excel Taslağı Yükle",
        CustomExcelDisplayName: "Özel Excel Görünecek İsim",
        ExcelSuccessfullyUploaded: "Excel başarıyla yüklendi",
        NoSelectedFile: "Seçili dosya yok.",
        DashboardMustBeSaved: "Rapor kayıtlı olmalı.",
        StandardExcel: "Standart Excel",
        UnsupportedCustomExcelExtension: "Desteklenmeyen özel excel uzantısı!",
        SupportedFileExtensions: "Desteklenen Dosya Uzantıları",
        NoDataConfiguration: "Veri Yok Açıklaması",
        NoDataConfigurationInfo: {
          Info: "Grafik konfigürasyonunda ve ayarlarda bulunan veri yok açıklaması görünüm sırası:",
          GraphicConfiguration: "1. Grafik Konfigürasyonu",
          Settings: "2. Ayarlar",
          DefaultText: "3. Varsayılan Metin (Seçilen kriterlerde veri bulunamadı. Plugin görselleştirilemedi.)"
        },
        ImageFileOverTheLimitError: "Resim 1 MB'tan küçük olmalıdır!",
        ImageFileTypeError: "Sadece JPG/PNG dosyası yükleyebilirsiniz!!",
        Theme: "Tema",
        SelectedTheme: "Aktif Tema",
        NoTheme: "Tema Yok",
        DefaultTheme: "Varsayılan Tema",
        StickToolsToTop: "Rapor Araçlarını En Üste Tuttur"
      },
      Configuration: {
        Configuration: "Ayarlar",
        ExcelExport: "Excel Çıktısı",
        PDFExport: "PDF Çıktısı",
        ExcelExportDisable: "Veri bulunmadığı için pdf çıktı alınamıyor. Veri ekledikten sonra çıktı alabilirsiniz.",
        PDFExportDisable: "Veri bulunmadığı için excel çıktı alınamıyor. Veri ekledikten sonra çıktı alabilirsiniz.",
        ConfigurationDescription: "Bu ekranda, {{pluginKey}} plugininizi detaylıca kişiselleştirebilirsiniz.",
        Fields: {
          RotateLabelsIfTheyDontFitDesc: "Bu özellik seçiliyken sığdıkları durumda etiketler döndürülmezler.",
          RotateLabelsIfTheyDontFit: "Yalnızca Etiketler Sığmadığında Döndür",
          TruncateLabels: "Etiketleri Kırp",
          HideOverlappingLabels: "Çakışan Etiketleri Gizle",
          AnimationTextColor: "Animasyon Yazı Rengi",
          AnimationFontSize: "Animasyon Yazı Boyutu",
          ShowGrids: "Izgaraları Göster",
          Bounce: "Sıçrama",
          Circle: "Dairesel",
          Cubic: "Kübik",
          Elastic: "Elastik",
          Exp: "Üstel",
          Sine: "Sinüs",
          Measures: "Ölçüm Değerleri",
          Auto: "Otomatik",
          Manual: "Manuel",
          ChartType: "Grafik Tipi",
          Radial: "Dairesel",
          Labels: "Etiketler",
          LabelPosition: "Etiket Pozisyonu",
          LabelFontSize: "Etiket Yazı Boyutu",
          LegendFontSize: "Gösterge Yazı Boyutu",
          ShowLegends: "Göstergeleri Göster",
          MeasuresOnAxis: "Değerleri Eksen Üzerinde Göster",
          Target: "Hedef",
          ShowTarget: "Hedefi Göster",
          ShowMeasureName: "Ölçüm İsimlerini Göster",
          ShowMeasureValue: "Ölçüm Değerlerini Göster",
          ValueType: "Değer türü",
          Triangle: "Üçgen",
          Classic: "Klasik",
          MeasureHandType: "Değer Kolu",
          StaticTarget: "Statik Hedef",
          MinMax: "Min/Maks",
          ShowLabels: "Etiketleri Göster",
          DistanceWithGrid: "Gridler Arası Mesafe",
          CategoryFontSize: "Kategori Font Boyutu",
          ShowValueOnRadar: "Radar'da Değerleri Göster",
          RadarValueFontSize: "Radar Değerleri Font Boyutu",
          Ranges: "Aralıklar",
          ShowRanges: "Aralıkları Göster",
          RangeDescription: "Aralıkları buradan belirleyebilir, istediğin kategoriler arasını özelleştirebilirsin.",
          CategoriesNotFound: "Başlangıç ya da Bitiş değeri mevcut veride yok",
          ImageAndIconsCantSeemSameTime: "Resim ve İkon aynı anda görüntülenmeyecektir",
          IncreaseDecreaseNotAvailable: "Artış/Azalış yok",
          PaginationBottomButtons: "Sayfalama Alt Butonları",
          Compare: "Karşılaştırma",
          CompareAlign: "Karşılaştırma Pozisyonu",
          CompareDescription: "Karşılaştırma Açıklaması",
          PaginationBottomButtonsInfo: "Alt butonları aktif ederseniz, karşılaştırılma kaldırılacaktır.",
          MeasureCompareStyling: "Karşılaştırma Stili",
          MeasureComparePosition: "Karşılaştırma Pozisyonu",
          MeasureCompareFontStyle: "Karşılaştırma Yazı Stili",
          ShowMeasureFrame: "İkon Çerçevesini Göster",
          MeasureCompareFont: "Karşılaştırma Yazı Stili",
          IsMultiple: "Çoklu",
          SetCompareArea: "Karşılaştırma Alanına Uygula",
          SetToMeasure: "Değer Alanına Uygula",
          SetAllMeasureArea: "Tüm Değer Alanlarına Uygula",
          SetHeaderArea: "Başlık Alanına Uygula",
          SetAllCompareArea: "Tüm Karşılaştırma Alanlarına Uygula",
          SetToHeader: "Başlık Alanına Uygula",
          SetAllHeaderArea: "Tüm Başlık Alanlarına Uygula",
          SetAllHeaderDescriptionArea: "Tüm Başlık Açıklaması Alanlarına Uygula",
          SetAllIconsToOther: "İkonları Tümüne Uygula",
          SetAllIconSizeToOther: "Boyutu Tümüne Uygula",
          MeasureStyling: "Değer Stili",
          MeasurePosition: "Değer Pozisyonu",
          MeasureFont: "Değer Yazı Stili",
          MeasureHeader: "Performans Döşeme Başlığı",
          MeasureHeaderDescription: "Spesifik bir başlık girin",
          HeaderDescriptionStyling: "Başlık Açıklaması Stili",
          HeaderFont: "Başlık Yazı Stili",
          HeaderFontDescription: "Başlık Açıklaması",
          HeaderFontDescriptionPosition: "Başlık Açıklaması Başlık Hizası",
          HeaderDescriptionPosition: "Performans Döşeme Başlık Hizası",
          HeaderTitleFontStyle: "Başlık Yazı Stili",
          MeasureFontStyle: "Değer Yazı Stili",
          MeasureFontType: "Değer Yazı Stili",
          HeaderDescriptionFontStyle: "Başlık Açıklaması Yazı Stili",
          HeaderDescriptionFont: "Başlık Açıklaması Yazı Stili",
          HeaderColor: "Başlık Rengi",
          IconSize: "İkon Boyutu",
          Both: "Her ikiside",
          Label: "Etiket",
          StartLimit: "Başlangıç",
          EndLimit: "Bitiş",
          FillArea:  "Alan Dolgusu",
          FillBubbles: "Baloncuk Dolgusu",
          NoFill: "Dolgu Yok",
          ColorFill: "Renk Dolgusu",
          XAxis: "X Ekseni",
          YAxis: "Y Ekseni",
          Scrollbars: "Kaydırma Çubukları",
          ScrollbarX: "Kaydırma Çubuğu X",
          ScrollbarY: "Kaydırma Çubuğu Y",
          LabelFontStyle: "Etiket Yazı Stili",
          AnimationDuration: "Süre (Saniye)",
          AnimationLabel: "Etiketi Göster",
          AnimationOverSizedBehaviour: "Etiket Sığdırma Davranışı",
          OverSizedFit: "Yazı boyutunu ölçekle",
          OverSizedTruncate: "Metni kırp",
          OverSizedWrap: "Metni kaydır",
          OverSizedWrapNoBreak: "Sözcükleri bölmeden metni kaydır",
          OverSizedHide: "Etiketi gizle",
          OverSizedNone: "Hiçbir şey",
          ActionsWarningRoot: "En üst mertebedeki düğümleri bir ebeveyn içerisinde toplamaya yarayan kök, ağaç grafik için zorunludur. Sanal bir düğüm olduğundan ve gerçek veri içermediğinden, kök herhangi bir etkileşimi veya gezinmeyi içermez.",
          Root: "Kök",
          ShowRoot: "Kökü Göster",
          RootName: "Kök İsmi",
          LinkHightlight: "Bağlantıları Vurgula",
          Draggability: "Sürüklenebilirlik",
          Collapsibility: "Katlanabilirlik",
          NodePadding: "Düğümler Arası Boşluk",
          Radius: "Yarıçap",
          MaximumRadius: "Maksimum Yarıçap",
          MinimumRadius: "Minimum Yapıçap",
          NetworkGraph: "Ağ Grafik",
          TreeChart: "Ağaç Grafik",
          GeneralSettings: "Genel Ayarlar",
          StrictMinMax: "Sabit Min/Maks",
          InvalidAxisMin: "Minimum değer, maksimum değerden büyük olamaz.",
          InvalidAxisMax: "Maksimum değer, minimum değerden küçük olamaz.",
          InvalidAxisMinMax: "Maksimum ve minimum değerler birbirine eşit olamaz.",
          AxisTargetExplanation: "Bazı hedeflerin eksenin değer aralığını aşması durumunda, hedeflerin görselleştirilmesini sağlamak amacıyla eksenin minimum veya maksimum değerleri yerine bu hedeflerin değerleri kullanılabilir.",
          MinMaxValues: "Min/Maks Değerleri",
          SelectedAxis: "Seçili Eksen",
          SelectAxis: "Eksen Seçiniz",
          Axes: "Eksenler",
          Axis: "Eksen",
          SingleAxis: "Tekli Eksen",
          ValueSettings: "Değer Ayarları",
          SmoothedLinesWarning: "Sürekli grafik özelliği, verileri olmayan alanları tahmin ederek tamamlar. Bu özellik kullanılırken çizgiler yumuşatılamaz çünkü bu, görsel bozulmalara neden olabilir.",
          AggregationRule: "Toplam Kuralı",
          Sum: "Toplam",
          Average: "Ortalama",
          Maximum: "Maksimum",
          Minimum: "Minimum",
          Count: "Count",
          First: "İlk",
          Last: "Son",
          None: "Yok",
          TargetsMultiColumnDescription: "Birden fazla eksen için hedef belirleyemezsiniz.",
          GroupCount: "Grup Sayısı",
          DataGrouping: "Veri Gruplama",
          GroupDataFields: "Veri Alanlarını Grupla",
          FillTargetArea: "Hedef Alanını Boya",
          Color: "Renk",
          Targets: "Hedefler",
          ShowTargets: "Hedefleri Göster",
          SmoothedLines: "Yumuşatılmış Çizgiler",
          ShowBullets: "Madde İşaretlerini Göster",
          Chart: "Grafik",
          ContinuousChart: "Sürekli Grafik",
          Navigator: "Navigatör",
          AreaChart: "Alan Grafiği",
          ZoomX: "X Yakınlaştırma",
          ZoomY: "Y Yakınlaştırma",
          ShowZoomX: "X Yakınlaştırıcıyı göster",
          ShowZoomY: "Y Yakınlaştırıcıyı göster",
          Alignment: "Hizalama",
          BarValueAutomatic: "Otomatik Hesaplama",
          ValueAngle: "Değer Açısı",
          LabelAngle: "Etiket Açısı",
          LegendConfiguration: "Gelişmiş Legend Konfigürasyonu",
          EnhancedMeasureConfiguration: "Gelişmiş Ölçüm Konfigürasyonu",
          ThreeDVisulisation: "Üç Boyutlu Görünüm",
          VisuliateByRadius: "Ölçüm değerlerine göre Pie'ı boyutlandır",
          SelectedValues: "Seçili Veriler",
          UserTextBox: "El İle Giriş",
          ApplyButtonText: "Uygula Buton Yazısı",
          ApplyButtonBackgroundColor: "Uygula Butonu Arkaplan Rengi",
          ApplyButtonTextColor: "Uygula Butonu Yazı Rengi",
          EnhanceMode: "Gelişmiş Mod",
          Browse: "Gözat",
          NoFileSelected: "Dosya seçilmedi",
          ImageUpload: "Resim yükle",
          CantChangeMeasureSize: "{{size}}px'den büyük değer giremezsin.",
          CantReduceMeasureSize: "{{size}}px'den küçük değer giremezsin.",
          PieChartDrilldownWarning: "Pie-Chart Enhanced Özel Drilldownlar tek kolonda çalışmaktadır!",
          PieChartInsideDrilldown: "Parçalı Drilldown",
          PieChartNormalDrilldown: "Standart Drilldown",
          DoublePieDrilldown: "Çift Grafikli Drilldown",
          DrilldownType: "Drilldown Tipi",
          SumOperations: "Toplam İşlemleri",
          Attribute: "Özellik",
          RowTotal: "Satır Toplamları",
          After: "Kolonların Sonrasında",
          Before: "Kolonların Öncesinde",
          GroupName: "Grup Adı",
          GroupDefaultExpanded: "Gruplar Varsayılan Olarak Açık",
          RowGroupOperations: "Satır Grup İşlemleri",
          PivotTheme: "Pivot Teması",
          ChangePluginType: "Vega Lite Plugin Özelleştirme",
          SelectIcon: "İkon",
          SearchIcon: "İkon ara",
          Size: "Boyut",
          BackgroundColor: "Arkaplan Rengi",
          LinkColor: "Link Rengi",
          Colours: "Tema",
          Palette: "Palet",
          Title: "Başlık",
          TitleInPlugin: "Date Başlık",
          TitleAlign: "Başlık hizası",
          YAxisAlign: "Y Ekseni Hizası",
          ApplyTitleFeaturesToOthers: "Başlık özelliklerini rapordaki diğer pluginlere uygula",
          ApplyFeatures: "Özellikleri uygula",
          RemoveFeatures: "Özellikleri kaldır",
          RemoveFeaturesWarning: "Özellikler rapor kaydedildikten sonra kaldırılamaz",
          FilterDefaultOperator: "Filtre Operatörü",
          ShowOperatorOptions: "Operatör Opsiyonlarını Göster",
          ShowDefaultFilterIcon: "Varsayılan Filtre İkonunu Göster",
          CloseColorPicker: "Renk seçiciyi kapat",
          CurrentPluginIsAMap: "Mevcut plugin bir haritadır",
          OpenColorPicker: "Renk seçiciyi aç",
          OpenIconSelector: "İkon seçiciyi aç",
          CloseIconSelector: "İkon seçiciyi kapat",
          OpenTooltip: "Araçları aç",
          CloseTooltip: "Araçları kapat",
          CloseUploadArea: "Yükleme alanını kapat",
          OpenUploadArea: "Yükleme alanını aç",
          Refresh: "Yenile",
          Delete: "Kaldır",
          ShowHideButton: "Gizleme Butonunu Göster",
          ShowConditionalFormatting: "Koşulsal Formatlamayı Göster",
          HideLabel: "Etiketleri Gizle",
          Width: "Genişlik",
          LinkField: "Link Alanı",
          FontStyle: "Yazı Stili",
          TotalFontFamily: "Toplam Yazı Stili",
          TotalStyling: "Toplam Biçimlendirme",
          Height: "Yükseklik",
          Others: "Diğer",
          GeneralAppearance: "Genel Görünüm",
          EnhancedConfiguration: "Gelişmiş Konfigürasyon",
          LegendPosition: "Gösterge Konumu",
          ValueFontFamily: "Değerin Yazı Stili",
          NullValue: "Null/Empty Varsayılan Değeri",
          RowColumnStyle: "Kolon/Satır Başlık Biçimlendirme",
          //HeaderFont: "Kolon/Satır Başlık Yazı Stili",
          HeaderFontSize: "Kolon/Satır Başlık Yazı Boyutu",
          FitCols: "Kolonları Sığdır",
          RowColor: "Satır Rengi",
          ColColor: "Kolon Rengi",
          TotalColor: "Toplam Rengi",
          FitRows: "Satırları Sığdır",
          FitRowsDanger: "Bu seçeneği işaretlerseniz satırlar, otomatik genişlik ve yüksekliğe sahip olacak",
          FitColsDanger: "Bu seçeneği işaretlerseniz sütunlar, otomatik genişlik ve yüksekliğe sahip olacak",
          DifferentDrillDanger: "Bu seçeneği işaretlerseniz, drilldowna sahip satır ve kolonlar, drilldown rengine göre renklenecek",
          DrilldownColumnHeaderColor: "Drilldown Kolon/Satır Rengi",
          DifferentDrillColours: "Farklı Renkte Drilldown Uygula",
          ValueColor: "Değer Rengi",
          LegendType: "Legend Gösterimi",
          TextStyling: "Yazı Biçimlendirme",
          HeaderTextStyling: "Başlık Yazı Biçimlendirme",
          AddVisual: "Görsel Ekle",
          SizePositioning: "Boyut / Konumlandırma",
          AxisConfiguration: "Eksen Konfigürasyonu",
          Sorting: "Sıralama",
          BarConfiguration: "Bar Konfigürasyonu",
          Values: "Değerler",
          Center: "Orta",
          Left: "Sol",
          Right: "Sağ",
          Bottom: "Alt",
          Top: "Üst",
          DontShowError: "Hata gösterme",
          MaxValue: "Maksimum değer",
          Duration: "Süre",
          LinkURL: "Bağlantı",
          InnerHeight: "İç Yükseklik",
          InnerWidth: "İç Genişlik",
          ArrowColor: "Ok Rengi",
          PlaceHolderColor: "Yer Tutucu Rengi",
          TextBackgroundColor: "Metin Arka Plan Rengi",
          PlaceHolder: "Filtre seçilmeden önce görünecek değer",
          IsMulti: "Çoklu Seçim",
          DropdownAppearance: "Liste Gösterimi",
          WrapText: "Yazıları sar",
          SpaceBetweenButtons: "Butonlar Arası Boşluk",
          TextColor: "Yazı Rengi",
          MinPointSize: "En Küçük Nokta Boyutu",
          MaxPointSize: "En Büyük Nokta Boyutu",
          XTitle: "X Eksen Başlığı",
          YTitle: "Y Eksen Başlığı",
          SecondYTitle: "İkinci Y Ekseni Başlığı",
          SecondAxis: "İkinci Eksen",
          LR: "Doğrusal Regresyon",
          SelectBox: "Sürükleyerek Seçim",
          CircleThickness: "Çember Kalınlığı",
          CircleFillGap: "Çember Doldurma Boşluğu",
          TextVertPosition: "Yazı Pozisyonu (Dikey)",
          MinValue: "Minimum Değer",
          WaveHeight: "Dalga Yüksekliği",
          WaveCount: "Dalga Sayısı",
          WaveRiseTime: "Dalga Yükselme Süresi",
          WaveAnimateTime: "Dalga Animasyon Süresi",
          WaveHeightScaling: "Dalga Yükseklik Boyutlandırması",
          DisplayPercent: "Yüzdeli Göster",
          WaveAnimate: "Çember Doldurma Boşluğu",
          TextSize: "Yazı Boyutu",
          HeaderTextSize: "Başlık Yazı Boyutu",
          NavigationNoDataFound: "Navigasyon İçin Veri Bulunamadı",
          MeasureTileEmptyDataError: "Yeni Measure Tile Plugininde birden fazla veri olduğu için görselleştirilme yapılamamıştır",
          Spiral: "Dizilim Algoritması",
          Scale: "Ölçeklendirme",
          MinSize: "En Küçük Yazı Boyutu",
          MaxSize: "En Büyük Yazı Boyutu",
          Font: "Yazı Stili",
          Orientation: "Yönelim Sayısı",
          FromAngle: "FromAngle",
          ToAngle: "ToAngle",
          Archimedean: "Arşimet'e Ait",
          Rectangular: "Dikdörtgensel",
          Linear: "Doğrusal",
          Log: "Sayının logaritmasını hesaplar.",
          Theme: "Temalar",
          ThemeButton: "Tema",
          defaultEnhancedPalette: "Gri Tema",
          FlatUI: "Tema 1",
          FlatUISoft: "Tema 2",
          CoolScale: "Tema 3",
          WarmScale: "Tema 4",
          Heatmap: "Tema 5",
          SelectedColor: "Seçim Rengi",
          Dark: "Koyu",
          Chalk: "Tebeşir",
          Light: "Açık",
          Patterns: "Desenler",
          Black: "Siyah",
          SelectTitleAlign: "Başlık hizalamasını seçin",
          SelectYAxisAlign: "Y ekseni hizalamasını seçin",
          RemoveColour: "Rengi sil",
          BackgroundImageUrl: "Arkaplan Resmi Url",
          GlassWidth: "Cam Genişliği",
          GlassHeight: "Cam Yüksekliği",
          ShowLastValue: "Son Değeri Göster",
          FontSize: "Yazı Boyutu",
          FontColour: "Yazı Rengi",
          RoundCorners: "Yuvarlatılmış Köşeler",
          ShowValuesOnTree: "Değerleri Göster",
          ShowValuesOnBar: "Değerleri Bar Üzerinde Göster",
          ValueFontSize: "Değer Font Boyutu",
          FilterLimit: "Filtre Limiti",
          BubbleLow: "En Küçük Bubble Yarıçapı",
          BubbleHigh: "En Büyük Bubble Yarıçapı",
          Stroke: "Çizgi Kalınlığı",
          StyleType: "Stil Tipi",
          SeriesPicker: "Dizi Seçici",
          SplitSeries: "Diziyi Ayır",
          SeriesScale: "Seri Boyutlandırma",
          Individual: "Özgün",
          Share: "Ortak",
          MapTileSelection: "Harita Altlığı Seçimi",
          AtlasMapTile: "Atlas Map Tile",
          OpenStreetMapTile: "Open Street Map Tile",
          BasarSoftTile: "Basar Soft Tile",
          Voronoi: "Yakın Noktaları Ayırt Et",
          Legends: "Göstergeler",
          Legend: "Göstergeleri Göster",
          LegendLocation: "Gösterge Konumu",
          Up: "Yukarı",
          Down: "Aşağı",
          Opacity: "Opaklık",
          MaxZoom: "Yoğunluk",
          Colour: "Renk",
          //MapTile: "Harita Kuyrukları",
          InnerRadius: "İç Yarıçap",
          dataType: "Ölçüm Gösterimi",
          Numeric: "Sayısal",
          Percentage: "Yüzdesel",
          NumericPercentage: "Yüzdesel-Sayısal",
          ShowMeasureWithLines: "Ölçümleri Oklar İle Grafiğin Dışında Göster",
          ShowMeasure: "Ölçümleri Göster",
          MeasureType: "Ölçümleri Gösterme Tipi",
          SelectMultiple: "Çoklu Dilim Seçimi",
          ShowMeasureOnPie: "Ölçümleri Pasta Dilimlerinin Üzerinde Göster",
          Levels: "Seviyeler",
          PointSize: "Nokta Boyutu",
          FillOpacity: "Opaklığı Doldur",
          StrokeOpacity: "Dış Çizgi Opaklığı",
          OwnScale: "Birbirinden Ayrı Ölçek",
          Topojson: "Topojson",
          SelectATopojson: "Topojson Seçiniz",
          AddNewTopojson: "Yeni Katman Ekle",
          RemoveLastTopojson: "Son Katmanı Sil",
          FeatureCode: "Feature Code",
          FeatureDesc: "Feature Desc",
          ScaleType: "Renk Ölçek Tipi",
          Quantile: "Yüzdelik",
          Individiual: "Özgün",
          Shared: "Ortak",
          StrokeWidth: "Çizgi Kalınlığı",
          EmptyTile: "Empty Tile",
          SelectLegend: "Gösterge Konumu Seçimi",
          SelectFeatureCode: "Feature Code Seçiniz",
          SelectFeatureDesc: "Feature Desc Seçiniz",
          ColumnWidth: "Kolon Genişliği",
          ColumnHeight: "Kolon Yüksekliği",
          SubTotal: "Ara Toplam (Satır alanı boş olamaz)",
          ShowSumColumns: "Toplam Kolonunu Göster",
          Control: "Kontrole İzin ver",
          WrapHeader: "Başlıkları Sar",
          ValuesAsColumns: "Değerleri Kolon Olarak Seç",
          Renderer: "Görselleştirme Tipi",
          Table: "Tablo",
          TableBarchart: "Tablo Çubuk Grafiği",
          PivotTableDescription: "Pivot Table açıklama",
          AutoSize: "Otomatik Boyutlandır",
          SizeToFit: "Sığdır",
          ExportToPDF: "PDF'e Dönüştür",
          ExportDate: "İndirme Tarihi",
          AllowControls: "Kontrole İzin Ver",
          ShowBoth: "İkisinide Göster",
          JustRow: "Sadece Satır",
          JustColumn: "Sadece Sütun",
          ThemeColour: "Tema Rengi",
          SelectSum: "Toplamları Seç",
          RowHeight: "Satır Yüksekliği",
          RowWidth: "Resim Satır Genişliği",
          LinkDescription: "Link Tasviri",
          ImageField: "Resimli Link Alanı",
          TableDescription: "Table açıklama",
          WrapCells: "Hücreleri Sar",
          EnablePaging: "Sayfalamayı Etkinleştir",
          HideFiltering: "Filtrelemeyi Gizle",
          ShowColumnTotal: "Toplam Göster",
          Pagination: "Sayfalama",
          PageNumber: "Excel Olarak İndirmek İstediğiniz Sayfa Sayısı",
          PaginationPageSize: "Her Sayfa da Gösterilecek Veri Sayısı",
          AxisNumber: "Eksen Sayısı",
          Multi: "Çoklu",
          Single: "Tekli",
          SingleStack: "Tekli Yığın",
          ShowLineValue: "Çizgi Değerini Göster",
          ColoredLineValue: "Çizgi Değerini Renklendir",
          ShowBarValue: "Bar Değerini Göster",
          AxisType: "Eksen Tipi",
          AxisSettings: "Eksen Ayarları",
          YAxisStylingOption : "Y Ekseni Başlığını Göster",
          YAxisFontStyle : "Y Ekseni Yazı Stili",
          XAxisStylingOption : "X Ekseni Başlığını Göster",
          XAxisFontStyle : "X Ekseni Yazı Stili",
          YAxisShowColumnNameAsTitle: "Kolon İsmini Başlık Olarak Göster",
          DisplayName: "İsmi Görüntüle",
          ShowEllipsis: "Uzun Tooltip Yazılarını Kırp",
          ShowEllipsisXAxis: "X Axis Başlığını Kırp",
          Icon: "İkon",
          IsImage: "Resim mi?",
          ImageSource: "Resim Kaynağı",
          Animation: "Animasyon",
          XAxisBarTitleAngle: "X Ekseni Bar Başlık Açısı",
          YAxisBarTitleAngle: "Y Ekseni Bar Başlık Açısı",
          XAxisBarTitleLength: "X Ekseni Bar Başlık Uzunluğu",
          CharacterValue: "Karakter Sayısı",
          Horizontal: "Yatay",
          Vertical: "Dikey",
          ZeroToOnehundredEighty: "0'dan 180'e",
          StackBar: "Yığınla",
          ShowValueOnBar: "Bar'da değeri göster",
          DefaultSortDirection: "Varsayılan Sıralama Yönü",
          VerticalDirection: "Dikey Yönlü",
          HorizontalDirection: "Yatay Yönlü",
          Descending: "Azalan",
          Ascending: "Artan",
          ColumnNumber: "(Kolon Numarası)",
          ShadeArea: "Gölge Alanı",
          MapTile: "Harita Döşemesi",
          ShowSortControl: "Sıralama Kontrolü",
          DefaultSortColumn: "Varsayılan Sıralama Kolonu",
          BrushNavigator: "Mini-Grafik Navigatörü",
          NULLColour: "Boş Veri Rengi",
          MinimumSize200: "Girilebilecek en küçük değer 200",
          DragSelect: "Sürükle Seç",
          LinkFieldInfo:"Çoklu linkleri \" ; \"ile ayırınız",
          LinkDescriptionInfo:"Çoklu linklere ait açıklamaları \" ; \" ile ayırınız",
          ShowMeasureDescriptionInfo: "Değerleri Pie'ın üzerinde ya da çizgiler ile dışarıda gösterir.",
          LegendsMustNotShowed: "Belirli boyut altında görüntüsel bozukluk yaşanacağı için legend otomatik olarak kaldırılır.",
          ShowMeasureMustNotShowed: "Belirli boyut altında görüntüsel bozukluk yaşanacağı için ölçümler otomatik olarak kaldırılır.",
          ColumnHeader: "Kolon Ayarları",
          ColumnHeaderFont: "Başlık Yazı Stili",
          ColumnHeaderFontSize: "Başlık Yazı Boyutu",
          ColumnHeaderFontColor: "Başlık Yazı Rengi",
          ColumnHeaderThemeColour: "Başlık Tema Rengi",
          ColumnHeaderHeight: "Başlık Yüksekliği",
          ShowCornerTotal: "Köşe Toplamı Göster",
          ShowCornerCheckboxInfo: "Sadece İkisinide göster ile kullanılır."
        }
      },
      Data: {
        DataFormatType: {
          DataType: {
            Text: "Metin",
            Number: "Sayı",
            Date: "Tarih",
            WholeNumber: "Tamsayı",
            Decimal: "Ondalık",
            Rounding: "Yuvarlama",
            Time: "Zaman",
            Timestamp: "Timestamp",
            Custom: "Özel",
            FormatWarning: "Veri tipi {{typeOrginal}} iken {{typeNew}} veri tipine çeviriyorsunuz.",
            CustomFormatDropdownError: "Özel format, liste gösterimi olmadan kullanılamaz.",
          },
          DecimalPlaces: "Ondalık Sayısı",
          Use1000Separator: "1000 Ayraç (.) Kullan",
          RoundingNumber: "Yuvarlama Sayısı",
          TimestampTypeError: "Tarih veri tipi timestamp veri tipine çevrilemez.",
          NoTimestamp: "Yok"
        },
        ExpressionDesc: "Yazılan formülün veri kaynağına gitmeden hesaplanmış sonucunun getirilmesidir.",
        QueryDesc: "Yazılan formülün ilgili veri kaynağındaki sonucunun getirilmesidir.",
        ExpressionError: "İşlemler hesaplanamadı veya hesaplanması çok uzun sürdü.",
        ExpressionUsedError: "Sorgu seçeneğinde işlemleri kullanamazsın.",
        Data: "Veri",
        DataDescription: "Pluginlerini görselleştirmek için verilerini buradan seçiyorsun. Tablolarından kolonlarını, kolon eşleştirmeleri bölümüne sürükleyerek işlem yapabilirsin.",
        MultipleColumn: "Birden fazla kolon alabilir.",
        MultiColumn: "Çoklu Kolon",
        Locale: "Bölge",
        Format: "Format",
        Name: "Ad",
        ValueAlign: "Değer Hizası",
        AffixAlign: "Eklenti Hizası",
        PreserveDataAs: "Parametre Olarak Sakla",
        PreserveDataAsDesc: "Bu kolonun verisini ifade parametrelerinde kullanmak için saklar. Varsayılan filtre üzerinden karşılaştırma kullanılan pluginlerde kullanılamaz.",
        Query: "Sorgu",
        Expression: "İşlem",
        ExpressionParametersMustBeNumeric: "İşlem parametreleri sayısal olmalıdır!",
        ExpressionCouldNotBeEvaluated: "İşlem hesaplanamadı!",
        CantPerformAnyActionError: "İşlem etkinken, herhangi bir etkileşimde bulunamazsın",
        ExpressionParameterNotFound: "İşlem parametresi bulunamadı",
        ExpressionParameterCompareError: "Karşılaştırma filtresi bulunan pluginde işlem parametresi kullanamazsınız!",
        ExpressionParameters: "İşlem Parametreleri",
        ExpressionCalculating: "İşlem hesaplanıyor...",
        RestrictedCharacters: "' (Kesme işareti), [ (Açık köşeli parantez), ] (Kapalı köşeli parantez), { (Açık süslü parantez) ve } (Kapalı süslü parantez) karakterleri kullanılamaz",
        ColumnProperties: "Kolon Özellikleri",
        ExistingNameError: "Bu isimde bir kolon zaten var.",
        UseConditionalFormatting: "Bu kolon koşulsal formatlama da kullanılmıştır.",
        DeleteConditionalFormatting: "Sildiğiniz takdirde  ilgili koşulsal formatlamada silinecektir.",
        AreYouSure: "Devam etmek istiyor musunuz?",
        Formula: "Formül",
        Value: "Değer",
        FormulaDescription: "Formül Açıklama",
        ValueTypeDescription: "Çoklu seçimleri alt satıra geçerek yapabilirsiniz.",
        ValueDescription: "Değer Açıklama",
        SortYourColumns: "Kolonlarını Sırala",
        SortColumnsDesc: 'Sürükle bırak yaparak kolonlarını sıralayabilirsin.',
        SortColumnsEmptyDesc: "Sıralama yapabilmek için, kolon eşleştirmelerine en az bir kolon sürükle.",
        NumericFilterError: "Sayısal alana sayısal olmayan değer girilemez.",
        UseColumnForTotal: "Kolonu toplama dahil et",
        EnableTotal: "Sütun toplamını gösteri etkinleştirmelisiniz",
        EmptyDataContentText:"b#Kendi verilerinizi/b# bu görselde görüntülemek için sol üstteki #i ikonuna tıklayınız.",
        ApplyAllRows: "Tüm Satırlara Uygula",
        ApplyAllColumns: "Tüm Sütunlara Uygula",
        ApplyAllMeasures: "Tüm Ölçülere Uygula",
        DefaultFilters: {
          Title: "Varsayılan Filtre",
          Desc: "Varsayılan Filtre açıklama alanı",
          EmptyDesc: "Hiç bir filtre bulunamadı"
        },
        WhatIf: {
          Title: "What If",
          Status: "Durum",
          Description: "What if açıklaması",
          EnableDescription: "Durum Açıklaması",
          Type: "Tip",
          OutputDescription: "Hedef açıklaması",
          InputDescription: "Veri girişi açıklaması",
          Input: "Veri Girişi",
          Output: "Hedef",
          OutputFormula: "Hedef Formülü",
          OutputFormulaDescription: "Hedef Formülü Açıklama",
          FormulaDisabled: "Formül aktif değil",
          WhatIfSuccessfullyAdded: "What if başarıyla eklendi.",
          CouldntEvaluate: "Hesaplanamadı",
          SetDefault: "varsayılan",
          AddNew: "Yeni Ekle",
          List: "Listele",
          SaveCurrentScenario: "Şu anki senaryoyu kaydet",
          ScenarioNameError: "Senaryoyu kaydetmek için isim vermelisin",
          ScenarioSuccessfullyAdded: "Senaryo başarıyla eklendi",
          ScenarioName: "Senaryo Adı",
          Scenarios: "Senaryolar",
          Parameters: "Parametreler",
          CurrentScenario: "Şu anki senaryo",
          CreateUpdate: {
            Name: "Formül Adı",
            DisplayName: "Görünen Ad",
            DefaultValue: "Varsayılan Değer",
            MinValue: "En Küçük Değer",
            MaxValue: "En Büyük Değer",
            IncrementValue: "Artış Değeri",
            DataType: "Parametre Veri Tipi",
            ShowType: "Gösterilme Biçimi",
            Slider: "Slider",
            Input: "Veri girişi",
            Integer: "Tam Sayı",
            Decimal: "Ondalık Sayı",
            Percent: "Yüzde",
            NoBlankSpaceCanBeLeft: "Boş alan bırakılamaz.",
            DefaultValueCannotBeLowerThanMinValue: "Varsayılan değer, minimum değerden küçük olamaz.",
            DefaultValueCannotBeGreaterThanMaxValue: "Varsayılan değer maksimum değerden büyük olamaz.",
            IncrementValueCannotBeGreaterThanDifferenceOfMaxValueAndMinValue: "Artış değeri, maksimum değer ile minimum değerin farkından büyük olamaz",
            IncrementValueMustBeGreaterThanZero: "Artış değeri sıfırdan büyük olmalıdır.",
            IncrementValueCannotBeGreaterThanMaxValue: "Artış değeri maksimum değerden büyük olamaz"

          },
          SuccessfullyAdded: "What if parametresi başarıyla eklendi",
          SuccessfullyUpdated: "What if parametresi başarıyla güncellendi"
        },
        ASC: "Artan",
        DESC: "Azalan"
      },
      ConditionalFormatting: {
        ConditionalFormatting: "Koşulsal Formatlama",
        ConditionalFormattingDescription: "Kolon, ifade ve değerlere koşullar ekleyerek pluginini özelleştirebilirsin.",
        NoConditionalFormatsFound: "Koşulsal formatlama bulunamadı.",
        TargetColumns: "Hedef Kolonlar",
        TargetColumnsDescription: "Koşulsal formatlamanın uygulanacağı kolonları buradan seçebilirsin.",
        Rule: "Koşul",
        RuleDescription: "Kuralı oluştururken; kolon, ifade veya değer seçebilir/yazabilirsin. Kuralın sol ve sağ tarafını seçeceğin operatör ile birleştirirsin.",
        RuleDescriptionTitle: "Kural Açıklaması",
        RuleDescriptionInfo: {
          Info: "Koşulsal formatlama gösterge alanında yazılacak metin alanıdır."
        },
        NoData: "Kural Açıklaması",
        LeftRule: "Sol Kural",
        Operator: "Operatör",
        RightRule: "Sağ Kural",
        BackgroundColor: "Arka Plan Rengi",
        Color: "Renk",
        ConditionalFormatSuccessFullyAdded: "Koşulsal formatlama başarıyla eklendi.",
        ConditionalFormatSuccessFullyDeleted: "Koşulsal formatlama başarıyla silindi.",
        OptionNotValid: "Hiç bir özellik seçilmedi.",
        RuleNotValid: "Kural geçerli değil.",
        TargetColumnsCannotEmpty: "En az bir hedef kolon seçilmeli.",
        Target: "Hedef",
        Condition: "Koşul",
        Style: "Biçim",
        TextColor: "Yazı Rengi",
        CouldNotRunRule: "Kural çalıştırılamadı. Lütfen kontrol edip geçerli bir kural giriniz.",
        TypeMismatch: "Kolon/değer tip uyumsuzluğu nedeni ile kural çalıştırılamadı.",
        Icon: "İkon",
        IconProperties: "İkon Durumu",
        Font: "Yazı Stili",
        TitleFontStyle: "Başlık Yazı Stilleri",
        ValueFontStyle: "Değer Yazı Stilleri",
        HeaderFontStyle: "Satır/Kolon Yazı Stilleri",
        TotalStyle: "Toplam Yazı Stilleri"
      },
      Save: {
        Save: "Kaydet",
        SaveAs: "Farklı Kaydet",
        TitleEmptyMessage: "Raporuna bir başlık eklemeden kaydedemezsin.",
        TitleEmptyError: "Başlık eklenmediği için rapor kaydedilemedi!",
        SuccessMessage: "Raporun başarıyla kaydedildi.",
        NoPlugins: "Hiç bir plugin olmadan rapor kaydedemezsin!"
      },
      Plugin: {
        Untitled: "Başlıksız",
        DoYouWantToDeleteThisPlugin: "Bu plugini silmek istiyor musunuz?",
        Calculations: {
          Errors: {
            "Missing": "#EKSİK",
            "Error": "#HATA"
          }
        }
      },
      PluginContextMenu: {
        DuplicatePlugin: "Plugini Çoğalt",
        Delete: "Sil",
        DuplicateAll: "Hepsini Seç",
        Interaction: "Etkileşimler",
        Drilldown: "Detaylar",
        Navigation: "Navigation",
        DefaultFilter: "Varsayılan Filtreler",
        ConditionalFormat: "Koşulsal Formatlama",
        PastePlugin: "Plugini Yapıştır",
        ConvertPlugin: "Dönüştür",
        Export: "Çıktı Al",
        Default: "Varsayılan",
        Back: "Geri",
        Options: "Seçenekler",
      },
      PluginConversion: {
        ConditionalFormatMismatchWarning: "Veri alanları değişen bazı sütunları içeren koşullu formatlar, artık uygulanabilir olamayacakları için silinecektir."
      },
      ToolsSection: {
        CantExportXLSX: "Dışarı aktarılacak plugin bulunamadı",
        DataNotFound: "Aşağıdaki pluginde veri mevcut değil:\n{{plugin}}",
        DataNotFoundMultiple: "Aşağıdaki pluginlerde veri mevcut değil:\n{{pluginList}}",
        ExporttoXLSX: "XLSX olarak çıkart",
        PluginList: "Görsel Listesi",
        ExporttoPDF: "PDF olarak çıkart",
        ExporttoPNG: "PNG olarak çıkart",
        ExportButton: "Dışarı Aktar",
        RefreshButton: "Yenile",
        ConvertButton: "Dönüştür",
      },
      AutoRefresh: {
        AutoRefresh: "Otomatik Yenileme",
        Description: "Bu alanda istediğiniz süreyi verip yenilenmesini istemediğiniz pluginleri hariç bırakarak, verilerinizin otomatik güncellenmesini sağlayabilirsiniz.",
        SuccessfullyEnabled: "Otomatik yenileme başarıyla başlatıldı.",
        SuccessfullyDisabled: "Otomatik yenileme başarıyla durduruldu.",
        EnableDescription: "Otomatik yenilemeyi buradan durdurup başlatabilirsiniz.",
        RefreshTime: "Yenileme süresi (sn)",
        ExceptedPlugins: "Hariç Bırakılacak Pluginler",
        AutoRefreshSuccessfullyAddedToDashboard: "Otomatik yenileme rapora başarıyla eklendi.",
        AutoRefreshIsActive: "Yenile - Otomatik yenileme aktif"
      },
      SuccessfullyDeleted: "Rapor başarıyla silindi.",
      SuccessfullyReloaded: "Rapor başarıyla geri yüklendi.",
      SureDeletePermanentlyDashboard: "Raporu kalıcı olarak silmek istediğinizden emin misiniz?",
      SuccessfullyPermanentlyDeleted: "Rapor kalıcı olarak silindi.",
      DashboardRemovedFromFavorite: "Rapor favoriden çıkartıldı.",
      DashboardAddedToFavorite: "Rapor favoriye eklendi.",
      AddToFavorite: "Favoriye ekle.",
      RemoveFromFavorite: "Favoriden kaldır.",
      LinkCopiedOnClipboard: "Bağlantı kopyalandı.",
      SureDeleteDashboard: "Bu raporu silmek istediğinden emin misin?",
      DashboardList: "Raporlar",
      SwitchDashboard: "Rapor Değiştir",
      DashboardNotFound: "Rapor bulunamadı!"
    },
    Designer: {
      Template: "Şablon",
      ReportTemplate: "Rapor Şablonu",
      ExistingTemplate: "Mevcut Şablon",
      NewTemplate: "Yeni Şablon",
      TemplateName: "Şablon İsmi",
      TemplateFile: "Şablon Dosyası",
      FileName: "Dosya İsmi",
      FileSize: "Dosya Boyutu",
      UploadDate: "Yüklenme Tarihi",
      DownloadTemplate: "Şablonu İndir",
      SelectFile: "Dosya Seç",
      SelectTemplateFile: "Şablon Dosyası Seç",
      SaveTemplate: "Şablonu Kaydet",
      ChangeFile: "Dosyayı Değiştir",
      SupportedFileFormats: "Desteklenen dosya formatları",
      Bytes: "Bayt",
      CannotSaveTemplateWithoutName: "Şablonu isim vermeden kaydedemezsiniz.",
      TemplateSaveSuccess: "Şablon başarıyla kaydedildi.",
      TemplateDownloadSuccess: "Şablon başarıyla indirildi.",

      Query: "Sorgu",
      Queries: "Sorgular",
      CreateNewQuery: "Yeni sorgu oluştur",
      QueryName: "Sorgu İsmi",
      YouCanCreateQuery: "Yukarıda bulunan + butonuna basarak sorgu oluşturabilirsiniz.",
      QueryCreatedButNotSaved: "Sorgu oluşturuldu fakat kaydedilmedi",
      YouCannotSaveQueryWithEmptyProperty: "Bir sorguyu boş bir özellik ile kaydedemezsiniz.",
      YouNeedToSaveQueryCreatedBefore: "Daha önce bir sorgu oluştulmuş fakat kaydedilmemiş. Lütfen sorguyu kaydedin ve tekrar deneyin.",
      QuerySaveSuccess: "Sorgu başarıyla kaydedildi.",
      QueriesAreYourSureDeleteThisPermenently: "Bu sorguyu kalıcı olarak silmekten emin misiniz?",

      Datasource: "Veri Kaynağı",
      Datasources: "Veri Kaynakları",
      Connection: "Bağlantı",
      NewConnection: "Yeni Bağlantı",
      SelectADatasource: "Bir veri kaynağı seçin",
      CouldNotFoundAnyDatasource: "Herhangi bir veri kaynağı bulunamadı.",

      Parameter: "Parametre",
      Parameters: "Parametreler",
      NewParameter: "Yeni Parametre",
      Name: "İsim",
      ParameterName: "Parametre İsmi",
      DataType: "Veri Tipi",
      Text: "Metin",
      Number: "Sayı",
      Date: "Tarih",
      InputType: "Giriş Tipi",
      Free: "Serbest",
      List: "Liste",
      Static: "Statik",
      Dynamic: "Dinamik",
      Single: "Tekli",
      Multiple: "Çoklu",
      Value: "Değer",
      Values: "Değerler",
      ValueName: "İsim",
      NamedParameterValues: "İsimlendirilmiş parametre değerleri",
      NamedParameterValuesInfo: "Açıklama...",
      StaticValuesInfo: "Değerlerinizi virgülle ayırın veya alt alta yazın.",
      StaticLabelsInfo: "Etiketlerinizi virgülle ayırın veya alt alta yazın.",
      ParameterValues: "Parametre değerleri",
      ParameterSaveSuccess: "Parametre başarıyla kaydedildi.",
      Dependency: "Bağımlılık",
      PleaseCheckThatThisParameterHasValue: "{parameter} parametresi, {dependencies} parametresine bağımlıdır. Lütfen bu parametrenin değeri olduğunu kontrol edin.",
      PleaseCheckThatTheseParametersHaveValue: "{parameter} parametresi, {dependencies} parametrelerine bağımlıdır. Lütfen bu parametrelerin değeri olduğunu kontrol edin.",
      RefreshParameters: "Parametreleri Yenile",
      PleaseCheckYourDependentParameters: "Lütfen parametrelerin iç içe bağımlılıklara sahip olmadığından emin olun.",

      RecycleBin: "Çöp Kutusu",
      RecycleBinInfo: "Silmek istediğiniz öğeler burada gösterilir. Raporunuzu kaydettiğinizde öğeler silinir ve çöp kutunuz boşaltılır.",
      EmptyRecycleBin: "Çöp kutusunda öğe yok.",
      MoveToRecycleBin: "Çöp kutusuna taşı",
      AreYouSureDeleteThisQueryPermenently: "Bu sorguyu kalıcı olarak silmekte emin misiniz?",
      AreYouSureMoveThisQueryToRecycleBin: "Bu sorguyu çöp kutusuna taşımakta emin misiniz?",
      AreYouSureMoveThisParameterToRecycleBin: "Bu parametreyi çöp kutusuna taşımakta emin misiniz?",

      ThereIsUnsavedChanges: "Kaydedilmemiş değişiklilkler mevcut",
      ReportNameAlreadyExists: "Bu isimde bir rapor zaten var.",
      YouCannotSaveWithoutAnyQuery: "Raporu hiç sorgu olmadan kaydedemezsiniz.",
      YouNeedToSaveReportBeforeQuerySave: "Soruguyu kaydetmeden önce raporunuzu kaydetmeniz gerekiyor.",
      YouNeedToSaveReportBeforeCreateParameter: "Parametre oluşturmadan önce raporunuzu kaydetmeniz gerekiyor.",
      YouNeedToUploadTemplateFile: "Bir şablon dosyası yüklemeniz gerekiyor.",
      YouNeedToSaveReportBeforeGenerate: "Raporunuzu indirmeden önce kaydetmeniz gerekiyor.",
      ParameterNameRules: "İsim bir harfle başlamalıdır ve yalnızca alfasayısal karakterler ve alt çizgi içerebilir (A-z, 0-9 ve _ ), Türkçe karakter içermemelidir.",
      PleaseUploadCompatipleTemplateFile: "Lütfen uyumlu bir şablon dosyası yükleyin.",
      YouDontHaveAnyParameter: "Kayıtlı bir parametreniz yok.",

      GenerateReport: "Raporu Oluştur",
      FileType: "Dosya Tipi",
      ParameterValueSelection: "Parametre Değer Seçimi",
      SelectValue: "Değer seçiniz",
      EnterValue: "Değer giriniz",
      EnterDate: "Tarih giriniz",
      ReportDownloadSuccess: "Rapor başarıyla indirildi.",
      FormatSettings: "Format Ayarları",
      AdvancedFormatSettings: "Gelişmiş Format Ayarları",
      GeneralSettings: "Genel",
      SetOnce: "Bir kez için uygula",
      SetAsDefault: "Varsayılan olarak uygula",
      RemoveChanges: "Değişiklikleri Kaldır",
      SetFromTemplate: "Şablon Formatını Getir",
      SettingsSaveSuccess: "Ayarlar başarıyla kaydedildi.",
      MeasurementUnit: "Ölçü Birimi",
      Extensions: {
        "pdf": "PDF",
        "xlsx": "Excel",
        "docx": "Word",
      },
      Unit: {
        Inch: "İnç",
        Centimeter: "Santimetre",
        Millimeter: "Milimetre",
        Point: "Punto"
      },
      Orientation: {
        Orientation: "Kağıt Yönü",
        Portrait: "Dikey",
        Landscape: "Yatay"
      },
      PaperFormat: {
        Title: "Kağıt Formatı",
        Format: "Format",
        Letter: "Mektup",
        A3: "A3",
        A4: "A4",
        A5: "A5",
        A6: "A6",
        Custom: "Özel",
        Width: "Genişlik",
        Height: "Uzunluk"
      },
      Margins: {
        Title: "Kenar Boşlukları",
        Left: "Sol",
        Right: "Sağ",
        Top: "Üst",
        Bottom: "Alt",
        Header: "Üst Bilgi",
        Footer: "Alt Bilgi"
      },
      PageOrder: {
        Title: "Sayfa Sıralaması",
        Order: "Sıralama",
        DownThenOver: "Yukarıdan aşağıya, sonra sağa",
        OverThenDown: "Soldan sağa, sonra aşağıya",
        FirstPageNumber: "İlk Sayfa Numarası"
      },
      Scale: {
        Title: "Ölçek",
        FitToWidth: "Genişliği sayfalara sığdır",
        FitToHeight: "Yüksekliği sayfalara sığdır"
      },
      TableAlignment: {
        Title: "Tablo Hizası",
        Horizontal: "Yatay",
        Vertical: "Dikey"
      },
      HeaderFooter: {
        ShowHeader: "Üstbilgiyi göster",
        ShowFooter: "Altbilgiyi göster",
        DifferentOddEven: "Tek ve çift sayfalar için farklı içerik",
        DifferentFirst: "İlk sayfa için farklı içerik",
        LeftArea: "Sol Alan",
        CenterArea: "Orta Alan",
        RightArea: "Sağ Alan",
        LeftMargin: "Sol Boşluk",
        RightMargin: "Sağ Boşluk",
        Spacing: "Aralık",
        Height: "Yükseklik",
        HeaderContent: "Üstbilgi İçeriği",
        FooterContent: "Altbilgi İçeriği",
        AllPages: "Tüm Sayfalar",
        FirstPage: "İlk Sayfa",
        OddPages: "Tek sayfalar",
        EvenPages: "Çift Sayfalar",
        RestOfPages: "Diğer Sayfalar"
      },
      AdvancedPage: {
        File: "Dosya",
        Page: "Kağıt",
        Header: "Üst Bilgi",
        Footer: "Alt Bilgi",
        Sheet: "Sayfa"
      },

      English: "İngilizce",
      Turkish: "Türkçe",
      FileLanguage: "Dil",

      Save: {
        Save: "Kaydet",
        SaveAs: "Farklı Kaydet",
        TitleEmptyMessage: "Raporuna bir başlık eklemeden kaydedemezsin.",
        TitleEmptyError: "Başlık eklenmediği için rapor kaydedilemedi!",
        SuccessMessage: "Raporun başarıyla kaydedildi.",
        NoPlugins: "Hiç bir plugin olmadan rapor kaydedemezsin!"
      }, 

      ReportNotFound: "Rapor bulunamadı."
    },
    FormulaEditor: {
      OpenFormulaEditor: "Formül editörünü aç",
      Titles: {
        TablesAndColumns: "Tablolar & Kolonlar",
        Parameters: "Parametreler",
        Editor: "Editör",
        Formulas: "Formüller",
        DescriptionArea: "Açıklama Alanı",
        AreYouSureCancel: "Çıkmak istediğinize emin misiniz?",
        Variables: "Değişkenler"
      },
      WhatIsExpression: "İşlem Nedir?",
      ExpressionUsageDesc: "Veri kaynağına sorgu olarak gitmeyen hesaplamalardır.",
      EmptyExpressionParameterList: "Herhangi bir işlem parametresi tanımladığınızda bu alanda listelenecektir.",
      TypeYourCode: "-- KODUNUZU YAZIN",
      YourChangesWillBeLost: "Yaptığınız tüm değişiklikler kaybolacak.",
      Advanced: "Gelişmiş",
      Description: "Açıklama",
      Usage: "Kullanım",
      Type: "Tip",
      SelectFormula: "İşlevini ve nasıl kullanıldığını görmek için sağdaki herhangi bir formüle tıklayın.",
      ExpressionParametersDescription: "Veri kaynağına sorgu olarak gitmeyen hesaplamalardır.",
      ExpressionQueryError: "İşlemleri sorgu kısmında kaydedemezsin.",
      ExpressionParametersUsageDesc: "(${exp.<b>param1</b>} + ${exp.<b>param2</b>}) / ${exp.<b>param3</b>} ",
      FunctionTypes: {
        AggregateFunctions: "Toplama Fonksiyonları",
        NumericFunctions: "Matematiksel Fonksiyonlar",
        StringFunctions: "Dizi Fonksiyonları",
        DateFunctions: "Tarih Fonksiyonları",
        TypeConversionFunctions: "Tür Dönüştürme Fonksiyonları",
        ConditionalFunctions: "Koşul Fonksiyonları"
      },
      FunctionType: {
        AggregateFunction: "Toplama Fonksiyonu",
        NumericFunction: "Matematiksel Fonksiyon",
        StringFunction: "Dizi Fonksiyonu",
        DateFunction: "Tarih Fonksiyonu",
        TypeConversionFunction: "Tür Dönüştürme Fonksiyonu",
        ConditionalFunction: "Koşul Fonksiyonu"
      },
      Formulas: {
        // Aggregate Functions
        SUM: {
          Description: "<b>x</b>'deki değerlerin toplamı."
        },
        COUNT: {
          Description: "<b>x</b>'deki değerlerin sayısı."
        },
        AVG: {
          Description: "<b>x</b>'deki değerlerin ortalaması."
        },
        MIN: {
          Description: "<b>x</b>'deki minimum değer."
        },
        MAX: {
          Description: "<b>x</b>'deki maksimum değer."
        },
        // Conditional Functions
        CASE: {
          Description: [
            "<b>Tip 1</b>, koşulun kontrol edileceği kolon ismi ile başlar değerler eşleşene kadar <b>WHEN</b> ifadelerini kontrol eder. ",
            "Eşleşme olunca <b>THEN</b> ifadesinde yazan çıktı oluşur. ",
            "Hiçbir <b>WHEN</b> eşleşmezse ve <b>ELSE</b> belirtilmezse sabit bir değer çıktısı oluşur.",
            "<br /><br />",
            "<b>Tip 2</b>, koşulun sağlanacağı kolon ve belirlenen değerin yazılmasıyla başlar ve koşul sağlanana kadar devam eder. ",
            "Herhangi bir <b>WHEN</b> ifadesinde bulunan herhangi koşul <b>true</b> dönerse <b>THEN</b> ifadesinde yazan çıktı oluşur. ",
            "Hiçbir <b>WHEN</b> eşleşmezse ve <b>ELSE</b> belirtilmezse sabit bir değer çıktısı oluşur."
          ].join("")
        },
        // Numeric Functions
        SQRT: {
          Description: "<b>x</b>'in karekökü."
        },
        ABS: {
          Description: "<b>x</b>'in mutlak değeri."
        },
        CEILING: {
          Description: "<b>x</b>'i üste yuvarlama."
        },
        FLOOR: {
          Description: "<b>x</b>'i aşağı yuvarlama."
        },
        ROUND: {
          Description: "<b>x</b>'deki ondalık sayının virgülden sonraki hanesini y'deki değere göre yuvarlar."
        },
        EXP: {
          Description: "<b>e<b> üzeri <b>x</b>."
        },
        LOG: {
          Description: "<b>e</b> tabanına göre log."
        },
        LOG10: {
          Description: "<b>10</b> tabanına göre log."
        },
        MOD: {
          Description: " <b>X/Y</b> (<b>x</b> bölü <b>y</b>) kalanı."
        },
        POWER: {
          Description: "<b>xʸ</b> (<b>x</b> üssü <b>y</b>)."
        },
        RADIANS: {
          Description: " <b>x</b>'deki derece değer, radyana dönüştürme."
        },
        DEGREES: {
          Description: "<b>x</b>'deki radyan değer, dereceye dönüştürme."
        },
        // String Functions
        ASCII: {
          Description: " <b>x</b>'de en soldaki karakter <b>ASCII</b> karşılığı."
        },
        CHR: {
          Description: "<b>x</b> <b>ASCII</b> değerinin karakter karşılığı."
        },
        CHAR: {
          Description: "<b>x</b> <b>ASCII</b> değerinin karakter karşılığı."
        },
        CONCAT: {
          Description: "<b>x</b> ve <b>y</b>'i birleştirir. <b>x</b> ve <b>y</b>'den herhangi birisi <b>NULL</b> ise sonuç <b>NULL</b> olur."
        },
        CONCAT2: {
          Description: "<b>x</b> ve <b>y</b>'i birleştirir. <b>x</b> ve <b>y</b>'den herhangi birisi <b>NULL</b> ise sonuç <b>NULL</b> olur."
        },
        ENDSWITH: {
          Description: "<b>y</b>, <b>x</b> ile bitiyor mu? (<b>true</b> veya <b>false</b> döner) <b>x</b> veya <b>y</b> <b>NULL</b> ise <b>NULL</b> döner."
        },
        INITCAP: {
          Description: "<b>x</b>'de ilk karakter büyük devamı küçük olarak gösterilir."
        },
        LCASE: {
          Description: "<b>x</b>'de tüm karakterler küçük olarak gösterilir."
        },
        LEFT: {
          Description: "<b>x</b>'de soldan <b>y</b> kadar karakter yazdır."
        },
        LENGTH: {
          Description: "<b>x</b> uzunluğu (<b>tamsayı</b> döner)."
        },
        CHAR_LENGTH: {
          Description: "<b>x</b> uzunluğu (<b>tamsayı</b> döner)."
        },
        CHARACTER_LENGTH: {
          Description: "<b>x</b> uzunluğu (<b>tamsayı</b> döner)."
        },
        LOCATE: {
          Description: "<b>y</b>'de <b>x</b>'in hangi konumda olduğunu bulur."
        },
        LTRIM: {
          Description: "Solda bulunan boşluk karakterini siler."
        },
        RTRIM: {
          Description: "Sağda bulunan boşluk karakterini siler."
        },
        REPEAT: {
          Description: "Kaç defa çoklanacağını gösterir."
        },
        RIGHT: {
          Description: "<b>x</b>'de sağdan <b>y</b> kadar karakter getirir."
        },
        SUBSTRING: {
          Description: "<b>y</b> konumundan <b>x</b>'in sonuna kadar alt dize alınır."
        },
        TRANSLATE: {
          Description: "<b>x</b> de yer alan verideki <b>y</b> karakterini <b>z</b> olarak değiştir."
        },
        TRIM: {
          Description: "<b>y</b> dizisinden <b>x</b> karakterini <b>baştan</b>, <b>sondan</b> veya <b>her iki taraftan</b> kırpma."
        },
        UCASE: {
          Description: "<b>x</b>'de tüm karakterler büyük olarak gösterilir."
        },
        // Type Conversation Functions
        CONVERT: {
          Description: "<b>x</b>'i belirtilen veri tipine çevirme."
        },
        CAST: {
          Description: "<b>x</b>'i belirtilen veri tipine çevirme."
        },
        // Date Functions
        CURDATE: {
          Description: "Güncel <b>tarih</b>'i gösterir."
        },
        CURRENT_DATE: {
          Description: "Güncel <b>tarih</b>'i gösterir."
        },
        CURTIME: {
          Description: "Güncel <b>saat</b>'i gösterir."
        },
        CURRENT_TIME: {
          Description: "Güncel <b>saat</b>'i gösterir."
        },
        NOW: {
          Description: "Güncel <b>zaman</b>'ı gösterir (<b>tarih</b> ve <b>milisaniye</b> detayında <b>saat</b>)."
        },
        CURRENT_TIMESTAMP: {
          Description: "Güncel <b>zaman</b>'ı gösterir (<b>tarih</b> ve <b>milisaniye</b> detayında <b>saat</b>)."
        },
        DAYNAME: {
          Description: "<b>x</b> tarihinin olduğu <b>gün</b> ismini gösterir (<b>lokal</b>'e göre)."
        },
        DAYOFMONTH: {
          Description: "<b>x</b> tarihinde mevcut <b>ay</b>'ın <b>gün</b> numarasını gösterir."
        },
        DAYOFWEEK: {
          Description: "<b>x</b> tarihinde mevcut <b>hafta</b>'nın kaçıncı <b>gün</b>'ü olduğunu gösterir."
        },
        DAYOFYEAR: {
          Description: "<b>x</b> tarihinde mevcut <b>yıl</b>ın kaçıncı <b>gün</b>'ü olduğunu gösterir."
        },
        EXTRACT: {
          Description: [
            "<b>x</b> tarihini verilen <b>FIELD</b> alanındaki cinsten getirir.",
            "<br />",
            "Geçerli FIELD alanları; <b>YEAR</b>, <b>MONTH</b>, <b>DAY</b>, <b>HOUR</b>, <b>MINUTE</b>, <b>SECOND</b>, <b>QUARTER</b>, <b>DOW</b> ve <b>DOY</b>."
          ].join("")
        },
        HOUR: {
          Description: "<b>x</b> tarihi <b>saat</b> bilgisini gösterir (24 saat formatında)"
        },
        MINUTE: {
          Description: "<b>x</b> tarihi <b>dakika</b> bilgisini gösterir"
        },
        MONTH: {
          Description: "<b>x</b> tarihi <b>ay no</b> bilgisini gösterir"
        },
        MONTHNAME: {
          Description: "<b>x</b> tarihi <b>ay adı</b> bilgisini gösterir."
        },
        QUARTER: {
          Description: "<b>x</b> tarihi <b>çeyrek</b> bilgisini gösterir."
        },
        SECOND: {
          Description: "<b>x</b> tarihi <b>saniye</b> bilgisini gösterir."
        },
        WEEK: {
          Description: "<b>x</b> de yer alan tarihte yer alan <b>yıl</b>'ın <b>hafta</b>'larını gösterir (1-53 arası)."
        },
        YEAR: {
          Description: "<b>x</b> de yer alan <b>tarih</b> bilgisinden dört haneli <b>yıl</b> bilgisini gösterir."
        },
        TIMESTAMPADD: {
          Description: [
            "Zamana belirli bir aralık miktarı ekler.",
            "<br /><br />",
            "<b>interval</b>:",
            "<br />",
            "<ul>",
            "<li><b>SQL_TSI_FRAC_SECOND</b>: saniyenin milyarda biri</li>",
            "<li><b>SQL_TSI_SECOND</b>: saniye</li>",
            "<li><b>SQL_TSI_MINUTE</b>: dakika</li>",
            "<li><b>SQL_TSI_HOUR</b>: saat</li>",
            "<li><b>SQL_TSI_DAY</b>: gün</li>",
            "<li><b>SQL_TSI_WEEK</b>: Pazar gününü ilk gün olarak kullanan haftalar</li>",
            "<li><b>SQL_TSI_MONTH</b>: ay</li>",
            "<li><b>SQL_TSI_QUARTER</b>: çeyrek</li>",
            "<li><b>SQL_TSI_YEAR</b>: yıl</li>",
            "</ul>",
            "<br />",
            "<b>count</b>: Zamana eklenecek tamsayı birim sayısı. Negatif değer girilirse zamandan çıkarılır.",
            "<br /><br />",
            "<b>timestamp</b>: datetime ifadesi."
          ].join("")
        },
        TIMESTAMPDIFF: {
          Description: [
            "Calculates the number of date part intervals between the two timestamps.",
            "<br /><br />",
            "<b>interval</b>:",
            "<br />",
            "<ul>",
            "<li><b>SQL_TSI_FRAC_SECOND</b>: saniyenin milyarda biri</li>",
            "<li><b>SQL_TSI_SECOND</b>: saniye</li>",
            "<li><b>SQL_TSI_MINUTE</b>: dakika</li>",
            "<li><b>SQL_TSI_HOUR</b>: saat</li>",
            "<li><b>SQL_TSI_DAY</b>: gün</li>",
            "<li><b>SQL_TSI_WEEK</b>: Pazar gününü ilk gün olarak kullanan haftalar</li>",
            "<li><b>SQL_TSI_MONTH</b>: ay</li>",
            "<li><b>SQL_TSI_QUARTER</b>: çeyrek</li>",
            "<li><b>SQL_TSI_YEAR</b>: yıl</li>",
            "</ul>",
            "<br />",
            "<b>startTime</b>: Fark hesaplanacak zaman (datetime).",
            "<br /><br />",
            "<b>endTime</b>: Fark hesaplanacak zaman (datetime)."
          ].join("")
        },
      }
    },
    Plugins: {
      "network-graph": {
        Name: "Ağ Grafiği",
        ColumnMap: {
          Id: {
            Name: "ID",
            Desc: "Ağ Grafiği ID açıklaması"
          },
          Label: {
            Name: "Etiket",
            Desc: "Ağ Grafiği etiket açıklaması"
          },
          LinkWith: {
            Name: "Bağlı",
            Desc: "Ağ Grafiği bağlı açıklaması"
          },
          ParentId: {
            Name: "Ebeveyn ID",
            Desc: "Ağ Grafiği ebeveyn ID açıklaması"
          },
          Measure: {
            Name: "Değer",
            Desc: "Ağ Grafiği değer açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Ağ Grafiği saklı alan açıklaması"
          }
        }
      },
      "custom": {
        Name: "Vega Lite",
        ColumnMap: {
          Columns: {
            Name: "Kolonlar",
            Desc: "Vega Lite kolonların açıklaması",
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Vega Lite saklı alan açıklaması"
          }
        },
      },
      "pie-chart-enhanced": {
        Name: "Pasta Grafiği",
        ColumnMap: {
          Measure: {
            Name: "Değer",
            Desc: "Pasta Grafiği ölçü değer açıklaması"
          },
          Category: {
            Name: "Kategori",
            Desc: "Pasta Grafiği kategori açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Pie chart saklı alan açıklaması"
          }
        }
      },
      "measure-tile": {
        Name: "Performans Döşeme",
        ColumnMap: {
          Measure: {
            Name: "Ölçü Değeri",
            Desc: "Performans döşeme ölçü değeri açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Performans döşeme saklı alan açıklaması"
          }
        }
      },
      "pivot-table": {
        Name: "Pivot Table",
        ColumnMap: {
          Rows: {
            Name: "Satırlar",
            Desc: "Pivot table satır açıklaması"
          },
          Columns: {
            Name: "Sütunlar",
            Desc: "Pivot table sütun açıklaması"
          },
          Measures: {
            Name: "Ölçü Değeri",
            Desc: "Pivot table ölçü değeri açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Pivot table saklı alan açıklaması"
          }
        }
      },
      "table": {
        Name: "Tablo",
        ColumnMap: {
          Columns: {
            Name: "Kolon",
            Desc: "Tablo kolon açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Tablo saklı alan değeri açıklaması"
          }
        }
      },
      sunburst: {
        Name: "sunburst",
        ColumnMap: {
          Level: {
            Name: "Seviye",
            Desc: "Sunburst Grafiği seviye değeri açıklaması"
          },
          Measure: {
            Name: "Ölçü Değeri",
            Desc: "Sunburst ölçü değeri açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Sunburst saklı alan açıklaması"
          }
        }
      },
      "liquid-gauge": {
        Name: "Sıvı Ölçek",
        ColumnMap: {
          Measure: {
            Name: "Ölçü Değeri",
            Desc: "Sıvı Ölçek ölçü değeri açıklaması",
          },
          Target: {
            Name: "Hedef değer",
            Desc: "Sıvı Ölçek hedef değer açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Sıvı Ölçek saklı alan açıklaması"
          }
        }
      },
      radar: {
        Name: "Radar",
        ColumnMap: {
          Category: {
            Name: "Kategori",
            Desc: "Radar kategori açıklaması"
          },
          Measure: {
            Name: "Ölçü Değeri",
            Desc: "Radar ölçü değeri açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Radar saklı alan açıklaması"
          }
        }
      },
      "pie-chart": {
        Name: "Pasta Grafiği",
        ColumnMap: {
          Category: {
            Name: "Kategori",
            Desc: "Pasta grafiği kategori açıklaması"
          },
          Measure: {
            Name: "Ölçü değeri",
            Desc: "Pasta Grafiği ölçü Değeri açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Pasta grafiği saklı alan açıklaması",
          },
        }
      },
      "sankey-chart": {
        Name: "Sankey",
        ColumnMap: {
          Level: {
            Name: "Seviye",
            Desc: "Sankey seviye değeri açıklaması",
          },
          Measure: {
            Name: "Ölçü Değeri",
            Desc: "Sankey ölçü değeri açıklaması",
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Sankey saklı alan açıklaması"
          }
        },
      },
      image: {
        Name: "Resim",
        ColumnMap: {
          URL: {
            Name: "URL",
            Desc: "Resim URL açıklaması",
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Ressim saklı alan açıklaması",
          },
        },
      },
      "word-cloud": {
        Name: "Kelime Bulutu",
        ColumnMap: {
          Freq: {
            Name: "Sıklık",
            Desc: "Kelime bulutu sıklık açıklaması",
          },
          Word: {
            Name: "Kelime",
            Desc: "Kelime bulutu kelime açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Kelime bulutu saklı alan açıklaması"
          }
        }
      },
      "map-choropleth": {
        Name: "Map Choropleth",
        ColumnMap: {
          Code: {
            Name: "Kod",
            Desc: "Map choropleth kod açıklaması"
          },
          Description: {
            Name: "Açıklama",
            Desc: "Map choropleth açıklama açıklaması"
          },
          Measure: {
            Name: "Ölçü değeri",
            Desc: "Map choropleth ölçü değeri açıklaması"
          },
          Vary: {
            Name: "Renge Göre Değişim",
            Desc: "Map choropleth renge göre değişim açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Map choropleth saklı alan açıklaması"
          }
        }
      },
      "line-bar-chart": {
        Name: "Line Bar Chart",
        ColumnMap: {
          Category: {
            Name: "Kategori",
            Desc: "Line Bar Grafiği kategori açıklaması",
          },
          BarMeasure: {
            Name: "Bar Ölçüsü",
            Desc: "Line Bar Grafiği bar ölçü değeri açıklaması"
          },
          LineMeasure: {
            Name: "Çizgi Ölçüsü",
            Desc: "Line Bar Grafiği çizgi ölçü değeri açıklaması",
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Line Bar Grafiği saklı alan açıklaması",
          },
        },
      },
      "line-bar": {
        Name: "Line Bar",
        ColumnMap: {
          Category: {
            Name: "Kategori",
            Desc: "Çizgi grafiği kategori açıklaması",
          },
          Measure: {
            Name: "Ölçü Değeri",
            Desc: "Çizgi grafiği ölçü değeri açıklaması",
          },
          Vary: {
            Name: "Renge Göre Değişim",
            Desc: "Çizgi grafiği renge göre değişim açıklaması",
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Çizgi grafiği saklı alan açıklaması",
          },
        },
      },
      "timeline-chart": {
        Name: "Timeline Chart",
        ColumnMap: {
          Date: {
            Name: "Tarih",
            Desc: "Zaman çizelgesi grafiği tarih açıklaması",
          },
          Measure: {
            Name: "Ölçü Değeri",
            Desc: "Zaman çizelgesi grafiği ölçü değeri açıklaması",
          },
          Vary: {
            Name: "Renge Göre Değişim",
            Desc: "Zaman çizelgesi grafiği renge göre değişim açıklaması",
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Zaman çizelgesi grafiği saklı alan açıklaması",
          },
        }
      },
      "age-pyramid": {
        Name: "Piramit Grafiği",
        ColumnMap: {
          Category: {
            Name: "Kategori",
            Desc: "Piramit Grafiği kategori değeri açıklaması"
          },
          Either: {
            Name: "Ölçü Değeri 1",
            Desc: "Piramit Grafiği ölçü değeri 1 açıklaması"
          },
          Other: {
            Name: "Ölçü Değeri 2",
            Desc: "Piramit Grafiği ölçü değeri 2 açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Piramit Grafiği saklı alan açıklaması"
          },
          Animation: {
            Name: "Animasyon",
            Desc: "Piramit Grafiği animasyon açıklaması"
          }
        }
      },
      "bar-chart": {
        Name: "Bar Grafiği",
        ColumnMap: {
          Category: {
            Name: "Kategori",
            Desc: "Bar Grafiği kategori açıklaması",
          },
          Measure: {
            Name: "Ölçü Değeri",
            Desc: "Bar Grafiği ölçü değeri açıklaması",
          },
          Vary: {
            Name: "Renge Göre Değişim",
            Desc: "Bar Grafiği renge göre değişim açıklaması",
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Bar Grafiği saklı alan açıklaması",
          },
        },
      },
      "multi-axis-line-chart": {
        Name: "Çok Eksenli Grafik",
        ColumnMap: {
          Category: {
            Name: "Kategori",
            Desc: "Çok Eksenli Grafik kategori açıklaması",
          },
          Columns: {
            Name: "Kolonlar",
            Desc: "Çok Eksenli Grafik kolonların açıklaması",
          },
          Lines: {
            Name: "Çizgiler",
            Desc: "Çok Eksenli Grafik çizgilerin açıklaması",
          },
          Vary: {
            Name: "Renge Göre Değişim",
            Desc: "Çok Eksenli Grafik renge göre değişim açıklaması",
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Çok Eksenli Grafik saklı alan açıklaması",
          },
          Tooltip: {
            Months: "Aylar",
            ShortMonths: "Kısa Aylar",
            Days: "Günler",
            ShortDays: "Kısa Günler",
            ExportToSVG: "SVG Olarak Çıkart",
            ExportToPNG: "PNG Olarak Çıkart",
            DownloadSVC: "SVC'yi İndir",
            Menu: "Menü",
            Selection: "Seçim",
            SelectionZoom: "Seçimi Yakınlaştır",
            ZoomIn: "Yakınlaştır",
            ZoomOut: "Uzaklaştır",
            Pan: "Pan",
            Reset: "Sıfırla"
          }
        },
      },
      "route-map": {
        Name: "Oklu Türkiye Haritası",
        ColumnMap: {
          SourceId: {
            Name: "Çıkış İl Plakası",
            Desc: "Oklu Türkiye haritası çıkış il plakası açıklaması",
          },
          SourceLat: {
            Name: "Çıkılan İlin Enlemi",
            Desc: "Oklu Türkiye haritası çıkılan ilin enlem açıklaması",
          },
          SourceLon: {
            Name: "Çıkılan İlin Boylamı",
            Desc: "Oklu Türkiye haritası çıkılan ilin boylam açıklaması",
          },
          DestinationId: {
            Name: "Varış İl Plakası",
            Desc: "Oklu Türkiye haritası varış il plaka açıklaması",
          },
          DestinationLat: {
            Name: "Varılan İlin Enlemi",
            Desc: "Oklu Türkiye haritası varılan ilin enlem açıklaması",
          },
          DestinationLon: {
            Name: "Varılan İlin Boylamı",
            Desc: "Oklu Türkiye haritası varılan ilin boylam açıklaması",
          },
          Value: {
            Name: "Değer",
            Desc: "Oklu Türkiye haritası değer açıklaması",
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Oklu Türkiye haritası saklı alan açıklaması"
          }
        },
      },
      title: {
        Name: "Başlık",
        ColumnMap: {
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Başlık saklı alan açıklaması"
          }
        }
      },
      "route-map-2": {
        Name: "Türkiye Haritası 2",
        ColumnMap: {
          SourceId: {
            Name: "Çıkış İl Plakası",
            Desc: "Oklu Türkiye haritası çıkış il plakası açıklaması",
          },
          SourceLat: {
            Name: "Çıkılan İlin Enlemi",
            Desc: "Oklu Türkiye haritası çıkılan ilin enlem açıklaması",
          },
          SourceLon: {
            Name: "Çıkılan İlin Boylamı",
            Desc: "Türkiye haritası çıkılan ilin boylam açıklaması",
          },
          DestinationId: {
            Name: "Varış İl Plakası",
            Desc: "Oklu Türkiye haritası varış il plaka açıklaması",
          },
          DestinationLat: {
            Name: "Varılan İlin Enlemi",
            Desc: "Oklu Türkiye haritası varılan ilin enlem açıklaması",
          },
          DestinationLon: {
            Name: "Varılan İlin Boylamı",
            Desc: "Oklu Türkiye haritası varılan ilin boylam açıklaması",
          },
          Value: {
            Name: "Değer",
            Desc: "Oklu Türkiye haritası değer açıklaması",
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Oklu Türkiye haritası saklı alan açıklaması"
          }
        },
      },
      "turkey-map": {
        Name: "Türkiye Haritası",
        ColumnMap: {
          Value: {
            Name: "Değer",
            Desc: "Türkiye haritası değer açıklaması",
          },
          Plaka: {
            Name: "Şehir Kimliği",
            Desc: "Türkiye haritası şehir kimliği açıklaması",
          },
          Title: {
            Name: "Şehir Adı",
            Desc: "Türkiye haritası şehir adı açıklaması",
          },
          IlceId: {
            Name: "İlçe Kimliği",
            Desc: "Türkiye haritası ilçe kimliği açıklaması",
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Türkiye haritası saklı alan açıklaması"
          }
        },
      },
      "tree-map": {
        Name: "Ağaç Harita",
        ColumnMap: {
          Group: {
            Name: "Grup",
            Desc: "Ağaç harita grup açıklaması"
          },
          Measure: {
            Name: "Ölçü Değeri",
            Desc: "Ağaç harita değer açıklaması"
          },
          Vary: {
            Name: "Renge Göre Değişim",
            Desc: "Ağaç harita renge göre değişim açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Ağaç harita saklı alan açıklaması"
          }
        }
      },
      "world-map": {
        Name: "Dünya Haritası",
        ColumnMap: {
          Value: {
            Name: "Değer",
            Desc: "Dünya haritası değer açıklaması"
          },
          CountryCode: {
            Name: "Ülke Kodu",
            Desc: "Dünya haritası ülke kodu açıklaması"
          },
          CountryTitle: {
            Name: "Ülke Adı",
            Desc: "Dünya haritası ülke adı açıklaması"
          },
          CityId: {
            Name: "Şehir Kimliği",
            Desc: "Dünya haritası şehir kimliği açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Dünya haritası saklı alan açıklaması"
          }
        }
      },
      "map-bubbles": {
        Name: "Bubbles Haritası",
        ColumnMap: {
          Description: {
            Name: "Açıklama",
            Desc: "Bubbles haritası açıklama açıklaması"
          },
          Longitude: {
            Name: "Boylam",
            Desc: "Bubbles haritası boylam açıklaması"
          },
          Latitude: {
            Name: "Enlem",
            Desc: "Bubbles haritası enlem açıklaması"
          },
          Measure: {
            Name: "Ölçü Değeri",
            Desc: "Bubbles haritası ölçü değeri açıklaması"
          },
          Vary: {
            Name: "Renge Göre Değişim",
            Desc: "Bubbles haritası renge göre değişim açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Bubbles haritası saklı alan açıklaması"
          }
        }
      },
      "glassed-title": {
        Name: "Camlı Başlık",
        ColumnMap: {
          Value: {
            Name: "Değer",
            Desc: "Camlı başlık değer açıklaması",
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Camlı başlık saklı alan açıklaması"
          }
        },
      },
      "scatter-chart": {
        Name: "Scatter Grafiği",
        ColumnMap: {
          MeasureX: {
            Name: "X değeri",
            Desc: "Scatter Grafiği X değeri açıklaması",
          },
          MeasureY: {
            Name: "Y değeri",
            Desc: "Scatter Grafiği Y değeri açıklaması",
          },
          Group: {
            Name: "Grup",
            Desc: "Scatter Grafiği grup açıklaması",
          },
          VaryColour: {
            Name: "Renge göre değişim",
            Desc: "Scatter Grafiği renge göre değişim açıklaması",
          },
          VarySize: {
            Name: "Büyüklüğe göre değişim",
            Desc: "Scatter Grafiği büyüklüğe göre değişim açıklaması",
          },
          Animation: {
            Name: "Animasyon",
            Desc: "Scatter Grafiği animasyon açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Scatter Grafiği saklı alan açıklaması"
          }
        },
      },
      filter: {
        Name: "Filtre",
        ColumnMap: {
          Filter: {
            Name: "Filtre",
            Desc: "Filtre, filtre açıklaması",
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Filtre saklı alan açıklaması"
          }
        },
        RemoveAllItems: "Tüm filtreleri kaldır"
      },
      "date-filter": {
        Today: "Bugün",
        ThisWeek: "Bu Hafta",
        ThisMonth: "Bu Ay",
        ThisYear: "Bu Yıl",
        CustomDate: "Özel Tarih"
      },
      "radio-button": {
        Name: "Buton Filtresi",
        ColumnMap: {
          Filter: {
            Name: "Filtre",
            Desc: "Seçim filtresi filtre açıklaması"
          },
          Hidden: {
            Name: "Filter",
            Desc: "Seçim filtresi saklı alan açıklaması"
          }
        }
      },
      "button-filter": {
        Name: "Buton Filtresi",
        ColumnMap: {
          Filter: {
            Name: "Filtre",
            Desc: "Buton filtresi filtre açıklaması"
          },
          Hidden: {
            Name: "Filter",
            Desc: "Buton filtresi saklı alan açıklaması"
          }
        }
      },
      "i-frame": {
        Name: "I-Frame",
        ColumnMap: {
          URL: {
            Name: "Url",
            Desc: "I-Frame url açıklaması",
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "I-Frame saklı alan açıklaması",
          },
        },
      },
      "radial-progress": {
        Name: "radyal ilerleme",
        RoundCorners: "Yuvarlatılmış köşeler",
        ColumnMap: {
          Measure: {
            Name: "Ölçü Değeri",
            Desc: "Radyal ilerleme Ölçü değeri açıklaması"
          },
          Target: {
            Name: "Hedef",
            Desc: "Radyal ilerleme Hedef değeri açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Radyal ilerleme saklı alan açıklaması"
          }
        }
      },
      "gauge-chart": {
        Name: "Gösterge",
        ColumnMap: {
          Measure: {
            Name: "Ölçü Değeri",
            Desc: "Gösterge ölçü değeri açıklaması"
          },
          Maximum: {
            Name: "Maksimum",
            Desc: "Gösterge maksimum değer açıklaması"
          },
          Minimum: {
            Name: "Minimum",
            Desc: "Gösterge minimum değer açıklaması"
          },
          Target: {
            Name: "Hedef",
            Desc: "Gösterge hedef değeri açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Gösterge saklı alan açıklaması"
          }
        }
      },
      "cylinder-bar": {
        Name: "Silindir Bar",
        ColumnMap: {
          Label: {
            Name: "Etiket",
            Desc: "Silindir bar etiket açıklaması",
          },
          Values: {
            Name: "Değerler",
            Desc: "Silindir bar değerler açıklaması",
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Silindir bar saklı alan açıklaması"
          }
        },
      },
      flag: {
        Name: "Bayrak",
        ColumnMap: {
          CountryID: {
            Name: "Ülke ID",
            Desc: "Bayrak plugini ülke ID değeri açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Bayrak saklı alan açıklaması"
          }
        }
      },
      "chord-diagram": {
        Name: "Chord Diyagram",
        ColumnMap: {
          Entities: {
            Name: "Varlıklar",
            Desc: "Chord diyagram varlıklar açıklaması"
          },
          Measure: {
            Name: "Ölçü Değeri",
            Desc: "Chord diyagram ölçü değeri açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Chord diyagram saklı alan açıklaması"
          }
        }
      },
      "selection-box": {
        Name: "Seçim Filtresi",
        ColumnMap: {
          Filter: {
            Name: "Filtre",
            Desc: "Seçim filtresi filtre açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Seçim filtresi saklı alan açıklaması"
          }
        }
      },
      "selection-filter": {
        Name: "Seçim Filtresi",
        ColumnMap: {
          Filter: {
            Name: "Filtre",
            Desc: "Seçim filtresi filtre açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Seçim filtresi saklı alan açıklaması"
          }
        }
      },
      "mind-gapper": {
        Name: "mindgapper",
        ColumnMap: {
          XAxis: {
            Name: "X ekseni",
            Desc: "MindGapper x ekseni açıklaması",
          },
          YAxis: {
            Name: "Y ekseni",
            Desc: "MindGapper y ekseni açıklaması",
          },
          BubbleSize: {
            Name: "Balon büyüklüğü",
            Desc: "MindGapper balon büyüklüğü değeri açıklaması",
          },
          Year: {
            Name: "Yıl",
            Desc: "MindGapper yıl açıklaması",
          },
          VaryByColor: {
            Name: "Renklendirme",
            Desc: "MindGapper renklendirme açıklaması",
          },
          BubbleName: {
            Name: "Balon İsmi",
            Desc: "MindGapper balon ismi açıklaması",
          },
          ToggleCriteria: {
            Name: "Geçiş ölçütleri",
            Desc: "MindGapper geçiş ölçütleri açıklaması",
          },
          Title: {
            Name: "Başlık",
            Desc: "MindGapper başlık açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "MindGapper saklı alan açıklaması"
          }
        }
      },
      "map-cluster": {
        Name: "Harita (Cluster)",
        ColumnMap: {
          Desc: {
            Name: "Açıklama",
            Desc: "Harita (Cluster) açıklaması"
          },
          Longitude: {
            Name: "Boylam",
            Desc: "Harita (Cluster) boylam açıklaması"
          },
          Latitude: {
            Name: "Enlem",
            Desc: "Harita (Cluster) enlem açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Harita (Cluster) saklı alan açıklaması"
          }
        }
      },
      "turkish-district-map": {
        Name: "Türkiye İlçeler Haritası",
        ColumnMap: {
          Value: {
            Name: "Değer",
            Desc: "Türkiye ilçeler haritası değer açıklaması"
          },
          CityId: {
            Name: "Şehir Kimliği",
            Desc: "Türkiye ilçeler haritası şehir kimliği açıklaması"
          },
          DistrictName: {
            Name: "İlçe Adı",
            Desc: "Türkiye ilçeler haritası şehir adı açıklaması"
          },
          DistrictId: {
            Name: "İlçe Kimliği",
            Desc: "Türkiye ilçeler haritası ilçe limliği açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Türkiye ilçeler haritası saklı alan açıklaması"
          }
        }
      },
      heatmap: {
        Name: "Harita (Heatmap)",
        ColumnMap: {
          Measure: {
            Name: "Ölçü Değeri",
            Desc: "Harita (Heatmap) ölçü değeri açıklaması"
          },
          Longitude: {
            Name: "Boylam",
            Desc: "Harita (Heatmap) boylam açıklaması"
          },
          Latitude: {
            Name: "Enlem",
            Desc: "Harita (Heatmap) enlem açıklaması"
          },
          Hidden: {
            Name: "Saklı Alan",
            Desc: "Harita (Heatmap) haritası saklı alan açıklaması"
          }
        }
      }
    },
    Errors: {
      MeasureAreaMustBeMeasure: "Beklenen veri tipi numerik fakat kolonun veri tipi numerik değil. Lütfen formul kısmını kullanınız.",
      DateAreaMustBeDate: "Beklenen veri tipi tarih fakat kolonun veri tipi tarih değil. Lütfen formul kısmını kullanınız.",
      MinimumColorValue: "Son renk silinemez.",
      BarChartVaryError: "Görsel resmedilemedi. Birden çok ölçüm sütunu veya tek bir ölçüm ve bir renkle çeşitlen boyutu kabul eder.",
      LineBarVaryError: "Çizgi grafiği resmedilemedi. Birden çok ölçüm sütunu veya tek bir ölçüm ve bir renkle çeşitlenme boyutu kabul eder.",
      FlagInValidColumnError: "Lütfen geçerli türde bir kolon seçin!",
      HeatmapNeedFiveColour: "Heatmap en az 5 renge ihtiyaç duyar.",
      TopojsonErrorLayer: "Sonraki katman hatalı!",
      SortingControl: "Sıralama vermezsen görsel bozukluk yaşayabilirsin.",
      CannotGetDataWithoutColumn: "Hiç bir kolon seçili değilken görselleştirme yapamazsınız.",
      ExpressionNameAlreadyExists: "'[oldExpressionName]' isimli bir işlem zaten mevcut olduğundan işlem ismi otomatik olarak '[newExpressionName]' ile değiştirildi."
    },
    Table: "Tablo",
    Column: "Kolon",
    Tables: "Tablolar",
    Columns: "Kolonlar",
    ColumnMapping: "Kolon Eşleştirmeleri",
    LandingPage: {
      NewDashboard: "Yeni Kumanda Tablosu",
      NewReport: "Yeni Rapor",
      ExpandLabel: 'Etiketi genişlet',
      CollapseLabel: 'Etiketi daralt',
      SortBy: 'Sıralama',
      SortDashboards: 'Raporları Sırala',
      NoDashboard: "Bu etikette hiç bir rapor bulunamadı.",
      Label: {
        Title: "Etiketler",
        Description:
          "Etiketleri seçerek önpanellerinizi görüntüleyebilirsiniz"
      },
      Preview: {
        CreatedUser: "Oluşturan Kullanıcı",
        Description: "Açıklama",
        CreationDate: "Oluşturulma Tarihi",
        UpdateDate: "Son Güncellenme Tarihi",
        ViewCount: "Görüntülenme Sayısı",
        TimesGenerated: "Oluşturulma Sayısı",
      },
      GoDashboard: "Kumanda Tablosuna Git",
      GoReport: "Rapora Git",
      DashboardLabel: {
        Increasing: 'Artan',
        Decreasing: 'Azalan',
        NameAscending: 'A-Z Artan',
        NameDescending: 'A-Z Azalan',
        MostViewed: "En çok görüntülenen",
        LeastViewed: "En az görüntülenen",
        NewestUpdated: 'En yeni güncellenen',
        OldestUpdated: 'En eski güncellenen'
      },
      DashboardFooter: {
        Views: "görüntülenme",
        TimesGenerated: "kez oluşturuldu",
      },
      EmptyLanding: {
        EmptyLandingDescription: "Verilerinizi görselleştiren b#muhteşem görseller/b# oluşturmak istiyorsanız i# butonuna basın.br#Belirlediğiniz şablona uygun b#raporlar/b# oluşturmak istiyorsanız i# butonuna basın.br#Halihazırda b#kumanda tablolarınız ve raporlarınız/b# varsa, ve bunları görüntülemek istiyorsanız i# butonuna basın ve istediğiniz g#etiketleri seçin/g#.",
        EmptyLandingDescriptionMobile: "Halihazırda b#kumanda tablolarınız ve raporlarınız/b# varsa, ve bunları görüntülemek istiyorsanız i# butonuna basın ve istediğiniz g#etiketleri seçin/g#."
      },
      Dashboard: "Kumanda Tablosu",
      Report: "Rapor"
    },
    DateCalculation: {
      Day: 'gün',
      Year: 'yıl',
      Month: 'ay',
      Week: 'hafta'
    },
    Model: {
      Title: "Model",
      SelectModel: {
        PlaceHolder: "Bir model seçin"
      },
      Tables: {
        TablesContentDescription: "Az önce bir model oluşturdunuz. Bu bölüm, seçilen tabloları görüntülemek için kullanılır. Bu modele tablolar eklemek için veri bağlantılarını kullanın."
      },
      RemoveTableWarningDescription: "Silmeye çalıştığın tabloya ait join/joinler bulundu. Silmek istediğinden emin misin?",
      TableName: "Tablo adı:",
      DeleteTable: "Tabloyu Sil",
      DataUpdateSuccess: "Veri yükleme işlemi başarıyla tamamlandı.",
      UnselectAllColumnInTable: "Lütfen en az bir column seçiniz!",
      ModelSelectionInformation: "Verilerinizi eklentiler üzerinde görselleştirmek için bir model seçin.",
      ModelCreationInformation: "Verilerinizi görsel bileşenler üzerinde görselleştirmek için; önce verileriniz ve görsel bileşenleriniz arasında bağlayıcı olan bir model oluşturmanız gerekir",
      ModelsPopup: "Modeller",
      ModelNameEmpty: "Model ismi boş bırakılmaz!",
      CreateModelNameNotification: "Model adı, modelleriniz arasında seçim yaparken size rehberlik edecektir. Bunun için anlamlı bir isim kullanmanızı öneririz. Verdiğiniz isim değiştirilemez.",
      ModelName: "Model İsmi",
      CreateModel: "Model Oluştur",
      ModelAfterCreateDescription: "Modeliniz create edilmiştir. Let's Go diyerek yeni kaynak ekleyip modeliniz için table seçebilirsiniz ya da var olan kaynaklarınızdan table seçebilirsiniz",
      ModelAfterCreateButton: "Hadi Başlayalım! :)",
      ModelNameUniqeError: "Model ismi daha önce kullanılmıştır. Model ismi eşsiz olmalıdır!",
      ChangeSelectedModel: "Seçili Modeli Değiştir",
      SelectedTablesForModel: "Model İçin Seçilmiş Tablolar ",
      ModelCreateAnfFileUploadSuccess: "Model oluşturma ve csv dosyası yükleme başarıyla tamamlandı",
      Join: "Birleştir",
      JoinCreation: " Birleştir",
      JoinEdition: "Birleştirme Düzenleme",
      JoinNoChange: "Değişiklik uygulanmadı",
      JoinCreateSuccessfull: "Join başarıyla oluşturuldu!",
      JoinEditSuccessfull: "Join başarıyla düzenlendi!",
      JoinDeletedSuccessfull: "Join başarıyla silindi!",
      JoinCanNotBeDeleted: "Join silinemedi!",
      JoinCreateFailed: "Join oluşturulamadı!",
      FactTableColumn: "Bilgi Tablo Kolonu",
      PleaseSelectFactTable: "Bilgi tablosu seç",
      PleaseSelectDimensionTable: "Boyut tablosu seç",
      AreYouSureDeleteJoinDefinition: "Bu birleştirmeyi silmek istediğinize emin misin?",
      FactTableColumnSelectError: "Bilgi tablosu için kolon seçilmelidir!",
      DimensionTablesColumns: "Boyut Tablosu Kolonları",
      AtLeastOneDimensionTableColumnSelectError: "Boyut tablosundan en az bir kolon seçilmelidir!",
      JoinType: "Tür",
      MoreThanOneFact: "Birden fazla bilgi tablosu var!",
      MustAddJoin: "Join eklemelisiniz.",
      JoinTypeSelectError: "Join tipi seçilmelidir.!",
      JoinErrorNotJoinTwoTable: "{{table1}} ve {{table2}} tabloları arasında join yok. Eklemek istiyor misiniz?",
      CantFoundJoinThisTables: "Aşağıdaki tablolar arasında join bulunamadı. Eklemek istiyor musunuz?",
      DifferentDatasourceError: "İki farklı veri kaynağından tablo atılamaz.",
      DifferentDatasourceErrorTitle: "Hata",
      CantFoundJoin: "Join Bulunamadı!",
      ModelAddSuccessfull: "Model başarıyla eklendi.",
      ModelNamingDone: "Model isimlendirmeyi tamamladım. Veri kaynağı eklemek istiyorum!",
      CancelModelCreate: "Model Eklemekten Vazgeç",
      NewDuplicatedTableSuccessMessage: "Duplicate edilmiş tablo modele başarıyla eklendi",
      ClearAggRule: "Toplama Kuralını Temizle",
      Create: "Oluştur",
      Edit: "Düzenle",
      Duplicate: "Kopyala",
      WhereClause: "Tümleç",
      AggrigationRule: "Toplama Kuralı",
      DuplicateColumnName: "Kopyalanacak kolonun ismini giriniz",
      WhereClauseColumn: "Tümleç kolonu giriniz",
      EnterAggriagationRule: "Toplama Kurali Giriniz",
      About: "Hakkında",
      Name: "Isim",
      DataSource: "Veri Kaynağı",
      TableNameIsNotUniqueTitle: "  adında bir tablo zaten modelde mevcut.",
      TableNameIsNotUniqueDescription: "Kullanılmayan bir tablo ismi vererek ekleyebilirsiniz.", 
      EnterRenameColumnName: "Yeni kolon ismi giriniz",
      Pin: "Sabitle",
      UnPin: "Sabitlemeyi Kaldır",
      EmptyTable: "Veri yok.",
      SearchNoData: "Bu kriterlerde veri bulunamadı."
    },
    FileUpload: {
      Delimiter: {
        Tab: "Tab",
        Space: "Boşluk"
      },
      Load: "Yükle",
      ChangeEncodingMessage: "Dosyayı yeniden yüklemeniz gerekiyor. Emin misiniz?",
      RowLoaded: " Satır Yüklendi",
      PreviewAndSelectDataType: "Önizleme ve Veri Tipi Seçimi",
      FileTypeValid: "Dosya türü geçerli değil, Lütfen kontrol edin!",
      EnterSplitter: "Ayırıcı",
      FileFormat: "Karakter Seti",
      EnterLocalization: "Yerelleştirme",
      AppendData: "Veri Ekle",
      TruncateFile: "Verileri Sil",
      DeleteFile: "Dosyayı Sil",
      CsvNotLoad: "Csv dosyası yüklenemedi",
      InvalidType: "Dosya tipi geçersiz. Lütfen kontrol ediniz!",
      EnterDateFormat: "Tarih formatını giriniz",
      SucceededRows: "Başarılı satır sayısı: ",
      FailedRows: "Başarısız satır sayısı: ",
      EmptyColumn: "Csv dosyasında boş bir sütun var, dosya yüklenemez. Boş sütunu silip tekrar deneyin!",
      SelectDataSource: "Seçilen veri kaynağı:",
      SelectModel: "Seçilen model: ",
      PleaseSelectModel: "Bir sonraki adım için belgeni yüklemek istediğin modeli seç.",
      PleaseSelectDataSource: "Bir sonraki adım için belgeni yüklemek istediğin veri kaynağını seç.",

      FileUpload: "Dosya yükle",
      YouMustChooseAnyFile: "Bir dosya seçmelisiniz!",
      YouMustChooseAnySchema: "Şema seçmelisiniz!",
      Splitter: "Ayraç",
      SelectSchemaName: "Seçili veri kaynağı için şema seçin::",
      ChooseYourFileType: "Dosya tipi seçin:",
      FileHasHeaderColumns: "Dosyada başlık kolonları var",
      ChooseYourFileTitle: "Dosya seçin",
      ChooseYourFileDesc: "Dosya tipi & dosya seçin.",
      PreviewAndEditTitle: "Önizleme & Düzenleme",
      PreviewAndEditDesc:"Düzenleme & alan seçme.",
      CheckAcceptibilityTitle: "Erişilebilirlik kontrolü",
      CheckAcceptibilityDesc: "Dosya & tablo/kolon kontrolü ve yükleme.",
      UploadingTitle: "Yükleniyor...",
      UploadingDesc: "Yükleme & ilerleme",
      FileUploadStatus: "Yükleme Durumu",
      YourDataIsBeingReviewed: "Verileriniz inceleniyor...",
      Cancel: "Vazgeç",
      Close: "Kapat",
      Previous: "Geri",
      Next: "İleri",
      CheckTables: "Tabloları kontrol et",
      Upload: "Yükle",
      Minimize: "Küçült",

      NOT_STARTED: "Başlamadı",
      UPLOADING: "Yükleniyor",
      DONE: "Tamamlandı",
      FAILED: "Başarısız",
      PROCESSING: "İşleniyor",

      ALREADY_EXIST: "Zaten Var",
      LOADING: "Yükleniyor...",
      ERROR: "Hata",
      READY_TO_CREATE: "Oluşturulmaya Hazır",
      ORIGINAL_TABLE: "Orijinal Tablo",

      SUCCESS: "Başarılı",
      WARNING: "Uyarı",
      NULL_ROWS: "satır boş olduğu için eklenmedi.",

      DO_NOTHING: "Hiç bir şey yapma",
      OVERWRITE: "Veriyi ez",
      APPEND: "Veriyi ekle",
      CREATE: "Oluştur",

      YouCannotChangeOriginalTable: "Veri kaynağınızın orijinal tablosunu değiştiremezsiniz!",
      FileUploadingStatus: "Dosya yükleniyor. Durum: ",
      AtLeastOneSheetMustWrite: "En az bir sayfa seçilmeli!",
      TableNameCouldNotBeEmpty:"Tablo ismi boş olmamalı",
      TableNameCouldNotIncludesSpecialChars: "Tablo ismi '\"', '%', '&', '[', ']', '|' veya '.' karakterleri içeremez",
      MustContainsAtLeastOneSheet: "En az bir sayfa içermeli.",
      MustContainsAtLeastOneColumnsPerSheets: "Her sayfada en az bir kolon seçilmeli. Boş sayfalar:",
      YouMustSelectAtLeastASheet: "En az bir sayfa seçilmeli.",
      YouMustSelectAtLeastAColumnPerSheet: 'Her sayfada en az bir kolon seçilmeli. Boş sayfalar: "',
      SheetNamesMustBeUnique: "Sayfa isimleri eşsiz olmalı. Eşsiz olan sayfa isimleri: ",
      ColumnNamesMustBeUniqueBySheet: "Bir sayfadaki kolon isimleri eşsiz olmalı.",
      ColumnNameCouldNotBeEmpty: "Kolon ismi boş olamaz",
      ColumnNameCouldNotIncludesSpecialChars: "Kolon ismi '\"' veya '.' karakterleri içeremez",
      FixSheetName: "Sayfa ismini düzelt",
      FixColumnName: "Kolon ismini düzelt",
      SameColumnNamesChanged: "Aynı kolon isimleri otomatik olarak değiştirilir.",
      InvalidSheetNamesDetected: "Geçersiz tablo isimleri bulundu",

      Sheet: "Sayfa",
      Status: "Durum",
      Operation: "İşlem",
      SheetList: "Sayfa Listesi",
      DataProcessing: "Veri işleniyor...",
      Result: "Sonuç",
      TableName: "Tablo Adı",
      Detail: "Detay",
      First10RowWithHeader: "Başlık ile birlikte ilk 10 satır",

      TimeOutError: "Yükleme işlemi zaman aşımına uğradı. Fakat arka tarafta verileriniz veri kaynağınıza işlenmeye devam ediyor."
    },
    DataConnections: {
      ConnectionsPreExplanation: "Mevcut bir veri bağlantısını seçin veya yeni bir tane oluşturun",
      Connections: "Bağlantılar",
      ConnectDataStore: "Bir Veri Deposuna Bağlanın",
      ChooseConnectionAndCreate: "Bağlantı türünüzü seçin ve yeni bir connection oluşturun",
      TestConnection: "Test bağlantısı",
      ConnectionName: "Bağlantı için bir isim verin",
      IpAdress: "IP Adresi",
      DatabaseName: "Veritabanı ismi",
      ServiceName: "Servis ismi",
      UserAndSchemaName: "Kullanıcı Adı/Şema ismi",
      SchemaName: "Şema ismi",
      Username: "Kullanıcı adı",
      Password: "Şifre",
      CouldNotFetch: "Veriler alınamadı.",
      EnterYourPassword: "Öğeyi silmek için şifrenizi girin.",
      AddTableOrSelectConnection: "Pluginler tablolarla etkileşim halindedir. Bir modele tablo eklemek için bir veri bağlantısı eklemeniz veya mevcut bir bağlantıyı seçmeniz gerekir.",
      TwoAlternatives: "İki alternatifiniz var;",
      FileUpload: "Dosya Yükleme",
      ClickToAdd: "Eklemek için tıklayın",
      RDBMSorNosql: "RDBMS veya Nosql",
      EditSource: "Kaynağı Düzenle",
      DeleteSource: "Kaynağı Sil",
      Used: "Kullanılanlar",
      DataConnectionSaved: "Veri bağlantısı başarıyla kaydedildi",
      SuccessTestConnection: "Test bağlantısı başarılı.",
      TruncateSuccess: "Silme işlemi başarılı",
      TruncateFailed: "Silme işlemi başarısız",
      EmptyFieldMessage: "Bu alan boş bırakılamaz!",
      SuccessFileUploadMessage: "Dosya başarıyla yüklendi"
    },
    SelfService: {
      Collection: "Çalışma Alanı",
      CollectionName: "Çalışma Alanı İsmi",
      YouCanNameTheCollection: "Dosyalarınızın yer alacağı çalışma alanına isim verebilirsiniz.",
      YouCanStoreTheCollection: "Dosyalarınızı çalışma alanı altında saklayabilirsiniz.",
      YouCanEditCollection: "Eklediğiniz çalışma alanı ismini düzenleyebilirsiniz."
    },
    Interaction: {
      Interaction: "Etkileşim",
      InteractionTargetSelection: "Bu plugini, seçilen pluginden filtreleme yapabilmek için tıklayın.",
      InteractionTargetSelectionSmall: "Filtreleme için tıklayın.",
      InteractionTargetSelected: "Bu plugin seçilen plugin tarafından filtrelenecektir.\nFiltreleme ayrıntılarını değiştirebilmek için 'DÜZENLE' butonuna tıklayınız.\nFiltrelemeyi kadırmak için 'SİL' butonuna tıklayınız",
      InteractionSourceSelected: "SEÇİLEN PLUGİN\n",
      InteractionModeClose: "Etkileşim modunu kapat",
      InteractionModeCloseWithInteraction: "Etkileşimi kaydet ve etkileşim modunu kapat",
      EditInteraction: "Etkileşimi Düzenle",
      InteractionTypes: "Etkileşim Türleri",
      Reactions: "Tepkiler",
      TitleReactions: "Başlık Etkileşimi",
      UpdateTitle: "Başlık Güncelle",
      ResetTitle: "Başlık Sıfırla",
      ApplyFilter: "Filtre Uygula",
      ApplyToAll: "Tümüne Uygula",
      RemoveToAll: "Tümünden Kaldır",
      IgnoreDefaultFilters: "Varsayılan Filtreleri Yoksay",
      "Yok": "Yok",
      "Update Title": "Başlık Güncelle",
      "Reset Title": "Başlık Sıfırla",
      "Başlık Güncelle": "Başlık Güncelle",
      "Başlık Sıfırla": "Başlık Sıfırla",
      "None": "Yok",
    },
    Notification: {
      Notifications: "Bildirimler",
      YouCanSeeYourNotifications: "Bildirimlerinizi Buradan Görebilirsiniz",
      YCSYNText: "Henüz bir bildiriminiz yok",
      MentionedYouAComment: " bir yorumunda sizi etiketledi.",
      RepliedYouAComment: " bir yorumunuza yanıt verdi.",
      UnsavedChanges: "Kumanda Tablosunda kaydedilmemiş değişiklikler mevcut.",
      UnsavedChangesContent: "Mevcut kumanda tablosunda başka kullanıcı tarafından değişiklikler mevcut. Ne yapmak istiyorsunuz?",
      UnsavedChangesContentContinue: "Diğer değişiklikleri görmezden gel ve devam et.",
      UnsavedChangesContentReloadDashboard: "Yaptığın değişiklikleri yoksay ve yeniden yükle",
      Overwrite: "Üzerine Yaz",
      SaveAs: "Farklı Kaydet",
      Continue: "Devam Et",
      ReloadDashboard: "Kumanda Tablosunu Yeniden Yükle",
    },
    DrillDown: {
      DrillDown: "Detaylar",
      ApplyDrillDown: "Detaya Git",
      DrillDownColumns: "Detay Kolonları",
      RowsDesc: "Drill down satır açıklaması",
      FieldWarning: "Aynı anda sadece tek bir alanda detay verebilirsiniz.",
      PreserveDefaultFilters: "Varsayılan Filtreleri Koru",
    },
    Navigation: {
      Descripton: "Navigasyon bir rapor dan başka bir rapora ilgili filtreleri de alarak gitmeyi sağlar",
      Dashboards: "Gösterge tabloları",
      FiltersUsed: "Bu kontrol panelinde kullanılan filtreler",
      Filters: "Filtreler",
      NavigationList: "Navigasyon Listesi",
      InvalidNavigation: "Geçersiz navigation!",
      ValidColumnFieldNotFound: "Geçerli column alanı bulunamadı",
      OneColumnMustBeSelected: "En az bir sütun seçilmelidir",
      DashboardMustBeSelected: "Kontrol paneli seçilmelidir",
      EditToMakeChanges: "Kayıt daha önceden oluşturulmuştur! Değişiklik yapmak için düzenleyiniz",
      SuccessfullyCreated: "Kayıt başarıyla oluşturuldu!",
      SuccessfullyUpdated: "Kayıt başarıyla güncellendi!",
      Columns: "Kolonlar",
      TransferDefaultFilters: "Varsayılan filtreleri detay rapora taşı",
      TransferDefaultFiltersInfo: "Karşılaştırma kolonun var ise, detay rapora seçtiğin varsayılan filtre ile taşınacaktır",
      TransferCompareValue: "Sadece karşılaştırma değerini taşı",
      TransferCompareValueInfo: "Bu seçeneği işaretlerseniz, karşılaştırma yapılan kolon ile yönlendirilme yapılacak",
      TransferInteractionFilters: "Etkileşimleri detay rapora taşı",
      TransferInteractionFiltersInfo: "Etkileşim filtreleri detay rapora taşınacak",
      PluginCanNotTransferInteractionFilters: "Etkileşim filtreleri taşınamaz"
    },
    AllFilters: {
      DefaultFilters: "Varsayılan Filtre",
      ProtectedDefaultFilters: "Varsayılan Filtre (Korumalı)",
      Navigation: "Navigation",
      Interaction: "Etkileşim",
      Drilldown: "Detay",
    },
    Actions: "İşlemler",
    NoDataContent: {
      NoData: "Seçilen kriterlerde veri bulunamadı",
      CouldNotFetch: "Veri alınamadı",
      PluginCouldNotBeVisualized: "Plugin görselleştirilemedi."
    },
    Cache: {
      Minute: "Dakika",
      OnTheQuarterHour: "Çeyrek Saat",
      OnTheHalfHour: "Yarım Saat",
      OnTheHour: "Tam Saat",
      Midnight: "Gece Yarısı",
      EnableCacheMode: "Önbellek Modu",
      CacheDescription: "Verilerinizi Önbelleğe Almak İçin Aktif Edin",
      UnsavedChanges: "Önbellekte Kaydedilmemiş Değişiklikler Mevcut",
      ExitCacheMode: "Önbellek Modundan Ayrıl",
      AddCacheMode: "Veriyi Önbelleğe Eklemek İçin Buraya Tıkla",
      CacheModeValidTime: "Önbellek Geçerlilik Süresi",
      RemoveCacheModeInPlugin: "Tüm Pluginleri Önbellek Modu'ndan Çıkart",
      AddCacheModeInPlugin: "Tüm Pluginleri Önbellek Modu'na Ekle",
      EveryMin: "Her Dakika",
      CacheModeDescription: "Seçtiğiniz zaman dilimine göre önbelleğe alınmaktadır"
    },
    DateFilter: {
      LastOrFutureNotEmpty: "Son/İleri alanı boş bırakılamaz",
      DateInputNotEmpty: "Lütfen Miktar alanına bir değer giriniz",
      DatePeriodNotEmpty: "Tarih dönemi alanı boş bırakılamaz",
      LastFuture: "Son/İleri",
      Last: "Son",
      Future: "İleri",
      Quantity: "Miktar",
      DatePeriod: "Zaman Periyodu",
      Day: "Gün",
      Week: "Hafta",
      Month: "Ay",
      Year: "Yıl",
      Apply: "Uygula"
    },
    Execute: "Calıştır",
    EXECUTING: "Çalıştırılıyor...",
    Orientation: "Oryantasyon",
    Landscape: "Yatay",
    Portrait: "Dikey",
    PleaseDashboardSave: "Lütfen raporu kaydedin ve tekrar deneyin",
    Navigations: "Navigasyonlar",
    NavigationTitle: "Navigasyon",
    SelectLanguage: "Dil Seçiniz",
    Error: "HATA!",
    Warning: "UYARI!",
    Run: "Çalıştır",
    EmptyErrorMessage: "Hata {{validationErrorMessage}} alanı boş bırakılamaz",
    Success: "Başarılı",
    Create: "Oluştur",
    Save: "Kaydet",
    SuccessfullyDeleted: "Başarıyla silindi",
    ErrorDeleted: "Silinemedi",
    Join: "Birleştir",
    Edit: "Düzenle",
    SessionVariable: "Oturum Değişkeni",
    Delete: "Sil",
    DeleteItAnyway: "Yine De Sil",
    Upload: "Yükle",
    Rename: "Adını değiştir",
    Map: "Harita",
    Reset: "Sıfırla",
    Add: "Ekle",
    Finish: "Bitti",
    Yes: "Evet",
    No: "Hayır",
    AreYouSure: "Emin misiniz?",
    Search: "Ara",
    ClickToOrder: "Sırala & Limit",
    Cancel: "Vazgeç",
    ColumnMapValidError: "{{pluginName}} pluginin zorunlu alanlarında eksik kolon vardır. Lütfen eksik alanları doldurunuz!",
    SomethingWentWrong: "Bir şeyler ters gitti! :(",
    Update: "Güncelle",
    OFF: "YOK",
    Password: "Şifre",
    Requiredfield: "Gerekli Alan",
    OK: "Tamam",
    Okay: "Tamam",
    Inner: "İç",
    Outer: "Dış",
    Custom: "Özel",
    Info: "Bilgi",
    Back: "Geri",
    AllMeasures: "Tüm Ölçüler",
    Settings: "Ayarlar",
    Favorite: "Favori",
    Shared: "Herkese Açık",
    Private: "Özel",
    Labels: "Etiketler",
    BackgroundImage: "Arkaplan Resmi",
    Default: "Varsayılan",
    ReturnToDefault: "Varsayılana Dön",
    ChangeImage: "Görseli Değiştir",
    UploadImage: "Görsel Yükle",
    Page: "Sayfa",
    Description: "Açıklama",
    Title: "Başlık",
    New: "Yeni",
    Close: "Kapat",
    EmptyFieldMessage: "Bu alan boş bırakılamaz!",
    Public: "Herkese Açık",
    Apply: "Uygula",
    CopyLink: "Bağlantıyı Kopyala",
    Measures: "Ölçü Değerleri",
    SelectAll: "Tümünü Seç",
    DeselectAll: "Tümünü Kaldır",
    AllOf: "Tümü",
    Total: "Toplam",
    More: "daha",
    Values: "Değerler",
    Left: "Sol",
    Right: "Sağ",
    OnlyIcon: "Sadece İkon",
    Predicates: "Operatörler",
    Filters: "Filtreler",
    FilterPredicates: {
      In: "İçinde Geçen",
      Equals: "Eşit",
      EqualsIn: "Eşit/İçinde Geçen",
      GreaterThan: "Büyüktür",
      GreaterThanOrEqual: "Büyük veya Eşit",
      LessThan: "Küçüktür",
      LessThanOrEqual: "Küçük veya Eşit",
      NotEqualsIn: "Eşit Değil/İçinde Geçmeyen",
      NotEquals: "Eşit Değil",
      NotIn: "İçinde Geçmeyen",
      IsNull: "Değeri Olmayan",
      IsNotNull: "Değeri Olan",
      Like: "Benzeyen",
    },
    FilterShowPredicates: {
      "=": "eşit",
      "!=": "eşit değil",
      ">": "büyük",
      ">=": "büyük veya eşit",
      "<": "küçük",
      "<=": "küçük veya eşit",
      "in": "içinde geçen",
      "not in": "içinde geçmeyen",
      "notIn": "içinde geçmeyen",
      "like": "benzeyen",
      "not like": "benzemeyen",
      "is null": "değeri olmayan",
      "is not null": "değeri olan",
      "isNull": "değeri olmayan",
      "isNotNull": "değeri olan",
      "CONTAINS": "içeren",
      "ENDS_WITH": "ile biten",
      "STARTS_WITH": "ile başlayan"
    },
    PluginErrors: {
      CannotRenderWithoutTopoJson: "Topojson özellik kodu olmadan kloroplet resmedilemez.",
      TopojsonNotValidFromat: "Topojson geçerli formatta değil!",
      NameOfMapMustNotBeEmpty: "Harita adı boş olmamalıdır.Lütfen bir ad girin!",
      FileSelectionMustNotBeEmpty: "Dosya seçimi boş olmamalıdır.Lütfen bir dosya seçin!",
      PleaseFileInValidFormat: "Lütfen geçerli formatta(topojon or json) file yükleyiniz",
      ImageFormatInvalid: "Geçersiz resim formatı. (Desteklenen formatlar: jpeg, jpg veya png)",
      ImageCouldntUpload: "Resim yüklenemedi",
      CannotRenderVisualisation: "Görsel resmedilemedi. Birden çok ölçüm sütunu veya tek bir ölçüm ve bir renkle çeşitlen boyutu kabul eder.",
      VisualizationError: "Plugin görselleştirilirken bir hata ile karşılaştık. Plugin detayını gözden geçirip tekrar deneyin."
    },
    Selected: "Seçili",
    SaveSuccess: "Saklama başarılı!",
    ReadOnly: "Raporu Sabitle",
    ReadOnlyDescriptionInactive: "Pluginleri sürükleyip bırakma ve yeniden boyutlandırma işlemlerini devre dışı bırakır.",
    ReadOnlyDescriptionActive: "Pluginleri sürükleyip bırakma ve yeniden boyutlandırma işlemlerini aktif hale getirir.",
    TitleNotSet: "Başlık ayarlanmadı",
    "Title not set": "Başlık ayarlanmadı",
    "Başlık ayarlanmadı": "Başlık ayarlanmadı",
    ImageNotSet: "Henüz resim eklenmedi.",
    Expression: "İfade",
    ScrollToTop: "Yukarı Git",
    Expand: "Genişlet",
    Collapse: "Daralt",
    Dashboards: "Raporlar",
    AddNew: "Yeni Ekle",
    TextOptions: "Metin Özellikleri",
    AllColumns: "Tüm Kolonlar",
    Loading: "Yükleniyor",
    OperatorIsNotValid: "operatörü geçerli değil.",
    Disable: "Pasif",
    Enable: "Aktif",
    SubTotal: "Ara Toplam",
    GeneralTotal: "Genel Toplam",
    Group: "Grup",
    Profile: "Profil",
    Logout: "Çıkış",
    Help: "Yardım",
    AdminPage: "Yönetim Sayfası",
    Options: "Ayarlar",
    ClickHereToTryAgain: "Tekrar denemek için buraya tıklayın",
    Copyright: "Telif hakkı 2020 ® Vispeahen tarafından geliştirildi.",
    TableCouldNotFoundInModel: "Seçili modelde tablo bulunamadı!",
    PointClick: "Tıklama - Nokta",
    "Tıklama - Nokta": "Tıklama - Nokta",
    "Başlık Güncelle": "Başlık Güncelle",
    "Başlık Sıfırla": "Başlık Sıfırla",
    "backgroundColor": "Arkaplan Rengi", 
    "colours": "Palet", 
    "condFormat": "Koşulsal Formatlama", 
    "legend": "Gösterge", 
    "titleAlign": "Başlık Pozisyonu", 
    "titleColour": "Başlık Rengi", 
    "titleFont": "Başlık Yazı Stili", 
    "titleFontSize": "Başlık Yazı Boyutu", 
    "titleFontStyle": "Başlık Yazı Stili", 
    "titleFontWeight": "Başlık Yazı Kalınlığı", 
    "titleTextDecor": "Başlık Yazı Dekoru",
    "ReturnToThemeDescription": "Aşağıda belirtilen özellikler temaya dönecektir.",
    "ReturnConfigToTheme": "Konfigürasyonu temaya sıfırla",
    "ReturnToTheme": "Temaya Dön",

    PluginsName: {
      names: {
        "network-graph": "Ağ Grafiği",
        "timeline-chart": "Zaman Çizelgesi Grafiği",
        "scatter-chart": "Scatter Grafiği",
        "chord-diagram": "Chord Diyagram",
        "sankey-chart": "Sankey Grafiği",
        "bar-chart": "Bar Grafiği",
        "cylinder-bar": "Silindir Bar",
        "age-pyramid": "Piramit Grafiği",
        "multi-axis-line-chart": "Çok Eksenli Grafik",
        "line-bar-chart": "Line/Bar Grafiği",
        "filter": "Filtre",
        "selection-box": "Seçim Filtresi",
        "radio-button-filter": "Seçim Filtresi",
        "button-filter": "Buton Filtresi",
        "line-bar": "Çizgi Grafiği",
        "map-bubbles": "Harita (Bubbles)",
        "map-choropleth": "Harita (Choropleth)",
        "map-cluster": "Harita (Cluster)",
        "heatmap": "Harita (Heatmap)",
        "route-map": "Oklu Türkiye Harita",
        "route-map-2": "Oklu Türkiye Harita 2",
        "turkey-map": "Türkiye Haritası",
        "world-map": "Dünya Haritası",
        "measure-tile": "Measure Tile",
        "flag": "Bayrak",
        "i-frame": "I-Frame",
        "image": "Resim",
        "title": "Başlık",
        "pie-chart": "Pasta Grafiği",
        "radial-progress": "Radyal İlerleme",
        "gauge-chart": "Gösterge Tablosu",
        "sunburst": "Sunburst",
        "liquid-gauge": "Sıvı Ölçek",
        "radar": "Radar",
        "pivot-table": "Pivot Tablo",
        "pivot-table-enhance": "Pivot Tablo Enhance",
        "table": "Tablo",
        "tree-map": "Ağaç Haritası",
        "word-cloud": "Kelime Bulutu",
        "pie-chart-enhanced": "Pasta Grafiği",
        "custom": "Özel",
      },
      PluginGroup: {
        "BUBBLE CHARTS": "KABARCIK GRAFİKLERİ",
        "CHORD-SANKEY GRAFİKLERİ": "CHORD-SANKEY GRAFİKLERİ",
        "CHORD-SANKEY CHARTS": "CHORD-SANKEY GRAFİKLERİ",
        "COLUMN CHARTS": "SÜTUN GRAFİKLERİ",
        "COMBINED CHARTS": "BİRLEŞİK GRAFİKLER",
        "FILTERS": "FİLTRELER",
        "LINE CHARTS": "ÇİZGİ GRAFİKLERİ",
        "MAPS": "HARİTALAR",
        "MEASURE TILE": "PERFORMANS DÖŞEME",
        "OTHERS": "DİĞERLERİ",
        "PIE-RING CHARTS": "PASTA-HALKA GRAFİKLERİ",
        "RADAR-SCALE CHARTS": "RADAR-ÖLÇEK GRAFİKLERİ",
        "TABLES": "TABLOLAR",
        "TREE MAP-WORD CLOUD": "AĞAÇ HARİTASI-KELİME BULUTU",
        "CUSTOM": "ÖZEL",
      }
    },
    JoinTable: {
      FactTable: "Bilgi Tablosu",
      FactColumn: "Bilgi Sütunu",
      DimensionTable: "Boyut Tablosu",
      DimensionColumn: "Boyut Sütunu",
      JoinType: "Tür",
      Inner: "İç",
      Outer: "Dış",
      DeleteJoin: "Birleştirme işlemini silmek istediğinize emin misiniz?",
      SelectPlaceholder: "Lütfen Seçiniz",
      NoData: "Veri Yok"
    },
    SessionVariables: {
      Title: "Oturum Değişkenleri",
      SessionName: "İsim",
      SessionDefaultVariable: "Varsayılan Değer",
      NoData: "Veri bulunamadı",
      Scope: "Çalıştırılma Zamanı",
      DataType: "Veri Tipi",
      SessionVariableEditorDescription: "Session Variable Açıklaması...",
      SelectDataSource: "Seçtiğiniz veri kaynağının tablo ve kolonları burada gösterilir.",
      NoDataSourceWarning: "Sorgunun çalıştırılabilmesi için bir veri kaynağı seçmeniz gerekir. Aksi durumda değişken her zaman varsayılan değeri alır.",
      DataTypes: {
        Text: "Metin",
        Number: "Sayı",
        Date: "Tarih"
      },
      Scopes: {
        "Dashboard": "Kumanda Tablosu Yüklenirken",
        "Login": "Giriş Yapılırken",
        "Query": "Sorgu Çalışırken"
      },
      TestQueryResult: "Sorgu Sonucu",
      TestQueryDescription: "Çalıştır butonunu kullanarak sorgu sonucunu test edebilirsiniz.",
      TypeMismatch: "[column] kolonu ile [sessionVariable] değişkeni arasındaki veri tipi uyuşmazlığı nedeniyle sorgu yürütülemeyebilir.",
      PleaseCheckField: "Lütfen [field] alanını kontrol edin.",
      PleaseCheckFields: "Lütfen [fields] alanlarını kontrol edin.",
      NameAlreadyExists: "Bu isimli bir değişken zaten mevcut.",
      AreYouSureDeleteSessionVariable: "Bu değişkeni silmek istediğinize emin misiniz?",
      Errors: {
        DS_NOT_FOUND: "Veri kaynağı bulunamadı.",
        DS_UNSUPPORTED: "Desteklenmeyen veri kaynağı tipi.",
        NO_DS_SELECTED: "Seçili bir veri kaynağı bulunamadı.",
        EmptyQueryResult: "Sorgu sonucu boş."
      }
    },
    TablePagination: {
      Next: "Sonraki sayfa",
      Previous: "Onceki sayfa"
    }
  }
};
