import React, { Component } from "react";
import DataConnectionCreateForms from "./DataConnections/DataConnectionCreateForms";
import "./dataSources.css";
import DataSourcesPopup from "../Modeling/DataSourcesPopup";
import ModelsPopup from "../Modeling/ModelsPopup";
import $ from "jquery"
import AddDataWithoutDataSourceForm from "./DataConnections/AddDataWithoutDataSourceForm";
import { connect } from "react-redux";
import { store } from "../..";
import { setNewColumnEditorContent } from "../GeneralComponents/NewColumn/NewColumnEditorAction";

/**
 * This component is the parent component that includes ModelsPopup, DataSourcesPopup, DataConnectionCreateForms components.
 * Includes common methods and communication of components
 */
class DataSourceMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleDataConnections: false,
      createDatasourceModalVisible: false,
      visibleDataSourcesPopup: false,
      visibleModelContentView: false,
      newConnection: {},
      editConnectionData: {},
      selectedTables: [],
      editModel: {},
      changeSelectedDataSourceVisible: false,
      newTableInModel: {},
      joinPopupVisible: false,
      fileUploadModalVisible: false,
      visibleDataUpload: false,
      datasourceEditMode: false,
      dataSourceEditModeId: "",
      isNotUpdatedSelfService: false,
      editedSelfService: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.display !== this.props.display) {
      this.setState({
        visibleModelContentView: nextProps.display,
      });
    }

    if (nextProps.selfService.openModelPopup === true) {
      this.setState({
        visibleModelContentView: true,
      });
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  //If click outside of the model and popups.
  handleClickOutside = (event) => {
    let isTargetTooltip =
      $(event.target).closest(".accordion-content-tooltip").length > 0 ||
      $(event.target).closest(".box-item-tooltip").length > 0 ||
      $(event.target).closest(".ant-tooltip-content").length > 0 ||
      $(event.target).closest(".models-toolbar-tooltip").length > 0 ||
      $(event.target).closest(".edit-button-tooltip").length > 0 ||
      $(event.target).closest(".data-source-list-tooltip").length > 0 ||
      $(event.target).closest(".back-button-tooltip").length > 0 ||
      $(event.target).closest(".model-item-operations-tooltip").length > 0 ||
      $(event.target).closest(".model-item-operation-icons-tooltip").length > 0 ||
      $(event.target).closest(".ant-notification").length > 0

    let isTargetInnerPopup =
      $(".innerPopup").length > 0 &&
      $(event.target).closest(".innerPopup").length > 0
    let isTargetModelButton =
      $(event.target).closest("#modelButton").length === 0 ? false : true;
    let isTargetModelSelectDropdown =
      $(event.target).closest(".ant-select-dropdown").length > 0
    let isTargetPopoverInnerContent =
      $(event.target).closest(".ant-popover-inner-content").length > 0
    let isTargetDropdownMenu =
      $(event.target).closest(".ant-dropdown-menu").length > 0
    let antDropdownMenuItem =
      $(event.target).closest(".ant-dropdown-menu-item").length > 0
    let isTargetModelItemPanel =
      $(event.target).closest(".model-item-panel").length > 0
    let isTagetModel =
      $(".ant-modal-wrap").length > 0 &&
      $(event.target).closest(".ant-modal-wrap").length > 0;
    let isTargetSplashScreen =
      $(event.target).closest("#splashScreenContainer").length > 0
    let isTargetMinimized =
      $(event.target).closest(".file-upload-status-minimized").length > 0;
    let isTooltip =
      $(event.target).closest(".ant-tooltip").length > 0

    let isTargetOutOfModel =
      (isTargetInnerPopup || isTargetTooltip || isTargetModelSelectDropdown ||
        isTargetPopoverInnerContent || isTargetDropdownMenu || antDropdownMenuItem || isTagetModel ||
        isTargetModelItemPanel || isTargetSplashScreen || isTargetMinimized || isTooltip) === true ? false : true
    let isJoinPopupClosed = !this.state.joinPopupVisible
    let isFileUploadPopupClosed = !this.state.fileUploadModalVisible
    let doesNeedToCloseAllContents = isTargetOutOfModel && isJoinPopupClosed && isFileUploadPopupClosed

    //If target outside of model when there is no open popup, close all
    if (doesNeedToCloseAllContents) {
      if (!$(event.target).closest("#loginPopup").length > 0) {
        this.setState({
          visibleModelContentView: false,
          visibleDataConnections: false,
          visibleDataSourcesPopup: false,
          visibleDataUpload: false,
          datasourceEditMode: false,
          dataSourceEditModeId: "",
          editedSelfService: {}
        }, () => {
          let newColumnObject = {}

          newColumnObject.table = ""
          newColumnObject.visible = false
          newColumnObject.selectedTable = ""
          newColumnObject.mode = ""
          newColumnObject.code = ""

          store.dispatch(setNewColumnEditorContent(newColumnObject))
        })
      }
    }

    if (isTargetModelButton) {
      this.setState({
        visibleModelContentView: true
      })
    }
  }

  /*
  * Sets visible of data upload status
  */
  setVisibleDataUploadStatus = (status, callback = false) => {
    this.setState({
      ...this.state,
      visibleDataUpload: status,
      visibleDataConnections: false
    }, () => {
      if (callback) {
        this.dataSourceEditForWithoutDatabase({}, false)
      }
    })
  }

  /*
  * Just closes data upload popup, not get all datasource datas
  */
  justCloseDataUploadStatus = () => {
    this.setState({
      ...this.state,
      visibleDataUpload: false,
      datasourceEditMode: false,
      dataSourceEditModeId: "",
      isNotUpdatedSelfService: true,
      visibleDataConnections: false
    }, () => {
      this.setState({
        ...this.state,
        isNotUpdatedSelfService: false
      })
    })
  }

  /**
   *  change join popup visibility
   */
  setJoinModalPopupVisiblity = (status) => {
    this.setState({
      ...this.state,
      joinPopupVisible: status
    })
  }

  /**
   *  change File upload popup visibility
   */
  changeFileUploadModalVisibity = (status) => {
    this.setState({
      ...this.state,
      fileUploadModalVisible: status
    })
  }

  /**
   *  model status and edit information
   */
  visibleDataConnections = (status, editData = undefined) => {
    if (editData?.dataSourceType !== "duckdb") {
      this.setState({
        ...this.state,
        visibleDataConnections: status,
        editConnectionData: editData,
      });
    } else {
      this.dataSourceEditForWithoutDatabase(editData, true)
    }
  };

  /**
   * Change status of selected dataSource
   */
  changeSelectedDataSource = (status) => {
    this.setState({
      changeSelectedDataSourceVisible: status,
    });
  };

  /**
   * Turns DataSourcesPopup on or off
   */
  visibleDataSourcesPopup = (status) => {
    this.setState({
      ...this.state,
      visibleDataSourcesPopup: status,
      visibleModelContentView: this.state.visibleModelContentView,
      visibleDataConnections: false,
    });
  };

  /**
   * Turns ModelsPopup on or off. Closes other popups
   */
  visibleModelContentView = (status) => {
    this.setState({
      ...this.state,
      visibleDataSourcesPopup: false,
      visibleDataConnections: false,
      visibleModelContentView: status,
      visibleDataUpload: status === false ? false : this.state.visibleDataUpload,
      datasourceEditMode: status === false ? false : this.state.datasourceEditMode,
      dataSourceEditModeId: status === false ? false : this.state.dataSourceEditModeId,
      editedSelfService: status === false ? false : this.state.editedSelfService
    });

    if (status == false) {
      this.props.close();
    }
  };

  /**
   * Keeps newly added connection information in newConnection variable and closes adding popup
   */
  addNewConnection = (newConnection) => {
    this.setState({
      ...this.state,
      newConnection: newConnection,
      visibleDataConnections: false,
    });
  };

  /**
   * Adds the 'status' information to the newTableInModel variable according to the selected table and the state of addition or subtraction.
   */
  selectedTables = (table, status, data) => {
    this.setState({
      ...this.state,
      newTableInModel: { table: table, status: status, data: data },
    });
  };

  /**
   * Opens the dataSource popup for the model edit function and adds the model to be edited to the editModel variable.
   */
  editModel = (model) => {
    this.setState({
      editModel: model,
      visibleDataSourcesPopup: true,
      visibleModelContentView: this.state.visibleModelContentView,
      visibleDataConnections: false,
    });
  };

  /**
   * Sets the response returned and the newly added table to the modelTableSaveResponse variable after saving the model.
   */
  modelTableSaveResponse = (newTableInModel, status) => {
    this.setState({
      ...this.state,
      modelTableSaveResponse: { table: newTableInModel, status: status },
    });
  };

  /*
  * Data source edit (Self Service Operation)
  */
  dataSourceEditForWithoutDatabase = (connection, status) => {
    this.setState({
      datasourceEditMode: status,
      dataSourceEditModeId: connection.name,
      editedSelfService: connection
    })
  }

  render() {
    return (
      <div className="datasource-menu">
        <ModelsPopup
          cancel={() => this.visibleModelContentView(false)}
          visible={this.state.visibleModelContentView}
          autoSelectModelId={this.props.selfService.modelId}
          newTableInModel={this.state.newTableInModel}
          editModel={this.editModel}
          modelId={this.props.modelId}
          modelTableSaveResponse={this.modelTableSaveResponse}
          setSelectedModel={this.props.setSelectedModel}
          selectedTables={this.selectedTables}
          visibleDataSourcesPopup={this.visibleDataSourcesPopup}
          join={this.props.join}
          findJoinChanges={this.props.findJoinChanges}
          model={this.props.model}
          getModelTables={this.props.getModelTables}
          joinPopupVisible={this.state.joinPopupVisible}
          setJoinModalPopupVisiblity={this.setJoinModalPopupVisiblity}
          updateModelTablesForJoin={this.props.updateModelTablesForJoin}
          getModel={this.props.getModel}
          newColumnAddedFlag={this.props.newColumnAddedFlag}
          removeNewColumn={this.props.removeNewColumn}
        />
        <DataSourcesPopup
          model={this.props.model}
          newConnection={this.state.newConnection}
          changeSelectedDataSource={this.changeSelectedDataSource}
          editModel={this.state.editModel}
          visibleDataConnections={this.visibleDataConnections}
          joins={this.props.join}
          cancel={() => this.visibleDataSourcesPopup(false)}
          visible={this.state.visibleDataSourcesPopup}
          selectedTables={this.selectedTables}
          modelTableSaveResponse={this.state.modelTableSaveResponse}
          changeFileUploadModalVisibity={this.changeFileUploadModalVisibity}
          fileUploadModalVisible={this.state.fileUploadModalVisible}
          setVisibleDataUploadStatus={this.setVisibleDataUploadStatus}
          datasourceEditMode={this.state.datasourceEditMode}
          dataSourceEditModeId={this.state.dataSourceEditModeId}
          dataSourceEditForWithoutDatabase={this.dataSourceEditForWithoutDatabase}
          editedSelfService={this.state.editedSelfService}
          isNotUpdatedSelfService={this.state.isNotUpdatedSelfService}
        />
        <DataConnectionCreateForms
          addData={this.addNewConnection}
          changeSelectedDataSourceVisible={
            this.state.changeSelectedDataSourceVisible
          }
          editConnectionData={this.state.editConnectionData}
          cancel={() => this.visibleDataConnections(false)}
          visible={this.state.visibleDataConnections}
          dataSourceEditForWithoutDatabase={this.dataSourceEditForWithoutDatabase}
        />
        <AddDataWithoutDataSourceForm
          visible={this.state.visibleDataUpload}
          setVisibleDataUploadStatus={this.setVisibleDataUploadStatus}
          datasourceEditMode={this.state.datasourceEditMode}
          dataSourceEditModeId={this.state.dataSourceEditModeId}
          dataSourceEditForWithoutDatabase={this.dataSourceEditForWithoutDatabase}
          editedSelfService={this.state.editedSelfService}
          justCloseDataUploadStatus={this.justCloseDataUploadStatus}
          visibleModelContentView={this.state.visibleModelContentView} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selfService: state.SelfServiceReducer,
  };
};

export default connect(mapStateToProps)(DataSourceMenu);